import React, { createElement, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import routes from '../../../../../../common/routes'
import webApp from '../../../../../utils/exdioWebAppUtils'
import { META_SCHEMA_ID } from '../../../../../../../constants/app'

/* style */
import { StyledP, StyledLink } from './style'

/** 次の／前のストーリーボタン */
const NextPrevButton = (
  { meta = null, nextOrPrev = 'next', children },
  context
) => {
  const model = context.falcorModel.batch(100)
  const [nextOrPrevMeta, setNextOrPrevMeta] = useState(null)

  useEffect(() => {
    getNextOrPrevMeta()
  }, [])

  /** メタ情報取得 */
  const getNextOrPrevMeta = () => {
    const nextOrPrevMetaKey = nextOrPrev === 'next' ? 'next_meta' : 'prev_meta'
    const nextOrPrevMeta = get(meta, ['values', nextOrPrevMetaKey])
    if (!nextOrPrevMeta) return

    switch (typeof nextOrPrevMeta) {
      case 'object':
        setNextOrPrevMeta(nextOrPrevMeta)
        break

      default:
        const path = ['metas', nextOrPrevMeta]
        model.fetch([path]).then((result) => {
          setNextOrPrevMeta(get(result, ['json', ...path]) || null)
        })
        break
    }
  }

  /** routeの判別 */
  const setRoute = (meta) => {
    if (!meta) return null
    const metaSchemaId = meta.meta_schema_id
    const isCatchUp =
      metaSchemaId === META_SCHEMA_ID.EPISODE ||
      metaSchemaId === META_SCHEMA_ID.LIVE // キャッチアップ判別
    const isApp = webApp.utils.isApp(context) // アプリ判別

    /** キャッチアップのroute */
    const catchUpRoute = isApp
      ? routes.app_catchupEpisode
      : routes.catchupEpisode

    /** その他エピソードのroute */
    const episodeRoute = isApp ? routes.app_episode : routes.episode

    return isCatchUp ? catchUpRoute : episodeRoute
  }

  const route = setRoute(nextOrPrevMeta)
  const seriesId = get(nextOrPrevMeta, ['values', 'parents_series', 'id'], null)
  const seasonId = get(nextOrPrevMeta, ['values', 'parents_season', 'id'], null)
  const episodeId = get(nextOrPrevMeta, ['meta_id'], null)
  const params = { seriesId, seasonId, episodeId }

  if (route) {
    return (
      <StyledLink route={route} params={params}>
        {children}
      </StyledLink>
    )
  }

  return <StyledP>{children}</StyledP>
}

export default NextPrevButton

NextPrevButton.propTypes = {
  // 月額見放題に含まれない単話の場合
  meta: PropTypes.shape({
    values: PropTypes.shape({
      prev_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
      next_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
    })
  }),
  nextOrPrev: PropTypes.oneOf(['next', 'prev'])
}

NextPrevButton.contextTypes = {
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object
}
