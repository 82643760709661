import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Footer extends Component {
  static contextTypes = {
    models: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.handleScrollToTop = this.handleScrollToTop.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.dougaLink = this.context.models.config.data.dougaLink;
  }

  static propTypes = {
    copyright: PropTypes.string,
  };

  //トップに移動する関数
  handleScrollToTop() {
    let scrolled = ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
    window.scrollTo( 0, Math.floor(scrolled/2));
    if (scrolled > 0) {
      window.setTimeout(this.handleScrollToTop, 30);
    }
  };
  componentDidMount() {
    window.addEventListener('scroll', this.handleThrottleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleThrottleScroll);
  };

  handleScroll() {
    let topButton = document.getElementById('btn-pgtop');
    let scrolled = ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
      (scrolled > 1) ? topButton.classList.add('fade-in') : topButton.classList.remove('fade-in');
  };
  handleThrottleScroll = _.throttle(this.handleScroll, 100);

  /* 
   アプリでluidを発行するために、基盤プレビュー環境のワンタイムURLが記載されているページを踏む必要がある
   アプリのステージング、開発環境でその静的ページを表示するリンクを付与する
  */
  renderOnetimeUrlLink() {
    let onetimeUrlLink;
    if (process.env.NODE_ENV != "production") {
      onetimeUrlLink = (<li><span>｜</span><a href="/luid_onetime/">ワンタイムURL</a></li>);
    }
    return onetimeUrlLink;
  }  

  render() {
    return (
      <footer id="app_footer" className="clearfix">
        <div id="footer_inner">
          <div id="btn-pgtop">
            <a href="javascript:void(0)" onClick={((e) => {this.handleScrollToTop()})} className="btn-pgtop_api"><img src="/images/exdio/btn_pagetop.gif" alt="btn_pagetop"/></a>
          </div>
          <div id="foot_copylight">
              <div className="foot_copylight_inner">{this.props.copyright}</div>
            </div>
        </div>
      </footer>
    );
  }
}
