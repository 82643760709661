import styled from 'styled-components'
import { pcMinSize } from '../styles'
import { mediaQuery } from '../../../style'
import Slider from './Slider/'
import List from './List/'

export const StyledSection = styled.section`
  margin: 0 auto;
  padding: 60px 0 36px;
  max-width: ${pcMinSize};
`

export const StyledH2 = styled.h2`
  font-size: 4.4rem;
  font-weight: 900;
  text-align: center;

  ${mediaQuery()} {
    font-size: 2rem;
  }

  span {
    padding: 0 100px;
    background: url(/images/exdio/renewal/gariben_daigaku/top/ranking.webp)
        no-repeat left center,
      url(/images/exdio/renewal/gariben_daigaku/top/ranking.webp) no-repeat
        right center;
    display: inline-block;

    ${mediaQuery()} {
      padding: 0 54px;
      background-size: 43px, 43px;
    }
  }
`

export const StyledList = styled(List)`
  margin-top: 30px;
`

export const StyledSlider = styled(Slider)`
  margin-top: 20px;
`
