/**
 * Dioサムネイル用 サイズ指定プレフィックスを付与
 * @param src: string
 * @param size: string [small, medium, large]
 */

export default function addSizePrefix(src='', size='medium') {
  if (!src) return
  if (!size) return src

  const sizeTypes = ['small', 'medium', 'large']

  if (size && sizeTypes.includes(size)) {
    return src.match(/\.$/) ? src.replace(/\.$/, `._${size}`) : src
  } else {
    return src
  }
}
