import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import routes from '../../../../common/routes'
import { Link } from '../../../../../sketch-platform/ui/routing'

const StickyLoginButton = ({ showLoginButtons = false, ...props }) => {
  return (
    <StyledDiv showLoginButtons={showLoginButtons} {...props}>
      <StyledLink
        route={routes.app_login}
        query={{ redirect: routes.app_mypage.makePath() }}
      >
        ログイン
      </StyledLink>
    </StyledDiv>
  )
}

export default StickyLoginButton

StickyLoginButton.propTypes = {
  showLoginButtons: PropTypes.bool
}

const StyledDiv = styled.div.withConfig({
  shouldForwardProp: (prop) => !['showLoginButtons'].includes(prop)
})`
  padding: 18px 14px 60px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  z-index: 5;
  opacity: 1;
  transition: opacity 0.2s linear 0s, visibility;

  ${({ showLoginButtons }) =>
    !showLoginButtons &&
    css`
      opacity: 0;
      z-index: -100;
    `}
`

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  height: 48px;
  text-align: center;
  border-radius: 5px;
  background: #000000;
  font-weight: bold;
  line-height: 48px;

  &:link,
  &:hover,
  &:visited,
  &:active {
    color: #fff;
  }
`
