import window from 'global/window'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import Cookie from 'js-cookie'
import MDSpinner from 'react-md-spinner'
import routes from '../../../routes'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'

/* components */
import { LOGIN_CONTENTS } from '../../../../../constants/app'
import Nav from './Nav'
import Login from './Login'
import SignupLink from './SignupLink'

/* style */
import { StyledWrapper, StyledDiv1, StyledDiv1Inner } from './styles'

const ERROR_SNS_LOGIN = 'SNSアカウントでのログインに失敗しました。'
const ERROR_SNS_LOGIN_REGISTER_FAILED =
  '登録できませんでした。管理者にお問い合わせください。'
const ERROR_SNS_LOGIN_EMAIL_EXISTS =
  'ご利用のメールアドレスはすでに登録されています。'
const ERROR_INTERNAL = 'エラーが発生しました。管理者にお問い合わせください。'

/** ログインフォーム共通部品 */
export default class LoginOrSignup extends Component {
  static propTypes = {
    content: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string
    }),
    /** タブ切り替え時コールバック */
    onChangeContent: PropTypes.func,
    /** ログイン成功後コールバック */
    onLoggedIn: PropTypes.func,
    callbackUrl: PropTypes.string
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object,
    updateUserInfo: PropTypes.func,
    updateGlobalNavigation: PropTypes.func
  }

  static defaultProps = {
    content: LOGIN_CONTENTS.LOGIN,
    onChangeContent: null,
    onLoggedIn: null,
    callbackUrl: null
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    this.state = {
      currentContent: props.content,
      isSubmitting: false,
      isSubmittingSnsAuth: this.isSnsLoginSucceeded(),
      errorMessages: [],
      socialLoginRedirectUrl: ''
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.setSocialLoginRedirectUrl()
    if (this.isSnsLoginSucceeded()) {
      this.snsAuth()
    } else if (this.isSnsLoginFailed()) {
      this.setSnsLoginErrorMessage()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.content !== nextProps.content) {
      this.setState({
        currentContent: nextProps.content
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onChangeContent(content, e) {
    e.preventDefault()
    if (this._isMounted) {
      this.setState({ currentContent: content })
    }
    if (this.props.onChangeContent) {
      this.props.onChangeContent(content)
    }
  }

  setSocialLoginRedirectUrl() {
    // SSRで設定すると画面直アクセス時にaタグにURLが設定されないのでSPAでセット
    const { host } = this.config
    const { route, params, query } = this.context.routeHandler
    const redirect = _.get(query, ['redirect'], '')

    const isApp = webApp.utils.isApp(this.context)
    const loginPageUrl = route.makePath(params, { redirect }, {})
    const androidSnsLoginUrl = routes.android_sns_login.makePath(
      {},
      { redirect },
      {}
    )
    const socialLoginRedirectUrl = encodeURIComponent(
      `${host}${isApp ? androidSnsLoginUrl : loginPageUrl}`
    )
    this.setState({ socialLoginRedirectUrl })
  }

  getEncryptQuery() {
    if (!this.encryptQuery) {
      const locationSearch = get(
        this.context,
        ['history', 'location', 'search'],
        ''
      )
      const urlSearchParams = new URLSearchParams(locationSearch)
      const query = Object.fromEntries(urlSearchParams.entries())
      this.encryptQuery = query.ret || null
    }
    return this.encryptQuery
  }

  setSnsLoginErrorMessage() {
    const { err } = this.context.routeHandler.query
    switch (err) {
      case '1':
      case '2':
      case '3':
        if (this._isMounted) {
          this.setState({ errorMessages: [ERROR_SNS_LOGIN_REGISTER_FAILED] })
        }
        break
      case '4':
        if (this._isMounted) {
          this.setState({ errorMessages: [ERROR_SNS_LOGIN_EMAIL_EXISTS] })
        }
        break
      default:
        if (this._isMounted) {
          this.setState({ errorMessages: [ERROR_SNS_LOGIN] })
        }
        break
    }
  }

  /** SNS認証 */
  snsAuth() {
    if (this.state.isSubmitting) return

    this.setState({ isSubmitting: true, isSubmittingSnsAuth: true }, () => {
      const encryptMemberId = this.getEncryptQuery()
      const { query } = this.context.routeHandler
      const cbsessidQuery = _.get(query, ['cbsessid'], '')
      const cbsessid = cbsessidQuery || Cookie.get('CBSESSIONID') || ''
      // 本番は.tv-asahi.co.jp, STGは.tvasahi.jpのサブドメインでCBSESSIONIDのCookieが発行されるのでlocalでは取得できない
      // if (this.context.models.env === 'development' && !cbsessid) cbsessid = 'ddb5p94hhu7u8e9an31k6hq086';

      // session regenerateできないため、Falcor経由ではなく、nodejs上のAPIを利用
      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            try {
              const response =
                get(JSON.parse(xhr.responseText), ['response']) || {}
              if (xhr.status !== 200) return reject(response)
              return resolve(response)
            } catch (err) {
              return reject(err)
            }
          }
        }
        xhr.open('post', '/do_sns_login', false)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.send(JSON.stringify({ encryptMemberId, cbsessid }))
      })
        .then((result) => {
          webApp.utils.debug(
            `[LoginForm] SNS Login succeeded. result: ${JSON.stringify(result)}`
          )
          const isApp = webApp.utils.isApp(this.context)

          // 購入導線の場合、遷移先はpurchaseのまま
          if (routes.purchase.match(this.context.routeHandler.path)) {
            window.location.href = routes.purchase.makePath(
              {},
              { content: 'after_login' }
            )
          } else {
            const { redirect } = this.context.routeHandler.query
            const route = isApp ? routes.app_home : routes.home
            window.location.href = redirect || route.makePath()
          }
        })
        .catch((error) => {
          webApp.utils.debug(
            `[LoginForm] SNS Login failed. error: ${JSON.stringify(error)}`
          )
          const isApp = webApp.utils.isApp(this.context)
          const loginKey = isApp ? 'app_login' : 'login'

          if (error.status === 401 && this._isMounted) {
            this.setState({ errorMessages: [ERROR_SNS_LOGIN] })
          } else if (error.status === 500 && this._isMounted) {
            this.setState({ errorMessages: [ERROR_INTERNAL] })
          }
          // ログインに失敗した場合にはretパラメータをリセット
          const query = Object.assign({}, this.context.routeHandler.query)
          delete query.ret
          // 購入導線の場合、遷移先はpurchaseのまま
          if (routes.purchase.match(this.context.routeHandler.path)) {
            this.context.history.replace(
              routes.purchase.makePath({}, query, {})
            )
          } else {
            this.context.history.replace(
              routes[loginKey].makePath({}, query, {})
            )
          }
          this.setSocialLoginRedirectUrl()
          // ログイン成功時にフォームが一瞬表示されてしまうので、ローディングスピナーはログイン失敗の場合のみ消す
          if (this._isMounted) {
            this.setState({ isSubmittingSnsAuth: false })
          }
        })
        .finally(() => {
          if (this._isMounted) {
            this.setState({ isSubmitting: false })
          }
        })
    })
  }

  isSnsLoginSucceeded() {
    // クエリパラメータretがあったらSNSログイン成功
    return !!this.getEncryptQuery()
  }

  isSnsLoginFailed() {
    // クエリパラメータerrがあったらSNSログイン失敗
    return !!this.context.routeHandler.query.err
  }

  render() {
    const { onChangeContent, callbackUrl, onLoggedIn, content } = this.props
    const { currentContent, socialLoginRedirectUrl, errorMessages } = this.state
    if (this.state.isSubmittingSnsAuth) {
      return (
        <div className="mdspinner">
          <MDSpinner size={96} />
        </div>
      )
    }

    return (
      <StyledWrapper {...this.props} style={{ display: 'none' }}>
        <Nav
          currentContent={currentContent}
          onChangeContent={onChangeContent}
        />
        <StyledDiv1>
          <StyledDiv1Inner>
            {currentContent === LOGIN_CONTENTS.LOGIN ? (
              <Login
                onLoggedIn={onLoggedIn}
                content={content}
                socialLoginRedirectUrl={socialLoginRedirectUrl}
                errorMessages={errorMessages}
              />
            ) : (
              <SignupLink callbackUrl={callbackUrl} />
            )}
          </StyledDiv1Inner>
        </StyledDiv1>
      </StyledWrapper>
    )
  }
}
