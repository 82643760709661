import React, { Component } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import _ from 'lodash'
import classnames from 'classnames'
import {
  LOCAL_STORAGE_KEY_PURCHASE,
  PURCHASE_CONTENTS,
  PURCHASE_TYPE,
  PRODUCT_SCHEMA
} from '../../../../../constants/app'
import AboutMedalLink from '../../../../common/components/renewal/AboutMedalLink'
import Axios from '../../../../../common/Axios'
import routes from '../../../../common/routes'
import Steps from './Steps'
import webApp from '../../../../../apps/exdio/utils/exdioWebAppUtils'

/** 購入ページ:商品の確認 */
export default class Select extends Component {
  static propTypes = {
    type: PropTypes.string,
    episodes: PropTypes.arrayOf(PropTypes.object),
    howToPlays: PropTypes.object,
    isPossibleForPack: PropTypes.object,
    pack: PropTypes.object,
    course: PropTypes.object,
    selected: PropTypes.arrayOf(PropTypes.number),
    availablePoint: PropTypes.shape({
      available_point: PropTypes.number,
      available_point_from_tvasahi_point: PropTypes.number
    }),
    purchased: PropTypes.arrayOf(PropTypes.object),
    belongsToGroup: PropTypes.bool,
    setContent: PropTypes.func.isRequired,
    emitSetState: PropTypes.func.isRequired
  }

  static defaultProps = {
    type: '',
    episodes: [],
    howToPlays: {},
    isPossibleForPack: {},
    pack: {},
    course: {},
    availablePoint: {
      available_point: 0,
      available_point_from_tvasahi_point: 0
    },
    selected: [],
    purchased: []
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = this.context.models.config.data

    this.state = {
      captionOpened: [],
      showMore: false
    }

    this.onClickLogin = this.onClickLogin.bind(this)
    this.onClickMore = this.onClickMore.bind(this)
    this.goNext = this.goNext.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  onClickLogin(e) {
    e.preventDefault()
    this.props.setContent(PURCHASE_CONTENTS.LOGIN)
  }

  onClickSelect(metaId) {
    let { selected } = this.props
    if (selected.includes(metaId)) {
      selected = selected.filter((id) => id !== metaId)
    } else {
      selected.push(metaId)
    }
    this.props.emitSetState({ selected })

    const tmp = window.localStorage.getItem(LOCAL_STORAGE_KEY_PURCHASE)
    const storage = JSON.parse(tmp)
    storage.selected = selected
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_PURCHASE,
      JSON.stringify(storage)
    )
  }

  onClickMore() {
    this.setState({ showMore: !this.state.showMore })
  }

  toggleOpen(metaId) {
    let { captionOpened } = this.state
    if (captionOpened.includes(metaId)) {
      captionOpened = captionOpened.filter((id) => id !== metaId)
    } else {
      captionOpened.push(metaId)
    }
    this.setState({ captionOpened })
  }

  async goNext(e) {
    e.preventDefault()

    const {
      episodes,
      selected,
      pack,
      setContent,
      availablePoint,
      type,
      belongsToGroup,
      howToPlays
    } = this.props

    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (isLoggedIn) {
      const totalPrice = this.totalPrice()

      if (PURCHASE_TYPE.PLAN === type) {
        // 月額見放題
        if (belongsToGroup) {
          // 購入済みは完了画面
          this.props.emitSetState({ alreadyPurchasedAll: true }, () => {
            this.props.setContent(PURCHASE_CONTENTS.COMPLETED)
          })
        } else {
          // 未購入は支払い画面
          this.context.history.push(
            routes.purchase_license.makePath(
              {},
              { LICENSE: this.props.course.ref_id }
            )
          )
        }
      } else {
        // 月額見放題以外
        if (!selected.length) return

        if (
          _.get(availablePoint, ['available_point_from_tvasahi_point'], 0) +
            _.get(availablePoint, ['available_point'], 0) <
          totalPrice
        ) {
          // 残高足りなければメダルチャージ画面
          const ax = new Axios({
            baseURL: '/api/crypto',
            timeout: 3000,
            headers: {},
            // タイムアウトしていないのにタイムアウトエラーがでる対応ととして
            // retryオプションを付ける
            retry: 1
          })
          let data = { contents: [], total: this.totalPrice() }
          if (PURCHASE_TYPE.PACK === type) {
            data.contents.push({
              title: pack.name,
              amount: _.get(pack, ['active_pricing', 'price'])
            })
          } else {
            const selectedMetas = episodes.filter((meta) =>
              selected.includes(meta.meta_id)
            )
            _.forEach(selectedMetas, (meta) => {
              const price = webApp.utils.calcPrice(howToPlays, [meta.meta_id])
              let [metaName, subTitle] = webApp.utils.titles(meta)
              metaName = `${metaName} ${subTitle}`
              data.contents.push({
                title: metaName,
                amount: price
              })
            })
          }
          ax.get('/encrypto', { data: JSON.stringify(data) })
            .then((response) => {
              console.log(`response: ${JSON.stringify(response.data)}`)
              this.context.history.push(
                routes.medal_charge.makePath(
                  {},
                  {
                    content: 'charge',
                    data: response.data.encrypted,
                    src: this.context.routeHandler.url
                  }
                )
              )
            })
            .catch((err) => console.log(JSON.stringify(err)))
        } else {
          // 残高あれば確認画面
          setContent(PURCHASE_CONTENTS.CONFIRM)
        }
      }
    } else {
      setContent(PURCHASE_CONTENTS.LOGIN)
    }
  }

  goBack(e) {
    e.preventDefault()

    // メダルチャージの戻るはhistory.backではフォーム再送信警告をブラウザが行ってしまうため、srcクエリパラメータから遷移先を判断している。
    // そのため、商品選択後にメダルチャージに入り、フォームのsubmitを行った後、戻ってきた場合、元々のコンテンツページに一度の戻るボタンクリックでは戻れなくなるので、
    // localstorageに格納されている遷移元画面の情報に従い遷移する。
    if (
      this.context.routeHandler.query.src === routes.medal_charge.makePath()
    ) {
      const tmp = window.localStorage.getItem(LOCAL_STORAGE_KEY_PURCHASE)
      const storage = JSON.parse(tmp)
      if (storage) {
        if (storage.page) {
          this.context.history.replace(storage.page)
          return
        }
      }
      this.context.history.replace(routes.home.makePath())
      return
    }

    this.context.history.goBack()
  }

  totalPrice() {
    const {
      type,
      howToPlays,
      pack,
      course,
      selected,
      belongsToGroup,
      isPossibleForPack
    } = this.props
    const isPackOnSale = webApp.utils.isOnSale(this.context, pack)
    if (PURCHASE_TYPE.EPISODE === type) {
      return webApp.utils.calcPrice(howToPlays, selected) || 0
    }
    if (PURCHASE_TYPE.PACK === type) {
      if ((isPossibleForPack && isPossibleForPack.status) || !isPackOnSale)
        return 0
      return _.get(pack, ['active_pricing', 'price']) || 0
    }
    if (PURCHASE_TYPE.PLAN === type) {
      if (belongsToGroup) return 0
      return _.get(course, ['active_pricing', 'price']) || 0
    }
    return null
  }

  /** 単話用 */
  renderEpisodes() {
    const { episodes } = this.props
    const { showMore } = this.state
    const classNameMore = showMore ? 'on' : ''
    const initialList = episodes.slice(0, 6)
    const moreList = episodes.slice(6)

    const rtn = [
      <div key="list" className="finish-wrapper movie-list-wrapper">
        <ul className={`movie-list-area ${classNameMore}`}>
          {this.renderEpisodeList(initialList)}
        </ul>
        <ul className={`more-list-wrapper ${classNameMore}`}>
          {this.renderEpisodeList(moreList)}
        </ul>
      </div>
    ]
    if (moreList.length) {
      rtn.push(
        <p
          key="more"
          className={`c-login-subtext movie-list-more ${classNameMore}`}
          onClick={this.onClickMore}
        />
      )
    }
    return rtn
  }

  renderEpisodeList(metas) {
    const { selected, purchased, howToPlays } = this.props
    const { captionOpened } = this.state
    const now = webApp.utils.now(this.context)
    return metas.map((meta) => {
      const classNameSelect = selected.includes(meta.meta_id) ? 'on' : ''
      const classNameOpen = captionOpened.includes(meta.meta_id) ? 'on' : ''
      const isPurchased = purchased.find((p) => p.meta_id === meta.meta_id)
      const products = _.get(howToPlays, [meta.meta_id, 'products']) || []
      const product = products.find(
        (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
      )
      const isOnSale = webApp.utils.isOnSale(this.context, product, now)
      const price = webApp.utils.calcPrice(howToPlays, [meta.meta_id])
      const synopsis = _.get(meta, ['values', 'evis_EpisodeLongSynopsis'])
      let [metaName, subTitle] = webApp.utils.titles(meta)
      metaName = `${metaName} ${subTitle}`

      return (
        <li key={meta.meta_id} className="movie-list purchase-list">
          <div className="payment-finish-balance">
            <div className={`movie-summary-wrapper ${classNameOpen}`}>
              <p
                className="arrow-bottom summary-open"
                onClick={() => this.toggleOpen(meta.meta_id)}
              >
                {synopsis && (
                  <span className={`arrow-bottom-icon ${classNameOpen}`}>
                    <span className="movie-detail" />
                  </span>
                )}
                <span className="movie-purchase-list">{metaName}</span>
              </p>
              <p className="balance-area movie-list-balance-area">
                <span className="coin-balance movie-purchase-coin">
                  {price}
                </span>
                {isPurchased && <span className="coin-choice select" />}
                {!isPurchased && !isOnSale && (
                  <span className="coin-choice disabled" />
                )}
                {!isPurchased && isOnSale && (
                  <span
                    className={`coin-choice ${classNameSelect}`}
                    onClick={() => this.onClickSelect(meta.meta_id)}
                  />
                )}
              </p>
            </div>
            {synopsis && (
              <div className="movie-summary c-login-subtext">
                <p className="summary">あらすじ</p>
                <p>{synopsis}</p>
              </div>
            )}
          </div>
        </li>
      )
    })
  }

  /** パック商品用 */
  renderPacks() {
    const { pack, isPossibleForPack } = this.props
    const { captionOpened } = this.state
    const classNameOpen = captionOpened.includes(pack.product_id) ? 'on' : ''
    const price = _.get(pack, ['active_pricing', 'price'])
    const isPurchased = isPossibleForPack.status
    const description = _.get(pack, ['description'])
    const isPackOnSale = webApp.utils.isOnSale(this.context, pack)
    let buttonStyle = ''
    if (isPurchased) {
      buttonStyle = 'select'
    } else if (isPackOnSale) {
      buttonStyle = 'on'
    }

    return (
      <div className="finish-wrapper movie-list-wrapper">
        <ul className="movie-list-area">
          <li className="movie-list purchase-list">
            <div className="payment-finish-balance unlimited-pack">
              <div className={`movie-summary-wrapper ${classNameOpen}`}>
                <p
                  className="arrow-bottom summary-open"
                  onClick={() => this.toggleOpen(pack.product_id)}
                >
                  {description && (
                    <span className={`arrow-bottom-icon ${classNameOpen}`}>
                      <span className="movie-detail" />
                    </span>
                  )}
                  <span className="movie-purchase-list">{pack.name}</span>
                </p>
                <p className="balance-area movie-list-balance-area">
                  <span className="coin-balance movie-purchase-coin">
                    {price}
                  </span>
                  <span className={`coin-choice ${buttonStyle}`} />
                </p>
              </div>
              {description && (
                <div className="movie-summary c-login-subtext">
                  <p>{description}</p>
                </div>
              )}
            </div>
          </li>
        </ul>
      </div>
    )
  }

  /** 月額見放題コース用 */
  renderPlans() {
    const isSp = webApp.utils.isSp()
    const { course, belongsToGroup } = this.props
    const { captionOpened } = this.state
    const classNameOpen = captionOpened.includes(course.course_id) ? 'on' : ''
    const price = _.get(course, ['active_pricing', 'price'])
    const description = _.get(course, ['description'])

    return [
      <div key="list" className="finish-wrapper movie-list-wrapper">
        <ul className="movie-list-area">
          <li className="movie-list purchase-list">
            <div className="payment-finish-balance movie-unlimited-balance">
              <div className={`movie-summary-wrapper ${classNameOpen}`}>
                <p
                  className="arrow-bottom summary-open"
                  onClick={() => this.toggleOpen(course.course_id)}
                >
                  {description && (
                    <span className={`arrow-bottom-icon ${classNameOpen}`}>
                      <span className="movie-detail" />
                    </span>
                  )}
                  <span className="movie-purchase-list">{course.name}</span>
                </p>
                {isSp && description && (
                  <div className="movie-summary c-login-subtext">
                    <p>{description}</p>
                  </div>
                )}
                <p className="balance-area movie-list-balance-area">
                  <span className="coin-balance monthly">{price}</span>
                  <span
                    className={`coin-choice ${
                      belongsToGroup ? 'select' : 'no'
                    }`}
                  />
                </p>
              </div>
              {!isSp && description && (
                <div className="movie-summary c-login-subtext">
                  <p>{description}</p>
                </div>
              )}
            </div>
          </li>
        </ul>
      </div>,
      <p key="description" className="login-already payment-finish monthly">
        ※ご購入日以降、毎月1ヶ月ごとに自動的に継続されます。
      </p>
    ]
  }

  render() {
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    const { type, selected } = this.props
    if (!type) return null

    const classNamePrice = classnames('coin', {
      monthly: PURCHASE_TYPE.PLAN === type
    })

    return (
      <div className="c-login">
        <div className="c-login-wrapper coin-step-head">
          <Steps
            current={PURCHASE_CONTENTS.SELECT}
            classNameTitle="movie-purchase-title"
          />
        </div>

        <div className="movie-purchase_cont">
          <div className="c-login-cont-wrapper coin-payment-wrapper">
            <div className="c-login-flowwrapper">
              <div className="payment-pieces-wrapper">
                <div className="movie-top-attention">
                  <p className="c-login-subtext">
                    視聴する商品をご確認の上、ご注文手続きへ進んでください。
                    <br />
                    {!isLoggedIn && (
                      <span className="sub-attention">
                        既にご購入済の方は、
                        <span className="link-text">
                          <a href="#" onClick={this.onClickLogin}>
                            ログイン
                          </a>
                        </span>
                        してください。
                      </span>
                    )}
                  </p>
                  {PURCHASE_TYPE.PLAN !== type && (
                    <div className="c-login-subtext about-coin-lower">
                      <AboutMedalLink />
                    </div>
                  )}
                </div>
                {PURCHASE_TYPE.EPISODE === type && this.renderEpisodes()}
                {PURCHASE_TYPE.PACK === type && this.renderPacks()}
                {PURCHASE_TYPE.PLAN === type && this.renderPlans()}
              </div>
            </div>
            <div className="movie-list-total">
              <span className="c-login-subtext">
                合計（{selected.length}商品）
              </span>
              <span className={classNamePrice}>{this.totalPrice()}</span>
            </div>
            <div className="check-wrapper">
              <div className="login-btn">
                <div className="login-btn__wrapper">
                  <a className="login-btn__wrapper__link" onClick={this.goNext}>
                    ご注文手続きへ
                  </a>
                </div>
              </div>
              <div className="back-btn">
                <a href="#" onClick={this.goBack}>
                  前のページへ戻る
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
