import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import webApp from '../../../../utils/exdioWebAppUtils'
import * as DOMUtils from '../../../../../utils/DOMUtils'
import * as browserEvents from '../../../../../utils/browserEvents'
import ConvertSvg from '../components/util/ConvertSvg'
import ListItem from './ListItem'

/* style */
import {
  StyledUl,
  StyledDiv3,
  StyledButton,
  StyledI,
  StyledSpan,
  StyledDiv4
} from './style'

/**
 * ## Logirl番組リスト
 *
 * ### 概要
 * - Dioパレットに登録されたシーズンを取得し、番組ページへのリンクをリスト形式にして表示する
 * - パレットはIDをベタが書きで指定(`paletteId`)
 *
 * ### 取得件数
 * - PC: 20 (`FETCH_COUNT_PC`)
 * - SP: 8 (`FETCH_COUNT_SP`)
 *
 * ### 取得タイミング
 * - PC: リスト最下部にスクロールしたとき
 * - SP: リスト最下部のボタンを押したとき
 *
 * ### 補足
 * - API実行中は`isFetching`を使用してローディングアイコンを表示する
 * - `palette`内の番組をすべて取得したら`canFetch`を`false`にする
 * - `browserEvents.addEventListener`で追加したイベントは、以降に変更されたstateが反映されないため、その都度イベントを更新する
 */
const LogirlProgramList = (props, context) => {
  const FETCH_COUNT_PC = 20
  const FETCH_COUNT_SP = 8

  /** useState */
  const [isSp, setIsSp] = useState(webApp.utils.isSp())
  const [objects, setObjects] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const [canFetch, setCanFetch] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [selectedSeasonId, setSelectedSeasonId] = useState(null)
  const [fetchCount, setFetchCount] = useState(
    isSp ? FETCH_COUNT_SP : FETCH_COUNT_PC
  )

  /** useRef */
  const isMounted = useRef(false)
  const listRef = useRef(null)

  const paletteId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 205
    : 102

  /** palette.objectsの総数を取得 */
  const getObjectCount = () => {
    const path = ['paletteObjects', paletteId, 'length']
    return context.falcorModel
      .fetch([path])
      .then((result) => get(result, ['json', ...path], 0))
      .catch((e) => webApp.utils.handleFalcorError(e, context))
  }

  /** fetchCount分のpalette.objectsを取得 */
  const fetchObjects = (pageNum) => {
    /** 既に実行中の場合は実行しない */
    if (isFetching) return Promise.resolve()
    setIsFetching(true)

    const path = ['paletteObjects', paletteId, fetchCount, pageNum]

    return context.falcorModel
      .fetch([path])
      .then((result) => get(result, ['json', ...path], []))
      .catch((e) => webApp.utils.handleFalcorError(e, context))
      .finally(() => setIsFetching(false))
  }

  /** リスト下端より下か判別 */
  const isWithinDistanceBuffer = () => {
    if (!listRef.current) return false
    return DOMUtils.getDistanceToBottomOfElement(listRef.current) < 0
  }

  /**
   * 条件付きページ送り
   * PC: リスト下端を通り過ぎたら
   * SP: リスト下のボタンを押したら
   */
  const pageCountUp = async () => {
    /** PCはリスト下端より下のときだけ実行する */
    if ((!isSp && !isWithinDistanceBuffer()) || isFetching) return

    const nextPage = page + 1
    const nextObjects = await fetchObjects(nextPage)
    if (nextObjects.length > 0) {
      setObjects([...objects, ...nextObjects])
      setPage(nextPage)
    }
  }
  useEffect(() => {
    // パレットAPIのlimitの初期値は20なので
    // 20件以下のとき遅延ロード不要
    if (totalCount <= fetchCount) {
      setCanFetch(false)
      return
    }

    /** すべてfetchしたらフラグ回収 */
    if (objects.length === totalCount) {
      setCanFetch(false)
      return
    }

    /** それ以外のときはfetch可能 */
    setCanFetch(true)
  }, [objects, totalCount])

  /** SP判別の再設定 */
  const resetIsSp = () => {
    const newIsSp = webApp.utils.isSp()

    /** 前回と同じ結果のときは実行しない */
    if (newIsSp === isSp) return
    setIsSp(newIsSp)

    /** 番組リストを初期化 */
    setObjects([])
    ;(async () => {
      const initObjects = await fetchObjects(1)
      await setFetchCount(isSp ? FETCH_COUNT_SP : FETCH_COUNT_PC)
      setObjects(initObjects)
    })()
  }

  useEffect(() => {
    isMounted.current = true
    ;(async () => {
      const initTotalCount = await getObjectCount()
      if (initTotalCount) setTotalCount(initTotalCount)
      const initialPalette = await fetchObjects(1)
      if (initialPalette) setObjects(initialPalette)

      browserEvents.addEventListener('resize', resetIsSp)
    })()

    return () => {
      isMounted.current = false
      browserEvents.removeEventListener('resize', resetIsSp)
    }
  }, [])

  /** 条件が変更されたときイベントを登録しなおす */
  useEffect(() => {
    browserEvents.removeEventListener('scroll', pageCountUp)

    if (!isSp && canFetch) {
      browserEvents.addEventListener('scroll', pageCountUp)
    }

    return () => {
      browserEvents.removeEventListener('scroll', pageCountUp)
    }
  }, [isSp, isFetching, canFetch])

  /** 条件が変更されたときイベントを登録しなおす */
  useEffect(() => {
    browserEvents.removeEventListener('resize', resetIsSp)
    browserEvents.addEventListener('resize', resetIsSp)

    return () => {
      browserEvents.removeEventListener('resize', resetIsSp)
    }
  }, [isSp])

  if (!(isMounted.current && (objects || []).length > 0)) return null
  return (
    <React.Fragment>
      <StyledUl ref={listRef} {...props}>
        {/* SP初期表示8件 */}
        {objects.map(({ meta }) => (
          <ListItem
            key={meta.meta_id}
            meta={meta}
            selectedSeasonId={selectedSeasonId}
            setSelectedSeasonId={setSelectedSeasonId}
          />
        ))}
      </StyledUl>
      {isFetching && (
        <StyledDiv4>
          <img
            src="/images/exdio/renewal/logirl/icon/loading.svg"
            alt="loading"
          />
        </StyledDiv4>
      )}
      {isSp && canFetch && (
        <StyledDiv3>
          <StyledButton type="button" onClick={pageCountUp}>
            <StyledI>
              <ConvertSvg
                src="/images/exdio/renewal/logirl/icon/arrow.svg"
                alt=""
              />
            </StyledI>
            <StyledSpan>もっと見る</StyledSpan>
          </StyledButton>
        </StyledDiv3>
      )}
    </React.Fragment>
  )
}

export default LogirlProgramList

LogirlProgramList.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  routeHandler: PropTypes.object
}
