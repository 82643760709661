/* style */
import styled from 'styled-components'
import ConvertSvg from '../components/util/ConvertSvg'
import { mediaSp } from '../style'

export const StyledDiv1 = styled.div`
  overflow: hidden;
`

export const StyledImg = styled.img`
  && {
    width: 100%;
    transition: transform 0.3s ease-in-out;
  }
`

export const StyledA = styled.a`
  && {
    width: 100%;
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.5;
    transition: opacity 0.2s ease-in-out;

    @media ${mediaSp} {
      font-size: 1.2rem;
    }

    &:hover {
      opacity: 0.7;

      ${StyledDiv1} {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
`

export const StyledDiv2 = styled.div`
  margin-top: 16px;

  @media ${mediaSp} {
    margin-top: 8px;
  }
`

export const StyledSpan1 = styled.span`
  display: block;
`

export const StyledSpan2 = styled.span`
  margin-top: 8px;
  padding: 0 12px;
  background-color: #f00;
  border-radius: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-family: 'M PLUS Rounded 1c', sans-serif;

  @media ${mediaSp} {
    margin-top: 4px;
    padding: 0 8px;
    border-radius: 12px;
    font-size: 1.6rem;
  }
`

export const StyledConvertSvg = styled(ConvertSvg)`
  margin-right: 8px;

  @media ${mediaSp} {
    margin-right: 4px;
  }

  svg {
    width: 27px;
    height: 24px;

    @media ${mediaSp} {
      width: 18px;
      height: 16px;
    }
  }
`
