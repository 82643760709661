import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import routes from '../../routes'
import webApp from '../../../exdio/utils/exdioWebAppUtils'
import Search from './Search'
import LoggedIn from './LoggedIn'
import Alert from './Alert'
import Hamburger from './Hamburger'
import MemberMenu from './MemberMenu/'

/** style */
import { mediaQuery } from '../../../exdio/components/style'

/** ヘッダー > 各種アイコン */
const IconMenu = (
  {
    toggleNavMenu = () => {},
    showSearch = false,
    setShowSearch = () => {},
    showMemberMenu = false,
    setIsSpSearch = () => {},
    path = ''
  },
  context
) => {
  const pathEvaluator = context.falcorModel
  const model = pathEvaluator ? pathEvaluator.batch(100) : null
  const config = context.models.config.data

  const [articles, setArticles] = useState(null) // Array お知らせ一覧
  const [userInfo, setUserInfo] = useState({}) // object ユーザー情報
  const [newsCount, setNewsCount] = useState(0) // number ハンバーガーメニュ > お知らせの表示位置
  const [isAvailablePoint, setIsAvailablePoint] = useState(null) // boolean ユーザーの保持コインを取得完了判定

  let redirect = context.routeHandler.path
  if (routes.login.match(redirect)) {
    redirect = context.routeHandler.query.redirect || null
  }

  /** ユーザー情報取得 */
  const getUserInfo = () => {
    const _path = ['user', 'info']
    return model.fetch([_path]).then((result) => {
      const _userInfo = _.get(result, ['json', ..._path], null)
      setUserInfo(_userInfo)
    })
  }

  /** 利用可能コイン数取得 */
  const getAvailablePoint = () => {
    const _path = ['infra', 'availablePoint']
    return model.fetch([_path]).then((result) => {
      const availablePoint = _.get(result, ['json', ..._path])
      context.updateUserInfo({ availablePoint })
    })
  }

  /** お知らせ取得 */
  const getArticles = () => {
    const publishPeriod = config.news_page.show_information_period
    const _path = ['articles', 'news', publishPeriod]

    return model.fetch([_path]).then((result) => {
      const _articles = _.get(result, ['json', ..._path], [])
      setArticles(_articles)
    })
  }

  /** ユーザーの保持コインを取得できたか判定 */
  const checkAvailablePoint = () => {
    if (context.models.userInfo.data.availablePoint.available_point != null)
      setIsAvailablePoint(true)
  }

  useEffect(() => {
    context.updateUserInfoSubscribe(() => checkAvailablePoint())

    if (webApp.utils.isLoggedIn(context)) {
      getUserInfo().catch((e) => webApp.utils.handleFalcorError(e, context))
      getAvailablePoint().catch((e) =>
        webApp.utils.handleFalcorError(e, context)
      )
    } else {
      setIsAvailablePoint(true)
    }

    // ニュース取得
    getArticles().catch((e) => webApp.utils.handleFalcorError(e, context))
  }, [])

  return (
    <StyledDiv>
      <StyledSearch
        show={showSearch}
        setShow={setShowSearch}
        setIsSpSearch={setIsSpSearch}
        toggleNavMenu={toggleNavMenu}
      />

      {isAvailablePoint && (
        <StyledLoggedIn
          toggleNavMenu={toggleNavMenu}
          profileIcon={
            userInfo.img_url || '/images/exdio/renewal/prof_icon.png'
          }
          path={path}
        />
      )}

      {articles && (
        <StyledAlert
          toggleNavMenu={toggleNavMenu}
          articles={articles}
          path={path}
        />
      )}

      {articles && isAvailablePoint && (
        <div>
          <Hamburger toggleNavMenu={toggleNavMenu} show={showMemberMenu} />
          {showMemberMenu && (
            <MemberMenu
              toggleNavMenu={toggleNavMenu}
              show={showMemberMenu}
              articles={articles}
              newsCount={newsCount}
              setNewsCount={setNewsCount}
            />
          )}
        </div>
      )}
    </StyledDiv>
  )
}

export default IconMenu

IconMenu.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  updateUserInfo: PropTypes.func,
  updateUserInfoSubscribe: PropTypes.func
}

IconMenu.propTypes = {
  /** GlobalNavigation.js > showSubMenu/showSearch/showMemberMenu 開閉処理 */
  toggleNavMenu: PropTypes.func.isRequired,
  /** 検索バー開閉状態 */
  showSearch: PropTypes.bool.isRequired,
  /** 検索バー開閉setState */
  setShowSearch: PropTypes.func.isRequired,
  /** ハンバーガーメニュ開閉状態 */
  showMemberMenu: PropTypes.bool.isRequired,
  /** 現在表示中のページが/searchかつSPかどうかの判定 */
  setIsSpSearch: PropTypes.func.isRequired,
  /** 現在のURL */
  path: PropTypes.string
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;

  ${mediaQuery()} {
    justify-content: space-between;
  }
`

const StyledSearch = styled(Search)`
  margin-right: 15px;
  position: relative;
  ${mediaQuery()} {
    margin-right: 0px;
  }
`

const StyledLoggedIn = styled(LoggedIn)`
  margin-right: 15px;
  ${mediaQuery()} {
    margin-right: 0px;
  }
`

const StyledAlert = styled(Alert)`
  margin-right: 15px;
  ${mediaQuery()} {
    margin-right: 0px;
  }
`
