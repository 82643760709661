import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PlayerCoverDisabled = ({
  metaName = '',
  thumbnailUrl = '',
  ...props
}) => {
  return (
    <StyledButton type="button" {...props}>
      <StyledImg src={thumbnailUrl} alt={metaName} width="1080" height="610" />
    </StyledButton>
  )
}

export default PlayerCoverDisabled

PlayerCoverDisabled.propTypes = {
  metaName: PropTypes.string,
  thumbnailUrl: PropTypes.string
}

const StyledButton = styled.button`
  padding: 0;
  background: none;
  border-width: 0;
  position: absolute;
  inset: 0;
`

const StyledImg = styled.img`
  width: 100%;
  position: absolute;
  inset: 0;
`
