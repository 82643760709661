import React from 'react';
import _ from 'lodash';
import CookieDough from 'cookie-dough';
import generateUuid from '../../common/generateUuid';
import resolveElement from '../ui/routing/resolveElement';
import match from '../ui/routing/match';
import AppContextProvider from './AppContextProvider';
import tokenDataStore from '../../utils/tokenDataStore';

class AppContext {
  constructor(app, state, options) {
    this.app = app;
    this.state = state || {};
    this.options = options || {};
    this.ab = this.getModelData('ab') || {};
    this.cookieDough = new CookieDough(_.get(this.state, ['model', 'node', 'req']));
    this.uuid = generateUuid();
    this.isTokenSetup = false;
    this.subscriptions = [];
    this.globalNavigationUpdater = () => {};
  }

  resolveState(pathname) {
    const routeHandler = match(this.state.routeHandlers, pathname);
    if (routeHandler !== this.state.routeHandler) {
      let state = Object.assign({}, this.state, { routeHandler });
      if (this.app.handleRouteChange) {
        state = this.app.handleRouteChange(state, routeHandler);
      }
      if (this.options.handleRouteChange) {
        state = this.options.handleRouteChange(state, routeHandler);
      }
      this.state = state;
    }

    // クライアントサイドのみ
    if (typeof window !== 'undefined') {
      let tokenData = this.getTokenData();
      if (!this.isTokenSetup) {
        this.isTokenSetup = true;
        if (tokenData) {
          tokenData.uuid = this.uuid;
          tokenData.isRefreshing = false;
        } else {
          tokenData = {
            uuid: this.uuid,
            isRefreshing: false
          };
        }

        window.addEventListener('focus', _e => {
          const listenerTokenData = this.getTokenData();
          if (listenerTokenData) {
            listenerTokenData.uuid = this.uuid;
            this.setTokenData(listenerTokenData);
          }
        });
      }

      const authContext = _.get(this.state, ['model', 'models', 'authContext', 'data']);
      if (authContext) {
        this.setTokenData(tokenData);
        tokenDataStore.setAuthContextData(authContext);
      } else {
        delete tokenData.id;
        delete tokenData.token;
        delete tokenData.time;
        this.setTokenData(tokenData);
      }
    }

    return this.state;
  }

  setTokenData(tokenData) {
    tokenDataStore.set(tokenData);
  }

  getTokenData() {
    return tokenDataStore.get();
  }

  resolveElement(cb) {
    const routeHandler = this.state.routeHandler;
    if (!routeHandler) {
      return cb(null, null);
    }
    if (routeHandler.resolveComponents) {
      routeHandler.resolveComponents(routeHandler, this, (err, components) => {
        if (err) {
          return cb(err);
        }
        const elm = resolveElement(components, this.state);
        if (!React.isValidElement(elm)) {
          return cb(new Error('Route handler does not have a valid React component'));
        }
        return cb(null, elm);
      });
    } else {
      const components = routeHandler.components;
      const elm = resolveElement(components, this.state);
      if (!React.isValidElement(elm)) {
        return cb(new Error('Route handler does not have a valid React component'));
      }
      return cb(null, elm);
    }
  }

  getFalcorModel() {
    return _.get(this.state, ['model', 'pathEvaluator']);
  }

  getState() {
    return this.state;
  }

  getRouteHandler() {
    return this.state.routeHandler;
  }

  getHistory() {
    return this.state.history;
  }

  getModels() {
    return this.state.model.models;
  }

  getModelData(key) {
    let models = _.get(this.state, ['model', 'models', key, 'data']);

    if (models && key === 'authContext') {
      let tokenData;
      try {
        tokenData = tokenDataStore.get();
        if (tokenData && tokenData.time > models.time) {
          models.id = tokenData.id;
          models.token = tokenData.token;
          models.time = tokenData.time;
        } else {
          tokenDataStore.setAuthContextData(models);
        }
      } catch (e) {
        console.log('getModelData(authContext): ', e);
      }
    }

    if (arguments.length > 1) {
      let i;
      for (i = 1; i < arguments.length; i++) {
        models = models[arguments[i]];
      }
    }
    return models;
  }

  getWebApp() {
    return _.get(this.state, ['model', 'webApp']);
  }

  getICUString() {
    // TODO:
  }

  getI18nString() {
    // TOOD:
  }

  getI18nBundle() {
    // TODO:
  }

  provideAppContextToElement(children) {
    return React.createElement(AppContextProvider, { appContext: this }, children);
  }

  getRenderTracker() {
    return this.state.renderTracker;
  }

  getLogger() {
    // TODO:
  }

  getDiscoveryApp() {
    // TODO:
  }

  getAB() {
    return this.ab;
  }

  getCookieDough() {
    return this.cookieDough;
  }

  /** context内のuserInfoを更新する */
  updateUserInfo(context, provider, userInfo) {
    context.state.model.models.userInfo.data = { ...context.state.model.models.userInfo.data, ...userInfo };
    // AppContextProvider#getChildContext()をフック
    provider.setState({});
    this.subscriptions.forEach(f => f());
  }
  /** updateUserInfoが実行された際に呼び出されるコールバック関数群を登録する */
  updateUserInfoSubscribe(func) {
    const funcName = _.get(func, ['name']) || '';
    const sameFunc = this.subscriptions.find(f => f.name === funcName);
    if (!funcName || !sameFunc) {
      this.subscriptions.push(func);
    }
  }

  /** ヘッダーナビの再レンダリング用関数を登録する */
  setGlobalNavigationUpdater(func) {
    this.globalNavigationUpdater = func;
  }
  /** ヘッダーナビを再レンダリングする */
  updateGlobalNavigation() {
    if (this.globalNavigationUpdater) {
      this.globalNavigationUpdater();
    }
  }
}

export default AppContext;
