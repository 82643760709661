import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import Footer from '../../../common/components/Footer'
import LoginOrSignup from '../../../common/components/renewal/LoginOrSignup'
import { LOGIN_CONTENTS } from '../../../../constants/app'
import routes from '../../../common/routes'
import webApp from '../../utils/exdioWebAppUtils'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import HeaderNewsComponent from './HeaderNewsComponent'
import browserInfo from '../../../../sketch-platform/utils/browserInfo'

/** ログインページ */
export default class LoginContent extends Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.state = {
      currentContent: LOGIN_CONTENTS.LOGIN
    }

    this.onChangeLoginContent = this.onChangeLoginContent.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    // 画面の初期表示時、ページ外のリンクからの遷移時に表示する情報判定. history.location.state利用.
    this.removeHistoryListener = this.context.history.listen((location) =>
      this.setContentByLocation(location)
    )
    this.setContentByLocation(this.context.history.location)

    // 固定viewport設定
    const device = browserInfo(navigator.userAgent, (data) => data)
    if (device.isSilk) {
      this.setPcWidthViewport()
    }

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context)
    // GTMの更新
    const title = this.context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)
  }

  componentWillUnmount() {
    this._isMounted = false
    if (typeof this.removeHistoryListener === 'function') {
      this.removeHistoryListener()
    }
    this.removePcWidthViewport()
  }

  onChangeLoginContent(content) {
    const { query } = this.context.routeHandler
    const loginUrl = query.signup
      ? routes.login.makePath({}, { signup: query.signup })
      : routes.login.makePath()
    this.context.history.replace(loginUrl, { content })
  }

  /** location.stateから現在の対象コンテンツを判定し内部コンテンツとして設定する. */
  setContentByLocation(location) {
    const content = _.get(location, ['state', 'content']) || {}
    const targetContent = Object.values(LOGIN_CONTENTS).find(
      (v) => v.id === content.id
    )
    if (this._isMounted) {
      this.setState({ currentContent: targetContent || LOGIN_CONTENTS.LOGIN })
    }
  }

  /**
   * 固定viewport設定
   * 対象: FireTVのSilkブラウザ
   * 1920x1080 -> 960x540 (2 / 1)
   * PC表示にするため`initial-scale`を調整
   */
  setPcWidthViewport() {
    const viewportMeta = document.querySelector('meta[name="viewport"]')
    if (!viewportMeta) return

    viewportMeta.content = viewportMeta.content.replace(
      'initial-scale=1',
      'initial-scale=0.75'
    )
  }

  /** 固定viewport解除 */
  removePcWidthViewport() {
    const viewportMeta = document.querySelector('meta[name="viewport"]')
    if (!viewportMeta) return

    viewportMeta.content = viewportMeta.content.replace(
      'initial-scale=0.75',
      'initial-scale=1'
    )
  }

  render() {
    const { currentContent } = this.state
    return (
      <React.Fragment>
        <div className="common-wrapper">
          <HeaderNewsComponent />
          <SpSubNavigation spOff />
          <StyledLoginOrSignup
            content={currentContent}
            onChangeContent={this.onChangeLoginContent}
          />

          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

const StyledLoginOrSignup = styled(LoginOrSignup)`
  margin-top: 26px;
  background: #f3f4f5;

  @media (max-width: 1024px) {
    margin-top: 5px;
  }
`
