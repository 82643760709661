import React, { memo } from 'react'
import PropTypes from 'prop-types'

/** JASRAC番号 */
const Jasrac = ({ courseId, ...props }) => {
  const doraemonId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 2
    : 1
  const shinchanId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 3
    : 2
  const logirlId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 4
    : 3
  const garibenId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 13
    : 5

  switch (courseId) {
    case doraemonId:
      return <p {...props}>6688647087Y45038</p>
    case shinchanId:
      return <p {...props}>6688647078Y45038</p>
    case logirlId:
      return <p {...props}>6688647051Y45038</p>
    case garibenId:
      return <p {...props}>6688647068Y45038</p>
    case null:
      return (
        <p {...props}>
          6688647019Y45038
          <br />
          6688647032Y45038
          <br />
          6688647069Y45038
        </p>
      )
  }
}

export default memo(Jasrac)

Jasrac.propTypes = {
  courseId: PropTypes.number
}

Jasrac.defaultProps = {
  courseId: null
}
