import React from 'react'
import PropTypes from 'prop-types'

/* components */
import List5Item from './List5Item'

/* style */
import { StyledUl } from './style'

/** Logirlプランページ | リスト5 */
const List5 = ({ articles, size, ...props }) => {
  // articlesが無いときはなにも表示しない
  if (!Boolean(articles.length)) return null

  return (
    <StyledUl data-size={size} {...props}>
      {articles.map((article, i) => (
        <List5Item key={i} article={article} />
      ))}
    </StyledUl>
  )
}

export default List5

List5.propTypes = {
  /** 描画する記事の配列 */
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      date_time: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.shape({
        0: PropTypes.string
      }),
      thumbnail: PropTypes.shape({
        0: PropTypes.string
      }),
      title: PropTypes.shape({
        0: PropTypes.string
      })
    })
  ).isRequired,
  size: PropTypes.oneOf([
    // 必要であれば適宜追加
    'large',
    ''
  ]),
  className: PropTypes.string
}

List5.defaultProps = {
  articles: [],
  size: '',
  className: ''
}
