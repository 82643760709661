import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import window from 'global'
import styled from 'styled-components'
import webApp from '../../utils/exdioWebAppUtils'
import routes from '../../../common/routes'
import { LOCAL_STORAGE_KEY_NOTICE_ARTICLES } from '../../../../constants/app'
import { mediaQuery, breakPoints, fixColors } from '../style'

/* components */
import { Link } from '../../../../sketch-platform/ui/routing'

/** ヘッダー下 お知らせエリア */
const HeaderNewsComponent = (props, context) => {
  const model = context.falcorModel.batch(100)
  const isApp = webApp.utils.isApp(context)
  const tmpStorage =
    window.localStorage &&
    window.localStorage.getItem(LOCAL_STORAGE_KEY_NOTICE_ARTICLES)
  const storage = tmpStorage && JSON.parse(tmpStorage)

  const [headArticles, setHeadArticles] = useState([])
  const [readArticles, setReadArticles] = useState(
    get(storage, ['articles'], [])
  )

  /** お知らせ取得 */
  const getArticles = () => {
    const publishPeriod =
      context.models.config.data.extras.header_menu_news_period
    const newsPath = ['articles', 'news', publishPeriod] // マイリストと同じ期間にしないと表示できないので期間指定にする
    return model.fetch([newsPath]).then((result) => {
      const newsArticles = get(result, ['json', ...newsPath]) || []
      if (size(newsArticles) > 0) {
        return newsArticles
          .filter((a) => get(a, ['values', 'show_top']))
          .map((a) => ({ ...a, msg: a.display_name }))
      }
      return []
    })
  }

  /** 閲覧済みか判別 */
  const isReadArticle = (article = { id: '', updated_at: '' }) => {
    return (
      readArticles.filter(
        (readArticle) =>
          readArticle.id === article.id &&
          readArticle.updated_at === article.updated_at
      ).length > 0
    )
  }

  /** 閲覧済みの記事を除外 */
  const filterArticles = (articles) => {
    return articles.filter((article) => !isReadArticle(article))
  }

  /** ローカルストレージに閲覧済み記事を保存 */
  const saveToLocalStorage = (newItem = { id: '', updated_at: '' }) => {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_NOTICE_ARTICLES,
      JSON.stringify({
        articles: [...readArticles, newItem]
      })
    )
  }

  /** 閉じるボタン押下時 */
  const clickClose = (id, updated_at) => {
    const newItem = { id, updated_at }
    if (isReadArticle(newItem)) return // 既読なら何もしない
    setReadArticles([...readArticles, newItem])
    saveToLocalStorage(newItem)
  }

  useEffect(() => {
    ;(async () => {
      const resHeadArticles = await getArticles().catch((e) => console.error(e))
      setHeadArticles(filterArticles(resHeadArticles))
    })()
  }, [])

  useEffect(() => {
    setHeadArticles(filterArticles(headArticles))
  }, [readArticles])

  if (size(headArticles) === 0) return null

  return (
    <StyledUl {...props}>
      {headArticles.map(
        ({ id = '', msg = '', updated_at = '', caption = '' }) => {
          // dioのcaptionにURLが設定されている場合はaタグにする
          const regExp = /([A-Za-z]{3,9})(?::\/\/|@)((?:[A-Za-z0-9\-.]+[.:])|(?:www\.|[-;:&=+$,\w]+@))([A-Za-z0-9.-]+)([/\-+=&;%@.\w_~()]*)([.!/\\\w-?%#~&=+()]*)/ // url判別
          const isURL = regExp.test(caption)
          const route = isApp ? routes.app_information : routes.information
          const isLink = caption !== '' && isURL
          const linkProps = isLink
            ? {
                href: caption
              }
            : {
                route,
                hash: `news-${id}`
              }

          return (
            <StyledLi key={id}>
              <StyledLink {...linkProps}>{msg}</StyledLink>
              <StyledButton onClick={() => clickClose(id, updated_at)}>
                ×
              </StyledButton>
            </StyledLi>
          )
        }
      )}
    </StyledUl>
  )
}

export default HeaderNewsComponent

HeaderNewsComponent.contextTypes = {
  models: PropTypes.shape({
    config: PropTypes.shape({
      data: PropTypes.shape({
        extras: PropTypes.shape({
          header_menu_news_period: PropTypes.number
        })
      })
    })
  }),
  falcorModel: PropTypes.shape({
    batch: PropTypes.func
  }),
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  })
}

// TODO margin削除(テンプレート側でつける)
const StyledUl = styled.ul`
  margin: 20px auto 0;
  max-width: ${breakPoints.mx}px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  ${mediaQuery(breakPoints.xl)} {
    margin: 0 auto 20px;
    padding: 0 15px;
  }

  ${mediaQuery()} {
    margin: 10px auto;
    padding: 0 15px;
  }
`

const StyledLi = styled.li`
  position: relative;
`

const StyledLink = styled(Link)`
  padding: 1.2em 2em 1.2em 5.2em;
  background-color: rgba(255, 235, 0, 0.2);
  border-radius: 5px;
  display: block;
  position: relative;
  color: ${fixColors.colorFont};
  font-size: 1.4rem;
  text-align: left;
  transition: background-color 0.4s;

  @media (hover: hover) {
    &:hover {
      background-color: rgba(255, 235, 0, 0.3);
    }
  }

  ${mediaQuery()} {
    padding: 10px 32px 10px 62px;
    font-size: 1.2rem;
    line-height: 1.5;
  }

  &::before {
    margin: auto;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -95%;
    content: url(/images/exdio/renewal/icon_attention.svg);

    @media screen and (max-width: 560px) {
      left: -85%;
    }

    @media screen and (min-width: 561px) and (max-width: 1023px) {
      left: -90%;
    }
  }
`

const StyledButton = styled.button`
  margin: auto;
  padding: 0;
  width: 20px;
  height: 20px;
  background-color: #e3e3e3;
  border: 0;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  color: #fff;
  font-size: 130%;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
`
