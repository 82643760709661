import styled from 'styled-components'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import { mediaQuery } from '../../../style'

export const StyledUl = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px 40px;

  ${mediaQuery()} {
    gap: 20px;
  }
`

export const StyledLi = styled.li``

export const StyledLink = styled(Link)`
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 10px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  transition: opacity 0.2s;

  ${mediaQuery('sm', 'min')} {
    &:hover {
      opacity: 0.7;
    }
  }

  ${mediaQuery()} {
    gap: 5px;
    font-size: 1.3rem;
  }
`
