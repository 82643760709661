import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledDiv,
  StyledFooter,
  StyledImg,
  StyledLink,
  StyledP,
  StyledTime
} from './style'

/** Section3｜リストの要素 */
const ListItem = ({ article = {}, ...props }) => {
  return (
    <StyledLink
      href={article.link}
      target="_blank"
      className="lazyload"
      {...props}
    >
      <StyledDiv>
        <StyledImg
          src={article.thumbnail}
          alt=""
          width="460"
          height="258"
          loading="lazy"
        />
      </StyledDiv>
      <StyledP>{article.title}</StyledP>
      <StyledFooter>
        <StyledTime dateTime={article.date_time}>{article.date}</StyledTime>
      </StyledFooter>
    </StyledLink>
  )
}

export default ListItem

ListItem.propTypes = {
  /** リストの表示要素
   * @param {string} title - コンテンツのタイトル
   * @param {string} link - 遷移先URL
   * @param {string} thumbnail - コンテンツのサムネイル
   * @param {string} date_time - dateTimeに渡すコンテンツの作成日
   * @param {string} date - 表示するコンテンツの作成日
   */
  article: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    thumbnail: PropTypes.string,
    date_time: PropTypes.string,
    date: PropTypes.string
  }),
  /** styleの継承 */
  className: PropTypes.string
}
