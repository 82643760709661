import { LOCAL_STORAGE_KEY } from '../../constants/app';

// エッジスワイプ(戻る)をしたときに呼び出されるメソッド
export function setAppSwipePrev() {
  history.back();
}

// エッジスワイプ(進む)をしたときに呼び出されるメソッド
export function setAppSwipeNext() {
  history.forward();
}

// アプリがページ読み込み時に呼ばれる
// 動画のレジユーム情報が渡される
export function bulkSetResumeInfo(resume_info) {
  if (!resume_info) return;

  window.localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify(resume_info)
  );
}

// レジューム情報を取得する
export function getResumeInfo(metaid) {
  const ls = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  if (ls && ls !== 'null') { // setItemでnullをセットするとnullという文字列になる
    const resumeInfos = JSON.parse(ls);
    if (!metaid) return resumeInfos;
    if (resumeInfos && typeof resumeInfos.find === 'function') {
      return resumeInfos.find(v => v.metaid == metaid) || null;
    }
  }
  return null;
}

// レジューム情報をセットする
// metaid : メタのID
// duration: 全体の尺
// lastPlayed: 再生終了時のposition (-1は最後まで再生)
export function setResumeInfo(metaid, duration, lastPlayed) {
  const resumeInfos = getResumeInfo(null) || [];
  removeResumeInfo(metaid);
  resumeInfos.push({ metaid, duration, lastPlayed });
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(resumeInfos));
}

// レジューム情報を削除する
export function removeResumeInfo(metaid) {
  const resumeInfos = getResumeInfo(null) || [];
  const wResumeInfos = resumeInfos.filter(v => v.metaid != metaid);
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(wResumeInfos));
}

// レジューム情報をすべて削除
export function removeAllResumeInfo() {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, null);
}
