import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Link from '../../../../sketch-platform/ui/routing/Link'
import routes from '../../routes'

/** style */
import styled from 'styled-components'
import { StyledNavLink } from './style'
import { mediaQuery } from '../../../exdio/components/style'

/** ナビゲーション > 各項目 > Link */
const NavLink = ({
  menu = {
    title: '',
    route: '',
    children: '',
    classNameLink: '',
    url: '',
    target: '',
    params: {}
  },
  toggleNavMenu = () => {},
  ...props
}) => {
  const classNameLink = menu.classNameLink || ''
  const linkProps = {
    href: menu.url,
    target: menu.target,
    route: routes[menu.route],
    params: menu.params,
    className: classNameLink,
    onClick: () => toggleNavMenu()
  }

  return menu.children ? (
    <StyledLink
      {...linkProps}
      dangerouslySetInnerHTML={{ __html: menu.children }}
    />
  ) : (
    <StyledLink {...linkProps}>{menu.title}</StyledLink>
  )
}

export default memo(NavLink)

NavLink.propTypes = {
  /** ナビゲーションドロップダウン開閉処理 */
  toggleNavMenu: PropTypes.func.isRequired,
  /** ナビゲーション毎の値 */
  menu: PropTypes.shape({
    title: PropTypes.string.isRequired,
    route: PropTypes.string,
    children: PropTypes.string,
    classNameLink: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
    params: PropTypes.object
  }).isRequired
}

const StyledLink = styled(Link)`
  ${StyledNavLink}
  ${mediaQuery()} {
    padding: 0 1rem;
  }

  .c-headNav-btn-link {
    /* homeのクラス */
    &-home,
    &-logoFree,
    &-logoExTheaterOnline {
      max-width: 76px;
      vertical-align: middle;
    }
  }
`
