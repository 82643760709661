import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import ProgramItemLink from '../ProgramItemLink'
import CardSkelton from './CardSkelton'

const SliderCardList = ({
  heading = '',
  cardDataList = [],
  link = {
    route: {},
    params: {},
    query: {}
  },
  loaded = false
}) => {
  const [showPrev, setShowPrev] = useState(false)
  const [showNext, setShowNext] = useState(false)
  const sliderContentRef = useRef(null)

  /**
   * カードリストスクロール
   * ボタンのみの制御で通常のscrollを使用しないため位置座標にて制御
   */
  const scrollPalette = (direction) => {
    if (!sliderContentRef.current) return

    // 位置、サイズ取得
    const scrollAreaWidth = sliderContentRef.current.offsetWidth
    let left = sliderContentRef.current.offsetLeft
    const children = sliderContentRef.current.childNodes
    const totalWidth = Array.from(children)
      .map((child) => window.getComputedStyle(child))
      .flatMap((childStyle) => [
        childStyle.width,
        childStyle.marginLeft,
        childStyle.marginRight
      ])
      .reduce((reduced, value) => {
        return reduced + Number(value.replace('px', ''))
      }, 0)

    // 次の位置算出
    // 左スクロール
    if (direction === -1) {
      // 表示が切れている左端の要素
      let borderChild = null
      for (let i = children.length; i > 0; i--) {
        if (left + children[i - 1].offsetLeft < 0) {
          borderChild = children[i - 1]
          break
        }
      }
      left +=
        scrollAreaWidth -
        (left + borderChild.offsetLeft + borderChild.offsetWidth)
      if (left > 0) left = 0
    }
    // 右スクロール
    else if (direction === 1) {
      // 表示が切れている右端の要素
      let borderChild = null
      for (let i = 0; i < children.length; i++) {
        if (
          left + children[i].offsetLeft + children[i].offsetWidth >
          scrollAreaWidth
        ) {
          borderChild = children[i]
          break
        }
      }
      left = -borderChild.offsetLeft
    }

    // 位置設定
    let right = left + totalWidth
    if (left < 0 && right < scrollAreaWidth) {
      left = scrollAreaWidth - totalWidth
      right = left + totalWidth
    }
    sliderContentRef.current.style.left = `${left}px`

    // scrollボタン表示判定
    setShowPrev(left < 0)
    setShowNext(scrollAreaWidth < right)
  }

  useEffect(() => {
    scrollPalette(0)
  }, [loaded, cardDataList])

  return (
    <div className="c-cards">
      {loaded && (
        <header className="c-cards-head">
          <h3 className="c-cards-head-hedding">
            <span className="c-cards-head-hedding-inner">{heading}</span>
          </h3>
          <Link
            route={link.route}
            params={link.params}
            query={link.query}
            className="c-cards-head-hedding-link"
          >
            もっと見る
          </Link>
        </header>
      )}
      <div className="c-card-slider">
        <div className="c-card-slider-cont" ref={sliderContentRef}>
          {loaded && cardDataList ? (
            cardDataList.map((cardData) => <ProgramItemLink {...cardData} />)
          ) : (
            <CardSkelton />
          )}
        </div>
        <div className="c-card-slider-nav">
          {showPrev && (
            <div
              className="c-card-slider-nav-prev"
              onClick={() => scrollPalette(-1)}
            >
              <span className="c-card-slider-nav-prev-img" />
            </div>
          )}
          {showNext && (
            <div
              className="c-card-slider-nav-next"
              onClick={() => scrollPalette(1)}
            >
              <span className="c-card-slider-nav-next-img" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SliderCardList

SliderCardList.propTypes = {
  /** カードリストの見出し */
  heading: PropTypes.string,
  cardDataList: PropTypes.array.isRequired,
  /** もっと見るのリンク先 */
  link: PropTypes.object,
  /** フェッチの状況 */
  loaded: PropTypes.bool
}
