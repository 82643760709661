/* style */
import styled from 'styled-components'
export const mediaSp = '(max-width: 1024px)'

export const StyledSmall = styled.small`
  grid-area: copy;
  padding: 24px 0;
  width: 100%;
  display: block;
  background-color: #0cc;
  font-size: 1.6rem;
  text-align: center;

  @media ${mediaSp} {
    padding: 8px 0;
    font-size: 1.2rem;
  }
`
