import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import PackList from '../details/PackList'
import {
  FAVORITE_TYPE,
  EPISODE_DISPLAY_MODE
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import FilterSort from '../../../../common/components/FilterSort'
import SideTab from '../details/SideTab'
import Caption from '../details/Caption'
import GoBack from '../../../../common/components/appli/renewal/GoBack'
import SideRecommend from '../details/SideRecommend'
import HtmlSnippet from '../../HtmlSnippet'
import AddButtonBlock from '../details/AddButtonBlock'
import HeaderNewsComponent from '../HeaderNewsComponent'
import Footer from '../../../../common/components/appli/StaticFooter'
import SearchBoxYearWrapper from '../../../../common/components/FilterSort/SearchBoxYearWrapper'

/** style */
import styled from 'styled-components'
import { mediaSp } from '../plan_shinchan/styles'

/** クレヨンしんちゃん番組ページ テンプレート */
export default class ProgramAppShinchan extends Component {
  static propTypes = {
    seriesId: PropTypes.string,
    seasonId: PropTypes.string,
    version: PropTypes.string,
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    episodes: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        original_price: PropTypes.number,
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    ),
    howToPlays: PropTypes.object
  }

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    version: '',
    episodes: [],
    products: [],
    howToPlays: {},
    loaded: true
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.state = {
      pagerOptions: {
        // ページャーの設定
        episodesPerPages: 60,
        range: 2
      },
      selectedYear: null,
      rectY: 0
    }

    this.updateSelectedYear = this.updateSelectedYear.bind(this)
  }

  componentDidMount() {
    /**
     * Planページにリダイレクト
     * https://media-plex.backlog.jp/view/DGA2-1481#comment-1360888212
     */
    this.context.history.push(routes.app_plan.makePath({ slug: 'shinchan' }))
  }

  /* 番組別お知らせの表示 */
  renderNotice() {
    const { meta } = this.props
    const notice = {
      text: _.get(meta, ['values', 'notice']),
      hyperlink: _.get(meta, ['values', 'notice_hyperlink']),
      start_at: _.get(meta, ['values', 'notice_publish_start_at']),
      end_at: _.get(meta, ['values', 'notice_publish_end_at'])
    }
    const now = Date.now()

    if (
      !notice.text ||
      now < Date.parse(notice.start_at) ||
      Date.parse(notice.end_at) < now
    ) {
      return null
    }

    const html = (
      <dl className="c-program_notice">
        <dt className="c-program_notice__term">お知らせ</dt>
        <dd className="c-program_notice__desc">{notice.text}</dd>
      </dl>
    )

    return notice.hyperlink ? (
      <a href={notice.hyperlink} className="c-program_notice__wrapper">
        {html}
      </a>
    ) : (
      <div className="c-program_notice__wrapper">{html}</div>
    )
  }

  /** 選択中の放送年を更新 */
  updateSelectedYear(selectYear) {
    this.setState({ selectedYear: selectYear })

    // sp時のみ年次選択時スクロール
    if (window.matchMedia('(max-width: 1024px)').matches) {
      const divElm = document.getElementById('episodes-list')
      const clientRect = divElm.getBoundingClientRect()
      const rectY = clientRect.top + window.pageYOffset
      this.setState({ rectY: rectY - 56 }, () => {
        window.scroll({ top: this.state.rectY, behavior: 'smooth' })
      })
    }
  }

  render() {
    const {
      seriesId,
      seasonId,
      version,
      meta,
      otherSeasons,
      episodes,
      products,
      displayModes,
      loaded
    } = this.props
    const { pagerOptions, selectedYear } = this.state

    const disp_order = _.get(meta, ['values', 'disp_order'])

    const latestEpisode = episodes[0]
    let isFree = false
    if (latestEpisode) {
      const latestEpisodeDisplayMode = displayModes[latestEpisode.meta_id]
      switch (latestEpisodeDisplayMode) {
        case EPISODE_DISPLAY_MODE.FREE:
        case EPISODE_DISPLAY_MODE.TVOD_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_FREE:
          isFree = true
          break
        case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.UNKNOWN:
        default:
          isFree = false
          break
      }
    }

    const thumbnailUrl =
      webApp.utils.customSizeImageUrl(meta.thumbnail_url, 'large') ||
      this.config.default_thumbnail
    const [metaName, subTitle] = webApp.utils.titles(meta)
    const snippetId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 40
      : 41

    return (
      <div className="common-wrapper">
        <GoBack />
        <HeaderNewsComponent />
        {this.renderNotice()}

        <div className="c-headMeta">
          <div className="c-headMeta-metaBox">
            <div className="c-headMeta-metaBox-art">
              <div className="c-headMeta-metaBox-art-inBox">
                <div className="c-headMeta-metaBox-art-inBox-artwork">
                  <img
                    src={thumbnailUrl}
                    className="c-headMeta-metaBox-art-inBox-artwork-img"
                  />
                </div>
              </div>
            </div>

            <div className="c-headMeta-metaBox-info">
              <h2 className="c-headMeta-metaBox-info-title">{metaName}</h2>

              <Caption
                caption={meta.values && meta.values.evis_SeasonLongSynopsis}
                actors={meta.values && meta.values.evis_SeasonActors}
                directors={meta.values && meta.values.evis_SeasonDirectors}
                producers={meta.values && meta.values.evis_SeasonProducers}
                writers={meta.values && meta.values.evis_SeasonWriters}
                className="c-headMeta-metaBox-info-caption"
              />

              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.META}
                favoriteId={meta.meta_id}
                title={metaName}
                showAddMyList={false}
              />
            </div>
          </div>
        </div>

        <div className="c-listMeta">
          <div className="c-listMeta-inBox">
            <div className="c-listMeta-inBox-main">
              <PackList products={products} />
              <HtmlSnippet snippetId={snippetId} key="banner" />
              {Object.keys(meta).length > 0 && (
                <div className="c-switchableListGrid--shinchan">
                  <StyledH3>単話</StyledH3>
                  {meta.name === 'クレヨンしんちゃん' && (
                    <StyledSearchBoxYearWrapper
                      selectedYear={selectedYear}
                      setSelectedYear={this.updateSelectedYear}
                    />
                  )}
                  <StyledFilterSort
                    childEpisodeIds={_.get(
                      meta,
                      ['values', 'child_episode_ids'],
                      []
                    )}
                    seasonIds={[Number(seasonId)]}
                    howToPlays={null}
                    placeholder="検索　※上限50文字です"
                    showNew
                    showChecked
                    onlySubTitle
                    listType={'grid'}
                    disp_order={disp_order}
                    pagerOptions={pagerOptions}
                    isShinchan={meta.name === 'クレヨンしんちゃん'}
                    showBottomPager
                    selectedYear={selectedYear}
                    loaded={loaded}
                  />
                </div>
              )}
            </div>
            <div className="c-listMeta-inBox-sub">
              <SideTab otherSeasons={otherSeasons} />
              <SideRecommend
                typeName={this.config.recommend.type_name.view}
                spotName={this.config.recommend.spot_name.program}
                courseRefId={'svod_shin'}
                episodes={episodes}
              />
            </div>
          </div>
        </div>
        <Footer className="mp-mt0" />
      </div>
    )
  }
}

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 600;

  @media ${mediaSp} {
    padding: 0 15px;
  }
`

const StyledSearchBoxYearWrapper = styled(SearchBoxYearWrapper)`
  margin-top: 20px;
`

const StyledFilterSort = styled(FilterSort)`
  margin-top: 20px;
`
