import styled from 'styled-components'
import { mediaSp } from '../styles'

const yellow = '#cece00'

export const StyledNav = styled.nav`
  background-color: #fff;

  @media ${mediaSp} {
    padding: 0 15px;
  }
`

export const StyledUl = styled.ul`
  margin: 0 auto;
  max-width: 415px;
  display: flex;
`

export const StyledLi = styled.li`
  width: 100%;
  display: block;
  text-align: center;
`

export const StyledButton = styled.button`
  padding: 1.5rem 0;
  width: 100%;
  background: none;
  border-width: 0;
  border-bottom: ${({ isActive }) => (isActive ? `5px solid ${yellow}` : null)};
  display: inline-block;
  color: #343434;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  transition: color 0.3s;

  &:hover {
    color: #939394;
  }
`
