import styled from 'styled-components'
import { mediaQuery } from '../../../style'

export const StyledH2 = styled.h2`
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;

  ${mediaQuery()} {
    padding-bottom: 0;
  }
`

export const StyledImg = styled.img`
  margin-top: auto;

  ${mediaQuery()} {
    margin-bottom: -30px;
  }
`
