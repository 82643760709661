import styled from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'
import { breakPoints } from '../../style'

export const StyledDiv = styled.div`
  margin: 20px 8px;
  width: auto;
`

export const StyledUl = styled.ul`
  overflow: hidden;

  ${mediaQuery(breakPoints.sp)} {
    overflow: initial;
  }
`
