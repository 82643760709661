import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import { checkAvailableStatus } from './util'
import styled from 'styled-components'
import { mediaSp } from './styles'

/** 再生ボタン */
export default class PlayButton extends Component {
  static propTypes = {
    /**
     * 動画ステータス
     * @param {number} displayMode - 動画の視聴形態(1-9)
     * @param {string} 1 - FREE
     * @param {string} 2 - TVOD_FREE
     * @param {string} 3 - TVOD_NOT_FREE
     * @param {string} 4 - SVOD_FREE
     * @param {string} 5 - SVOD_NOT_FREE
     * @param {string} 6 - SVOD_AUTH_FREE
     * @param {string} 7 - STVOD_FREE
     * @param {string} 8 - STVOD_TVOD_NOT_FREE
     * @param {string} 9 - STVOD_SVOD_NOT_FREE
     */
    status: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      isDeviceNotAvailable: PropTypes.bool,
      limitDate: PropTypes.string,
      isPossible: PropTypes.bool,
      isBelonging: PropTypes.bool
    }),
    product: PropTypes.object,
    /** onClickPlayの動作を制御する */
    disabled: PropTypes.bool,
    /** [購入に必要なコイン枚数, 購入に必要な日本円] */
    price: PropTypes.array,
    /** ボタンを押した時に発火する関数 */
    onClickPlay: PropTypes.func,
    /** trueの時、このコンポーネントは描画されない */
    hidePlayButton: PropTypes.bool,
    /** 未ログイン時にログイン文言を表示する */
    showLogin: PropTypes.bool
  }

  static defaultProps = {
    disabled: false,
    price: [null, null],
    onClickPlay: () => {},
    hidePlayButton: false,
    showLogin: false
  }

  static contextTypes = {
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      isLoggedIn: webApp.utils.isLoggedIn(this.context)
    }
  }

  componentDidMount() {}

  render() {
    const { status, hidePlayButton, showLogin } = this.props
    const { isLoggedIn } = this.state
    const watchAvailableStatus = checkAvailableStatus(this.props, this.context)
    const isApp = webApp.utils.isApp(this.context)

    // 未ログイン時にログイン文言を表示する
    if (!isLoggedIn && showLogin) {
      return (
        <>
          <StyledDiv1>ログイン</StyledDiv1>
          <StyledDiv2>ログイン</StyledDiv2>
        </>
      )
    }

    // 「しんちゃん」で、未ログイン状態の時アイコンが非表示になる。(仕様検討中)
    if (!isLoggedIn && hidePlayButton) {
      return null
    }

    switch (watchAvailableStatus) {
      /** 再生する */
      case 'PLAY':
        // コンテンツして有効な状態でないのであれば再生ボタンを非表示に
        if (!status.isFree && !status.isPurchseAvailable) return null
        return (
          <>
            <StyledDiv1 />
            <StyledDiv2>視聴する</StyledDiv2>
          </>
        )

      /** 購入する */
      case 'PURCHASE_EPISODE':
      case 'PURCHASE_PLAN':
        if (isApp) {
          return (
            <StyledDiv4>
              ログインすることで
              <br />
              既にお持ちのコンテンツを
              <br />
              お楽しみいただけます。
            </StyledDiv4>
          )
        }
        return (
          <>
            <StyledDiv3>
              未購入
              <StyledSpan>
                購入済の場合は
                <br />
                ログインしてください
              </StyledSpan>
            </StyledDiv3>
            <StyledDiv1>購入して再生</StyledDiv1>
            <StyledDiv2>購入して再生</StyledDiv2>
          </>
        )

      /** 機能しない */
      case 'NOT_PLAY':
      default:
        return null
    }
  }
}

export const StyledDiv1 = styled.div`
  margin: auto;
  position: absolute;
  padding-top: 170px;
  width: 144px;
  height: 144px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_pc.svg)
    no-repeat center center;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;

  @media ${mediaSp} {
    display: none;
  }
`

export const StyledDiv2 = styled.div`
  padding-top: 68px;
  width: 80px;
  height: 80px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_sp.svg)
    no-repeat top center;
  display: none;
  position: absolute;
  z-index: 1;
  right: 1%;
  bottom: 15px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  vertical-align: bottom;
  filter: drop-shadow(0 0 3px #fff);

  @media ${mediaSp} {
    display: block;
    color: #000;
  }
`

export const StyledDiv3 = styled.div`
  margin-left: auto;
  padding: 0.6em 1.2em;
  background: rgba(102, 102, 102, 0.5);
  border-radius: 5px;
  position: absolute;
  bottom: 21px;
  left: 15px;
  z-index: 1;
  color: #fff;
  font-size: 1.8rem;
  text-align: center;

  @media ${mediaSp} {
    font-size: 1.3rem;
  }
`

/** EpisodeAppShinchanで継承されないため重複部分もベタ書き */
export const StyledDiv4 = styled(StyledDiv3)`
  margin-left: auto;
  padding: 0.6em 3.2em;
  background: rgba(102, 102, 102, 0.5);
  border-radius: 5px;
  position: absolute;
  bottom: 21px;
  left: 15px;
  z-index: 1;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;

  @media ${mediaSp} {
    padding: 0.6em 1.6em;
    font-size: 1rem;
  }
`

export const StyledSpan = styled.span`
  margin-top: 5px;
  display: block;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.25;

  @media ${mediaSp} {
    font-size: 1rem;
  }
`
