import React, { Component } from 'react';
import PropTypes from 'prop-types';
import routes from '../../../../common/routes';
import { MYPAGE_CONTENTS } from '../../../../../constants/app';
import Link from '../../../../../sketch-platform/ui/routing/Link';
import webApp from '../../../utils/exdioWebAppUtils';

/** アカウント設定 */
export default class AccountSetting extends Component {
  static propTypes = {};
  static defaultProps = {};
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.config = context.models.config.data;
    this.state = {};

    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  goBack(e) {
    e.preventDefault();
    this.context.history.goBack();
  }

  render() {
    const isApp = webApp.utils.isApp(this.context);
    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <div className="c-mypageAccount">
            <header className="c-mypageAccount-header">
              {this.context.history && this.context.history.length > 1 ? (
                <a href="#" className="c-mypage-btnBack alwaysOn" onClick={this.goBack}>
                  戻る
                </a>
              ) : (
                <Link route={routes.app_home} className="c-mypage-btnBack alwaysOn">
                  戻る
                </Link>
              )}
              <h3 className="c-mypageAccount-header-title">アカウント設定</h3>
            </header>
            <div className="c-mypageAccount-cont">
              <div className="c-mypageAccount-cont-personal">
                <div className="c-mypageAccountSetting">
                  <div className="c-mypageAccountSetting-guides">
                    <ul className="c-mypageAccountSetting-guides-inBox">
                      <li className="c-mypageAccountSetting-guides-inBox-btn userguide">
                        <Link
                          route={isApp ? routes.app_mypage_member_setting : routes.mypage_member_setting}
                          state={{ content: MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_MEMBER }}
                          className="c-mypageAccountSetting-guides-inBox-btn-link"
                          replace
                        >
                          登録情報の変更
                        </Link>
                      </li>
                      <li className="c-mypageAccountSetting-guides-inBox-btn faq">
                        <Link
                          route={isApp ? routes.app_mypage_member_setting : routes.mypage_member_setting}
                          state={{ content: MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_PASSWORD }}
                          className="c-mypageAccountSetting-guides-inBox-btn-link"
                          replace
                        >
                          パスワードの変更
                        </Link>
                      </li>
                      <li className="c-mypageAccountSetting-guides-inBox-btn contact">
                        {(() => {
                          if (document.cookie.split(';').some(item => item.includes('RAKUTEN_LOGIN=1'))) {
                            return (
                              <Link
                                route={isApp ? routes.app_mypage_member_setting : routes.mypage_member_setting}
                                state={{ content: MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_EMAIL }}
                                className="c-mypageAccountSetting-guides-inBox-btn-link"
                                replace
                              >
                                連絡先メールアドレスの登録／変更
                              </Link>
                            );
                          } else {
                            return (
                              <Link
                                route={isApp ? routes.app_mypage_member_setting : routes.mypage_member_setting}
                                state={{ content: MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_EMAIL }}
                                className="c-mypageAccountSetting-guides-inBox-btn-link"
                                replace
                              >
                                メールアドレスの変更
                              </Link>
                            );
                          }
                        })()}
                      </li>
                      <li className="c-mypageAccountSetting-guides-inBox-btn privacy">
                        <Link
                          route={isApp ? routes.app_mypage_member_setting : routes.mypage_member_setting}
                          state={{ content: MYPAGE_CONTENTS.MEMBER_SETTING.DELETE_TVASAHI_ID }}
                          className="c-mypageAccountSetting-guides-inBox-btn-link"
                          replace
                        >
                          tv asahi idの削除
                        </Link>
                      </li>
                      <li className="c-mypageAccountSetting-guides-inBox-btn blank" />
                      <li className="c-mypageAccountSetting-guides-inBox-btn blank" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
