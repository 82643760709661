import React, { useState, useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { get, size, union, shuffle } from 'lodash'
import routes from '../../../common/routes'
import webApp from '../../utils/exdioWebAppUtils'
import Loading from './PlanGariben/Loading'
import PlanGariben from './PlanGariben'

/** 月額見放題ページ */
const PlanGaribenContent = ({ course = {}, ...props }, context) => {
  const config = context.models.config.data
  const model = context.falcorModel.batch(100)
  const isLoggedIn = webApp.utils.isLoggedIn(context)

  // 更新情報パレット
  const paletteId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 226
    : 115

  const programIds = config.svod.gariben_daigaku
  const schoolAssembliesSeasonId = programIds.school_assemblies.season_id // ライブ配信
  const liveArchivesSeasonId = programIds.live_archives.season_id // ライブ配信アーカイブ
  const specialMoviesSeasonId = programIds.special_movies.season_id // 特別授業動画
  const archivesSeasonId = programIds.archives.season_id // 神回アーカイブ

  const [programMetas, setProgramMetas] = useState([])
  const [status, setStatus] = useState({
    isPurchseAvailable: course.active_pricing !== null,
    isPurchased: false,
    isNotPurchased: true
  })

  const ignore = useRef(false)
  const timeoutId = useRef(false)
  const [loaded, setLoaded] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [point, setPoint] = useState(0)
  const [nickname, setNickname] = useState('')
  const [specialMovies, setSpecialMovies] = useState([])
  const [archives, setArchives] = useState([])
  const [tags, setTags] = useState([])
  const [ranking, setRanking] = useState([])
  const [schoolAssemblies, setSchoolAssemblies] = useState([])
  const [liveArchives, setLiveArchives] = useState([])
  const [blogPosts, setBlogPosts] = useState([])
  const [newsPalettes, setNewsPalettes] = useState([])
  const [news, setNews] = useState([])
  const [newsPalettePublishStartAt, setNewsPalettePublishStartAt] = useState('')
  const [specialArchives, setSpecialArchives] = useState({
    isActive: false,
    episodes: []
  })
  const [recommendArchives, setRecommendArchives] = useState({
    isActive: false,
    recommenders: []
  })

  const api = axios.create({
    baseURL: config.service.endpoint,
    timeout: config.service.timeout
  })

  /** シーズンのメタを取得 */
  const getProgramMetas = (metaIds) => {
    const path = ['metas', metaIds]
    return model.fetch([path]).then((result) => {
      const res = get(result, ['json', 'metas'], [])
      delete res.$__path
      return res
    })
  }

  /** グループのライセンス有無取得 */
  const getBelonging = () => {
    if (!course) return Promise.reject()

    if (!isLoggedIn) return Promise.resolve(status)

    const refId = course.ref_id
    if (!refId) return Promise.reject()

    const path = ['infra', 'gBelong', refId]
    return model.fetch([path]).then((result) => {
      const belonging = get(result, ['json', ...path]) || false
      return {
        ...status,
        isPurchased: belonging,
        isNotPurchased: !belonging
      }
    })
  }

  /** 番組ごとのエピソードを取得 */
  const getEpisodes = async (seasonId, limit = 1) => {
    const path = ['meta', 'latest', seasonId, limit]
    const resEpisodes = await model.fetch([path])
    return get(resEpisodes, ['json', ...path]) || []
  }

  /** 図書館ブログ 記事取得 */
  const getBlogPosts = () => {
    const blogApi =
      'https://www.tv-asahi.co.jp/douga_mv/gariben_daigaku/api/get_blog_feed.php'
    const rss = 'https://www.tv-asahi.co.jp/reading/garibendaigaku/feed/'

    /*
     * データ取得
     */
    return axios
      .get(blogApi, { params: { rss: encodeURI(rss) } })
      .then(({ data }) => {
        return data.map((item) => {
          const {
            thumbnail: resThumb = [],
            link: resLink = '',
            title: resTitle = '',
            date,
            date_time: dateTime
          } = item
          const thumbnail = resThumb[0]
            ? `https://www.tv-asahi.co.jp${resThumb[0]}`
            : `/images/exdio/renewal/gariben_daigaku/top/blog_default_thum.webp`
          const link = typeof resLink === 'object' ? resLink[0] : resLink
          const title = typeof resTitle === 'object' ? resTitle[0] : resTitle
          if (thumbnail && title && date && dateTime) {
            return {
              type: 'blog',
              thumbnail,
              link,
              title,
              date,
              date_time: dateTime
            }
          }
          return null
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  /**
   * 更新情報を取得
   *
   * 手動で1件追加が可能（パレット）
   * 各シリーズの動画を新着順に並び替え、
   * パレットにmetaが登録されている場合は1件目に挿入し、
   * マージした配列を先頭から6件切り取る
   * */
  const getNews = () => {
    const limit = 6
    const episodes = [].concat(
      schoolAssemblies,
      liveArchives,
      specialMovies,
      archives
    )

    /* DateTime(ミリ秒)を取得 */
    const getDateTime = (metaOrPost) => {
      let dateTime
      switch (get(metaOrPost, ['type'])) {
        case 'MediaMeta':
        case 'media_meta':
        case 'Media':
          dateTime = get(metaOrPost, ['publish_start_at'])
          break
        case 'blog':
          dateTime = get(metaOrPost, ['date_time'])
          break
        default:
          break
      }
      if (dateTime) {
        dateTime = dateTime.replace(/-/g, '/')
      }
      return Date.parse(dateTime)
    }

    /* yyyy.mm.dd の形に整形 */
    const formatDate = (dateTimeStr) => {
      const d = new Date(Date.parse(dateTimeStr))
      const year = d.getFullYear()
      const month = `0${d.getMonth() + 1}`.slice(-2)
      const date = `0${d.getDate()}`.slice(-2)

      return `${year}.${month}.${date}`
    }

    const episodesAndBlogPosts = []
      .concat(episodes, blogPosts)
      .sort((a, b) => (getDateTime(a) < getDateTime(b) ? 1 : -1))

    const episodesAndBlogPostsArr = episodesAndBlogPosts.map((item) => {
      switch (get(item, ['type'])) {
        case 'MediaMeta':
        case 'media_meta':
        case 'Media': {
          const episodeId = get(item, ['meta_id'])
          const seriesId = get(item, ['values', 'parents_series', 'id'])
          const seasonId = get(item, ['values', 'parents_season', 'id'])

          return {
            type: '動画',
            route: routes.episode,
            params: { seriesId, seasonId, episodeId },
            name: get(item, ['name']),
            dateTime: formatDate(get(item, ['publish_start_at'])),
            thumbnail: get(item, ['thumbnail_url'])
          }
        }

        case 'blog':
          return {
            type: 'ブログ',
            href: get(item, ['link']),
            params: {},
            name: get(item, ['title']),
            dateTime: get(item, ['date']),
            thumbnail: get(item, ['thumbnail'])
          }

        default:
          return null
      }
    })

    const newsPalettesArr = newsPalettes.map((item) => {
      switch (get(item, ['type'])) {
        case 'meta': {
          const episodeId = get(item, ['meta', 'meta_id'])
          const seriesId = get(item, ['meta', 'values', 'parents_series', 'id'])
          const seasonId = get(item, ['meta', 'values', 'parents_season', 'id'])

          return {
            type: '動画',
            route: routes.episode,
            params: { seriesId, seasonId, episodeId },
            name: get(item, ['meta', 'name']),
            dateTime: formatDate(newsPalettePublishStartAt),
            thumbnail: get(item, ['meta', 'thumbnail_url'])
          }
        }

        case 'advertising':
          return {
            type: '',
            href: get(item, ['advertising', 'url']),
            name: get(item, ['advertising', 'name']),
            dateTime: formatDate(newsPalettePublishStartAt),
            thumbnail: get(item, [
              'advertising',
              'creatives',
              0,
              'attachment',
              'file_url'
            ])
          }

        default:
          return null
      }
    })

    const resNews = [...newsPalettesArr, ...episodesAndBlogPostsArr].slice(
      0,
      limit
    )

    setNews(resNews)
  }

  /** 更新情報パレットの取得 */
  const getNewsPalettes = async () => {
    return api
      .get(`/palettes/${paletteId}/objects`)
      .then(({ data }) => get(data, ['objects']))
  }

  /** 更新情報パレット 公開日の取得 */
  const getNewsPalettePublishStartAt = async () => {
    return api
      .get(`/palettes/${paletteId}`)
      .then(({ data }) => get(data, ['publish_start_at']))
  }

  /** ランキングのpaletteを取得 */
  const getRanking = () => {
    const path = ['paletteByKey', 'garidai_ranking']
    return model
      .fetch([path])
      .then(async (result) => get(result, ['json', ...path, 'objects'], []))
  }

  /**
   * 神回アーカイブ特別６本を取得
   */
  const getArchiveEpisodes = async (programMeta) => {
    const _specialArchives = get(
      programMeta,
      ['values', 'season_front_display_config', 'feature'],
      {
        isActive: false,
        episodeIds: []
      }
    )
    const episodeIds = get(_specialArchives, ['episodeIds'], [])
    const path = ['metas', episodeIds]
    return model
      .fetch([path])
      .then((result) => {
        const episodes = get(result, ['json', 'metas'], {})

        /** Dioの記載順に並べ替え、空要素の削除 */
        const episodesArray = episodeIds
          .map((episodeId) => episodes[episodeId]) // idをkeyに持つvalueを抽出
          .filter(Boolean) // 空要素の削除

        return {
          ..._specialArchives,
          episodes: episodesArray
        }
      })
      .catch((err) => console.error(err))
  }

  /**
   * おすすめ神回アーカイブの動画情報を取得
   */
  const getRecommendArchiveEpisodes = async (programMeta) => {
    const _recommendArchives = get(
      programMeta,
      ['values', 'season_front_display_config', 'featureRecommend'],
      {
        isActive: false,
        recommenders: []
      }
    )
    const recommenders = get(_recommendArchives, ['recommenders'], [])
    const mergedEpisodes = recommenders.reduce(
      (acc, cur) => [...acc, ...cur.episodeIds],
      []
    )

    const path = ['metas', mergedEpisodes]
    return model
      .fetch([path])
      .then((result) => {
        const recommends = get(result, ['json', 'metas'], {})
        delete recommends.$__path
        Object.keys(recommends).forEach((key) => {
          if (recommends[key] === null) delete recommends[key]
        })
        return {
          ..._recommendArchives,
          episodes: Object.values(recommends)
        }
      })
      .catch((err) => console.error(err))
  }

  /** 検索エリアで使用するタグを取得 (ランダム１０子) */
  const getTags = async () => {
    /** 指定したシーズンのエピソードを取得 */
    const getAllEpisodes = (seasonIdList) => {
      const path = ['meta', 'children', seasonIdList]
      return model.fetch([path]).then((result) => {
        return seasonIdList.reduce((acc, seasonId) => {
          const episodes = get(result, ['json', 'meta', 'children', seasonId])
          return [...acc, ...episodes]
        }, [])
      })
    }

    /** 取得したエピソードのタグを取得 */
    const allEpisodes = await getAllEpisodes([
      liveArchivesSeasonId,
      specialMoviesSeasonId,
      archivesSeasonId
    ])
    const allTags = union(
      allEpisodes.reduce((acc, episode) => {
        const episodeTags = get(episode, ['tags'])
        return [...acc, ...episodeTags]
      }, [])
    )
    // ランダムに並べ替え、上から１０件を切り取って返す
    return shuffle(allTags).slice(0, 10)
  }

  /** ユーザー情報取得 */
  const getUserInfo = () => {
    const path = ['user', 'info']
    return model
      .fetch([path])
      .then((result) => get(result, ['json', ...path]) || null)
  }

  /** 単位ポイントの取得 */
  const getPoint = async () => {
    try {
      const {
        data
      } = await axios.get(
        `https://${config.infra_host}/mps/bangumi/gariben_daigaku/api/getpoint.php`,
        { withCredentials: true }
      )
      return get(data, ['point'])
    } catch (err) {
      return console.error(err)
    }
  }

  /** ニックネームの取得 */
  const getNickName = async () => {
    try {
      const {
        data
      } = await axios.get(
        `https://${config.infra_host}/mps/bangumi/gariben_daigaku/api/getnickname.php`,
        { withCredentials: true }
      )
      return get(data, ['nick_name'])
    } catch (err) {
      return console.error(err)
    }
  }

  useEffect(() => {
    ;(async () => {
      const metaIds = [
        specialMoviesSeasonId,
        liveArchivesSeasonId,
        archivesSeasonId
      ]
      const resProgramMetas = await getProgramMetas(metaIds).catch((e) =>
        webApp.utils.handleFalcorError(e, context)
      )
      if (!ignore.current) setProgramMetas(resProgramMetas)

      const resSpecialArchives = await getArchiveEpisodes(
        resProgramMetas[archivesSeasonId]
      )
      if (!ignore.current) setSpecialArchives(resSpecialArchives)

      const resRecommendArchives = await getRecommendArchiveEpisodes(
        resProgramMetas[archivesSeasonId]
      )
      if (!ignore.current) setRecommendArchives(resRecommendArchives)
    })()
    ;(async () => {
      const resTags = await getTags()
      if (!ignore.current) setTags(resTags)
    })()

    /** 必要なエピソードの取得 */
    ;(async () => {
      const resSchoolAssemblies = await getEpisodes(schoolAssembliesSeasonId)
      if (!ignore.current) setSchoolAssemblies(resSchoolAssemblies)
      const resLiveArchives = await getEpisodes(liveArchivesSeasonId, 8)
      if (!ignore.current) setLiveArchives(resLiveArchives)
      const resSpecialMovies = await getEpisodes(specialMoviesSeasonId, 8)
      if (!ignore.current) setSpecialMovies(resSpecialMovies)
      const resArchives = await getEpisodes(archivesSeasonId, 2)
      if (!ignore.current) setArchives(resArchives)
    })()
    ;(async () => {
      const resRanking = await getRanking()
      if (!ignore.current) setRanking(resRanking)
    })()

    /** ブログ記事の取得 */
    ;(async () => {
      const resBlogPosts = await getBlogPosts()
      if (!ignore.current) setBlogPosts(resBlogPosts.slice(0, 8))
    })()

    /** 更新情報パレットの取得 */
    ;(async () => {
      const resNewsPalettePublishStartAt = await getNewsPalettePublishStartAt()
      if (!ignore.current)
        setNewsPalettePublishStartAt(resNewsPalettePublishStartAt)
    })()

    /** ログイン判別 */
    if (isLoggedIn === true) {
      ;(async () => {
        /** ユーザー情報取得 */
        const resUserInfo = await getUserInfo()
        if (!ignore.current) setUserInfo(resUserInfo)
      })()
      ;(async () => {
        /** 単位ポイントの取得 */
        const resPoint = await getPoint()
        if (!ignore.current) setPoint(resPoint)
      })()
      ;(async () => {
        /** ニックネームの取得 */
        const resNickName = await getNickName()
        if (!ignore.current) setNickname(resNickName)
      })()
    }

    timeoutId.current = setTimeout(() => {
      if (!ignore.current) setLoaded(true)
    }, 3000)

    return () => {
      ignore.current = true
      clearTimeout(timeoutId.current)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      // ライセンス取得
      const resStatus = (await getBelonging(course)) || status
      // getSyncだとfalcorのキャッシュが入ってないとundefinedになるっぽいので、ここで再設定
      resStatus.isPurchseAvailable = course && course.active_pricing !== null
      if (!ignore.current) setStatus(resStatus)
    })()
  }, [course])

  useEffect(() => {
    getNews()
  }, [
    schoolAssemblies,
    liveArchives,
    specialMovies,
    archives,
    blogPosts,
    newsPalettes,
    newsPalettePublishStartAt
  ])

  useEffect(() => {
    ;(async () => {
      const resNewsPalettes = await getNewsPalettes()
      if (size(resNewsPalettes) > 0) {
        if (!ignore.current) setNewsPalettes(resNewsPalettes)
      }
    })()
  }, [newsPalettePublishStartAt])

  return (
    <>
      <Loading loaded={loaded} />
      <PlanGariben
        course={course}
        status={status}
        programMetas={programMetas}
        userInfo={userInfo}
        point={point}
        nickname={nickname}
        archives={archives}
        tags={tags}
        ranking={ranking}
        schoolAssemblies={schoolAssemblies}
        liveArchives={liveArchives}
        blogPosts={blogPosts}
        news={news}
        specialArchives={specialArchives}
        recommendArchives={recommendArchives}
        {...props}
      />
    </>
  )
}

export default memo(PlanGaribenContent)

PlanGaribenContent.propTypes = {
  slug: PropTypes.string,
  course: PropTypes.shape({
    ref_id: PropTypes.string.isRequired,
    course_id: PropTypes.number.isRequired,
    schema_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    active_pricing: PropTypes.shape({}).isRequired,
    values: PropTypes.shape({}).isRequired
  }).isRequired
}
PlanGaribenContent.contextTypes = {
  falcorModel: PropTypes.shape({}),
  models: PropTypes.shape({})
}
