import styled, { css } from 'styled-components'
import { StyledLink } from '../../Button1'
import { mediaQuery } from '../../../../style'

export const StyledUl = styled.ul`
  display: flex;
  justify-content: center;

  ${mediaQuery()} {
    margin: 0 auto;
    width: calc(100% - 40px);
    justify-content: flex-start;
  }
`

export const StyledLi = styled.li`
  margin-left: 100px;

  &:first-child {
    margin-left: 0;
  }

  ${mediaQuery()} {
    margin-left: 20px;
    width: 96px;
  }
`

export const StyledButton = styled(StyledLink)`
  border: 0;
  border-radius: 13px;
  box-shadow: 0 5px 0 0 #ffbc09;
  display: flex;
  color: #672806;
  font-size: 1.8rem;
  cursor: pointer;

  &:active {
    color: #672806;
  }

  &:hover {
    background-color: #ffbc09;
    box-shadow: 0 0 0 0 #ffbc09;
  }

  ${mediaQuery()} {
    box-shadow: none;
    border-radius: 5px;
    font-size: 1.4rem;
  }

  ${({ checked }) => {
    if (checked) {
      return css`
        background-color: #ffbc09;
        box-shadow: 0 0 0 0 #ffbc09;

        ${mediaQuery(null, 'min')} {
          transform: translateY(5px);
        }
      `
    }
    return null
  }}
`

export const StyledRadio = styled.input`
  display: none;
`
