import styled from 'styled-components'

// Switchable用style
export const StyledDiv1 = styled.div`
  margin-bottom: ${({ isSmp }) => (isSmp ? '20px' : '30px')};
`

export const StyledUl1 = styled.ul`
  display: grid;
  grid-template-columns: ${({ isSmp }) =>
    isSmp ? '1fr 1fr' : '1fr 1fr 1fr 1fr'};
  gap: ${({ isSmp }) => (isSmp ? '10px' : '0 10px')};
  max-width: ${({ isSmp }) => (isSmp ? '650px' : 'auto')};
  margin: 0 auto;
`
