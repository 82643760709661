import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import window from 'global'
import { createGlobalStyle } from 'styled-components'
import Footer from '../../../../common/components/Footer'
import DFPBanner from '../../../../common/components/DFPBanner'
import PackList from '../details/PackList'
import AboutMedalLink from '../../../../common/components/renewal/AboutMedalLink'
import EpisodeListPack from '../details/EpisodeListPack'
import CastInfo from '../details/CastInfo'
import SideTab from '../details/SideTab'
import ProgramTopLink from '../details/ProgramTopLink'
import EpisodePager from '../details/EpisodePager'
import {
  FAVORITE_TYPE,
  LOCAL_STORAGE_KEY_PURCHASE,
  PURCHASE_TYPE
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import AddButtonBlock from '../details/AddButtonBlock'
import webApp from '../../../utils/exdioWebAppUtils'
import HtmlSnippet from '../../HtmlSnippet'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'
import SideRecommend from '../details/SideRecommend'
import PackViewTerm from '../details/PackViewTerm'
import HeaderNewsComponent from '../HeaderNewsComponent'
import { mediaQuery } from '../../style'

/** パックページ デフォルトテンプレート */
export default class PackDefault extends Component {
  static propTypes = {
    /** パックのID */
    productId: PropTypes.string,
    /** パックの情報 */
    product: PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object,
      values: PropTypes.object.isRequired
    }).isRequired,
    /** パックに含まれる動画情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** パックの詳細情報、視聴権利関連情報 */
    right: PropTypes.shape({
      view_term: PropTypes.number
    }),
    /** パックに含まれる動画の視聴権利情報 */
    rightMetas: PropTypes.arrayOf(PropTypes.object),
    /** パックのシーズン情報 */
    season: PropTypes.shape({
      meta_id: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
      values: PropTypes.object
    }).isRequired,
    /** パック販売情報 */
    products: PropTypes.arrayOf(PropTypes.object),
    /** パックの購入情報 */
    status: PropTypes.shape({
      isPurchseAvailable: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      limitDate: PropTypes.string
    })
  }

  static defaultProps = {
    episodes: [],
    packs: [],
    right: null,
    status: {
      isPurchased: false,
      isNotPurchased: false,
      limitDate: null
    }
  }

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.state = {}

    this.onClickWatch = this.onClickWatch.bind(this)
  }

  componentDidMount() {}

  /** 視聴ボタン押下 */
  onClickWatch(e) {
    e.preventDefault()

    const { status } = this.props
    if (!status.isNotPurchased) return

    const { productId } = this.props
    const productIdNum = Number(productId)
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_PURCHASE,
      JSON.stringify({
        type: PURCHASE_TYPE.PACK,
        id: productIdNum,
        selected: [productIdNum],
        page: this.context.routeHandler.url
      })
    )
    this.context.history.push(routes.purchase.makePath())
  }

  render() {
    const {
      product,
      series,
      season,
      packs,
      right,
      rightMetas,
      status
    } = this.props

    const price = _.get(product, ['active_pricing', 'price'])
    const thumbnailUrl =
      _.get(product, ['thumbnail_url']) || this.config.default_thumbnail

    const otherInstructions =
      (product.values && product.values.otherInstructions) || null
    const actors = (product.values && product.values.actors) || null
    const directors = (product.values && product.values.directors) || null
    const producers = (product.values && product.values.producers) || null
    const writers = (product.values && product.values.writers) || null
    const productions = (product.values && product.values.productions) || null
    const description = _.get(product, ['description'], '').replace(
      /\r?\n/g,
      '<br />'
    )
    const discount = webApp.utils.calcPackDiscount(product)

    // 他のシリーズ用ID
    let otherSeasons = _.get(product, ['values', 'other_series']) || []
    const otherSeasonsLive =
      _.get(product, ['values', 'other_series_live']) || []
    otherSeasons = otherSeasons.concat(otherSeasonsLive)

    const bannerId1 = _.get(product, ['values', 'banner_1', 'id'])
    const bannerId2 = _.get(product, ['values', 'banner_2', 'id'])

    let labelStatus = ''
    if (status.isNotPurchased) labelStatus = '未購入'
    if (status.isPurchased) labelStatus = '購入済み'

    const isOnSale = webApp.utils.isOnSale(this.context, product)

    const disp_order = _.get(season, ['values', 'disp_order'])

    return (
      <div className="common-wrapper">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />

        <div className="c-headMeta">
          <div className="c-headMeta-metaBox">
            <div className="c-headMeta-metaBox-art">
              <div className="c-headMeta-metaBox-art-inBox">
                {labelStatus && (
                  <div className="c-headMeta-metaBox-art-inBox-labelStatus">
                    {labelStatus}
                  </div>
                )}
                <div className="c-headMeta-metaBox-art-inBox-artwork">
                  <img
                    src={thumbnailUrl}
                    className="c-headMeta-metaBox-art-inBox-artwork-img"
                  />
                </div>
              </div>

              {/* for PC */}
              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.PRODUCT}
                favoriteId={product.product_id}
                title={product.name}
              />
            </div>

            <div className="c-headMeta-metaBox-info">
              <h2 className="c-headMeta-metaBox-info-title clickable">
                <a href="/purchase" onClick={this.onClickWatch}>
                  {product.name}
                </a>
              </h2>
              <div className="c-headMeta-metaBox-info-price pack">
                {status.isPurchseAvailable ? (
                  <React.Fragment>
                    料金：<span className="coin">{price}</span>
                    <AboutMedalLink />
                  </React.Fragment>
                ) : (
                  '料金：--'
                )}
              </div>
              {status.isPurchased ? (
                <div className="c-headMeta-metaBox-info-price isPurchased">
                  購入済
                </div>
              ) : null}
              <PackViewTerm right={right} status={status} />
              <div
                className="c-headMeta-metaBox-info-titleHeader"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <div className="c-storyMeta-inBox-actions">
                <div className="c-pageBtn playNow">
                  {status.isPurchseAvailable && isOnSale ? (
                    <a
                      href="/purchase"
                      className={
                        status.isPurchased
                          ? 'c-pageBtn-link isPurchased'
                          : 'c-pageBtn-link'
                      }
                      onClick={this.onClickWatch}
                    >
                      {status.isPurchased ? (
                        <span>購入済み</span>
                      ) : (
                        <span>
                          <span className="c-pageBtn-link-coins">{price}</span>
                          でパック視聴
                          {discount > 0 && (
                            <span className="c-pageBtn-link-discount">
                              <span className="c-pageBtn-link-discount-rate">
                                {discount}%
                              </span>
                              お得
                            </span>
                          )}
                        </span>
                      )}
                    </a>
                  ) : (
                    <a className="c-pageBtn-link disabled" disabled>
                      現在購入いただけません
                    </a>
                  )}
                </div>

                {/* for SP */}
                <AddButtonBlock
                  favoriteType={FAVORITE_TYPE.PRODUCT}
                  favoriteId={product.product_id}
                  title={product.name}
                />

                <EpisodePager
                  seriesId={series && series.id}
                  seasonId={season && season.meta_id}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="c-listMeta">
          <div className="c-listMeta-inBox">
            <div className="c-listMeta-inBox-main">
              <PackList products={packs} showMedal />
              {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
              {rightMetas && rightMetas.length > 0 && (
                <EpisodeListPack
                  episodes={rightMetas}
                  disp_order={disp_order}
                />
              )}
              <CastInfo
                otherInstructions={otherInstructions}
                actors={actors}
                directors={directors}
                producers={producers}
                writers={writers}
                productions={productions}
                isPadding
              />
            </div>
            <div className="c-listMeta-inBox-sub">
              <div className="common-box-ad-rectangle">
                <DFPBanner position="foot" meta_id="" />
              </div>
              {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
              <SideTab otherSeasons={otherSeasons} />
              <ProgramTopLink
                seriesId={series && series.id}
                seasonId={season && season.meta_id}
                isFree={false}
                disabled={!series || !season}
              />
              <HtmlSnippet
                snippetId={this.config.extras.common_banner_snippet_key}
              />
              <SideRecommend
                typeName={this.config.recommend.type_name.view}
                spotName={this.config.recommend.spot_name.program}
              />
            </div>
          </div>
        </div>

        <Footer className="mt0" />
        <GlobalStyle />
      </div>
    )
  }
}

const GlobalStyle = createGlobalStyle`
  #point-header + .mainView {
    ${mediaQuery()} {
      margin-top: 15px;
    }
  }
`
