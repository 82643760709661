import React, { memo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ProgramItemLink from '../../ProgramItemLink'
import {
  ADVERTISING_SCHEMA_ID,
  META_SCHEMA_ID
} from '../../../../../../constants/app'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'

/** パレットの編成内容 */
const Objects = (
  { palette = {}, howToPlays = {}, courses = null, products = null, ...props },
  context
) => {
  const { objects = [], id_key: idKey = '' } = palette

  /** AppTopの特定のpaletteは表示を変更する */
  const isAppTopPalette = idKey.match(/^app_palette_top_free_/)

  return (objects || []).map((object) => {
    const paletteAd = _.get(object, 'advertising')
    const productId = _.get(paletteAd, ['values', 'product'], null)
    const courseId = _.get(paletteAd, ['values', 'course'], null)
    const product = productId && _.get(products, productId)
    const course = courseId && _.get(courses, courseId)
    const howToPlay = _.get(howToPlays, object.id, null)

    if (paletteAd) {
      if (paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT) {
        const thumbnail = _.get(
          paletteAd,
          ['creatives', 0, 'attachment', 'file_url'],
          context.models.config.data.default_thumbnail
        )
        return (
          <ProgramItemLink
            key={`${object.type}-${object.id}`}
            title={paletteAd.name}
            thumbnail={thumbnail}
            href={_.get(paletteAd, ['url'])}
            {...props}
          />
        )
      }
      if (!product && !course) return null
      const linkParams = webApp.utils.getProgramLinkRoutes(
        context,
        null,
        product,
        course
      )
      return (
        <ProgramItemLink
          key={`${object.type}-${object.id}`}
          product={product}
          course={course}
          howToPlay={howToPlay}
          showDelivery
          showInCourse
          {...linkParams}
          {...props}
        />
      )
    }

    const meta = object.meta || null
    if (!meta) return null

    const showRemaining =
      [META_SCHEMA_ID.EPISODE, META_SCHEMA_ID.LIVE].indexOf(
        object.meta.meta_schema_id
      ) > -1
    const linkParams = webApp.utils.getProgramLinkRoutes(
      context,
      meta,
      product,
      course
    )

    if (isAppTopPalette) {
      return (
        <ProgramItemLink
          key={`${object.type}-${object.id}`}
          meta={meta}
          product={product}
          course={course}
          howToPlay={howToPlay}
          showRemaining
          showNew={webApp.utils.showNew(meta)}
          {...linkParams}
          {...props}
        />
      )
    }

    return (
      <ProgramItemLink
        key={`${object.type}-${object.id}`}
        meta={meta}
        product={product}
        course={course}
        howToPlay={howToPlay}
        showRemaining={showRemaining}
        showDelivery
        showDeadLine
        showCoin
        showInCourse
        showNew={webApp.utils.showNew(meta)}
        breakSubTitle
        showDeliveryEndPriorToStart
        {...linkParams}
        {...props}
      />
    )
  })
}

export default memo(Objects)

Objects.propTypes = {
  palette: PropTypes.shape({
    name: PropTypes.string.isRequired,
    schema_id: PropTypes.number,
    objects: PropTypes.array.isRequired
  }).isRequired,
  howToPlays: PropTypes.object,
  products: PropTypes.object,
  courses: PropTypes.object
}

Objects.contextTypes = {
  history: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object,
  models: PropTypes.object
}
