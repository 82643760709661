import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import routes from '../../../../../common/routes'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import { mainColor, keyColor } from './styles'

/** 全英オープン Header */
const Header = ({ seriesId, seasonId, ...props }) => {
  if (!seriesId || !seasonId) return null

  return (
    <StyledHeader
      borderColor={keyColor}
      backgroundColor={mainColor}
      {...props}
    >
      <StyledDiv>
        <StyledLink
          route={routes.program}
          params={{ seriesId, seasonId }}
        >
          <img
            src="/images/exdio/renewal/british/logo.png"
            width="530"
            height="47"
            alt="全英オープン最強王者決定戦 ～歴代優勝者No.1は誰だ？～"
          />
        </StyledLink>
      </StyledDiv>
    </StyledHeader>
  )
}

export default memo(Header)

Header.propTypes = {
  seriesId: PropTypes.string,
  seasonId: PropTypes.string
}

Header.defaultProps = {
  seriesId: '',
  seasonId: ''
}

const StyledHeader = styled.header`
background-color: ${props => props.backgroundColor};
padding: 16px 0;

@media (max-width: 1024px) {
  border-top: 3px solid ${props => props.borderColor};
}
`

const StyledDiv = styled.div`
margin: 0 auto;
width: 960px;

@media (max-width: 1024px) {
  max-width: 530px;
  width: 94%;
}
`

const StyledLink = styled(Link)`
display: inline-block;
transition: opacity .4s ease-in-out;

&:hover {
  opacity: .7;
}
`
