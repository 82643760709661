import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import window from 'global';
import Footer from '../../../../common/components/Footer';
import DFPBanner from '../../../../common/components/DFPBanner';
import PackList from '../details/PackList';
import AboutMedalLink from '../../../../common/components/renewal/AboutMedalLink';
import SidePalette from '../details/SidePalette';
import EpisodeList from '../details/EpisodeList';
import CastInfo from '../details/CastInfo';
import SideTab from '../details/SideTab';
import ProgramTopLink from '../details/ProgramTopLink';
import EpisodePager from '../details/EpisodePager';
import { FAVORITE_TYPE, LOCAL_STORAGE_KEY_PURCHASE, PURCHASE_TYPE } from '../../../../../constants/app';
import routes from '../../../../common/routes';
import AddButtonBlock from '../details/AddButtonBlock';
import webApp from '../../../utils/exdioWebAppUtils';
import HtmlSnippet from '../../HtmlSnippet';

/** パックページ テストテンプレート */
export default class PackTemplateTest extends Component {
  static propTypes = {
    /** パックのID */
    productId: PropTypes.string,
    /** パックの情報 */
    product: PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      schema_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired
    }).isRequired,
    /** パックに含まれる動画情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** パックのシーズン情報 */
    season: PropTypes.shape({
      meta_id: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
      values: PropTypes.object
    }).isRequired,
    /** パック販売情報 */
    products: PropTypes.arrayOf(PropTypes.object),
    /** パックの購入情報 */
    status: PropTypes.shape({
      isPurchseAvailable: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool
    })
  };

  static defaultProps = {
    productId: '',
    episodes: [],
    products: [],
    status: {
      isPurchased: false,
      isNotPurchased: false
    }
  };

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.config = this.context.models.config.data;
    this.state = {};

    this.onClickWatch = this.onClickWatch.bind(this);
  }

  componentDidMount() {}

  /** 視聴ボタン押下 */
  onClickWatch(e) {
    e.preventDefault();

    const { status } = this.props;
    if (!status.isNotPurchased) return;

    const { productId } = this.props;
    const productIdNum = Number(productId);
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_PURCHASE,
      JSON.stringify({
        type: PURCHASE_TYPE.PACK,
        id: productIdNum,
        selected: [productIdNum]
      })
    );
    this.context.history.push(routes.purchase.makePath());
  }

  render() {
    const { product, episodes, season, products, status } = this.props;

    const price = _.get(product, ['active_pricing', 'price']);
    const thumbnailUrl = _.get(product, ['thumbnail_url']) || this.config.default_thumbnail;
    const series = _.get(season, ['values', 'parents_series'], {});

    // キャストはエピソードのキャストを重複排除して表示
    const allCast = episodes
      .flatMap(meta => {
        if (!meta || !meta.values || !meta.values.evis_Actors) return null;
        const castTmp = meta.values.evis_Actors;
        return castTmp.split(/\r?\n|\t| |　/g);
      })
      .filter(v => v);
    const distinctMap = {};
    allCast.forEach(v => {
      distinctMap[v] = v;
    });
    const cast = Object.keys(distinctMap).join(' ');
    const description = _.get(product, ['description'], '').replace(/\r?\n/g, '<br />');
    const discount = webApp.utils.calcPackDiscount(product);
    const otherSeasonIds = _.get(season, ['values', 'other_series']) || [];
    const bannerId1 = _.get(product, ['values', 'banner_1']);
    const bannerId2 = _.get(product, ['values', 'banner_2']);

    let labelStatus = '';
    if (status.isNotPurchased) labelStatus = '未購入';
    if (status.isPurchased) labelStatus = '購入済み';

    return (
      <div className="common-wrapper">
        <DFPBanner position="head" meta_id="" key="banner" />

        <div>テンプレートテスト</div>
        <div className="c-headMeta">
          <div className="c-headMeta-metaBox">
            <div className="c-headMeta-metaBox-art">
              <div className="c-headMeta-metaBox-art-inBox clickable" onClick={this.onClickWatch}>
                {labelStatus && <div className="c-headMeta-metaBox-art-inBox-labelStatus">{labelStatus}</div>}
                <div className="c-headMeta-metaBox-art-inBox-labelBottom">見放題パック</div>
                <div className="c-headMeta-metaBox-art-inBox-artwork">
                  <img src={thumbnailUrl} className="c-headMeta-metaBox-art-inBox-artwork-img" />
                </div>
              </div>

              {/* for PC */}
              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.PRODUCT}
                favoriteId={product.product_id}
                title={product.name}
              />
            </div>

            <div className="c-headMeta-metaBox-info">
              <h2 className="c-headMeta-metaBox-info-title clickable" onClick={this.onClickWatch}>{product.name}</h2>
              <div className="c-headMeta-metaBox-info-price pack">
                {status.isPurchseAvailable ?
                (
                  <span>
                    料金：<span className="coin">{price}</span>
                    <AboutMedalLink />
                  </span>
                )
                :
                "料金：--"
                }
              </div>
              <div className="c-headMeta-metaBox-info-viewingPeriod">
                視聴期間：テレ朝動画で最終回が配信された日から2週間後
              </div>
              <div className="c-headMeta-metaBox-info-titleHeader" dangerouslySetInnerHTML={{ __html: description }} />
              <div className="c-pageBtn playNow">
                <a href="#" className="c-pageBtn-link" onClick={this.onClickWatch}>
                  {status.isPurchseAvailable ?
                    (
                      <span>
                        <span className="c-pageBtn-link-coins">{price}</span>でパック視聴
                        {discount > 0 && (
                          <span className="c-pageBtn-link-discount">
                            <span className="c-pageBtn-link-discount-rate">{discount}%</span>お得
                          </span>
                        )}
                      </span>
                    )
                    :
                    "現在購入いただけません"
                  }
                </a>
              </div>

              {/* for SP */}
              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.PRODUCT}
                favoriteId={product.product_id}
                title={product.name}
              />

              <EpisodePager seriesId={series.id} seasonId={season.meta_id} />
            </div>
          </div>
        </div>

        <div className="c-listMeta">
          <div className="c-listMeta-inBox">
            <div className="c-listMeta-inBox-main">
              <PackList products={products} showMedal />
              {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
              <EpisodeList episodes={episodes} />
              <CastInfo cast={cast} />
            </div>
            <div className="c-listMeta-inBox-sub">
              <div className="common-box-ad-rectangle">
                <DFPBanner position="foot" meta_id="" />
              </div>
              {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
              <SideTab
                currentSeasonId={season.meta_id}
                otherSeasonIds={otherSeasonIds}
                currentSeasonIds={season.meta_id ? [season.meta_id] : []}
              />
              <ProgramTopLink
                seriesId={series.id}
                seasonId={season.meta_id}
                isFree={false}
                disabled={!series.id || !season.meta_id}
              />
              <HtmlSnippet snippetId={this.config.extras.common_banner_snippet_key} />
            </div>
          </div>
        </div>

        <Footer className="mt0" />
      </div>
    );
  }
}
