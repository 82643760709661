import styled from 'styled-components'
import { mediaSp } from '../styles'
import { Link } from '../../../../../../sketch-platform/ui/routing'

export const StyledUl = styled.ul`
  margin: 2.8rem auto 0;
  max-width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media ${mediaSp} {
    display: block;
  }
`

export const StyledLi = styled.li`
  margin-top: 22px;

  &:first-child {
    margin-top: 0;
  }
`

export const StyledP = styled.p`
  font-weight: 400;
  line-height: 1.5;
  text-align: center;

  @media ${mediaSp} {
    font-size: 1.4rem;
  }
`

export const StyledLink = styled(Link)`
  display: block;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`
