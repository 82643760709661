import styled from 'styled-components'
import { pcMinSize } from '../styles'
import { mediaQuery } from '../../../style'
import TwitterArea from './TwitterArea'

export const StyledDiv1 = styled.div`
  padding: 30px 0 60px;
  display: grid;
  grid-template-areas: '. twitter banners .';
  grid-template-columns: auto 600px calc(${pcMinSize} - 640px) auto;
  align-items: center;
  column-gap: 40px;

  ${mediaQuery()} {
    padding: 20px 10px 30px;
    grid-template-areas:
      'twitter'
      'banners';
    grid-template-columns: auto;
    row-gap: 10px;
    column-gap: 0;
  }
`

export const StyledTwitterArea = styled(TwitterArea)`
  grid-area: twitter;
`

export const StyledDiv2 = styled.div`
  margin: 0 auto;
  width: ${pcMinSize};
  display: flex;
  align-items: flex-start;
  justify-content: center;

  ${mediaQuery()} {
    width: auto;
    display: block;
  }
`

export const StyledDiv3 = styled.div`
  grid-area: banners;
  padding-top: 60px;

  ${mediaQuery()} {
    padding-top: 0;
  }
`
