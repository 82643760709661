import styled, { createGlobalStyle } from 'styled-components'
import { mediaQuery, pxToVw } from '../../../../exdio/components/style'

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Noto Serif JP', serif;
  }
`

export const StyledDivInner = styled.div`
  margin: 0 auto;
  max-width: 1080px;
  width: 100%;

  ${mediaQuery()} {
    width: 94%;
    overflow: hidden;
  }
`

/**
 * ドキュメントで独自スタイルを持っているためそれを打ち消すスタイル
 * コンポーネントには関係ない
 */
export const MdxStyle1 = styled.div`
  && {
    h3 {
      color: #000 !important;
    }
  }
`

export const MdxStyle2 = styled.div`
  && {
    h3 {
      color: #fff !important;
    }
  }
`
