import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PURCHASE_CONTENTS } from '../../../../../constants/app';

/** 購入ページ:ステップ */
export default function Steps(props) {
  const { current, noSteps, showTitle, className, classNameTitle, children } = props;
  return (
    <div className={`c-login-flowwrapper ${className}`}>
      {!noSteps && (
        <div className="c-login-stepwrapper">
          <ul className="c-login-steparea">
            {Object.values(PURCHASE_CONTENTS)
              .filter(content => content.label)
              .map(content => {
                const classNameCurrent = classnames('login-flow', { visit: current === content });
                return (
                  <li
                    key={content.id}
                    className={classNameCurrent}
                    dangerouslySetInnerHTML={{ __html: content.label }}
                  />
                );
              })}
          </ul>
        </div>
      )}
      {current.title && showTitle && (
        <h2 className={`login-ttl provisional-head-ttl flow-title ${classNameTitle}`}>{current.title}</h2>
      )}
      {children}
    </div>
  );
}

Steps.propTypes = {
  current: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  // 上部購入ステップを表示するか
  noSteps: PropTypes.bool,
  showTitle: PropTypes.bool,
  className: PropTypes.string,
  classNameTitle: PropTypes.string,
  children: PropTypes.any
};

Steps.defaultProps = {
  noSteps: false,
  showTitle: true,
  className: '',
  classNameTitle: '',
  children: null
};
