import React from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../common/routes'

/* style */
import { StyledDiv, StyledP, StyledLink } from './style'

const Breadcrumbs = ({ items = [] }) => {
  const [HarborTop, episodeTitle] = items

  const breadTail = () => {
    if (!episodeTitle) return <span>港時間</span>
    return (
      <>
        <StyledP>
          <StyledLink route={routes.program} params={HarborTop}>
            港時間
          </StyledLink>
        </StyledP>
        <span>{episodeTitle}</span>
      </>
    )
  }

  return (
    <StyledDiv>
      <StyledP>
        <StyledLink route={routes.home}>TOP</StyledLink>
      </StyledP>
      <StyledP>
        <StyledLink route={routes.genre} params={{ genre: 'hobby' }}>
          スポーツ・趣味
        </StyledLink>
      </StyledP>
      {breadTail()}
    </StyledDiv>
  )
}

export default Breadcrumbs

Breadcrumbs.propTypes = {
  /** パンくずの要素　現在地の動画情報 */
  items: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
}

Breadcrumbs.defaultProps = {
  items: []
}
