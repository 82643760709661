import React from 'react'
import PropTypes from 'prop-types'

/** style */
import styled from 'styled-components'
import { mediaSp } from './styles'

/** SelectionItem Component */
const SelectionItem = ({ title = '', thumb = '', alt = '' }) => {
  return (
    <>
      <StyledImg1 src={thumb} width="270" height="216" alt="" loading="lazy" />
      <StyledImg2
        src={title}
        width="270"
        height="50"
        alt={alt.replace('<br>', '')}
        loading="lazy"
      />
    </>
  )
}

export default SelectionItem

SelectionItem.propTypes = {
  /** タイトル画像パス */
  title: PropTypes.string,
  /** サムネイルパス */
  thumb: PropTypes.string,
  /** 画像alt */
  alt: PropTypes.string
}

const StyledImg1 = styled.img`
  margin: 0 auto;
  max-width: 188px;

  @media ${mediaSp} {
    max-width: 100%;
  }
`

const StyledImg2 = styled.img`
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 8px;
`
