import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../common/routes'
import {
  StyledButton3,
  StyledButton1,
  StyledButton2,
  StyledDiv1,
  StyledDiv2,
  StyledH1,
  StyledImg1,
  StyledLink,
  StyledNav5,
  StyledNotice,
  StyledUl
} from './style'

/** ガリベン大学 メインビュー */
const Mv = (
  { navItems = [], status = {}, isLoggedIn = false, point = 0, articleId = 0 },
  context
) => {
  const [notice, setNotice] = useState()

  useEffect(() => {
    const model = context.falcorModel.batch(100)
    const path = ['articles', 'popup']

    return model.fetch([path]).then((result) => {
      const notices = _.get(result, ['json', ...path]) || []
      const notice = notices.filter((notice) => notice.article_id === articleId)
      if (notice.length > 0) {
        setNotice(notice[0])
      }
    })
  }, [])

  const noticeCharacterLength = 10 // お知らせ アニメーション開始文字数

  return (
    <StyledDiv1>
      <StyledDiv2>
        <StyledLink route={routes.home}>
          <StyledImg1
            src="/images/exdio/renewal/logo_tv_asahi_douga.png"
            alt="テレ朝動画"
            width="150"
            height="30"
            loading="lazy"
          />
        </StyledLink>
        <StyledH1>
          <img
            src="/images/exdio/renewal/gariben_daigaku/logo/logo.webp"
            alt="私立ガリベン大学"
            width="248"
            height="204"
            loading="lazy"
          />
        </StyledH1>
        {!status.isPurchased && (
          <React.Fragment>
            <StyledButton3
              href="https://www.tv-asahi.co.jp/douga_mv/purchase/gariben_daigaku/"
              target="_blank"
              html="生徒になる<span>（有料会員）</span>"
            />
            <StyledUl>
              <li>
                <StyledButton1
                  href="https://www.tv-asahi.co.jp/douga_mv/gariben_daigaku/lp/"
                  name="私立ガリベン大学とは？"
                  icon
                />
              </li>
              {!isLoggedIn && (
                <li>
                  <StyledButton2
                    route={routes.login}
                    query={{ redirect: '/plan/gariben_daigaku' }}
                    name="ログイン"
                    icon
                  />
                </li>
              )}
            </StyledUl>
          </React.Fragment>
        )}

        {notice && (
          <StyledNotice
            notice={notice}
            noticeCharacterLength={noticeCharacterLength}
            status={status}
          />
        )}
        <StyledNav5
          navItems={navItems}
          status={status}
          isLoggedIn={isLoggedIn}
          point={point}
        />
      </StyledDiv2>
    </StyledDiv1>
  )
}

export default Mv

Mv.propTypes = {
  /** Nav5の表示要素
   * @param {string} name - 表示するリンク名
   * @param {string} href - 遷移先URL
   * @param {string} src - ボタンの背景画像
   */
  navItems: PropTypes.arrayOf(PropTypes.object),
  /** ボタンリストの描画、Nav5の表示に使用
   * @param {boolean} isPurchseAvailable - 購入可能か否か
   * @param {boolean} isPurchased - 購入済みか否か
   * @param {boolean} isNotPurchased - 購入済みでないか否か
   */
  status: PropTypes.object,
  /** ログインボタン、Nav5の表示コントロール */
  isLoggedIn: PropTypes.bool,
  /** Nav5の単にポイントの描画に使用 */
  point: PropTypes.number,
  /** お知らせの表示に必要 */
  articleId: PropTypes.number
}

Mv.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func
}
