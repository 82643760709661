/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import axios from 'axios'
import cn from 'classnames'
import styled, { createGlobalStyle } from 'styled-components'
import {
  META_SCHEMA_ID,
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE,
  DIO_PLAYER_DEVICE,
  LOCAL_STORAGE_KEY_SHINCHAN_PAGER,
  LOCAL_STORAGE_KEY_SHINCHAN_SORT,
  LOCAL_STORAGE_KEY_SHINCHAN_FILTER
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  checkAvailableStatus,
  getThumbnailUrl,
  play,
  purchasePlan
} from '../EpisodeDefault/util'

/** hooks */
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/** components */
import Header from '../../../../common/components/Header'
import EpisodeFooter from '../../../../common/components/EpisodeFooter'
import RenewalPlayer from '../../player/RenewalPlayerPcModeOnly'
import RenewalPlayerInline from '../../player/RenewalPlayerInline'
import Notice from '../EpisodeDefault/Notice'
import CommonVideoArea from '../EpisodeDefault/CommonVideoArea'
import Meta from '../EpisodeDefault/Meta'
import Actions from '../EpisodeDefault/Actions'
import HtmlSnippet from '../../HtmlSnippet'
import FilterSort from '../../../../common/components/FilterSort'
import ListMetaSub from '../EpisodeDefault/ListMetaSub'
import HeaderNewsComponent from '../HeaderNewsComponent'
import SearchBoxYearWrapper from '../../../../common/components/FilterSort/SearchBoxYearWrapper'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'
import Chat from '../chat'

/** style */
import { mediaQuery } from '../../style'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 単話ページ */
const EpisodeShinchan = (
  {
    seriesId = '',
    seasonId = '',
    episodeId = '',
    meta = {},
    programMeta = {},
    howToPlay = {},
    episodes = [],
    product = {},
    course = {},
    status = {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    sendPlayLog = () => {},
    loaded = true
  },
  context
) => {
  if (!size(meta) || !size(programMeta)) return null

  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isPc = webApp.utils.getDevice() === DIO_PLAYER_DEVICE.PC
  const isShinchan =
    Number(seasonId) === config.svod.shinchan.shinchan.season_id

  const [progress, setProgress] = useState(webApp.utils.progress(config, meta))
  const [isArchived, setIsArchived] = useState(false)
  const [showThumb, setShowThumb] = useState(true)

  const handlePostMessage = useRef(() => {})
  const intervalId = useRef(null)
  const playerRef = useRef(null)
  const setPlayerRef = (e) => {
    playerRef.current = e
  }

  /**
   * ページャー、ソート、フィルタをlocalStorageから取得
   * クレヨンしんちゃんシーズンのときだけ
   */
  const dataFromLocalStorage = (() => {
    if (!isShinchan) return {}

    // ローカルストレージの値を取得
    const pageNum =
      parseInt(
        window.localStorage.getItem(LOCAL_STORAGE_KEY_SHINCHAN_PAGER),
        10
      ) || 1
    const sortedBy =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_SHINCHAN_SORT) ||
      'avails_release_history_original_older'
    const filteredBy =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_SHINCHAN_FILTER) || ''
    return { pageNum, sortedBy, filteredBy }
  })()

  const setVh = () => {
    const vh = window.innerHeight * 0.01
    document.body.style.setProperty('--vh', `${vh}px`)
  }

  const searchParams = useSearchParams(
    {
      childEpisodeIds: get(programMeta, ['values', 'child_episode_ids'], []),
      seasonIds: [Number(seasonId)],
      ...dataFromLocalStorage,
      pagerOptions: {
        episodesPerPages: 60,
        range: 2,
        showBottom: true
      }
    },
    {
      afterSearch: ({
        pageNum = 1,
        sortedBy = 'avails_release_history_original_older',
        filteredBy = ''
      }) => {
        if (!isShinchan) return
        const ls = window.localStorage
        ls.setItem(LOCAL_STORAGE_KEY_SHINCHAN_SORT, sortedBy)
        ls.setItem(LOCAL_STORAGE_KEY_SHINCHAN_FILTER, filteredBy)
        ls.setItem(LOCAL_STORAGE_KEY_SHINCHAN_PAGER, pageNum)
      }
    }
  )

  const initIsArchived = () => {
    if (!meta) return

    const isLive =
      meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
      meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
    const baseURL = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 'https://st-cc-api.tvasahi.jp/api/v1/chats'
      : 'https://cc-api.tv-asahi.co.jp/api/v1/chats'
    const frontDisplayConfigStr = isLive
      ? 'live_stvod_front_display_config'
      : 'episode_front_display_config'
    const chatHash = get(
      meta,
      ['values', frontDisplayConfigStr, 'chat_hash'],
      ''
    )
    if (chatHash) {
      axios.get(`${baseURL}/${chatHash}/information`).then((res) => {
        const roomStatus = get(res.data, ['status'])
        setIsArchived(roomStatus === 2)
        /**
         * READY: 0
         * LIVE: 1
         * ARCHIVED: 2
         */
      })
    }
  }

  const onClickPlay = (e, asSvod = false, skipRedirectLogin = false) => {
    if (e) e.preventDefault()
    setShowThumb(false)

    // SVOD配信パターン
    const svodDeliveryPattern = get(meta, ['values', 'svod_delivery_pattern'])

    // しんちゃんの場合はPLAYかPURCHASE_PLANの状態しか返ってこない。
    const action = checkAvailableStatus({ status, product }, context, asSvod)

    // 購入済みかSVOD配信パターンが無料視聴可能の場合、再生
    if (action === 'PLAY' || svodDeliveryPattern === '1') {
      play(playerRef.current, sendPlayLog)
      if (isArchived) {
        clearInterval(intervalId.current)
        intervalId.current = setInterval(() => {
          const DioPlayer = document.getElementById('DIOplayer')
          const videoElm = DioPlayer ? DioPlayer.querySelector('video') : null
          const seekTime = videoElm ? Math.floor(videoElm.currentTime) : 0
          handlePostMessage.current(seekTime)
        }, 1000)
      }
      return
    }

    // 未購入の場合、ログイン状態を確認
    if (action === 'PURCHASE_PLAN') {
      /**
       * 未ログイン状態の場合はログイン画面へ遷移する
       * SVOD配信パターンが無料視聴可能のときはスルーする
       */
      if (!skipRedirectLogin && !isLoggedIn) {
        context.history.push(
          routes.login.makePath(null, {
            redirect: context.routeHandler.path
          })
        )
        return
      }

      purchasePlan({ course }, context, 'shinchan')
    }
  }

  const onClosePlayer = () => {
    setProgress(webApp.utils.progress(config, meta))
  }

  /** 選択中の放送年を更新 */
  const updateSelectedYear = (selectedYear) => {
    searchParams.set({
      selectedYear
    })

    // sp時のみ年次選択時スクロール
    if (window.matchMedia('(max-width: 1024px)').matches) {
      const divElm = document.getElementById('episodes-list')
      const clientRect = divElm.getBoundingClientRect()
      const rectY = clientRect.top + window.pageYOffset - 56
      window.scroll({ top: rectY, behavior: 'smooth' })
    }
  }

  const setPostMessage = (postMessage) => {
    handlePostMessage.current = postMessage
  }

  const onOpenChat = () => {
    const header = document.getElementById('mp-header')
    const videoWrapper = document.querySelector('.c-video_wrapper')
    const iframeWrapper = document.querySelector('.c-iframe2__wrapper')

    /** 背景スクロールの禁止 */
    document.body.style.overflow = 'hidden'

    /** ヘッダー非表示 */
    const headerHeight = header && header.offsetHeight
    header && (header.style.marginTop = `-${headerHeight}px`)
    header && (header.style.top = 'auto')
    videoWrapper && (videoWrapper.style.top = 0)
    iframeWrapper && (iframeWrapper.style.top = 'calc(100vw * 9 / 16)')
  }

  const onCloseChat = () => {
    const header = document.getElementById('mp-header')
    const videoWrapper = document.querySelector('.c-video_wrapper')
    const iframeWrapper = document.querySelector('.c-iframe2__wrapper')

    /** 背景スクロールの禁止 解除 */
    document.body.style.overflow = null

    /** ヘッダー非表示 解除 */
    header && (header.style.marginTop = null)
    header && (header.style.top = null)
    videoWrapper && (videoWrapper.style.top = null)
    videoWrapper && (videoWrapper.style.position = null)
    videoWrapper && (videoWrapper.style.zIndex = null)
    iframeWrapper && (iframeWrapper.style.top = null)
  }

  useEffect(() => {
    initIsArchived()
    setVh()

    const chatHash = get(
      meta,
      ['values', 'episode_front_display_config', 'chat_hash'],
      ''
    )
    if (chatHash) {
      document.body.classList.add('has-chat')
    }

    return () => {
      document.body.style.overflow = null
      document.body.classList.remove('has-chat')
      clearInterval(intervalId.current)
    }
  }, [])

  useEffect(() => {
    const isAutoPlay = context.routeHandler.query.auto === 't'
    // SPもブラウザで再生するためコメントアウト
    if (loaded && status && /* this.isPc &&  */ isAutoPlay) {
      const isFree = EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode)
      const isAuthFree =
        EPISODE_DISPLAY_MODE.SVOD_AUTH_FREE === status.displayMode &&
        webApp.utils.isLoggedIn(context)
      const withValidPreviewToken = get(context, [
        'models',
        'state',
        'data',
        'withValidPreviewToken'
      ])
      if (isFree || isAuthFree || status.isPurchased || withValidPreviewToken) {
        onClickPlay()
      }
    }
  }, [loaded, status])

  const metaName = (() => {
    const [title, subTitle] = webApp.utils.titles(meta)
    return `${title} ${subTitle}`
  })()

  // const episodeBannerId1 = get(meta, ['values', 'banner_1'])
  const episodeBannerId2 = get(meta, ['values', 'banner_2'])
  const programBannerId1 = get(programMeta, ['values', 'banner_1'])
  const programBannerId2 = get(programMeta, ['values', 'banner_2'])
  const isLive =
    meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
    meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
  let isClipVod = false

  // LIVE配信 主/副切替ID
  let metaIdForPlayer
  if (isLive) {
    if (meta.values.clipping_select_media_type === 'vod') {
      metaIdForPlayer = meta.values.clipping_vod_ref_id
      isClipVod = true
    }
    if (!metaIdForPlayer) metaIdForPlayer = meta.values.target_ref_id
  }
  if (!metaIdForPlayer)
    metaIdForPlayer = `${config.videocloud.reference_id_prefix || ''}${
      meta.meta_id
    }`
  const playerSettings = webApp.utils.getPlayerSettings(
    config,
    meta,
    status.displayMode
  )

  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  const isBefore = Boolean(
    !withValidPreviewToken && moment(prevTime).isBefore(meta.delivery_start_at)
  )
  const isAfter = Boolean(
    !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
  )
  const thumbnailUrl = getThumbnailUrl(
    { meta, programMeta },
    context,
    config,
    previewUrlList,
    cookieRubocopPrefix
  )
  const inViewTerm = !(isBefore || isAfter)
  const nextPrevText = (() => {
    if (inViewTerm || isLive) return null
    if (isBefore) return 'まもなく配信予定'
    return '次回の配信もお楽しみに！'
  })()
  const isPlayerRenderable =
    metaIdForPlayer &&
    status.displayMode &&
    (inViewTerm || withValidPreviewToken)

  /** 通常orライブでキーが変わるため出し分け */
  const frontDisplayConfigStr = isLive
    ? 'live_stvod_front_display_config'
    : 'episode_front_display_config'
  const chatHash = get(meta, ['values', frontDisplayConfigStr, 'chat_hash'])
  const hasChat = Boolean(chatHash)

  const playerProps = {
    ref: setPlayerRef,
    meta_id: metaIdForPlayer,
    thumbnail_url: meta.thumbnail_url,
    subtitle: !!meta.values.subtitle,
    enqueteEnabled: status.isFree,
    material_id: get(product, ['ref_id']) || '',
    license_id: get(course, ['ref_id']) || '',
    display_mode: status.displayMode,
    onClose: onClosePlayer,
    isLive,
    isClipVod,
    playInline: hasChat,
    ...playerSettings
  }

  return (
    <>
      <Header />
      <div className={cn('common-wrapper', { 'has-chat': hasChat })}>
        {(isPc || !hasChat) && <HeaderNewsComponent />}
        <SpSubNavigation spOff />
        {isPc && <Notice programMeta={programMeta} />}
        {hasChat && <StyledVideoSpacer />}

        <StyledContainer>
          <StyledWrapper>
            <StyledInner>
              <StyledVideoWrapper
                className={cn('c-video_wrapper', { 'is-open': !showThumb })}
              >
                {isPlayerRenderable &&
                  (hasChat ? (
                    <RenewalPlayerInline {...playerProps} />
                  ) : (
                    <RenewalPlayer {...playerProps} />
                  ))}

                <CommonVideoArea
                  progress={progress}
                  onClickPlay={onClickPlay}
                  isLive={isLive}
                  inViewTerm={inViewTerm}
                  metaName={metaName}
                  thumbnailUrl={thumbnailUrl}
                  nextPrevText={nextPrevText}
                  meta={meta}
                  status={status}
                  product={product}
                  // permitClickPlayButton={permitClickPlayButton}
                  episodeId={episodeId}
                  seriesId={seriesId}
                  seasonId={seasonId}
                  hidePlayButton
                />
              </StyledVideoWrapper>

              {!isPc && hasChat && <HeaderNewsComponent />}
              {!isPc && <Notice programMeta={programMeta} />}

              <div className="c-storyMeta">
                <div className="c-storyMeta-inBox">
                  <Meta
                    isLive={isLive}
                    metaName={metaName}
                    meta={meta}
                    product={product}
                    course={course}
                    status={status}
                  />
                  <Actions
                    onClickPlay={onClickPlay}
                    inViewTerm={inViewTerm}
                    metaName={metaName}
                    seriesId={seriesId}
                    seasonId={seasonId}
                    meta={meta}
                    product={product}
                    status={status}
                    course={course}
                    renderLinkLoginOrMyPage
                  />
                </div>
              </div>
            </StyledInner>
            {hasChat && (
              <Chat
                hash={chatHash}
                setPostMessage={setPostMessage}
                onOpen={onOpenChat}
                onClose={onCloseChat}
              />
            )}
          </StyledWrapper>

          <div className="c-listMeta">
            <div className="c-listMeta-inBox">
              <div className="c-listMeta-inBox-main">
                {programBannerId1 && (
                  <HtmlSnippet snippetId={programBannerId1} />
                )}
                {Object.keys(programMeta).length > 0 && (
                  <section>
                    <StyledH3>単話</StyledH3>
                    {isShinchan && (
                      <StyledSearchBoxYearWrapper
                        selectedYear={searchParams.state.selectedYear}
                        setSelectedYear={updateSelectedYear}
                      />
                    )}
                    <StyledFilterSort
                      searchParams={searchParams}
                      episodeListItemProps={{
                        showNew: true,
                        showChecked: true,
                        showCoin: true,
                        showCaption: true,
                        onlySubTitle: true
                      }}
                    />
                  </section>
                )}
              </div>
              <ListMetaSub
                loaded={loaded}
                episodeBannerId={episodeBannerId2}
                programBannerId={programBannerId2}
                episodes={episodes}
                course={course}
                seriesId={seriesId}
                seasonId={seasonId}
                status={status}
                meta={meta}
              />
            </div>
          </div>

          <EpisodeFooter
            className="mt0"
            copyrights={get(meta, ['values', 'evis_Copyright'])}
            courseId={
              howToPlay && howToPlay.courses
                ? get(howToPlay, ['courses', 0, 'course_id'])
                : null
            }
          />
        </StyledContainer>
      </div>
      <GlobalStyle />
    </>
  )
}

export default EpisodeShinchan

EpisodeShinchan.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のエピソードID */
  episodeId: PropTypes.string,

  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** シーズンのメタ情報 */
  programMeta: PropTypes.object,
  /** 動画の視聴権利関連情報 */
  howToPlay: PropTypes.object,
  /** 動画の価格、配信期間情報など */
  product: PropTypes.shape({
    product_id: PropTypes.number,
    name: PropTypes.string,
    original_price: PropTypes.number,
    active_pricing: PropTypes.shape({
      price: PropTypes.number,
      unit: PropTypes.string
    })
  }),

  // 通常単話
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  // 月額見放題
  /** 動画のコース情報 */
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),

  /** 動画の視聴ステータス情報 */
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  /** 動画再生時のログ送信 */
  sendPlayLog: PropTypes.func,
  /** データの取得が終わっているか */
  loaded: PropTypes.bool
}

EpisodeShinchan.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 600;

  ${mediaQuery()} {
    padding: 0 15px;
  }
`

const StyledSearchBoxYearWrapper = styled(SearchBoxYearWrapper)`
  margin-top: 20px;
`

const StyledFilterSort = styled(FilterSort)`
  margin-top: 20px;
`

const StyledWrapper = styled.div``

const StyledInner = styled.div``

const StyledVideoWrapper = styled.div``

const StyledVideoSpacer = styled.div`
  display: none;
`

const StyledContainer = styled.div``

const GlobalStyle = createGlobalStyle`
  body {
    &.has-chat {
      ${mediaQuery()} {
        height: calc(var(--vh, 1vh) * 100);
        position: static;
        overflow: hidden;
      }

      ${mediaQuery(320)} {
        zoom: normal;
      }

      .c-pageTop {
        ${mediaQuery()} {
          display: none;
        }
      }

      ${StyledVideoSpacer} {
        ${mediaQuery()} {
          height: calc(100vw * 9 / 16);
          display: block;
        }
      }

      ${StyledContainer} {
        ${mediaQuery()} {
          height: calc((var(--vh, 1vh) * 100) - (56px + (100vw * 9 / 16) + 40px));
          overflow-y: scroll;
        }
      }
    }
  }

  .common-wrapper {
    &.has-chat {
      ${mediaQuery()} {
        height: calc(100vh - 56px);
        overflow-y: scroll;
      }

      ${StyledWrapper} {
        margin: 30px auto 40px;
        max-width: 1280px;
        display: flex;

        ${mediaQuery()} {
          margin-top: 0;
        }
      }

      ${StyledInner} {
        flex: 1 0;
      }

      ${StyledVideoWrapper} {
        margin: 0 auto;
        max-width: 1080px;
        width: 100%;
        position: relative;

        ${mediaQuery()} {
          position: fixed;
          top: 56px;
          right: 0;
          left: 0;
          z-index: 2;
          transition: top 0.4s ease-in-out;
        }

        &::before {
          padding-top: percentage((9 / 16));
          display: block;
          content: '';
        }

        .player-modal-wrapper {
          width: auto;
          height: auto;
          background: none;
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          z-index: 1;

          .watch {
            width: 100%;
          }
        }

        .common-video-area {
          margin-top: 0;

          & > a > .seekbar {
            bottom: 3px;
          }
        }

        &.is-open {
          .player-modal-wrapper {
            z-index: 2;
          }

          .common-video-area {
            z-index: 0;
          }
        }
      }

      .c-storyMeta {
        ${mediaQuery()} {
          padding: 0 15px;
        }
      }

      .c-storyMeta-inBox {
        display: block;
      }

      .c-storyMeta-inBox-meta {
        padding-right: 0;
        border-right-width: 0;
      }

      .c-storyMeta-inBox-actions {
        margin-top: 30px;
        padding: 0;
        max-width: none;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;

        ${mediaQuery()} {
          flex-direction: column;
        }
      }

      .c-pageBtn {
        flex-basis: calc((100% - 30px) / 2);

        ${mediaQuery()} {
          flex-basis: 100%;
        }

        &.mt10 {
          margin-top: 0;
          margin-left: 30px;

          ${mediaQuery()} {
            margin-top: 10px;
            margin-left: 0;
          }
        }

        &-link {
          height: 52px;
        }
      }

      .c-addBtns {
        flex-basis: 100%;
      }

      .c-storyPager {
        width: 460px;

        ${mediaQuery()} {
          margin: 20px auto;
          width: 100%;
        }
      }

      .c-iframe2__wrapper {
        &.is-active {
          z-index: 2;
        }
      }
    }
  }

  .c-listMeta-inBox-main {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
`
