/* style */
import styled from 'styled-components'

export const StyledA = styled.a`
  padding: ${({ isSmp }) => (isSmp ? '20px 0' : '35px 0')};
  width: 100%;
  display: inline-block;
  border: 3px solid #fff;
  border-radius: ${({ isSmp }) => (isSmp ? '25px' : '40px')};
  background: linear-gradient(
    -90deg,
    rgb(255, 255, 112) 0%,
    rgb(135, 245, 131) 65%,
    rgb(15, 235, 149) 100%
  );
  text-align: center;
  font-weight: bold;
  font-size: ${({ isSmp }) => (isSmp ? '1.8rem' : '2.2rem')};
  letter-spacing: ${({ isSmp }) => (isSmp ? '3px' : '6px')};
  transition: background 0.3s, border 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
    border: 3px solid rgba(255, 255, 255, 0);
  }
`

export default StyledA
