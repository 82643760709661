import styled from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import Footer from '../../../../../common/components/Footer'
import { breakPoints } from '../style'

export const StyledDiv1 = styled.div`
  padding-top: 30px;
  background: #010505
    url('https://wws.tv-asahi.co.jp/douga_mv/sailgp/images/bg_01.png') no-repeat
    top/100% auto;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo,
    メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;

  ${mediaQuery(breakPoints.sp)} {
    padding-top: 0;
  }
`

export const StyledMain = styled.main`
  margin: 0 auto;
  overflow: hidden;
`

// StyledH1
export const StyledH1 = styled.h1`
  margin: 0 auto;
  width: 1280px;

  ${mediaQuery(breakPoints.tab)} {
    width: 960px;
  }

  ${mediaQuery(breakPoints.sp)} {
    padding: 15px 0;
    width: auto;
  }
`

export const StyledLink1 = styled(Link)`
  display: inline-block;
`

export const StyledImg1 = styled.img`
  width: 180px;

  ${mediaQuery(breakPoints.sp)} {
    width: 120px;
  }
`

// sns area
export const StyledDiv2 = styled.div`
  margin: 0 auto 32px;
  width: 1280px;
  position: relative;

  ${mediaQuery(breakPoints.tab)} {
    width: 960px;
  }
`

// footer前画像
export const StyledImg2 = styled.img`
  width: 100%;
  margin-bottom: -5px;

  ${mediaQuery(breakPoints.sp)} {
    margin-bottom: -3px;
  }
`

// Footer
export const StyledFooter = styled(Footer)`
  margin-top: 0;
`

//** sp Only
export const StyledDiv3 = styled.div`
  ${mediaQuery(breakPoints.tab)} {
    margin: 0 8px;
  }
`
