/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledButton } from './styles'

/* images */
import Img from './images/btn9_img.png'
import ImgRev from './images/btn9_img_reverse.png'

/** index Component */
const Button1 = ({ direction = 'back', seconds = 15, ...props }) => {
  /** 前後判別 */
  const isBack = useCallback(() => {
    return direction === 'back'
  }, [direction])

  return (
    <StyledButton type="button" {...props}>
      <img
        src={isBack() ? ImgRev : Img}
        width="66"
        height="66"
        alt={isBack() ? `-${seconds}` : seconds}
      />
    </StyledButton>
  )
}

export default Button1

Button1.propTypes = {
  /** 戻る or 進む */
  direction: PropTypes.oneOf(['back', 'forward']),
  /** 戻す/進む 秒数 */
  seconds: PropTypes.number
}
