import styled from 'styled-components'
import { mediaQuery } from '../../../style'

const Heading1 = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.25;

  ${mediaQuery()} {
    font-size: 1.6rem;
  }
`

export default Heading1
