import React from 'react'
import styled from 'styled-components'

/** そだてれび：海外視聴用 PC 単話ページ動画詳細 あらすじ */
const InfoCaption = ({ children }) => {
  return <StyledP>{children}</StyledP>
}

export default InfoCaption

const StyledP = styled.p`
  font-size: 13px;
  line-height: 1.5;
  color: #515151;
`
