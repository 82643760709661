import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import { get, size } from 'lodash'
import webApp from '../../../../utils/exdioWebAppUtils'
import routes from '../../../../../common/routes'
import confetti from '../confetti'
import List from './List'
import {
  StyledButton3,
  StyledDiv1,
  StyledDiv2,
  StyledHeading1,
  StyledSection
} from './style'

/** ガリベン大学 Section4 */
const Section4 = ({
  nextLive = {},
  archive = {},
  showMoreButton = false,
  ...props
}) => {
  const [oldWindowWidth, setOldWindowWidth] = useState(0)

  useEffect(() => {
    // Androidで動作が重くなるため、紙吹雪はPCのみ実行
    if (
      !webApp.utils.isSp() &&
      window.matchMedia('(min-width: 1025px)').matches
    ) {
      confetti()
    }
    window.addEventListener('resize', initConfetti)

    return () => {
      window.removeEventListener('resize', initConfetti)
    }
  }, [nextLive])

  const initConfetti = () => {
    const windowWidth = $(window).width()
    const diff = 20
    if (Math.abs(windowWidth - oldWindowWidth) < diff) return

    // window幅を更新
    setOldWindowWidth(windowWidth)

    $('#confetti canvas').remove()
    if (
      !webApp.utils.isSp() &&
      window.matchMedia('(min-width: 1025px)').matches
    ) {
      confetti()
    }
  }

  return (
    <StyledSection id="top4" {...props}>
      <StyledHeading1
        src="/images/exdio/renewal/gariben_daigaku/top/sec4_hdg.webp"
        alt="ライブ授業 SCHOOL ASSEMBLY"
        width="470"
        height="130"
      />
      <StyledDiv1 id="confetti">
        <StyledDiv2>
          <List nextLive={nextLive} archive={archive} />
          {showMoreButton && size(archive) > 0 && (
            <StyledButton3
              route={routes.program}
              params={{
                seriesId: get(archive, ['values', 'parents_series', 'id']),
                seasonId: get(archive, ['values', 'parents_season', 'id'])
              }}
              name="もっと見る"
            />
          )}
        </StyledDiv2>
      </StyledDiv1>
    </StyledSection>
  )
}

export default Section4

Section4.propTypes = {
  /** 次回配信動画情報をAPIを叩いて取得するために使用 */
  nextLive: PropTypes.object,
  /** 前回配信の情報 */
  archive: PropTypes.object,
  /** もっと見るの表示制御 */
  showMoreButton: PropTypes.bool
}
