import React from 'react'
import PropTypes from 'prop-types'
import window from 'global'

/* style */
import { StyledListItem, StyledButton } from './style'

/** シェアボタン ベースコンポーネント */
const Button = ({
  isApp = false,
  linkUrl = '',
  windowOpenOption = [],
  src = '',
  alt = '',
  ...props
}) => {
  const onClickHandler = () => {
    // アプリの場合は画面内遷移
    if (isApp) {
      window.location.href = linkUrl
    } else {
      window.open(linkUrl, ...windowOpenOption)
    }
  }

  return (
    <StyledListItem {...props}>
      <StyledButton type="button" onClick={onClickHandler}>
        <img src={src} width="35" height="35" alt={alt} />
      </StyledButton>
    </StyledListItem>
  )
}

export default Button

Button.prototype = {
  isApp: PropTypes.bool,
  linkUrl: PropTypes.string,
  windowOpenOption: PropTypes.arrayOf(PropTypes.string),
  src: PropTypes.string,
  alt: PropTypes.string
}
