import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ProgramItemLink from './ProgramItemLink';
import webApp from '../../../exdio/utils/exdioWebAppUtils';
import { META_SCHEMA_ID } from '../../../../constants/app';
import * as DOMUtils from '../../../utils/DOMUtils';
import * as browserEvents from '../../../utils/browserEvents';

/** リスト表示切り替えコンポーネント */
export default class SwitchableList extends Component {
  static propTypes = {
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        meta_schema_id: PropTypes.number.isRequired,
        thumbnail_url: PropTypes.string,
        values: PropTypes.object.isRequired,
        name: PropTypes.string,
        duration: PropTypes.number,
        delivery_start_at: PropTypes.string,
        delivery_end_at: PropTypes.string
      })
    ),
    rootMetas: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    placeholder: PropTypes.string,
    showNew: PropTypes.bool,
    showChecked: PropTypes.bool,
    showDelivery: PropTypes.bool,
    onlySubTitle: PropTypes.bool
  };

  static defaultProps = {
    episodes: [],
    rootMetas: null,
    className: '',
    placeholder: '',
    showNew: false,
    showChecked: false,
    showDelivery: false,
    onlySubTitle: false
  };

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func,
    routeHandler: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.state = {
      filteredEpisodes: props.episodes,
      isList: false,
      searchWord: '',
      modeDsearch: !props.episodes, // episodesにnullが指定されたらrootMetasを利用してdsearch検索を行う
      onlySubTitle: props.onlySubTitle
    };

    this.isLoading = false;

    this.onChangeSearchWord = this.onChangeSearchWord.bind(this);
    this.onClearSearchWord = this.onClearSearchWord.bind(this);
    this.onKeyDownSearchWord = this.onKeyDownSearchWord.bind(this);
    this.onTouchmove = this.onTouchmove.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.setListRef = e => {
      this.listRef = e;
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.state.modeDsearch) {
      this.search();
    }

    browserEvents.addEventListener('touchmove', this.onTouchmove);
    browserEvents.addEventListener('scroll', this.onScroll);

    // DBG_CODE
    // window.setResumeInfo = setResumeInfo;
    // window.getResumeInfo = getResumeInfo;
    // window.removeAllResumeInfo = removeAllResumeInfo;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rootMetas !== this.props.rootMetas) {
      this.search();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    browserEvents.removeEventListener('touchmove', this.onTouchmove);
    browserEvents.removeEventListener('scroll', this.onScroll);
  }

  /** 全角->半角変換、大文字->小文字変換 */
  cleanseWord(str) {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).toLowerCase();
  }

  /** 検索ワード変更時 */
  onChangeSearchWord(e) {
    const searchWord = e.target.value;
    const searchWordArr = this.cleanseWord(searchWord).split(' ');

    if (!this.state.modeDsearch) {
      const { episodes } = this.props;
      const filteredEpisodes = episodes.filter(meta => {
        for (const word of searchWordArr) {
          if (!this.cleanseWord(meta.name).includes(word)) return false;
        }
        return true;
      });
      this.setState({ searchWord, filteredEpisodes });
    } else {
      this.setState({ searchWord });
    }
  }

  onKeyDownSearchWord(e) {
    if (this.state.modeDsearch) {
      // 入力のたびに検索されるとこまるので、Enter押したときだけ検索するようにする
      if (e.key === 'Enter') {
        this.setState({ filteredEpisodes: [] }, () => {
          this.search();
        });
      }
    }
  }

  async search() {
    const { rootMetas } = this.props;
    const { searchWord, filteredEpisodes } = this.state;

    if (!rootMetas || rootMetas.length <=0 || this.isLoading) {
      return;
    }
    // fromが常に0でもfalcorが差分のみリクエストしてくれる
    const range = { from: 0, to: (filteredEpisodes || []).length + 19 };

    // シリーズは運用で入らない
    let episode_ids = [];
    let season_ids = [];
    let series_ids = [0];

    _.forEach(rootMetas, meta => {
      if (meta.meta_schema_id === META_SCHEMA_ID.EPISODE || meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE ||
          meta.meta_schema_id === META_SCHEMA_ID.LIVE || meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE) {
        episode_ids.push(meta.meta_id);
      } else if (meta.meta_schema_id === META_SCHEMA_ID.SEASON || meta.meta_schema_id === META_SCHEMA_ID.LIVE_SEASON) {
        season_ids.push(meta.meta_id);
      }
    });

    let showEpisodeFlag = true;
    if (episode_ids.length === 0 && season_ids.length > 1) {
      // シーズンが複数件登録されていたらシーズン一覧表示
      showEpisodeFlag = false;
    }

    if (episode_ids.length === 0) {
      episode_ids = [0];
    }
    if (season_ids.length === 0) {
      season_ids = [0];
    }

    const searchType = showEpisodeFlag ? 'episode' : 'season';
    this.isLoading = true;
    const path = [['meta', 'packSearch', searchType, searchWord || '', episode_ids, season_ids, series_ids, [range]]];

    const deviceInfo = this.context.models.browserInfo.data;
    let func = () => {
      return this.model
        .fetch(path)
        .then(result => {
          if (result && result.json) {
            const metas = _.get(result.json, [
              'meta',
              'packSearch',
              searchType,
              searchWord || '',
              episode_ids[0],
              season_ids[0],
              series_ids[0]
            ]);
            if (metas) {
              delete metas.$__path;
              if (this._isMounted) {
                if (metas !== this.state.filteredEpisodes) {
                  // シリーズを検索する場合はonlySubTitleを変えてタイトルを表示してあげる
                  this.setState({ filteredEpisodes: Object.values(metas), onlySubTitle: showEpisodeFlag });
                }
              }
            }
          } else {
            this.setState({ filteredEpisodes: [] });
          }
        })
    }
    if(deviceInfo.isIE) {
      // IEだとfinallyがエラーになる
      await func();
      this.isLoading = false;
    } else {
      func().finally(() => {
        this.isLoading = false;
      });
    }
  }

  /** 検索ワードクリア時 */
  onClearSearchWord() {
    if (!this.state.modeDsearch) {
      this.setState({ searchWord: '', filteredEpisodes: this.props.episodes });
    } else {
      this.setState({ searchWord: '' }, () => {
        this.search();
      });
    }
  }

  toggleList(isList) {
    this.setState({ isList });
  }

  onTouchmove() {
    if (this && this.isWithinDistanceBuffer()) this.search();
  }

  onScroll() {
    if (this && this.isWithinDistanceBuffer()) this.search();
  }

  isWithinDistanceBuffer() {
    if (!this.listRef) return false;
    return DOMUtils.getDistanceToBottomOfElement(this.listRef) < 0;
  }

  render() {
    const { className, placeholder, showNew, showDelivery, showChecked } = this.props;
    const { filteredEpisodes, isList, searchWord, onlySubTitle } = this.state;

    return (
      <div className={`c-card-vertical ${className} ${isList ? 'list' : ''}`}>
        <header className="c-card-vertical-head">
          <div className="common-search-box">
            <div className="search-input">
              <input
                type="text"
                value={searchWord}
                placeholder={placeholder}
                onChange={this.onChangeSearchWord}
                onKeyDown={this.onKeyDownSearchWord}
              />
              <span className="icon-close" onClick={this.onClearSearchWord} />
            </div>
          </div>
          <div className="c-sortSwitch">
            <ul className="c-sortSwitch-inBox">
              <li className={`c-sortSwitch-inBox-btn with-thumb ${isList ? '' : 'current'}`}>
                <span className="c-sortSwitch-inBox-btn-link" onClick={() => this.toggleList(false)} />
              </li>
              <li className={`c-sortSwitch-inBox-btn no-thumb ${isList ? 'current' : ''}`}>
                <span className="c-sortSwitch-inBox-btn-link" onClick={() => this.toggleList(true)} />
              </li>
            </ul>
          </div>
        </header>

        <div className="c-card-vertical-cont" ref={this.setListRef}>
          {filteredEpisodes &&
            filteredEpisodes.map(meta => {
              const { route, params, query } = webApp.utils.getProgramLinkRoutes(this.context, meta)
              return (
                <ProgramItemLink
                  key={meta.meta_id}
                  meta={meta}
                  showCaption
                  showNew={showNew && webApp.utils.showNew(meta)}
                  showChecked={showChecked && webApp.utils.isWatched(meta)}
                  showDelivery={showDelivery}
                  onlySubTitle={onlySubTitle}
                  showCoin
                  route={route}
                  params={params}
                  query={query}
                />
              );
            })}
        </div>
      </div>
    );
  }
}
