import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/** 動画リンク: サムネイル下の視聴状況プログレスバー */
const Progress = ({ barColor, progress, height, isHide, className }) => {
  return (
    <StyledDiv1 className={className} height={height} isHide={isHide}>
      {progress > 0 && <StyledDiv2 barColor={barColor} width={progress} />}
    </StyledDiv1>
  )
}

export default Progress

Progress.propTypes = {
  /** プログレスバーの高さ(太さ) */
  height: PropTypes.number,
  /** プログレスバーの進捗具合(%) */
  progress: PropTypes.number,
  /** プログレスバーの色 */
  barColor: PropTypes.string,
  /** trueの時プログレスバーを隠す */
  isHide: PropTypes.bool
}

Progress.defaultProps = {
  height: 3,
  progress: null,
  barColor: '#cece00',
  isHide: false
}

const StyledDiv1 = styled.div`
  background: #e3e3e3;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  display: ${({ isHide }) => (isHide ? 'none' : null)};
`

const StyledDiv2 = styled.div`
  height: 100%;
  background: ${({ barColor }) => barColor};
  width: ${({ width }) => `${width}%`};
`
