import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import NotFound from '../../../generic/components/errors/NotFound';
import webApp from '../../utils/exdioWebAppUtils';
import Link from '../../../../sketch-platform/ui/routing/Link';
import routes from '../../../common/routes'
import Header from '../../../common/components/Header';
import HeaderNewsComponent from './HeaderNewsComponent'
import Footer from '../../../common/components/Footer';

/** 静的ページ */
export default class CampaignStaticPageContent extends Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  };

  static getPath(_models, options, _props) {
    const { routeHandler } = options;
    const url = routeHandler.path;
    return ['page', url];
  }

  static getPrefetchPaths(models, options, props) {
    return [CampaignStaticPageContent.getPath(models, options, props)];
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const page = _.get(prefetchResult, ['json', ...CampaignStaticPageContent.getPath(models, options, props)]);
    return { title: page.name };
  }

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.config = context.models.config.data;

    const path = CampaignStaticPageContent.getPath({}, { routeHandler: context.routeHandler }, props);
    const page = this.model.getSync(path) || null;

    this.state = {
      page,
      isNotFound: false,
      btnHref: ''
    };
  }

  componentDidMount() {
    this.fetchData()
      .then(() => this.updateMetaTags())
      .catch(e => webApp.utils.handleFalcorError(e, this.context));

    // GTMで使用する静的ページのイベントを入れる
    let gtm_tags = [
      {key: 'event', value: 'staticPageChange'},
      {key: 'genre',  value: 'cu'}
    ];
    webApp.utils.updateDataLayer(gtm_tags);
    webApp.utils.updateCookieSync(window.navigator.userAgent);
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags() {
    const { page } = this.state;
    const title = page.name;
    webApp.utils.updateTitle(title);

    const { copyright } = this.config;
    const description = page.description || this.config.description;
    const keywords = page.keywords || this.config.keywords;
    const url = window.location.href;
    const regularUrl = url.replace(/\?.*$/, '');
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: regularUrl }]
    };
    webApp.utils.updateMeta(metaTags);
  }

  fetchData() {
    // 念のため削除
    const scriptTags = document.querySelector('#static-page-content script');
    if (scriptTags) Array.from(scriptTags).forEach(e => e.remove());

    const url = this.context.routeHandler.path;
    const path = ['page', url];
    return this.model.fetch([path]).then(result => {
      const page = _.get(result, ['json', ...path]) || null;
      this.setState({ page, isNotFound: !page || !page.innerHTML }, () => {
        // ブラウザバック時にscriptが走らないので、クライアント側で明示的にscriptタグの追加を行いscriptを実行する
        if (!page) return;
        const divElem = document.getElementById('static-page-content');
        if (!divElem) return;
        const scriptElem = document.createElement('script');
        scriptElem.innerHTML = page.script;
        divElem.appendChild(scriptElem);

        /**
         * Dioページ機能のHTMLタブにて、{{href}}の形でボタンのリンク先を記述する
         * 例) {{https://www.tv-asahi.co.jp/apps/apply/jump.php?fid=9270}}
         * 正規表現で該当の文字列を拾い、ボタンに反映する
         * 反映後に{{href}}は削除する
         *
         * TODO: Dio側で自由入力欄等が追加された場合、そちらを使用する
        */
        const btnHref = _.get(page.content.match(/<!-- \{\{(.+)\}\} -->/), [1])

        if (btnHref) {
          this.setState({ btnHref })
        }
      });
    });
  }

  render() {
    const { page, isNotFound, btnHref } = this.state;
    if (isNotFound) return <NotFound />;
    if (!page) return null;

    // ログイン判別
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)

    return (
      <React.Fragment>
        <Header />
        {/* <HeaderNewsComponent /> */}
        <div id="static-page-content" className="l-container">
          <div dangerouslySetInnerHTML={{ __html: page.content }} />

          {btnHref &&
            <div style={{margin: '80px auto 100px', maxWidth: 'calc(100% - 30px)', textAlign: 'center'}}>
              {isLoggedIn
                ?
                <Link
                  href={btnHref}
                  className="p-present__btn"
                >
                  応募する
                </Link>
                :
                <Link
                  route={routes.login}
                  query={{ redirect: btnHref }}
                  className="p-present__btn"
                >
                  ログインして応募する
                </Link>
              }
            </div>
          }

          {page.style && <style>{page.style}</style>}
        </div>
        <Footer className="mt0" />
      </React.Fragment>
    );
  }
}
