import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import window from 'global';
import { META_SCHEMA_ID, LOCAL_STORAGE_KEY_PURCHASE, PURCHASE_TYPE, FAVORITE_TYPE } from '../../../../../constants/app';
import routes from '../../../../common/routes';
import webApp from '../../../utils/exdioWebAppUtils';
import Footer from '../../../../common/components/Footer';
import DFPBanner from '../../../../common/components/DFPBanner';
import AboutMedalLink from '../../../../common/components/renewal/AboutMedalLink';
import SidePalette from '../details/SidePalette';
import PackList from '../details/PackList';
import EpisodeList from '../details/EpisodeList';
import SideTab from '../details/SideTab';
import Caption from '../details/Caption';
import ProgramTopLink from '../details/ProgramTopLink';
import EpisodePager from '../details/EpisodePager';
import RenewalPlayer from '../../player/RenewalPlayer';
import SwitchableList from '../../../../common/components/renewal/SwitchableList';
import AddButtonBlock from '../details/AddButtonBlock';
import SideRecommend from '../details/SideRecommend';
import HtmlSnippet from '../../HtmlSnippet';

moment.createFromInputFallback = config => { config._d = new Date(config._i) };

/** 単話ページ テストテンプレート */
export default class EpisodeTemplateTest extends Component {
  static propTypes = {
    /** 動画のシリーズID */
    seriesId: PropTypes.string,
    /** 動画のシーズンID */
    seasonId: PropTypes.string,
    /** 動画のエピソードID */
    episodeId: PropTypes.string,

    /** 動画のメタ情報 */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    /** 動画の視聴権利関連情報 */
    howToPlay: PropTypes.object,
    /** 動画の価格、配信期間情報など */
    product: PropTypes.object,

    // 通常単話
    /** 関連動画情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** パック販売情報 */
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number,
        name: PropTypes.string,
        original_price: PropTypes.number,
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    ),
    /** episodesのhowToPlay情報 */
    howToPlays: PropTypes.object,
    // 月額見放題
    /** 動画のコース情報 */
    course: PropTypes.shape({
      course_id: PropTypes.number,
      schema_id: PropTypes.number,
      name: PropTypes.string,
      active_pricing: PropTypes.object,
      values: PropTypes.object
    }),

    /** 動画の視聴ステータス情報 */
    status: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      limitDate: PropTypes.string
    }),
    /** 動画のシーズン情報 */
    season: PropTypes.object,

    /** おすすめ動画情報 */
    recommendItems: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    episodeId: '',
    howToPlay: {},
    episodes: [],
    products: [],
    howToPlays: {},
    course: {},
    showPlayer: false,
    status: {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null
    },
    recommendItems: []
  };

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.config = this.context.models.config.data;
    this.state = {
      showPlayer: props.showPlayer
    };

    this.onClickPlay = this.onClickPlay.bind(this);
    this.onClosePlayer = this.onClosePlayer.bind(this);
    this.onClickAddMyList = this.onClickAddMyList.bind(this);

    this.setSwitchableListRef = e => {
      this.switchableListRef = e;
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillReceiveProps(nextProps) {
    const { showPlayer, status } = nextProps;
    if (!this.state.showPlayer && !this.props.showPlayer && showPlayer) {
      if ((status.isNotInCourse && status.isFree) || status.isPurchased) {
        this.setState({
          showPlayer: true
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.body.style.overflow = null;
  }

  onClickPlay(e) {
    if (e) e.preventDefault();

    const { status } = this.props;

    // 念の為購入できないコンテンツはブロックする
    if (!status.isPurchseAvailable) {
      return;
    }

    if (!status.isGeoDeliverable) {
      return;
    } else if ((status.isNotInCourse && status.isFree) || status.isPurchased) {
      this.setState({
        showPlayer: true
      });
      // バックグラウンドのスクロール抑止
      document.body.style.overflow = 'hidden';
    } else if (status.isNotInCourse && status.isNotFree && status.isNotPurchased) {
      const metaId = this.props.episodeId;
      const { seasonId } = this.props;
      window.localStorage.setItem(
        LOCAL_STORAGE_KEY_PURCHASE,
        JSON.stringify({
          type: PURCHASE_TYPE.EPISODE,
          id: Number(seasonId),
          selected: [Number(metaId)]
        })
      );
      this.context.history.push(routes.purchase.makePath());
    } else if (status.isInCourse && status.isNotPurchased) {
      const { course } = this.props;
      const courseIdNum = Number(course.course_id);
      window.localStorage.setItem(
        LOCAL_STORAGE_KEY_PURCHASE,
        JSON.stringify({
          type: PURCHASE_TYPE.PLAN,
          id: courseIdNum,
          selected: [courseIdNum]
        })
      );
      this.context.history.push(routes.purchase.makePath());
    }
  }

  onClosePlayer() {
    this.setState({
      showPlayer: false
    });
    document.body.style.overflow = null;
  }

  /** マイリスト追加時 */
  onClickAddMyList() {
    this.switchableListRef.getFavorites();
  }

  /** 料金 */
  price() {
    const { meta, product, status, course } = this.props;
    if (!meta) return '';

    // 月額見放題
    if (status.isInCourse) {
      return _.get(course, ['active_pricing', 'price']) || null;
    }
    // 月額見放題に含まれていない無料
    if (META_SCHEMA_ID.EPISODE === meta.meta_schema_id) {
      return '無料';
    }
    // 月額見放題に含まれていない有料
    if (META_SCHEMA_ID.EPISODE_NOT_FREE === meta.meta_schema_id) {
      const activePrice = _.get(product, ['active_pricing']) || null;
      return (activePrice && activePrice.price) || 0;
    }
    return '';
  }

  /** 放送日 */
  onAirDate() {
    const onAirDate = _.get(this.props, ['meta', 'values', 'avails_ReleaseHistoryOriginal']);
    return onAirDate ? moment(onAirDate).format('YYYY年M月D日') : '-';
  }

  renderPlayButton() {
    const { status } = this.props;

    // 月額見放題に含まれていない無料、または、単話でも月額見放題でも購入済み、は視聴可能
    if ((status.isNotInCourse && status.isFree) || status.isPurchased) {
      return [
        <div key="btn" className="play-btn" />,
        <div key="btn-sp" className="play-btn-sp">
          視聴する
        </div>
      ];
    }
    // 未購入は視聴不可
    if (status.isNotPurchased) {
      if (status.isPurchseAvailable) {
        return [
          <div key="status" className="label-status">
            未購入
          </div>,
          <div key="btn" className="play-btn">
            購入して再生
          </div>,
          <div key="btn-sp" className="play-btn-sp">
            購入して再生
          </div>
        ];
      } else {
        return [null, null, null]
      }
    }
    // データ取得中等、ステータスが確定していない場合
    return null;
  }

  renderPlayLink() {
    const { status } = this.props;

    // 海外判定
    if (status.isGeoDeliverable === false) {
      return '海外のため視聴不可';
    } else if (!status.isGeoDeliverable) {
      return '';
    }

    // 月額見放題に含まれていない無料
    if (status.isNotInCourse && status.isFree) {
      return [
        <span key="free" className="c-pageBtn-link-free">
          無料
        </span>,
        'で視聴'
      ];
    }
    if (status.isPurchased) {
      return '視聴する';
    }
    if (status.isNotPurchased) {
      if (status.isNotInCourse) {
        return [
          <span key="not-free" className="c-pageBtn-link-coin">
            {this.price()}
          </span>,
          'で視聴'
        ];
      }
      if (status.isInCourse) {
        return [
          '月額',
          <span key="in-course" className="c-pageBtn-link-yen">
            {this.price()}
          </span>,
          '円で視聴'
        ];
      }
    }
    // データ取得中等、ステータスが確定していない場合
    return null;
  }

  renderDeliveryStartAt() {
    const { meta, status } = this.props;
    if (!meta || !meta.meta_id) return null;
    if (status.isInCourse) {
      const deliveryStartAt = moment(meta.delivery_start_at).format('YYYY年M月D日');
      return <div className="c-storyMeta-inBox-meta-castStart">配信開始日：{deliveryStartAt}</div>;
    }
    return null;
  }

  renderPeriod() {
    const { meta, status } = this.props;
    if (!meta || !meta.meta_id) return null;

    if (status.isNotInCourse) {
      const deliveryEndAt = `視聴期限：${webApp.utils.deliveryEndAt(meta, 'YYYY年M月D日 HH:mm') || '-'}`;
      // AVOD
      if (status.isFree) {
        return <div className="c-storyMeta-inBox-meta-viewingPeriod">{deliveryEndAt}</div>;
      }
      // TVOD
      if (status.isNotFree) {
        if (webApp.utils.isSp()) {
          if (status.isPurchased) {
            const deliveryEndAtSp = `視聴期限：${webApp.utils.deliveryEndAt(meta) || '-'}`;
            const remaining = webApp.utils.remaining(this.context, _.get(meta, ['delivery_end_at']));
            return (
              <div className="c-storyMeta-inBox-meta-viewingPeriod purchased">
                <dl>
                  <dt>購入済</dt>
                  <dd>
                    {deliveryEndAtSp}
                    {remaining && `（${remaining}）`}
                  </dd>
                </dl>
              </div>
            );
          }
          if (status.isNotPurchased) {
            return <div className="c-storyMeta-inBox-meta-viewingPeriod">視聴期間：7日間</div>;
          }
        } else {
          if (status.isPurchased) {
            return (
              <div className="c-storyMeta-inBox-meta-viewingPeriod purchased">
                <dl>
                  <dt>購入済</dt>
                  <dd>{deliveryEndAt}</dd>
                </dl>
              </div>
            );
          }
          if (status.isNotPurchased) {
            return <div className="c-storyMeta-inBox-meta-viewingPeriod">{deliveryEndAt}</div>;
          }
        }
      }
    }
    // SVOD
    if (status.isInCourse) {
      if (status.isNotPurchased) {
        return <div className="c-storyMeta-inBox-meta-viewingPeriod">視聴期間：視聴ライセンス購入後１ヶ月間</div>;
      }
      if (status.isPurchased) {
        const deadLine = status.limitDate
          ? webApp.utils.deadLine(this.context, status.limitDate, true, 'YYYY/M/D HH:mm')
          : null;
        return (
          <div className="c-storyMeta-inBox-meta-viewingPeriod purchased">
            <dl>
              <dt>購入済</dt>
              <dd>{deadLine}</dd>
            </dl>
          </div>
        );
      }
    }
    return null;
  }

  renderPrice() {
    const { status } = this.props;
    const price = this.price();

    if (!status.isPurchseAvailable) {
      return null;
    }

    if (status.isNotInCourse) {
      return (
        <div className={`c-storyMeta-inBox-meta-price ${status.isFree && 'free'}`}>
          料金：<span className="c-storyMeta-inBox-meta-price-coin">{price}</span>
          <AboutMedalLink linkClassName="c-storyMeta-inBox-meta-price-aboutCoin" />
        </div>
      );
    }
    if (status.isInCourse) {
      return <div className="c-storyMeta-inBox-meta-price">料金：月額 {price}円 (税込)</div>;
    }
    return null;
  }

  render() {
    const { seriesId, seasonId, meta, episodes, products, howToPlays, status, product, course, season, recommendItems } = this.props;
    const { showPlayer } = this.state;

    const latest = (episodes && episodes[episodes.length - 1]) || {};
    const isLatest = meta && latest && meta.meta_id === latest.meta_id;
    const thumbnailUrl = meta.thumbnail_url || this.config.default_thumbnail;
    const time = webApp.utils.duration(meta) || '-';
    const rest = webApp.utils.rest(meta);
    const showRest = status.isPurchased || (status.isNotInCourse && status.isFree);
    const progress = webApp.utils.progress(this.config, meta);
    const otherSeasonIds = _.get(season, ['values', 'other_series']) || [];
    const bannerId1 = _.get(meta, ['values', 'banner_1']);
    const bannerId2 = _.get(meta, ['values', 'banner_2']);

    const playLink = this.renderPlayLink();

    const metaIdForPlayer = `${this.config.videocloud.reference_id_prefix}${meta.meta_id}`;
    const playerSettings = webApp.utils.getPlayerSettings(this.config, meta, status.displayMode);

    const material_id = _.get(product, ['ref_id']) || '';
    const license_id = _.get(course, ['ref_id']) || '';

    return (
      <div className="common-wrapper">
        <DFPBanner position="head" meta_id={meta.meta_id} key="banner" />

        <div>テンプレートテスト</div>
        {metaIdForPlayer && showPlayer && status.displayMode && (
          <RenewalPlayer
            meta_id={metaIdForPlayer}
            auto_start
            product_type={playerSettings.product_type}
            channel={playerSettings.channel}
            ssai_ad_config_id={playerSettings.ssai_ad_config_id}
            ssai_player_id={playerSettings.ssai_player_id}
            stvod_player_id={playerSettings.stvod_player_id}
            live_player_id={playerSettings.live_player_id}
            delivery_config_id={playerSettings.delivery_config_id}
            thumbnail_url={meta.thumbnail_url}
            subtitle={!!meta.values.subtitle}
            enqueteEnabled={status.isFree}
            onClose={this.onClosePlayer}
            material_id={material_id}
            license_id={license_id}
            display_mode={status.displayMode}
          />
        )}

        <div className="common-video-area">
          <a href="#" onClick={this.onClickPlay}>
            {this.renderPlayButton()}
            {showRest && <div className="remaining-time">{rest}</div>}
            <div className="seekbar">{progress > 0 && <div className="seek" style={{ width: `${progress}%` }} />}</div>
            <div className="video-area">
              <img src={thumbnailUrl} alt={meta.name} />
            </div>
          </a>
        </div>

        <div className="c-storyMeta">
          <div className="c-storyMeta-inBox">
            <div className="c-storyMeta-inBox-meta">
              <h2 className="c-storyMeta-inBox-meta-title">
                {meta.name}
                <span className="c-storyMeta-inBox-meta-title-small">{isLatest && '（最新話）'}</span>
              </h2>
              <div className="c-storyMeta-inBox-meta-time">時間：{time}</div>
              {this.renderPrice() || <div className="inBox">料金：-</div>}
              <div className="c-storyMeta-inBox-meta-onAir">放送日：{this.onAirDate()}</div>
              {this.renderDeliveryStartAt()}
              {this.renderPeriod()}

              <Caption caption={meta.values && meta.values.evis_EpisodeLongSynopsis} />
            </div>
            <div className="c-storyMeta-inBox-actions">
              <div className="c-pageBtn">
                {playLink && (
                  <a
                    href="#"
                    className={`c-pageBtn-link ${status.isGeoDeliverable === false ? 'disabled' : ''}`}
                    onClick={this.onClickPlay}
                  >
                    {status.isPurchseAvailable ?
                      playLink
                      :
                      "現在購入いただけません"
                    }
                  </a>
                )}
              </div>

              {/* for PC&SP */}
              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.META}
                favoriteId={meta.meta_id}
                title={meta.name}
                onClickAddMyList={this.onClickAddMyList}
              />

              <EpisodePager meta={meta} seriesId={seriesId} seasonId={seasonId} episodes={episodes} course={course} />
            </div>
          </div>
        </div>

        <div className="c-listMeta">
          <div className="c-listMeta-inBox">
            <div className="c-listMeta-inBox-main">
              {status.isNotInCourse && [
                <PackList key="pack" products={products} showMedal />,
                bannerId1 && <HtmlSnippet key="banner" snippetId={bannerId1} />,
                <EpisodeList key="episode" episodes={episodes} howToPlays={howToPlays} />
              ]}
              {status.isInCourse && [
                bannerId1 && <HtmlSnippet key="banner" snippetId={bannerId1} />,
                <SwitchableList
                  key="episode"
                  episodes={episodes}
                  placeholder="パックの中から探す"
                  showNew
                  showChecked
                  onlySubTitle
                  onClickAddMyList={this.onClickAddMyList}
                  ref={this.setSwitchableListRef}
                />
              ]}
            </div>

            <div className="c-listMeta-inBox-sub">
              <div className="common-box-ad-rectangle">
                <DFPBanner position="foot" meta_id="" />
              </div>
              {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
              <SideTab currentSeasonId={seasonId} otherSeasonIds={otherSeasonIds} currentSeasonIds={[seasonId]} />
              <ProgramTopLink seriesId={seriesId} seasonId={seasonId} isFree={status.isFree} />
              <HtmlSnippet snippetId={this.config.extras.common_banner_snippet_key} />
              <SideRecommend recommendItems={recommendItems} />
            </div>
          </div>
        </div>

        <Footer className="mt0" />
      </div>
    );
  }
}
