/* style */
import styled from 'styled-components'
import { mediaSp } from '../style'

export const StyledDiv3 = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;

  & + ${StyledDiv3} {
    margin-top: 16px;
  }
`

export const StyledButton = styled.button`
  padding: 4px 8px;
  border-radius: 24px;
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 0 1px 0 #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1;
  transform: translateY(0);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
`

export const StyledI = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
`

export const StyledSpan = styled.span`
  margin-left: 4px;
`

export const StyledDiv4 = styled.div`
  text-align: center;
  margin-top: 64px;

  @media ${mediaSp} {
    margin-top: 32px;
  }
`
