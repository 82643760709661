import React, { memo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import axios from 'axios'
import { PANEL_ID } from '../config'
import { SelectionItemType } from '../types'
import browserInfo from '../../../../../../sketch-platform/utils/browserInfo'

/* components */
import ModalDefault from './ModalDefault'
import ModalSilk from './ModalSilk'

/* style */
import {
  StyledWrapper,
  StyledSelectionDetail,
  StyledModal,
  StyledButton1
} from './styles'

/** 厳選エピソード スライダー */
const Selection = (
  {
    selectedTabId = '',
    selectedTags = [],
    setSelectedTags = () => {},
    selectionItems = [],
    ...props
  },
  context
) => {
  const pickUpTagName = '厳選まとめ'
  const device = browserInfo(navigator.userAgent, (data) => data)

  /** useState */
  const [meta, setMeta] = useState({})
  const [banner, setBanner] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false) // モーダル開閉判別
  const [selectedItem, setSelectedItem] = useState(null) // 選択中の厳選エピソード
  const [orderBy, setOrderBy] = useState('desc') // ソート順
  const [hasNext, setHasNext] = useState(false) // 次(より古い)のエピソードが存在するか
  const [hasPrev, setHasPrev] = useState(false) // 前(より新しい)のエピソードが存在するか

  /** useRef */
  const scrollTop = useRef(0)

  /** タグが変更されたとき履歴とパラメータの書き換えをする */
  const onChangeTags = (selectionItem = { id: null, tag: null }) => {
    const { id, tag } = selectionItem
    if (tag) setSelectedTags([tag])

    const { routeHandler } = context
    const { query, params } = routeHandler
    const { slug } = params

    /**
     * 履歴の追加、パスパラメータの書き換え
     * // NOTE
     * contextが書き換えられると再レンダリングされるため
     * context.history.replaceは使えない
     */
    const path = new URLSearchParams({ ...query, id }).toString()
    const url = `${slug}?${path}`
    window.history.replaceState(null, '', url)
  }

  /** 選択中のアイテム情報を絞り込み */
  const getSelectedItem = () => {
    if (!(size(selectedTags) > 0 && size(selectionItems) > 0)) return []

    return Object.values(selectionItems).find(({ tag }) => {
      const selectedTag = get(selectedTags, [0])
      return tag === selectedTag
    })
  }

  /**
   * 選択中の厳選エピソードから前後のエピソードに移動
   * @param {'next'|'prev'} [nextOrPrev='next'] - 次/前に移動
   */
  const slideTo = (nextOrPrev = 'next') => {
    if (!selectedItem) return
    const index = Object.values(selectionItems).indexOf(selectedItem)

    switch (nextOrPrev) {
      case 'next': {
        if (!hasNext) return

        const nextItem = get(selectionItems, [index + 1], false)
        if (nextOrPrev) onChangeTags(nextItem)
        break
      }
      case 'prev': {
        if (!hasPrev) return
        const prevItem = get(selectionItems, [index - 1], false)
        if (prevItem) onChangeTags(prevItem)
        break
      }
      default:
        break
    }
  }

  /** 前後の厳選エピソードが存在するか判別 */
  const hasNextPrev = () => {
    if (!(size(selectionItems) > 0 && selectedItem))
      return {
        hasNext: false,
        hasPrev: false
      }
    const index = Object.values(selectionItems).indexOf(selectedItem)
    const nextItem = get(selectionItems, [index + 1], {})
    const prevItem = get(selectionItems, [index - 1], {})
    return {
      hasNext: size(nextItem),
      hasPrev: size(prevItem)
    }
  }

  /** メタを取得 */
  const getMeta = async () => {
    const { tag } = selectedItem || {}
    if (!tag) return Promise.resolve()

    const params = {
      limit: '1',
      condition: {
        and: [{ tags: tag }, { tags: pickUpTagName }]
      },
      fields: 'values,tags'
    }
    return axios.post('/api/dsearch/post', params).then(({ data }) => {
      const { metas } = data
      return metas && metas[0]
    })
  }

  /** バナーを取得 */
  const getBanner = async () => {
    const { id, hasBanner } = selectedItem || {}
    if (!hasBanner) return Promise.resolve()

    const path = `https://${context.models.config.data.douga_mv_host}/douga_mv/shinchan/svod/banners/${id}.html`
    return axios.post(path).then(({ data }) => data)
  }

  /** useEffect */
  useEffect(() => {
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  /** モーダル開閉に併せてbodyのスタイルを変更 */
  useEffect(() => {
    if (isModalOpen === true) {
      scrollTop.current = window.pageYOffset
      document.body.style.width = '100%'
      document.body.style.position = 'fixed'
      document.body.style.top = `${-scrollTop.current}px`
    } else {
      document.body.removeAttribute('style')
      window.scrollTo(0, scrollTop.current)
    }
  }, [isModalOpen])

  /** 選択中のエピソードが変更されたとき */
  useEffect(() => {
    if (selectedTabId !== PANEL_ID.SELECTION) return

    /** 選択中のアイテム情報の抽出 */
    setSelectedItem(getSelectedItem())

    /** モーダル閉じる */
    setIsModalOpen(false)
  }, [selectedTags, selectionItems])

  useEffect(() => {
    const {
      hasNext: _hasNext = false,
      hasPrev: _hasPrev = false
    } = hasNextPrev()
    setHasNext(_hasNext)
    setHasPrev(_hasPrev)
    ;(async () => {
      const metaData = await getMeta()
      setMeta(metaData)
      const bannerData = await getBanner()
      setBanner(bannerData)
    })()
  }, [selectionItems, selectedItem])

  return (
    <StyledWrapper {...props}>
      <StyledButton1
        type="yellow"
        as="button"
        onClick={() => setIsModalOpen(true)}
      >
        テーマを選ぶ
      </StyledButton1>
      {selectedItem && (
        <StyledSelectionDetail
          data={selectedItem}
          meta={meta}
          banner={banner}
          hasNext={hasNext}
          hasPrev={hasPrev}
          onClickNext={() => slideTo('next')}
          onClickPrev={() => slideTo('prev')}
        />
      )}
      {size(selectionItems) > 0 && (
        <StyledModal
          isOpen={isModalOpen}
          onClickBg={() => setIsModalOpen(false)}
        >
          {device.isSilk ? (
            <ModalSilk
              selectionItems={Object.values(selectionItems)}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              setIsModalOpen={setIsModalOpen}
              onChangeTags={onChangeTags}
            />
          ) : (
            <ModalDefault
              selectionItems={Object.values(selectionItems)}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              setIsModalOpen={setIsModalOpen}
              onChangeTags={onChangeTags}
            />
          )}
        </StyledModal>
      )}
    </StyledWrapper>
  )
}

export default memo(Selection)

Selection.propTypes = {
  /** 選択中のタブ */
  selectedTabId: PropTypes.string,
  /** 選択中のタグ */
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  /** 選択中のタグを変更 */
  setSelectedTags: PropTypes.func,
  /** 厳選エピソードデータ */
  selectionItems: PropTypes.arrayOf(SelectionItemType)
}

Selection.contextTypes = {
  routeHandler: PropTypes.object,
  models: PropTypes.object
}
