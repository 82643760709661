import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import SpSailItem from '../SpSailItem'
import webApp from '../../../../../utils/exdioWebAppUtils'

/* style */
import { StyledDiv, StyledUl } from './style'

/** SailGp sp 動画リスト表示切り替えコンポーネント */
const SpSwitchableSail = ({ seasonId = null }, context) => {
  const model = context.falcorModel.batch(100)
  const [filteredEpisodes, setFilteredEpisodes] = useState([])

  /** 同シーズンのエピソード一覧取得 */
  const getEpisodes = () => {
    const range = { from: 0, to: 2000 }
    const path = [
      'meta',
      'packSearch',
      'episode',
      '',
      [0],
      seasonId,
      [0],
      [range]
    ]
    return model
      .fetch([path])
      .then((result) => {
        let filtering = _.get(result, [
          'json',
          'meta',
          'packSearch',
          'episode',
          '',
          0,
          seasonId,
          0
        ])
        if (filtering) {
          delete filtering.$__path
          filtering = Object.values(filtering)
          // 話数でソートする
          filtering.sort((a, b) =>
            Number(a.values.avails_EpisodeNumber) <
            Number(b.values.avails_EpisodeNumber)
              ? 1
              : -1
          )
          if (filtering !== filteredEpisodes) {
            return filtering
          }
        }
      })
      .catch((error) => console.error(error))
  }

  const fecthEpisodes = async () => {
    const filteredEpisodesList = await getEpisodes()
    setFilteredEpisodes(filteredEpisodesList)
  }

  useEffect(() => {
    fecthEpisodes()
  }, [])

  return (
    <StyledDiv>
      <StyledUl>
        {filteredEpisodes &&
          filteredEpisodes.map((meta) => {
            return (
              <SpSailItem
                key={meta.meta_id}
                meta={meta}
                onClickThumbnail={() =>
                  webApp.utils.goToProgramLink(context, meta)
                }
              />
            )
          })}
      </StyledUl>
    </StyledDiv>
  )
}

export default SpSwitchableSail

SpSwitchableSail.propTypes = {
  seasonId: PropTypes.string,
  className: PropTypes.string
}

SpSwitchableSail.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  routeHandler: PropTypes.object
}
