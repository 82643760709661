import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Origin from './Origin'
import { mediaQuery } from '../../../../style'
import { black, darkYellow, yellow } from '../../styles'

const Button3 = ({
  className = '',
  href = '',
  target = '',
  name = '',
  html = '',
  ...props
}) => {
  return (
    <StyledOrigin
      className={className}
      href={href}
      target={target}
      name={name}
      html={html}
      {...props}
    />
  )
}

export default Button3

Button3.propTypes = {
  /** 遷移先 */
  href: PropTypes.string,
  /** aタグのtarget属性 */
  target: PropTypes.string,
  /** 描画される要素(単純な文字列の場合) */
  name: PropTypes.string,
  /** 描画される要素(htmlの場合) */
  html: PropTypes.string,
  className: PropTypes.string
}

const StyledOrigin = styled(Origin)`
  padding: 10px 20px;
  height: 60px;
  position: relative;
  background-color: ${yellow};
  box-shadow: 0 3px 0 3px ${darkYellow};
  color: ${black}!important;

  ${mediaQuery()} {
    height: 50px;
  }

  &:hover {
    box-shadow: 0 0 0 ${darkYellow};
  }

  &::after {
    margin: auto 0;
    width: 16px;
    height: 18px;
    background: url(/images/exdio/renewal/gariben_daigaku/components/btn3_icon.svg)
      no-repeat center / 100% auto;
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    content: '';

    ${mediaQuery()} {
      width: 12px;
      height: 14px;
      right: 10px;
    }
  }
`
