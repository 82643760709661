/* style */
import styled from 'styled-components'
import Link from '../../../../../../../../sketch-platform/ui/routing/Link'

export const mediaSp = '(max-width: 1024px)'

export const StyledDiv1 = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;

  /*
  * .c-menu[aria-hidden="true"]
  */
  &[aria-hidden='true'] {
    display: none;
  }
`

export const StyledUl = styled.ul`
  margin-top: 16px;
  padding: 0 12px;
  display: flex;
`

export const StyledLi = styled.li`
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }
`

export const StyledDiv2 = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
`

export const StyledButton = styled.button`
  margin-left: auto;
  padding: 0;
  width: 32px;
  height: 32px;
  background: none;
  border-width: 0;
  position: relative;

  &::before,
  &::after {
    margin: auto;
    width: 32px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`

export const StyledLink = styled(Link)`
  width: 84px;

  svg {
    path,
    rect {
      fill: #fff;
    }
  }
`

export const StyledDiv3 = styled.div`
  margin-top: 16px;
  padding: 0 16px;
`

export const StyledDiv4 = styled.div`
  border-top: 2px solid #fff;

  &:first-child {
    border-top-width: 0;
  }
`

export const StyledLi2 = styled.li`
  && {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;

    &:first-child {
      border-top-width: 0;
    }

    i {
      flex: 28px 0;

      svg {
        width: 100%;
        min-width: 20px;
        display: inline-block;

        path,
        rect {
          fill: #fff;
          transition: fill 0.2s ease-in-out;
        }
      }

      & + span {
        margin-left: 8px;
      }
    }
  }
`

export const StyledLi2Main = styled(StyledLi2)`
  && {
    i {
      svg {
        min-width: 28px;

        path,
        rect {
          fill: #00a3a3;
        }
      }
    }

    span {
      color: #00a3a3;
      font-size: 2.4rem;
    }
  }
`

export const StyledSpan = styled.span`
  display: inline-block;
  font-size: 1.6rem;
  flex: 1 0;
`
