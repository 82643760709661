import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GlobalFooterNav from './GlobalFooterNav';
import GlobalFooterOther from './GlobalFooterOther';

export default class GlobalFooter extends Component {
  static propTypes = {
    children: PropTypes.any,
    model: PropTypes.object,
  }

  static contextTypes = {
    models: PropTypes.object,
    history: PropTypes.object,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      other: false,
    }
  }

  // その他エリアの表示非表示
  handleOtherVisible(){
    this.setState({
      other: !this.state.other,
    });
  }

  render() {
    return (
      <nav className="globalFooter">
        {this.props.children}

        <GlobalFooterNav
          handleOtherVisible={() => this.handleOtherVisible()}
        />
        <GlobalFooterOther
          handleOtherVisible={() => this.handleOtherVisible()}
          visible={this.state.other}
        />
      </nav>
    );
  }
}
