import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import routes from '../../../../../../common/routes'

/** 在学証明書｜サムネイル、学長印 */
const ThumbnailAndStamp = ({ icon = '' }) => {
  return (
    <StyledDiv>
      <StyledLink route={routes.edit_icon}>
        <img src={icon} alt="サムネイル画像" />
      </StyledLink>
      <StyledImg
        src="/images/exdio/renewal/gariben_daigaku/components/certificate-stamp.webp"
        alt="私立ガリベン大学小峠英二学長乃印"
      />
    </StyledDiv>
  )
}

export default ThumbnailAndStamp

ThumbnailAndStamp.propTypes = {
  /** 証明書写真のパス */
  icon: PropTypes.string
}

const StyledDiv = styled.div`
  width: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledLink = styled(Link)`
  width: 86px;
  height: 86px;
  display: inline-block;
  border-radius: 50%;
  background-color: gray;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
`

const StyledImg = styled.img`
  margin-top: 6px;
`
