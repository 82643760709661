import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../utils/exdioWebAppUtils'
import routes from '../../../../../common/routes'
import {
  StyledButton1,
  StyledButton2,
  StyledButton3,
  StyledLi,
  StyledUl
} from './style'

/** ガリベン大学 Section0 */
const Section0 = ({ ...props }, context) => {
  const config = context.models.config.data
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  useEffect(() => {
    setIsLoggedIn(webApp.utils.isLoggedIn(context))
  }, [])

  return (
    <StyledUl {...props}>
      <StyledLi>
        <StyledButton3
          href={config.purchase_plan.gariben_daigaku}
          target="_blank"
          html="生徒になる<span>（有料会員）</span>"
        />
      </StyledLi>
      <StyledLi>
        <StyledButton1
          href="https://www.tv-asahi.co.jp/douga_mv/gariben_daigaku/lp/"
          name="私立ガリベン大学とは？"
          icon
        />
      </StyledLi>
      {!isLoggedIn && (
        <StyledLi>
          <StyledButton2
            route={routes.login}
            query={{ redirect: '/plan/gariben_daigaku' }}
            name="ログイン"
            icon
          />
        </StyledLi>
      )}
    </StyledUl>
  )
}

export default Section0

Section0.propTypes = {}

Section0.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func
}
