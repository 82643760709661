import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import window from 'global'
import MDSpinner from 'react-md-spinner'
import webApp from '../../utils/exdioWebAppUtils'
import * as tags from './plan_logirl/Tags'
import PlanLogirl from './templates/PlanLogirl'

/** 月額見放題ページ */
export default class PlanLogirlContent extends Component {
  static propTypes = {
    slug: PropTypes.string,
    course: PropTypes.shape({
      course_id: PropTypes.number.isRequired,
      schema_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired
    }).isRequired
  }

  static defaultProps = {
    slug: ''
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  static getPath(_models, _options, props) {
    return ['courseBySlug', props.slug]
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    const path = PlanLogirlContent.getPath({}, {}, props)
    const course = this.model.getSync(path) || {}

    this.state = {
      course,
      right: {},
      rightMetas: [],
      season: null,
      series: null,
      episodes: [],
      isNotFound: false,
      status: {
        isPurchseAvailable: course.active_pricing != null,
        isPurchased: false,
        isNotPurchased: false
      },
      loaded: false,
      popupArticles: []
    }
  }

  async componentDidMount() {
    this._isMounted = true

    // logirlリタゲタグの挿入
    this.addLogirlTags()

    this.setState({
      loaded: true
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    // リタゲタグの削除
    webApp.utils.removeUserGram(Object.values(tags.tagIds))
    webApp.utils.returnDefaultWebClip()
  }

  /** logirl 広告タグ */
  addLogirlTags() {
    Object.keys(tags.tagIds).forEach((key) => {
      const target = document.getElementById(tags.tagIds[key])
      if (!target) {
        webApp.utils.appendUserGram(tags[key])
      }
    })
  }

  render() {
    const { loaded } = this.state

    if (!loaded) {
      return (
        <div className="common-wrapper">
          <div className="mdspinner">
            <MDSpinner size={96} />
          </div>
        </div>
      )
    }

    const childProps = Object.assign({}, this.props)
    return React.createElement(PlanLogirl, childProps)
  }
}
