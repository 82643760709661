/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

export const StyledDiv1 = styled.div`
  width: 1240px;
  padding-right: 288px;
  position: relative;
  margin: 0 auto 10px;
  background: linear-gradient(
    -90deg,
    rgb(255, 255, 112) 0%,
    rgb(135, 245, 131) 65%,
    rgb(15, 235, 149) 100%
  );

  ul {
    width: auto;
    bottom: -25px;
    right: -275px;
  }

  li {
    width: 8px;
    height: 8px;
    border: 1px solid #1f9064;
    background: #fff;
    margin: 0 4px;
  }

  li.slick-active {
    background: #1f9064;
  }

  .slick-dots li button {
    width: 100%;
    height: 100%;

    &::before {
      content: '';
    }
  }

  ${mediaQuery(1320)} {
    width: 920px;
    padding-right: 224px;

    ul {
      right: -200px;
    }
  }
`

export const StyledH1 = styled.h1`
  width: 1280px;
  margin: 30px auto 0;

  ${mediaQuery(1320)} {
    width: 920px;
  }
`

export const StyledDiv2 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 536px;
  overflow: hidden;

  ${mediaQuery(1320)} {
    height: 390px;
  }
`

export const StyledDiv3 = styled.div`
  width: 301px;
  height: 652px;
  padding-top: 94px;
  transform: translate3d(0, -94px, 0);
  transition: transform 0.7s ease 0s;

  ${mediaQuery(1320)} {
    width: 268px;
  }

  .slick-slide {
    width: 319px !important; /* slickが生成するwidthを打ち消すimportant */
    position: relative;
  }

  .slick-current img {
    opacity: 1;
  }

  .slick-current div:before {
    content: '';
    position: absolute;
    height: 100%;
    border-left: 5px solid #1f9064;
    left: -2px;
    top: 0;
    margin-left: 13px;

    ${mediaQuery(1320)} {
      left: 30px;
    }
  }

  .slick-current div:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: -1px;
    top: 50%;
    margin-top: -8px;
    border-right: 13px solid #1f9064;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;

    ${mediaQuery(1320)} {
      left: 30px;
    }
  }
`

export const StyledImg = styled.img`
  display: block;
  opacity: 0.5;
  width: 284px;

  ${mediaQuery(1320)} {
    width: 220px;
  }
`

export const StyledP1 = styled.p`
  display: inline-block;
`
export const StyledLink1 = styled(Link)`
  color: #6d9cc8;

  ::after {
    content: '>';
    margin: 0 3px;
  }
`

export const StyledP2 = styled.p`
  font-family: '游明朝体', 'Yu Mincho', YuMincho, 'ヒラギノ明朝 Pro',
    'Hiragino Mincho Pro', 'MS P明朝', 'MS PMincho', serif;
  font-size: 3rem;
  margin-bottom: 20px;
  letter-spacing: 6px;
`

export const StyledP3 = styled.p`
  font-size: 1.9rem;
  line-height: 2.5;
  margin-bottom: 40px;
`
