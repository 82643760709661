import styled from 'styled-components'
import { mediaQuery } from '../../../../style'
import { brown1, white } from '../../styles'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

export const StyledDiv1 = styled.div``

export const StyledDt = styled.dt`
  position: relative;
  z-index: 1;
`

export const StyledLink1 = styled(Link)`
  display: inline-block;
  transition: opacity 0.4s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  ${mediaQuery()} {
    width: 94px;
  }
`

export const StyledDd = styled.dd`
  margin-top: 40px;
  background-color: ${white};
  border: 2px solid ${brown1};
  position: relative;
  z-index: 0;

  ${mediaQuery()} {
    margin-top: 20px;
  }

  &::before {
    width: calc(100% + 4px);
    height: 86px;
    background: url(/images/exdio/renewal/gariben_daigaku/components/dl2_desc_bg1.webp)
      no-repeat bottom center / 100% auto;
    position: absolute;
    right: -2px;
    left: -2px;
    transform: translateY(-88px);
    content: '';

    ${mediaQuery()} {
      height: 45px;
      background: url(/images/exdio/renewal/gariben_daigaku/components/dl2_desc_bg1.webp)
          no-repeat top center / auto 100%,
        url(/images/exdio/renewal/gariben_daigaku/components/dl2_desc_bg2.webp)
          repeat-x bottom left / auto 11px;
      transform: translateY(-46px);
    }
  }
`

export const StyledDiv2 = styled.div`
  min-height: 260px;

  .twitter-timeline,
  iframe {
    width: 100% !important;
  }
`

export const StyledLink2 = styled(Link)`
  margin: 10px auto;
  width: 560px;
  height: 50px;
  background-color: ${brown1};
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${white} !important;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  transition: opacity 0.4s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:after {
    margin: auto 0;
    width: 12px;
    height: 14px;
    background: url(/images/exdio/renewal/gariben_daigaku/components/btn1_icon.svg)
      no-repeat center / 100% auto;
    position: absolute;
    top: 0;
    right: 18px;
    bottom: 0;
    content: '';
  }

  ${mediaQuery()} {
    margin: 10px;
    width: auto;
  }
`
