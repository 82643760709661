import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  POINT_HISTORY_ADD_TYPE,
  POINT_HISTORY_PAYMETHOD,
  PRODUCT_SCHEMA,
  CHARGE_LICENSE_IDS,
  META_SCHEMA_ID
} from '../../../../../../constants/app'
import webApp from '../../../../utils/exdioWebAppUtils'
import { Link } from '../../../../../../sketch-platform/ui/routing'

const History = ({ histories = [], ...props }, context) => {
  if (!histories) return <div className="c-coinHist-cont-entry" />
  if (!histories.length) {
    return (
      <div className="c-coinHist-cont-entry no-history">
        ご利用履歴はありません。
      </div>
    )
  }

  /** チャージのライセンスIDであれば、種類の文字列を返す */
  const chargeLicenseTypeStr = (id) => {
    const result = CHARGE_LICENSE_IDS.reduce((r, { price }) => {
      const o = price.filter(({ ids }) => ids.includes(id))
      if (o && o.length) r.push(o[0].name)
      return r
    }, [])
    return result && result.length ? result[0] : null
  }

  /** routesの取得 */
  const getRoute = (meta, product, course, autoPlay = true) => {
    return webApp.utils.getProgramLinkRoutes(context, meta, product, course, {
      autoPlay
    })
  }

  return histories.map((history) => {
    let title = null
    let addType = ''
    let badgeClass = ''
    let payMethod = ''
    let showCoin = false
    let showPrice = false
    let showType = false
    let typeString = ''
    let showTitle = false
    let showPayMethod = false
    let meta = null
    let course = null
    let product = null
    switch (history.add_type) {
      case POINT_HISTORY_ADD_TYPE.PURCHASE:
      case POINT_HISTORY_ADD_TYPE.CONTINUE:
        const chargeLicenseType = chargeLicenseTypeStr(history.license_id)
        if (chargeLicenseType) {
          addType = '月額'
          badgeClass = 'c-badge-monthly'
          typeString = chargeLicenseType
        } else {
          addType = '追加'
          badgeClass = 'c-badge-in'
        }
        payMethod = POINT_HISTORY_PAYMETHOD[history.pay_method]
        showCoin = true
        showType = true
        showPayMethod = true
        showPrice = true
        break
      //case POINT_HISTORY_ADD_TYPE.USE_LICENSE: // 考慮不要。サーバ側で除去される
      //  addType = '月額';
      //  badgeClass = 'c-badge-monthly';
      //  payMethod = 'クレジットカード';
      //  showTitle = true;
      //  showPayMethod = true;
      //  course = _.get(history, ['course']) || null;
      //  title = _.get(course, ['name']) || '';
      //  break;
      case POINT_HISTORY_ADD_TYPE.USE_MATERIAL:
        addType = '消費'
        badgeClass = 'c-badge-out'
        payMethod = 'メダル'
        showCoin = true
        showTitle = true
        showPayMethod = true
        product = _.get(history, ['product']) || null
        if (_.get(product, ['schema_id']) === PRODUCT_SCHEMA.SINGLE_STORY.id) {
          const rightMetas = _.get(product, ['rights', 0, 'metas']) || []
          meta =
            rightMetas.find((m) => {
              return [
                META_SCHEMA_ID.EPISODE_NOT_FREE,
                META_SCHEMA_ID.LIVE_NOT_FREE
              ].includes(m.meta_schema_id)
            }) || null
          title = _.get(meta, ['name']) || ''
        } else {
          title = _.get(product, ['name']) || ''
        }
        break
      //case POINT_HISTORY_ADD_TYPE.EXPIRED: // 考慮不要。サーバ側で除去される
      //case POINT_HISTORY_ADD_TYPE.CANCEL: // 考慮不要。サーバ側で除去される
      case POINT_HISTORY_ADD_TYPE.ADMIN:
        addType = history.add_point > 0 ? '追加' : '消費'
        badgeClass = history.add_point > 0 ? 'c-badge-in' : 'c-badge-out'
        payMethod = 'Special'
        showCoin = true
        showType = true
        showPayMethod = true
        break
      case POINT_HISTORY_ADD_TYPE.TV_ASAHI_POINT:
        addType = '追加'
        badgeClass = 'c-badge-in'
        payMethod = 'テレ朝ポイントの利用'
        showCoin = true
        showType = true
        showPayMethod = true
        showPrice = false
        break
      default:
        return null
    }

    const useDate = moment(history.use_date).format('YYYY/MM/DD')

    const route = getRoute(meta, product, course)

    return (
      <div
        key={history.history_id}
        className="c-coinHist-cont-entry"
        {...props}
      >
        <div className="c-coinHist-cont-entry-date">
          <div className="c-coinHist-cont-entry-date-heading">利用日</div>
          <div className="c-coinHist-cont-entry-date-cont">{useDate}</div>
          <div className="c-coinHist-cont-entry-date-badge">
            <div className={badgeClass}>{addType}</div>
            {showCoin && (
              <div className="c-badge-coin">{history.add_point}</div>
            )}
          </div>
        </div>
        {showPayMethod && (
          <div className="c-coinHist-cont-entry-payMethod">
            <div className="c-coinHist-cont-entry-payMethod-heading">
              支払方法
            </div>
            <div className="c-coinHist-cont-entry-payMethod-cont">
              {payMethod}
            </div>
          </div>
        )}
        {showTitle && (
          <div className="c-coinHist-cont-entry-title">
            <div className="c-coinHist-cont-entry-title-heading">タイトル</div>
            <div className="c-coinHist-cont-entry-title-cont">
              <Link
                className="c-coinHist-cont-entry-title-cont-link"
                {...route}
              >
                {title}
              </Link>
            </div>
          </div>
        )}
        {showType && (
          <div className="c-coinHist-cont-entry-payType">
            <div className="c-coinHist-cont-entry-payType-heading">種類</div>
            <div className="c-coinHist-cont-entry-payType-cont">
              {typeString || `メダルチャージ（${history.add_point}）`}
            </div>
          </div>
        )}
        {showPrice && (
          <div className="c-coinHist-cont-entry-price">
            <div className="c-coinHist-cont-entry-price-heading">金額</div>
            <div className="c-coinHist-cont-entry-price-cont">
              {history.price}円（税込）
            </div>
          </div>
        )}
      </div>
    )
  })
}

export default History

History.propTypes = {
  histories: PropTypes.arrayOf(
    PropTypes.shape({
      add_type: PropTypes.string,
      add_point: PropTypes.string,
      history_id: PropTypes.string,
      license_id: PropTypes.string,
      material_id: PropTypes.string,
      pay_method: PropTypes.string,
      price: PropTypes.string,
      use_date: PropTypes.string
    })
  )
}

History.contextTypes = {
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}
