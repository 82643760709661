import React from 'react'

/* style */
import { StyledSmall } from './style'

/** Logirlプランページ | コピーライト */
const Copy = () => {
  return <StyledSmall>Copyright(C) tv asahi All rights reserved.</StyledSmall>
}

export default Copy
