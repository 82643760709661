import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../../../sketch-platform/ui/routing/Link';
import routes from '../../../../common/routes';
import webApp from '../../../utils/exdioWebAppUtils';

/** 詳細画面:番組TOPリンクコンポーネント */
export default function ProgramTopLink(props, context) {
  const { seriesId, seasonId, isFree, disabled } = props;
  if (!seriesId || !seasonId) return null;

  const route = webApp.utils.isApp(context) ? routes.app_program : routes.program;
  return (
    <div className="c-listMeta-inBox-sub-btnProgramTop" style={{ paddingTop: `${3}rem` }}>
      {disabled && (
        <a href="#" className="c-listMeta-inBox-sub-btnProgramTop-link">
          番組TOP
        </a>
      )}
      {!disabled && (
        <Link route={route} params={{ seriesId, seasonId }} className="c-listMeta-inBox-sub-btnProgramTop-link">
          番組TOP
        </Link>
      )}
    </div>
  );
}

ProgramTopLink.propTypes = {
  seriesId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFree: PropTypes.bool,
  disabled: PropTypes.bool
};

ProgramTopLink.defaultProps = {
  seriesId: null,
  seasonId: null,
  isFree: true,
  disabled: false
};

ProgramTopLink.contextTypes = {
  routeHandler: PropTypes.object
};
