/** mediaQuery */
export const mediaPc = '(min-width: 1025px)'
export const mediaSp = '(max-width: 1024px)'

export const contentWidth = '1080px'

/** colors */
export const white = '#fff'
export const black = '#333'
export const pink = '#e551a3'
export const darkPink = '#b2166c'
export const blue = '#09f'
export const darkBlue = '#007eff'
export const green = '#07b21a'
export const darkGreen = '#06a217'
export const yellow = '#fcee21'
export const darkYellow = '#fcdb29'
