import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import webApp from '../../../../utils/exdioWebAppUtils'

/** APP:視聴方法リンク */
export default class HowToPlayLink extends Component {
  static contextTypes = {
    routeHandler: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      show: false
    }

    this.onClickLink = this.onClickLink.bind(this)
    this.onClickClose = this.onClickClose.bind(this)
  }

  onClickLink(e) {
    e.preventDefault()
    this.setState({ show: true })
  }

  onClickClose(e) {
    if (e) e.preventDefault()
    this.setState({ show: false })
  }

  render() {
    const isApp = webApp.utils.isApp(this.context)
    const modalClassName = classnames('c-modal howtoPlay', {
      on: this.state.show
    })

    return (
      <div className="c-howtoPlay">
        <a href="#" className="c-howtoPlay-link" onClick={this.onClickLink}>
          視聴方法
        </a>
        <div className={modalClassName}>
          <div className="c-modal-inBox">
            <div className="c-modal-inBox-close" onClick={this.onClickClose} />
            <div className="c-modal-inBox-cont">
              <div className="howtoPlay-conts">
                <header className="howtoPlay-conts-head">
                  {isApp ? (
                    <React.Fragment>
                      アプリ内購入には対応していません。
                      <br />
                      <br />
                      ログインすることで既にお持ちのコンテンツをお楽しみいただけます。
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      このアプリを使用すると、ウェブや他のデバイスでテレ朝動画から購入した動画を再生できます。
                      <br />
                      <br />
                      このデバイスはアプリ内購入には対応していません。
                    </React.Fragment>
                  )}
                </header>
                <div className="howtoPlay-conts-cont">
                  <a
                    href="#"
                    className="howtoPlay-conts-cont-link"
                    onClick={this.onClickClose}
                  >
                    OK
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
