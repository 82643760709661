import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Progress from './Progress'
import ImgBottomLabel from './ImgBottomLabel'
import {
  ImgDuratuionLabel,
  ImgMask,
  ImgNewLabel,
  ImgRemainLabel
} from './styles'

/** 動画リンク: サムネイル部分 */
const Thumbnail = ({
  showMask,
  thumbnailAlt,
  thumbnailUrl,
  showNew,
  showRemaining,
  remaining,
  showDuration,
  duration,
  height,
  progress,
  barColor,
  isHide,
  showBottomLabel,
  product,
  course
}) => {
  return (
    <StyledDiv1>
      <StyledDiv2>
        {showMask && <StyledImgMask>配信終了しました</StyledImgMask>}
        <StyledImg
          src={thumbnailUrl}
          alt={thumbnailAlt}
          width="200"
          height="112"
          loading="lazy"
        />
      </StyledDiv2>
      {showNew && <ImgNewLabel />}
      {showRemaining && remaining && (
        <StyledImgRemainLabel>{remaining}</StyledImgRemainLabel>
      )}
      {showDuration && duration && (
        <StyledImgDurationLabel>{duration}</StyledImgDurationLabel>
      )}
      <StyledProgress
        progress={progress}
        height={height}
        barColor={barColor}
        isHide={isHide}
      />
      {showBottomLabel && (
        <StyledImgBottomLabel product={product} course={course} />
      )}
    </StyledDiv1>
  )
}

export default Thumbnail

Thumbnail.propTypes = {
  /** 「配信終了しました」のマスクを表示する */
  showMask: PropTypes.bool,
  /** サムネイル画像のalt */
  thumbnailAlt: PropTypes.string,
  /** サムネイル画像のsrc */
  thumbnailUrl: PropTypes.string,
  /** Newラベルを表示する */
  showNew: PropTypes.bool,
  /** 配信終了までの日数ラベルを表示する */
  showRemaining: PropTypes.bool,
  /** 配信終了までの日数ラベルの文言 */
  remaining: PropTypes.string,
  /** 動画の再生時間ラベルを表示する */
  showDuration: PropTypes.bool,
  /** 動画の再生時間ラベルの文言 */
  duration: PropTypes.string,
  /** プログレスバーの高さ(太さ) */
  height: PropTypes.number,
  /** プログレスバーの進捗具合(%) */
  progress: PropTypes.number,
  /** プログレスバーの色 */
  barColor: PropTypes.string,
  /** trueの時プログレスバーを隠す */
  isHide: PropTypes.bool,
  /** '見放題パック'のラベルを表示する */
  showBottomLabel: PropTypes.bool,
  /** 月額見放題パックコース('見放題パック'のラベルの描画判断に使用) */
  product: PropTypes.shape({
    name: PropTypes.string,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object
  }),
  /** 月額見放題パックコース('見放題パック'のラベルの描画判断に使用) */
  course: PropTypes.shape({
    name: PropTypes.string,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object
  })
}

Thumbnail.defaultProps = {
  showMask: false,
  thumbnailAlt: '',
  thumbnailUrl: '',
  showNew: false,
  showRemaining: false,
  remaining: '',
  showDuration: false,
  duration: '',
  progress: 0,
  showBottomLabel: false,
  product: {},
  course: {},
  height: 3,
  progress: null,
  barColor: '#cece00',
  isHide: false
}

const StyledDiv1 = styled.div`
  position: relative;
  overflow: hidden;
  width: 144px;
  height: 81px;
  z-index: 0;
`

export const StyledDiv2 = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(0, 0, 0, 0.1) 50%,
      rgba(0, 0, 0, 0.6) 100%
    );
    content: '';
    z-index: 1;
    transition: 0.3s;
  }
`

export const StyledImg = styled.img`
  width: 100%;
  height: auto;
  vertical-align: top;
  transition-duration: 0.3s;
`

const StyledImgMask = styled(ImgMask)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`

const StyledImgRemainLabel = styled(ImgRemainLabel)`
  position: absolute;
  bottom: 8px;
  left: 0;
  z-index: 1;
`

const StyledImgDurationLabel = styled(ImgDuratuionLabel)`
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;
  z-index: 1;
`

const StyledProgress = styled(Progress)`
  position: absolute;
  bottom: 0;
  z-index: 1;
`

const StyledImgBottomLabel = styled(ImgBottomLabel)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`
