import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button2 from '../../common/Button/Button2'

/** Nav｜List1 */
const List1 = ({ linkItems = {}, onClick = () => {} }) => {
  return (
    <StyledUl>
      {linkItems.map((link, i) => (
        <li key={`link-${i}`}>
          <Button2
            href={link.href}
            target={link.target}
            name={link.name}
            onClick={(event) => onClick(event, link.href)}
          />
        </li>
      ))}
    </StyledUl>
  )
}

export default List1

List1.propTypes = {
  /** ナビゲーションリンクの表示要素
   * @param {string} href - Button2コンポーネントに渡すhref
   * @param {string} target - Button2コンポーネントに渡すtarget
   * @param {string} name - リンクのテキスト
   */
  linkItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      name: PropTypes.string
    })
  ),
  /** ボタンクリック時に発火する関数 */
  onClick: PropTypes.func
}

const StyledUl = styled.ul`
  width: 100%;
  max-width: 475px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 15px;
`
