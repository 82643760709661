import React, { useEffect, useState, useRef } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'
import { ADVERTISING_SCHEMA_ID } from '../../../../../constants/app'
import useIsMounted from '../../../../hooks/useIsMounted'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import SliderArrow from './components/SliderArrow/'
import { mediaQuery } from '../../../../exdio/components/style'

/** 画像スライダー */
const ImageSlider = ({ objects = [] }, context) => {
  const model = context.falcorModel.batch(100)
  const config = context.models.config.data
  const [products, setProducts] = useState({})
  const [courses, setCourses] = useState({})
  const sliderRef = useRef(null)
  const isMounted = useIsMounted()

  /** 商品とコース情報を取得 */
  const getProductsAndCourses = async (items) => {
    if (!items || !items.length) {
      return Promise.resolve()
    }

    const advertisings = items
      .filter((obj) => obj.type === 'advertising')
      .map((obj) => obj.advertising)
    const productIds = advertisings
      .filter((ad) =>
        [
          ADVERTISING_SCHEMA_ID.PRODUCT,
          ADVERTISING_SCHEMA_ID.PRODUCT_LIVE
        ].includes(ad.schema_id)
      )
      .map((ad) => ad.values.product)
    const courseIds = advertisings
      .filter((ad) =>
        [
          ADVERTISING_SCHEMA_ID.COURSE,
          ADVERTISING_SCHEMA_ID.COURSE_LIVE
        ].includes(ad.schema_id)
      )
      .map((ad) => ad.values.course)

    const path = [
      ['product', productIds],
      ['course', courseIds]
    ]

    try {
      const result = await model.fetch(path)
      if (isMounted) {
        setProducts(get(result, 'json.product', {}))
        setCourses(get(result, 'json.course', {}))
      }
      return result
    } catch (error) {
      console.error('Failed to fetch products and courses:', error)
      return Promise.reject(error)
    }
  }

  useEffect(() => {
    if (objects && objects.length) {
      getProductsAndCourses(objects)
    }
  }, [objects])

  const onClick = (e) => {
    const slickSlideClassList = get(e.target, [
      'parentElement',
      'parentElement',
      'parentElement',
      'classList'
    ])
    if (slickSlideClassList && slickSlideClassList.contains('slick-active')) {
      // 真ん中の要素はリンク先に遷移
    } else {
      // 真ん中以外はスライド
      e.preventDefault()
      const clientRect = e.target.getBoundingClientRect()
      if (window.innerWidth / 2 < clientRect.left) {
        sliderRef.slickNext()
      } else {
        sliderRef.slickPrev()
      }
    }
  }

  /** ルート作成に必要な情報を取得 */
  const getRoute = (meta, product, course, autoPlay = true) => {
    return webApp.utils.getProgramLinkRoutes(context, meta, product, course, {
      autoPlay
    })
  }

  /** objから要素作成に必要な情報を取得 */
  const getObjData = (obj) => {
    /** 初期化 */
    const objData = {
      thumbnailUrl: null,
      alt: null,
      routeData: {
        route: null,
        params: null,
        query: null,
        href: null
      }
    }

    // メタ
    if (obj.meta) {
      objData.thumbnailUrl =
        obj.meta.thumbnail_url || context.models.config.data.default_thumbnail
      const [metaName, subTitle] = webApp.utils.titles(obj.meta)
      objData.alt = `${metaName} ${subTitle}`
      objData.routeData = getRoute(obj.meta, null, null)
      return objData
    }

    if (obj.advertising) {
      // パック
      if (
        ADVERTISING_SCHEMA_ID.PRODUCT === obj.advertising.schema_id ||
        ADVERTISING_SCHEMA_ID.PRODUCT_LIVE === obj.advertising.schema_id
      ) {
        const productId = get(obj, ['advertising', 'values', 'product']) || null
        const product = productId && products[productId]
        objData.thumbnailUrl =
          get(product, ['thumbnail_url']) ||
          context.models.config.data.default_thumbnail
        objData.alt = obj.advertising.name
        objData.routeData = getRoute(null, product, null)
        return objData
      }

      // 月額見放題
      if (
        ADVERTISING_SCHEMA_ID.COURSE === obj.advertising.schema_id ||
        ADVERTISING_SCHEMA_ID.COURSE_LIVE === obj.advertising.schema_id
      ) {
        const courseId = get(obj, ['advertising', 'values', 'course']) || null
        const course = courseId && courses[courseId]
        objData.thumbnailUrl =
          get(course, ['thumbnail_url']) ||
          context.models.config.data.default_thumbnail
        objData.alt = obj.advertising.name
        objData.routeData = getRoute(null, null, course)
        return objData
      }

      // 固定リンク
      objData.thumbnailUrl =
        get(obj, ['advertising', 'creatives', 0, 'attachment', 'file_url']) ||
        context.models.config.data.default_thumbnail
      objData.alt = obj.advertising.name
      objData.routeData.href = obj.advertising.url
      return objData
    }

    return null
  }

  if (!objects || !objects.length) return null

  // 表示対象が1件だと表示崩れを起こすため、2件として設定.
  const objForSlide = [...objects]
  if (objects.length === 1) objForSlide.push(objects[0])

  /** react-slickライブラリ設定 */
  const SLICK_SETTINGS = {
    dots: true,
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    variableWidth: true,
    nextArrow: <SliderArrow type="next" />,
    prevArrow: <SliderArrow type="prev" />
  }

  return (
    <StyledDiv1>
      <StyledDiv3>
        <Slider {...SLICK_SETTINGS} ref={sliderRef}>
          {objForSlide.map((obj) => {
            const objData = getObjData(obj)
            if (!objData) return null

            return (
              <StyledDiv2
                key={`${obj.type}-${obj.id}`}
                className="slick-slide-program"
              >
                <StyledLink {...objData.routeData} onClick={(e) => onClick(e)}>
                  <StyledImg
                    src={get(objData, ['thumbnailUrl'])}
                    alt={get(objData, ['alt'])}
                    width="700"
                    height="394"
                  />
                </StyledLink>
              </StyledDiv2>
            )
          })}
        </Slider>
      </StyledDiv3>
    </StyledDiv1>
  )
}

export default ImageSlider

ImageSlider.propTypes = {
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      /** パレット>メタ */
      meta: PropTypes.shape({
        meta_id: PropTypes.number.isRequired,
        meta_schema_id: PropTypes.number.isRequired,
        name: PropTypes.string,
        thumbnail_url: PropTypes.string
      }),
      /** パレット>広告 */
      advertising: PropTypes.shape({
        advertising_id: PropTypes.number,
        url: PropTypes.string.isRequired,
        name: PropTypes.string,
        creatives: PropTypes.arrayOf(
          PropTypes.shape({
            attachment: PropTypes.shape({
              file_url: PropTypes.string.isRequired
            })
          })
        )
      })
    })
  )
}

ImageSlider.contextTypes = {
  models: PropTypes.shape({}),
  falcorModel: PropTypes.shape({}),
  history: PropTypes.shape({}),
  routeHandler: PropTypes.shape({})
}

const StyledDiv1 = styled.div`
  position: relative;
  margin-top: 20px;

  ${mediaQuery()} {
    margin-top: 0;
  }
`

const StyledDiv2 = styled.div`
  height: 394px;
  overflow: hidden;
  border-radius: 5px;
  background-color: gray;
  outline: none;

  ${mediaQuery(760)} {
    height: 170px;
  }
`

const StyledLink = styled(Link)`
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
`

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0.5;
`

const StyledDiv3 = styled.div`
  margin-top: 20px;

  ${mediaQuery('mx')} {
    margin-top: 0;
  }

  ${mediaQuery(760)} {
    height: 180px;
  }

  .slick-slide {
    max-height: 600px;
    display: block;
    width: 720px;
    height: 405px;
    padding: 0 10px;

    ${mediaQuery(760)} {
      width: 320px;
      height: 180px;
      padding: 0 5px;
    }

    &.slick-active {
      ${StyledImg} {
        opacity: 1;
      }
    }

    @media (hover: hover) {
      &:hover {
        ${StyledLink} {
          transform: scale(1.1);
        }
      }
    }
  }

  .slick-list {
    ${mediaQuery(760)} {
      height: 180px;
    }

    ${mediaQuery(761, 'min')} {
      max-height: 405px;
    }
  }

  .slick-track {
    max-height: 405px;
  }

  // 矢印デザインを上書きする
  .slick-prev {
    width: calc((100vw - 750px) / 2);
    height: 395px;
    z-index: 1;
    position: absolute;
    left: 0;
    &::before {
      content: none;
    }
    ${mediaQuery(760)} {
      width: calc((100vw - 350px) / 2);
      height: 180px;
    }
  }

  .slick-next {
    width: calc((100vw - 750px) / 2);
    height: 395px;
    z-index: 1;
    position: absolute;
    right: 0;
    &::before {
      content: none;
    }
    ${mediaQuery(760)} {
      width: calc((100vw - 350px) / 2);
      height: 180px;
    }
  }

  .slide-prev {
    width: 50px;
    height: 50px;
    border-top: 5px solid rgb(255, 255, 255);
    border-right: 5px solid rgb(255, 255, 255);
    transform: rotate(-135deg);
    z-index: 1;
    position: absolute;
    top: 180px;
    left: 50px;
    &::before {
      content: none;
    }
    ${mediaQuery(760)} {
      width: 15px;
      height: 15px;
      border-top: 2px solid rgb(255, 255, 255);
      border-right: 2px solid rgb(255, 255, 255);
      top: 76px;
      left: 10px;
    }
  }

  .slide-next {
    width: 50px;
    height: 50px;
    border-top: 5px solid rgb(255, 255, 255);
    border-right: 5px solid rgb(255, 255, 255);
    transform: rotate(45deg);
    z-index: 1;
    position: absolute;
    top: 180px;
    right: 50px;
    &::before {
      content: none;
    }
    ${mediaQuery(760)} {
      width: 15px;
      height: 15px;
      border-top: 2px solid rgb(255, 255, 255);
      border-right: 2px solid rgb(255, 255, 255);
      top: 76px;
      right: 10px;
    }
  }

  // 下部ドットの調整
  .slick-dots {
    bottom: -15px;
    li {
      margin: 0;
      &.slick-active {
        button {
          &:before {
            color: #f0027f;
          }
        }
      }
    }
  }
`
