import React, { memo, useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import routes from '../../routes'
import NavItem from '../NavItems/NavItem'
import DropdownItem from '../NavItems/DropdownItem'
import useMediaQuery from '../../../hooks/useMediaQuery'
import useIsMounted from '../../../hooks/useIsMounted'

/** style */
import styled, { css } from 'styled-components'
import { mediaQuery, breakPoints } from '../../../exdio/components/style'

/** SPサブメニュ */
const SpSubNavigation = ({ spOff, ...props }, context) => {
  const config = context.models.config.data
  const navigationMenu = config.global_navigation
  const [show, setShow] = useState({}) // object ドロップダウン 開:true/閉:false
  const [scrollValue, setScrollValue] = useState({ left: false, right: false }) // object 横スクロールボタン 開:true/閉:false
  const target = useRef(null)
  const isSmp = useMediaQuery()
  const isMounted = useIsMounted()
  let imgLength
  let imgLoadedCount = 0

  /**
   * 現在のページがメニュに該当するか
   * @param {boolean} isModalOpen
   */
  const isCurrent = (menu) => {
    const { routeHandler } = context
    const currentPath = routeHandler.path
    const menuPathList = (menu.subNavigation || [menu])
      .filter((m) => routes[m.route])
      .map((m) => routes[m.route].makePath(m.params, m.query))
    return menuPathList.includes(currentPath)
  }

  /** ドロップダウンメニュ開閉 */
  const toggleNavMenu = useCallback(
    /**
     * @param {string} stateName
     */
    (stateName) => {
      setShow((oldShow) => {
        const _show = { ...oldShow }
        if (stateName !== null) _show[stateName] = !_show[stateName]
        let keys = Object.keys(_show)
        if (stateName !== null) keys = keys.filter((k) => k !== stateName)
        keys.forEach((k) => {
          _show[k] = false
        })
        return _show
      })
    },
    []
  )

  const getTargetWidth = () => target.current.clientWidth
  const getScrollWidth = () => target.current.scrollWidth
  const getRightEnd = () => getScrollWidth() - getTargetWidth()

  /** 現在のページがメニュにある場合、該当メニュをセンターに横スクロール */
  const setCurrentMenuCenter = () => {
    const currentMenu = (() => {
      const currentIndex = navigationMenu
        .map((menu) => isCurrent(menu))
        .indexOf(true)
      return target.current.querySelector(`li:nth-child(${currentIndex + 1}`)
    })()
    if (!currentMenu) return
    const currentRectX = currentMenu.getBoundingClientRect().left
    const scrollValue =
      currentRectX - (getTargetWidth() / 2 - currentMenu.clientWidth / 2)
    if (currentRectX > getTargetWidth() / 2)
      target.current.scroll({ left: scrollValue, behavior: 'instant' })
  }

  /** 矢印をクリックしたら両端にスクロール */
  const navScroll = useCallback(
    /**
     * @param {('left'|'right')} direction
     */
    (direction) => {
      if (['left', 'right'].includes(direction) === -1) return
      const left = direction === 'left' ? 0 : getRightEnd()
      target.current.scroll({ left, behavior: 'smooth' })
    },
    []
  )

  /** 矢印の表示、非表示 */
  const onScroll = () => {
    const _value = { ...scrollValue }
    for (const direction of ['left', 'right']) {
      const endValue = direction === 'left' ? 0 : -getRightEnd()
      const currentValue =
        direction === 'left'
          ? target.current.scrollLeft
          : /** Androidの場合一番右にスクロール出来ないバグ発生
             *  対処のため小数点の切り上げで対応している */
            -Math.ceil(target.current.scrollLeft)

      if (currentValue > endValue && !_value[direction]) {
        _value[direction] = true
      } else if (currentValue === endValue && _value[direction]) {
        _value[direction] = false
      }
    }

    setScrollValue((oldValue) => {
      if (JSON.stringify(_value) !== JSON.stringify(oldValue)) {
        return _value
      } else {
        return oldValue
      }
    })
  }

  /** 画面遷移の場合は画像読み込みが未のため */
  const imgLoad = () => {
    imgLoadedCount++
    if (imgLoadedCount === imgLength) {
      onScroll()
      setCurrentMenuCenter()
    }
  }

  useEffect(() => {
    if (!isMounted || spOff) return
    target.current.addEventListener('scroll', onScroll)
    return () => {
      if (target && target.current) {
        target.current.removeEventListener('scroll', onScroll)
      }
    }
  }, [scrollValue, isMounted, isSmp])

  useEffect(() => {
    if (!isMounted || spOff) return

    // レンダリングのタイミングによって画像の取得タイミングが異なるため処理を追加
    const targetChildImg = target.current.querySelectorAll('img')
    imgLength = targetChildImg.length
    for (const img of targetChildImg) {
      if (img.complete) imgLoadedCount++
      img.addEventListener('load', imgLoad)
    }
    // 初期ロードの場合は画像読み込みが済のため
    if (imgLoadedCount === imgLength) {
      onScroll()
      setCurrentMenuCenter()
    }

    return () => {
      for (const img of targetChildImg) {
        img.removeEventListener('load', imgLoad)
      }
    }
  }, [isMounted, isSmp])

  if (spOff || !isMounted) return null

  return (
    <StyledDiv1 {...props}>
      <StyledDiv2>
        <StyledUl ref={target}>
          {navigationMenu.map((menu) => {
            return (
              <NavItem
                key={menu.title}
                menu={menu}
                toggleNavMenu={toggleNavMenu}
                show={show}
              />
            )
          })}
        </StyledUl>
        {scrollValue.left && (
          <StyledButton direction="left" onClick={() => navScroll('left')}>
            <img src="/images/exdio/renewal/arrow_sub_nav.svg" alt="" />
          </StyledButton>
        )}

        {scrollValue.right && (
          <StyledButton direction="right" onClick={() => navScroll('right')}>
            <img src="/images/exdio/renewal/arrow_sub_nav.svg" alt="" />
          </StyledButton>
        )}
        {isSmp &&
          navigationMenu
            .filter((menu) => menu.subNavigation)
            .map((menu) => {
              return (
                <DropdownItem
                  key={menu.title}
                  menu={menu}
                  toggleNavMenu={toggleNavMenu}
                  show={show}
                />
              )
            })}
      </StyledDiv2>
    </StyledDiv1>
  )
}

export default memo(SpSubNavigation)

SpSubNavigation.propTypes = {
  /** SPで非表示にするか(タブレットサイズで表示しSPで非表示にする場合にtrueにする) */
  spOff: PropTypes.bool
}

SpSubNavigation.defaultProps = {
  spOff: false
}

SpSubNavigation.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  history: PropTypes.object,
  routeHandler: PropTypes.object
}

// SpSubNavigationを表示する幅
const showWidth = 1137

const StyledDiv1 = styled.div`
  width: 100%;
  max-width: ${breakPoints.mx}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

const StyledDiv2 = styled.div`
  display: none;
  align-items: center;
  height: 57px;
  width: 100%;
  position: relative;
  ${mediaQuery(showWidth)} {
    display: flex;
  }
`

const StyledUl = styled.ul`
  display: flex;
  align-items: center;
  font-weight: 600;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery()} {
    padding-left: 1.6rem;
    font-size: 1.2rem;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
`

const StyledButton = styled.button`
  display: none;
  align-items: center;
  height: 57px;
  width: 100%;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  display: none;
  position: absolute;
  top: 9px;
  width: 59px;
  height: 38px;
  ${mediaQuery(showWidth)} {
    display: flex;
  }

  ${mediaQuery('cs2')} {
    display: block;
  }

  ${({ direction }) => {
    switch (direction) {
      case 'right':
        return css`
          right: 0px;
        `
      case 'left':
        return css`
          left: 0px;
          img {
            transform: scale(-1, 1);
          }
        `
      default:
        break
    }
  }}
`
