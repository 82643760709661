import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import webApp from '../../../utils/exdioWebAppUtils';
import routes from '../../../../common/routes';
import Link from '../../../../../sketch-platform/ui/routing/Link';

/** 詳細画面:サイドナビ:他のシリーズ/キーワードタブコンポーネント */
export default class SideTab extends Component {
  static propTypes = {
    currentSeasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    otherSeasons: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    currentSeasonId: '',
    otherSeasons: []
  };

  static contextTypes = {
    falcorModel: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.state = {
      currentTab: 'series'
    };

    this.onClickTab = this.onClickTab.bind(this);
    this.onClickOtherSeries = this.onClickOtherSeries.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClickTab(e, key) {
    e.preventDefault();
    this.setState({ currentTab: key });
  }

  onClickOtherSeries(e, season) {
    e.preventDefault();
    webApp.utils.goToProgramLink(this.context, season);
  }

  getRoute(meta, product, course, autoPlay = true) {
    return webApp.utils.getProgramLinkRoutes(this.context, meta, product, course, { autoPlay })
  }

  render() {
    const { otherSeasons } = this.props;
    if (!otherSeasons || !otherSeasons.length) return null;

    const { currentTab } = this.state;
    const showSeries = !!otherSeasons.length;
    let tagSeason = [];
    if (otherSeasons) {
      _.forEach(otherSeasons, season => {
        if (season && season.tags) {
          tagSeason = tagSeason.concat(season.tags);
        }
      });
    }
    tagSeason = tagSeason.filter((e, i, self) => self.indexOf(e) === i); // distinct
    const showKeywords = !!tagSeason.length;
    if (!showSeries && !showKeywords) return null;

    const searchRoute = webApp.utils.isApp(this.context) ? routes.app_search : routes.search;

    /* 並べ替え */
    const sortedOtherSeasons = otherSeasons.sort((a, b) => {
      // parents_seriesのidで降順
      if (_.get(a, ['values', 'parents_series', 'id']) !== _.get(b, ['values', 'parents_series', 'id'])) {
        return _.get(b, ['values', 'parents_series', 'id']) - _.get(a, ['values', 'parents_series', 'id'])
      }

      // avails_SeasonNumberで降順
      return _.get(b, ['values', 'avails_SeasonNumber']) - _.get(a, ['values', 'avails_SeasonNumber'])
    })

    return (
      <div className="common-switching-box">
        <header className="switching-header">
          <ul className="switching-nav">
            {showSeries && (
              <li
                className={classnames({
                  current: currentTab === 'series',
                  widthFull: showSeries && !showKeywords
                })}
              >
                <a href="#" onClick={e => this.onClickTab(e, 'series')}>
                  関連番組
                </a>
              </li>
            )}
            {showKeywords && (
              <li
                className={classnames({
                  current: currentTab === 'keywords',
                  widthFull: !showSeries && showKeywords
                })}
              >
                <a href="#" onClick={e => this.onClickTab(e, 'keywords')}>
                  キーワード
                </a>
              </li>
            )}
          </ul>
        </header>
        <div className="switching-content">
          <ul className="label">
            {showSeries &&
              currentTab === 'series' &&
              sortedOtherSeasons.filter(m => !!m).map(meta => {
                if(!meta || !meta.values) return null;
                const { route, params, query } = this.getRoute(meta, null, null)

                let metaName = null;
                if (meta && meta.values.evis_FrontDisplayTitle != null && meta.values.evis_FrontDisplayTitle.length > 0) {
                  metaName = meta.values.evis_FrontDisplayTitle;
                } else if(meta.values.avails_SeriesTitleDisplayUnlimited != null && meta.values.avails_SeriesTitleDisplayUnlimited.length > 0 &&
                          meta.values.avails_SeasonTitleDisplayUnlimited != null && meta.values.avails_SeasonTitleDisplayUnlimited.length > 0) {
                  metaName = meta.values.avails_SeriesTitleDisplayUnlimited + " " + meta.values.avails_SeasonTitleDisplayUnlimited;
                } else {
                  metaName = meta.name;
                }
                return (
                  <li key={meta.meta_id}>
                    <Link
                      route={route}
                      params={params}
                      query={query}
                    >
                      {metaName}
                    </Link>
                  </li>
                );
              })
            }
            {showKeywords &&
              currentTab === 'keywords' &&
              tagSeason.map(tag => (
                <li key={tag}>
                  <Link route={searchRoute} query={{ keyword: `タグ:${tag}` }}>
                    {tag}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}
