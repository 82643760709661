/* eslint-disable react/require-default-props */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import moment from 'moment'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import {
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE,
  DIO_PLAYER_DEVICE
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  checkAvailableStatus,
  play,
  purchaseEpisode,
  getPrice
} from '../EpisodeDefault/util'

/* hooks */
import useMediaQuery from '../../../../hooks/useMediaQuery'
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/* components */
import Summary from './Panel/Summary'
import Vote from './Panel/Vote'
import Heading1 from './Heading1'
import ButtonList2 from './ButtonList2'
import Banners from './Banners'

/* style */
import {
  colors,
  GlobalStyle,
  StyledWrapper,
  StyledHeader,
  StyledTabs,
  StyledContainer,
  StyledPlayer,
  StyledMain,
  StyledButton1,
  StyledLink1,
  StyledLink2,
  StyledSide,
  StyledSection1,
  StyledSection2,
  StyledSection3,
  StyledDiv1,
  StyledFilterSort,
  StyledP,
  StyledEpisodeList,
  StyledChat,
  StyledComment,
  StyledEpisodeFooter
} from './style'

moment.createFromInputFallback = (config) => ({
  ...config,
  _d: new Date(config._i)
})

/** 単話ページ */
const EpisodeLiveGariben = (
  {
    seasonId = '',
    episodeId = '',
    meta = {},
    // programMeta = {},
    howToPlay = {},
    product = [],
    course = {},
    status = {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    sendPlayLog = () => {},
    loaded = false
  },
  context
) => {
  const config = context.models.config.data
  const model = context.falcorModel.batch(100)
  const device = webApp.utils.getDevice()
  const isPcDevice = device === DIO_PLAYER_DEVICE.PC
  const isSp = useMediaQuery()
  const purchaseHref = `${config.purchase_plan.gariben_daigaku}?utm_source=live&utm_medium=bt&utm_id=garilive`
  const conversionText = '私立ガリベン大学に会員登録する'

  const [title, subTitle] = webApp.utils.titles(meta)
  const metaName = `${title} ${subTitle}`

  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])

  const frontDisplayConfig = get(meta, [
    'values',
    'live_stvod_front_display_config'
  ])

  /* コメント */
  const commentHash = get(frontDisplayConfig, ['comment_hash'])
  const hasComment = Boolean(commentHash)

  /* チャット */
  const chatHash = get(frontDisplayConfig, ['chat_hash'])
  const hasChat = Boolean(chatHash)

  /* 投票 */
  const voteButtonData = get(frontDisplayConfig, ['button_list'])
  const hasVote = get(voteButtonData, ['visible']) || false

  const [progress, setProgress] = useState(webApp.utils.progress(config, meta))
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const [showThumb, setShowThumb] = useState(true)
  const [isArchived, setIsArchived] = useState(false)
  const [archives, setArchives] = useState([])
  const [palette, setPalette] = useState([])
  const [featureEpisodes, setFeatureEpisodes] = useState([])
  const [currentTabId, setCurrentTabId] = useState('')
  const [tabsDisplayState, setTabsDisplayState] = useState('static')

  const intervalId = useRef(null)
  const playerRef = useRef(null)
  const containerRef = useRef(null)
  const tabsRef = useRef(null)
  const oldContainerScrollTop = useRef(0)
  const scrollTargetRef = useRef(null)
  const handlePostMessage = useRef(() => {})
  const setPlayerRef = (e) => {
    playerRef.current = e
  }

  const setVh = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const searchParams = useSearchParams({
    seasonIds: [config.svod.gariben_daigaku.live_archives.season_id],
    sortedBy: 'delivery_start_at_newer'
  })

  const resetIsArchived = () => {
    if (!size(meta)) return

    axios
      .get(`${config.chat.endpoint}/${chatHash}/information`)
      .then(({ data }) => {
        const chatStatus = get(data, ['status'])
        setIsArchived(chatStatus === 2)
        /**
         * READY: 0
         * LIVE: 1
         * ARCHIVED: 2
         */
      })
  }

  /**
   * 視聴権を確認して再生するか購入ページに飛ばすかをしている
   */
  const onClickPlay = (e, asSvod = false) => {
    if (e) e.preventDefault()

    if (!isLoggedIn) {
      context.history.push(
        routes.login.makePath(null, { redirect: context.routeHandler.path })
      )
      return
    }

    switch (checkAvailableStatus({ status, product }, context, asSvod)) {
      case 'PLAY':
        play(playerRef.current, sendPlayLog)

        /** チャットアーカイブを開始する */
        if (isArchived) {
          clearInterval(intervalId.current)
          intervalId.current = setInterval(() => {
            const DioPlayer = document.getElementById('DIOplayer')
            const videoElm = DioPlayer ? DioPlayer.querySelector('video') : null
            const seekTime = videoElm ? Math.floor(videoElm.currentTime) : 0
            handlePostMessage.current(seekTime)
          }, 1000)
        }
        break
      case 'PURCHASE_EPISODE':
        purchaseEpisode({ episodeId, seasonId }, context)
        break
      case 'PURCHASE_PLAN':
        window.open(config.purchase_plan.gariben_daigaku)
        break
      default:
        break
    }
  }

  const onClosePlayer = () => {
    setProgress(webApp.utils.progress(config, meta))
  }

  const toggleShowThumb = () => {
    setShowThumb(!showThumb)
  }

  const setPostMessage = (postMessage) => {
    handlePostMessage.current = postMessage
  }

  /** ライブ配信アーカイブのメタを取得 */
  const getLiveArchivesMeta = () => {
    const path = ['metas', config.svod.gariben_daigaku.live_archives.season_id]
    return model.fetch([path]).then((result) => {
      return get(result, ['json', ...path])
    })
  }

  /** 神回アーカイブを取得 */
  const getArchiveEpisodes = async () => {
    const archiveSeasonId = config.svod.gariben_daigaku.archives.season_id
    const path = ['meta', 'children', archiveSeasonId]
    return model
      .fetch([path])
      .then((result) => get(result, ['json', ...path]), [])
      .catch((err) => console.error(err))
  }

  /** パレットの取得 */
  const getPalette = () => {
    const path = ['paletteByKey', 'garidai_livepage']
    return model
      .fetch([path])
      .then((result) => get(result, ['json', ...path]), [])
      .catch((err) => console.error(err))
  }

  /** パレットobjectの整形 */
  const shapePaletteObjects = (objects) => {
    return objects
      .map(({ meta: objectMeta = {} }) => objectMeta)
      .filter((e) => e)
  }

  /**
   * PC すべて表示する
   * SP: currentTabIdと一致する場合は表示する
   */
  const isCurrentTab = (targetTabId = '') => {
    if (!isSp) return true
    return currentTabId === targetTabId
  }

  /** タブの表示/非表示切り替え */
  const toggleTabs = () => {
    if (!isSp) return

    const containerScrollTop = containerRef.current.scrollTop
    const tabsHeight = tabsRef.current.offsetHeight
    const displayState = (() => {
      // 上にスクロールしているとき
      if (containerScrollTop < oldContainerScrollTop.current) return 'show'
      // タブの高さより下
      if (containerScrollTop > tabsHeight) return 'hide'
      // タブの高さより上
      return 'show'
    })()

    if (displayState !== tabsDisplayState) {
      setTabsDisplayState(displayState)
    }

    oldContainerScrollTop.current = containerScrollTop
  }

  const smoothScroll = () => {
    if (!scrollTargetRef.current) return
    const marginTop = 60
    const targetPos = scrollTargetRef.current.offsetTop - marginTop
    window.scrollTo({ top: targetPos, behavior: 'smooth' })
  }

  useEffect(() => {
    resetIsArchived()
    setVh()
    ;(async () => {
      const liveArchivesMeta = await getLiveArchivesMeta()

      const resPalette = await getPalette()
      setPalette(resPalette)
      const paletteObjects = shapePaletteObjects(
        get(resPalette, ['objects'], [])
      )
      setFeatureEpisodes(paletteObjects)

      const resArchives = await getArchiveEpisodes()
      const sortedArchives = resArchives.sort(
        (
          { delivery_start_at: deliveryStartAtA = '' },
          { delivery_start_at: deliveryStartAtB = '' }
        ) => {
          const dateA = new Date(deliveryStartAtA)
          const dateB = new Date(deliveryStartAtB)
          return dateA > dateB ? -1 : 1
        }
      )
      setArchives(sortedArchives)

      searchParams.set({
        childEpisodeIds: get(
          liveArchivesMeta,
          ['values', 'child_episode_ids'],
          []
        )
      })
    })()

    return () => {
      clearInterval(intervalId.current)
    }
  }, [])

  useEffect(() => {
    const isAutoPlay = context.routeHandler.query.auto === 't'
    if (loaded && status && isPcDevice && isAutoPlay) {
      const isFree = EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode)
      const isAuthFree =
        EPISODE_DISPLAY_MODE.SVOD_AUTH_FREE === status.displayMode &&
        webApp.utils.isLoggedIn(context)
      if (isFree || isAuthFree || status.isPurchased || withValidPreviewToken) {
        toggleShowThumb()
        onClickPlay()
      }
    }
  }, [loaded, status])

  return (
    <StyledWrapper>
      <StyledContainer onScroll={toggleTabs} ref={containerRef}>
        {!isSp && <StyledHeader heading={subTitle} />}
        <Helmet>
          <link
            rel="icon"
            href="/images/exdio/renewal/gariben_daigaku/top/gbu_favicon.webp"
          />
          <link
            rel="apple-touch-icon"
            href="/images/exdio/renewal/gariben_daigaku/top/gbu_favicon.webp"
          />
        </Helmet>

        <StyledPlayer
          meta={meta}
          product={product}
          course={course}
          status={status}
          progress={progress}
          onClickPlay={onClickPlay}
          onClosePlay={onClosePlayer}
          setPlayerRef={setPlayerRef}
        />

        <StyledTabs
          hasVote={hasVote}
          hasChat={hasChat}
          hasComment={hasComment}
          isLoggedIn={isLoggedIn}
          currentTabId={currentTabId}
          setCurrentTabId={setCurrentTabId}
          displayState={tabsDisplayState}
          ref={tabsRef}
        />

        <StyledMain>
          {/* ログイン */}
          {isCurrentTab() && (
            <Summary
              meta={meta}
              metaName={subTitle}
              status={status}
              isLoggedIn={isLoggedIn}
            />
          )}

          {/* 投票ボタン */}
          {hasVote && isCurrentTab('vote') && (
            <Vote
              meta={meta}
              status={status}
              price={getPrice(meta, product, status, course)}
              buttonData={voteButtonData}
              isLoggedIn={isLoggedIn}
            />
          )}

          {!status.isPurchased && (
            <StyledButton1 type="button" onClick={smoothScroll}>
              <img
                src="/images/exdio/renewal/gariben_daigaku/live/bnr_01.png"
                width="850"
                height="90"
                alt=""
              />
            </StyledButton1>
          )}
          {hasComment && (
            <StyledComment hash={commentHash} show={isCurrentTab('comment')} />
          )}

          {size(featureEpisodes) > 0 && (
            <StyledSection2 ref={scrollTargetRef}>
              <Heading1>{palette.header}</Heading1>
              <StyledEpisodeList episodes={featureEpisodes.slice(0, 4)} />
              {!status.isPurchased && (
                <StyledLink1 href={purchaseHref}>{conversionText}</StyledLink1>
              )}
            </StyledSection2>
          )}

          {size(archives) > 0 && (
            <StyledSection3>
              <Heading1>
                ガリベンガーV 神回アーカイブ
                {size(archives) > 2 && (
                  <StyledLink2
                    route={routes.program}
                    params={{
                      seriesId: String(
                        config.svod.gariben_daigaku.archives.series_id
                      ),
                      seasonId: String(
                        config.svod.gariben_daigaku.archives.season_id
                      )
                    }}
                  >
                    もっと見る
                  </StyledLink2>
                )}
              </Heading1>
              <StyledP>
                「ガリベンガーV」の過去放送回から、月替わりで神回をお届け！
              </StyledP>
              <StyledEpisodeList
                episodes={Object.values(archives).slice(0, 2)}
              />
              {!status.isPurchased && (
                <StyledLink1 href={purchaseHref}>{conversionText}</StyledLink1>
              )}
            </StyledSection3>
          )}
        </StyledMain>

        <StyledSide currentTabId={currentTabId}>
          {hasChat && (
            <StyledChat
              hash={chatHash}
              setPostMessage={setPostMessage}
              show={isCurrentTab('chat')}
            />
          )}

          {!isSp && <ButtonList2 meta={meta} metaName={metaName} />}

          {!isSp && <Banners />}

          <StyledSection1 className="c-switchableListGrid--shinchan">
            <Heading1>ライブ配信 アーカイブ</Heading1>
            {size(searchParams.state.childEpisodeIds) > 0 && (
              <StyledFilterSort
                defaultListType="default"
                searchParams={searchParams}
                episodeListItemProps={{
                  showNew: true,
                  showChecked: true,
                  onlySubTitle: true,
                  clampWidth: 4
                }}
                hideSwitches
              />
            )}
          </StyledSection1>
        </StyledSide>

        <StyledDiv1>
          <StyledLink1
            route={routes.plan}
            params={{ slug: 'gariben_daigaku' }}
            bgColor={colors.yellow}
            color={colors.black}
          >
            私立ガリベン大学TOPに戻る
          </StyledLink1>
        </StyledDiv1>

        <StyledEpisodeFooter
          copyrights={get(meta, ['values', 'evis_Copyright'])}
          courseId={get(howToPlay, ['courses', 0, 'course_id']) || null}
        />
      </StyledContainer>
      <GlobalStyle />
    </StyledWrapper>
  )
}

export default EpisodeLiveGariben

EpisodeLiveGariben.propTypes = {
  seasonId: PropTypes.string,
  episodeId: PropTypes.string,

  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({}).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  programMeta: PropTypes.shape({
    values: PropTypes.shape({
      banner_1: PropTypes.number
    })
  }),
  howToPlay: PropTypes.shape({}),
  product: PropTypes.shape({}),
  // 月額見放題
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.shape({}),
    values: PropTypes.shape({})
  }),

  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),

  // 再生ログ送信
  sendPlayLog: PropTypes.func,

  // データ取得が完了したか
  loaded: PropTypes.bool
}

EpisodeLiveGariben.contextTypes = {
  falcorModel: PropTypes.shape({}),
  models: PropTypes.shape({}),
  routeHandler: PropTypes.shape({}),
  history: PropTypes.shape({})
}
