import React, { memo } from 'react'
import styled from 'styled-components'
import { mediaSp } from '../common/styles'

/** 全英オープン List */
const List = ({ children, ...props }) => {
  return (
    <StyledUl {...props}>
      {children.map((child, i) => (
        <StyledLi key={i}>{ child }</StyledLi>
      ))}
    </StyledUl>
  )
}

export default memo(List)

const StyledUl = styled.ul`
font-size: 1.2rem;

@media ${mediaSp} {
  display: flex;
  font-size: 1.3rem;
  line-height: 1.5;
}
`

const StyledLi = styled.li`
&:not(:first-child) {
  margin-top: 12px;

  @media ${mediaSp} {
    margin-top: 0;
  }
}
`
