import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import { green } from '../../styles'

/** Nav5｜アコーディオンメニュー、メニュー部分 */
const AccordionMenu = ({ listItems = [], ...props }) => {
  return (
    <StyledUl {...props}>
      {listItems.map((item) => {
        if (!item.isShow) return
        return (
          <StyledLi>
            <StyledLink route={item.route} href={item.href} query={item.query}>
              {item.name}
            </StyledLink>
          </StyledLi>
        )
      })}
    </StyledUl>
  )
}

export default AccordionMenu

AccordionMenu.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      isShow: PropTypes.bool,
      route: PropTypes.string,
      query: PropTypes.object,
      href: PropTypes.string,
      name: PropTypes.string
    })
  )
}

const StyledUl = styled.ul`
  border-top: 1px solid ${green};
`

const StyledLi = styled.li`
  border: 1px solid ${green};
  border-top-width: 0;
`

const StyledLink = styled(Link)`
  padding: 15px 0;
  background-color: #fff;
  display: block;
  color: ${green};
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;

  &:link,
  &:visited,
  &:active {
    color: ${green};
  }

  &:hover {
    background-color: ${green};
    color: #fff;
  }
`
