import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MainViewLink from '../MainViewLink';
import routes, { getHomeRoute } from '../../routes';

import GlobalNavigation from "../GlobalNavigation";
import SearchForm from "../SearchForm";

const cookie = require('cookie-dough')();
export default class HeaderOld extends Component {

  static propTypes = {
    id:PropTypes.string,
    title:PropTypes.string,
    episode:PropTypes.string,
    thumbnailUrl:PropTypes.string,
    newsInfoClass:PropTypes.string,
  }

  static contextTypes = {
    models: PropTypes.object,
    getModelData: PropTypes.func.isRequired,
    history: PropTypes.object,
    routeHandler: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.state = {
      search: false
    }
  }

  componentDidMount() {
    this.setRubocopTimeZoneName();
  }

  componentWillReceiveProps(nextProps) {
    this.setRubocopTimeZoneName();
  }

  setRubocopTimeZoneName() {
    if (this.context.models.config.data.preview_url_list.indexOf(location.hostname) !== -1){
      if(!this.rubocopTimeZoneName) this.rubocopTimeZoneName = location.hostname + this.context.models.config.data.cookie_rubocop_prefix
    }
  }

  handleClick(e) {
    cookie.remove(this.rubocopTimeZoneName, [])
    alert("削除しました。")
    // リダイレクト
    location.href = location.pathname
  }

  render() {
    let timecop = (
      <div/>
    )

    // プレビュー用の時間設定
    // ここはcookieを扱うのでブラウザ上でしか動かせない
    // 実装上ページが読み込まれたらHeaderコンポーネントのロードが必ず行われるので、
    // SSR時に生成されなくても問題はない
    if (process.env.BROWSER) {
      this.setRubocopTimeZoneName();
      if (this.rubocopTimeZoneName){
        const rubocopTimeZone = cookie.get(this.rubocopTimeZoneName)
        if (rubocopTimeZone){
          timecop = (
            <div>
              { "現在の設定時間は" + rubocopTimeZone + "です"}
              <input type="button" onClick={this.handleClick.bind(this)}  value="削除します。"/>
            </div>
          )
        }
      }
    }

    const globalNavBack = this.context.routeHandler.path === '/app/' ? '' :
    (
      <a className="globalNav-back icon-arow-left menu_api" onClick={((e) => {history.back(-1);})}></a>
    );

    return (
      <header id="globalNav">
        {globalNavBack}
        <h1>
            <img src="/images/exdio/catchup_logo.png" alt="テレ朝キャッチアップ" className="menu_api" style={{width:"160px"}}
              onClick={ ((e) => {
                const { history } = this.context;
                history.push(routes.app.makePath());
              }) }
            />
        </h1>
      </header>
    );
  }
}
