import styled, { css } from 'styled-components'
import { breakPoints, mediaQuery } from '../../../../../exdio/components/style'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import Img from './Img'
import Title from './Title'

export const StyledFigure = styled.figure``

export const StyledImg = styled(Img)`
  grid-area: img;

  &::before {
    transition: background 0.3s;
  }

  img {
    transition: transform 0.3s;
  }
`

export const StyledTitle = styled(Title)``

export const StyledFigcaption = styled.figcaption`
  grid-area: figcaption;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  position: relative;
`

export const StyledP = styled.p`
  max-height: calc(2em * 1.6);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 1.6;

  ${mediaQuery()} {
    max-height: calc(2em * 1.4);
    font-size: 1.2rem;
    line-height: 1.4;
  }
`

export const StyledTime = styled.time`
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 300;
`

export const StyledPlayButton = styled.img`
  margin: 0;
  min-width: 30px;
  display: block;
  transition: opacity 0.3s;
`

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['listType'].includes(prop)
})`
  background-color: #fff;
  display: block;
  transition: color 0.3s;

  @media (hover: hover) {
    &:hover {
      color: #939394;

      ${StyledImg} {
        &::before {
          background: linear-gradient(
            hsla(0, 0%, 100%, 0.3),
            hsla(0, 0%, 100%, 0.3)
          );
        }

        img {
          transform: scale(1.1);
        }
      }

      ${StyledPlayButton} {
        opacity: 0.5;
      }
    }
  }

  ${({ listType }) => {
    switch (listType) {
      case 'default':
        return css`
          ${StyledFigure} {
            display: grid;
            grid:
              'img figcaption'
              / 144px calc(100% - 144px - 10px);
            align-items: start;
            column-gap: 10px;
          }

          ${StyledImg} {
            width: 144px;
            height: 81px;
          }
        `

      case 'list':
        return css`
          padding: 8px 0;
          border-bottom: 1px solid #e3e3e3;

          ${StyledFigure} {
            display: flex;
            justify-content: space-between;
            column-gap: 16px;
          }

          ${StyledFigcaption} {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 7px;
          }

          ${StyledTitle} {
            flex: 1 0;

            ${mediaQuery(breakPoints.sm, 'min')} {
              /* -webkit-line-clamp: 1; */
              min-height: auto;
            }
          }
        `

      case 'grid':
        return css`
          ${StyledFigure} {
            display: grid;
            grid:
              'img'
              'figcaption';
            row-gap: 10px;
          }

          ${StyledImg} {
            width: 100%;
          }
        `

      default:
        return null
    }
  }}
`
