import React, { memo } from 'react'
import PropTypes from 'prop-types'

/** style */
import styled, { keyframes } from 'styled-components'
import { mediaPc } from './styles'
import {
  StyledButton,
  StyledSpan,
  Img1Style,
  StyledImg2
} from './ToggleImgButton'

/** 回転する画像ボタン */
const BuzzImgButton = ({ index, name, imgName, img, ...props }) => {
  return (
    <StyledBuzzButton type="button" {...props}>
      {/* hvr-buzz-out */}
      <StyledSpan index={index}>
        <StyledImg1 src={img} width="270" height="216" alt="" loading="lazy" />
      </StyledSpan>
      <StyledImg2
        src={imgName}
        width="210"
        height="24"
        alt={name}
        loading="lazy"
      />
    </StyledBuzzButton>
  )
}

export default memo(BuzzImgButton)

BuzzImgButton.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  imgName: PropTypes.string,
  img: PropTypes.string
}

BuzzImgButton.defaultProps = {
  index: 0,
  name: '',
  imgName: '',
  img: ''
}

const hvrBuzzOut = keyframes`
  10% { transform: translateX(3px) rotate(2deg) }
  20% { transform: translateX(-3px) rotate(-2deg) }
  30% { transform: translateX(3px) rotate(2deg) }
  40% { transform: translateX(-3px) rotate(-2deg) }
  50% { transform: translateX(2px) rotate(1deg) }
  60% { transform: translateX(-2px) rotate(-1deg) }
  70% { transform: translateX(2px) rotate(1deg) }
  80% { transform: translateX(-2px) rotate(-1deg) }
  90% { transform: translateX(1px) rotate(0) }
  100% { transform: translateX(-1px) rotate(0) }
`

const StyledBuzzButton = styled(StyledButton)`
  &:hover {
    @media ${mediaPc} {
      animation: ${hvrBuzzOut} 0.75s linear;
    }
  }
`

const StyledImg1 = styled.img`
  ${Img1Style}
`
