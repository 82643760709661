import React from 'react'
import PropTypes from 'prop-types'
import HtmlSnippet from '../../../HtmlSnippet'
import InfoSlider from './InfoSlider/'
import {
  StyledDd,
  StyledDiv1,
  StyledDiv2,
  StyledDiv3,
  StyledDl,
  StyledDt,
  StyledSuggestionBox,
  GlobalStyle
} from './style'

/** ガリベン大学 Section1 */
const Section1 = ({ news = [], bannerId1 = '', bannerId2 = '' }) => {
  return (
    <StyledDiv1 id="top1">
      <StyledDiv2>
        <HtmlSnippet snippetId={bannerId1} />

        <StyledDiv3>
          <StyledDl>
            <StyledDt>
              <img
                src="/images/exdio/renewal/gariben_daigaku/top/text1.webp"
                width="392"
                height="45"
                alt="私立ガリベン大学 更新情報"
                loading="lazy"
              />
            </StyledDt>
            <StyledDd>
              <InfoSlider news={news} />
            </StyledDd>
          </StyledDl>
          <StyledSuggestionBox />
        </StyledDiv3>
        <HtmlSnippet snippetId={bannerId2} />
      </StyledDiv2>
      <GlobalStyle />
    </StyledDiv1>
  )
}

export default Section1

Section1.propTypes = {
  /** htmlスニペットに渡すバナーID */
  bannerId1: PropTypes.number,
  /** htmlスニペットに渡すバナーID */
  bannerId2: PropTypes.number,
  /** 更新情報のリスト
   * @param {string} type - 情報のタイプ"動画"か"ブログ"
   * @param {string} thumbnail - サムネ画像のパス
   * @param {string} dateTime - 投稿日
   * @param {string} name - タイトル
   * @param {string} params - Linkに渡すためのパラメータ
   */
  news: PropTypes.arrayOf(PropTypes.object)
}
