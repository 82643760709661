import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ProgramItem from '../../../../common/components/renewal/ProgramItem';
import webApp from '../../../utils/exdioWebAppUtils';
import { META_SCHEMA_ID } from '../../../../../constants/app';

/** 詳細画面:エピソードリストコンポーネント */
export default class EpisodeList extends Component {
  static propTypes = {
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        meta_id: PropTypes.number.isRequired,
        thumbnail_url: PropTypes.string,
        values: PropTypes.shape({
          avails_EpisodeNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        }).isRequired
      })
    ),
    howToPlays: PropTypes.object,
    showOnlyFreeCoin: PropTypes.bool,
    onlySubTitle: PropTypes.bool
  };

  static defaultProps = {
    episodes: [],
    howToPlays: {},
    showOnlyFreeCoin: false,
    onlySubTitle: false
  };

  static contextTypes = {
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.state = {
      episodes: props.episodes,
      rootMetas: props.rootMetas,
      howToPlays: props.howToPlays,
      showOnlyFreeCoin: props.showOnlyFreeCoin,
      modeDsearch: !props.episodes // episodesにnullが指定されたらrootMetasを利用してdsearch検索を行う
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.state.modeDsearch) {
      this.search();
    }
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.episodes !== nextProps.episodes) {
      if (!nextProps.episodes) {
        return { episodes: prevState.episodes };
      } else {
        return { episodes: nextProps.episodes };
      }
    }
    return nextProps;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async search() {
    const { rootMetas } = this.props;

    if (!rootMetas) {
      return;
    }

    // これを使うページにページングはなさそうなので、視認して嫌にならない程度にとる
    const range = { from: 0, to: 100 };

    let episode_ids = [];
    let season_ids = [];
    let series_ids = [0];

    _.forEach(rootMetas, (meta) => {
      if (meta.meta_schema_id === META_SCHEMA_ID.EPISODE || meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE ||
         meta.meta_schema_id === META_SCHEMA_ID.LIVE || meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE) {
        episode_ids.push(meta.meta_id);
      } else if (meta.meta_schema_id === META_SCHEMA_ID.SEASON || meta.meta_schema_id === META_SCHEMA_ID.LIVE_SEASON) {
        season_ids.push(meta.meta_id);
      }
    })

    let showEpisodeFlag = true;
    if (episode_ids.length === 0 && season_ids.length > 1) {
      // シーズンが複数件登録されていたらシーズン一覧表示
      showEpisodeFlag = false;
    }

    if (episode_ids.length === 0) {
      episode_ids = [0];
    }
    if (season_ids.length === 0) {
      season_ids = [0];
    }

    // どうもpathに大量のarrayを入れるとfalcorがMaxRetryExceededErrorをかえすようなので
    // 下記episodeのみの指定で件数が多い場合は分割取得するようにする
    const searchType = showEpisodeFlag ? "episode" : "season";
    if(episode_ids.length >= 50 && season_ids.length === 1 && season_ids[0] === 0) {
      // array分割
      const arrayChunk = ([...array], size = 1) => {
        return array.reduce((acc, value, index) => index % size ? acc : [...acc, array.slice(index, index + size)], []);
      }
      let chunk = arrayChunk(episode_ids, 50);
      let episodes = [];
      for(let i = 0; i < chunk.length; i++) {
        const path = [['meta', 'packSearch', searchType, "", chunk[i], season_ids, series_ids, [range]]];
        this.context.falcorModel.invalidate(path);
        await this.context.falcorModel.fetch(path).then(result => {
          if (result.json) {
            const metas = _.get(result.json, ['meta', 'packSearch', searchType, "", chunk[i][0], season_ids[0], series_ids[0]] );
            if (metas) {
              delete metas['$__path'];
              episodes = episodes.concat(Object.values(metas));
            }
          }
        });
      }
      if (this._isMounted) {
        if (episodes !== this.state.filteredEpisodes) {
          this.setState({ episodes: episodes, onlySubTitle: showEpisodeFlag });
        }
      }
    } else {
      const path = [['meta', 'packSearch', searchType, "", episode_ids, season_ids, series_ids, [range]]];
      this.context.falcorModel.invalidate(path);
      return this.model.fetch(path).then(result => {
        if (result.json) {
          const metas = _.get(result.json, ['meta', 'packSearch', searchType, "", episode_ids[0], season_ids[0], series_ids[0]] );
          if (metas) {
            delete metas['$__path'];
            if (this._isMounted) {
              if (metas !== this.state.filteredEpisodes) {
                this.setState({ episodes: Object.values(metas), onlySubTitle: showEpisodeFlag });
              }
            }
          }
        }
      });
    }
  }

  /*
  * エピソードをソートする
  * × エピソード番号(昇順or降順) > AVOD->TVOD(固定) > 配信開始日時(昇順or降順) > ID(昇順or降順)
  *
  * 2021.03.09 DGA_PJ-85
  * 配信開始[昇順/降順] > エピソード番号[昇順/降順] > ID[昇順/降順]
  */
  sortEpisodes (episodes) {
    if ((episodes||[]).length < 1) return

    // 配列の破壊を防ぐため複製
    let sortedEpisodes = episodes.slice()

    const { disp_order } = this.props
    const direction = disp_order == 'asc' ? -1 : 1

    sortedEpisodes.sort((a, b) => {
      // 配信開始日時 -> 管理画面の昇順or降順に合わせる
      if (a.delivery_start_at < b.delivery_start_at) return direction * 1;
      if (a.delivery_start_at > b.delivery_start_at) return direction * -1;

      // エピソード番号 -> 管理画面の昇順or降順に合わせる
      if (Number(a.values.avails_EpisodeNumber) < Number(b.values.avails_EpisodeNumber)) return direction * 1;
      if (Number(a.values.avails_EpisodeNumber) > Number(b.values.avails_EpisodeNumber)) return direction * -1;

      // ID -> 管理画面の昇順or降順に合わせる
      if (a.id < b.id) return direction * 1;
      if (a.id > b.id) return direction * -1;

      // メタスキーマIDの昇順(無料, 有料の順) -> 固定
      // if (a.meta_schema_id < b.meta_schema_id) return -1;
      // if (a.meta_schema_id > b.meta_schema_id) return 1;

      return 0;
    })

    return sortedEpisodes
  }

  render() {
    const { episodes, howToPlays, showOnlyFreeCoin, onlySubTitle } = this.state;
    if (!episodes || !episodes.length) return null;

    const sorted = this.sortEpisodes(Object.assign([], episodes));

    return (
      <div className="c-card-vertical story">
        <div className="c-card-vertical-cont">
          {sorted.map((meta, idx) => {
            const isFree = META_SCHEMA_ID.EPISODE === meta.meta_schema_id || META_SCHEMA_ID.LIVE === meta.meta_schema_id;
            return (
              <ProgramItem
                key={meta.meta_id}
                meta={meta}
                howToPlay={howToPlays[meta.meta_id]}
                showCaption
                showCoin={!showOnlyFreeCoin || isFree}
                showLatest={false}
                showNew={webApp.utils.showNew(meta)}
                onlySubTitle={onlySubTitle}
                onClickThumbnail={() => webApp.utils.goToProgramLink(this.context, meta)}
                onClickCaption={() => webApp.utils.goToProgramLink(this.context, meta, null, null, { autoPlay: false })}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
