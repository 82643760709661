import React from 'react'
import styled from 'styled-components'

/** そだてれび：海外視聴用 PC 単話ページ動画詳細 再生ボタン */
const PlayButton = ({ className }) => {
  return <StyledDiv className={className} key="btn" />
}

export default PlayButton

const StyledDiv = styled.div`
  text-align: center;
  color: #fff;
  font-weight: 600;
  padding-top: 170px;
  font-size: 2rem;
  display: block;
  width: 144px;
  height: 144px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_pc.svg)
    no-repeat center center;
`
