import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PlayButton from './PlayButton'

/** そだてれび：海外視聴用 PC 単話ページ動画詳細  サムネイル*/
const DetailThumbnail = ({ thumbnailAlt, thumbnailUrl, onClickPlay }) => {
  return (
    <StyledDiv>
      <a href="#" onClick={onClickPlay}>
        <StyledPlayButton />
        <img src={thumbnailUrl} alt={thumbnailAlt} />
      </a>
    </StyledDiv>
  )
}

DetailThumbnail.propTypes = {
  thumbnailUrl: PropTypes.string,
  thumbnailAlt: PropTypes.string,
  onClickPlay: PropTypes.func
}

export default DetailThumbnail

const StyledDiv = styled.div`
  height: 360px;
  width: 640px;
  position: relative;
  line-height: 0;

  a {
    display: block;
  }
`

const StyledPlayButton = styled(PlayButton)`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`
