import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import webApp from '../../../../../utils/exdioWebAppUtils'
import SpEpisodePager from './SpEpisodePager'
import SpDetailInfo from './SpDetailInfo'
import SpDetailThumbnail from './SpDetailThumbnail'
import SpDetailTitle from './SpDetailTitle'
import Label from '../../common/Label'
import convertTimeString from '../../functions/convertTimeString'

/** そだてれび：海外視聴用 SP 単話ページ動画詳細 */
const SpDetail = (
  { meta, onClickPlay, episodes, course, seasonId, seriesId },
  context
) => {
  const [title, subTitle] = webApp.utils.titles(meta)
  // 動画タイトル
  const metaName = `${title} ${subTitle}`
  // 動画試聴時間
  const time = convertTimeString(webApp.utils.duration(meta)) || '-'
  //配信期間
  const separatorString = /\s+/
  const delivery_date = meta.publish_start_at.split(separatorString, 1)
  //配信開始日
  const release_date =
    meta.values.avails_ReleaseHistoryOriginal.split(separatorString, 1) ||
    delivery_date
  //あらすじ
  const desc = meta.values.evis_EpisodeLongSynopsis
    ? webApp.utils.feedLine(meta.values.evis_EpisodeLongSynopsis)
    : ''
  // 最新動画かどうか
  const isNew = webApp.utils.isNew(context, meta)

  return (
    <div>
      <StyledLabel isNew={isNew} />
      <StyledSpDetailTitle title={metaName} />
      <SpDetailThumbnail
        thumbnailAlt={title}
        thumbnailUrl={meta.thumbnail_url}
        onClickPlay={onClickPlay}
      />
      <SpEpisodePager
        meta={meta}
        seriesId={seriesId}
        seasonId={seasonId}
        episodes={episodes}
        course={course}
      />
      <SpDetailInfo
        time={time}
        delivery_date={delivery_date}
        release_date={release_date}
        content={desc}
      />
    </div>
  )
}

export default SpDetail

SpDetail.contextTypes = {
  models: PropTypes.object
}

const StyledSpDetailTitle = styled(SpDetailTitle)`
  margin-top: 10px;
`

const StyledLabel = styled(Label)`
  text-align: right;
  > div:last-child {
    display: none;
  }
`
