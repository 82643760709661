import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import layoutContext from '../../common/hocs/layoutContext'
import { provideRoutingContext as routing } from '../../../sketch-platform/ui/routing'
import connectToApps from '../components/connectToApps'

const BaseRouteHandler = ({ model, isRefreshingApp, children }, context) => {
  return (
    <div lang="ja-JP" dir="ltr">
      {React.cloneElement(children, {
        isRefreshingApp,
        isInitialRender: false,
        is4KSupported: false,
        isHDRSupported: false,
        isOverlayPage: false,
        model,
        profileGateAppState: {
          activeView: _.get(context.models, 'profileGateState.data', '')
        },
        uiView: ''
      })}
    </div>
  )
}

const _layout = connectToApps()(layoutContext(BaseRouteHandler))
const layout = process.env.BROWSER ? routing()(_layout) : _layout

export default layout

BaseRouteHandler.propTypes = {
  model: PropTypes.object,
  isRefreshingApp: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element
  ]).isRequired
}

BaseRouteHandler.contextTypes = {
  getModelData: PropTypes.func.isRequired,
  webApp: PropTypes.object,
  history: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object.isRequired
}
