import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'
import {
  META_SCHEMA_ID,
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE,
  DIO_PLAYER_DEVICE
} from '../../../../../constants/app'
import webApp from '../../../utils/exdioWebAppUtils'
import Header from '../../../../common/components/Header'
import EpisodeFooter from '../../../../common/components/EpisodeFooter'
import RenewalPlayer from '../../player/RenewalPlayer'
import Notice from '../EpisodeDefault/Notice'
import CommonVideoArea from '../EpisodeDefault/CommonVideoArea'
import Meta from '../EpisodeDefault/Meta'
import Actions from '../EpisodeDefault/Actions'
import ListMetaMain from '../EpisodeDefault/ListMetaMain'
import ListMetaSub from '../EpisodeDefault/ListMetaSub'
import HeaderNewsComponent from '../HeaderNewsComponent'
import TPS from '../TPS'
import {
  checkAvailableStatus,
  play,
  purchaseEpisode,
  purchasePlan,
  getThumbnailUrl
} from '../EpisodeDefault/util'
import { mediaQuery } from '../../style'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 単話ページ */
export default class EpisodeDefault extends Component {
  static propTypes = {
    seriesId: PropTypes.string,
    seasonId: PropTypes.string,
    episodeId: PropTypes.string,

    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      meta_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    howToPlay: PropTypes.object,
    product: PropTypes.object,
    productRight: PropTypes.object,

    // 通常単話
    episodes: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number,
        name: PropTypes.string,
        original_price: PropTypes.number,
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    ),
    howToPlays: PropTypes.object,
    // 月額見放題
    course: PropTypes.shape({
      course_id: PropTypes.number,
      schema_id: PropTypes.number,
      name: PropTypes.string,
      active_pricing: PropTypes.object,
      values: PropTypes.object
    }),

    status: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      isDeviceNotAvailable: PropTypes.bool,
      limitDate: PropTypes.string,
      isPossible: PropTypes.bool,
      isBelonging: PropTypes.bool
    }),

    season: PropTypes.object,

    // 再生ログ送信
    sendPlayLog: PropTypes.func,

    // データ取得が完了したか
    loaded: PropTypes.bool
  }

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    episodeId: '',
    howToPlay: {},
    episodes: [],
    products: [],
    howToPlays: {},
    course: {},
    status: {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    sendPlayLog: () => {},
    loaded: false
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.previewUrlList = this.config.preview_url_list
    this.cookieRubocopPrefix = this.config.cookie_rubocop_prefix
    this.model = context.falcorModel.batch(100)
    this.isPc = webApp.utils.getDevice() === DIO_PLAYER_DEVICE.PC

    this.state = {
      progress: webApp.utils.progress(this.config, props.meta),
      pagerOptions: {
        // ページャーの設定
        episodesPerPages: 12,
        range: 2
      }
    }

    this.onClickPlay = this.onClickPlay.bind(this)
    this.onClosePlayer = this.onClosePlayer.bind(this)

    this.setPlayerRef = (e) => {
      this.playerRef = e
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillReceiveProps(nextProps) {
    const { loaded, status } = nextProps
    const isAutoPlay = this.context.routeHandler.query.auto === 't'
    if (loaded && status && this.isPc && isAutoPlay) {
      const isFree = EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode)
      const isAuthFree =
        EPISODE_DISPLAY_MODE.SVOD_AUTH_FREE === status.displayMode &&
        webApp.utils.isLoggedIn(this.context)
      const withValidPreviewToken = _.get(this.context, [
        'models',
        'state',
        'data',
        'withValidPreviewToken'
      ])
      if (isFree || isAuthFree || status.isPurchased || withValidPreviewToken) {
        this.onClickPlay()
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    document.body.style.overflow = null
  }

  /**
   * 視聴権を確認して再生するか購入ページに飛ばすかをしている
   */
  onClickPlay(e, asSvod = false) {
    if (e) e.preventDefault()

    switch (checkAvailableStatus(this.props, this.context, asSvod)) {
      case 'PLAY':
        play(this.playerRef, this.props.sendPlayLog)
        break
      case 'PURCHASE_EPISODE':
        purchaseEpisode(this.props, this.context)
        break
      case 'PURCHASE_PLAN':
        purchasePlan(this.props, this.context)
        break
      default:
        break
    }
  }

  /**
   * プレイヤー閉じ
   */
  onClosePlayer() {
    if (this._isMounted) {
      this.setState({
        progress: webApp.utils.progress(this.config, this.props.meta)
      })
    }
  }

  render() {
    const {
      seasonId,
      meta,
      programMeta,
      status,
      product,
      course,
      loaded
    } = this.props
    const { progress, pagerOptions } = this.state
    const isDoraemon =
      Number(seasonId) === this.config.svod.doraemon.doraemon.season_id

    const [mainTitle, subTitle] = webApp.utils.titles(meta)
    const metaName = `${mainTitle} ${subTitle}`

    const episodeBannerId1 = _.get(meta, ['values', 'banner_1'])
    const episodeBannerId2 = _.get(meta, ['values', 'banner_2'])
    /** ドラえもんテンプレと同シーズンで重複するため非表示 */
    const programBannerId1 = isDoraemon
      ? ''
      : _.get(programMeta, ['values', 'banner_1'])
    const programBannerId2 = isDoraemon
      ? ''
      : _.get(programMeta, ['values', 'banner_2'])
    const isLive =
      meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
      meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
    let isClipVod = false

    // LIVE配信 主/副切替ID
    let metaIdForPlayer
    if (isLive) {
      if (meta.values.clipping_select_media_type === 'vod') {
        metaIdForPlayer = meta.values.clipping_vod_ref_id
        isClipVod = true
      }
      if (!metaIdForPlayer) metaIdForPlayer = meta.values.target_ref_id
    }
    if (!metaIdForPlayer)
      metaIdForPlayer = `${this.config.videocloud.reference_id_prefix || ''}${
        meta.meta_id
      }`
    const playerSettings = webApp.utils.getPlayerSettings(
      this.config,
      meta,
      status.displayMode
    )

    const withValidPreviewToken = _.get(this.context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])
    const prevTime = Cookie.get(this.previewUrlList + this.cookieRubocopPrefix)
    const isBefore = Boolean(
      !withValidPreviewToken &&
        moment(prevTime).isBefore(meta.delivery_start_at)
    )
    const isAfter = Boolean(
      !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
    )
    const thumbnailUrl = getThumbnailUrl(
      this.props,
      this.context,
      this.config,
      this.previewUrlList,
      this.cookieRubocopPrefix
    )
    const inViewTerm = !(isBefore || isAfter)
    const nextPrevText =
      inViewTerm || isLive
        ? null
        : isBefore
        ? 'まもなく配信予定'
        : '次回の配信もお楽しみに！'
    const isPlayerRenderable =
      metaIdForPlayer &&
      status.displayMode &&
      (inViewTerm || withValidPreviewToken)

    return (
      <React.Fragment>
        <Helmet>
          <link rel="icon" href="/favicon.ico" />
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        </Helmet>
        <Header />
        <TPS />
        <div className="p-episode_default common-wrapper">
          <HeaderNewsComponent />
          <SpSubNavigation spOff />
          {this.isPc && <Notice programMeta={programMeta} />}

          {isPlayerRenderable && (
            <RenewalPlayer
              ref={this.setPlayerRef}
              meta_id={metaIdForPlayer}
              product_type={playerSettings.product_type}
              channel={playerSettings.channel}
              ssai_ad_config_id={playerSettings.ssai_ad_config_id}
              ssai_player_id={playerSettings.ssai_player_id}
              stvod_player_id={playerSettings.stvod_player_id}
              live_player_id={playerSettings.live_player_id}
              delivery_config_id={playerSettings.delivery_config_id}
              thumbnail_url={meta.thumbnail_url}
              subtitle={!!meta.values.subtitle}
              enqueteEnabled={status.isFree}
              material_id={_.get(product, ['ref_id']) || ''}
              license_id={_.get(course, ['ref_id']) || ''}
              display_mode={status.displayMode}
              onClose={this.onClosePlayer}
              isLive={isLive}
              isClipVod={isClipVod}
            />
          )}

          <CommonVideoArea
            progress={progress}
            onClickPlay={this.onClickPlay}
            isLive={isLive}
            inViewTerm={inViewTerm}
            metaName={metaName}
            thumbnailUrl={thumbnailUrl}
            nextPrevText={nextPrevText}
            product_type={playerSettings.product_type}
            channel={playerSettings.channel}
            meta_id={meta.meta_id}
            subtitle={!!meta.values.subtitle}
            useUniversalLinks
            {...this.props}
          />

          {!this.isPc && <Notice programMeta={programMeta} />}

          <div className="c-storyMeta">
            <div className="c-storyMeta-inBox">
              <Meta isLive={isLive} metaName={metaName} {...this.props} />
              <Actions
                onClickPlay={this.onClickPlay}
                inViewTerm={inViewTerm}
                metaName={metaName}
                product_type={playerSettings.product_type}
                channel={playerSettings.channel}
                meta_id={meta.meta_id}
                subtitle={!!meta.values.subtitle}
                useUniversalLinks
                isAppWatch
                {...this.props}
              />
            </div>
          </div>

          <div className="c-listMeta">
            <div className="c-listMeta-inBox">
              <ListMetaMain
                pagerOptions={pagerOptions}
                episodeBannerId={episodeBannerId1}
                programBannerId={programBannerId1}
                {...this.props}
              />
              <ListMetaSub
                loaded={loaded}
                episodeBannerId={episodeBannerId2}
                programBannerId={programBannerId2}
                {...this.props}
              />
            </div>
          </div>

          <EpisodeFooter
            className="mt0"
            copyrights={_.get(meta, ['values', 'evis_Copyright'])}
          />
        </div>
        <GlobalStyle />
      </React.Fragment>
    )
  }
}

const GlobalStyle = createGlobalStyle`
  #point-header + .common-wrapper {
    ${mediaQuery()} {
      margin-top: 15px;
    }
  }
`
