import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/** Balloon Component */
const Balloon = ({ name = '', synopsis = '', ...props }, ref) => {
  return (
    <StyledBalloon ref={ref} {...props}>
      <StyledDl>
        <StyledDt>{name}</StyledDt>
        <StyledDd>{synopsis}</StyledDd>
      </StyledDl>
    </StyledBalloon>
  )
}

export default forwardRef(Balloon)

Balloon.propTypes = {
  /** 見出し */
  name: PropTypes.string,
  /** あらすじ */
  synopsis: PropTypes.string
}

const StyledBalloon = styled.aside.withConfig({
  shouldForwardProp: (prop) => !['thumbnail'].includes(prop)
})`
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  border: 1px solid #333;
  border-radius: 5px;
  color: #333;
`

const StyledDl = styled.dl`
  display: grid;
  row-gap: 15px;
`

const StyledDt = styled.dt`
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.25;
`

const StyledDd = styled.dd`
  line-height: 1.5;
`
