import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import { get } from 'lodash'
import { PANEL_ID } from './config'

/** style */
import styled, { css, keyframes } from 'styled-components'
import { mediaPc, mediaSp, pink, darkPink } from './styles'

/** images */
import NavText from './NavText/'

const Tab = ({
  id,
  title,
  iconType,
  isChecked,
  onChangeFunc,
  setIsModalOpen,
  ...props
}) => {
  const [isSp, setIsSp] = useState(false)
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  /** 画面サイズ変更に対応して値を取得 */
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  /** 画面サイズが変わったときにSP判定を変更 */
  useEffect(() => {
    const _isSp = get(windowSize, ['width']) <= 1024
    if (_isSp !== isSp) {
      setIsSp(_isSp)
    }
  }, [windowSize])

  const onClickHandler = () => {
    if (isSp) {
      setIsModalOpen(true)
    }
  }

  return (
    <StyledLabel
      iconType={iconType}
      htmlFor={`tab-${id}`}
      isChecked={isChecked}
      {...props}
    >
      <StyledRadio
        type="radio"
        id={`tab-${id}`}
        name="tabs"
        value={id}
        checked={isChecked}
        onChange={onChangeFunc}
      />
      <StyledSpan
        as={isSp ? 'button' : null}
        type={isSp ? 'button' : null}
        onClick={onClickHandler}
      >
        <NavText id={id} title={title} />
      </StyledSpan>
    </StyledLabel>
  )
}

export default memo(Tab)

Tab.propTypes = {
  /** Tabのid HTMLのidに使用する */
  id: PropTypes.string,
  /** 見出し画像alt */
  title: PropTypes.string,
  /** 装飾の種類 */
  iconType: PropTypes.string,
  /** チェックしているか */
  isChecked: PropTypes.bool,
  /** 変更時に実行する関数 */
  onChangeFunc: PropTypes.func,
  /** モーダル開閉状況変更 */
  setIsModalOpen: PropTypes.func
}

Tab.defaultProps = {
  id: '',
  title: '',
  iconType: null,
  isChecked: false,
  onChangeFunc: () => {},
  setIsModalOpen: () => {}
}

const StyledRadio = styled.input`
  display: none;
`

const StyledSpan = styled.span`
  padding-left: 18px;
  display: inline-block;
  background: url(/douga_mv/shinchan/svod/images/nav/arrow1.svg) no-repeat left
    0 center / 10px 14px;

  @media ${mediaSp} {
    padding-left: 0;
    padding: 0 30px 0 15px;
    width: 100%;
    background: #fff;
    border-width: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
  }

  &::before,
  &::after {
    margin: auto 0;
    position: absolute;
    top: 50%;
    right: 15px;
    content: '';
  }

  &::before {
    @media ${mediaSp} {
      width: 13px;
      height: 8px;
      background: url(/douga_mv/shinchan/svod/images/nav/arrow2.svg) no-repeat
        center / 13px 8px;
      transform: translateY(-9px) rotate(180deg);
    }
  }

  &::after {
    @media ${mediaSp} {
      width: 13px;
      height: 8px;
      background: url(/douga_mv/shinchan/svod/images/nav/arrow2.svg) no-repeat
        center / 13px 8px;
      transform: translateY(1px);
      content: '';
    }
  }

  path {
    @media ${mediaSp} {
      fill: ${pink};
    }
  }
`

/** 揺れるアニメーション */
const swing = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
`

/** 回転するアニメーション */
const role = keyframes`
  from { transform: rotateY(0deg) translateY(0); }
  50% { transform: rotateY(180deg) translateY(-10px); }
  to { transform: rotateY(360deg) translateY(0); }
`

/** すべてのおはなし */
const TypeAll = css`
  &::after {
    width: 49px;
    height: 59px;
    background: url(/douga_mv/shinchan/svod/images/nav/icon01.svg) no-repeat
      center / 100%;
    position: absolute;
    right: 7px;
    bottom: 0;
    animation: ${swing} 3s linear -3s infinite;
    content: '';
  }
`

/** 厳選エピソード */
const TypeSelection = css`
  &::after {
    width: 55px;
    height: 58px;
    background: url(/douga_mv/shinchan/svod/images/nav/icon02.svg) no-repeat
      center / 100%;
    position: absolute;
    right: 8px;
    bottom: 2px;
    animation: ${swing} 2s linear -2s infinite;
    content: '';
  }
`

/** 年代別 */
const TypeAge = css`
  @media ${mediaPc} {
    justify-content: center;
  }

  &::before,
  &::after {
    background: no-repeat center / 100%;
    position: absolute;
    bottom: 6px;
    content: '';
  }

  &::before {
    width: 38px;
    height: 52px;
    background-image: url(/douga_mv/shinchan/svod/images/nav/icon03-l.svg);
    left: 12px;
    animation: ${swing} 3s linear -3s infinite;
  }

  &::after {
    width: 65px;
    height: 46px;
    background-image: url(/douga_mv/shinchan/svod/images/nav/icon03-r.svg);
    right: 12px;
    animation: ${swing} 2s linear -2s infinite;
  }

  ${StyledSpan} {
    @media ${mediaPc} {
      transform: translateX(-10px);
    }
  }
`

/** キャラクター別 */
const TypeCharacter = css`
  &::after {
    width: 60px;
    height: 57px;
    background: url(/douga_mv/shinchan/svod/images/nav/icon04.svg) no-repeat
      center / 100%;
    position: absolute;
    right: 7px;
    bottom: 2px;
    animation: ${swing} 3s linear -3s infinite;
    content: '';
  }
`

/** テレビスペシャル */
const TypeTvSpecial = css`
  &::after {
    width: 50px;
    height: 60px;
    background: url(/douga_mv/shinchan/svod/images/nav/icon05.svg) no-repeat
      center / 100%;
    position: absolute;
    right: 4px;
    bottom: 0;
    animation: ${swing} 2s linear -2s infinite;
    content: '';
  }
`

/** SHIN-MEN */
const TypeShinMen = css`
  &::after {
    width: 90px;
    height: 45px;
    background: url(/douga_mv/shinchan/svod/images/nav/icon06.svg) no-repeat
      center / 100%;
    position: absolute;
    right: 4px;
    bottom: 9px;
    animation: ${swing} 3s linear -3s infinite;
    content: '';
  }
`

export const StyledLabel = styled.label`
  padding: 0 20px;
  width: 100%;
  height: 50px;
  background-color: ${(props) => (props.isChecked ? darkPink : pink)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  transition: background-color 0.4s ease-in-out;
  cursor: ${({ isChecked }) => (isChecked ? 'default' : 'pointer')};

  @media ${mediaPc} {
    ${(props) => {
      switch (props.iconType) {
        case PANEL_ID.ALL:
          return TypeAll
        case PANEL_ID.SELECTION:
          return TypeSelection
        case PANEL_ID.AGE:
          return TypeAge
        case PANEL_ID.CHARACTER:
          return TypeCharacter
        case PANEL_ID.TV_SPECIAL:
          return TypeTvSpecial
        case PANEL_ID.SHIN_MEN:
          return TypeShinMen
        default:
          return
      }
    }}
  }

  @media ${mediaSp} {
    padding: 8px 62px 8px 8px;
    background-color: ${pink};
    display: ${({ isChecked }) => (isChecked ? null : 'none')};
    align-items: stretch;
    ${TypeAll}
  }

  &::before,
  &::after {
    transform-origin: bottom center 0;
  }

  &:hover {
    @media ${mediaPc} {
      background-color: ${darkPink};

      &::before,
      &::after {
        animation: ${role} 0.6s ease-in;
      }
    }
  }
`
