import Marks from './Marks/'
import styled from 'styled-components'
import { mediaQuery } from '../../../style'
import { pcMaxSize } from '../styles'

export const StyledFooter = styled.footer`
  background: #f3f4f5;
  font-family: Arial, 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3',
    'Hiragino Kaku Gothic ProN', Meiryo, メイリオ, Osaka, 'MS PGothic', arial,
    helvetica, sans-serif;

  ${mediaQuery()} {
    padding-top: 40px;
  }
`

export const StyledDiv1 = styled.div`
  margin: 0 auto;
  padding: 24px 0 46px;
  max-width: ${pcMaxSize};
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${mediaQuery()} {
    padding: 40px 0 0;
    max-width: 1024px;
    display: block;
  }
`

export const StyledDiv2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${mediaQuery()} {
    grid-template-columns: 1fr;
  }
`

export const StyledDiv3 = styled.div`
  padding-top: 24px;
  padding-left: 12px;

  ${mediaQuery()} {
    padding-top: 0;
    padding-left: 0;
  }
`

export const StyledP = styled.p`
  font-size: 1.1rem;
  line-height: 1.4;

  ${mediaQuery()} {
    padding: 20px 15px 15px;
  }
`

export const StyledMarks = styled(Marks)`
  margin-top: 20px;
`

export const StyledDiv4 = styled.div`
  padding-top: 26px;
  padding-bottom: 48px;
  background: #fff;
  text-align: center;
`

export const StyledSmall = styled.small`
  color: #343434;
  font-size: 1.2rem;
  font-weight: 600;
`
