import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Cookie from 'js-cookie'
import List from './List/'
import { StyledDiv1, StyledDiv2, StyledHeading1, StyledI } from './style'

const programList = {
  garibenV: 36059, // 謎解き戦士！ガリベンガーV
  dougaNews: 36134 // 楽しく学ぶ！世界動画ニュース
}

/** ガリベン大学 Section6 */
const Section6 = ({}, context) => {
  const [garibenV, setGaribenV] = useState({})
  const [dougaNews, setDougaNews] = useState({})

  useEffect(() => {
    getLatest()
  }, [])

  const getLatest = () => {
    const path = [
      'meta',
      'children',
      [programList.garibenV, programList.dougaNews]
    ]
    model.fetch([path]).then((result) => {
      const _garibenV =
        _.get(result, ['json', 'meta', 'children', programList.garibenV]) || []
      const _dougaNews =
        _.get(result, ['json', 'meta', 'children', programList.dougaNews]) || []

      setGaribenV(getLatestMeta(_garibenV))
      setDougaNews(getLatestMeta(_dougaNews))
    })
  }

  const getLatestMeta = (data) => {
    const filterdMetas = data
      .filter(
        (meta) =>
          _.get(meta, ['type']) === 'MediaMeta' ||
          _.get(meta, ['type']) === 'media_meta' ||
          _.get(meta, ['type']) === 'Media'
      ) // メディア判別
      .filter((meta) => {
        // 視聴期間内判別
        const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
        const inViewTerm = !(
          moment(prevTime).isBefore(meta.delivery_start_at) ||
          moment(prevTime).isAfter(meta.delivery_end_at)
        )
        return inViewTerm
      })
      .sort((a, b) => (a.delivery_start_at < b.delivery_start_at ? 1 : -1))

    return filterdMetas.length ? filterdMetas[0] : {}
  }

  const model = context.falcorModel.batch(100)
  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix

  const snippetId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 49
    : 53

  if (!(garibenV && dougaNews)) return null

  return (
    <section id="top6">
      <StyledHeading1
        src="/images/exdio/renewal/gariben_daigaku/top/sec6_hdg.webp"
        alt="テレビオンエア見逃し ON AIR"
        width="584"
        height="129"
      >
        <StyledI className="lazyload" />
        <StyledI className="lazyload" />
        <StyledI className="lazyload" />
      </StyledHeading1>
      <StyledDiv1 className="lazyload">
        <StyledDiv2>
          <List
            garibenV={garibenV}
            snippetId={snippetId}
            dougaNews={dougaNews}
          />
        </StyledDiv2>
      </StyledDiv1>
    </section>
  )
}

export default Section6

Section6.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func
}
