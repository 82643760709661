import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash'
import moment from 'moment'
import webApp from '../../../utils/exdioWebAppUtils'
import { EPISODE_DISPLAY_MODE } from '../../../../../constants/app'

/** 詳細画面:配信期限コンポーネント */
export default function DeliverPeriod(
  { meta = null, status = null, productRight = null },
  context
) {
  if (!meta || !meta.meta_id) return null
  let html = null
  const className = status.isPurchased ? 'purchased' : ''

  /** AVOD */
  const avodHtml = () => {
    // AVOD
    if (status.isFree) {
      return `視聴期限：${webApp.utils.deliveryEndAt(
        meta,
        'YYYY年M月D日 HH:mm'
      ) || '-'}`
    }
    return null
  }

  /** TVOD */
  const tvodHtml = () => {
    if (status.isNotPurchased) {
      const view_term = get(productRight, ['view_term'])
      if (view_term) {
        return `視聴期間：${view_term} 日間`
      }
      return null
    }

    if (status.isPurchased) {
      const str_limitDate = get(status, ['limitDate'])
      if (!str_limitDate) {
        return (
          <dl>
            <dt>購入済</dt>
            <dd />
          </dl>
        )
      }

      const isNothing = str_limitDate.replace(/-/g, '/').startsWith('9999/') // 9999年の場合は表示しない
      const limitDate = moment(str_limitDate).format(
        '視聴期限：YYYY年M月D日 HH:mmまで'
      )
      const remaining = webApp.utils.isSp()
        ? webApp.utils.remaining(context, str_limitDate)
        : null

      return (
        <dl>
          <dt>購入済</dt>
          <dd>{!isNothing && [limitDate, remaining && `（${remaining}）`]}</dd>
        </dl>
      )
    }
    return null
  }

  /** SVOD */
  const svodHtml = () => {
    if (status.isNotPurchased) {
      return '視聴期間：決済月の最終日まで'
    }
    if (status.isPurchased) {
      return (
        <dl>
          <dt>購入済</dt>
          <dd>
            {status.limitDate
              ? webApp.utils.deadLine(
                  context,
                  status.limitDate,
                  true,
                  'YYYY/M/D HH:mm'
                )
              : null}
          </dd>
        </dl>
      )
    }
    return null
  }

  const isWithin3Days = webApp.utils.checkRemainingDays(
    _.get(meta, ['delivery_end_at']),
    3
  )

  switch (status.displayMode) {
    case EPISODE_DISPLAY_MODE.FREE:
      html = avodHtml()
      break
    case EPISODE_DISPLAY_MODE.TVOD_FREE:
    case EPISODE_DISPLAY_MODE.SVOD_FREE:
    case EPISODE_DISPLAY_MODE.STVOD_FREE:
      break
    case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
    case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
      html = tvodHtml()
      break
    case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
    case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
      html = svodHtml()
      break
    case EPISODE_DISPLAY_MODE.UNKNOWN:
    default:
      break
  }

  return html ? (
    <div
      className={classnames(
        `c-storyMeta-inBox-meta-viewingPeriod ${className}`,
        {
          isDeadline: isWithin3Days
        }
      )}
    >
      {html}
    </div>
  ) : null
}

DeliverPeriod.propTypes = {
  meta: PropTypes.shape({}),
  status: PropTypes.shape({}),
  productRight: PropTypes.shape({})
}

DeliverPeriod.contextTypes = {
  models: PropTypes.shape({})
}
