// colors
export const commonBgColor = '#efefef'
export const borderColor2 = '#e2e2e2'
export const keyColors = {
  color_1: '#2699fb',
  color_1_2: '#00a0c6',
  color_2: '#f87b98',
  color_2_2: '#f33699',
  color_3: '#fded19',
  color_3_2: '#ebeb10',
  color_3_3: '#ffff11',
  color_3_4: '#ffeb00',
  color_3_5: '#cece00'
}
export const hoverBgColor = '#efefef'

export const fixColors = {
  colorMain: '#ffff11',
  colorSub: '#cece00',
  colorKey: '#f0027f',
  colorFont: '#343434',
  colorBg: '#f3f4f5',
  colorBk: '#2d2d2d',
  colorFormBg: '#e5e6cb',
  colorBorder: '#e3e3e3'
}

export const breakPoints = {
  mx: 1280,
  cs: 1196,
  sm: 1024,
  cs2: 670,
  mm: 578,
  ss: 360
}

/**
 * minmaxを判定し、minの場合は(width + 1)を返します。
 * @param {number | keyof typeof breakPoints} width - ビューポートの幅またはbreakPointsのキーです。
 * @param {'min' | 'max'} minmax - 幅の最小値または最大値を指定します。
 * @returns {number} 判定結果の値です。
 */
export const minmaxDecision = (width, minmax) => {
  let _width = typeof width === 'number' ? width : breakPoints[width]
  _width = minmax === 'min' ? _width + 1 : _width
  return _width
}

/**
 * ピクセルをビューポート幅（vw）に変換します。
 * breakPointsが指定された場合、minmaxを判定してminの場合は(width + 1)を適用します。
 * @param {number} px - 変換するピクセルの値です。
 * @param {number | keyof typeof breakPoints} width - ビューポートの幅またはbreakPointsのキーです。
 * @param {'min'|'max'} [minmax='min'] - 幅の最小値または最大値を使用するか指定します。
 * @returns {string} ビューポート幅（vw）で変換された値です。
 */
export const pxToVw = (px, width = breakPoints.sm, minmax = 'min') => {
  const _width =
    typeof width === 'string' ? minmaxDecision(width, minmax) : width
  return `${(100 / _width) * px}vw`
}

/**
 * メディアクエリを生成します。
 * breakPointsが指定された場合、minmaxを判定してminの場合は(width + 1)を適用します。
 * @param {number | keyof typeof breakPoints} width - ビューポートの幅またはbreakPointsのキーです。
 * @param {'min'|'max'} minmax - 幅の最小値または最大値を指定します。
 * @returns {string} メディアクエリの文字列です。
 */
export const mediaQuery = (width = breakPoints.sm, minmax = 'max') => {
  const _width =
    typeof width === 'string' ? minmaxDecision(width, minmax) : width
  return `@media (${minmax}-width: ${_width}px)`
}
