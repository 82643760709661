import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import cn from 'classnames'
import Cookie from 'js-cookie'
import styled from 'styled-components'
import {
  PRODUCT_SCHEMA,
  PURCHASE_CONTENTS,
  PURCHASE_TYPE
} from '../../../../../constants/app'
import Steps from './Steps'
import webApp from '../../../../../apps/exdio/utils/exdioWebAppUtils'
import routes from '../../../../common/routes'
import Axios from '../../../../../common/Axios'
import { mediaQuery } from '../../style'

/** 購入ページ:購入確認 */
export default class Confirm extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    episodes: PropTypes.arrayOf(PropTypes.object),
    howToPlays: PropTypes.object,
    pack: PropTypes.object,
    course: PropTypes.object,
    selected: PropTypes.arrayOf(PropTypes.number),
    availablePoint: PropTypes.shape({
      available_point: PropTypes.number,
      available_point_from_tvasahi_point: PropTypes.number
    }),
    paymentMethod: PropTypes.object.isRequired,
    setContent: PropTypes.func.isRequired,
    emitSetState: PropTypes.func.isRequired
  }

  static defaultProps = {
    episodes: [],
    howToPlays: {},
    pack: {},
    course: {},
    selected: [],
    availablePoint: {
      available_point: 0,
      available_point_from_tvasahi_point: 0
    }
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data
    this._isMounted = false
    this.state = {
      productsOpened: false,
      checkedRadio: 'NONE',
      tvasahiPoint: {},
      useMedal: 0,
      useMedalVal: '',
      remainingCoins: 0,
      errorMessage: ''
    }

    this.radioButtons = [
      {
        id: 'NONE',
        text: 'ポイントを利用しない'
      },
      {
        id: 'ALL',
        text: 'すべてのポイントを利用する'
      },
      {
        id: 'SOME',
        text: '一部のポイントを利用する'
      }
    ]
    this.pointPerMedal = 11 // 11ポイント / メダル

    this.confirm = this.confirm.bind(this)
    this.toggleOpen = this.toggleOpen.bind(this)
    this.changeRadioValue = this.changeRadioValue.bind(this)
    this.goBack = this.goBack.bind(this)
    this.howManyMedalShouldUse = this.howManyMedalShouldUse.bind(this)
    this.getRemainingCoins = this.getRemainingCoins.bind(this)
    this.changeUseMedal = this.changeUseMedal.bind(this)
    this.trimValue = this.trimValue.bind(this)
    this.goToMedalCharge = this.goToMedalCharge.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    ;(async () => {
      await this.getTvAsahiPoint()
      this.getRemainingCoins()
    })()
  }

  componentDidUpdate(prevProps) {
    const { howToPlays, availablePoint } = this.props
    if (
      JSON.stringify(howToPlays) !== JSON.stringify(prevProps.howToPlays) ||
      JSON.stringify(availablePoint) !==
        JSON.stringify(prevProps.availablePoint)
    ) {
      this.getRemainingCoins()
    }
  }

  getTvAsahiPoint() {
    const path = [['infra', 'tvasahiPoint']]
    return this.model.fetch(path).then((result) => {
      const tvasahiPoint = _.get(result, ['json', 'infra', 'tvasahiPoint'])
      if (tvasahiPoint) {
        this.setState({ tvasahiPoint })
      }
    })
  }

  /** 利用可能コイン数取得 */
  getAvailablePoint() {
    const path = [['infra', 'availablePoint']]
    return this.model.fetch(path).then((result) => {
      const availablePoint = _.get(result, ['json', 'infra', 'availablePoint'])
      this.context.updateUserInfo({
        availablePoint
      })
      return availablePoint
    })
  }

  toggleOpen() {
    this.setState({ productsOpened: !this.state.productsOpened })
  }

  changeRadioValue(e) {
    const { availablePoint } = this.props
    const availablePointFromTvasahiPoint = _.get(
      availablePoint,
      ['available_point_from_tvasahi_point'],
      0
    )
    const checkedRadio = e.target.value || 'NONE'
    const useMedal =
      checkedRadio === 'ALL'
        ? Math.min(this.totalPrice(), availablePointFromTvasahiPoint)
        : 0
    this.setState({ checkedRadio, useMedal }, () => {
      this.getRemainingCoins()
    })
  }

  /** 支払い後のこり */
  getRemainingCoins() {
    const { availablePoint } = this.props
    const { useMedal } = this.state
    const remainingCoins =
      _.get(availablePoint, ['available_point'], 0) -
      (this.totalPrice() - useMedal)

    this.setState({
      remainingCoins,
      errorMessage: remainingCoins < 0 ? '所持メダル数が不足しています' : ''
    })
    return remainingCoins
  }

  async confirm() {
    const {
      type,
      episodes,
      pack,
      selected,
      howToPlays,
      setContent,
      emitSetState
    } = this.props
    const { checkedRadio, useMedal } = this.state

    /**
     * 利用可能コイン数再取得
     * 別タブ等での支払い状況を考慮
     */
    const availablePoint = await this.getAvailablePoint()
    const availablePointFromTvasahiPoint = _.get(
      availablePoint,
      ['available_point_from_tvasahi_point'],
      0
    )
    const remainingCoins = this.getRemainingCoins() // 支払い後残り

    // 購入対象素材ID
    const refIds = []
    if (PURCHASE_TYPE.EPISODE === type) {
      const episodeRefIds = selected
        .map((metaId) => {
          const products = _.get(howToPlays, [metaId, 'products'], [])
          const product = products.find(
            (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
          )
          return _.get(product, ['ref_id'])
        })
        .filter((v) => v)
      refIds.push(...episodeRefIds)
    } else if (PURCHASE_TYPE.PACK === type) {
      refIds.push(pack.ref_id)
    } else {
      // 月額見放題. 基盤提供画面のため、実装なし. 画面遷移のみ.
      setContent(PURCHASE_CONTENTS.COMPLETED)
      console.error(
        '[Confirm] Plans must be purchased on the infra page. This is a dio front page.'
      )
      return Promise.reject()
    }
    if (remainingCoins < 0) {
      this.setState({ errorMessage: '所持メダル数が不足しています' })
      this.getTvAsahiPoint()
      return Promise.reject()
    }

    /** テレ朝ポイントの妥当性を検証 */
    if (useMedal < 0 || availablePointFromTvasahiPoint < useMedal) {
      this.setState({ errorMessage: 'テレ朝ポイントが不足しています' })
      this.getTvAsahiPoint()
      return Promise.reject()
    }

    // 購入
    const path = ['infra', 'usePoint']
    const args = [
      {
        materialIds: refIds,
        tvasahiPointUseType: checkedRadio,
        tvasahiPoint:
          checkedRadio === 'SOME' ? useMedal * this.pointPerMedal : 0
      }
    ]
    return this.model
      .call(path, args)
      .then((result) => {
        webApp.utils.debug(
          `[PurchaseContent Confirm] usePoint ended. result: ${JSON.stringify(
            result
          )}`
        )

        // 利用可能コイン数再取得
        this.getAvailablePoint()

        // 結果通知と購入済みリスト再生成.
        emitSetState({
          result,
          purchased: episodes.filter((meta) => selected.includes(meta.meta_id))
        })

        // コンバージョンログ
        this.recommendActionLogHistoryConversion()

        // 画面遷移
        //setContent(PURCHASE_CONTENTS.COMPLETED);
        // SPAだと購入済み一覧がとれないので、リダイレクトで対応
        window.location.href =
          webApp.utils.rootUrl() +
          routes.purchase.makePath({}, { content: 'completed' })
      })
      .catch((err) => webApp.utils.handleFalcorError(err, this.context))
  }

  /** レコメンド(コンバージョンログ蓄積) */
  recommendActionLogHistoryConversion() {
    const { type, howToPlays, selected } = this.props
    if (PURCHASE_TYPE.EPISODE !== type || !selected.length)
      return Promise.resolve()
    const itemIds = selected
      .map((metaId) => {
        return `${metaId}|${webApp.utils.calcPrice(howToPlays, [metaId])}`
      })
      .join(',')
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) return Promise.resolve()
    const path = ['action_log', 'history', 'conversion', itemIds, sessionId]
    return this.model.fetch([path])
  }

  totalPrice() {
    const { type, howToPlays, pack, course, selected } = this.props
    if (PURCHASE_TYPE.EPISODE === type) {
      return webApp.utils.calcPrice(howToPlays, selected) || 0
    }
    if (PURCHASE_TYPE.PACK === type) {
      return _.get(pack, ['active_pricing', 'price'], 0)
    }
    if (PURCHASE_TYPE.PLAN === type) {
      return _.get(course, ['active_pricing', 'price'], 0)
    }
    return null
  }

  goBack(e) {
    e.preventDefault()
    this.props.setContent(PURCHASE_CONTENTS.SELECT)
  }

  /** 使用メダルの計算 */
  howManyMedalShouldUse() {
    const { availablePoint } = this.props
    const { checkedRadio, useMedal } = this.state
    const availablePointFromTvasahiPoint = _.get(
      availablePoint,
      ['available_point_from_tvasahi_point'],
      0
    )

    switch (checkedRadio) {
      case 'ALL':
        const medal = Math.min(
          this.totalPrice(),
          availablePointFromTvasahiPoint
        )
        return (
          <span className="coin-balance coin-remaining-total">
            {medal}（
            <span className="coin-balance--pink">
              {medal * this.pointPerMedal}P
            </span>
            ）
          </span>
        )

      case 'SOME':
        return (
          <span className="coin-balance coin-remaining-total">
            {useMedal || 0}（
            <span className="coin-balance--pink">
              {useMedal * this.pointPerMedal}P
            </span>
            ）
          </span>
        )

      case 'NONE':
      default:
        return (
          <span className="coin-balance coin-remaining-total">
            0（<span className="coin-balance--pink">0P</span>）
          </span>
        )
    }
  }

  /** 使用メダル変更 */
  changeUseMedal(e) {
    this.setState({ useMedalVal: e.target.value })
  }

  /* valueのトリミング */
  trimValue(e) {
    const { availablePoint } = this.props
    let val = e.target.value
    const availablePointFromTvasahiPoint = _.get(
      availablePoint,
      ['available_point_from_tvasahi_point'],
      0
    )

    /** 数字以外を削除 */
    if (val) {
      val = val.replace(/[^0-9０-９]/g, '')
    }
    /** 数字を全角から半角に変換 */
    if (val) {
      val = val.replace(/[０-９]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      })
    }

    /** 必要・可能以上のポイントが入力されているときは必要・可能なポイントの最大値に書き換える */
    if (
      val &&
      Math.min(this.totalPrice(), availablePointFromTvasahiPoint) < Number(val)
    ) {
      val = Math.min(this.totalPrice(), availablePointFromTvasahiPoint)
    }

    this.setState(
      {
        useMedal: val ? Number(val) : 0,
        useMedalVal: Number(val) || ''
      },
      () => {
        /** 支払い後残りの計算 */
        this.getRemainingCoins()
      }
    )
  }

  /** メダルチャージページへの遷移 */
  goToMedalCharge() {
    const { type, episodes, pack, selected, howToPlays } = this.props
    // 残高足りなければメダルチャージ画面
    const ax = new Axios({
      baseURL: '/api/crypto',
      timeout: 3000,
      headers: {},
      // タイムアウトしていないのにタイムアウトエラーがでる対応ととして
      // retryオプションを付ける
      retry: 1
    })
    let data = { contents: [], total: this.totalPrice() }
    if (PURCHASE_TYPE.PACK === type) {
      data.contents.push({
        title: pack.name,
        amount: _.get(pack, ['active_pricing', 'price'])
      })
    } else {
      const selectedMetas = episodes.filter((meta) =>
        selected.includes(meta.meta_id)
      )
      _.forEach(selectedMetas, (meta) => {
        const price = webApp.utils.calcPrice(howToPlays, [meta.meta_id])
        let [metaName, subTitle] = webApp.utils.titles(meta)
        metaName = `${metaName} ${subTitle}`
        data.contents.push({
          title: metaName,
          amount: price
        })
      })
    }
    ax.get('/encrypto', { data: JSON.stringify(data) })
      .then((response) => {
        console.log(`response: ${JSON.stringify(response.data)}`)
        this.context.history.push(
          routes.medal_charge.makePath(
            {},
            {
              content: 'charge',
              data: response.data.encrypted,
              src: this.context.routeHandler.url
            }
          )
        )
      })
      .catch((err) => console.log(JSON.stringify(err)))
  }

  render() {
    const {
      type,
      selected,
      episodes,
      pack,
      course,
      availablePoint,
      howToPlays,
      paymentMethod
    } = this.props
    const {
      productsOpened,
      checkedRadio,
      tvasahiPoint,
      useMedal,
      useMedalVal,
      errorMessage,
      remainingCoins
    } = this.state
    const totalPrice = this.totalPrice()
    const selectedMetas = episodes.filter((meta) =>
      selected.includes(meta.meta_id)
    )
    const className = productsOpened ? 'on' : ''
    const availablePointFromTvasahiPoint = _.get(
      availablePoint,
      ['available_point_from_tvasahi_point'],
      0
    )

    return (
      <div className="c-login">
        <div className="c-login-wrapper coin-step-head">
          <Steps
            current={PURCHASE_CONTENTS.CONFIRM}
            className="coin-payment-wrapper"
          />
        </div>
        <div className="c-login-cont">
          <div className="c-login-cont-wrapper coin-payment-wrapper">
            <div className="payment-finish-wrapper">
              <p className="payment-subtext top-text">
                注文内容をご確認の上、「購入を確定する」ボタンを押してください。
              </p>
              <div className="buy-product-wrapper">
                <p className="c-login-subtext w-600 payment-finish-subtitle">
                  商品情報
                </p>
                <div className="finish-wrapper">
                  <ul className="finish-area">
                    <li className="payment-finish-balance fix w-600  payment-sp confirm-list product-info">
                      <span className="payment-subtext">
                        合計（{selected.length}商品）
                      </span>
                      {PURCHASE_TYPE.PLAN !== type && (
                        <span className="balance-area">
                          <span className="coin-balance coin-total">
                            {totalPrice}
                          </span>
                        </span>
                      )}
                      {PURCHASE_TYPE.PLAN === type && (
                        <span className="balance-area">
                          <span className="coin-total monthly-total">
                            <span className="monthly">月額</span>
                            <span className="total-price">{totalPrice}</span>
                            <span className="sub-text-m">円</span>
                            <span className="c-login-subtext">（税込）</span>
                          </span>
                        </span>
                      )}
                    </li>
                  </ul>
                  <ul className="finish-area">
                    <li
                      className={`c-login-subtext detail payment-finish ${className}`}
                    >
                      <span
                        className="product-detail"
                        onClick={this.toggleOpen}
                      >
                        商品詳細
                      </span>
                    </li>
                    {PURCHASE_TYPE.EPISODE === type &&
                      selectedMetas.map((meta) => {
                        let [metaName, subTitle] = webApp.utils.titles(meta)
                        metaName = `${metaName} ${subTitle}`
                        return (
                          <li
                            key={meta.meta_id}
                            className={`payment-finish-balance movie-list confirm-list more-product ${className}`}
                          >
                            <span>{metaName}</span>
                            <span className="balance-area">
                              <span className="coin-balance">
                                {webApp.utils.calcPrice(howToPlays, [
                                  meta.meta_id
                                ])}
                              </span>
                            </span>
                          </li>
                        )
                      })}
                    {PURCHASE_TYPE.PACK === type && (
                      <li
                        className={`payment-finish-balance movie-list confirm-list more-product ${className}`}
                      >
                        <span>{pack.name}</span>
                        <span className="balance-area">
                          <span className="coin-balance">
                            {_.get(pack, ['active_pricing', 'price'])}
                          </span>
                        </span>
                      </li>
                    )}
                    {PURCHASE_TYPE.PLAN === type && (
                      <li
                        className={`payment-finish-balance movie-list confirm-list more-product ${className}`}
                      >
                        <span>{course.name}</span>
                        <span className="balance-area">
                          <span className="monthly">月額</span>
                          <span className="total-price">
                            {_.get(course, ['active_pricing', 'price'])}
                          </span>
                          <span className="sub-text-m">円</span>
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              <p className="c-login-subtext w-600 payment-finish-balance confirm-list">
                注文内容
              </p>
              <div className="finish-wrapper">
                <ul className="finish-area">
                  <li className="payment-finish-balance payment-sp confirm-list order-details">
                    <span className="payment-subtext subtext-sp">
                      お支払い方法
                    </span>
                    <span className="coin-payment">
                      {paymentMethod.name}
                      {PURCHASE_TYPE.PLAN !== type && (
                        <span className="balance-area">
                          <span className="balance confirm">残高</span>
                          <span className="coin-balance">
                            {_.get(availablePoint, ['available_point'], 0)}
                          </span>
                        </span>
                      )}
                    </span>
                  </li>
                </ul>
                <ul className="finish-area point-area">
                  <li className="payment-finish-balance">
                    <span className="payment-subtext subtext-sp">
                      テレ朝ポイント利用
                    </span>
                    <p className="point-area__possessionpoints">
                      保有ポイント
                      <span className="point-area__possessionpoints--pink">
                        <span className="point-area__possessionpoints--large">
                          {_.get(tvasahiPoint, ['point'], 0)}
                        </span>
                        P
                      </span>
                      （
                      <span className="coin-balance coin-total">
                        {availablePointFromTvasahiPoint}
                      </span>
                      相当）
                    </p>
                    <p className="point-area__limited">
                      期間限定{_.get(tvasahiPoint, ['limited_point'], 0)}P含む
                    </p>
                  </li>
                  <li className="payment-finish-balance payment-sp confirm-list">
                    <p className="point-area__notes">
                      ※テレ朝ポイントは11P（=1メダル）から利用が可能です。11Pを下回るポイントは利用できません。
                      <br />
                      ※購入後に利用ポイントの変更はできません。
                      <br />
                      ※「すべてのポイントを利用する」を選択した場合、ご購入時点の利用可能なすべてのポイントが利用ポイントとして確定いたします。
                      <br />
                      ※期間限定ポイントを所持している場合、通常ポイントより優先的に利用されます。
                      <br />
                      ※期間限定ポイントをご利用の場合、購入完了時に有効期限を過ぎているポイントは失効になります。
                    </p>
                    <ul className="point-area__radiobtn">
                      {this.radioButtons.map(({ id, text }) => (
                        <li key={id}>
                          <input
                            type="radio"
                            name="point"
                            value={id}
                            className="point-area__radiobtn-input"
                            id={id}
                            checked={checkedRadio === id}
                            onChange={this.changeRadioValue}
                          />
                          <label htmlFor={id}>{text}</label>
                          {id === 'SOME' && checkedRadio === 'SOME' && (
                            <div className="point-area__medalinput">
                              <input
                                type="text"
                                name="useMedal"
                                value={useMedalVal}
                                pattern="\d+"
                                inputMode="numeric"
                                onChange={this.changeUseMedal}
                                onBlur={this.trimValue}
                                className={cn('point-area__medalinput-number', {
                                  'point-area__error-number':
                                    useMedal > availablePointFromTvasahiPoint
                                })}
                              />
                              メダル
                              {useMedal > availablePointFromTvasahiPoint && (
                                <p className="point-area__error-text">
                                  ※利用可能な最大メダル数を超過しています。
                                  <br />
                                  利用可能な最大メダル数は
                                  {availablePointFromTvasahiPoint}
                                  です。
                                </p>
                              )}
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>

                <ul className="finish-area">
                  <li className="payment-finish-balance payment-sp confirm-list">
                    <span className="payment-subtext subtext-sp">
                      お支払い金額
                    </span>
                    {PURCHASE_TYPE.PLAN !== type && (
                      <div className="price-detail">
                        <p className="price-detail__item">
                          テレ朝ポイント利用
                          {this.howManyMedalShouldUse()}
                        </p>
                        <p className="price-detail__item">
                          メダル支払い
                          <span className="coin-balance coin-remaining-total">
                            {totalPrice - useMedal}
                          </span>
                        </p>
                        <p className="price-detail__item">
                          お支払い合計
                          <span className="coin-balance coin-remaining-total coin-balance--large">
                            {totalPrice}
                          </span>
                        </p>
                        <p className="price-detail__item">
                          支払い後のこり
                          <span className="coin-balance coin-remaining-total">
                            {remainingCoins}
                          </span>
                        </p>
                      </div>
                    )}
                    {PURCHASE_TYPE.PLAN === type && (
                      <span className="price-total w-600">
                        <span className="monthly-total">月額</span>
                        <span className="total-price">{totalPrice}</span>
                        <span className="sub-text-m">円</span>
                        <span className="c-login-subtext tax">（税込）</span>
                      </span>
                    )}
                  </li>
                </ul>
              </div>

              <p className="login-already payment-finish">
                「購入を確定する」ボタンを押してご注文いただくことで、
                <span>
                  <a href={this.config.guide_link.kiyaku} target="_blank">
                    ご利用にあたっての同意規約
                  </a>
                </span>
                に同意したものとします。
              </p>
              {PURCHASE_TYPE.PLAN === type && (
                <p
                  className="c-login-flowwrapper-text finish"
                  style={{ color: 'red' }}
                >
                  月額見放題の購入は基盤提供画面のため実装なし.
                </p>
              )}
            </div>
            {this._isMounted && (
              <StyledDiv1>
                {errorMessage && (
                  <StyledP>所持メダル数が不足しています</StyledP>
                )}
                {errorMessage && (
                  <StyledButton1 onClick={this.goToMedalCharge}>
                    メダルチャージ
                  </StyledButton1>
                )}
                <StyledButton1
                  disabled={Boolean(errorMessage)}
                  onClick={this.confirm}
                >
                  購入を確定する
                </StyledButton1>
                <StyledDiv2>
                  <StyledButton2 onClick={this.goBack}>戻る</StyledButton2>
                </StyledDiv2>
              </StyledDiv1>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const StyledDiv1 = styled.div`
  margin-top: 54px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;

  ${mediaQuery()} {
    margin-top: 34px;
    gap: 14px;
  }
`

const StyledP = styled.p`
  flex-basis: 100%;
  color: #f0027f;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
`

const StyledButton1 = styled.button`
  padding: 15px 20px;
  max-width: 100%;
  width: 346px;
  background-color: #343434;
  border: 0;
  border-radius: 5px;
  display: block;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  transition: opacity 0.3s, background-color 0.3s;

  &:disabled {
    background-color: #939394;
    opacity: 0.7;
    color: #fff;
    cursor: default;
  }

  &:not(:disabled) {
    &:hover {
      background-color: #939394;
      opacity: 0.7;
      color: #fff;
    }
  }
`

const StyledDiv2 = styled.div`
  flex-basis: 100%;
  text-align: center;

  ${mediaQuery()} {
    margin-top: 26px;
  }
`

const StyledButton2 = styled.button`
  padding: 0;
  border: 0;
  color: #939394;
  font-size: 1.3rem;
  text-decoration: underline;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`
