/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledButton } from './styles'

/** index Component */
const Button2 = ({ children = null, ...props }) => {
  return (
    <StyledButton type="button" {...props}>
      {children}
    </StyledButton>
  )
}

export default Button2

Button2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
}
