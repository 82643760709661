import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledUl, StyledLi, StyledButton, StyledRadio } from './style'

/** List2 Component */
const List2 = ({ orderBy = 'asc', setOrderBy = () => {}, ...props }) => {
  return (
    <StyledUl {...props}>
      <StyledLi>
        <StyledButton type="yellow" as="label" checked={orderBy === 'desc'}>
          <StyledRadio
            type="radio"
            name="orderBy"
            value="desc"
            checked={orderBy === 'desc'}
            onChange={() => setOrderBy('desc')}
          />
          新しい順
        </StyledButton>
      </StyledLi>
      <StyledLi>
        <StyledButton type="yellow" as="label" checked={orderBy === 'asc'}>
          <StyledRadio
            type="radio"
            name="orderBy"
            value="asc"
            checked={orderBy === 'asc'}
            onChange={() => setOrderBy('asc')}
          />
          古い順
        </StyledButton>
      </StyledLi>
    </StyledUl>
  )
}

export default List2

List2.propTypes = {
  orderBy: PropTypes.oneOf(['asc', 'desc']),
  setOrderBy: PropTypes.func
}
