import styled from 'styled-components'

export const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export const StyledLi = styled.li`
  display: inline-block;
`

export const StyledLabel = styled.label`
  padding: 5px 15px;
  background-color: #fff;
  border: 2px solid #ff009b;
  border-radius: 3px;
  display: block;
  color: #ff009b;
  line-height: 1;
  font-size: 1.3rem;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ff009b;
    color: #fff;
  }

  @media (max-width: 1024px) {
    font-size: 1.2rem;

    &:hover {
      background-color: #fff;
      color: #ff009b;
    }
  }
`

export const StyledCheckbox = styled.input`
  &:checked {
    & ~ ${StyledLabel} {
      background-color: #ff009b;
      color: #fff;
    }
  }
`
