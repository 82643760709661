import React, { memo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import styled, { createGlobalStyle, css } from 'styled-components'
import routes from '../../routes'
import webApp from '../../../exdio/utils/exdioWebAppUtils'
import { FAVORITE_TYPE } from '../../../../constants/app'
import useIsMounted from '../../../hooks/useIsMounted'

/* hooks */
import { defaultState } from './hooks/useSearchParams'

/* components */
import Pager from '../../../common/components/renewal/Pager2' // ページャー
import SearchBoxText from './SearchBoxText'
import SortElm from './SortElm'
import FilterElm from './FilterElm'
import YearsElm from './YearsElm'
import ExFilterElm from './ExFilterElm'
import SwitchElm from './SwitchElm'
import EpisodeList from './EpisodeList'
import Skeletons from './Skeletons'
import Alert from './Alert'

/* style */
import { mediaQuery } from '../../../exdio/components/style'
import { StyledTitle } from './EpisodeList/ListItem/style'
import { StyledChecked } from './EpisodeList/ListItem/Title'

/**
 * エピソードリスト
 * フィルター&ソート
 */
const FilterSort = (
  {
    // for visual
    defaultListType = 'grid',
    placeholder = '検索　※上限50文字です',
    hideSwitches = false,
    hasBalloon = false,
    hasFavorite = false,
    selectableYears = {},
    // EpisodeListItem
    episodeListItemProps = {
      showNew: false,
      showChecked: false,
      showDelivery: false,
      showCoin: false,
      showCaption: false,
      onlySubTitle: false,
      clampWidth: 2,
      images: {
        on: '/images/exdio/renewal/icon_mylist_added.svg',
        off: ''
      }
    },
    // for search
    searchParams = {
      state: defaultState,
      response: {
        episodes: [],
        totalCount: 0
      },
      set: () => {},
      paging: () => {},
      isLoading: false
    },
    exFilterProps = [],
    ...props
  },
  context
) => {
  const model = context.falcorModel.batch(100)
  const isApp = webApp.utils.isApp(context)
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isMounted = useIsMounted()
  const [preSearchWord, setPreSearchWord] = useState('')
  const [listType, setListType] = useState(defaultListType)
  const [howToPlays, setHowToPlays] = useState(null)
  const [favorites, setFavorites] = useState([])
  const [isAdded, setIsAdded] = useState(false)
  const [isShow, setIsShow] = useState(false) // 子コンポーネントで使用するため分割代入しない
  const [selectedElm, setSelectedElm] = useState('') // 子コンポーネントで使用するため分割代入しない
  const typingTimer = useRef(null)
  const timeoutId = useRef(null)
  const latestRequestIdRef = useRef(0)
  const { state, response, isLoading } = searchParams
  const {
    pageNum,
    onlyWatchedAll,
    filteredBy,
    sortedBy,
    selectedYear,
    pagerOptions
  } = {
    ...defaultState,
    ...state
  }
  const { episodes, totalCount } = response

  /**
   * 検索ワード変更時
   * タイピング毎にリクエストが飛ばないように{duration}の時間だけ待機
   */
  const onChangeSearchWord = (word) => {
    const strLengthLimit = 50 // 文字数上限
    const truncatedSearchWord = word.slice(0, strLengthLimit)
    const duration = 500
    setPreSearchWord(truncatedSearchWord)

    // タイマーリセット
    if (typingTimer.current) clearTimeout(typingTimer.current)
    typingTimer.current = setTimeout(async () => {
      searchParams.set({
        searchWord: truncatedSearchWord
      })
    }, duration)
  }

  /** 検索ワードクリア時 */
  const onClearSearchWord = () => {
    setPreSearchWord('')
    searchParams.set({
      searchWord: ''
    })
  }

  /** searchParamsの更新 */
  const updateSearchParams = (newState = {}) => {
    if (size(newState)) searchParams.set(newState)
  }

  const getHowToPlays = async () => {
    const ids = episodes.map((episode) => episode.id)
    const path = ['meta', 'howToPlay', false, ids]
    return model
      .fetch([path])
      .then(
        (result) => get(result, ['json', 'meta', 'howToPlay', false]) || null
      )
  }

  /**
   * マイリストを取得
   */
  const getFavorites = async () => {
    if (!isLoggedIn || !hasFavorite) return Promise.resolve([])
    const path = ['favorites', FAVORITE_TYPE.META, 0, 1000]
    return model
      .fetch([path])
      .then((result) => get(result, ['json', ...path], []))
      .catch((e) => webApp.utils.handleFalcorError(e, context))
  }

  /** マイリストset */
  const reloadFavorites = async () => {
    const resFavorites = await getFavorites()
    if (size(resFavorites)) setFavorites(resFavorites)
  }

  /** マイリスト脱着 */
  const toggleFavorites = async (meta, isAdd = true) => {
    const path = ['favorite', isAdd ? 'add' : 'delete']
    const args = [{ modelType: FAVORITE_TYPE.META, modelId: meta.meta_id }]
    if (isLoggedIn) {
      await model.call(path, args)
      reloadFavorites()
      setIsAdded(isAdd)
      setIsShow(true)
      if (timeoutId.current) clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(() => {
        setIsShow(false)
      }, 3000)
    } else {
      context.history.push(
        routes.login.makePath(null, {
          redirect: context.routeHandler.path
        })
      )
    }
  }

  useEffect(() => {
    reloadFavorites()
  }, [])

  useEffect(() => {
    let ignore = false

    const fetchData = async () => {
      if (size(episodes) === 0) return

      latestRequestIdRef.current += 1
      const requestId = latestRequestIdRef.current

      try {
        const resHowToPlays = await getHowToPlays()
        if (!ignore && requestId === latestRequestIdRef.current) {
          setHowToPlays(resHowToPlays)
        }
      } catch (e) {
        webApp.utils.handleFalcorError(e, context)
      }
    }

    fetchData()

    return () => {
      ignore = true
    }
  }, [episodes])

  const pagerProps = {
    option: pagerOptions,
    episodeLength: totalCount,
    currentPageNum: pageNum,
    updateCurrentPageNum: (nextPageNum) => searchParams.paging(nextPageNum)
  }

  return (
    <StyledWrapper id="episodes-list" listType={listType} {...props}>
      <StyledHeader>
        <StyledDiv1>
          <StyledSearchBoxText
            searchWord={preSearchWord}
            placeholder={placeholder}
            onChangeSearchWord={(e) =>
              onChangeSearchWord(get(e, ['target', 'value'], ''))
            }
            onClearSearchWord={onClearSearchWord}
          />
          <Pager {...pagerProps} />
        </StyledDiv1>
        <StyledDiv2>
          <StyledSortElm
            sortedBy={sortedBy}
            updateSearchParams={updateSearchParams}
            stateSelectedElm={[selectedElm, setSelectedElm]}
          />
          <StyledFilterElm
            filteredBy={filteredBy}
            updateSearchParams={updateSearchParams}
            stateSelectedElm={[selectedElm, setSelectedElm]}
          />
          {size(selectableYears) > 0 && (
            <StyledYearsElm
              selectedYear={selectedYear}
              updateSearchParams={updateSearchParams}
              stateSelectedElm={[selectedElm, setSelectedElm]}
              {...selectableYears}
            />
          )}
          {size(exFilterProps) > 0 &&
            exFilterProps.map((ex) => (
              <StyledExFilterElm
                key={ex.slug}
                stateSelectedElm={[selectedElm, setSelectedElm]}
                {...ex}
              />
            ))}
          {!hideSwitches && (
            <StyledSwitchElm listType={listType} updateListType={setListType} />
          )}
        </StyledDiv2>
      </StyledHeader>
      <StyledEpisodeList
        episodes={episodes}
        howToPlays={howToPlays}
        favorites={favorites}
        target={selectedYear && !isApp ? '_blank' : null}
        isHide={isLoading}
        listType={listType}
        clampWidth={listType === 'grid' ? 2 : null}
        hasBalloon={hasBalloon}
        hasFavorite={hasFavorite}
        toggleFavorites={toggleFavorites}
        onlyWatchedAll={onlyWatchedAll}
        {...episodeListItemProps}
      />
      <StyledSkeletons
        pagerOptions={pagerOptions}
        listType={listType}
        hasThumb={listType !== 'list'}
        textRowLength={listType === 'default' ? 2 : 0}
        hasPrice={listType === 'default'}
        hasPlayButton={listType === 'list'}
        isHide={!isLoading}
      />

      <Alert stateIsShow={[isShow, setIsShow]} isAdded={isAdded} />

      {pagerOptions && pagerOptions.showBottom && (
        <Pager {...pagerProps} clickScroll />
      )}
      <GlobalStyle />
    </StyledWrapper>
  )
}

export default memo(FilterSort)

FilterSort.propTypes = {
  /** リストスタイルの初期値 */
  defaultListType: PropTypes.oneOf(['default', 'list', 'grid']),
  /** placeholderに表示する文字列 */
  placeholder: PropTypes.string,
  /** SwitchElmの表示制御 */
  hideSwitches: PropTypes.bool,
  /** エピソードホバーであらすじダイアログを出すか */
  hasBalloon: PropTypes.bool,
  /** マイリストボタンを表示するか */
  hasFavorite: PropTypes.bool,
  /** YearsElmの表示制御 */
  selectableYears: PropTypes.shape({
    to: PropTypes.number
  }),
  /** episodeListItemで使用するprops */
  episodeListItemProps: PropTypes.shape({
    showNew: PropTypes.bool,
    showChecked: PropTypes.bool,
    showDelivery: PropTypes.bool,
    showCoin: PropTypes.bool,
    showCaption: PropTypes.bool,
    onlySubTitle: PropTypes.bool,
    clampWidth: PropTypes.number,
    images: PropTypes.shape({
      on: PropTypes.string,
      off: PropTypes.string
    })
  }),
  /** 検索用のパラメータ */
  searchParams: PropTypes.shape({
    state: PropTypes.shape({
      /** ページャーの設定 */
      pagerOptions: PropTypes.shape({
        episodesPerPages: PropTypes.number,
        range: PropTypes.number,
        showBottom: PropTypes.bool
      }),
      seasonIds: PropTypes.arrayOf(PropTypes.number),
      childEpisodeIds: PropTypes.arrayOf(PropTypes.number),
      selectedYear: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
      notTags: PropTypes.arrayOf(PropTypes.string),
      forceUpdateParams: PropTypes.func,
      pageNum: PropTypes.number,
      filteredBy: PropTypes.oneOf(['', 'watched', 'not_watched']),
      sortedBy: PropTypes.string
    }),
    response: PropTypes.shape({
      episodes: PropTypes.arrayOf(PropTypes.object),
      totalCount: PropTypes.number
    }),
    set: PropTypes.func,
    paging: PropTypes.func,
    isLoading: PropTypes.bool
  }),
  exFilterProps: PropTypes.arrayOf(
    PropTypes.shape({
      filteredBy: PropTypes.string,
      updateFilteredBy: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      initialValue: PropTypes.string,
      slug: PropTypes.string
    })
  )
}

FilterSort.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  routeHandler: PropTypes.object
}

const StyledWrapper = styled.div`
  padding: 25px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 25px;

  ${mediaQuery()} {
    padding: 15px;
    row-gap: 17px;
  }
`

export const StyledEpisodeList = styled(EpisodeList).withConfig({
  shouldForwardProp: (prop) => !['isHide'].includes(prop)
})`
  display: ${({ isHide }) => (isHide ? 'none !important' : null)};

  ${({ listType, hasFavorite, images }) => {
    if (
      listType === 'grid' &&
      hasFavorite &&
      get(images, ['on']) !== '/images/exdio/renewal/icon_mylist_added.svg'
    ) {
      return css`
        ${StyledTitle} {
          padding-right: 45px;
          position: relative;

          ${StyledChecked} {
            margin-right: 0;
            width: 20px;
            height: 18px;
            position: absolute;
            top: 1px;
            right: 25px;
          }
        }
      `
    }
    return null
  }}
`

const StyledSkeletons = styled(Skeletons).withConfig({
  shouldForwardProp: (prop) => !['isHide'].includes(prop)
})`
  display: ${({ isHide }) => (isHide ? 'none !important' : null)};
`

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px 0;
`

export const StyledDiv1 = styled.div`
  display: flex;
  align-items: center;
  gap: 0 15px;

  ${mediaQuery()} {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 15px 0;
  }
`

const StyledSwitchElm = styled(SwitchElm)``

const StyledDiv2 = styled.div`
  display: flex;
  align-items: center;
  gap: 0 15px;

  ${mediaQuery()} {
    flex-wrap: wrap;
    gap: 15px 10px;
  }

  ${StyledSwitchElm} {
    margin-left: auto;
  }
`

const StyledSortElm = styled(SortElm)`
  width: 210px;

  ${mediaQuery()} {
    width: 60%;
  }
`

const StyledFilterElm = styled(FilterElm)`
  width: 120px;

  ${mediaQuery()} {
    width: calc(40% - 10px);
  }
`

const StyledYearsElm = styled(YearsElm)`
  width: 210px;

  ${mediaQuery()} {
    width: 60%;
  }
`

const StyledExFilterElm = styled(ExFilterElm)`
  width: 210px;

  ${mediaQuery()} {
    width: calc(40% - 10px);
  }
`

export const StyledSearchBoxText = styled(SearchBoxText)`
  flex: 1 0;
`

const GlobalStyle = createGlobalStyle`
  .appli {
    ${StyledWrapper} {
      img {
        margin: 0;
        width: auto;
      }
    }
  }
`
