import styled, { css } from 'styled-components'
import { mediaSp } from '../styles'
import Button1 from './Button1'
import Button2 from './Button2'

const borderColor = '#4a4a4a'
const fontColor = '#0e0e0e'

export const StyledDiv = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${mediaSp} {
    padding: 0 15px;
  }
`

export const StyledUl = styled.ul`
  display: flex;
  justify-content: space-between;
`

export const StyledLi = styled.li`
  position: relative;
  margin-left: 30px;

  @media ${mediaSp} {
    margin-left: 5px;
  }

  &:first-child {
    margin-left: 0;
  }

  ${({ hasDot }) => {
    if (!hasDot) return null
    return css`
      margin-left: 0;
      padding-left: 2em;

      @media ${mediaSp} {
        margin-left: 5px;
        padding-left: 0;
      }

      &::before {
        margin: auto 0;
        width: 1em;
        height: 1em;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 1em;
        bottom: 0;
        content: ':';

        @media ${mediaSp} {
          content: none;
        }
      }
    `
  }}
`

export const StyledSelect = styled.select`
  width: 70px;
  height: 40px;
  border: 3px solid ${borderColor};
  border-radius: 0;
  color: ${fontColor};
  font-size: 1.5rem;
  text-align: center;

  @media ${mediaSp} {
    width: 3em;
    height: 35px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`

export const StyledButton1 = styled(Button1)`
  @media ${mediaSp} {
    width: 50px;
  }
`

export const StyledButton2 = styled(Button2)`
  @media ${mediaSp} {
    min-width: 70px;
  }
`
