import styled from 'styled-components'
import { mediaQuery } from '../../../style'
import { lightYellow, pcMinSize, spMinSize } from '../styles'
import Heading1 from '../Heading1'
import Button3 from '../common/Button/Button3'

export const StyledSection = styled.section`
  position: relative;

  ${mediaQuery()} {
    padding-top: 20px;
  }

  canvas {
    min-width: 100%;
  }
`

export const StyledHeading1 = styled(Heading1)`
  height: 219px;
  position: relative;
  z-index: 1;

  ${mediaQuery()} {
    height: 45px;
  }

  img {
    position: relative;
    z-index: 1;

    ${mediaQuery()} {
      width: 216px;
    }
  }

  &.lazyloaded::after {
    margin: 0 auto;
    width: 1158px;
    height: 239px;
    background: url(/images/exdio/renewal/gariben_daigaku/top/bg3_2.webp)
      no-repeat bottom center / 1158px auto;
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0;
    content: '';

    ${mediaQuery()} {
      width: ${spMinSize};
      height: calc(239px * (320 / 1158));
      background-size: 100% auto;
      bottom: -4px;
    }
  }
`

export const StyledDiv1 = styled.div`
  background-color: ${lightYellow};
  position: relative;
  overflow: hidden;
`

export const StyledDiv2 = styled.div`
  margin: 0 auto;
  padding: 90px 0 40px;
  width: ${pcMinSize};
  position: relative;
  z-index: 1;

  ${mediaQuery()} {
    padding: 52px 5px 15px;
    width: auto;
  }
`

export const StyledButton3 = styled(Button3)`
  margin: 50px auto 0;
  width: 340px;
  font-size: 2rem;
  cursor: pointer;

  ${mediaQuery()} {
    margin-top: 10px;
    width: auto;
    font-size: 1.6rem;
  }
`
