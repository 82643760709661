import React from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../utils/exdioWebAppUtils'
import PlayButton from '../../EpisodeDefault/PlayButton'
import { whatKindOfVideo } from '../../EpisodeDefault/util'
import useMediaQuery from '../../../../../hooks/useMediaQuery'

/* components */
import Mask from './Mask'
import NextPrevText from './NextPrevText'

/* style */
import { StyledDiv1, StyledDiv2, StyledButton } from './style'

/** 動画カバー */
const CommonVideoArea = ({
  meta = undefined,
  status = {
    isFree: false,
    isNotFree: false,
    isPurchased: false,
    isNotPurchased: false,
    isInCourse: false,
    isNotInCourse: false,
    isGeoDeliverable: null,
    isDeviceNotAvailable: null,
    limitDate: null,
    isPossible: null,
    isBelonging: null
  },
  product = {},
  onClickPlay = () => {},
  metaName = '',
  thumbnailUrl = '',
  nextPrevText = '',
  showMask = false,
  showLogin = false,
  inViewTerm = false,
  ...props
}) => {
  const isSp = useMediaQuery()
  const kindOfVideo = whatKindOfVideo(status.displayMode)
  const showRest = status.isPurchased || kindOfVideo === 'FREE'
  const rest = showRest && isSp ? webApp.utils.rest(meta) : null

  return (
    <StyledButton type="button" onClick={onClickPlay} {...props}>
      {inViewTerm && (
        <PlayButton status={status} product={product} showLogin={showLogin} />
      )}
      {rest && <StyledDiv1>{rest}</StyledDiv1>}
      <StyledDiv2>
        <img src={thumbnailUrl} alt={metaName} width="1080" height="610" />
        {showMask && (
          <>
            <Mask />
            <NextPrevText>{nextPrevText}</NextPrevText>
          </>
        )}
      </StyledDiv2>
    </StyledButton>
  )
}

export default CommonVideoArea

CommonVideoArea.propTypes = {
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  product: PropTypes.object,
  onClickPlay: PropTypes.func,
  metaName: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  nextPrevText: PropTypes.string,
  showMask: PropTypes.bool,
  showLogin: PropTypes.bool,
  inViewTerm: PropTypes.bool
}
