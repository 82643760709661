import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQuery } from '../../../../exdio/components/style'

/** テキスト検索 */
const SearchBoxText = ({
  searchWord = '',
  placeholder = '',
  onChangeSearchWord = () => {},
  onClearSearchWord = () => {},
  ...props
}) => {
  return (
    <StyledDiv {...props}>
      <StyledInput
        type="text"
        value={searchWord}
        placeholder={placeholder}
        onChange={onChangeSearchWord}
      />
      <StyledButton type="button" onClick={onClearSearchWord} />
    </StyledDiv>
  )
}

export default memo(SearchBoxText)

SearchBoxText.propTypes = {
  searchWord: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeSearchWord: PropTypes.func,
  onClearSearchWord: PropTypes.func
}

const StyledDiv = styled.div`
  padding: 0 38px;
  position: relative;
  background: #e5e6cb;
  border-radius: 20px;

  ${mediaQuery()} {
    width: 100%;
  }

  &::before {
    margin: auto;
    width: 18px;
    height: 18px;
    background: url(/images/exdio/renewal/icon_search.svg) no-repeat center /
      100%;
    display: block;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 12px;
    content: '';
  }
`

const StyledInput = styled.input`
  padding: 0;
  width: 100%;
  height: 37px;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
  color: #343434;
  font-size: 1.6rem;
  transform: scale(13 / 16);
`

const StyledButton = styled.button`
  margin: auto;
  padding: 0;
  width: 19px;
  height: 19px;
  background: url(/images/exdio/renewal/icon_close.svg) no-repeat center / 100%;
  border-width: 0;
  display: block;
  position: absolute;
  top: 50%;
  right: 12px;
  bottom: 50%;
  cursor: pointer;
`
