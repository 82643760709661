import React, { Component } from 'react';
import window from 'global';
import PropTypes from 'prop-types';
import Cookie from 'js-cookie';

export default class PreviewComponent extends Component {
  static contextTypes = {
    models: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.config = context.models.config.data;

    this.state = {
      rubocopTimeZoneName: null
    };

    this.onClickClear = this.onClickClear.bind(this);
  }

  componentDidMount() {
    this.setRubocopTimeZoneName();
  }

  setRubocopTimeZoneName() {
    if (this.config.preview_url_list.indexOf(window.location.hostname) === -1) return;
    this.setState({
      rubocopTimeZoneName: window.location.hostname + this.config.cookie_rubocop_prefix
    });
  }

  onClickClear(e) {
    e.preventDefault();
    e.stopPropagation();
    const { rubocopTimeZoneName } = this.state;
    if (!rubocopTimeZoneName) return;

    Cookie.remove(rubocopTimeZoneName, { path: '/', domain: this.config.domain });
    alert('プレビュー時刻設定を解除しました。');
    window.location.reload();
  }

  render() {
    const { rubocopTimeZoneName } = this.state;
    // cookieを扱うのでブラウザ上でしか動かせない。
    if (!process.env.BROWSER || !rubocopTimeZoneName) return null;

    const previewTime = Cookie.get(rubocopTimeZoneName);
    if (!previewTime) return null;

    return (
      <div className="c-preview">
        <div className="c-preview-inBox">
          プレビュー中<br />
          {previewTime}
          <a onClick={this.onClickClear}>解除</a>
        </div>
      </div>
    );
  }
}
