import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Cookie from 'js-cookie'
import ProgramItemLink from '../../../../common/components/renewal/ProgramItemLink'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  META_SCHEMA_ID,
  PRODUCT_SCHEMA,
  SEARCH_TYPE
} from '../../../../../constants/app'
import styled, { css } from 'styled-components'
import { mediaSp, bgColor, colorSub } from './styles'

/** 詳細画面:サイドナビ:あなたへのオススメコンポーネント */
export default class SideRecommend extends Component {
  static propTypes = {
    /** リコメンド種別(one_to_one/recommend) */
    recommendType: PropTypes.string,
    /** 表示箇所名 */
    spotName: PropTypes.string.isRequired,
    /** レコメンド種別 */
    typeName: PropTypes.string.isRequired,
    /** 見出し */
    title: PropTypes.string,
    /** タブ */
    tabs: PropTypes.arrayOf(PropTypes.string),
    /** スタイル */
    style: PropTypes.objectOf(PropTypes.any),
    /** 月額見放題コースの参照ID */
    courseRefId: PropTypes.string,
    /** エピソード一覧 */
    episodes: PropTypes.arrayOf(PropTypes.object)
  }

  static defaultProps = {
    recommendType: 'one_to_one',
    title: 'あなたへのオススメ',
    tabs: ['無料', '有料'],
    style: { paddingTop: `${4}rem` },
    courseRefId: '',
    spotName: '',
    episodes: []
  }

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data
    this.state = {
      recommendItems: null,
      currentIdx: 0
    }

    this.onClickTab = this.onClickTab.bind(this)
    this.onClickItem = this.onClickItem.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.fetchData(this.props.episodes)
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.episodes || !this.props.episodes.length) &&
      nextProps.episodes &&
      !!nextProps.episodes.length
    ) {
      this.fetchData(nextProps.episodes)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  fetchData(episodes) {
    const { recommendType } = this.props
    if (recommendType === 'one_to_one') {
      this.oneToOne()
        .then(() => this.recommendActionLog())
        .catch((e) => webApp.utils.handleFalcorError(e, this.context))
    } else if (recommendType === 'recommend') {
      this.recommend(episodes)
        .then(() => this.recommendActionLog())
        .catch((e) => webApp.utils.handleFalcorError(e, this.context))
    } else {
      console.error(`Unknown recommendType is specified: ${recommendType}`)
    }
  }

  /** タブクリック時 */
  onClickTab(idx, e) {
    e.preventDefault()
    this.setState({ currentIdx: idx }, () => {
      this.oneToOne()
        .then(() => this.recommendActionLog())
        .catch((err) => webApp.utils.handleFalcorError(err, this.context))
    })
  }

  /** 一覧要素クリック時 */
  onClickItem(meta, autoPlay = true) {
    this.recommendClick(meta.meta_id)
    webApp.utils.goToProgramLink(this.context, meta, null, null, { autoPlay })
  }

  /** One to Oneレコメンド */
  oneToOne() {
    const { spotName, courseRefId, episodes } = this.props
    const { currentIdx } = this.state
    const itemIdsCsv = (episodes || [])
      .slice(0, 10)
      .map((meta) => meta.meta_id)
      .join(',')
    const userId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // onetooneはuser_id必須なのでluid取れなければ非表示
    if (!userId) {
      webApp.utils.debug(
        '[SideRecommend] skip call one_to_one API because luid and _ga are blank.'
      )
      return Promise.resolve()
    }
    const responseNumber = 4
    let type = SEARCH_TYPE.ALL.value
    // 月額見放題は有料/無料タブなし
    if (!courseRefId) {
      if (currentIdx === 0) {
        if (webApp.utils.isLoggedIn(this.context)) {
          type = SEARCH_TYPE.AUTH_FREE.value
        } else {
          type = SEARCH_TYPE.FREE.value
        }
      } else if (currentIdx === 1) {
        type = SEARCH_TYPE.NOT_FREE.value
      }
    }
    const genreName = ''
    const metaSchemaId = ''
    const tag = courseRefId || ''
    const path = [
      'oneToOne',
      itemIdsCsv,
      userId,
      spotName,
      type,
      genreName,
      metaSchemaId,
      tag,
      responseNumber
    ]

    return this.model.fetch([path]).then((result) => {
      const recommendItems = _.get(result, ['json', ...path]) || []
      if (this._isMounted) {
        this.setState({ recommendItems })
      }
    })
  }

  /** レコメンド */
  recommend(episodes) {
    const { spotName, courseRefId } = this.props
    const { currentIdx } = this.state
    const itemIdsCsv = (episodes || [])
      .slice(0, 10)
      .map((meta) => meta.meta_id)
      .join(',')
    if (!itemIdsCsv) return Promise.resolve()
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    let type = SEARCH_TYPE.ALL.value
    // 月額見放題は有料/無料タブなし
    if (!courseRefId) {
      if (currentIdx === 0) {
        if (webApp.utils.isLoggedIn(this.context)) {
          type = SEARCH_TYPE.AUTH_FREE.value
        } else {
          type = SEARCH_TYPE.FREE.value
        }
      } else if (currentIdx === 1) {
        type = SEARCH_TYPE.NOT_FREE.value
      }
    }
    const genreName = ''
    const metaSchemaId = ''
    const tag = courseRefId || ''
    const responseNumber = 4
    const path = [
      'recommend',
      itemIdsCsv,
      sessionId,
      spotName,
      type,
      genreName,
      metaSchemaId,
      tag,
      responseNumber
    ]
    return this.model.fetch([path]).then((result) => {
      const recommendItems = _.get(result, ['json', ...path]) || []
      if (this._isMounted) {
        this.setState({ recommendItems })
      }
    })
  }

  /** レコメンド(ログ蓄積) */
  recommendActionLog() {
    const { typeName, spotName } = this.props
    const { recommendItems } = this.state
    if (!recommendItems || !recommendItems.length) return Promise.resolve()
    const itemIds = recommendItems.map((r) => r.meta_id).join(',')
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) {
      webApp.utils.debug(
        '[SideRecommend] skip call action_log/kpi/display API because luid and _ga are blank.'
      )
      return Promise.resolve()
    }
    const path = [
      'action_log',
      'kpi',
      'display',
      itemIds,
      sessionId,
      typeName,
      spotName
    ]
    return this.model.fetch([path])
  }

  /** レコメンド(クリック) */
  recommendClick(itemId) {
    const { typeName, spotName } = this.props
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) {
      webApp.utils.debug(
        '[SideRecommend] skip call action_log/kpi/click API because luid and _ga are blank.'
      )
      return Promise.resolve()
    }
    const path = [
      'action_log',
      'kpi',
      'click',
      itemId,
      sessionId,
      typeName,
      spotName
    ]
    return this.model.fetch([path])
  }

  render() {
    const { title, tabs, style, courseRefId } = this.props
    const { currentIdx, recommendItems } = this.state
    const noTab = !!courseRefId
    const isApp = webApp.utils.isApp(this.context)
    const _tabs = isApp ? ['無料'] : tabs

    return (
      <section className="common-switching-box" style={style}>
        <StyledH3>{title}</StyledH3>
        {!noTab && (
          <StyledUl>
            {_tabs.map((tab, idx) => (
              <StyledLi key={tab} isApp={isApp}>
                <StyledA
                  href="#"
                  isCurrent={idx === currentIdx}
                  onClick={(e) => this.onClickTab(idx, e)}
                >
                  {tab}
                </StyledA>
              </StyledLi>
            ))}
          </StyledUl>
        )}
        <div className="switching-content">
          <div className="c-card-vertical sub">
            <div className="c-card-vertical-cont">
              {(recommendItems || []).map((item) => {
                const ids = item.ids ? JSON.parse(item.ids) : {}
                const deliveryDateTime = item.delivery_datetime
                  ? JSON.parse(item.delivery_datetime)
                  : {}
                const delivery_start_at = (
                  deliveryDateTime.start_at || ''
                ).replace(/-/g, '/')
                const delivery_end_at = (deliveryDateTime.end_at || '').replace(
                  /-/g,
                  '/'
                )
                const meta = {
                  meta_id: item.meta_id,
                  meta_schema_id: item.meta_schema_id,
                  thumbnail_url: item.thumbnail_url,
                  delivery_start_at,
                  delivery_end_at,
                  name: item.program_name,
                  values: {
                    avails_EpisodeTitleDisplayUnlimited: item.episode_name,
                    evis_EpisodeLongSynopsis: item.synopsis,
                    parents_series: {
                      id: ids.series,
                      avails_SeriesTitleDisplayUnlimited: item.program_name
                    },
                    parents_season: {
                      id: ids.season
                    },
                    duration: item.duration
                  }
                }
                const price = item.price ? JSON.parse(item.price) : {}
                const priceTvod = price.tvod
                const refId = item.course_ref_ids
                  ? item.course_ref_ids.split(':')[0]
                  : ''
                const priceSvod = _.get(price, ['svod', refId]) || null
                const howToPlay = {
                  products: [
                    {
                      schema_id: PRODUCT_SCHEMA.SINGLE_STORY.id,
                      active_pricing: { price: priceTvod }
                    }
                  ],
                  courses: [{ active_pricing: { price: priceSvod } }]
                }
                return (
                  <ProgramItemLink
                    key={meta.meta_id}
                    meta={meta}
                    howToPlay={howToPlay}
                    recommendItem={item}
                    showRemaining={
                      META_SCHEMA_ID.EPISODE === meta.meta_schema_id
                    }
                    showCaption
                    showCoin
                    showNew={webApp.utils.showNew(meta)}
                    route={_.get(
                      webApp.utils.getProgramLinkRoutes(this.context, meta),
                      ['route']
                    )}
                    params={_.get(
                      webApp.utils.getProgramLinkRoutes(this.context, meta),
                      ['params']
                    )}
                    query={_.get(
                      webApp.utils.getProgramLinkRoutes(
                        this.context,
                        meta,
                        null,
                        null,
                        { autoPlay: true }
                      ),
                      ['query']
                    )}
                    isSideRecommend
                    titlesMinHeight
                  />
                )
              })}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 600;

  @media ${mediaSp} {
    margin: 0 -15px;
    padding: 18px 15px;
    background-color: ${bgColor};
    font-size: 1.6rem;
    font-weight: 700;
  }
`

const StyledUl = styled.ul`
  display: flex;
  // シリーズ・キーワードの一方が非表示でも崩れないように
  justify-content: unset;
`

const StyledLi = styled.li`
  width: ${({ isApp }) => (isApp ? '100%' : '50%')};
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
  @media ${mediaSp} {
    font-weight: 700;
  }
`

const StyledA = styled.a`
  padding-bottom: 1.4rem;
  padding-top: 2rem;
  display: block;
  ${({ isCurrent }) => {
    if (isCurrent) {
      return css`
        border-bottom: 5px solid ${colorSub};
        @media ${mediaSp} {
          border-bottom: 3px solid ${colorSub};
        }
      `
    }
    return null
  }}
  &:hover {
    color: #939394;
  }
`
