import styled, { css } from 'styled-components'
import Modal from './Modal'
import SelectionDetail from '../SelectionDetail'
import { StyledLink } from '../Button1'
import Button3 from '../Button3'
import { contentWidth } from '../styles'
import { mediaQuery } from '../../../style'

export const StyledWrapper = styled.div`
  padding: 25px 25px 0;

  ${mediaQuery()} {
    padding: 10px 10px 0;
  }
`

export const StyledSelectionDetail = styled(SelectionDetail)`
  margin-top: 30px;

  ${mediaQuery()} {
    margin-top: 10px;
  }
`

export const StyledModal = styled(Modal)`
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledDiv1 = styled.div`
  padding: 30px 15px;
  max-width: ${contentWidth};
  width: 100%;
  height: 90%;
  position: relative;

  ${mediaQuery()} {
    padding: 20px 10px;
    height: 100%;
  }
`

export const StyledDiv2 = styled.div`
  padding-top: 30px;
  height: calc(100% - 45px);

  ${mediaQuery()} {
    padding-top: 20px;
  }
`

export const StyledButton1 = styled(StyledLink)`
  margin: 0 auto;
  border: 0;
  border-radius: 13px;
  box-shadow: 0 5px 0 0 #ffbc09;
  display: flex;
  color: #672806;
  font-size: 1.8rem;

  &:active {
    color: #672806;
  }

  &:hover {
    background-color: #ffbc09;
    box-shadow: 0 0 0 0 #ffbc09;
  }

  ${mediaQuery()} {
    padding: 0 15px;
    width: calc(100% - 50px);
    box-shadow: none;
    border-radius: 5px;
    justify-content: flex-start;
    position: relative;
    font-size: 1.4rem;
  }

  &::before,
  &::after {
    ${mediaQuery()} {
      margin: auto 0;
      position: absolute;
      top: 50%;
      right: 15px;
      content: '';
    }
  }

  &::before {
    ${mediaQuery()} {
      width: 13px;
      height: 8px;
      background: url(/douga_mv/shinchan/svod/images/selection/arrow1.svg)
        no-repeat center / 13px 8px;
      transform: translateY(-9px) rotate(180deg);
    }
  }

  &::after {
    ${mediaQuery()} {
      width: 13px;
      height: 8px;
      background: url(/douga_mv/shinchan/svod/images/selection/arrow1.svg)
        no-repeat center / 13px 8px;
      transform: translateY(1px);
      content: '';
    }
  }
`

export const StyledButton3 = styled(Button3)`
  position: fixed;
  top: 30px;
  right: 30px;

  ${mediaQuery()} {
    top: 20px;
    right: 20px;
  }
`
