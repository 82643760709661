/* eslint-disable import/prefer-default-export */

/** DIOPlayer LocalStorageKey */
export const LOCAL_STORAGE_KEY = 'DIOresumeInfo' // 旧キャッチアップがDIOresumeInfoだったので2をつけた
export const LOCAL_STORAGE_KEY_PURCHASE = 'Purchase'
export const LOCAL_STORAGE_KEY_CHECKED_ARTICLES = 'CheckedArticles'
export const LOCAL_STORAGE_KEY_MODAL_ARTICLES = 'ModalArticles'
export const LOCAL_STORAGE_KEY_NOTICE_ARTICLES = 'NoticeArticles'
export const LOCAL_STORAGE_KEY_SHINCHAN_PAGER = 'ShinchanPager'
export const LOCAL_STORAGE_KEY_SHINCHAN_SORT = 'ShinchanSort'
export const LOCAL_STORAGE_KEY_SHINCHAN_FILTER = 'ShinchanFilter'
export const LOCAL_STORAGE_KEY_DORAEMON_PAGER = 'DoraemonPager'
export const LOCAL_STORAGE_KEY_DORAEMON_SORT = 'DoraemonSort'
export const LOCAL_STORAGE_KEY_DORAEMON_FILTER = 'DoraemonFilter'
export const LOCAL_STORAGE_KEY_DORAEMON_EXFILTER = 'DoraemonExFilter'
export const LOCAL_STORAGE_KEY_DORAEMON_YEAR = 'DoraemonSelectedYear'

/** DIOPlayerデバイス */
export const DIO_PLAYER_DEVICE = {
  PC: '1',
  IOS: '2',
  ANDROID: '3',
  APPLI: '4'
}

/** キーコード */
export const KEY_CODE = {
  ENTER: 13
}

/** 基盤ログイン種別 */
export const INFRA_LOGIN_TYPE = {
  TV_ASAHI: { id: '0', label: 'tv asahi iD', color: '' },
  RAKUTEN: { id: '1', label: '楽天ID', color: '#BF0000' },
  YAHOO: { id: '2', label: 'Yahoo! JAPAN ID', color: '#FF0033' },
  FACEBOOK: { id: '3', label: 'Facebook', color: '#1878F2' },
  GOOGLE: { id: '4', label: 'Google', color: '#757675' }
}

/** メタスキーマID */
export const META_SCHEMA_ID = {
  EPISODE: 1,
  SERIES: 2,
  SEASON: 3,
  LIVE: 4,
  LIVE_SERIES: 5,
  LIVE_SEASON: 6,
  EPISODE_NOT_FREE: 7,
  LIVE_NOT_FREE: 8
}
export const META_SCHEMA_IDS = [
  META_SCHEMA_ID.EPISODE,
  META_SCHEMA_ID.SERIES,
  META_SCHEMA_ID.SEASON,
  META_SCHEMA_ID.LIVE,
  META_SCHEMA_ID.LIVE_SERIES,
  META_SCHEMA_ID.LIVE_SEASON,
  META_SCHEMA_ID.EPISODE_NOT_FREE,
  META_SCHEMA_ID.LIVE_NOT_FREE
]
export const SEASON_META_SCHEMA_IDS = [
  META_SCHEMA_ID.SEASON,
  META_SCHEMA_ID.LIVE_SEASON
]

// SVOD配信パターン
export const SVOD_DELIVERY_PATTERN = {
  DEFAULT: 0,
  FREE: 1,
  AUTH_FREE: 2
}

/** パレットスキーマID */
export const PALETTE_SCHEMA_ID = {
  RECOMMEND: 1,
  CATCHUP: 2,
  GENRE_RECOMMEND: 3,
  GENRE_DSEARCH: 4,
  GENRE_FIXED: 5,
  IMAGES: 6,
  KEYWORDS: 7,
  INDIVIDUAL_RECOMMEND: 8,
  GENRE_TOP_PROGRAM_LIST: 9
}

/** ジャンル検索種別 */
export const GENRE_SEARCH_TYPE = {
  ID: { value: 'id', label: 'ID指定' },
  NEW_ARRIVAL: { value: 'newarrival', label: '新着' },
  FREE: { value: 'free', label: '無料' },
  RECOMMEND: { value: 'recommend', label: 'オススメ' }
}

/** 商品スキーマ */
export const PRODUCT_SCHEMA = {
  SINGLE_STORY: { id: 1, label: '単話' },
  PACK: { id: 2, label: '全話パック' }
}
export const PRODUCT_SCHEMAS = [
  PRODUCT_SCHEMA.SINGLE_STORY,
  PRODUCT_SCHEMA.PACK
]

/** 記事スキーマID */
export const ARTICLE_SCHEMAS = {
  CAMPAIGN: { id: 1, label: '特別キャンペーン', color: '#e88299' },
  IMPORTANT: { id: 2, label: '重要', color: '#de632a' },
  NOTIFICATION: { id: 3, label: '大切なお知らせ', color: '#dd327e' },
  MAINTENANCE: { id: 4, label: 'メンテナンス・障害', color: '#459ec2' },
  OTHER: { id: 5, label: 'その他', color: '#b7b7b7' },
  POPUP_TOP: { id: 6, label: 'TOPページポップアップ' }
  // ph1対応外
  // SOON_END_DELIVERY: { id: 6, label: 'まもなく配信終了', color: '' },
  // SOON_END_PUBLISH: { id: 7, label: 'まもなく視聴終了', color: '' },
  // START_DELIVERY: { id: 8, label: '配信開始', color: '' },
  // RECOMMEND: { id: 9, label: 'あなたへのおすすめ', color: '' }
}

/** 広告スキーマID */
export const ADVERTISING_SCHEMA_ID = {
  DEFAULT: 1,
  PRODUCT: 2,
  COURSE: 3,
  PRODUCT_LIVE: 4,
  COURSE_LIVE: 5
}

/** 公開ステータス */
export const PUBLISH_STATUS = {
  PUBLISHED: 4
}

/** 単話画面の表示モード */
export const EPISODE_DISPLAY_MODE = {
  // 無料
  FREE: 1,
  TVOD_FREE: 2,
  TVOD_NOT_FREE: 3,
  SVOD_FREE: 4,
  SVOD_NOT_FREE: 5,
  SVOD_AUTH_FREE: 6,
  STVOD_FREE: 7,
  STVOD_TVOD_NOT_FREE: 8,
  STVOD_SVOD_NOT_FREE: 9,
  UNKNOWN: 10
}
export const EPISODE_DISPLAY_MODES_FREE = [
  EPISODE_DISPLAY_MODE.FREE,
  EPISODE_DISPLAY_MODE.TVOD_FREE,
  EPISODE_DISPLAY_MODE.SVOD_FREE,
  EPISODE_DISPLAY_MODE.STVOD_FREE
]

/** ポイント履歴加算種別 */
export const POINT_HISTORY_ADD_TYPE = {
  // ライセンス購入による加算
  PURCHASE: '01',
  // 02：ライセンス継続による加算
  CONTINUE: '02',
  // 03：ポイント消費（ライセンス）
  USE_LICENSE: '03',
  // 04：ポイント消費（素材）
  USE_MATERIAL: '04',
  // 05：期限切れによる失効
  EXPIRED: '05',
  // 06：ライセンス解約による失効
  CANCEL: '06',
  // テレビ朝日ポイント消費による加算
  TV_ASAHI_POINT: '08',
  // 99：管理者操作（仮）
  ADMIN: '99'
}

export const POINT_HISTORY_PAYMETHOD = {
  0: '無料ライセンス',
  1: 'クレジットカード',
  2: 'コンビニ決済',
  3: 'キャリア課金',
  4: '課金ポイント消費',
  5: '携帯支払い(DoCoMo)',
  6: '携帯支払い(au)',
  7: '携帯支払い(au月額)',
  8: 'spモード',
  9: 'auかんたん決済',
  10: 'ソフトバンクまとめて支払い',
  11: 'WebMoney',
  12: '楽天ペイ',
  13: 'Yahooウォレット',
  14: 'ソフトバンク・ペイメント・サービス ソフトバンクまとめて支払い（B）'
}

/** 決済毎のライセンスID */
export const CHARGE_LICENSE_IDS = [
  {
    // クレジットカード
    price: [
      {
        add_point: 60,
        ids: ['pc_m60_cp', 'ip_m60_cp', 'ad_m60_cp'],
        name: '毎月60メダル自動チャージ'
      },
      {
        add_point: 120,
        ids: ['pc_m110_cp', 'ip_m110_cp', 'ad_m110_cp'],
        name: '毎月120メダル自動チャージ'
      },
      {
        add_point: 180,
        ids: ['pc_m160_cp', 'ip_m160_cp', 'ad_m160_cp'],
        name: '毎月180メダル自動チャージ'
      },
      {
        add_point: 250,
        ids: ['pc_m210_cp', 'ip_m210_cp', 'ad_m210_cp'],
        name: '毎月250メダル自動チャージ'
      }
    ]
  },
  // spモード、auかんたん決済
  {
    price: [
      {
        add_point: 60,
        ids: ['pc_au50_cp', 'ip_au50_cp', 'as_m50_cp'],
        name: '60メダル自動チャージ（毎月1日課金）'
      },
      {
        add_point: 120,
        ids: ['pc_au100_cp', 'ip_au100_cp', 'as_m100_cp'],
        name: '120メダル自動チャージ（毎月1日課金）'
      },
      {
        add_point: 180,
        ids: ['pc_au150_cp', 'ip_au150_cp', 'as_m150_cp'],
        name: '180メダル自動チャージ（毎月1日課金）'
      },
      {
        add_point: 250,
        ids: ['pc_au200_cp', 'ip_au200_cp', 'as_m200_cp'],
        name: '250メダル自動チャージ（毎月1日課金）'
      }
    ]
  },
  // ソフトバンク
  {
    price: [
      {
        add_point: 60,
        ids: ['pc_m50_sb', 'ip_m50_sb', 'ad_m50_sb'],
        name: '毎月60メダル自動チャージ'
      },
      {
        add_point: 120,
        ids: ['pc_m100_sb', 'ip_m100_sb', 'ad_m100_sb'],
        name: '毎月120メダル自動チャージ'
      },
      {
        add_point: 180,
        ids: ['pc_m150_sb', 'ip_m150_sb', 'ad_m150_sb'],
        name: '毎月180メダル自動チャージ'
      },
      {
        add_point: 250,
        ids: ['pc_m200_sb', 'ip_m200_sb', 'ad_m200_sb'],
        name: '毎月250メダル自動チャージ'
      }
    ]
  },
  // Yahoo!ウォレット
  {
    price: [
      {
        add_point: 60,
        ids: ['pc_m60_ya', 'ip_m60_ya', 'ad_m60_ya'],
        name: '毎月60メダル自動チャージ'
      },
      {
        add_point: 120,
        ids: ['pc_m110_ya', 'ip_m110_ya', 'ad_m110_ya'],
        name: '毎月120メダル自動チャージ'
      },
      {
        add_point: 180,
        ids: ['pc_m160_ya', 'ip_m160_ya', 'ad_m160_ya'],
        name: '毎月180メダル自動チャージ'
      },
      {
        add_point: 250,
        ids: ['pc_m210_ya', 'ip_m210_ya', 'ad_m210_ya'],
        name: '毎月250メダル自動チャージ'
      }
    ]
  },
  // 楽天ペイ
  {
    price: [
      {
        add_point: 60,
        ids: ['pc_m60_ra', 'ip_m60_ra', 'ad_m60_ra'],
        name: '毎月60メダル自動チャージ'
      },
      {
        add_point: 120,
        ids: ['pc_m110_ra', 'ip_m110_ra', 'ad_m110_ra'],
        name: '毎月120メダル自動チャージ'
      },
      {
        add_point: 180,
        ids: ['pc_m160_ra', 'ip_m160_ra', 'ad_m160_ra'],
        name: '毎月180メダル自動チャージ'
      },
      {
        add_point: 250,
        ids: ['pc_m210_ra', 'ip_m210_ra', 'ad_m210_ra'],
        name: '毎月250メダル自動チャージ'
      }
    ]
  }
]

/** ソート種別 */
export const SORT_TYPE = {
  SCORE: { label: '関連度順', value: 'score' },
  NEWER: { label: '新着順', value: 'newer' },
  OLDER: { label: '古い順', value: 'older' },
  ENDING: { label: 'もうすぐ終了順', value: 'ending' },
  ASC: { label: '50音順（あ→わ）', value: 'asc' },
  DESC: { label: '50音順（わ→あ）', value: 'desc' }
}
export const SORT_TYPES = [
  SORT_TYPE.NEWER,
  SORT_TYPE.OLDER,
  SORT_TYPE.ENDING,
  SORT_TYPE.ASC,
  SORT_TYPE.DESC
]

/** 動画検索条件 */
export const SEARCH_TYPE = {
  ALL: { label: 'すべて', value: 'all' },
  FREE: { label: '無料', value: 'free' },
  NOT_FREE: { label: '有料', value: 'not_free' },
  AUTH_FREE: { label: '無料', value: 'auth_free' },
  ALL_WO_LIVE: { label: 'すべて', value: 'all_wo_live' },
  FREE_WO_LIVE: { label: '無料', value: 'free_wo_live' },
  NOT_FREE_WO_LIVE: { label: '有料', value: 'not_free_wo_live' },
  AUTH_FREE_WO_LIVE: { label: '無料', value: 'auth_free_wo_live' }
}
export const SEARCH_TYPES = [
  SEARCH_TYPE.ALL,
  SEARCH_TYPE.FREE,
  SEARCH_TYPE.NOT_FREE
]

/** 動画検索条件 */
export const SEARCH_TYPE_APP = {
  ALL: { label: 'すべて', value: 'all' },
  FREE: { label: '見逃し', value: 'free' }
}
export const SEARCH_TYPES_APP = [SEARCH_TYPE_APP.ALL, SEARCH_TYPE_APP.FREE]

/** 単話/番組/パック/月額見放題 種別 */
export const CARD_TYPE = {
  ALL: { label: 'すべて', value: 'all' },
  EPISODE: { label: '単話', value: 'episode' },
  PROGRAM: { label: '番組', value: 'program' },
  PACK: { label: 'パック', value: 'pack' },
  PLAN: { label: '月額見放題', value: 'plan' }
}
export const CARD_TYPES = [
  CARD_TYPE.ALL,
  CARD_TYPE.EPISODE,
  CARD_TYPE.PROGRAM,
  CARD_TYPE.PACK,
  CARD_TYPE.PLAN
]

/** お知らせ種別 */
export const INFORMATION_TYPE = {
  ALL: { label: 'すべて', value: 'all' },
  MAINTENANCE: {
    label: ARTICLE_SCHEMAS.MAINTENANCE.label,
    value: ARTICLE_SCHEMAS.MAINTENANCE.id
  },
  CAMPAIGN: {
    label: ARTICLE_SCHEMAS.CAMPAIGN.label,
    value: ARTICLE_SCHEMAS.CAMPAIGN.id
  },
  IMPORTANT: {
    label: ARTICLE_SCHEMAS.IMPORTANT.label,
    value: ARTICLE_SCHEMAS.IMPORTANT.id
  },
  NOTIFICATION: {
    label: ARTICLE_SCHEMAS.NOTIFICATION.label,
    value: ARTICLE_SCHEMAS.NOTIFICATION.id
  },
  OTHER: { label: ARTICLE_SCHEMAS.OTHER.label, value: ARTICLE_SCHEMAS.OTHER.id }
}
export const INFORMATION_TYPES = [
  INFORMATION_TYPE.ALL,
  INFORMATION_TYPE.NOTIFICATION,
  INFORMATION_TYPE.CAMPAIGN,
  INFORMATION_TYPE.MAINTENANCE,
  INFORMATION_TYPE.IMPORTANT,
  INFORMATION_TYPE.OTHER
]

/** ログインフォームコンテンツ種別 */
export const LOGIN_CONTENTS = {
  LOGIN: {
    id: 0,
    label: 'ログイン'
  },
  SIGNUP: {
    id: 1,
    label: '新規アカウント登録'
  }
}

/** マイページコンテンツ種別 */
export const MYPAGE_CONTENTS = {
  TAB: {
    ACCOUNT: {
      id: 2,
      label: 'アカウント情報'
    },
    PURCHASED: {
      id: 0,
      label: '購入済'
    },
    MYLIST: {
      id: 1,
      label: 'マイリスト'
    }
  },
  APPTAB: {
    APPTOP: {
      id: 90,
      label: 'TOP'
    },
    PURCHASED: {
      id: 91,
      label: 'お持ちのコンテンツ'
    },
    MYLIST: {
      id: 92,
      label: 'マイリスト'
    }
  },
  LINK: {
    ACCOUNT: {
      id: 2,
      label: 'アカウント情報'
    },
    COIN: {
      id: 3,
      label: '通帳'
    }
  },
  PURCHASED: {
    EPISODE: { id: 10 },
    PACK: { id: 11 },
    PLAN: { id: 12 }
  },
  ACCOUNT: {
    SETTING: { id: 20 },
    EDIT_PROFILE: { id: 21 },
    EDIT_INFO: { id: 22 },
    EDIT_PASS: { id: 23 },
    EDIT_EMAIL: { id: 24 },
    DELETE: { id: 25 },
    PLAN: { id: 26 },
    PAYMENT: { id: 27 },
    CANCEL_PAYMENT: { id: 28 }
  },
  MEMBER_SETTING: {
    UPDATE_MEMBER: { id: 31 },
    UPDATE_PASSWORD: { id: 32 },
    UPDATE_EMAIL: { id: 33 },
    UPDATE_EMAIL_RESULT: { id: 34 },
    DELETE_TVASAHI_ID: { id: 35 },
    UPDATE_CREDIT: { id: 36 },
    ICON_EDIT: { id: 37 } // 置き場所が微妙だが仕方ないのでMEMBER_SETTINGの下に置く
  }
}

/** ニュースページコンテンツ種別 */
export const NEWS_CONTENTS = {
  // MY_NEWS: {
  //   id: 0,
  //   label: 'マイ通知',
  //   route: 'mynews'
  // },
  INFORMATION: {
    id: 1,
    label: 'お知らせ',
    route: 'information'
  },
  NEW_ARRIVAL: {
    id: 2,
    label: '新着動画',
    route: 'newarrival'
  }
}

/** お問い合わせページコンテンツ種別 */
export const CONTACT_CONTENTS = {
  FORM: { id: 0 },
  CONFIRM: { id: 1 },
  COMPLETED: { id: 2 }
}

/** 購入ページコンテンツ種別 */
export const PURCHASE_CONTENTS = {
  SELECT: {
    id: 0,
    label: '商品の確認',
    title: '商品の確認'
  },
  LOGIN: {
    id: 1,
    label: 'ログイン',
    title: ''
  },
  PAYMENT: {
    id: 2,
    label: 'お支払い<br class="sp-visible" />方法',
    title: 'お支払い方法'
  },
  CONFIRM: {
    id: 3,
    label: '購入確認',
    title: '内容確認'
  },
  COMPLETED: {
    id: 4,
    title: '購入完了'
  },
  CHARGE: {
    id: 5,
    title: 'コインチャージ'
  },
  CHARGE_COMPLETED: {
    id: 6,
    title: 'コインチャージ完了'
  },
  LOADING: {
    id: 99
  }
}

/** 購入ページ購入対象 */
export const PURCHASE_TYPE = {
  EPISODE: 'episode',
  PACK: 'pack',
  PLAN: 'plan'
}

/** 支払い方法 */
export const PAYMENT_METHOD = {
  COIN: {
    id: 'coin',
    name: 'メダル支払い'
  },
  CREDIT_CARD: {
    id: 'credit_card',
    name: 'クレジットカード払い'
  },
  DOCOMO: {
    id: 'docomo',
    name: 'キャリア決済(docomo)'
  },
  AU: {
    id: 'au',
    name: 'キャリア決済(au)'
  },
  SOFTBANK: {
    id: 'softbank',
    name: 'キャリア決済(softbank)'
  },
  WEB_MANEY: {
    id: 'web_money',
    name: 'WEB MANEY'
  },
  YAHOO: {
    id: 'yahoo',
    name: 'YAHOO ウォレット'
  },
  RAKUTEN: {
    id: 'rakuten',
    name: '楽天ペイ'
  }
}

/** お気に入り種別 */
export const FAVORITE_TYPE = {
  META: 'meta',
  PRODUCT: 'product',
  COURSE: 'course'
}
