import styled from 'styled-components'

export const StyledUl = styled.ul`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const StyledLi = styled.li``
