import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledLabel } from './style'

/** index Component */
const Radio = ({
  label = '',
  checked = false,
  radioProps = {
    name: '',
    value: '',
    id: '',
    onChange: () => {},
    onClick: () => {}
  },
  ...props
}) => {
  return (
    <StyledLabel checked={checked} {...props}>
      <input type="radio" checked={checked} {...radioProps} />
      {label}
    </StyledLabel>
  )
}

export default Radio

Radio.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  radioProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func
  }).isRequired
}
