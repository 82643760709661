import React, { useState } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import { StyledButton, StyledImg } from './style'

/** ガリベン大学 スクロールトップボタン */
const ScrollTop = ({ ...props }) => {
  /** 画像src */
  const img = '/images/exdio/renewal/gariben_daigaku/components/page_top.webp'
  const imgOver = img.replace('.webp', '_over.webp')

  const [src, setSrc] = useState(img)
  const [animationFlag, setAnimationFlag] = useState(false)

  const onMouseOverHandler = () => {
    // アニメーション中は実行しない
    if (animationFlag) return

    setSrc(imgOver)
  }

  const onMouseOutHandler = () => {
    // アニメーション中は実行しない
    if (animationFlag) return

    setSrc(img)
  }

  const onClickHandler = () => {
    setAnimationFlag(true)
    const $target = $('#pageTop')
    const targetHeight = 288

    // ページ上部にスクロールする
    $('html, body').animate(
      {
        scrollTop: 0
      },
      800,
      'swing'
    )

    // 画像の切り替え
    setSrc(imgOver)

    // ロケットアニメーションの実行
    $target.animate(
      {
        bottom: '100%'
      },
      600,
      'swing',
      () => {
        // アニメーション完了後にもとに戻す
        setTimeout(() => {
          $target.hide().animate(
            {
              bottom: `-${targetHeight}px`
            },
            0,
            () => {
              setSrc(img)
              $target.show().removeAttr('style')
              setAnimationFlag(false)
            }
          )
        }, 600)
      }
    )
  }

  return (
    <StyledButton
      type="button"
      id="pageTop"
      className="shake"
      onMouseOver={onMouseOverHandler}
      onMouseOut={onMouseOutHandler}
      onClick={onClickHandler}
      {...props}
    >
      {/* imgタグ側でwidth, height属性を設定するとアイコンホバー時に画像サイズがズレるため設定しない */}
      <StyledImg src={src} alt="PAGE TOP" loading="lazy" />
    </StyledButton>
  )
}

export default ScrollTop

ScrollTop.propTypes = {
  hide: PropTypes.bool
}
