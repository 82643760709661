import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import webApp from '../../../utils/exdioWebAppUtils'
import Footer from '../../../../common/components/Footer'
import DFPBanner from '../../../../common/components/DFPBanner'
import PackList from '../details/PackList'
import AboutMedalLink from '../../../../common/components/renewal/AboutMedalLink'
import SidePalette from '../details/SidePalette'
import { FAVORITE_TYPE, META_SCHEMA_ID } from '../../../../../constants/app'
import EpisodeList from '../details/EpisodeList'
import CastInfo from '../details/CastInfo'
import SideTab from '../details/SideTab'
import Caption from '../details/Caption'
import ProgramTopLink from '../details/ProgramTopLink'
import AddButtonBlock from '../details/AddButtonBlock'
import SideRecommend from '../details/SideRecommend'
import HtmlSnippet from '../../HtmlSnippet'

/** 番組ページ テストトテンプレート */
export default class ProgramTemplateTest extends Component {
  static propTypes = {
    /** 動画のシリーズID */
    seriesId: PropTypes.string,
    /** 動画のシーズンID */
    seasonId: PropTypes.string,
    /** 動画のメタ情報 */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    /** 関連動画情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** パック販売情報 */
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        original_price: PropTypes.number,
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    ),
    /** episodesのhowToPlay情報 */
    howToPlays: PropTypes.object,
    recommendItems: PropTypes.arrayOf(PropTypes.object)
  }

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    episodes: [],
    products: [],
    howToPlays: {},
    recommendItems: []
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.state = {}
  }

  componentDidMount() {}

  /** 料金 */
  price(meta) {
    const metaSchemaId = meta.meta_schema_id
    switch (metaSchemaId) {
      case META_SCHEMA_ID.EPISODE:
        return '無料'
      case META_SCHEMA_ID.EPISODE_NOT_FREE:
        return 100
      default:
        return ''
    }
  }

  /** 放送日 */
  onAirDate(meta, isSp = false) {
    const formatStr = isSp ? 'YYYY/M/D放送' : 'YYYY年M月D日'
    const onAirDate = _.get(meta, 'values.avails_ReleaseHistoryOriginal', null)
    return onAirDate ? moment(onAirDate).format(formatStr) : '-'
  }

  /** 配信期間 */
  deliveryEndAt(meta) {
    const deliveryEndAt = meta.delivery_end_at
    return deliveryEndAt
      ? moment(deliveryEndAt).format('YYYY年M月D日 HH:mmまで')
      : '-'
  }

  render() {
    const {
      seriesId,
      seasonId,
      meta,
      episodes,
      products,
      howToPlays,
      recommendItems
    } = this.props

    const latestEpisode = episodes[0] || {}
    const thumbnailUrl = meta.thumbnail_url || this.config.default_thumbnail
    const latestEpisodeThumbnailUrl =
      latestEpisode.thumbnail_url || this.config.default_thumbnail
    const isFree = META_SCHEMA_ID.EPISODE === latestEpisode.meta_schema_id
    const cast = (meta && meta.values && meta.values.evis_Actors) || null
    const copyRight =
      (meta && meta.values && meta.values.evis_Copyright) || null
    const time = webApp.utils.duration(latestEpisode)
    const remaining = webApp.utils.remaining(
      this.context,
      _.get(latestEpisode, ['delivery_end_at'])
    )
    const progress = webApp.utils.progress(this.config, meta)
    const otherSeasonIds = _.get(meta, ['values', 'other_series']) || []
    const bannerId1 = _.get(meta, ['values', 'banner_1'])
    const bannerId2 = _.get(meta, ['values', 'banner_2'])

    return (
      <div className="common-wrapper">
        <DFPBanner position="head" meta_id={meta.meta_id} key="banner" />

        <div>テンプレートテスト</div>
        <div className="c-headMeta">
          <div className="c-headMeta-metaBox">
            <div className="c-headMeta-metaBox-art">
              <div className="c-headMeta-metaBox-art-inBox">
                <div className="c-headMeta-metaBox-art-inBox-artwork">
                  <img
                    src={thumbnailUrl}
                    className="c-headMeta-metaBox-art-inBox-artwork-img"
                  />
                </div>
              </div>

              {/* for PC */}
              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.META}
                favoriteId={meta.meta_id}
                title={meta.name}
              />
            </div>

            <div className="c-headMeta-metaBox-info">
              <h2 className="c-headMeta-metaBox-info-title">{meta.name}</h2>

              <Caption
                caption={meta.values && meta.values.evis_SeasonLongSynopsis}
                className="c-headMeta-metaBox-info-caption"
              />

              {/* for SP */}
              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.META}
                favoriteId={meta.meta_id}
                title={meta.name}
              />
            </div>
          </div>
        </div>

        <div className="c-listMeta">
          <div className="c-listMeta-inBox">
            <div className="c-listMeta-inBox-main">
              <div className="c-listHeadMeta">
                <div className="c-listHeadMeta-cont">
                  <div className="c-listHeadMeta-cont-art">
                    <div
                      className="c-listHeadMeta-cont-art-inBox clickable"
                      onClick={() =>
                        webApp.utils.goToProgramLink(
                          this.context,
                          latestEpisode
                        )
                      }
                    >
                      <div className="c-card-inBox-art-artwork shadowOn">
                        <img
                          src={latestEpisodeThumbnailUrl}
                          className="c-card-inBox-art-artwork-img"
                        />
                      </div>
                      {remaining && (
                        <div
                          // chromaticで画像の変更を検知しないための設定
                          data-chromatic="ignore"
                          className="c-artInfo-period"
                        >
                          {remaining}
                        </div>
                      )}
                      <div className="c-artInfo-seekarea h3px">
                        {progress > 0 && (
                          <div
                            className="c-artInfo-seekarea-bar"
                            style={{ width: `${progress}%` }}
                          />
                        )}
                      </div>
                      <div className="c-artInfo-time">{time}</div>
                    </div>
                  </div>
                  <div
                    className="c-listHeadMeta-cont-info clickable"
                    onClick={() =>
                      webApp.utils.goToProgramLink(
                        this.context,
                        latestEpisode,
                        null,
                        null,
                        { autoPlay: false }
                      )
                    }
                  >
                    <h2 className="c-listHeadMeta-cont-info-title">
                      {latestEpisode.name}（最新話）
                    </h2>
                    <div className="c-listHeadMeta-cont-info-time">
                      時間：{time || '-'}
                    </div>
                    <div className="c-listHeadMeta-cont-info-price free">
                      料金：
                      <span className="c-listHeadMeta-cont-info-price-coin">
                        {this.price(latestEpisode)}
                      </span>
                      <AboutMedalLink linkClassName="c-listHeadMeta-cont-info-price-aboutCoin" />
                    </div>
                    <div className="c-listHeadMeta-cont-info-onAir">
                      放送日：{this.onAirDate(latestEpisode)}
                    </div>
                    <div className="c-listHeadMeta-cont-info-onAirSp">
                      {this.onAirDate(latestEpisode, true)}
                    </div>
                    <div className="c-listHeadMeta-cont-info-viewingPeriod">
                      視聴期間：{this.deliveryEndAt(latestEpisode)}
                    </div>
                    <div className="c-listHeadMeta-cont-info-caption">
                      {latestEpisode.values &&
                        latestEpisode.values.evis_EpisodeLongSynopsis}
                    </div>
                  </div>
                </div>
              </div>

              <PackList products={products} showMedal />
              {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
              <EpisodeList episodes={episodes} howToPlays={howToPlays} />
              <CastInfo cast={cast} copyRight={copyRight} />
            </div>
            <div className="c-listMeta-inBox-sub">
              <div className="common-box-ad-rectangle">
                <DFPBanner position="foot" meta_id="" />
              </div>
              {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
              <SideTab
                currentSeasonId={seasonId}
                otherSeasonIds={otherSeasonIds}
                currentSeasonIds={[seasonId]}
              />
              <ProgramTopLink
                seriesId={seriesId}
                seasonId={seasonId}
                isFree={isFree}
              />
              <HtmlSnippet
                snippetId={this.config.extras.common_banner_snippet_key}
              />
              <SideRecommend recommendItems={recommendItems} />
            </div>
          </div>
        </div>

        <Footer className="mt0" />
      </div>
    )
  }
}
