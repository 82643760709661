/* style */
import styled from 'styled-components'

export const StyledDiv1 = styled.div`
  position: relative;
  margin: 15px auto 20px;
`

export const StyledDiv2 = styled.div`
  .slick-dots {
    bottom: -155px;

    li {
      width: 12px;
      height: 12px;
      border: 1px solid rgb(31, 144, 100);
      background: rgb(255, 255, 255);

      &.slick-active {
        background: rgb(31, 144, 100);
      }

      button {
        width: 100%;
        height: 100%;

        ::before {
          content: '';
        }
      }
    }
  }
`

export const StyledDiv3 = styled.div`
  text-align: left;
  padding: 16px 10px 18px;
  margin-top: -3px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(
    -90deg,
    rgb(255, 255, 112) 0%,
    rgb(135, 245, 131) 65%,
    rgb(15, 235, 149) 100%
  );
`

export const StyledDiv4 = styled.div`
  margin-top: -13px;
  margin-bottom: 50px;
`

export const StyledDiv6 = styled.div`
  position: relative;
  height: 89px;
  z-index: 99;
`

export const StyledDiv5 = styled.div`
  .slick-current img {
    opacity: 1;
  }

  .slick-current ${StyledDiv6}::before {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 5px solid rgb(31, 144, 100);
    top: 0;
    margin-top: 13px;
  }

  .slick-current ${StyledDiv6}::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: 0;
    margin-left: -8px;
    border-bottom: 13px solid rgb(31, 144, 100);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
`

export const StyledImg = styled.img`
  padding-top: 18px;
  margin-top: 0;
  opacity: 0.5;
`
