import React, { useContext, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get, size } from 'lodash'
import webApp from '../../../../utils/exdioWebAppUtils'
import { getPrice } from '../../EpisodeDefault/util'
import { mediaQuery } from '../../../style'

/* context */
import { EpisodeContext } from '../context/Provider'

/* components */
import Caption from '../../details/Caption'
import DeliverPeriod from '../../details/DeliverPeriod'
import Price from '../../EpisodeDefault/Price'
import DeliveryStartAt from '../../EpisodeDefault/DeliveryStartAt'
import OnAirDate from '../../EpisodeDefault/OnAirDate'

/** エピソードメタ情報 */
const Meta = (
  { isLive = false, metaName = '', hidePrice = false, ...props },
  context
) => {
  const episodeContext = useContext(EpisodeContext)
  const {
    meta = {},
    episodes = [],
    productRight = {},
    product = {},
    course = {},
    status = {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    }
  } = episodeContext
  const isApp = webApp.utils.isApp(context)

  const [isShow, setIsShow] = useState(true)
  const pRef = useRef(null)

  /** 動画の長さ取得 */
  const duration = webApp.utils.duration(meta) || '-'

  /** 価格 */
  const price = getPrice(meta, product, status, course)

  /** あらすじ */
  const synopsis = get(meta, ['values', 'evis_EpisodeLongSynopsis'], '')

  /** 最新話判別 */
  const isLatest = (() => {
    const latest = get(episodes, [0]) || {}
    if (!(size(meta) && size(latest))) return false

    return get(meta, ['meta_id']) === get(latest, ['meta_id'])
  })()

  useEffect(() => {
    /**
     * あらすじの高さを取得
     * ボーダーより大きいときは隠し、「もっと見る」ボタンを表示する
     */
    if (!pRef.current) return
    const getStyle = getComputedStyle(pRef.current)
    const { lineHeight, webkitLineClamp } = getStyle
    const borderHeight = parseInt(lineHeight, 10) * webkitLineClamp
    if (pRef.current.scrollHeight > borderHeight) {
      setIsShow(false)
    }
  }, [])

  return (
    <StyledDiv1 {...props}>
      <StyledH2>
        {metaName}
        {isLatest && <span>（最新話）</span>}
      </StyledH2>

      <StyledDiv3>
        <StyledP ref={pRef} isShow={isShow}>
          {synopsis}
        </StyledP>
        <StyledButton
          type="button"
          isShow={isShow}
          onClick={() => setIsShow(!isShow)}
        >
          <span>{isShow ? '元に戻す' : 'もっと見る'}</span>
        </StyledButton>
      </StyledDiv3>

      <StyledDiv2>
        {!isLive && <p>時間：{duration}</p>}
        {!hidePrice && <Price status={status} price={price} />}
        <OnAirDate meta={meta} isLive={isLive} />
        {!isApp && (
          <DeliveryStartAt meta={meta} status={status} isLive={isLive} />
        )}
        <DeliverPeriod
          meta={meta}
          status={status}
          productRight={productRight}
        />
        <Caption {...get(meta, ['values'], {})} />
      </StyledDiv2>
    </StyledDiv1>
  )
}

export default Meta

Meta.propTypes = {
  /** ライブ配信か否かの判定を行う */
  isLive: PropTypes.bool,
  /** 動画の題名 */
  metaName: PropTypes.string,
  /** trueの時、料金を非表示にする */
  hidePrice: PropTypes.bool
}

Meta.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

const StyledDiv1 = styled.div`
  display: grid;
  grid:
    'StyledH2'
    '...' 20px
    'StyledDiv3'
    '...' 26px
    'StyledDiv2';
  font-size: 1.3rem;
  line-height: 1.6;

  ${mediaQuery()} {
    grid:
      'StyledH2'
      'StyledDiv3'
      'StyledDiv2';
    row-gap: 10px;
  }
`

const StyledH2 = styled.h2`
  grid-area: StyledH2;
  font-size: 3.8rem;
  font-weight: 600;
  line-height: 1.2;
  word-break: break-all;

  ${mediaQuery()} {
    font-size: 2rem;
  }

  span {
    font-size: 2.5rem;

    ${mediaQuery()} {
      font-size: 1.2rem;
    }
  }
`

const StyledP = styled.p.withConfig({
  shouldForwardProp: (prop) => !['isShow'].includes(prop)
})`
  display: ${({ isShow }) => (isShow ? null : '-webkit-box')};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`

const StyledDiv2 = styled.div`
  grid-area: StyledDiv2;

  .c-storyMeta-inBox-meta-viewingPeriod {
    margin-bottom: 0;
  }
`

const StyledDiv3 = styled.div`
  grid-area: StyledDiv3;
  display: grid;
  row-gap: 10px;
`

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isShow'].includes(prop)
})`
  padding: 0;
  background: none;
  border-width: 0;
  display: block;
  justify-self: center;
  color: #343434;
  font-size: 1.3rem;
  font-family: Arial, 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3',
    'Hiragino Kaku Gothic ProN', Meiryo, メイリオ, Osaka, 'MS PGothic', arial,
    helvetica, sans-serif;
  transition: opacity 0.2s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }

  span {
    display: grid;
    grid-template-columns: 1fr 1em;
    align-items: center;
    gap: 5px;

    &::after {
      width: 0;
      height: 0;
      border-width: 0.8em 0.5em 0 0.5em;
      border-style: solid;
      border-color: #343434 transparent transparent;
      display: block;
      transform: ${({ isShow }) => (isShow ? 'rotate(180deg)' : null)};
      content: '';
    }
  }
`
