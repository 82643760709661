import styled from 'styled-components'
import { mediaQuery } from '../../../style'

export const StyledButton = styled.button`
  /* csshakeに上書きされるため、詳細度を上げる */
  && {
    padding: inherit;
    width: 64px;
    height: 288px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    background: none;
    border: none;
    position: fixed;
    right: 10px;
    bottom: -20px;
    z-index: ${({ hide }) => (hide ? -1 : 999)};
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    cursor: pointer;
    transition: bottom 0.4s ease-in-out, opacity 0.4s ease-in-out,
      z-index 0.4s ease-in-out;

    ${mediaQuery()} {
      display: none !important;
    }

    @media screen and (min-width: 1025px) and (max-height: 920px) {
      right: 70px;
    }

    @media screen and (max-width: 1025px) {
      &:hover {
        animation: none !important;
      }
    }
  }
`

export const StyledImg = styled.img`
  margin: inherit;
  max-width: 100%;
  height: auto;
  vertical-align: top;
`
