import React from 'react'
import PropTypes from 'prop-types'
import { StyledUl } from '../EpisodeList'
import Item from './Item'

/** スケルトンスクリーン */
const Skeletons = ({
  listType = 'default',
  pagerOptions = { episodesPerPages: 12, range: 2 },
  hasThumb = false,
  titleRowLength = 1,
  textRowLength = 2,
  hasPrice = false,
  hasPlayButton = false,
  ...props
}) => {
  return (
    <StyledUl listType={listType} {...props}>
      {(() =>
        [...Array(pagerOptions.episodesPerPages)].map((_, i) => (
          <li key={i}>
            <Item
              listType={listType}
              hasThumb={hasThumb}
              titleRowLength={titleRowLength}
              textRowLength={textRowLength}
              hasPrice={hasPrice}
              hasPlayButton={hasPlayButton}
            />
          </li>
        )))()}
    </StyledUl>
  )
}

export default Skeletons

Skeletons.propTypes = {
  listType: PropTypes.oneOf(['default', 'list', 'grid']),
  pagerOptions: PropTypes.shape({
    episodesPerPages: PropTypes.number,
    range: PropTypes.number
  }),
  hasThumb: PropTypes.bool,
  titleRowLength: PropTypes.number,
  textRowLength: PropTypes.number,
  hasPrice: PropTypes.bool,
  hasPlayButton: PropTypes.bool
}
