import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { InitImage } from '../../styles'

/** 在学証明書｜発行日、発行者証明 */
const DescImgs = ({ ...props }) => {
  return (
    <div {...props}>
      <StyledImg
        src="/images/exdio/renewal/gariben_daigaku/components/certificate-day.svg"
        alt="発行日：2020年 12月 吉日（大安）"
      />
      <StyledImg
        src="/images/exdio/renewal/gariben_daigaku/logo/logo_side.webp"
        alt="私立ガリベン大学"
      />
      <StyledImg
        src="/images/exdio/renewal/gariben_daigaku/components/certificate-prove.svg"
        alt="上記の者は、本大学の生徒であることを証明します"
      />
    </div>
  )
}

export default DescImgs

DescImgs.propTypes = {
  /** styleの継承 */
  className: PropTypes.string
}

const StyledImg = styled(InitImage)`
  max-width: 100%;
  display: block;

  &:not(:first-child) {
    margin-top: 5px;
  }
`
