import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledBanner,
  StyledButton3,
  StyledDiv1,
  StyledDiv2,
  StyledHeading1,
  StyledList
} from './style'
import webApp from '../../../../utils/exdioWebAppUtils'

/** ガリベン大学 Section3 */
const Section3 = ({ posts = [] }) => {
  const isSp = webApp.utils.isSp()
  const [articles, setArticles] = useState(posts)

  useEffect(() => {
    if (isSp) {
      setArticles(posts.slice(0, 4))
    }
  }, [isSp])

  return (
    <section id="top3">
      <StyledHeading1
        src="/images/exdio/renewal/gariben_daigaku/top/sec3_hdg.webp"
        alt="図書館ブログ BLOG"
        width="374"
        height="129"
      />
      <StyledDiv1 className="lazyload">
        <StyledDiv2>
          <StyledBanner />
          <StyledList posts={articles} />
          <StyledButton3
            href="https://www.tv-asahi.co.jp/reading/garibendaigaku/"
            target="_blank"
            name="もっと見る"
          />
        </StyledDiv2>
      </StyledDiv1>
    </section>
  )
}

export default Section3

Section3.propTypes = {
  /** ブログ情報の配列
   * @param {string} title - ブログタイトル
   * @param {string} link - リンクの遷移先
   * @param {string} thumbnail - ブログのサムネ画像パス
   * @param {string} date_time - ブログ公開日(timeタグのdatetimeに使用)
   * @param {string} date - ブログ公開日
   */
  posts: PropTypes.arrayOf(PropTypes.object)
}
