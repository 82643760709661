import React from 'react'
import PropTypes from 'prop-types'

/* components */
import ConvertSvg from '../../util/ConvertSvg'

/* style */
import { StyledA } from './style'

/** Logirlプランページ | ボタン2 */
const Btn2 = ({ href, icon, ...props }) => {
  return (
    <StyledA href={href} {...props}>
      <ConvertSvg {...icon} />
    </StyledA>
  )
}

export default Btn2

Btn2.propTypes = {
  /** aタグに渡すhref */
  href: PropTypes.string,
  /** ボタンに表示するアイコン */
  icon: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  })
}

Btn2.defaultProps = {
  href: '',
  icon: null
}
