import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import Header from '../../../../common/components/Header'
import Footer from '../../../../common/components/Footer'
import DFPBanner from '../../../../common/components/DFPBanner'
import SideTab from '../details/SideTab'
import ProgramTopLink from '../details/ProgramTopLink'
import {
  FAVORITE_TYPE,
  LOCAL_STORAGE_KEY_PURCHASE,
  PURCHASE_TYPE
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import SwitchableListGrid from '../../../../common/components/renewal/SwitchableListGrid'
import AddButtonBlock from '../details/AddButtonBlock'
import SideRecommend from '../details/SideRecommend'
import HtmlSnippet from '../../HtmlSnippet'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'
import HeaderNewsComponent from '../HeaderNewsComponent'

/** 月額見放題ページ デフォルトテンプレート */
export default class PlanDefault extends Component {
  static propTypes = {
    /** プランの情報 */
    course: PropTypes.shape({
      course_id: PropTypes.number.isRequired,
      schema_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired
    }).isRequired,
    /** プランに含まれる動画の情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** プランに含まれる動画の権利関連情報 */
    rightMetas: PropTypes.arrayOf(PropTypes.object),
    /** プランの購入情報 */
    status: PropTypes.shape({
      isPurchseAvailable: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool
    })
  }

  static defaultProps = {
    episodes: [],
    rightMetas: [],
    status: {
      isPurchased: false,
      isNotPurchased: false
    }
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.state = {
      pagerOptions: {
        // ページャーの設定
        episodesPerPages: 12,
        range: 2
      }
    }

    this.onClickWatch = this.onClickWatch.bind(this)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /** 視聴ボタン押下 */
  onClickWatch(e) {
    e.preventDefault()

    const { course, status } = this.props
    if (!course || !Object.keys(course).length) return

    const courseIdNum = Number(course.course_id)
    // ストレージに保存して画面遷移後にもロードできるように。
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_PURCHASE,
      JSON.stringify({
        type: PURCHASE_TYPE.PLAN,
        id: courseIdNum,
        selected: [courseIdNum],
        page: this.context.routeHandler.url
      })
    )

    // 購入済みの場合は購入済み画面へ直接飛ばしてあげる
    if (status.isPurchased) {
      this.context.history.push(
        routes.purchase.makePath({}, { content: 'completed' })
      )
      return
    }

    this.context.history.push(routes.purchase.makePath())
  }

  render() {
    const { course, season, series, episodes, rightMetas, status } = this.props
    const { pagerOptions } = this.state

    const price = _.get(course, ['active_pricing', 'price'])
    const thumbnailUrl =
      webApp.utils.customSizeImageUrl(
        _.get(course, ['thumbnail_url']),
        'large'
      ) || this.config.default_thumbnail
    const description = (_.get(course, ['description']) || '').replace(
      /\r?\n/g,
      '<br />'
    )

    // 他のシリーズ用ID
    let otherSeasons = _.get(course, ['values', 'other_series']) || []
    const otherSeasonsLive =
      _.get(course, ['values', 'other_series_live']) || []
    otherSeasons = otherSeasons.concat(otherSeasonsLive)

    const bannerId1 = _.get(course, ['values', 'banner_1', 'id'])
    const bannerId2 = _.get(course, ['values', 'banner_2', 'id'])

    let labelStatus = ''
    if (status.isNotPurchased) labelStatus = '未購入'
    if (status.isPurchased) labelStatus = '購入済み'

    return (
      <React.Fragment>
        <Header />
        <div className="common-wrapper">
          <HeaderNewsComponent />
          <SpSubNavigation spOff />

          <div className="c-headMeta">
            <div className="c-headMeta-metaBox">
              <div className="c-headMeta-metaBox-art">
                <div
                  className="c-headMeta-metaBox-art-inBox clickable"
                  onClick={this.onClickWatch}
                >
                  <div className="c-headMeta-metaBox-art-inBox-ribbon">
                    見放題
                  </div>
                  {labelStatus && (
                    <div className="c-headMeta-metaBox-art-inBox-labelStatus">
                      {labelStatus}
                    </div>
                  )}
                  {/* <div className="c-headMeta-metaBox-art-inBox-labelBottom">見放題パック</div> */}
                  <div className="c-headMeta-metaBox-art-inBox-artwork">
                    <img
                      src={thumbnailUrl}
                      className="c-headMeta-metaBox-art-inBox-artwork-img"
                    />
                  </div>
                </div>

                {/* for PC */}
                <AddButtonBlock
                  favoriteType={FAVORITE_TYPE.COURSE}
                  favoriteId={course.course_id}
                  title={course.name}
                />
              </div>

              <div className="c-headMeta-metaBox-info">
                <h2
                  className="c-headMeta-metaBox-info-title clickable"
                  onClick={this.onClickWatch}
                >
                  {course.name}
                </h2>
                <div className="c-headMeta-metaBox-info-price pack">
                  料金：月額 {price}円（税込）
                </div>
                <div className="c-headMeta-metaBox-info-viewingPeriod">
                  視聴期間：決済月の最終日まで
                </div>
                <div
                  className="c-headMeta-metaBox-info-titleHeader"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <div className="c-pageBtn playNow">
                  {status.isPurchseAvailable ? (
                    <a
                      href="#"
                      className={`c-pageBtn-link ${
                        status.isPurchased ? 'isPurchased disabled' : ''
                      }`}
                      onClick={this.onClickWatch}
                    >
                      {status.isPurchased ? (
                        '購入済み'
                      ) : (
                        <>
                          月額
                          <span className="c-pageBtn-link-yen">{price}</span>
                          円で視聴
                        </>
                      )}
                    </a>
                  ) : (
                    '現在購入いただけません'
                  )}
                </div>

                {/* for SP */}
                <AddButtonBlock
                  favoriteType={FAVORITE_TYPE.COURSE}
                  favoriteId={course.course_id}
                  title={course.name}
                />
              </div>
            </div>
          </div>

          <div className="c-listMeta">
            <div className="c-listMeta-inBox">
              <div className="c-listMeta-inBox-main">
                {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
                <SwitchableListGrid
                  episodes={null}
                  rootMetas={rightMetas}
                  placeholder="検索"
                  showNew
                  showChecked
                  onlySubTitle
                  listType="default"
                  disp_order="desc"
                  pagerOptions={pagerOptions}
                />
              </div>
              <div className="c-listMeta-inBox-sub">
                <div className="common-box-ad-rectangle">
                  <DFPBanner position="foot" meta_id="" />
                </div>
                {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
                <SideTab otherSeasons={otherSeasons} />
                <ProgramTopLink
                  seriesId={series && series.meta_id}
                  seasonId={season && season.meta_id}
                  isFree={false}
                  disabled={!(series && season)}
                />
                <HtmlSnippet
                  snippetId={this.config.extras.common_banner_snippet_key}
                />
                <SideRecommend
                  recommendType="recommend"
                  typeName={this.config.recommend.type_name.view}
                  spotName={this.config.recommend.spot_name.program}
                  courseRefId={course.ref_id}
                  episodes={episodes}
                />
              </div>
            </div>
          </div>

          <Footer className="mt0" />
        </div>
      </React.Fragment>
    )
  }
}
