import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import routes from '../../routes'
import { mediaQuery } from '../../../exdio/components/style'

/* components */
import { Link } from '../../../../sketch-platform/ui/routing'

/** アプリ用フッダー 動的 */
const EpisodeAppFooter = (
  { copyrights = '', showLogo = false, className = '' },
  context
) => {
  const config = context.models.config.data

  return (
    <footer className={`common-footer ${className}`}>
      <div className="inner">
        <div className="footer-notes">
          <div className="notes-content">
            <p>
              配信されている動画は放送内容と一部異なる場合がございます。また、予告なく配信終了する場合がございますがご了承ください。表示されている情報は、収録および放送当時のものです。
              <br />
              消費税率の変更により、テレビ朝日番組内およびホームページ内の価格表示と異なる場合があります。
            </p>
          </div>
          <div className="copyrights">
            <p>{copyrights}</p>
          </div>
        </div>
        {showLogo && (
          <StyledDiv3>
            <StyledLink route={routes.app_home}>
              <img
                src="/images/exdio/renewal/logo_tv_asahi_douga.png"
                width="207"
                height="41"
                alt="テレ朝動画"
              />
            </StyledLink>
          </StyledDiv3>
        )}
      </div>
      <div className="footer-copyrights">
        <small>{config.copyright}</small>
      </div>
      <GlobalStyle />
    </footer>
  )
}

export default EpisodeAppFooter

EpisodeAppFooter.propTypes = {
  copyrights: PropTypes.string,
  showLogo: PropTypes.bool,
  className: PropTypes.string
}

const GlobalStyle = createGlobalStyle`
  .common-footer .inner {
    flex-wrap: wrap;
  }
`

const StyledDiv3 = styled.div`
  margin-top: 24px;
  width: 100%;
  text-align: center;

  ${mediaQuery()} {
    margin-top: 0;
    padding-bottom: 24px;
  }
`

const StyledLink = styled(Link)`
  display: inline-block;

  ${mediaQuery()} {
    img {
      width: 207px;
    }
  }
`

EpisodeAppFooter.contextTypes = {
  models: PropTypes.shape({
    config: PropTypes.shape({
      data: PropTypes.shape({
        copyright: PropTypes.string
      })
    })
  })
}
