import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styled from 'styled-components'
import CastInfo from './CastInfo'
import * as browserEvents from '../../../../utils/browserEvents'

/** 詳細画面:あらすじコンポーネント */
const Caption = ({ caption = '', className = '', ...props }) => {
  const [expand, setExpand] = useState(false)
  const [showExpandBtn, setShowExpandBtn] = useState(false)

  const captionRef = useRef(null)
  const captionInnerRef = useRef(null)
  const descriptionRef = useRef(null)

  const monitorCaptionSize = () => {
    if (!captionRef.current) return
    setShowExpandBtn(
      captionInnerRef.current.clientHeight > captionRef.current.clientHeight - 1
    )
  }

  const toggle = (e) => {
    e.preventDefault()
    setExpand(!expand)
  }

  /** [time=hh:mm:ss]をtimeタグに変換 */
  const convertTimeTag = (innerHtml) => {
    const html = innerHtml.replace(
      /\[time=(([0-9]{1,2}:)?[0-5]?[0-9]:[0-5][0-9])\]/g,
      '<time datetime="$1">$1</time>'
    )
    return html
  }

  /** \r?\nをbrタグに変換 */
  const convertToBr = (text) => {
    return text.replace(/(\r?\n)/g, '<br />')
  }

  /**
   * 時間から秒に直す
   * 03:25:45 -> 12345
   */
  const timeToSeconds = (time = '00:00:00') => {
    if (typeof time !== 'string') return 0
    const timeArr = time.split(':')

    if (timeArr.length === 3) {
      const hours = Number(timeArr[0]) * 60 * 60
      const minutes = Number(timeArr[1]) * 60
      const seconds = Number(timeArr[2])
      return hours + minutes + seconds
    }

    const minutes = Number(timeArr[0]) * 60
    const seconds = Number(timeArr[1])
    return minutes + seconds
  }

  /** テンプレート側にseekTimeを渡す */
  const sendCurrentTime = (time = '00:00:00') => {
    const DioPlayer = document.getElementById('DIOplayer')
    if (!DioPlayer) return

    const videoElm = DioPlayer.querySelector('video')
    if (!videoElm) return

    /** 形式チェック */
    if (time.match(/([0-9]{1,2}:)?[0-5]?[0-9]:[0-5][0-9]/) === null) return

    videoElm.currentTime = timeToSeconds(time)
  }

  useEffect(() => {
    monitorCaptionSize()
    browserEvents.addEventListener('resize', monitorCaptionSize)

    return () => {
      browserEvents.removeEventListener('resize', monitorCaptionSize)
    }
  }, [])

  /** descriptionのタイムリンクにクリックイベントを付与 */
  useEffect(() => {
    const descriptionElm = descriptionRef.current
    if (!descriptionElm) return

    const timeElms = descriptionElm.querySelectorAll('time')
    timeElms.forEach((elm) => {
      elm.addEventListener('click', () => sendCurrentTime(elm.innerText))
    })
  }, [caption])

  if (!caption) return null

  return (
    <>
      <div
        className={cn('c-storyMeta-inBox-meta-caption', {
          className,
          open: expand
        })}
        ref={captionRef}
      >
        <div
          className="c-headMeta-metaBox-info-caption-inner"
          ref={captionInnerRef}
        >
          <StyledSpan
            ref={descriptionRef}
            dangerouslySetInnerHTML={{
              __html: convertToBr(convertTimeTag(caption))
            }}
          />
          <CastInfo {...props} idPadding={false} />
        </div>
      </div>
      <div
        className={cn('c-more', {
          forceHide: !showExpandBtn
        })}
      >
        <a className="c-more-btn" onClick={toggle}>
          <span className={`c-more-btn-txt ${expand ? 'open' : ''}`} />
        </a>
      </div>
    </>
  )
}

export default Caption

Caption.propTypes = {
  caption: PropTypes.string,
  otherInstructions: PropTypes.string,
  actors: PropTypes.string,
  directors: PropTypes.string,
  producers: PropTypes.string,
  writers: PropTypes.string,
  productions: PropTypes.string,
  className: PropTypes.string
}

const StyledSpan = styled.span`
  time {
    color: #0000ee;
    text-decoration: underline;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }
`
