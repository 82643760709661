import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import Link from '../../../../sketch-platform/ui/routing/Link'
import routes from '../../routes'
import webApp from '../../../exdio/utils/exdioWebAppUtils'

/** style */
import { mediaQuery } from '../../../exdio/components/style'

/** ヘッダー > ログインボタン/ユーザー情報 */
const LoggedIn = (
  { toggleNavMenu = () => {}, profileIcon = '', path = '', ...props },
  context
) => {
  const { availablePoint } = context.models.userInfo.data
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isApp = webApp.utils.isApp(context)

  let redirect = path || context.routeHandler.path
  if (routes[isApp ? 'app_login' : 'login'].match(redirect)) {
    redirect = context.routeHandler.query.redirect || null
  }

  if (isLoggedIn) {
    return (
      <div onClick={toggleNavMenu} {...props}>
        <StyledMyPageLink
          route={routes[isApp ? 'app_mypage' : 'mypage_account_info']}
          state={
            isApp
              ? { content: { id: 91, label: '購入済' } }
              : { content: { id: 2, label: 'アカウント情報' } }
          }
        >
          <StyledDiv1>
            <StyledImg src={profileIcon} alt="マイページ" />
          </StyledDiv1>
          {isApp ? (
            <StyledDiv3>ログイン中</StyledDiv3>
          ) : (
            <StyledDiv2>
              {get(availablePoint, ['available_point'], 0)}
            </StyledDiv2>
          )}
        </StyledMyPageLink>
      </div>
    )
  }

  return (
    <div>
      <StyledLoginLink
        route={routes[isApp ? 'app_login' : 'login']}
        query={redirect ? { redirect } : {}}
        onClick={() => toggleNavMenu()}
        {...props}
      >
        ログイン
      </StyledLoginLink>
    </div>
  )
}

export default memo(LoggedIn)

LoggedIn.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}

LoggedIn.propTypes = {
  /** GlobalNavigation.js > showSubMenu/showSearch/showMemberMenu 開閉処理 */
  toggleNavMenu: PropTypes.func.isRequired,
  /** ユーザーアイコン */
  profileIcon: PropTypes.string,
  /** 現在のURL */
  path: PropTypes.string
}

const StyledMyPageLink = styled(Link)`
  width: 44px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${mediaQuery()} {
    width: 37px;
    height: 39px;
  }

  &:hover {
    opacity: 0.6;
    transition: opacity 0.2s;
  }
`

const StyledDiv1 = styled.div`
  margin: auto;
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

const StyledImg = styled.img`
  border-radius: 50%;
`

const StyledDiv2 = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  font-size: 1.3rem;
  font-weight: 600;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;

  white-space: nowrap; // ヘッダーのコイン数。桁数多いと改行されてしまうため。

  ${mediaQuery()} {
    font-size: 1rem;
  }

  &::before {
    margin-right: 3px;
    width: 16px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    content: url(/images/exdio/renewal/icon_medal.svg);
  }
`

const StyledDiv3 = styled.div`
  margin: auto;
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 0;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
  white-space: nowrap;
  transform: translateX(-64%);

  ${mediaQuery()} {
    font-size: 1rem;
  }
`

const StyledLoginLink = styled(Link)`
  width: 81px;
  height: 27px;
  background-color: #fff;
  border-radius: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 600;

  ${mediaQuery()} {
    width: 68px;
    height: 23px;
    font-size: 1.1rem;

    ${mediaQuery(374)} {
      width: 56px;
      font-size: 1rem;
    }
  }

  &:hover {
    background-color: #e3e3e3;
    transition: background-color 0.2s;
  }
`
