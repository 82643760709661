import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
import Cookie from 'js-cookie';

import routes from '../../../../../common/routes';

// MEMO: watchページの子メタ
export default class AnotherCard extends Component {

  static getPaths = function(models, options, props = {}) {
    let keyPath = [];
    if (props.titleId) {
      keyPath = keyPath.concat(['meta', props.titleId]);
    }
    return [keyPath.concat([['id','name', 'thumbnailUrl', 'type', 'values', 'deliveryStartAt', 'deliveryEndAt']])];
  };

  static getRootPath = function(models, options, props = {}) {
    if (!props.titleId) return;
    return ['meta', props.titleId];
  }

  static propTypes = {
    mainId: PropTypes.number,
    model: PropTypes.object.isRequired,
    rowModel: PropTypes.object,
    index: PropTypes.number,
    rowNum: PropTypes.number,
    titleId: PropTypes.number.isRequired,
    popType: PropTypes.string,
    name: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    progress: PropTypes.number,
    subtitle: PropTypes.bool,
  };

  static contextTypes = {
    models: PropTypes.object,
    columnsInRow: PropTypes.number,
    currentPage: PropTypes.string,
    getModelData: PropTypes.func,
    history: PropTypes.object,
    isInitialRender: PropTypes.bool,
    isOverlayPage: PropTypes.bool,
    isRtl: PropTypes.bool,
    isTallRow: PropTypes.bool,
    titleRowEpisodeTrackId: PropTypes.number,
    titleRowTrackId: PropTypes.number,
    titleRowTrailerTrackId: PropTypes.number,
    listContext: PropTypes.string,
    listType: PropTypes.string,
    loggingApp: PropTypes.object,
    lolomoId: PropTypes.string,
    profileManager: PropTypes.string,
    requestId: PropTypes.string,
    routeHandler: PropTypes.object,
    rowNum: PropTypes.number,
    trackId: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    this.item = props.model.getSync(rootPath);
    this.index = props.index;
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath)
    };
    this._isMounted = false;
    this.handleResize = this.handleResize.bind(this);
    this.imgResize = this.imgResize.bind(this);
    this.imgHeight = this.imgHeight.bind(this);
    this.imgHeightBox = this.imgHeightBox.bind(this);
    this.previewUrlList = this.context.models.config.data.preview_url_list;
    this.cookieRubocopPrefix = this.context.models.config.data.cookie_rubocop_prefix;
  }

  componentWillMount() {
    this.fetchData(this.props);
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('resize', this.imgResize);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.imgResize);
  }

  handleResize() {
    let imgElement = $(".avod_ep_list_box img")[this.props.index];
    if(imgElement != "undefined"){
      $(imgElement).height($(imgElement).width() * 0.562);
    }
  }
  
  imgResize() {
    let imgSize = $(".appli_avod_ep_list_imgwarp");    
    if(imgSize != "undefined"){
      $(imgSize).height($(imgSize).width() * 0.562);
    }
  }

  imgHeight() {
    let imgElement = $(".avod_ep_list_box img")[this.props.index];
    if(imgElement != "undefined"){
      return { 
        height: 'auto'
      };
    }else{
      {}
    }
  }
  
  imgHeightBox(){
    let imgSize = $(".appli_avod_ep_list_imgwarp");
    if(imgSize != "undefined"){
      return { height: $(imgSize).width() * 0.562+ "px"};
    }else{
      {}
    }
  }

  nextPrevText(e, alt) {
    return(
      <div className="avod_ep_list_imgwarp" style={this.imgResize()}>
        <img src={this.props.thumbnailUrl} alt={alt}/>
        <p className="avod_ep_list_img_nextPrevText">{e}</p>
      </div>
    )
  }

  render() {
    if (!this.item) {
      return null;
    }

    var itemName = this.item.name;
    var episodeName = this.props.program_link ? 'エピソード一覧' : this.item.values.avails_EpisodeTitleDisplayUnlimited;
    var programName = itemName.replace(episodeName, '').trim();
    var icon = this.props.program_link ? (<span className="appli_avod_ep_list_iconwrap"><img src="/images/exdio/icon_episode.png" className="appli_avod_ep_list_icon" alt="リストアイコン"/></span>) : '';

    let titleArtwork;
    
    const thumbnailUrl = this.item.thumbnailUrl + '_medium' || window.app.reactContext.models.config.data.default_thumbnail;
    const prevTime = Cookie.get(this.previewUrlList + this.cookieRubocopPrefix);

    const progressbar = this.props.program_link || this.props.progress === 0 ? '' :
    (
      <div className="avod_ep_list_progress_wrap">
        <div className="avod_ep_list_progress" style={{width: `${this.props.progress}%`}}></div>
      </div>
    );

    if (moment(prevTime).isBefore(this.item.deliveryStartAt) && !this.props.program_link) {
      titleArtwork = this.nextPrevText('まもなく配信予定', programName);
    } else if (moment(prevTime).isAfter(this.item.deliveryEndAt) && !this.props.program_link){
      titleArtwork = this.nextPrevText('次回もお楽しみに！', programName);
    } else {
      titleArtwork = (<div className="appli_avod_ep_list_imgwarp" style={this.imgHeightBox()}>
        {icon}
          <img src={this.props.thumbnailUrl} alt={programName}/>
          {progressbar}
        </div>);
    }
    const itemPrefixSubtileIcon = this.props.subtitle ? <img src="/images/exdio/top_cc_icon.png" className="icon-subtitle" /> : ''

    return (
      <div className="avod_ep_list_box post_cu_api">
        <div className="avod_ep_list_inner"
             key={`another_${this.props.TitleId}_${this.props.index}`}
             onClick={this.handleClick.bind(this)}
             onKeyDown={this.handleKeyDown.bind(this)}
        >
          <div className="avod_ep_list_img">
            {titleArtwork}
            <div className="post-title">
              <h2 className="avod_ep_li_ttl">{programName}</h2>
              <p className="avod_ep_list_air">{!this.props.program_link && itemPrefixSubtileIcon}{episodeName}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleClick(e) {
    e.preventDefault();
    const { history } = this.context;
    if (this.props.program_link == true && this.props.seasonId){
      history.push(routes.app_program.makePath({id: this.props.seasonId}));
    } else {
      history.push(routes.app_watch.makePath({id: this.props.titleId}));
    }
  }

handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  fetchData(props) {
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    this.state.dispose = evaluator.dispose;
    evaluator.then((res) => {
      this.item = _.get(res.json, rootPath, {});
      const newState = {
        fetchDataError: null,
        dispose: null,
        generation: props.model.getVersion(rootPath)
      };
      if (this._isMounted) this.setState(newState);
      else Object.assign(this.state, newState);
    }).catch((e) => {
      console.error(e.stack);
      const newState = {
        fetchDataError: e,
        dispose: null
      };
      if (this._isMounted) this.setState(newState);
      else this.state = Object.assign(newState);
    });
  }
}
