import styled from 'styled-components'
import { mediaQuery } from '../../../../../../../exdio/components/style'
import { breakPoints } from '../../../style'

export const StyledDiv1 = styled.div`
  margin: 0 auto 50px;
  width: 1280px;
  position: relative;

  ${mediaQuery(breakPoints.tab)} {
    width: 960px;
  }

  ${mediaQuery(breakPoints.sp)} {
    width: auto;
  }
`

export const StyledDiv2 = styled.div`
  .slick-slider {
    .slick-list {
      width: 992px;
    }

    .slick-dots {
      width: auto;
      right: 0;
      bottom: -30px;
      li {
        margin: 0 4px;
        width: 8px;
        height: 8px;
        background: #b7c5d9;
        button {
          width: 100%;
          height: 100%;
        }
        button::before {
          content: none;
        }
      }
      .slick-active {
        background: red !important;
      }
    }
  }

  ${mediaQuery(breakPoints.tab)} {
    .slick-slider {
      .slick-list {
        width: 736px;
      }
    }
  }

  ${mediaQuery(breakPoints.sp)} {
    .slick-slider {
      .slick-list {
        width: auto;
      }
      .slick-dots {
        width: 100%;
        bottom: -150px;
        li {
          margin: 0 5px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`

export const StyledDiv3 = styled.div`
  height: 558px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;

  ${mediaQuery(breakPoints.tab)} {
    height: 416px;
  }

  ${mediaQuery(breakPoints.sp)} {
    margin-top: -90px;
    margin-bottom: 50px;
    height: auto;
    overflow: initial;
    position: initial;
    top: initial;
    right: initial;
  }
`

export const StyledImg = styled.img`
  width: 284px;
  opacity: 0.5;

  ${mediaQuery(breakPoints.tab)} {
    width: 220px;
    margin-left: 16px;
  }

  ${mediaQuery(breakPoints.sp)} {
    margin-top: 0;
    margin-left: 0;
    padding-top: 18px;
    width: auto;
  }
`

export const StyledDiv4 = styled.div``

export const StyledDiv5 = styled.div`
  padding-top: 94px;
  width: 301px;
  height: 652px;
  transform: translate3d(0, -94px, 0);
  transition: transform 0.7s ease 0s;

  .slick-slide {
    width: 319px !important;
    position: relative;
  }

  .slick-current {
    ${StyledDiv4}::before {
      margin-left: 13px;
      height: 100%;
      border-left: 5px solid red;
      position: absolute;
      top: 0;
      left: -2px;
      content: '';
    }

    ${StyledDiv4}::after {
      margin-top: -8px;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-right: 13px solid red;
      border-bottom: 8px solid transparent;
      position: absolute;
      top: 50%;
      left: -1px;
      content: '';
    }

    ${StyledImg} {
      opacity: 1;
    }
  }

  ${mediaQuery(breakPoints.tab)} {
    width: 237px;
    height: 646px;
  }

  ${mediaQuery(breakPoints.sp)} {
    padding: 0;
    width: auto;
    height: auto;
    transform: initial;
    transition: initial;

    .slick-slide {
      width: 120px !important;
    }
  }
`

export const StyledDiv6 = styled.div`
  padding: 10px 0;
  text-align: left;

  a {
    color: #fff;
    font-weight: bold;
  }

  ${mediaQuery(breakPoints.sp)} {
    margin-bottom: 95px;
    padding: 10px;
    height: 29px;
    overflow: hidden;
    position: relative;
    z-index: 100;
    color: #fff;
    font-weight: 700;
    font-style: italic;

    text-overflow: ellipsis;
    white-space: nowrap;
    .slick-slider {
      .slick-list {
        .slick-slide {
          a {
            font-size: 1.5rem;
            font-weight: 700;
          }
        }
      }
    }
  }
`
