import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import routes from '../../../../../../common/routes'
import webApp from '../../../../../utils/exdioWebAppUtils'
import { META_SCHEMA_ID } from '../../../../../../../constants/app'

/* style */
import { StyledDiv1, StyledLink1, StyledLink2 } from './style'

/** 港時間:SP単話ページングコンポーネント */
const SpEpisodePager = ({ meta, seriesId, seasonId, hover }, context) => {
  const model = context.falcorModel.batch(100)
  const [prevMeta, setPrevMeta] = useState(null)
  const [nextMeta, setNextMeta] = useState(null)

  useEffect(() => {
    getPrevMeta()
    getNextMeta()
  }, [])

  /** 前のメタ情報取得 */
  const getPrevMeta = () => {
    if (!meta || !meta.values || !meta.values.prev_meta)
      return Promise.resolve()
    if (typeof meta.values.prev_meta === 'object') {
      setPrevMeta(meta.values.prev_meta)
      return Promise.resolve()
    }
    const path = ['metas', meta.values.prev_meta]
    return model.fetch([path]).then((result) => {
      setPrevMeta(_.get(result, ['json', ...path]) || null)
    })
  }

  /** 次のメタ情報取得 */
  const getNextMeta = () => {
    if (!meta || !meta.values || !meta.values.next_meta)
      return Promise.resolve()
    if (typeof meta.values.next_meta === 'object') {
      setNextMeta(meta.values.next_meta)
      return Promise.resolve()
    }
    const path = ['metas', meta.values.next_meta]
    return model.fetch([path]).then((result) => {
      setNextMeta(_.get(result, ['json', ...path]) || null)
    })
  }

  const judgeEpisodeRoute = (meta) => {
    if (!meta) return null
    if (webApp.utils.isApp(context)) {
      return meta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE
        ? routes.app_catchupEpisode
        : routes.app_episode
    }
    return meta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
      meta.meta_schema_id === META_SCHEMA_ID.LIVE
      ? routes.catchupEpisode
      : routes.episode
  }

  if (!seriesId || !seasonId) return null
  const prevEpisodeRoute = judgeEpisodeRoute(prevMeta)
  const nextEpisodeRoute = judgeEpisodeRoute(nextMeta)

  return (
    <StyledDiv1>
      {prevMeta && (
        <StyledLink1
          route={prevEpisodeRoute}
          params={{ seriesId, seasonId, episodeId: prevMeta.meta_id }}
        >
          前の動画
        </StyledLink1>
      )}
      {nextMeta && (
        <StyledLink2
          route={nextEpisodeRoute}
          params={{ seriesId, seasonId, episodeId: nextMeta.meta_id }}
        >
          次の動画
        </StyledLink2>
      )}
    </StyledDiv1>
  )
}

export default SpEpisodePager

SpEpisodePager.propTypes = {
  // 月額見放題に含まれない単話の場合
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    values: PropTypes.shape({
      prev_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
      next_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
    })
  }),
  /** シリーズID */
  seriesId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** シーズンID */
  seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hover: PropTypes.bool
}

SpEpisodePager.defaultProps = {
  meta: null,
  seriesId: null,
  seasonId: null,
  hover: false
}

SpEpisodePager.contextTypes = {
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object
}
