import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { get, includes } from 'lodash'

/* component */
import Radio from '../Radio'

/* style */
import { StyledDiv, StyledUl, StyledLi } from './style'
import { StyledLabel } from '../Radio/style'

const options = [
  {
    id: 1,
    value: 'avails_release_history_original_older',
    label: '放送が古い順'
  },
  {
    id: 2,
    value: 'avails_release_history_original_newer',
    label: '放送が新しい順'
  },
  {
    id: 3,
    value: 'delivery_start_at_older',
    label: '配信が古い順'
  },
  {
    id: 4,
    value: 'delivery_start_at_newer',
    label: '配信が新しい順'
  },
  {
    id: 5,
    value: 'jp_syllabary_order_asc',
    label: 'あいうえお順(昇順)'
  },
  {
    id: 6,
    value: 'jp_syllabary_order_desc',
    label: 'あいうえお順(降順)'
  },
  {
    id: 7,
    value: 'play_time_longer',
    label: '動画が長い順'
  },
  {
    id: 8,
    value: 'play_time_shorter',
    label: '動画が短い順'
  }
]

/**
 * ソート機能
 * フィルター機能と内容がほぼ同じなので、共通コンポーネントにしても良い
 */
const SortElm = ({
  sortedBy = 'values.avails_EpisodeNumber',
  updateSearchParams = () => {},
  stateSelectedElm = ['', () => {}],
  ...props
}) => {
  const [selectedElm, setSelectedElm] = stateSelectedElm
  const isOpen = selectedElm === 'sortedBy'
  const [currentOption, setCurrentOption] = useState({})
  const [sortedByDefaultLabel, setSortedByDefaultLabel] = useState(
    includes(
      options.map((o) => o.value),
      sortedBy
    )
      ? ''
      : '並び替え'
  )

  useEffect(() => {
    setCurrentOption(options.find((option) => option.value === sortedBy))
    if (
      sortedBy !== 'values.avails_EpisodeNumber' &&
      sortedByDefaultLabel !== ''
    )
      setSortedByDefaultLabel('')
  }, [sortedBy])

  return (
    <StyledDiv isOpen={isOpen} {...props}>
      <StyledUl isOpen={isOpen}>
        <StyledLi>
          <StyledLabel onClick={() => setSelectedElm(isOpen ? '' : 'sortedBy')}>
            {sortedByDefaultLabel || get(currentOption, ['label'], '')}
          </StyledLabel>
        </StyledLi>
        {options.map(({ id, value, label }) => (
          <StyledLi key={value}>
            <Radio
              label={label}
              checked={get(currentOption, ['value']) === value}
              radioProps={{
                name: 'sort',
                value,
                id: `fSort${id}`,
                onChange: () => {
                  updateSearchParams({ sortedBy: value })
                },
                onClick: () => setSelectedElm('')
              }}
            />
          </StyledLi>
        ))}
      </StyledUl>
    </StyledDiv>
  )
}

export default memo(SortElm)

SortElm.propTypes = {
  sortedBy: PropTypes.string,
  updateSearchParams: PropTypes.func,
  stateSelectedElm: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  )
}
