import React, { memo } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import styled from 'styled-components'
import webApp from '../../../exdio/utils/exdioWebAppUtils'
import Link from '../../../../sketch-platform/ui/routing/Link'
import routes from '../../routes'
import { LOCAL_STORAGE_KEY_CHECKED_ARTICLES } from '../../../../constants/app'

/** style */
import { mediaQuery } from '../../../exdio/components/style'

/** ヘッダー > ベルアイコン */
const Alert = (
  { toggleNavMenu = () => {}, articles = [], path = '', ...props },
  context
) => {
  const isApp = webApp.utils.isApp(context)

  /** 未読のニュースがあるか確認 */
  const areAllArticlesChecked = () => {
    if (
      !articles ||
      !articles.length ||
      routes[isApp ? 'app_information' : 'information'].match(path)
    )
      return true
    const tmp = window.localStorage.getItem(LOCAL_STORAGE_KEY_CHECKED_ARTICLES)
    if (!tmp) return false
    const storage = JSON.parse(tmp)
    const notChecked = articles.find((article) => !storage.includes(article.id))
    return !notChecked
  }

  const isAreAllArticlesChecked = areAllArticlesChecked()

  return (
    <div key="alert" {...props}>
      <StyledLink
        route={routes[isApp ? 'app_information' : 'information']}
        checked={isAreAllArticlesChecked}
        onClick={() => toggleNavMenu()}
      >
        <StyledImg src="/images/exdio/renewal/icon_alert.png" alt="お知らせ" />
      </StyledLink>
    </div>
  )
}

export default memo(Alert)

Alert.propTypes = {
  /** GlobalNavigation.js > showSubMenu/showSearch/showMemberMenu 開閉処理 */
  toggleNavMenu: PropTypes.func.isRequired,
  /** お知らせ一覧 */
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      /** お知らせ > ID */
      id: PropTypes.number
    })
  ),
  /** 現在のURL */
  path: PropTypes.string
}

Alert.contextTypes = {
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  })
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 47px;
  position: relative;
  ${mediaQuery()} {
    width: 18px;
    height: 39px;
  }
  &:hover {
    opacity: 0.6;
    transition: 0.2s;
  }
  &::before {
    display: ${({ checked }) => (checked ? 'none' : 'block')};
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ff0000;
    position: absolute;
    right: 12px;
    top: 9px;
    ${mediaQuery()} {
      width: 6px;
      height: 6px;
      right: 10px;
      top: 8px;
    }
  }
`

const StyledImg = styled.img`
  ${mediaQuery()} {
    width: 18px;
  }
`
