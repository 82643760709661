import styled from 'styled-components'
import { mediaQuery } from '../../../style'

const tabHeight = '55px'

const white = '#fff'
const black = '#000'
const yellow = '#cece00'

export const StyledDiv = styled.div`
  height: ${({ isClose }) => (isClose ? tabHeight : null)};
`

export const StyledUl1 = styled.ul`
  display: flex;

  ${mediaQuery('sm', 'min')} {
    display: none;
  }
`

export const StyledLi1 = styled.li`
  width: 100%;
  height: ${tabHeight};

  ${mediaQuery('sm', 'min')} {
    display: ${({ hidePc }) => (hidePc ? 'none' : null)};
  }
`

export const StyledLabel = styled.label`
  padding: 17px 0;
  width: 100%;
  border-bottom: 3px solid ${black};
  background-color: ${black};
  display: block;
  color: ${white};
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
`

export const StyledRadio = styled.input`
  display: none;

  &:checked ~ ${StyledLabel} {
    border-bottom-color: ${yellow};
  }
`

export const StyledUl2 = styled.ul`
  ${mediaQuery()} {
    position: absolute;
    top: ${tabHeight};
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const StyleLi2 = styled.li`
  display: ${({ show }) => (show ? null : 'none')};

  ${mediaQuery()} {
    height: 100%;
  }
`
