import styled from 'styled-components'
import { mediaQuery } from '../../../../../../../exdio/components/style'
import { breakPoints } from '../../../style'

export const StyledDiv = styled.div`
  width: 992px;
  height: 558px;

  ${mediaQuery(breakPoints.tab)} {
    width: auto;
    height: auto;
  }
`

export const StyledA = styled.a`
  display: inline-block;
  width: 100%;
`

export const StyledImg = styled.img`
  width: 100%;
`
