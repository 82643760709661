import styled, { css, keyframes } from 'styled-components'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import { mediaQuery } from '../../../style'
import { deepPink, pink, white } from '../styles'

const flash = keyframes`
  0% {
    opacity: .4;
  }
  100% {
    opacity: 1;
  }
`

export const StyledNav = styled.nav`
  background-color: ${pink};
`

export const StyledUl = styled.ul`
  margin: 0 auto;
  width: 1080px;
  border-left: 1px solid ${deepPink};
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  display: flex;

  ${mediaQuery()} {
    width: auto;
    flex-wrap: wrap;
  }
`

export const StyledLi = styled.li`
  border-right: 1px solid ${deepPink};
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  flex: 1;

  br {
    display: none;
  }

  ${mediaQuery()} {
    width: calc(100% / 3);
    flex: 0 1 auto;

    br {
      display: block;
    }

    &:nth-child(n + 4) {
      border-top: 1px solid ${deepPink};
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25) inset;
    }
  }
`

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['isRich'].includes(prop)
})`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.25;

  &:hover {
    animation: ${flash} 1s;
  }

  ${mediaQuery()} {
    padding: 7px 0;
  }

  ${({ isRich }) => {
    if (isRich) {
      return css`
        border-radius: 1px;
        background-image: linear-gradient(
          90deg,
          rgb(217, 163, 0) 0%,
          rgb(255, 217, 0) 50%,
          rgb(201, 158, 0) 100%
        );
        position: relative;
      `
    }
    return null
  }}
`

export const StyledI = styled.i`
  width: 26px;
  height: 26px;
  opacity: 0.8;

  img {
    width: 100%;
    height: auto;
  }

  ${mediaQuery()} {
    width: 18px;
    height: 18px;
  }
`

export const StyledSpan = styled.span.withConfig({
  shouldForwardProp: (prop) => !['isRich'].includes(prop)
})`
  margin-left: 10px;
  color: ${white};
  font-size: 1.5rem;
  font-weight: 700;

  ${mediaQuery()} {
    margin-left: 5px;
    font-size: 1.2rem;
  }

  ${({ isRich }) => {
    if (isRich) {
      return css`
        text-shadow: 1px 1px 2px #fff, -1px 1px 2px #fff, 1px -1px 2px #fff,
          -1px -1px 2px #fff, 1px 0px 2px #fff, 0px 1px 2px #fff,
          -1px 0px 2px #fff, 0px -1px 2px #fff;
        color: #855401;
      `
    }
    return null
  }}
`

export const StyledDiv = styled.div.withConfig({
  shouldForwardProp: (prop) => !['navTextLength'].includes(prop)
})`
  width: 100%;
  padding: 3px 0;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: -18px;
  border-radius: 12px;
  background-color: #f10000;

  ${({ navTextLength }) => {
    if (navTextLength > 12) {
      return css`
        width: calc(${navTextLength}% * 8);
      `
    }
    return null
  }}

  ${mediaQuery()} {
    width: 100%;
    padding: 5px 0;
    font-size: 1.1rem;
    top: inherit;
    bottom: -12px;
  }

  ${mediaQuery(578)} {
    ${({ navTextLength }) => {
      if (navTextLength > 10) {
        return css`
          width: calc(${navTextLength}% * 10);
        `
      }
      return null
    }}
  }

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-top: 11px solid #f10000;
    border-bottom: 0;
    position: absolute;
    top: 19px;
    right: calc(50% - 6px);

    ${mediaQuery()} {
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-bottom: 11px solid #f10000;
      border-top: 0;
      top: inherit;
      top: -5px;
    }
  }
`
