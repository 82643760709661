import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import { MYPAGE_CONTENTS } from '../../../../../constants/app'
import routes from '../../../../common/routes'

/** マイページ:購入済 */
export default class Purchased extends Component {
  static propTypes = {
    setContent: PropTypes.func.isRequired,
    emitSetState: PropTypes.func.isRequired
  }

  static defaultProps = {}

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)

    this.state = {
      vodPurchased: null,
      ppvPurchased: null
    }

    this.isApp = !!routes.app_mypage.match(this.context.routeHandler.url)
  }

  componentDidMount() {
    this._isMounted = true
    this.fetchData().catch(() => {})
    this.fetchPpv().catch(() => {})
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** 購入済情報取得 */
  fetchData() {
    const path = ['infra', 'vodPurchased']
    return this.model.fetch([path]).then((result) => {
      const vodPurchased = _.get(result, ['json', ...path]) || []
      if (this._isMounted) {
        this.setState({ vodPurchased })
      }
    })
  }

  /** ppv購入情報取得 */
  fetchPpv() {
    const path = ['infra', 'ppvPurchased']
    return this.model.fetch([path]).then((result) => {
      const ppvPurchased = _.get(result, ['json', ...path]) || []
      if (this._isMounted) {
        this.setState({ ppvPurchased })
      }
    })
  }

  name(data) {
    const { meta, product, course } = data
    const target = meta || product || course

    // last_purchase_dateがppvにはないのでこのキーでppvかvodを判断
    const lastPurchaseDate = 'last_purchase_date' in data
    if (!lastPurchaseDate) return _.get(data, ['name'], '')
    return (
      _.get(target, ['values', 'evis_FrontDisplayTitle'], '') ||
      _.get(target, ['name'], '')
    )
  }

  thumbnail(data) {
    const { meta, product, course } = data

    let thumbnailUrl = null
    let thumbnailAlt = null
    if (meta) {
      let [metaName, subTitle] = webApp.utils.titles(meta)
      metaName = `${metaName} ${subTitle}`
      thumbnailUrl = meta.thumbnail_url
      thumbnailAlt = metaName
    } else if (product) {
      thumbnailUrl = product.thumbnail_url
      thumbnailAlt = product.name
    } else if (course) {
      thumbnailUrl = course.thumbnail_url
      thumbnailAlt = course.name
    }

    /* 読み込む画像サイズを変更する */
    thumbnailUrl = webApp.utils.customSizeImageUrl(thumbnailUrl, 'small')

    return [
      thumbnailUrl || this.context.models.config.data.default_thumbnail,
      thumbnailAlt
    ]
  }

  episodeCount(data) {
    const { episodes } = data
    if (!episodes || !episodes.length) return null
    return episodes.length
  }

  key(data) {
    const { meta, product, course } = data
    if (meta) {
      return `meta-${meta.meta_id}`
    } else if (product) {
      return `product-${product.product_id}`
    } else if (course) {
      return `course-${course.course_id}`
    }
    return ''
  }

  onClick(data, e) {
    e.preventDefault()
    e.stopPropagation()
    const { setContent, emitSetState } = this.props
    const { meta, product, course } = data
    emitSetState({ currentVodPurchased: data }, () => {
      if (meta) {
        setContent(MYPAGE_CONTENTS.PURCHASED.EPISODE)
      } else if (product) {
        setContent(MYPAGE_CONTENTS.PURCHASED.PACK)
      } else if (course) {
        setContent(MYPAGE_CONTENTS.PURCHASED.PLAN)
      }
    })
  }

  renderBrowser() {
    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">{this.renderCommon()}</div>
      </div>
    )
  }

  renderAppli() {
    return this.renderCommon()
  }

  renderCommon() {
    const { vodPurchased, ppvPurchased } = this.state
    if (!vodPurchased) return null

    let purchased = []
    if (vodPurchased !== null && ppvPurchased !== null) {
      const BeforeFormatVod = vodPurchased.concat()
      //last_purchase_dateがnullの場合はフォーマットのみ行う
      //last_purchase_dateがppvにはないのでpurchase_dateに入れ込む
      const formattedVod = BeforeFormatVod.map((data) => ({
        ...data,
        purchase_date: `${
          data.last_purchase_date !== null
            ? data.last_purchase_date.replace(/-/g, '/')
            : data.purchase_date.replace(/-/g, '/')
        }`
      }))

      const BeforeFormatPpv = ppvPurchased.concat()
      const formattedPpv = BeforeFormatPpv.map((data) => ({
        ...data,
        purchase_date: data.purchase_date.replace(/-/g, '/')
      }))
      const combine = formattedVod.concat(formattedPpv)
      combine.sort((a, b) => {
        return a.purchase_date < b.purchase_date ? 1 : -1
      })
      purchased = combine
    }

    return (
      <div className="c-card-vertical myPurchasedList">
        {vodPurchased &&
          vodPurchased.length === 0 &&
          ppvPurchased &&
          ppvPurchased.length === 0 && (
            <div className="c-card-vertical-cont no-mylist">
              購入済み動画はありません
            </div>
          )}
        <div className="c-card-vertical-cont">
          {/* {vodPurchased.map(data => {
            const { product, course } = data;
            const [thumbnailUrl, thumbnailAlt] = this.thumbnail(data);
            const episodeCount = this.episodeCount(data);
            const deadLine = data.limit_date ? webApp.utils.deadLine(this.context, data.limit_date, !!data.course) : '';
            const bottomLabel = webApp.utils.productLabel(product, course);
            return (
              <a href="#" key={this.key(data)} className="c-card" onClick={e => e.preventDefault()}>
                <figure className="c-card-inBox clickable" onClick={e => this.onClick(data, e)}>
                  <div className="c-card-inBox-art">
                    <div className="c-card-inBox-art-artwork shadowOn">
                      <img src={thumbnailUrl} className="c-card-inBox-art-artwork-img" alt={thumbnailAlt} />
                    </div>
                    {bottomLabel && <div className="c-artInfo-labelBottom">{bottomLabel}</div>}
                  </div>
                  <figcaption className="c-card-inBox-meta">
                    <div className="c-card-inBox-meta-titles">
                      <div className="c-card-inBox-meta-titles-title">{this.name(data)}</div>
                    </div>
                    {episodeCount && <div className="c-card-inBox-meta-episode">{episodeCount}エピソード</div>}
                    {deadLine && <div className="c-card-inBox-meta-viewingDeadline">{deadLine}</div>}
                  </figcaption>
                </figure>
              </a>
            );
          })} */}
          {purchased.map((data) => {
            if (data.groupname) {
              const url = data.top_url ? data.top_url : '#'
              const thumbnail = data.thumbnail_url
                ? data.thumbnail_url
                : this.context.models.config.data.default_thumbnail
              const deadLine = data.limit_date
                ? `視聴期限：${data.limit_date.replace(/-/g, '/')}まで`
                : ''

              return (
                <a
                  href={url}
                  key={this.key(data)}
                  className="c-card"
                  target="_blank"
                >
                  <figure className="c-card-inBox clickable">
                    <div className="c-card-inBox-art">
                      <div className="c-card-inBox-art-artwork">
                        <img
                          src={thumbnail}
                          className="c-card-inBox-art-artwork-img"
                          alt={this.name(data)}
                        />
                      </div>
                    </div>
                    <figcaption className="c-card-inBox-meta">
                      <div className="c-card-inBox-meta-titles">
                        <div className="c-card-inBox-meta-titles-title">
                          {this.name(data)}
                        </div>
                      </div>
                      <div
                        // chromaticで画像の変更を検知しないための設定
                        data-chromatic="ignore"
                        className="c-card-inBox-meta-viewingDeadline"
                      >
                        {deadLine}
                      </div>
                    </figcaption>
                  </figure>
                </a>
              )
            } else {
              const { product, course } = data
              const [thumbnailUrl, thumbnailAlt] = this.thumbnail(data)
              const episodeCount = this.episodeCount(data)
              const deadLine = data.limit_date
                ? webApp.utils.deadLine(
                    this.context,
                    data.limit_date,
                    !!data.course
                  )
                : ''
              const bottomLabel = webApp.utils.productLabel(product, course)
              return (
                <a
                  href="#"
                  key={this.key(data)}
                  className="c-card"
                  onClick={(e) => e.preventDefault()}
                >
                  <figure
                    className="c-card-inBox clickable"
                    onClick={(e) => this.onClick(data, e)}
                  >
                    <div className="c-card-inBox-art">
                      <div className="c-card-inBox-art-artwork">
                        <img
                          src={thumbnailUrl}
                          className="c-card-inBox-art-artwork-img"
                          alt={thumbnailAlt}
                        />
                      </div>
                      {bottomLabel && (
                        <div className="c-artInfo-labelBottom">
                          {bottomLabel}
                        </div>
                      )}
                    </div>
                    <figcaption className="c-card-inBox-meta">
                      <div className="c-card-inBox-meta-titles">
                        <div className="c-card-inBox-meta-titles-title">
                          {this.name(data)}
                        </div>
                      </div>
                      {episodeCount && (
                        <div className="c-card-inBox-meta-episode">
                          {episodeCount}エピソード
                        </div>
                      )}
                      {deadLine && (
                        <div
                          // chromaticで画像の変更を検知しないための設定
                          data-chromatic="ignore"
                          className="c-card-inBox-meta-viewingDeadline"
                        >
                          {deadLine}
                        </div>
                      )}
                    </figcaption>
                  </figure>
                </a>
              )
            }
          })}
        </div>
      </div>
    )
  }

  render() {
    // ブラウザ(PC, SP)、WebView(APP)で取得・制御処理は変わらないのでrenderのみ分ける
    return this.isApp ? this.renderAppli() : this.renderBrowser()
  }
}
