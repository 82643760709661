import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Origin from './Origin'
import { mediaQuery } from '../../../../style'
import { darkPink, pink, white } from '../../styles'

const Button1 = ({
  className = '',
  href = '',
  target = '',
  name = '',
  html = '',
  icon = false,
  ...props
}) => {
  return (
    <StyledOrigin
      className={className}
      href={href}
      target={target}
      name={name}
      html={html}
      icon={icon}
      {...props}
    />
  )
}

export default Button1

Button1.propTypes = {
  /** 遷移先 */
  href: PropTypes.string,
  /** aタグのtarget属性 */
  target: PropTypes.string,
  /** 描画される要素(単純な文字列の場合) */
  name: PropTypes.string,
  /** 描画される要素(htmlの場合) */
  html: PropTypes.string,
  /** trueの時アイコンを表示する */
  icon: PropTypes.bool,
  className: PropTypes.string
}

const StyledOrigin = styled(({ icon, ...props }) => <Origin {...props} />)`
  height: 50px;
  background-color: ${pink};
  box-shadow: 0 3px 0 3px ${darkPink};
  color: ${white}!important;
  font-size: 2.6rem;

  &:hover {
    box-shadow: 0 0 0 ${darkPink};
  }

  ${mediaQuery()} {
    font-size: 1.2rem;
  }

  ${({ icon }) => {
    if (icon) {
      return css`
        position: relative;

        &::after {
          margin: auto 0;
          width: 16px;
          height: 18px;
          background: url(/images/exdio/renewal/gariben_daigaku/components/btn1_icon.svg)
            no-repeat center / 100% auto;
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          content: '';

          ${mediaQuery()} {
            width: 12px;
            height: 14px;
            right: 10px;
          }
        }
      `
    }
    return null
  }}
`
