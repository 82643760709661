import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css, createGlobalStyle } from 'styled-components'
import routes from '../../../../apps/common/routes'
import webApp from '../../utils/exdioWebAppUtils'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import match from '../../../../sketch-platform/ui/routing/match'
import HeaderNewsComponent from './HeaderNewsComponent'
import { mediaQuery } from '../style'

/** メダルチャージ 画面 */
const MedalChargeContent = (props, context) => {
  const config = context.models.config.data

  const [url, setUrl] = useState(null)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [pointHeaderHeight, setPointHeaderHeight] = useState(0)

  const setVh = () => {
    const vh = window.innerHeight * 0.01
    document.body.style.setProperty('--vh', `${vh}px`)
  }

  const getHeadersHeight = () => {
    const header = document.getElementById('mp-header')
    const pointHeader = document.getElementById('point-header')

    return {
      headerHeight: header ? header.offsetHeight : 0,
      pointHeaderHeight: pointHeader ? pointHeader.offsetHeight : 0
    }
  }

  const initStyles = () => {
    setVh()

    const {
      headerHeight: _headerHeight = 0,
      pointHeaderHeight: _pointHeaderHeight = 0
    } = getHeadersHeight()
    setHeaderHeight(_headerHeight)
    setPointHeaderHeight(_pointHeaderHeight)
  }

  const onBack = (e) => {
    if (e.data.action === 'onBack') {
      // 遷移元画面から渡されたクエリパラメータを元に遷移先を判定する
      let routeHandler = null
      if (context.routeHandler.query.src) {
        routeHandler = match(
          context.routeHandlers,
          context.routeHandler.query.src
        )
      }
      let route = {}
      let params = {}
      let query = {}
      let hash = {}
      if (routeHandler) {
        route = routeHandler.route
        params = routeHandler.params
        query = routeHandler.query
        hash = routeHandler.hash
      } else {
        route = webApp.utils.isApp(context) ? routes.app_home : routes.home
      }
      if (route === routes.purchase) {
        query.src = routes.medal_charge.makePath()
      }
      const path = route.makePath(params, query, hash)

      context.history.replace(path)
      window.history.pushState(null, null, path)
    }
  }

  const setIframeUrl = () => {
    // この画面は必ず戻るURLが必要
    let cont_url
    const contents_data = context.routeHandler.query.data

    let _url
    const path = config.static_links.medal_charge.replace(
      ':host',
      webApp.utils.infraHost(context)
    )
    const rootUrl = webApp.utils.rootUrl()
    if (contents_data) {
      cont_url = rootUrl + routes.purchase_result.makePath({})
      _url = `${path}?CONT_URL=${encodeURIComponent(
        cont_url
      )}&data=${contents_data}`
    } else {
      // dataがない場合は、マイページからの遷移と判断
      cont_url =
        rootUrl + routes.purchase_result.makePath({}, { src: 'mypage' })
      _url = `${path}?CONT_URL=${encodeURIComponent(cont_url)}`
    }

    return _url
  }

  useEffect(() => {
    setUrl(setIframeUrl())

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(context)

    // GTMの更新
    const title = context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    context.webApp.utils.updateDataLayer(gtmTags)

    // リスナーを追加する
    window.addEventListener('message', onBack)

    /** ヘッダー等の表示待ち */
    setTimeout(initStyles, 4000)
    window.addEventListener('load', initStyles)
    window.addEventListener('resize', initStyles)

    return () => {
      // リスナーを削除する
      window.removeEventListener('message', onBack)

      window.removeEventListener('load', initStyles)
      window.removeEventListener('resize', initStyles)
    }
  }, [])

  return (
    <StyledDiv
      className="common-wrapper"
      headerHeight={headerHeight}
      pointHeaderHeight={pointHeaderHeight}
    >
      <HeaderNewsComponent />
      <SpSubNavigation spOff />
      {url && <StyledIframe src={url} />}
      <GlobalStyle />
    </StyledDiv>
  )
}

export default MedalChargeContent

MedalChargeContent.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  routeHandlers: PropTypes.array,
  webApp: PropTypes.object,
  history: PropTypes.object
}

const GlobalStyle = createGlobalStyle`
  body {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }

  #point-header {
    & + * {
      margin-top: 15px;
    }
  }
`

const StyledDiv = styled.div`
  ${({ headerHeight, pointHeaderHeight }) => {
    const pointHeaderMargin = 30 // #point-headerの上下マージン
    const heightSum = headerHeight + pointHeaderHeight + pointHeaderMargin
    return css`
      height: calc(var(--vh, 1vh) * 100 - ${heightSum}px);
      height: calc(100dvh - ${heightSum}px); // iPhoneSafari
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow: hidden;

      ${mediaQuery()} {
        gap: 0;
      }
    `
  }}
`

const StyledIframe = styled.iframe`
  width: 100%;
  flex: 1 0;
`
