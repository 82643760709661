import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '../../../../../../hooks/useMediaQuery'

/* components */
import List1 from '../List1'
import List3 from '../List3'

/* style */
import { StyledDiv, StyledList2 } from './style'
import { StyledDiv1, StyledDiv2, StyledButton3 } from '../styles'

/** モーダル内コンテンツ (Silk) */
const ModalSilk = ({
  selectionItems = [],
  orderBy = '',
  setOrderBy = () => {},
  setIsModalOpen = () => {},
  onChangeTags = () => {}
}) => {
  const isSp = useMediaQuery()

  const ITEM_PER_PAGES = isSp ? 6 : 8 // 1ページあたりのアイテム数
  const [page, setPage] = useState(1)

  const maxPage = Math.ceil(selectionItems.length / ITEM_PER_PAGES)
  const start = (page - 1) * ITEM_PER_PAGES
  const end = page * ITEM_PER_PAGES

  /** ソートが変更されたとき1ページ目にリセット */
  useEffect(() => {
    setPage(1)
  }, [orderBy])

  return (
    <StyledDiv1>
      <StyledDiv>
        <StyledList2 orderBy={orderBy} setOrderBy={setOrderBy} />
        <List3 page={page} setPage={setPage} maxPage={maxPage} />
      </StyledDiv>
      <StyledDiv2>
        <List1
          orderBy={orderBy}
          selectionItems={selectionItems}
          start={start}
          end={end}
          onChangeTags={onChangeTags}
        />
      </StyledDiv2>
      <StyledButton3 onClick={() => setIsModalOpen(false)} />
    </StyledDiv1>
  )
}

export default ModalSilk

ModalSilk.propTypes = {
  selectionItems: PropTypes.array,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  setIsModalOpen: PropTypes.func,
  onChangeTags: PropTypes.func
}
