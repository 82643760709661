import React, { memo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import window from 'global'
import { size } from 'lodash'
import browserInfo from '../../../../../sketch-platform/utils/browserInfo'
import { PALETTE_SCHEMA_ID } from '../../../../../constants/app'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import PaletteKeywords from '../PaletteKeywords'
import {
  getAttribute,
  getMetasByGenre,
  getHowToPlays,
  setMoreLink,
  getRecommend
} from './functions'

/* components */
import CardSliderController from './CardSliderController'
import Arrow from './Arrow'

const defaultPalette = {
  description: '',
  header: '',
  name: '',
  objects: [],
  palette_id: null,
  publish_end_at: null,
  publish_start_at: '',
  schema_id: null,
  short_name: '',
  type: null
}

/** パレットコンポーネント */
const Palette = (
  {
    palette = defaultPalette,
    howToPlays = {},
    large = false,
    products = null,
    courses = null,
    paletteHeader = true,
    loaded = false,
    ...props
  },
  context
) => {
  const model = context.falcorModel.batch(100)
  const config = context.models.config.data

  const [link, setLink] = useState(null)
  const [showPrev, setShowPrev] = useState(false)
  const [showNext, setShowNext] = useState(false)
  const [recommendItems, setRecommendItems] = useState([])
  const [genre, setGenre] = useState(null)
  const [dsearchMetas, setDsearchMetas] = useState(null)
  const [stateHowToPlays, setStateHowToPlays] = useState(null)
  const sliderContentRef = useRef(null)

  const isDsearch = PALETTE_SCHEMA_ID.GENRE_DSEARCH === palette.schema_id

  const setSliderContentRef = (e) => {
    const deviceInfo = browserInfo(navigator.userAgent, (data) => data)
    if (e) {
      if (deviceInfo.isTablet) {
        e.style.setProperty('overflow-x', 'auto', 'important')
      }
    }
    sliderContentRef.current = e
  }

  /**
   * パレットスクロール
   * ボタンのみの制御で通常のscrollを使用しないため位置座標にて制御
   */
  const scrollPalette = (direction) => {
    if (!sliderContentRef.current) return

    // 位置、サイズ取得
    const scrollAreaWidth = sliderContentRef.current.offsetWidth
    let left = sliderContentRef.current.offsetLeft
    const children = sliderContentRef.current.childNodes
    const totalWidth = Array.from(children)
      .map((child) => window.getComputedStyle(child))
      .flatMap((childStyle) => [
        childStyle.width,
        childStyle.marginLeft,
        childStyle.marginRight
      ])
      .reduce((reduced, value) => {
        return reduced + Number(value.replace('px', ''))
      }, 0)

    // 次の位置算出
    // 左スクロール
    if (direction === -1) {
      // 表示が切れている左端の要素
      let borderChild = null
      for (let i = children.length; i > 0; i -= 1) {
        if (left + children[i - 1].offsetLeft < 0) {
          borderChild = children[i - 1]
          break
        }
      }
      left +=
        scrollAreaWidth -
        (left + borderChild.offsetLeft + borderChild.offsetWidth)
      if (left > 0) left = 0
    }
    // 右スクロール
    else if (direction === 1) {
      // 表示が切れている右端の要素
      let borderChild = null
      for (let i = 0; i < children.length; i += 1) {
        if (
          left + children[i].offsetLeft + children[i].offsetWidth >
          scrollAreaWidth
        ) {
          borderChild = children[i]
          break
        }
      }
      left = -borderChild.offsetLeft
    }

    // 位置設定
    let right = left + totalWidth
    if (left < 0 && right < scrollAreaWidth) {
      left = scrollAreaWidth - totalWidth
      right = left + totalWidth
    }
    sliderContentRef.current.style.left = `${left}px`

    // scrollボタン表示判定
    setShowPrev(left < 0)
    setShowNext(scrollAreaWidth < right)
  }

  useEffect(() => {
    // 複数箇所で参照するのでジャンル属性は先に同期取得
    ;(async () => {
      const resGenre = await getAttribute(model, palette)
      setGenre(resGenre)

      if (isDsearch) {
        const resDsearchMetas = await getMetasByGenre(model, config, palette)
        setDsearchMetas(resDsearchMetas)

        const resHowToPlays = await getHowToPlays(
          model,
          dsearchMetas,
          howToPlays
        )
        setStateHowToPlays(resHowToPlays)
      }

      // もっと見る
      const resLink = setMoreLink(palette, genre)
      setLink(resLink)

      // レコメンド
      const resRecommendItems = await getRecommend(context, palette, () =>
        scrollPalette(0)
      )
      if (size(resRecommendItems)) {
        setRecommendItems(resRecommendItems)
      }

      scrollPalette(0)
    })()
  }, [palette])

  useEffect(() => {
    scrollPalette(0)
  }, [loaded, courses, products])

  const isKeywords = palette.schema_id === PALETTE_SCHEMA_ID.KEYWORDS

  if (isKeywords) {
    return <PaletteKeywords palette={palette} {...props} />
  }

  return (
    <div className="c-cards" {...props}>
      {paletteHeader && (
        <header className="c-cards-head">
          <h3 className="c-cards-head-hedding">
            <span className="c-cards-head-hedding-inner">{palette.name}</span>
          </h3>
          {link && (
            <Link
              route={link.route}
              params={link.params}
              query={link.query}
              className="c-cards-head-hedding-link"
            >
              もっと見る
            </Link>
          )}
        </header>
      )}
      <div className={cn('c-card-slider', { cardLarge: large })}>
        <div className="c-card-slider-cont" ref={setSliderContentRef}>
          <CardSliderController
            loaded={loaded}
            isDsearch={isDsearch}
            dsearchMetas={dsearchMetas}
            recommendItems={recommendItems}
            palette={palette}
            howToPlays={Object.assign({}, howToPlays, stateHowToPlays)}
            courses={courses}
            products={products}
          />
        </div>
        <div className="c-card-slider-nav">
          {showPrev && <Arrow scrollPalette={scrollPalette} />}
          {showNext && <Arrow scrollPalette={scrollPalette} isNext />}
        </div>
      </div>
    </div>
  )
}

export default memo(Palette)

Palette.propTypes = {
  palette: PropTypes.shape({
    name: PropTypes.string.isRequired,
    schema_id: PropTypes.number,
    objects: PropTypes.array.isRequired
  }).isRequired,
  howToPlays: PropTypes.object,
  large: PropTypes.bool,
  products: PropTypes.object,
  courses: PropTypes.object,
  paletteHeader: PropTypes.bool,
  loaded: PropTypes.bool
}

Palette.contextTypes = {
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  routeHandler: PropTypes.object,
  models: PropTypes.object
}
