import styled from 'styled-components'
import { mediaQuery, breakPoints } from '../../../style'
import Link from '../../../../../../sketch-platform/ui/routing/Link'

export const StyledList = styled.ul`
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  ${mediaQuery()} {
    justify-content: center;
    gap: clamp(5px, 4.34%, 15px);
  }
`

export const StyledListItem = styled.li``

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`

export const StyledImg = styled.img`
  margin-bottom: 5px;
  margin-right: 0;
  margin-left: 0;
  display: inline-block;
`
