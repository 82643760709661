import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQuery } from '../../../style'

/** EpisodeLive 見出し */
const Heading = ({ isLatest = false, children = '', ...props }) => {
  return (
    <StyledH2 {...props}>
      {children}
      <span>{isLatest && '（最新話）'}</span>
    </StyledH2>
  )
}

export default Heading

Heading.propTypes = {
  isLatest: PropTypes.bool,
  children: PropTypes.string
}

const StyledH2 = styled.h2`
  font-size: 3.8rem;
  font-weight: 600;
  line-height: 1.2;

  ${mediaQuery()} {
    font-size: 2rem;
  }

  span {
    font-size: 2.5rem;

    ${mediaQuery()} {
      font-size: 1.2rem;
    }
  }
`
