import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import classnames from 'classnames';

import AnotherCard from './AnotherCard';
import LoadingGallery from '../../../loader/LoadingGallery';
import * as browserEvents from '../../../../../utils/browserEvents';
import * as DOMUtils from '../../../../../utils/DOMUtils';
import Footer from '../../../../../common/components/appli/Footer';

const PERPAGE = 999;
var copyrights = []
var copyright

export default class AnotherContent extends React.Component {

  static getPaths = function(models, options, props) {
    const indexes = function(models, options, props) {
      return { from:props && props.fromNum || 0, to: (props && props.toNum ? props.toNum : options ? options.numTitles + (props.fromNum || 0) : PERPAGE - 1)};
    }
    const rootPath = this.getRootPath(models, options, props);
    const paths = AnotherCard.getPaths().map(function(path) {
      return rootPath.concat(indexes(models, options, props)).concat(path);
    });
    const lengthPath = rootPath.concat(['length']);
    return (props && props.fromNum || 0) === 0 ? paths.concat([lengthPath]) : paths;
  };

  static getRootPath = function(models, options, props) {
    const rootPath = [];
    if (props.keyPrefix) rootPath.push(props.keyPrefix);
    if (!!props.id) rootPath.push(props.id);
    if (!!props.term) {
      rootPath.push('byTerm');
      rootPath.push(props.term);
    }
    if (props.sortOrder) rootPath.push(props.sortOrder);
    if (props.videoRoot) rootPath.push(props.videoRoot);
    return rootPath;
  };

  static propTypes = {
    filter: PropTypes.func,
    mainId: PropTypes.number,
    id: PropTypes.string,
    isPrefetchingPaths: PropTypes.bool,
    keyPrefix: PropTypes.string,
    model: PropTypes.object,
    query: PropTypes.array,
    sortOrder: PropTypes.string,
    uiView: PropTypes.string,
    enablePushOut: PropTypes.bool,
    classnames: PropTypes.object
  };

  static contextTypes = {
    models: PropTypes.object,
    columnsInRow: PropTypes.number,
    currentPage: PropTypes.string,
    isInitialRender: PropTypes.bool,
    isRtl: PropTypes.bool,
    isTallRow: PropTypes.bool,
    loggingApp: PropTypes.object,
    profileManager: PropTypes.string,
    routeHandler: PropTypes.object,
    popType: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.onTouchmove = this.onTouchmove.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.DIOresumeInfo = this.getResumeStorage.bind(this);

    if (!props.children) {
      const rootPath = this.constructor.getRootPath(context.models, null, props);
      this.totalCount = props.model.getSync(rootPath.concat('length'));
      this.isFirstSetOfResults = true;
      this.numTitlesToFetch = PERPAGE;

      this.state = {
        dispose: null,
        fetchDataError: null,
        generation: props.model.getVersion(rootPath),
        key: parseInt(props.id, 10),
      };
    } else {
      this.totalCount = props.totalItems;
      this.state = {};
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.children) {
      this.fetchData(this.props);
      browserEvents.addEventListener('touchmove', this.onTouchmove);
      browserEvents.addEventListener('scroll', this.onScroll);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children) {
      return;
    }

    if (nextProps.sortOrder !== this.props.sortOrder ||
        nextProps.id !== this.props.id ||
        nextProps.term !== this.props.term ||
        nextProps.keyPrefix !== this.props.keyPrefix) {
      this.isFirstSetOfResults = true;
      this.numTitlesToFetch = PERPAGE;
      this.totalCount = -1;
      this.items = [];
      this.setState({fetchDataError: null, generation: -1});
      this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
    if (!this.props.children) {
      browserEvents.removeEventListener('touchmove', this.onTouchmove);
      browserEvents.removeEventListener('scroll', this.onScroll);
    }
  }

  fetchMoreRows() {
    if (this.props.children) {
      return;
    }

    if (this.items && this.numTitlesToFetch > this.items.length && !this.state.fetchingMoreRows) {
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props)
    }
  }

  isLoading() {
    return !!this.state.dispose || this.props.isPrefetchingPaths;
  }
  
  fetchData(props) {
    if (this.props.children) return;
    const pathProps = Object.assign({}, props, {toNum: this.numTitlesToFetch - 1});
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;
    // 事前に通信している場合、その情報は全て削除してしまったほうが良い
    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, {dispose});
    } else {
      this.setState({dispose});
    }
    let program_link = this.props.program_link;
    evaluator.then((result) => {
      if (result) {
        let length = 0;
        let data = _.get(result.json, this.constructor.getRootPath(this.context.models, {}, pathProps));
        if (program_link == true){
          let delete_list = [];
          for(var key in data) {
            if (!data[key]) continue;
            if (key == "length") continue;
            if (data[key] && data[key].values && data[key].values.ex_templateId == 2) {
              if (delete_list[data[key].values.parents_season.avails_SeasonAltID]) {
                length--;
                delete data[key];
              } else {
                delete_list[data[key].values.parents_season.avails_SeasonAltID] = true;
              }
            }
          }
        }
        this.items = _.clone(_.values(_.omitBy(data, (val, key) => (key.indexOf('$') === 0 || key === 'length'))));
        data.length = data.length ? data.length - length : this.items.length;
        
        copyrights.push('(C)テレビ朝日')
        for(var i in this.items){
          if (this.items[i].values && this.items[i].values.evis_Copyright){
            copyrights.push(this.items[i].values.evis_Copyright)
          }
        }

        const m_copyrights = copyrights.filter(function (x, i, self) {
          return self.indexOf(x) === i;
        });

        copyright = m_copyrights.join('　');
        
        if (data.hasOwnProperty('length')) {
          this.totalCount = data.length;
        }
      } else {
        this.totalCount = this.items.length;
      }
      this.numTitlesToFetch += PERPAGE;
      if (this.numTitlesToFetch > this.totalCount) this.numTitlesToFetch = this.totalCount;
      delete this.state[JSON.stringify(paths)];
      this.isFirstSetOfResults = false;
      const newState = {
        fetchDataError: null,
        dispose: null,
        fetchingMoreRows: undefined,
        generation: this.state.generation + 1
      };
      if (this._isMounted) this.setState(newState);
      else Object.assign(this.state, newState);
    }).catch((e) => {
      const newState = {
        fetchDataError: e,
        fetchingMoreRows: undefined,
        dispose: null
      };
      delete this.state[JSON.stringify(paths)];
      if (this._isMounted) this.setState(newState);
      else this.state = newState;
    });
  }

  getResumeStorage() {
    try {
      return JSON.parse( window.localStorage.getItem('DIOresumeInfo') )
    } catch(e) {
      console.log(e)
      return []
    }
  }

  setProgress(cardId) {
    let progress = 0;
    _.forEach(this.DIOresumeInfo(), item => {
      if(item.metaid === cardId){
        progress = Math.ceil(item.lastPlayed / item.duration * 100);
        return false;
      }
    });

    return progress;
  }

  render() {
    let cards, program_link = this.props.program_link;
    let sortFlags = _.map(this.context.models.config.data.genre_sort_data, s => s.flag !== 'other' ? s.flag : null);
    let sortSelected = sortFlags.indexOf(this.props.sortFlag) === -1 ? 'other' : this.props.sortFlag ; 
    let sortItems;
    if(sortSelected === 'other'){
      sortItems = sortSelected === null ? this.items : _.filter(this.items || [], (itemData, index) => {
        const flg = itemData.values.parents_season.evis_Genre || itemData.values.parents_series.evis_Genre
        return sortFlags.indexOf(flg) === -1;
      });
    } else {
      sortItems = sortSelected === null ? this.items : _.filter(this.items || [], (itemData, index) => {
        const flg = itemData.values.parents_season.evis_Genre || itemData.values.parents_series.evis_Genre
        return flg.match(sortSelected) !== null;
      });
    }
    let sortResults;
    if(sortItems.length <= 1){ // 単話リストがメインと同じものしかなかった場合
      sortResults = [];
      _.forEach(this.items, (itemData, index) => {
        if(index >= 6) return false ;
        sortResults[index] = itemData
      });
    } else {
      sortResults = sortItems;
    }
    cards = _.map(sortResults || [], (itemData, index) => {
      let link = (program_link == true && itemData.values.ex_templateId == 2) ? true : false;
      // 番組リスト化以外のメインと同等のものは表示させない
      if(itemData.id == this.props.mainId && link == false){
        return false;
      }
      return (
        <AnotherCard
          mainId={this.props.mainId}
          popType={this.context.popType}
          model={this.props.model}
          key={`another_${itemData.id}_${index}`}
          name={itemData.name}
          thumbnailUrl={itemData.thumbnailUrl + '_medium'}
          rowNum={0}
          index={index}
          seasonId={itemData.values.parents_season.id}
          program_link={link}
          titleId={itemData.id}
          progress={this.setProgress(itemData.id)}
          subtitle={itemData.values.subtitle}
        />
      );
    });
    this.itemSize = cards.length;

    const size = cards.length;
    const chunckSize = this.props.itemsInRow || this.context.columnsInRow; // いくつずつに分割するか
    const cardChuncks = []; // 新しく作る配列

    for (let i = 0; i < Math.ceil(size / chunckSize); i++) {
      const j = i * chunckSize;
      const chunckedTitles = cards.slice(j, j + chunckSize);
      cardChuncks.push(chunckedTitles);
    }
    let content;

    if (this.isLoading() && this.isFirstSetOfResults && cardChuncks.length === 0) {
      content = (
        <LoadingGallery pulsateTitles={true} />
      );
    } else if (cards.length > 0) {
      content = (
        <div id="app_avod_ep_li">
          <div className="avod_ep_box clearfix">
            <h3>あなたにおすすめ</h3>
            {cards}
          </div>
        </div>
      );
    } else if (this.totalCount == 0) {
      if (this.props.noResults) {
        content = this.props.noResults;
      } else {
        content = (
          <div>
            <div className="galleryMessage">一致するタイトルは見つかりませんでした。</div>
          </div>
        );
      }
    }

    return (
      <div ref="another" className={classnames("another", "row-with-x-columns", this.props.classes)}>
        <CSSTransitionGroup
          component="div"
          className="anotherContent"
          transitionEnterTimeout={250}
          transitionLeaveTimeout={550}
          transitionName="pulsateTransition">
          {content}
        </CSSTransitionGroup>
        <Footer
        copyright = {copyright}
        />
      </div>
    );
  }


  onTouchmove(e) {
    if (this && this.isWithinDistanceBuffer(300)) {
      this.fetchMoreRows(true);
    }
  }

  onScroll(e) {
    if (this && this.isWithinDistanceBuffer(300)) {
      this.fetchMoreRows(true);
    }
  }

  isWithinDistanceBuffer(distanceBuffer){
    if (!this.refs.another) return;
    const el = ReactDOM.findDOMNode(this.refs.another);
    if (el) {
      return DOMUtils.getDistanceToBottomOfElement(el) < distanceBuffer;
    }
    return false;
  }
}
