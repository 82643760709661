import styled from 'styled-components'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['hover'].includes(prop)
})`
  margin: auto;
  height: 24px;
  opacity: ${({ hover }) => (hover ? '1' : '0')};
  position: absolute;
  top: 0;
  bottom: 0;
  color: #1f9064;
  font-size: 2.4rem;
  transition: opacity 0.2s ease 0s;

  :visited,
  :link {
    color: #1f9064;
  }
`

export const StyledLink1 = styled(StyledLink)`
  left: -40px;
  &::before {
    font-size: 4rem;
    font-family: 'Material Icons';
    content: '\\e314';
  }
`

export const StyledLink2 = styled(StyledLink)`
  right: -40px;
  &::before {
    font-size: 4rem;
    font-family: 'Material Icons';
    content: '\\e315';
  }
`
