import React from 'react'
import PropTypes from 'prop-types'

import { StyledH2, StyledImg } from './style'

const Heading1 = ({
  className = '',
  src = '',
  alt = '',
  width = '',
  height = '',
  children = ''
}) => {
  return (
    <StyledH2 className={`${className} lazyload`}>
      <StyledImg
        src={src}
        alt={alt}
        width={width}
        height={height}
        loading="lazy"
      />
      {children}
    </StyledH2>
  )
}

export default Heading1

Heading1.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}
