import { createRoute } from '../../sketch-platform/ui/routing'

const routes = {
  // ホーム
  home: createRoute({ path: '/' }),
  // ログイン
  login: createRoute({ path: '/login' }),
  // 見逃し無料
  catchup: createRoute({ path: '/catchup' }),
  // 番組 別バージョン(検索高機能等)
  program_other_version: createRoute({
    path: '/program/:seriesId-:seasonId-:version'
  }),
  // 番組
  program: createRoute({ path: '/program/:seriesId-:seasonId' }),
  // 単話
  episode: createRoute({ path: '/program/:seriesId-:seasonId/:episodeId' }),
  catchupEpisode: createRoute({
    path: '/catchup/:seriesId-:seasonId/:episodeId'
  }),
  // パック
  pack: createRoute({ path: '/pack/:productId' }),
  // 月額見放題
  plan: createRoute({ path: '/plan/:slug' }),
  // ジャンル
  genre: createRoute({ path: '/genre/:genre' }),
  // ジャンル検索
  genreSearch: createRoute({ path: '/genre/:genreKey/:idOrGenreSearchType' }),
  // テレ朝動画のオススメ
  recommend: createRoute({ path: '/recommend' }),
  // あなたへのオススメ
  recommendYou: createRoute({ path: '/recommend_you/:paletteId' }),
  // 検索
  search: createRoute({ path: '/search' }),
  // マイページ
  mypage: createRoute({ path: '/mypage/list' }),
  // マイページ(通帳・ご利用履歴)
  mypage_wallet: createRoute({ path: '/mypage/wallet' }),
  // マイページ(契約中ライセンス)
  mypage_view_license: createRoute({ path: '/view_license' }),
  // マイページ(契約中ライセンス解約完了)
  mypage_cancel_license: createRoute({ path: '/cancel_license' }),
  // マイページ(アカウント情報)
  mypage_account_info: createRoute({ path: '/mypage/member' }),
  // マイページ(アカウント設定)
  mypage_account_setting: createRoute({ path: '/mypage/account' }),
  // マイページ(アカウント設定 基盤連携)
  mypage_member_setting: createRoute({ path: '/member/setting' }),
  // マイページ(アカウント設定 メールアドレス変更メール受信 基盤連携)
  mypage_update_email_result: createRoute({
    path: '/member/setting/email_result'
  }),
  // マイページ(お支払い方法)
  mypage_update_credit: createRoute({ path: '/member/update/credit' }),
  // マイページ アイコン変更
  edit_icon: createRoute({ path: '/icon' }),
  // 購入
  purchase: createRoute({ path: '/purchase' }),
  // 月額見放題購入
  purchase_license: createRoute({ path: '/purchase_plan' }),
  // 購入完了 月額見放題・メダル共通
  purchase_result: createRoute({ path: '/purchase_result' }),
  // メダルチャージ
  medal_charge: createRoute({ path: '/payment_plan' }),
  // ニュース
  information: createRoute({ path: '/information' }),
  newarrival: createRoute({ path: '/newarrival' }),
  // 特集ページ
  feature: createRoute({ path: '/feature' }),
  // 静的ページ
  static: createRoute({ path: '/feature/:url' }),
  // email登録(基盤側連携親フレーム)
  regist_email: createRoute({ path: '/regist_email' }),
  // 会員情報登録(基盤側連携親フレーム)
  regist_member: createRoute({ path: '/regist_member' }),
  // パスワードリマインダ(基盤側連携親フレーム)
  remake_password: createRoute({ path: '/remake_password' }),
  // パスワードリマインダ登録(基盤側連携親フレーム)
  remake_set_password: createRoute({ path: '/remake_setpassword' }),
  //プライバシーポリシー
  privacy: createRoute({ path: '/help/privacy' }),
  // logirl LIVE一覧
  logirl_live: createRoute({ path: '/logirl/live' }),
  // logirl 静的ページ
  logirl_static: createRoute({ path: '/logirl/:url' }),
  // logirl ラジオ番組ページ
  logirl_program_radio: createRoute({ path: '/logirl/radio/:url' }),
  // 私立ガリベン大学 タグ一覧ページ
  gariben_tag_list: createRoute({ path: '/gariben_daigaku/tag_list' }),
  // redirect
  redirect: createRoute({ path: '/rd' }),
  // WebMoney戻りページ
  wmquick_return: createRoute({ path: '/wmquick_return' }),
  // キャリア決済購入キャンセル
  purchase_cancel: createRoute({ path: '/purchase_cancel' }),
  // プレゼントページ (PENTAGON プレゼントキャンペーン)
  present_static: createRoute({ path: '/pentagon_present' }),
  // for kidsページ
  kids: createRoute({ path: '/kids' }),
  // キャンペーンページテンプレート
  campaign_static: createRoute({ path: '/campaign/:url' }),
  //infoページ
  info: createRoute({ path: '/info' }),
  //info配下ページ
  info_static: createRoute({ path: '/info/:url' }),
  // アプリSNSログイン受けページ
  android_sns_login: createRoute({ path: '/android_sns_login' }),
  // 必殺LP
  hissatsu: createRoute({ path: '/hissatsu' }),
  // ガリ大ABEMAライブ配信用会員限定画面
  gariben_anime_matsuri: createRoute({ path: '/gariben_daigaku/anime_matsuri2024' }),
  // ガリ大ABEMAライブ配信用エラー画面
  gariben_anime_matsuri_error: createRoute({ path: '/gariben_daigaku/anime_matsuri2024/error' }),
  // アプリ
  // ホーム
  app_home: createRoute({ path: '/app' }),
  // ログイン
  app_login: createRoute({ path: '/app/login' }),
  // 見逃し無料
  app_catchup: createRoute({ path: '/app/catchup' }),
  // 番組 別バージョン(検索高機能等)
  app_program_other_version: createRoute({
    path: '/app/program/:seriesId-:seasonId-:version'
  }),
  // 番組
  app_program: createRoute({ path: '/app/program/:seriesId-:seasonId' }),
  // 単話
  app_episode: createRoute({
    path: '/app/program/:seriesId-:seasonId/:episodeId'
  }),
  app_catchupEpisode: createRoute({
    path: '/app/catchup/:seriesId-:seasonId/:episodeId'
  }),
  // パック
  app_pack: createRoute({ path: '/app/pack/:productId' }),
  // 月額見放題
  app_plan: createRoute({ path: '/app/plan/:slug' }),
  // テレ朝動画のオススメ
  app_recommend: createRoute({ path: '/app/recommend' }),
  // 検索
  app_search: createRoute({ path: '/app/search' }),
  // マイページ
  app_mypage: createRoute({ path: '/app/mypage/list' }),
  // マイページ(通帳・ご利用履歴)
  app_mypage_wallet: createRoute({ path: '/app/mypage/wallet' }),
  // マイページ(契約中ライセンス)
  app_mypage_view_license: createRoute({ path: '/app/view_license' }),
  // マイページ(契約中ライセンス解約完了)
  app_mypage_cancel_license: createRoute({ path: '/app/cancel_license' }),
  // マイページ(アカウント情報)
  app_mypage_account_info: createRoute({ path: '/app/mypage/member' }),
  // マイページ(アカウント設定)
  app_mypage_account_setting: createRoute({ path: '/app/mypage/account' }),
  // マイページ(アカウント設定 基盤連携)
  app_mypage_member_setting: createRoute({ path: '/app/member/setting' }),
  // マイページ(お支払い方法)
  app_mypage_update_credit: createRoute({ path: '/app/member/update/credit' }),
  // マイページ アイコン変更
  app_edit_icon: createRoute({ path: '/app/icon' }),
  // ニュース
  app_information: createRoute({ path: '/app/information' }),
  app_newarrival: createRoute({ path: '/app/newarrival' }),
  // email登録(基盤側連携親フレーム)
  app_regist_email: createRoute({ path: '/app/regist_email' }),
  // パスワードリマインダ(基盤側連携親フレーム)
  app_remake_password: createRoute({ path: '/app/remake_password' }),

  // Sample Component (開発のみ)
  sample: createRoute({ path: '/sample' })
}

export function getHomeRoute(_models) {
  return routes.home
}
export function getAppHomeRoute(_models) {
  return routes.app
}

export default routes
