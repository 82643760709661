import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { EPISODE_DISPLAY_MODE } from '../../../../../../constants/app'
import webApp from '../../../../utils/exdioWebAppUtils'
import { getPrice, gotoPurchasePage } from '../functions'
import { fixColors } from '../../../style'

/** 視聴or購入導線ボタン */
const PlayLink = (
  {
    seasonId = '',
    episodeId = '',
    meta = {},
    status = {},
    product = {},
    course = {},
    onClickPlay = () => {},
    forceDisabled = false,
    ...props
  },
  context
) => {
  const {
    isPurchased,
    isNotPurchased,
    isGeoDeliverable,
    isDeviceNotAvailable,
    isPurchseAvailable: isPurchaseAvailable, // 誤字なので置き換え
    displayMode
  } = status

  const {
    FREE,
    TVOD_FREE,
    TVOD_NOT_FREE,
    SVOD_FREE,
    SVOD_NOT_FREE,
    STVOD_FREE,
    STVOD_TVOD_NOT_FREE,
    STVOD_SVOD_NOT_FREE,
    UNKNOWN
  } = EPISODE_DISPLAY_MODE

  // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])

  if (withValidPreviewToken) return null

  /** 海外判定 */
  if (isGeoDeliverable === false) {
    return (
      <StyledButton disabled {...props}>
        ご利用の地域では視聴できません
      </StyledButton>
    )
  }
  if (isDeviceNotAvailable === true) {
    return (
      <StyledButton disabled {...props}>
        ご利用の端末では視聴できません
      </StyledButton>
    )
  }

  /** 価格 */
  const [priceOfEpisode, priceOfCourse] = getPrice(
    meta,
    status,
    product,
    course
  )

  /** 通常テキスト */
  const defaultText = (() => {
    if (isPurchased) {
      // ret = '視聴する';
      return null
    }

    switch (displayMode) {
      // 無料
      case FREE:
      case TVOD_FREE:
      case SVOD_FREE:
      case STVOD_FREE:
        return (
          <>
            <StyledSpanFree>無料</StyledSpanFree>で視聴
          </>
        )

      // TVOD
      case TVOD_NOT_FREE:
      case STVOD_TVOD_NOT_FREE:
        if (isNotPurchased) {
          if (!isPurchaseAvailable) {
            // データの準備ができていない
            return '現在購入いただけません'
          }
          if (product && !webApp.utils.isOnSale(context, product)) {
            // 商品が販売期間外
            return '現在購入いただけません'
          }
          return (
            <>
              <StyledSpanCoin>{priceOfEpisode}</StyledSpanCoin>
              で視聴
            </>
          )
        }
        return null

      // SVOD
      case SVOD_NOT_FREE:
      case STVOD_SVOD_NOT_FREE:
        if (isNotPurchased) {
          if (!isPurchaseAvailable) {
            // データの準備ができていない
            return '現在購入いただけません'
          }
          return (
            <>
              月額
              <StyledSpanYen>{priceOfCourse}</StyledSpanYen>
              円で視聴
            </>
          )
        }
        return null

      // 条件外
      case UNKNOWN:
      default:
        return null
    }
  })()

  /** STVOD用 月額購入導線テキスト */
  const svodText = (() => {
    if (displayMode === STVOD_TVOD_NOT_FREE) {
      // 前出のret設定ロジックと同等
      // 購入済みを除外
      if (isPurchased) return null
      if (!isNotPurchased) return null

      // 購入できない場合を除外
      if (!isPurchaseAvailable) return null

      // 商品の購入期間外の場合を除外
      if (product && !webApp.utils.isOnSale(context, product)) return null

      return (
        <>
          月額
          <StyledSpanYen>{priceOfCourse}</StyledSpanYen>
          円で視聴
        </>
      )
    }

    return null
  })()

  // trueの場合、黒背景で非活性になる
  const wDisabled = (() => {
    // 視聴済みの場合は除外
    if (isPurchased) return false
    if (!isNotPurchased) return false

    switch (displayMode) {
      // TVOD
      case TVOD_NOT_FREE:
      case STVOD_TVOD_NOT_FREE:
        // データの準備ができていない
        if (!isPurchaseAvailable) return true
        // 商品が販売期間外
        return product && !webApp.utils.isOnSale(context, product)

      // SVOD
      case SVOD_NOT_FREE:
      case STVOD_SVOD_NOT_FREE:
        // データの準備ができていない
        return !isPurchaseAvailable

      // 無料 or 条件外
      case FREE:
      case TVOD_FREE:
      case SVOD_FREE:
      case STVOD_FREE:
      case UNKNOWN:
      default:
        return false
    }
  })()

  return (
    <>
      {defaultText && (
        <StyledButton
          type="button"
          disabled={isGeoDeliverable === false || isDeviceNotAvailable === true}
          onClick={
            forceDisabled || wDisabled
              ? (e) => e.preventDefault()
              : () => gotoPurchasePage(context, episodeId, seasonId)
          }
          {...props}
        >
          {defaultText}
        </StyledButton>
      )}

      {svodText && (
        <StyledButton
          type="button"
          onClick={
            forceDisabled || wDisabled
              ? (e) => e.preventDefault()
              : () => onClickPlay(true)
          }
          {...props}
        >
          {svodText}
        </StyledButton>
      )}
    </>
  )
}

export default PlayLink

PlayLink.propTypes = {
  seasonId: PropTypes.string,
  episodeId: PropTypes.string,
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool,
    isPurchseAvailable: PropTypes.bool,
    displayMode: PropTypes.number
  }),
  product: PropTypes.shape({
    product_id: PropTypes.number,
    name: PropTypes.string,
    original_price: PropTypes.number,
    active_pricing: PropTypes.shape({
      price: PropTypes.number,
      unit: PropTypes.string
    })
  }),
  // 月額見放題
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),
  onClickPlay: PropTypes.func,
  /** 強制非活性 */
  forceDisabled: PropTypes.bool
}

PlayLink.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

const StyledButton = styled.button`
  padding: 14px 24px;
  width: 100%;
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d2d2d;
  border-radius: 5px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  transition: opacity, 0.4s;

  &:link,
  &:visited {
    color: #fff;
  }

  &:disabled {
    opacity: 0.7;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`

const StyledSpanFree = styled.span`
  margin-right: 0.2em;
  padding: 3px 5px;
  background-color: ${fixColors.colorKey};
  border-radius: 3px;
  font-size: 1.2rem;
`

const StyledSpanCoin = styled.span`
  font-size: 2rem;

  &::before {
    margin-right: 0.2em;
    vertical-align: middle;
    content: url(/images/exdio/renewal/icon_coin.svg);
  }
`

const StyledSpanYen = styled.span`
  font-weight: 600;
  font-size: 2.4rem;
  vertical-align: bottom;
  line-height: 1;
`
