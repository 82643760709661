import styled from 'styled-components'
import CommonVideoArea from '../../EpisodeGariben/CommonVideoArea'
import { StyledDiv2 as PlayButtonDiv2 } from '../../EpisodeDefault/PlayButton'

export const StyledDiv = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  touch-action: none;
`

export const StyledCommonVideoArea = styled(CommonVideoArea)`
  ${PlayButtonDiv2} {
    background-image: url(/images/exdio/renewal/icon_play_btn_for_video_area_pc.svg);
    background-size: 60px;
    text-shadow: #fff 1px 1px 0px, #fff -1px 1px 0px, #fff 1px -1px 0px,
      #fff -1px -1px 0px;
    filter: none;
  }
`
