import React from 'react'

/* style */
import { StyledUl, StyledLi, StyledLink } from './style'

/** Banners Component */
const Banners = ({ ...props }) => {
  return (
    <StyledUl {...props}>
      <StyledLi>
        <StyledLink
          href="https://www.tv-asahi.co.jp/apps/apply/jump.php?fid=9147"
          target="_blank"
          className="snippet__banner2"
        >
          <img
            src="/images/exdio/renewal/gariben_daigaku/live/bnr_02.png"
            alt=""
          />
        </StyledLink>
      </StyledLi>
      <StyledLi>
        <StyledLink
          href="https://www.tv-asahi.co.jp/douga_mv/gariben_daigaku/live_present/?utm_source=present&utm_medium=bt&utm_id=garilive"
          target="_blank"
        >
          <img
            src="/images/exdio/renewal/gariben_daigaku/live/bnr_03.png"
            alt=""
          />
        </StyledLink>
      </StyledLi>
    </StyledUl>
  )
}

export default Banners

Banners.propTypes = {}
