import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Card from './Card'

/* style */
import { StyledDivInner } from '../../style'
import styled from 'styled-components'
import { mediaQuery, pxToVw } from '../../../../../../exdio/components/style'

/** 必殺仕事人 LP | 視聴方法 */
const HowToWatch = ({ howToWatchItems = [] }) => {
  return (
    <StyledSection id="link3">
      <StyledDivInner>
        <StyledH2>
          <img
            src="/images/exdio/renewal/hissatsu/howtowatch_title.png"
            alt="視聴方法"
          />
        </StyledH2>
        <StyledP>
          過去の必殺シリーズが
          <br />
          テレ朝動画にて配信中！
        </StyledP>
        <StyledDiv>
          {howToWatchItems.map((howToWatchItem, i) => (
            <Card howToWatchItem={howToWatchItem} i={i} key={i} />
          ))}
        </StyledDiv>
      </StyledDivInner>
    </StyledSection>
  )
}

export default memo(HowToWatch)

HowToWatch.propTypes = {
  /**
   * 視聴方法の要素の配列
   * @param {Object[]} howToWatchItems - 視聴方法の要素
   * @param {string} howToWatchItems.src - 画像のURL
   * @param {string} howToWatchItems.text - 視聴方法のテキスト説明
   * @param {string} [howToWatchItems.supplement] - 追加情報
   */
  howToWatchItems: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      text: PropTypes.string,
      supplement: PropTypes.string
    })
  )
}

const StyledSection = styled.section`
  padding: 80px 0;
  background: url(/images/exdio/renewal/hissatsu/howtowatch_bg.png) #740000
    no-repeat bottom center / cover;

  ${mediaQuery(768)} {
    padding: 30px 0;
  }
`

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  gap: 40px;
  margin-top: 40px;

  ${mediaQuery(768)} {
    flex-direction: column;
  }
`

const StyledH2 = styled.h2`
  display: block;
  margin: 0 auto;
  text-align: center;

  ${mediaQuery(768)} {
    img {
      width: min(${pxToVw(267, 750)}, 267px);
    }
  }
`

const StyledP = styled.p`
  margin-top: 30px;
  font-size: 3.2rem;
  text-align: center;
  color: #fff;

  br {
    display: none;
  }

  ${mediaQuery(768)} {
    font-size: min(${pxToVw(44, 750)}, 44px);
    line-height: 1.3;

    br {
      display: block;
    }
  }
`
