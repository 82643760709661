import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

/** 放送日 */
export default class OnAirDate extends Component {
  static propTypes = {
    /**
     動画メタ情報<br>
      <span class="css-ks2jcn">object</span><br>
      <br>
      <table>
        <thead>
          <tr>
              <th>meta > values</th>
          </tr>
        </thead>
        <tbody>
          <tr>
              <td>avails_ReleaseHistoryOriginal<span class="css-1ywjlcj">*</span>: 動画リリース日<br>
              <span class="css-ks2jcn">Date</span></td>
          </tr>
        </tbody>
      </table>
     */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    /** ライブ配信かそれ以外か */
    isLive: PropTypes.bool
  }

  static defaultProps = {
    isLive: false
  }

  static contextTypes = {
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {}

    this.getOnAirDate = this.getOnAirDate.bind(this)
  }

  componentDidMount() {}

  /** 放送日 */
  getOnAirDate () {
    const { meta, isLive } = this.props
    if (!meta) return null
    if (isLive) return ''
    const onAirDate = _.get(meta, ['values', 'avails_ReleaseHistoryOriginal'])
    return onAirDate ? moment(onAirDate).format('放送日：YYYY年M月D日') : ''
  }

  render() {
    const onAirDate = this.getOnAirDate()
    return onAirDate ? <div className="c-storyMeta-inBox-meta-onAir">{onAirDate}</div> : null
  }
}
