import styled from 'styled-components'
import { mediaQuery } from '../../../style'

export const StyledWrapper = styled.div`
  margin: 20px auto 0;
  max-width: 1080px;
  display: block;

  ${mediaQuery()} {
    margin: 20px 10px 0;
  }

  &[href] {
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }
`

export const StyledDl = styled.dl`
  width: 100%;
  display: flex;
`

export const StyledDt = styled.dt`
  padding: 15px;
  background-color: #f33699;
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  color: #fff;
  ${mediaQuery()} {
    padding: 10px;
    font-size: 1.2rem;
  }
`

export const StyledDd = styled.dd`
  padding: 15px;
  background-color: #f336991a;
  flex: 1 0;
  border-radius: 0 5px 5px 0;
  line-height: 1.25;
  ${mediaQuery()} {
    padding: 10px;
    font-size: 1.2rem;
    line-height: 18px;
  }
`
