import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledListItem } from './style'

const Facebook = ({ isApp = false, linkUrl = '', ...props }) => {
  const windowOpenOption = [
    'FBwindow',
    'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes'
  ]

  return (
    <StyledListItem
      src={'/images/exdio/renewal/share_btn_facebook.svg'}
      alt={'Facebook'}
      isApp={isApp}
      linkUrl={linkUrl}
      windowOpenOption={windowOpenOption}
      {...props}
    />
  )
}

export default Facebook

Facebook.prototype = {
  isApp: PropTypes.bool,
  linkUrl: PropTypes.string
}
