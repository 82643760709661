import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { PANEL_ID } from '../config'

/** images */
import TextAll from './TextAll'
import TextSelection from './TextSelection'
import TextAge from './TextAge'
import TextCharacter from './TextCharacter'
import TextTvSpecial from './TextTvSpecial'
import TextShinMen from './TextShinMen'

/** SVGテキスト */
const NavText = ({ id, ...props }) => {
  switch (id) {
    case PANEL_ID.ALL:
      return <TextAll {...props} />
    case PANEL_ID.SELECTION:
      return <TextSelection {...props} />
    case PANEL_ID.AGE:
      return <TextAge {...props} />
    case PANEL_ID.CHARACTER:
      return <TextCharacter {...props} />
    case PANEL_ID.TV_SPECIAL:
      return <TextTvSpecial {...props} />
    case PANEL_ID.SHIN_MEN:
      return <TextShinMen {...props} />
    default:
      return null
  }
}

export default memo(NavText)

NavText.propTypes = {
  id: PropTypes.oneOf(Object.values(PANEL_ID))
}

NavText.defaultProps = {
  id: ''
}
