import React, { memo } from 'react'
import PropTypes from 'prop-types'

/* style */
import styled from 'styled-components'
import { mediaQuery, pxToVw } from '../../../../../../../exdio/components/style'

/** 必殺仕事人 LP | 視聴方法カード */
const Card = ({ howToWatchItem = {}, i = 0 }) => {
  return (
    <StyledSection>
      <StyledH3>
        STEP<StyledSpan1>{i + 1}</StyledSpan1>
      </StyledH3>
      <StyledImg src={howToWatchItem.src} alt={howToWatchItem.text} />
      <StyledP>
        {howToWatchItem.text}
        {howToWatchItem.supplement && (
          <StyledSpan2>{howToWatchItem.supplement}</StyledSpan2>
        )}
      </StyledP>
    </StyledSection>
  )
}

export default memo(Card)

Card.propTypes = {
  /**
   * 「必殺仕事人」の視聴方法カードコンポーネント
   *
   * @param {Object} howToWatchItem - 視聴方法の詳細情報
   * @param {string} howToWatchItem.src - 画像のURL
   * @param {string} howToWatchItem.text - 視聴方法の説明テキスト
   * @param {string} [howToWatchItem.supplement] - 視聴方法に関する追加情報（オプショナル）
   */
  howToWatchItem: PropTypes.shape({
    src: PropTypes.string,
    text: PropTypes.string,
    supplement: PropTypes.string
  }),
  /** @param {number} i - 配列のインデックスや一意のキーとして使用する番号 */
  i: PropTypes.number
}

const StyledSection = styled.section`
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  font-weight: 900;
  text-align: center;

  ${mediaQuery(768)} {
    display: flex;
    gap: ${pxToVw(20, 750)};
    align-items: center;
    padding: 15px;
    text-align: left;
  }
`
const StyledImg = styled.img`
  margin-top: 25px;
  ${mediaQuery(768)} {
    margin-top: 0;
    width: calc(180 / 690 * 100%);
    height: auto;
  }
`

const StyledH3 = styled.h3`
  font-size: 3.2rem;

  ${mediaQuery(768)} {
    font-size: ${pxToVw(38, 750)};
  }
`

const StyledSpan1 = styled.span`
  font-size: 7rem;

  ${mediaQuery(768)} {
    font-size: ${pxToVw(80, 750)};
  }
`

const StyledP = styled.p`
  margin-top: 25px;
  font-size: 2.2rem;
  line-height: 1.4;
  font-weight: 900;
  white-space: pre-wrap;

  ${mediaQuery(768)} {
    width: calc(430 / 690 * 100%);
    margin-top: 10px;
    font-size: ${pxToVw(30, 750)};
  }
`

const StyledSpan2 = styled.span`
  display: block;
  font-size: 1.8rem;
  font-weight: 400;

  ${mediaQuery(768)} {
    font-size: ${pxToVw(24, 750)};
  }
`
