import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import cn from 'classnames'
import webApp from '../../../utils/exdioWebAppUtils'
import { EPISODE_DISPLAY_MODE, DIO_PLAYER_DEVICE } from '../../../../../constants/app'
import { whatKindOfVideo } from './util'
import LinkLoginOrMyPage from './LinkLoginOrMyPage'

/** 再生ボタン */
export default class PlayLink extends Component {
  static propTypes = {
    /**   シリーズID */
    seriesId: PropTypes.string.isRequired,
    /** シーズンID */
    seasonId: PropTypes.string.isRequired,
    /** エピソードID */
    episodeId: PropTypes.string.isRequired,
    /**
     * 動画ステータス
     * @param {number} displayMode - 動画の視聴形態(1-9)
     * @param {string} 1 - FREE
     * @param {string} 2 - TVOD_FREE
     * @param {string} 3 - TVOD_NOT_FREE
     * @param {string} 4 - SVOD_FREE
     * @param {string} 5 - SVOD_NOT_FREE
     * @param {string} 6 - SVOD_AUTH_FREE
     * @param {string} 7 - STVOD_FREE
     * @param {string} 8 - STVOD_TVOD_NOT_FREE
     * @param {string} 9 - STVOD_SVOD_NOT_FREE
     */

    /** 動画のメタ情報 */
    meta: PropTypes.shape({
      values: PropTypes.shape({
        svod_delivery_pattern: PropTypes.string
      })
    }),
    status: PropTypes.shape({
      displayMode: PropTypes.number,
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      isDeviceNotAvailable: PropTypes.bool,
      limitDate: PropTypes.string,
      isPossible: PropTypes.bool,
      isBelonging: PropTypes.bool,
      /** IOS用のapp-dougaへのリンクに使用 */
      episodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /** IOS用のapp-dougaへのリンクに使用 */
      seriesId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /** IOS用のapp-dougaへのリンクに使用 */
      seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    /**
     * 動画情報
     * @param {string} sales_start_at - 販売開始日時
     * @param {string} sales_end_at - 販売終了日時
     */
    product: PropTypes.object,
    /** onClickPlayの動作を制御する */
    disabled: PropTypes.bool,
    /** [購入に必要なコイン枚数, 購入に必要な日本円] */
    price: PropTypes.array,
    /** ボタンを押した時に発火する関数 */
    onClickPlay: PropTypes.func,
    /** trueの時ログインもしくはマイページ遷移ボタンが描画される */
    renderLinkLoginOrMyPage: PropTypes.bool,
    /** パックのみテンプレートか */
    isPackOnly: PropTypes.bool,
    /** 自動再生On/Off */
    auto_start: PropTypes.bool,
    /** 商品タイプ */
    product_type:PropTypes.number,
    /** アプリ用チャンネルID */
    channel:PropTypes.string,
    /** DIOメタID */
    meta_id: PropTypes.number,
      /** ssai(動画広告を挿入する技術)の設定id */
    ssai_ad_config_id:PropTypes.string,
    /** ユーザ識別パラメータ */
    uid:PropTypes.string,
    /** コンテンツ識別パラメータ */
    ga_cid:PropTypes.string,
    /** サブタイトルを表示するか */
    subtitle:PropTypes.bool,
    /** ユニバーサルリンクを使用するか否か */
    useUniversalLinks: PropTypes.bool,
    /** ボタンのテキストを`アプリで視聴`というテキストにするか */
    isAppWatch : PropTypes.bool
  }

  static contextTypes = {
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object
  }

  static defaultProps = {
    disabled: false,
    price: [null, null],
    onClickPlay: () => {},
    renderLinkLoginOrMyPage: false,
    isPackOnly: false,
    auto_start: true,
    product_type: 1,
    channel: 'ex',
    meta_id: null,
    ssai_ad_config_id: '',
    uid: '',
    ga_cid: '',
    subtitle: false,
    useUniversalLinks: false,
    isAppWatch: false
  }

  constructor(props, context) {
    super(props, context)
    this.state = {}
    this.config = context.models.config.data

    this.renderInnerHtml = this.renderInnerHtml.bind(this)
    this.renderInnerHtmlSvod = this.renderInnerHtmlSvod.bind(this)
    this.wDisabled = this.wDisabled.bind(this)

    this.ua = navigator.userAgent.toLowerCase()
    this.isIos =
      this.ua.indexOf('iphone') >= 0 ||
      this.ua.indexOf('ipad') >= 0 ||
      (this.ua.indexOf('macintosh') >= 0 && 'ontouchend' in document) ||
      this.ua.indexOf('ipod') >= 0
    this.isAndroid = webApp.utils.getDevice() === DIO_PLAYER_DEVICE.ANDROID
    this.isApp = webApp.utils.isApp(this.context) // アプリ判別

  }

  componentDidMount() {}

  wDisabled() {
    const { meta, status, product } = this.props
    // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
    const withValidPreviewToken = _.get(this.context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])
    if (withValidPreviewToken || status.isPurchased) return false

    // SVOD配信パターン
    const svodDeliveryPattern = _.get(meta, ['values', 'svod_delivery_pattern'])

    // 購入済みかSVOD配信パターンが無料視聴可能の場合、再生
    if (svodDeliveryPattern === '1') {
      return false
    }

    if (!status.isPurchseAvailable) return true
    // SVODの場合sales_start_atの値の影響でisOnSale関数からfalseが返ってきてしまうため以下の順序で判定すること
    if (
      product &&
      whatKindOfVideo(status.displayMode) !== 'SVOD' &&
      !webApp.utils.isOnSale(this.context, product)
    )
      return true

    return false
  }

  /** 再生or購入導線 */
  renderInnerHtml() {
    const { status, product, price, isPackOnly,isAppWatch } = this.props
    const kindOfVideo = whatKindOfVideo(status.displayMode)

    // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
    const withValidPreviewToken = _.get(this.context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])
    if (withValidPreviewToken) return '視聴する'

    // 海外判定
    if (status.isGeoDeliverable === false)
      return <div className="c-pageBtn">ご利用の地域では視聴できません</div>
    if (status.isDeviceNotAvailable === true)
      return <div className="c-pageBtn">ご利用の端末では視聴できません</div>
    if (!status.isGeoDeliverable) return <div className="c-pageBtn" />

    //購入済み
    if (status.isPurchased) {
      if (isAppWatch && kindOfVideo === 'TVOD' && !this.isApp && (this.isIos || this.isAndroid)) {
        return [
            <span key="free" className="c-pageBtn-link-free">
              アプリ
            </span>,
            'で視聴'
        ]
      } else {
        return '視聴する';
      }
    }

    // パックのみ販売単話、未購入
    if (isPackOnly) return '現在パックのみ購入いただけます'

    // データの準備ができていない
    if (status.isPurchseAvailable === false) return '現在購入いただけません'

    // 商品が販売期間外
    if (
      kindOfVideo !== 'SVOD' &&
      status.displayMode !== EPISODE_DISPLAY_MODE.SVOD_FREE &&
      product &&
      !webApp.utils.isOnSale(this.context, product)
    )
      return '現在購入いただけません'

    switch (kindOfVideo) {
      case 'FREE':
        return [
          <span key="free" className="c-pageBtn-link-free">
            {(!this.isApp &&
              webApp.utils.isSp() &&
              status.displayMode === EPISODE_DISPLAY_MODE.FREE) ||
            (!this.isApp &&
              this.isIos &&
              status.displayMode === EPISODE_DISPLAY_MODE.FREE)
              ? 'アプリ'
              : '無料'}
          </span>,
          'で視聴'
        ]
      case 'TVOD':
        return [
          <span key="not-free" className="c-pageBtn-link-coin">
            {price[0]}
          </span>,
          'で視聴'
        ]
      case 'SVOD':
        return [
          '月額',
          <span key="in-course" className="c-pageBtn-link-yen">
            {price[1]}
          </span>,
          '円で視聴'
        ]
      case 'UNKNOWN':
      default:
        return null
    }
  }

  /** STVODのとき、SVODとTVODの両方の導線を置く */
  renderInnerHtmlSvod() {
    const { status, product, price } = this.props

    if (
      status.displayMode === EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE && // TVOD(有料)
      status.isNotPurchased && // 購入していない
      status.isPurchseAvailable && // 購入可能
      !(product && !webApp.utils.isOnSale(this.context, product)) // 商品が販売期間外でない
    ) {
      return [
        '月額',
        <span key="in-course" className="c-pageBtn-link-yen">
          {price[1]}
        </span>,
        '円で視聴'
      ]
    }
    return null
  }

  render() {
    const {
      status,
      disabled,
      onClickPlay,
      renderLinkLoginOrMyPage,
      isPackOnly,
      auto_start,
      product_type,
      channel,
      meta_id,
      ssai_ad_config_id,
      uid,
      ga_cid,
      subtitle,
      useUniversalLinks
    } = this.props
    const innerHtml = this.renderInnerHtml()
    const innerHtmlSvod = this.renderInnerHtmlSvod()
    const wDisabled = this.wDisabled() // trueの場合、黒背景で非活性になる (wとは、watch?)
    const kindOfVideo = whatKindOfVideo(status.displayMode)
    const isUniversalLinks =
      useUniversalLinks &&
      !this.isApp &&
      this.isIos &&
      (kindOfVideo === 'FREE' || (kindOfVideo === 'TVOD' && status.isPurchased))

    const commonParams =
      'type=play' +
      `&auto_start=${auto_start}` +
      `&product_type=${product_type}` +
      `&channel=${channel}` +
      `&meta_id=${meta_id}` +
      `&ssai_ad_config_id=${ssai_ad_config_id}` +
      `&uid=${uid}` +
      `&cid=${ga_cid}` +
      `&subtitle=${subtitle}` +
      `&return_url=${window.location.href}`

    return (
      <React.Fragment>
        {innerHtml && (
          <div className="c-pageBtn">
            <a
              href={
                isUniversalLinks
                  ? `${this.config.universal_links_domain}?${commonParams}`
                  : '#'
              }
              className={cn('c-pageBtn-link', {
                disabled:
                  status.isGeoDeliverable === false ||
                  status.isDeviceNotAvailable === true ||
                  (status.isNotPurchased && isPackOnly)
              })}
              onClick={
                disabled || wDisabled
                  ? (e) => e.preventDefault()
                  : isUniversalLinks
                  ? false
                  : (e) => onClickPlay(e, false, true)
              }
            >
              {innerHtml}
            </a>
          </div>
        )}
        {innerHtmlSvod && (
          <div className="c-pageBtn mt10">
            <a
              href="#"
              className="c-pageBtn-link"
              onClick={
                disabled || wDisabled
                  ? (e) => e.preventDefault()
                  : (e) => onClickPlay(e, true, true)
              }
            >
              {innerHtmlSvod}
            </a>
          </div>
        )}
        {renderLinkLoginOrMyPage && <LinkLoginOrMyPage />}
      </React.Fragment>
    )
  }
}
