import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import axios from 'axios'
import MDSpinner from 'react-md-spinner'
import { PUBLISH_STATUS } from '../../../../constants/app'
import webApp from '../../utils/exdioWebAppUtils'
import PlanShinchan from './templates/PlanShinchan'
import browserInfo from '../../../../sketch-platform/utils/browserInfo'
import { PANEL_ID } from './plan_shinchan/config'

/** 月額見放題ページ */
export default class PlanShinchanContent extends Component {
  static propTypes = {
    slug: PropTypes.string,
    course: PropTypes.shape({
      course_id: PropTypes.number.isRequired,
      schema_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired
    }).isRequired
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  static defaultProps = {
    slug: ''
  }

  static getPath(_models, _options, props) {
    return ['courseBySlug', props.slug]
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    const path = PlanShinchanContent.getPath({}, {}, props)
    const course = this.model.getSync(path) || {}

    this.state = {
      status: {
        isPurchseAvailable: course.active_pricing != null,
        isPurchased: false,
        isNotPurchased: false
      },
      programMetas: [],
      selectionItems: [],
      initialTabId: '',
      initialTag: [],
      loaded: false,
      popupArticles: [],
      /**
       * テーマ
       * (normal: 通常)
       * spring: 春
       * rainy: 梅雨
       * summer: 夏
       * autumn: 秋
       * winter: 冬
       * movie: 映画
       * buriburiclub: ぶりぶりclub
       */
      theme: 'buriburiclub'
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const { course } = this.props

    // 固定viewport設定
    const device = browserInfo(navigator.userAgent, (data) => data)
    if (device.isSilk) {
      this.setPcWidthViewport()
    }

    this.getArticles().catch((e) => console.error(e))

    // クレヨンしんちゃんplanページへuergramタグ追加
    this.addUsergramTagShinchan()

    // ライセンス取得
    const status = (await this.getBelonging(course)) || this.state.status
    // 権利を取得
    const right = await this.getRight(course).catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
    // rightに紐付くメタ(すべてでは無く権利に紐つけたメタのみ)
    const rightMetas = await this.getRightMetas(right).catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    // rightMetasからシーズンのメタを取得
    const metaIds = rightMetas.map((meta) => get(meta, ['meta_id'], null))
    const programMetas = await Promise.all(
      metaIds.map((metaId) => this.getProgramMetas(metaId))
    ).catch((e) => webApp.utils.handleFalcorError(e, this.context))

    // getSyncだとfalcorのキャッシュが入ってないとundefinedになるっぽいので、ここで再設定
    status.isPurchseAvailable = course && course.active_pricing != null

    const selectionItems = await this.getSelectionItems().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    const characterItems = await this.getCharacterItems().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    /** パスパラメータにタブの指定がある場合は初期表示 */
    const initialTabId = get(this.context.routeHandler, ['query', 'tab'], '')

    /** パスパラメータにタグの指定がある場合は初期表示 */
    let initialTag = get(this.context.routeHandler, ['query', 'tag'], '')

    /** パスパラメータにIDの指定がある場合は初期表示 */
    const queryId = get(this.context.routeHandler, ['query', 'id'], '')
    if (queryId) {
      switch (initialTabId) {
        /** 厳選エピソード */
        case PANEL_ID.SELECTION: {
          const selectedItem = Object.values(selectionItems).find(
            ({ id = '' }) => {
              return String(id) === queryId
            }
          )
          initialTag = get(selectedItem, ['tag'], '')
          break
        }
        /** キャラクター別 */
        case PANEL_ID.CHARACTER: {
          const selectedItem = characterItems.find(
            ({ id = '' }) => String(id) === queryId
          )
          initialTag = get(selectedItem, ['name'], '')
          break
        }
        default:
          break
      }
    }

    this.setState({
      status,
      programMetas,
      selectionItems,
      characterItems,
      initialTabId,
      initialTag: initialTag ? [decodeURI(initialTag)] : [],
      loaded: true
    })
  }

  UNSAFE_componentWillUnmount() {
    this._isMounted = false
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    const usergramIds = [
      'usergram-member-shinchan-plan',
      'usergram-common-shinchan-plan'
    ]
    if (isLoggedIn === true) {
      webApp.utils.removeUserGram(usergramIds)
    }
    webApp.utils.returnDefaultWebClip()
    this.removePcWidthViewport()
  }

  /** クレヨンしんちゃんplanページ USERGRAMタグ追加 */
  addUsergramTagShinchan() {
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)

    if (isLoggedIn === false) {
      return
    }

    let member_id = ''
    const cookies = document.cookie
    const cookiesArr = cookies.split(';')

    cookiesArr.forEach((cookie) => {
      const cookieArr = cookie.split('=')
      if (cookieArr[0] === ' CBM_ID') {
        member_id = cookieArr[1]
      }
    })

    let browseUserGram = ''
    browseUserGram +=
      '<script type="text/javascript" id="usergram-member-shinchan-plan">'
    browseUserGram += 'window.ugattr = window.ugattr || {};'
    browseUserGram += `ugattr['serviceId'] = '${member_id}';`
    browseUserGram += `ugattr['prop07'] = 'クレヨンしんちゃんスペシャルページ';`
    browseUserGram += `ugattr['prop09'] = '';`
    browseUserGram += `ugattr['prop10'] = '';`
    browseUserGram += '</script>'

    browseUserGram +=
      '<script type="text/javascript" id="usergram-common-shinchan-plan">'
    browseUserGram +=
      '(function(){var a=window,b=document,c=a.usergram=a.usergram||[],d,e;'
    browseUserGram +=
      "c.l||(c.s=(new Date()).getTime(),c.l=!0,d=b.getElementsByTagName('script')[0],"
    browseUserGram +=
      "e=b.createElement('script'),e.type='text/javascript',e.async=true,"
    browseUserGram +=
      "e.src='//code.usergram.info/js/usergram.js',d.parentNode.insertBefore(e,d))})();"
    browseUserGram +=
      'window.usergram=window.usergram||[],window.ugattr=window.ugattr||{};'
    browseUserGram +=
      "usergram.push(['send','Ug37cn-1','cv','shinchan_program',ugattr]);"
    browseUserGram += '</script>'

    webApp.utils.appendUserGram(browseUserGram)
  }

  /** グループのライセンス有無取得 */
  getBelonging(course) {
    if (!course) return Promise.resolve()

    const refId = course.ref_id
    if (!refId) return Promise.resolve()

    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) {
      if (this._isMounted) {
        return {
          ...this.state.status,
          isNotPurchased: true
        }
      }
      return Promise.resolve()
    }

    const path = ['infra', 'gBelong', refId]
    return this.model.fetch([path]).then((result) => {
      const belonging = get(result, ['json', ...path]) || false
      if (this._isMounted) {
        return {
          ...this.state.status,
          isPurchased: belonging,
          isNotPurchased: !belonging
        }
      }
      return this.state.status
    })
  }

  /** 権利情報取得 */
  getRight(course) {
    if (!course) return Promise.resolve()

    const path = ['course', 'rights', course.course_id]
    return this.model.fetch([path]).then((result) => {
      const rights = get(result, ['json', ...path], [])
      // 紐つける権利は一つに限定
      return rights[0]
    })
  }

  /** 権利メタ取得 */
  getRightMetas(right) {
    if (!right || !right.right_id) return Promise.resolve([])
    const path = ['right', 'metas', right.right_id]
    return this.model.fetch([path]).then((result) => {
      // TODO とり方変えたのでここの処理は考え直す
      const metas = get(result, ['json', ...path], [])
      // 公開以外も取得される&重複して取得されることがあるのでフィルター
      const metaIds = {}
      const rightMetas = metas.filter((meta) => {
        const published = meta.publish_status === PUBLISH_STATUS.PUBLISHED
        const duprecated = Object.keys(metaIds).includes(
          meta.meta_id.toString()
        )
        metaIds[meta.meta_id] = null
        return published && !duprecated
      })
      return rightMetas
    })
  }

  /** シーズンのメタを取得 */
  getProgramMetas(metaId) {
    const path = ['metas', metaId]
    return this.model.fetch([path]).then((result) => {
      const metas = get(result, ['json', ...path], [])
      return metas
    })
  }

  /** お知らせ取得 */
  getArticles() {
    const popupPath = ['articles', 'popup']
    return this.model.fetch([popupPath]).then((result) => {
      const popupArticles = get(result, ['json', ...popupPath]) || []
      this.setState({ popupArticles })
    })
  }

  async getSelectionItems() {
    const dougaMvHost = webApp.utils.dougaMvHost(this.context)
    const timeStamp = webApp.utils.getTimeStamp()
    const jsonPath = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? '/douga_mv/shinchan/svod/data/selection.json'
      : `//${dougaMvHost}/douga_mv/shinchan/svod/data/selection.json`
    return axios.get(`${jsonPath}?t=${timeStamp}`).then(({ data = [] }) => {
      const indexItems = data.reduce(
        (prev, current, i) => ({ ...prev, [i]: current }),
        {}
      )
      return indexItems
    })
  }

  /** キャラクター別で使用するデータの取得 */
  async getCharacterItems() {
    const dougaMvHost = webApp.utils.dougaMvHost(this.context)
    const timeStamp = webApp.utils.getTimeStamp()
    const jsonPath = `//${dougaMvHost}/douga_mv/shinchan/svod/data/characters.json?t=${timeStamp}`
    return axios.get(jsonPath).then(({ data }) => data)
  }

  /**
   * 固定viewport設定
   * 対象: FireTVのSilkブラウザ
   * 1920x1080 -> 960x540 (2 / 1)
   * PC表示にするため`initial-scale`を調整
   */
  setPcWidthViewport() {
    const viewportMeta = document.querySelector('meta[name="viewport"]')
    if (!viewportMeta) return

    viewportMeta.content = viewportMeta.content.replace(
      'initial-scale=1',
      'initial-scale=0.75'
    )
  }

  /** 固定viewport解除 */
  removePcWidthViewport() {
    const viewportMeta = document.querySelector('meta[name="viewport"]')
    if (!viewportMeta) return

    viewportMeta.content = viewportMeta.content.replace(
      'initial-scale=0.75',
      'initial-scale=1'
    )
  }

  render() {
    const { slug } = this.props
    const {
      status,
      popupArticles,
      programMetas,
      selectionItems,
      characterItems,
      initialTabId,
      initialTag,
      loaded,
      theme
    } = this.state
    if (!loaded) {
      return (
        <div className="common-wrapper">
          <div className="mdspinner">
            <MDSpinner size={96} />
          </div>
        </div>
      )
    }

    const childProps = Object.assign({}, this.props, {
      slug,
      status,
      popupArticles,
      programMetas,
      selectionItems,
      characterItems,
      initialTabId,
      initialTag,
      theme
    })
    return React.createElement(PlanShinchan, childProps)
  }
}
