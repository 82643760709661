import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import PresTrackedContainer from '../../../common/components/PresTrackedContainer';

const getWidth = function(elem) {
  return elem.getBoundingClientRect().width || elem.offsetWidth || 0;
};

export default class Row extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      isPopOpen: false,
      lowestVisibleItemIndex: 0
    };
  }

  componentDidMount() {
    if (this.context.focusManager) {
      const el = ReactDOM.findDOMNode(this.refs.row);
      if (el) {
        this.context.focusManager.collectFocasableElements(el);
      }
    }
  }

  componentWillUnmount() {
    if (this.popAnimationId) {
      clearTimeout(this.popAnimationId);
      delete this.popAnimationId;
    }
  }

  getChildContext() {
    return {rowNum: this.props.rowNum};
  }

  handleRowBlur(e) {
    // TODO
  }

  render() {

    const settings = {
      initialSlide: 0,
      swipe: false,
      enableLooping: true,
      enablePeek: this.props.enablePeek,
      enablePaginationIndicator: this.props.enablePaginationIndicator
    };

    return (
      <div ref="row">
            <PresTrackedContainer>
              <ul className="avod_line_wrap" onMouseLeave={this.handleRowBlur.bind(this)}>
                  {this.renderChildren()}
              </ul>
              <span className="titleRowContent"></span>
            </PresTrackedContainer>
        </div>
    );
  }

  renderChildren() {
    return React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        getRowHasPopOpen: () => {
          return this.state.isPopOpen;
        },
        isDoubleWide: false,
        isTallPanel: this.context.isTallRow === true,
        onPopClose: this.onPopClose.bind(this),
        onPopLeave: this.onPopLeave.bind(this),
        onPopOpen: this.onPopOpen.bind(this),
        rankNum: index
      });
    });
  }

  onPopOpen(targetTitleCard) {
    if (this.popAnimationId && this.activeTitleCard === targetTitleCard) {
      clearTimeout(this.popAnimationId);
      delete this.popAnimationId;
    }

    this.activeTitleCard = targetTitleCard;

    this.setState({isPopOpen: true});

    const targetSliderItem = this.refs.slider.refs[targetTitleCard.props.sliderItemId];
    const targetViewportPosition = targetSliderItem.props.viewportPosition;

    const zoomSize = targetTitleCard.getZoomScale();
    const width = Math.ceil(getWidth(ReactDOM.findDOMNode(targetTitleCard.refs.titleCard)) * (zoomSize - 1.0)/2.0);
    const slide = ReactDOM.findDOMNode(targetSliderItem);
    if (!slide) return;

    const defaultStyle = 'transition-duration: 400ms; transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1); transition-delay: 0ms;';

    let offset = 0;
    if (targetViewportPosition === 'leftEdge') {
      offset = width;
    } else if (targetViewportPosition === 'rightEdge') {
      offset = -width;
    }
    if (this.props.enablePushOut !== true) {
      ReactDOM.findDOMNode(targetTitleCard).setAttribute('style', `z-index: 4; transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`);
      slide.style.zIndex = 4;
    } else {
      slide.setAttribute('style', `z-index: 4; transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`);
    }

    if (this.props.enablePushOut !== true) {
      return;
    }

    // 両サイドのSliderItemの位置を重ならないように移動する

    let elem = slide;
    // 左側のSliderItemの処理
    offset = -1 * width;
    if (targetViewportPosition === 'rightEdge') {
      offset = offset * 2;
    } else if (targetViewportPosition === 'leftEdge') {
      offset = 0;
    }
    while (elem = elem.previousElementSibling) {
      if (elem.classList.contains('slider-item') && !elem.classList.contains('slider-item-')) {
        elem.setAttribute('style', `transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`);
      }
    }

    // 右側のSliderItemの処理
    elem = slide;
    offset = width;
    if (targetViewportPosition === 'leftEdge') {
      offset = offset * 2;
    } else if (targetViewportPosition === 'rightEdge') {
      offset = 0;
    }
    while (elem = elem.nextElementSibling) {
      if (elem.classList.contains('slider-item') && !elem.classList.contains('slider-item-')) {
        elem.setAttribute('style', `transform: translate3d(${offset}px, 0px, 0px); ${defaultStyle}`);
      }
    }
  }

  onPopLeave(targetTitleCard, delay = 0) {
    this.popAnimationId = setTimeout(() => {
      delete this.popAnimationId;
      if (this.activeTitleCard !== targetTitleCard && this.activeTitleCard !== undefined) {
        if (this.props.enablePushOut !== true) {
          const targetSliderItem = this.refs.slider.refs[targetTitleCard.props.sliderItemId];
          // 同じスライドItemの場合は処理を行わない
          if (targetTitleCard.props.sliderItemId !== this.activeTitleCard.props.sliderItemId) {
            const slide = ReactDOM.findDOMNode(targetSliderItem);
            if (slide) {
              slide.style.zIndex = 2;
              slide.style.transform = 'translate3d(0px, 0px, 0px)';
            }
          }

          const container = ReactDOM.findDOMNode(targetTitleCard);
          if (container) {
            container.style.zIndex = 2;
            container.style.transform = 'translate3d(0px, 0px, 0px)';
          }
        }
        return;
      }

      const container = ReactDOM.findDOMNode(targetTitleCard);
      if (container) {
        if (parseInt(container.style.zIndex, 10) === 4) {
          container.style.zIndex = 2;
        }
        container.style.transform = 'translate3d(0px, 0px, 0px)';
      }

      const sliderDOM = ReactDOM.findDOMNode(this.refs.slider);
      if (sliderDOM) {
        Array.prototype.forEach.call(sliderDOM.querySelectorAll('.slider-item:not(.slider-item-)'), (slide) => {
          if (parseInt(slide.style.zIndex, 10) === 4) {
            slide.style.zIndex = 2;
          }
          if (this.props.enablePushOut === true) {
            slide.style.transform = 'translate3d(0px, 0px, 0px)';
          }
        });
      }
    }, delay);
  }

  onPopClose(targetTitleCard) {
    if (this.activeTitleCard !== targetTitleCard) {
      if (this.props.enablePushOut !== true) {
        const targetSliderItem = this.refs.slider.refs[targetTitleCard.props.sliderItemId];
        const slide = ReactDOM.findDOMNode(targetSliderItem);
        if (slide) {
          slide.removeAttribute('style');
        }
        const card = ReactDOM.findDOMNode(targetTitleCard);
        if (card) {
          card.removeAttribute('style');
        }
      }
      return;
    }

    delete this.activeTitleCard;
    this.setState({isPopOpen: false});
    const sliderDOM = ReactDOM.findDOMNode(this.refs.slider);
    if (sliderDOM) {
      Array.prototype.forEach.call(sliderDOM.querySelectorAll('.slider-item:not(.slider-item-)'), (slide) => {
        slide.removeAttribute('style');
      });
      Array.prototype.forEach.call(sliderDOM.querySelectorAll('.slider-item:not(.slider-item-) .title-card-container'), (container) => {
        container.removeAttribute('style');
      });
    }
  }

  handleSliderMove(nextState, actionType) {
    this.setState({ lowestVisibleItemIndex: nextState.lowestVisibleItemIndex });
    if (this.props.handleSliderMove) {
      this.props.handleSliderMove();
    }
  }
}

Row.propTypes = {
  containsDoubleWides: PropTypes.bool,
  enablePushOut: PropTypes.bool,
  enablePeek: PropTypes.bool,
  enablePaginationIndicator: PropTypes.bool,
  handleSliderMove: PropTypes.func,
  isMyListRow: PropTypes.bool,
  titleRowModelIndex: PropTypes.number,
  titleRowRankNum: PropTypes.number,
  orderedItemList: PropTypes.array,
  rowNum: PropTypes.number,
  totalItems: PropTypes.number,
  loadingComponent: PropTypes.object,
};

Row.defaultProps = {
  enablePaginationIndicator: true,
  containsDoubleWides: false,
  handleSliderMove: null,
  isMyListRow: false,
  titleRowModelIndex: null,
  titleRowRankNum: null,
  orderedItemList: null,
  rowNum: null,
  totalItems: null,
  loadingComponent: null,
};

Row.contextTypes = {
  columnsInRow: PropTypes.number,
  currentPage: PropTypes.string,
  history: PropTypes.object,
  isInitialRender: PropTypes.bool,
  isOverlayPage: PropTypes.bool,
  isRtl: PropTypes.bool,
  isTallRow: PropTypes.bool,
  titleRowEpisodeTrackId: PropTypes.number,
  titleRowTrackId: PropTypes.number,
  titleRowTrailerTrackId: PropTypes.number,
  listContext: PropTypes.string,
  listType: PropTypes.string,
  loggingApp: PropTypes.object,
  canvasId: PropTypes.string,
  profileManager: PropTypes.string,
  requestId: PropTypes.string,
  routeHandler: PropTypes.object,
  trackId: PropTypes.number,
  focusManager: PropTypes.object
};

Row.childContextTypes = {
  rowNum: PropTypes.number
};
