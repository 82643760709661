import styled from 'styled-components'
import List2 from '../List2'
import { StyledLi as List2Li } from '../List2/style'
import { mediaQuery } from '../../../../style'

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaQuery()} {
    flex-direction: column;
    gap: 15px;
  }
`

export const StyledList2 = styled(List2)`
  gap: 30px;

  ${List2Li} {
    margin-left: 0;
  }
`
