import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../utils/exdioWebAppUtils'
import HiramekiItem from '../HiramekiItem'
import Pager from '../../../../../common/components/renewal/Pager2'

/* style */
import { StyledDiv2, StyledDiv3, StyledUl1 } from './style'

/** ヒラメキのタネ動画リスト表示切り替えコンポーネント */
const SwitchableHirameki = ({ episodes, disp_order }, context) => {
  const [filteredEpisodes, setFilteredEpisodes] = useState([])
  const [countries, setCountries] = useState([])
  const pagerOptions = {
    episodesPerPages: 24,
    range: 2
  }
  const [currentPageNum, setCurrentPageNum] = useState(1)
  const [currentEpisodes, setCurrentEpisodes] = useState([])
  const setListRef = (e) => {
    const listRef = e
  }

  /** エピソードをソートする関数 */
  const sortEpisodes = (episodes) => {
    const direction = disp_order == 'asc' ? -1 : 1
    episodes.sort((a, b) => {
      // ソート番号 -> 管理画面の昇順or降順に合わせる
      if (a.sort < b.sort) return direction * 1
      if (a.sort > b.sort) return direction * -1

      // 配信開始日時 -> 管理画面の昇順or降順に合わせる
      if (a.delivery_start_at < b.delivery_start_at) return direction * 1
      if (a.delivery_start_at > b.delivery_start_at) return direction * -1

      // メタスキーマIDの昇順(無料, 有料の順) -> 固定
      if (a.meta_schema_id < b.meta_schema_id) return -1
      if (a.meta_schema_id > b.meta_schema_id) return 1

      // エピソード番号 -> 管理画面の昇順or降順に合わせる
      if (
        Number(a.values.avails_EpisodeNumber) <
        Number(b.values.avails_EpisodeNumber)
      )
        return direction * 1
      if (
        Number(a.values.avails_EpisodeNumber) >
        Number(b.values.avails_EpisodeNumber)
      )
        return direction * -1

      // ID -> 管理画面の昇順or降順に合わせる
      if (a.id < b.id) return direction * 1
      if (a.id > b.id) return direction * -1

      return 0
    })
  }

  /** ページネーションのページに応じて表示する動画を返す */
  const returnCurrentPageEpisodes = (episodes, pageNum) => {
    const firstVal = pagerOptions.episodesPerPages * (pageNum - 1)
    const lastVal = pagerOptions.episodesPerPages * pageNum
    return episodes.slice(firstVal, lastVal)
  }

  /** ページ番号の更新 */
  const updateCurrentPageNum = (currentPageNum) => {
    sortEpisodes(episodes)
    setCurrentPageNum(currentPageNum)
    setCurrentEpisodes(
      returnCurrentPageEpisodes(filteredEpisodes, currentPageNum)
    )
  }

  /** ページネーションを1ページ目に設定する */
  const initializePagination = (episodes) => {
    sortEpisodes(episodes)
    setCurrentEpisodes(returnCurrentPageEpisodes(episodes, 1))
    setCurrentPageNum(1)
    setFilteredEpisodes(episodes)
  }

  // 初期化・episodeが更新されたとき検索を再実行
  useEffect(() => {
    const beforeCountries = episodes.map((meta) => meta.description)
    const afterCountries = Array.from(new Set(beforeCountries))
    setCountries(afterCountries)
    initializePagination(episodes)
  }, [episodes])

  return (
    <React.Fragment>
      {/* 動画の総数が1ページに表示する動画数よりも少ない場合は表示しない。 */}
      {filteredEpisodes.length > pagerOptions.episodesPerPages && (
        <StyledDiv2>
          <Pager
            option={pagerOptions}
            episodeLength={filteredEpisodes.length}
            currentPageNum={currentPageNum}
            updateCurrentPageNum={updateCurrentPageNum}
          />
        </StyledDiv2>
      )}

      <StyledDiv3>
        <StyledUl1 ref={setListRef}>
          {currentEpisodes &&
            currentEpisodes.map((meta) => (
              <HiramekiItem
                key={meta.meta_id}
                meta={meta}
                onClickThumbnail={() =>
                  webApp.utils.goToProgramLink(context, meta)
                }
              />
            ))}
        </StyledUl1>
      </StyledDiv3>
    </React.Fragment>
  )
}

export default SwitchableHirameki

SwitchableHirameki.propTypes = {
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    })
  ),
  rootMetas: PropTypes.arrayOf(PropTypes.object)
}

SwitchableHirameki.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  routeHandler: PropTypes.object
}
