import styled from 'styled-components'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

export const StyledDiv1 = styled.div`
  background: hsla(0, 0%, 100%, 0.7);
  overflow: hidden;
`

export const StyledLink = styled(Link)`
  && {
    width: 49.75%;
    box-sizing: border-box;
    line-height: 28px;
    position: relative;
    text-align: center;
    font-size: 1.2rem;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 0;
    background: linear-gradient(
        -90deg,
        rgb(255, 255, 112) 0%,
        rgb(135, 245, 131) 65%,
        rgb(15, 235, 149) 100%
      )
      100% / auto 100%;
    padding: 2px 0;
  }
`

export const StyledLink1 = styled(StyledLink)`
  float: left;
  &::before {
    font-family: Material Icons;
    content: '\\E314';
    font-size: 2rem;
    position: absolute;
    left: 8%;
    top: 2px;
  }
`

export const StyledLink2 = styled(StyledLink)`
  float: right;
  &::before {
    font-family: Material Icons;
    content: '\\E315';
    font-size: 2rem;
    position: absolute;
    right: 8%;
    top: 2px;
  }
`
