import { createStore } from 'redux';

export default function createApp() {
  const store = createStore(function() {
  });
  return {
    getStore: function() {
      return store;
    }
  };
};
