import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  SEARCH_TYPE,
  KEY_CODE,
  META_SCHEMA_ID,
  SORT_TYPE,
  SORT_TYPES,
  SEARCH_TYPE_APP,
  SEARCH_TYPES_APP,
  ADVERTISING_SCHEMA_ID
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import ProgramItem from '../../../../common/components/renewal/ProgramItem'
import TabNavigation from '../../../../common/components/renewal/TabNavigation'
import SelectBox from '../../../../common/components/renewal/SelectBox'
import webApp from '../../../utils/exdioWebAppUtils'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import HeaderNewsComponent from '../HeaderNewsComponent'
import * as DOMUtils from '../../../../utils/DOMUtils'
import * as browserEvents from '../../../../utils/browserEvents'
import Footer from '../../../../common/components/appli/StaticFooter'

const PAGE_TITLE = '検索結果'

/** 検索結果ページ */
export default class SearchContent extends Component {
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  static getSsrMetaTags(_models, _options, _props, _prefetchResult) {
    return { title: PAGE_TITLE }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data
    const { keyword } = context.routeHandler.query
    const isInitial = !Object.prototype.hasOwnProperty.call(
      this.context.routeHandler.query,
      'keyword'
    )
    this.state = {
      keyword: keyword || '',
      searchedKeyword: null,
      searchType: SEARCH_TYPE.ALL.value,
      sortType: SORT_TYPE.SCORE.value,
      searchResult: [],
      totalCount: null,
      howToPlays: {},
      metasForPalettes: [],
      recommends: [],
      newArrivals: [],
      howToPlaysForPalettes: {},
      isInitial,
      products: {},
      courses: {}
    }

    this.onChangeKeyword = this.onChangeKeyword.bind(this)
    this.onKeyDownKeyword = this.onKeyDownKeyword.bind(this)
    this.onClearKeyword = this.onClearKeyword.bind(this)
    this.onChangeSearchType = this.onChangeSearchType.bind(this)
    this.onChangeSortType = this.onChangeSortType.bind(this)
    this.onClickItem = this.onClickItem.bind(this)
    this.onTouchmove = this.onTouchmove.bind(this)
    this.onScroll = this.onScroll.bind(this)

    this.setSortRef = (e) => {
      this.sortRef = e
    }
    this.setListRef = (e) => {
      this.listRef = e
    }
  }

  componentDidMount() {
    this._isMounted = true
    const { isInitial } = this.state
    this.search().catch((e) => webApp.utils.handleFalcorError(e, this.context))
    this.getTotalCount().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
    if (isInitial) {
      this.getRecommend()
        .then(() => this.getNewArrival())
        .then(() => this.getHowToPlaysForPalettes())
        .then(() => this.getProductsAndCourses())
        .catch((e) => webApp.utils.handleFalcorError(e, this.context))
    }

    // 検索画面でヘッダーの検索フォームから再検索された場合のためにリスナー登録.
    // 自画面遷移のhistory.push()ではコンポーネントの状態変更を直接トリガーできないため.
    this.removeHistoryListener = this.context.history.listen((location) => {
      if (routes.app_search.makePath() !== location.pathname) return
      const queryParams = new URLSearchParams(location.search)
      const keywordParam = queryParams.get('keyword') || ''
      if (!isInitial && keywordParam === this.state.keyword) return
      if (this._isMounted) {
        const sortType = SORT_TYPE.SCORE.value
        this.setState(
          {
            keyword: keywordParam,
            isInitial: !queryParams.has('keyword'),
            sortType
          },
          () => {
            if (this.sortRef) this.sortRef.setValue(sortType)
            this.search().catch((e) =>
              webApp.utils.handleFalcorError(e, this.context)
            )
            this.getTotalCount().catch((e) =>
              webApp.utils.handleFalcorError(e, this.context)
            )
          }
        )
      }
    })

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context, PAGE_TITLE)
    // GTMの更新
    const title = this.context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)

    browserEvents.addEventListener('touchmove', this.onTouchmove)
    browserEvents.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    this._isMounted = false
    browserEvents.removeEventListener('touchmove', this.onTouchmove)
    browserEvents.removeEventListener('scroll', this.onScroll)
    if (typeof this.removeHistoryListener === 'function')
      this.removeHistoryListener()
  }

  /** 検索ワード変更時 */
  onChangeKeyword(e) {
    const keyword = e.target.value
    if (this._isMounted) {
      this.setState({ keyword })
    }
  }

  /** 検索ワード確定時 */
  onKeyDownKeyword(event) {
    if (KEY_CODE.ENTER !== event.keyCode) return
    this.search(true)
    this.getTotalCount()
  }

  /** 検索ワードクリア時 */
  onClearKeyword() {
    if (this._isMounted) {
      this.setState({ keyword: '' })
    }
  }

  /** 検索条件変更時 */
  onChangeSearchType(searchType) {
    if (this._isMounted) {
      this.setState({ searchResult: [], isInitial: false, searchType }, () => {
        this.search().catch((e) =>
          webApp.utils.handleFalcorError(e, this.context)
        )
        this.getTotalCount().catch((e) =>
          webApp.utils.handleFalcorError(e, this.context)
        )
      })
    }
  }

  /** 並び替え条件変更時 */
  onChangeSortType(sortType) {
    if (this._isMounted) {
      this.setState({ searchResult: [], isInitial: false, sortType }, () =>
        this.search()
      )
    }
  }

  /** 検索結果の動画リンク押下時 */
  onClickItem(meta, product = null, course = null, autoPlay = true) {
    webApp.utils.goToProgramLink(this.context, meta, product, course, {
      autoPlay
    })
  }

  /** タグ検索か */
  isTagSearch() {
    return this.state.keyword.trim().startsWith('タグ:')
  }

  /** 検索 */
  search() {
    const { searchResult, searchType, sortType } = this.state
    const keyword = this.state.keyword.trim()
    if (!keyword) {
      this.setState({
        keyword: '',
        searchedKeyword: '',
        searchResult: []
      })
      return Promise.resolve()
    }

    const range = { from: 0, to: (searchResult || []).length + 29 }

    const tag = keyword.replace(/^タグ:(\s|　)*/, '')
    const base_path = this.isTagSearch()
      ? ['metaByTag', tag, searchType, sortType]
      : ['meta', 'dsearch', keyword, searchType, sortType, 25]
    const path = base_path.concat([range])
    return this.model
      .fetch([path])
      .then((result) => {
        const tmpSearchResult = _.get(result, ['json', ...base_path], [])
        delete tmpSearchResult.$__path
        if (this._isMounted) {
          this.setState({
            searchResult: Object.values(tmpSearchResult),
            searchedKeyword: keyword
          })
        }
      })
      .then(() => this.getHowToPlays())
  }

  getTotalCount() {
    const { searchType } = this.state
    const keyword = this.state.keyword.trim()
    if (!keyword) {
      this.setState({ totalCount: 0 })
      return Promise.resolve()
    }

    const tag = keyword.replace(/^タグ:(\s|　)*/, '')
    const path = this.isTagSearch()
      ? ['metaByTag', tag, searchType, 'length']
      : ['meta', 'dsearch', keyword, searchType, 25, 'length']
    return this.model.fetch([path]).then((result) => {
      const totalCount = _.get(result, ['json', ...path]) || 0
      if (this._isMounted) {
        this.setState({ totalCount })
      }
    })
  }

  /** 価格情報取得 */
  getHowToPlays() {
    const { searchResult } = this.state
    // タグ検索の場合は取得しない
    if (!searchResult.length || this.isTagSearch()) return Promise.resolve()

    const metaIds = searchResult
      .filter((meta) => meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE)
      .map((e) => e.meta_id)
    const path = [['meta', 'howToPlay', false, metaIds]]
    return this.model.fetch(path).then((result) => {
      const howToPlays =
        _.get(result, ['json', 'meta', 'howToPlay', false]) || {}
      if (this._isMounted) {
        this.setState({ howToPlays })
      }
    })
  }

  /** テレ朝動画のオススメ取得 */
  getRecommend() {
    const path = ['paletteByKey', 'recommend']
    return this.model.fetch([path]).then((result) => {
      const palette = _.get(result, ['json', ...path]) || {}
      const paletteObjects = palette.objects || []
      // 表示する3件以外は切り捨てる
      const recommends = paletteObjects.slice(0, 3)
      const recommendMetas = palette.objects
        ? palette.objects.map((obj) => obj.meta).filter((v) => v)
        : []
      const { metasForPalettes } = this.state
      metasForPalettes.push(...recommendMetas)
      if (this._isMounted) {
        this.setState({ metasForPalettes, recommends })
      }
    })
  }

  /** 新着動画取得 */
  getNewArrival() {
    const period = this.config.extras.new_arrival_period
    const path = ['meta', 'newArrival', period]
    return this.model.fetch([path]).then((result) => {
      const tmpNewArrivals = _.get(result, ['json', ...path]) || []
      // 表示する3件以外は切り捨てる
      const newArrivals = tmpNewArrivals.slice(0, 3)
      const { metasForPalettes } = this.state
      metasForPalettes.push(...newArrivals)
      if (this._isMounted) {
        this.setState({ metasForPalettes, newArrivals })
      }
    })
  }

  /** 価格情報取得 */
  getHowToPlaysForPalettes() {
    const { metasForPalettes } = this.state
    if (!metasForPalettes.length) return Promise.resolve()

    const metaIds = metasForPalettes
      .filter((meta) => meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE)
      .map((e) => e.meta_id)
    const path = [['meta', 'howToPlay', false, metaIds]]
    return this.model.fetch(path).then((result) => {
      const howToPlaysForPalettes =
        _.get(result, ['json', 'meta', 'howToPlay', false]) || {}
      if (this._isMounted) {
        this.setState({ howToPlaysForPalettes })
      }
    })
  }

  /** 商品・コース情取得報 */
  getProductsAndCourses() {
    const { recommends } = this.state

    const advertisings = recommends
      .filter((obj) => obj.type === 'advertising')
      .map((obj) => obj.advertising)

    const productIds = advertisings
      .filter(
        (ad) =>
          ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT ||
          ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT_LIVE
      )
      .map((h) => h.values.product)
      .filter((v) => v)
    const courseIds = advertisings
      .filter(
        (ad) =>
          ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE ||
          ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE_LIVE
      )
      .map((h) => h.values.course)
      .filter((v) => v)

    const path = [
      ['product', productIds],
      ['course', courseIds]
    ]
    return this.model.fetch(path).then((result) => {
      const products = _.get(result, ['json', 'product']) || {}
      const courses = _.get(result, ['json', 'course']) || {}
      if (this._isMounted) {
        this.setState({ products, courses })
      }
    })
  }

  onTouchmove() {
    if (this && this.isWithinDistanceBuffer()) this.search()
  }

  onScroll() {
    if (this && this.isWithinDistanceBuffer()) this.search()
  }

  isWithinDistanceBuffer() {
    if (!this.listRef) return false
    return DOMUtils.getDistanceToBottomOfElement(this.listRef) < 0
  }

  render() {
    const {
      searchedKeyword,
      searchResult,
      totalCount,
      howToPlays,
      recommends,
      newArrivals,
      howToPlaysForPalettes,
      isInitial,
      products,
      courses
    } = this.state

    return (
      <div className="common-wrapper">
        <HeaderNewsComponent />
        {/* 検索時 */}
        {!isInitial && (
          <div className="c-searchResults center">
            {searchedKeyword && (
              <div className="c-searchResults-head">
                <div className="c-searchResults-head-keyword">
                  <h3>
                    「{searchedKeyword}」の検索結果（{totalCount}件）
                  </h3>
                </div>

                <div className="c-searchResults-head-nav">
                  <TabNavigation
                    types={SEARCH_TYPES_APP}
                    initialType={SEARCH_TYPE_APP.ALL}
                    onSelect={this.onChangeSearchType}
                  />
                  <SelectBox
                    ref={this.setSortRef}
                    types={[SORT_TYPE.SCORE].concat(SORT_TYPES)}
                    onSelect={this.onChangeSortType}
                  />
                </div>
              </div>
            )}

            <div className="c-card-panel">
              <div className="c-card-panel-cont" ref={this.setListRef}>
                {searchResult.map((meta) => (
                  <ProgramItem
                    key={meta.meta_id}
                    meta={meta}
                    howToPlay={howToPlays[meta.meta_id]}
                    showCaption
                    showFreeCoinOnly
                    showNew={webApp.utils.showNew(meta)}
                    onClickThumbnail={() => this.onClickItem(meta)}
                    onClickCaption={() =>
                      this.onClickItem(meta, null, null, false)
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        {/* 初期表示時 */}
        {isInitial && (
          <div className="c-searchResults">
            <div className="c-card-panel input">
              <div className="c-card-panel-head">
                <h3>テレ朝動画のオススメ</h3>
              </div>
              <div className="c-card-panel-cont">
                {recommends.map((object) => {
                  const meta = object.meta || null
                  const howToPlay = meta && howToPlaysForPalettes[meta.meta_id]
                  const productId =
                    _.get(object, ['advertising', 'values', 'product']) || null
                  const courseId =
                    _.get(object, ['advertising', 'values', 'course']) || null
                  const product = productId && products[productId]
                  const course = courseId && courses[courseId]

                  const paletteAd = _.get(object, ['advertising'])
                  if (
                    paletteAd &&
                    paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT
                  ) {
                    const thumbnail =
                      _.get(paletteAd, [
                        'creatives',
                        0,
                        'attachment',
                        'file_url'
                      ]) || this.context.models.config.data.default_thumbnail
                    return (
                      <ProgramItem
                        key={`${object.type}-${object.id}`}
                        title={paletteAd.name}
                        thumbnail={thumbnail}
                        onClick={() => {
                          if (paletteAd.url)
                            window.location.href = paletteAd.url
                        }}
                      />
                    )
                  }

                  return (
                    <ProgramItem
                      key={`${object.type}-${object.id}`}
                      meta={meta}
                      product={product}
                      course={course}
                      howToPlay={howToPlay}
                      showCaption
                      showInCourse
                      breakSubTitle
                      showFreeCoinOnly
                      showNew={webApp.utils.showNew(meta)}
                      onClickThumbnail={() =>
                        this.onClickItem(meta, product, course)
                      }
                      onClickCaption={() =>
                        this.onClickItem(meta, product, course, false)
                      }
                    />
                  )
                })}
              </div>
              <div className="c-more">
                <Link route={routes.app_recommend} className="c-more-btn">
                  <span className="c-more-btn-txt link" />
                </Link>
              </div>
            </div>
            <div className="c-card-panel input">
              <div className="c-card-panel-head">
                <h3>新着動画</h3>
              </div>
              <div className="c-card-panel-cont open">
                <div className="c-card-panel-cont">
                  {newArrivals.map((meta) => (
                    <ProgramItem
                      key={meta.meta_id}
                      meta={meta}
                      howToPlay={howToPlaysForPalettes[meta.meta_id]}
                      showCaption
                      breakSubTitle
                      showFreeCoinOnly
                      showNew={webApp.utils.showNew(meta)}
                      onClickThumbnail={() => this.onClickItem(meta)}
                      onClickCaption={() =>
                        this.onClickItem(meta, null, null, false)
                      }
                    />
                  ))}
                </div>
                <div className="c-more">
                  <Link route={routes.app_newarrival} className="c-more-btn">
                    <span className="c-more-btn-txt link" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer className="mp-mt0" />
      </div>
    )
  }
}
