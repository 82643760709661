import React, { useEffect, useState, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import styled from 'styled-components'
import webApp from '../../../../../utils/exdioWebAppUtils'

/* components */
import ListItem from '../EpisodeList/ListItem'

/* style */
import { mediaQuery } from '../../../../style'
import { StyledTitle } from '../EpisodeList/ListItem/style'
import { StyledChecked } from '../EpisodeList/ListItem/Title'
import { StyledSlider } from '../../styles'

/** ドラえもん 今月のテーマ */
const MonthlyTheme = (
  {
    objects = [],
    favorites = [],
    toggleFavorites = () => {},
    context,
    ...props
  },
  ref
) => {
  const model = context.falcorModel.batch(100)
  /** 最新のテーマを取得 (jsonで最初に来るもの) */
  const latestTheme = get(objects, [0])
  const [themeId, setThemeId] = useState(latestTheme.id || 1)
  const [themeIds, setThemeIds] = useState([])
  const [metas, setMetas] = useState([])
  const filterObjects = [...objects].filter((object) => object.id === themeId)
  const sliderRef = useRef(null)

  const getMetas = async (metaId) => {
    const path = ['metas', metaId]
    return model
      .fetch([path], {
        mode: 'cors'
      })
      .then((result) => get(result, ['json', ...path], []))
  }

  /** スライダー設定 */
  const sliderSetting = {
    dots: false,
    infinite: false,
    speed: 400,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          variableWidth: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          variableWidth: true
        }
      }
    ]
  }

  useEffect(() => {
    setThemeIds(get(filterObjects, [0, 'items'], []).map((item) => item.id))
  }, [themeId])

  useEffect(() => {
    ;(async () => {
      const fetchedMetas = await Promise.all(themeIds.map(getMetas))
      setMetas(fetchedMetas)
    })()
  }, [themeIds])

  return (
    <StyledSection {...props} ref={ref}>
      <StyledH2>
        <ruby>
          今月<rt>こんげつ</rt>
        </ruby>
        のテーマ
      </StyledH2>
      <StyledH3>{get(filterObjects, [0, 'themetitle'], '')}</StyledH3>
      <StyledP>{get(filterObjects, [0, 'themecaption'], '')}</StyledP>

      {size(objects) > 1 && (
        <StyledDiv2>
          <StyledSelect onChange={(e) => setThemeId(Number(e.target.value))}>
            {objects.map(({ id, themetitle }) => {
              return (
                <option value={id} key={id}>
                  {themetitle}
                </option>
              )
            })}
          </StyledSelect>
        </StyledDiv2>
      )}

      <StyledSlider ref={sliderRef} {...sliderSetting}>
        {metas.map((meta, index) => {
          let deliveryDate = ''
          const exPublishStartAt = get(
            meta,
            ['values', 'ex_PublishStartAt'],
            ''
          )
          if (exPublishStartAt !== '') {
            const parts = exPublishStartAt.split(' ')
            if (parts.length > 0) {
              deliveryDate = parts[0]
            }
          }
          const isFree = get(filterObjects, [0, 'items', index, 'free'], [])

          const isFavorite = favorites.find(
            (favorite) =>
              get(favorite, ['meta', 'meta_id']) === get(meta, ['meta_id'])
          )
          const itemRoutes = webApp.utils.getProgramLinkRoutes(
            context,
            meta,
            null,
            null,
            {
              autoPlay: true
            }
          )

          if (size(meta) === 0) return null

          return (
            <StyledDiv key={meta.id}>
              <StyledListItem
                isFree={isFree}
                meta={meta}
                showNew={webApp.utils.showNew(meta)}
                showChecked={webApp.utils.isWatched(meta, true)}
                onlySubTitle
                clampWidth={3}
                listType="grid"
                images={{
                  on: '/images/exdio/renewal/doraemon/watched.png',
                  off: '/images/exdio/renewal/doraemon/not_watched.png'
                }}
                deliveryDate={deliveryDate}
                {...itemRoutes}
              />
              <StyledLabel
                htmlFor={`fav-${meta.meta_id}`}
                title={isFavorite ? `マイリストから削除` : `マイリストに追加`}
                isFavorite={isFavorite}
              >
                <input
                  type="checkbox"
                  id={`fav-${meta.meta_id}`}
                  name="favorite"
                  value={meta.meta_id}
                  checked={Boolean(isFavorite)}
                  onChange={() => toggleFavorites(meta, !isFavorite)}
                />
                <img
                  src={`/images/exdio/renewal/doraemon/my_list_${
                    isFavorite ? 'added' : 'removed'
                  }.svg`}
                  width="20"
                  height="18"
                  alt=""
                />
              </StyledLabel>
            </StyledDiv>
          )
        })}
      </StyledSlider>
    </StyledSection>
  )
}

export default forwardRef(MonthlyTheme)

MonthlyTheme.propTypes = {
  context: PropTypes.object,
  objects: PropTypes.array,
  favorites: PropTypes.array,
  toggleFavorites: PropTypes.func
}

const StyledSection = styled.section`
  margin: 0 auto;
  padding: 60px 60px 0;
  max-width: 1800px;
  position: relative;

  ${mediaQuery()} {
    padding: 30px 0 0;
  }
`

const StyledH2 = styled.h2`
  font-weight: 700;
  font-size: 2.6rem;
  margin-bottom: 2rem;
`

const StyledH3 = styled.h3`
  position: relative;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1rem;

  &::before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url(/images/exdio/renewal/doraemon/monthlytheme_dora.png)
      no-repeat top center / 100%;
    margin-right: 10px;
    vertical-align: sub;
  }
`

const StyledP = styled.p`
  font-weight: 700;
  margin-bottom: 2rem;
`

const StyledDiv = styled.div`
  position: relative;
  display: inline-grid !important; // slickに上書きされるため!importantつける
  grid: repeat(2, auto) / 1fr auto;
  padding: 0 10px;

  ${mediaQuery()} {
    padding: 0;
  }
`

const StyledListItem = styled(ListItem)`
  grid-column: 1 / 3;
  grid-row: 1 / 3;

  ${StyledTitle} {
    padding-right: 45px;
    position: relative;

    ${StyledChecked} {
      margin-right: 0;
      width: 20px;
      height: 18px;
      position: absolute;
      top: 1px;
      right: 25px;
    }
  }
`

export const StyledLabel = styled.label.withConfig({
  shouldForwardProp: (prop) => !['isFavorite'].includes(prop)
})`
  position: relative;
  bottom: calc(15px * 1.25 * 3 - 20px); // ListItem > StyledPに合わせる
  z-index: 1;
  grid-column: 2;
  grid-row: 2;
  transition: opacity 0.4s;
  cursor: pointer;

  ${mediaQuery()} {
    bottom: calc(13px * 1.25 * 3 - 22px); // ListItem > StyledPに合わせる
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`

const StyledSelect = styled.select`
  border: 2px solid #fff;
  background-color: #c0eafd;
  color: #00a0e9;
  padding: 10px 70px 10px 20px;
  font-size: 1.4rem;
  border-radius: 20px;
  cursor: pointer;

  ${mediaQuery()} {
    width: 100%;
  }
`

const StyledDiv2 = styled.div`
  width: fit-content;
  position: absolute;
  top: 60px;
  right: 50px;

  &::before {
    position: absolute;
    top: 50%;
    right: 15px;
    content: '';
    display: block;
    width: 15px;
    height: 13px;
    background: url(/images/exdio/renewal/doraemon/arrow2.png) no-repeat top
      center / 100%;
    transform: translateY(-50%);
  }

  ${mediaQuery()} {
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 2rem;
    width: 100%;
  }
`
