import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledDiv, StyledImg, StyledP } from './style'

/**
 * Sample Child Component (Dev Only).
 * Description of Component.
 */
const ChildComponent = ({
  somethingString = '',
  somethingImagePath = '',
  ...props
}) => {
  return (
    <StyledDiv {...props}>
      <StyledImg src={somethingImagePath} alt={somethingString} />
      <StyledP>{somethingString}</StyledP>
    </StyledDiv>
  )
}

export default ChildComponent

ChildComponent.propTypes = {
  /** Description of somethingString. */
  somethingString: PropTypes.string,
  /** Description of somethingImagePath */
  somethingImagePath: PropTypes.string
}
