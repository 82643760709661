import React from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import { Link } from '../../../../../sketch-platform/ui/routing'
import { MYPAGE_CONTENTS } from '../../../../../constants/app'

const LinkLoginOrMyPage = ({ ...props }, context) => {
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isApp = webApp.utils.isApp(context)

  const linkData = isLoggedIn
    ? // ログイン時
      {
        route: routes[isApp ? 'app_mypage' : 'mypage'],
        style: { backgroundColor: '#0baffd' },
        text: 'マイページ',
        state: {
          content: MYPAGE_CONTENTS.TAB.PURCHASED
        }
      }
    : // ログアウト時
      {
        route: routes[isApp ? 'app_login' : 'login'],
        query: { redirect: context.routeHandler.path } || null,
        style: { backgroundColor: '#4ad28a' },
        text: 'ログイン'
      }

  // アプリのときマイページボタンを表示しない
  if (isLoggedIn && isApp) return null

  return (
    <div className="c-pageBtn mt10" {...props}>
      <Link className="c-pageBtn-link" {...linkData}>
        {linkData.text}
      </Link>
    </div>
  )
}

export default LinkLoginOrMyPage

LinkLoginOrMyPage.contextTypes = {
  routeHandler: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string
  }),
  models: PropTypes.shape({
    authContext: PropTypes.shape({
      data: PropTypes.object
    })
  })
}
