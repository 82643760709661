import React, { useRef, useEffect, useState } from 'react'
import useMediaQuery from '../../../../../../hooks/useMediaQuery'
import PropTypes from 'prop-types'
import IconText from './IconText'
import InfoCaption from './InfoCaption'
import InfoHeading from './InfoHeading'
import List from './List'
import styled from 'styled-components'
import { mediaPc } from '../../styles'

/** そだてれび：海外視聴用 PC 単話ページ動画詳細 情報 */
const DetailInfo = ({
  params,
  title,
  subTitle,
  time,
  delivery_date,
  release_date,
  isNew,
  content,
  className
}) => {
  const infoInnerRef = useRef(null)
  const infoHeaderRef = useRef(null)

  // captionエリアの高さ
  const [captionHeight, setCaptionHeight] = useState(0)
  const isPc = useMediaQuery('max', 1320)

  useEffect(() => {
    if (!infoInnerRef.current || !infoHeaderRef.current) return
    setCaptionHeight(
      infoInnerRef.current.clientHeight - infoHeaderRef.current.clientHeight
    )
  }, [infoInnerRef, infoHeaderRef, isPc])

  return (
    <StyledDiv1 className={className}>
      <StyledDiv2 ref={infoInnerRef}>
        <div ref={infoHeaderRef}>
          <InfoHeading
            params={params}
            title={title}
            subTitle={subTitle}
            isNew={isNew}
          />
          <List>
            <IconText icon="\e8ae">Time：{time}</IconText>
            <IconText icon="\e04b">OnAir：{release_date[0]}</IconText>
            <IconText icon="\e916">
              Delivery Period：{delivery_date[0]}〜
            </IconText>
          </List>
        </div>
        <StyledDiv3 height={captionHeight}>
          <InfoCaption>{content}</InfoCaption>
        </StyledDiv3>
      </StyledDiv2>
    </StyledDiv1>
  )
}

DetailInfo.propTypes = {
  params: PropTypes.shape({
    seriesId: PropTypes.string,
    seasonId: PropTypes.string
  }),
  title: PropTypes.string,
  time: PropTypes.string,
  delivery_date: PropTypes.arrayOf(PropTypes.string),
  release_date: PropTypes.arrayOf(PropTypes.string),
  content: PropTypes.string,
  isNew: PropTypes.bool
}

export default DetailInfo

const StyledDiv1 = styled.div`
  background: rgba(255, 255, 255, 0.7);
  color: #e6ebef;
  padding: 10px;
  height: 360px;

  @media ${mediaPc} {
    width: 600px;
  }
`

const StyledDiv2 = styled.div`
  height: 100%;
`

const StyledDiv3 = styled.div`
  max-height: ${({ height }) => `${height}px`};
  overflow: auto;
`
