import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import ProgramItemSkeleton from './ProgramItemSkeleton';

/** パレットコンポーネント */
class PaletteSkeleton extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  cardSliderController() {
    let skeletonObjects = []
    for (let i = 0; i < 6; i++) {
      skeletonObjects.push(
        <ProgramItemSkeleton
          key={i}
          hasThumb={true}
          titleLength={2}
        />
      )
    }
    return skeletonObjects
  }

  render() {
    return (
      <div className="c-cards">
        <header className="c-cards-head" style={{display: 'block'}}>
          <h3 className="c-cards-head-hedding">
            <span className="c-cards-head-hedding-inner"><Skeleton variant="rect" style={{width: '25%'}} /></span>
          </h3>
        </header>
        <div className="c-card-slider">
          <div className="c-card-slider-cont" ref={this.setSliderContentRef}>
            <this.cardSliderController />
          </div>
        </div>
      </div>
    )
  }
}

export default PaletteSkeleton;
