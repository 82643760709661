import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ProgramItem from '../../../../common/components/renewal/ProgramItem';
import webApp from '../../../utils/exdioWebAppUtils';
import SidePaletteTab from './SidePaletteTab';
import { META_SCHEMA_ID } from '../../../../../constants/app';

/** 詳細画面:サイドナビ:パレットコンポーネント */
export default class SidePalette extends Component {
  static propTypes = {
    /** 見出し */
    paletteTitle: PropTypes.string,
    /** パレットキー */
    paletteKeys: PropTypes.arrayOf(PropTypes.string),
    /** タブのラベル */
    paletteLabels: PropTypes.arrayOf(PropTypes.string),
    /** 無料のみコインを表示するか */
    showOnlyFreeCoin: PropTypes.bool
  };

  static defaultProps = {
    paletteTitle: 'テレ朝動画のオススメ',
    paletteKeys: [],
    paletteLabels: ['無料', '有料'],
    showOnlyFreeCoin: false
  };

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.config = context.models.config.data;
    this.state = {
      paletteMetas: [],
      howToPlays: {}
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getPalette().then(() => this.getHowToPlays());
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /** パレット情報詳細取得 */
  getPalette() {
    const { paletteKeys } = this.props;
    const keys =
      paletteKeys && paletteKeys.length
        ? paletteKeys
        : [this.config.detail_page.side_palette_key.free, this.config.detail_page.side_palette_key.not_free];
    const path = [['paletteByKey', keys, ['name', 'objects']]];
    return this.model.fetch(path).then(result => {
      const paletteMetas = [];
      keys.forEach(key => {
        const palette = _.get(result, ['json', 'paletteByKey', key]) || {};
        const metas = (palette.objects || []).filter(o => o.type === 'meta').map(o => o.meta);
        paletteMetas.push(metas);
      });
      if (this._isMounted) {
        this.setState({ paletteMetas });
      }
    });
  }

  /** 価格情報取得 */
  getHowToPlays() {
    const { paletteMetas } = this.state;
    if (!paletteMetas.length) return Promise.resolve();

    const metaIds = paletteMetas
      .flatten()
      .map(e => e.meta_id)
      .filter((e, i, self) => self.indexOf(e) === i); // distinct
    const path = [['meta', 'howToPlay', false, metaIds]];
    return this.model.fetch(path).then(result => {
      const howToPlays = _.get(result, ['json', 'meta', 'howToPlay', false]) || {};
      if (this._isMounted) {
        this.setState({ howToPlays });
      }
    });
  }

  render() {
    const { paletteTitle, paletteLabels, showOnlyFreeCoin } = this.props;
    const { paletteMetas, howToPlays } = this.state;
    return (
      <SidePaletteTab tabs={paletteLabels} title={paletteTitle} style={{ marginTop: `${4}rem` }}>
        {paletteMetas.map((metas, i) => (
          <div key={i} className="c-card-vertical sub">
            <div className="c-card-vertical-cont">
              {metas.map(meta => {
                const isFree = META_SCHEMA_ID.EPISODE === meta.meta_schema_id || META_SCHEMA_ID.LIVE === meta.meta_schema_id;
                return (
                  <ProgramItem
                    key={meta.meta_id}
                    meta={meta}
                    howToPlay={howToPlays[meta.meta_id]}
                    showCaption
                    showCoin={!showOnlyFreeCoin || isFree}
                    showNew={webApp.utils.showNew(meta)}
                    onClickThumbnail={() => webApp.utils.goToProgramLink(this.context, meta)}
                    onClickCaption={() => webApp.utils.goToProgramLink(this.context, meta, null, null, { autoPlay: false })}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </SidePaletteTab>
    );
  }
}
