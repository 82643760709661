import styled from 'styled-components'
import { mediaSp } from '../../styles'

export const StyledButton = styled.button`
  padding: 0;
  width: 65px;
  background-color: #fff;
  border: 3px solid #4a4a4a;
  border-radius: 6px;
  line-height: 0;
  cursor: pointer;

  @media ${mediaSp} {
    width: 50px;
  }
`
