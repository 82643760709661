import React, { memo } from 'react'
import styled from 'styled-components'
import { mainColor } from './styles'

/** 全英オープン Heading2 */
const Heading2 = ({ children, ...props }) => {
  return <StyledH1 {...props}>{ children }</StyledH1>
}

export default memo(Heading2)

const StyledH1 = styled.h1`
margin-top: 16px;
padding-bottom: 6px;
border-bottom: 1px solid #ccc;
color: ${mainColor};
font-size: 1.8rem;
font-weight: bold;

@media (max-width: 1024px) {
  margin-top: 10px;
  padding-bottom: 10px;
  line-height: 1;
  font-size: 1.4rem;
}
`
