import React from 'react'
import PropTypes from 'prop-types'

/** Arrow Component */
const Arrow = ({ isNext = false, scrollPalette = '', ...props }) => {
  const slug = isNext ? 'next' : 'prev'
  const scrollDirection = isNext ? 1 : -1

  return (
    <div
      className={`c-card-slider-nav-${slug}`}
      onClick={() => scrollPalette(scrollDirection)}
      {...props}
    >
      <span className={`c-card-slider-nav-${slug}-img`} />
    </div>
  )
}

export default Arrow

Arrow.propTypes = {
  /** 次/前の判別 */
  isNext: PropTypes.bool,
  /** スライド管理 */
  scrollPalette: PropTypes.func
}
