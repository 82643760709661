import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import styled from 'styled-components'
import { mediaQuery } from '../../../style'

/** チャット機能 */
const Chat = ({ hash = '', setPostMessage = () => {}, ...props }, context) => {
  if (!hash) return null

  const config = context.models.config.data
  const parentUrl = window.location.href || ''
  const params = {
    hash,
    template_type: 'DIO',
    parent_url: parentUrl
  }
  const paramsStr = new URLSearchParams(params).toString()
  const src = `${config.chat.src}?${paramsStr}`

  const iframeRef = useRef(null)

  const handleSeekTime = (e) => {
    const { origin, data } = e
    if (!(config.chat.origin.includes(origin) && data)) return

    const seekTime = get(data, ['seekTime'])
    if (typeof seekTime !== 'number') return

    const DioPlayer = document.getElementById('DIOplayer')
    const videoElm = DioPlayer ? DioPlayer.querySelector('video') : null
    if (videoElm) {
      videoElm.currentTime = seekTime
    }
  }

  /** プレイヤー操作をチャットに送信 */
  const postMessage = (seekTime) => {
    iframeRef.current.contentWindow.postMessage({ seekTime }, '*')
  }

  useEffect(() => {
    setPostMessage(postMessage)

    if (window !== undefined) {
      window.addEventListener('message', handleSeekTime, false)
    }

    return () => {
      window.removeEventListener('message', handleSeekTime, false)
    }
  }, [])

  return (
    <StyledIframe
      src={src}
      width="400"
      height="478"
      frameBorder="0"
      scrolling="no"
      ref={iframeRef}
      {...props}
    />
  )
}

export default Chat

Chat.propTypes = {
  hash: PropTypes.string.isRequired,
  setPostMessage: PropTypes.func
}

Chat.contextTypes = {
  models: PropTypes.object
}

const StyledIframe = styled.iframe`
  width: 100%;
  height: 478px;
  background-color: #fff;
  overscroll-behavior-y: none;

  ${mediaQuery()} {
    height: 100%;
  }
`
