import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { pink } from '../../styles'

/** 在学証明書｜ユーザー情報 */
const CertificateList = ({ point = 0, nickname = '' }) => {
  return (
    <dl>
      <StyledDiv>
        <dt>
          <img
            src="/images/exdio/renewal/gariben_daigaku/components/certificate-name.svg"
            alt="ニックネーム"
          />
        </dt>
        <StyledDd>{nickname}</StyledDd>
      </StyledDiv>
      <StyledDiv>
        <dt>
          <img
            src="/images/exdio/renewal/gariben_daigaku/components/certificate-point.svg"
            alt="単位ポイント"
          />
        </dt>
        <StyledDd>
          <StyledSpan>{point}</StyledSpan>
          単位
        </StyledDd>
      </StyledDiv>
      <StyledDiv>
        <dt>
          <img
            src="/images/exdio/renewal/gariben_daigaku/components/certificate-belong.svg"
            alt="所属 私立ガリベン大学 全学部 全学科"
          />
        </dt>
        <StyledDd></StyledDd>
      </StyledDiv>
    </dl>
  )
}

export default CertificateList

CertificateList.propTypes = {
  /** 保有単位ポイント */
  point: PropTypes.number,
  /** ユーザーのニックネーム */
  nickname: PropTypes.string
}

const StyledDiv = styled.div`
  height: 27px;
  border-bottom: 2px solid #333;
  display: flex;
  align-items: center;
  color: ${pink};
  font-size: 1.2rem;
  font-weight: 700;
`

const StyledDd = styled.dd`
  margin-left: 30px;
  display: -webkit-box;
  flex: 1 0;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

const StyledSpan = styled.span`
  font-size: 1.8rem;
`
