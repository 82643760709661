import React, { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import useMediaQuery from '../../../../../../hooks/useMediaQuery'

/* style */
import { StyledDivInner } from '../../style'
import styled from 'styled-components'
import { useIntersectionObserver } from './useIntersectionObserver'
import { mediaQuery, pxToVw } from '../../../../../../exdio/components/style'

/** 必殺仕事人 LP | 人気の必殺シリーズ */
const PopularSeries = ({ popularSeriesItem = [], i }) => {
  const isSp = useMediaQuery(768)

  useIntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('on')
        }
      })
    },
    { threshold: [0.2, 1] }
  )

  return (
    <StyledSection1 bg={popularSeriesItem.bg} className="scroll">
      <StyledDivInner>
        {i === 0 && (
          <StyledH2>
            <img
              src="/images/exdio/renewal/hissatsu/popularseries_title.png"
              alt="人気の必殺シリーズ"
            />
          </StyledH2>
        )}
        <StyledSection2>
          <StyledDiv1>
            <StyledLink href={popularSeriesItem.href}>
              <img src={popularSeriesItem.img} alt="" />
            </StyledLink>
            {!isSp && (
              <StyledLink href={popularSeriesItem.href}>
                <img
                  src="/images/exdio/renewal/hissatsu/watch_btn.jpg"
                  alt="テレ朝動画で視聴する"
                />
              </StyledLink>
            )}
          </StyledDiv1>
          <StyledDiv2>
            <StyledH3
              dangerouslySetInnerHTML={{ __html: popularSeriesItem.title }}
            />
            <StyledP1
              dangerouslySetInnerHTML={{ __html: popularSeriesItem.text }}
            />
            <StyledP2
              dangerouslySetInnerHTML={{ __html: popularSeriesItem.cast }}
            />
            <StyledP3
              dangerouslySetInnerHTML={{ __html: popularSeriesItem.song }}
            />
          </StyledDiv2>
          {isSp && (
            <StyledLink href={popularSeriesItem.href}>
              <img
                src="/images/exdio/renewal/hissatsu/watch_btn.png"
                alt="テレ朝動画で視聴する"
              />
            </StyledLink>
          )}
        </StyledSection2>
      </StyledDivInner>
    </StyledSection1>
  )
}

export default memo(PopularSeries, areEqual)

PopularSeries.propTypes = {
  /**
   * `PopularSeries` コンポーネントは、人気シリーズの詳細情報を表示します。
   *
   * @param {Object} popularSeriesItem - 人気シリーズの詳細情報
   * @param {string} popularSeriesItem.href - シリーズ詳細へのリンク
   * @param {string} popularSeriesItem.img - シリーズのイメージ画像URL
   * @param {string} popularSeriesItem.bg - 背景画像のURL
   * @param {string} popularSeriesItem.title - シリーズのタイトル
   * @param {string} popularSeriesItem.text - シリーズに関する説明文
   * @param {string} popularSeriesItem.cast - シリーズの出演キャスト
   * @param {string} popularSeriesItem.song - シリーズのテーマ曲
   */
  popularSeriesItem: PropTypes.shape({
    href: PropTypes.string,
    img: PropTypes.string,
    bg: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    cast: PropTypes.string,
    song: PropTypes.string
  })
}

const areEqual = (prevProps, nextProps) =>
  Object.keys(prevProps.popularSeriesItem).every(
    (key) =>
      prevProps.popularSeriesItem[key] === nextProps.popularSeriesItem[key]
  )

const StyledSection2 = styled.section`
  display: flex;
  gap: 50px;

  ${mediaQuery(768)} {
    flex-direction: column;
    gap: 10px;
  }
`

const StyledDiv1 = styled.div`
  width: calc(470 / 1080 * 100%);
  transform: translateY(25%);
  transition: transform 0.6s;
  opacity: 0;

  .on & {
    transform: translateX(0);
    opacity: 1;
  }

  ${mediaQuery(768)} {
    width: 100%;

    img {
      width: 100%;
    }
  }
`

const StyledDiv2 = styled.div`
  width: calc(560 / 1080 * 100%);
  color: #fff;
  line-height: 1.4;
  font-size: 1.6rem;
  transform: translateX(25%);
  transition: transform 0.6s;
  opacity: 0;

  .on & {
    transform: translateX(0);
    opacity: 1;
  }

  ${mediaQuery(768)} {
    width: 100%;
    font-size: ${pxToVw(26, 750)};
  }
`

const StyledSection1 = styled.section`
  padding: 80px 0;
  background: url(${({ bg }) => bg}) no-repeat top center / cover;

  &:nth-of-type(even) {
    ${StyledSection2} {
      flex-direction: row-reverse;
    }

    ${StyledDiv2} {
      transform: translateX(-25%);
    }

    &.on ${StyledDiv2} {
      transform: translateX(0);
      opacity: 1;
    }
  }

  ${mediaQuery(768)} {
    padding: 30px 0;

    &:nth-of-type(even) {
      ${StyledSection2} {
        flex-direction: column;
      }
    }
  }
`

const StyledLink = styled(Link)`
  display: block;
  margin-top: 10px;

  img {
    width: 100%;
  }
`

const StyledH3 = styled.h3`
  color: #dcaf05;
  font-size: 3.2rem;

  ${mediaQuery(768)} {
    font-size: ${pxToVw(44, 750)};
  }
`

const StyledP1 = styled.p`
  margin-top: 25px;
  font-size: 2.2rem;

  ${mediaQuery(768)} {
    font-size: ${pxToVw(32, 750)};
  }
`

const StyledP2 = styled.p`
  margin-top: 25px;
`

const StyledP3 = styled.p`
  margin-top: 25px;
`

const StyledH2 = styled.h2`
  margin-bottom: 90px;
  text-align: center;

  ${mediaQuery(768)} {
    margin-bottom: 30px;

    img {
      width: min(${pxToVw(610, 750)}, 610px);
    }
  }
`
