import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import webApp from '../../../../../utils/exdioWebAppUtils'
import routes from '../../../../../../common/routes'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'
import HowToPlayLink from '../../../details/HowToPlayLink'
import { mediaQuery } from '../../../../style'
import Btn from '../Btn'

const Fv = ({ isPurchased = false, setFvHeight = () => {} }, context) => {
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const config = context.models.config.data
  const isApp = webApp.utils.isApp(context)
  const slug = 'doraemon'
  const loginBtnProps = {
    route: isApp ? routes.app_login : routes.login,
    query: {
      redirect: isApp
        ? routes.app_plan.makePath({ slug })
        : routes.plan.makePath({ slug })
    }
  }
  const host = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 'pwww.tv-asahi.co.jp'
    : 'www.tv-asahi.co.jp'
  const unlimitedPackLink = `https://${host}/douga_mv/doraemon_lp/`
  const fvRef = useRef(null)

  useEffect(() => {
    if (fvRef.current) {
      setFvHeight(fvRef.current.clientHeight)
    }
  }, [])

  return (
    <StyledDiv ref={fvRef}>
      <StyledDivInner>
        <StyledLink route={isApp ? routes.app_home : routes.home}>
          <img
            src="/images/exdio/renewal/doraemon/logo_tv_asahi_douga.jpg"
            alt="テレ朝動画"
          />
        </StyledLink>

        <h1>
          <StyledPicture>
            <source
              media="(max-width: 1024px)"
              srcSet={`/images/exdio/renewal/doraemon/fv_sp${
                isApp ? '_app' : ''
              }.png`}
              width="1500"
              height="1222"
            />
            <img
              src={`/images/exdio/renewal/doraemon/fv_character${
                isApp ? '_app' : ''
              }.png`}
              alt="ドラえもんTV"
              width="1060"
              height="398"
            />
          </StyledPicture>
        </h1>

        {/** 空のリストを表示しない（余白のみ表示されるため） */}
        {((!isApp && (!isLoggedIn || !isPurchased)) || isApp) && (
          <StyledUl>
            {!isLoggedIn && (
              <li>
                <Btn {...loginBtnProps} text="ログイン" />
              </li>
            )}
            {isApp && (
              <li>
                <HowToPlayLink isPlanPage />
              </li>
            )}
            {!isApp && !isPurchased && (
              <>
                <li>
                  <Btn
                    href={config.purchase_plan.doraemon}
                    color="blue"
                    text="購入する"
                  />
                </li>
                <li>
                  <Btn
                    href={unlimitedPackLink}
                    color="pink"
                    text="ドラえもんTVとは？"
                  />
                </li>
              </>
            )}
          </StyledUl>
        )}

        {!isApp && isPurchased && (
          <StyledUl>
            <li>
              <Btn
                href={`${unlimitedPackLink}#top3`}
                color="pink"
                text="視聴方法"
              />
            </li>
          </StyledUl>
        )}
      </StyledDivInner>
    </StyledDiv>
  )
}

export default Fv

Fv.propTypes = {
  isPurchased: PropTypes.bool,
  setFvHeight: PropTypes.func
}

Fv.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}

const StyledDiv = styled.div`
  background: #5879ae url(/images/exdio/renewal/doraemon/fv_bg.png) no-repeat
    top center / cover;
`

const StyledDivInner = styled.div`
  margin: 0 auto;
  padding: 16px 10px 0;
  max-width: 1080px;
  position: relative;

  ${mediaQuery()} {
    padding: 10px 0 0;
  }
`

// ロゴ
const StyledLink = styled(Link)`
  max-width: 236px;
  display: block;
  position: absolute;

  ${mediaQuery()} {
    max-width: 110px;
    left: 10px;
  }

  @media (hover: hover) {
    transition: opacity 0.4s;

    &:hover {
      opacity: 0.7;
    }
  }
`

const StyledPicture = styled.picture`
  width: 100%;
  display: block;
  line-height: 0;

  img {
    width: 100%;
  }
`

const StyledUl = styled.ul`
  width: calc(230 / 1080 * 100%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  right: 10px;
  top: 100px;

  ${mediaQuery()} {
    margin: 0 auto;
    padding-bottom: 20px;
    width: calc(100% - 20px);
    position: static;
  }
`
