import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import webApp from '../../../../../utils/exdioWebAppUtils'
import SliderTitle from '../../../program_harbor/SliderTitle'

const Slider2 = ({ episodes, nav, setSlider }, context) => {
  const settings = {
    arrows: false,
    infinite: true,
    fade: true,
    slidesToShow: 1
  }

  return (
    <Slider {...settings} asNavFor={nav} ref={(slider) => setSlider(slider)}>
      {episodes &&
        episodes.map((meta) => {
          return (
            <SliderTitle
              key={meta.meta_id}
              meta={meta}
              onClickTitle={() => webApp.utils.goToProgramLink(context, meta)}
            />
          )
        })}
    </Slider>
  )
}

Slider2.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

Slider2.propTypes = {
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    })
  )
}

export default Slider2
