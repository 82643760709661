import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledH2, StyledSpan, StyledConvertSvg } from './style'

/** Logirlプランページ | ページタイトル1 */
const Hdg1 = ({ src, alt, modifier, children, ...props }) => {
  return (
    <StyledH2 data-modifier={modifier} {...props}>
      <StyledConvertSvg src={src} alt={alt} />
      <StyledSpan>{children}</StyledSpan>
    </StyledH2>
  )
}

export default Hdg1

Hdg1.propTypes = {
  /** h2タグのclassに付与するmodifier */
  modifier: PropTypes.string,
  /** アイコン画像のリンク */
  src: PropTypes.string,
  /** アイコン画像のalt */
  alt: PropTypes.string,
  /** styled-components拡張の為のclassName */
  className: PropTypes.string
}

Hdg1.defaultProps = {
  modifier: '',
  src: '',
  alt: '',
  className: ''
}
