import styled from "styled-components";

export const ImgDuratuionLabel = styled.div`
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.3;
`

export const ImgMask = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.5);
`

export const ImgNewLabel = styled.div`
  overflow: hidden;
  font-size: 0.9rem;

  &::before {
    position: absolute;
    top: -36px;
    left: -30px;
    width: 60px;
    height: 60px;
    content: '';
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
    background-color: ${({color = '#ff1'}) => color};
  }
  &::after {
    position: absolute;
    content: 'NEW';
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 1rem;
    left: 0.3rem;
    font-size: 0.9rem;
    font-weight: 600;
    z-index: 1;
  }
`

export const ImgRemainLabel = styled.div`
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
  padding: 5px 7px;
  background-color: ${({color = '#f0027f'}) => color};
`
