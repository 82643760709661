import styled from 'styled-components'
import { mediaQuery } from '../../../../exdio/components/style'

export const StyledDiv = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop)
})`
  height: 36px;
  position: relative;

  ${mediaQuery()} {
    // 縦積みのとき重なってしまうため、StyledUlのz-indexより大きい値を入れる
    z-index: ${({ isOpen }) => (isOpen ? 2 : null)};
  }
`

export const StyledUl = styled.ul.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop)
})`
  height: ${({ isOpen }) => (isOpen ? 'auto' : '36px')};
  border: 1px solid #d3d6db;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  font-size: 1.6rem;
  line-height: 1;

  ${mediaQuery()} {
    font-size: 1.1rem;
  }

  &::after {
    margin: auto 0;
    width: 0;
    height: 0;
    border-width: 8px 5px 0;
    border-style: solid;
    border-color: #343434 transparent transparent;
    position: absolute;
    top: 18px;
    right: 10px;
    transform: ${({ isOpen }) =>
      isOpen ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)'};
    content: '';
  }
`

export const StyledLi = styled.li`
  border-top: 1px solid #d3d6db;

  &:first-child {
    border-top-width: 0;
  }
`
