import React, { Fragment, memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import styled from 'styled-components'
import routes from '../../../../../common/routes'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import webApp from '../../../../utils/exdioWebAppUtils'
import { META_SCHEMA_ID } from '../../../../../../constants/app'
import { mediaSp } from '../common/styles'

/** 全英オープン:単話ページングコンポーネント */
const EpisodePager = ({ meta, seriesId, seasonId }, context) => {
  if (!seriesId || !seasonId) return null

  const model = context.falcorModel.batch(100)
  const [prevMeta, setPrevMeta] = useState(null)
  const [nextMeta, setNextMeta] = useState(null)

  useEffect(() => {
    getPrevMeta()
    getNextMeta()
  }, [meta])

  /** 前のメタ情報取得 */
  const getPrevMeta = () => {
    const _prevMeta = get(meta, ['values', 'prev_meta'])
    if (!_prevMeta) return

    if (typeof _prevMeta === 'object') {
      return setPrevMeta(_prevMeta)
    }

    const path = ['metas', _prevMeta]
    return model.fetch([path]).then(result => {
      setPrevMeta(get(result, ['json', ...path], null))
    })
  }

  /** 次のメタ情報取得 */
  const getNextMeta = () => {
    const _nextMeta = get(meta, ['values', 'next_meta'])
    if (!_nextMeta) return

    if (typeof _nextMeta === 'object') {
      return setNextMeta(_nextMeta)
    }

    const path = ['metas', _nextMeta]
    return model.fetch([path]).then(result => {
      setNextMeta(get(result, ['json', ...path], null))
    })
  }

  /** metaからrouteを取得 */
  const getRoute = (nextOrPrevMeta) => {
    if (!nextOrPrevMeta) return null
    const metaSchemaId = get(nextOrPrevMeta, ['meta_schema_id'])
    const isCatchupMeta = metaSchemaId === META_SCHEMA_ID.EPISODE || metaSchemaId === META_SCHEMA_ID.LIVE
    const browserRoute = isCatchupMeta ? routes.catchupEpisode : routes.episode
    const appRoute = isCatchupMeta ? routes.app_catchupEpisode : routes.app_episode

    return webApp.utils.isApp(context) ? appRoute : browserRoute
  }

  return (
    <Fragment>
      {prevMeta && (
        <StyledButton
          route={getRoute(prevMeta)}
          params={{ seriesId, seasonId, episodeId: prevMeta.meta_id }}
          nextprev={'prev'}
        >前に戻る</StyledButton>
      )}
      {nextMeta && (
        <StyledButton
          route={getRoute(nextMeta)}
          params={{ seriesId, seasonId, episodeId: nextMeta.meta_id }}
          nextprev={'next'}
        >続きを見る</StyledButton>
      )}
    </Fragment>
  )
}

export default memo(EpisodePager)

EpisodePager.propTypes = {
  // 月額見放題に含まれない単話の場合
  meta: PropTypes.shape({
    values: PropTypes.shape({
      prev_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
      next_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
    })
  }),
  seriesId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

EpisodePager.defaultProps = {
  meta: null,
  seriesId: null,
  seasonId: null
}

EpisodePager.contextTypes = {
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object
}

const StyledButton = styled(Link)`
width: 220px;
height: 50px;
background: no-repeat center top / cover;
background-image: url(/douga_mv/british-open-golf/images/${props => props.nextprev === 'prev' ? 'preview_btn' : 'next_btn'}.png);
display: inline-flex;
justify-content: center;
align-items: center;
color: #fff !important;
font-size: 1.4rem;
transition: opacity .4s ease-in-out;

@media ${mediaSp} {
  width: 146px;
  height: 33px;
}

&::before {
  content: none;
}

&:hover {
  opacity: .8;
}

&:not(:only-child) {
  margin-left: 30px;

  @media ${mediaSp} {
    margin-left: 12px;
  }
}
`
