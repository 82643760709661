import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Footer from '../../../../common/components/Footer';
import DFPBanner from '../../../../common/components/DFPBanner';
import SidePalette from '../details/SidePalette';
import SideTab from '../details/SideTab';
import ProgramTopLink from '../details/ProgramTopLink';
import { FAVORITE_TYPE, LOCAL_STORAGE_KEY_PURCHASE, PURCHASE_TYPE } from '../../../../../constants/app';
import routes from '../../../../common/routes';
import SwitchableList from '../../../../common/components/renewal/SwitchableList';
import AddButtonBlock from '../details/AddButtonBlock';
import SideRecommend from '../details/SideRecommend';
import HtmlSnippet from '../../HtmlSnippet';

/** 月額見放題ページ テストテンプレート */
export default class PlanTemplateTest extends Component {
  static propTypes = {
    /** プランの情報 */
    course: PropTypes.shape({
      course_id: PropTypes.number.isRequired,
      schema_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired
    }).isRequired,
    /** プランに含まれる動画の情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** プランの購入情報 */
    status: PropTypes.shape({
      isPurchseAvailable: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool
    }),
    /** あなたへのおすすめ動画の情報 */
    recommendItems: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    episodes: [],
    status: {
      isPurchased: false,
      isNotPurchased: false
    },
    recommendItems: []
  };

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.config = this.context.models.config.data;
    this.state = {};

    this.onClickWatch = this.onClickWatch.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /** 視聴ボタン押下 */
  onClickWatch(e) {
    e.preventDefault();

    const { course } = this.props;
    if (!course || !Object.keys(course).length) return;

    const courseIdNum = Number(course.course_id);
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_PURCHASE,
      JSON.stringify({
        type: PURCHASE_TYPE.PLAN,
        id: courseIdNum,
        selected: [courseIdNum]
      })
    );
    this.context.history.push(routes.purchase.makePath());
  }

  /** シーズンIDリスト(他のシリーズ用) */
  allSeasonIds() {
    const { episodes } = this.props;
    const allSeasonIds = {};
    episodes.forEach(meta => {
      const seasonId = _.get(meta, ['values', 'parents_season', 'id'], null);
      if (seasonId) allSeasonIds[seasonId] = seasonId;
    });
    return Object.keys(allSeasonIds).sort();
  }

  render() {
    const { course, episodes, status, recommendItems } = this.props;

    const price = _.get(course, ['active_pricing', 'price']);
    const thumbnailUrl = _.get(course, ['thumbnail_url']) || this.config.default_thumbnail;
    const description = (_.get(course, ['description']) || '').replace(/\r?\n/g, '<br />');

    // 番組TOP用シリーズ・シーズンは１つ目の単話で代表
    const series = _.get(episodes, [0, 'values', 'parents_series'], {});
    const season = _.get(episodes, [0, 'values', 'parents_season'], {});

    // 他のシリーズ用ID
    const otherSeasonIds = _.get(course, ['values', 'other_series']) || [];
    const allSeasonIds = this.allSeasonIds() || [];

    const bannerId1 = _.get(course, ['values', 'banner_1']);
    const bannerId2 = _.get(course, ['values', 'banner_2']);

    let labelStatus = '';
    if (status.isNotPurchased) labelStatus = '未購入';
    if (status.isPurchased) labelStatus = '購入済み';

    return (
      <div className="common-wrapper">
        <DFPBanner position="head" meta_id="" key="banner" />

        <div>テンプレートテスト</div>
        <div className="c-headMeta">
          <div className="c-headMeta-metaBox">
            <div className="c-headMeta-metaBox-art">
              <div className="c-headMeta-metaBox-art-inBox clickable" onClick={this.onClickWatch}>
                <div className="c-headMeta-metaBox-art-inBox-ribbon">見放題</div>
                {labelStatus && (
                  <div className="c-headMeta-metaBox-art-inBox-labelStatus">
                    {labelStatus}
                  </div>
                )}
                <div className="c-headMeta-metaBox-art-inBox-labelBottom">見放題パック</div>
                <div className="c-headMeta-metaBox-art-inBox-artwork">
                  <img src={thumbnailUrl} className="c-headMeta-metaBox-art-inBox-artwork-img" />
                </div>
              </div>

              {/* for PC */}
              <AddButtonBlock favoriteType={FAVORITE_TYPE.COURSE} favoriteId={course.course_id} title={course.name} />
            </div>

            <div className="c-headMeta-metaBox-info">
              <h2 className="c-headMeta-metaBox-info-title clickable" onClick={this.onClickWatch}>{course.name}</h2>
              <div className="c-headMeta-metaBox-info-price pack">料金：月額 {price}円（税込）</div>
              <div className="c-headMeta-metaBox-info-viewingPeriod">視聴期間：視聴ライセンス購入後1ヶ月間</div>
              <div className="c-headMeta-metaBox-info-titleHeader" dangerouslySetInnerHTML={{ __html: description }} />
              <div className="c-pageBtn playNow">
                {status.isPurchseAvailable ?
                  (
                    <a href="#" className="c-pageBtn-link" onClick={this.onClickWatch}>
                      月額<span className="c-pageBtn-link-yen">{price}</span>円で視聴
                    </a>
                  )
                  :
                  "現在購入いただけません"
                }
              </div>

              {/* for SP */}
              <AddButtonBlock favoriteType={FAVORITE_TYPE.COURSE} favoriteId={course.course_id} title={course.name} />
            </div>
          </div>
        </div>

        <div className="c-listMeta">
          <div className="c-listMeta-inBox">
            <div className="c-listMeta-inBox-main">
              {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
              <SwitchableList
                episodes={episodes}
                placeholder="パックの中から探す"
                showNew
                showChecked
                onlySubTitle
              />
            </div>
            <div className="c-listMeta-inBox-sub">
              <div className="common-box-ad-rectangle">
                <DFPBanner position="foot" meta_id="" />
              </div>
              {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
              <SideTab otherSeasonIds={otherSeasonIds} currentSeasonIds={allSeasonIds} />
              <ProgramTopLink
                seriesId={series.id}
                seasonId={season.id}
                isFree={false}
                disabled={!series.id || !season.id}
              />
              <HtmlSnippet snippetId={this.config.extras.common_banner_snippet_key} />
              <SideRecommend recommendItems={recommendItems} />
            </div>
          </div>
        </div>

        <Footer className="mt0" />
      </div>
    );
  }
}
