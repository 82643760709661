import React from 'react'
import PropTypes from 'prop-types'
import addSizePrefix from '../../addSizePrefix'
import routes from '../../../../../../common/routes'
import { StyledDiv, StyledImg, StyledLink, StyledP } from './style'

/** Section4｜リストの要素 */
const ListItem = ({ meta = {}, src = '', title = '' }) => {
  return (
    <StyledLink
      route={routes.episode}
      params={{
        seriesId: _.get(meta, ['values', 'parents_series', 'id']),
        seasonId: _.get(meta, ['values', 'parents_season', 'id']),
        episodeId: _.get(meta, ['id'])
      }}
    >
      <StyledDiv>
        <StyledImg
          src={addSizePrefix(src, 'large')}
          alt=""
          width="470"
          height="265"
          loading="lazy"
        />
      </StyledDiv>
      <StyledP>{title}</StyledP>
    </StyledLink>
  )
}

export default ListItem

ListItem.propTypes = {
  /** リストの表示要素
   * @param {string} id - コンテンツのid
   * @param {string} values.parents_series.id - 親要素のID, 遷移先の指定に使用
   * @param {string} values.parents_season.id - 親要素のID, 遷移先の指定に使用
   */
  meta: PropTypes.object,
  /** サムネイル画像 */
  src: PropTypes.string,
  /** 動画タイトル */
  title: PropTypes.string
}
