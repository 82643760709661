import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '../../../../../hooks/useMediaQuery'
import { breakPoints } from '../../../style'

/* style */
import { StyledSection, StyledH2, StyledList, StyledSlider } from './style'

/**
 * # 人気急上昇ランキング
 *
 * ## `List`と`Slider`
 * 画面幅に応じてコンポーネントを出し分ける
 *
 * 対応表
 * | SP | TAB | PC |
 * | ---- | ---- | ---- |
 * | Slider | List | List |
 *
 * ## ブレークポイントについて
 * タブレット表示のとき`List`を表示する
 *
 * https://media-plex.backlog.jp/view/DGA2-1702#comment-1402299961
 */

const Section9 = ({ items = [], ...props }) => {
  const isMmWidth = useMediaQuery('max', breakPoints.mm)
  const displayLength = 5 // 何件表示するか

  return (
    <StyledSection id="top9" {...props}>
      <StyledH2>
        <span>人気急上昇ランキング</span>
      </StyledH2>
      {isMmWidth ? (
        <StyledSlider items={items.slice(0, displayLength)} />
      ) : (
        <StyledList items={items.slice(0, displayLength)} />
      )}
    </StyledSection>
  )
}

export default Section9

Section9.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      meta: PropTypes.object,
      type: PropTypes.string,
      values: PropTypes.object
    })
  )
}
