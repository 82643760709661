import React from 'react'
import PropTypes from 'prop-types'

/* components */
import ConvertSvg from '../../util/ConvertSvg'

/* style */
import { StyledLink, StyledSpan } from './style'

/** Logirlプランページ | ボタン1 */
const Btn1 = ({ text, icon, color, ...props }) => {
  return (
    <StyledLink {...props}>
      {icon && <ConvertSvg {...icon} />}
      <StyledSpan color={color}>{text}</StyledSpan>
    </StyledLink>
  )
}

export default Btn1

Btn1.propTypes = {
  /** Linkに渡すhref */
  href: PropTypes.string,
  /** Linkに渡すroute */
  route: PropTypes.object,
  /** Linkに渡すparams */
  params: PropTypes.object,
  /** Linkに渡すquery */
  query: PropTypes.object,
  /** ボタンに表示するテキスト */
  text: PropTypes.string,
  /** ボタンに表示するアイコン */
  icon: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  /** ボタンのテキストカラー */
  color: PropTypes.string,
  /** ボタンのボーダーカラー */
  borderColor: PropTypes.string,
  /** ボタンのシャドウカラー */
  shadowColor: PropTypes.string,
  /** Linkに渡すclassName */
  className: PropTypes.string
}

Btn1.defaultProps = {
  href: '',
  route: null,
  params: null,
  query: null,
  text: '',
  icon: null,
  color: '',
  borderColor: '',
  shadowColor: '',
  className: ''
}
