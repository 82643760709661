import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import routes from '../../../routes'

/** APP:戻る */
export default class GoBack extends Component {
  static contextTypes = {
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.goBack = this.goBack.bind(this)
  }

  goBack(e) {
    e.preventDefault()
    this.context.history.goBack()
  }

  render() {
    const { className } = this.props

    return (
      <div className={`c-headBack ${className}`}>
        <div className="c-headBack-back">
          {this.context.history.length > 1 ? (
            <a href="" className="c-headBack-back-link" onClick={this.goBack}>
              戻る
            </a>
          ) : (
            <Link route={routes.app_home} className="c-headBack-back-link">
              戻る
            </Link>
          )}
        </div>
      </div>
    )
  }
}
