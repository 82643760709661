import styled from 'styled-components'
import { pcMinSize } from '../../styles'
import { mediaQuery } from '../../../../style'

export const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${mediaQuery(parseInt(pcMinSize))} {
    padding: 0 10px 0 5px;
  }
`
