import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import RenewalPlayer from '../../player/RenewalPlayer'
import {
  DIO_PLAYER_DEVICE,
  META_SCHEMA_ID,
  PRODUCT_SCHEMA
} from '../../../../../constants/app'

/** マイページ:購入済単話 */
export default class PurchasedEpisodes extends Component {
  static propTypes = {
    /** 購入済の動画リスト */
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        purchase_date: PropTypes.string,
        limit_date: PropTypes.string,
        meta: PropTypes.object,
        product: PropTypes.object
      })
    ).isRequired,
    /** 「戻る」ボタン押下時実行する関数 */
    goBack: PropTypes.func.isRequired
  }

  static defaultProps = {}

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data
    const device = webApp.utils.getDevice()
    if (device === DIO_PLAYER_DEVICE.PC) {
      this.isPc = true
      this.isSp = false
    } else {
      this.isPc = false
      this.isSp = true
    }

    this.state = {
      playMeta: null,
      howToPlays: null
    }

    this.onClickText = this.onClickText.bind(this)
    this.onClickThumbnail = this.onClickThumbnail.bind(this)
    this.onClosePlayer = this.onClosePlayer.bind(this)

    this.setPlayerRef = (e) => {
      this.playerRef = e
    }

    // DioPlayer_iOSかチェック
    this.isIosDioPlayer = navigator.userAgent
      .split(' ')
      .find((value) => value.match(/^DioPlayer_iOS/g))
  }

  componentDidMount() {
    this._isMounted = true
    this.getHowToPlays()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** 権限情報(権利、商品、コース)取得 */
  getHowToPlays() {
    const { episodes } = this.props

    const metaIds = episodes
      .map((v) => _.get(v, ['meta', 'meta_id']))
      .filter((v) => v)
    if (!metaIds.length) return Promise.resolve()

    const path = ['meta', 'howToPlay', false, metaIds]
    return this.model.fetch([path]).then((result) => {
      const howToPlays =
        _.get(result, ['json', 'meta', 'howToPlay', false]) || {}
      if (this._isMounted) {
        this.setState({ howToPlays })
      }
    })
  }

  thumbnail(meta) {
    let [metaName, subTitle] = webApp.utils.titles(meta)
    metaName = `${metaName} ${subTitle}`
    const thumbnailUrl = meta.thumbnail_url || this.config.default_thumbnail
    const thumbnailAlt = metaName
    return [thumbnailUrl, thumbnailAlt]
  }

  onClickText(e, meta) {
    webApp.utils.goToProgramLink(this.context, meta, null, null, {
      autoPlay: false
    })
  }

  onClickThumbnail(_e, meta) {
    if (this.isPc) {
      webApp.utils.goToProgramLink(this.context, meta)
    } else if (this._isMounted) {
      this.setState({ playMeta: meta }, () => {
        this.playerRef.play()
      })
    }
  }

  onClosePlayer() {
    if (this._isMounted) {
      this.setState({
        playMeta: null
      })
    }
  }

  render() {
    const { episodes, goBack } = this.props
    const { playMeta, howToPlays } = this.state

    let displayMode = {}
    let playerSettings = {}
    let metaIdForPlayer = null
    let material_id = ''
    let license_id = ''

    if (playMeta) {
      const howToPlay = _.get(howToPlays, [playMeta.meta_id])
      const product =
        (_.get(howToPlay, ['products']) || []).find(
          (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
        ) || null
      const course = _.get(howToPlay, ['courses', 0]) || null
      displayMode = webApp.utils.getDisplayMode(playMeta, product, course)
      playerSettings = webApp.utils.getPlayerSettings(
        this.config,
        playMeta,
        displayMode
      )

      const isLive =
        playMeta.meta_schema_id === META_SCHEMA_ID.LIVE ||
        playMeta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
      if (isLive) {
        if (playMeta.values.clipping_select_media_type === 'vod')
          metaIdForPlayer = playMeta.values.clipping_vod_ref_id
        if (!metaIdForPlayer) metaIdForPlayer = playMeta.values.target_ref_id
      }
      if (!metaIdForPlayer)
        metaIdForPlayer = `${this.config.videocloud.reference_id_prefix || ''}${
          playMeta.meta_id
        }`

      material_id = _.get(product, ['ref_id']) || ''
      license_id = _.get(course, ['ref_id']) || ''
    }

    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <header className="c-mypage-cont-inBox-head">
            <a href="#" className="c-mypage-btnBack" onClick={goBack}>
              戻る
            </a>
            <h3 className="c-mypage-cont-inBox-head-title">{this.isIosDioPlayer ? "お持ちのコンテンツ" : "購入済"}</h3>
          </header>

          <div className="c-card-vertical myPageListChild">
            <div className="c-card-vertical-cont">
              {episodes.map((episode) => {
                const { meta } = episode
                const [thumbnailUrl, thumbnailAlt] = this.thumbnail(meta)
                const progress = webApp.utils.progress(this.config, meta)
                const purchaseDate = moment(episode.purchase_date).format(
                  'YYYY/MM/DD'
                )
                const deadLine = webApp.utils.deadLine(
                  this.context,
                  episode.limit_date
                )
                const price = _.get(episode, [
                  'product',
                  'active_pricing',
                  'price'
                ])
                const isWatched = webApp.utils.isWatched(meta)

                let [metaName, subTitle] = webApp.utils.titles(meta)
                metaName = `${metaName} ${subTitle}`

                const howToPlayEpi = _.get(howToPlays, [meta.meta_id])
                const productEpi = (
                  _.get(howToPlayEpi, ['products']) || []
                ).find((p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id)
                const rightId = _.get(productEpi, ['right_ids', 0])
                const rightEpi = (_.get(howToPlayEpi, ['rights']) || []).find(
                  (p) => p.right_id === rightId
                )
                const viewTerm = _.get(rightEpi, ['view_term']) || ' '

                return (
                  <a
                    href="#"
                    key={meta.meta_id}
                    className="c-card"
                    onClick={(e) => e.preventDefault()}
                  >
                    <figure className="c-card-inBox">
                      <div
                        className="c-card-inBox-art"
                        onClick={(e) => this.onClickText(e, meta)}
                      >
                        <div className="c-card-inBox-art-artwork shadowOn">
                          <img
                            src={thumbnailUrl}
                            className="c-card-inBox-art-artwork-img"
                            alt={thumbnailAlt}
                          />
                        </div>
                        <div className="c-artInfo-time">
                          {webApp.utils.duration(meta)}
                        </div>
                        <div className="c-artInfo-seekarea h3px">
                          {progress > 0 && (
                            <div
                              className="c-artInfo-seekarea-bar"
                              style={{ width: `${progress}%` }}
                            />
                          )}
                        </div>
                      </div>
                      <figcaption
                        className="c-card-inBox-meta"
                        onClick={(e) => this.onClickText(e, meta)}
                      >
                        <div className="c-card-inBox-meta-titles">
                          <div className="c-card-inBox-meta-titles-titleSub">
                            {isWatched && <span className="iconMylistAdded" />}
                            {metaName}
                          </div>
                        </div>
                        <div className="c-card-inBox-meta-purchasedDate">
                          {this.isIosDioPlayer ? "コンテンツ取得日：" : "購入日："}{purchaseDate}
                        </div>
                        {!this.isIosDioPlayer && (
                          <div className="c-card-inBox-meta-amount">
                            購入金額：
                            <span className="c-card-inBox-meta-amount-coin">
                              {price}
                            </span>
                          </div>
                        )}
                        {viewTerm !== ' ' && viewTerm > 0 && (
                          <div className="c-card-inBox-meta-viewingPeriod">
                            視聴期間：{this.isIosDioPlayer ? "コンテンツ取得後" : "動画購入後"}{viewTerm}日間
                          </div>
                        )}
                        <div
                          // chromaticで画像の変更を検知しないための設定
                          data-chromatic="ignore"
                          className="c-card-inBox-meta-viewingDeadline"
                        >
                          {deadLine}
                        </div>
                      </figcaption>
                    </figure>
                  </a>
                )
              })}
            </div>
          </div>
        </div>

        {this.isSp && playMeta && (
          <RenewalPlayer
            ref={this.setPlayerRef}
            meta_id={metaIdForPlayer}
            product_type={playerSettings.product_type}
            channel={playerSettings.channel}
            ssai_ad_config_id={playerSettings.ssai_ad_config_id}
            ssai_player_id={playerSettings.ssai_player_id}
            stvod_player_id={playerSettings.stvod_player_id}
            live_player_id={playerSettings.live_player_id}
            delivery_config_id={playerSettings.delivery_config_id}
            thumbnail_url={playMeta.thumbnail_url}
            subtitle={!!playMeta.values.subtitle}
            enqueteEnabled={
              playMeta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
              playMeta.meta_schema_id === META_SCHEMA_ID.LIVE
            }
            onClose={this.onClosePlayer}
            material_id={material_id}
            license_id={license_id}
            display_mode={displayMode}
          />
        )}
      </div>
    )
  }
}
