import styled from 'styled-components'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import { mediaSp, yellow } from '../styles'

export const StyledDiv = styled.div`
  padding-top: 28px;
`

export const StyledLink = styled(Link)`
  margin: 54px auto 0;
  padding: 1.5rem 2rem;
  width: 346px;
  border-radius: 5px;
  display: block;
  background-color: ${yellow};
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;

  @media ${mediaSp} {
    margin-top: 20px;
    max-width: 346px;
    width: auto;
  }
`
