import React from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'

/* components */
import TwitterIframe from '../../TwitterIframe'
import DFPBanner from '../../../DFPBanner'
import Palette from '../../Palette'
import HtmlSnippet from '../../../../../exdio/components/HtmlSnippet'

/** CardWithAd Component */
const CardWithAd = (
  {
    palettes = [],
    howToPlays = {},
    products = null,
    courses = null,
    canvasConfig = {},
    loaded = false,
    ...props
  },
  context
) => {
  const config = context.models.config.data
  const { twitter, large_idx, palette_header } = canvasConfig
  const twitterRenderable = typeof window !== 'undefined'
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isApp = webApp.utils.isApp(context)
  const fixedBnr = config.canvas_snippet.fixed

  return (
    <div className="c-card-withAd" {...props}>
      <div className="c-card-withAd-colCards">
        {(palettes || []).map((palette, i) => {
          switch (palette.type) {
            case 'flex_bnr':
              if (!isLoggedIn && !isApp && loaded) {
                return (
                  <div key="bnr-flex" className="bnr-flex">
                    <HtmlSnippet snippetId={fixedBnr} />
                  </div>
                )
              }
              return null

            case 'dfp_bnr':
              return (
                loaded && <DFPBanner key="banner" position="head" meta_id="" />
              )

            default:
              return (
                <Palette
                  key={palette.palette_id}
                  palette={palette}
                  howToPlays={howToPlays}
                  large={(large_idx || []).includes(i)}
                  products={products}
                  courses={courses}
                  paletteHeader={palette_header !== false}
                  loaded={loaded}
                />
              )
          }
        })}
      </div>
      <div className="c-card-withAd-colAd">
        <div className="common-box-ad">
          <DFPBanner position="foot" meta_id="" withFooterAd />
        </div>
        {twitter && twitterRenderable && <TwitterIframe />}
      </div>
    </div>
  )
}

export default CardWithAd

CardWithAd.propTypes = {
  palettes: PropTypes.array,
  howToPlays: PropTypes.object,
  products: PropTypes.object,
  courses: PropTypes.object,
  canvasConfig: PropTypes.shape({
    ad_idx_banner: PropTypes.number,
    ad_idx_side: PropTypes.number,
    large_idx: PropTypes.arrayOf(PropTypes.number),
    twitter: PropTypes.bool,
    ad: PropTypes.bool,
    palette_header: PropTypes.bool
  }).isRequired,
  loaded: PropTypes.bool
}

CardWithAd.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}
