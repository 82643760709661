import styled from 'styled-components'
import { mediaQuery, fixColors } from '../../../style'
import Mask from './Mask'
import NextPrevText from './NextPrevText'

export const StyledButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border-width: 0;
  display: block;
  position: relative;
  cursor: pointer;

  ${mediaQuery()} {
    position: absolute;
    inset: 0;
  }
`

export const StyledDiv1 = styled.div`
  position: absolute;
  left: 1%;
  bottom: 4%;
  z-index: 1;
  color: #fff;

  ${mediaQuery()} {
    left: 3%;
    bottom: 6%;
    color: ${fixColors.colorFont};
    font-size: 1.1rem;
    font-weight: bold;
  }
`

export const StyledDiv2 = styled.div`
  max-width: 1080px;

  img {
    width: 100%;
    height: auto;
  }

  ${Mask},
  ${NextPrevText} {
    position: absolute;
    inset: 0;
  }
`
