import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../../utils/exdioWebAppUtils'

/* style */
import { StyledDiv, StyledImg } from './style'

/** 港時間 スライダー内 画像部分 コンポーネント */
const SliderImage = ({ meta, onClickThumbnail, ...props }) => {
  /** サムネイル画像 */
  const thumbnail = () => {
    let thumbnailUrl = null
    let thumbnailAlt = null
    if (meta) {
      thumbnailUrl = meta.thumbnail_url
      const [title, subTitle] = webApp.utils.titles(meta)
      thumbnailAlt = `${title} ${subTitle}`
    }
    return [thumbnailUrl || config.default_thumbnail, thumbnailAlt]
  }
  const [thumbnailUrl, thumbnailAlt] = thumbnail()

  const handleClick = (propFunc, e) => {
    e.preventDefault()
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation()
      propFunc(e)
    }
  }

  return (
    <StyledDiv onClick={(e) => handleClick(onClickThumbnail, e)}>
      <StyledImg src={thumbnailUrl} alt={thumbnailAlt} />
    </StyledDiv>
  )
}

export default SliderImage

SliderImage.propTypes = {
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),

  /** イベントハンドラ 画像クリック時のページ遷移に使用 */
  onClickThumbnail: PropTypes.func,
  /** 強制的にタイトルを指定する場合に設定 */
  title: PropTypes.string,
  /** 強制的にサムネイル画像を指定する場合に設定 */
  thumbnail: PropTypes.string
}

SliderImage.defaultProps = {
  meta: null,
  onClickThumbnail: null
}
