import styled, { createGlobalStyle } from 'styled-components'
import { breakPoints, mediaQuery } from '../../style'

/* size */
export const pcMaxSize = '1280px'
export const pcMinSize = '1080px'
export const spMinSize = '320px'

/* color */
export const black = '#333'
export const white = '#fff'
export const gray1 = '#eee'
export const gray2 = '#e9e9e9'
export const gray3 = '#e6e6e6'

export const lightPink = '#fff0f5'
export const lightPink2 = '#ffe1dd'
export const pink = '#ff009b'
export const deepPink = '#ce007d'
export const darkPink = '#93015a'

export const brown1 = '#a0948b'
export const brown2 = '#776352'
export const brown3 = '#8d6c3e'

export const lightYellow = '#fefeb1'
export const yellow = '#ffff01'
export const darkYellow = '#9a9a00'

export const lightGreen = '#ddf5c8'
export const green = '#00d4b1'
export const darkGreen = '#008872'

export const aqua = '#c0f2fb'

export const lightBlue = '#e6faff'
export const blue = '#497bd7'
export const darkBlue = '#223965'

export const badgeMovie = pink
export const badgeBlog = '#00d4ce'

/** application.scssの影響で付与されてしまうstyleをリセットする */
export const InitImage = styled.img`
  margin: inherit;
  max-width: inherit;
  height: inherit;
`

export const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v5.0.2 | 20191019
   License: none (public domain)
*/
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
      display: none;
  }
  body {
    line-height: 1;
  }
  menu, ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html {
    font-size: 62.5%;
  }

  body {
    min-width: 1200px;
    background-color: ${white};
    color: ${black};
    font-size: 1.4rem;
    font-family: "Noto Sans JP","游ゴシック","YuGothic","ヒラギノ角ゴ Pro","Hiragino Kaku Gothic Pro","Arial","メイリオ","Meiryo","Roboto",sans-serif;
    line-height: 1.5;
    scroll-behavior: smooth;

    ${mediaQuery()} {
      min-width: ${spMinSize};
    }
  }

  .u-hide {
    display: none;

    &--sp {
      ${mediaQuery()} {
        display: none;
      }
    }

    &--pc {
      ${mediaQuery('sm', 'min')} {
        display: none;
      }
    }
  }

  .c-pageTop {
    display: none;
  }
`

export const StyledMain = styled.main`
  position: relative;

  &.lazyloaded {
    background: ${lightBlue}
      url(/images/exdio/renewal/gariben_daigaku/top/sec1_bg1.webp) no-repeat
      fixed center / cover;
  }
`

export const StyledDiv1 = styled.div`
  ${mediaQuery()} {
    margin-top: 20px;
    padding: 0 13px;
  }
`

export const StyledDiv2 = styled.div`
  position: absolute;
  top: 30px;
  left: calc(50% + 190px);

  ${mediaQuery()} {
    margin-top: 20px;
    padding: 0 10px;
    position: static;
  }
`
