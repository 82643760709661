import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '../../../../../../hooks/useMediaQuery'

/* components */
import ButtonList2 from '../../ButtonList2'
import Heading1 from '../../Heading1'
import Banners from '../../Banners'

/* style */
import { StyledDiv, StyledButtonList1 } from './style'

/** Summary Component */
const Summary = ({
  meta = {},
  metaName = [],
  status = {},
  isLoggedIn = false,
  ...props
}) => {
  const isSp = useMediaQuery()

  if (!isSp && status.isPurchased) return null

  return (
    <StyledDiv {...props}>
      {isSp && <Heading1 as="h1">{metaName}</Heading1>}

      {!status.isPurchased && <StyledButtonList1 isLoggedIn={isLoggedIn} />}

      {isSp && <ButtonList2 meta={meta} metaName={metaName} />}

      {isSp && <Banners />}
    </StyledDiv>
  )
}

export default Summary

Summary.propTypes = {
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  metaName: PropTypes.string,
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  isLoggedIn: PropTypes.bool
}
