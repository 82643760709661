"use strict";
import inNode from '../sketch-platform/ui/utils/inNode';
const space = inNode ? global : window;

try {
  if (!space.app) {
    space.app= {
      i18n: {
      },
      contextData: {
      }
    };
  }
  space.app.namespace = function(path) {
    var ret = space.app;
    var keys = path.split(".");
    if (keys[0] === "app") {
      keys.shift();
    }

    let i = 0;
    for (i = 0; i < keys.length; i++) {
      key = keys[i].toString();
      ret = ret[key] || {};
    }
    return ret;
  };
  space.name = "_exdio";
} catch(t) {
}

export default space.app;
