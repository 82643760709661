import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import routes from '../../../../common/routes'
import {
  MYPAGE_CONTENTS,
  PURCHASE_CONTENTS,
  PURCHASE_TYPE
} from '../../../../../constants/app'
import Steps from './Steps'
import webApp from '../../../utils/exdioWebAppUtils'
import ProgramItem from '../../../../common/components/renewal/ProgramItem'
import Link from '../../../../../sketch-platform/ui/routing/Link'

/** 購入ページ:購入完了 */
export default class Completed extends Component {
  static propTypes = {
    result: PropTypes.object,
    type: PropTypes.string.isRequired,
    howToPlays: PropTypes.object,
    purchased: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    alreadyPurchasedAll: PropTypes.bool,
    srcPage: PropTypes.string,
    belongsToGroup: PropTypes.bool,
    pack: PropTypes.object,
    course: PropTypes.object,
    availablePoint: PropTypes.shape({
      available_point: PropTypes.number,
      available_point_from_tvasahi_point: PropTypes.number
    })
  }

  static defaultProps = {
    result: {},
    howToPlays: {},
    purchased: [],
    selected: [],
    alreadyPurchasedAll: null,
    belongsToGroup: null,
    pack: {},
    course: {},
    availablePoint: {
      available_point: 0,
      available_point_from_tvasahi_point: 0
    }
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {}

    const { type, purchased, selected, srcPage } = this.props
    // 購入済みのものが1件の場合かつ、ログイン直後の遷移の場合は、詳細画面へ遷移する
    const { query } = this.context.routeHandler

    if (query.content === 'after_login') {
      if (purchased.length === 1) {
        const meta = purchased[0]
        webApp.utils.goToProgramLink(this.context, meta)
      } else {
        // バックを買うとpurchasedには購入したエピソードすべてが入るが、
        // 単話ページから遷移した場合は、そこの画面に遷移させたいのでselectedに入っているページに遷移させる
        // とりあえず影響範囲を抑えるためにエピソードだけに限定
        if (type === PURCHASE_TYPE.EPISODE && selected.length === 1) {
          const selectedMetas = purchased.filter((meta) =>
            selected.includes(meta.meta_id)
          )
          webApp.utils.goToProgramLink(this.context, selectedMetas[0])
        }
      }
    }

    // ちょっと強引だけど時間がないので、
    // 遷移元のURLがprogramから始まる場合は、そのページへリダイレクトする
    if (type === PURCHASE_TYPE.PLAN) {
      if (srcPage) {
        const pattern = /^\/program\/.*/
        if (srcPage.match(pattern)) {
          window.location.href = srcPage
        }
      }
    }
  }

  componentDidMount() {
    // GTMの更新
    // const title = this.context.models.config.data.default_title;
    // const [program] = title === undefined ? [''] : title.split(' | ');
    // const gtmTags = [
    //   { key: 'event', value: 'pageChange' },
    //   { key: 'genre', value: 'cu' },
    //   { key: 'program', value: program }
    // ];
    // webApp.utils.updateDataLayer(gtmTags);
  }

  componentDidUpdate() {
    const { purchased, howToPlays, type, selected, pack } = this.props

    if (purchased.length > 0 && PURCHASE_TYPE.EPISODE === type) {
      let selectedMetas = []
      if (type === PURCHASE_TYPE.EPISODE) {
        selectedMetas = purchased.filter((meta) =>
          selected.includes(meta.meta_id)
        )
      }
      const season_name = selectedMetas.map((e) => {
        if (e.values.evis_FrontDisplayTitle === null) {
          return e.values.avails_SeriesTitleDisplayUnlimited
        } else {
          return e.values.evis_FrontDisplayTitle
        }
      })
      const eps_name = selectedMetas.map((e) => {
        return e.values.avails_EpisodeTitleDisplayUnlimited
      })
      const medal = selectedMetas.map((e) => {
        return webApp.utils.calcPrice(howToPlays, [e.meta_id])
      })
      const season_name_str = JSON.stringify(season_name)
      const eps_name_str = JSON.stringify(eps_name)
      const medal_str = JSON.stringify(medal)

      const gtmTags = [
        { key: 'event', value: 'complete' },
        { key: 'season_name', value: season_name_str },
        { key: 'episode_name', value: eps_name_str },
        { key: 'medal', value: medal_str }
      ]

      webApp.utils.updateDataLayerPurchase(gtmTags)
    } else if (PURCHASE_TYPE.PACK === type && Object.keys(pack).length > 0) {
      const pack_name = pack.name
      const medal = pack.active_pricing.price

      const gtmTags = [
        { key: 'event', value: 'complete' },
        { key: 'pack_name', value: pack_name },
        { key: 'medal', value: medal }
      ]

      webApp.utils.updateDataLayerPurchase(gtmTags)
    }
  }

  errorMessages() {
    const { result } = this.props
    if (!result) return []

    const resultCode = _.get(result, [
      'json',
      'infra',
      'usePoint',
      'result_code'
    ])
    const errorMessages = []
    switch (resultCode) {
      case 'ERROR_NOT_FOUND_MATERIAL':
        errorMessages.push('存在しない商品があります。')
        break
      case 'ERROR_NOT_FOUND_POINT':
        errorMessages.push('メダル情報が存在しません。')
        break
      case 'ERROR_LACK_POINT':
        errorMessages.push('メダルが不足しています。')
        break
      case 'ERROR_OUT_PERIOD':
      case 'ERROR_DIO_OUT_PERIOD':
        errorMessages.push('販売期間外の商品があります。')
        break
      case 'ERROR_FATAL':
        errorMessages.push(
          'エラーが発生しました。管理者へお問い合わせください。'
        )
        break
      default:
    }
    return errorMessages
  }

  render() {
    const {
      type,
      alreadyPurchasedAll,
      belongsToGroup,
      availablePoint,
      purchased,
      selected,
      srcPage,
      pack,
      course
    } = this.props

    // ssrで余計な描画がはいるのでこれでブロック
    if (!type) return null

    // 余計な表示がはいるのでここでブロック
    if (type === PURCHASE_TYPE.PLAN) {
      if (srcPage) {
        const pattern = /^\/program\/.*/
        if (srcPage.match(pattern)) {
          return null
        }
      }
    }

    const classNameFinish =
      PURCHASE_TYPE.PLAN === type ? 'finishPack' : 'finish'
    const errorMessages = this.errorMessages()
    const { term } = this.context.routeHandler.query

    // type = EPISODEの場合は、purchasedから選択したものだけを抽出する
    let selectedMetas = []
    if (type === PURCHASE_TYPE.EPISODE) {
      selectedMetas = purchased.filter((meta) =>
        selected.includes(meta.meta_id)
      )
    }

    const isPurshased = alreadyPurchasedAll || belongsToGroup
    // エラー時、購入済み時は「購入完了」を表示しない
    const showTitle = !errorMessages.length && !isPurshased

    return (
      <div className="c-login finish">
        <div className="c-login-wrapper coin-step-head">
          <Steps
            current={PURCHASE_CONTENTS.COMPLETED}
            className="finish"
            noSteps
            showTitle={showTitle}
          >
            {errorMessages.map((msg, i) => (
              <p key={i} className="c-login-flowwrapper-text finish error">
                {msg}
              </p>
            ))}
            {errorMessages.length === 0 && (
              <p className={`c-login-flowwrapper-text ${classNameFinish}`}>
                {isPurshased && !term
                  ? '選択された動画は既にご購入済です。'
                  : 'ご購入ありがとうございます。'}
                <br />
                視聴をお楽しみください。
              </p>
            )}
            {PURCHASE_TYPE.PLAN !== type && (
              <div className="c-myInfoBox-coins">
                <div className="c-myInfoBox-coins-balance">
                  残高
                  <span className="c-myInfoBox-coins-balance-num">
                    {_.get(availablePoint, ['available_point'], 0)}
                  </span>
                </div>
              </div>
            )}
          </Steps>
        </div>

        <div className="c-login-cont finish">
          <div className="c-login-cont-wrapper coin-payment-wrapper">
            <div className="payment-finish-wrapper">
              {PURCHASE_TYPE.EPISODE === type && (
                <div className="c-card-vertical　mylist">
                  <div className="c-card-vertical-cont">
                    {selectedMetas.map((meta) => (
                      <ProgramItem
                        key={meta.meta_id}
                        meta={meta}
                        showCaption
                        showNew={webApp.utils.showNew(meta)}
                        onClickThumbnail={() =>
                          webApp.utils.goToProgramLink(this.context, meta)
                        }
                        onClickCaption={() =>
                          webApp.utils.goToProgramLink(
                            this.context,
                            meta,
                            null,
                            null,
                            { autoPlay: false }
                          )
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
              {PURCHASE_TYPE.PACK === type && (
                <div className="c-card-vertical-cont finishedPack">
                  <ProgramItem
                    product={pack}
                    onClick={() =>
                      webApp.utils.goToProgramLink(this.context, null, pack)
                    }
                  />
                </div>
              )}
              {PURCHASE_TYPE.PLAN === type && (
                <div className="c-card-vertical-cont finishedPack">
                  <ProgramItem
                    course={course}
                    purchased={purchased}
                    showDeadLine
                    showBottomLabel
                    onClick={() =>
                      webApp.utils.goToProgramLink(
                        this.context,
                        null,
                        null,
                        course
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className="check-wrapper">
            <div className="back-btn">
              <Link
                route={routes.mypage}
                state={{ content: MYPAGE_CONTENTS.TAB.PURCHASED }}
              >
                マイページ（購入済）へ
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
