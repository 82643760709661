import styled from 'styled-components'
import {
  mediaQuery,
  breakPoints
} from '../../../../../../exdio/components/style'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'
import { breakPoints as SailGPbreakPoints } from '../../style'

export const StyledDiv = styled.div`
  margin: 0 auto;
  width: 1280px;

  ${mediaQuery(breakPoints.sm)} {
    width: 960px;
  }

  ${mediaQuery(breakPoints.sm)} {
    padding: 15px 0;
    width: auto;
  }
`

export const StyledLink = styled(Link)`
  display: inline-block;
`

export const StyledImg = styled.img`
  width: 180px;

  ${mediaQuery(breakPoints.sm)} {
    width: 120px;
  }
`
