import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AppContextProvider extends Component {

  static propTypes = {
    appContext: PropTypes.object.isRequired,
    children: PropTypes.object
  }

  static childContextTypes = {
    ab: PropTypes.object,
    cookies: PropTypes.object,
    falcorModel: PropTypes.object,
    getModelData: PropTypes.func,
    webApp: PropTypes.object,
    history: PropTypes.object,
    logger: PropTypes.object,
    models: PropTypes.object,
    getICUString: PropTypes.func,
    getI18nString: PropTypes.func,
    getI18nBundle: PropTypes.func,
    provideAppContextToElement: PropTypes.func,
    routeHandler: PropTypes.object,
    renderTracker: PropTypes.object,
    discoveryApp: PropTypes.object,
    updateUserInfo: PropTypes.func,
    updateUserInfoSubscribe: PropTypes.func,
    setGlobalNavigationUpdater: PropTypes.func,
    updateGlobalNavigation: PropTypes.func
  }

  getChildContext() {
    const context = this.props.appContext;
    return {
      ab: context.getAB(),
      cookies: context.getCookieDough(),
      falcorModel: context.getFalcorModel(),
      getModelData: context.getModelData.bind(context),
      webApp: context.getWebApp(),
      history: context.getHistory(),
      logger: context.getLogger(),
      models: context.getModels(),
      getICUString: context.getICUString.bind(context),
      getI18nString: context.getI18nString.bind(context),
      getI18nBundle: context.getI18nBundle.bind(context),
      provideAppContextToElement: context.provideAppContextToElement.bind(context),
      routeHandler: context.getRouteHandler(),
      renderTracker: context.getRenderTracker(),
      discoveryApp: context.getDiscoveryApp(),
      updateUserInfo: userInfo => context.updateUserInfo(context, this, userInfo),
      updateUserInfoSubscribe: context.updateUserInfoSubscribe.bind(context),
      setGlobalNavigationUpdater: context.setGlobalNavigationUpdater.bind(context),
      updateGlobalNavigation: context.updateGlobalNavigation.bind(context)
    };
  }

  render() {
    if (!this.props.children) return null;

    return React.cloneElement(this.props.children, this.props.appContext.getState());
  }
}

AppContextProvider.displayName = function() {
  return 'AppContextProvider';
};

export default AppContextProvider;
