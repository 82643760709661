import styled from 'styled-components'

export const StyledWrapper = styled.div`
  position: relative;
`

export const StyledButton = styled.button`
  padding: 0;
  background: none;
  border-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: ${(props) => (props.isOpen ? 'relative' : 'static')};
  z-index: ${(props) => (props.isOpen ? 22 : null)};
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;
  color: #343434;

  &:hover {
    opacity: 0.7;
  }
`

export const StyledImg = styled.img`
  margin-bottom: 5px;
  margin-right: 0;
  margin-left: 0;
  display: inline-block;
`

export const StyledList = styled.ul`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 21;
`

export const StyledBg = styled.div`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.isShow ? 20 : -1)};
  opacity: ${(props) => (props.isShow ? 1 : 0)};
  transition: opacity 0.2s linear;
`
