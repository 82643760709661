import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import styled, { css } from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'

/* style */
import {
  StyledFigure,
  StyledFigcaption as Figcaption,
  StyledLink
} from '../../EpisodeList/ListItem/style'

/** 動画、番組リスト要素コンポーネント スケルトンバージョン */
const Item = ({
  listType = 'default',
  hasThumb = false,
  titleRowLength = 1,
  textRowLength = 2,
  hasPrice = false,
  hasPlayButton = false,
  ...props
}) => {
  return (
    <StyledLink as="div" listType={listType} {...props}>
      <StyledFigure>
        {hasThumb && (
          <StyledImg listType={listType}>
            <Skeleton variant="rect" />
          </StyledImg>
        )}

        <StyledFigcaption listType={listType}>
          {titleRowLength > 0 && (
            <StyledTitle>
              <Skeleton variant="rect" count={titleRowLength} />
            </StyledTitle>
          )}
          {textRowLength > 0 && (
            <StyledText>
              <Skeleton variant="rect" count={textRowLength} />
            </StyledText>
          )}
          {hasPrice && (
            <StyledPrice>
              <Skeleton variant="rect" />
            </StyledPrice>
          )}
        </StyledFigcaption>

        {hasPlayButton && <StyledPlayButton circle width={30} height={30} />}
      </StyledFigure>
    </StyledLink>
  )
}

export default Item

Item.propTypes = {
  listType: PropTypes.oneOf(['default', 'list', 'grid']),
  hasThumb: PropTypes.bool,
  titleRowLength: PropTypes.number,
  textRowLength: PropTypes.number,
  hasPrice: PropTypes.bool,
  hasPlayButton: PropTypes.bool
}

const StyledImg = styled.div.withConfig({
  shouldForwardProp: (prop) => !['listType'].includes(prop)
})`
  .react-loading-skeleton {
    max-width: 100%;

    ${({ listType }) => {
      switch (listType) {
        case 'default':
          return css`
            max-width: 144px;
            height: 81px;
          `
        case 'grid':
          return css`
            max-width: 218px;
            height: 122px;

            ${mediaQuery()} {
              max-height: calc((100vw - 30px - 10px) / 2 * 9 / 16);
            }
          `
        case 'list':
        default:
          return null
      }
    }}
  }
`

const StyledTitle = styled.span`
  display: block;

  .react-loading-skeleton {
    width: 75%;
    height: 19px;
  }
`

const StyledText = styled.span`
  display: block;

  & > span {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .react-loading-skeleton {
    height: 16px;
  }
`

const StyledPrice = styled.span`
  display: block;

  .react-loading-skeleton {
    width: 36px;
    height: 16px;
  }
`

const StyledFigcaption = styled(Figcaption).withConfig({
  shouldForwardProp: (prop) => !['listType'].includes(prop)
})`
  flex: ${({ listType }) => (listType === 'list' ? '1 0' : null)};

  & > span {
    width: 100%;
    display: block;
  }

  .react-loading-skeleton {
    display: block;
  }
`

const StyledPlayButton = styled(Skeleton)`
  min-width: 30px;
`
