import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import styled, { createGlobalStyle } from 'styled-components'
import {
  META_SCHEMA_ID,
  LOCAL_STORAGE_KEY_SHINCHAN_PAGER,
  LOCAL_STORAGE_KEY_SHINCHAN_SORT,
  LOCAL_STORAGE_KEY_SHINCHAN_FILTER
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  checkAvailableStatus,
  getThumbnailUrl,
  play
} from '../EpisodeDefault/util'

/** hooks */
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/* components */
import Footer from '../../../../common/components/appli/EpisodeAppFooter'
import RenewalPlayer from '../../player/RenewalPlayer'
import Notice from '../EpisodeDefault/Notice'
import CommonVideoArea from '../EpisodeDefault/CommonVideoArea'
import Meta from '../EpisodeDefault/Meta'
import Actions from '../EpisodeDefault/Actions'
import HtmlSnippet from '../../HtmlSnippet'
import FilterSort from '../../../../common/components/FilterSort'
import ListMetaSub from '../EpisodeDefault/ListMetaSub'
import HeaderNewsComponent from '../HeaderNewsComponent'
import SearchBoxYearWrapper from '../../../../common/components/FilterSort/SearchBoxYearWrapper'
import GoBack from '../../../../common/components/appli/renewal/GoBack'

/** style */
import { mediaQuery } from '../../style'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 単話ページ */
const EpisodeAppShinchan = (
  {
    seriesId = '',
    seasonId = '',
    episodeId = '',
    meta = {},
    programMeta = {},
    episodes = [],
    product = {},
    course = {},
    status = {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    sendPlayLog = () => {},
    loaded = true
  },
  context
) => {
  if (!size(meta) || !size(programMeta)) return null

  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isShinchan =
    Number(seasonId) === config.svod.shinchan.shinchan.season_id

  const [progress, setProgress] = useState(webApp.utils.progress(config, meta))

  const playerRef = useRef(null)
  const setPlayerRef = (e) => {
    playerRef.current = e
  }

  /**
   * ページャー、ソート、フィルタをlocalStorageから取得
   * クレヨンしんちゃんシーズンのときだけ
   */
  const dataFromLocalStorage = (() => {
    if (!isShinchan) return {}

    // ローカルストレージの値を取得
    const pageNum =
      parseInt(
        window.localStorage.getItem(LOCAL_STORAGE_KEY_SHINCHAN_PAGER),
        10
      ) || 1
    const sortedBy =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_SHINCHAN_SORT) ||
      'avails_release_history_original_older'
    const filteredBy =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_SHINCHAN_FILTER) || ''
    return { pageNum, sortedBy, filteredBy }
  })()

  const searchParams = useSearchParams(
    {
      childEpisodeIds: get(programMeta, ['values', 'child_episode_ids'], []),
      seasonIds: [Number(seasonId)],
      ...dataFromLocalStorage,
      pagerOptions: {
        episodesPerPages: 60,
        range: 2,
        showBottom: true
      }
    },
    {
      afterSearch: ({
        pageNum = 1,
        sortedBy = 'avails_release_history_original_older',
        filteredBy = ''
      }) => {
        if (!isShinchan) return
        const ls = window.localStorage
        ls.setItem(LOCAL_STORAGE_KEY_SHINCHAN_SORT, sortedBy)
        ls.setItem(LOCAL_STORAGE_KEY_SHINCHAN_FILTER, filteredBy)
        ls.setItem(LOCAL_STORAGE_KEY_SHINCHAN_PAGER, pageNum)
      }
    }
  )

  const onClickPlay = (e, asSvod = false, skipRedirectLogin = false) => {
    if (e) e.preventDefault()

    // 未ログイン状態の場合はログイン画面へ遷移する。
    if (!skipRedirectLogin && !isLoggedIn) {
      context.history.push(
        routes.app_login.makePath(null, {
          redirect: context.routeHandler.path
        })
      )
      return
    }

    switch (checkAvailableStatus({ status, product }, context, asSvod)) {
      case 'PLAY':
        play(playerRef.current, sendPlayLog)
        break
      case 'PURCHASE_EPISODE': // 有料なので何もする必要がない
      case 'PURCHASE_PLAN': // 有料なので何もする必要がない
      default:
        break
    }
  }

  const onClosePlayer = () => {
    setProgress(webApp.utils.progress(config, meta))
  }

  /** 選択中の放送年を更新 */
  const updateSelectedYear = (selectedYear) => {
    searchParams.set({
      selectedYear
    })

    // sp時のみ年次選択時スクロール
    if (window.matchMedia('(max-width: 1024px)').matches) {
      const divElm = document.getElementById('episodes-list')
      const clientRect = divElm.getBoundingClientRect()
      const rectY = clientRect.top + window.pageYOffset - 56
      window.scroll({ top: rectY, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    return () => {
      document.body.style.overflow = null
    }
  })

  const metaName = (() => {
    const [title, subTitle] = webApp.utils.titles(meta)
    return `${title} ${subTitle}`
  })()
  const isLive =
    meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
    meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
  let isClipVod = false

  // LIVE配信 主/副切替ID
  let metaIdForPlayer
  if (isLive) {
    if (meta.values.clipping_select_media_type === 'vod') {
      metaIdForPlayer = meta.values.clipping_vod_ref_id
      isClipVod = true
    }
    if (!metaIdForPlayer) metaIdForPlayer = meta.values.target_ref_id
  }
  if (!metaIdForPlayer)
    metaIdForPlayer = `${config.videocloud.reference_id_prefix || ''}${
      meta.meta_id
    }`
  const playerSettings = webApp.utils.getPlayerSettings(
    config,
    meta,
    status.displayMode
  )

  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  const isBefore = Boolean(
    !withValidPreviewToken && moment(prevTime).isBefore(meta.delivery_start_at)
  )
  const isAfter = Boolean(
    !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
  )
  const thumbnailUrl = getThumbnailUrl(
    { meta, programMeta },
    context,
    config,
    previewUrlList,
    cookieRubocopPrefix
  )
  const inViewTerm = !(isBefore || isAfter)
  const nextPrevText = (() => {
    if (inViewTerm || isLive) return null
    if (isBefore) return 'まもなく配信予定'
    return '次回の配信もお楽しみに！'
  })()

  const isPlayerRenderable =
    metaIdForPlayer &&
    status.displayMode &&
    (inViewTerm || withValidPreviewToken)

  const snippetId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 40
    : 41

  return (
    <div className="common-wrapper">
      <GoBack />
      <HeaderNewsComponent />
      <Notice programMeta={programMeta} />

      {isPlayerRenderable && (
        <RenewalPlayer
          ref={(e) => setPlayerRef(e)}
          meta_id={metaIdForPlayer}
          thumbnail_url={meta.thumbnail_url}
          subtitle={!!meta.values.subtitle}
          enqueteEnabled={status.isFree}
          onClose={onClosePlayer}
          material_id={get(product, ['ref_id']) || ''}
          license_id={get(course, ['ref_id']) || ''}
          display_mode={status.displayMode}
          isLive={isLive}
          isClipVod={isClipVod}
          {...playerSettings}
        />
      )}

      <CommonVideoArea
        progress={progress}
        onClickPlay={onClickPlay}
        isLive={isLive}
        inViewTerm={inViewTerm}
        metaName={metaName}
        thumbnailUrl={thumbnailUrl}
        nextPrevText={nextPrevText}
        permitClickPlayButton={!isLoggedIn}
        meta={meta}
        status={status}
        product={product}
        episodeId={episodeId}
        seriesId={seriesId}
        seasonId={seasonId}
      />

      <div className="c-storyMeta">
        <div className="c-storyMeta-inBox">
          <Meta
            isLive={isLive}
            metaName={metaName}
            meta={meta}
            product={product}
            course={course}
            status={status}
          />
          <Actions
            onClickPlay={onClickPlay}
            inViewTerm={inViewTerm}
            metaName={metaName}
            seriesId={seriesId}
            seasonId={seasonId}
            meta={meta}
            product={product}
            status={status}
            course={course}
            loaded={loaded}
            renderLinkAppLogin
          />
        </div>
      </div>

      <div className="c-listMeta">
        <div className="c-listMeta-inBox">
          <div className="c-listMeta-inBox-main">
            <HtmlSnippet key="banner" snippetId={snippetId} />
            {Object.keys(programMeta).length > 0 && (
              <section>
                <StyledH3>単話</StyledH3>
                {isShinchan && (
                  <StyledSearchBoxYearWrapper
                    selectedYear={searchParams.state.selectedYear}
                    setSelectedYear={updateSelectedYear}
                  />
                )}
                <StyledFilterSort
                  searchParams={searchParams}
                  episodeListItemProps={{
                    showNew: true,
                    showChecked: true,
                    showCaption: true,
                    onlySubTitle: true
                  }}
                />
              </section>
            )}
          </div>
          <ListMetaSub
            loaded={loaded}
            episodes={episodes}
            course={course}
            seriesId={seriesId}
            seasonId={seasonId}
            status={status}
            meta={meta}
          />
        </div>
      </div>
      <Footer
        className="mt0"
        copyrights={get(meta, ['values', 'evis_Copyright'])}
      />
      <GlobalStyle />
    </div>
  )
}

export default EpisodeAppShinchan

EpisodeAppShinchan.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のエピソードID */
  episodeId: PropTypes.string,

  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** シーズンのメタ情報 */
  programMeta: PropTypes.object,
  /** 動画の価格、配信期間情報など */
  product: PropTypes.shape({
    product_id: PropTypes.number,
    name: PropTypes.string,
    original_price: PropTypes.number,
    active_pricing: PropTypes.shape({
      price: PropTypes.number,
      unit: PropTypes.string
    })
  }),

  // 通常単話
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  // 月額見放題
  /** 動画のコース情報 */
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),

  /** 動画の視聴ステータス情報 */
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  /** 動画再生時のログ送信 */
  sendPlayLog: PropTypes.func,
  /** データの取得が終わっているか */
  loaded: PropTypes.bool
}

EpisodeAppShinchan.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 600;

  ${mediaQuery()} {
    padding: 0 15px;
  }
`

const StyledSearchBoxYearWrapper = styled(SearchBoxYearWrapper)`
  margin-top: 20px;
`

const StyledFilterSort = styled(FilterSort)`
  margin-top: 20px;
`

const GlobalStyle = createGlobalStyle`
  .c-listMeta-inBox-main {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
`
