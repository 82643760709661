import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import classnames from 'classnames';
import _ from 'lodash';
import Cookie from 'js-cookie';
import $ from 'jquery';

import ExPresTrackedElement from '../../../../common/components/ExPresTrackedElement';
import routes from '../../../../common/routes';
import MainViewLink from '../../../../common/components/MainViewLink';

// MEMO: topページの子メタ
export default class Card extends Component {

  static getPaths = function(models, options, props = {}) {
    let keyPath = [];
    if (props.titleId) {
      keyPath = keyPath.concat(['meta', props.titleId]);
    }
    const paths = [keyPath.concat([['id', 'name']])];
    return [keyPath.concat([['id','name', 'thumbnailUrl', 'type', 'values', 'deliveryStartAt', 'deliveryEndAt']])];
  };

  static getRootPath = function(models, options, props = {}) {
    if (!props.titleId) return;
    return ['meta', props.titleId];
  }

  static propTypes = {
    sliderItemId: PropTypes.string,
    getRowHasPopOpen: PropTypes.func,
    isDoubleWide: PropTypes.bool,
    isTallPanel: PropTypes.bool,
    itemTabbable: PropTypes.bool,
    model: PropTypes.object.isRequired,
    onPopClose: PropTypes.func,
    onPopLeave: PropTypes.func,
    onPopOpen: PropTypes.func,
    rowModel: PropTypes.object,
    rankNum: PropTypes.number,
    rowNum: PropTypes.number,
    titleId: PropTypes.number.isRequired,
    popType: PropTypes.string,
    popClasses: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    progress: PropTypes.number,
    imgTarget: PropTypes.string,
  };

  static defaultProps = {
    rankNum: 0,
    rowNum: 0
  };

  static contextTypes = {
    models: PropTypes.object,
    columnsInRow: PropTypes.number,
    currentPage: PropTypes.string,
    getModelData: PropTypes.func,
    history: PropTypes.object,
    isInitialRender: PropTypes.bool,
    isOverlayPage: PropTypes.bool,
    isRtl: PropTypes.bool,
    isTallRow: PropTypes.bool,
    titleRowEpisodeTrackId: PropTypes.number,
    titleRowTrackId: PropTypes.number,
    titleRowTrailerTrackId: PropTypes.number,
    listContext: PropTypes.string,
    listType: PropTypes.string,
    loggingApp: PropTypes.object,
    lolomoId: PropTypes.string,
    profileManager: PropTypes.string,
    requestId: PropTypes.string,
    routeHandler: PropTypes.object,
    rowNum: PropTypes.number,
    trackId: PropTypes.number,
    resume: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    this.handleHover = this.handleHover.bind(this);
    this.item = props.model.getSync(rootPath);
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath)
    };
    this._isMounted = false;
    this.imgResize = this.imgResize.bind(this);
    this.previewUrlList = this.context.models.config.data.preview_url_list;
    this.cookieRubocopPrefix = this.context.models.config.data.cookie_rubocop_prefix;
    this.imgTarget = this.props.imgTarget || 'avod_ep_list_img'
  }
  handleHover(){
    this.setState({
      isHovered: !this.state.isHovered
    });
  }

  componentWillMount() {
    this.fetchData(this.props);
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this.imgResize);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.imgResize);
    
    if (this.mouseOutTimeoutId) {
      clearTimeout(this.mouseOutTimeoutId);
      delete this.mouseOutTimeoutId;
    }
    if (this.mouseOverTimeoutId) {
      clearTimeout(this.mouseOverTimeoutId);
      delete this.mouseOverTimeoutId;
    }
  }
  
  imgResize() {
    let imgSize = $(`.${this.imgTarget}`);
    if(imgSize != "undefined"){
      $(imgSize).height($(imgSize).width() * 0.562);
    }
  }

  nextPrevText(e, styles) {
    return(
      <div className={this.imgTarget}>
        <div
        className={classnames('title-artwork avod_ep_list_thumb', {
          'double-wide': this.props.isDoubleWide === true && this.props.isTallPanel !== true
        })}
        style={styles}></div>
        <div className="avod_ep_list_prg_end"><p>{e}</p></div>
      </div> 
    )
  }


  render() {
    if (!this.item) {
      return null;
    }

    var itemName = '　'
    var deliveryEndAt = '　'
    var deliveryEndAtSet =  '　'
    var onAirAt = '　'
    if (this.item){
      // MEMO: タイトル（全て表示に変更）
      if ( this.item.name ) {
        itemName = this.item.name
      }

      // MEMO: 配信期間終了日
      if (this.item.deliveryEndAt) {
        deliveryEndAt = moment(this.item.deliveryEndAt).format("YYYY年M月D日 HH:mmまで")
        if (this.props.program_link) {
          deliveryEndAtSet = (<p className="avod_ep_list_deli">
              エピソード一覧
            </p>
          );

        } else {
          deliveryEndAtSet = (<p className="avod_ep_list_deli">
              <span>配信期間：</span>{ deliveryEndAt }
            </p>
          );
        }
      } else {
        deliveryEndAtSet = (<p className="avod_ep_list_deli">
              <span>配信期間：</span>{ deliveryEndAt }
            </p>
        );
      }

      // MEMO: 番組名・単話名
      var itemName = this.item.name;
      var episodeName = this.item.values.avails_EpisodeTitleDisplayUnlimited;
      var programName = itemName.replace(episodeName, '').trim();
      var titleName = '';


      // MEMO: 放送日
      if (this.item.values.avails_ReleaseHistoryOriginal) {
        onAirAt = moment(this.item.values.avails_ReleaseHistoryOriginal).format("放送日：YYYY/M/D")
      }
    }

    // MEMO: 時間
    let duration = 0;
    if (this.props.program_link) {
      duration = ''
    } else if (this.item.values.duration) {
      duration = this.item.values.duration;
      let seconds = duration % 60; let minutes = ((duration - seconds) / 60) % 60; let hour = parseInt(duration / 3600, 10);
      if (hour > 0) {
        if (minutes > 0) {
          duration = `${hour}時間${minutes}分`;
        } else {
          duration = `${hour}時間`;
        }
      } else if (minutes > 0) {
        duration = `${minutes}分`;
      } else {
        duration = `${seconds}秒`;
      }
    }

    const childFactory = (child) => {
      return React.cloneElement(child, {
      });
    };

    const key = `title-card-${this.props.rowNum}-${this.props.rankNum}`;
    const tabIndex = (this.props.itemTabbable) ? 0 : -1;

    // MEMO: 配信期間・公開期間
    let titleArtwork;
    
    const thumbnailUrl = this.item.thumbnailUrl + '_medium' || window.app.reactContext.models.config.data.default_thumbnail;
    const prevTime = Cookie.get(this.previewUrlList + this.cookieRubocopPrefix);
    const styles = {
      backgroundImage: `url('${thumbnailUrl}')`, 
      opacity: this.state.isHovered ? "0.8" : "1",
      maxHeight: this.imgResize()
    };

    const progressbar = this.props.program_link || this.props.progress === 0 ? '' :
    (
      <div className="avod_ep_list_progress_wrap">
        <div className="avod_ep_list_progress" style={{width: `${this.props.progress}%`}}></div>
      </div>
    );
  
    if (moment(prevTime).isBefore(this.item.deliveryStartAt) && !this.props.program_link) {
      titleArtwork = this.nextPrevText('まもなく配信予定', styles);
    } else if (moment(prevTime).isAfter(this.item.deliveryEndAt) && !this.props.program_link){
      titleArtwork = this.nextPrevText('次回もお楽しみに！', styles);
    } else {
      const icon = this.props.program_link ? (<img src="/images/exdio/list.png" id="overPlay" alt="リストアイコン" width="78"/>) : ""; 
      titleArtwork = (<div className={this.imgTarget}>
        <div
        className={classnames('title-artwork avod_ep_list_thumb', {
          'double-wide': this.props.isDoubleWide === true && this.props.isTallPanel !== true
        })}
        style={styles}></div>
          {icon}
          <span className="avod_ep_list_time">{duration}</span>
          {progressbar}
        </div> 
      );
    }

    let titleTallArtwork;
    if (this.props.isTallPanel === true) {
      titleTallArtwork = (
        <div className={'title-artwork title-artwork-tall'} style={{backgroundImage: `url('${this.item.tallThumbnailUrl}')`}}>
        </div>
      );
    }

    let metaSet = ""

      if (this.props.program_link) {
        metaSet = (<div className="avod_line_box_link">
              {titleArtwork}
              {deliveryEndAtSet}
              <h2 className="avod_ep_list_ttl program_list"><span className={this.state.isHovered ? "hover" : ""}>{programName}</span></h2>
            <p className="avod_ep_list_air">{ onAirAt }</p>
          </div>
        );
      } else {
        const itemPrefixSubtileIcon = this.props.subtitle ? <img src="/images/exdio/top_cc_icon.png" /> : ''
        metaSet = (<div className="avod_line_box_link">
              {titleArtwork}
              {deliveryEndAtSet}
              <h2 className="avod_ep_list_ttl"><span className={this.state.isHovered ? "hover" : ""}>{programName}</span></h2>
              <p className="avod_ep_list_name">{itemPrefixSubtileIcon}<span className={this.state.isHovered ? "hover" : ""}>{episodeName}</span></p>
            <p className="avod_ep_list_air">{ onAirAt }</p>
          </div>
        );
      }

    return (
      <li className="avod_line_box" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
        <div
          className={classnames('smallTitleCard', 'title_card', {
            'hasPop': this.state.hasPop === true,
            'lockup': true,
            'sliderRefocus': true,
          })}
          aria-label={this.item.name}
          ref="titleCard"
          tabIndex={tabIndex}
          role={'link'}
          key={key}
          id={key}
          onClick={this.handleClick.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
          onMouseEnter={this.handleMouseOver.bind(this)}
          onMouseLeave={this.handleMouseOut.bind(this)}>
          <ExPresTrackedElement idDoubleWide={this.props.isDoubleWide}>
            {metaSet}
          </ExPresTrackedElement>
        </div>
      </li>
    );
  }

  handleClick(e) {
    e.preventDefault();
    const { history } = this.context;
    if (this.props.program_link == true && this.props.seasonId){
      history.push(routes.program.makePath({id: this.props.seasonId}));
    } else {
      history.push(routes.watch.makePath({id: this.props.titleId}));
    }
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  handleMouseOver(e) {

    if (this.props.popType === 'none') {
      return;
    }

    if (this.state.hasPop && this.mouseOutTimeoutId && this.props.onPopOpen) {
      this.props.onPopOpen(this);
    }

    if (this.mouseOutTimeoutId) {
      clearTimeout(this.mouseOutTimeoutId);
      delete this.mouseOutTimeoutId;
      return;
    }

    let timeout = 300;
    if (this.props.getRowHasPopOpen) {
      if (this.props.getRowHasPopOpen()) {
        timeout = 100;
      }
    }
    if (this.popUnmounting === true) {
      timeout = 300;
    }

    this.alreadyPopOpened = false;
    this.mouseOverTimeoutId = setTimeout(() => {
      delete this.mouseOverTimeoutId;
      this.setState({hasPop: true});
    }, timeout);
  }

  handleMouseOut(e) {

    if (this.props.popType === 'none') {
      return;
    }

    // まだ拡大が始まっていない時は拡大しない
    if (this.mouseOverTimeoutId) {
      clearTimeout(this.mouseOverTimeoutId);
      delete this.mouseOverTimeoutId;
    }

    if (!this.state.hasPop) return;

    const delay = (this.alreadyPopOpened) ? 150 : 1;
    if (this.props.onPopLeave) {
      this.props.onPopLeave(this, delay);
    }

    this.mouseOutTimeoutId = setTimeout(() => {
      delete this.mouseOutTimeoutId;
      this.popUnmounting = true;
      this.setState({hasPop: false});
    }, delay);
  }

  onPopOpen() {
    if (this.props.popType === 'none') {
      return;
    }

    this.alreadyPopOpened = true;
    if (this.props.onPopOpen && this.state.hasPop === true) {
      this.props.onPopOpen(this);
    }
  }

  onPopClose() {
    if (this.props.popType === 'none') {
      return;
    }

    delete this.popUnmounting;
    if (this.props.onPopClose) {
      this.props.onPopClose(this);
    }
  }

  getZoomScale() {
    return PopCard.getZoomScale(this.props.isTallPanel, this.props.popType);
  }

  fetchData(props) {
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    this.state.dispose = evaluator.dispose;
    evaluator.then((res) => {
      this.item = _.get(res.json, rootPath, {});
      const newState = {
        fetchDataError: null,
        dispose: null,
        generation: props.model.getVersion(rootPath)
      };
      if (this._isMounted) this.setState(newState);
      else Object.assign(this.state, newState);
    }).catch((e) => {
      console.error(e.stack);
      const newState = {
        fetchDataError: e,
        dispose: null
      };
      if (this._isMounted) this.setState(newState);
      else this.state = Object.assign(newState);
    });
  }
}
