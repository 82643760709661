import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DFPBanner from '../../../../common/components/DFPBanner';
import Palette from '../../../../common/components/renewal/Palette';
import ImageSlider from '../../../../common/components/renewal/ImageSlider';
import { PALETTE_SCHEMA_ID } from '../../../../../constants/app';
import TwitterIframe from '../../../../common/components/renewal/TwitterIframe';
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation';
import Footer from '../../../../common/components/Footer';

/** ジャンルトップ テストテンプレート */
export default class GenreTemplateTest extends Component {
  static propTypes = {
    genre: PropTypes.string.isRequired,
    /** 画像スライドの描画に使用 */
    canvasConfig: PropTypes.shape({
      ad_idx_banner: PropTypes.number,
      ad_idx_side: PropTypes.number,
      large_idx: PropTypes.arrayOf(PropTypes.number),
      twitter: PropTypes.bool,
      ad: PropTypes.bool,
      more_link: PropTypes.bool
    }).isRequired,
    /** 画像スライドのタイトル、タイプ判別に使用 */
    attribute: PropTypes.object,
    /** 画像スライドに描画する情報のパレット */
    palettes: PropTypes.array,
    /** 動画の視聴権利関連情報 */
    howToPlays: PropTypes.object,
    /** 動画の価格、配信期間情報など */
    products: PropTypes.object,
    /** 動画のコース情報 */
    courses: PropTypes.object
  };

  static defaultProps = {};

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {}

  renderWithAd(paletteComponents) {
    const { ad_idx_side, ad_idx_banner, twitter } = this.props.canvasConfig;
    const adIdxSide = ad_idx_side || 3;
    const adIdxBanner = ad_idx_banner || 4;

    const paletteComponentsWithBanner = [];
    paletteComponentsWithBanner.push(...paletteComponents.slice(0, adIdxBanner - 1));
    paletteComponentsWithBanner.push(<DFPBanner position="head" meta_id="" key="banner" />);
    paletteComponentsWithBanner.push(...paletteComponents.slice(adIdxBanner - 1));

    // configのad_idx_banner, ad_idx_sideで指定した行に広告挿入
    const rowCountWithAd = (twitter ? 3 : 1) - 1;
    const beforeAdPalettes = paletteComponentsWithBanner.slice(0, adIdxSide - 1);
    const adPalettes = paletteComponentsWithBanner.slice(adIdxSide - 1, adIdxSide + rowCountWithAd);
    const afterAdPalettes = paletteComponentsWithBanner.slice(adIdxSide + rowCountWithAd);

    return [
      beforeAdPalettes,
      <div className="c-card-withAd" key="ad-row">
        <div className="c-card-withAd-colCards">{adPalettes}</div>
        <div className="c-card-withAd-colAd">
          <div className="common-box-ad">
            <DFPBanner position="foot" meta_id="" />
          </div>
          {twitter && <TwitterIframe />}
        </div>
      </div>,
      afterAdPalettes
    ];
  }

  render() {
    const { genre, canvasConfig, attribute, palettes, howToPlays, products, courses } = this.props;
    const { large_idx, ad, palette_header } = canvasConfig;

    // 画像スライド用パレット
    const imageSliderPalette = (palettes || []).find(p => p.schema_id === PALETTE_SCHEMA_ID.IMAGES);
    const imageSliderObjects = _.get(imageSliderPalette, 'objects') || [];

    // 画像スライド以外のパレット
    const paletteComponents = (palettes || []).filter(p => p.schema_id !== PALETTE_SCHEMA_ID.IMAGES).map((p, i) => {
      return (
        <Palette
          key={p.palette_id}
          palette={p}
          howToPlays={howToPlays}
          large={(large_idx || []).includes(i + 1)}
          products={products}
          courses={courses}
          paletteHeader={palette_header !== false}
        />
      );
    });

    return (
      <div className="common-wrapper">
        <div className="c-head-sub">
          <div className="c-head-sub-genreTitle">
            <h2 className="c-head-sub-genreTitle-title">{attribute.name}</h2>
          </div>
        </div>
        <SpSubNavigation key="sp-nav" />
        <div>テンプレートテスト</div>
        <ImageSlider key="slide-program" objects={imageSliderObjects} />
        {ad === false ? paletteComponents : this.renderWithAd(paletteComponents)}
        <Footer className="mt120" />
      </div>
    );
  }
}
