/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../style'

export const StyledDiv1 = styled.div``

export const StyledDiv2 = styled.div`
  min-width: 1024px;

  ${mediaQuery()} {
    min-width: auto;
  }
`

export const StyledDiv3 = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const StyledDiv4 = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;

  ${mediaQuery()} {
    display: none;
  }
`

export const StyledDiv5 = styled.div`
  max-width: 1272px;
  margin: 55px auto 60px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px 60px;

  ${StyledDiv1} {
    :nth-child(n) {
      margin-top: 0;
    }
  }

  div {
    a {
      display: block;
      color: black;
      text-decoration: none;
      overflow: hidden;
      position: relative;

      :hover {
        img {
          transform: scale(1.1);
          transition-duration: 0.3s;
        }
      }
    }
  }

  p {
    font-size: 1.4rem;
    font-family: 'Hiragino Kaku Gothic ProN';
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 10.5px;
  }

  ${mediaQuery(1280)} {
    max-width: 1070px;
  }

  ${mediaQuery(1196)} {
    gap: 20px;
  }

  ${mediaQuery(1090)} {
    max-width: 875px;
  }

  ${mediaQuery()} {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 12px;
  }

  ${mediaQuery(920)} {
    max-width: 690px;
  }

  ${mediaQuery(765)} {
    max-width: 92vw;
    margin: 5.33vw auto 10.66vw;

    div {
      a {
        display: block;
        color: black;
        text-decoration: none;
        overflow: hidden;
        position: relative;

        img {
          margin: 0 auto;
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  ${mediaQuery(578)} {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const StyledH2 = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;

  ${mediaQuery()} {
    width: 100%;
    margin: 0;
    padding: 15px 0;
  }
`

export const StyledDiv6 = styled.div`
  margin-top: 120px;
`
