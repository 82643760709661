import { useState, useEffect } from 'react'
import window from 'global/window'
import { breakPoints, minmaxDecision } from '../exdio/components/style'

/**
 * メディアクエリのカスタムhook
 * @param {number | keyof typeof breakPoints} width - ビューポートの幅またはbreakPointsのキーです。
 * @param {'min'|'max'} minmax - 幅の最小値または最大値を指定します。
 * @returns {boolean} マッチしたかどうか
 */
const useMediaQuery = (minmax = 'max', width = breakPoints.sm) => {
  const _width =
    typeof width === 'string' ? minmaxDecision(width, minmax) : width
  const isMatches = () =>
    window &&
    window.matchMedia &&
    window.matchMedia(`(${minmax}-width: ${_width}px)`).matches

  const [isMatch, setIsMatch] = useState(isMatches())

  const resetIsMatch = () => {
    const nowIsMatch = isMatches()
    setIsMatch(() => nowIsMatch)
  }

  useEffect(() => {
    window.addEventListener('resize', resetIsMatch)
    return () => {
      window.removeEventListener('resize', resetIsMatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isMatch
}

export default useMediaQuery
