/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../../../exdio/components/style'

export const StyledMain = styled.main`
  width: 100%;
  background: rgba(255, 255, 255, 0);
  max-width: 900px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 75px;

  ${mediaQuery()} {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const StyledHeader = styled.header`
  position: relative;
  margin-bottom: 30px;

  ${mediaQuery()} {
    background: #fff;
    padding: 24px 0 0 0;
    margin-bottom: 0;
  }
`

export const StyledH2 = styled.h2`
  text-align: center;
  font-size: 2rem;
  font-weight: 600;

  ${mediaQuery()} {
    font-size: 1.4rem;
  }
`

export const StyledNav = styled.nav`
  margin-top: 25px;
`

export const StyledUl = styled.ul`
  display: flex;

  ${mediaQuery()} {
    padding: 0 16px;
  }
`
