import React from 'react'
import PropTypes from 'prop-types'
import List from './List/'
import {
  StyledDiv1,
  StyledDiv2,
  StyledDiv3,
  StyledHeading1,
  StyledDiv4,
  StyledDiv5,
  StyledImg,
  StyledDiv6,
  StyledP1,
  StyledDiv7
} from './style'

/** ガリベン大学 Section8 */
const Section8 = ({ recommends = [], episodes = [], lead = '' }) => {
  if (!episodes.length) return null

  if (recommends.isActive) {
    return (
      <section id="top8">
        <StyledHeading1
          src="/images/exdio/renewal/gariben_daigaku/top/sec8_hdg.webp"
          alt="神回アーカイブ ARCHIVE"
          width="443"
          height="129"
        >
          <StyledDiv1>
            <StyledDiv2 className="lazyload" />
          </StyledDiv1>
        </StyledHeading1>
        <StyledDiv3 className="lazyload">
          {lead && (
            <StyledDiv4 className="lazyload">
              <p>{lead}</p>
            </StyledDiv4>
          )}
          {recommends.recommenders.map((recommender, index) => {
            const movieInfoList = []
            recommender.episodeIds.map((id) => {
              return movieInfoList.push(
                episodes.find((episode) => episode.id === id)
              )
            })
            return (
              <div key={index}>
                <StyledDiv5>
                  <StyledImg src={recommender.image} alt={recommender.name} />
                  <StyledDiv6>
                    <StyledP1>{recommender.name}さんのコメント</StyledP1>
                    <StyledDiv7
                      dangerouslySetInnerHTML={{ __html: recommender.text }}
                    />
                  </StyledDiv6>
                </StyledDiv5>
                <List episodes={movieInfoList} />
              </div>
            )
          })}
        </StyledDiv3>
      </section>
    )
  }
  return (
    <section id="top8">
      <StyledHeading1
        src="/images/exdio/renewal/gariben_daigaku/top/sec8_hdg.webp"
        alt="神回アーカイブ ARCHIVE"
        width="443"
        height="129"
      >
        <StyledDiv1>
          <StyledDiv2 className="lazyload" />
        </StyledDiv1>
      </StyledHeading1>
      <StyledDiv3 className="lazyload">
        {lead && (
          <StyledDiv4 className="lazyload">
            <p>{lead}</p>
          </StyledDiv4>
        )}
        <List episodes={episodes} />
      </StyledDiv3>
    </section>
  )
}

export default Section8

Section8.propTypes = {
  recommends: PropTypes.object.isRequired,
  /** 神回アーカイブ動画情報の配列 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  lead: PropTypes.string.isRequired
}
