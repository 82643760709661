import React from 'react'
import HtmlSnippet from '../../../HtmlSnippet'
import { StyledButton3, StyledDiv1, StyledDiv2, StyledHeading1 } from './style'

/** ガリベン大学 購買部 */
const Section5 = () => {
  return (
    <section id="top5">
      <StyledHeading1
        src="/images/exdio/renewal/gariben_daigaku/top/sec5_hdg.webp"
        alt="購買部 BOOTH"
        width="303"
        height="130"
      />
      <StyledDiv1 className="lazyload">
        <StyledDiv2>
          <HtmlSnippet snippetId="plan_gariben_booth" />
          <StyledButton3
            href="https://garibenv.booth.pm/"
            target="_blank"
            name="番組公式BOOTHはこちら"
          />
        </StyledDiv2>
      </StyledDiv1>
    </section>
  )
}

export default Section5
