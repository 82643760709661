import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import webApp from '../../../../utils/exdioWebAppUtils'

/** ButtonSP Component */
const ButtonSP = ({ watchAvailableStatus = 3, ...props }, context) => {
  const isLoggedIn = webApp.utils.isLoggedIn(context)

  switch (watchAvailableStatus) {
    case 0:
      return <StyledSpan {...props}>視聴する</StyledSpan>
    case 1:
      if (isLoggedIn) {
        return <StyledSpan {...props}>購入して再生</StyledSpan>
      }
      return <StyledSpan {...props}>ログイン</StyledSpan>
    case 2:
      if (isLoggedIn) {
        return <StyledSpan {...props}>視聴する</StyledSpan>
      }
      return <StyledSpan {...props}>ログイン</StyledSpan>
    default:
      return null
  }
}

export default ButtonSP

ButtonSP.propTypes = {
  /** 視聴権フラグ */
  watchAvailableStatus: PropTypes.oneOf([0, 1, 2, 3])
}

ButtonSP.contextTypes = {
  models: PropTypes.shape({
    authContext: PropTypes.shape({
      data: PropTypes.object
    })
  })
}

const StyledSpan = styled.span`
  padding-top: 68px;
  width: 80px;
  height: 80px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_sp.svg)
    no-repeat top center;
  display: block;
  font-size: 1.1rem;
  text-align: center;
  font-weight: bold;
  filter: drop-shadow(0 0 3px #fff);
`
