import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import convertTimeString from '../../functions/convertTimeString'

/** そだてれび：海外視聴用動画一覧 要素コンポーネント サムネイル */
const Thumbnail = ({ thumbnailAlt, thumbnailUrl, duration, isSmp }) => {
  return (
    <StyledDiv>
      <StyledImg src={thumbnailUrl} alt={thumbnailAlt} />
    </StyledDiv>
  )
}

export default Thumbnail

Thumbnail.propTypes = {
  /** サムネイル画像Alt */
  thumbnailAlt: PropTypes.string,
  /** サムネイル画像Src */
  thumbnailUrl: PropTypes.string,
  /** 動画再生時間 */
  duration: PropTypes.string,
  /** スマートフォンでの表示か */
  isSmp: PropTypes.bool
}

const StyledDiv = styled.div`
  position: relative;
  line-height: 0;
`

export const StyledImg = styled.img`
  transition: opacity 0.3s;
`
