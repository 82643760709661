import styled from 'styled-components'
import { mediaSp } from '../styles'

export const StyledDiv = styled.div`
  margin: 20px auto 0;
  width: 400px;

  @media ${mediaSp} {
    width: 335px;
  }
`

export const StyledP = styled.p`
  margin-top: 10px;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.5;

  &:first-child {
    margin-top: 0;
  }

  a {
    text-decoration: underline;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: #36f;
    }

    &:hover {
      text-decoration: none;
    }
  }
`
