import React from 'react'
import PropTypes from 'prop-types'
import { FAVORITE_TYPE } from '../../../../../../constants/app'

/* components */
import MyListButton from '../../EpisodeGariben/EpisodePager/MyListButton'
import ShareButton from '../../EpisodeGariben/EpisodePager/ShareButton'

/* style */
import { StyledUl, StyledLi } from './style'

/** マイリスト、シェアボタン */
const ButtonList2 = ({ meta = {}, metaName = '', ...props }) => {
  return (
    <StyledUl {...props}>
      <StyledLi>
        <MyListButton
          favoriteType={FAVORITE_TYPE.META}
          favoriteId={meta.meta_id}
        />
      </StyledLi>
      <StyledLi>
        <ShareButton title={metaName} />
      </StyledLi>
    </StyledUl>
  )
}

export default ButtonList2

ButtonList2.propTypes = {
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  metaName: PropTypes.string
}
