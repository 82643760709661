import React from 'react'
import styled from 'styled-components'
import routes from '../../../../../common/routes'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import { mediaPc } from '../styles'

/** そだてれび：海外視聴用 Footer */
const Footer = ({ isSmp, ...props }) => {
  return (
    <StyledFooter {...props}>
      <StyledLink route={routes.home}>tv asahi douga</StyledLink>
      <StyledSmall>Copyright(C) tv asahi All rights reserved.</StyledSmall>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  padding: 40px;
  margin: 0 auto;
  text-align: center;

  @media ${mediaPc} {
    padding: 80px;
    max-width: ${({ isSmp }) => !isSmp && '1280px'};
  }
`

const StyledLink = styled(Link)`
  font-size: 28px;
  text-align: center;
`

const StyledSmall = styled.small`
  display: block;
  margin-top: 8px;
  font-size: 12px;
  text-align: center;
`
