import styled, { keyframes } from 'styled-components'

const pop = keyframes`
  from {
    transform: scale(1)
  }
  50% {
    transform: scale(1.1)
  }
  to {
    transform: scale(1)
  }
`

export const StyledDiv1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ loaded }) => (loaded ? '-1' : '999')};
  opacity: ${({ loaded }) => (loaded ? '0' : '1')};
  overflow: hidden;
  transition: z-index 0.4s ease-out 0.4s, opacity 0.4s ease-out 0.4s;
`

export const StyledImg = styled.img`
  position: relative;
  z-index: 1;
  animation: ${pop} 8s infinite;
`

export const StyledDiv2 = styled.div`
  width: 100%;
  height: 12.5%;
  background-color: #fff;
  position: absolute;
  top: ${({ i }) => 12.5 * i}%;
  z-index: 0;
  transform-origin: ${({ direction }) => direction};
  transform: ${({ loaded }) => (loaded ? 'scale(0, 1)' : 'scale(1, 1)')};
  transition: transform 0.3s ease-out ${({ i }) => 0.1 * i}s;
`
