import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../common/routes'

/* style */
import * as Component from './style'

/** ガリベン大学 Section2 */
const Section2 = ({ searchParams = {}, tags = [] }, context) => {
  const config = context.models.config.data
  const listRef = useRef(null)

  /** タグの変更に応じて検索条件を変更する */
  const onChangeTags = (e) => {
    const { checked, value } = e.currentTarget
    const selected =
      checked === true
        ? [...searchParams.state.tags, value]
        : searchParams.state.tags.filter((tag) => tag !== value)
    searchParams.set({ tags: selected })
  }

  /** 計測用のclassを追加 (フリーワード検索) */
  useEffect(() => {
    const filterSort = document.getElementById('episodes-list')
    if (!filterSort) return

    const input = filterSort.querySelector('input[type="text"]')
    if (!input) return

    input.classList.add('gari_plan_free')
  }, [])

  /** 計測用のclassを追加 (タグ) */
  useEffect(() => {
    if (!listRef.current) return

    const listItems = listRef.current.querySelectorAll('li')
    listItems.forEach((li) => {
      li.classList.add('gari_plan_tag')
    })
  }, [tags])

  return (
    <section id="top2">
      <Component.StyledHeading1
        src="/images/exdio/renewal/gariben_daigaku/top/sec2_hdg.webp"
        alt="特別授業動画 SPECIAL MOVIE"
        width="505"
        height="128"
      >
        <Component.StyledSpan className="lazyload" />
      </Component.StyledHeading1>
      <Component.StyledDiv1 className="lazyload">
        <Component.StyledDiv2>
          <Component.StyledDiv3>
            <Component.StyledDiv4>
              <Component.StyledH3>おすすめのタグ</Component.StyledH3>
              <Component.StyledTagList
                tags={tags}
                selected={searchParams.state.tags}
                changeTags={onChangeTags}
                ref={listRef}
              />
            </Component.StyledDiv4>
            <Component.StyledFilterSort
              searchParams={searchParams}
              episodeListItemProps={{
                showNew: true,
                showChecked: true,
                showCoin: true,
                showCaption: true,
                onlySubTitle: true
              }}
            />
          </Component.StyledDiv3>
          <Component.StyledButton3
            route={routes.program}
            params={{
              seriesId: config.svod.gariben_daigaku.special_movies.series_id,
              seasonId: config.svod.gariben_daigaku.special_movies.season_id
            }}
            name="もっと見る"
          />
        </Component.StyledDiv2>
      </Component.StyledDiv1>
    </section>
  )
}

export default Section2

Section2.propTypes = {
  /** 検索用パラメータ */
  searchParams: PropTypes.shape({}),
  /** タグ */
  tags: PropTypes.arrayOf(PropTypes.string)
}

Section2.contextTypes = {
  models: PropTypes.shape({
    config: PropTypes.shape({
      data: PropTypes.shape({
        svod: PropTypes.shape({
          gariben_daigaku: PropTypes.shape({
            special_movies: PropTypes.shape({
              series_id: PropTypes.number,
              season_id: PropTypes.number
            })
          })
        })
      })
    })
  })
}
