import styled from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'
export const mediaSp = '(max-width: 1024px)'

export const StyledDiv = styled.div`
  width: 1280px;
  margin: 0 auto;

  ${mediaQuery(1320)} {
    width: 960px;
  }

  ${mediaQuery()} {
    width: 100%;
    text-align: center;
  }
`

export const StyledA = styled.a`
  margin-right: 5px;
  display: inline-block;

  ${mediaQuery()} {
    margin: 0 5px;
  }
`

export const StyledImg = styled.img`
  width: 30px;
`
