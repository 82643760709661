import React from 'react'
import PropTypes from 'prop-types'
import img1 from '../../../../../../../../public/images/exdio/renewal/gariben_daigaku/nav/a-nav_01.webp'
import img2 from '../../../../../../../../public/images/exdio/renewal/gariben_daigaku/nav/a-nav_01_bg.webp'
import { StyledButton, StyledImg, StyledSpan1, StyledSpan2 } from './style'

/** Nav5｜アコーディオンメニュー開閉ボタン */
const AccordionButton = ({
  isLoggedIn = false,
  point = 0,
  status = {},
  ...props
}) => {
  return (
    <StyledButton type="button" isLoggedIn={isLoggedIn} {...props}>
      {isLoggedIn && status.isPurchased ? (
        <React.Fragment>
          <StyledSpan1>
            <StyledSpan2>{point}</StyledSpan2>
            単位
          </StyledSpan1>
          <StyledImg src={img2} alt="単位ポイント" width="180" height="100" />
        </React.Fragment>
      ) : (
        <img src={img1} alt="単位ポイント" width="180" height="100" />
      )}
    </StyledButton>
  )
}

export default AccordionButton

AccordionButton.propTypes = {
  /** ボタン押下時に発火する関数 */
  onClick: PropTypes.func,
  /** ログイン状態によってボタンのデザイン変更 */
  isLoggedIn: PropTypes.bool,
  /** 所有単位ポイント */
  point: PropTypes.number,
  /** 「プロフィール設定」のボタンの描画に使用
   * @param {boolean} isPurchased - 購入済みか否か
   */
  status: PropTypes.object
}
