import React, { useState, forwardRef, useImperativeHandle } from 'react'
import routes from '../../../../../../../common/routes'
import Link from '../../../../../../../../sketch-platform/ui/routing/Link'
/* components */
import Nav1 from '../../nav/Nav1'
import ConvertSvg from '../../util/ConvertSvg'

/* style */
import {
  StyledDiv1,
  StyledUl,
  StyledLi,
  StyledDiv2,
  StyledButton,
  StyledLink,
  StyledDiv3,
  StyledDiv4,
  StyledLi2,
  StyledLi2Main,
  StyledSpan
} from './style'

/** Logirlプランページ | ヘッダーメニュー */
const Menu = (props, ref) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  /* メニューを開く */
  useImperativeHandle(ref, () => ({
    openMenuHandler() {
      setIsMenuOpen(true)
    }
  }))

  /* メニューを閉じる */
  const closeMenuHandler = () => {
    setIsMenuOpen(false)
  }

  const purchasePath = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 'https://st-www.tvasahi.jp/douga_mv/purchase/logirl/'
    : 'https://wws.tv-asahi.co.jp/douga_mv/purchase/logirl/'

  return (
    <StyledDiv1 aria-hidden={!isMenuOpen}>
      <StyledDiv2>
        <StyledLink route={routes.plan} params={{ slug: 'logirl' }}>
          <img
            src="/images/exdio/renewal/logirl/logo.svg"
            alt="Logirl ロガール"
          />
        </StyledLink>
        <StyledButton type="button" onClick={closeMenuHandler} />
      </StyledDiv2>

      <StyledDiv3>
        <StyledDiv4>
          <Nav1 closeMenu={closeMenuHandler} />
        </StyledDiv4>
        <StyledDiv4>
          <nav>
            <ul>
              <StyledLi2>
                <Link
                  route={routes.logirl_static}
                  params={{ url: 'about' }}
                  className="c-nav1__list__link"
                >
                  <ConvertSvg
                    src="/images/exdio/renewal/logirl/icon/question.svg"
                    alt=""
                  />
                  <StyledSpan>logirl紹介・見放題について</StyledSpan>
                </Link>
              </StyledLi2>
              <StyledLi2Main>
                <Link href={purchasePath} className="c-nav1__list__link">
                  <ConvertSvg
                    src="/images/exdio/renewal/logirl/icon/member.svg"
                    alt=""
                  />
                  <StyledSpan>会員になる</StyledSpan>
                </Link>
              </StyledLi2Main>
            </ul>
          </nav>
        </StyledDiv4>
        <StyledDiv4>
          <StyledUl>
            <StyledLi>
              <Link href="https://twitter.com/LoGiRL2015" target="_blank">
                <ConvertSvg src="/images/exdio/renewal/logirl/icon/X-2.svg" />
              </Link>
            </StyledLi>
            <StyledLi>
              <Link
                href="https://www.instagram.com/tv_asahi_logirl/"
                target="_blank"
              >
                <ConvertSvg src="/images/exdio/renewal/logirl/icon/instagram.svg" />
              </Link>
            </StyledLi>
          </StyledUl>
        </StyledDiv4>
      </StyledDiv3>
    </StyledDiv1>
  )
}

export default forwardRef(Menu)
