import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledList, StyledListItem, StyledButton } from './style'

/** タブリスト (番組概要／コメント切り替え) */
const TabList = ({
  items = [
    {
      id: '',
      name: ''
    }
  ],
  currentId = '',
  onChangeTab = () => {},
  ...props
}) => {
  if (!items.length) return null

  /** 選択中のタブid */
  const [currentTabId, setCurrentTabId] = useState(currentId)

  /**
   * タブ変更
   * @param {string} id タブid
   */
  const onClickHandler = (id) => {
    setCurrentTabId(id)
    if (typeof onChangeTab === 'function') onChangeTab(id)
  }

  return (
    <StyledList {...props}>
      {items.map((item, i) => {
        const { id, name } = item
        return (
          <StyledListItem key={id} isCurrent={id === currentTabId}>
            <StyledButton type="button" onClick={() => onClickHandler(id)}>
              {name}
            </StyledButton>
          </StyledListItem>
        )
      })}
    </StyledList>
  )
}

export default memo(TabList)

TabList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  currentId: PropTypes.string,
  onChangeTab: PropTypes.func
}
