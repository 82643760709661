import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQuery, fixColors } from '../../../../../../style'

const Img = ({
  src = '',
  alt = '',
  showNew = false,
  duration = 0,
  progress = 0,
  ...props
}) => {
  return (
    <StyledDiv {...props}>
      <img src={src} alt={alt} width="218" height="122" loading="lazy" />
      {showNew && <StyledNew>NEW</StyledNew>}
      <StyledDuration>{duration}</StyledDuration>
      {progress > 0 && (
        <StyledSeekBar>
          <StyledProgress style={{ width: `${progress}%` }} />
        </StyledSeekBar>
      )}
    </StyledDiv>
  )
}

export default Img

Img.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  showNew: PropTypes.bool,
  duration: PropTypes.string,
  progress: PropTypes.number
}

const StyledDiv = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(0, 0, 0, 0.1) 50%,
      rgba(0, 0, 0, 0.6) 100%
    );
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: '';
  }

  img {
    width: 100%;
  }
`

const StyledDuration = styled.time`
  display: block;
  position: absolute;
  bottom: 6px;
  right: 6px;
  z-index: 1;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.3;

  ${mediaQuery()} {
    font-size: 0.9rem;
  }
`

const StyledSeekBar = styled.div`
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  background: #e3e3e3;
  z-index: 1;
`

const StyledProgress = styled.div.withConfig({
  shouldForwardProp: (prop) => !['progress'].includes(prop)
})`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  background: ${fixColors.colorSub};
`

const StyledNew = styled.span`
  width: 60px;
  height: 60px;
  background-color: ${fixColors.colorMain};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: -32px;
  left: -34px;
  z-index: 1;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.5;
  transform: rotate(-45deg);
  overflow: hidden;
`
