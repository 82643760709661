import React from 'react'
import $ from 'jquery'
import PropTypes from 'prop-types'
import Accordion from './Accordion/'
import { StyledLi, StyledLink, StyledNav, StyledUl } from './style'

/** ガリベン大学 Nav5 */
const Nav5 = ({
  navItems = [],
  status = {},
  isLoggedIn = false,
  point = 0,
  ...props
}) => {
  /** スムーススクロール */
  const smoothScroll = (event, href) => {
    // ページ内遷移の場合のみ発火
    if (href.charAt(0) === '#') {
      event.preventDefault()
      const speed = 600
      const position = document.getElementById(href.slice(1)).offsetTop
      $('html, body').animate({ scrollTop: position }, speed, 'swing')
    }
  }

  return (
    <StyledNav {...props}>
      <StyledUl>
        <StyledLi>
          <Accordion status={status} isLoggedIn={isLoggedIn} point={point} />
        </StyledLi>
        {navItems.map((item, i) => (
          <StyledLi key={`navItem-${i}`}>
            <StyledLink
              href={item.href}
              // 計測用class名 https://media-plex.backlog.jp/view/DGA2-1752
              className={item.className ? item.className : ''}
              onClick={(event) => smoothScroll(event, item.href)}
            >
              <img src={item.src} alt={item.name} />
            </StyledLink>
          </StyledLi>
        ))}
      </StyledUl>
    </StyledNav>
  )
}

export default Nav5

Nav5.propTypes = {
  /** ナビゲーションの表示要素
   * @param {string} name - 表示するリンク名
   * @param {string} href - 遷移先URL
   * @param {string} src - ボタンの背景画像
   */
  navItems: PropTypes.array,
  /** 「プロフィール設定」のボタンの描画に使用
   * @param {boolean} isPurchseAvailable - 購入可能か否か
   * @param {boolean} isPurchased - 購入済みか否か
   * @param {boolean} isNotPurchased - 購入済みでないか否か
   */
  status: PropTypes.object,
  /** 単位に関する情報の表示に使用 */
  isLoggedIn: PropTypes.bool,
  /** 所有単位ポイント数 */
  point: PropTypes.number,
  /** styleの継承 */
  className: PropTypes.string
}
