import styled, { keyframes } from 'styled-components'
import Heading1 from '../Heading1'
import Button3 from '../common/Button/Button3'
import FilterSort from '../../../../../common/components/FilterSort'
import TagList from '../../ProgramGariben/TagList'
import { lightGreen, pcMinSize } from '../styles'
import { mediaQuery } from '../../../style'

const car = keyframes`
10% {
    bottom: 145px;
}
30% {
    bottom: 165px;
}
50% {
    bottom: 145px;
}
70% {
    bottom: 165px;
}
90% {
    bottom: 145px;
}
100% {
    right: calc(100% + 104px);
}
`

const car_sp = keyframes`
10% {
    bottom: 30px;
}
30% {
    bottom: 50px;
}
50% {
    bottom: 30px;
}
70% {
    bottom: 50px;
}
90% {
    bottom: 30px;
}
100% {
    right: calc(100% + 42px);
}
`

export const StyledHeading1 = styled(Heading1)`
  height: 260px;
  position: relative;
  &.lazyloaded {
    background: url(/images/exdio/renewal/gariben_daigaku/top/sec1_bg2.webp)
      no-repeat bottom center / 1162px auto;

    ${mediaQuery()} {
      background-size: auto 60px;
    }
  }

  ${mediaQuery()} {
    height: 80px;
    background-size: auto 60px;
  }

  img {
    ${mediaQuery()} {
      width: 232px;
    }
  }
`

export const StyledSpan = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  &.lazyloaded::after {
    width: 104px;
    height: 50px;
    background: url(/images/exdio/renewal/gariben_daigaku/top/sec1_bg_img1.webp)
      no-repeat center / 100% auto;
    position: absolute;
    right: -104px;
    bottom: 155px;
    animation: ${car} 18s linear infinite;
    content: '';

    ${mediaQuery()} {
      width: 42px;
      height: 20px;
      right: -42px;
      bottom: 40px;
      animation: ${car_sp} 20s linear infinite;
    }
  }
`

export const StyledDiv1 = styled.div`
  &.lazyloaded {
    background: ${lightGreen}
      url(/images/exdio/renewal/gariben_daigaku/top/sec2_bg.webp) repeat left
      top / 1902px auto;
  }
`

export const StyledDiv2 = styled.div`
  margin: 0 auto;
  padding: 70px 0 30px;
  width: calc(${pcMinSize} + 80px);

  ${mediaQuery()} {
    padding: 45px 7px 15px;
    width: auto;
  }
`

export const StyledDiv3 = styled.div`
  position: relative;

  &::after {
    width: 100%;
    height: 100%;
    background-image: radial-gradient(#b7d148 33%, transparent 38%),
      radial-gradient(#b7d148 33%, transparent 38%);
    background-position: 0 0, 5px 5px;
    background-size: 10px 10px;
    border-radius: 12px;
    position: absolute;
    right: -10px;
    bottom: -10px;
    z-index: 0;
    content: '';

    ${mediaQuery()} {
      content: none;
    }
  }
`

export const StyledButton3 = styled(Button3)`
  margin: 40px auto 0;
  width: 340px;
  font-size: 2rem;
  cursor: pointer;

  ${mediaQuery()} {
    margin-top: 10px;
    width: auto;
    font-size: 1.6rem;
  }
`

export const StyledFilterSort = styled(FilterSort)`
  border-radius: 0 0 12px 12px;
  position: relative;
  z-index: 1;
`

export const StyledDiv4 = styled.div`
  padding: 25px;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
  display: grid;
  gap: 25px;
  position: relative;
  z-index: 1;

  ${mediaQuery()} {
    padding: 15px;
    gap: 15px;
  }
`

export const StyledH3 = styled.h3`
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;

  ${mediaQuery()} {
    font-size: 1.6rem;
  }
`

export const StyledTagList = styled(TagList)`
  display: grid;
  grid-template-columns: repeat(5, auto);

  ${mediaQuery()} {
    grid-template-columns: auto;
  }

  li {
    text-align: center;
  }
`
