import styled from 'styled-components'
import { mediaQuery } from '../../../../style'

export const StyledUl = styled.ul`
  display: flex;
  justify-content: center;
  flex: 1 0;

  ${mediaQuery()} {
    margin: 0 auto;
    width: calc(100% - 40px);
    justify-content: flex-start;
  }
`

export const StyledLi = styled.li``

export const StyledP = styled.p`
  padding: 0 10px;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;

  ${mediaQuery()} {
    justify-content: flex-end;
  }
`
