import React, { memo } from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../utils/exdioWebAppUtils'
import { StyledSpan, StyledImg, StyledLink, StyledP } from './ProgramItem'

/** 新着動画コンポーネント */
const Banner = ({ banner, ...props }, context) => {
  const { href, appHref, target, src, alt, text } = banner
  const isApp = webApp.utils.isApp(context)

  return (
    <StyledLink
      href={isApp ? appHref : href}
      target={isApp ? null : target}
      {...props}
    >
      <StyledSpan>
        <StyledImg src={src} width="200" height="113" alt={alt} />
      </StyledSpan>
      <StyledP>{text}</StyledP>
    </StyledLink>
  )
}

export default memo(Banner)

Banner.propTypes = {
  banner: PropTypes.shape({
    href: PropTypes.string,
    target: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    text: PropTypes.string
  })
}

Banner.defaultProps = {
  banner: PropTypes.shape({
    href: '',
    target: null,
    src: '',
    alt: '',
    text: ''
  })
}

Banner.contextTypes = {
  routeHandler: PropTypes.object
}
