import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import EpisodeListItem from './EpisodeListItem'
import { mediaSp } from './styles'

/** 全英オープン動画リスト */
const EpisodeList = ({ episodes, ...props }) => {
  if (!episodes) return null

  /** フィルター済みのエピソード */
  const [filteredEpisodes, setFilteredEpisodes] = useState(episodes)

  useEffect(() => {
    setFilteredEpisodes(
      episodes.sort((a, b) => {
        if (Number(a.values.avails_EpisodeNumber) > Number(b.values.avails_EpisodeNumber)) return  1
        if (Number(a.values.avails_EpisodeNumber) < Number(b.values.avails_EpisodeNumber)) return  -1
        return 0
      })
    )
  }, [episodes])

  return (
    <StyledUl { ...props }>
      {filteredEpisodes.map(meta => (
          <li key={meta.meta_id}>
            <EpisodeListItem meta={meta} />
          </li>
        )
      )}
    </StyledUl>
  )
}

export default memo(EpisodeList)

EpisodeList.propTypes = {
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    })
  )
}

EpisodeList.defaultProps = {
  episodes: []
}

const StyledUl = styled.ul`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
gap: 20px;

@media ${mediaSp} {
  grid-template-columns: 1fr 1fr;
  gap: 15px 12px;
}
`
