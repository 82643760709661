import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import { mediaQuery } from '../../../../style'

/** Footer｜左側リスト */
const LinkList = ({ linkList = [] }) => {
  return (
    <StyledUl>
      {linkList.map((item) => {
        return (
          <StyledLi key={item.text}>
            <StyledLink
              route={item.route}
              href={item.href}
              target={item.target}
            >
              {item.text}
            </StyledLink>
          </StyledLi>
        )
      })}
    </StyledUl>
  )
}

export default LinkList

LinkList.propTypes = {
  /** styleの継承 */
  className: PropTypes.string,
  /** リンク情報の配列
   * @param {string} route - Linkコンポーネントに渡すroute
   * @param {string} href - Linkコンポーネントに渡すhref
   * @param {string} target - Linkコンポーネントに渡すtarget
   * @param {string} text - リンクのテキスト
   */
  linkList: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.object,
      href: PropTypes.string,
      target: PropTypes.string,
      text: PropTypes.string
    })
  )
}

const StyledUl = styled.ul`
  padding: 15px;
  font-weight: 600;

  ${mediaQuery()} {
    padding: 0;
    width: 100%;
    background: #fff;
  }
`

const StyledLi = styled.li`
  border-bottom: 1px solid #e2e2e2;
  font-size: 1.3rem;

  ${mediaQuery()} {
    font-size: 1.5rem;
  }
`

const StyledLink = styled(Link)`
  padding: 1.57em 0.42em;
  color: #343434;
  display: block;
  line-height: 1.4;

  &:hover {
    opacity: 0.7;
    transition: opacity 0.3s;
  }

  &:visited {
    color: #343434;
  }

  ${mediaQuery()} {
    padding: 1.46em;
  }
`
