import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/** components */
import Tab from '../Tab'

/** style */
import { mediaQuery } from '../../../../../style'

/** タブリスト */
const TabList = ({
  tabsData = [],
  selectedTabId = null,
  setSelectedTabId = () => {},
  setIsModalOpen = () => {},
  ...props
}) => {
  return (
    <StyledWrapper>
      <StyledUl {...props}>
        {tabsData.map((tab) => {
          const { id } = tab
          return (
            <StyledLi key={id}>
              <Tab
                {...tab}
                iconType={id}
                isChecked={id === selectedTabId}
                setIsModalOpen={setIsModalOpen}
                onChangeFunc={() => setSelectedTabId(id)}
              />
            </StyledLi>
          )
        })}
      </StyledUl>
    </StyledWrapper>
  )
}

export default memo(TabList)

TabList.propTypes = {
  /** それぞれのタブの内容 */
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      /** id */
      id: PropTypes.string,
      /** 見出し画像alt */
      title: PropTypes.string
    })
  ),
  /** 選択中のタブID */
  selectedTabId: PropTypes.string,
  /** タブ変更時の関数 */
  setSelectedTabId: PropTypes.func,
  /** モーダル開閉状況変更 */
  setIsModalOpen: PropTypes.func
}

const StyledWrapper = styled.div`
  position: relative;
`

const StyledUl = styled.ul`
  display: flex;
  gap: 40px;

  ${mediaQuery()} {
    display: block;
  }
`

const StyledLi = styled.li`
  position: relative;
  width: calc(100% / 4);

  ${mediaQuery()} {
    width: 100%;
  }
`
