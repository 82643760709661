import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import routes from '../../../../../apps/common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import { mediaQuery } from '../../style'

/** マイページ 契約中リスト画面 */
export default class ViewLicenseContent extends Component {
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    webApp: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = context.models.config.data

    this.state = {
      url: null
    }

    this.onBack = this.onBack.bind(this)
  }

  componentDidMount() {
    this.setIframeUrl()

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context)

    // GTMの更新
    const title = this.context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    this.context.webApp.utils.updateDataLayer(gtmTags)

    // 子フレームから呼ばれる用のメソッドをセット
    window.onBack = this.onBack

    // リスナーを追加する
    window.addEventListener('message', this.onBack)
  }

  componentWillUnmount() {
    // リスナーを削除する
    window.removeEventListener('message', this.onBack)
  }

  onBack(e) {
    if (e.data.action === 'onBack') {
      // アカウントページへ戻したいので直指定
      const route = webApp.utils.isApp(this.context)
        ? routes.app_mypage_account_info
        : routes.mypage_account_info
      const { history } = this.context
      history.replace(route)
      window.history.pushState(null, null, route.makePath())
    }
  }

  setIframeUrl() {
    const cont_url = encodeURIComponent(
      webApp.utils.rootUrl() +
        (webApp.utils.isApp(this.context)
          ? routes.app_mypage_cancel_license.makePath({})
          : routes.mypage_cancel_license.makePath({}))
    )
    const path = this.config.static_links.view_license.replace(
      ':host',
      webApp.utils.infraHost(this.context)
    )
    const url = `${path}?CONT_URL=${cont_url}`

    this.setState({ url })
  }

  render() {
    const { url } = this.state
    return (
      <React.Fragment>
        <StyledDiv className="ex-base">{url && <iframe src={url} />}</StyledDiv>
      </React.Fragment>
    )
  }
}

const StyledDiv = styled.div`
  iframe {
    background: #f3f4f5;
    height: 1360px;

    ${mediaQuery()} {
      height: 1695px;
    }
  }
`
