import _ from 'lodash'
import Cookie from 'js-cookie'
import routes from '../../../routes'
import {
  GENRE_SEARCH_TYPE,
  SEARCH_TYPE,
  SORT_TYPE,
  META_SCHEMA_ID,
  PALETTE_SCHEMA_ID
} from '../../../../../constants/app'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'

/** ジャンル属性情報取得 */
export const getAttribute = (model, palette) => {
  const genreId =
    _.get(palette, ['values', 'genre']) ||
    _.get(palette, ['values', 'category2'])
  if (!genreId) return

  const path = ['attribute', genreId]
  return model
    .fetch([path])
    .then((result) => _.get(result, ['json', ...path], {}))
}

/** メタ情報取得 */
export const getMetasByGenre = (model, config, palette) => {
  const genreId = _.get(palette, ['values', 'genre'])
  const searchType = _.get(palette, ['values', 'search_type'])

  // 注)オートで検索させるパレットを使用したときはライブは入れないようにしてます。
  const wSearchType =
    GENRE_SEARCH_TYPE.FREE.value === searchType
      ? SEARCH_TYPE.FREE_WO_LIVE
      : SEARCH_TYPE.ALL_WO_LIVE
  const range = {
    from: 0,
    to: config.extras.genre_list_limit_in_palette - 1
  }
  const basePath = [
    'metaByGenreSearch',
    genreId,
    wSearchType.value,
    SORT_TYPE.NEWER.value
  ]
  const path = basePath.concat([range])
  return model.fetch([path]).then((result) => {
    const searchResult = _.get(result, ['json', ...basePath], {})
    delete searchResult.$__path
    return Object.values(searchResult)
  })
}

/** 価格情報取得 */
export const getHowToPlays = (model, dsearchMetas, howToPlays) => {
  if (!dsearchMetas || !dsearchMetas.length) return Promise.resolve()

  // propsのhowToPlayにないものがあれば別途取得
  const metaIds = dsearchMetas
    .filter(
      (meta) =>
        meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
    )
    .filter((meta) => !howToPlays[meta.meta_id])
    .map((e) => e.meta_id)
  const path = [['meta', 'howToPlay', false, metaIds]]
  return model.fetch(path).then((result) => {
    const howToPlaysOpt = _.get(
      result,
      ['json', 'meta', 'howToPlay', false],
      {}
    )
    return Object.assign(howToPlays, howToPlaysOpt)
  })
}

/** もっと見るリンク先設定 */
export const setMoreLink = (palette, genre) => {
  if (palette.schema_id === PALETTE_SCHEMA_ID.INDIVIDUAL_RECOMMEND) {
    // あなたへのオススメ
    return {
      route: routes.recommendYou,
      params: { paletteId: palette.palette_id }
    }
  }

  if (genre) {
    let idOrGenreSearchType = null
    if (palette.schema_id === PALETTE_SCHEMA_ID.GENRE_RECOMMEND) {
      // /genre/[ジャンルディレクトリ名]/recommend
      idOrGenreSearchType = 'recommend'
    } else if (palette.schema_id === PALETTE_SCHEMA_ID.GENRE_DSEARCH) {
      // /genre/[ジャンルディレクトリ名]/newarrival
      // /genre/[ジャンルディレクトリ名]/free
      idOrGenreSearchType = _.get(palette, ['values', 'search_type'])
    } else if (palette.schema_id === PALETTE_SCHEMA_ID.GENRE_FIXED) {
      // /genre/[ジャンルディレクトリ名]/{パレットID}
      idOrGenreSearchType = palette.palette_id
    }
    if (!idOrGenreSearchType) return
    if (genre.slug) {
      return {
        route: routes.genreSearch,
        params: { genreKey: genre.slug, idOrGenreSearchType }
      }
    }
  }

  if (palette.schema_id === PALETTE_SCHEMA_ID.RECOMMEND) {
    // テレ朝動画のオススメ
    return { route: routes.recommend }
  }

  if (palette.schema_id === PALETTE_SCHEMA_ID.CATCHUP) {
    // 見逃し無料
    return { route: routes.catchup }
  }
}

/** レコメンド処理 */
export const getRecommend = async (context, palette, cb = () => {}) => {
  const model = context.falcorModel.batch(100)
  const config = context.models.config.data
  if (!palette) return
  const isIndividualRecommend =
    palette.schema_id === PALETTE_SCHEMA_ID.INDIVIDUAL_RECOMMEND
  if (!isIndividualRecommend) return
  const { api_type, tags1, category2 } = _.get(palette, ['values'], {})

  let refId = ''
  if (tags1) {
    // tags1はコースID
    const path = ['course', tags1]
    refId = await model.fetch([path]).then((result) => {
      const course = _.get(result, ['json', ...path], {})
      return course.ref_id
    })
  }

  let genreName = ''
  if (category2) {
    // category2はジャンル名
    const path = ['attribute', category2]
    genreName = await model.fetch([path]).then((result) => {
      const genre = _.get(result, ['json', ...path], {})
      return genre.name
    })
  }

  let getRecommendExec = null
  switch (api_type) {
    case 'one_to_one':
      getRecommendExec = oneToOne
      break
    case 'recommend':
      getRecommendExec = recommend
      break
    case 'longtail':
      getRecommendExec = longtail
      break
    default:
      return
  }
  const recommendItems = await getRecommendExec(
    model,
    palette,
    genreName,
    refId
  )
  await recommendActionLog(model, config, recommendItems, palette)
    .then(() => typeof cb === 'function' && cb())
    .catch((e) => webApp.utils.handleFalcorError(e, context))
  return recommendItems
}

/** One to Oneレコメンド */
const oneToOne = (model, palette, genreName, courseRefId) => {
  const itemIdsCsv = _.get(palette, ['values', 'item_ids'], [])
    .slice(0, 10)
    .join(',')
  const userId = Cookie.get('luid') || Cookie.get('_ga') || ''
  // onetooneはuser_id必須なのでluid取れなければ非表示
  if (!userId) {
    webApp.utils.debug(
      `[Palette] skip call one_to_one API because luid and _ga are blank. id: ${
        (palette || {}).palette_id
      }`
    )
    return Promise.resolve()
  }
  const spotName = _.get(palette, ['values', 'spot_name']) || ''
  let type = _.get(palette, ['values', 'category1'], SEARCH_TYPE.ALL.value)
  if (type === SEARCH_TYPE.FREE.value && webApp.utils.isLoggedIn(context)) {
    type = SEARCH_TYPE.AUTH_FREE.value
  }
  const metaSchemaId = _.get(palette, ['values', 'category3']) || ''
  const responseNumber = _.get(palette, ['values', 'display_count']) || 8
  const path = [
    'oneToOne',
    itemIdsCsv,
    userId,
    spotName,
    type,
    genreName,
    metaSchemaId,
    courseRefId,
    responseNumber
  ]
  return model
    .fetch([path])
    .then((result) => _.get(result, ['json', ...path], []))
}

/** レコメンド */
const recommend = (model, palette, genreName, courseRefId) => {
  const itemIdsCsv =
    _.get(palette, ['values', 'item_ids']) || [].slice(0, 10).join(',')
  if (!itemIdsCsv) return Promise.resolve()
  const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
  const spotName = _.get(palette, ['values', 'spot_name'], '')
  let type = _.get(palette, ['values', 'category1']) || SEARCH_TYPE.ALL.value
  if (type === SEARCH_TYPE.FREE.value && webApp.utils.isLoggedIn(context)) {
    type = SEARCH_TYPE.AUTH_FREE.value
  }
  const metaSchemaId = _.get(palette, ['values', 'category3']) || ''
  const responseNumber = _.get(palette, ['values', 'display_count']) || 8
  const path = [
    'recommend',
    itemIdsCsv,
    sessionId,
    spotName,
    type,
    genreName,
    metaSchemaId,
    courseRefId,
    responseNumber
  ]
  return model
    .fetch([path])
    .then((result) => _.get(result, ['json', ...path], []))
}

/** ロングテール */
const longtail = (model, palette, genreName, courseRefId) => {
  const itemIdsCsv =
    _.get(palette, ['values', 'item_ids']) || [].slice(0, 10).join(',')
  if (!itemIdsCsv) return Promise.resolve()
  const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
  const spotName = _.get(palette, ['values', 'spot_name']) || ''
  let type = _.get(palette, ['values', 'category1'], SEARCH_TYPE.ALL.value)
  if (type === SEARCH_TYPE.FREE.value && webApp.utils.isLoggedIn(context)) {
    type = SEARCH_TYPE.AUTH_FREE.value
  }
  const metaSchemaId = _.get(palette, ['values', 'category3']) || ''
  const responseNumber = _.get(palette, ['values', 'display_count']) || 8
  const path = [
    'recommendLongtail',
    itemIdsCsv,
    sessionId,
    spotName,
    type,
    genreName,
    metaSchemaId,
    courseRefId,
    responseNumber
  ]
  return model
    .fetch([path])
    .then((result) => _.get(result, ['json', ...path], []))
}

/** レコメンド(ログ蓄積) */
const recommendActionLog = (model, config, recommendItems, palette) => {
  if (!recommendItems || !recommendItems.length) return Promise.resolve()

  const itemIds = recommendItems.map((r) => r.meta_id).join(',')
  const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
  // sessionId必須なのでluid取れなければ処理なし
  if (!sessionId) {
    webApp.utils.debug(
      `[Pallete] skip call action_log/kpi/display API because luid and _ga are blank. id: ${
        (palette || {}).palette_id
      }`
    )
    return Promise.resolve()
  }
  const typeName = config.recommend.type_name.view
  const spotName = _.get(palette, ['values', 'spot_name']) || ''
  const path = [
    'action_log',
    'kpi',
    'display',
    itemIds,
    sessionId,
    typeName,
    spotName
  ]
  return model.fetch([path])
}
