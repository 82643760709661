import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import PackList from '../details/PackList'
import {
  FAVORITE_TYPE,
  EPISODE_DISPLAY_MODE
} from '../../../../../constants/app'
import EpisodeList from '../details/EpisodeList'
import SwitchableListGrid from '../../../../common/components/renewal/SwitchableListGrid'
import SideTab from '../details/SideTab'
import Caption from '../details/Caption'
import ProgramTopLink from '../details/ProgramTopLink'
import GoBack from '../../../../common/components/appli/renewal/GoBack'
import SideRecommend from '../details/SideRecommend'
import HtmlSnippet from '../../HtmlSnippet'
import AddButtonBlock from '../details/AddButtonBlock'
import HeaderNewsComponent from '../HeaderNewsComponent'
import Footer from '../../../../common/components/appli/StaticFooter'

/** 番組ページ デフォルトテンプレート */
export default class ProgramAppDefault extends Component {
  static propTypes = {
    /** 動画のシリーズID */
    seriesId: PropTypes.string,
    /** 動画のシーズンID */
    seasonId: PropTypes.string,
    /** 動画のメタ情報 */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    /** 関連動画情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** パック販売情報 */
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        original_price: PropTypes.number,
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    ),
    /** episodesのhowToPlay情報 */
    howToPlays: PropTypes.object,
    loaded: PropTypes.bool
  }

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    episodes: [],
    products: [],
    howToPlays: {},
    loaded: false
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.state = {
      pagerOptions: {
        // ページャーの設定
        episodesPerPages: 12,
        range: 2
      }
    }
  }

  componentDidMount() {}

  /* 番組別お知らせの表示 */
  renderNotice() {
    const { meta } = this.props
    const notice = {
      text: _.get(meta, ['values', 'notice']),
      hyperlink: _.get(meta, ['values', 'notice_hyperlink']),
      start_at: _.get(meta, ['values', 'notice_publish_start_at']),
      end_at: _.get(meta, ['values', 'notice_publish_end_at'])
    }
    const now = Date.now()

    if (
      !notice.text ||
      now < Date.parse(notice.start_at) ||
      Date.parse(notice.end_at) < now
    ) {
      return null
    }

    const html = (
      <dl className="c-program_notice">
        <dt className="c-program_notice__term">お知らせ</dt>
        <dd className="c-program_notice__desc">{notice.text}</dd>
      </dl>
    )

    return notice.hyperlink ? (
      <a href={notice.hyperlink} className="c-program_notice__wrapper">
        {html}
      </a>
    ) : (
      <div className="c-program_notice__wrapper">{html}</div>
    )
  }

  render() {
    const {
      seriesId,
      seasonId,
      meta,
      otherSeasons,
      episodes,
      products,
      displayModes,
      howToPlays,
      loaded
    } = this.props
    const { pagerOptions } = this.state

    const disp_order = _.get(meta, ['values', 'disp_order'])

    const latestEpisode = episodes[0]
    let isFree = false
    if (latestEpisode) {
      const latestEpisodeDisplayMode = displayModes[latestEpisode.meta_id]
      switch (latestEpisodeDisplayMode) {
        case EPISODE_DISPLAY_MODE.FREE:
        case EPISODE_DISPLAY_MODE.TVOD_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_FREE:
          isFree = true
          break
        case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.UNKNOWN:
        default:
          isFree = false
          break
      }
    }

    // ドラえもん番組ページは単話リストの表示数を60に変更
    const envKey = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 'stg'
      : 'prd'
    const isDoraemon =
      (envKey === 'stg' && seasonId === '11003') ||
      (envKey === 'prd' && seasonId === '13509')
    isDoraemon ? (pagerOptions.episodesPerPages = 60) : ''

    const thumbnailUrl =
      webApp.utils.customSizeImageUrl(meta.thumbnail_url, 'large') ||
      this.config.default_thumbnail
    const bannerId1 = _.get(meta, ['values', 'banner_1'])
    // const bannerId2 = _.get(meta, ['values', 'banner_2']);

    const [metaName, subTitle] = webApp.utils.titles(meta)
    const officialUrl =
      meta.schema_id === 6 ? meta.values.evis_OfficialSiteURL : null

    return (
      <div className="common-wrapper">
        <GoBack />
        <HeaderNewsComponent />

        <div className="c-headMeta">
          <div className="c-headMeta-metaBox">
            <div className="c-headMeta-metaBox-art">
              <div className="c-headMeta-metaBox-art-inBox">
                <div className="c-headMeta-metaBox-art-inBox-artwork">
                  <img
                    src={thumbnailUrl}
                    className="c-headMeta-metaBox-art-inBox-artwork-img"
                  />
                </div>
              </div>
            </div>

            {this.renderNotice()}

            <div className="c-headMeta-metaBox-info">
              <h2 className="c-headMeta-metaBox-info-title">{metaName}</h2>

              <Caption
                caption={meta.values && meta.values.evis_SeasonLongSynopsis}
                actors={meta.values && meta.values.evis_SeasonActors}
                directors={meta.values && meta.values.evis_SeasonDirectors}
                producers={meta.values && meta.values.evis_SeasonProducers}
                writers={meta.values && meta.values.evis_SeasonWriters}
                className="c-headMeta-metaBox-info-caption"
              />

              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.META}
                favoriteId={meta.meta_id}
                title={metaName}
                showAddMyList={false}
                officialUrl={officialUrl}
              />
            </div>
          </div>
        </div>

        <div className="c-listMeta">
          <div className="c-listMeta-inBox">
            <div
              className={`c-listMeta-inBox-main ${
                isDoraemon ? 'has_underPager' : ''
              }`}
            >
              <PackList products={products} />
              {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
              {/* <EpisodeList episodes={episodes} onlySubTitle showOnlyFreeCoin /> */}
              <SwitchableListGrid
                key="episode"
                episodes={episodes}
                howToPlays={howToPlays}
                placeholder="検索"
                showNew
                showChecked
                onlySubTitle
                listType="default"
                disp_order={disp_order}
                pagerOptions={pagerOptions}
                showPagerUnder={isDoraemon ? true : false}
                loaded={loaded}
              />
            </div>
            <div className="c-listMeta-inBox-sub">
              {/* {bannerId2 && <HtmlSnippet snippetId={bannerId2} />} */}
              <SideTab otherSeasons={otherSeasons} />
              {/* <ProgramTopLink seriesId={seriesId} seasonId={seasonId} isFree={isFree} /> */}
              {/* <HtmlSnippet snippetId={this.config.extras.common_banner_snippet_key} /> */}
              <SideRecommend
                typeName={this.config.recommend.type_name.view}
                spotName={this.config.recommend.spot_name.program}
              />
            </div>
          </div>
        </div>
        <Footer className="mp-mt0" />
      </div>
    )
  }
}
