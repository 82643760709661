import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import HamburgerButton from './HamburgerButton/'
import List1 from './List1/'
import { StyledDiv1, StyledNav, StyledList2, StyledList3 } from './style'

/** ガリベン大学 Nav3 */
const Nav3 = ({
  navItems = [],
  anchorList = [],
  status = {
    isPurchased: false,
    isNotPurchased: false
  }
}) => {
  const [isSp, setIsSp] = useState(
    window.matchMedia('(max-width: 1024px)').matches
  )
  const [isOpen, setIsOpen] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    window.addEventListener('resize', initIsSp)

    return () => {
      window.removeEventListener('resize', initIsSp)
    }
  }, [])

  /* SP判別 初期化 */
  const initIsSp = () => {
    const _isSp = window.matchMedia('(max-width: 1024px)').matches
    if (isSp !== _isSp) {
      setIsSp(_isSp)
    }
  }

  /** メニュー開くとき背景固定 */
  const menuOpen = (scrollTop) => {
    document.body.style.width = '100%'
    document.body.style.position = 'fixed'
    document.body.style.top = -scrollTop + 'px'
  }

  /** メニュー閉じるとき背景固定解除 */
  const menuClose = () => {
    document.body.removeAttribute('style')
    scrollTo(0, scrollTop)
  }

  /* クリックハンドラ */
  const onClickHandler = () => {
    if (!isSp) return
    setIsOpen(!isOpen)

    if (!isOpen) {
      setScrollTop(window.pageYOffset)
      menuOpen(window.pageYOffset)
    } else {
      menuClose()
    }
  }

  /** スムーススクロール */
  const smoothScroll = (event, href) => {
    setIsOpen(false)
    menuClose()
    // ページ内遷移の場合のみ発火
    if (href.charAt(0) === '#') {
      event.preventDefault()
      const speed = 600
      const position = document.getElementById(href.slice(1)).offsetTop
      $('html, body').animate({ scrollTop: position }, speed, 'swing')
    }
  }

  const list1Item = [
    {
      name: '単位について',
      href: 'https://www.tv-asahi.co.jp/douga_mv/gariben_daigaku/credit/'
    },
    ...anchorList
  ]

  return (
    <StyledNav>
      <HamburgerButton onClick={onClickHandler} isOpen={isOpen} />
      <StyledDiv1 isOpen={isOpen}>
        {list1Item.length && (
          <List1 linkItems={list1Item} onClick={smoothScroll} />
        )}
        {navItems.length && (
          <StyledList2 linkItems={navItems} onClick={smoothScroll} />
        )}
        <StyledList3 isPurchased={status.isPurchased} />
      </StyledDiv1>
    </StyledNav>
  )
}

export default Nav3

Nav3.propTypes = {
  /** ナビゲーションリンクの表示要素
   * @param {string} href - リンク先URL
   * @param {string} src - アイコン画像のパス
   * @param {string} width - アイコン画像の横幅
   * @param {string} height - アイコン画像の高さ
   * @param {string} alt - アイコン画像のalt
   * @param {string} html - 表示するリンク
   * @param {string} target - リンクのtarget要素
   */
  navItems: PropTypes.array,
  /** ナビゲーションリンクの表示要素
   * @param {string} name - 表示するリンク名
   * @param {string} href - 遷移先URL
   * @param {string} src - ボタンの背景画像
   */
  anchorList: PropTypes.array,
  /** 「生徒になる」「ポイント確認」のボタンの描画に使用
   * @param {boolean} isPurchseAvailable - 購入可能か否か
   * @param {boolean} isPurchased - 購入済みか否か
   * @param {boolean} isNotPurchased - 購入済みでないか否か
   */
  status: PropTypes.object
}
