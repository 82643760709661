import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import window from 'global'
import Cookie from 'js-cookie'
import moment from 'moment'
import {
  META_SCHEMA_ID,
  PRODUCT_SCHEMA,
  PUBLISH_STATUS
} from '../../../../constants/app'
import webApp from '../../utils/exdioWebAppUtils'
import namespace from '../../../../common/namespace'
import NotFound from '../../../generic/components/errors/NotFound'
import routes from '../../../common/routes'
import Footer from '../../../common/components/Footer'
import ProgramContent from './ProgramContent'
import { EPISODE_DISPLAY_MODE } from '../../../../constants/app'
import logirlId from './plan_logirl/data/program_list.json'
import browserInfo from '../../../../sketch-platform/utils/browserInfo'

const { sprintf } = require('sprintf-js')

/** 単話ページ */
export default class EpisodeContent extends Component {
  static propTypes = {
    seriesId: PropTypes.string,
    seasonId: PropTypes.string,
    episodeId: PropTypes.string
  }

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    episodeId: ''
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  static getPath(_models, _options, props) {
    return ['metas', props.episodeId]
  }

  static getPrefetchPaths(models, options, props) {
    return [EpisodeContent.getPath(models, options, props)]
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const config = models.config.data
    const { routeHandler } = options
    const meta = _.get(prefetchResult, [
      'json',
      ...EpisodeContent.getPath(models, options, props)
    ])
    if (!meta) return {}

    const title = webApp.utils.makeTitleTagString(meta)
    let titlePrior
    if (title) {
      const template = routes.episode.match(routeHandler.url)
        ? config.title_template
        : config.title_template_catchup
      titlePrior = sprintf(template, title)
    }
    const description = meta.values
      ? meta.values.evis_EpisodeLongSynopsis
      : null
    const thumbnail = meta.thumbnail_url

    return { title, titlePrior, thumbnail, description }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    const path = EpisodeContent.getPath({}, {}, props)
    const meta = this.model.getSync(path) || null

    this.state = {
      meta,
      programMeta: {},
      otherSeasons: null,
      howToPlay: {},
      product: {},
      productRight: {},
      episodes: [],

      // 通常単話
      products: [],
      howToPlays: {},
      // 月額見放題
      course: {},
      rightMetas: [],

      showPlayer: false,
      status: {
        displayMode: null,
        isFree: null,
        isNotFree: null,
        isPurchased: null,
        isNotPurchased: null,
        isInCourse: null,
        isNotInCourse: null,
        isGeoDeliverable: null,
        isDeviceNotAvailable: null,
        isPurchseAvailable: null,
        isPossible: null,
        isBelonging: null
      },
      // シーズン
      season: null,
      loaded: false
    }

    this.sendPlayLog = this.sendPlayLog.bind(this)
  }

  async componentDidMount() {
    this._isMounted = true
    const { seriesId, seasonId, episodeId } = this.props

    const envKey = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 'stg'
      : 'prd'
    const str_series_season = `${seriesId}-${seasonId}`
    const isLogirl = logirlId[envKey].data.includes(str_series_season) // logirl判定

    // latest指定がある場合のリダイレクトはフロントサーバー側(Node.js - Express)で行うのでこの時点で/latestにきたらNotFound
    if (episodeId === 'latest') {
      this.setState({ meta: {} })
      return
    }

    let func = () => {
      return this.getMeta()
        .then(() => this.updateMetaTags())
        .then(() => this.getHowToPlay())
        .then(() => this.getOtherSeason())
        .then(() => {
          this.setDisplayMode()

          const { meta, status } = this.state
          const templateId = _.get(meta, ['values', 'ex_templateId'], 0)
          const shinchanSeriesId = ['development', 'staging'].includes(
            process.env.NODE_ENV
          )
            ? ['10965', '12859']
            : ['11721']
          const isShinchan =
            shinchanSeriesId.indexOf(seriesId) > -1 && templateId == 200

          if (isShinchan) {
            // 固定viewport設定
            const device = browserInfo(navigator.userAgent, (data) => data)
            if (device.isSilk) {
              this.setPcWidthViewport()
            }
          }

          if (shinchanSeriesId.indexOf(seriesId) > -1) {
            this.addUserGramTagShinchan()
          }

          // 通常単話の場合の取得処理
          if (status.isNotInCourse) {
            return this.getIsPossible()
              .then(() => this.getSameSeasonEpisodes())
              .then(() => this.getPacks())
          }
          // 月額見放題に含まれる単話の場合の取得処理
          if (status.isInCourse) {
            return this.getBelonging()
              .then(() => {
                const { isPurchased, isNotPurchased } = this.state.status
                if (isPurchased) {
                  // 月額見放題加入済みの場合は期限取得
                  return this.getPurchased()
                } else if (isNotPurchased) {
                  // 月額見放題未加入の場合は単話購入確認
                  return this.getIsPossible()
                }
                return Promise.resolve()
              })
              .then(() => {
                if (!(isShinchan || isLogirl)) this.getSameSeasonEpisodes()
              })
              .then(() => {
                if (!(isShinchan || isLogirl)) this.getPacks()
              })
              .then(() => {
                if (!(isShinchan || isLogirl)) this.getRightMetas()
              })
            //.then(() => this.getCourseEpisodes());
          }
          return Promise.resolve()
        })
        .catch((e) => webApp.utils.handleFalcorError(e, this.context))
    }

    if (this._isMounted) {
      const deviceInfo = this.context.models.browserInfo.data
      if (deviceInfo.isIE) {
        // IEだとfinallyがエラーになる
        await func()
        this.setState({ loaded: true })
      } else {
        func().finally(() => {
          this.setState({ loaded: true })
        })
      }
    }

    this.getProgramMeta().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    this.getSeason().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    // レコメンド
    this.recommendActionLog().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
  }

  componentWillUnmount() {
    this._isMounted = false
    const { seriesId } = this.props
    const shinchanSeriesId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? '10965'
      : '11721'
    const shinchanUsergramIds = [
      'usergram-member-shinchan-ep',
      'usergram-common-shinchan-ep'
    ]
    if (seriesId === shinchanSeriesId) {
      webApp.utils.removeUserGram(shinchanUsergramIds)
    }
    webApp.utils.remove_vr_sync()
    this.removePcWidthViewport()
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags() {
    const { routeHandler } = this.context
    const { meta } = this.state
    if (!meta || !Object.keys(meta).length) return

    const isFree = routes.catchupEpisode.match(routeHandler.url)

    // タイトルタグの更新
    let title = webApp.utils.makeTitleTagString(meta)
    if (title) {
      const template = isFree
        ? this.config.title_template_catchup
        : this.config.title_template
      title = sprintf(template, title)
    }
    webApp.utils.updateTitle(title)

    let description = meta.values ? meta.values.evis_EpisodeLongSynopsis : null
    if (!description) {
      description = isFree
        ? this.config.description_catchup
        : this.config.description
    }
    const thumbnail = meta.thumbnail_url
    const defaultThumbnail = isFree
      ? this.config.og_image_catchup
      : this.config.og_image

    // メタタグの更新
    const { copyright } = this.config
    const keywords = isFree
      ? this.config.keywords_catchup
      : this.config.keywords
    const rootUrl = `${window.location.protocol}//${window.location.host}`
    const ogImage =
      thumbnail || sprintf('%s/images/exdio/%s', rootUrl, defaultThumbnail)
    const url = window.location.href
    const regularUrl = url.replace(/\?.*$/, '')
    let [episode, program] = title === undefined ? ['', ''] : title.split(' | ')
    program = program ? program.trim() : ''
    const episodeId = meta.meta_id
    const programId = `${meta.values.parents_series.avails_SeriesAltID}_${meta.values.parents_season.avails_SeasonAltID}`
    const now = new Date().getTime()
    const deliveryPattern = meta.values.delivery_pattern
    const tsukasaId =
      meta.values.parents_season.avails_SeasonTitleInternalAlias || ''
    const displayMode = this.state.status.displayMode
    const evisTitle = meta.values.evis_FrontDisplayTitle
    const seriesTitle =
      meta.values.avails_SeriesTitleDisplayUnlimited === null
        ? ''
        : meta.values.avails_SeriesTitleDisplayUnlimited
    const seasonTitle =
      meta.values.avails_SeasonTitleDisplayUnlimited === null
        ? ''
        : meta.values.avails_SeasonTitleDisplayUnlimited
    const availsTitle = seriesTitle + seasonTitle
    const programName = evisTitle === null ? availsTitle : evisTitle

    switch (displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        var gtmPrice = 'free'
        break
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.UNKNOWN:
        var gtmPrice = 'charge'
        break
      default:
        var gtmPrice = 'free'
        break
    }

    let gtmPurchased = true
    if (gtmPrice === 'charge' && !this.state.status.isPurchased) {
      gtmPurchased = false
    }

    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'startTime', value: now },
      { key: 'program_id', value: programId },
      { key: 'program', value: programName },
      { key: 'episode_id', value: episodeId },
      { key: 'episode', value: episode },
      { key: 'delivery_pattern', value: deliveryPattern },
      { key: 'tsukasa_id', value: tsukasaId },
      { key: 'price', value: gtmPrice },
      { key: 'purchased', value: gtmPurchased }
    ]

    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: ogImage },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'mixi:image', content: ogImage },
        { property: 'og:image', content: ogImage },
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: regularUrl }]
    }

    webApp.utils.updateMeta(metaTags)
    webApp.utils.updateDataLayer(gtmTags)
    webApp.utils.updateCookieSync(window.navigator.userAgent)

    if (meta.meta_schema_id === META_SCHEMA_ID.EPISODE) {
      webApp.utils.load_vr_sync()
    }
  }

  /** クレヨンしんちゃん単話ページ usergramタグ追加*/
  addUserGramTagShinchan() {
    const { meta } = this.state
    const season_name = meta.values.evis_FrontDisplayTitle

    let browseUserGram = ''
    browseUserGram +=
      '<script type="text/javascript" id="usergram-member-shinchan-ep">'
    browseUserGram += 'window.ugattr = window.ugattr || {};'
    browseUserGram += `ugattr[\'prop07\'] = \'${season_name}\';`
    browseUserGram += '</script>'
    browseUserGram +=
      '<script type="text/javascript" id="usergram-common-shinchan-ep">'
    browseUserGram +=
      '(function(){var a=window,b=document,c=a.usergram=a.usergram||[],d,e;'
    browseUserGram +=
      "c.l||(c.s=(new Date()).getTime(),c.l=!0,d=b.getElementsByTagName('script')[0],"
    browseUserGram +=
      "e=b.createElement('script'),e.type='text/javascript',e.async=true,"
    browseUserGram +=
      "e.src='//code.usergram.info/js/usergram.js',d.parentNode.insertBefore(e,d))})();"
    browseUserGram +=
      'window.usergram=window.usergram||[],window.ugattr=window.ugattr||{};'
    browseUserGram +=
      "usergram.push(['send','Ug37cn-1','cv','shin_epi_browser',ugattr]);"
    browseUserGram += '</script>'

    webApp.utils.appendUserGram(browseUserGram)
  }

  /** メタ情報取得 */
  getMeta() {
    const { episodeId, seriesId, seasonId } = this.props
    if (!episodeId || isNaN(episodeId) || !seriesId || !seasonId) {
      if (this._isMounted) {
        this.setState({ meta: {} })
      }
      return Promise.resolve()
    }

    const path = EpisodeContent.getPath({}, {}, this.props)
    return this.model.fetch([path]).then((result) => {
      const meta = _.get(result, ['json', ...path]) || {}
      const parentSeriesId = _.get(meta, ['values', 'parents_series', 'id'])
      const parentSeasonId = _.get(meta, ['values', 'parents_season', 'id'])
      const isValidParentsId =
        seriesId === String(parentSeriesId) &&
        seasonId === String(parentSeasonId)
      const delivery_device_rule = _.get(meta, ['delivery_device_rule'])
      let isDeviceNotAvailable = false
      if (
        delivery_device_rule &&
        !delivery_device_rule.includes(
          this.props.model.models.browserInfo.data.deviceCode
        )
      ) {
        isDeviceNotAvailable = true
      }

      if (this._isMounted) {
        this.setState({
          meta: isValidParentsId ? meta : {},
          status: {
            ...this.state.status,
            isFree:
              META_SCHEMA_ID.EPISODE === meta.meta_schema_id ||
              META_SCHEMA_ID.LIVE === meta.meta_schema_id,
            isNotFree:
              META_SCHEMA_ID.EPISODE_NOT_FREE === meta.meta_schema_id ||
              META_SCHEMA_ID.LIVE_NOT_FREE === meta.meta_schema_id,
            isGeoDeliverable: _.get(meta, ['delivery_geo', 'deliverable']),
            isDeviceNotAvailable
          }
        })
      }
    })
  }

  getProgramMeta() {
    const { seriesId, seasonId } = this.props
    if (!seriesId || !seasonId) {
      if (this._isMounted) {
        this.setState({ programMeta: {} })
      }
      return Promise.resolve()
    }
    const path = ProgramContent.getPath({}, {}, this.props)
    return this.model.fetch([path]).then((result) => {
      const meta = _.get(result, ['json', ...path]) || {} //シーズンのメタ情報
      const parentSeriesId = _.get(meta, ['values', 'parents_series', 'id'])
      const isValidSeriesId = seriesId === String(parentSeriesId)
      if (this._isMounted) {
        this.setState({ programMeta: isValidSeriesId ? meta : {} })
      }
    })
  }

  // 他のシーズンの取得
  async getOtherSeason() {
    const { status, course } = this.state

    let w_otherSeasons
    let w_otherSeasonsLive
    if (status.isInCourse) {
      w_otherSeasons = _.get(course, ['values', 'other_series']) || []
      w_otherSeasonsLive = _.get(course, ['values', 'other_series_live']) || []
    } else {
      const { seasonId } = this.props
      const seasonMeta = await this.model
        .fetch([['metas', seasonId]])
        .then((result) => {
          const seasonMetaResult =
            _.get(result, ['json', 'metas', seasonId]) || {}
          delete seasonMetaResult.$__path
          return seasonMetaResult
        })
      w_otherSeasons = _.get(seasonMeta, ['values', 'other_series']) || []
      w_otherSeasonsLive =
        _.get(seasonMeta, ['values', 'other_series_live']) || []
    }
    w_otherSeasons = w_otherSeasons.concat(w_otherSeasonsLive)
    w_otherSeasons = w_otherSeasons.filter((v) => v)
    if (!w_otherSeasons || !w_otherSeasons.length) return Promise.resolve()

    const path = ['metas', w_otherSeasons]
    return this.model.fetch([path]).then((result) => {
      const hash_otherSeasons = _.get(result, ['json', 'metas']) || {}
      delete hash_otherSeasons.$__path
      if (hash_otherSeasons) {
        this.setState({ otherSeasons: Object.values(hash_otherSeasons) })
      }
    })
  }

  /** 権限情報(権利、商品、コース)取得 */
  getHowToPlay() {
    const { meta } = this.state
    if (!meta || !Object.keys(meta).length) return Promise.resolve()

    const metaIds = [meta.meta_id]

    const path = [['meta', 'howToPlay', false, metaIds]]
    return this.model.fetch(path).then((result) => {
      const howToPlays = _.get(result, ['json', 'meta', 'howToPlay', false], {})
      const howToPlay = howToPlays[meta.meta_id]
      const product = (howToPlay.products || []).find(
        (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
      )
      // productはPRODUCT_SCHEMA.SINGLE_STORY.idで絞っているので単話しかありえず、権利はひとつだけである
      const productRight = (howToPlay.rights || []).find(
        (p) => product && p.right_id === product.right_ids[0]
      )
      const course = metaIds.flatMap((id) => howToPlays[id].courses)[0]
      const isInCourse = !(course === undefined || course === null)
      const isNotInCourse = !isInCourse
      let isPurchseAvailable = false // 価格設定が取得でき、購入可能かどうか
      // 月額見放題の場合
      if (isInCourse) {
        isPurchseAvailable = _.get(course, ['active_pricing', 'price']) != null
      } else {
        // 月額見放題に含まれていない無料の場合は常に購入可能
        if (
          META_SCHEMA_ID.EPISODE === meta.meta_schema_id ||
          META_SCHEMA_ID.LIVE === meta.meta_schema_id
        ) {
          isPurchseAvailable = true
        }
        // 月額見放題に含まれていない有料の場合
        if (
          META_SCHEMA_ID.EPISODE_NOT_FREE === meta.meta_schema_id ||
          META_SCHEMA_ID.LIVE_NOT_FREE === meta.meta_schema_id
        ) {
          isPurchseAvailable = _.get(product, ['active_pricing']) != null
        }
      }
      if (this._isMounted) {
        this.setState({
          howToPlay,
          product,
          productRight,
          course,
          status: {
            ...this.state.status,
            isInCourse,
            isNotInCourse,
            isPurchseAvailable
          }
        })
      }
    })
  }

  // 表示モード判定
  setDisplayMode() {
    const { meta, product, course, status } = this.state
    const displayMode = webApp.utils.getDisplayMode(meta, product, course)
    status.displayMode = displayMode
    if (this._isMounted) {
      this.setState({ status })
    }
  }

  // 単話関連
  /** 素材のライセンス有無取得 */
  getIsPossible() {
    const { product } = this.state
    if (!product) return Promise.resolve()
    const refId = product.ref_id
    if (!refId) {
      // コンソールにエラーログだけ吐いて後続処理
      console.error(
        `The "ref_id" of the product "${product.name}" is not registered.`
      )
      return Promise.resolve()
    }

    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) {
      if (this._isMounted) {
        this.setState({
          status: {
            ...this.state.status,
            isNotPurchased: true
          }
        })
      }
      return Promise.resolve()
    }

    const path = ['infra', 'isPossible', refId]
    return this.model.fetch([path]).then((result) => {
      const isPossible = _.get(result, ['json', ...path]) || {}
      if (this._isMounted) {
        this.setState({
          status: {
            ...this.state.status,
            isPurchased: !!isPossible.status,
            isNotPurchased: !isPossible.status,
            limitDate: isPossible.limit_date,
            isPossible: !!isPossible.status
          }
        })
      }
    })
  }
  /** 同シーズンのエピソード取得 */
  getSameSeasonEpisodes() {
    const { seasonId } = this.props

    const path = ['meta', 'children', seasonId]
    return this.model.fetch([path]).then((result) => {
      const episodes = _.get(result, ['json', ...path]) || []
      // 話数でソートする
      episodes.sort((a, b) =>
        Number(a.values.avails_EpisodeNumber) <
        Number(b.values.avails_EpisodeNumber)
          ? 1
          : -1
      )
      if (this._isMounted) {
        this.setState({ episodes })
      }
    })
  }
  /**
   * パック商品情報取得
   * 同一シーズンのエピソードを含むパックを取得
   */
  async getPacks() {
    const { episodes } = this.state
    if (!episodes || !episodes.length) return Promise.resolve()

    const metaIds = episodes.map((e) => e.meta_id)

    return await (async () => {
      // howtoplayがかなり重いので
      // 最初に5件取得してファーストビューのデータを表示した後、残りを遅延ロードさせる

      // array分割
      const arrayChunk = ([...array], size = 1) => {
        return array.reduce(
          (acc, value, index) =>
            index % size ? acc : [...acc, array.slice(index, index + size)],
          []
        )
      }

      let initial_chunk = metaIds.slice(0, 5)
      let after_chunk = metaIds.slice(5, metaIds.length)

      after_chunk = arrayChunk(after_chunk, 10)
      const chunk = [initial_chunk].concat(after_chunk)

      let products = []
      let howToPlays = {}

      for (let i = 0; i < chunk.length; i++) {
        const path = [['meta', 'howToPlay', true, chunk[i]]]
        const w_model = this.context.falcorModel
        await w_model.fetch(path).then((result) => {
          let w_howToPlays =
            _.get(result, ['json', 'meta', 'howToPlay', true]) || {}

          w_howToPlays = Object.assign(w_howToPlays, howToPlays)

          const productsTmp = {}
          Object.keys(w_howToPlays)
            .filter((k) => !k.startsWith('$'))
            .filter((k) => !!w_howToPlays[k])
            .flatMap((k) => w_howToPlays[k].products)
            .filter((p) => p.schema_id !== PRODUCT_SCHEMA.SINGLE_STORY.id)
            // distinct
            .forEach((p) => {
              productsTmp[p.product_id] = p
            })
          const w_products = Object.keys(productsTmp).map((k) => productsTmp[k])

          products = Object.assign(products, w_products)
          howToPlays = Object.assign(howToPlays, w_howToPlays)
        })

        // 最初１グループのときだけレンダリングして一旦表示させて、あとは最後まで読み込んでから表示
        if (i === 0) {
          this.setState({ products, howToPlays })
        }
      }

      return this.setState({ products, howToPlays })
    })()
  }

  // 月額見放題関連
  /** グループのライセンス有無取得 */
  getBelonging() {
    const { course } = this.state
    if (!course) return Promise.resolve()

    const refId = course.ref_id
    if (!refId) {
      // コンソールにエラーログだけ吐いて後続処理
      console.error(
        `The "ref_id" of the course "${course.name}" is not registered.`
      )
      return Promise.resolve()
    }

    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) {
      if (this._isMounted) {
        this.setState({
          status: {
            ...this.state.status,
            isNotPurchased: true
          }
        })
      }
      return Promise.resolve()
    }

    const path = ['infra', 'gBelong', refId]
    return this.model.fetch([path]).then((result) => {
      const belonging = _.get(result, ['json', ...path]) || false
      if (this._isMounted) {
        this.setState({
          status: {
            ...this.state.status,
            isPurchased: belonging,
            isNotPurchased: !belonging,
            isBelonging: belonging
          }
        })
      }
    })
  }
  /** 購入済情報取得 */
  getPurchased() {
    const { course, status } = this.state
    if (!course || !status.isPurchased) return Promise.resolve()
    const refId = course.ref_id
    if (!refId) return Promise.resolve()

    const path = ['infra', 'vodPurchased']
    return this.model.fetch([path]).then((result) => {
      const vodPurchased = _.get(result, ['json', ...path]) || []
      const targetPurchased = vodPurchased.find((purchased) => {
        return purchased.course && purchased.course.ref_id === refId
      })
      if (this._isMounted && targetPurchased) {
        this.setState({
          status: {
            ...this.state.status,
            limitDate: targetPurchased.limit_date
          }
        })
      }
    })
  }
  /** 権利メタ取得 */
  getRightMetas() {
    const { course } = this.state
    // 権利は最初のもので代表
    const rightId = _.get(course, ['right_ids', 0])
    if (!rightId) return Promise.resolve()

    const path = ['right', 'metas', rightId]
    return this.model.fetch([path]).then((result) => {
      const metas = _.get(result, ['json', ...path], [])
      // 公開以外も取得される&重複して取得されることがあるのでフィルター
      const metaIds = {}
      const rightMetas = metas.filter((meta) => {
        const published = meta.publish_status === PUBLISH_STATUS.PUBLISHED
        const duprecated = Object.keys(metaIds).includes(
          meta.meta_id.toString()
        )
        metaIds[meta.meta_id] = null
        return published && !duprecated
      })
      if (this._isMounted) {
        this.checkParentSeasonInRightMetas(rightMetas)
        //this.setState({ rightMetas });
      }
    })
  }

  checkParentSeasonInRightMetas(rightMetas) {
    const { seasonId } = this.props
    const { meta } = this.state
    let seasonIdsInRightMetas = []
    let mediaMetaIds = []
    // この画面で何も考えずにパックに登録されているエピソードの一覧を出すと
    // エピソード名しか表示しない仕様のため、シリーズが混在して表示されるとなんの番組なのかわからない
    // なので、表示するエピソードの親シーズンを検索して、シーズンの検索をできるようにしておく必要がある。
    let return_flag = false
    _.forEach(rightMetas, (line) => {
      if (meta.values.parents_season.id === line.meta_id) {
        // 自分自身の親が含まれているのでそれを返して、バック検索のrootにして処理終了
        this.setState({ rightMetas: [line] })
        return_flag = true
        return
      }
    })

    if (!return_flag) {
      this.setState({ rightMetas: rightMetas })
    }
  }

  /** 月額見放題コース内のエピソード取得 */
  getCourseEpisodes() {
    const { rightMetas } = this.state

    const stories = rightMetas.filter((meta) =>
      [
        META_SCHEMA_ID.EPISODE,
        META_SCHEMA_ID.EPISODE_NOT_FREE,
        META_SCHEMA_ID.LIVE,
        META_SCHEMA_ID.LIVE_NOT_FREE
      ].includes(meta.meta_schema_id)
    )
    const parentIds = rightMetas
      .filter((meta) =>
        [
          META_SCHEMA_ID.SERIES,
          META_SCHEMA_ID.SEASON,
          META_SCHEMA_ID.LIVE_SERIES,
          META_SCHEMA_ID.LIVE_SEASON
        ].includes(meta.meta_schema_id)
      )
      .map((meta) => meta.meta_id)

    const path = [['meta', 'children', parentIds]]
    return this.model.fetch(path).then((result) => {
      const childrenMap = _.get(result, ['json', 'meta', 'children'], {})
      const children = parentIds
        .flatMap((id) => childrenMap[id])
        .filter((meta) =>
          [
            META_SCHEMA_ID.EPISODE,
            META_SCHEMA_ID.EPISODE_NOT_FREE,
            META_SCHEMA_ID.LIVE,
            META_SCHEMA_ID.LIVE_NOT_FREE
          ].includes(meta.meta_schema_id)
        )
      // distinct
      const episodeMap = {}
      ;[...stories, ...children].forEach((e) => {
        episodeMap[e.meta_id] = e
      })
      const episodes = Object.values(episodeMap)
      episodes.sort((a, b) =>
        a.delivery_start_at < b.delivery_start_at ? 1 : -1
      )

      if (this._isMounted) {
        this.setState({ episodes })
      }
    })
  }

  /** シーズン情報取得 */
  getSeason() {
    const { seasonId } = this.props
    if (!seasonId) return Promise.resolve()

    const path = ['meta', seasonId]
    return this.model.fetch([path]).then((result) => {
      const season = _.get(result, ['json', ...path]) || {}
      if (this._isMounted) {
        this.setState({ season })
      }
    })
  }

  /** レコメンド(閲覧ログ蓄積) */
  recommendActionLog() {
    const metaId = this.props.episodeId
    if (!metaId) return Promise.resolve()
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) return Promise.resolve()
    const path = [['action_log', 'history', 'view', metaId, sessionId]]
    return this.model.fetch(path)
  }

  /** 再生ログ送信 */
  sendPlayLog() {
    const { status, meta, product, course } = this.state
    // ログインしていない場合基盤のメンバーIDが取れないので送信しない
    // 月額見放題内の素材IDを持つもののみ送信可能
    if (
      !webApp.utils.isLoggedIn(this.context) ||
      !meta ||
      !status.isInCourse ||
      !product ||
      !product.ref_id ||
      !course ||
      !course.ref_id
    )
      return
    const params = {
      svodGroupId: course.ref_id,
      materialId: product.ref_id,
      playAt: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      pid: _.get(meta, ['values', 'parents_season', 'evis_ProgramID']) || ''
    }
    return this.model
      .call(['infra', 'svodPlayLog'], [params])
      .then(() => {})
      .catch((err) => webApp.utils.handleFalcorError(err, this.context))
  }

  /**
   * 固定viewport設定
   * 対象: FireTVのSilkブラウザ
   * 1920x1080 -> 960x540 (2 / 1)
   * PC表示にするため`initial-scale`を調整
   */
  setPcWidthViewport() {
    const viewportMeta = document.querySelector('meta[name="viewport"]')
    if (!viewportMeta) return

    viewportMeta.content = viewportMeta.content.replace(
      'initial-scale=1',
      'initial-scale=0.75'
    )
  }

  /** 固定viewport解除 */
  removePcWidthViewport() {
    const viewportMeta = document.querySelector('meta[name="viewport"]')
    if (!viewportMeta) return

    viewportMeta.content = viewportMeta.content.replace(
      'initial-scale=0.75',
      'initial-scale=1'
    )
  }

  render() {
    const {
      meta,
      programMeta,
      otherSeasons,
      howToPlay,
      product,
      productRight,
      episodes,
      products,
      howToPlays,
      showPlayer,
      status,
      course,
      season,
      rightMetas,
      loaded
    } = this.state
    if (!meta) return null
    if (!Object.keys(meta).length)
      return [<NotFound key="not-found" />, <Footer key="footer" />]

    // 単話ページはテンプレート利用対象
    const templateId = meta.values.ex_templateId || 0
    const templateKeyPrefix = 'episode_'
    const templateKey = `${templateKeyPrefix}${templateId}`
    const template =
      namespace.templates[templateKey] ||
      namespace.templates[`${templateKeyPrefix}0`]
    if (!template) {
      console.error(`Template:${templateKey} not found.`)
      return null
    }

    const childProps = Object.assign({}, this.props, {
      meta,
      programMeta,
      otherSeasons,
      episodes,
      howToPlay,
      product,
      productRight,
      products,
      howToPlays,
      showPlayer,
      status,
      course,
      season,
      rightMetas,
      sendPlayLog: this.sendPlayLog,

      loaded
    })
    return React.createElement(template, childProps)
  }
}
