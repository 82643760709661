import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import NotFound from '../../../generic/components/errors/NotFound'
import webApp from '../../utils/exdioWebAppUtils'
import namespace from '../../../../common/namespace'

const { sprintf } = require('sprintf-js')

/** 月額見放題ページ */
export default class PlanContent extends Component {
  static propTypes = {
    slug: PropTypes.string
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  static defaultProps = {
    slug: ''
  }

  static getPath(_models, _options, props) {
    return ['courseBySlug', props.slug]
  }

  static getPrefetchPaths(models, options, props) {
    return [PlanContent.getPath(models, options, props)]
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const course = _.get(prefetchResult, [
      'json',
      ...PlanContent.getPath(models, options, props)
    ])
    if (!course) return { title: '', thumbnail: '', description: '' }
    const title = course.name
    const thumbnail = course.thumbnail_url
    const { description } = course
    return { title, thumbnail, description }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    const path = PlanContent.getPath({}, {}, props)
    const course = this.model.getSync(path) || {}

    this.state = {
      course,
      season: null,
      isNotFound: false
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const { slug } = this.props
    if (!slug) {
      this.setState({ isNotFound: true })
      return
    }

    const { course, isNotFound, season } = await this.getCourse().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    this.updateMetaTags(course)

    this.setState({
      course,
      season,
      isNotFound,
      loaded: true
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags(course) {
    const tmpCourse = course || {}

    // タイトルタグの更新
    const title = sprintf(this.config.title_template, tmpCourse.name)
    webApp.utils.updateTitle(title)

    // メタタグの更新
    const { copyright } = this.config
    const description = tmpCourse.description || this.config.description
    const keywords = this.config.keywords
    const rootUrl = `${window.location.protocol}//${window.location.host}`
    const ogImage =
      _.get(course, ['thumbnail_url']) ||
      sprintf('%s/images/exdio/%s', rootUrl, this.config.og_image)
    const url = window.location.href
    const regularUrl = url.replace(/\?.*$/, '')
    // GTMの更新
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: ogImage },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'mixi:image', content: ogImage },
        { property: 'og:image', content: ogImage },
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: regularUrl }]
    }
    webApp.utils.updateMeta(metaTags)
    webApp.utils.updateDataLayer(gtmTags)
    if (document.URL.match(/logirl/)) {
      webApp.utils.updateWebClip('/logirl.png')
    } else if (document.URL.match(/shinchan/)) {
      webApp.utils.updateWebClip('/shinchan.png')
    }
  }

  /** コース情報取得 */
  getCourse() {
    const { slug } = this.props
    const path = [['courseBySlug', slug]]
    return this.model.fetch(path).then((result) => {
      const course = _.get(result, ['json', 'courseBySlug', slug])
      const season = _.get(course, ['values', 'season'])
      if (this._isMounted) {
        const isNotFound = !course
        return { course, isNotFound, season }
      }
    })
  }

  render() {
    if (this.state.isNotFound) return <NotFound />

    const { course, season } = this.state
    if (!course || !_.size(course)) return null

    // 月額見放題ページはテンプレート利用対象
    const templateId = course.values.ex_templateId || 0
    const templateKeyPrefix = 'plan_'
    const templateKey = `${templateKeyPrefix}${templateId}`
    const template =
      namespace.templates[templateKey] ||
      namespace.templates[`${templateKeyPrefix}0`]
    if (!template) {
      console.error(`Template:${templateKey} not found.`)
      return null
    }

    const childProps = Object.assign({}, this.props, { course, season })
    return React.createElement(template, childProps)
  }
}
