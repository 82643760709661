import React, { memo, useState, useEffect } from 'react'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import { StyledDivInner } from '../../style'
import styled from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'

const Footer = () => {
  const [isScrollTop, setIsScrollTop] = useState(true)

  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const onScroll = () => {
    const currentScroll = document.scrollingElement.scrollTop
    if (currentScroll > 1 && isScrollTop) {
      setIsScrollTop(false)
    } else if (currentScroll < 1) {
      setIsScrollTop(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [isScrollTop])

  return (
    <footer>
      <StyledDiv1>
        <StyledButton onClick={returnTop} isScrollTop={isScrollTop}>
          <img
            src="/images/exdio/renewal/hissatsu/pagetop.png"
            alt="ページトップに戻る"
          />
        </StyledButton>
        <StyledDivInner>
          <StyledLink
            href="https://www.tv-asahi.co.jp/douga_mv/recommend/?utm_source=lp&utm_medium=hissatsu"
            target="_blank" // 新しいタブで開く
          >
            <img
              src="/images/exdio/renewal/hissatsu/douga_bnr.jpg"
              alt="テレ朝動画ならあの番組をいつでも楽しめる！テレ朝動画についてはこちら"
            />
          </StyledLink>
        </StyledDivInner>
      </StyledDiv1>
      <StyledDiv2>
        <StyledDivInner>
          <small>（C）ABCテレビ・松竹（C）ABCテレビ・テレビ朝日・松竹</small>
        </StyledDivInner>
      </StyledDiv2>
    </footer>
  )
}

export default memo(Footer)

const StyledDiv1 = styled.div`
  position: relative;
  padding: 80px 0;
  background: #000;
  text-align: center;

  ${mediaQuery(768)} {
    padding: 30px 0;
  }
`
const StyledLink = styled(Link)`
  display: block;
  width: fit-content;
  margin: 0 auto;
  transition: opacity 0.3s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`

const StyledDiv2 = styled.div`
  padding: 40px 0;
  background: #740000;
  text-align: center;
  color: #fff;
  font-size: 1.3rem;
  font-feature-settings: 'palt';

  ${mediaQuery(768)} {
    padding: 20px 0;
  }
`

const StyledButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: transparent;
  border: 0;
  transition: all 0.3s;
  opacity: ${({ isScrollTop }) => (isScrollTop ? 0 : 1)};

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }

  ${mediaQuery(768)} {
    bottom: 10px;
    right: 10px;
    width: 80px;
    height: 80px;

    img {
      width: 100%;
    }
  }
`
