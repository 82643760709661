import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import routes from '../../routes';

export default class GlobalFooterNav extends Component {
  static propTypes = {
    handleOtherVisible: PropTypes.func,
  }

  static contextTypes = {
    history: PropTypes.object,
  }

  constructor(props, context) {
    super(props, context);
  }

  // その他エリアの表示非表示
  handleOtherVisible(){
    return this.props.handleOtherVisible()
  }

  render() {
    return (
      <ul className="globalFooter-nav">
        <li className="globalFooter-nav-item navi_search_api"
          onClick={ ((e) => { 
            if(location.pathname === '/app/search'){
              history.back()
            } else {
              const { history } = this.context;
              history.push(routes.app_search.makePath());
            }
          })
        }>
          <img src="/images/exdio/search.png" className="globalFooter-nav-item-icon" alt="さがす"/>
          <p className="globalFooter-nav-item-text">さがす</p>
        </li>
        <li className="globalFooter-nav-item navi_home_api"
          onClick={ ((e) => { 
            const { history } = this.context;
            history.push(routes.app.makePath());
          })
        }>
          <img src="/images/exdio/home.png" className="globalFooter-nav-item-icon" alt="ホーム"/>
          <p className="globalFooter-nav-item-text">ホーム</p>
        </li>
        <li className="globalFooter-nav-item navi_etc_api" onClick={this.handleOtherVisible.bind(this)}>
          <img src="/images/exdio/other.png" className="globalFooter-nav-item-icon" alt="その他"/>
          <p className="globalFooter-nav-item-text">その他</p>
        </li>
      </ul>
    );
  }
}
