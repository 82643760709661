import styled from 'styled-components'

export const StyledDiv = styled.div`
  padding: 30px;
  background-color: #333;
`

export const StyledP = styled.p`
  font-size: 2rem;
  color: #fff;
`

export const StyledImg = styled.img`
  margin: 0;
  display: block;

  & + ${StyledP} {
    margin-top: 30px;
  }
`
