import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'jquery';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import classnames from 'classnames';
import Card from './Card';
import LoadingGallery from '../../loader/LoadingGallery';
import * as browserEvents from '../../../../utils/browserEvents';
import * as DOMUtils from '../../../../utils/DOMUtils';
import window from 'global/window';

const PERPAGE = 999;
export default class GalleryContent extends React.Component {

  static getPaths = function(models, options, props) {
    const indexes = function(models, options, props) {
      return { from:props && props.fromNum || 0, to: (props && props.toNum ? props.toNum : options ? options.numTitles + (props.fromNum || 0) : PERPAGE - 1)};
    }
    const rootPath = this.getRootPath(models, options, props);
    const paths = Card.getPaths().map(function(path) {
      return rootPath.concat(indexes(models, options, props)).concat(path);
    });
    const lengthPath = rootPath.concat(['length']);
    return (props && props.fromNum || 0) === 0 ? paths.concat([lengthPath]) : paths;
  };

  static getRootPath = function(models, options, props) {
    const rootPath = [];
    if (props.keyPrefix) rootPath.push(props.keyPrefix);
    if (!!props.id) rootPath.push(props.id);
    if (!!props.term) {
      rootPath.push('byTerm');
      rootPath.push(props.term);
    }
    if (props.sortOrder) rootPath.push(props.sortOrder);
    if (props.videoRoot) rootPath.push(props.videoRoot);
    return rootPath;
  };

  static propTypes = {
    filter: PropTypes.func,
    mainId: PropTypes.number,
    id: PropTypes.string,
    isPrefetchingPaths: PropTypes.bool,
    keyPrefix: PropTypes.string,
    model: PropTypes.object,
    query: PropTypes.array,
    sortOrder: PropTypes.string,
    uiView: PropTypes.string,
    enablePushOut: PropTypes.bool,
    classnames: PropTypes.object
  };

  static contextTypes = {
    columnsInRow: PropTypes.number,
    currentPage: PropTypes.string,
    isInitialRender: PropTypes.bool,
    isRtl: PropTypes.bool,
    isTallRow: PropTypes.bool,
    loggingApp: PropTypes.object,
    profileManager: PropTypes.string,
    routeHandler: PropTypes.object,
    popType: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    this.DIOresumeInfo = this.getResumeStorage.bind(this);

    if (!props.children) {
      const rootPath = this.constructor.getRootPath(context.models, null, props);
      this.totalCount = props.model.getSync(rootPath.concat('length'));
      this.isFirstSetOfResults = true;
      this.numTitlesToFetch = PERPAGE;

      this.state = {
        dispose: null,
        fetchDataError: null,
        generation: props.model.getVersion(rootPath),
        key: parseInt(props.id, 10),
      };
    } else {
      this.totalCount = props.totalItems;
      this.state = {};
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.children) {
      this.fetchData(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children) {
      return;
    }

    if (nextProps.sortOrder !== this.props.sortOrder ||
        nextProps.id !== this.props.id ||
        nextProps.term !== this.props.term ||
        nextProps.keyPrefix !== this.props.keyPrefix) {
      this.isFirstSetOfResults = true;
      this.numTitlesToFetch = PERPAGE;
      this.totalCount = -1;
      this.items = [];
      this.setState({fetchDataError: null, generation: -1});
      this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
    if (!this.props.children) {
    }
  }

  movePrev(event){
    $('.content_next').show();
    var slidePos = $('.episode_slider').position().left;
    event.preventDefault();
    if (slidePos >= -960) {
        $('.episode_slider').not(':animated').animate({
            left: '0'
        }, 'fast');
        $('.content_prev').hide();
    } else {
        $('.episode_slider').not(':animated').animate({
            left: '+=960'
        }, 'fast');
    }
  }

  moveNext(event){
    console.log("----moveNext---");
        $('.content_prev').show();
        var slidePos = $('.episode_slider').position().left;
        event.preventDefault();
        if (slidePos <= this.limitSlide) {
            $('.episode_slider').not(':animated').animate({
                left: this.limitSlide - 960
            }, 'fast');
            $('.content_next').hide();
        } else {
            $('.episode_slider').not(':animated').animate({
                left: '-=960'
            }, 'fast');
        }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
    if (!this.props.children) {
    }
  }

  isLoading() {
    return !!this.state.dispose || this.props.isPrefetchingPaths;
  }

  getResumeStorage() {
    try {
      return JSON.parse( window.localStorage.getItem('DIOresumeInfo') )
    } catch(e) {
      console.log(e)
      return []
    }
  }

  setProgress(cardId) {
    let progress = 0;
    _.forEach(this.DIOresumeInfo(), item => {
      if(item.metaid === cardId){
        progress = Math.ceil(item.lastPlayed / item.duration * 100);
        return false;
      }
    });

    return progress;
  }

  render() {
    let cards, program_link = this.props.program_link;
    cards = _.map(this.items || [], (itemData, index) => {
      let mainView = this.props.mainId;
      if(itemData.id == this.props.mainId || (typeof(itemData.mainSeason) != "undefined" && itemData.mainSeason == this.props.mainId)) {
        this.currentIndex = index;
        mainView = itemData.id;
      }
      let link = (program_link == true && itemData.values.ex_templateId == 2) ? true : false;
      return (
        <Card
          mainId={mainView}
          popType={this.context.popType}
          model={this.props.model}
          key={`title_${itemData.id}_${index}`}
          rowNum={0}
          rankNum={index}
          seasonId={itemData.values.parents_season.id}
          program_link={link}
          titleId={itemData.id}
          progress={this.setProgress(itemData.id)}
          subtitle={itemData.values.subtitle}
        />
      );
    });
    this.itemSize = cards.length;

  this.limitSlide = -(this.itemSize * 160 - 960) + 960;
  let prevStyle = {display: "block"};
  this.currentPosition = {};
  if(this.itemSize <= 6 || this.currentIndex < 3){
    prevStyle = {display: "none"}
  }

  let nextStyle = {display: "block"};
  if(this.itemSize <= 6 || this.currentIndex >= (this.itemSize - 4)){
    nextStyle = {display: "none"}
    this.currentPosition = {left: -(this.itemSize - 6) * 160}
  }

  if(this.currentIndex > 3 && this.itemSize > 6 && this.currentIndex < (this.itemSize - 4)){
    this.currentPosition = {left: -(this.currentIndex - 2) * 160}
  }

    const size = cards.length;
    const chunckSize = this.props.itemsInRow || this.context.columnsInRow; // いくつずつに分割するか
    const cardChuncks = []; // 新しく作る配列

    for (let i = 0; i < Math.ceil(size / chunckSize); i++) {
      const j = i * chunckSize;
      const chunckedTitles = cards.slice(j, j + chunckSize);
      cardChuncks.push(chunckedTitles);
    }
    let content;

    if (this.isLoading() && this.isFirstSetOfResults && cardChuncks.length === 0) {
      content = (<div className="gallerySpinLoader"><div className="loader-cont"></div></div>);
    } else if (cards.length > 0) {
      content = (
        <div key="main-gallery" className="avod_slider">
          <div key={`gallery-${this.props.id}`} id="epi_sld">
            <div className="epi_sld_wrap wide_wrap">
              <div className="epi_sld">
                <div className="epi_sld_wrap wide_wrap">
                  <ul className="episode_slider wide_slide" style={this.currentPosition}>
                    {cards}
                  </ul>
                  <button className="content_prev slider_btn icon-arow-left" type="button" onClick={this.movePrev.bind(this)} style={prevStyle}></button>
                  <button className="content_next slider_btn icon-arow-right" type="button" onClick={this.moveNext.bind(this)} style={nextStyle}></button>
                  <span className="titleRowContent"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.totalCount == 0) {
      if (this.props.noResults) {
        content = this.props.noResults;
      } else {
        content = (
          <div>
            <div className="galleryMessage">一致するタイトルは見つかりませんでした。</div>
          </div>
        );
      }
    }

    return (
      <div ref="gallery" className={classnames("gallery", "row-with-x-columns", this.props.classes)}>
        <CSSTransitionGroup
          component="div"
          className="galleryContent"
          transitionEnterTimeout={250}
          transitionLeaveTimeout={550}
          transitionName="pulsateTransition">
          {content}
        </CSSTransitionGroup>
      </div>
    );
  }

  fetchData(props) {
    if (this.props.children) return;
    const pathProps = Object.assign({}, props, {toNum: this.numTitlesToFetch - 1});
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    // 事前に通信している場合、その情報は全て削除してしまったほうが良い
    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, {dispose});
    } else {
      this.setState({dispose});
    }
    evaluator.then((result) => {
      if (result) {
        let length = 0;
        let data = _.get(result.json, this.constructor.getRootPath(this.context.models, {}, pathProps));
        if (this.props.program_link == true){
          let delete_list = [];
          for(var key in data) {
            if (!data[key]) continue;
            if (key == "length") continue;
            if (data[key] && data[key].values && data[key].values.ex_templateId == 2) {
              if (delete_list[data[key].values.parents_season.avails_SeasonAltID]) {
                if (data[key].id == this.props.mainId) {
                  data[delete_list[data[key].values.parents_season.avails_SeasonAltID]].mainSeason = this.props.mainId;
                }
                length--;
                delete data[key];
              } else {
                delete_list[data[key].values.parents_season.avails_SeasonAltID] = key;
              }
            }
          }
        }
        this.items = _.clone(_.values(_.omitBy(data, (val, key) => (key.indexOf('$') === 0 || key === 'length'))));
        data.length = data.length ? data.length - length : this.items.length;
        if (data.hasOwnProperty('length')) {
          this.totalCount = data.length;
        }
      } else {
        this.totalCount = this.items.length;
      }
      this.numTitlesToFetch += PERPAGE;
      if (this.numTitlesToFetch > this.totalCount) this.numTitlesToFetch = this.totalCount;
      delete this.state[JSON.stringify(paths)];
      this.isFirstSetOfResults = false;
      const newState = {
        fetchDataError: null,
        dispose: null,
        fetchingMoreRows: undefined,
        generation: this.state.generation + 1
      };
      if (this._isMounted) this.setState(newState);
      else Object.assign(this.state, newState);
    }).catch((e) => {
      const newState = {
        fetchDataError: e,
        fetchingMoreRows: undefined,
        dispose: null
      };
      delete this.state[JSON.stringify(paths)];
      if (this._isMounted) this.setState(newState);
      else this.state = newState;
    });
  }

  isWithinDistanceBuffer(distanceBuffer){
    if (!this.refs.gallery) return;
    const el = ReactDOM.findDOMNode(this.refs.gallery);
    if (el) {
      return DOMUtils.getDistanceToBottomOfElement(el) < distanceBuffer;
    }
    return false;
  }
}
