import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Footer from '../../../../common/components/Footer'
import EpisodeList from '../british/common/EpisodeList'
import BreadCrumb from '../british/common/BreadCrumb'
import Header from '../british/common/Header'
import Heading1 from '../british/common/Heading1'
import Heading3 from '../british/common/Heading3'
import { mediaSp, mainColor, yuGothic } from '../british/common/styles'

/** 全英オープン番組ページ テンプレート */
const ProgramBritish = ({ seriesId, seasonId, episodes, ...props }) => {
  return (
    <div>
      <StyledBreadCrumb
        seriesId={seriesId}
        seasonId={seasonId}
      />

      <Header
        seriesId={seriesId}
        seasonId={seasonId}
      />

      <StyledH1>
        <img
          src="/douga_mv/british-open-golf/images/main.png"
          alt="全英オープン最強王者決定戦 ～歴代優勝者No.1は誰だ？～"
        />
      </StyledH1>

      <StyledSection1>
        <Heading1 text={'ABOUT'}></Heading1>
        <StyledText>全英オープンの歴代王者がセントアンドリュースに集結！！<br />Jニクラウス、Tワトソンら往年の名プレイヤーとTウッズ、Rマキロイといった現役最強プレイヤーが<br />時空を超えて架空のトーナメントで熱き戦いを繰り広げる。<br />歴代優勝者のNo.1に輝くのは果たして…</StyledText>
      </StyledSection1>

      <StyledSection2>
        <Heading3
          enStr={'MOVIE'}
          jaStr={'動画一覧'}
        />

        <StyledEpisodeList
          episodes={episodes}
          rootMetas={episodes}
          showNew
          showChecked
          onlySubTitle
        />
      </StyledSection2>
      <StyledFooter />
    </div>
  )
}

export default memo(ProgramBritish)

ProgramBritish.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object)
};

ProgramBritish.defaultProps = {
  seriesId: '',
  seasonId: '',
  episodes: []
};

const StyledBreadCrumb = styled(BreadCrumb)`
@media ${mediaSp} {
  display: none;
}
`

const StyledH1 = styled.h1`
padding: 135px 0;
background: url('/douga_mv/british-open-golf/images/TO_Hero_StGeorges_1st_Ext_Landscape.jpg') center top / cover;
text-align: center;

@media ${mediaSp} {
  padding: 58px calc((55) / 375 * 100%);
  letter-spacing: .7px;
}
`

const StyledSection1 = styled.section`
margin: 50px auto 0;
text-align: center;

@media ${mediaSp} {
  margin: 20px 3% 0;
  text-align: left;
}
`

const StyledSection2 = styled.section`
margin-top: 50px;
padding-bottom: 100px;

@media ${mediaSp} {
  margin-top: 20px;
  padding-bottom: 50px;
}
`

const StyledEpisodeList = styled(EpisodeList)`
margin: 30px auto 0;
width: 960px;

@media ${mediaSp} {
  margin: 20px 15px 0;
  width: auto;
}
`

const StyledText = styled.p`
margin-top: 40px;
color: ${mainColor};
font: bold 1.6rem ${yuGothic};
line-height: 1.875;

@media ${mediaSp} {
  margin-top: 26px;
  font-size: 1.4rem;
  line-height: 1.6;
}
`

const StyledFooter = styled(Footer)`
margin-top: 0;
`
