import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import Caption from '../details/Caption'
import DeliverPeriod from '../details/DeliverPeriod'
import Price from './Price'
import DeliveryStartAt from './DeliveryStartAt'
import OnAirDate from './OnAirDate'
import { getPrice } from './util'

/** エピソードメタ情報 */
const Meta = (
  {
    isLive = false,
    metaName = '',
    hidePrice = false,
    meta = {},
    episodes = [],
    productRight = {},
    product = {},
    course = {},
    status = {},
    className = '',
    ...props
  },
  context
) => {
  const isApp = webApp.utils.isApp(context)

  /** 動画の長さ取得 */
  const time = webApp.utils.duration(meta) || '-'

  /** 最新話判別 */
  const isLatest = () => {
    const latest = get(episodes, [0]) || {}
    return meta && latest && get(meta, ['meta_id']) === get(latest, ['meta_id'])
  }

  return (
    <div className={`c-storyMeta-inBox-meta ${className}`} {...props}>
      <h2 className="c-storyMeta-inBox-meta-title">
        {metaName}
        <span className="c-storyMeta-inBox-meta-title-small">
          {isLatest() && '（最新話）'}
        </span>
      </h2>
      {!isLive && (
        <div className="c-storyMeta-inBox-meta-time">時間：{time}</div>
      )}
      {hidePrice !== true && (
        <Price
          status={status}
          price={getPrice(meta, product, status, course)}
        />
      )}
      <OnAirDate meta={meta} isLive={isLive} />
      {!isApp && (
        <DeliveryStartAt meta={meta} status={status} isLive={isLive} />
      )}
      <DeliverPeriod meta={meta} status={status} productRight={productRight} />
      <Caption
        caption={get(meta, ['values', 'evis_EpisodeLongSynopsis'])}
        otherInstructions={get(meta, ['values', 'avalis_OtherInstructions'])}
        actors={get(meta, ['values', 'evis_Actors'])}
        directors={get(meta, ['values', 'evis_Directors'])}
        producers={get(meta, ['values', 'evis_Producers'])}
        writers={get(meta, ['values', 'evis_Writers'])}
        productions={get(meta, ['values', 'evis_Productions'])}
      />
    </div>
  )
}

export default Meta

Meta.propTypes = {
  /** ライブ配信か否かの判定を行う */
  isLive: PropTypes.bool,
  /** 動画の題名 */
  metaName: PropTypes.string,
  /** 同一シーズンのエピソード */
  episodes: PropTypes.arrayOf(PropTypes.shape({})),
  /** trueの時、料金を非表示にする */
  hidePrice: PropTypes.bool,
  /** 動画情報
   * @param {string} values.evis_EpisodeLongSynopsis - 動画説明文
   * @param {string} values.avalis_OtherInstructions - これが入力されていると出演者〜制作などの情報が描画されない代わりにavalis_OtherInstructionsの内容が描画されるようになる
   * @param {string} values.evis_Actors - 出演者
   * @param {string} values.evis_Directors - 監督
   * @param {string} values.evis_Producers - プロデューサー
   * @param {string} values.evis_Writers - 脚本
   * @param {string} values.evis_Productions - 制作
   * @param {string} values.avails_ReleaseHistoryOriginal - 動画配信日
   * @param {string} values.duration - 動画再生時間（秒）
   */
  meta: PropTypes.shape({}),
  /** 動画関連の権利
   * @param {string} view_term - 視聴可能期間
   */
  productRight: PropTypes.shape({}),
  /** 動画情報
   * @param {number} active_pricing.price - 動画単品値段
   */
  product: PropTypes.shape({}),
  /** 動画コース情報
   * @param {number} active_pricing.price - 動画コース料金
   */
  course: PropTypes.shape({}),
  /** 動画の視聴ステータス情報 */
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  className: PropTypes.string
}

Meta.contextTypes = {
  models: PropTypes.shape({}),
  routeHandler: PropTypes.shape({}),
  history: PropTypes.shape({})
}
