import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import { colors } from '../style'
import { mediaQuery } from '../../../style'

const Button1 = ({ children = '', ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>
}

export default Button1

Button1.propTypes = {
  children: PropTypes.string
}

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['bgColor', 'color'].includes(prop)
})`
  padding: 13px 15px;
  height: 100%;
  background-color: ${({ bgColor }) => bgColor || colors.pink};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color || '#fff'};
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.5;
  transition: opacity 0.2s;

  &:link,
  &:visited {
    color: ${({ color }) => color || '#fff'};
  }

  &:hover {
    ${mediaQuery('sm', 'min')} {
      opacity: 0.7;
    }
  }

  ${mediaQuery()} {
    padding: 13px 15px;
    font-size: 1.6rem;
  }
`
