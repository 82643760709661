/* style */
import styled from 'styled-components'

export const StyledLi = styled.li`
  width: calc(100% / 2 - 8px);
  margin: 0 4px 12px;
  float: left;
`

export const StyledDiv1 = styled.div`
  position: relative;
`

export const StyledP1 = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(31, 144, 100);
  padding: 3px 5px;
  color: #fff;
  font-size: 1rem;
`

export const StyledDiv2 = styled.div`
  padding: 5px 0;
  position: relative;
`

export const StyledDiv3 = styled.div`
  color: #333;
  background: #eee;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.2;
  position: absolute;
  top: -20px;
  right: -5px;
  padding: 7px 7px 5px;
  width: 90%;
`

export const StyledDiv4 = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 30px;
`

export const StyledP2 = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

export const StyledImg = styled.img`
  width: 40px;
  clip-path: none;
`

export const StyledP3 = styled.p`
  text-align: right;
  font-size: 1.2rem;
`
