import styled from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'

export const StyledDiv = styled.div`
  margin: 12px auto 0;
  text-align: center;

  ${mediaQuery(960)} {
    margin: 20px 8px 0;
    max-width: none;
  }
`

export const StyledP = styled.p`
  width: 100%;

  ${mediaQuery(960)} {
    text-align: center;
  }
`

export const StyledA = styled.a`
  display: inline-block;
`

export const StyledImg = styled.img`
  width: inherit;
  height: auto;
  ${mediaQuery(960)} {
    width: 100%;
    height: auto;
  }
`
