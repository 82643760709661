/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'

export const StyledDiv = styled.div`
  width: 950px;
  height: 534px;
  cursor: pointer;

  ${mediaQuery(1320)} {
    width: 694px;
    height: 390px;
  }

  ${mediaQuery()} {
    width: 100%;
    height: 100%;
  }
`
export const StyledImg = styled.img`
  display: block;
  width: 100%;
`
