import styled from 'styled-components'
import Link from '../../../../../../../../sketch-platform/ui/routing/Link'

export const StyledDiv1 = styled.div`
  margin: 3px 8px;
  overflow: hidden;
`

export const StyledLink = styled(Link)`
  && {
    padding: 2px 0;
    width: 49.75%;
    background: #6c6e6e;
    border: none;
    border-radius: 0;
    position: relative;
    color: #fff;
    line-height: 28px;
    text-align: center;
  }
`

export const StyledLink1 = styled(StyledLink)`
  float: left;
  &::before {
    position: absolute;
    top: 2px;
    left: 8%;
    font-size: 20px;
    font-family: 'Material Icons';
    content: '\\e314';
  }
`

export const StyledLink2 = styled(StyledLink)`
  float: right;
  &::before {
    position: absolute;
    top: 2px;
    right: 8%;
    font-size: 20px;
    font-family: 'Material Icons';
    content: '\\e315';
  }
`
