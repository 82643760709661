import React from 'react';
import PropTypes from 'prop-types';

import webApp from '../../../utils/exdioWebAppUtils';

/** 詳細画面:キャスト情報コンポーネント */
export default function CastInfo(props) {
  const { otherInstructions, actors, directors, producers, writers, productions, copyRight, isPadding } = props;
  const showItems = [];
  const removeIndention = (str='') => {
    return str.replace(/\r?\n/g, ' ')
  };

  if (otherInstructions) {
    showItems.push(
      <div className="meta-category" key="otherInstructions">
        <div className="meta-category-content">
          <ul className={!isPadding ? 'no-margin' : null}>
            <li>{webApp.utils.feedLine(otherInstructions)}</li>
          </ul>
        </div>
      </div>
    );
  } else {
    if (actors) {
      showItems.push(
        <div className="meta-category" key="cast">
          <header className="meta-category-header">
            <h4 className="meta-category-name">出演者</h4>
          </header>
          <div className="meta-category-content">
            <ul className={!isPadding ? 'no-margin' : null}>
              <li>{removeIndention(actors)}</li>
            </ul>
          </div>
        </div>
      );
    }
    if (writers) {
      showItems.push(
        <div className="meta-category" key="writers">
          <header className="meta-category-header">
            <h4 className="meta-category-name">脚本</h4>
          </header>
          <div className="meta-category-content">
            <ul className={!isPadding ? 'no-margin' : null}>
              <li>{removeIndention(writers)}</li>
            </ul>
          </div>
        </div>
      );
    }
    if (directors) {
      showItems.push(
        <div className="meta-category" key="directors">
          <header className="meta-category-header">
            <h4 className="meta-category-name">監督</h4>
          </header>
          <div className="meta-category-content">
            <ul className={!isPadding ? 'no-margin' : null}>
              <li>{removeIndention(directors)}</li>
            </ul>
          </div>
        </div>
      );
    }
    if (producers) {
      showItems.push(
        <div className="meta-category" key="producers">
          <header className="meta-category-header">
            <h4 className="meta-category-name">プロデューサー</h4>
          </header>
          <div className="meta-category-content">
            <ul className={!isPadding ? 'no-margin' : null}>
              <li>{removeIndention(producers)}</li>
            </ul>
          </div>
        </div>
      );
    }
    if (productions) {
      showItems.push(
        <div className="meta-category" key="staff">
          <header className="meta-category-header">
            <h4 className="meta-category-name">制作</h4>
          </header>
          <div className="meta-category-content">
            <ul className={!isPadding ? 'no-margin' : null}>
              <li>{removeIndention(productions)}</li>
            </ul>
          </div>
        </div>
      );
    }
  }
  if (!showItems.length && !copyRight) return null;

  return (
    <div className="common-list-meta">
      <div className={`common-list-meta-box ${isPadding ? '' : 'no-padding'}`}>
        {showItems}
        {copyRight && (
          <div className="meta-category copy">
            <div className="meta-category-content">
              <ul>
                <li>{copyRight}</li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

CastInfo.propTypes = {
  otherInstructions: PropTypes.string,
  actors: PropTypes.string,
  directors: PropTypes.string,
  producers: PropTypes.string,
  writers: PropTypes.string,
  productions: PropTypes.string,
  copyRight: PropTypes.string,
  isPadding: PropTypes.bool
};

CastInfo.defaultProps = {
  otherInstructions: '',
  actors: '',
  directors: '',
  producers: '',
  writers: '',
  productions: '',
  copyRight: '',
  isPadding: false
};
