import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from '../../../sketch-platform/ui/routing';

class MainViewLink extends PureComponent {

  static contextTypes = {
    models: PropTypes.object.isRequired,
    routeHandler: PropTypes.object.isRequired,
    routeHandlers: PropTypes.array
  }

  constructor(props, context) {
    super(props, context);

    this.preventClick = false;
  }

  handleClick(e) {
    if (this.preventClick) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.preventClick = true;
    setTimeout(() => {
      this.preventClick = false;
    }, 483);

    if (typeof this.props.onClick === 'function') {
      this.props.onClick(e, this.props);
    }
  }

  render() {
    let to;
    if (this.props.resolver) {
      to = this.props.resolver(this.context.models);
    } else {
      to = this.props.to;
    }
    let params = this.props.params || {};
    let query = this.props.query;
    if (to) {
      try {
        const href = to.makePath(params, query);
        const routeHandlers = this.context.routeHandlers;
        if (routeHandlers && _.find(routeHandlers, function(routeHandler) {
          return to._regex === _.get(routeHandler, 'route._regex');
        })) {
          return React.createElement(Link, _.assignIn(_.omit(this.props, ["resolver", "to", "params", "query", "startTime", "episode", "responsiveButton", "replaceHistory","model","notPrimaryButton","flatButton","videoId","episodeId","ariaLabel"]), {href, onClick: this.handleClick.bind(this)}), this.props.children);
        } else if (href) {
          return React.createElement('a', _.extend(_.omit(this.props, ["resolver","to","params","query","onAfterNavigation"]), {href}), this.props.children);
        }
      } catch(e) {
        const URL = (this.context.routeHandler) ? this.context.routeHandler.path : "";
        e.message += " with class "+ (this.props.className || "") + ". Current URL: " + URL;
        throw e;
      }
    }
    if (this.props.href) {
      return React.createElement('a', _.omit(this.props, ["resolver","to","params","query","onAfterNavigation"]), this.props.children);
    }
    return React.createElement('span', _.omit(this.props, ["resolver","to","params","query","onAfterNavigation"]), this.props.children);
  }
}

export default MainViewLink;
