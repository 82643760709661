import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import webApp from '../../../../utils/exdioWebAppUtils'

/** ButtonPC Component */
const ButtonPC = ({ watchAvailableStatus = 3, ...props }, context) => {
  const isLoggedIn = webApp.utils.isLoggedIn(context)

  switch (watchAvailableStatus) {
    case 0:
      return <StyledSpan {...props} />

    case 1:
      if (isLoggedIn) {
        return <StyledSpan {...props}>購入して再生</StyledSpan>
      }
      return <StyledSpan {...props}>ログイン</StyledSpan>

    case 2:
      if (isLoggedIn) {
        return <StyledSpan {...props} />
      }
      return <StyledSpan {...props}>ログイン</StyledSpan>
    default:
      return null
  }
}

export default ButtonPC

ButtonPC.propTypes = {
  /** 視聴権フラグ */
  watchAvailableStatus: PropTypes.oneOf([0, 1, 2, 3])
}

ButtonPC.contextTypes = {
  models: PropTypes.shape({
    authContext: PropTypes.shape({
      data: PropTypes.object
    })
  })
}

const StyledSpan = styled.span`
  padding-top: 170px;
  width: 144px;
  height: 144px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_pc.svg)
    no-repeat center center;
  display: block;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
`
