import React, { Component } from 'react'
import axios from 'axios'

/* style */
import styled from 'styled-components'

export default class GetSvg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      svg: null
    }
  }

  componentDidMount() {
    const { src } = this.props

    if (src.indexOf('.svg') > -1) {
      axios
        .get(src)
        .then(
          function(res) {
            this.setState({ svg: res.data })
          }.bind(this)
        )
        .catch(
          function(err) {
            console.error(err)
          }.bind(this)
        )
    }
  }

  render() {
    const { className } = this.props
    const { svg } = this.state
    return svg ? (
      <StyledIcon>
        <StyledSpan
          dangerouslySetInnerHTML={{ __html: svg }}
          className={className}
        />
      </StyledIcon>
    ) : (
      <StyledIcon>
        <img {...this.props} />
      </StyledIcon>
    )
  }
}

const mediaSp = '(max-width: 1024px)'
const StyledIcon = styled.i`
  display: inline-block;

  @media ${mediaSp} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @media ${mediaSp} {
    object-fit: contain;
  }
`
