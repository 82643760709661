import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Footer from '../../../common/components/Footer';
import DFPBanner from '../../../common/components/DFPBanner';
import NotFound from '../../../generic/components/errors/NotFound';
import webApp from '../../utils/exdioWebAppUtils';
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation';
import HeaderNewsComponent from './HeaderNewsComponent'

/** 静的ページ */
export default class StaticPageContent extends Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  };

  static getPath(_models, options, _props) {
    const { routeHandler } = options;
    const url = routeHandler.path;
    return ['page', url];
  }

  static getPrefetchPaths(models, options, props) {
    return [StaticPageContent.getPath(models, options, props)];
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const page = _.get(prefetchResult, ['json', ...StaticPageContent.getPath(models, options, props)]);
    return { title: page.name };
  }

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.config = context.models.config.data;

    const path = StaticPageContent.getPath({}, { routeHandler: context.routeHandler }, props);
    const page = this.model.getSync(path) || null;

    this.state = {
      page,
      isNotFound: false
    };
  }

  componentDidMount() {
    this.fetchData()
      .then(() => this.updateMetaTags())
      .catch(e => webApp.utils.handleFalcorError(e, this.context));

    // GTMで使用する静的ページのイベントを入れる
    let gtm_tags = [
      {key: 'event', value: 'staticPageChange'},
      {key: 'genre',  value: 'cu'}
    ];
    webApp.utils.updateDataLayer(gtm_tags);
    webApp.utils.updateCookieSync(window.navigator.userAgent);
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags() {
    const { page } = this.state;
    const title = page.name;
    webApp.utils.updateTitle(title);

    const { copyright } = this.config;
    const description = page.description || this.config.description;
    const keywords = page.keywords || this.config.keywords;
    const url = window.location.href;
    const regularUrl = url.replace(/\?.*$/, '');
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: regularUrl }]
    };
    webApp.utils.updateMeta(metaTags);
  }

  fetchData() {
    // 念のため削除
    const scriptTags = document.querySelector('#static-page-content script');
    if (scriptTags) Array.from(scriptTags).forEach(e => e.remove());

    const url = this.context.routeHandler.path;
    const path = ['page', url];
    return this.model.fetch([path]).then(result => {
      const page = _.get(result, ['json', ...path]) || null;
      this.setState({ page, isNotFound: !page || !page.innerHTML }, () => {
        // ブラウザバック時にscriptが走らないので、クライアント側で明示的にscriptタグの追加を行いscriptを実行する
        if (!page) return;
        const divElem = document.getElementById('static-page-content');
        if (!divElem) return;
        const scriptElem = document.createElement('script');
        scriptElem.innerHTML = page.script;
        divElem.appendChild(scriptElem);
      });
    });
  }

  render() {
    const { page, isNotFound } = this.state;
    if (isNotFound) return <NotFound />;
    if (!page) return null;

    return (
      <div className="common-wrapper">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />
        <div id="static-page-content">
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
          {page.style && <style>{page.style}</style>}
        </div>
        <Footer className="mt0" />
      </div>
    );
  }
}
