import React, { memo } from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import { paletteAddAd } from '../functions'

/* components */
import Palette from '../../Palette'
import CardWithAd from '../CardWithAd'
import DFPBanner from '../../../../../common/components/DFPBanner'
import HtmlSnippet from '../../../../../exdio/components/HtmlSnippet'

const PalettesWithAd = (
  {
    palettes = [],
    howToPlays = {},
    products = null,
    courses = null,
    canvasConfig = {},
    loaded = false
  },
  context
) => {
  const config = context.models.config.data
  const {
    ad_idx_side,
    ad_idx_banner,
    twitter,
    large_idx,
    palette_header
  } = canvasConfig

  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isApp = webApp.utils.isApp(context)
  const adIdxBanner = ad_idx_banner || 4
  const adIdxSide = ad_idx_side || 3
  const rowCountWithAd = twitter ? 2 : 0
  const fixedBnr = config.canvas_snippet.fixed

  /**
   * 横向きバナーを挿入
   * configのad_idx_banner, ad_idx_sideで指定した行に広告挿入
   */
  const paletteWithAd = paletteAddAd(palettes, adIdxBanner)

  /** 横位置バナー + Twitter */
  const adPalettes = paletteWithAd.slice(
    adIdxSide,
    adIdxSide + rowCountWithAd + 1
  )
  paletteWithAd.splice(adIdxSide, rowCountWithAd + 1, {
    id: adIdxSide,
    type: 'card_with_ad'
  })

  return paletteWithAd.map((palette, i) => {
    switch (palette.type) {
      case 'card_with_ad':
        return (
          <CardWithAd
            key="ad-row"
            palettes={adPalettes}
            howToPlays={howToPlays}
            products={products}
            courses={courses}
            canvasConfig={canvasConfig}
            loaded={loaded}
          />
        )

      case 'flex_bnr':
        if (!isLoggedIn && !isApp && loaded) {
          return (
            <div key="bnr-flex" className="bnr-flex">
              <HtmlSnippet snippetId={fixedBnr} />
            </div>
          )
        }
        return null

      case 'dfp_bnr':
        return loaded && <DFPBanner key="banner" position="head" meta_id="" />

      default:
        return (
          <Palette
            key={palette.palette_id}
            palette={palette}
            howToPlays={howToPlays}
            large={(large_idx || []).includes(i + 1)}
            products={products}
            courses={courses}
            paletteHeader={palette_header !== false}
            loaded={loaded}
          />
        )
    }
  })
}

export default memo(PalettesWithAd)

PalettesWithAd.propTypes = {
  palettes: PropTypes.array,
  howToPlays: PropTypes.object,
  products: PropTypes.object,
  courses: PropTypes.object,
  canvasConfig: PropTypes.shape({
    ad_idx_banner: PropTypes.number,
    ad_idx_side: PropTypes.number,
    large_idx: PropTypes.arrayOf(PropTypes.number),
    twitter: PropTypes.bool,
    ad: PropTypes.bool,
    palette_header: PropTypes.bool
  }).isRequired
}

PalettesWithAd.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}
