import React from 'react'
import PropTypes from 'prop-types'
import Link from '../../../../../sketch-platform/ui/routing/Link'

/** style */
import styled, { css } from 'styled-components'
import {
  mediaQuery,
  fixColors,
  keyColors
} from '../../../../exdio/components/style'

/** ヘッダー > ハンバーガーメニュ > ボタン共通 */
const Btn = ({
  route = {},
  query = {},
  onClick = () => {},
  type = 'default',
  children = '',
  state = {},
  ...props
}) => {
  return (
    <StyledDiv type={type} {...props}>
      <Link route={route} query={query} onClick={onClick} state={state}>
        {children}
      </Link>
    </StyledDiv>
  )
}

export default Btn

Btn.propTypes = {
  //** LinkコンポーネントのonClick */
  onClick: PropTypes.func,
  //** Linkコンポーネントのroute */
  route: PropTypes.object,
  //** Linkコンポーネントのparams */
  params: PropTypes.object,
  //** Linkコンポーネントのquery */
  query: PropTypes.object,
  //** Linkコンポーネントのstate */
  state: PropTypes.object,
  /** ボタンのスタイルタイプ */
  type: PropTypes.oneOf(['regist', 'login', 'charge', 'default'])
}

const StyledDiv = styled.div`
  width: 170px;
  font-size: 1.5rem;
  font-weight: 600;
  ${mediaQuery('ss')} {
    width: 144px;
  }
  a {
    display: block;
    padding: 1em;
    border-radius: 4px;
    text-align: center;
    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }

    &:visited {
      color: #fff;
    }
  }

  ${({ type }) => {
    switch (type) {
      case 'regist':
        return css`
          margin-right: 7px;
          a {
            background-color: ${fixColors.colorMain};
            border-radius: 5px;
          }
          a:visited {
            color: ${fixColors.colorFont};
          }
        `
      case 'login':
        return css`
          margin-left: 7px;
          a {
            background-color: ${fixColors.colorBk};
            color: #fff;
            border-radius: 5px;
          }
        `
      case 'charge':
        return css`
          margin-left: 1.8rem;
          margin-bottom: -3px;
          font-size: 1.1rem;
          width: 80px;
          a {
            background-color: ${fixColors.colorMain};
            padding: 0.7em;
            border-radius: 5px;
            color: #fff;
          }
        `
      default:
        return css`
          a {
            background-color: ${keyColors.color_1};
          }
        `
    }
  }}
`
