import React, { memo } from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../../../utils/exdioWebAppUtils'
import { DIO_PLAYER_DEVICE } from '../../../../../../../../constants/app'

/** style */
import styled from 'styled-components'
import { yellow } from '../../../styles'

/** SP タブ切り替えモーダル */
const SpNav = ({
  isShow = false,
  tabsData = [],
  selectedTabId = null,
  setSelectedTabId = () => {},
  setSelectedTags = () => {},
  setIsModalOpen = () => {},
  ...props
}) => {
  const isAndroid = webApp.utils.getDevice() === DIO_PLAYER_DEVICE.ANDROID

  const onClickHandler = (id) => {
    setSelectedTabId(id)
    setIsModalOpen(false)
    /** 選択中のタグをリセット */
    setSelectedTags([])
  }

  return (
    <StyledWrapper
      isShow={isShow}
      onClick={() => setIsModalOpen(false)}
      {...props}
    >
      <StyledUl>
        {tabsData.map((tab) => {
          const { id, title, html } = tab
          return (
            <StyledLi key={id}>
              <StyledButton
                type="button"
                isChecked={id === selectedTabId}
                onClick={() => onClickHandler(id)}
              >
                <StyledSpan
                  html={html ? true : false}
                  isAndroid={isAndroid}
                  dangerouslySetInnerHTML={{ __html: html || title }}
                />
              </StyledButton>
            </StyledLi>
          )
        })}
      </StyledUl>
    </StyledWrapper>
  )
}

export default memo(SpNav)

SpNav.propTypes = {
  /** 表示制御 */
  isShow: PropTypes.bool,
  /** それぞれのタブの内容 */
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      /** id */
      id: PropTypes.string,
      /** 見出し画像alt */
      title: PropTypes.string
    })
  ),
  /** 選択中のタブID */
  selectedTabId: PropTypes.string,
  /** 選択中のタブID変更 */
  setSelectedTabId: PropTypes.func,
  /** 選択中のタグ変更 */
  setSelectedTags: PropTypes.func,
  /** モーダル開閉状況変更 */
  setIsModalOpen: PropTypes.func
}

const StyledWrapper = styled.div`
  background-color: rgba(25, 168, 217, 0.9);
  display: ${({ isShow }) => (isShow ? null : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const StyledLi = styled.li`
  &:not(:first-child) {
    margin-top: 20px;
  }
`

const StyledSpan = styled.span`
  padding-left: 18px;
  height: 48px;
  display: inline-flex;
  align-items: baseline;
  position: relative;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 48px;

  &::before {
    margin: auto 0;
    width: 18px;
    height: 16.5px;
    background: url(/images/exdio/renewal/doraemon/tab_arrow.png) no-repeat
      center / 100%;
    position: absolute;
    top: ${({ isAndroid }) => (isAndroid ? '50%' : '0')};
    bottom: 0;
    left: -7px;
    transform: rotate(-90deg)
      ${({ isAndroid }) => (isAndroid ? 'translateX(10px)' : '')};
  }
`

const StyledButton = styled.button`
  padding: 0;
  border-width: 0;
  background: none;
  color: ${({ isChecked }) => (isChecked ? yellow : '#fff')};
  font-family: 'M PLUS Rounded 1c', sans-serif;

  ${StyledSpan} {
    &::before {
      content: ${({ isChecked }) => (isChecked ? '""' : 'none')};
    }
  }

  path {
    fill: ${({ isChecked }) => (isChecked ? yellow : null)};
  }
`
