import React, { forwardRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

/** style */
import { StyledUl1, StyledLi1, StyledLabel, StyledRadio } from './styles'
import useMediaQuery from '../../../../../hooks/useMediaQuery'

/** EpisodeLiveGariben SPタブ */
const Tabs = (
  {
    hasVote = false,
    hasChat = false,
    hasComment = false,
    isLoggedIn = false,
    currentTabId = '',
    setCurrentTabId = () => {},
    ...props
  },
  ref
) => {
  const isSp = useMediaQuery()

  /**
   * タブ初期値
   * PC: 関連動画
   * SP: チャット
   */
  const getInitialTabId = () => {
    if (!isSp) return ''
    return hasChat ? 'chat' : ''
  }

  /** タブの変更 */
  const changeCurrentTab = (e) => {
    const value = get(e, ['target', 'value'])
    setCurrentTabId(value)
    window.scrollTo(0, 0)
  }

  /** useEffect */
  useEffect(() => {
    /** タブの初期化 */
    setCurrentTabId(getInitialTabId())
  }, [])

  useEffect(() => {
    /** PC/SPが入れ替わったとき、表示フラグが変更されたとき初期化 */
    setCurrentTabId(getInitialTabId())
  }, [isSp])

  return (
    <StyledUl1 ref={ref} {...props}>
      <StyledLi1>
        <StyledRadio
          type="radio"
          name="currentTab"
          value=""
          id="fSummary"
          checked={currentTabId === ''}
          onChange={changeCurrentTab}
        />
        <StyledLabel htmlFor="fSummary">
          {!isLoggedIn && (
            <>
              ログイン
              <br />
            </>
          )}
          関連動画
          {isLoggedIn && (
            <>
              <br />
              プレゼント
            </>
          )}
        </StyledLabel>
      </StyledLi1>
      {hasVote && (
        <StyledLi1>
          <StyledRadio
            type="radio"
            name="currentTab"
            value="vote"
            id="fVote"
            checked={currentTabId === 'vote'}
            onChange={changeCurrentTab}
          />
          <StyledLabel htmlFor="fVote">投票</StyledLabel>
        </StyledLi1>
      )}
      {hasChat && (
        <StyledLi1>
          <StyledRadio
            type="radio"
            name="currentTab"
            value="chat"
            id="fChat"
            checked={currentTabId === 'chat'}
            onChange={changeCurrentTab}
          />
          <StyledLabel htmlFor="fChat">チャット</StyledLabel>
        </StyledLi1>
      )}
      {hasComment && (
        <StyledLi1 hidePc>
          <StyledRadio
            type="radio"
            name="currentTab"
            value="comment"
            id="fComment"
            checked={currentTabId === 'comment'}
            onChange={changeCurrentTab}
          />
          <StyledLabel htmlFor="fComment">コメント</StyledLabel>
        </StyledLi1>
      )}
    </StyledUl1>
  )
}

export default forwardRef(Tabs)

Tabs.propTypes = {
  hasVote: PropTypes.bool,
  hasChat: PropTypes.bool,
  hasComment: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  currentTabId: PropTypes.oneOf(['', 'vote', 'chat', 'comment']),
  setCurrentTabId: PropTypes.func
}
