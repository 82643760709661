import styled from 'styled-components'
import { badgeBlog, badgeMovie, white } from '../../styles'

export const StyledImg = styled.img`
  width: 100%;
`

export const StyledDiv = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
  font-weight: bold;
`

export const StyledP1 = styled.p`
  margin-left: auto;
  padding: 5px 10px;
  border-radius: 10px;
  color: ${white};
  line-height: 1;

  background-color: ${({ type }) => {
    switch (type) {
      case '動画':
        return badgeMovie
      case 'ブログ':
        return badgeBlog
      default:
        break
    }
  }};
`

export const StyledP2 = styled.p`
  margin-top: 5px;
  width: 100%;
`
