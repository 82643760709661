import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import routes from '../routes'
import webApp from '../../exdio/utils/exdioWebAppUtils'

/* hooks */
import useIsMounted from '../../hooks/useIsMounted'

/* components */
import GlobalNavigation from './GlobalNavigation'
import Link from '../../../sketch-platform/ui/routing/Link'

/** style */
import {
  mediaQuery,
  fixColors,
  breakPoints
} from '../../exdio/components/style'

/** ヘッダー */
const Header = ({ path = '' }, context) => {
  const isMounted = useIsMounted()
  const isApp = webApp.utils.isApp(context)
  const homeRoute = routes[isApp ? 'app_home' : 'home']
  const logoSrc = isApp
    ? '/images/exdio/renewal/icon_go.png'
    : '/images/exdio/renewal/logo_tv_asahi_douga.png'

  if (!isMounted) return null

  return (
    <StyledHeader id="mp-header">
      <StyledDiv1 isApp={isApp}>
        <StyledDiv2 isApp={isApp}>
          <StyledLink route={homeRoute} isApp={isApp}>
            <img src={logoSrc} alt="テレ朝動画" />
          </StyledLink>
          {isApp && (
            <a className="gHead-nav-in-btn-link catchup" href="/app/catchup">
              <img
                src="/images/exdio/renewal/btn_catchup.png"
                className="gHead-nav-in-btn-link-img"
                alt=""
              />
            </a>
          )}
        </StyledDiv2>
        <GlobalNavigation path={path} />
      </StyledDiv1>
    </StyledHeader>
  )
}

export default memo(Header)

Header.propTypes = {
  /** 現在のURL */
  path: PropTypes.string
}

Header.contextTypes = {
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  })
}

const StyledHeader = styled.header`
  width: 100%;
  background: linear-gradient(to right, #fff 50%, ${fixColors.colorMain} 50%);
  position: sticky;
  top: 0;
  z-index: 10;
`

const StyledDiv1 = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isApp'].includes(prop)
})`
  margin: 0 auto;
  max-width: ${breakPoints.mx}px;
  height: 74px;
  background-color: ${fixColors.colorMain};
  display: grid;
  grid-template-columns: auto auto 1fr;
  position: relative;

  ${({ isApp }) =>
    isApp
      ? css`
          margin: 0;
          max-width: none;
          height: 56px;
          grid-template-columns: 1fr auto;
        `
      : css`
          ${mediaQuery()} {
            height: 56px;
            grid-template-columns: auto auto;
          }

          ${mediaQuery(374)} {
            grid-template-columns: 189px 1fr;
          }
        `}
`

const StyledDiv2 = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isApp'].includes(prop)
})`
  padding-right: 74px;
  /*
  * 遅延防止の為base64エンコーディング
  * /images/exdio/renewal/head_back_arrow_pc.png
  */
  background: #fff
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAABKCAYAAAAc0MJxAAAAAXNSR0IArs4c6QAABItJREFUeAHlmz1oFEEUx2fuEmM0xpwoFgqKCAoiiCCCKISAKAiCaCEICkIKwcLCUtJYWlgIgmIRUAS/UBS/QCSJUbGxsbGwsLAQLEw0oCh34+9lb8lly7udmTfxQZiPY3fe/vY/b+cr1rRpzg2cN8aea/Py5C6znXgMrIvAOtPJPVK5tiNQ8pDO1S6TnErlgdv1s2NQ0vD/AKskUIb7DFyjG55s941pv64UUJmqZmFdB9Yx7Q/djn+lgWrCqqCsW8A60o4zmq/hwcoza03DmKmjRK275d1Vx51KVVT+SM6ZLpR1D2UdzOtST72AEigZrNojsvtThyT+ewMlNwfWIpT1lGaGpJyylRqjiiCIWX+IWQdA9rL4W2plr4rKYaCsJcbUnlPendellgYBJVCasERZO1ODJP4GAyWNAauPmDVOs9ulnJJ5jVFFEMSsmSywu/fF37SXgyoqh+Fc/wpjqmOUt+Z12tMooARKE9Zrspu1QxL/ooGSxp3rW2VM1xvc2ChlzRY0RhVBWDvzzZi/e0D2qfibtnJUReUwnOtda0zPK5S1Pq/TlqoAJVAyWIvfkgWaPova9VpxWPvrC7johuZra72WvBpQAsTaqc/G1FXCUtP1WpXj3DKGDNUJ0PFV1GEqQQmaJizGWZbBaXxT1fVacVj78yOQBqmbbq2PlVcLSoBY+/2DMY0hstFhqQaVwZpmAl3fR54JdTxTG6OKSJgbso5VlfUsFgHDm3pF5Uis/fGOvCjrd14XMk0GlEAhZk0Ss1iDDw8rKVAZrGm6X/0QAwg2LsJZMjGqiISYxX5hhX1Dy2arf0tOUTkSYtYz8odRVj2v85kmC0qgMDd8CCg56+AdVtKgMljTHAixJ4DV8KmoZGNUEQqn/jiX5eR8lpdnSl5Rc8AaXgeiCwIUajrNF/CqLzXJy0gelHPLz/Icl+aU5SeXNCjOuY/wri/4QTP/rl4C3/wm/JTobgJI1BTEgoxqy34SlCRdjbgUzpJSFKdh8Ld2BTzD4RBlLSWjqAzSwChuHw8NSdpLQlFAqqKkG/jLdCWOqVcUkPBx4DZ4WFqJZ6oVBaRulHQfPLJYF9XUKgpIPSiJ9SazNyqhZuMqFQWkXiA9IYQOaoAkPqgDBSQOxM4etd6lBZL4oarrAakfJb3Arx2aIIkvahTF5LaGO2wc2G3aIIk/KhTFgYyVTG7H8GeLOKXRooNybulq3tc4cDZpBJT7FBUUxxHXGNM9gTMbcoe0ptFiFCsA64CCkqyk6i2KogjcKMiKklBUGlYJ7SaBm1hkOYSfDiRhFBQU/6nAV606CSgCeFoWLEYRk2R8JOMkxkvpWRBFcaCCkbaVwJ0kJHmt3kERk5izzZ6UY3qSrnntenS3QVTEKoCsBqRt3hTFEEDWkeRoTvKQ5BV7AcWeGyuS9jF/LL4tDCsdFN2NtW33AEgs4y4cKxUUXzfZJbkDpCgjfp+vpTRQKIn9tspNILG1tPCsFFAE7mHQjALJ61c0Jv6OQYU4mxQTUN52R6BQUpCzSbmzMdO2QRGTRohJF2I6H7Ltfwii8MS0q/lWAAAAAElFTkSuQmCC)
    no-repeat center right / auto 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  ${mediaQuery()} {
    padding-right: 34px;
    padding-left: ${({ isApp }) => (isApp ? null : '15px')};
    width: ${({ isApp }) => (isApp ? null : '203px')};
    /*
    * 遅延防止の為base64エンコーディング
    * /images/exdio/renewal/head_back_arrow_sp.png
    */
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAA4CAYAAACYCio/AAAAAXNSR0IArs4c6QAAAlJJREFUWAnNmTtLQzEYhpNaEUGt/TtOLoLgooMgDm7dHB3dHB2ddOokgoMugqKD/8jLIuIlvp/p16b2nNPcY6AkDaf5nrxvTpImUqnlQyHkkSicJMVXqnMgROu4JMsvyH+AGYJomO4+8pMSyoyBlISZACkFUwmiYTo9DOCzXDbVguSGaQQZwchTzDVTnw1Rz6pxTHp7CNJPCWMFopVJC2MNkhrGCUTDLO3gbTqPbZMzSCqYFjXsmqR8vRDiexfL5Zfrb+ue91KEG8PbtIXyJWya4Trf3EsRDibl8xXK21Dmk+t88yBFOKhS3Q3AXEOZNte55lFAKGgoTJA1Zq+lfLqBIptA+jDrbcvRFOGA2HauAYigZrnOJo8OQkF9YKJZY/Zaypd74NAAfjfrm8pJFOGAmGdWUb6FTXNcV5cnBaGgtjBJrDF7jUnvEd/X8Xkz6/+WkyvCAZVaXBGi/YDv81xn5tlAKGgTTHJrzF4PypUrdjZFBmrcAWahAk5kAWmyhKGSW6Nf3/pByiDeyzY30JSP5hBRbkIbQUyfVakzSazRi57d1M6KRh+sPitvdEX0Ls19L0Ig0RQZbBWxmXbbEEW1JnS/GsUavB34bxO2gw+2RkPE+YPlPUZiQnhboxSdCMRRgiAoOSuiIeIfSzjNrLADR1jxIZwU0RDpztGsrEkNQYpMtQZrRw/P9TGcrKCpUZ/U2LiGyHP6XAuSE4IUrLQGaweuS/IowTZOKKIh8t/ZjCkCO3Cllh+CVBkqoiHK3ev9gpSGIEVamKxw3VpOCYKg9AOfebP8/TPHNAAAAABJRU5ErkJggg==);
  }
`

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['isApp'].includes(prop)
})`
  padding: ${({ isApp }) => (isApp ? '0 6px' : null)};
  max-width: ${({ isApp }) => (isApp ? '72px' : '207px')};
  display: inline-block;

  ${mediaQuery()} {
    max-width: ${({ isApp }) => (isApp ? null : '154px')};
  }

  @media (hover: hover) {
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.6;
    }
  }
`
