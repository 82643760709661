import React, { memo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ProgramItemLink from '../../ProgramItemLink'
import { META_SCHEMA_ID } from '../../../../../../constants/app'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'

/** dsearch結果 */
const DsearchMetas = ({ metas = [], howToPlays = {}, ...props }, context) => {
  if (!(metas && metas.length > 0)) return null

  return metas.map((meta) => {
    const id = meta.id || meta.meta_id
    const showRemaining = [META_SCHEMA_ID.EPISODE, META_SCHEMA_ID.LIVE].indexOf(
      meta.meta_schema_id
    )
    const linkParams = webApp.utils.getProgramLinkRoutes(context, meta)

    return (
      <ProgramItemLink
        key={id}
        meta={meta}
        howToPlay={howToPlays[id]}
        showRemaining={showRemaining}
        showDeadLine
        showDelivery
        showInCourse
        showCoin
        showNew={webApp.utils.showNew(meta)}
        breakSubTitle
        {...linkParams}
        {...props}
      />
    )
  })
}

export default memo(DsearchMetas)

DsearchMetas.propTypes = {
  /** 検索結果 */
  metas: PropTypes.array
}

DsearchMetas.contextTypes = {
  history: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object
}
