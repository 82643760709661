import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/** 動画リンク: 動画説明欄(タイトル、サブタイトル、あらすじ) */
const Caption = ({ title, subTitle, synopsis, className }) => {
  return (
    <StyledFigcaption className={className}>
      {title && <StyledP1>{title}</StyledP1>}
      {subTitle && <StyledP2>{subTitle}</StyledP2>}
      {synopsis && <StyledP3>{synopsis}</StyledP3>}
    </StyledFigcaption>
  )
}

export default Caption

Caption.propTypes = {
  /** 動画タイトル(avails_SeriesTitleDisplayUnlimited avails_SeasonTitleDisplayUnlimited) */
  title: PropTypes.string,
  /** 動画サブタイトル(avails_EpisodeTitleDisplayUnlimited) */
  subTitle: PropTypes.string,
  /** 動画あらすじ(evis_EpisodeLongSynopsis) */
  synopsis: PropTypes.string
}

Caption.defaultProps = {
  title: '',
  subTitle: '',
  synopsis: ''
}

const StyledFigcaption = styled.figcaption`
  width: 100%;
  position: relative;
  flex: 1 0;
  cursor: pointer;
`

const StyledP1 = styled.p`
  font-size: 1.3rem;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;

  @media (max-width: 768px) {
    -webkit-line-clamp: 2;
  }
`

const StyledP2 = styled(StyledP1)`
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    margin-top: 0.8rem;
  }
`
const StyledP3 = styled(StyledP1)`
  margin-top: 0.5rem;
  line-height: 1.4;
  font-size: 1.2rem;
  font-weight: 400;
  -webkit-line-clamp: 3;

  @media (max-width: 768px) {
    display: none;
  }
`
