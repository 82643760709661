import styled from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'
import { breakPoints } from '../../style'

export const StyledDiv = styled.div`
  margin: 0 auto;
  width: 1280px;

  ${mediaQuery(breakPoints.tab)} {
    width: 960px;
  }
`

export const StyledUl = styled.ul`
  overflow: hidden;
`
