import React from 'react'
import PropTypes from 'prop-types'
import { EPISODE_DISPLAY_MODE } from '../../../../../../constants/app'
import { getPrice } from '../functions'
import AboutMedalLink from '../../../../../common/components/renewal/AboutMedalLink'

/**
 * Price Component
 * AboutMedalLinkのスタイルを反映するため、class名を残している
 *
 * // TODO
 * AboutMedalLinkと合わせてstyled-components化
 */
const Price = ({
  meta = {},
  status = {},
  product = {},
  course = {},
  ...props
}) => {
  const {
    isFree,
    isPurchseAvailable: isPurchaseAvailable, // 誤字なので置き換え
    displayMode
  } = status

  const {
    FREE,
    TVOD_FREE,
    TVOD_NOT_FREE,
    SVOD_FREE,
    SVOD_NOT_FREE,
    STVOD_FREE,
    STVOD_TVOD_NOT_FREE,
    STVOD_SVOD_NOT_FREE,
    UNKNOWN
  } = EPISODE_DISPLAY_MODE

  const [priceOfEpisode, priceOfCourse] = getPrice(
    meta,
    status,
    product,
    course
  )

  // 有料コンテンツで、内部設定が完了していないものは出さない
  if (!isFree && !isPurchaseAvailable)
    return <div className="inBox">料金：-</div>

  switch (displayMode) {
    case FREE:
    case TVOD_FREE:
    case SVOD_FREE:
    case STVOD_FREE:
      return (
        <div className="c-storyMeta-inBox-meta-price free" {...props}>
          料金：
          <span className="c-storyMeta-inBox-meta-price-coin">
            {priceOfEpisode}
          </span>
          <AboutMedalLink linkClassName="c-storyMeta-inBox-meta-price-aboutCoin" />
        </div>
      )

    case TVOD_NOT_FREE:
      return (
        <div className="c-storyMeta-inBox-meta-price" {...props}>
          料金：
          <span className="c-storyMeta-inBox-meta-price-coin">
            {priceOfEpisode}
          </span>
          <AboutMedalLink linkClassName="c-storyMeta-inBox-meta-price-aboutCoin" />
        </div>
      )

    case STVOD_TVOD_NOT_FREE:
      return (
        <div className="c-storyMeta-inBox-meta-price" {...props}>
          料金：
          <span className="c-storyMeta-inBox-meta-price-coin">
            {priceOfEpisode}
          </span>
          <span className="c-storyMeta-inBox-meta-price-or">or</span>
          <span className="c-storyMeta-inBox-meta-price-stvod">
            月額 {priceOfCourse}円
          </span>{' '}
          (税込)
          <AboutMedalLink linkClassName="c-storyMeta-inBox-meta-price-aboutCoin" />
        </div>
      )

    case SVOD_NOT_FREE:
    case STVOD_SVOD_NOT_FREE:
      return (
        <div className="c-storyMeta-inBox-meta-price" {...props}>
          料金：月額 {priceOfCourse}円 (税込)
        </div>
      )

    case UNKNOWN:
    default:
      return (
        <div className="inBox" {...props}>
          料金：-
        </div>
      )
  }
}

export default Price

Price.propTypes = {
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isPurchseAvailable: PropTypes.bool,
    displayMode: PropTypes.number
  }),
  product: PropTypes.shape({
    product_id: PropTypes.number,
    name: PropTypes.string,
    original_price: PropTypes.number,
    active_pricing: PropTypes.shape({
      price: PropTypes.number,
      unit: PropTypes.string
    })
  }),
  // 月額見放題
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  })
}
