import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '../ListItem/'
import { StyledLi, StyledUl } from './style'

/** Section3｜リスト */
const List = ({ posts = [], ...props }) => {
  return (
    <StyledUl className={`lazyload ${props.className}`}>
      {posts.map((article, i) => (
        <StyledLi key={`article-${i}`}>
          <ListItem article={article} />
        </StyledLi>
      ))}
    </StyledUl>
  )
}

export default List

List.propTypes = {
  /** ブログ情報の配列
   * @param {string} title - ブログタイトル
   * @param {string} link - リンクの遷移先
   * @param {string} thumbnail - ブログのサムネ画像パス
   * @param {string} date_time - ブログ公開日(timeタグのdatetimeに使用)
   * @param {string} date - ブログ公開日
   */
  posts: PropTypes.arrayOf(PropTypes.object),
  /** styleの継承 */
  className: PropTypes.string
}
