import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import { get } from 'lodash'
import webApp from '../../../../utils/exdioWebAppUtils'
import { FAVORITE_TYPE } from '../../../../../../constants/app'
import {
  getPrice,
  isPlayable,
  whatKindOfVideo
} from '../../EpisodeDefault/util'
import { mediaQuery, fixColors } from '../../../style'

/* context */
import { EpisodeContext } from '../context/Provider'

/* components */
import AddButtonBlock from '../../details/AddButtonBlock'
import PlayLink from '../../EpisodeDefault/PlayLink'
import HtmlSnippet from '../../../HtmlSnippet'

/** 動画に対するアクション */
const Actions = (
  {
    onClickPlay = () => {},
    inViewTerm = false,
    metaName = '',
    renderLinkLoginOrMyPage = false,
    renderLinkAppLogin = false,
    isPackOnly = false,
    loaded = false,
    isSummerStation = false,
    programBannerId1 = 0,
    ...props
  },
  context
) => {
  const episodeContext = useContext(EpisodeContext)
  const {
    meta = {},
    product = {},
    products = {},
    status = {},
    course = {},
    season = {}
  } = episodeContext
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isApp = webApp.utils.isApp(context)
  const isFree = whatKindOfVideo(status.displayMode) === 'FREE'

  const todayData = new Date(Date.now())
  let salesEndAt =
    get(product, ['sales_end_at']) || get(products, [0, 'sales_end_at'])

  const isDeadline = (() => {
    // 販売終了期間が設定されていなかったら表示
    if (salesEndAt === null || salesEndAt === undefined) return false

    // 販売終了期間が未来だったら表示
    salesEndAt = new Date(salesEndAt)

    if (todayData < salesEndAt) return false

    return true
  })()

  return (
    <StyledDiv {...props}>
      {isSummerStation && <HtmlSnippet snippetId={programBannerId1} />}

      {(!isApp || isPlayable(status)) && (
        <PlayLink
          meta={meta}
          price={getPrice(meta, product, status, course)}
          product={product}
          status={status}
          onClickPlay={onClickPlay}
          disabled={!inViewTerm}
          renderLinkLoginOrMyPage={renderLinkLoginOrMyPage}
          isPackOnly={isPackOnly}
        />
      )}

      {/* for PC&SP */}
      <AddButtonBlock
        favoriteType={FAVORITE_TYPE.META}
        favoriteId={meta.meta_id}
        title={metaName}
        showLoginButton={isApp && renderLinkAppLogin && !isLoggedIn}
        showAddMyList={isApp && !isPlayable(status)}
        officialUrl={
          meta.schema_id === 8 && season !== null
            ? season.values.evis_OfficialSiteURL
            : null
        }
        isPurchased={status.isPurchased}
        isFree={isFree}
        isLoaded={loaded}
        isDeadline={isDeadline}
        showPurchasedBrowserBtn
      />
      <GlobalStyle />
    </StyledDiv>
  )
}

export default Actions

Actions.propTypes = {
  /** 「視聴ボタン」のクリック時に発火するイベント */
  onClickPlay: PropTypes.func,
  /** falseの時「視聴ボタン」のクリックイベントが無効化される */
  inViewTerm: PropTypes.bool,
  /** twitterシェアボタンのurl作成に使用される */
  metaName: PropTypes.string,
  /** trueの時、ログインもしくはマイページ遷移ボタンが描画される */
  renderLinkLoginOrMyPage: PropTypes.bool,
  /** trueの時、且つApp使用時にログインボタンが描画される */
  renderLinkAppLogin: PropTypes.bool,
  isPackOnly: PropTypes.bool,
  /** データの取得が終わっているか */
  loaded: PropTypes.bool,
  /** サマステのシーズンIDに合致するか */
  isSummerStation: PropTypes.bool,
  /** バナー用スニペット */
  programBannerId1: PropTypes.number
}

Actions.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

const GlobalStyle = createGlobalStyle`
.c-pageBtn {
  text-align: center;

  &.mt10 {
    margin-top: 13px;

    ${mediaQuery()} {
      margin-top: 10px;
    }
  }

  & + .c-addBtns {
    margin-top: 20px;

    ${mediaQuery()} {
      margin-top: 10px;
    }
  }

  .c-pageBtn-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${mediaQuery()} {
      width: 100%;
    }
  }
}

.c-addBtns-inBox-mylist-link {
  background-image: url("/images/exdio/renewal/doraemon/my_list_removed.svg");

  &.added {
    background-image: url("/images/exdio/renewal/doraemon/my_list_added.svg");
  }
}

.c-pageBtn-link-addMylist {
  display: flex;
  align-items: center;

  &::before {
    margin-right: 5px;
    width: 22px;
    height: 22px;
    background: url(/images/exdio/renewal/icon_mylist_add.svg) no-repeat center center;
    display: inline-block;
    content: '';
  }
}

// .appliのclassを削除しているため、元のスタイルが反映されない
.addMylist {
  .c-modal-inBox-cont {
    border-radius: 0;
  }

  &-conts {
    &-head {
      padding: 24px;
      font-weight: 300;
      font-size: 13px;
      line-height: 1.4;
      text-align: left;
    }
  }

  .c-gMenu {
    &-logins {
      &.logins-app-top {
        width: 100%;
        position: fixed;
        bottom: 0;
        background: #fff;
        opacity: 1;
        transition: opacity 0.2s linear 0s, visibility;
        z-index: 5;
      }

      &.none {
        opacity: 0;
        z-index: -100;
      }

      &-in {
        padding: 18px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.app-top-pd {
          padding: 18px 14px 60px;
        }
      }

      &-signup {
        width: 49%;
        margin-right: 0.5%;

        &-link {
          display: block;
          width: 100%;
          height: 48px;
          text-align: center;
          border-radius: 5px;
          background: ${fixColors.colorMain};
          font-weight: bold;
          line-height: 48px;

          &:active,
          &:hover {
            opacity: 0.7;
            transition: 0.3s;
          }
        }
      }

      &-login {
        width: 49%;
        margin-left: 0.5%;

        &-link {
          display: block;
          width: 100%;
          height: 48px;
          text-align: center;
          border-radius: 5px;
          background: #000;
          font-weight: bold;
          line-height: 48px;

          &:link,
          &:hover,
          &:visited,
          &:active {
            color: #fff;
          }
        }
      }
    }
  }
}
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`
