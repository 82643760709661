import React, { memo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import ProgramItemLink from '../../ProgramItemLink'
import { META_SCHEMA_ID, PRODUCT_SCHEMA } from '../../../../../../constants/app'

/** あなたへのオススメ */
const Recommend = ({ metas = [], ...props }, context) => {
  if (!(metas && metas.length > 0)) return null

  return metas.map((item) => {
    const ids = item.ids ? JSON.parse(item.ids) : {}
    const deliveryDateTime = item.delivery_datetime
      ? JSON.parse(item.delivery_datetime)
      : {}
    const deliveryStartAt = (deliveryDateTime.start_at || '').replace(/-/g, '/')
    const deliveryEndAt = (deliveryDateTime.end_at || '').replace(/-/g, '/')
    const meta = {
      ...item,
      delivery_start_at: deliveryStartAt,
      delivery_end_at: deliveryEndAt,
      name: item.program_name,
      values: {
        avails_EpisodeTitleDisplayUnlimited: item.episode_name,
        evis_EpisodeLongSynopsis: item.synopsis,
        parents_series: {
          id: ids.series,
          avails_SeriesTitleDisplayUnlimited: item.program_name
        },
        parents_season: {
          id: ids.season
        },
        duration: item.duration
      }
    }
    const price = item.price ? JSON.parse(item.price) : {}
    const priceTvod = price.tvod
    const refId = item.course_ref_ids ? item.course_ref_ids.split(':')[0] : ''
    const priceSvod = _.get(price, ['svod', refId], null)
    const howToPlay = {
      products: [
        {
          schema_id: PRODUCT_SCHEMA.SINGLE_STORY.id,
          active_pricing: { price: priceTvod }
        }
      ],
      courses: [{ active_pricing: { price: priceSvod } }]
    }
    const linkParams = webApp.utils.getProgramLinkRoutes(context, meta)

    return (
      <ProgramItemLink
        key={meta.meta_id}
        meta={meta}
        howToPlay={howToPlay}
        recommendItem={item}
        showRemaining={
          META_SCHEMA_ID.EPISODE === meta.meta_schema_id ||
          META_SCHEMA_ID.LIVE === meta.meta_schema_id
        }
        showDeadLine
        showDelivery
        showDeliveryEndPriorToStart
        showCoin
        showNew={webApp.utils.showNew(meta)}
        breakSubTitle
        {...linkParams}
        {...props}
      />
    )
  })
}

export default memo(Recommend)

Recommend.propTypes = {
  /** おすすめ */
  metas: PropTypes.array
}

Recommend.contextTypes = {
  history: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object
}
