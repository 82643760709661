import styled from 'styled-components'
import { mediaQuery } from '../../../../style'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

export const StyledWrapper = styled.div`
  position: relative;
`

export const StyledAlert = styled.div`
  padding: 15px 12px;
  width: 375px;
  background-color: #ffff11;
  border-radius: 5px;
  display: ${(props) => (props.isShow ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: -100%;
  z-index: 22;
  transform: translateX(-50%);
  cursor: pointer;

  ${mediaQuery()} {
    width: 300px;
  }
`

export const StyledLink = styled(Link)`
  display: block;
  color: #343434;
  font-size: 1.4rem;
  font-weight: 600;
  transition: opacity 0.4s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`

export const StyledAlertButton = styled.button`
  padding: 12px 10px;
  background: no-repeat
    url(/images/exdio/renewal/icon_notification_close_btn.svg) center;
  border-width: 0;
`

export const StyledButton = styled.button`
  padding: 0;
  background: none;
  border-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  transition: opacity 0.4s ease-in-out;
  color: #343434;

  &:hover {
    opacity: 0.7;
  }

  &::after {
    margin: 0 auto;
    width: 38px;
    height: 38px;
    display: block;
    background: url(/images/exdio/renewal/gariben_daigaku/episode/icon/mylist_on.svg)
      no-repeat center / 38px auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    content: ${(props) => (props.isMyListEpisode ? '""' : 'none')};
  }
`

export const StyledImg = styled.img`
  margin-bottom: 5px;
  margin-right: 0;
  margin-left: 0;
  display: inline-block;
`
