import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledDiv } from './style'

/** Thumbnail Component */
const Thumbnail = ({ src = '', alt = '', ...props }) => {
  return (
    <StyledDiv {...props}>
      <img src={src} alt="" />
    </StyledDiv>
  )
}

export default Thumbnail

Thumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
}
