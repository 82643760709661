import React, { useState, useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import { get, size, includes } from 'lodash'
import styled, { css, createGlobalStyle } from 'styled-components'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  LOCAL_STORAGE_KEY_SHINCHAN_PAGER,
  LOCAL_STORAGE_KEY_SHINCHAN_SORT,
  LOCAL_STORAGE_KEY_SHINCHAN_FILTER
} from '../../../../../constants/app'
import { SEASON_SLUG, PANEL_ID } from '../plan_shinchan/config'
import browserInfo from '../../../../../sketch-platform/utils/browserInfo'
import useIsMounted from '../../../../hooks/useIsMounted'

/** hooks */
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/** components */
import HtmlSnippet from '../../HtmlSnippet'
import AnnounceModal from '../../../../common/components/renewal/AnnounceModal'
import Footer from '../../../../common/components/Footer'
import AppFooter from '../../../../common/components/appli/StaticFooter'
import MainVisual from '../plan_shinchan/MainVisual'
import NewArrivals from '../plan_shinchan/NewArrivals'
import ShinchanFooter from '../plan_shinchan/Footer'
import FilterSort from '../../../../common/components/FilterSort'
import TabPanel from '../plan_shinchan/TabPanel'

/** style */
import { mediaPc, mediaSp, contentWidth } from '../plan_shinchan/styles'

/** 月額見放題ページ クレヨンしんちゃんテンプレート */
const PlanShinchan = (
  {
    slug = '',
    course,
    status = {
      isPurchased: false,
      isNotPurchased: false
    },
    popupArticles = [],
    programMetas = [],
    selectionItems = [],
    characterItems = [],
    initialTabId = PANEL_ID.ALL,
    initialTag = [],
    theme = 'normal'
  },
  context
) => {
  const config = context.models.config.data
  const isMounted = useIsMounted()
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isApp = webApp.utils.isApp(context)
  const bannerId1 = get(course, ['values', 'banner_1', 'id'])
  const bannerId2 = get(course, ['values', 'banner_2', 'id'])
  const device = browserInfo(navigator.userAgent, (data) => data)
  const childEpisodeIds = programMetas.flatMap((program) =>
    get(program, ['values', 'child_episode_ids'], null)
  )
  /** シーズンのエピソード一覧を表示するタブ */
  const seasonTabs = [PANEL_ID.SHIN_MEN, PANEL_ID.TV_SPECIAL]
  /**  対象のシーズン一覧 */
  const seasonIdList = {
    [SEASON_SLUG.SHIN_CHAN]: config.svod.shinchan.shinchan.season_id,
    [SEASON_SLUG.SHIN_MEN]: config.svod.shinchan.shin_men.season_id,
    [SEASON_SLUG.TV_SPECIAL]: config.svod.shinchan.tv_special.season_id,
    [SEASON_SLUG.LIVE_ARCHIVE]: config.svod.shinchan.live_archive.season_id
  }
  /**
   * ページャー、ソート、フィルタをlocalStorageから取得
   * すべてのおはなしのときだけ
   */
  const dataFromLocalStorage = (() => {
    if (initialTabId !== '') return {}
    // ローカルストレージの値を取得
    const pageNum =
      parseInt(
        window.localStorage.getItem(LOCAL_STORAGE_KEY_SHINCHAN_PAGER),
        10
      ) || 1
    const sortedBy =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_SHINCHAN_SORT) ||
      'avails_release_history_original_older'
    const filteredBy =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_SHINCHAN_FILTER) || ''
    return { pageNum, sortedBy, filteredBy }
  })()

  /** useState */
  const [filteredArticle, setFilteredArticle] = useState([])
  const [selectedTabId, setSelectedTabId] = useState(
    initialTabId || PANEL_ID.ALL
  ) // 選択中のタブID
  const [initialSlide, setInitialSlide] = useState(
    context.routeHandler.query.id - 1
  )

  /** useRef */
  const tabPanelRef = useRef(null)
  const isInitial = useRef(true)

  const searchParams = useSearchParams(
    {
      childEpisodeIds,
      seasonIds: includes(seasonTabs, initialTabId)
        ? [seasonIdList[initialTabId]]
        : Object.values(seasonIdList),
      tags: initialTag || [],
      notTags: initialTabId === PANEL_ID.SELECTION ? ['厳選まとめ'] : [],
      ...dataFromLocalStorage,
      pagerOptions: {
        episodesPerPages: 60,
        range: 2,
        showBottom: true
      }
    },
    {
      afterSearch: ({
        pageNum = 1,
        sortedBy = 'avails_release_history_original_older',
        filteredBy = ''
      }) => {
        if (selectedTabId !== PANEL_ID.ALL) return
        const ls = window.localStorage
        ls.setItem(LOCAL_STORAGE_KEY_SHINCHAN_SORT, sortedBy)
        ls.setItem(LOCAL_STORAGE_KEY_SHINCHAN_FILTER, filteredBy)
        ls.setItem(LOCAL_STORAGE_KEY_SHINCHAN_PAGER, pageNum)
      }
    }
  )

  /** 子コンポーネントでのselectedYear変更 */
  const setSelectedYear = (newSelectedYear) =>
    searchParams.set({ selectedYear: newSelectedYear })

  /** 子コンポーネントでのtags変更 */
  const setSelectedTags = (newTags) => searchParams.set({ tags: newTags })

  useEffect(() => {
    const { routeHandler } = context

    /** パスパラメータにタブの指定がある場合はスクロール */
    const hasQueryTab = get(routeHandler, ['query', 'tab'], '')
    if (hasQueryTab) {
      /** タブエリアまでスクロール */
      setTimeout(() => {
        if (tabPanelRef.current) {
          tabPanelRef.current.scrollIntoView({
            behavior: 'smooth'
          })
        }
      }, 1500)
    }

    /** アプリ用のclassを消去 */
    const wrapperElm = document.querySelector('.bd.lang_ja.appli')
    if (wrapperElm) wrapperElm.classList.remove('appli')

    return () => {
      /** アプリ用のclassを戻す */
      if (wrapperElm) wrapperElm.classList.add('appli')
    }
  }, [])

  /** お知らせ */
  useEffect(() => {
    if (size(popupArticles) > 0) {
      const isAndroidBrowser = !isApp && device.isAndroid
      setFilteredArticle(() =>
        popupArticles.filter((article) => {
          const tagArr = get(article, ['admin_tags_array'], [])
          const isShinchanArticle = tagArr.indexOf('クレヨンしんちゃん') > -1
          const isForAndroidArticle = tagArr.indexOf('ブラウザ-Android') > -1

          /** AndroidBrowserのときだけ表示する記事がある (DGA2-1616) */
          if (isAndroidBrowser) {
            return isShinchanArticle
          }
          return isShinchanArticle && !isForAndroidArticle
        })
      )
    }
  }, [popupArticles])

  /** タブを切り替えたとき初期化 */
  useEffect(() => {
    if (isInitial.current === true) {
      /** 初回は実行しない */
      isInitial.current = false
      return
    }

    const { routeHandler } = context
    const { query } = routeHandler

    // 不要なキーを削除
    delete query.tab
    delete query.id

    /**
     * 選択中のタブをパスパラメータに付与
     * 「すべてのおはなし」のときはtabパラメータをトル
     */
    if (selectedTabId && selectedTabId !== PANEL_ID.ALL) {
      query.tab = selectedTabId
    }

    /** 固有の初期値を設定 */
    let selectedTags = []
    switch (selectedTabId) {
      case PANEL_ID.SELECTION: {
        const latestItem = get(selectionItems, [0])
        const tag = get(latestItem, ['tag'], '')
        const id = get(latestItem, ['id'], '')
        selectedTags = [tag]
        query.id = id
        break
      }
      case PANEL_ID.CHARACTER: {
        query.id = 1
        const tag = get(characterItems, [0, 'name'], '')
        selectedTags = [tag]
        setInitialSlide(0)
        break
      }
      default:
        break
    }

    searchParams.set({
      seasonIds: includes(seasonTabs, selectedTabId)
        ? [seasonIdList[selectedTabId]]
        : Object.values(seasonIdList),
      tags: selectedTags,
      notTags: selectedTabId === PANEL_ID.SELECTION ? ['厳選まとめ'] : []
    })

    /**
     * 履歴の追加、パスパラメータの書き換え
     * // NOTE
     * contextが書き換えられると再レンダリングされるため
     * context.history.replaceは使えない
     */
    const path = new URLSearchParams(query).toString()
    const url = path ? `${slug}?${path}` : slug
    window.history.replaceState(null, '', url)

    /** タブエリアまでスクロール */
    if (tabPanelRef.current) {
      tabPanelRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [selectedTabId])

  if (!isMounted) return null

  return (
    <StyledContainer id="PlanShinchan" theme={theme}>
      <MainVisual
        isLoggedIn={isLoggedIn}
        isPurchased={status.isPurchased}
        theme={theme}
      />
      <StyledContainerInner>
        <NewArrivals
          seasonIds={seasonIdList}
          scrollTargetRef={tabPanelRef}
          setSelectedTabId={setSelectedTabId}
          setSelectedTags={setSelectedTags}
        />

        <div>
          {/* Snippets */}
          {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
          {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
        </div>

        <TabPanel
          ref={tabPanelRef}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          selectedYear={searchParams.state.selectedYear}
          setSelectedYear={setSelectedYear}
          selectedTags={searchParams.state.tags}
          setSelectedTags={setSelectedTags}
          selectionItems={selectionItems}
          characterItems={characterItems}
          initialSlide={initialSlide}
        />

        {size(searchParams.state.seasonIds) > 0 && (
          <FilterSort
            searchParams={searchParams}
            episodeListItemProps={{
              showNew: true,
              showChecked: true,
              showCoin: true,
              showCaption: true,
              onlySubTitle: true
            }}
          />
        )}
      </StyledContainerInner>
      <StyledShinchanFooter theme={theme} />
      {isApp ? (
        <StyledAppFooter courseId={get(course, ['course_id'])} showLogo />
      ) : (
        <StyledFooter courseId={get(course, ['course_id'])} showLogo />
      )}
      {!device.isSilk && size(filteredArticle) > 0 && (
        <AnnounceModal articles={filteredArticle} />
      )}
      <GlobalStyle isApp={isApp} />
    </StyledContainer>
  )
}

export default memo(PlanShinchan)

PlanShinchan.propTypes = {
  slug: PropTypes.string,
  /** プランの情報 */
  course: PropTypes.shape({
    course_id: PropTypes.number.isRequired,
    schema_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    active_pricing: PropTypes.shape({}).isRequired,
    values: PropTypes.shape({}).isRequired
  }).isRequired,
  /** プランの購入情報 */
  status: PropTypes.shape({
    isPurchseAvailable: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool
  }),
  /** 厳選エピソードデータ */
  selectionItems: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      primary: PropTypes.shape({
        thumb: PropTypes.string,
        title: PropTypes.string,
        alt: PropTypes.string
      }),
      tag: PropTypes.string
    })
  ),
  /** キャラクター別データ */
  characterItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      category: PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string,
        img: PropTypes.string
      }),
      imgName: PropTypes.string,
      img: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          on: PropTypes.string,
          off: PropTypes.string
        })
      ])
    })
  ),
  popupArticles: PropTypes.arrayOf(PropTypes.shape({})),
  programMetas: PropTypes.arrayOf(PropTypes.shape({})),
  /** 初期表示タブID */
  initialTabId: PropTypes.string,
  /** 初期表示タグ */
  initialTag: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.oneOf([
    'normal',
    'spring',
    'rainy',
    'summer',
    'autumn',
    'winter',
    'movie'
  ])
}

PlanShinchan.contextTypes = {
  models: PropTypes.shape({}),
  routeHandler: PropTypes.shape({}),
  history: PropTypes.shape({}),
  updateUserInfo: PropTypes.func
}

const GlobalStyle = createGlobalStyle`
body {
  color: #333;
}
img {
  margin: 0;
}
.c-pageTop {
  display: none;
}
.c-modal {
  z-index: 100;
}

/* HTMLスニペット内のコンテンツの出し分け用 */
${({ isApp }) => {
  if (isApp) {
    return css`
      .u-hide--app {
        display: none !important;
      }
    `
  }
  return css`
    .u-hide--browser {
      display: none !important;
    }
  `
}}

/* /app */
.common-footer {
  margin-top: 0;
}
`

const StyledContainer = styled.div`
  background-image: url(/douga_mv/shinchan/svod/images/main/bg-pattern.png);
  overflow: hidden;

  ${({ theme }) => {
    switch (theme) {
      /** 春テーマ */
      case 'spring':
        return css`
          background-color: #b3ea57;
        `

      /** 梅雨テーマ */
      case 'rainy':
        return css`
          background-color: #95d6ff;
        `

      /** 夏テーマ */
      case 'summer':
        return css`
          background-color: #f5e2a6;
        `

      /** 秋テーマ */
      case 'autumn':
        return css`
          background-color: #ffb640;
        `

      /** 冬テーマ */
      case 'winter':
        return css`
          background-color: #dbecf6;
        `

      /** 映画テーマ */
      case 'movie':
        return css`
          background-color: #feef89;
        `

      /** ぶりぶりclub */
      case 'buriburiclub':
        return css`
          background-color: #acffa0;
        `

      default:
        return null
    }
  }}
`

const StyledContainerInner = styled.div`
  margin: 0 auto;
  padding: 20px 15px 60px; // コンテンツ幅以下のとき用の左右余白
  max-width: calc(${contentWidth} + 30px);

  @media ${mediaSp} {
    padding: 10px;
    position: relative;
  }
`

const StyledShinchanFooter = styled(ShinchanFooter)`
  /* コンテンツ幅以下のとき用の左右余白 */
  @media ${mediaPc} {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media ${mediaSp} {
    padding-bottom: 140px;
  }
`

const StyledFooter = styled(Footer)`
  margin-top: 0;
`

const StyledAppFooter = styled(AppFooter)`
  margin-top: 0;
`
