import FalcorDataSource from 'falcor-http-datasource';
import _ from 'lodash';
import PathEvaluator from '../../../sketch-platform/utils/PathEvaluator';
import initAppContext from '../initAppContext';
import history from '../../history';
import { ReactClientRenderer } from '../../../sketch-platform/renderers';

window.app = window.app || {};
window.app.reactContext = window.app.reactContext || {};

const falcorModel = new PathEvaluator({
  collectRatio: 0.75,
  source: new FalcorDataSource('/pathEvaluator', {
    withCredentials: true,
    timeout: 15 * 60 * 1000
  }),
  errorSelector(path, error) {
    // mergeJSONGraphNode.jsから呼び出される。
    console.error('errorSelector', { path, error });
    error.$expires = -1000 * 60 * 2;
    // errorを返さないとmergeJSONGraphNode.jsでエラーとなる。
    return error;
  }
});
falcorModel.setCache(window.app.falcorCache);

const model = _.cloneDeep(window.app.reactContext);
const container = document.getElementById('appMountPoint');

initAppContext(falcorModel, history, function(appContext) {
  new ReactClientRenderer(container, appContext).render((err, a) => {
    if (err) {
      console.error(err);
    }
  });
});
