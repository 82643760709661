/* style */
import styled from 'styled-components'
import { mediaQuery, breakPoints } from '../../../style'
import Link from '../../../../../../sketch-platform/ui/routing/Link'

export const StyledDiv1 = styled.div`
  background: #fff
    url('https://wws.tv-asahi.co.jp/douga_mv/minato/images/main_bg.png')
    no-repeat top;
  margin: 0 auto;
  overflow: hidden;
  font-family: 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体',
    'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif;

  ${mediaQuery(breakPoints.sm)} {
    background-size: 250% auto;
    background-position: top;
    background-repeat: no-repeat;
  }
`

export const StyledH1 = styled.h1`
  width: 1280px;
  margin: 30px auto 0;

  a {
    display: inline-block;

    :hover {
      cursor: pointer;
    }
  }

  ${mediaQuery(1320)} {
    width: 960px;
  }

  ${mediaQuery()} {
    width: 100%;
    margin: 0;
    padding: 15px 0;

    img {
      width: 120px;
    }
  }
`

export const StyledSection = styled.section`
  position: relative;
  margin: 30px auto 40px;
  text-align: center;
  text-shadow: #fff 0 0 15px;
  color: #666;

  ${mediaQuery()} {
    text-align: center;
    text-shadow: #fff 0 0 15px;
    margin-top: 30px;
    color: #666;
  }
`

export const StyledH2 = styled.h2`
  margin-bottom: 0px;
  line-height: 1.2;
  font-size: 1.2rem;

  ${mediaQuery()} {
    font-size: 2.4rem;
    letter-spacing: 3px;
  }
`

export const StyledP = styled.p`
  font-size: 1.9rem;
  line-height: 2.5;
  margin-bottom: 40px;

  ${mediaQuery()} {
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 0;
  }
`

export const StyledDiv2 = styled.div`
  margin-bottom: 40px;
  background: inherit;
  overflow: hidden;
  padding: 0px 12px;

  ${mediaQuery(breakPoints.sm)} {
    margin-bottom: 0;
    padding: 0px 8px;
  }
`

export const StyledDiv3 = styled.div`
  width: 1280px;
  position: relative;
  margin: 0px auto;

  ${mediaQuery(1320)} {
    width: 960px;
  }

  ${mediaQuery(breakPoints.sm)} {
    margin: auto;
    width: auto;
    position: inherit;
  }
`

export const StyledDiv10 = styled.div`
  ${mediaQuery(breakPoints.sm)} {
    margin: 0 8px 0;
    max-width: unset;
  }
`

export const StyledDiv4 = styled.div`
  background: rgba(255, 255, 255, 0.698);
  margin: 0px auto;
  overflow: hidden;

  ${mediaQuery(breakPoints.sm)} {
    padding: 0;
  }
`

export const StyledDiv5 = styled.div`
  width: 640px;
  height: 360px;
  float: left;
  position: relative;

  ${mediaQuery(breakPoints.sm)} {
    width: auto;
    height: auto;
    float: inherit;
  }
`

export const StyledA1 = styled.a`
  ${mediaQuery(breakPoints.sm)} {
  }
`

export const StyledLink1 = styled(Link)`
  && {
    font-weight: 700;
    color: rgb(109, 156, 200);
  }
`

export const StyledDiv6 = styled.div`
  width: 600px;
  margin-top: 20px;
  color: rgb(230, 235, 239);
  float: right;
  padding: 0px 20px 0px 0px;

  ${mediaQuery(1320)} {
    width: 300px;
  }
`

export const StyledDiv7 = styled.div`
  display: none;
  ${mediaQuery(breakPoints.sm)} {
    display: block;
    padding-top: 68px;
    width: 80px;
    height: 80px;
    background: url(/images/exdio/renewal/icon_play_btn_for_video_area_sp.svg)
      no-repeat top center;
    position: absolute;
    right: 1%;
    bottom: 5%;
    z-index: 1;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
  }
`

export const StyledDiv8 = styled.div`
  margin-left: auto;
  padding: 0.6em 1.2em;
  background: rgba(102, 102, 102, 0.5);
  border-radius: 5px;
  position: absolute;
  bottom: 21px;
  left: 15px;
  z-index: 1;
  color: #fff;
  font-size: 1.8rem;
  text-align: center;

  ${mediaQuery(breakPoints.sm)} {
    font-size: 1.3rem;
  }
`

export const StyledDiv9 = styled.div`
  margin: auto;
  padding-top: 170px;
  width: 144px;
  height: 144px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_pc.svg)
    no-repeat center center;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;

  ${mediaQuery(breakPoints.sm)} {
    display: none;
  }
`

export const StyledImg1 = styled.img`
  margin: 0 auto 0 0;

  ${mediaQuery(breakPoints.sm)} {
    margin: 5px auto 0 0;
  }
`

export const StyledH3 = styled.h3`
  font-weight: 700;
  color: rgb(81, 81, 81);
  font-size: 18px;
  line-height: 22px;
  margin-top: 6px;
  margin-bottom: 16px;

  ${mediaQuery(breakPoints.sm)} {
    padding: 15px 10px 10px;
    background: rgba(109, 156, 200, 0.55);
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
  }
`

export const StyledUl1 = styled.ul`
  color: rgb(81, 81, 81);
  border-top: 1px solid rgb(230, 228, 228);
  padding: 18px 0px 12px;

  ${mediaQuery(breakPoints.sm)} {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
  }
`

export const Styledli1 = styled.li`
  margin-bottom: 12px;
  font-size: 12px;

  &::before {
    color: #6c6e6e;
    margin-right: 5px;
    font-size: 12px;
    font-family: 'Material Icons';
    content: '${({ icon }) => icon}';
  }
`

export const StyledP1 = styled.p`
  font-size: 1.3rem;
  line-height: 1.5;
  color: rgb(81, 81, 81);
`

export const StyledDiv11 = styled.div`
  ${mediaQuery(breakPoints.sm)} {
    margin: 0 0 10px;
    text-align: right;
  }
`

export const StyledDiv12 = styled.div`
  ${mediaQuery(breakPoints.sm)} {
    color: #515151;
    padding: 15px 0;
    background: hsla(0, 0%, 100%, 0.7);
  }
`

export const StyledDiv13 = styled.div`
  margin-bottom: 40px;

  ${mediaQuery(breakPoints.sm)} {
    margin-bottom: 0;
  }
`

export const StyledP2 = styled.p`
  ${mediaQuery(breakPoints.sm)} {
    color: #515151;
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 12px;
    padding: 0 10px;
  }
`
