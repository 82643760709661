import PropTypes from 'prop-types'

export const SelectionItemType = PropTypes.shape({
  id: PropTypes.number,
  sort: PropTypes.number,
  primary: PropTypes.shape({
    thumb: PropTypes.string,
    title: PropTypes.string,
    alt: PropTypes.string
  }),
  tag: PropTypes.string
})
