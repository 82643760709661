/**
 * 文字列形式の時間表記を変換します。
 *
 * @param {string} timeString - 変換対象の時間表記文字列（例：「12時15分20秒」）
 * @returns {string} 変換後の時間表記文字列（例：「12 hour 15 min 20 sec」）
 */
const convertTimeString = (timeString) => {
  const timeUnits = ['日', '時', '分', '秒']
  const regex = /(\d+)([日時分秒])/g
  let result = ''
  let match = regex.exec(timeString)
  while (match) {
    const unitIndex = timeUnits.indexOf(match[2])
    const convertedUnit = ['', 'hour', 'min', 'sec'][unitIndex]
    result += `${match[1]} ${convertedUnit} `
    match = regex.exec(timeString)
  }
  return result.trim()
}

export default convertTimeString
