import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import useMediaQuery from '../../../../../hooks/useMediaQuery'
import routes from '../../../../../common/routes'
import SwitchableSail from '../components/SwitchableSail'
import SpSwitchableSail from '../components/SpSwitchableSail'
import ProgramSailSlide from './components/ProgramSailSlide'
import SpSailSlide from './components/SpSailSlide'
import Sns from '../components/Sns'
import Bnr from '../components/Bnr'
import Breadcrumbs from '../components/Breadcrumbs'
import Logo from '../components/Logo'

/* style */
import {
  StyledDiv1,
  StyledMain,
  StyledH1,
  StyledLink1,
  StyledImg1,
  StyledDiv2,
  StyledDiv3,
  StyledImg2,
  StyledFooter
} from './style'

/** SailGp 番組ページ テンプレート */
const ProgramSailGp = ({ episodes, seriesId, seasonId, meta }, context) => {
  const pageUrl =
    webApp.utils.rootUrl() + routes.program.makePath({ seriesId, seasonId })
  const isSmp = useMediaQuery()

  // snsコンポーネントへ渡す配列
  const twitter = {
    href: `https://twitter.com/share?url=${pageUrl}&text=${encodeURI(
      'SailGP｜テレ朝動画'
    )}`,
    img: 'https://wws.tv-asahi.co.jp/douga_mv/sailgp/images/tw.png'
  }
  const facebook = {
    href: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`,
    img: 'https://wws.tv-asahi.co.jp/douga_mv/sailgp/images/fb.png'
  }
  const line = {
    href: `https://line.me/R/msg/text/?SailGP｜テレ朝動画%0D%0A${pageUrl}`,
    img: 'https://wws.tv-asahi.co.jp/douga_mv/sailgp/images/line.jpg'
  }
  const snsPc = [twitter, facebook]
  const snsSp = [...snsPc, line]

  const sortedEpisodes = episodes.sort((a, b) =>
    Number(a.values.avails_EpisodeNumber) <
    Number(b.values.avails_EpisodeNumber)
      ? 1
      : -1
  )

  const top = { seriesId, seasonId }
  const breadcrumbsItems = [top]

  /** pcページ表示 */
  const renderPcPage = () => {
    return (
      <StyledDiv1>
        <StyledMain>
          <Logo SailGPTop={top} as="h1" />

          <Breadcrumbs items={breadcrumbsItems} />

          {episodes.length > 0 && (
            <ProgramSailSlide episodes={sortedEpisodes} />
          )}

          <StyledDiv2>
            <Sns items={snsPc} />
            <Bnr />
          </StyledDiv2>

          <SwitchableSail seasonId={seasonId} />
        </StyledMain>

        <StyledImg2 src="https://wws.tv-asahi.co.jp/douga_mv/sailgp/images/bg_05.png" />
        <StyledFooter />
      </StyledDiv1>
    )
  }

  /** spページ表示 */
  const renderSpPage = () => {
    return (
      <StyledDiv1>
        <StyledDiv3>
          <Logo SailGPTop={top} as="h1" />

          {episodes.length > 0 && <SpSailSlide episodes={sortedEpisodes} />}

          <Sns items={snsSp} />
          <Bnr />

          <SpSwitchableSail seasonId={seasonId} />
        </StyledDiv3>
        <StyledImg2 src="https://wws.tv-asahi.co.jp/douga_mv/sailgp/images/bg_sp_btm.jpg" />
        <StyledFooter />
      </StyledDiv1>
    )
  }

  return isSmp ? renderSpPage() : renderPcPage()
}

export default ProgramSailGp

ProgramSailGp.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object)
}

ProgramSailGp.defaultProps = {
  seriesId: '',
  seasonId: '',
  episodes: []
}

ProgramSailGp.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  falcorModel: PropTypes.object
}
