import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import webApp from '../../../../../utils/exdioWebAppUtils'
import SliderImage from '../../../program_harbor/SliderImage'

const Slider1 = ({ episodes, setSlider, nav }, context) => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000
  }

  return (
    <Slider {...settings} asNavFor={nav} ref={(slider) => setSlider(slider)}>
      {episodes.map((meta) => {
        return (
          <SliderImage
            key={meta.meta_id}
            meta={meta}
            onClickThumbnail={() => webApp.utils.goToProgramLink(context, meta)}
          />
        )
      })}
    </Slider>
  )
}

Slider1.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

Slider1.propTypes = {
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    })
  )
}

export default Slider1
