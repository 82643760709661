import React from 'react'
import PropTypes from 'prop-types'
import GenreLinksSp from '../../../../../../../common/components/renewal/GenreLinksSp'
import webApp from '../../../../../../utils/exdioWebAppUtils'
import routes from '../../../../../../../common/routes'

/* style */
import styled from 'styled-components'
import {
  StyledFooter,
  StyledDiv1,
  StyledDiv2,
  StyledDiv3,
  StyledUl1,
  StyledLi1,
  StyledLink1,
  StyledDiv4,
  StyledP,
  StyledDiv5,
  StyledUl2,
  StyledLi2,
  StyledDl,
  StyledDt,
  StyledDd,
  StyledH4,
  StyledP2,
  StyledUl3,
  StyledLi3,
  StyledLink2,
  StyledImg
} from './style'
import { mediaQuery } from '../../../../../style'

/** Logirlプランページ | フッター */
const AfterFooter = ({ courseId, ...props }, context) => {
  const config = context.models.config.data
  const guideLinks = config.guide_links
  const linkRowCount = guideLinks.length / 2 + (guideLinks.length % 2)
  const firstLinks = guideLinks.slice(0, linkRowCount)
  const secondLinks = guideLinks.slice(linkRowCount)

  const renderJasrac = () => {
    const doraemonId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 2
      : 1
    const shinchanId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 3
      : 2
    const logirlId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 4
      : 3
    const garibenId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 13
      : 5

    let ret = null
    switch (courseId) {
      case doraemonId:
        ret = <p>6688647077Y45038</p>
        break
      case shinchanId:
        ret = <p>6688647078Y45038</p>
        break
      case logirlId:
        ret = <p>6688647051Y45038</p>
        break
      case garibenId:
        ret = <p>6688647068Y45038</p>
        break
      case null:
        ret = (
          <p>
            6688647019Y45038
            <br />
            6688647032Y45038
            <br />
            6688647069Y45038
          </p>
        )
        break
    }

    return ret
  }

  return (
    <StyledFooter {...props}>
      <StyledGenreLinksSp />

      <StyledDiv1>
        <StyledDiv2>
          <StyledDiv3>
            <StyledUl1>
              {firstLinks.map((link) => {
                return (
                  <StyledLi1 key={link.title}>
                    <StyledLink1
                      href={
                        link.url
                          ? link.url.replace(
                              ':host',
                              webApp.utils.dougaMvHost(context)
                            )
                          : null
                      }
                      route={routes[link.route]}
                      params={link.params}
                      target={link.target}
                    >
                      {link.title}
                    </StyledLink1>
                  </StyledLi1>
                )
              })}
            </StyledUl1>
          </StyledDiv3>
          <StyledDiv3>
            <StyledUl1>
              {secondLinks.map((link) => (
                <StyledLi1 key={link.title}>
                  <StyledLink1
                    href={
                      link.url
                        ? link.url.replace(
                            ':host',
                            webApp.utils.dougaMvHost(context)
                          )
                        : null
                    }
                    route={routes[link.route]}
                    params={link.params}
                    target={link.target}
                  >
                    {link.title}
                  </StyledLink1>
                </StyledLi1>
              ))}
            </StyledUl1>
          </StyledDiv3>
        </StyledDiv2>
        <StyledDiv4>
          <div className="notes-content">
            <StyledP>
              配信されている動画は放送内容と一部異なる場合がございます。また、予告なく配信終了する場合がございますがご了承ください。表示されている情報は、収録および放送当時のものです。
              <br />
              消費税率の変更により、テレビ朝日番組内およびホームページ内の価格表示と異なる場合があります。
            </StyledP>
          </div>
          {/* <div className="copyrights">
              <p>
                (C)テレビ朝日　(C)柳沢きみお/日刊ゲンダイ・テレビ朝日・MMJ　(C)臼井儀人/双葉社・シンエイ・テレビ朝日・ADK　(C)藤子プロ・小学館・テレビ朝日・シンエイ・ADK　(C)テレビ朝日・東映　(C)2014
                テレビ朝日　(C)テレビ朝日／吉本興業　(C)池井戸潤「民王」／テレビ朝日　(C)今野敏／テレビ朝日　(C)テレビ朝日・MMJ　(C)青木琴美・小学館／テレビ朝日、MMJ　(C)麻見和史・KADOKAWA/テレビ朝日　(C)2005
                筒井康隆／テレビ朝日・東宝株式会社　(C)テレビ朝日・東宝　(C)テレビ朝日・テレビマンユニオン・ViviA　(C)ママレード／Cork・シンエイ・ADK　(C)テレビ朝日・ラストアイドル製作委員会　(C)BS朝日　(C)テレビ朝日・東京サウンドプロダクション　(C)テレビ朝日・テレビ朝日映像
              </p>
            </div> */}
          <StyledDiv5>
            <StyledUl2>
              <StyledLi2>
                <StyledDl>
                  <StyledDt>
                    <img
                      src="/images/exdio/renewal/icon_jasrac.png"
                      alt="icon_jasrac"
                    />
                  </StyledDt>
                  <StyledDd>
                    <StyledH4>JASRAC許諾番号</StyledH4>
                    {renderJasrac()}
                  </StyledDd>
                </StyledDl>
              </StyledLi2>
              <StyledLi2>
                <StyledDl>
                  <StyledDt>
                    <img
                      src="/images/exdio/renewal/icon_nextone.png"
                      alt="icon_nextone"
                    />
                  </StyledDt>
                  <StyledDd>
                    <StyledH4>NexTone許諾番号</StyledH4>
                    <StyledP2>
                      UD000001695 <br />
                      UD000001696
                    </StyledP2>
                  </StyledDd>
                </StyledDl>
              </StyledLi2>
              <StyledLi2>
                <StyledDl>
                  <StyledDt>
                    <img
                      src="/images/exdio/renewal/icon_lmark.png"
                      alt="icon_lmark"
                    />
                  </StyledDt>
                  <StyledDd>
                    <StyledP2>
                      エルマークは、レコード会社・映像制作会社が提供するコンテンツを示す登録商標です
                    </StyledP2>
                    <StyledP2>IRAJ60017003</StyledP2>
                  </StyledDd>
                </StyledDl>
              </StyledLi2>
            </StyledUl2>
          </StyledDiv5>
        </StyledDiv4>
      </StyledDiv1>

      <StyledUl3>
        <StyledLi3>
          <StyledLink2 route={routes.home}>
            <StyledImg
              src="/images/exdio/renewal/logo_tv_asahi_douga.png"
              alt="テレ朝動画"
              loading="lazy"
            />
          </StyledLink2>
        </StyledLi3>
        <StyledLi3>
          <StyledLink2 href="https://www.tv-asahi.co.jp/">
            <StyledImg
              src="/images/exdio/renewal/logo_tv_asahi.svg"
              alt="テレビ朝日"
              loading="lazy"
            />
          </StyledLink2>
        </StyledLi3>
      </StyledUl3>
    </StyledFooter>
  )
}

export default AfterFooter

AfterFooter.propTypes = {
  /** footerに渡すクラス名 */
  className: PropTypes.string,
  /** Jasracの番号識別のためのコースID */
  courseId: PropTypes.number
}

AfterFooter.defaultProps = {
  className: '',
  courseId: null
}

AfterFooter.contextTypes = {
  models: PropTypes.object
}

const StyledGenreLinksSp = styled(GenreLinksSp)`
  margin-top: 2rem;
  ${mediaQuery('mm')} {
    margin-top: 1rem;
  }
`
