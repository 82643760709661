import React from 'react'
import PropTypes from 'prop-types'

/* components */
import Item from '../Item'

/* style */
import { StyledSlider } from './style'

/** Slider Component */
const Slider = ({ items = [], ...props }) => {
  if (!items.length > 0) return null
  return (
    <StyledSlider {...props}>
      {items.map(({ meta = null }, i) => (
        <Item key={i} meta={meta} rank={i + 1} />
      ))}
    </StyledSlider>
  )
}

export default Slider

Slider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      meta: PropTypes.object,
      type: PropTypes.string,
      values: PropTypes.object
    })
  )
}
