import styled from 'styled-components'
import { mediaSp } from '../../styles'

export const StyledButton = styled.button`
  height: 40px;
  padding: 0 15px;
  border: 3px solid #4a4a4a;
  background-color: #fff6a7;
  font-size: 2.1rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;

  @media ${mediaSp} {
    padding: 0 0.5em;
    height: 35px;
    font-size: 1.4rem;
    font-weight: normal;
  }
`
