import { createGlobalStyle } from 'styled-components'

/** mediaQuery */
export const mediaPc = '(min-width: 1320px)'

export const blue = '#005064'

export const skyBlue = '#00dcd2'

export const yellow = '#fffc24'

export const bgColor = '#37d600'

export const GlobalStyle = createGlobalStyle`
  body {
    background: url('/images/exdio/renewal/global/bg-left.png'), url('/images/exdio/renewal/global/bg-right.png'), url('/images/exdio/renewal/global/bg.png');
    background-position: top left, top right, top center;
    background-repeat: repeat-y, repeat-y, repeat;
    background-size: 80px auto, 80px auto, auto;

    @media ${mediaPc} {
      background-size: auto;
    }
  }

  main {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    color: #333;
  }
`
