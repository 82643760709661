import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import window from 'global'
import Cookie from 'js-cookie'
import Skeleton from 'react-loading-skeleton' // スケルトンスクリーン
import styled from 'styled-components'
import classnames from 'classnames'
import Footer from '../../../common/components/Footer'
import DFPBanner from '../../../common/components/DFPBanner'
import webApp from '../../utils/exdioWebAppUtils'
import ProgramItemLink from '../../../common/components/renewal/ProgramItemLink'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import HeaderNewsComponent from './HeaderNewsComponent'
import ProgramItemSkeleton from '../../../common/components/renewal/ProgramItemSkeleton' // スケルトンスクリーン
import Link from '../../../../sketch-platform/ui/routing/Link'
import { mediaQuery } from '../style'

const { sprintf } = require('sprintf-js')
const cookie = require('cookie-dough')()
const tk = require('timekeeper')

/** 見逃し無料ページ */
export default class CatchupContent extends Component {
  /**
   * タブ定義生成
   * falcorのpathに指定しやすいよう整形
   * @param genres
   * @returns {Array}
   */
  static createTabs(genres) {
    return genres.map((genre, i) => {
      const ids = genre.exclusive
        ? genres.filter((g) => !g.exclusive).flatMap((g) => g.keys)
        : genre.keys
      const path = ids.join(',')
      const key = [i, ...ids].join('_')
      return { key, path, ids, ...genre }
    })
  }

  /** スケルトンスクリーン */
  static SkeletonIndex() {
    return (
      <div className="c-catchupIndex">
        <div className="c-headMeta">
          <div className="c-headMeta-metaBox">
            <div className="c-headMeta-metaBox-art">
              <div className="c-headMeta-metaBox-art-inBox clickable">
                <div className="c-headMeta-metaBox-art-inBox-artwork shadowOn">
                  <Skeleton
                    variant="rect"
                    width="475"
                    height="267"
                    className="c-headMeta-metaBox-art-inBox-artwork-img"
                  />
                </div>
              </div>
            </div>
            <div className="c-headMeta-metaBox-info">
              <h2 className="c-headMeta-metaBox-info-title clickable">
                <Skeleton variant="rect" />
              </h2>
              <p className="c-headMeta-metaBox-info-titleSub clickable">
                <Skeleton variant="rect" width="75%" />
              </p>
              <div className="c-headMeta-metaBox-info-viewingPeriod">
                <Skeleton variant="rect" width="50%" />
              </div>
              <div className="c-headMeta-metaBox-info-onAir">
                <Skeleton variant="rect" width="50%" />
              </div>
              <div className="c-headMeta-metaBox-info-btn">
                <Skeleton
                  variant="rect"
                  style={{
                    marginTop: '3rem',
                    borderRadius: '5px',
                    height: '48px',
                    maxWidth: '459px'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /** スケルトンスクリーン */
  static Skeletons() {
    const skeletonObj = []
    for (let i = 0; i < 12; i += 1) {
      skeletonObj.push(<ProgramItemSkeleton key={i} hasThumb titleLength={2} />)
    }
    return skeletonObj
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  static getSsrMetaTags(models, options, _props, _prefetchResult) {
    const config = models.config.data
    const { req } = options
    const rootUrl = `${req.protocol}://${req.get('host')}`
    return {
      titlePrior: config.default_title_catchup,
      description: config.description_catchup,
      thumbnail: sprintf('%s/images/exdio/%s', rootUrl, config.og_image_catchup)
    }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data
    this.genres = this.config.catchup.genres

    this.state = {
      mainMeta: {},
      currentGenre: this.genres[0],
      genreAttributes: [],
      metas: [],
      isFetching: false,
      adClass: '',
      mainMetaLoaded: false,
      metasLoaded: false
    }

    this.onClickMain = this.onClickMain.bind(this)
    this.onClickItem = this.onClickItem.bind(this)
    this.onClickTab = this.onClickTab.bind(this)
    this.goBack = this.goBack.bind(this)

    this.setListRef = (e) => {
      this.listRef = e
    }
  }

  componentDidMount() {
    this._isMounted = true
    webApp.utils.load_vr_sync()
    this.fetchMainData().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
    this.fetchGenres()
      .then(() => this.fetchListData())
      .catch((e) => webApp.utils.handleFalcorError(e, this.context))
    this.updateMetaTags()

    const isSmp = webApp.utils.isSp()
    if (isSmp) {
      this.setState({ adClass: 'common-free-programs-set-header-titles' })
    } else {
      this.setState({ adClass: 'common-free-programs-set-header-titles mp_mt' })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    webApp.utils.remove_vr_sync()
  }

  onClickMain(e, meta) {
    e.preventDefault()
    webApp.utils.goToProgramLink(this.context, meta)
  }

  onClickItem(e, meta, autoPlay = true) {
    e.preventDefault()
    webApp.utils.goToProgramLink(this.context, meta, null, null, {
      autoPlay,
      programLink: true
    })
  }

  onClickTab(genre) {
    if (genre === this.state.currentGenre || this.state.isFetching) return

    this.setState(
      {
        currentGenre: genre,
        metas: []
      },
      () => {
        this.fetchListData()
        CatchupContent.setCurrentMenuCenter()
      }
    )
  }

  static setCurrentMenuCenter() {
    if (window.screen.width > 768) return

    const genreNav = document.getElementById('genre-nav')

    // .currentのoffsetを取得
    const li = genreNav.querySelectorAll('li')
    let isLeftFromCurrent = false
    const offsetLeft = Array.from(li).reduce((prev, elm) => {
      if (Array.from(elm.classList).indexOf('current') > -1) {
        isLeftFromCurrent = true
      }
      return isLeftFromCurrent ? prev : prev + elm.offsetWidth
    }, 0)

    const windowWidthHalf = window.innerWidth / 2 - 24 // .common-nav-horizontal-slideから左の余白分を引く
    const tabWidthHalf = genreNav.querySelector('.current').offsetWidth / 2
    const margin = 16 // paddingの余白分を調整

    /**
     * offsetLeft: .currentを左端に配置
     * margin: 余白等があり左端からずれるため調整
     * windowWidthHalf: .currentを画面中央に配置
     * tabWidthHalf: .currentの左端が基準になっているため、要素分だけ調整
     */
    const scrollLeft = offsetLeft + margin - windowWidthHalf + tabWidthHalf
    genreNav.scrollTo({ left: scrollLeft, behavior: 'smooth' })
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags() {
    // タイトルタグの更新
    const title = this.config.default_title_catchup
    webApp.utils.updateTitle(title)
    // メタタグの更新
    const { copyright } = this.config
    const description = this.config.description_catchup
    const keywords = this.config.keywords_catchup
    const rootUrl = `${window.location.protocol}//${window.location.host}`
    const ogImage = sprintf(
      '%s/images/exdio/%s',
      rootUrl,
      this.config.og_image_catchup
    )
    const url = window.location.href
    const regularUrl = url.replace(/\?.*$/, '')
    // GTMの更新
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: ogImage },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'mixi:image', content: ogImage },
        { property: 'og:image', content: ogImage },
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: regularUrl }]
    }
    webApp.utils.updateMeta(metaTags)
    webApp.utils.updateDataLayer(gtmTags)
    webApp.utils.updateCookieSync(window.navigator.userAgent)
  }

  fetchMainData() {
    // SPでは取得不要
    if (webApp.utils.isSp()) return Promise.resolve()

    const basePath = ['meta', 'catchupMain']
    const range = { from: 0, to: this.config.catchup.limit_for_main - 1 }
    const path = basePath.concat([range])
    return this.model.fetch([path]).then((result) => {
      const data = _.get(result, ['json', ...basePath]) || {}
      delete data.$__path
      const metas = Object.values(data)

      // 配信終了しているものは除く
      const filteredMetas = metas.filter((meta) => {
        const prevTime = Cookie.get(
          this.previewUrlList + this.cookieRubocopPrefix
        )
        return moment(prevTime).isBefore(meta.delivery_end_at)
      })

      // 既存ロジック踏襲 ref.)GalleryContent
      const mainMeta =
        // dio_priorityがtrue
        filteredMetas.find((meta) => meta.values.dio_priority) ||
        // dio_priorityが存在しない場合、放送日が今日（4:00 〜 28:00）のものからランダム
        this.targetDateItem(filteredMetas) ||
        // 配信している全体からランダムに表示
        filteredMetas[Math.floor(Math.random() * filteredMetas.length)] ||
        {}

      this.setState({ mainMeta }, () => {
        this.setState({ mainMetaLoaded: true })
      })
    })
  }

  /** 放送日が今日（4:00 〜 28:00）のメタをランダム取得 */
  targetDateItem(metas) {
    const rubocopTimeZoneName =
      window.location.host + this.config.cookie_rubocop_prefix
    const rubocopTimeZone = cookie.get(rubocopTimeZoneName)
    if (rubocopTimeZone) {
      tk.reset()
      const basedate = new Date(rubocopTimeZone)
      // タイムトラベル
      tk.travel(basedate)
    }

    const now = moment()
    const y = now.year()
    const m = now.month() + 1
    const format = 'YYYY-MM-DD HH:mm'
    let beforeDate = null
    let afterDate = null
    if (now.hours() < 4) {
      // AM4時以内の場合は前日の4時から当日の4時までとして換算する
      const yesterday = now.add(-1, 'days')
      beforeDate = moment(`${y}-${m}-${yesterday.date()} 03:59`, format)
      afterDate = moment(
        `${y}-${m}-${yesterday.add(1, 'days').date()} 04:00`,
        format
      )
    } else {
      // AM4時以降の場合は当日の4時から次の日の4時までとして換算する
      beforeDate = moment(`${y}-${m}-${now.date()} 03:59`, format)
      afterDate = moment(`${y}-${m}-${now.add(1, 'days').date()} 04:00`, format)
    }

    const todayMetas = metas.filter((meta) => {
      const availsReleaseHistoryOriginal = _.get(meta, [
        'values',
        'avails_ReleaseHistoryOriginal'
      ])
      return (
        availsReleaseHistoryOriginal &&
        moment(availsReleaseHistoryOriginal).isBetween(beforeDate, afterDate)
      )
    })
    // ランダムで1件取得
    const targetMeta = todayMetas.length
      ? todayMetas[Math.floor(Math.random() * todayMetas.length)]
      : null

    tk.reset()
    return targetMeta
  }

  fetchGenres() {
    const genreKeys = this.genres.flatMap((g) => g.keys).filter((k) => k)
    const path = [
      ['attributeByType', 'genre', genreKeys],
      ['attributeByType', 'sub_genre', genreKeys]
    ]

    return this.model.fetch(path).then((result) => {
      const attributes = _.get(result, ['json', 'attributeByType'], {})
      delete attributes.$__path
      const genreAttributes = Object.keys(attributes).reduce((prev, key) => {
        delete attributes[key].$__path
        return { ...prev, ...attributes[key] }
      }, {})
      this.setState({ genreAttributes })
    })
  }

  fetchListData() {
    const { isFetching, currentGenre, genreAttributes } = this.state
    if (isFetching) return
    this.setState({ isFetching: true, metasLoaded: false }, async () => {
      let isSubGenre = 0
      const ids = currentGenre.exclusive
        ? genreAttributes.map((attr) => attr.attribute_id)
        : currentGenre.keys
            .map((key) => {
              const genreAttr = Object.values(genreAttributes).find(
                (attr) => attr.slug === key
              )
              if (genreAttr.type === 'SUB_GENRE') {
                isSubGenre = 1
              }
              return genreAttr.attribute_id || null
            })
            .filter((v) => v)
      const genreIds = ids.join(',')
      const isExclusive = currentGenre.exclusive ? 1 : 0

      // 注)ライブは除外しています。
      const path = ['meta', 'catchupList', genreIds, isSubGenre, isExclusive]

      const deviceInfo = this.context.models.browserInfo.data
      if (deviceInfo.isIE) {
        // IEだとfinallyがエラーになる
        await this.model.fetch([path]).then((result) => {
          const metas = _.get(result, ['json', ...path]) || []
          this.setState({ metas }, () => {
            this.setState({ metasLoaded: true })
          })
        })
        this.setState({ isFetching: false })
      } else {
        this.model
          .fetch([path])
          .then((result) => {
            const metas = _.get(result, ['json', ...path]) || []
            this.setState({ metas }, () => {
              this.setState({ metasLoaded: true })
            })
          })
          .finally(() => {
            this.setState({
              isFetching: false
            })
          })
      }
    })
  }

  goBack(e) {
    e.preventDefault()
    this.context.history.goBack()
  }

  checkRemainingDays = (endDateStr, days = 7) => {
    const today = new Date()
    const endDate = new Date(endDateStr)

    // 残り日数を計算
    const diffTime = endDate - today
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    // 指定された日数以内かどうかのフラグを返す
    return diffDays <= days
  }

  renderCatchupIndex = () => {
    const { mainMeta } = this.state
    if (!(mainMeta && !!Object.keys(mainMeta).length)) return null

    const thumbnailUrl =
      (mainMeta && mainMeta.thumbnail_url) || this.config.default_thumbnail
    const [title, subTitle] = webApp.utils.titles(mainMeta)
    const remaining = webApp.utils.remaining(
      this.context,
      _.get(mainMeta, ['delivery_end_at']),
      this.context.models.config.data.catchup.show_remaining_days
    )
    let onAirDate = webApp.utils.onAirDate(mainMeta)
    let deliveryEndAt = webApp.utils.deliveryEndAt(
      mainMeta,
      'YYYY年M月D日 HH:mm'
    )
    if (Object.keys(mainMeta).length) {
      if (!onAirDate) onAirDate = '-'
      if (!deliveryEndAt) deliveryEndAt = '-'
    }
    const duration = webApp.utils.duration(mainMeta)

    return (
      <div className="c-catchupIndex">
        <div className="c-headMeta">
          <div className="c-headMeta-metaBox">
            <div className="c-headMeta-metaBox-art">
              <Link
                className="c-headMeta-metaBox-art-inBox clickable"
                route={_.get(
                  webApp.utils.getProgramLinkRoutes(this.context, mainMeta),
                  ['route']
                )}
                params={_.get(
                  webApp.utils.getProgramLinkRoutes(this.context, mainMeta),
                  ['params']
                )}
                query={_.get(
                  webApp.utils.getProgramLinkRoutes(this.context, mainMeta),
                  ['query']
                )}
              >
                <div className="c-headMeta-metaBox-art-inBox-artwork shadowOn">
                  <img
                    src={thumbnailUrl}
                    width="475"
                    height="267"
                    alt=""
                    loading="lazy"
                    className="c-headMeta-metaBox-art-inBox-artwork-img"
                  />
                </div>
                {remaining &&
                  this.checkRemainingDays(
                    _.get(mainMeta, ['delivery_end_at'])
                  ) && (
                    <div
                      data-chromatic="ignore"
                      className="c-headMeta-metaBox-art-inBox-viewingPeriod"
                    >
                      {remaining}
                    </div>
                  )}
              </Link>
            </div>
            <div className="c-headMeta-metaBox-info">
              <h2 className="c-headMeta-metaBox-info-title clickable">
                <Link
                  route={_.get(
                    webApp.utils.getProgramLinkRoutes(this.context, mainMeta),
                    ['route']
                  )}
                  params={_.get(
                    webApp.utils.getProgramLinkRoutes(this.context, mainMeta),
                    ['params']
                  )}
                  query={_.get(
                    webApp.utils.getProgramLinkRoutes(
                      this.context,
                      mainMeta,
                      null,
                      null,
                      { autoplay: false }
                    ),
                    ['query']
                  )}
                >
                  {title}
                </Link>
              </h2>
              <Link
                className="c-headMeta-metaBox-info-titleSub clickable"
                route={_.get(
                  webApp.utils.getProgramLinkRoutes(this.context, mainMeta),
                  ['route']
                )}
                params={_.get(
                  webApp.utils.getProgramLinkRoutes(this.context, mainMeta),
                  ['params']
                )}
                query={_.get(
                  webApp.utils.getProgramLinkRoutes(
                    this.context,
                    mainMeta,
                    null,
                    null,
                    { autoplay: false }
                  ),
                  ['query']
                )}
              >
                {subTitle}
              </Link>
              {duration && <p>時間：{duration}</p>}
              <div
                className={classnames('c-headMeta-metaBox-info-viewingPeriod', {
                  isDeadline: this.checkRemainingDays(
                    _.get(mainMeta, ['delivery_end_at']),
                    3
                  )
                })}
              >
                配信期間：{deliveryEndAt}
              </div>
              <div className="c-headMeta-metaBox-info-onAir">
                放送日：{onAirDate}
              </div>
              <div className="c-headMeta-metaBox-info-btn">
                <Link
                  className="c-headMeta-metaBox-info-btn-link"
                  route={_.get(
                    webApp.utils.getProgramLinkRoutes(this.context, mainMeta),
                    ['route']
                  )}
                  params={_.get(
                    webApp.utils.getProgramLinkRoutes(this.context, mainMeta),
                    ['params']
                  )}
                  query={_.get(
                    webApp.utils.getProgramLinkRoutes(this.context, mainMeta),
                    ['query']
                  )}
                >
                  <span className="c-headMeta-metaBox-info-btn-link-free">
                    無料
                  </span>
                  で視聴
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      currentGenre,
      metas,
      adClass,
      mainMetaLoaded,
      metasLoaded
    } = this.state

    return (
      <div className="common-wrapper catchup">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />
        <DFPBanner position="head" meta_id="" key="banner" />

        {mainMetaLoaded ? (
          <this.renderCatchupIndex />
        ) : (
          <CatchupContent.SkeletonIndex />
        )}

        <div className="common-free-programs-set" ref={this.setListRef}>
          <header className="common-free-programs-set-header">
            <div className={adClass}>
              <div className="common-free-programs-set-header-titles-back">
                <a
                  className="common-free-programs-set-header-titles-back-link"
                  onClick={this.goBack}
                >
                  戻る
                </a>
              </div>
              <div className="common-free-programs-set-header-titles-title">
                <h2>
                  <img
                    src="/images/exdio/renewal/logo_tv_asahi_free.png"
                    width="117"
                    height="18"
                    alt="テレ朝動画見逃し無料"
                    loading="lazy"
                  />
                </h2>
              </div>
            </div>
            <div className="common-nav-horizontal-slide">
              <ul id="genre-nav">
                {this.genres.map((genre) => (
                  <li
                    key={genre.title}
                    className={
                      genre.title === currentGenre.title ? 'current' : ''
                    }
                  >
                    <button
                      type="button"
                      onClick={() => this.onClickTab(genre)}
                    >
                      {genre.title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </header>

          <div className="common-free-programs-set-content">
            <div className="inner">
              <div className="c-card-catchup">
                {metasLoaded ? (
                  (() => {
                    if (!_.size(metas)) {
                      return (
                        <StyledP>現在配信中のエピソードはありません</StyledP>
                      )
                    }
                    return (
                      <div className="c-card-catchup-cont">
                        {metas.map((meta) => (
                          <ProgramItemLink
                            key={meta.meta_id}
                            meta={meta}
                            showRemaining
                            showDeadLine
                            showNew={false}
                            deliveryEndFormat="YYYY年M月D日 HH:mmまで"
                            route={_.get(
                              webApp.utils.getProgramLinkRoutes(
                                this.context,
                                meta
                              ),
                              ['route']
                            )}
                            params={_.get(
                              webApp.utils.getProgramLinkRoutes(
                                this.context,
                                meta
                              ),
                              ['params']
                            )}
                            query={_.get(
                              webApp.utils.getProgramLinkRoutes(
                                this.context,
                                meta
                              ),
                              ['query']
                            )}
                            programLink={meta.is_grouped}
                            showEndText
                          />
                        ))}
                      </div>
                    )
                  })()
                ) : (
                  <div className="c-card-catchup-cont">
                    <CatchupContent.Skeletons />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer className="mt0" />
      </div>
    )
  }
}

const StyledP = styled.p`
  font-size: 1.5rem;

  ${mediaQuery()} {
    font-size: 1.3rem;
  }
`
