import React, { Component } from 'react';
import PropTypes from 'prop-types';
import webApp from '../../../utils/exdioWebAppUtils';

/** メールアドレスの変更 */
export default class AccountEditEmail extends Component {
  static propTypes = {};
  static defaultProps = {};
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.state = {
      userInfo: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUserInfo();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /** ユーザー情報取得 */
  getUserInfo() {
    const path = [['user', 'info']];
    this.model
      .fetch(path)
      .then(result => {
        const userInfo = _.get(result, ['json', 'user', 'info']) || null;
        if (this._isMounted) {
          this.setState({ userInfo });
        }
      })
      .catch(e => webApp.utils.handleFalcorError(e, this.context));
  }

  onSubmit() {}

  goBack(e) {
    e.preventDefault();
    this.context.history.goBack();
  }

  render() {
    const { userInfo } = this.state;
    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <div className="c-mypageAccount">
            <header className="c-mypageAccount-header">
              <a href="#" className="c-mypage-btnBack alwaysOn" onClick={this.goBack}>
                戻る
              </a>
              <h3 className="c-mypageAccount-header-title">メールアドレスの変更</h3>
            </header>
            <div className="c-mypageAccount-cont">
              <div className="c-mypageAccount-cont-personal">
                <div className="c-mypageAccountEditMail">
                  <div className="c-mypageAccountEditMail-mailCurrent">
                    <dl className="c-mypageAccountEditMail-mailCurrent-inBox">
                      <dt className="c-mypageAccountEditMail-mailCurrent-inBox-title">
                        ログインメールアドレス（使用中）
                      </dt>
                      <dd className="c-mypageAccountEditMail-mailCurrent-inBox-mail">{userInfo.login_mail}</dd>
                    </dl>
                  </div>
                  <div className="c-mypageAccountEditMail-mailNext">
                    <h4 className="c-mypageAccountEditMail-mailNext-title require">メールアドレス</h4>
                    <div className="c-mypageAccountEditMail-mailNext-mail">
                      <input type="text" name="mail" placeholder="例：sample@xx.co.jp" className="c-inputText" />
                    </div>
                    <p className="c-mypageAccountEditMail-mailNext-attention">
                      入力したメールアドレスはログインする際に使用されます。<br />
                      「@mail.tvasahi.jp」からのメールを受け取れるようにして下さい。
                    </p>
                  </div>
                  <div className="c-mypageAccountEditMail-btnSave">
                    <a href="#" className="c-mypageAccountEditMail-btnSave-link" onClick={this.onSubmit}>
                      変更する
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
