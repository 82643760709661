import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from '../Label'
import { mediaPc } from '../../styles'

/** そだてれび：海外視聴用 動画一覧 要素コンポーネント 情報ラベル */
const Info = ({ title, isNew, broadcast, isSmp, ...props }) => {
  return (
    <StyledDiv1
      isSmp={isSmp}
      className="c-episodes__list__item--info"
      {...props}
    >
      <StyledDiv2>
        <StyledP1 isSmp={isSmp}>{title}</StyledP1>
      </StyledDiv2>
    </StyledDiv1>
  )
}

export default Info

Info.propTypes = {
  /** 動画タイトル */
  title: PropTypes.string,
  /** 最新動画か */
  isNew: PropTypes.bool,
  /** 放送開始日 */
  broadcast: PropTypes.string,
  /** スマートフォンでの表示か */
  isSmp: PropTypes.bool
}

const StyledDiv1 = styled.div`
  padding: ${({ isSmp }) => (isSmp ? '0 0 5px 0' : '0 0 20px 0 ')};
  position: relative;
`

const StyledDiv2 = styled.div`
  padding: ${({ isSmp }) => (isSmp ? '7px 7px 5px' : '8px 12px')};
  background: #eee;
`

const StyledP1 = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  overflow: hidden;
  line-height: ${({ isSmp }) => (isSmp ? '1.2' : '1.5')};
  color: #333;
  font-weight: bold;
  font-size: 14px;
  height: calc(1em * ${({ isSmp }) => (isSmp ? '1.2' : '1.5')} * 2);
`

const StyledDiv3 = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ isSmp }) => (isSmp ? '8px' : '4px')};
`

const StyledP2 = styled.p`
  margin-top: 0;
  width: auto;
  text-align: left;
  font-size: 12px;
`
