import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import useMediaQuery from '../../../../hooks/useMediaQuery'

/* style */
import { StyledIframe } from './style'

/** コメント機能 */
const Comment = ({ hash = '', ...props }, context) => {
  if (!hash) return null

  const config = context.models.config.data
  const isSp = useMediaQuery()

  const parentUrl = window.location.href || ''
  const params = {
    hash,
    template_type: 'DIO',
    parent_url: parentUrl
  }
  const paramsStr = new URLSearchParams(params).toString()
  const src = `${config.comment.src}?${paramsStr}`

  const iframeRef = useRef(null)

  const messageHandler = (e) => {
    const { origin, data } = e
    if (!config.comment.origin.includes(origin) || !data) return

    const height = get(data, ['height'])
    const scrollFlag = get(data, ['scrollFlag'])

    if (height) iframeRef.current.height = height

    if (scrollFlag) {
      const scrollTopPos = iframeRef.current.offsetTop - (isSp ? 56 : 74)
      window.scrollTo({ top: scrollTopPos, behavior: 'smooth' })
    }

    const seekTime = get(data, ['seekTime'])
    if (typeof seekTime !== 'number') return

    const DioPlayer = document.getElementById('DIOplayer')
    const videoElm = DioPlayer ? DioPlayer.querySelector('video') : null
    if (videoElm) {
      videoElm.currentTime = seekTime
    }
  }

  useEffect(() => {
    /** iframe内部の高さが変わったらiframe要素の高さを変更する */
    window.addEventListener('message', messageHandler, false)

    return () => {
      window.removeEventListener('message', messageHandler, false)
    }
  }, [])

  return (
    <StyledIframe
      src={src}
      width="850"
      height="473"
      frameBorder="0"
      scrolling="no"
      ref={iframeRef}
      {...props}
    />
  )
}

export default Comment

Comment.propTypes = {
  hash: PropTypes.string.isRequired
}

Comment.contextTypes = {
  models: PropTypes.object
}
