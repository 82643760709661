import React from 'react'

import { NEWS_CONTENTS } from '../../../../../../constants/app'
import routes from '../../../../../common/routes'

/* style */
import { StyledLi, StyledLink } from './style'

const Tabs = ({ currentContent }) => {
  return Object.values(NEWS_CONTENTS).map((content) => {
    const current = currentContent === content

    return (
      <StyledLi key={content.id}>
        <StyledLink route={routes[content.route]} current={current}>
          {content.label}
        </StyledLink>
      </StyledLi>
    )
  })
}

export default Tabs
