import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import webApp from '../../../../utils/exdioWebAppUtils'
import CastInfo from '../../details/CastInfo'

/* style */
import {
  StyledInner,
  StyledButton,
  StyledButtonInner,
  StyledWrapper
} from './style'

/** 詳細画面:あらすじコンポーネント */
const Caption = memo(({ meta, isOpen, hideButton, ...props }) => {
  const caption = get(meta, ['values', 'evis_EpisodeLongSynopsis'])

  if (!caption) return null

  /** アコーディオン開閉フラグ */
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen)

  /** アコーディオン開閉 */
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen)
  }

  return (
    <StyledWrapper isOpen={isAccordionOpen} {...props}>
      <StyledInner>
        {webApp.utils.feedLine(caption)}
        <CastInfo
          otherInstructions={meta.values.avalis_OtherInstructions}
          actors={meta.values.evis_Actors}
          directors={meta.values.evis_Directors}
          producers={meta.values.evis_Producers}
          writers={meta.values.evis_Writers}
          productions={meta.values.evis_Productions}
          isPadding={false}
        />
      </StyledInner>
      {!hideButton && (
        <StyledButton type="button" onClick={toggleAccordion}>
          <StyledButtonInner>
            {isAccordionOpen ? '元に戻す' : 'もっと見る'}
          </StyledButtonInner>
        </StyledButton>
      )}
    </StyledWrapper>
  )
})

export default Caption

Caption.propTypes = {
  meta: PropTypes.shape({
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      avalis_OtherInstructions: PropTypes.string,
      evis_Actors: PropTypes.string,
      evis_Directors: PropTypes.string,
      evis_Producers: PropTypes.string,
      evis_Writers: PropTypes.string,
      evis_Productions: PropTypes.string
    }).isRequired
  }).isRequired,
  isOpen: PropTypes.bool,
  hideButton: PropTypes.bool
}

Caption.defaultProps = {
  isOpen: false,
  hideButton: false
}
