import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import window from 'global';
import NotFound from '../../../../generic/components/errors/NotFound';
import { PUBLISH_STATUS, SEASON_META_SCHEMA_IDS } from '../../../../../constants/app';
import webApp from '../../../utils/exdioWebAppUtils';
import namespace from '../../../../../common/namespace';

const { sprintf } = require('sprintf-js');

/** パックページ */
export default class PackContent extends Component {
  static propTypes = {
    productId: PropTypes.string
  };

  static defaultProps = {
    productId: ''
  };

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  static getPath(_models, _options, props) {
    return ['product', props.productId];
  }

  static getPrefetchPaths(models, options, props) {
    return [PackContent.getPath(models, options, props)];
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const product = _.get(prefetchResult, ['json', ...PackContent.getPath(models, options, props)]);
    const title = _.get(product, ['name']);
    const thumbnail = _.get(product, ['thumbnail_url']);
    const description = _.get(product, ['description']);
    return { title, thumbnail, description };
  }

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.config = context.models.config.data;

    const path = PackContent.getPath({}, {}, props);
    const product = this.model.getSync(path) || {};

    this.state = {
      product,
      series: null,
      season: null,
      right: {},
      rightMetas: [],
      episodes: [],
      products: [],
      packs: [],
      isNotFound: false,
      status: {
        isPurchseAvailable: product.active_pricing != null,
        isPurchased: false,
        isNotPurchased: false
      },
      loaded: false
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { productId } = this.props;
    if (!productId) {
      this.setState({ isNotFound: true });
      return;
    }

    let { product, isNotFound, season, status } = await this.getPack(productId).catch(e => webApp.utils.handleFalcorError(e, this.context));
    if (isNotFound) {
      this.setState({ isNotFound: true });
      return;
    }

    // スキーマに設定したシーズンを取得
    const series = await this.getSeries(season).catch(e => webApp.utils.handleFalcorError(e, this.context));

    // headタグのメタ情報を書き換えるだけで、setstateしないので、awaitしない
    this.updateMetaTags(product);
    // ライセンス取得
    status = await this.getIsPossible(product, status);
    // 権利を取得する
    const right = await this.getRight(productId).catch(e => webApp.utils.handleFalcorError(e, this.context));
    // rightに紐付くメタ(すべてでは無く権利に紐つけたメタのみ)
    const rightMetas = await this.getRightMetas(right).catch(e => webApp.utils.handleFalcorError(e, this.context));
    // rightに紐付くメタすべてを取得 ※多分これはもういらなくなる
    const episodes = await this.getEpisodes(right).catch(e => webApp.utils.handleFalcorError(e, this.context));
    // 関連パックを取得する
    const packs = await this.getPacks(product).catch(e => webApp.utils.handleFalcorError(e, this.context));

    this.setState({
      product,
      isNotFound,
      season,
      series,
      status,
      right,
      rightMetas,
      episodes,
      packs,
      loaded: true
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags(product) {
    // タイトルタグの更新
    const title = sprintf(this.config.title_template, product.name);
    webApp.utils.updateTitle(title);

    // メタタグの更新
    const { copyright } = this.config;
    const description = product.description || this.config.description;
    const keywords = this.config.keywords;
    const rootUrl = `${window.location.protocol}//${window.location.host}`;
    const ogImage = product.thumbnail_url || sprintf('%s/images/exdio/%s', rootUrl, this.config.og_image);
    const url = window.location.href;
    const regularUrl = url.replace(/\?.*$/, '');
    const removeAppUrl = regularUrl.replace('/app', '');

    // GTMの更新
    const [program] = title === undefined ? [''] : title.split(' | ');
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ];
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: ogImage },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'mixi:image', content: ogImage },
        { property: 'og:image', content: ogImage },
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: removeAppUrl }]
    };
    webApp.utils.updateMeta(metaTags);
    webApp.utils.updateDataLayer(gtmTags);
  }

  /** パック情報取得 */
  getPack(productId) {
    const path = [['product', productId]];
    return this.model.fetch(path).then(result => {
      const product = _.get(result, ['json', 'product', productId]) || {};
      const season = _.get(product, ['values', 'season']);
      if (this._isMounted) {
        return {
          product,
          isNotFound: !Object.keys(product).length,
          season,
          status: {
            isPurchseAvailable: product.active_pricing != null
          }
        };
      }
    });
  }

  /** 権利情報取得 */
  getRight(productId) {
    const path = [['product', 'rights', productId]];
    return this.model.fetch(path).then(result => {
      const rights = _.get(result, ['json', 'product', 'rights', productId], []);
      if (this._isMounted) {
        return rights[0];
      }
    });
  }

  /** 権利メタ取得 */
  getRightMetas(right) {
    const path = ['right', 'metas', right.right_id];
    return this.model.fetch([path]).then(result => {
      // TODO とり方変えたのでここの処理は考え直す
      const metas = _.get(result, ['json', ...path], []);
      // 公開以外も取得される&重複して取得されることがあるのでフィルター
      const metaIds = {};
      const rightMetas = metas.filter(meta => {
        const published = meta.publish_status === PUBLISH_STATUS.PUBLISHED;
        const duprecated = Object.keys(metaIds).includes(meta.meta_id.toString());
        metaIds[meta.meta_id] = null;
        return published && !duprecated;
      });

      /** rightMetasにシーズンメタが含まれる場合はシーズンIDを取得 */
      const seasonMetaIdArr = rightMetas.reduce((result, current) => {
        if (SEASON_META_SCHEMA_IDS.includes(current.meta_schema_id)) {
          result.push(current.meta_id)
        }
        return result
      }, [])

      // rightMetasにシーズンメタが含まれる場合の処理①〜④
      if (seasonMetaIdArr.length > 0) {
        /** ① rightMetasからシーズンメタを除外 */
        const filteredMetas = rightMetas.filter(meta => {
          return !seasonMetaIdArr.includes(meta.meta_id)
        })

        // ② 除外したシーズンメタの子要素エピソードをfalcor経由で取得
        const path = ['meta', 'children', seasonMetaIdArr]
        return this.context.falcorModel.fetch([path]).then(result => {
          const childrenMetas = _.get(result, ['json', 'meta', 'children'])

          /** ③ 取得した子要素エピソードと①で残ったエピソードを結合 */
          let episodeMetas = [...filteredMetas]
          seasonMetaIdArr.forEach(id => {
            episodeMetas = [...episodeMetas, ...childrenMetas[id]]
          })

          // ④ マウント後③の配列を返す
          if (this._isMounted) {
            return episodeMetas
          }
        })
      }

      // rightMetasに含まれるメタ情報が全てエピソードメタの時はそのまま返す。
      if (this._isMounted) {
        return rightMetas;
      }
    });
  }

  /** エピソード取得 */
  getEpisodes(right) {
    const path = [['right', 'metas', right.right_id]];
    return this.model.fetch(path).then(result => {
      const metas = _.get(result, ['json', 'right', 'metas', right.right_id], []);
      // 公開以外も取得されるのでフィルター
      const episodes = metas.filter(meta => meta.publish_status === PUBLISH_STATUS.PUBLISHED);
      return episodes;
    });
  }

  getSeries(season) {
    if (!season) return Promise.resolve();
    const path = [['meta', 'season', season.meta_id, 'parent']];
    return this.model.fetch(path).then(result => {
      if(!result) return null;
      const series = _.get(result.json, path[0]);
      if (this._isMounted) {
        return series;
      }
    });
  }

  /**
   * パック商品情報取得
   * 同一シーズンのエピソードを含むパックを取得
   */
  async getPacks(product) {
    let packs = _.get(product, ['values', 'packs', 'products']) || [];
    const packs_live = _.get(product, ['values', 'packs_live', 'products']) || [];
    packs = packs.concat(packs_live);
    if (!packs) return null;

    const res = [];
    for(const pack of packs) {
      const wPack = await this.getPack(pack.product_id).catch(e => webApp.utils.handleFalcorError(e, this.context));
      if (wPack.product) {
        res.push(wPack.product);
      }
    }

    return res;
  }

  /** 素材のライセンス有無取得 */
  getIsPossible(product, status) {
    if (!product) return Promise.resolve();

    const refId = product.ref_id;
    if (!refId) return Promise.reject(new Error('Error. refId is required.'));

    const isLoggedIn = webApp.utils.isLoggedIn(this.context);
    if (!isLoggedIn) {
      status.isNotPurchased = true;
      return status;
    }

    const path = [['infra', 'isPossible', refId]];
    return this.model.fetch(path).then(result => {
      const isPossible = _.get(result, ['json', 'infra', 'isPossible', refId]) || {};
      status.isPurchased = !!isPossible.status;
      status.isNotPurchased = !isPossible.status;
      status.limitDate = isPossible.limit_date;
      return status;
    });
  }

  render() {
    if (this.state.isNotFound) return <NotFound />;

    const { product, episodes, series, season, packs, right, rightMetas, status } = this.state;
    if (!product || !Object.keys(product).length) return null;

    // パックページはテンプレート利用対象
    const templateId = product.values.ex_templateId || 0;
    const templateKeyPrefix = 'pack_app_';
    const templateKey = `${templateKeyPrefix}${templateId}`;
    const template = namespace.templates[templateKey] || namespace.templates[`${templateKeyPrefix}0`];
    if (!template) {
      console.error(`Template:${templateKey} not found.`);
      return null;
    }

    const childProps = Object.assign({}, this.props, { product, episodes, series, season, packs, right, rightMetas, status });
    return React.createElement(template, childProps);
  }
}
