import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../utils/exdioWebAppUtils'
import routes from '../../../../../common/routes'

/* style */
import {
  StyledUl,
  StyledLi,
  StyledLink,
  StyledDiv1,
  StyledImg,
  StyledDiv2
} from './style'

const FETCH_COUNT = 20

/** Logirl live配信リストコンポーネント */
const LogirlLiveList = (props, context) => {
  const [objects, setObjects] = useState([])

  useEffect(() => {
    getLiveProgramPalettes()
  }, [])

  /** LIVE番組パレット取得 */
  const getLiveProgramPalettes = () => {
    const paletteId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 74
      : 104
    const path = [['palette', [paletteId]]]
    context.falcorModel.fetch(path).then((result) => {
      const palettesObj = _.get(result, ['json', 'palette', paletteId], {})
      setObjects(palettesObj.objects)
    })
  }

  if (objects.length === 0) return null
  return (
    <StyledUl>
      {objects &&
        objects.map((program, i) => {
          const meta = program.meta
          const seriesId = _.get(meta, ['values', 'parents_series', 'id'])
          const seasonId = _.get(meta, ['meta_id'])
          const params = { seriesId, seasonId }

          /* 読み込む画像サイズを変更する */
          const thumbUrl = webApp.utils.customSizeImageUrl(
            meta.thumbnail_url,
            'medium'
          )

          if (!(seriesId && seasonId)) return null
          return (
            <StyledLi key={i}>
              <StyledLink route={routes.program} params={params}>
                <StyledDiv1>
                  <StyledImg src={thumbUrl} alt={meta.name} />
                </StyledDiv1>
                <StyledDiv2>{meta.name}</StyledDiv2>
              </StyledLink>
            </StyledLi>
          )
        })}
    </StyledUl>
  )
}

export default LogirlLiveList

LogirlLiveList.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  routeHandler: PropTypes.object
}
