import React from 'react';
import PropTypes from 'prop-types';
import LoadingBox from './LoadingBox';

export default class LoadingTitle extends React.PureComponent {

  static propTypes = {
    lockupClassName: PropTypes.string,
    delay: PropTypes.number,
    displayWhenNotPulsing: PropTypes.bool,
    pulsate: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    delay: 0,
    displayWhenNotPulsing: true,
    pulsate: false,
  };

  constructor(props, context) {
    super(props, context);
  }

  render() {
    let loadingBox = React.createElement(LoadingBox, Object.assign({boxClassNames: {'ratio-16x9': true}}, this.props));
    if (this.props.lockupClassName) {
      loadingBox = React.createElement('div', {className: this.props.lockupClassName}, loadingBox)
    }

    return React.createElement('div', {
      className: "smallTitleCard loadingTitle",
    }, loadingBox);
  }
}
