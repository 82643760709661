import React, { createContext } from 'react'
import PropTypes from 'prop-types'

const defaultProps = {
  seriesId: '',
  seasonId: '',
  episodeId: '',
  meta: {},
  programMeta: {},
  howToPlay: {},
  episodes: [],
  product: {},
  course: {},
  status: {
    isFree: false,
    isNotFree: false,
    isPurchased: false,
    isNotPurchased: false,
    isInCourse: false,
    isNotInCourse: false,
    isGeoDeliverable: null,
    isDeviceNotAvailable: null,
    limitDate: null,
    isPossible: null,
    isBelonging: null
  },
  sendPlayLog: () => {},
  loaded: true
}

export const EpisodeContext = createContext(defaultProps)

export const EpisodeProvider = ({ value = defaultProps, children = null }) => (
  <EpisodeContext.Provider value={value}>{children}</EpisodeContext.Provider>
)

EpisodeProvider.propTypes = {
  value: PropTypes.shape({
    /** 動画のシリーズID */
    seriesId: PropTypes.string,
    /** 動画のシーズンID */
    seasonId: PropTypes.string,
    /** 動画のエピソードID */
    episodeId: PropTypes.string,

    /** 動画のメタ情報 */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    /** シーズンのメタ情報 */
    programMeta: PropTypes.object,
    /** 動画の視聴権利関連情報 */
    howToPlay: PropTypes.object,
    /** 動画の価格、配信期間情報など */
    product: PropTypes.shape({
      product_id: PropTypes.number,
      name: PropTypes.string,
      original_price: PropTypes.number,
      active_pricing: PropTypes.shape({
        price: PropTypes.number,
        unit: PropTypes.string
      })
    }),

    // 通常単話
    /** 関連動画情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    // 月額見放題
    /** 動画のコース情報 */
    course: PropTypes.shape({
      course_id: PropTypes.number,
      schema_id: PropTypes.number,
      name: PropTypes.string,
      active_pricing: PropTypes.object,
      values: PropTypes.object
    }),

    /** 動画の視聴ステータス情報 */
    status: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      isDeviceNotAvailable: PropTypes.bool,
      limitDate: PropTypes.string,
      isPossible: PropTypes.bool,
      isBelonging: PropTypes.bool
    }),
    /** 動画再生時のログ送信 */
    sendPlayLog: PropTypes.func,
    /** データの取得が終わっているか */
    loaded: PropTypes.bool
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
}
