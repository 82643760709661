import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import ListItem from '../ListItem'
import { StyledLi, StyledUl } from './style'

/** Section4｜リスト */
const List = ({ nextLive = {}, archive = {} }, context) => {
  const [nextLiveText, setNextLiveText] = useState('次回の生配信')
  const [nextLiveThumbnail, setNextLiveThumbnail] = useState('')

  useEffect(() => {
    getNextLiveJson()
  }, [nextLive])

  /**
   * 次回の生配信部分で表示する情報の取得
   * Dioシーズン管理画面で変更可能
   */
  const getNextLiveJson = () => {
    if (!nextLive) return

    const seasonId = _.get(nextLive, ['values', 'parents_season', 'id'])
    const dateTime = Math.floor(new Date().getTime() / 1000)
    api.get(`/metas/${seasonId}?date=${dateTime}`).then((res) => {
      const _nextLiveText = _.get(res, [
        'data',
        'values',
        'season_front_display_config',
        'planPageText'
      ])
      const _nextLiveThumbnail = _.get(res, [
        'data',
        'values',
        'season_front_display_config',
        'thumbnail'
      ])
      setNextLiveText(_nextLiveText || nextLiveText)
      setNextLiveThumbnail(_nextLiveThumbnail || nextLiveThumbnail)
    })
  }

  const api = axios.create({
    baseURL: context.models.config.data.service.endpoint,
    timeout: context.models.config.data.service.timeout
  })

  let listItems = []
  if (Object.keys(nextLive).length > 0) {
    listItems.push({
      isHide: false,
      meta: nextLive,
      src: nextLiveThumbnail,
      title: nextLiveText
    })
  }

  if (Object.keys(archive).length > 0) {
    listItems.push({
      isHide: !archive,
      meta: archive,
      src: archive.thumbnail_url,
      title: '前回の生配信 アーカイブ'
    })
  }

  return (
    <StyledUl>
      {listItems.map((item, i) => {
        if (item.isHide) return false

        return (
          <StyledLi key={i}>
            <ListItem {...item} />
          </StyledLi>
        )
      })}
    </StyledUl>
  )
}

export default List

List.propTypes = {
  /** 次回配信動画情報をAPIを叩いて取得するために使用 */
  nextLive: PropTypes.object,
  /** 前回配信の情報 */
  archive: PropTypes.object
}

List.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func
}
