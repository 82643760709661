import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import window from 'global'
import classnames from 'classnames'
import ProgramItemLink from './ProgramItemLink'
import webApp from '../../../exdio/utils/exdioWebAppUtils'
import * as DOMUtils from '../../../utils/DOMUtils'
import {
  LOCAL_STORAGE_KEY_DORAEMON_PAGER,
  LOCAL_STORAGE_KEY_DORAEMON_SORT,
  LOCAL_STORAGE_KEY_DORAEMON_FILTER
} from '../../../../constants/app'
import ProgramItemSkeleton from './ProgramItemSkeleton' // スケルトンスクリーン
import Pager from './Pager2/' // ページャー

/**
 * ドラえもん用 エピソードリスト
 * ブラウザ・アプリドラえもん番組・単話　ページで使用
 * リスト表示切り替えコンポーネント
 */
export default class SwitchableListGridAndFilter extends Component {
  static propTypes = {
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        meta_schema_id: PropTypes.number.isRequired,
        thumbnail_url: PropTypes.string,
        values: PropTypes.object.isRequired,
        name: PropTypes.string,
        duration: PropTypes.number,
        delivery_start_at: PropTypes.string,
        delivery_end_at: PropTypes.string
      })
    ),
    rootMetas: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    placeholder: PropTypes.string,
    showNew: PropTypes.bool,
    showChecked: PropTypes.bool,
    showDelivery: PropTypes.bool,
    onlySubTitle: PropTypes.bool,
    showPagerUnder: PropTypes.bool,
    seasonId: PropTypes.number
  }

  static defaultProps = {
    episodes: [],
    rootMetas: null,
    className: '',
    placeholder: '',
    showNew: false,
    showChecked: false,
    showDelivery: false,
    onlySubTitle: false,
    showPagerUnder: false,
    loaded: false,
    seasonId: null
  }

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func,
    routeHandler: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.state = {
      filteredEpisodes: [],
      listType: props.listType,
      sortBy: 'delivery_start_at_newer',
      filteredBy: '',
      searchWord: '',
      onlySubTitle: props.onlySubTitle,
      currentPageNum: 1,
      isAccordionOpen: false,
      selectedYear: null,
      rectY: 0,
      isOpenFilterList: false,
      isOpenSortList: false
    }

    this.filteredByDefaultLabel = 'フィルタ'
    this.sortByDefaultLabel = '並び替え'

    this.updateCurrentPageNum = this.updateCurrentPageNum.bind(this)
    this.execSearch = this.execSearch.bind(this)
    this.getEpisodeFilteredByWord = this.getEpisodeFilteredByWord.bind(this)
    this.episodeSorter = this.episodeSorter.bind(this)
    this.episodeFilterer = this.episodeFilterer.bind(this)
    this.renderSearchBoxText = this.renderSearchBoxText.bind(this)
    this.renderFilterElm = this.renderFilterElm.bind(this)
    this.renderSortElm = this.renderSortElm.bind(this)
    this.renderSwitchElm = this.renderSwitchElm.bind(this)
    this.renderProgramList = this.renderProgramList.bind(this)
    this.renderSkeletons = this.renderSkeletons.bind(this)

    this.setListRef = (e) => {
      this.listRef = e
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const { seasonId } = this.props
    const DoraemonSeasonId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 11003
      : 13509

    // 初期化
    if (seasonId === DoraemonSeasonId) {
      this.remindValue()
    } else {
      this.execSearch()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { episodes, seasonId } = this.props
    const DoraemonSeasonId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 11003
      : 13509

    // 親のepisodeが更新されたとき検索を再実行する(初期化)
    if (prevProps.episodes !== episodes) {
      if (seasonId === DoraemonSeasonId) {
        this.remindValue()
      } else {
        this.execSearch()
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** ・ページャー、ソート、フィルタ維持 */
  remindValue() {
    const { episodes } = this.props
    const { sortBy } = this.state

    //ローカルストレージの値取得
    const StoragePager = parseInt(
      window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_PAGER)
    )
    const StorageSort =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_SORT) || sortBy
    const StorageFilter =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_FILTER) || ''

    if (episodes && episodes.length > 0) {
      //1ページ以上単話読み込み後
      this.setState(
        {
          sortBy: StorageSort,
          filteredBy: StorageFilter
        },
        async () => {
          await this.firstSearch()
          const totalPages = Math.ceil(this.state.filteredEpisodes.length / 60)
          if (StoragePager <= totalPages) {
            //ストレージにあるページ番号よりもフィルタリング後のページ総数が多い場合のみ実行
            this.setState({ currentPageNum: StoragePager })
          }
        }
      )
    }
  }

  firstSearch() {
    const { episodes } = this.props
    let filteredEpisodes = []

    if (episodes && episodes.length > 0) {
      filteredEpisodes = this.getEpisodeFilteredByWord(episodes) // 建枠ワードフィルタ
      filteredEpisodes = this.episodeFilterer(filteredEpisodes) // 視聴状況フィルタ
      filteredEpisodes = this.episodeSorter(filteredEpisodes) // 条件別ソート
    }

    this.setState({ filteredEpisodes })
  }

  /**
   * 検索実行
   * 各種フィルタを実装後、ソートして順番を変更する
   */
  execSearch() {
    const { episodes, seasonId } = this.props
    const DoraemonSeasonId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 11003
      : 13509
    let filteredEpisodes = []

    if (episodes && episodes.length > 0) {
      filteredEpisodes = this.getEpisodeFilteredByWord(episodes) // 建枠ワードフィルタ
      filteredEpisodes = this.episodeFilterer(filteredEpisodes) // 視聴状況フィルタ
      filteredEpisodes = this.episodeSorter(filteredEpisodes) // 条件別ソート
    }

    // フィルターにかけたEpisodeをセット、1ページ目を表示
    this.setState({ filteredEpisodes, currentPageNum: 1 }, () => {
      if (seasonId === DoraemonSeasonId) {
        window.localStorage.setItem(
          //ページャーをローカルストレージにset
          LOCAL_STORAGE_KEY_DORAEMON_PAGER,
          1
        )
      }
    })
  }

  /** 検索ワードによる検索 */
  getEpisodeFilteredByWord(episodes) {
    const { searchWord } = this.state

    /** 全角->半角変換、大文字->小文字変換 */
    const cleanseWord = (str) => {
      return str
        .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) =>
          String.fromCharCode(s.charCodeAt(0) - 65248)
        )
        .toLowerCase()
    }

    /** ひらがな->カタカナ */
    const hiraToKana = (str) => {
      return str.replace(/[\u3041-\u3096]/g, function(match) {
        var chr = match.charCodeAt(0) + 0x60
        return String.fromCharCode(chr)
      })
    }

    const searchWordArr = cleanseWord(searchWord).split(/\s/) // 空白文字で区切って配列にする

    /** フィルタリングしたエピソードを格納 */
    const filteredEpisodes = (episodes || []).filter((meta) => {
      for (const word of searchWordArr) {
        const searchWord = cleanseWord(hiraToKana(word)) // 整形した
        const cleanseMetaName = cleanseWord(hiraToKana(meta.name))
        const cleanseMetaRuby = cleanseWord(hiraToKana(meta.name_ruby))
        // タイトルorフリガナに該当しない場合は候補から外す
        if (
          !cleanseMetaName.includes(searchWord) &&
          !cleanseMetaRuby.includes(searchWord)
        )
          return false
      }

      // 候補から外れなかったエピソードを残す
      return true
    })

    return filteredEpisodes
  }

  /** テキスト検索 */
  renderSearchBoxText() {
    const { placeholder } = this.props
    const { searchWord } = this.state

    /** 検索ワード変更時 */
    const onChangeSearchWord = (e) => {
      this.setState({ searchWord: e.target.value }, () => {
        this.execSearch()
      })
    }

    /** 検索ワードクリア時 */
    const onClearSearchWord = () => {
      this.setState({ searchWord: '' }, () => {
        this.execSearch()
      })
    }

    return (
      <div className="search-input">
        <input
          type="text"
          value={searchWord}
          placeholder={placeholder}
          onChange={onChangeSearchWord}
        />
        <span className="icon-close" onClick={onClearSearchWord} />
      </div>
    )
  }

  /** フィルタ機能(描画) */
  renderFilterElm() {
    const { seasonId } = this.props
    const { filteredBy, isOpenFilterList } = this.state
    const DoraemonSeasonId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 11003
      : 13509

    const options = [
      {
        value: '',
        label: 'すべて'
      },
      {
        value: 'watched',
        label: '視聴済み'
      },
      {
        value: 'not_watched',
        label: '未視聴'
      }
    ]

    const currentOption = _.get(
      options.filter((option) => option.value === filteredBy),
      0
    )

    const onClickHandler = () => {
      if (isOpenFilterList === true && this.filteredByDefaultLabel !== '') {
        this.filteredByDefaultLabel = ''
      }

      this.setState({ isOpenFilterList: !isOpenFilterList })
    }

    const onChangeHandler = (value) => {
      this.setState({ filteredBy: value }, () => this.execSearch())
      if (seasonId === DoraemonSeasonId) {
        window.localStorage.setItem(
          //選択したフィルターをローカルストレージにset
          LOCAL_STORAGE_KEY_DORAEMON_FILTER,
          value
        )
      }
    }

    return (
      <div className="c-sl_list1__wrapper--shorter">
        <ul
          className={classnames('c-sl_list1', { 'is-open': isOpenFilterList })}
        >
          <li
            className="c-sl_list1__item c-sl_radio1__label"
            onClick={onClickHandler}
          >
            {this.filteredByDefaultLabel || currentOption.label}
          </li>
          {options.map((option, i) => {
            return (
              <li key={i} className="c-sl_list1__item c-sl_radio1__wrapper">
                <input
                  type="radio"
                  name="filter"
                  value={option.value}
                  id={`fFilter${i}`}
                  className="c-sl_radio1"
                  checked={filteredBy === option.value}
                  onChange={() => {
                    onChangeHandler(option.value)
                  }}
                  onClick={onClickHandler}
                />
                <label htmlFor={`fFilter${i}`} className="c-sl_radio1__label">
                  {option.label}
                </label>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  /** ソート機能(描画) */
  renderSortElm() {
    const { courseId, seasonId } = this.props
    const { sortBy, isOpenSortList } = this.state
    const logirlId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 4
      : 3 //logirl番組・単話だけは放送・あいうえお順を除く
    const DoraemonSeasonId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 11003
      : 13509

    let options

    const optionsDefault = [
      {
        value: 'delivery_start_at_newer',
        label: '配信が新しい順'
      },
      {
        value: 'delivery_start_at_older',
        label: '配信が古い順'
      },
      {
        value: 'avails_release_history_original_newer',
        label: '放送が新しい順'
      },
      {
        value: 'avails_release_history_original_older',
        label: '放送が古い順'
      },
      {
        value: 'jp_syllabary_order_asc',
        label: 'あいうえお順(昇順)'
      },
      {
        value: 'jp_syllabary_order_desc',
        label: 'あいうえお順(降順)'
      },
      {
        value: 'play_time_longer',
        label: '動画が長い順'
      },
      {
        value: 'play_time_shorter',
        label: '動画が短い順'
      }
    ]

    const optionsLogirl = [
      {
        value: 'delivery_start_at_newer',
        label: '配信が新しい順'
      },
      {
        value: 'delivery_start_at_older',
        label: '配信が古い順'
      },
      {
        value: 'play_time_longer',
        label: '動画が長い順'
      },
      {
        value: 'play_time_shorter',
        label: '動画が短い順'
      }
    ]

    options = courseId === logirlId ? optionsLogirl : optionsDefault

    const currentOption = _.get(
      options.filter((option) => option.value === sortBy),
      0
    )

    const onClickHandler = () => {
      if (isOpenSortList === true && this.sortByDefaultLabel !== '') {
        this.sortByDefaultLabel = ''
      }
      this.setState({ isOpenSortList: !isOpenSortList })
    }

    const onChangeHandler = (value) => {
      this.setState({ sortBy: value }, () => this.execSearch())
      if (seasonId === DoraemonSeasonId) {
        window.localStorage.setItem(
          //選択したソートをローカルストレージにset
          LOCAL_STORAGE_KEY_DORAEMON_SORT,
          value
        )
      }
    }

    return (
      <div className="c-sl_list1__wrapper">
        <ul className={classnames('c-sl_list1', { 'is-open': isOpenSortList })}>
          <li
            className="c-sl_list1__item c-sl_radio1__label"
            onClick={onClickHandler}
          >
            {this.sortByDefaultLabel || currentOption.label}
          </li>
          {options.map((option, i) => {
            return (
              <li key={i} className="c-sl_list1__item c-sl_radio1__wrapper">
                <input
                  type="radio"
                  name="sort"
                  value={option.value}
                  id={`fSort${i}`}
                  className="c-sl_radio1"
                  checked={sortBy === option.value}
                  onChange={() => {
                    onChangeHandler(option.value)
                  }}
                  onClick={onClickHandler}
                />
                <label htmlFor={`fSort${i}`} className="c-sl_radio1__label">
                  {option.label}
                </label>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  /** リストタイプの切り替え */
  renderSwitchElm() {
    const { listType } = this.state
    const types = ['default', 'list', 'grid']

    /** 表示するリストタイプの変更 */
    const onClickHandler = (type) => {
      this.setState({ listType: type })
    }

    return (
      <div className="c-sortSwitch">
        <ul className="c-sortSwitch-inBox">
          {types.map((type, i) => (
            <li
              key={i}
              className={classnames('c-sortSwitch-inBox-btn', {
                current: listType === type,
                'with-thumb': type === 'default',
                'no-thumb': type === 'list',
                grid: type === 'grid'
              })}
            >
              <span
                className="c-sortSwitch-inBox-btn-link"
                onClick={() => onClickHandler(type)}
              />
            </li>
          ))}
        </ul>
      </div>
    )
  }

  isWithinDistanceBuffer() {
    if (!this.listRef) return false
    return DOMUtils.getDistanceToBottomOfElement(this.listRef) < 0
  }

  /**
   * エピソードをデフォルトのソート順に並べ替えて返却する
   * エピソード番号(昇順or降順) > AVOD->TVOD(固定) > 配信開始日時(昇順or降順) > ID(昇順or降順)
   * @props episodes
   */
  sortEpisodesDefault(episodes = []) {
    if ((episodes || []).length < 1) return []

    // 配列の破壊を防ぐため複製
    let sortedEpisodes = episodes.slice()

    const { disp_order } = this.props
    const direction = disp_order == 'asc' ? -1 : 1

    sortedEpisodes.sort((a, b) => {
      //ソート番号 -> 管理画面の昇順or降順に合わせる
      if (a.sort < b.sort) return direction * 1
      if (a.sort > b.sort) return direction * -1

      // エピソード番号 -> 管理画面の昇順or降順に合わせる
      if (
        Number(a.values.avails_EpisodeNumber) <
        Number(b.values.avails_EpisodeNumber)
      )
        return direction * 1
      if (
        Number(a.values.avails_EpisodeNumber) >
        Number(b.values.avails_EpisodeNumber)
      )
        return direction * -1

      // メタスキーマIDの昇順(無料, 有料の順) -> 固定
      if (a.meta_schema_id < b.meta_schema_id) return -1
      if (a.meta_schema_id > b.meta_schema_id) return 1

      // 配信開始日時 -> 管理画面の昇順or降順に合わせる
      if (a.delivery_start_at < b.delivery_start_at) return direction * 1
      if (a.delivery_start_at > b.delivery_start_at) return direction * -1

      // ID -> 管理画面の昇順or降順に合わせる
      if (a.id < b.id) return direction * 1
      if (a.id > b.id) return direction * -1

      return 0
    })

    return sortedEpisodes
  }

  // 子コンポーネントからのからのアクセス用
  updateCurrentPageNum(currentPageNum) {
    const { seasonId } = this.props
    const DoraemonSeasonId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 11003
      : 13509
    this.setState({ currentPageNum: currentPageNum }, () => {
      if (seasonId === DoraemonSeasonId) {
        window.localStorage.setItem(
          //現在のページャーをローカルストレージにset
          LOCAL_STORAGE_KEY_DORAEMON_PAGER,
          this.state.currentPageNum
        )
      }
    })
  }

  /** フィルター機能 */
  episodeFilterer(episodes = []) {
    const { filteredBy } = this.state

    let filteredEpisodes = []
    switch (filteredBy) {
      case 'watched': //視聴済み
        filteredEpisodes = episodes.filter((episode) => {
          return webApp.utils.isWatched(episode)
        })
        break
      case 'not_watched': //未視聴
        filteredEpisodes = episodes.filter((episode) => {
          return !webApp.utils.isWatched(episode)
        })
        break
      default:
        filteredEpisodes = episodes
        break
    }

    return filteredEpisodes
  }

  /** ソート機能 */
  episodeSorter(episodes = []) {
    const { sortBy } = this.state

    let sortedEpisodes = []
    switch (sortBy) {
      case 'delivery_start_at_newer': // 配信が新しい順
        sortedEpisodes = episodes.sort((a, b) => {
          const dateA = new Date(_.get(a, ['delivery_start_at'])).getTime()
          const dateB = new Date(_.get(b, ['delivery_start_at'])).getTime()
          if (dateA === dateB) {
            // 同じ値の場合はエピソード順でソート
            return parseInt(_.get(a, ['values', 'avails_EpisodeNumber'])) >
              parseInt(_.get(b, ['values', 'avails_EpisodeNumber']))
              ? 1
              : -1
          } else {
            return dateA > dateB ? -1 : 1
          }
        })
        break

      case 'delivery_start_at_older': // 配信が古い順
        sortedEpisodes = episodes.sort((a, b) => {
          const dateA = new Date(_.get(a, ['delivery_start_at'])).getTime()
          const dateB = new Date(_.get(b, ['delivery_start_at'])).getTime()
          if (dateA === dateB) {
            // 同じ値の場合はエピソード順でソート
            return parseInt(_.get(a, ['values', 'avails_EpisodeNumber'])) >
              parseInt(_.get(b, ['values', 'avails_EpisodeNumber']))
              ? 1
              : -1
          } else {
            return dateA > dateB ? 1 : -1
          }
        })
        break

      case 'avails_release_history_original_newer': // 放送が新しい順
        sortedEpisodes = episodes.sort((a, b) => {
          const dateA = new Date(
            _.get(a, ['values', 'avails_ReleaseHistoryOriginal'])
          ).getTime()
          const dateB = new Date(
            _.get(b, ['values', 'avails_ReleaseHistoryOriginal'])
          ).getTime()
          if (dateA === dateB) {
            // 同じ値の場合はエピソード順でソート
            return parseInt(_.get(a, ['values', 'avails_EpisodeNumber'])) >
              parseInt(_.get(b, ['values', 'avails_EpisodeNumber']))
              ? 1
              : -1
          } else {
            return dateA > dateB ? -1 : 1
          }
        })
        break

      case 'avails_release_history_original_older': // 放送が古い順
        sortedEpisodes = episodes.sort((a, b) => {
          const dateA = new Date(
            _.get(a, ['values', 'avails_ReleaseHistoryOriginal'])
          ).getTime()
          const dateB = new Date(
            _.get(b, ['values', 'avails_ReleaseHistoryOriginal'])
          ).getTime()
          if (dateA === dateB) {
            // 同じ値の場合はエピソード順でソート
            return parseInt(_.get(a, ['values', 'avails_EpisodeNumber'])) >
              parseInt(_.get(b, ['values', 'avails_EpisodeNumber']))
              ? 1
              : -1
          } else {
            return dateA > dateB ? 1 : -1
          }
        })
        break

      case 'jp_syllabary_order_asc': // あいうえお順（昇順）
        sortedEpisodes = episodes.sort(
          (a, b) =>
            _.get(a, ['name_ruby']).localeCompare(_.get(b, ['name_ruby'])),
          'ja'
        )
        break

      case 'jp_syllabary_order_desc': // あいうえお順（降順）
        sortedEpisodes = episodes.sort(
          (a, b) =>
            _.get(b, ['name_ruby']).localeCompare(_.get(a, ['name_ruby'])),
          'ja'
        )
        break

      case 'play_time_longer': // 動画が長い順
        sortedEpisodes = episodes.sort((a, b) => {
          const durationA = _.get(a, ['values', 'duration'])
          const durationB = _.get(b, ['values', 'duration'])
          if (durationA === durationB) {
            // 同じ値の場合はエピソード順でソート
            return parseInt(_.get(a, ['values', 'avails_EpisodeNumber'])) >
              parseInt(_.get(b, ['values', 'avails_EpisodeNumber']))
              ? 1
              : -1
          } else {
            return durationA > durationB ? -1 : 1
          }
        })
        break

      case 'play_time_shorter': // 動画が短い順
        sortedEpisodes = episodes.sort((a, b) => {
          const durationA = _.get(a, ['values', 'duration'])
          const durationB = _.get(b, ['values', 'duration'])
          if (durationA === durationB) {
            // 同じ値の場合はエピソード順でソート
            return parseInt(_.get(a, ['values', 'avails_EpisodeNumber'])) >
              parseInt(_.get(b, ['values', 'avails_EpisodeNumber']))
              ? 1
              : -1
          } else {
            return durationA > durationB ? 1 : -1
          }
        })
        break

      default:
        sortedEpisodes = this.sortEpisodesDefault(episodes)
        break
    }

    return sortedEpisodes
  }

  /** エピソードリストの描画 */
  renderProgramList() {
    const {
      howToPlays,
      showNew,
      showDelivery,
      showChecked,
      pagerOptions
    } = this.props
    const {
      filteredEpisodes,
      onlySubTitle,
      selectedYear,
      currentPageNum
    } = this.state
    const isApp = webApp.utils.isApp(this.context)

    // ページャー関連
    const episodesPerPages = _.get(pagerOptions, ['episodesPerPages']) // 1ページあたりに表示するエピソード数
    const startEpisodeNum = episodesPerPages * (currentPageNum - 1) // 何件目から表示するか
    const endEpisodeNum = startEpisodeNum + episodesPerPages // 何件目まで表示するか

    return (
      <div className="c-card-vertical-cont" ref={this.setListRef}>
        {(filteredEpisodes || []).length > 0 &&
          filteredEpisodes.slice(startEpisodeNum, endEpisodeNum).map((meta) => {
            //放送年が選択されている場合は新規タブで単話ページを開く
            //アプリの場合はblankはつけない
            const blank = isApp
              ? () => webApp.utils.goToProgramLink(this.context, meta)
              : () => webApp.utils.goToProgramLinkBlank(this.context, meta)
            const notBlank = () =>
              webApp.utils.goToProgramLink(this.context, meta)
            const clickCaption = isApp
              ? () =>
                  webApp.utils.goToProgramLink(this.context, meta, null, null, {
                    autoPlay: false
                  })
              : () =>
                  webApp.utils.goToProgramLinkBlank(
                    this.context,
                    meta,
                    null,
                    null,
                    { autoPlay: false }
                  )
            const { route, params, query } = webApp.utils.getProgramLinkRoutes(
              this.context,
              meta
            )

            return (
              <ProgramItemLink
                key={meta.meta_id}
                meta={meta}
                howToPlay={
                  howToPlays && howToPlays[meta.meta_id]
                    ? howToPlays[meta.meta_id]
                    : null
                }
                showCaption
                showNew={showNew && webApp.utils.showNew(meta)}
                showChecked={showChecked && webApp.utils.isWatched(meta)}
                showDelivery={showDelivery}
                showCoin
                onlySubTitle={onlySubTitle}
                route={route}
                params={params}
                query={query}
              />
            )
          })}
      </div>
    )
  }

  /** スケルトンスクリーン */
  renderSkeletons() {
    const { pagerOptions } = this.props
    let skeletonObj = []
    for (let i = 0; i < pagerOptions.episodesPerPages; i++) {
      skeletonObj.push(
        <ProgramItemSkeleton
          key={i}
          hasThumb={true}
          titleLength={1}
          textLength={2}
          hasPrice={true}
        />
      )
    }
    return (
      <div className="c-card-vertical-cont" ref={this.setListRef}>
        {skeletonObj}
      </div>
    )
  }

  render() {
    const { className, pagerOptions, showPagerUnder, loaded } = this.props
    const { filteredEpisodes, listType, currentPageNum } = this.state

    // コンポーネント
    const SearchBoxText = this.renderSearchBoxText
    const FilterElm = this.renderFilterElm
    const SortElm = this.renderSortElm
    const SwitchElm = this.renderSwitchElm
    const ProgramList = this.renderProgramList
    const Skeletons = this.renderSkeletons

    return (
      <div className="c-switchableListGrid--shinchan">
        <div className="c-cards-head">
          <h3 className="c-cards-head-hedding">単話</h3>
        </div>
        <div
          className={`c-card-vertical ${className} ${listType}`}
          id="episodes-list"
        >
          <Pager
            option={pagerOptions}
            episodeLength={filteredEpisodes.length}
            currentPageNum={currentPageNum}
            updateCurrentPageNum={this.updateCurrentPageNum}
          />
          <header className="c-card-vertical-head">
            <div className="c-card-vertical-head-inner">
              <div className="common-search-box">
                <SearchBoxText />
              </div>
            </div>
            <div className="c-card-vertical-head-inner">
              <SortElm />
              <FilterElm />
              <SwitchElm />
            </div>
          </header>

          {/* 親要素のloadedがtrueに変更された場合 or 既にエピソードが存在する場合 */}
          {loaded === true || filteredEpisodes.length > 0 ? (
            <ProgramList />
          ) : (
            <Skeletons />
          )}

          {showPagerUnder && (
            <Pager
              option={pagerOptions}
              episodeLength={filteredEpisodes.length}
              currentPageNum={currentPageNum}
              updateCurrentPageNum={this.updateCurrentPageNum}
              clickScroll
            />
          )}
        </div>
      </div>
    )
  }
}
