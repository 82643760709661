import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import convertTimeString from '../functions/convertTimeString'
import { skyBlue } from '../styles'

const Label = ({ isNew, duration, className }) => {
  const time = convertTimeString(duration)
  return (
    <StyledDiv className={className}>
      <div>
        <StyledSpan backgroundColor="#fa6400">Free</StyledSpan>
        {isNew && <StyledSpan backgroundColor="#DE1A96">New!</StyledSpan>}
      </div>
      <div>
        <StyledSpan backgroundColor={skyBlue} autoWidth={true}>
          {time}
        </StyledSpan>
      </div>
    </StyledDiv>
  )
}

export default Label

Label.propTypes = {
  /** 最新動画か */
  isNew: PropTypes.bool,
  className: PropTypes.string
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledSpan = styled.span`
  display: inline-block;
  color: #fff;
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '40px')};
  min-width: 40px;
  line-height: 1;
  font-size: 10px;
  text-align: center;
  padding: 2px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
