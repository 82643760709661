import React, { memo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import styled, { css } from 'styled-components'
import { size, includes } from 'lodash'
import { PANEL_ID } from '../../../config'

/* hooks */
import useMediaQuery from '../../../../../../../hooks/useMediaQuery'
import useIsMounted from '../../../../../../../hooks/useIsMounted'

/** style */
import { mediaSp } from '../../../styles'

const Character = (
  {
    sliderItems = [],
    selectedTabId = '',
    selectedTags = [],
    setSelectedTags = () => {},
    ...props
  },
  context
) => {
  const isMounted = useIsMounted()
  const isSp = useMediaQuery()
  const sliderRef = useRef(null)
  const isCurrentPanel = selectedTabId === PANEL_ID.CHARACTER
  const initialSlide = context.routeHandler.query.id - 1

  /** スライダー設定 */
  const sliderSetting = {
    dots: false,
    infinite: false,
    speed: 400,
    arrows: true,
    slidesToShow: isSp ? 2 : 7,
    slidesToScroll: isSp ? 2 : 1,
    initialSlide: isCurrentPanel && initialSlide ? initialSlide : 0,
    variableWidth: isSp
  }

  /** タグが変更されたとき履歴とパラメータの書き換えをする */
  const onChangeTags = (sliderItem = {}) => {
    const { id = null, name = '' } = sliderItem
    if (name) setSelectedTags([name])

    const { routeHandler } = context
    const { query, params } = routeHandler
    const { slug } = params

    /**
     * 履歴の追加、パスパラメータの書き換え
     * // NOTE
     * contextが書き換えられると再レンダリングされるため
     * context.history.replaceは使えない
     */
    const path = new URLSearchParams({ ...query, id }).toString()
    const url = `${slug}?${path}`
    window.history.replaceState(null, '', url)
  }

  useEffect(() => {
    if (isMounted && isCurrentPanel && sliderRef.current) {
      sliderRef.current.slickGoTo(0)
    }
  }, [selectedTabId])

  if (!isMounted) return null

  return (
    <StyledDiv {...props}>
      <Slider ref={sliderRef} {...sliderSetting}>
        {size(sliderItems) > 0 &&
          sliderItems.map(({ id = null, name = '', src = '' }) => (
            <StyledLabel
              key={id}
              htmlFor={`character-${id}`}
              checked={includes(selectedTags, name)}
            >
              <input
                type="radio"
                id={`character-${id}`}
                name="character"
                value={name}
                checked={includes(selectedTags, name)}
                onChange={() => onChangeTags({ id, name })}
              />
              <StyledImg src={src} alt={name} loading="lazy" />
            </StyledLabel>
          ))}
      </Slider>
    </StyledDiv>
  )
}

export default memo(Character)

Character.propTypes = {
  sliderItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      src: PropTypes.string
    })
  ),
  selectedTabId: PropTypes.string,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  setSelectedTags: PropTypes.func
}

Character.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}

const StyledDiv = styled.div`
  .slick {
    &-track {
      display: flex;
    }

    &-slide {
      padding: 0 10px;
      display: block;
      position: relative;
    }

    &-arrow {
      margin-top: -22px;
      width: 48px;
      height: 48px;
      box-shadow: 0px 3px 0px 0px #fcdb29;
      border-radius: 90px;
      position: absolute;
      top: 50%;
      z-index: 10;
      transform: none;
      transition: box-shadow 0.2s, transform 0.2s;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 0px 0px #fcdb29;
        transform: translateY(3px);
      }

      &::before {
        content: none;
      }
    }

    &-prev {
      background: #ffeb00
        url('/douga_mv/shinchan/svod/images/common/slider-arrow-l.svg')
        no-repeat center / auto;
      left: -24px;
      right: auto;

      &:hover,
      &:focus {
        background: #ffeb00
          url('/douga_mv/shinchan/svod/images/common/slider-arrow-l.svg')
          no-repeat center / auto;
      }
    }

    &-next {
      background: #ffeb00
        url('/douga_mv/shinchan/svod/images/common/slider-arrow-r.svg')
        no-repeat center / auto;
      right: -24px;
      left: auto;

      &:hover,
      &:focus {
        background: #ffeb00
          url('/douga_mv/shinchan/svod/images/common/slider-arrow-r.svg')
          no-repeat center / auto;
      }
    }

    &-disabled {
      display: none !important;
    }
  }
`

const StyledLabel = styled.label.withConfig({
  shouldForwardProp: (prop) => !['checked'].includes(prop)
})`
  text-align: center;
  cursor: pointer;

  ${({ checked }) => {
    if (checked) {
      return css`
        transform: rotateY(360deg);
        transition: transform 0.4s ease-in-out;
      `
    }
    return null
  }}
`

const StyledImg = styled.img`
  @media ${mediaSp} {
    width: 100%;
    height: auto;
    max-width: 160px;
    max-height: 160px;
  }
`
