import styled from 'styled-components'

const tabHeight = '45px'
const white = '#fff'
const black = '#343434'
const yellow = '#cece00'

export const StyledUl1 = styled.ul`
  width: 100%;
  display: flex;
`

export const StyledLi1 = styled.li`
  flex: 1 0;
`

export const StyledLabel = styled.label`
  height: ${tabHeight};
  border-bottom: 3px solid ${black};
  background-color: ${black};
  display: grid;
  align-items: center;
  justify-items: center;
  color: ${white};
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  cursor: pointer;
`

export const StyledRadio = styled.input`
  display: none;

  &:checked ~ ${StyledLabel} {
    border-bottom-color: ${yellow};
  }
`
