import React, { Component } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import _ from 'lodash'
import moment from 'moment'
import { LOCAL_STORAGE_KEY_MODAL_ARTICLES } from '../../../../constants/app'
import webApp from '../../../exdio/utils/exdioWebAppUtils'

/**
 * # お知らせモーダル
 * Dioの記事を表示する
 * https://renewal-admin-dio.tv-asahi.co.jp/admin/articles
 *
 * ## 使用箇所
 * - HomeContent (tag: TOPページ-ブラウザ)
 * - MyPageContent (tag: TOPページ-アプリ)
 * - PlanShinchan (tag: クレヨンしんちゃん)

*/
export default class AnnounceModal extends Component {
  static propTypes = {
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        article_id: PropTypes.number.isRequired,
        schema_id: PropTypes.number.isRequired,
        name: PropTypes.string
      })
    )
  }

  static defaultProps = {
    articles: []
  }

  static contextTypes = {
    models: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.config = context.models.config.data
    this.state = {
      showModal: false,
      notShowAgain: false,
      notShowList: [],
      currentIdx: null
    }

    this.onClickCheckBox = this.onClickCheckBox.bind(this)
    this.onClickClose = this.onClickClose.bind(this)
  }

  componentDidMount() {
    this._isMounted = true

    this.setState({ showModal: this.shouldShowModal() })
    this.setCurrentIdx()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.articles !== this.props.articles) {
      this.setCurrentIdx()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onClickCheckBox() {
    this.setState({ notShowAgain: !this.state.notShowAgain })
  }

  onClickClose() {
    const { articles } = this.props
    const { currentIdx, notShowAgain, notShowList } = this.state

    // 「次回から表示しない」の保存
    if (notShowAgain) {
      notShowList.push(articles[currentIdx].article_id)
      this.setState({ notShowList })
    }

    // 次のお知らせの表示判定
    for (let i = currentIdx + 1; i < articles.length; i++) {
      const article = articles[i]
      if (this.shouldShowArticle(article)) {
        this.setState({ currentIdx: i, notShowAgain: false })
        return
      }
    }

    // 全部見たら保存. 既にお知らせに存在しないものを除く.
    const notShow = notShowList.filter((v) =>
      articles.find((a) => a.article_id === v)
    )
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_MODAL_ARTICLES,
      JSON.stringify({
        lastShowAt: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        notShow
      })
    )

    this.setState({ showModal: false })
  }

  /** 表示を開始するお知らせを判定 */
  setCurrentIdx() {
    const { articles } = this.props
    if (articles && articles.length) {
      const currentIdx = articles.findIndex((article) =>
        this.shouldShowArticle(article)
      )
      this.setState({ currentIdx: currentIdx < 0 ? null : currentIdx })
    }
  }

  getImageUrl(article) {
    const imageUrlField =
      webApp.utils.isApp(this.context) || webApp.utils.isSp()
        ? 'thumbnail_sp_url'
        : 'thumbnail_pc_url'
    return _.get(article, ['values', imageUrlField])
  }

  shouldShowArticle(article) {
    return (
      this.getImageUrl(article) &&
      (!this.storage || !this.storage.notShow.includes(article.article_id))
    )
  }

  shouldShowModal() {
    const tmpStorage = window.localStorage.getItem(
      LOCAL_STORAGE_KEY_MODAL_ARTICLES
    )
    if (tmpStorage) {
      this.storage = JSON.parse(tmpStorage)
      const lastShowAt = new Date(this.storage.lastShowAt)
      const showPeriod = this.config.extras.show_announce_modal_span
      const borderDateTime = new Date(this.storage.lastShowAt)
      borderDateTime.setHours(lastShowAt.getHours() + showPeriod)

      // 最後に見てから24時間経っていたら表示
      const now = new Date()
      return borderDateTime < now
    }
    return true
  }

  render() {
    const { articles } = this.props
    const { showModal, notShowAgain, currentIdx } = this.state
    if (!articles || !articles.length || currentIdx == null) return null

    const article = articles[currentIdx]
    const imageUrl = this.getImageUrl(article)
    const imageLink = _.get(article, ['values', 'link_url'])

    return (
      <div
        className={`c-modal m-gModal ${showModal ? 'on' : ''}`}
        onClick={this.onClickClose}
      >
        <div className="c-modal-inBox" onClick={(e) => e.stopPropagation()}>
          <div className="c-modal-inBox-close" onClick={this.onClickClose} />
          <div className="c-modal-inBox-check">
            <input
              id="dontShowAgain"
              type="checkbox"
              checked={notShowAgain}
              onChange={() => {}}
            />
            <label
              htmlFor="dontShowAgain"
              className="clickable"
              onClick={this.onClickCheckBox}
            >
              次回から表示しない
            </label>
          </div>
          <div className="c-modal-inBox-cont">
            <div className="m-gModalCont">
              <div>
                {imageLink && (
                  <a
                    href={imageLink}
                    target="_blank"
                    className="m-gModalCont-link"
                  >
                    <img
                      src={imageUrl}
                      alt={article.display_name}
                      className="c-card-inBox-art-artwork-img"
                    />
                  </a>
                )}
                {!imageLink && (
                  <img
                    src={imageUrl}
                    alt={article.display_name}
                    className="c-card-inBox-art-artwork-img"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
