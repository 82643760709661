import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import cn from 'classnames'
import { Helmet } from 'react-helmet'
import {
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import RenewalPlayerInline from '../../player/RenewalPlayerInline'
import HtmlSnippet from '../../HtmlSnippet'
import DeliveryStartAt from '../EpisodeDefault/DeliveryStartAt'
import TagList from '../ProgramGariben/TagList'
import TabList from '../EpisodeGariben/TabList'
import CommonVideoArea from '../EpisodeGariben/CommonVideoArea'
import {
  checkAvailableStatus,
  play,
  purchaseEpisode,
  purchasePlan,
  getThumbnailUrl
} from '../EpisodeDefault/util'

/* hooks */
import useMediaQuery from '../../../../hooks/useMediaQuery'
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/** style */
import {
  StyledContainer,
  StyledContainerInner,
  StyledDiv1,
  StyledDiv2,
  StyledHdg,
  StyledHdgSpan,
  StyledMeta,
  StyledList1,
  StyledList1Item,
  StyledEpisodePager,
  StyledCaption,
  StyledFilterSort,
  StyledComment,
  StyledLink,
  StyledEpisodeFooter,
  GlobalStyle
} from './style'

moment.createFromInputFallback = (config) => {
  // eslint-disable-next-line no-param-reassign
  config._d = new Date(config._i)
}

/** 単話ページ */
const EpisodeGariben = (
  {
    seriesId = '',
    seasonId = '',
    episodeId = '',
    meta = undefined,
    programMeta = undefined,
    howToPlay = {},
    product = [],
    episodes = [],
    course = {},
    status = {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    sendPlayLog = () => {},
    loaded = false,
    ...props
  },
  context
) => {
  if (!(size(meta) > 0 && size(programMeta) > 0)) return null

  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix
  const model = context.falcorModel.batch(100)
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isSp = useMediaQuery()

  const commentHash = get(meta, [
    'values',
    'episode_front_display_config',
    'comment_hash'
  ])
  const hasComment = Boolean(commentHash)

  const tabList = hasComment
    ? [
        { id: 'meta', name: '番組概要' },
        { id: 'comment', name: 'コメント' }
      ]
    : []
  const [mainTitle, subTitle] = webApp.utils.titles(meta)
  const latest = (episodes && episodes[0]) || {}
  const isLatest = meta && latest && meta.meta_id === latest.meta_id
  const time = webApp.utils.duration(meta) || '-'

  const bannerId1 = get(programMeta, ['values', 'banner_1'])

  // LIVE配信 主/副切替ID
  const metaIdForPlayer = `${config.videocloud.reference_id_prefix || ''}${
    meta.meta_id
  }`
  const playerSettings = webApp.utils.getPlayerSettings(
    config,
    meta,
    status.displayMode
  )

  const thumbnailUrl = getThumbnailUrl(
    { meta, programMeta },
    context,
    config,
    previewUrlList,
    cookieRubocopPrefix
  )
  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  const isBefore = Boolean(
    !withValidPreviewToken && moment(prevTime).isBefore(meta.delivery_start_at)
  )
  const isAfter = Boolean(
    !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
  )
  const inViewTerm = !(isBefore || isAfter)
  const isPlayerRenderable =
    metaIdForPlayer &&
    status.displayMode &&
    (inViewTerm || withValidPreviewToken)

  const extendSeasonIds = get(
    programMeta,
    ['values', 'season_front_display_config', 'episodeList', 'extendSeasonIds'],
    []
  )

  const [progress, setProgress] = useState(webApp.utils.progress(config, meta))
  const [showThumb, setShowThumb] = useState(true)
  const [currentTabId, setCurrentTabId] = useState('comment')
  const [otherSeasonMetas, setOtherSeasonMetas] = useState({})
  const playerRef = useRef(null)
  const setPlayerRef = (e) => {
    playerRef.current = e
  }

  const onClosePlayer = () => {
    setProgress(webApp.utils.progress(config, meta))
  }

  const toggleShowThumb = () => {
    setShowThumb(!showThumb)
  }

  /** 他のシーズンに属するエピソードのID(child_episode_ids)がほしいので取得 */
  const getOtherSeasonMetas = () => {
    if (!extendSeasonIds) return Promise.reject()

    const path = ['metas', extendSeasonIds]
    return model.fetch([path]).then((result) => {
      const seasons = get(result, ['json', 'metas'], null) // シーズンのメタ情報
      delete seasons.$__path
      Object.keys(seasons).forEach((key) => {
        if (seasons[key] === null) delete seasons[key]
      })
      return seasons
    })
  }

  /**
   * child_episode_idsを抽出し、metaの内容に含めてセット
   * FilterSortのフィルタで使用するため
   */
  const resetChildEpisodeIds = () => {
    /** 自シーズンの子エピソードID① */
    let _childEpisodeIds = get(programMeta, ['values', 'child_episode_ids'], [])

    if (size(otherSeasonMetas) > 0) {
      /**
       * 他のシーズン情報がある場合は
       * 自シーズンの子エピソードID①と
       * 他シーズンの子エピソードID②をセットする
       */
      /** 他シーズンの子エピソードID② */
      const otherChildEpisodeIds = Object.values(
        otherSeasonMetas
      ).flatMap((_meta) => get(_meta, ['values', 'child_episode_ids'], []))
      _childEpisodeIds = [..._childEpisodeIds, ...otherChildEpisodeIds]
    }
    return _childEpisodeIds
  }

  const forceUpdateParamsFunc = (current, tags) => {
    const params = { ...current }
    params.condition.tags = tags
    return params
  }

  const searchParams = useSearchParams({
    childEpisodeIds: resetChildEpisodeIds(),
    seasonIds: [seasonId, ...extendSeasonIds].map(Number),
    tags: [],
    sortedBy: 'delivery_start_at_newer',
    forceUpdateParams: (current) =>
      forceUpdateParamsFunc(current, get(meta, ['tags'], []))
  })

  const onClickPlay = (e, asSvod = false) => {
    if (e) e.preventDefault()

    if (!isLoggedIn) {
      context.history.push(
        routes.login.makePath(null, {
          redirect: context.routeHandler.path
        })
      )
      return
    }

    switch (checkAvailableStatus({ status, product }, context, asSvod)) {
      case 'PLAY':
        play(playerRef.current, sendPlayLog)
        break
      case 'PURCHASE_EPISODE':
        purchaseEpisode({ episodeId, seasonId }, context)
        break
      case 'PURCHASE_PLAN':
        purchasePlan({ course }, context, 'gariben_daigaku')
        break
      default:
        break
    }
  }

  /**
   * 選択中のタグを管理
   * @param e {object} イベント
   */
  const onChangeTags = (e) => {
    const { checked, value } = e.target
    const _selectedTags =
      checked === true
        ? searchParams.state.tags.concat(value)
        : searchParams.state.tags.filter((tag) => tag !== value)
    const forceUpdateParams =
      size(_selectedTags) > 0
        ? {}
        : (current) => forceUpdateParamsFunc(current, get(meta, ['tags'], []))

    searchParams.set({
      tags: _selectedTags,
      forceUpdateParams
    })
  }

  const onChangeTab = (tabId) => {
    setCurrentTabId(tabId)
  }

  useEffect(() => {
    /** コメント掲出していない場合はタブを非表示 */
    if (!hasComment) {
      setCurrentTabId('meta')
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const resOtherSeasonMetas = await getOtherSeasonMetas()
      setOtherSeasonMetas(resOtherSeasonMetas)
      const _childEpisodeIds = resetChildEpisodeIds()

      searchParams.set({
        seasonIds: [seasonId, ...extendSeasonIds].map(Number),
        childEpisodeIds: _childEpisodeIds
      })
    })()
  }, [programMeta])

  useEffect(() => {
    const isAutoPlay = context.routeHandler.query.auto === 't'
    if (loaded && status && isAutoPlay) {
      const isFree = EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode)
      const isAuthFree =
        EPISODE_DISPLAY_MODE.SVOD_AUTH_FREE === status.displayMode && isLoggedIn
      if (isFree || isAuthFree || status.isPurchased || withValidPreviewToken) {
        toggleShowThumb()
        onClickPlay()
      }
    }
  }, [loaded, status])

  return (
    <StyledContainer {...props}>
      <Helmet>
        <link
          rel="icon"
          href="/images/exdio/renewal/gariben_daigaku/top/gbu_favicon.webp"
        />
        <link
          rel="apple-touch-icon"
          href="/images/exdio/renewal/gariben_daigaku/top/gbu_favicon.webp"
        />
      </Helmet>
      <StyledContainerInner>
        <StyledDiv1>
          <div
            className={cn('c-video_wrapper', { 'is-active': showThumb })}
            onClick={toggleShowThumb}
            role="button"
          >
            {isPlayerRenderable && (
              <RenewalPlayerInline
                ref={setPlayerRef}
                meta_id={metaIdForPlayer}
                product_type={playerSettings.product_type}
                channel={playerSettings.channel}
                ssai_ad_config_id={playerSettings.ssai_ad_config_id}
                ssai_player_id={playerSettings.ssai_player_id}
                stvod_player_id={playerSettings.stvod_player_id}
                live_player_id={playerSettings.live_player_id}
                delivery_config_id={playerSettings.delivery_config_id}
                thumbnail_url={meta.thumbnail_url}
                subtitle={!!meta.values.subtitle}
                enqueteEnabled={status.isFree}
                material_id={get(product, ['ref_id']) || ''}
                license_id={get(course, ['ref_id']) || ''}
                display_mode={status.displayMode}
                onClose={onClosePlayer}
                isClipVod={false}
                playInline
              />
            )}
            <CommonVideoArea
              meta={meta}
              status={status}
              product={product}
              progress={progress}
              onClickPlay={onClickPlay}
              inViewTerm={inViewTerm}
              metaName={subTitle}
              thumbnailUrl={thumbnailUrl}
            />
          </div>
          <StyledHdg>
            {subTitle}
            <StyledHdgSpan>{isLatest && '（最新話）'}</StyledHdgSpan>
          </StyledHdg>
          <TabList
            items={tabList}
            currentId="comment"
            onChangeTab={onChangeTab}
          />
          <StyledMeta isShow={currentTabId === 'meta'}>
            <StyledList1>
              <StyledList1Item>時間：{time}</StyledList1Item>
              <StyledList1Item>
                <DeliveryStartAt meta={meta} status={status} />
              </StyledList1Item>
            </StyledList1>
            <StyledEpisodePager
              meta={meta}
              seriesId={seriesId}
              seasonId={seasonId}
              episodes={episodes}
              course={course}
            />
            <StyledCaption
              meta={meta}
              isOpen={!isSp && !hasComment}
              hideButton={!isSp && !hasComment}
            />
          </StyledMeta>
          {hasComment && (
            <StyledComment
              hash={commentHash}
              hide={isSp && currentTabId !== 'comment'}
            />
          )}
        </StyledDiv1>

        <StyledDiv2>
          <TagList
            tags={get(meta, ['tags'], [])}
            selected={searchParams.state.tags}
            changeTags={onChangeTags}
          />
          {size(searchParams.state.childEpisodeIds) > 0 && (
            <StyledFilterSort
              defaultListType="default"
              searchParams={searchParams}
              episodeListItemProps={{
                showNew: true,
                showChecked: true,
                onlySubTitle: true,
                clampWidth: 4
              }}
              hideSwitches
            />
          )}
        </StyledDiv2>
      </StyledContainerInner>

      {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}

      <StyledLink route={routes.plan} params={{ slug: 'gariben_daigaku' }}>
        私立ガリベン大学TOPに戻る
      </StyledLink>
      <StyledEpisodeFooter
        copyrights={get(meta, ['values', 'evis_Copyright'])}
        courseId={get(howToPlay, ['courses', 0, 'course_id']) || null}
      />
      <GlobalStyle />
    </StyledContainer>
  )
}

export default EpisodeGariben

EpisodeGariben.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のエピソードID */
  episodeId: PropTypes.string,

  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** シーズンのメタ情報 */
  programMeta: PropTypes.object,
  /** 動画の視聴権利関連情報 */
  howToPlay: PropTypes.object,
  /** 動画の価格、配信期間情報など */
  product: PropTypes.object,
  productRight: PropTypes.object,

  // 通常単話
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  /** パック販売情報 */
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number,
      name: PropTypes.string,
      original_price: PropTypes.number,
      active_pricing: PropTypes.shape({
        price: PropTypes.number,
        unit: PropTypes.string
      })
    })
  ),
  /** episodesのhowToPlay情報 */
  howToPlays: PropTypes.object,
  // 月額見放題
  /** 動画のコース情報 */
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),

  /** 動画の視聴ステータス情報 */
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  /** 動画のシーズン情報 */
  season: PropTypes.object,
  /** 動画再生時のログ送信 */
  sendPlayLog: PropTypes.func,
  /** データの取得が終わっているか */
  loaded: PropTypes.bool
}

EpisodeGariben.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}
