import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../utils/exdioWebAppUtils'

/* style */
import {
  StyledLi,
  StyledDiv1,
  StyledP1,
  StyledDiv2,
  StyledP2,
  StyledImg
} from './style'

/** 港時間動画一覧 要素コンポーネント */
const HarborItem = ({ meta, onClickThumbnail }, context) => {
  // /** タイトル */
  const [title, subTitle] = webApp.utils.titles(meta)

  /** サムネイル画像 */
  const thumbnail = () => {
    let thumbnailUrl = null
    let thumbnailAlt = null

    thumbnailUrl = meta.thumbnail_url
    thumbnailAlt = `${title} ${subTitle}`

    /* 読み込む画像サイズを変更する */
    thumbnailUrl = webApp.utils.customSizeImageUrl(thumbnailUrl, 'medium')

    return [thumbnailUrl || config.default_thumbnail, thumbnailAlt]
  }

  const handleClick = (propFunc, e) => {
    e.preventDefault()
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation()
      propFunc(e)
    }
  }

  const duration = webApp.utils.duration(meta)
  const [thumbnailUrl, thumbnailAlt] = thumbnail()
  const isNew = webApp.utils.isNew(context, meta)

  return (
    <StyledLi>
      <a href="#" onClick={(e) => handleClick(onClickThumbnail, e)}>
        <StyledDiv1>
          <img src={thumbnailUrl} alt={thumbnailAlt} />
          <StyledP1>{duration}</StyledP1>
        </StyledDiv1>
        <StyledDiv2>
          <StyledP2>{subTitle}</StyledP2>
          {isNew && (
            <StyledImg
              src="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_new.jpg"
              srcSet="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_new@2x.jpg 2x"
              alt="NEW"
            />
          )}
          <StyledImg
            src="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_free-ep.jpg"
            alt="無料"
            srcSet="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_free-ep@2x.jpg 2x"
          />
        </StyledDiv2>
      </a>
    </StyledLi>
  )
}

export default HarborItem

HarborItem.propTypes = {
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  /**  イベントハンドラ<br />
  各onClickは引数に(event)を渡して呼び出される */
  onClickThumbnail: PropTypes.func
}

HarborItem.defaultProps = {
  meta: null,
  onClickThumbnail: null
}

HarborItem.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}
