import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import routes from '../../../../../common/routes'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import { mediaSp, mainColor } from '../common/styles'

/** 全英オープン ProgramName */
const ProgramName = ({ seriesId, seasonId, children, ...props }) => {
  return (
    <StyledLink
      route={routes.program}
      params={{ seriesId, seasonId }}
      {...props}
    >
      { children }
    </StyledLink>
  )
}

export default memo(ProgramName)

ProgramName.propTypes = {
  seriesId: PropTypes.string,
  seasonId: PropTypes.string
}

ProgramName.defaultProps = {
  seriesId: '',
  seasonId: ''
}

const StyledLink = styled(Link)`
display: block;
color: ${mainColor};
font-size: 1.2rem;
font-weight: bold;
line-height: 1.2;
transition: opacity .4s ease-in-out;

@media ${mediaSp} {
  line-height: 1;
}

&:hover {
  opacity: .7;
}
`
