import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import routes from '../../../routes'
import { LOGIN_CONTENTS } from '../../../../../constants/app'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'
import Btn from './Btn'

/** style */
import { mediaQuery } from '../../../../exdio/components/style'

/** ヘッダー > ハンバーガーメニュ > 未ログイン時ボタン */
const LoginButtons = ({ onClick = () => {} }, context) => {
  const isApp = webApp.utils.isApp(context)
  let redirect = context.routeHandler.path
  if (routes.login.match(redirect)) {
    redirect = context.routeHandler.query.redirect || null
  }

  return (
    <StyledDiv isApp={isApp}>
      {!isApp && (
        <Btn
          route={routes.login}
          state={{ content: LOGIN_CONTENTS.SIGNUP }}
          onClick={onClick}
          type="regist"
        >
          新規登録
        </Btn>
      )}
      <StyledBtn
        route={routes[isApp ? 'app_login' : 'login']}
        query={redirect ? { redirect } : {}}
        onClick={onClick}
        type="login"
        isApp={isApp}
      >
        ログイン
      </StyledBtn>
    </StyledDiv>
  )
}

export default memo(LoginButtons)

LoginButtons.propTypes = {
  /** GlobalNavigation.js > showSubMenu/showSearch/showMemberMenu 開閉処理 */
  onClick: PropTypes.func.isRequired
}

LoginButtons.contextTypes = {
  routeHandler: PropTypes.object
}

const StyledDiv = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isApp'].includes(prop)
})`
  margin: 0 auto;
  order: 1;
  display: flex;
  justify-content: center;

  ${mediaQuery('mm')} {
    padding: 0 0.6rem;
  }

  ${({ isApp }) =>
    isApp &&
    css`
      width: 100%;
    `}
`

const StyledBtn = styled(Btn).withConfig({
  shouldForwardProp: (prop) => !['isApp'].includes(prop)
})`
  ${({ isApp }) =>
    isApp &&
    css`
      width: 50%;
    `}
`
