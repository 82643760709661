import styled from 'styled-components'

export const StyledListItem = styled.li`
  width: 35px;
  height: 35px;
`

export const StyledButton = styled.button`
  padding: 0;
  background: none;
  border-width: 0;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`
