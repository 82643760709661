import styled from 'styled-components'

export const StyledDiv1 = styled.div`
  margin: 0 auto 20px;
  width: auto;
`

export const StyledDiv2 = styled.div`
  .slick-slider {
    .slick-list {
      width: auto;
    }

    .slick-dots {
      width: 100%;
      bottom: -150px;

      li {
        margin: 0 5px;
        width: 12px;
        height: 12px;
        background: #b7c5d9;

        button {
          width: 100%;
          height: 100%;
        }
        button::before {
          content: none;
        }

        &.slick-active {
          background: red;
        }
      }
    }
  }
`

export const StyledDiv3 = styled.div`
  margin-bottom: 95px;
  padding: 10px;
  height: 29px;
  overflow: hidden;
  position: relative;
  z-index: 100;
  color: #fff;
  font-weight: 700;
  font-style: italic;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  .slick-slider {
    .slick-list {
      .slick-slide {
        a {
          color: #fff;
          font-size: 1.5rem;
          font-weight: 700;
        }
      }
    }
  }
`

export const StyledDiv4 = styled.div`
  margin-top: -90px;
  margin-bottom: 50px;
  height: auto;
`

export const StyledDiv6 = styled.div`
  outline: none;
`

export const StyledImg = styled.img`
  outline: none;
  opacity: 0.5;
  margin-top: 0;
  margin-left: 0;
  padding-top: 18px;
  width: auto;
`

export const StyledDiv5 = styled.div`
  && {
    padding: 0;
    width: auto;
    height: auto;

    .slick-slide {
      width: 120px !important;
      position: relative;
    }

    .slick-current {
      .active-arrow::before {
        margin-left: 13px;
        height: 100%;
        border-left: 5px solid red;
        position: absolute;
        top: 0;
        left: -2px;
        content: '';
      }

      .active-arrow::after {
        margin-top: -8px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-right: 13px solid red;
        border-bottom: 8px solid transparent;
        position: absolute;
        top: 50%;
        left: -1px;
        content: '';
      }

      ${StyledImg} {
        opacity: 1;
      }

      ${StyledDiv6} {
        &::before {
          margin-top: 13px;
          width: 100%;
          border-bottom: 5px solid red;
          position: absolute;
          top: 0;
          content: '';
        }

        &::after {
          margin-left: -8px;
          width: 0;
          height: 0;
          border-right: 8px solid transparent;
          border-bottom: 13px solid red;
          border-left: 8px solid transparent;
          position: absolute;
          top: 0;
          left: 50%;
          content: '';
        }
      }
    }
  }
`
