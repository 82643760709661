import styled from 'styled-components'
import { Link } from '../../../../../../sketch-platform/ui/routing'

export const StyledHeader = styled.header`
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
`

export const StyledLink = styled(Link)`
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
`

export const StyledH1 = styled.h1`
  color: #343434;
  font-size: 2.2rem;
  font-weight: 600;
`
