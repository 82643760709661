import styled from 'styled-components'

export const StyledList = styled.ul`
  margin-top: 10px;
  display: none;

  @media (max-width: 1024px) {
    display: flex;
  }
`

export const StyledListItem = styled.li`
  width: 100%;
  position: relative;

  &::after {
    width: 100%;
    height: 3px;
    background-color: #00d4b1;
    display: block;
    position: absolute;
    bottom: 0;
    content: ${(props) => (props.isCurrent ? '""' : 'none')};
  }
`

export const StyledButton = styled.button`
  padding: 10px 0;
  width: 100%;
  border-width: 0;
  background-color: #343434;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
`
