import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Card from './Card'

/* style */
import { StyledDivInner } from '../../style'
import styled from 'styled-components'
import { mediaQuery, pxToVw } from '../../../../../../exdio/components/style'

/** 必殺仕事人 LP | テレ朝動画の人気コンテンツ */
const PopulurContents = ({ populurContentsItems = [] }) => {
  const populurContentsItemsFirst = populurContentsItems.slice(0, 5)
  const populurContentsItemsLatter = populurContentsItems.slice(5)

  return (
    <StyledSection id="link4">
      <StyledDivInner>
        <StyledH2>
          <img
            src="/images/exdio/renewal/hissatsu/populur_title.png"
            alt="テレ朝動画の人気コンテンツ"
          />
        </StyledH2>
        <StyledP>
          テレ朝動画では必殺シリーズ以外の時代劇をはじめ、
          <br />
          相棒や科捜研の女などの人気ドラマも全シリーズ配信中！
        </StyledP>
        <StyledDiv1>
          {populurContentsItemsFirst.map((populurContentsItem, i) => (
            <Card populurContentsItem={populurContentsItem} key={i} />
          ))}
        </StyledDiv1>
      </StyledDivInner>

      <StyledDiv2>
        <StyledDivInner>
          <StyledDiv1>
            {populurContentsItemsLatter.map((populurContentsItem, i) => (
              <Card populurContentsItem={populurContentsItem} key={i} />
            ))}
          </StyledDiv1>
        </StyledDivInner>
      </StyledDiv2>
    </StyledSection>
  )
}

export default memo(PopulurContents)

PopulurContents.propTypes = {
  /**
   * 人気コンテンツのリストを表示するコンポーネント。
   *
   * @param {Object[]} populurContentsItems - 人気コンテンツのアイテムの配列
   * @param {string} populurContentsItems.href - コンテンツへのリンクURL
   * @param {string} populurContentsItems.src - コンテンツの画像URL
   * @param {string} populurContentsItems.title - コンテンツのタイトル
   */
  populurContentsItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      src: PropTypes.string,
      title: PropTypes.string
    })
  )
}

const StyledSection = styled.section`
  padding: 100px 0 0;
  background: url(/images/exdio/renewal/hissatsu/populur_bg.png) no-repeat top
    center / cover;

  ${mediaQuery(768)} {
    padding: 30px 0 0;
  }
`

const StyledH2 = styled.h2`
  display: block;
  margin: 0 auto;
  text-align: center;
`

const StyledP = styled.p`
  margin-top: 30px;
  font-size: 3.2rem;
  text-align: center;
  color: #fff;
  line-height: 1.4;

  ${mediaQuery(768)} {
    font-size: ${pxToVw(44, 750)};
  }
`

const StyledDiv1 = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  gap: 40px;

  ${mediaQuery(768)} {
    gap: 8px;
  }
`

const StyledDiv2 = styled.div`
  margin-top: 40px;
  padding: 40px 0;
  background: rgba(255, 255, 255, 0.18);

  ${mediaQuery(768)} {
    margin-top: 30px;
    padding: 30px 0;
  }
`
