import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import routes from '../../../../../common/routes'
import GenreLinksSp from '../../../../../common/components/renewal/GenreLinksSp'
import LinkList from './LinkList/'

/** style */
import {
  StyledDiv1,
  StyledDiv2,
  StyledDiv3,
  StyledDiv4,
  StyledFooter,
  StyledMarks,
  StyledP,
  StyledSmall
} from './style'
import { mediaQuery } from '../../../style'

/** ガリベン大学 Footer */
const Footer = ({ ...props }, context) => {
  const config = context.models.config.data
  const guideList = [
    {
      href: 'https://wws.tv-asahi.co.jp/apps/gariben_daigaku/logout.php',
      target: '_blank',
      text: 'ログアウト'
    },
    {
      href: 'https://www.tv-asahi.co.jp/douga_mv/gariben_daigaku/faq/',
      target: '_blank',
      text: 'FAQ'
    },
    {
      href: 'https://www.tv-asahi.co.jp/douga_mv/help/',
      target: '_blank',
      text: 'ヘルプセンター'
    },
    {
      href: config.dougaLink.Privacy,
      target: '_blank',
      text: '個人情報の取り扱いについて'
    }
  ]
  const termList = [
    {
      href: 'https://www.tv-asahi.co.jp/douga_mv/kiyaku/',
      target: '_blank',
      text: 'テレ朝動画利用規約'
    },
    {
      href: 'https://www.tv-asahi.co.jp/douga_mv/gariben_daigaku/terms/',
      target: '_blank',
      text: '私立ガリベン大学利用規約'
    },
    {
      href: 'https://www.tv-asahi.co.jp/douga_mv/tokusho/',
      target: '_blank',
      text: '特定商取引に関する法律に基づく表示'
    },
    {
      route: routes.privacy,
      text: 'プライバシーポリシー'
    }
  ]

  return (
    <StyledFooter {...props}>
      <StyledGenreLinksSp />
      <StyledDiv1>
        <StyledDiv2>
          <LinkList linkList={guideList} />
          <LinkList linkList={termList} />
        </StyledDiv2>
        <StyledDiv3>
          <StyledP>
            配信されている動画は放送内容と一部異なる場合がございます。また、予告なく配信終了する場合がございますがご了承ください。表示されている情報は、収録および放送当時のものです。
            <br />
            消費税率の変更により、テレビ朝日番組内およびホームページ内の価格表示と異なる場合があります。
          </StyledP>
          <StyledMarks />
        </StyledDiv3>
      </StyledDiv1>
      <StyledDiv4>
        <StyledSmall>{config.copyright}</StyledSmall>
      </StyledDiv4>
    </StyledFooter>
  )
}

export default Footer

Footer.contextTypes = {
  models: PropTypes.object
}

const StyledGenreLinksSp = styled(GenreLinksSp)`
  margin-top: 20px;
  ${mediaQuery('mm')} {
    margin-top: 10px;
  }
`
