/* style */
import styled from 'styled-components'
import Link from '../../../../../../../../sketch-platform/ui/routing/Link'
export const mediaSp = '(max-width: 1024px)'

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['borderColor', 'shadowColor'].includes(prop)
})`
  && {
    padding: 8px 32px;
    border-radius: 24px;
    border: 2px solid ${({ borderColor }) => borderColor || '#000'};
    background-color: #fff;
    box-shadow: 0 2px 0 ${({ shadowColor }) => shadowColor || '#000'};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    transform: translateY(0);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    &:hover {
      transform: translateY(2px);
      box-shadow: 0 0 0 #000;
    }

    @media ${mediaSp} {
      padding: 4px 8px;
      border-width: 1px;
      box-shadow: 0 1px 0 ${({ shadowColor }) => shadowColor || '#000'};
      font-size: 1.4rem;
    }
  }
`

export const StyledSpan = styled.span`
  margin-left: 12px;
  color: ${({ color }) => color || '#000'};

  @media ${mediaSp} {
    margin-left: 4px;
  }
`
