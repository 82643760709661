import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import routes from '../../../../../../../common/routes'
import webApp from '../../../../../../../exdio/utils/exdioWebAppUtils'
import { Link } from '../../../../../../../../sketch-platform/ui/routing'

/* style */
import styled from 'styled-components'

/** 必殺仕事人 LP | テレ朝動画の人気コンテンツカード */
const Card = ({ seasonMetas = [] }) => {
  const { values, id: seasonId = '' } = seasonMetas
  const pageUrl = `${webApp.utils.rootUrl()}${routes.program.makePath({
    seriesId: get(values, ['parents_series', 'id'], ''),
    seasonId
  })}?utm_source=lp&utm_medium=hissatsu`

  return (
    <article>
      <StyledLink href={pageUrl}>
        <img src={seasonMetas.thumbnail_url} alt="" />
        <StyledP1>{values.avails_SeasonTitleDisplayUnlimited}</StyledP1>
        <StyledP2>{values.evis_SeasonLongSynopsis}</StyledP2>
      </StyledLink>
    </article>
  )
}

export default Card

Card.propTypes = {
  /**
   * 各エピソードに関連するメタデータ
   *
   * @param {Object} seasonMetas - シリーズのメタデータ
   * @param {number} seasonMetas.id - シリーズの一意の識別子
   * @param {string} seasonMetas.name - シリーズの名前
   * @param {string} seasonMetas.thumbnail_url - シリーズのサムネイル画像のURL
   * @param {Object} seasonMetas.values - シリーズに関する追加の詳細情報を含むオブジェクト
   * @param {string} seasonMetas.values.evis_SeasonLongSynopsis - シリーズの長いあらすじ
   * @param {Object} seasonMetas.values.parents_series - シリーズの親シリーズに関する情報
   * @param {number} seasonMetas.values.parents_series.id - 親シリーズの一意の識別子
   */
  seasonMetas: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_SeasonLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        id: PropTypes.number
      })
    })
  })
}

const StyledLink = styled(Link)`
  display: block;
  transition: opacity 0.3s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`

const StyledP1 = styled.p`
  margin-top: 5px;
  font-size: 1.5rem;
  font-weight: bold;
`

const StyledP2 = styled.p`
  margin-top: 5px;
  font-size: 1.3rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`
