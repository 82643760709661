import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import webApp from '../../../exdio/utils/exdioWebAppUtils';

export default class NotFound extends Component {
  static propTypes = {
    redirectToTop: PropTypes.bool
  };

  static defaultProps = {
    redirectToTop: true
  };

  static contextTypes = {
    routeHandler: PropTypes.object
  };

  static bundleName = 'errors/NotFound';

  componentDidMount() {
    this._isMounted = true;
    // SPA用トップページへの自動遷移処理
    if (this.props.redirectToTop) {
      const isApp = webApp.utils.isApp(this.context);
      const route = isApp ? routes.app_home : routes.home;
      setTimeout(() => {
        if (this._isMounted) {
          window.location.href = route.makePath();
        }
      }, 3000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    // SSR用にscriptタグにトップページへの自動遷移処理を記述する
    const isApp = webApp.utils.isApp(this.context);
    const route = isApp ? routes.app_home : routes.home;
    return (
      <div>
        <div id="global_nav_space" />
        <div id="contents" className="clearfix">
          <div id="error_box">
            <p className="txt01">お探しのページは見つかりません。</p>
            <p className="txt02">
              このページは3秒後に自動的にトップページへジャンプします。<br />
              自動的にジャンプしない場合は、下記リンクをクリックして下さい。
            </p>
            <p className="txt03">
              <MainViewLink to={route}>トップページへ戻る</MainViewLink>
            </p>
          </div>
        </div>
        {this.props.redirectToTop && (
          <script
            dangerouslySetInnerHTML={{
              __html: `setTimeout(() => {
                window.location.href = '${route.makePath()}';
              }, 3000);`
            }}
          />
        )}
      </div>
    );
  }
}
