import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import routes from '../../../../apps/common/routes';
import webApp from '../../utils/exdioWebAppUtils';
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation';
import HeaderNewsComponent from './HeaderNewsComponent'

/** 月額見放題 メダルチャージ 完了画面 ※多分メダルからしか使わない */
export default class PurchaseResultContent extends Component {
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    webApp: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.config = context.models.config.data;
    this.routeHandler = context.routeHandler;

    this.state = {
      url: null
    };

    this.onBack = this.onBack.bind(this);
  }

  componentDidMount() {
    this.setIframeUrl();

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context);

    // GTMの更新
    const title = this.context.models.config.data.default_title;
    const [program] = title === undefined ? [''] : title.split(' | ');
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ];
    this.context.webApp.utils.updateDataLayer(gtmTags);

    // リスナーを追加する
    window.addEventListener('message', this.onBack);
  }

  componentWillUnmount() {
    // リスナーを削除する
    window.removeEventListener('message', this.onBack);
  }

  onBack(e) {}

  setIframeUrl() {
    const { src } = this.context.routeHandler.query;

    let cont_url;
    const rootUrl = webApp.utils.rootUrl();
    const path = this.config.static_links.purchase_result.replace(':host', webApp.utils.infraHost(this.context));
    if (src === 'mypage') {
      cont_url = rootUrl + routes.mypage.makePath({});
    } else {
      cont_url = rootUrl + routes.purchase.makePath({}, { content: 'confirm' });
    }
    const url = `${path}?CONT_URL=${encodeURIComponent(cont_url)}&${queryString.stringify(this.routeHandler.query)}`;

    this.setState({ url });
  }

  render() {
    const { url } = this.state;
    return (
      <React.Fragment>
        <div className="common-wrapper">
          <HeaderNewsComponent />
          <SpSubNavigation spOff />
          <div className="ex-base">
            {url && <iframe src={url} />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
