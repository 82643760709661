import styled from 'styled-components'
import { mediaSp } from '../styles'
import SnsButtons from '../SnsButtons'
import Button2 from '../Button2'
import EpisodeListItem from '../../../../../common/components/FilterSort/EpisodeList/ListItem'

const blue = '#b9eeff'
const yellow = '#e6ffe2'
const pink = '#ffddf0'
const green = '#fff77f'
export const StyledWrapper = styled.div`
  padding: 7px;
  background: repeating-linear-gradient(
      -45deg,
      ${blue} 0,
      ${blue} 30px,
      ${yellow} 31px,
      ${yellow} 60px,
      ${pink} 61px,
      ${pink} 90px,
      ${green} 91px,
      ${green} 120px
    )
    top left;
  border-radius: 8px;
  position: relative;

  @media ${mediaSp} {
    padding: 5px;
  }
`

export const StyledInner2 = styled.div`
  max-width: calc((1016px - 240px) / 2);
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mediaSp} {
    max-width: none;
    width: auto;
    display: block;
  }
`

export const StyledInner1 = styled.div`
  max-width: calc((1016px - 240px) / 2);
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mediaSp} {
    max-width: none;
    width: auto;
  }

  img {
    &:nth-child(1) {
      @media ${mediaSp} {
        width: 60%;
      }
    }

    &:nth-child(2) {
      @media ${mediaSp} {
        width: 100%;
      }
    }
  }

  & + ${StyledInner2} {
    @media ${mediaSp} {
      margin-top: 20px;
    }
  }
`

export const StyledDiv2 = styled.div`
  margin: 0 auto;
  padding: 15px 25px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media ${mediaSp} {
    padding: 10px;
    flex-direction: column;
    align-items: center;
  }
`

export const StyledEpisodeListItem = styled(EpisodeListItem)`
  margin-right: 0;
  width: 250px;

  @media ${mediaSp} {
    width: 100%;
  }

  .c-card-inBox-art {
    width: auto;
    height: auto;
  }

  .c-card-inBox-meta {
    display: none;
  }
`

export const StyledP2 = styled.p`
  margin-top: 10px;
  max-width: 215px;
  text-align: center;

  @media ${mediaSp} {
    margin: 5px auto 0;
  }
`

export const StyledSnsButtons2 = styled(SnsButtons)`
  margin-top: 15px;
  width: 100%;
  justify-content: center;

  @media ${mediaSp} {
    margin-top: 10px;
  }
`

export const StyledButton2Left = styled(Button2)`
  display: ${({ isShow }) => (isShow ? null : 'none')};
  right: auto;
  left: -48px;

  @media ${mediaSp} {
    box-shadow: none;
    background-color: transparent;
    left: -32px;
  }
`

export const StyledButton2Right = styled(Button2)`
  display: ${({ isShow }) => (isShow ? null : 'none')};
  right: -48px;
  left: auto;

  @media ${mediaSp} {
    box-shadow: none;
    background-color: transparent;
    right: -32px;
  }
`
