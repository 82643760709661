import React from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../routes'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'

/* style */
import { StyledH2 } from '../styles'
import { StyledDiv, StyledLink } from './styles'

/**
 * 新規登録ボタン
 */
// TODO: styled-componentsに移行
const SignupLink = ({ callbackUrl = '', ...props }, context) => {
  const { query } = context.routeHandler
  const { signup } = query
  const { app_regist_email, regist_email } = routes
  const w_callbackUrl = signup || callbackUrl
  const route = webApp.utils.isApp(context) ? app_regist_email : regist_email

  return (
    <StyledDiv key="section-1" className="c-login-pass new-account" {...props}>
      <StyledH2>tv asahi iDで新規登録</StyledH2>
      <StyledLink
        route={route}
        query={w_callbackUrl ? { cont_url: w_callbackUrl } : null}
      >
        新規登録
      </StyledLink>
    </StyledDiv>
  )
}

export default SignupLink

SignupLink.propTypes = {
  callbackUrl: PropTypes.string
}

SignupLink.contextTypes = {
  routeHandler: PropTypes.object
}
