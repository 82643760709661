import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import SideTab from '../details/SideTab'
import ProgramTopLink from '../details/ProgramTopLink'
import { FAVORITE_TYPE } from '../../../../../constants/app'
import SwitchableList from '../../../../common/components/renewal/SwitchableList'
import AddButtonBlock from '../details/AddButtonBlock'
import GoBack from '../../../../common/components/appli/renewal/GoBack'
import SideRecommend from '../details/SideRecommend'
import HeaderNewsComponent from '../HeaderNewsComponent'
import Header from '../../../../common/components/Header'
import Footer from '../../../../common/components/appli/StaticFooter'

/** 月額見放題ページ デフォルトテンプレート */
export default class PlanAppDefault extends Component {
  static propTypes = {
    series: PropTypes.shape({
      meta_id: PropTypes.oneOfType(['string', 'number'])
    }).isRequired,
    season: PropTypes.shape({
      meta_id: PropTypes.oneOfType(['string', 'number'])
    }).isRequired,
    /** プランの情報 */
    course: PropTypes.shape({
      course_id: PropTypes.number.isRequired,
      schema_id: PropTypes.number.isRequired,
      ref_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.shape({}).isRequired,
      values: PropTypes.shape({}).isRequired
    }).isRequired,
    /** プランに含まれる動画の情報 */
    episodes: PropTypes.arrayOf(PropTypes.shape({})),
    /** プランに含まれる動画の権利関連情報 */
    rightMetas: PropTypes.arrayOf(PropTypes.shape({})),
    /** プランの購入情報 */
    status: PropTypes.shape({
      isPurchseAvailable: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool
    })
  }

  static contextTypes = {
    models: PropTypes.shape({}),
    routeHandler: PropTypes.shape({}),
    history: PropTypes.shape({}),
    updateUserInfo: PropTypes.func
  }

  static defaultProps = {
    episodes: [],
    rightMetas: [],
    status: {
      isPurchased: false,
      isNotPurchased: false
    }
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.state = {}

    this.onClickMore = this.onClickMore.bind(this)
  }

  onClickMore(e) {
    e.preventDefault()
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  render() {
    const { course, season, series, episodes, rightMetas, status } = this.props
    const { isExpanded } = this.state

    const thumbnailUrl =
      webApp.utils.customSizeImageUrl(
        _.get(course, ['thumbnail_url']),
        'large'
      ) || this.config.default_thumbnail
    const description = (_.get(course, ['description']) || '').replace(
      /\r?\n/g,
      '<br />'
    )

    // 他のシリーズ用ID
    let otherSeasons = _.get(course, ['values', 'other_series']) || []
    const otherSeasonsLive =
      _.get(course, ['values', 'other_series_live']) || []
    otherSeasons = otherSeasons.concat(otherSeasonsLive)

    let labelStatus = ''
    if (status.isNotPurchased) labelStatus = '未購入'
    if (status.isPurchased) labelStatus = '購入済み'

    return (
      <React.Fragment>
        <Header />
        <div className="common-wrapper">
          <GoBack />
          <HeaderNewsComponent />

          <div className="c-headMeta">
            <div className="c-headMeta-metaBox">
              <div className="c-headMeta-metaBox-art">
                <div className="c-headMeta-metaBox-art-inBox">
                  <div className="c-headMeta-metaBox-art-inBox-ribbon">
                    見放題
                  </div>
                  {labelStatus && (
                    <div className="c-headMeta-metaBox-art-inBox-labelStatus">
                      {labelStatus}
                    </div>
                  )}
                  <div className="c-headMeta-metaBox-art-inBox-labelBottom">
                    見放題パック
                  </div>
                  <div className="c-headMeta-metaBox-art-inBox-artwork">
                    <img
                      src={thumbnailUrl}
                      alt=""
                      className="c-headMeta-metaBox-art-inBox-artwork-img"
                    />
                  </div>
                </div>
              </div>

              <div className="c-headMeta-metaBox-info">
                <h2 className="c-headMeta-metaBox-info-title">{course.name}</h2>
                <div className="c-headMeta-metaBox-info-viewingPeriod">
                  視聴期間：決済月の最終日まで
                </div>
                <div
                  className={`c-headMeta-metaBox-info-titleHeader ${
                    isExpanded ? 'open' : ''
                  }`}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <AddButtonBlock
                  favoriteType={FAVORITE_TYPE.COURSE}
                  favoriteId={course.course_id}
                  title={course.name}
                  showAddMyList
                />
              </div>
            </div>
          </div>

          <div className="c-listMeta">
            <div className="c-listMeta-inBox">
              <div className="c-listMeta-inBox-main">
                <SwitchableList
                  episodes={null}
                  rootMetas={rightMetas}
                  placeholder="検索"
                  showNew
                  showChecked
                  onlySubTitle
                />
              </div>
              <div className="c-listMeta-inBox-sub">
                <SideTab otherSeasons={otherSeasons} />
                <ProgramTopLink
                  seriesId={series && series.meta_id}
                  seasonId={season && season.meta_id}
                  isFree={false}
                  disabled={!(series && season)}
                />
                <SideRecommend
                  recommendType="recommend"
                  typeName={this.config.recommend.type_name.view}
                  spotName={this.config.recommend.spot_name.program}
                  courseRefId={course.ref_id}
                  episodes={episodes}
                />
              </div>
            </div>
          </div>
          <Footer className="mp-mt0" />
        </div>
      </React.Fragment>
    )
  }
}
