import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import Canvas from '../../../common/components/renewal/Canvas'
import Footer from '../../../common/components/Footer'
import AnnounceModal from '../../../common/components/renewal/AnnounceModal'
import webApp from '../../utils/exdioWebAppUtils'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import MPMaintenanceMessage from '../../../common/components/renewal/MPMaintenanceMessage'

/** ホーム画面 */
const HomeContent = (props, context) => {
  const model = context.falcorModel.batch(100)
  const canvasConfig = context.models.config.data.canvas.home
  const [newsArticles, setNewsArticles] = useState([])
  const [popupArticles, setPopupArticles] = useState([])
  const [headArticles, setHeadArticles] = useState([])
  const doraemonSeasonId = ['development', 'staging'].includes(
    process.env.NODE_ENV
  )
    ? 11003
    : 13509

  useEffect(() => {
    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(context)

    // GTMの更新
    const title = context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)
    webApp.utils.initializeChatBot(window)
    getArticles().catch((e) => console.error(e))

    return () => {
      webApp.utils.unmountChatBot(window)
    }
  }, [])

  /** お知らせ取得 */
  const getArticles = () => {
    const publishPeriod =
      context.models.config.data.extras.header_menu_news_period
    const newsPath = ['articles', 'news', publishPeriod] // マイリストと同じ期間にしないと表示できないので期間指定にする
    const popupPath = ['articles', 'popup']
    return model.fetch([newsPath, popupPath]).then((result) => {
      const _newsArticles = _.get(result, ['json', ...newsPath]) || []
      const topPageArticles = _.get(result, ['json', ...popupPath]) || []
      //ポップアップ記事の中でタグにTOPページが含まれているものを抽出
      const _popupArticles = topPageArticles.filter((article) => {
        const matchTag = article.admin_tags_array.map((tag) => {
          if (tag.indexOf('TOPページ-ブラウザ') >= 0) return true
        })
        return matchTag.includes(true)
      })
      setNewsArticles(_newsArticles)
      setPopupArticles(_popupArticles)

      if (_newsArticles.length > 0) {
        const _headArticles = _newsArticles
          .filter((a) => _.get(a, ['values', 'show_top']))
          .map((a) => ({ ...a, msg: a.display_name }))
        setHeadArticles(_headArticles)
      }
    })
  }

  return (
    <>
      {popupArticles.length > 0 && <AnnounceModal articles={popupArticles} />}
      <Helmet>
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      </Helmet>
      <div className="common-wrapper">
        <div
          className={classnames('c-head-sub', {
            announceOn: newsArticles
          })}
        >
          <SpSubNavigation key="sp-nav" />
          {headArticles.length > 0 && (
            <div className="c-head-sub-announce">
              <MPMaintenanceMessage
                messages={headArticles}
                seasonId={doraemonSeasonId}
              />
            </div>
          )}
        </div>
        <Canvas canvasKey="home" canvasConfig={canvasConfig} />
        <Footer />
      </div>
    </>
  )
}

export default HomeContent

HomeContent.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  webApp: PropTypes.object,
  history: PropTypes.object
}
