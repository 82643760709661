import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { yellow } from './styles'

const Button2 = ({ ...props }) => {
  return <StyledButton {...props} />
}

export default memo(Button2)

Button2.propTypes = {
  prevOrNext: PropTypes.oneOf(['prev', 'next'])
}

Button2.defaultProps = {
  prevOrNext: 'prev'
}

const StyledButton = styled.button`
  margin: auto 0;
  padding: 0;
  width: 48px;
  height: 48px;
  background: ${yellow} no-repeat center center / auto;
  background-image: ${({ prevOrNext }) =>
    prevOrNext === 'prev'
      ? 'url(/douga_mv/shinchan/svod/images/common/slider-arrow-l.svg)'
      : 'url(/douga_mv/shinchan/svod/images/common/slider-arrow-r.svg)'};
  box-shadow: 0 3px 0 rgb(252 219 41);
  border-width: 0;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: ${({ prevOrNext }) => (prevOrNext === 'prev' ? 'auto' : '-24px')};
  bottom: 0;
  left: ${({ prevOrNext }) => (prevOrNext === 'prev' ? '-24px' : 'auto')};
  z-index: 1;
  transform: translateY(-3px);
  transition: box-shadow 0.2s, transform 0.2s;

  &:hover {
    box-shadow: 0 0 0 rgb(252 219 41);
    transform: translateY(0);
  }
`
