import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ProgramItemLink from '../../../../common/components/renewal/ProgramItemLink';
import ProgramMixedItemLink from '../../../../common/components/renewal/ProgramMixedItemLink';
import webApp from '../../../utils/exdioWebAppUtils';
import { META_SCHEMA_ID } from '../../../../../constants/app';

/** パック詳細画面:エピソードリストコンポーネント */
export default class EpisodeListPack extends Component {
  static propTypes = {
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        meta_id: PropTypes.number.isRequired,
        thumbnail_url: PropTypes.string,
        values: PropTypes.shape({
          avails_EpisodeNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        }).isRequired
      })
    ),
    showOnlyFreeCoin: PropTypes.bool,
  };

  static defaultProps = {
    episodes: [],
    showOnlyFreeCoin: false,
  };

  static contextTypes = {
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.state = {
      isMixedSeason: false,
      seasonNumbers: {},
      episodes: props.episodes,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const seasonIdArr = this.getSeasonIdArr(this.state.episodes)

    /* 複数シーズンで構成されるパックリストの時、並替で使用する用にシーズン番号を以下の形式で取得する
     * {シーズンID: シーズン番号, ...}
     */
    if (seasonIdArr.length > 1) {
      const path = ['metas', seasonIdArr]
      this.context.falcorModel.fetch([path]).then(result => {
        let seasonNumbers = {}
        seasonIdArr.map(seasonId => {
          seasonNumbers[seasonId] = _.get(result, ['json', 'metas', seasonId, 'values', 'avails_SeasonNumber'])
        })
        this.setState({seasonNumbers: seasonNumbers, isMixedSeason: true})
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.episodes !== nextProps.episodes) {
      if (!nextProps.episodes) {
        return { episodes: prevState.episodes };
      } else {
        return { episodes: nextProps.episodes };
      }
    }
    return nextProps;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /** 動画リストのseasonIDの配列を取得する */
  getSeasonIdArr (episodes) {
    return episodes.reduce((result, current) => {
      if (current.values.parents_season && !result.includes(current.values.parents_season.id)) {
        result.push(current.values.parents_season.id)
      }
      return result
    }, [])
  }

  /** 複数番組、シーズンで構成されるパックリストのソート
   * シリーズID>シーズン番号>配信開始日時>エピソード番号>エピソードID
   */
  sortMixedEpisodes (episodes) {
    // ①エピソードをシリーズIDごとにグループ化し、昇順に並べる
    const groupedSeries = episodes.reduce((result, current) => {
      const seriesId = current.values.parents_series.id
      const seriesGroup = result.find(group => group.seriesId === seriesId)

      if (seriesGroup) {
        seriesGroup.metas.push(current)
        } else {
          result.push({
            seriesId: seriesId,
            metas: [current]
          })
        }
        return result

    }, []).sort((a, b) => {
      return a.seriesId - b.seriesId
    })

    let sortedEpisodes = []

    groupedSeries.map(groupedSeries => {
      // ②シリーズIDでグループ化されたエピソードを今度はシーズン番号でグループ化し、昇順に並べる
      const groupedSeasons = groupedSeries.metas.reduce((result, current) => {
        const seasonNumber = current.values.parents_season && this.state.seasonNumbers[current.values.parents_season.id] || 0
        const seasonGroup = result.find(group => group.seasonNumber === seasonNumber)

        if (seasonGroup) {
          seasonGroup.metas.push(current)
        } else {
          result.push({
            seasonNumber: seasonNumber,
            metas: [current]
          })
        }
        return result

      }, []).sort((a, b) => {
        return a.seasonNumber - b.seasonNumber
      })

      // ③シーズン番号でグループ化されたエピソードをsortEpisodes関数で並べ替える。
      groupedSeasons.map(data => {
        const episodes = this.sortEpisodes(data.metas)
        sortedEpisodes = [...sortedEpisodes, ...episodes]
      }, [])
    })

    return sortedEpisodes
  }

  /*
  * 配信開始[昇順/降順] > エピソード番号[昇順/降順] > ID[昇順/降順]
  */
  sortEpisodes (episodes) {
    if ((episodes||[]).length < 1) return

    // 配列の破壊を防ぐため複製(エピソードAVODを除く)
    const sortedEpisodes = episodes.slice().filter(item => item.meta_schema_id !== 1);

    const { disp_order } = this.props
    const direction = disp_order == 'asc' ? -1 : 1

    sortedEpisodes.sort((a, b) => {
      // 配信開始日時 -> 管理画面の昇順or降順に合わせる
      if (a.delivery_start_at < b.delivery_start_at) return direction * 1;
      if (a.delivery_start_at > b.delivery_start_at) return direction * -1;

      // エピソード番号 -> 管理画面の昇順or降順に合わせる
      if (Number(a.values.avails_EpisodeNumber) < Number(b.values.avails_EpisodeNumber)) return direction * 1;
      if (Number(a.values.avails_EpisodeNumber) > Number(b.values.avails_EpisodeNumber)) return direction * -1;

      // ID -> 管理画面の昇順or降順に合わせる
      if (a.id < b.id) return direction * 1;
      if (a.id > b.id) return direction * -1;

      return 0;
    })

    return sortedEpisodes
  }

  render() {
    const { episodes, isMixedSeason } = this.state;
    if (!episodes || !episodes.length) return null;

    const sorted = isMixedSeason ? this.sortMixedEpisodes(Object.assign([], episodes)) : this.sortEpisodes(Object.assign([], episodes));

    return (
      <div className="c-card-vertical story">
        <div className="c-card-vertical-cont">
          {sorted.map(meta => {
            const isFree = META_SCHEMA_ID.LIVE === meta.meta_schema_id;
            const { route, params, query } = webApp.utils.getProgramLinkRoutes(this.context, meta)
            return (
              isMixedSeason ?
              <ProgramMixedItemLink
                key={meta.meta_id}
                meta={meta}
                showNew={webApp.utils.showNew(meta)}
                route={route}
                params={params}
                query={query}
              />
              :
              <ProgramItemLink
                key={meta.meta_id}
                meta={meta}
                showCaption
                onlySubTitle
                showCoin={!this.props.showOnlyFreeCoin || isFree}
                showLatest={false}
                showNew={webApp.utils.showNew(meta)}
                route={route}
                params={params}
                query={query}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
