import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: ${({ isOpen }) => (isOpen ? null : 'none !important')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`

export const StyledBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`
