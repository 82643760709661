import styled, { keyframes, createGlobalStyle } from 'styled-components'
import { mediaQuery } from '../../../style'
import { pcMinSize } from '../styles'
import SuggestionBox from './SuggestionBox'

const dl1_swing = keyframes`
0% { transform: rotate(0deg); }
30% { transform: rotate(0deg); }
35% { transform: rotate(3deg); }
40% { transform: rotate(-3deg); }
45% { transform: rotate(5deg); }
50% { transform: rotate(-5deg); }
55% { transform: rotate(5deg); }
60% { transform: rotate(-3deg); }
65% { transform: rotate(4deg); }
70% { transform: rotate(0deg); }
100% { transform: rotate(0deg); }
`

export const StyledDiv1 = styled.div`
  padding: 30px 0 60px;

  ${mediaQuery()} {
    padding: 0 0 20px;
  }
`

export const StyledDiv2 = styled.div`
  margin: 0 auto;
  width: ${pcMinSize};

  ${mediaQuery()} {
    padding: 25px 10px 0;
    width: auto;
  }

  .p-top1__banner {
    margin-top: 30px;
    cursor: default;

    &[href] {
      cursor: pointer;
      transition: opacity 0.4s ease-in-out;

      &:hover {
        opacity: 0.7;
      }
    }

    & + #js-banner {
      .c-list3 {
        margin-top: 30px;
      }
    }
  }
`

export const StyledDiv3 = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${mediaQuery()} {
    margin-top: 20px;
    display: block;
  }
`

export const StyledDl = styled.dl`
  width: 810px;
  min-height: 390px;
  padding: 97px 40px 40px;
  background: url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_img1.webp)
      no-repeat top 26px left 35px / 107px 82px,
    url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_img2.webp)
      no-repeat top 17px right 20px / 53px 77px,
    url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_img3.webp)
      no-repeat bottom 0 left 306px / 59px 72px,
    url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_img4.webp)
      no-repeat bottom 10px right 224px / 166px 206px,
    url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_top.webp)
      no-repeat top center / 100% auto,
    url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_bottom.webp)
      no-repeat bottom center / 100% auto,
    url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_middle.webp)
      repeat-y top 10px center / 100% auto;
  position: relative;

  ${mediaQuery()} {
    margin: 0 -10px;
    padding: 12px 10px 55px;
    width: auto;
    min-height: auto;
    flex: 0 1 auto;
    margin: 0 -10px;
    padding: 15px 10px 55px;
    background: url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_img1.webp)
        no-repeat bottom 10px left 128px / 73px 60px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_img2.webp)
        no-repeat bottom 32px right 33px / 31px 45px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_img3.webp)
        no-repeat bottom 14px left 58px / 59px 72px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_sp_top_left.webp)
        no-repeat top left / 6px 5px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_sp_top_right.webp)
        no-repeat top right / 5px 5px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_sp_bottom_left.webp)
        no-repeat bottom left / 6px 5px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_sp_bottom_right.webp)
        no-repeat bottom right / 5px 5px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_sp_bar1.webp)
        repeat-x top left / 309px 5px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_sp_bar1.webp)
        repeat-x bottom left / 309px 5px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_sp_bar2.webp)
        repeat-y top left / 6px 285px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_sp_bar2.webp)
        repeat-y top right / 6px 285px,
      url(/images/exdio/renewal/gariben_daigaku/components/dl1_bg_sp_middle.webp)
        repeat top 10px left 10px / 309px 285px;
  }

  &::before {
    width: 52px;
    height: 96px;
    background: url(/images/exdio/renewal/gariben_daigaku/components/dl1_img1.webp)
      no-repeat center / 100% auto;
    position: absolute;
    top: 30px;
    right: 74px;
    transform-origin: center -10px 0;
    animation: ${dl1_swing} 16s ease-in-out -1s infinite;
    content: '';

    ${mediaQuery()} {
      width: 32px;
      height: 58px;
      top: auto;
      right: 70px;
      bottom: 20px;
    }
  }

  &::after {
    width: 47px;
    height: 89px;
    background: url(/images/exdio/renewal/gariben_daigaku/components/dl1_img2.webp)
      no-repeat center / 100% auto;
    position: absolute;
    bottom: 58px;
    left: 11px;
    transform-origin: center -10px 0;
    animation: ${dl1_swing} 16s ease-in-out infinite;
    content: '';

    ${mediaQuery()} {
      width: 32px;
      height: 58px;
      bottom: 20px;
      left: 36px;
    }
  }
`

export const StyledDt = styled.dt`
  margin: 0 auto;
  position: absolute;
  top: 27px;
  right: 0;
  left: 0;
  text-align: center;

  ${mediaQuery()} {
    position: static;
  }
`

export const StyledDd = styled.dd`
  ${mediaQuery()} {
    margin-top: 10px;
  }
`

export const StyledSuggestionBox = styled(SuggestionBox)`
  ${mediaQuery()} {
    margin: 20px auto 0;
  }
`

export const GlobalStyle = createGlobalStyle`


/*
* バナースニペット用に残し
* // TODO: スニペット側でスタイルを設定する
*/
.c-list3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${mediaQuery()} {
    display: block;
  }

  /*
    * .c-list3__item
    */
  &__item {
    margin-left: 40px;
    width: calc((100% - 40px) / 2);

    ${mediaQuery()} {
      margin-top: 10px;
      margin-left: 0;
      width: auto;
    }

    &:nth-child(2n + 1) {
      margin-left: 0;

      ${mediaQuery()} {
        margin-top: 0;
      }
    }

    &:nth-child(n + 3) {
      margin-top: 20px;

      ${mediaQuery()} {
        margin-top: 10px;
      }
    }
  }
}
`
