import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Button = ({ value, isCurrent, isFirstChild, children, ...props }) => {
  return (
    <StyledButton
      type="button"
      value={value}
      isCurrent={isCurrent}
      isFirstChild={isFirstChild}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

export default memo(Button)

Button.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isCurrent: PropTypes.bool,
  isFirstChild: PropTypes.bool,
  children: PropTypes.node
}

Button.defaultProps = {
  value: '',
  isCurrent: false,
  isFirstChild: false
}

const StyledButton = styled.button`
  padding: 0;
  width: 30px;
  height: 30px;
  background-color: ${({ isCurrent }) => (isCurrent ? '#434a53' : '#fff')};
  border: ${({ isCurrent }) =>
    isCurrent ? '1px solid #434a53' : 'solid #d3d6db'};
  border-width: 1px 1px 1px 0;
  display: inline-block;
  color: ${({ isCurrent }) => (isCurrent ? '#fff' : '#434a53')};
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  box-sizing: border-box;
  cursor: ${({ isCurrent }) => (isCurrent ? 'auto' : 'pointer')};
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;

  ${({ isFirstChild }) => {
    if (isFirstChild) {
      return css`
        border-left-width: 1px;

        &:hover {
          border-color: #434a53;
        }
      `
    }
  }}

  &:hover {
    background: #434a53;
    border: 1px solid #434a53;
    color: #fff;
  }
`
