import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import Helmet from 'react-helmet'
import {
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE,
  FAVORITE_TYPE
} from '../../../../../constants/app'
import webApp from '../../../utils/exdioWebAppUtils'
import { play } from '../EpisodeDefault/util'
import useMediaQuery from '../../../../hooks/useMediaQuery'
// 共通コンポーネント
import RenewalPlayer from '../../player/RenewalPlayerPcModeOnly'
import AddButtonBlock from '../sodaTvGlobal/common/AddButtonBlock'
import Switchable from '../sodaTvGlobal/common/Switchable'
import Heading from '../sodaTvGlobal/common/Heading'
import Footer from '../sodaTvGlobal/common/Footer'
// pc
import Detail from '../sodaTvGlobal/episode/pc/Detail'
// sp
import SpDetail from '../sodaTvGlobal/episode/sp/SpDetail'
import { GlobalStyle, mediaPc } from '../sodaTvGlobal/styles'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

// そだてれび: 海外視聴用視聴テンプレート
const EpisodeSodaTvGlobal = (
  {
    seriesId = '',
    seasonId = '',
    meta = [],
    episodes = [],
    product = {},
    course = {},
    status = {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    sendPlayLog = () => {},
    loaded = false
  },
  context
) => {
  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix

  const params = { seriesId, seasonId }
  const playerId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 'GazJha4tgC'
    : 'gklewDAM1'
  const [dispOrder, setDispOrder] = useState([])
  const [metaIdForPlayer, setMetaIdForPlayer] = useState()
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [playerSettings, setPlayerSettings] = useState()
  const [isPlayerRenderable, setIsPlayerRenderable] = useState(false)
  const playerRef = useRef(null)

  const isSmp = useMediaQuery()
  const isApp = webApp.utils.isApp(context)
  const metaName = `${title} ${subTitle}`

  /**
   * 視聴権を確認して再生するか購入ページに飛ばすかをしている
   */
  const onClickPlay = (e) => {
    if (e) e.preventDefault()
    play(playerRef.current, sendPlayLog)
  }

  useEffect(() => {
    // console.log(playerId)

    /** アプリからブラウザにリダイレクト */
    if (isApp) {
      const pathname = _.get(context, ['history', 'location', 'pathname'])
      const redirectHref = pathname && pathname.replace('/app', '')
      window.location.href = redirectHref
      context.history.push(context.routes.top)
    }

    return () => {
      document.body.style.overflow = null
    }
  }, [])

  useEffect(() => {
    if (Object.keys(meta).length) {
      const [_title, _subTitle] = webApp.utils.titles(meta)
      setDispOrder(_.get(meta, ['values', 'disp_order']))
      setMetaIdForPlayer(
        `${config.videocloud.reference_id_prefix || ''}${meta.meta_id}`
      )
      setTitle(_title)
      setSubTitle(_subTitle)
      setPlayerSettings(
        webApp.utils.getPlayerSettings(config, meta, status.displayMode)
      )
    }
  }, [meta])

  useEffect(() => {
    const withValidPreviewToken = _.get(context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])
    const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
    const isBefore = Boolean(
      !withValidPreviewToken &&
        moment(prevTime).isBefore(meta.delivery_start_at)
    )
    const isAfter = Boolean(
      !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
    )
    const inViewTerm = !(isBefore || isAfter)
    setIsPlayerRenderable(
      metaIdForPlayer &&
        status.displayMode &&
        (inViewTerm || withValidPreviewToken)
    )
  }, [metaIdForPlayer, status])

  useEffect(() => {
    const isAutoPlay = context.routeHandler.query.auto === 't'
    if (loaded && status && !isSmp && isAutoPlay) {
      const isFree = EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode)
      const isAuthFree =
        EPISODE_DISPLAY_MODE.SVOD_AUTH_FREE === status.displayMode &&
        webApp.utils.isLoggedIn(context)
      const withValidPreviewToken = _.get(context, [
        'models',
        'state',
        'data',
        'withValidPreviewToken'
      ])
      if (isFree || isAuthFree || status.isPurchased || withValidPreviewToken) {
        onClickPlay()
      }
    }
  }, [loaded, status, isSmp])

  if (isApp) return null

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@900&display=swap"
        />
      </Helmet>
      <GlobalStyle />
      <Heading params={params} isSmp />
      <StyledMain isSmp={isSmp}>
        {isPlayerRenderable && (
          <RenewalPlayer
            ref={playerRef}
            meta_id={metaIdForPlayer}
            product_type={playerSettings.product_type}
            channel={playerSettings.channel}
            ssai_ad_config_id={playerSettings.ssai_ad_config_id}
            ssai_player_id={playerSettings.ssai_player_id}
            stvod_player_id={playerId}
            live_player_id={playerSettings.live_player_id}
            delivery_config_id={playerSettings.delivery_config_id}
            thumbnail_url={meta.thumbnail_url}
            subtitle={!!meta.values.subtitle}
            enqueteEnabled={status.isFree}
            material_id={_.get(product, ['ref_id']) || ''}
            license_id={_.get(course, ['ref_id']) || ''}
            display_mode={status.displayMode}
            isClipVod={false}
          />
        )}

        {isSmp ? (
          <SpDetail
            params={params}
            meta={meta}
            onClickPlay={onClickPlay}
            seriesId={seriesId}
            seasonId={seasonId}
            episodes={episodes}
            course={course}
          />
        ) : (
          <StyledDetail
            params={params}
            meta={meta}
            onClickPlay={onClickPlay}
            episodes={episodes}
            course={course}
          />
        )}

        <AddButtonBlock
          favoriteType={FAVORITE_TYPE.META}
          favoriteId={meta.meta_id}
          title={metaName}
        />
        <StyledSwitchable
          episodes={episodes}
          disp_order={dispOrder}
          isSmp={isSmp}
        />
      </StyledMain>
      <StyledFooter isSmp={isSmp} />
    </>
  )
}

export default EpisodeSodaTvGlobal

const StyledMain = styled.main`
  padding: ${({ isSmp }) => (isSmp ? '0 8px' : '0 20px')};
  max-width: ${({ isSmp }) => !isSmp && '960px'};
  margin: 0 auto;

  @media ${mediaPc} {
    max-width: ${({ isSmp }) => !isSmp && '1280px'};
  }

  .c-addBtns-inBox-mylist {
    display: none;
  }

  .c-addBtns-inBox-share,
  .c-addBtns-inBox-share-link {
    margin-left: 0;
  }
`

const StyledDetail = styled(Detail)`
  margin-top: 15px;
`

const StyledSwitchable = styled(Switchable)`
  margin-top: ${({ isSmp }) => (isSmp ? '20px' : '30px')};
`

const StyledFooter = styled(Footer)`
  margin-top: 30px;
`

EpisodeSodaTvGlobal.propTypes = {
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  product: PropTypes.object,
  productRight: PropTypes.object,

  // 通常単話
  episodes: PropTypes.arrayOf(PropTypes.object),
  // 月額見放題
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),

  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  // 再生ログ送信
  sendPlayLog: PropTypes.func,

  // データ取得が完了したか
  loaded: PropTypes.bool
}

EpisodeSodaTvGlobal.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}
