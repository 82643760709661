import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import styled, { createGlobalStyle } from 'styled-components'
import {
  META_SCHEMA_ID,
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE,
  DIO_PLAYER_DEVICE
} from '../../../../../constants/app'
import * as tags from '../plan_logirl/Tags'
import {
  checkAvailableStatus,
  getThumbnailUrl,
  play,
  purchaseEpisode,
  purchasePlan
} from '../EpisodeDefault/util'

/** hooks */
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/* components */
import webApp from '../../../utils/exdioWebAppUtils'
import Header from '../../../../common/components/Header'
import EpisodeFooter from '../../../../common/components/EpisodeFooter'
import RenewalPlayer from '../../player/RenewalPlayerPcModeOnly'
import Notice from '../EpisodeDefault/Notice'
import CommonVideoArea from '../EpisodeDefault/CommonVideoArea'
import Meta from '../EpisodeDefault/Meta'
import Actions from '../EpisodeDefault/Actions'
import FilterSort from '../../../../common/components/FilterSort'
import ListMetaSub from '../EpisodeDefault/ListMetaSub'
import HtmlSnippet from '../../HtmlSnippet'
import HeaderNewsComponent from '../HeaderNewsComponent'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'

/** style */
import { mediaQuery } from '../../style'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 単話ページ */
const EpisodeLogirl = (
  {
    seriesId = '',
    seasonId = '',
    episodeId = '',
    meta = {},
    programMeta = {},
    howToPlay = {},
    episodes = [],
    product = {},
    course = {},
    status = {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    sendPlayLog = () => {},
    loaded = false
  },
  context
) => {
  if (!size(meta) || !size(programMeta)) return null

  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix
  const isPcDevice = webApp.utils.getDevice() === DIO_PLAYER_DEVICE.PC

  const [progress, setProgress] = useState(webApp.utils.progress(config, meta))

  const playerRef = useRef(null)
  const setPlayerRef = (e) => {
    playerRef.current = e
  }

  const searchParams = useSearchParams({
    childEpisodeIds: get(programMeta, ['values', 'child_episode_ids'], []),
    seasonIds: [Number(seasonId)],
    sortedBy: 'delivery_start_at_newer',
    pagerOptions: {
      episodesPerPages: 60,
      range: 2,
      showBottom: true
    }
  })

  const onClickPlay = (e, asSvod = false) => {
    if (e) e.preventDefault()

    switch (checkAvailableStatus({ status, product }, context, asSvod)) {
      case 'PLAY':
        play(playerRef.current, sendPlayLog)
        break
      case 'PURCHASE_EPISODE':
        purchaseEpisode({ episodeId, seasonId }, context)
        break
      case 'PURCHASE_PLAN':
        purchasePlan({ course }, context, 'logirl')
        break
      default:
        break
    }
  }

  const onClosePlayer = () => {
    setProgress(webApp.utils.progress(config, meta))
  }

  useEffect(() => {
    // リタゲタグの挿入
    Object.keys(tags.tagIds).forEach((key) => {
      const target = document.getElementById(tags.tagIds[key])
      if (!target) {
        webApp.utils.appendUserGram(tags[key])
      }
    })

    return () => {
      document.body.style.overflow = null

      // リタゲタグの削除
      webApp.utils.removeUserGram(Object.values(tags.tagIds))
    }
  }, [])

  useEffect(() => {
    const isAutoPlay = context.routeHandler.query.auto === 't'
    // SPもブラウザで再生するためコメントアウト
    if (loaded && status && /* isPc &&  */ isAutoPlay) {
      const isFree = EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode)
      const isAuthFree =
        EPISODE_DISPLAY_MODE.SVOD_AUTH_FREE === status.displayMode &&
        webApp.utils.isLoggedIn(context)
      const withValidPreviewToken = get(context, [
        'models',
        'state',
        'data',
        'withValidPreviewToken'
      ])
      if (isFree || isAuthFree || status.isPurchased || withValidPreviewToken) {
        onClickPlay()
      }
    }
  }, [loaded, status])

  const metaName = (() => {
    const [title, subTitle] = webApp.utils.titles(meta)
    return `${title} ${subTitle}`
  })()

  // const episodeBannerId1 = get(meta, ['values', 'banner_1'])
  const episodeBannerId2 = get(meta, ['values', 'banner_2'])
  const programBannerId1 = get(programMeta, ['values', 'banner_1'])
  const programBannerId2 = get(programMeta, ['values', 'banner_2'])
  const isLive =
    meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
    meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
  let isClipVod = false

  // LIVE配信 主/副切替ID
  let metaIdForPlayer
  if (isLive) {
    if (meta.values.clipping_select_media_type === 'vod') {
      metaIdForPlayer = meta.values.clipping_vod_ref_id
      isClipVod = true
    }
    if (!metaIdForPlayer) metaIdForPlayer = meta.values.target_ref_id
  }
  if (!metaIdForPlayer)
    metaIdForPlayer = `${config.videocloud.reference_id_prefix || ''}${
      meta.meta_id
    }`
  const playerSettings = webApp.utils.getPlayerSettings(
    config,
    meta,
    status.displayMode
  )

  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  const isBefore = Boolean(
    !withValidPreviewToken && moment(prevTime).isBefore(meta.delivery_start_at)
  )
  const isAfter = Boolean(
    !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
  )
  const thumbnailUrl = getThumbnailUrl(
    { meta, programMeta },
    context,
    config,
    previewUrlList,
    cookieRubocopPrefix
  )
  const inViewTerm = !(isBefore || isAfter)
  const nextPrevText = (() => {
    if (inViewTerm || isLive) return null
    if (isBefore) return 'まもなく配信予定'
    return '次回の配信もお楽しみに！'
  })()
  const isPlayerRenderable =
    metaIdForPlayer &&
    status.displayMode &&
    (inViewTerm || withValidPreviewToken)

  return (
    <>
      <Header />
      <div className="common-wrapper">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />
        {isPcDevice && <Notice programMeta={programMeta} />}
        {isPlayerRenderable && (
          <RenewalPlayer
            ref={setPlayerRef}
            meta_id={metaIdForPlayer}
            thumbnail_url={meta.thumbnail_url}
            subtitle={!!meta.values.subtitle}
            enqueteEnabled={status.isFree}
            material_id={get(product, ['ref_id']) || ''}
            license_id={get(course, ['ref_id']) || ''}
            display_mode={status.displayMode}
            onClose={onClosePlayer}
            isLive={isLive}
            isClipVod={isClipVod}
            {...playerSettings}
          />
        )}
        <CommonVideoArea
          progress={progress}
          onClickPlay={onClickPlay}
          isLive={isLive}
          inViewTerm={inViewTerm}
          metaName={metaName}
          thumbnailUrl={thumbnailUrl}
          nextPrevText={nextPrevText}
          meta={meta}
          status={status}
          product={product}
          episodeId={episodeId}
          seriesId={seriesId}
          seasonId={seasonId}
          hidePlayButton
        />
        <div className="c-storyMeta">
          <div className="c-storyMeta-inBox">
            <Meta
              isLive={isLive}
              metaName={metaName}
              meta={meta}
              product={product}
              course={course}
              status={status}
            />
            <Actions
              onClickPlay={onClickPlay}
              inViewTerm={inViewTerm}
              metaName={metaName}
              seriesId={seriesId}
              seasonId={seasonId}
              meta={meta}
              product={product}
              status={status}
              course={course}
              renderLinkLoginOrMyPage
            />
          </div>
        </div>
        <div className="c-listMeta">
          <div className="c-listMeta-inBox">
            <div className="c-listMeta-inBox-main">
              {programBannerId1 && (
                <HtmlSnippet key="banner" snippetId={programBannerId1} />
              )}
              {Object.keys(programMeta).length > 0 && (
                <section>
                  <StyledH3>単話</StyledH3>
                  <StyledFilterSort
                    searchParams={searchParams}
                    episodeListItemProps={{
                      showNew: true,
                      showChecked: true,
                      showCoin: true,
                      showCaption: true,
                      onlySubTitle: true
                    }}
                  />
                </section>
              )}
            </div>
            <ListMetaSub
              loaded={loaded}
              episodeBannerId={episodeBannerId2}
              programBannerId={programBannerId2}
              episodes={episodes}
              course={course}
              seriesId={seriesId}
              seasonId={seasonId}
              status={status}
              meta={meta}
            />
          </div>
        </div>
        <EpisodeFooter
          className="mt0"
          copyrights={meta.values.evis_Copyright}
          courseId={
            Object.keys(howToPlay).length
              ? howToPlay.courses[0].course_id
              : null
          }
        />
      </div>
      <GlobalStyle />
    </>
  )
}

export default EpisodeLogirl

EpisodeLogirl.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のエピソードID */
  episodeId: PropTypes.string,

  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** シーズンのメタ情報 */
  programMeta: PropTypes.object,
  /** 動画の視聴権利関連情報 */
  howToPlay: PropTypes.object,
  /** 動画の価格、配信期間情報など */
  product: PropTypes.shape({
    product_id: PropTypes.number,
    name: PropTypes.string,
    original_price: PropTypes.number,
    active_pricing: PropTypes.shape({
      price: PropTypes.number,
      unit: PropTypes.string
    })
  }),

  // 通常単話
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  // 月額見放題
  /** 動画のコース情報 */
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),
  /** 動画の視聴ステータス情報 */
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  /** 動画再生時のログ送信 */
  sendPlayLog: PropTypes.func,
  /** データの取得が終わっているか */
  loaded: PropTypes.bool
}

EpisodeLogirl.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

const GlobalStyle = createGlobalStyle`
.u-hide--browser {
  display: none !important;
}

.c-listMeta-inBox-main {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
`

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 600;

  ${mediaQuery()} {
    padding: 0 15px;
  }
`

const StyledFilterSort = styled(FilterSort)`
  margin-top: 20px;
`
