import styled from 'styled-components'
import { mediaQuery, breakPoints } from '../../../style'
import Button1 from '../common/Button/Button1'
import Button2 from '../common/Button/Button2'
import Button3 from '../common/Button/Button3'

export const StyledUl = styled.ul`
  ${mediaQuery('sm', 'min')} {
    display: none;
  }
`

export const StyledLi = styled.li`
  &:not(:first-child) {
    margin-top: 15px;
  }
`

export const StyledButton1 = styled(Button1)`
  font-size: 1.6rem;
`

export const StyledButton2 = styled(Button2)`
  font-size: 1.6rem;
`

export const StyledButton3 = styled(Button3)`
  height: 50px;
  font-size: 1.6rem;

  span {
    font-size: 1.4rem;
  }
`
