import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import routes from '../../../../routes'
import browserInfo from '../../../../../../sketch-platform/utils/browserInfo'

/* components */
import { LOGIN_CONTENTS } from '../../../../../../constants/app'
import Errors from './Errors'
import SnsButtons from '../SnsButtons'
import Terms from '../Terms'
import QRCode from './QRCode'

/* style */
import { StyledH2 } from '../styles'
import {
  StyledDiv1,
  StyledSection,
  StyledForm,
  StyledUl,
  StyledLi,
  StyledLabel,
  StyledButton,
  StyledLink
} from './styles'

const ERROR_INVALID_LOGIN =
  'ログインメールアドレス、またはパスワードを正しく入力してください。'
const ERROR_INTERNAL = 'エラーが発生しました。管理者にお問い合わせください。'

/** ログイン コンテンツ */
export default class Login extends Component {
  static propTypes = {
    /** ログイン成功後コールバック */
    onLoggedIn: PropTypes.func,
    content: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string
    }),
    socialLoginRedirectUrl: PropTypes.string,
    errorMessages: PropTypes.arrayOf(PropTypes.string)
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object,
    updateUserInfo: PropTypes.func,
    updateGlobalNavigation: PropTypes.func
  }

  static defaultProps = {
    onLoggedIn: null,
    content: LOGIN_CONTENTS.LOGIN,
    socialLoginRedirectUrl: '',
    errorMessages: []
  }

  constructor(props, context) {
    super(props, context)
    this.config = context.models.config.data
    this.state = {
      form: {
        email: '',
        password: '',
        rememberMe: false
      },
      errorMessages: props.errorMessages,
      isSubmitting: false,
      currentContent: props.content
    }

    this.onChangeEmail = this.onChangeEmail.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangeRememberMe = this.onChangeRememberMe.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.login = this.login.bind(this)
  }

  componentDidUpdate(prevProps) {
    // HACK 親コンポーネントのsetState完了前に本コンポーネントのconstructorが走るため、
    // 親errorMessagesのstate変更を受けてからstateを更新する
    const { errorMessages } = this.props
    if (errorMessages !== prevProps.errorMessages) {
      this.setState({ errorMessages })
    }
  }

  onChangeEmail(e) {
    this.setState({
      form: {
        ...this.state.form,
        email: e.target.value
      }
    })
  }

  onChangePassword(e) {
    this.setState({
      form: {
        ...this.state.form,
        password: e.target.value
      }
    })
  }

  onChangeRememberMe(e) {
    this.setState({
      form: {
        ...this.state.form,
        rememberMe: e.target.checked
      },
      isChecked: !this.state.isChecked
    })
  }

  onKeyDown(e) {
    // 入力のたびに検索されるとこまるので、Enter押したときだけ検索するようにする
    if (e.key === 'Enter') {
      const { email, password } = this.state.form
      if (email && password) {
        this.login(e)
      }
    }
  }

  /**
   * ログイン時バリデーション
   * @returns {boolean} バリデーション結果 (true: 未入力のフィールドがある, false: 入力されている)
   */
  validate() {
    const { email, password } = this.state.form
    const isValid = !email || !password
    if (isValid) {
      this.setState({ errorMessages: [ERROR_INVALID_LOGIN] })
    }
    return isValid
  }

  /** ログイン */
  login(e) {
    e.preventDefault()
    if (this.state.isSubmitting || this.validate()) return

    this.setState({ isSubmitting: true }, () => {
      // session regenerateできないため、Falcor経由ではなく、nodejs上のAPIを利用
      const { email, password, rememberMe } = this.state.form
      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            try {
              const response =
                get(JSON.parse(xhr.responseText), ['response']) || {}
              if (xhr.status !== 200) return reject(response)
              return resolve(response)
            } catch (err) {
              return reject(err)
            }
          }
        }
        xhr.open('post', '/do_login', false)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.send(JSON.stringify({ email, password, rememberMe }))
      })
        .then((result) => {
          webApp.utils.debug(
            `[LoginForm] Login succeeded. result: ${JSON.stringify(result)}`
          )

          if (this.props.onLoggedIn) {
            this.context.updateGlobalNavigation()
            this.props.onLoggedIn()
          } else {
            // redirect指定があったらリダイレクト、なければトップ画面
            const { redirect } = this.context.routeHandler.query
            /** 単話ページの判定 */
            const reg = /\/program\/[0-9]{1,5}-[0-9]{1,5}\/[0-9]{1,5}/
            const isEpisodePage = reg.test(redirect)
            /** 単話ページからきたらパラメーター付与 */
            const path = isEpisodePage ? `${redirect}?auto=t` : redirect
            const route = webApp.utils.isApp(this.context)
              ? routes.app_home
              : routes.home
            window.location.href = path || route.makePath()
          }
        })
        .catch((error) => {
          webApp.utils.debug(
            `[LoginForm] Login failed. error: ${JSON.stringify(error)}`
          )
          if (error.status === 401) {
            this.setState({ errorMessages: [ERROR_INVALID_LOGIN] })
          } else if (error.status === 500) {
            this.setState({ errorMessages: [ERROR_INTERNAL] })
          }
        })
        .finally(() => {
          this.setState({ isSubmitting: false })
        })
    })
  }

  render() {
    const { socialLoginRedirectUrl } = this.props
    const { errorMessages, isSubmitting, currentContent } = this.state
    const route = webApp.utils.isApp(this.context)
      ? routes.app_remake_password
      : routes.remake_password
    const device = browserInfo(navigator.userAgent, (data) => data)

    return (
      <>
        {/* TODO div1不要 */}
        <StyledDiv1>
          <StyledSection>
            <StyledForm>
              <StyledH2>tv asahi iDでログイン</StyledH2>
              <Errors errors={errorMessages} />
              <StyledUl>
                <StyledLi>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="メールアドレス"
                    value={this.state.form.email}
                    onChange={this.onChangeEmail}
                    onKeyDown={this.onKeyDown}
                  />
                </StyledLi>
                <StyledLi className="input-box">
                  <input
                    type="password"
                    name="pass"
                    id="pass"
                    placeholder="パスワード"
                    value={this.state.form.password}
                    onChange={this.onChangePassword}
                    onKeyDown={this.onKeyDown}
                  />
                </StyledLi>
              </StyledUl>
              <StyledLabel isChecked={this.state.isChecked}>
                <input
                  type="checkbox"
                  name="remember-me"
                  id="check"
                  checked={this.state.rememberMe}
                  onChange={this.onChangeRememberMe}
                />
                ログインしたままにする
              </StyledLabel>

              {device.isSilk && <QRCode />}

              <StyledButton
                type="button"
                onClick={this.login}
                disabled={isSubmitting}
                isSilk={device.isSilk}
              >
                ログイン
              </StyledButton>
              <StyledLink route={route}>パスワードをお忘れですか？</StyledLink>
            </StyledForm>
          </StyledSection>

          <StyledSection>
            <SnsButtons
              currentContent={currentContent}
              socialLoginRedirectUrl={socialLoginRedirectUrl}
            />
          </StyledSection>
        </StyledDiv1>

        <Terms />
      </>
    )
  }
}
