import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { StyledDiv4, StyledImg } from './styles'

const Slider3 = ({ episodes, nav, setSlider }) => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    focusOnSelect: true
  }

  return (
    <Slider {...settings} asNavFor={nav} ref={(slider) => setSlider(slider)}>
      {episodes.map((meta, index) => {
        const thumbnailUrl = meta.thumbnail_url
        return (
          <StyledDiv4 key={index}>
            <StyledImg src={thumbnailUrl} />
          </StyledDiv4>
        )
      })}
    </Slider>
  )
}

Slider3.propTypes = {
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    })
  )
}

export default Slider3
