import React from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import {
  StyledI,
  StyledLi,
  StyledLink,
  StyledNav,
  StyledSpan,
  StyledDiv,
  StyledUl
} from './style'

/** ガリベン大学 ナビゲーションバー */
const Nav1 = ({ navItems = [], navText = '', ...props }) => {
  /** スムーススクロール */
  const smoothScroll = (e, href) => {
    // ページ内遷移の場合のみ発火
    if (href.charAt(0) === '#') {
      e.preventDefault()
      const speed = 600
      const position = document.getElementById(href.slice(1)).offsetTop
      $('html, body').animate({ scrollTop: position }, speed, 'swing')
    }
  }

  if (!navItems.length) return null

  return (
    <StyledNav {...props}>
      <StyledUl>
        {navItems.map(
          (
            {
              href = '',
              src = '',
              width = '',
              height = '',
              alt = '',
              html = '',
              target = ''
            },
            i
          ) => (
            <StyledLi key={`navItem-${i}`}>
              {/* isRich属性追加でナビ外見変更 */}
              <StyledLink
                href={href}
                target={target || null}
                onClick={(e) => smoothScroll(e, href)}
              >
                <StyledI>
                  <img
                    src={src}
                    width={width}
                    height={height}
                    alt={alt}
                    loading="lazy"
                  />
                </StyledI>
                <StyledSpan dangerouslySetInnerHTML={{ __html: html }} />
              </StyledLink>
            </StyledLi>
          )
        )}
      </StyledUl>
    </StyledNav>
  )
}

export default Nav1

Nav1.propTypes = {
  /** ナビゲーションリンクの表示要素
   * @param {string} href - リンク先URL
   * @param {string} src - アイコン画像のパス
   * @param {string} width - アイコン画像の横幅
   * @param {string} height - アイコン画像の高さ
   * @param {string} alt - アイコン画像のalt
   * @param {string} html - 表示するリンク
   * @param {string} target - リンクのtarget要素
   */
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      src: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      alt: PropTypes.string,
      html: PropTypes.string,
      target: PropTypes.string
    })
  ),
  navText: PropTypes.string
}
