import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ExPresTrackedElement extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div className="ptrack-content" data-ui-tracking-context="">
        {this.props.children}
      </div>
    );
  }
}

ExPresTrackedElement.propTypes = {
  imageKey: PropTypes.string,
  isDoubleWide: PropTypes.bool,
  videoId: PropTypes.number
};

ExPresTrackedElement.defaultProps = {
  imageKey: '',
  isDoubleWide: false,
  videoId: 0
};

ExPresTrackedElement.contextTypes = {
  currentPage: PropTypes.string,
  rankNum: PropTypes.number,
  requestId: PropTypes.string ,
  rowNum: PropTypes.number,
  trackId: PropTypes.number,
};
