import React, { memo, useState, useRef, useEffect } from 'react'
import window from 'global'
import styled, { createGlobalStyle } from 'styled-components'
import { mediaQuery } from '../../../exdio/components/style'

/**
 * 汎用ページトップ
 * ボタンクリックでページトップにページ内遷移する
 */
const ScrollTopButton = () => {
  const [showButton, setShowButton] = useState(true)
  const oldIsShowButton = useRef(null)

  const onClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const onScroll = () => {
    const { scrollTop } = document.scrollingElement
    const newShowButton = scrollTop > 1

    if (oldIsShowButton !== newShowButton) {
      setShowButton(newShowButton)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      <button type="button" onClick={onClick} className="c-pageTop" />
      <GlobalStyle isShow={showButton} />
    </>
  )
}

export default memo(ScrollTopButton)

const GlobalStyle = createGlobalStyle`
.c-pageTop {
  padding: 0;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 50%;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 20;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  transition: opacity 0.2s linear 0s;

  &::before {
    width: 16px;
    height: 16px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: block;
    position: absolute;
    top: 25px;
    left: 23px;
    transform: rotate(45deg);
    content: '';
  }

  ${mediaQuery()} {
    right: 12px;
    bottom: 12px;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }

}
`
