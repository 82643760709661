import React, { Component } from 'react';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import browserInfo from '../../../sketch-platform/utils/browserInfo';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import window from 'global';
import webApp from '../../exdio/utils/exdioWebAppUtils';

export default class DFPBanner extends Component {

  static contextTypes = {
    routeHandler: PropTypes.object,
    models: PropTypes.object
  };

  genreKeyMaps = {
    aibou: 'drama',
    doctorx: 'drama',
    precure: 'anime'
  }

  genreMaps = {
    hobby: 'etc'
  }

  getAdUnit() {
    this.adUnit = 'index.php';

    // Genre strings in URL
    if (typeof(this.routeHandler.params.genre) !== 'undefined') {
      if (this.genreMaps[this.routeHandler.params.genre]) {
        // change the genre string
        this.adUnit = this.genreMaps[this.routeHandler.params.genre];
      } else {
        // set the genre string
        this.adUnit = this.routeHandler.params.genre;
      }
    } else if (this.genreKeyMaps[this.routeHandler.params.genreKey]) {
      this.adUnit = this.genreKeyMaps[this.routeHandler.params.genreKey];
    } else if (this.genre) {
      this.adUnit = this.genre;
    }
  }

  constructor(props, context) {
    super(props, context);
    this.routeHandler = context.routeHandler;
    this.meta_id = this.props.meta_id ;
    this.genre = this.props.genre;
    this.getAdUnit();
  }

  componentWillReceiveProps(nextProps){
    if(this.meta_id != nextProps.meta_id){
      DFPManager.refresh();
      this.meta_id = nextProps.meta_id ;
    }
  }

  render() {
    // レクタングルでSP表示のときは表示しない
    const isHide = this.props.position !== 'head' && webApp.utils.isSp();
    if (isHide) return null;

    let Banner = null;

    const withFooterAd = this.props.withFooterAd;
    const device = browserInfo(navigator.userAgent, function(data){ return data; });
    const width = _.get(window, ['document', 'body', 'offsetWidth']);

    // set adUnit
    const deviceType = (device.isAndroid || device.isIPhone) ? 'sp' : 'pc';

    var targetingParam = {};
    if (this.routeHandler.params.seriesId) {
      targetingParam.directory = this.routeHandler.params.seriesId;
    }
    if (this.routeHandler.params.seasonId) {
      targetingParam.season = this.routeHandler.params.seasonId;
    }

    if ((device.isAndroid || device.isIPhone) && width <= 760) {
      if (this.props.position === 'head'){
        Banner = (
          <DFPSlotsProvider dfpNetworkId='107859452'>
            <AdSlot dfpNetworkId='107859452' sizes={[[320, 50]]} adUnit={this.adUnit+'_'+deviceType+'_header'} targetingArguments={targetingParam} />
          </DFPSlotsProvider>
        );
      } else if (this.props.position === 'foot'){
        Banner = (
          <DFPSlotsProvider dfpNetworkId='107859452'>
            <AdSlot dfpNetworkId='107859452' sizes={[[320, 50], [300, 250]]} adUnit={this.adUnit+'_'+deviceType+'_footer'} targetingArguments={targetingParam} />
          </DFPSlotsProvider>
        );
      }
    } else {
      if (this.props.position === 'head') {
        Banner = (
          <div>
            <DFPSlotsProvider dfpNetworkId='107859452'>
              <AdSlot dfpNetworkId='107859452' sizes={[[728, 90]]} adUnit={this.adUnit+'_'+deviceType+'_superbanner'} targetingArguments={targetingParam} />
            </DFPSlotsProvider>
          </div>
        );
      } else if (this.props.position === 'foot') {
        Banner = (
          <div id="contents_rect" className={classnames('avod_ep_contents_rect', {'withFooterAd': withFooterAd})}>
            <img src="/images/exdio/pr.gif"  className="prIcon" alt="pr"/>
            <div className="analytics_pr_rect">
              <DFPSlotsProvider dfpNetworkId='107859452'>
                <AdSlot dfpNetworkId='107859452' sizes={[[300, 250]]} adUnit={this.adUnit+'_'+deviceType+'_rectangle'} targetingArguments={targetingParam} />
              </DFPSlotsProvider>
            </div>
          </div>
        );
      }
    }

    const className = this.props.position === 'head' ? 'common-box-ad-landscape' : '';

    return (
      <div className={`banner ${className}`}>
        {Banner}
      </div>
    );
  }
}
