import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import routes from '../../../../common/routes'

/* hooks */
import useIsMounted from '../../../../hooks/useIsMounted'
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/* components */
import Header from '../ProgramGariben/Header'
import FilterSort from '../../../../common/components/FilterSort'

/* style */
import {
  StyledWrapper,
  StyledH2,
  StyledTagList,
  StyledDiv1,
  StyledDiv2,
  StyledDivInner,
  StyledUl,
  StyledLi,
  StyledButton1,
  StyledButton2,
  StyledFooter
} from './style'

/** GaribenTagList Component */
const GaribenTagList = (
  { meta = {}, tags = [], seasonIdList = [], ...props },
  context
) => {
  if (!size(meta)) return null

  const config = context.models.config.data
  const isMounted = useIsMounted()

  const [selectedTags, setSelectedTags] = useState([]) // 選択中のタグ

  const searchParams = useSearchParams({
    childEpisodeIds: get(meta, ['values', 'child_episode_ids'], []),
    seasonIds: seasonIdList,
    tags: [],
    sortedBy: 'delivery_start_at_newer',
    pagerOptions: {
      episodesPerPages: 60,
      range: 2,
      showBottom: true
    }
  })

  /** タグの変更に合わせて選択中タグをセット */
  const onChangeTags = (e) => {
    const { checked, value } = e.target
    const _selectedTags =
      checked === true
        ? [...selectedTags, value]
        : selectedTags.filter((tag) => tag !== value)
    setSelectedTags(_selectedTags)
  }

  /** タグによる検索 */
  const onSubmitTags = () => {
    searchParams.set({ tags: selectedTags })
  }

  /** 選択中、検索中のタグをリセット */
  const onResetTags = () => {
    const checkboxes = document.querySelectorAll(
      'input[type="checkbox"][name="selectedTag"]'
    )
    checkboxes.forEach((checkbox) => {
      // eslint-disable-next-line no-param-reassign
      checkbox.checked = false
    })
    setSelectedTags([])
    searchParams.set({ tags: [] })
  }

  /**
   * 前のページに戻る
   * historyがある場合は前のページ
   * ない場合は特別授業動画に遷移
   */
  const goBack = () => {
    const { history } = context
    const seriesId = config.svod.gariben_daigaku.special_movies.series_id
    const seasonId = config.svod.gariben_daigaku.special_movies.season_id

    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(routes.program.makePath({ seriesId, seasonId }))
    }
  }

  if (!isMounted) return null

  return (
    <StyledWrapper {...props}>
      <Header hasBackButton onClickButton={goBack} />
      <StyledDiv1>
        <StyledH2>タグ一覧</StyledH2>
        <StyledTagList
          tags={tags.map((tag) => tag.name)}
          selected={selectedTags}
          changeTags={onChangeTags}
        />
        <StyledUl>
          <StyledLi>
            <StyledButton1 type="button" onClick={onSubmitTags}>
              検索
            </StyledButton1>
          </StyledLi>
          <StyledLi>
            <StyledButton2 type="button" onClick={onResetTags}>
              リセット
            </StyledButton2>
          </StyledLi>
        </StyledUl>
      </StyledDiv1>
      <StyledDiv2>
        <StyledDivInner>
          <FilterSort
            searchParams={searchParams}
            episodeListItemProps={{
              showNew: true,
              showChecked: true,
              onlySubTitle: true
            }}
          />
        </StyledDivInner>
      </StyledDiv2>
      <StyledFooter />
    </StyledWrapper>
  )
}

export default GaribenTagList

GaribenTagList.propTypes = {
  meta: PropTypes.shape({}),
  tags: PropTypes.arrayOf(PropTypes.string),
  seasonIdList: PropTypes.arrayOf(PropTypes.number)
}

GaribenTagList.contextTypes = {
  models: PropTypes.shape({}),
  history: PropTypes.shape({})
}
