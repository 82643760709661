import styled, { keyframes } from 'styled-components'
import { mediaQuery } from '../../../style'
import { lightPink2, spMinSize } from '../styles'
import Heading1 from '../Heading1'

const rocket = keyframes`
  0% {
      top: 110%;
      left: -20%;
  }
  100% {
      top: -20%;
      left: 90%;
  }
`
const rocket_sp = keyframes`
  0% {
      top: 110%;
      left: -50%;
  }
  100% {
      top: -20%;
      left: 90%;
  }
`

export const StyledHeading1 = styled(Heading1)`
  height: 412px;
  position: relative;

  ${mediaQuery()} {
    height: 120px;
  }

  &.lazyloaded::after {
    margin: 0 auto;
    width: 1152px;
    height: 394px;
    background: url(/images/exdio/renewal/gariben_daigaku/top/bg1_2.webp)
      no-repeat bottom center / 100% auto;
    position: absolute;
    right: 0;
    bottom: -15px;
    left: 0;
    content: '';

    ${mediaQuery()} {
      width: ${spMinSize};
      height: calc(394px * (320 / 1152));
      bottom: -3px;
    }
  }

  img {
    position: relative;
    z-index: 1;

    ${mediaQuery()} {
      width: 202px;
    }
  }
`

export const StyledDiv1 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`

export const StyledDiv2 = styled.div`
  width: 358px;
  height: 91px;
  position: absolute;
  animation: ${rocket} 16s linear infinite;

  &.lazyloaded {
    background: url(/images/exdio/renewal/gariben_daigaku/top/rocket.webp)
      no-repeat center / 100% auto;
  }

  ${mediaQuery()} {
    transform: scale(0.5);
    animation: ${rocket_sp} 16s linear infinite;
  }
`

export const StyledDiv3 = styled.div`
  padding: 40px 0;
  &.lazyloaded {
    background: ${lightPink2}
      url(/images/exdio/renewal/gariben_daigaku/top/sec8_bg.webp) repeat top
      center / cover;
  }

  ${mediaQuery()} {
    padding: 35px 10px 10px;
  }
`

export const StyledDiv4 = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  color: #855401;
  text-shadow: 2px 2px 0 #fff, -2px -2px 0 #fff, -2px 2px 0 #fff,
    2px -2px 0 #fff, 0px 2px 0 #fff, 0-2px 0 #fff, -2px 0 0 #fff, 2px 0 0 #fff;
  padding: 10px 0;
  margin: 0 auto 20px;
  width: 1100px;
  text-align: center;

  &.lazyloaded {
    background: url(/images/exdio/renewal/gariben_daigaku/top/sec8_dg_pc.png)
      no-repeat center / 100% auto;
  }

  ${mediaQuery()} {
    width: auto;
    margin: 15px auto;

    p {
      width: 90%;
      margin: 0 auto;
      font-size: 1.8rem;
    }

    &.lazyloaded {
      background: url(/images/exdio/renewal/gariben_daigaku/top/sec8_dg_sp.png)
        no-repeat center / 100% auto;
    }
  }
`

export const StyledDiv5 = styled.div`
  display: flex;
  margin: 30px auto 10px;
  padding: 15px;
  width: 960px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);

  ${mediaQuery()} {
    width: auto;
  }

  ${mediaQuery(767)} {
    flex-flow: column;
    align-items: center;
    width: auto;
  }
`

export const StyledImg = styled.img`
  width: 150px;
  margin-right: 15px;

  ${mediaQuery(767)} {
    width: 100px;
    margin-right: 0;
    margin-bottom: 5px;
  }
`

export const StyledDiv6 = styled.div`
  font-size: 1.4rem;
  width: 765px;

  ${mediaQuery()} {
    width: calc(100% - 165px);
  }

  ${mediaQuery(767)} {
    width: auto;
  }
`

export const StyledP1 = styled.p`
  font-weight: bold;
  margin-bottom: 15px;

  ${mediaQuery(767)} {
    width: auto;
    margin-bottom: 5px;
    text-align: center;
  }
`

export const StyledDiv7 = styled.div`
  white-space: pre-wrap;
`
