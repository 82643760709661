import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import { PUBLISH_STATUS } from '../../../../../constants/app'
import SwitchableList from '../../../../common/components/renewal/SwitchableList'

/** マイページ:購入済パック */
export default class PurchasedPack extends Component {
  static propTypes = {
    /** 購入済のパック */
    product: PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      schema_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired
    }).isRequired,
    /** 購入日時 */
    purchase_date: PropTypes.string.isRequired,
    /** 視聴期限 */
    limit_date: PropTypes.string.isRequired,
    /** 「戻る」ボタン押下時実行する関数 */
    goBack: PropTypes.func.isRequired
  }

  static defaultProps = {}

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.state = {
      right: {},
      episodes: []
    }

    // DioPlayer_iOSかチェック
    this.isIosDioPlayer = navigator.userAgent
      .split(' ')
      .find((value) => value.match(/^DioPlayer_iOS/g))
  }

  componentDidMount() {
    this._isMounted = true
    this.getRight().then(() => this.getEpisodes())
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** 権利情報取得 */
  getRight() {
    const { product } = this.props
    const path = ['product', 'rights', product.product_id]
    return this.model.fetch([path]).then((result) => {
      const rights = _.get(result, ['json', ...path]) || []
      this.setState({ right: rights[0] })
    })
  }

  /** エピソード取得 */
  getEpisodes() {
    const { right } = this.state
    const path = ['right', 'metas', right.right_id]
    return this.model.fetch([path]).then((result) => {
      const metas = _.get(result, ['json', ...path]) || []
      // 公開以外も取得されるのでフィルター
      const episodes = metas
        .filter((meta) => meta.publish_status === PUBLISH_STATUS.PUBLISHED)
        .sort((a, b) => (a.delivery_start_at < b.delivery_start_at ? 1 : -1))
      this.setState({ episodes })
    })
  }

  render() {
    const { product, goBack } = this.props
    const { episodes, right } = this.state
    const limitDate = this.props.limit_date
    const purchaseDate = this.props.purchase_date

    const config = this.context.models.config.data
    const price = _.get(product, ['active_pricing', 'price'])
    const thumbnailUrl =
      _.get(product, ['thumbnail_url']) || config.default_thumbnail
    const purchaseDateFmt = moment(purchaseDate).format('YYYY/MM/DD')
    const deadLine = webApp.utils.deadLine(this.context, limitDate)
    const bottomLabel = webApp.utils.productLabel(product, null)
    const viewTerm = _.get(right, ['view_term']) || ' '

    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <header className="c-mypage-cont-inBox-head">
            <a href="#" className="c-mypage-btnBack" onClick={goBack}>
              戻る
            </a>
            <h3 className="c-mypage-cont-inBox-head-title">{this.isIosDioPlayer ? "お持ちのコンテンツ" : "購入済"}</h3>
          </header>

          <div className="c-myHeadMeta">
            <div className="c-myHeadMeta-metaBox">
              <div className="c-myHeadMeta-metaBox-art">
                <div className="c-myHeadMeta-metaBox-art-inBox">
                  {bottomLabel && (
                    <div className="c-myHeadMeta-metaBox-art-inBox-labelBottom">
                      {bottomLabel}
                    </div>
                  )}
                  <div className="c-myHeadMeta-metaBox-art-inBox-artwork">
                    <img
                      src={thumbnailUrl}
                      className="c-myHeadMeta-metaBox-art-inBox-artwork-img"
                    />
                  </div>
                </div>
              </div>
              <div
                className="c-myHeadMeta-metaBox-info clickable"
                onClick={() =>
                  webApp.utils.goToProgramLink(this.context, null, product)
                }
              >
                <h2 className="c-myHeadMeta-metaBox-info-title">
                  {product.name}
                </h2>
                <div className="c-myHeadMeta-metaBox-info-purchasedDate">
                  {this.isIosDioPlayer ? "コンテンツ取得日：" : "購入日："}{purchaseDateFmt}
                </div>
                {!this.isIosDioPlayer && (
                  <div className="c-myHeadMeta-metaBox-info-price pack">
                    購入金額：
                    <span className="c-myHeadMeta-metaBox-info-price-coin">
                      {price}
                    </span>
                  </div>
                )}
                {viewTerm !== ' ' && viewTerm > 0 && (
                  <div className="c-myHeadMeta-metaBox-info-viewingPeriod">
                    視聴期間：{this.isIosDioPlayer ? "コンテンツ取得後" : "動画購入後"}{viewTerm}日間
                  </div>
                )}
                <div
                  // chromaticで画像の変更を検知しないための設定
                  data-chromatic="ignore"
                  className="c-myHeadMeta-metaBox-info-viewingDeadline"
                >
                  {deadLine}
                </div>
              </div>
            </div>
          </div>

          <SwitchableList
            episodes={null}
            rootMetas={episodes}
            className="myPageList"
            placeholder="パックの中から探す"
            onlySubTitle
            showNew
            showChecked
          />
        </div>
      </div>
    )
  }
}
