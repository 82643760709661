import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../common/routes'
import useIsMounted from '../../../../../hooks/useIsMounted'

/* style */
import { StyledHeader, StyledLink, StyledButton } from './style'

/** Header Component */
const Header = (
  { hasBackButton = false, onClickButton = () => {}, ...props },
  ref
) => {
  const isMounted = useIsMounted()

  if (!isMounted) return null

  return (
    <StyledHeader ref={ref} {...props}>
      {hasBackButton && (
        <StyledButton onClick={onClickButton}>戻る</StyledButton>
      )}
      <StyledLink route={routes.plan} params={{ slug: 'gariben_daigaku' }}>
        <img
          src="/images/exdio/renewal/gariben_daigaku/gariben_top_banner.png"
          alt="私立ガリベン大学"
        />
      </StyledLink>
    </StyledHeader>
  )
}

export default forwardRef(Header)

Header.propTypes = {
  hasBackButton: PropTypes.bool,
  onClickButton: PropTypes.func
}
