import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from '../../../../../../../sketch-platform/ui/routing'

/** Section3｜バナー */
const Banner = ({ ...props }) => {
  return (
    <StyledDiv {...props}>
      <StyledLink
        href="https://www.tv-asahi.co.jp/reading/garibendaigaku/"
        target="_blank"
        className="p-top3__banner"
        aria-label="私立ガリベン大学図書館ブログトップ　書架一覧はこちら"
      >
        <img
          src="/images/exdio/renewal/gariben_daigaku/top/bnr_750x115_03.webp"
          alt="私立ガリベン大学図書館ブログトップ　書架一覧はこちら"
          width="750"
          height="115"
          className="p-top3__banner__img"
          loading="lazy"
        />
      </StyledLink>
    </StyledDiv>
  )
}

export default Banner

Banner.propTypes = {
  /** styleの継承 */
  className: PropTypes.string
}

const StyledDiv = styled.div`
  text-align: center;
`

const StyledLink = styled(Link)`
  display: inline-block;
  transition: opacity 0.4s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`
