import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import DFPBanner from '../../../../common/components/DFPBanner'
import Palette from '../../../../common/components/renewal/Palette'
import PaletteSkeleton from '../../../../common/components/renewal/PaletteSkeleton'
import ImageSlider from '../../../../common/components/renewal/ImageSlider'
import { PALETTE_SCHEMA_ID } from '../../../../../constants/app'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'
import Footer from '../../../../common/components/Footer'
import ProgramListGrid from '../genre/ProgramListGrid'
import HeaderNewsComponent from '../HeaderNewsComponent'
import HtmlSnippet from '../../HtmlSnippet'

/** ジャンルトップ */
export default class GenreDefault extends Component {
  static propTypes = {
    /** 画像スライドの描画に使用 */
    canvasConfig: PropTypes.shape({
      ad_idx_banner: PropTypes.number,
      ad_idx_side: PropTypes.number,
      large_idx: PropTypes.arrayOf(PropTypes.number),
      twitter: PropTypes.bool,
      ad: PropTypes.bool,
      palette_header: PropTypes.bool
    }).isRequired,
    /** 画像スライドのタイトル、タイプ判別に使用 */
    attribute: PropTypes.shape({
      name: PropTypes.string,
      attribute_id: PropTypes.number
    }).isRequired,
    /** 画像スライドに描画する情報のパレット */
    palettes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    /** 動画の視聴権利関連情報 */
    howToPlays: PropTypes.shape({}).isRequired,
    /** 動画の価格、配信期間情報など */
    products: PropTypes.shape({}).isRequired,
    /** 動画のコース情報 */
    courses: PropTypes.shape({}).isRequired,
    /** データの取得が終わっているか */
    loaded: PropTypes.bool
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  static defaultProps = {
    loaded: false
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      pagerOptions: {
        // ページャーの設定
        episodesPerPages: 20,
        range: 2
      }
    }

    this.paletteComponents = this.paletteComponents.bind(this)
    this.keywordComponent = this.keywordComponent.bind(this)
  }

  componentDidMount() {}

  paletteComponents() {
    const {
      canvasConfig,
      palettes,
      howToPlays,
      products,
      courses,
      loaded
    } = this.props
    const { large_idx, palette_header } = canvasConfig

    // 画像スライド、注目キーワード、ジャンルトップ番組一覧以外のパレット
    let otherPalette = []
    if ((palettes || []).length > 0) {
      otherPalette = palettes.filter((p) => {
        return ![
          PALETTE_SCHEMA_ID.IMAGES,
          PALETTE_SCHEMA_ID.KEYWORDS,
          PALETTE_SCHEMA_ID.GENRE_TOP_PROGRAM_LIST
        ].includes(p.schema_id)
      })
    }

    if (otherPalette.length > 0 || loaded) {
      return otherPalette.map((p, i) => {
        return (
          <Palette
            key={p.palette_id}
            palette={p}
            howToPlays={howToPlays}
            large={(large_idx || []).includes(i + 1)}
            products={products}
            courses={courses}
            paletteHeader={palette_header !== false}
            loaded={loaded}
          />
        )
      })
    }
    const skeletonObjects = []
    for (let i = 0; i < 2; i += 1) {
      skeletonObjects.push(<PaletteSkeleton key={`palette_skeleton_${i}`} />)
    }
    return skeletonObjects
  }

  keywordComponent() {
    const { canvasConfig, palettes, loaded } = this.props
    const { palette_header } = canvasConfig

    // 注目キーワード用パレット
    const keywordPalette = (palettes || []).find(
      (p) => p.schema_id === PALETTE_SCHEMA_ID.KEYWORDS
    )

    return keywordPalette ? (
      <Palette
        key={keywordPalette.palette_id}
        palette={keywordPalette}
        paletteHeader={palette_header !== false}
        loaded={loaded}
      />
    ) : null
  }

  renderWithAd(paletteComponents) {
    const { ad_idx_side, ad_idx_banner, twitter } = this.props.canvasConfig
    const { loaded } = this.props
    const adIdxSide = ad_idx_side || 3
    const adIdxBanner = ad_idx_banner || 4

    const paletteComponentsWithBanner = []
    paletteComponentsWithBanner.push(
      ...paletteComponents.slice(0, adIdxBanner - 1)
    )
    paletteComponentsWithBanner.push(
      loaded ? <DFPBanner position="head" meta_id="" key="banner" /> : null
    )
    paletteComponentsWithBanner.push(
      ...paletteComponents.slice(adIdxBanner - 1)
    )

    // configのad_idx_banner, ad_idx_sideで指定した行に広告挿入
    const rowCountWithAd = (twitter ? 3 : 1) - 1
    const beforeAdPalettes = paletteComponentsWithBanner.slice(0, adIdxSide - 1)
    const adPalettes = paletteComponentsWithBanner.slice(
      adIdxSide - 1,
      adIdxSide + rowCountWithAd
    )
    const afterAdPalettes = paletteComponentsWithBanner.slice(
      adIdxSide + rowCountWithAd
    )

    return [beforeAdPalettes, adPalettes, afterAdPalettes]
  }

  render() {
    const {
      canvasConfig,
      attribute,
      palettes,
      howToPlays,
      products,
      courses
    } = this.props
    const { ad } = canvasConfig
    const { pagerOptions } = this.state

    // 画像スライド用パレット
    const imageSliderPalette = (palettes || []).find(
      (p) => p.schema_id === PALETTE_SCHEMA_ID.IMAGES
    )
    const imageSliderObjects = _.get(imageSliderPalette, 'objects') || []

    // ジャンルトップ番組一覧用パレット
    const genreTopProgramList = (palettes || []).find(
      (p) => p.schema_id === PALETTE_SCHEMA_ID.GENRE_TOP_PROGRAM_LIST
    )

    // アニメジャンルのみ表示するスニペット
    const animeBnr = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 13
      : 62
    const animeAttributeId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 25
      : 20

    // ドラマジャンルのみ表示するスニペット
    const doramaBnr = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 13
      : 64
    const doramaAttributeId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 23
      : 18

    return (
      <div className="common-wrapper genre">
        <HeaderNewsComponent />
        <SpSubNavigation />
        <div className="c-head-sub">
          <div className="c-head-sub-genreTitle">
            <h2 className="c-head-sub-genreTitle-title">
              {/* ('／', '・'に置換 DGA2-1989) */}
              {attribute.name.replace('／', '・')}
            </h2>
          </div>
        </div>
        <ImageSlider key="slide-program" objects={imageSliderObjects} />
        {attribute && attribute.attribute_id === doramaAttributeId && (
          <HtmlSnippet key="banner" snippetId={doramaBnr} />
        )}
        {attribute && attribute.attribute_id === animeAttributeId && (
          <HtmlSnippet key="banner" snippetId={animeBnr} />
        )}
        {ad === false ? (
          <this.paletteComponents />
        ) : (
          this.renderWithAd(this.paletteComponents())
        )}
        <this.keywordComponent />
        {genreTopProgramList && (
          <ProgramListGrid
            palette={genreTopProgramList}
            howToPlays={Object.assign({}, howToPlays)}
            products={products}
            courses={courses}
            placeholder="検索"
            pagerOptions={pagerOptions}
            showPagerUnder={webApp.utils.isSp()}
          />
        )}
        <Footer />
      </div>
    )
  }
}
