import { Link } from '../../../../../../../sketch-platform/ui/routing'
import styled from 'styled-components'
import { mediaQuery } from '../../../../style'
import { black } from '../../styles'

export const StyledImg = styled.img`
  width: 235px;
  height: 132px;
  vertical-align: bottom;
  object-fit: cover;
  transition: transform 0.4s ease-in-out;

  ${mediaQuery()} {
    width: 100%;
    height: calc((9 / 16) * (100vw * (170 / 375)));
  }
`

export const StyledLink = styled(Link)`
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  color: ${black};
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  cursor: pointer;

  &.lazyloaded {
    background: url(/images/exdio/renewal/gariben_daigaku/components/list10_bg.webp)
      no-repeat center / 100% 100%;
  }

  ${mediaQuery()} {
    padding: 10px 5px;
    font-size: 1.3rem;
    letter-spacing: 0;
    &.lazyloaded {
      background: url(/images/exdio/renewal/gariben_daigaku/components/list10_bg_sp.webp)
        no-repeat center / 100% 100%;
    }
  }

  &:hover {
    ${StyledImg} {
      transform: scale(1.2);
    }
  }
`

export const StyledDiv = styled.div`
  overflow: hidden;
`

export const StyledP = styled.p`
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  font-weight: bold;

  ${mediaQuery()} {
    padding: 5px 3px 10px;
    font-size: 1.3rem;
  }
`

export const StyledFooter = styled.footer`
  margin-top: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledTime = styled.time`
  margin-left: auto;
  display: inline-block;
  color: #666;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: -0.05em;

  ${mediaQuery()} {
    font-size: 1.1rem;
  }
`
