import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import moment from 'moment';
import classnames from 'classnames';
import Cookie from 'js-cookie';
import routes, { getHomeRoute } from '../../../../common/routes';
import DFPBanner from '../../../../common/components/DFPBanner';
import axios from 'axios';

var $  = require('jquery');
import GalleryContent from '../watch/GalleryContent';
import AnotherContent from '../watch/AnotherContent';
import Header from '../../../../common/components/Header';
import HeadNotice from '../../../../common/components/HeadNotice';
import ExVideoPlayer from '../../player/ExVideoPlayer';
import ExLivePlayer from '../../player/ExLivePlayer';
import MainViewLink from '../../../../common/components/MainViewLink';
import Enquete from '../../../../common/components/Enquete';
import browserInfo from '../../../../../sketch-platform/utils/browserInfo';
import AnotherCard from '../watch/AnotherCard';
import Row from '../Row';
import Card from '../top/Card';

var copyright
let setHeight;
let imgHeight;

class Catchup extends Component {

  static getPaths = function(models, options, props) {
    return [];
  };

  static propTypes = {
    history: PropTypes.object,
    id: PropTypes.string,
    metadata: PropTypes.object.isRequired
  };

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    getModelData: PropTypes.func,
    history: PropTypes.object,
    activeMetaId: PropTypes.number,
    webApp: PropTypes.object,
    columnsInRow: PropTypes.number
  };

  constructor(props, context) {
    super(props, context);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);

    this.state = {
      dispose: null,
      generation: -1,
      fetchDataError: null,
      url_set: this.context.models.config.data.dougaLink.Catchup + '/watch/' + this.props.metadata.meta_id,
      hashtags: this.context.models.config.data.default_title,
      hashtags_name: this.context.models.config.data.hashtag,
      epList: null
    };
    this._handleOpenTwitter = this._handleOpenTwitter.bind(this);
    this._handleOpenFacebook = this._handleOpenFacebook.bind(this);
    this.setResize = this.setResize.bind(this);
    this.imgResize = this.imgResize.bind(this);
    this.dougaLink = this.context.models.config.data.dougaLink;
    this.previewUrlList = this.context.models.config.data.preview_url_list;
    this.cookieRubocopPrefix = this.context.models.config.data.cookie_rubocop_prefix;
    this.deviceInfo = browserInfo(navigator.userAgent, function (data) { return data; });
    this.isIPadOS = this.deviceInfo.isMac && global.navigator.platform === "MacIntel" && typeof global.navigator.standalone !== 'undefined'
    this.isSp = this.deviceInfo.isAndroid || this.deviceInfo.isIOS || this.isIPadOS;
    this.api = axios.create({
      baseURL: this.context.models.config.data.service.endpoint,
      timeout: this.context.models.config.data.service.timeout
    });
    this.fetchFrlink = async function(){
      let parent_meta = await this.api.get(`/metas/${this.props.metadata.values.parents_season.id}`).catch(this.errorHandler);
      await this.fetchProgramData(parent_meta);
    }
  }

  _handleOpenTwitter(e) {
    window.open(encodeURI(`https://twitter.com/share?&text=`) + encodeURIComponent(`${this.props.metadata.name}`) + encodeURI(` | ${this.state.hashtags}&hashtags=${this.state.hashtags_name}&url=${this.state.url_set}`), 'tweetwindow', 'width=650, height=450, personalbar=0, toolbar=0, scrollbars=1, resizable=1, left=(window.screen.width-300)/2, top=(window.screen.height-300)/2 ');
    return false;

  }

  _handleOpenFacebook(e) {
    // http://www.tv-asahi.co.jp/douga/crossfire_cu/1981
    window.open(`https://www.facebook.com/share.php?u=${this.state.url_set}`, 'FBwindow', 'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes')
    return false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData(this.props);
    window.addEventListener('resize', this.setResize);
    window.addEventListener('resize', this.imgResize);
    // headタグ内の情報を書き換える
    // ※このタイミングで呼ばないと変更スクリプトが動かないので、必ずテンプレート側で呼び出すこと
    this.props.setHeadTags(this.props.metadata, this.context.webApp.utils, this.context.models.config.data);
    // 単話一覧
    if(this.props.metadata.values.ex_templateId === 2){
      this.fetchFrlink();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
    window.removeEventListener('resize', this.setResize);
    window.removeEventListener('resize', this.imgResize);
  }

  setResize() {
    let imgElement = $(".cnt_ph_main");
    if(imgElement != "undefined"){
      $(imgElement).height($(imgElement).width() * 0.562);
    }
  }

  imgResize() {
    let imgEle = $("#slides");
    if(imgEle != "undefined"){
      $(imgEle).height($(imgEle).width() * 0.562);
    }
  }

  componentDidUpdate(){
    setHeight = this.setResize();
    imgHeight = this.imgResize();
  }

  fetchData(props) {
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, {dispose});
    } else {
      this.setState({dispose});
    }

    evaluator.then(res => {
      delete this.state[JSON.stringify(paths)];

      const newState = {
        fetchDataError: null,
        dispose: null,
        generation: this.model.getVersion(rootPath)
      };

      if (this._isMounted) this.setState(newState);
      else Object.assign(this.state, newState);
    }).catch((e) => {
      const newState = {
        fetchDataError: e,
        fetchingMoreRows: undefined,
        dispose: null
      };
      delete this.state[JSON.stringify(paths)];
      if (this._isMounted) this.setState(newState);
      else Object.assign(this.state, newState);
    });
  }

  fetchProgramData(parent_meta){
    // パラメータに「sort=sort」を付けると、単話の「ソート」を見た一覧で取得できる
    // 取得した一覧の順序はデフォルトが昇順
    // パラメータに「order=asc」で昇順、「order=desc」降順でも設定できる
    const disp_order = parent_meta.data.values.disp_order;
    let param;
    switch(disp_order){
      case 'asc': // 昇順
        param = '?sort=sort&order=asc';
        break;
      case 'desc': // 降順
        param = '?sort=sort&order=desc';
        break;
      default:
        param = '?sort=sort&order=asc';
        break;
    }

    // パラメータを付与して一覧を取得
    this.api
      .get(`/metas/${this.props.metadata.values.parents_season.id}/children${param}`)
      .then(res => {
      // 取得した一覧から前後リンクを設定
        if(res.data.metas.length <= 1) return false ;
        const children = res.data.metas;
        this.setState({epList:children})
      })
      .catch(this.errorHandler);
  }

  // 単話一覧 : PC
  addPcEpisodeList(items) {
    if(this.props.metadata.values.ex_templateId !== 2) return false ;

    // card
    const cards = _.map(items || [], (item, index) => {
      return (
        <Card
          popType={this.context.popType}
          model={this.model}
          key={`title_${item.meta_id}_${index}`}
          rowNum={0}
          rankNum={index}
          seasonId={item.values.parents_season.meta_id}
          program_link={false}
          titleId={item.meta_id}
          progress={0}
          imgTarget='avod_contents_eplist_img'
          subtitle={item.values.subtitle}
        />
      );
    });

    // row
    const size = cards.length;
    const chunckSize = this.props.itemsInRow || this.context.columnsInRow; // いくつずつに分割するか
    const cardChuncks = []; // 新しく作る配列

    for (let i = 0; i < Math.ceil(size / chunckSize); i++) {
      const j = i * chunckSize;
      const chunckedTitles = cards.slice(j, j + chunckSize);
      cardChuncks.push(chunckedTitles);
    }

    const rows = _.map(cardChuncks, (chunck, index) => {
      return (
        <Row
          key={`eplist-row-${index}`}
          totalItems={chunck.length}
          rowNum={index}
          enablePeek={true}
          enablePaginationIndicator={false}
          enablePushOut={true}>
          {chunck}
        </Row>
      );
    });

    return (
      <div className="avod_contents_wrap avod_contents_eplist">
        <div className="avod_main_inner">
          <h3>シリーズ一覧を見る</h3>
          {rows}
        </div>
      </div>
    )
  }

  // 単話一覧 : SP
  addSpEpisodeList(items) {
    if(this.props.metadata.values.ex_templateId !== 2) return false ;

    return (
      <div id="app_avod_ep_li" className="avod_contents_eplist">
        <div className="avod_ep_box clearfix">
          <h3>シリーズ一覧を見る</h3>
          {_.map(items || [], (item, index) => {
            return (
              <AnotherCard
                mainId={this.props.mainId}
                popType={this.context.popType}
                model={this.model}
                key={`epList_${item.meta_id}_${index}`}
                name={item.name}
                thumbnailUrl={item.thumbnail_url + '_medium'}
                rowNum={0}
                index={index}
                seasonId={item.values.parents_season.id}
                program_link={false}
                titleId={item.meta_id}
                progress={0}
                subtitle={item.values.subtitle}
              />
            )
          })}
        </div>
      </div>
    )
  }

  renderHeader() {
    const routeHandler = this.props.routeHandler || this.context.routeHandler;
    const term = routeHandler.query.q || decodeURIComponent(routeHandler.params.term || "");
    const props = {
      fixed: true
    };
    if (term) {
      props.term = term;
    }
    return (
      <Header {...props} />
    );
  }

  // この番組をもっと見るボタン
  renderOfficialSite() {
    let officialSiteUrl;
    if (this.props.metadata.values.ex_OfficialSiteURL) {
      officialSiteUrl = (<p className="avod_main_ep_btn">
        <a href={this.props.metadata.values.ex_OfficialSiteURL} className="FromA_api">この番組をもっと見る</a>
      </p>
      );
    }
    return officialSiteUrl;
  }

  nextPrevText(thumb, e) {
    const alt = e === undefined ? '次回もお楽しみに！' : e;
    return(
      <div id="slides">
        <img src={thumb} width="100%" alt={alt}/>
        {!e ? '' : <span className="slides_back"><p className="slides_ready">{e}</p></span>}
      </div>
    )
  }

  handleClick(e) {
    e.preventDefault();
    const { history } = this.context;
    history.push(routes.program.makePath({id: this.props.metadata.values.parents_season.id}));
  }

  render() {
    const { routeHandler } = this.context;

    if (this.props.metadata.values && this.props.metadata.values.evis_Copyright){
      copyright = this.props.metadata.values.evis_Copyright
    }else{
      copyright = '(C)テレビ朝日'
    }

    var deliveryEndAt = '　'
    var longSynopsis = '　'
    var duration = ''
    var onAirAt = ''
    var cast_area = ''
    var title = ''
    var meta_id = ''
    var target_ref_id = ''
    var target_ad_config_id = ''
    if (this.props.metadata){
      // MEMO: タイトル
      if (this.props.metadata.name && title == '') {
        title = this.props.metadata.name
      }

      // MEMO: meta_id
      if (this.props.metadata.meta_id && meta_id == '') {
        meta_id = this.props.metadata.meta_id
      }
      // LIVE配信 主/副切替ID
      if (this.props.metadata.values.target_ref_id && target_ref_id == '') {
        target_ref_id = this.props.metadata.values.target_ref_id
      }
      // LIVE配信 主/副切替広告ID
      if (this.props.metadata.values.target_ad_config_id && target_ad_config_id == '') {
        target_ad_config_id = this.props.metadata.values.target_ad_config_id;
      }
      // MEMO: 配信期間終了日
      if (this.props.metadata.delivery_end_at && deliveryEndAt == '　') {
        deliveryEndAt = moment(this.props.metadata.delivery_end_at).format("配信期間：YYYY年M月D日 HH:mmまで")
      }

      // MEMO: 時間
      if (this.props.metadata.duration && duration == ''){
        duration = this.props.metadata.duration;
        let seconds = duration % 60; let minutes = ((duration - seconds) / 60) % 60; let hour = parseInt(duration / 3600, 10);
        if (hour > 0) {
          if (minutes > 0) {
            duration = `${hour}時間${minutes}分`;
          } else {
            duration = `${hour}時間`;
          }
        } else if (minutes > 0) {
          duration = `${minutes}分`;
        } else {
          duration = `${seconds}秒`;
        }
        duration = `時間：${duration}`;
      }

      if (this.props.metadata.values){
        // MEMO: エピソードあらすじ(長)
        if (this.props.metadata.values.evis_EpisodeLongSynopsis && longSynopsis ==  '　'){
          var regex = /(\n)/g
          longSynopsis = this.props.metadata.values.evis_EpisodeLongSynopsis.split(regex).map(function (line, i) {
            if (line.match(regex)) {
              return React.createElement('br', {key: i})
            }
            else {
              return line;
            }
          });
        }

        // MEMO: 放送日
        if (this.props.metadata.values.avails_ReleaseHistoryOriginal && onAirAt == '') {
          onAirAt = moment(this.props.metadata.values.avails_ReleaseHistoryOriginal).format("放送日：YYYY/M/D")
        }

        if (this.props.metadata.values.evis_Actors){
          cast_area = (
              <div className="info-cast">
                <h2 className="details-inner-title"><span className="icon-cast"></span>キャスト</h2>
                <p className="episode-cast">{this.props.metadata.values.evis_Actors}</p>
              </div>
            )
        }
      }
    }

    var titleArtwork;
    if (this.props.metadata && this.props.metadata.thumbnail_url){
      titleArtwork = (<div
        className={classnames('title-artwork avod_ep_list_thumb thumbnail', {
          'double-wide': this.props.isDoubleWide === true && this.props.isTallPanel !== true
        })}
        style={{backgroundImage: `url('${this.props.metadata.thumbnail_url}')`}}>
          <img src="/images/exdio/play.png" alt="play" className="btn_play" />
        </div>
      );
    }
    const duration_and_air = duration && onAirAt ? `${duration}　｜　${onAirAt}` : duration ? duration : onAirAt ? onAirAt : ""

    let playerArea;
    const prevTime = Cookie.get(this.previewUrlList + this.cookieRubocopPrefix);

    let playerIcon = (
      <img src="/images/exdio/play.png" id="overPlay" alt="リストアイコン" width="78"/>
    );
    let setPlayerIcon;
    const isIPadOS = this.deviceInfo.isMac && global.navigator.platform === "MacIntel" && typeof global.navigator.standalone !== 'undefined'
    if(this.deviceInfo.isIOS || this.deviceInfo.isAndroid || isIPadOS) {
      setPlayerIcon = playerIcon;
    }

    if (moment(prevTime).isBefore(this.props.metadata.delivery_start_at)) {
      var thumbnail_url = (this.props.metadata.meta_schema_id == 4) ? this.props.metadata.values.livectl_image_before_stream_url : this.props.metadata.thumbnail_url;
      playerArea = this.nextPrevText(thumbnail_url, 'まもなく配信予定');
    } else if (moment(prevTime).isAfter(this.props.metadata.delivery_end_at)) {
      var thumbnail_url = (this.props.metadata.meta_schema_id == 4) ? this.props.metadata.values.livectl_image_after_stream_url : '/images/exdio/nextImage.jpg';
      playerArea = this.nextPrevText(thumbnail_url, '次回の配信もお楽しみに！');
    } else {
      if (this.props.metadata.meta_schema_id == 4 && this.props.metadata.values.target_vod_status === 'not_created') {
        playerArea = (
            <div id="slides" style={imgHeight}>
                <ExLivePlayer
                model={this.model}
                meta_id={target_ref_id ? target_ref_id : meta_id}
                auto_start={true}
                play_head_time={0}
                volume={1}
                is_mute={false}
                controller="default"
                bitrate="auto"
                product_type={1}
                uid=""
                ad_config_id={target_ad_config_id}
                ad_insertion={this.props.metadata.values.livectl_ad_insertion}
                thumbnail_url={this.props.metadata.thumbnail_url}
                subtitle={this.props.metadata.values.subtitle}
                />
              <div className="icon_area">
              {setPlayerIcon}
              </div>
            </div>
        );
      } else {
        meta_id = (this.props.metadata.meta_schema_id == 4 && target_ref_id) ? target_ref_id : meta_id;
        playerArea = (
          <div id="slides" style={imgHeight}>
            <ExVideoPlayer
              model={this.model}
              meta_id={meta_id}
              auto_start={true}
              play_head_time={0}
              volume={1}
              is_mute={false}
              controller="default"
              bitrate="auto"
              product_type={1}
              uid=""
              thumbnail_url={this.props.metadata.thumbnail_url}
              subtitle={this.props.metadata.values.subtitle}
            />
            <div className="icon_area">
            {setPlayerIcon}
            </div>
          </div>
        );
      }
    }

    // 番組用のパンくず
    const programName = title.replace(this.props.metadata.values.avails_EpisodeTitleDisplayUnlimited, '').trim()
    const program_breadcrumbs = this.props.metadata.values.ex_templateId !== 2 ? null : ([
      <a href="javascript:viod(0);" key="program_breadcrumbs" onClick={this.handleClick.bind(this)}>{programName}</a>,
      <span key="program_breadcrumbs_space">&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span> // エスケープ文字はタグで囲む必要がある
    ]);

    // シリーズ一覧を見る : PCレスポンシブ対応のためrender()内で記述
    const EpisodeList = this.isSp ? this.addSpEpisodeList(this.state.epList) : this.addPcEpisodeList(this.state.epList) ;

    return (
      <div>
        {this.renderHeader()}
        <div id="global_nav_space"></div>
        <HeadNotice />
        <div className="newHeaderBanner">
          <div className="headerBannerWrap">
            <DFPBanner
              position="head"
              meta_id={meta_id}
            />
          </div>
        </div>
        <div className="watch">
          <div>
            <div id="avod_head_contents_wrap" className="clearfix">
              <div className="avod_inner">
                <div id="avod_breadcrumbs">
                <p className="navi">
                  <a href={this.dougaLink.Top}>{"TOP"}</a>&nbsp;&nbsp;&gt;&nbsp;&nbsp;
                  <MainViewLink resolver={getHomeRoute} className="breadcrumbs_api">{"テレ朝キャッチアップ"}</MainViewLink>&nbsp;&nbsp;&gt;&nbsp;&nbsp;
                  {program_breadcrumbs}
                  <span>{ title }</span>
                </p>
                </div>
                <div className="avod_main_ep clearfix">
                  <div className="avod_main_ep_L avod_video">
                    <div className="enquete_area">
                      {(() => {
                        return this.deviceInfo.isIOS || this.deviceInfo.isAndroid || this.deviceInfo.isAppli ? null : <Enquete /> ;
                      })()}
                    </div>
                    <div className="cnt_ph_main" style={setHeight}>
                      {playerArea}
                    </div>
                  </div>
                  <div className="avod_main_ep_R">
                    <div className="avod_main_ep_detail">
                      <p className="avod_main_ep_deli">{deliveryEndAt}</p>
                      <h1 className="avod_main_ep_ttl">{this.props.metadata.values.subtitle ? <img src="/images/exdio/episode_cc_icon.png"/> : ''}{title}</h1>
                        <p className="avod_main_ep_air">{duration_and_air}</p>
                      <div id="sns_list">
                        <ul>
                          <li className="sns_list_icon first" id="sns_list_icon_twitter">
                            <a href="javascript:void(0)" onClick={((e) => {this._handleOpenTwitter()})}>
                              <img src="/images/exdio/twitter.png" alt="twitter" />
                            </a>
                          </li>
                          <li className="sns_list_icon" id="sns_list_icon_facebook">
                            <a href="javascript:void(0)" onClick={((e) => {this._handleOpenFacebook()})}>
                              <img src="/images/exdio/facebook.png" alt="facebook" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="avod_main_ep_detail_right">
                    <div className="avod_main_ep_detail_right_inner">
                      {this.renderOfficialSite()}
                      <p className="avod_main_ep_back">
                        <span><img src="/images/exdio/pc_bullet.png" alt="pc_bullet"/></span>
                        <MainViewLink resolver={getHomeRoute} className="main_api">
                          テレ朝キャッチアップ一覧
                        </MainViewLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 他のリストをここに移動させる
          */}
          <GalleryContent
            keyPrefix={"catchup"}
            mainId={this.props.metadata.meta_id}
            sortOrder={'su'}
            program_link={true}
            model={this.model} />
          {/* 概要はここから
          */}
          <div id="avod_contents_wrap">
            <div className="avod_inner">
              <div id="contents_main" className="avod_ep_contents_main">
                <div className="js-main-bnr">
                </div>
                <div className="avod_ep-main-container row">
                  <div className="new_buy_info">
                    <div className="info-wrap">
                      <p className="episode-story">{longSynopsis}</p>
                    </div>
                    {cast_area}
                  </div>
                </div>
                <div className="cpr">
                  <p className="program-copyright">{copyright}</p>
                </div>
              </div>
              <DFPBanner
                position="foot"
                meta_id={meta_id}
              />
              <div id="goTop"><a href="#container"><img src="/images/exdio/bt_gotop.png" alt="ページトップへ戻る" width="114" height="16"/></a></div>
            </div>
          </div>

          {EpisodeList}

          <AnotherContent
            keyPrefix={"catchup"}
            mainId={this.props.metadata.meta_id}
            sortOrder={'su'}
            program_link={true}
            model={this.model} />
        </div>
      </div>
    );
  }
}

export default Catchup;
