/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'

export const StyledDiv1 = styled.div`
  background: #fff
    url('https://wws.tv-asahi.co.jp/douga_mv/minato/images/main_bg.png')
    no-repeat top;
  margin: 0 auto;
  overflow: hidden;
  font-family: 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体',
    'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif;
`

export const StyledH1 = styled.h1`
  width: 1280px;
  margin: 30px auto 0;

  a {
    display: inline-block;

    :hover {
      cursor: pointer;
    }
  }

  ${mediaQuery(1320)} {
    width: 960px;
  }

  ${mediaQuery()} {
    width: 100%;
    margin: 0;
    padding: 15px 0;

    img {
      width: 120px;
    }
  }
`

export const StyledSection = styled.section`
  position: relative;
  margin: 30px auto 40px;
  text-align: center;
  text-shadow: #fff 0 0 15px;
  color: #666;

  ${mediaQuery()} {
    text-align: center;
    text-shadow: #fff 0 0 15px;
    margin-top: 30px;
    color: #666;
  }
`

export const StyledH2 = styled.h2`
  font-family: '游明朝体', 'Yu Mincho', YuMincho, 'ヒラギノ明朝 Pro',
    'Hiragino Mincho Pro', 'MS P明朝', 'MS PMincho', serif;
  font-size: 3rem;
  margin-bottom: 20px;
  letter-spacing: 6px;

  ${mediaQuery()} {
    font-size: 2.4rem;
    letter-spacing: 3px;
  }
`

export const StyledP = styled.p`
  font-size: 1.9rem;
  line-height: 2.5;
  margin-bottom: 40px;

  ${mediaQuery()} {
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 0;
  }
`

export const StyledDiv2 = styled.div`
  background: #fff
    url('https://wws.tv-asahi.co.jp/douga_mv/minato/images/main_bg.png')
    no-repeat top/250% auto;
  padding: 0 8px;
  font-family: 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体',
    'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif;
`
