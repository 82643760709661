import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ProgramItemLink from './ProgramItemLink'
import webApp from '../../../exdio/utils/exdioWebAppUtils'
import { META_SCHEMA_ID } from '../../../../constants/app'
import * as DOMUtils from '../../../utils/DOMUtils'

import Pager from './Pager' // ページャー
import ProgramItemSkeleton from './ProgramItemSkeleton' // スケルトンスクリーン

/** リスト表示切り替えコンポーネント */
export default class SwitchableListGrid extends Component {
  static propTypes = {
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        meta_schema_id: PropTypes.number.isRequired,
        thumbnail_url: PropTypes.string,
        values: PropTypes.object.isRequired,
        name: PropTypes.string,
        duration: PropTypes.number,
        delivery_start_at: PropTypes.string,
        delivery_end_at: PropTypes.string
      })
    ),
    rootMetas: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    placeholder: PropTypes.string,
    showNew: PropTypes.bool,
    showChecked: PropTypes.bool,
    showDelivery: PropTypes.bool,
    onlySubTitle: PropTypes.bool,
    showPagerUnder: PropTypes.bool,
    showEndText: PropTypes.bool
  }

  static defaultProps = {
    loaded: false,
    episodes: [],
    rootMetas: null,
    className: '',
    placeholder: '',
    showNew: false,
    showChecked: false,
    showDelivery: false,
    onlySubTitle: false,
    showPagerUnder: false,
    showEndText: false
  }

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func,
    routeHandler: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.state = {
      filteredEpisodes: props.episodes,
      listType: props.listType,
      searchWord: '',
      modeDsearch: !(props.episodes || []).length, // episodesにnullが指定されたらrootMetasを利用してdsearch検索を行う
      onlySubTitle: props.onlySubTitle,
      currentPageNum: 1,
      displayEpisodes: [],
      loaded: false
    }

    this.isLoading = false

    this.onChangeSearchWord = this.onChangeSearchWord.bind(this)
    this.onClearSearchWord = this.onClearSearchWord.bind(this)
    this.updateDisplayEpisodes = this.updateDisplayEpisodes.bind(this)
    this.updateCurrentPageNum = this.updateCurrentPageNum.bind(this)

    this.setListRef = (e) => {
      this.listRef = e
    }

    this.pagingRef = React.createRef()
  }

  componentDidMount() {
    this._isMounted = true
    const { pagerOptions, episodes } = this.props
    const { modeDsearch, currentPageNum } = this.state

    if (modeDsearch) {
      this.search()
    } else {
      this.setState(
        { filteredEpisodes: episodes },
        function() {
          this.execPaging(currentPageNum, pagerOptions)
        }.bind(this)
      )
    }

    // DBG_CODE
    // window.setResumeInfo = setResumeInfo;
    // window.getResumeInfo = getResumeInfo;
    // window.removeAllResumeInfo = removeAllResumeInfo;
  }

  componentDidUpdate(prevProps, prevState) {
    const { episodes, rootMetas, pagerOptions } = this.props
    const { modeDsearch, currentPageNum } = this.state

    // modeDsearchが変更されたときepisodeを読み込みを再実行
    if (prevState.modeDsearch !== !((episodes || []).length > 0)) {
      this.setState({ modeDsearch: !(episodes || []).length > 0 }, () => {
        if (this.state.modeDsearch) {
          this.search()
        } else {
          this.setState({ filteredEpisodes: episodes }, () => {
            this.execPaging(currentPageNum, pagerOptions)
          })
        }
      })
    }

    if (prevProps.rootMetas !== rootMetas && modeDsearch) {
      this.search()
    }

    if (
      prevProps.episodes !== episodes &&
      (episodes || []).length > 0 &&
      !modeDsearch
    ) {
      this.setState({ filteredEpisodes: episodes }, () => {
        this.execPaging(currentPageNum, pagerOptions)
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** 全角->半角変換、大文字->小文字変換 */
  cleanseWord(str) {
    return str
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 65248)
      )
      .toLowerCase()
  }

  /** 検索ワード変更時 */
  onChangeSearchWord(e) {
    const { pagerOptions } = this.props
    const { currentPageNum } = this.state

    const searchWord = e.target.value
    const searchWordArr = this.cleanseWord(searchWord).split(' ')

    if (!this.state.modeDsearch) {
      const { episodes } = this.props
      const filteredEpisodes = episodes.filter((meta) => {
        for (const word of searchWordArr) {
          if (!this.cleanseWord(meta.name).includes(word)) return false
        }
        return true
      })
      this.setState({ searchWord, filteredEpisodes, currentPageNum: 1 }, () => {
        this.execPaging(currentPageNum, pagerOptions)
      })
    } else {
      this.setState({ searchWord }, () => {
        this.search()
      })
    }
  }

  search() {
    const { rootMetas, pagerOptions } = this.props
    const { searchWord } = this.state

    if (!rootMetas || rootMetas.length <= 0 || this.isLoading) {
      return
    }

    // fromが常に0でもfalcorが差分のみリクエストしてくれる
    const range = { from: 0, to: 2000 }

    // シリーズは運用で入らない
    let episode_ids = []
    let season_ids = []
    let series_ids = [0]

    _.forEach(rootMetas, (meta) => {
      if (
        meta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
        meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
      ) {
        episode_ids.push(meta.meta_id)
      } else if (
        meta.meta_schema_id === META_SCHEMA_ID.SEASON ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE_SEASON
      ) {
        season_ids.push(meta.meta_id)
      }
    })

    let showEpisodeFlag = true
    if (episode_ids.length === 0 && season_ids.length > 1) {
      // シーズンが複数件登録されていたらシーズン一覧表示
      showEpisodeFlag = false
    }

    if (episode_ids.length === 0) {
      episode_ids = [0]
    }
    if (season_ids.length === 0) {
      season_ids = [0]
    }

    const searchType = showEpisodeFlag ? 'episode' : 'season'
    this.isLoading = true
    const path = [
      [
        'meta',
        'packSearch',
        searchType,
        searchWord || '',
        episode_ids,
        season_ids,
        series_ids,
        [range]
      ]
    ]
    return this.model
      .fetch(path)
      .then((result) => {
        if (result && result.json) {
          const metas = _.get(result.json, [
            'meta',
            'packSearch',
            searchType,
            searchWord || '',
            episode_ids[0],
            season_ids[0],
            series_ids[0]
          ])
          if (metas) {
            delete metas.$__path
            if (this._isMounted) {
              if (metas !== this.state.filteredEpisodes) {
                // シリーズを検索する場合はonlySubTitleを変えてタイトルを表示してあげる
                this.setState({
                  filteredEpisodes: Object.values(metas),
                  onlySubTitle: showEpisodeFlag,
                  loaded: true
                })
              }
            }
          }
        } else {
          this.setState({ filteredEpisodes: [] })
        }
      })
      .finally(() => {
        // 1ページ目をセットする
        this.setState(
          { currentPageNum: 1 },
          function() {
            this.execPaging(this.state.currentPageNum, pagerOptions) // 1ページ目を表示する
            this.isLoading = false
          }.bind(this)
        )
      })
  }

  /** 検索ワードクリア時 */
  onClearSearchWord() {
    const { episodes, pagerOptions } = this.props
    const { modeDsearch, currentPageNum } = this.state

    if (modeDsearch) {
      this.setState({ searchWord: '' }, () => {
        this.search()
      })
    } else {
      this.setState(
        { searchWord: '', filteredEpisodes: episodes, currentPageNum: 1 },
        () => {
          this.execPaging(currentPageNum, pagerOptions)
        }
      )
    }
  }

  toggleListType(type) {
    this.setState({ listType: type })
  }

  isWithinDistanceBuffer() {
    if (!this.listRef) return false
    return DOMUtils.getDistanceToBottomOfElement(this.listRef) < 0
  }

  /** Pagerクラスのpagingを実行 */
  execPaging(pageNum, option) {
    this.pagingRef.current.paging(pageNum, option)
  }

  /*
   * エピソードをソートする
   * × エピソード番号(昇順or降順) > AVOD->TVOD(固定) > 配信開始日時(昇順or降順) > ID(昇順or降順)
   *
   * 2021.03.04 DGA_PJ-85
   * 配信開始順のみのソートに変更
   *
   * 2021.03.05 DGA_PJ-85
   * 配信開始[昇順/降順] > エピソード番号[昇順/降順] > ID[昇順/降順]
   */
  sortEpisodes(episodes) {
    if ((episodes || []).length < 1) return

    // 配列の破壊を防ぐため複製
    let sortedEpisodes = episodes.slice()

    const { disp_order } = this.props
    const direction = disp_order == 'asc' ? -1 : 1

    sortedEpisodes.sort((a, b) => {
      //ソート番号 -> 管理画面の昇順or降順に合わせる
      if (a.sort < b.sort) return direction * 1
      if (a.sort > b.sort) return direction * -1

      // 配信開始日時 -> 管理画面の昇順or降順に合わせる
      if (a.delivery_start_at < b.delivery_start_at) return direction * 1
      if (a.delivery_start_at > b.delivery_start_at) return direction * -1

      // メタスキーマIDの昇順(無料, 有料の順) -> 固定
      if (a.meta_schema_id < b.meta_schema_id) return -1
      if (a.meta_schema_id > b.meta_schema_id) return 1

      // エピソード番号 -> 管理画面の昇順or降順に合わせる
      if (
        Number(a.values.avails_EpisodeNumber) <
        Number(b.values.avails_EpisodeNumber)
      )
        return direction * 1
      if (
        Number(a.values.avails_EpisodeNumber) >
        Number(b.values.avails_EpisodeNumber)
      )
        return direction * -1

      // ID -> 管理画面の昇順or降順に合わせる
      if (a.id < b.id) return direction * 1
      if (a.id > b.id) return direction * -1

      return 0
    })

    return sortedEpisodes
  }

  /** 子コンポーネントからのからのアクセス用 */
  updateDisplayEpisodes(episodes) {
    if ((episodes || []).length > 0) {
      this.setState({ loaded: true })
    }
    this.setState({ displayEpisodes: episodes })
  }

  /** 子コンポーネントからのからのアクセス用 */
  updateCurrentPageNum(currentPageNum) {
    this.setState({ currentPageNum: currentPageNum })
  }

  render() {
    const {
      howToPlays,
      className,
      placeholder,
      showNew,
      showDelivery,
      showChecked,
      pagerOptions,
      showPagerUnder,
      showEndText,
      hidePrice,
      loaded: fetchLoaded
    } = this.props
    const {
      filteredEpisodes,
      listType,
      searchWord,
      onlySubTitle,
      displayEpisodes,
      currentPageNum,
      loaded
    } = this.state

    const episodes = this.sortEpisodes(filteredEpisodes) || filteredEpisodes

    /** エピソードリスト */
    const Episodes = () => {
      if (!(displayEpisodes || []).length > 0)
        return '現在配信中のコンテンツはございません'

      return displayEpisodes.map((meta) => {
        const { route, params, query } = webApp.utils.getProgramLinkRoutes(
          this.context,
          meta
        )
        return (
          <ProgramItemLink
            key={meta.meta_id}
            meta={meta}
            howToPlay={
              howToPlays && howToPlays[meta.meta_id]
                ? howToPlays[meta.meta_id]
                : null
            }
            showCaption
            showNew={showNew && webApp.utils.showNew(meta)}
            showChecked={showChecked && webApp.utils.isWatched(meta)}
            showDelivery={showDelivery}
            showCoin
            onlySubTitle={onlySubTitle}
            showEndText={showEndText}
            route={route}
            params={params}
            query={query}
            hidePrice={hidePrice}
          />
        )
      })
    }

    /** スケルトンスクリーン */
    const Skeletons = () => {
      let skeletonObj = []
      for (let i = 0; i < pagerOptions.episodesPerPages; i++) {
        skeletonObj.push(
          <ProgramItemSkeleton
            key={i}
            hasThumb={true}
            titleLength={1}
            textLength={2}
            hasPrice={true}
          />
        )
      }
      return skeletonObj
    }

    return (
      <div
        className={`c-card-vertical ${className} ${listType}`}
        id="episodes-list"
      >
        <Pager
          ref={this.pagingRef}
          option={pagerOptions}
          episodes={episodes}
          currentPageNum={currentPageNum}
          updateDisplayEpisodes={this.updateDisplayEpisodes}
          updateCurrentPageNum={this.updateCurrentPageNum}
        />
        <header className="c-card-vertical-head">
          <div className="common-search-box">
            <div className="search-input">
              <input
                type="text"
                value={searchWord}
                placeholder={placeholder}
                onChange={this.onChangeSearchWord}
              />
              <span className="icon-close" onClick={this.onClearSearchWord} />
            </div>
          </div>
          <div className="c-sortSwitch">
            <ul className="c-sortSwitch-inBox">
              <li
                className={`c-sortSwitch-inBox-btn with-thumb ${
                  listType == 'default' ? 'current' : ''
                }`}
              >
                <span
                  className="c-sortSwitch-inBox-btn-link"
                  onClick={() => this.toggleListType('default')}
                />
              </li>
              <li
                className={`c-sortSwitch-inBox-btn no-thumb ${
                  listType == 'list' ? 'current' : ''
                }`}
              >
                <span
                  className="c-sortSwitch-inBox-btn-link"
                  onClick={() => this.toggleListType('list')}
                />
              </li>
              <li
                className={`c-sortSwitch-inBox-btn grid ${
                  listType == 'grid' ? 'current' : ''
                }`}
              >
                <span
                  className="c-sortSwitch-inBox-btn-link"
                  onClick={() => this.toggleListType('grid')}
                ></span>
              </li>
            </ul>
          </div>
        </header>

        <div className="c-card-vertical-cont" ref={this.setListRef}>
          {loaded === true || fetchLoaded === true ? (
            <Episodes />
          ) : (
            <Skeletons />
          )}
        </div>

        {showPagerUnder && (
          <Pager
            ref={this.pagingRef}
            option={pagerOptions}
            episodes={episodes}
            currentPageNum={currentPageNum}
            updateDisplayEpisodes={this.updateDisplayEpisodes}
            updateCurrentPageNum={this.updateCurrentPageNum}
            clickScroll
          />
        )}
      </div>
    )
  }
}
