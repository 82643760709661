import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** 登録情報の変更 */
export default class AccountEditInfo extends Component {
  static propTypes = {};
  static defaultProps = {};
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};

    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit() {}

  goBack(e) {
    e.preventDefault();
    this.context.history.goBack();
  }

  render() {
    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <div className="c-mypageAccount">
            <header className="c-mypageAccount-header">
              <a href="#" className="c-mypage-btnBack alwaysOn" onClick={this.goBack}>
                戻る
              </a>
              <h3 className="c-mypageAccount-header-title">登録情報の変更</h3>
            </header>
            <div className="c-mypageAccount-cont">
              <div className="c-mypageAccount-cont-personal">
                <div className="c-mypageAccountEditInfo">
                  <div className="c-mypageAccountEditInfo-name">
                    <h4 className="c-mypageAccountEditInfo-name-title require">お名前</h4>
                    <ul className="c-mypageAccountEditInfo-name-btns">
                      <li className="c-mypageAccountEditInfo-name-btns-lastName">
                        <input type="text" name="name" placeholder="せい" className="c-inputText" />
                      </li>
                      <li className="c-mypageAccountEditInfo-name-btns-firstName">
                        <input type="text" name="name" placeholder="めい" className="c-inputText" />
                      </li>
                    </ul>
                    <p className="c-mypageAccountEditInfo-nameRead-error">＊必須項目です</p>
                  </div>
                  <div className="c-mypageAccountEditInfo-nameRead">
                    <h4 className="c-mypageAccountEditInfo-nameRead-title require">ふりがな</h4>
                    <ul className="c-mypageAccountEditInfo-nameRead-btns">
                      <li className="c-mypageAccountEditInfo-nameRead-btns-lastName">
                        <input type="text" name="nameRead" placeholder="せい" className="c-inputText" />
                      </li>
                      <li className="c-mypageAccountEditInfo-nameRead-btns-firstName">
                        <input type="text" name="nameRead" placeholder="めい" className="c-inputText" />
                      </li>
                    </ul>
                    <p className="c-mypageAccountEditInfo-nameRead-error">＊必須項目です</p>
                  </div>
                  <div className="c-mypageAccountEditInfo-birthday">
                    <h4 className="c-mypageAccountEditInfo-birthday-title">生年月日</h4>
                    <ul className="c-mypageAccountEditInfo-birthday-btns">
                      <li className="c-mypageAccountEditInfo-birthday-btns-year">
                        <div className="c-pulldown">
                          <div className="c-pulldown-current">2000</div>
                          <div className="c-pulldown-menu">
                            <ul className="c-pulldown-menu-inBox">
                              <li className="c-pulldown-menu-inBox-btn">2019年</li>
                              <li className="c-pulldown-menu-inBox-btn">2018年</li>
                              <li className="c-pulldown-menu-inBox-btn">2017年</li>
                              <li className="c-pulldown-menu-inBox-btn">2016年</li>
                              <li className="c-pulldown-menu-inBox-btn">2015年</li>
                              <li className="c-pulldown-menu-inBox-btn">2014年</li>
                              <li className="c-pulldown-menu-inBox-btn">2013年</li>
                              <li className="c-pulldown-menu-inBox-btn">2012年</li>
                              <li className="c-pulldown-menu-inBox-btn">2011年</li>
                              <li className="c-pulldown-menu-inBox-btn">2010年</li>
                              <li className="c-pulldown-menu-inBox-btn">2009年</li>
                              <li className="c-pulldown-menu-inBox-btn">2008年</li>
                              <li className="c-pulldown-menu-inBox-btn">2007年</li>
                              <li className="c-pulldown-menu-inBox-btn">2006年</li>
                              <li className="c-pulldown-menu-inBox-btn">2005年</li>
                              <li className="c-pulldown-menu-inBox-btn">2004年</li>
                              <li className="c-pulldown-menu-inBox-btn">2003年</li>
                              <li className="c-pulldown-menu-inBox-btn">2002年</li>
                              <li className="c-pulldown-menu-inBox-btn">2001年</li>
                              <li className="c-pulldown-menu-inBox-btn">2000年</li>
                              <li className="c-pulldown-menu-inBox-btn">〜〜</li>
                              <li className="c-pulldown-menu-inBox-btn">1900年</li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li className="c-mypageAccountEditInfo-birthday-btns-month">
                        <div className="c-pulldown">
                          <div className="c-pulldown-current">1</div>
                          <div className="c-pulldown-menu">
                            <ul className="c-pulldown-menu-inBox">
                              <li className="c-pulldown-menu-inBox-btn current">1</li>
                              <li className="c-pulldown-menu-inBox-btn">2</li>
                              <li className="c-pulldown-menu-inBox-btn">3</li>
                              <li className="c-pulldown-menu-inBox-btn">4</li>
                              <li className="c-pulldown-menu-inBox-btn">5</li>
                              <li className="c-pulldown-menu-inBox-btn">6</li>
                              <li className="c-pulldown-menu-inBox-btn">7</li>
                              <li className="c-pulldown-menu-inBox-btn">8</li>
                              <li className="c-pulldown-menu-inBox-btn">9</li>
                              <li className="c-pulldown-menu-inBox-btn">10</li>
                              <li className="c-pulldown-menu-inBox-btn">11</li>
                              <li className="c-pulldown-menu-inBox-btn">12</li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li className="c-mypageAccountEditInfo-birthday-btns-day">
                        <div className="c-pulldown">
                          <div className="c-pulldown-current">1</div>
                          <div className="c-pulldown-menu">
                            <ul className="c-pulldown-menu-inBox">
                              <li className="c-pulldown-menu-inBox-btn current">1</li>
                              <li className="c-pulldown-menu-inBox-btn">2</li>
                              <li className="c-pulldown-menu-inBox-btn">3</li>
                              <li className="c-pulldown-menu-inBox-btn">4</li>
                              <li className="c-pulldown-menu-inBox-btn">5</li>
                              <li className="c-pulldown-menu-inBox-btn">6</li>
                              <li className="c-pulldown-menu-inBox-btn">7</li>
                              <li className="c-pulldown-menu-inBox-btn">8</li>
                              <li className="c-pulldown-menu-inBox-btn">9</li>
                              <li className="c-pulldown-menu-inBox-btn">10</li>
                              <li className="c-pulldown-menu-inBox-btn">11</li>
                              <li className="c-pulldown-menu-inBox-btn">12</li>
                              <li className="c-pulldown-menu-inBox-btn">13</li>
                              <li className="c-pulldown-menu-inBox-btn">14</li>
                              <li className="c-pulldown-menu-inBox-btn">15</li>
                              <li className="c-pulldown-menu-inBox-btn">16</li>
                              <li className="c-pulldown-menu-inBox-btn">17</li>
                              <li className="c-pulldown-menu-inBox-btn">18</li>
                              <li className="c-pulldown-menu-inBox-btn">19</li>
                              <li className="c-pulldown-menu-inBox-btn">20</li>
                              <li className="c-pulldown-menu-inBox-btn">〜〜</li>
                              <li className="c-pulldown-menu-inBox-btn">31</li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="c-mypageAccountEditInfo-gender">
                    <h4 className="c-mypageAccountEditInfo-gender-title">性別</h4>
                    <ul className="c-mypageAccountEditInfo-gender-btns">
                      <li className="c-mypageAccountEditInfo-gender-btns-btn">
                        <input type="radio" name="gender" value="genderMale" id="male" className="c-inputRadio" />
                        <label htmlFor="male" className="c-inputRadio">
                          男性
                        </label>
                      </li>
                      <li className="c-mypageAccountEditInfo-gender-btns-btn">
                        <input type="radio" name="gender" value="genderFeMale" id="female" className="c-inputRadio" />
                        <label htmlFor="female" className="c-inputRadio">
                          女性
                        </label>
                      </li>
                      <li className="c-mypageAccountEditInfo-gender-btns-btn">
                        <input
                          type="radio"
                          name="gender"
                          value="genderNoAnswer"
                          id="noAnswer"
                          className="c-inputRadio"
                        />
                        <label htmlFor="noAnswer" className="c-inputRadio">
                          回答しない
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="c-mypageAccountEditInfo-mail">
                    <div className="c-mypageAccountEditInfo-mail-inBox">
                      <div className="c-mypageAccountEditInfo-mail-inBox-btns">
                        <input type="checkbox" value="" id="mail" className="c-inputCheck" />
                        <label htmlFor="mail" className="c-inputCheck">
                          テレビ朝日からのサービス案内や商品のご案内を受け取る
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="c-mypageAccountEditInfo-btnSave">
                    <a href="#" className="c-mypageAccountEditInfo-btnSave-link" onClick={this.onSubmit}>
                      保存する
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
