import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css, createGlobalStyle } from 'styled-components'
import window from 'global'
import routes from '../../../../apps/common/routes'
import webApp from '../../utils/exdioWebAppUtils'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import HeaderNewsComponent from './HeaderNewsComponent'
import { mediaQuery } from '../style'

/** 月額見放題 購入画面 */
const PurchaseLicenseContent = (props, context) => {
  const config = context.models.config.data

  const [url, setUrl] = useState(null)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [pointHeaderHeight, setPointHeaderHeight] = useState(0)

  const setVh = () => {
    const vh = window.innerHeight * 0.01
    document.body.style.setProperty('--vh', `${vh}px`)
  }

  const getHeadersHeight = () => {
    const header = document.getElementById('mp-header')
    const pointHeader = document.getElementById('point-header')

    return {
      headerHeight: header ? header.offsetHeight : 0,
      pointHeaderHeight: pointHeader ? pointHeader.offsetHeight : 0
    }
  }

  const initStyles = () => {
    setVh()

    const {
      headerHeight: _headerHeight = 0,
      pointHeaderHeight: _pointHeaderHeight = 0
    } = getHeadersHeight()
    setHeaderHeight(_headerHeight)
    setPointHeaderHeight(_pointHeaderHeight)
  }

  const onBack = (e) => {
    if (e.data.action === 'onBack') {
      context.history.goBack()
    }
  }

  const setIframeUrl = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const cont_url = encodeURIComponent(
      webApp.utils.rootUrl() +
        routes.purchase.makePath({}, { content: 'completed', term: 'f' })
    )
    const path = config.static_links.purchase_license.replace(
      ':host',
      webApp.utils.infraHost(context)
    )
    const _url = `${path}?LICENSE=${queryParams.get(
      'LICENSE'
    )}&CONT_URL=${cont_url}`

    setUrl(_url)
  }

  useEffect(() => {
    setIframeUrl()

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(context)

    // GTMの更新
    const title = context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    context.webApp.utils.updateDataLayer(gtmTags)

    // リスナーを追加する
    window.addEventListener('message', onBack)

    /** ヘッダー等の表示待ち */
    setTimeout(initStyles, 4000)
    window.addEventListener('load', initStyles)
    window.addEventListener('resize', initStyles)

    return () => {
      // リスナーを削除する
      window.removeEventListener('message', onBack)

      window.removeEventListener('load', initStyles)
      window.removeEventListener('resize', initStyles)
    }
  }, [])

  return (
    <StyledDiv
      className="common-wrapper"
      headerHeight={headerHeight}
      pointHeaderHeight={pointHeaderHeight}
    >
      <HeaderNewsComponent />
      <SpSubNavigation spOff />
      {url && <StyledIframe src={url} />}
      <GlobalStyle />
    </StyledDiv>
  )
}

export default PurchaseLicenseContent

PurchaseLicenseContent.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  webApp: PropTypes.object,
  history: PropTypes.object
}

const GlobalStyle = createGlobalStyle`
  body {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }

  #point-header {
    & + * {
      margin-top: 15px;
    }
  }
`

const StyledDiv = styled.div`
  ${({ headerHeight, pointHeaderHeight }) => {
    const pointHeaderMargin = 30 // #point-headerの上下マージン
    const heightSum = headerHeight + pointHeaderHeight + pointHeaderMargin
    return css`
      height: calc(var(--vh, 1vh) * 100 - ${heightSum}px);
      height: calc(100dvh - ${heightSum}px); // iPhoneSafari
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow: hidden;

      ${mediaQuery()} {
        gap: 0;
      }
    `
  }}
`

const StyledIframe = styled.iframe`
  width: 100%;
  flex: 1 0;
`
