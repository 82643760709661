import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import HtmlSnippet from '../../HtmlSnippet'
import DFPBanner from '../../../../common/components/DFPBanner'
import SideTab from '../details/SideTab'
import ProgramTopLink from '../details/ProgramTopLink'
import SideRecommend from '../details/SideRecommend'

/** サイドバー */
export default class ListMetaSub extends Component {
  static propTypes = {
    /** HtmlSnippetの描画に使用 */
    episodeBannerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** HtmlSnippetの描画に使用 */
    programBannerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** おすすめ動画の描画に使用 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** おすすめ動画の描画に使用 */
    course: PropTypes.object,
    /** 関連番組の描画に使用 */
    otherSeasons: PropTypes.arrayOf(PropTypes.object),
    /** 「番組トップ」ボタンの描画に使用 */
    seriesId: PropTypes.string,
    /** 「番組トップ」ボタンの描画に使用 */
    seasonId: PropTypes.string,
    /** 「番組トップ」ボタンの描画に使用 */
    status: PropTypes.object,
    /** ロードの完了、未完了の判定 */
    loaded: PropTypes.bool,
    /** genreの先頭の値を取得 */
    meta: PropTypes.object,
    className: PropTypes.string
  }

  static defaultProps = {
    episodeBannerId: null,
    programBannerId: null,
    className: ''
  }

  static contextTypes = {
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.state = {}

    // AdUnitの為genreの先頭の値を取得
    var metaValues = props.meta.values
    var metaGenre = props.meta.values.genre
    this.genre = ''
    if (metaGenre) {
      if (this.config.genre_maps[metaGenre[0]]) {
        this.genre = this.config.genre_maps[metaGenre[0]].typeName
      }
    } else {
      var evis_genre_data = props.meta.values.parents_series.evis_Genre
      if (evis_genre_data && typeof evis_genre_data[0].id != 'undefined') {
        if (this.config.genre_maps[evis_genre_data[0].id]) {
          this.genre = this.config.genre_maps[evis_genre_data[0].id].typeName
        }
      }
    }
  }

  componentDidMount() {}

  render() {
    const {
      seriesId,
      seasonId,
      status,
      course,
      episodes,
      otherSeasons,
      loaded,
      episodeBannerId,
      programBannerId,
      className
    } = this.props
    const isApp = webApp.utils.isApp(this.context)

    return (
      <div className={`c-listMeta-inBox-sub ${className}`}>
        {!isApp && (
          <div className="common-box-ad-rectangle">
            <DFPBanner position="foot" meta_id="" genre={this.genre} />
          </div>
        )}
        {!isApp && episodeBannerId && (
          <HtmlSnippet snippetId={episodeBannerId} />
        )}
        {!isApp && programBannerId && (
          <HtmlSnippet snippetId={programBannerId} />
        )}
        <SideTab otherSeasons={otherSeasons} />
        <ProgramTopLink
          seriesId={seriesId}
          seasonId={seasonId}
          isFree={status.isFree}
        />
        {!isApp && (
          <HtmlSnippet
            snippetId={this.config.extras.common_banner_snippet_key}
          />
        )}
        {loaded && (
          <SideRecommend
            typeName={this.config.recommend.type_name.view}
            spotName={this.config.recommend.spot_name.episode}
            courseRefId={course ? course.ref_id : ''}
            episodes={episodes}
          />
        )}
      </div>
    )
  }
}
