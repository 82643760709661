import React from 'react'
import _ from 'lodash'
import PalettesForCardList from './PalettesForCardList'
import NewArrivalCardList from './NewArrivalCardList'

const SearchInitial = ({}) => {
  return (
    <div className="c-searchResults center">
      <PalettesForCardList paletteKey={'recommend'} />
      <NewArrivalCardList />
    </div>
  )
}

export default SearchInitial
