import React, { useState } from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../../common/routes'
import AccordionButton from '../AccordionButton/'
import AccordionMenu from '../AccordionMenu/'
import styled from 'styled-components'
import { mediaQuery } from '../../../../style'

/** Nav5｜アコーディオンメニュー */
const Accordion = ({ status = {}, isLoggedIn = false, point = 0 }) => {
  const [isAcdOpen, setIsAcdOpen] = useState(false)

  const listItems = [
    {
      isShow: !isLoggedIn,
      route: routes.login,
      query: {
        redirect:
          'https://wws.tv-asahi.co.jp/apps/stock/lp/result.php?s=svod_gariv'
      },
      name: 'ログインして確認する'
    },
    {
      isShow: isLoggedIn && status.isPurchased,
      route: routes.mypage_account_info,
      name: 'プロフィール設定'
    },
    {
      isShow: isLoggedIn && status.isPurchased,
      href: 'https://wws.tv-asahi.co.jp/apps/stock/lp/result.php?s=svod_gariv',
      name: '単位ポイントを確認する'
    },
    {
      isShow: true,
      href: 'https://www.tv-asahi.co.jp/douga_mv/gariben_daigaku/credit/',
      name: '単位について'
    }
  ]

  return (
    <StyledDiv>
      <AccordionButton
        onClick={() => setIsAcdOpen(!isAcdOpen)}
        isLoggedIn={isLoggedIn}
        point={point}
        status={status}
      />
      {isAcdOpen && <StyledAccordionMenu listItems={listItems} />}
    </StyledDiv>
  )
}

export default Accordion

Accordion.propTypes = {
  /** 「プロフィール設定」のボタンの描画に使用
   * @param {boolean} isPurchased - 購入済みか否か
   */
  status: PropTypes.object,
  /** 単位に関する情報の表示に使用 */
  isLoggedIn: PropTypes.bool,
  /** 所有単位ポイント数 */
  point: PropTypes.number
}

const StyledDiv = styled.div`
  position: relative;
`

const StyledAccordionMenu = styled(AccordionMenu)`
  position: absolute;
  top: calc(100% - 14px);
  right: 0;
  left: 0;
  z-index: 3;

  ${mediaQuery()} {
    width: 100vw;
    top: calc(100% - 10px);
  }
`
