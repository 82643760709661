import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Cookie from 'js-cookie'
import { get } from 'lodash'
import webApp from '../../../../utils/exdioWebAppUtils'
import { META_SCHEMA_ID } from '../../../../../../constants/app'

/* components */
import RenewalPlayerInline from '../../../player/RenewalPlayerInline'

/* style */
import { StyledDiv, StyledCommonVideoArea } from './style'

/** Player Component */
const Player = (
  {
    meta = {},
    product = {},
    course = {},
    status = {},
    progress = 0,
    onClickPlay = () => {},
    onClosePlay = () => {},
    setPlayerRef = () => {},
    ...props
  },
  context
) => {
  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix

  const {
    meta_id: metaId = '',
    meta_schema_id: metaSchemaId = META_SCHEMA_ID.LIVE_NOT_FREE,
    thumbnail_url: thumbnailUrl = '',
    delivery_start_at: deliveryStartAt,
    delivery_end_at: deliveryEndAt
  } = meta
  const {
    subtitle,
    target_ref_id: targetRefId = '',
    clipping_vod_ref_id: clippingVodRefId = '',
    clipping_select_media_type: clippingSelectMediaType
  } = meta.values
  const { isFree, displayMode } = status
  const { ref_id: materialId = '' } = product
  const { ref_id: licenseId = '' } = course

  const playerSettings = webApp.utils.getPlayerSettings(
    config,
    meta,
    displayMode
  )

  const isLive =
    metaSchemaId === META_SCHEMA_ID.LIVE ||
    metaSchemaId === META_SCHEMA_ID.LIVE_NOT_FREE

  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  const isBefore = Boolean(
    !withValidPreviewToken && moment(prevTime).isBefore(deliveryStartAt)
  )
  const isAfter = Boolean(
    !withValidPreviewToken && moment(prevTime).isAfter(deliveryEndAt)
  )
  const inViewTerm = !isBefore && !isAfter

  const isClipVod = isLive && clippingSelectMediaType === 'vod'
  // LIVE配信 主/副切替ID
  const metaIdForPlayer = (() => {
    if (isClipVod && clippingVodRefId) return clippingVodRefId
    if (targetRefId) return targetRefId
    return `${config.videocloud.reference_id_prefix || ''}${metaId}`
  })()

  const isPlayerRenderable =
    metaIdForPlayer && displayMode && (inViewTerm || withValidPreviewToken)

  const beforeAfterText = isBefore
    ? 'まもなく配信予定'
    : '次回の配信もお楽しみに！'
  const nextPrevText = inViewTerm ? null : beforeAfterText

  const [title, subTitle] = webApp.utils.titles(meta)
  const metaName = `${title} ${subTitle}`

  return (
    <StyledDiv {...props}>
      {isPlayerRenderable && (
        <RenewalPlayerInline
          ref={setPlayerRef}
          meta_id={metaIdForPlayer}
          thumbnail_url={thumbnailUrl}
          subtitle={!!subtitle}
          enqueteEnabled={isFree}
          material_id={materialId}
          license_id={licenseId}
          display_mode={displayMode}
          onClose={onClosePlay}
          isClipVod={isClipVod}
          isLive
          playInline
          {...playerSettings}
        />
      )}

      <StyledCommonVideoArea
        meta={meta}
        status={status}
        product={product}
        progress={progress}
        onClickPlay={onClickPlay}
        inViewTerm={inViewTerm}
        metaName={metaName}
        thumbnailUrl={thumbnailUrl}
        nextPrevText={nextPrevText}
        showMask={isAfter}
        showLogin
        isLive
      />
    </StyledDiv>
  )
}

export default Player

Player.propTypes = {
  meta: PropTypes.shape({
    meta_id: PropTypes.number,
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string,
    target_ref_id: PropTypes.string,
    clipping_vod_ref_id: PropTypes.string
  }).isRequired,
  product: PropTypes.object,
  // 月額見放題
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    ref_id: PropTypes.string,
    values: PropTypes.object
  }),
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool,
    displayMode: PropTypes.number
  }),
  progress: PropTypes.number,
  onClickPlay: PropTypes.func,
  onClosePlay: PropTypes.func,
  setPlayerRef: PropTypes.func
}

Player.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}
