import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledWrapper, StyledDl, StyledDt, StyledDd } from './style'

/* 番組別お知らせの表示 */
const Notice = ({ meta, ...props }) => {
  const {
    notice: text = '',
    notice_hyperlink: hyperlink = '',
    notice_publish_start_at: publishStartAt = null,
    notice_publish_end_at: publishEndAt = null
  } = meta.values
  const now = Date.now()

  if (
    !text ||
    now < Date.parse(publishStartAt) ||
    Date.parse(publishEndAt) < now
  ) {
    return null
  }

  return (
    <StyledWrapper
      as={hyperlink ? 'a' : null}
      href={hyperlink || null}
      {...props}
    >
      <StyledDl>
        <StyledDt>お知らせ</StyledDt>
        <StyledDd>{text}</StyledDd>
      </StyledDl>
    </StyledWrapper>
  )
}

export default Notice

Notice.propTypes = {
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired
}
