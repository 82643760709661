import Heading1 from '../Heading1'
import Button3 from '../common/Button/Button3'
import styled, { keyframes } from 'styled-components'
import { mediaQuery, breakPoints } from '../../../style'
import { pcMinSize, spMinSize } from '../styles'

const list13_item_bound = keyframes`
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0, -15px);
  }
  25% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(0, -15px);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
`

export const StyledHeading1 = styled(Heading1)`
  height: 210px;
  position: relative;
  z-index: 1;

  ${mediaQuery()} {
    height: 70px;
  }

  &.lazyloaded::after {
    margin: 0 auto;
    width: 1159px;
    height: 194px;
    background: url(/images/exdio/renewal/gariben_daigaku/top/bg4_2.webp)
      no-repeat bottom center / 100% auto;
    position: absolute;
    right: 0;
    bottom: -10px;
    left: 0;
    content: '';

    ${mediaQuery()} {
      width: ${spMinSize};
      height: calc(194px * (320 / 1159));
      bottom: -4px;
    }
  }

  img {
    position: absolute;
    z-index: 1;

    ${mediaQuery()} {
      width: 136px;
    }
  }
`

export const StyledDiv1 = styled.div`
  &.lazyloaded {
    background: url(/images/exdio/renewal/gariben_daigaku/top/sec5_bg.webp)
      center / cover;
  }
`

export const StyledDiv2 = styled.div`
  margin: 0 auto;
  padding: 95px 0 45px;
  width: ${pcMinSize};

  ${mediaQuery()} {
    padding: 50px 10px 35px;
    width: auto;
  }
`

export const StyledButton3 = styled(Button3)`
  margin: 94px auto 0;
  width: 340px;
  font-size: 2rem;

  ${mediaQuery()} {
    margin-top: 35px;
    width: auto;
    font-size: 1.6rem;
  }
`
