/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'

export const StyledUl = styled.ul`
  margin-top: 30px;
  display: grid;
  gap: 10px;
  grid-template-columns: ${({ isSmp }) =>
    isSmp ? '1fr 1fr' : '1fr 1fr 1fr 1fr'};

  ${mediaQuery(1320)} {
    max-width: 920px;
    margin: 30px auto 0;
  }

  ${mediaQuery()} {
    margin-top: 20px;
    max-width: 100%;
  }
`

export default StyledUl
