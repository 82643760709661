/* style */
import styled from 'styled-components'

/* components */
import ConvertSvg from '../../util/ConvertSvg'

export const mediaSp = '(max-width: 1024px)'
export const StyledH2 = styled.h2`
  display: flex;
  align-items: center;

  @media ${mediaSp} {
    justify-content: center;
  }

  &::before,
  &::after {
    width: 100%;
    height: 16px;
    background-color: rgba(255, 255, 255, 0);
    background-image: radial-gradient(#fff 30%, transparent 30%);
    background-size: 16px 16px;
    display: block;
    flex: 1 0;
    content: '';

    @media ${mediaSp} {
      content: none;
    }
  }
`

export const StyledSpan = styled.span`
  padding: 0 32px;
  display: inline-flex;
  align-items: center;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1;
  font-family: 'M PLUS Rounded 1c', sans-serif;

  @media ${mediaSp} {
    padding: 0;
    font-size: 2.4rem;
    line-height: 1.25;
  }
`

export const StyledConvertSvg = styled(ConvertSvg)`
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @media ${mediaSp} {
    margin-right: 4px;
  }
`
