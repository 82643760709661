import styled from 'styled-components'
import { bgColor, blue, yellow } from '../../styles'

// spMainSlider用のstyle
export const StyledDiv1 = styled.div`
  position: relative;
  margin: 0 auto 20px;
  color: #fff;

  img {
    width: 100%;
    height: auto;
  }
`

export const StyledDiv2 = styled.div`
  .slick-dots {
    bottom: -155px;
    li {
      width: 12px;
      height: 12px;
      border: 1px solid ${blue};
      background: #fff;
      button {
        width: 100%;
        height: 100%;
      }
      button::before {
        content: none;
      }
    }
    .slick-active {
      background: ${yellow} !important;
    }
  }
`

export const StyledDiv3 = styled.div`
  text-align: left;
  padding: 16px 10px 18px;
  margin-top: -3px;
  text-overflow: ellipsis;
  position: relative;
  background: ${bgColor};

  span {
    font-weight: bold;
    font-size: 15px;
  }
`

export const StyledDiv4 = styled.div`
  position: relative;
  height: 89px !important;
`

export const StyledImg = styled.img`
  padding-top: 18px;
  margin-top: 0 !important;
  opacity: 0.5;
`

export const StyledDiv5 = styled.div`
  margin-top: -13px;
  margin-bottom: 50px;

  .slick-current {
    ${StyledDiv4}::before {
      content: '';
      position: absolute;
      width: 100%;
      border-bottom: 5px solid ${blue};
      top: 0;
      margin-top: 13px;
    }
    ${StyledDiv4}::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: 50%;
      top: 0;
      margin-left: -8px;
      border-bottom: 13px solid ${blue};
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
    ${StyledImg} {
      opacity: 1;
    }
  }
`
