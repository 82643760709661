import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Footer from '../../../../common/components/Footer'
import { NEWS_CONTENTS } from '../../../../../constants/app'
import routes from '../../../../common/routes'
import useIsMounted from '../../../../hooks/useIsMounted'

import Tabs from './Tabs'
import Contents from './Contents'

import webApp from '../../../utils/exdioWebAppUtils'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'
import HeaderNewsComponent from '../HeaderNewsComponent'

/* style */
import {
  StyledMain,
  StyledHeader,
  StyledH2,
  StyledNav,
  StyledUl
} from './style'

/** ニュースページ */
const NewsContent = (props, context) => {
  const url = context.routeHandler.path
  const isMounted = useIsMounted()
  const currentContent = Object.values(NEWS_CONTENTS).find((content) => {
    return routes[content.route].match(url)
  })

  // 初回レンダリング時のみ実行
  useEffect(() => {
    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(context)
    // GTMの更新
    const title = context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)
  }, [])

  if (!isMounted) return null

  return (
    <>
      <HeaderNewsComponent />
      <SpSubNavigation spOff />

      {/* 共通コンポーネントレイアウト調整用のclassName */}
      <StyledMain className="c-mypage news">
        <StyledHeader>
          <StyledH2>ニュース</StyledH2>
          <StyledNav>
            <StyledUl>
              <Tabs currentContent={currentContent} />
            </StyledUl>
          </StyledNav>
        </StyledHeader>
        <Contents currentContent={currentContent} />
      </StyledMain>

      <Footer className="mt0" />
    </>
  )
}

export default NewsContent

NewsContent.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}
