import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import routes from '../../../../../../common/routes'
import styled from 'styled-components'
import Label from '../../common/Label'
import { mediaPc } from '../../styles'

/** そだてれび：海外視聴用 PC 単話ページ動画詳細 タイトル*/
const InfoHeading = ({ params, title, subTitle, isNew }) => {
  return (
    <div>
      <StyledH2>
        <StyledLink route={routes.program} params={params}>
          {title}
        </StyledLink>
      </StyledH2>
      <StyledLabel isNew={isNew} />
      <StyledH3>{subTitle}</StyledH3>
    </div>
  )
}

InfoHeading.propTypes = {
  params: PropTypes.string,
  title: PropTypes.string,
  isNew: PropTypes.bool
}

export default InfoHeading

const StyledH2 = styled.h2`
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 12px;
`

const StyledLink = styled(Link)`
  font-weight: bold;
`

const StyledLabel = styled(Label)`
  > div:last-child {
    display: none;
  }
`

const StyledH3 = styled.h3`
  font-weight: bold;
  color: #515151;
  font-size: 16px;
  line-height: 22px;
  padding-top: 6px;
  padding-bottom: 16px;
  word-break: break-all;
  @media ${mediaPc} {
    font-size: 18px;
  }
`
