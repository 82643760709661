import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

const Btn = ({ href, color, text, ...props }) => {
  return (
    <StyledLink color={color} href={href} {...props}>
      {text}
    </StyledLink>
  )
}

export default Btn

Btn.propTypes = {
  href: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string
}

const handleColorType = (color) => {
  const colors = {
    blue: css`
      color: #fff;
      background: #00a0e9;
      box-shadow: 0px 4px 0px 2px #0178ae;
    `,
    red: css`
      color: #fff;
      background: #f91f1f;
      box-shadow: 0px 4px 0px 2px #c40111;
    `,
    pink: css`
      color: #fff;
      background: #f684b6;
      box-shadow: 0px 4px 0px 2px #c46b92;
    `,
    default: css`
      color: #fff;
      background: #1d4a9e;
      box-shadow: 0px 4px 0px 2px #092455;
    `
  }
  return colors[color] || colors.default
}

const StyledLink = styled(Link)`
  height: 55px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ color }) => handleColorType(color)};
  font-size: 2rem;
  font-weight: 800;
  transform: translateY(0);

  &:link,
  &:visited,
  &:hover {
    color: #fff;
  }

  @media (hover: hover) {
    transition: box-shadow 0.2s, transform 0.2s;

    &:hover {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
`
