import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import browserInfo from '../../../../sketch-platform/utils/browserInfo';

export default class GlobalFooterOther extends Component {
  static propTypes = {
    handleOtherVisible: PropTypes.func,
    visible: PropTypes.bool
  }

  static contextTypes = {
    models: PropTypes.object,
  }

  constructor(props, context) {
    super(props, context);
    this.dougaLink = this.context.models.config.data.dougaLink;
    this.deviceInfo = browserInfo(navigator.userAgent, function (data) { return data; });
    this.contactLink = this.dougaLink.Contact
    this.state = {
      other: false
    }
  }

  componentDidMount() {
    if(this.deviceInfo.isAppli) {
      if(this.deviceInfo.isAndroid) {
        this.contactLink = this.dougaLink.Contact.replace(/(douga\/)/, '$1Android/')
      } else {
        this.contactLink = this.dougaLink.Contact.replace(/(douga\/)/, '$1iPhone/')
      }
    } else {
      this.contactLink = this.dougaLink.Contact
    }
  };

  // その他エリアの表示非表示
  handleOtherVisible(){
    return this.props.handleOtherVisible()
  }

  /* 
   アプリでluidを発行するために、基盤プレビュー環境のワンタイムURLが記載されているページを踏む必要がある
   アプリのステージング、開発環境でその静的ページを表示するリンクを付与する
  */
  renderOnetimeUrlLink() {
    let onetimeUrlLink;
    if (process.env.NODE_ENV != "production") {
      onetimeUrlLink = (<li className="globalFooter-other-list"><a href="/luid_onetime/" className="globalFooter-other-link">ワンタイムURL<img src="/images/exdio/arow-right01.png" alt="右矢印" className="globalFooter-other-list-icon" /></a></li>);
    }
    return onetimeUrlLink;
  }  

  render() {
    return (
      <div className={classnames("globalFooter-other", {'is-open': this.props.visible})}>
        <div className="globalFooter-other-heading">
          <p className="globalFooter-other-header">その他</p>

          <div className="globalFooter-other-close etc_close_api" onClick={this.handleOtherVisible.bind(this)}>
            <img src="/images/exdio/close.png" className="globalFooter-other-close-icon" alt="閉じる"/>
            <p className="globalFooter-other-close-text">閉じる</p>
          </div>
        </div>

        <div className="globalFooter-other-contents">
          <ul className="globalFooter-other-lists">
            <li className="globalFooter-other-list">
              <a href={this.dougaLink.GuideDevices} className="globalFooter-other-link etc_action_api">
                動作環境について
                <p className="globalFooter-other-list-website">webサイト</p>
                <img src="/images/exdio/icon_link.svg" alt="斜め右矢印" className="globalFooter-other-list-icon" />
              </a>
            </li>
            <li className="globalFooter-other-list">
              <a href={this.contactLink} className="globalFooter-other-link etc_inquiry_api">
                お問い合わせ
                <p className="globalFooter-other-list-website">webサイト</p>
                <img src="/images/exdio/icon_link.svg" alt="斜め右矢印" className="globalFooter-other-list-icon" />
              </a>
            </li>
            <li className="globalFooter-other-list">
              <a href={this.dougaLink.Privacy} className="globalFooter-other-link etc_privacy_api">
                プライバシーポリシー
                <p className="globalFooter-other-list-website">webサイト</p>
                <img src="/images/exdio/icon_link.svg" alt="斜め右矢印" className="globalFooter-other-list-icon" />
              </a>
            </li>
            <li className="globalFooter-other-list">
              <a href={this.dougaLink.Top} className="globalFooter-other-link etc_tv-douga_api">
                テレ朝動画TOP
                <p className="globalFooter-other-list-website">webサイト</p>
                <img src="/images/exdio/icon_link.svg" alt="斜め右矢印" className="globalFooter-other-list-icon" />
               </a>
             </li>
            {this.renderOnetimeUrlLink()}
          </ul>
        </div>
      </div>
    );
  }
}
