import React from 'react'
import PropTypes from 'prop-types'
import addSizePrefix from '../../addSizePrefix'
import { StyledDiv, StyledImg, StyledP1, StyledP2 } from './style'

/** Section1｜スライドの要素 */
const SlideItem = ({ item = {} }) => {
  return (
    <React.Fragment>
      <div>
        <StyledImg
          src={addSizePrefix(item.thumbnail, 'medium')}
          width="320"
          height="180"
          alt={item.name}
        />
      </div>
      <StyledDiv>
        <time dateTime={item.dateTime}>{item.dateTime}</time>
        {item.type && <StyledP1 type={item.type}>{item.type}</StyledP1>}
        <StyledP2>{item.name}</StyledP2>
      </StyledDiv>
    </React.Fragment>
  )
}

export default SlideItem

SlideItem.propTypes = {
  /** スライドの描画要素
   * @param {string} name - コンテンツの内容
   * @param {string} thumbnail - コンテンツのサムネイル
   * @param {string} dateTime - コンテンツの発行日
   * @param {string} type - コンテンツのタイプ（動画, ブログ）
   */
  item: PropTypes.shape({
    name: PropTypes.string,
    thumbnail: PropTypes.string,
    dateTime: PropTypes.string,
    type: PropTypes.string
  })
}
