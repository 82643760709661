import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledH2, StyledSpan, StyledConvertSvg } from './style'

/** Logirlプランページ | ページタイトル2 */
const Hdg2 = ({ src, alt, children }) => {
  return (
    <StyledH2>
      <StyledConvertSvg src={src} alt={alt} />
      <StyledSpan>{children}</StyledSpan>
    </StyledH2>
  )
}

export default Hdg2

Hdg2.propTypes = {
  /** アイコン画像のリンク */
  src: PropTypes.string,
  /** アイコン画像のalt */
  alt: PropTypes.string,
  /** styled-components拡張の為のclassName */
  className: PropTypes.string
}

Hdg2.defaultProps = {
  src: '',
  alt: '',
  className: ''
}
