import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledDiv, StyledA, StyledImg } from './style'

const Sns = ({ items, ...props }) => {
  return (
    <StyledDiv {...props}>
      {items.map((item) => {
        return (
          <StyledA href={item.href} target="_blank" key={item.href}>
            <StyledImg src={item.img} alt={item.alt} />
          </StyledA>
        )
      })}
    </StyledDiv>
  )
}

export default Sns

Sns.propTypes = {
  /** 描画するsnsの配列 */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      href: PropTypes.string,
      img: PropTypes.string
    })
  ).isRequired
}

Sns.defaultProps = {
  items: []
}
