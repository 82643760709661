import React from 'react'
import PropTypes from 'prop-types'

/* components */
import Item from '../Item'

/* style */
import { StyledUl } from './style'

/** List Component */
const List = ({ items = [], ...props }) => {
  if (!items.length > 0) return null

  return (
    <StyledUl {...props}>
      {items.map(({ meta = null }, i) => (
        <Item key={i} as="li" meta={meta} rank={i + 1} />
      ))}
    </StyledUl>
  )
}

export default List

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      meta: PropTypes.object,
      type: PropTypes.string,
      values: PropTypes.object
    })
  )
}
