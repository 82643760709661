import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { StyledDiv1, StyledDiv2, StyledImg } from './style'

/** ガリベン大学 ローディングアニメーション */
const Loading = ({ loaded = false, ...props }) => {
  const BG_DIV_LENGTH = 8

  return (
    <StyledDiv1 loaded={loaded} {...props}>
      {(() => {
        const html = []
        for (let i = 0; i < BG_DIV_LENGTH; i += 1) {
          html.push(
            <StyledDiv2
              key={i}
              loaded={loaded}
              direction={i % 2 === 0 ? 'right' : 'left'}
              i={i}
            />
          )
        }
        return html
      })()}
      <StyledImg
        src="/images/exdio/renewal/gariben_daigaku/logo/logo.webp"
        alt="私立ガリベン大学"
        width="248"
        height="204"
      />
    </StyledDiv1>
  )
}

export default memo(Loading)

Loading.propTypes = {
  /** trueの時、表示されなくなる */
  loaded: PropTypes.bool
}
