import styled from 'styled-components'
import { brown3, white } from '../../styles'
import Slider from 'react-slick'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import { mediaQuery, breakPoints } from '../../../../style'

export const StyledSlider = styled(Slider)`
  padding: 10px 35px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  &.lazyloaded {
    background: url(/images/exdio/renewal/gariben_daigaku/components/list4_icon1.webp)
        no-repeat top 4px left 4px / 15px 18px,
      url(/images/exdio/renewal/gariben_daigaku/components/list4_icon2.webp)
        no-repeat top 4px right 4px / 15px 18px,
      url(/images/exdio/renewal/gariben_daigaku/components/list4_icon3.webp)
        no-repeat bottom 4px left 4px / 15px 18px,
      ${white}
        url(/images/exdio/renewal/gariben_daigaku/components/list4_icon4.webp)
        no-repeat bottom 4px right 5px / 15px 18px;
  }

  ${mediaQuery()} {
    padding: 15px 10px 30px;
  }

  .slick-slide {
    padding: 0 5px;
  }

  .slick-next,
  .slick-prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${brown3};
    transition: opacity 0.4s ease-in-out;

    ${mediaQuery('sm', 'min')} {
      &:hover,
      &:focus {
        background-color: ${brown3};
        opacity: 0.7;
      }
    }

    &::before {
      width: 17px;
      height: 14px;
      background: url(/images/exdio/renewal/gariben_daigaku/components/list4_arrow.svg)
        no-repeat center / 17px auto;
      display: inline-block;
      opacity: 1;
      content: '';
    }
  }

  .slick-prev {
    &::before {
      transform: rotate(180deg);
    }
  }
`

export const StyledLink = styled(Link)`
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`
