import React from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../../../utils/exdioWebAppUtils'

/* components */
import routes from '../../../../../../../common/routes'

/* style */
import {
  StyledFooter,
  StyledUl1,
  StyledLi1,
  StyledLink1,
  StyledUl2,
  StyledLi2,
  StyledLink2,
  StyledImg
} from './style'

/** Logirlプランページ | フッター */
const Footer = ({ ...props }, context) => {
  const config = context.models.config.data
  const guideLinks = config.guide_links

  return (
    <StyledFooter {...props}>
      <nav>
        <StyledUl1>
          {guideLinks &&
            guideLinks.map((link, i) => {
              return (
                <StyledLi1 key={i}>
                  <StyledLink1
                    href={
                      link.url
                        ? link.url.replace(
                            ':host',
                            webApp.utils.dougaMvHost(context)
                          )
                        : null
                    }
                    route={routes[link.route]}
                    params={link.params}
                    target={link.target}
                  >
                    {link.title}
                  </StyledLink1>
                </StyledLi1>
              )
            })}
        </StyledUl1>
        <StyledUl2>
          <StyledLi2>
            <StyledLink2 route={routes.home}>
              <StyledImg
                src="/images/exdio/renewal/logo_tv_asahi_douga.png"
                alt="テレ朝動画"
                loading="lazy"
              />
            </StyledLink2>
          </StyledLi2>
          <StyledLi2>
            <StyledLink2 href="https://www.tv-asahi.co.jp/">
              <StyledImg
                src="/images/exdio/renewal/logo_tv_asahi.svg"
                alt="テレビ朝日"
                loading="lazy"
              />
            </StyledLink2>
          </StyledLi2>
        </StyledUl2>
      </nav>
    </StyledFooter>
  )
}

export default Footer

Footer.propTypes = {
  className: PropTypes.string
}

Footer.defaultProps = {
  className: ''
}

Footer.contextTypes = {
  models: PropTypes.object,
  className: PropTypes.string
}
