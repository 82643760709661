import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import { mediaQuery } from '../../../../style'

const Origin = ({ name = null, html = null, ...props }) => {
  if (html) {
    return <StyledLink dangerouslySetInnerHTML={{ __html: html }} {...props} />
  }

  return <StyledLink {...props}>{name}</StyledLink>
}

export default Origin

Origin.propTypes = {
  /** Linkコンポーネントに渡すhref */
  href: PropTypes.string,
  /** Linkコンポーネントに渡すroute */
  route: PropTypes.object,
  /** Linkコンポーネントに渡すquery */
  query: PropTypes.object,
  /** aタグのtarget属性 */
  target: PropTypes.string,
  /** 描画される要素(単純な文字列の場合) */
  name: PropTypes.string,
  /** 描画される要素(htmlの場合) */
  html: PropTypes.string,
  /** ボタンの色 */
  bgColor: PropTypes.string,
  /** ボタンの影の色 */
  shadowColor: PropTypes.string,
  /** フォントの色 */
  fontColor: PropTypes.string,
  /** フォントのサイズ */
  fontSize: PropTypes.string,
  /** spのフォントのサイズ */
  spFontSize: PropTypes.string
}

const StyledLink = styled(Link)`
  margin: 0 auto;
  padding: 10px 0;
  background-color: ${({ bgColor }) => bgColor};
  box-shadow: 0 3px 0 3px ${({ shadowColor }) => shadowColor};
  border-width: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ fontColor }) => fontColor}!important;
  font-size: ${({ fontSize }) => fontSize};
  text-align: center;
  font-weight: bold;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 0 ${({ shadowColor }) => shadowColor};
    transform: translateY(6px);
  }

  ${mediaQuery()} {
    font-size: ${({ spFontSize }) => spFontSize};
  }
`
