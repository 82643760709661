import React, { useState } from 'react'

/* style */
import styled from 'styled-components'

/**
 * Counter Component (Dev Only)
 * The count added 1, when button was clicked.
 */
const Counter = () => {
  /** useState */
  const [count, setCount] = useState(0)

  /**
   * Count Up
   * @param {string} args - sample argument
   */
  const countUp = (args) => {
    // console.info({ args })
    setCount(count + 1)
  }

  return (
    <>
      <StyledButton type="button" onClick={() => countUp('test')}>
        count up
      </StyledButton>
      <StyledP>{count}</StyledP>
    </>
  )
}

export default Counter

const StyledButton = styled.button`
  padding: 10px 30px;
  background-color: #00f;
  border-width: 0;
  border-radius: 15px;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
`

const StyledP = styled.p`
  margin-top: 15px;
`
