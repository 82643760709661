import styled, { keyframes } from 'styled-components'
import { mediaQuery } from '../../../../../style'
import Button from '../Button'

/** SNSシェアボタン-LINE表示時のアニメーション*/
const popInPc = keyframes`
from {
  top: 0;
  left: 0;
  opacity: 0;
}
to {
  top: 37px;
  left: 54px;
  opacity: 1;
}
`
const popInSp = keyframes`
from {
  top: 0;
  left: 0;
  opacity: 0;
}
to {
  top: 37px;
  left: 24px;
  opacity: 1;
}
`

export const StyledListItem = styled(Button)`
  position: absolute;
  top: 37px;
  left: 54px;
  animation: ${popInPc} 0.2s ease 0s;

  ${mediaQuery()} {
    left: 24px;
    animation: ${popInSp} 0.2s ease 0s;
  }
`
