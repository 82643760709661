import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import _ from 'lodash'
import {
  INFORMATION_TYPE,
  INFORMATION_TYPES,
  LOCAL_STORAGE_KEY_CHECKED_ARTICLES
} from '../../../../../../constants/app'
import SelectBox from '../../../../../common/components/renewal/SelectBox'
import Articles from './Articles'
import routes from '../../../../../common/routes'
import useIsMounted from '../../../../../hooks/useIsMounted'

/* style */
import { StyledP, StyledDiv } from './style'

/** お知らせページ */
const Information = (props, context) => {
  const model = context.falcorModel.batch(100)
  const config = context.models.config.data.news_page
  const isMounted = useIsMounted()

  const [articles, setArticles] = useState(null)
  const [filtered, setFiltered] = useState([])
  const [openIds, setOpenIds] = useState([])

  /** URLのハッシュから初期表示時に表示するメッセージを設定する */
  const setDefaultOpen = (hashArg = '') => {
    const hashCtx = context.routeHandler.hash
    const hash = hashArg || hashCtx
    if (!hash) return

    const openId = Number(hash.replace('news-', ''))
    if (!openId) return

    if (openIds.indexOf(openId) < 0) {
      setOpenIds([...openIds, openId])
    }

    const element = document.getElementsByName(hash)[0]
    if (!element) return

    process.nextTick(() => {
      window.scroll({ top: element.offsetTop - 80, behavior: 'smooth' })
    })
  }

  /** 記事情報取得 */
  const fetchData = () => {
    const publishPeriod = config.show_information_period
    const path = ['articles', 'news', publishPeriod]
    return model.fetch([path]).then((result) => {
      const articlesData = _.get(result, ['json', ...path]) || []
      setArticles(articlesData)
      setDefaultOpen()

      // 閲覧済としてlocalStorageに保持
      window.localStorage.setItem(
        LOCAL_STORAGE_KEY_CHECKED_ARTICLES,
        JSON.stringify(articlesData.map((a) => a.id))
      )
      // バッジ表示反映のためヘッダーナビをforceUpdate
      context.updateGlobalNavigation()
    })
  }

  /** 並び替え条件変更時 */
  const onChangeFilterType = (type) => {
    const filteredObj =
      !type || type === INFORMATION_TYPE.ALL.value
        ? articles
        : articles.filter((a) => a.schema_id.toString() === type)
    setFiltered(filteredObj)
  }

  useEffect(() => {
    if (isMounted) {
      fetchData()
    }
    // ニュース画面でヘッダーのお知らせから遷移する場合のためにリスナー登録.
    const removeHistoryListener = context.history.listen((location) => {
      if (
        ![
          routes.information.makePath(),
          routes.app_information.makePath()
        ].includes(location.pathname)
      )
        return
      const { hash } = location
      if (hash) setDefaultOpen(hash.replace('#', ''))
    })

    return () => {
      if (typeof removeHistoryListener === 'function') removeHistoryListener()
    }
  }, [isMounted])

  useEffect(() => {
    if (articles) onChangeFilterType()
  }, [articles])

  if (!articles) return null

  if (articles && articles.length === 0) {
    return <StyledP>お知らせはありません</StyledP>
  }

  if (!isMounted) return null

  return (
    <>
      <StyledDiv key="select">
        <SelectBox types={INFORMATION_TYPES} onSelect={onChangeFilterType} lg />
      </StyledDiv>
      {filtered.length > 0 && <Articles articles={filtered} />}
    </>
  )
}

export default Information

Information.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  falcorModel: PropTypes.object,
  updateGlobalNavigation: PropTypes.func
}
