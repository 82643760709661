import React from 'react'
import PropTypes from 'prop-types'
import { NEWS_CONTENTS } from '../../../../../../constants/app'

import NotFound from '../../../../../generic/components/errors/NotFound'
import Information from '../Information'
import NewArrival from '../NewArrival'

const Contents = ({ currentContent }) => {
  if (!currentContent) return <NotFound />

  switch (currentContent) {
    case NEWS_CONTENTS.INFORMATION:
      return <Information />
    case NEWS_CONTENTS.NEW_ARRIVAL:
      return <NewArrival />
    default:
      return <NotFound />
  }
}

export default Contents

Contents.propTypes = {
  currentContent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired
  }).isRequired
}
