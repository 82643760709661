import styled from 'styled-components'
import { mediaQuery } from '../../../../style'
import VoteForm from '../../../vote_form'
import ButtonList1 from '../../ButtonList1'

export const StyledDiv = styled.div`
  ${mediaQuery()} {
    padding: 20px 10px 0;
  }
`

export const StyledP = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`

export const StyledVoteForm = styled(VoteForm)`
  padding: 10px;

  ${mediaQuery()} {
    padding: 10px;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }

  .c-list1 {
    margin-top: 20px;
    justify-content: space-between;
  }

  .c-radio {
    &__label {
      width: 80px;
      height: 80px;
    }
  }

  .c-accordion {
    margin-top: 50px;
    text-align: center;

    .c-text1 {
      font-size: 1.4rem;
    }

    .c-list2 {
      margin-top: 20px;
    }

    .c-btn1 {
      min-width: 230px;
      display: inline-block;

      ${mediaQuery()} {
        min-width: auto;
        width: 100%;
      }
    }
  }
`

export const StyledButtonList1 = styled(ButtonList1)`
  &:not(:first-child) {
    margin-top: 30px;

    ${mediaQuery()} {
      margin-top: 20px;
    }
  }
`
