import React, { Component } from 'react';
import PropTypes from 'prop-types';

import routes from '../../../../apps/common/routes';
import webApp from '../../utils/exdioWebAppUtils';
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation';
import HeaderNewsComponent from './HeaderNewsComponent'

/** パスワードリマインダ email登録画面 */
export default class RemakePasswordContent extends Component {
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    webApp: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.config = context.models.config.data;

    this.state = {
      url: null
    };

    this.onBack = this.onBack.bind(this);
  }

  componentDidMount() {
    this.setIframeUrl();

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context);

    // GTMの更新
    const title = this.context.models.config.data.default_title;
    const [program] = title === undefined ? [''] : title.split(' | ');
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ];
    this.context.webApp.utils.updateDataLayer(gtmTags);

    // 子フレームから呼ばれる用のメソッドをセット
    window.onBack = this.onBack;

    // リスナーを追加する
    window.addEventListener('message', this.onBack);
  }

  componentWillUnmount() {
    // リスナーを削除する
    window.removeEventListener('message', this.onBack);
  }

  onBack(e) {
    if (e.data.action === 'onBack') {
      this.context.history.goBack();
    }
  }

  setIframeUrl() {
    const cont_url = encodeURIComponent(webApp.utils.rootUrl() + routes.remake_set_password.makePath({}));
    const path = this.config.static_links.remake_password.replace(':host', webApp.utils.infraHost(this.context));
    const url = `${path}?CONT_URL=${cont_url}`;

    this.setState({ url });
  }

  render() {
    const { url } = this.state;
    return (
      <React.Fragment>
        <div className="common-wrapper">
          <HeaderNewsComponent />
          <SpSubNavigation spOff />
          <div className="ex-base">
            {url && <iframe src={url} />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
