import styled from 'styled-components'
import { mediaQuery } from '../../../style'
import { white } from '../styles'
import CertificateBtn from './CertificateBtn'
import DescImgs from './DescImgs'

export const StyledDl = styled.dl`
  width: 338px;
  height: 216px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  overflow: hidden;

  ${mediaQuery()} {
    margin: 0 auto;
  }

  @media print {
    &#printElm img {
      display: block !important;
    }
  }
`

export const StyledDt = styled.dt`
  background-color: ${white};
`

export const StyledDd = styled.dd`
  padding: 18px 15px 5px;
  background: #fff
    url(/images/exdio/renewal/gariben_daigaku/components/certificate-cont_bg.webp)
    no-repeat 50%/100% auto;
  display: flex;
  justify-content: space-between;
`

export const StyledDiv = styled.div`
  width: 200px;
`

export const StyledDescImgs = styled(DescImgs)`
  margin-top: 5px;
`

export const StyledCertificateBtn = styled(CertificateBtn)`
  margin: 10px auto 0;
`
