import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import axios from 'axios'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import HtmlSnippet from '../../HtmlSnippet'
import Slider from 'react-slick'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import routes from '../../../../common/routes'

import HeaderNewsComponent from '../HeaderNewsComponent'
import LogirlProgramList from '../plan_logirl/LogirlProgramList'
import LogirlProgramItem from '../plan_logirl/LogirlProgramItem'
import LogirlBlog from '../plan_logirl/LogirlBlog/index'
import Logirl_Header from '../plan_logirl/components/layout/Header'
import Logirl_Footer from '../plan_logirl/components/layout/Footer'
import AfterFooter from '../plan_logirl/components/layout/AfterFooter'
import Nav2 from '../plan_logirl/components/nav/Nav2'
import Hdg1 from '../plan_logirl/components/heading/Hdg1'
import Hdg2 from '../plan_logirl/components/heading/Hdg2'
import Hdg3 from '../plan_logirl/components/heading/Hdg3'
import Copy from '../plan_logirl/components/other/Copy'
import Btn1 from '../plan_logirl/components/button/Btn1'
import { mediaSp } from '../plan_logirl/style'

/** Logirl月額見放題ページ デフォルトテンプレート */
export default class PlanLogirl extends Component {
  static propTypes = {
    /** プランの情報 */
    course: PropTypes.shape({
      course_id: PropTypes.number.isRequired,
      schema_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired
    }).isRequired,
    /** プランに含まれる動画の情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** プランに含まれる動画の権利関連情報 */
    rightMetas: PropTypes.arrayOf(PropTypes.object),
    /** プランの購入情報 */
    status: PropTypes.shape({
      isPurchseAvailable: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool
    })
  }

  static defaultProps = {
    episodes: [],
    rightMetas: [],
    status: {
      isPurchased: false,
      isNotPurchased: false
    }
  }

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.model = this.context.falcorModel.batch(100)
    this.canvasConfig = this.context.models.config.data.canvas.home
    this.state = {
      loaded: false,
      isSp: webApp.utils.isSp(),
      isSpStyle: null,
      mvPalettes: [],
      pickupPalettes: [],
      palettes: []
    }
    this.sliderSetting = {
      mv: {
        dots: true,
        infinite: true,
        speed: 400,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true,
        centerPadding: '29.15%',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: false,
              centerPadding: 0
            }
          }
        ]
      },
      pickup: {
        dots: false,
        infinite: true,
        speed: 400,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }

    this.api = axios.create({
      baseURL: this.context.models.config.data.service.endpoint,
      timeout: this.context.models.config.data.service.timeout
    })
  }

  componentDidMount() {
    this._isMounted = true
    this.setState({ loaded: true })
    this.getMvPalettes()
    this.getPickupPalettes()

    // twitterウィジェットの追加
    const script = document.createElement('script')
    script.src = 'https://platform.twitter.com/widgets.js'
    script.async = true
    document.body.appendChild(script)

    // bodyにmin-widthを付与
    this.setState({
      isSpStyle: !window.matchMedia('(min-width: 1024px)').matches
    })

    // ハッシュの位置に移動
    if (window.location.hash) {
      setTimeout(() => {
        const targetId = window.location.hash.replace('#', '')
        const target = document.getElementById(targetId)
        const targetPosTop = target.offsetTop
        window.scrollTo({
          top: targetPosTop,
          left: 0,
          behavior: 'smooth'
        })
      }, 3000)
      /*
       * 画像の読み込みで高さが変わるため、ひとまず3秒を設定しているが、
       * なにかしらのcallbackにしたほうが確実
       */
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._isMounted && prevState.isSp !== webApp.utils.isSp()) {
      this.setState({ isSp: webApp.utils.isSp() })
    }

    if (
      this._isMounted &&
      prevState.isSpStyle !== !window.matchMedia('(min-width: 1024px)').matches
    ) {
      this.setState({
        isSpStyle: !window.matchMedia('(min-width: 1024px)').matches
      })
    }
  }

  componentWillUnMount() {
    this._isMounted = false
  }

  /** MV表示パレット取得取得 */
  getMvPalettes() {
    const paletteId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 204
      : 101
    this.api.get(`/palettes/${paletteId}/objects`).then((res) => {
      const items = res.data.objects
      this.setState({ mvPalettes: items })
    })
  }

  /** PickUp表示パレット取得取得 */
  getPickupPalettes() {
    const paletteId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 206
      : 103
    this.api.get(`/palettes/${paletteId}/objects`).then((res) => {
      const items = res.data.objects
      const meta = items.map((obj) => obj.meta)
      this.setState({ pickupPalettes: meta })
    })
  }

  render() {
    const { course } = this.props
    const { loaded, isSp, isSpStyle, mvPalettes, pickupPalettes } = this.state
    const courseId = course.course_id

    // お知らせHTMLスニペットID
    const newsSnippetId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 15
      : 3

    // バナーHTMLスニペットID
    const bannerId1 = _.get(course, ['values', 'banner_1', 'id'])
    const focusOnId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 41
      : 42

    const twitterRenderable = typeof window !== 'undefined'

    return (
      <div className="p-logirl p-plan">
        <Logirl_Header />
        <StyledNav2 />
        {/* <HeaderNewsComponent /> */}
        <div className="p-plan13">
          <HtmlSnippet snippetId={'logirl_information'} />
        </div>

        {loaded && mvPalettes && (
          <div className="p-plan1">
            <Slider {...this.sliderSetting.mv} className="c-slide1">
              {mvPalettes.map((palette, i) => {
                switch (palette.type) {
                  case 'meta':
                    const meta = _.get(palette, ['meta'])
                    const seriesId = _.get(meta, [
                      'values',
                      'parents_series',
                      'id'
                    ])

                    const seasonId =
                      meta.type === 'MediaMeta'
                        ? _.get(meta, ['values', 'parents_season', 'id'])
                        : _.get(meta, ['meta_id'])

                    const episodeId = _.get(meta, ['meta_id'])

                    const params =
                      meta.type === 'MediaMeta'
                        ? { seriesId, seasonId, episodeId }
                        : { seriesId, seasonId }

                    /*
                     * 拡張子がついていないとき'_large'を付与する
                     * オリジナルは画像サイズが大きすぎるため
                     */
                    const thumbUrl =
                      meta.thumbnail_url.slice(-1) == '.'
                        ? `${meta.thumbnail_url}_large`
                        : meta.thumbnail_url

                    return (
                      <Link
                        route={
                          meta.type === 'MediaMeta'
                            ? routes.episode
                            : routes.program
                        }
                        params={params}
                        className="c-banner"
                        key={i}
                      >
                        <img
                          src={thumbUrl}
                          alt={meta.name}
                          className="c-banner__img"
                          loading="lazy"
                        />
                      </Link>
                    )

                  case 'advertising':
                    const advertising = _.get(palette, ['advertising'])
                    const href = _.get(advertising, ['url'])
                    const alt = _.get(advertising, ['name'])
                    /*
                     * 拡張子がついていないとき'_large'を付与する
                     * オリジナルは画像サイズが大きすぎるため
                     */
                    const baseThumbUrl = _.get(advertising, [
                      'creatives',
                      0,
                      'attachment',
                      'file_url'
                    ])
                    const src =
                      baseThumbUrl && baseThumbUrl.slice(-1) == '.'
                        ? `${baseThumbUrl}_large`
                        : baseThumbUrl

                    return (
                      <Link href={href} className="c-banner" key={i}>
                        <img
                          src={src}
                          alt={alt}
                          className="c-banner__img"
                          loading="lazy"
                        />
                      </Link>
                    )
                }
              })}
            </Slider>
          </div>
        )}

        <section className="p-plan2 c-box1 l-container1">
          <StyledHdg1_1 src="/images/exdio/renewal/logirl/icon/daily.svg">
            Daily logirl
          </StyledHdg1_1>
          <HtmlSnippet snippetId="daily-logirl" data-chromatic="ignore" />
        </section>

        <section className="p-plan3 c-box2 l-container1">
          {isSp
            ? [
                <StyledHdg1_2
                  src="/images/exdio/renewal/logirl/icon/youtube1.svg"
                  modifier="main"
                  key="hdg2"
                >
                  YouTube
                </StyledHdg1_2>
              ]
            : [
                <Hdg2
                  src="/images/exdio/renewal/logirl/icon/youtube2.svg"
                  key="hdg2"
                >
                  公式YouTube
                </Hdg2>
              ]}
          <HtmlSnippet snippetId={'logirl_youtube'} />
        </section>

        {loaded && pickupPalettes && (
          <section className="p-plan4">
            <div className="p-plan4__inner">
              <StyledHdg1_3
                src="/images/exdio/renewal/logirl/icon/pickup.svg"
                modifier="red"
              >
                PICKUP
              </StyledHdg1_3>
              <Slider {...this.sliderSetting.pickup} className="c-slide2">
                {pickupPalettes.map((meta, i) => {
                  return (
                    <LogirlProgramItem
                      key={i}
                      meta={meta}
                      course={course}
                      onClickThumbnail={() =>
                        webApp.utils.goToProgramLink(this.context, meta)
                      }
                      onClickPlay={() =>
                        webApp.utils.goToProgramLink(this.context, meta)
                      }
                      onClickCaption={() =>
                        webApp.utils.goToProgramLink(
                          this.context,
                          meta,
                          null,
                          null,
                          { autoPlay: false }
                        )
                      }
                    />
                  )
                })}
              </Slider>
            </div>
          </section>
        )}

        <div id="blog" className="p-plan12 l-container2">
          <StyledBtn1
            href="https://www.tv-asahi.co.jp/reading/logirl/"
            text="記事コンテンツ一覧"
            icon={{
              src: '/images/exdio/renewal/logirl/icon/note.svg',
              alt: ''
            }}
          />
          <StyledLogirlBlog />
        </div>

        <div className="p-plan11 l-container2">
          <HtmlSnippet snippetId={focusOnId} />
        </div>

        {bannerId1 && (
          <div className="p-plan7 l-container2">
            <HtmlSnippet snippetId={bannerId1} />
          </div>
        )}

        <section id="program" className="p-plan8 l-container1">
          <StyledHdg3 src="/images/exdio/renewal/logirl/icon/program.svg">
            番組一覧
          </StyledHdg3>
          <StyledLogirlProgramList />
        </section>

        <section className="p-plan9 l-container1">
          <StyledHdg1_4 src="/images/exdio/renewal/logirl/icon/news.svg">
            お知らせ
          </StyledHdg1_4>
          <div className="c-news">
            <HtmlSnippet snippetId={newsSnippetId} />
          </div>
        </section>

        {twitterRenderable && (
          <section className="p-plan10 l-container2">
            <StyledHdg1_5 src="/images/exdio/renewal/logirl/icon/X.svg">
              logirl公式 X
            </StyledHdg1_5>
            <a
              className="twitter-timeline"
              data-height="400"
              data-chrome="noheader"
              href="https://twitter.com/LoGiRL2015"
            />
          </section>
        )}

        {isSp ? (
          <StyledLogirl_Footer />
        ) : (
          <StyledAfterFooter courseId={courseId} />
        )}
        <Copy />

        <style>
          {isSpStyle
            ? `body { min-width: 320px; }`
            : `body { min-width: 1280px; }`}
        </style>
      </div>
    )
  }
}

const StyledNav2 = styled(Nav2)`
  grid-area: nav;
  margin-top: 16px;
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;

  @media ${mediaSp} {
    display: none;
  }
`

const StyledBtn1 = styled(Btn1)`
  && {
    margin: 0 auto;
    max-width: 100%;
    width: 420px;
    font-size: 3.2rem;

    @media ${mediaSp} {
      padding: 8px 12px;
      font-size: 2.4rem;
    }

    i {
      span {
        width: 24px;
        height: 24px;

        @media ${mediaSp} {
          width: 18px;
          height: 18px;
        }

        svg {
          path {
            fill: #333;
          }
        }
      }
    }
  }
`

const StyledHdg1_1 = styled(Hdg1)`
  i {
    span {
      @media ${mediaSp} {
        width: 24px;
        height: 26px;
      }

      svg {
        width: 32px;
        height: 35px;

        @media ${mediaSp} {
          width: 24px;
          height: 26px;
        }
      }
    }
  }
`
const StyledHdg1_2 = styled(Hdg1)`
  && {
    @media ${mediaSp} {
      color: #00a3a3;

      i {
        span {
          width: 24px;
          height: 18px;

          svg {
            path {
              fill: #00a3a3;
            }
          }
        }
      }
    }
  }
`
const StyledHdg1_3 = styled(Hdg1)`
  @media ${mediaSp} {
    i {
      span {
        width: 30px;
        height: 29px;
      }
    }
  }
`
const StyledHdg1_4 = styled(Hdg1)`
  @media ${mediaSp} {
    i {
      span {
        width: 29px;
        height: 28px;
      }
    }
  }
`
const StyledHdg1_5 = styled(Hdg1)`
  margin-bottom: 16px;

  @media ${mediaSp} {
    i {
      span {
        width: 27px;
        height: 22px;
      }
    }
  }
`

const StyledHdg3 = styled(Hdg3)`
  i {
    span {
      width: 37px;
      height: 42px;

      @media ${mediaSp} {
        width: 28px;
        height: 31px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`

const StyledLogirl_Footer = styled(Logirl_Footer)`
  @media ${mediaSp} {
    margin-top: 16px;
    nav {
      ul {
        li {
          margin-top: 8px;
        }
      }
    }
  }
`

const StyledAfterFooter = styled(AfterFooter)`
  margin-top: 136px;
`

const StyledLogirlProgramList = styled(LogirlProgramList)`
  margin-top: 16px;
`

const StyledLogirlBlog = styled(LogirlBlog)`
  &:nth-of-type(2) {
    i {
      @media ${mediaSp} {
        position: relative;
        top: -3px;
        height: 41px;
      }
      svg {
        width: 28px;
        height: 35px;
      }
    }

    ul {
      margin-top: 24px;
      @media ${mediaSp} {
        margin-top: 8px;
      }
    }
  }
`
