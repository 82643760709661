import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NavItem from './NavItem'

/** style */
import { mediaQuery } from '../../../exdio/components/style'

/** ナビゲーション */
const NavItems = (
  { toggleNavMenu = () => {}, show = {}, ...props },
  context
) => {
  const config = context.models.config.data
  const navigationMenu = config.global_navigation

  return (
    <StyledUl {...props}>
      {navigationMenu.map((menu) => {
        return (
          <NavItem
            key={menu.title}
            menu={menu}
            toggleNavMenu={toggleNavMenu}
            show={show}
          />
        )
      })}
    </StyledUl>
  )
}

export default NavItems

NavItems.contextTypes = {
  models: PropTypes.object
}

NavItems.propTypes = {
  /** ナビゲーションドロップダウン開閉処理 */
  toggleNavMenu: PropTypes.func.isRequired,
  /** ナビゲーションドロップダウン開閉状態 */
  show: PropTypes.object.isRequired
}

const StyledUl = styled.ul`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  ${mediaQuery()} {
    padding-left: 1.6rem;
    font-size: 1.2rem;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
`
