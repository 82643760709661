import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import webApp from '../../../../../utils/exdioWebAppUtils'
import routes from '../../../../../../common/routes'
import * as browserEvents from '../../../../../../utils/browserEvents'

/* style */
import {
  StyledBtn1,
  StyledLi,
  StyledLink,
  StyledDiv1,
  StyledImg,
  StyledDiv2,
  StyledDl,
  StyledDt,
  StyledDd,
  StyledP
} from './style'

/**
 * ## LogirlProgramList ListItem
 *
 * ### 概要
 * - `LogirlProgramList`の`ul`内で使用される`li`
 * - props.metaを元にリンク先を指定
 * - クリックorホバーで番組概要を表示
 *
 * ### 番組概要の表示
 * - PC: ホバー時に表示、リンクorボタンクリックで番組ページに遷移（SPA）
 *   - CSSのみで制御
 * - SP: リンククリック時に表示、ボタンクリックで番組ページに遷移（SPA）
 *   - `selectedSeasonId`で制御
 */
const ListItem = ({ meta, selectedSeasonId, setSelectedSeasonId }, context) => {
  const { name, values, meta_id: seasonId, thumbnail_url: thumbnailUrl } = meta

  /* useState */
  const [isSp, setIsSp] = useState(webApp.utils.isSp())
  const [imgHeight, setImgHeight] = useState(0)

  /** useRef */
  const imgRef = useRef(null)

  const seriesId = get(values, ['parents_series', 'id'])
  const params = { seriesId, seasonId }

  /** 概要 */
  const seasonLongSynopsis = get(values, ['evis_SeasonLongSynopsis'])
  const replacedSeasonLongSynopsis = seasonLongSynopsis
    ? seasonLongSynopsis.replace(/\n/g, '<br />')
    : seasonLongSynopsis

  /* 読み込む画像サイズを変更する */
  const thumbUrl =
    webApp.utils.customSizeImageUrl(thumbnailUrl, 'medium') ||
    context.models.config.data.default_thumbnail

  /** 番組クリックで詳細を開く (SPのみ) */
  const spModalHandler = (e) => {
    /** SPでない場合は終了 */
    if (!isSp) return

    e.preventDefault()
    if (seasonId === selectedSeasonId) {
      setSelectedSeasonId(null) // 閉じる
    } else {
      setSelectedSeasonId(seasonId) // 開く
    }

    /** 画像の高さを取得 */
    if (!imgRef.current) return
    setImgHeight(imgRef.current.clientHeight)
  }

  /** SP判別の再設定 */
  const resetIsSp = () => {
    if (isSp === webApp.utils.isSp()) return
    setIsSp(webApp.utils.isSp())
  }

  useEffect(() => {
    browserEvents.addEventListener('resize', resetIsSp)

    return () => {
      browserEvents.removeEventListener('resize', resetIsSp)
    }
  }, [])

  /** 条件が変更されたときイベントを登録しなおす */
  useEffect(() => {
    browserEvents.removeEventListener('resize', resetIsSp)
    browserEvents.addEventListener('resize', resetIsSp)

    return () => {
      browserEvents.removeEventListener('resize', resetIsSp)
    }
  }, [isSp])

  if (!seriesId || !seasonId) return null

  return (
    <StyledLi selected={seasonId === selectedSeasonId} imgHeight={imgHeight}>
      <StyledLink
        route={routes.program}
        params={params}
        onClick={spModalHandler}
      >
        <StyledDiv1>
          <StyledImg src={thumbUrl} alt={name} loading="lazy" ref={imgRef} />
        </StyledDiv1>
        <StyledDiv2>{name}</StyledDiv2>
      </StyledLink>
      <StyledDl>
        <StyledDt>{name}</StyledDt>
        <StyledDd>
          <StyledP
            dangerouslySetInnerHTML={{
              __html: replacedSeasonLongSynopsis
            }}
          />
          <StyledBtn1
            route={routes.program}
            params={params}
            text="エピソード一覧"
            icon={{
              src: '/images/exdio/renewal/logirl/icon/arrow.svg',
              alt: ''
            }}
          />
        </StyledDd>
      </StyledDl>
    </StyledLi>
  )
}

export default ListItem

ListItem.propTypes = {
  /** 番組メタ */
  meta: PropTypes.shape({
    name: PropTypes.string,
    meta_id: PropTypes.number,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      parents_series: PropTypes.shape({
        id: PropTypes.number
      })
    })
  }),
  /** 選択中のシーズンID */
  selectedSeasonId: PropTypes.number,
  /** 選択中のシーズンの変更 */
  setSelectedSeasonId: PropTypes.func
}

ListItem.defaultProps = {
  meta: {
    name: '',
    meta_id: '',
    thumbnail_url: '',
    values: {
      parents_series: {
        id: null
      }
    }
  },
  selectedSeasonId: null,
  setSelectedSeasonId: () => {}
}

ListItem.contextTypes = {
  models: PropTypes.object
}
