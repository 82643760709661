import React from 'react'

/* style */
import { StyledDiv, StyledP } from './styles'

/** ログインページ 注意書き */
const Terms = () => {
  return (
    <StyledDiv>
      <StyledP>
        テレビ朝日では、2022/4/1にプライバシーポリシーを改定いたしました。
      </StyledP>
      <StyledP>
        tv asahi iDおよびSNSアカウントでのサービスご利用にあたり
        <br />・
        <a
          href="https://www.tv-asahi.co.jp/ex/member/rule/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          tv asahi iDご利用規約
        </a>
        　(2023/6/23改定)
        <br />・
        <a
          href="https://www.tv-asahi.co.jp/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          テレビ朝日における個人情報の取扱いについて
        </a>
        　(2022/4/1改定)
        <br />
        に同意の上、ログインしてください。
      </StyledP>
    </StyledDiv>
  )
}

export default Terms
