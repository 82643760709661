import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import { Helmet } from 'react-helmet'
import webApp from '../../../utils/exdioWebAppUtils'

/* hooks */
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/* Components */
import Mv from './Mv'
import Nav1 from './Nav1'
import Nav3 from './Nav3'
import Section0 from './Section0'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section6 from './Section6'
import Section7 from './Section7'
import Section8 from './Section8'
import Section9 from './Section9'
import ScrollTop from './ScrollTop'
import Certificate from './Certificate'
import Footer from './Footer'

/* data */
import navItems from './json/navItems.json'
import anchorList from './json/anchor_list.json'

/* style */
import { GlobalStyle, StyledDiv1, StyledDiv2, StyledMain } from './styles'

/** 月額見放題ページ 私立ガリベン大学テンプレート */
const PlanGariben = (
  {
    course,
    status = {
      isPurchased: false,
      isNotPurchased: false
    },
    programMetas = [],
    userInfo = {},
    point = 0,
    nickname = '',
    archives = [],
    tags = [],
    ranking = [],
    schoolAssemblies = [],
    liveArchives = [],
    blogPosts = [],
    news = [],
    specialArchives = {
      isActive: false,
      episodes: []
    },
    recommendArchives = {
      isActive: false,
      recommenders: []
    }
  },
  context
) => {
  if (!size(programMetas)) return null

  const config = context.models.config.data
  const isLoggedIn = webApp.utils.isLoggedIn(context)

  // お知らせパレット
  const articleId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 108
    : 79

  const bannerId1 = get(course, ['values', 'banner_1', 'id'])
  const bannerId2 = get(course, ['values', 'banner_2', 'id'])
  const childEpisodeIds = Object.values(programMetas).flatMap((program) =>
    get(program, ['values', 'child_episode_ids'], null)
  )

  const programIds = config.svod.gariben_daigaku
  const liveArchivesSeasonId = programIds.live_archives.season_id // ライブ配信アーカイブ
  const specialMoviesSeasonId = programIds.special_movies.season_id // 特別授業動画
  const archivesSeasonId = programIds.archives.season_id // 神回アーカイブ

  const [hidePageTop, setHidePageTop] = useState(true)

  const searchParams = useSearchParams({
    childEpisodeIds,
    seasonIds: [specialMoviesSeasonId, liveArchivesSeasonId, archivesSeasonId],
    sortedBy: 'delivery_start_at_newer',
    pagerOptions: {
      episodesPerPages: 8,
      range: 2
    }
  })

  /** PAGE TOP 表示制御 */
  const windowScrollHandler = () => {
    const scrollTop = window.scrollY
    const windowHeightHalf = Math.floor(window.innerHeight * 0.5)

    /** 初回だけ非表示にする（アニメーション考慮） */
    const isHide = scrollTop < windowHeightHalf
    if (!isHide) {
      setHidePageTop(false)
      window.removeEventListener('scroll', windowScrollHandler)
    }
  }

  useEffect(() => {
    /**
     * ハッシュの位置に移動
     * 画像の読み込みで高さが変わるため、ひとまず3秒を設定しているが、
     * なにかしらのcallbackにしたほうが確実
     */
    if (window.location.hash) {
      setTimeout(() => {
        const targetId = window.location.hash.replace('#', '')
        const target = document.getElementById(targetId)
        const targetPosTop = target.offsetTop
        window.scrollTo({
          top: targetPosTop,
          left: 0,
          behavior: 'smooth'
        })
      }, 3000)
    }

    window.addEventListener('scroll', windowScrollHandler)

    return () => {
      window.removeEventListener('scroll', windowScrollHandler)
    }
  }, [])

  let archiveEpisodes = []
  let archiveLead = ''
  let navText = ''
  if (recommendArchives.isActive) {
    archiveEpisodes = recommendArchives.episodes
    archiveLead = recommendArchives.lead || ''
    navText = recommendArchives.navText || ''
  } else if (specialArchives.isActive) {
    archiveEpisodes = specialArchives.episodes
    archiveLead = specialArchives.lead || ''
    navText = specialArchives.navText || ''
  } else {
    archiveEpisodes = archives
    archiveLead = '「ガリベンガーV」の過去放送回から、月替わりで神回をお届け！'
    navText = ''
  }

  return (
    <div id="gariben">
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://csshake.surge.sh/csshake.min.css"
        />
        <link
          rel="icon"
          href="/images/exdio/renewal/gariben_daigaku/top/gbu_favicon.webp"
        />
        <link
          rel="apple-touch-icon"
          href="/images/exdio/renewal/gariben_daigaku/top/gbu_favicon.webp"
        />
        <script type="text/javascript">
          {`(function(add, cla){window['UserHeatTag']=cla;window[cla]=window[cla]||function(){(window[cla].q=window[cla].q||[]).push(arguments)},window[cla].l=1*new Date();var ul=document.createElement('script');var tag = document.getElementsByTagName('script')[0];ul.async=1;ul.src=add;tag.parentNode.insertBefore(ul,tag);})('//uh.nakanohito.jp/uhj2/uh.js', '_uhtracker');_uhtracker({id:'uhsXftw6Ax'});`}
        </script>
      </Helmet>
      {/* SPメニュー */}
      <Nav3 navItems={navItems} anchorList={anchorList} status={status} />
      <StyledMain className="lazyload">
        <Mv
          articleId={articleId}
          navItems={anchorList}
          status={status}
          isLoggedIn={isLoggedIn}
          point={point}
        />

        {/* グローバルナビ */}
        {size(navItems) > 0 && (
          <Nav1 navItems={navItems} navText={navText} status={status} />
        )}

        {/* SP ボタンリスト */}
        {!status.isPurchased && (
          <StyledDiv1>
            <Section0 />
          </StyledDiv1>
        )}

        {/* バナー & 更新情報 & 投書箱 */}
        {size(news) > 0 && (
          <Section1 news={news} bannerId1={bannerId1} bannerId2={bannerId2} />
        )}

        {/* ライブ授業 */}
        {(size(schoolAssemblies) > 0 || size(liveArchives) > 0) && (
          <Section4
            nextLive={get(schoolAssemblies, [0])}
            archive={get(liveArchives, [0])}
            showMoreButton={size(liveArchives) > 1}
          />
        )}

        {/* 神回アーカイブ */}
        {archiveEpisodes && (
          <Section8
            recommends={recommendArchives || {}}
            episodes={archiveEpisodes}
            lead={archiveLead || ''}
          />
        )}

        {/* 人気急上昇ランキング */}
        {size(ranking) > 0 && <Section9 items={ranking} />}

        {/* 特別授業動画 */}
        {size(searchParams.state.seasonIds) > 0 && (
          <Section2 searchParams={searchParams} tags={tags} />
        )}

        {/* 図書館ブログ */}
        {size(blogPosts) > 0 && <Section3 posts={blogPosts} />}

        {/* テレビオンエア見逃し */}
        <Section6 />

        {/* 購買部 */}
        <Section5 />

        {/* 在学証明書 */}
        {status.isPurchased && (
          <StyledDiv2>
            <Certificate
              icon={userInfo.img_url}
              point={point}
              nickname={nickname}
            />
          </StyledDiv2>
        )}

        {/* Twitter バナーエリア */}
        <Section7 />

        {/* ページトップ */}
        <ScrollTop hide={hidePageTop} />
      </StyledMain>

      <Footer />
      <GlobalStyle />
    </div>
  )
}

export default memo(PlanGariben)

PlanGariben.propTypes = {
  /** プランの情報 */
  course: PropTypes.shape({
    course_id: PropTypes.number.isRequired,
    schema_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    active_pricing: PropTypes.shape({}).isRequired,
    values: PropTypes.shape({}).isRequired
  }).isRequired,
  /** プランの購入情報 */
  status: PropTypes.shape({
    isPurchseAvailable: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool
  }),
  /** 配下番組の情報 */
  programMetas: PropTypes.arrayOf(PropTypes.shape({})),
  /** ユーザー情報 */
  userInfo: PropTypes.shape({}),
  /** ユーザーの保有ポイント */
  point: PropTypes.number,
  /** ユーザー名 */
  nickname: PropTypes.string,
  /** 神回アーカイブデータ */
  archives: PropTypes.arrayOf(PropTypes.shape({})),
  /** 特別授業動画 タグ */
  tags: PropTypes.arrayOf(PropTypes.string),
  /** 人気急上昇ランキングデータ */
  ranking: PropTypes.arrayOf(PropTypes.shape({})),
  /** ライブ授業データ */
  schoolAssemblies: PropTypes.arrayOf(PropTypes.shape({})),
  /** ライブ授業アーカイブデータ */
  liveArchives: PropTypes.arrayOf(PropTypes.shape({})),
  /** 図書館ブログ記事データ */
  blogPosts: PropTypes.arrayOf(PropTypes.shape({})),
  /** 更新情報データ */
  news: PropTypes.arrayOf(PropTypes.shape({})),
  /** 神回アーカイブエリア特別記事データ */
  specialArchives: PropTypes.shape({
    isActive: PropTypes.bool,
    episodes: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  /** 神回アーカイブエリアおすすめ記事データ */
  recommendArchives: PropTypes.shape({
    isActive: PropTypes.bool,
    recommenders: PropTypes.arrayOf(PropTypes.shape({}))
  })
}

PlanGariben.contextTypes = {
  models: PropTypes.shape({}),
  falcorModel: PropTypes.shape({})
}
