import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import _ from 'lodash'
import List5 from '../components/list/List5'
import Hdg1 from '../components/heading/Hdg1'

/* style */
import { StyledSection } from './style'

/** Logirl Blog */
const LogirlBlog = ({ ...props }, context) => {
  const config = context.models.config.data
  const [fixedArticles, setFixedArticles] = useState([])
  const [otherArticles, setOtherArticles] = useState([])
  const api = axios.create({
    baseURL: config.service.endpoint,
    timeout: config.service.timeout
  })

  useEffect(() => {
    const fetchData = async () => {
      const otherArticles = await getArticles('-2%2C-3%2C-47%2C-45') // その他記事
      const leftArticle = await getArticles('32') // 左に配置する記事
      const rightArticle = await getArticles('31') // 右に配置する記事
      // 固定表示記事をまとめる
      const fixedArticles = [].concat(
        leftArticle.slice(0, 1),
        rightArticle.slice(0, 1)
      )
      setFixedArticles(fixedArticles)
      setOtherArticles(otherArticles)
    }
    fetchData()
  }, [])

  /** 記事を取得 */
  const getArticles = (cat = '') => {
    const host = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 'pwww.tv-asahi.co.jp'
      : 'www.tv-asahi.co.jp'
    const category = cat ? `?cat=${cat}` : ''

    return axios
      .get(
        `https://${host}/douga_mv/logirl/api/get_blog_feed.php?rss=https://www.tv-asahi.co.jp/reading/logirl/feed/${category}`
      )
      .then((res) => res.data)
      .catch((err) => console.error(err))
  }

  return (
    <React.Fragment>
      {fixedArticles.length > 0 && (
        <StyledSection {...props}>
          <List5 articles={fixedArticles} size="large" />
        </StyledSection>
      )}
      {otherArticles.length > 0 && (
        <StyledSection {...props}>
          <Hdg1 src="/images/exdio/renewal/logirl/icon/note.svg">NEW POST</Hdg1>
          <List5 articles={otherArticles} />
        </StyledSection>
      )}
    </React.Fragment>
  )
}

export default LogirlBlog

LogirlBlog.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  className: PropTypes.string
}
