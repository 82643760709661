/* style */
import styled from 'styled-components'

export const StyledDiv1 = styled.div`
  position: relative;
`

export const StyledLi = styled.li`
  width: calc(100% / 4 - 12px);
  margin: 0 6px;
  float: left;

  img {
    transition: opacity 0.3s ease 0s;
  }

  a:hover {
    ${StyledDiv1} {
      img {
        opacity: 0.7;
      }
    }
  }
`

export const StyledP1 = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  background: #1f9064;
  padding: 3px 5px;
  color: #fff;
  font-size: 1.2rem;
`

export const StyledDiv2 = styled.div`
  padding: 20px 0;
  position: relative;
`

export const StyledP2 = styled.p`
  color: #333;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.5;
  background: #eee;
  position: absolute;
  top: -20px;
  right: -5px;
  width: 88%;
  padding: 8px 12px;
`

export const StyledDiv3 = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 25px;
`

export const StyledImg = styled.img`
  width: 40px;
  margin: 0;
  clip-path: none;
`

export const StyledP3 = styled.p`
  text-align: right;
  font-size: 1.2rem;
`
