import styled from 'styled-components'
import { bgColor, blue, mediaPc, yellow } from '../../styles'

// MainSlider用style
export const StyledDiv1 = styled.div`
  padding-right: 224px;
  position: relative;
  background: ${bgColor};
  color: #fff;

  @media ${mediaPc} {
    padding-right: 288px;
  }
`

export const StyledDiv2 = styled.div`
  .slick-list {
    height: 416px;

    .slick-slide {
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
      }
    }

    @media ${mediaPc} {
      height: 100%;
    }
  }

  .slick-dots {
    width: auto;
    bottom: -25px;
    right: -200px;

    @media ${mediaPc} {
      right: -275px;
    }

    li {
      width: 8px;
      height: 8px;
      border: 1px solid ${blue};
      background: #fff;
      margin: 0 4px;

      button {
        width: 100%;
        height: 100%;
      }

      button::before {
        content: none;
      }
    }

    .slick-active {
      background: ${yellow} !important;
    }
  }
`

export const StyledDiv3 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  max-height: 390px;
  height: calc(100% - 44px);
  overflow: hidden;

  @media ${mediaPc} {
    max-height: 536px;
  }
`

export const StyledDiv4 = styled.div`
  width: 268px;
  height: 652px;
  padding-top: 94px;
  transform: translate3d(0px, -94px, 0px);
  transition: transform 0.7s ease 0s;

  .slick-current {
    .active-arrow::before {
      content: '';
      position: absolute;
      height: 100%;
      border-left: 5px solid ${blue};
      left: 30px;
      top: 0;
      margin-left: 13px;
    }
    .active-arrow::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: 30px;
      top: 50%;
      margin-top: -8px;
      border-right: 13px solid ${blue};
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }

  @media ${mediaPc} {
    width: 301px;

    .slick-current {
      .active-arrow::before {
        content: '';
        left: -2px;
      }
      .active-arrow::after {
        left: -1px;
      }
    }
  }

  .slick-slide {
    width: 319px !important;
    position: relative;
  }

  .thumbnail-img {
    opacity: 0.6;
    width: 220px;

    @media ${mediaPc} {
      width: 284px;
    }
  }

  .slick-current {
    .thumbnail-img {
      opacity: 1;
    }
  }
`

export const StyledDiv5 = styled.div`
  text-align: left;

  span {
    font-weight: bold;
    display: inline-block;
    padding: 10px 0px 18px 10px;
    width: 100%;
  }
`
