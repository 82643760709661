import React from 'react'
import PropTypes from 'prop-types'
import { FAVORITE_TYPE } from '../../../../../../constants/app'
import routes from '../../../../../common/routes'
import webApp from '../../../../utils/exdioWebAppUtils'

/* components */
import NextPrevButton from './NextPrevButton'
import MyListButton from './MyListButton'
import ShareButton from './ShareButton'

/* style */
import { StyledList, StyledListItem, StyledLink, StyledImg } from './style'

/** 詳細画面:単話ページングコンポーネント */
const EpisodePager = (
  { meta = null, seriesId = null, seasonId = null, ...props },
  context
) => {
  if (!seriesId || !seasonId) return null

  const isApp = webApp.utils.isApp(context)
  const programRoute = isApp ? routes.app_program : routes.program

  let [metaName, subTitle] = webApp.utils.titles(meta)
  metaName = `${metaName} ${subTitle}`

  return (
    <StyledList {...props}>
      <StyledListItem>
        <NextPrevButton meta={meta} nextOrPrev={'prev'}>
          <img
            src="/images/exdio/renewal/gariben_daigaku/episode/icon/prev.svg"
            width="38"
            height="38"
            alt=""
          />
          前の動画
        </NextPrevButton>
      </StyledListItem>
      <StyledListItem>
        <StyledLink route={programRoute} params={{ seriesId, seasonId }}>
          <StyledImg
            src="/images/exdio/renewal/gariben_daigaku/episode/icon/home.svg"
            width="38"
            height="38"
            alt=""
          />
          番組TOP
        </StyledLink>
      </StyledListItem>
      <StyledListItem>
        <NextPrevButton meta={meta} nextOrPrev={'next'}>
          <img
            src="/images/exdio/renewal/gariben_daigaku/episode/icon/next.svg"
            width="38"
            height="38"
            alt=""
          />
          次の動画
        </NextPrevButton>
      </StyledListItem>
      <StyledListItem>
        <MyListButton
          favoriteType={FAVORITE_TYPE.META}
          favoriteId={meta.meta_id}
        />
      </StyledListItem>
      <StyledListItem>
        <ShareButton title={metaName} />
      </StyledListItem>
    </StyledList>
  )
}

export default EpisodePager

EpisodePager.propTypes = {
  // 月額見放題に含まれない単話の場合
  meta: PropTypes.shape({
    values: PropTypes.shape({
      prev_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
      next_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
    })
  }),
  seriesId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

EpisodePager.contextTypes = {
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object
}
