import _ from 'lodash'
import React, { Fragment } from 'react'
import moment from 'moment'
import window from 'global'
import Cookie from 'js-cookie'
import routes from '../../common/routes'
import {
  DIO_PLAYER_DEVICE,
  EPISODE_DISPLAY_MODE,
  META_SCHEMA_ID,
  PRODUCT_SCHEMA,
  PRODUCT_SCHEMAS,
  SVOD_DELIVERY_PATTERN
} from '../../../constants/app'
import browserInfo from '../../../sketch-platform/utils/browserInfo'
import { getResumeInfo } from '../../common/global_functions.js'
import webApp from './exdioWebAppUtils'

const $ = require('jquery')
const { sprintf } = require('sprintf-js')

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

const exdioWebAppUtils = {
  utils: {
    rememberMeCheckBoxTrigger() {
      $('.login-check').trigger('click')
    },
    updateTitle(title) {
      $('title').html(title)
    },
    updateMeta(metadata) {
      // dio用の拡張メタタグをすべて削除する
      $('head > .dio').each(function() {
        $(this).remove()
      })
      _.keys(metadata).map((key) => {
        for (const line of metadata[key]) {
          if (key === 'names') {
            $('head').append(
              `<meta name="${line.name}" content="${line.content}" class="dio" >`
            )
          } else if (key === 'properties') {
            $('head').append(
              `<meta property="${line.property}" content="${line.content}" class="dio" >`
            )
          } else if (key === 'links') {
            $('head').append(
              `<link rel="${line.rel}" href="${line.href}" class="dio" >`
            )
          }
        }
      })
    },
    updateWebClip(img) {
      $("link[rel='apple-touch-icon']").remove()
      $('head').append(`<link rel="apple-touch-icon" href=${img} >`)
    },
    appendUserGram(html) {
      $('body').append(html)
    },
    removeUserGram(ids) {
      for (let i = 0; i < ids.length; i++) {
        $(`#${ids[i]}`).remove()
      }
    },
    returnDefaultWebClip() {
      $("link[rel='apple-touch-icon']").remove()
      $('head').append(
        `<link rel="apple-touch-icon" href="/apple-touch-icon.png" >`
      )
    },

    load_vr_sync() {
      this.remove_vr_sync()
      const script = document.createElement('script')
      script.id = 'vr_sync'
      script.src = '/text/javascript'
      script.src = '/vr_sync.js'
      const head = document.getElementsByTagName('head')[0]
      head.appendChild(script)
    },
    remove_vr_sync() {
      const currentScript = document.getElementById('vr_sync')
      if (currentScript) {
        const deviceInfo = browserInfo(navigator.userAgent, (data) => data)
        if (!deviceInfo.isIE) {
          currentScript.remove()
        } else {
          currentScript.parentNode.removeChild(currentScript)
        }
      }
    },

    updateDataLayer(metadata) {
      $('head > #gtm_datalayer').remove()
      const tag = this.makedataLayerTagProps(metadata)
      const script = $(`<script id="gtm_datalayer">${tag}</script>`)
      $('head').append(script)
    },
    updateDataLayerPurchase(metadata) {
      $('head > #gtm_datalayer').remove()
      const tag = this.makedataLayerTagPropsPurchase(metadata)
      const script = $(`<script id="gtm_datalayer">${tag}</script>`)
      $('head').append(script)
    },

    /** titleに使用する文字列を作成 */
    makeTitleTagString(meta) {
      if (!meta) return null
      const {
        avails_EpisodeTitleDisplayUnlimited: episodeTitle = ''
      } = meta.values

      /**
       * 1 evis_FrontDisplayTitle
       * 2 ${avails_SeriesTitleDisplayUnlimited} ${avails_SeasonTitleDisplayUnlimited}
       * 3 avails_SeriesTitleDisplayUnlimitedが存在する場合
       */
      const getSeriesSeasonName = (meta = { values: {} }) => {
        const {
          avails_SeriesTitleDisplayUnlimited: seriesTitle = '',
          avails_SeasonTitleDisplayUnlimited: seasonTitle = '',
          evis_FrontDisplayTitle: frontDisplayTitle = ''
        } = meta.values

        /** フロント表示名がはいっていたら優先する */
        if (frontDisplayTitle) return frontDisplayTitle
        /** シリーズ名 + シーズン名 */
        if (seriesTitle && seasonTitle) return `${seriesTitle} ${seasonTitle}`
        /** シリーズ名 */
        if (seriesTitle) return seriesTitle

        return null
      }
      const seriesSeasonName = getSeriesSeasonName(meta)

      /** 条件に応じて値を返却 */
      if (seriesSeasonName) {
        if (episodeTitle) return `${episodeTitle}｜${seriesSeasonName}`
        return seriesSeasonName
      }
      return episodeTitle
    },

    makeMetaTagProps(metadata) {
      const res = []
      let count = 0
      _.keys(metadata).map((key) => {
        for (const line of metadata[key]) {
          count++
          if (key === 'names') {
            res.push({
              key: String(count),
              name: line.name,
              content: line.content,
              className: 'dio'
            })
          } else if (key === 'properties') {
            res.push({
              key: String(count),
              property: line.property,
              content: line.content,
              className: 'dio'
            })
          }
        }
      })
      return res
    },
    makedataLayerTagProps(metadata) {
      let pushItem = ''
      metadata.map((item) => {
        let { value } = item
        if (typeof value === 'string') {
          value = value.replace(/'/g, "\\'")
        }
        pushItem += `'${item.key}': '${value}',`
      })
      let res = 'var dataLayer = dataLayer || [];'
      res += `dataLayer.push({${pushItem}});`
      return res
    },
    makedataLayerTagPropsPurchase(metadata) {
      let pushItem = ''
      metadata.map((item) => {
        let { value } = item
        pushItem += `'${item.key}': '${value}',`
      })
      let res = 'var dataLayer = dataLayer || [];'
      res += `dataLayer.push({${pushItem}});`
      return res
    },
    updateCookieSync: function(userAgent) {
      let device = browserInfo(userAgent, function(data) {
        return data
      })
      if (!device.isAppli) {
        $('body > #cookieSyncTag').remove()
        $('body').append(
          '<iframe id="cookieSyncTag" src="https://js.rfp.fout.jp/info/beacon.html?network_id=26&publisher_id=823" width="1" height="1" style="display:none" frameBorder="0"></iframe>'
        )
      }
    },

    setDefaultMetaTags(context, optTitle = null) {
      if (!context) throw 'Error. context required.'
      if (!context.models) throw 'Error. context.models required.'

      const config = context.models.config.data

      // タイトルタグの更新
      const title = optTitle
        ? sprintf(config.title_template, optTitle)
        : config.default_title
      this.updateTitle(title)

      // メタタグの更新
      const { copyright, description, keywords } = config
      const rootUrl = `${window.location.protocol}//${window.location.host}`
      const ogImage = sprintf('%s/images/exdio/%s', rootUrl, config.og_image)
      const url = window.location.href
      const regularUrl = url.replace(/\?.*$/, '')
      const removeAppUrl = regularUrl.replace('/app', '')

      const metaTags = {
        names: [
          { name: 'copyright', content: copyright },
          { name: 'description', content: description },
          { name: 'keywords', content: keywords },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:image', content: ogImage },
          { name: 'twitter:title', content: title },
          { name: 'twitter:url', content: regularUrl },
          { name: 'twitter:description', content: description }
        ],
        properties: [
          { property: 'mixi:image', content: ogImage },
          { property: 'og:image', content: ogImage },
          { property: 'og:title', content: title },
          { property: 'og:url', content: regularUrl },
          { property: 'og:description', content: description }
        ],
        links: [{ rel: 'canonical', href: removeAppUrl }]
      }
      this.updateMeta(metaTags)
    },

    // *********************
    // 共通
    // *********************

    /** ログアウト */
    logout(context, callback = null) {
      if (!context) throw 'Error. context required.'
      if (!context.falcorModel) throw 'Error. context.falcorModel required.'

      const path = ['user', 'logout']
      return context.falcorModel.call(path, []).then(() => {
        if (typeof callback === 'function') callback()
      })
    },

    /** falcor呼び出しエラーハンドリング */
    handleFalcorError(error, context) {
      if (!context) throw 'Error. context required.'
      if (!context.falcorModel) throw 'Error. context.falcorModel required.'

      const status = _.get(error, [0, 'value', 'status'])
      if (status === 401) {
        const path = _.get(error, [0, 'path'])
        this.debug(
          `[exdioWebAppUtils] will logout because Falcor request ended with 401. path=${JSON.stringify(
            path
          )}`
        )
        this.logout(context)
          .then(() => window.location.reload())
          .catch((e) => console.error(e))
      } else {
        console.error(error)
      }
    },

    /** WebView判定 */
    isApp(context) {
      if (!context) throw 'Error. context required.'
      if (!context.routeHandler) throw 'Error. context.routeHandler required.'
      return (
        context.routeHandler.url === '/app' ||
        context.routeHandler.url.startsWith('/app/')
      )
    },

    /** SP判定 */
    isSp() {
      const device = browserInfo(navigator.userAgent, (data) => data)
      return device.isIOS || device.isAndroid
    },

    /** SP判定 iPadOSも含む */
    isSpAndIPad() {
      const device = browserInfo(navigator.userAgent, (data) => data)
      const ua = window.navigator.userAgent.toLowerCase()
      const isIPadOS =
        ua.indexOf('ipad') > -1 ||
        (ua.indexOf('macintosh') > -1 && 'ontouchend' in document)
      return device.isIOS || device.isAndroid || isIPadOS
    },

    /** デバイス判定 */
    getDevice() {
      const deviceInfo = browserInfo(navigator.userAgent, (data) => data)
      const ua = window.navigator.userAgent.toLowerCase()
      const isIPadOS =
        ua.indexOf('ipad') > -1 ||
        (ua.indexOf('macintosh') > -1 && 'ontouchend' in document)
      if (deviceInfo.isAppli) {
        return DIO_PLAYER_DEVICE.APPLI // Appli
      } else if (deviceInfo.isIOS || isIPadOS) {
        return DIO_PLAYER_DEVICE.IOS // iOS or iPadOS
      } else if (deviceInfo.isAndroid) {
        return DIO_PLAYER_DEVICE.ANDROID // Android
      }
      return DIO_PLAYER_DEVICE.PC // PC
    },

    /** ログイン判定 */
    isLoggedIn(context) {
      if (!context) throw 'Error. context required.'
      if (!context.models) throw 'Error. context.models required.'
      return !!_.get(context.models, ['authContext', 'data'])
    },

    // *********************
    // ユーティリティ
    // *********************
    debug(data) {
      if (['development', 'staging'].includes(process.env.NODE_ENV)) {
        const msg =
          data instanceof Object ? JSON.stringify(data, null, 2) : data
        console.log(`[dev only] ${msg}`)
      }
    },

    /**
     * @param duration 時間(秒数)
     * @returns {string}
     */
    formatDuration(duration) {
      const seconds = duration % 60
      const minutes = ((duration - seconds) / 60) % 60
      const hour = parseInt(duration / 3600, 10)
      if (hour > 0) {
        return minutes > 0 ? `${hour}時間${minutes}分` : `${hour}時間`
      }
      if (minutes > 0) return `${minutes}分`
      if (seconds > 0) return `${seconds}秒`
      return ''
    },

    // *********************
    // 動画情報関連
    // *********************
    /** 動画リンク遷移 */
    goToProgramLink(
      context,
      meta,
      product = null,
      course = null,
      options = { autoPlay: true }
    ) {
      if (!context) throw 'Error. context required.'
      if (!context.history) throw 'Error. context.history required.'
      if (!context.falcorModel) throw 'Error. context.falcorModel required.'
      if (!context.routeHandler) throw 'Error. context.routeHandler required.'

      const isApp = this.isApp(context)
      let route = null

      if (meta) {
        let params = null
        let query = null
        let path = null

        switch (meta.meta_schema_id) {
          case META_SCHEMA_ID.EPISODE:
          case META_SCHEMA_ID.EPISODE_NOT_FREE:
          case META_SCHEMA_ID.LIVE:
          case META_SCHEMA_ID.LIVE_NOT_FREE:
            if (options.programLink && meta.is_grouped) {
              // 番組リンクの場合
              route = isApp ? routes.app_program : routes.program
            } else {
              // 通常(番組リンクでない場合)
              if (
                META_SCHEMA_ID.EPISODE === meta.meta_schema_id ||
                META_SCHEMA_ID.LIVE === meta.meta_schema_id
              ) {
                route = isApp
                  ? routes.app_catchupEpisode
                  : routes.catchupEpisode
              } else {
                route = isApp ? routes.app_episode : routes.episode
              }
              if (options.autoPlay) {
                query = { auto: 't' }
              }
            }
            params = {
              seriesId: meta.values.parents_series.id,
              seasonId: meta.values.parents_season.id,
              episodeId: meta.id || meta.meta_id
            }
            context.history.push(route.makePath(params, query))
            break

          case META_SCHEMA_ID.SEASON:
          case META_SCHEMA_ID.LIVE_SEASON:
            route = isApp ? routes.app_program : routes.program
            params = {
              seriesId: meta.values.parents_series.id,
              seasonId: meta.meta_id
            }
            context.history.push(route.makePath(params))
            break

          case META_SCHEMA_ID.SERIES:
          case META_SCHEMA_ID.LIVE_SERIES:
            // 最新シーズンの番組ページへ遷移する
            path = [['meta', 'children', meta.meta_id]]
            context.falcorModel.fetch(path).then((result) => {
              const children = _.get(
                result,
                ['json', 'meta', 'children', meta.meta_id],
                []
              )
              const latestSeason = children
                .filter((m) => META_SCHEMA_ID.SEASON === m.meta_schema_id)
                .sort((a, b) =>
                  Number(a.values.avails_SeasonNumber) <
                  Number(b.values.avails_SeasonNumber)
                    ? 1
                    : -1
                )[0]
              if (!latestSeason) return
              route = isApp ? routes.app_program : routes.program
              params = {
                seriesId: latestSeason.values.parents_series.id,
                seasonId: latestSeason.meta_id
              }
              context.history.push(route.makePath(params))
            })
            break

          default:
        }
      } else if (product) {
        route = isApp ? routes.app_pack : routes.pack
        context.history.push(
          route.makePath({ productId: product.product_id || product.id })
        )
      } else if (course) {
        route = isApp ? routes.app_plan : routes.plan
        context.history.push(route.makePath({ slug: course.slug }))
      }
    },

    /** 動画リンク取得 */
    getProgramLinkRoutes(
      context,
      meta,
      product = null,
      course = null,
      options = { autoPlay: true }
    ) {
      if (!context) throw 'Error. context required.'
      if (!context.history) throw 'Error. context.history required.'
      if (!context.falcorModel) throw 'Error. context.falcorModel required.'
      if (!context.routeHandler) throw 'Error. context.routeHandler required.'

      const isApp = this.isApp(context)
      let route = null

      if (meta) {
        let params = null
        let query = null
        let path = null

        switch (_.get(meta, ['meta_schema_id'])) {
          case META_SCHEMA_ID.EPISODE:
          case META_SCHEMA_ID.EPISODE_NOT_FREE:
          case META_SCHEMA_ID.LIVE:
          case META_SCHEMA_ID.LIVE_NOT_FREE:
            if (_.get(meta, ['is_grouped'])) {
              // 番組リンクの場合
              route = isApp
                ? _.get(routes, ['app_program'])
                : _.get(routes, ['program'])
            } else {
              // 通常(番組リンクでない場合)
              if (
                META_SCHEMA_ID.EPISODE === _.get(meta, ['meta_schema_id']) ||
                META_SCHEMA_ID.LIVE === _.get(meta, ['meta_schema_id'])
              ) {
                route = isApp
                  ? _.get(routes, ['app_catchupEpisode'])
                  : _.get(routes, ['catchupEpisode'])
              } else {
                route = isApp
                  ? _.get(routes, ['app_episode'])
                  : _.get(routes, ['episode'])
              }
              if (_.get(options, ['autoPlay'])) {
                query = { auto: 't' }
              }
            }
            params = {
              seriesId: _.get(meta, ['values', 'parents_series', 'id']) || '',
              seasonId: _.get(meta, ['values', 'parents_season', 'id']) || '',
              episodeId: _.get(meta, ['id']) || _.get(meta, ['meta_id']) || ''
            }
            if (
              route &&
              _.get(params, ['seriesId']) &&
              _.get(params, ['seasonId']) &&
              _.get(params, ['episodeId'])
            ) {
              return {
                route,
                params,
                query
              }
            }
            // context.history.push(route.makePath(params, query));
            break

          case META_SCHEMA_ID.SEASON:
          case META_SCHEMA_ID.LIVE_SEASON:
            route = isApp
              ? _.get(routes, ['app_program'])
              : _.get(routes, ['program'])
            params = {
              seriesId: _.get(meta, ['values', 'parents_series', 'id']) || '',
              seasonId: _.get(meta, ['meta_id']) || ''
            }
            if (
              route &&
              _.get(params, ['seriesId']) &&
              _.get(params, ['seasonId'])
            ) {
              return {
                route,
                params
              }
            }
            // context.history.push(route.makePath(params));
            break

          case META_SCHEMA_ID.SERIES:
          case META_SCHEMA_ID.LIVE_SERIES:
            /** [テスト] シリーズで使用されているページを確認 */
            return (async () => {
              // 最新シーズンの番組ページへ遷移する
              path = [['meta', 'children', meta.meta_id]]
              const result = await context.falcorModel.fetch(path)
              const children = _.get(
                result,
                ['json', 'meta', 'children', meta.meta_id],
                []
              )
              const latestSeason = children
                .filter((m) => META_SCHEMA_ID.SEASON === m.meta_schema_id)
                .sort((a, b) =>
                  Number(_.get(a, ['values', 'avails_SeasonNumber'])) <
                  Number(_.get(b, ['values', 'avails_SeasonNumber']))
                    ? 1
                    : -1
                )[0]
              if (!latestSeason) return
              route = isApp
                ? _.get(routes, ['app_program'])
                : _.get(routes, ['program'])
              params = {
                seriesId:
                  _.get(latestSeason, ['values', 'parents_series', 'id']) || '',
                seasonId: _.get(latestSeason, ['meta_id']) || ''
              }
              return {
                route,
                params
              }
              // context.falcorModel.fetch(path).then(result => {
              //   // context.history.push(route.makePath(params));
              // });
            })()
            break

          default:
        }
      } else if (product) {
        route = isApp ? _.get(routes, ['app_pack']) : _.get(routes, ['pack'])
        return {
          route,
          params: {
            productId: _.get(product, ['product_id']) || _.get(product, ['id'])
          }
        }
        // context.history.push(route.makePath({ productId: product.product_id || product.id }));
      } else if (course) {
        route = isApp ? _.get(routes, ['app_plan']) : _.get(routes, ['plan'])
        return {
          route,
          params: { slug: _.get(course, ['slug']) }
        }
        // context.history.push(route.makePath({ slug: course.slug }));
      }
    },

    //クレヨンしんちゃん番組ページのみ新規タブ移動
    goToProgramLinkBlank(
      context,
      meta,
      product = null,
      course = null,
      options = { autoPlay: true }
    ) {
      if (!context) throw 'Error. context required.'
      if (!context.history) throw 'Error. context.history required.'
      if (!context.falcorModel) throw 'Error. context.falcorModel required.'
      if (!context.routeHandler) throw 'Error. context.routeHandler required.'

      const isApp = this.isApp(context)
      let route = null

      if (meta) {
        let params = null
        let query = null
        let path = null

        switch (meta.meta_schema_id) {
          case META_SCHEMA_ID.EPISODE:
          case META_SCHEMA_ID.EPISODE_NOT_FREE:
          case META_SCHEMA_ID.LIVE:
          case META_SCHEMA_ID.LIVE_NOT_FREE:
            if (options.programLink && meta.is_grouped) {
              // 番組リンクの場合
              route = isApp ? routes.app_program : routes.program
            } else {
              // 通常(番組リンクでない場合)
              if (
                META_SCHEMA_ID.EPISODE === meta.meta_schema_id ||
                META_SCHEMA_ID.LIVE === meta.meta_schema_id
              ) {
                route = isApp
                  ? routes.app_catchupEpisode
                  : routes.catchupEpisode
              } else {
                route = isApp ? routes.app_episode : routes.episode
              }
              if (options.autoPlay) {
                query = { auto: 't' }
              }
            }
            params = {
              seriesId: meta.values.parents_series.id,
              seasonId: meta.values.parents_season.id,
              episodeId: meta.id || meta.meta_id
            }
            const domain = ['development', 'staging'].includes(
              process.env.NODE_ENV
            )
              ? 'https://st-douga.tvasahi.jp'
              : 'https://douga.tv-asahi.co.jp'
            window.open(
              `${domain}/program/${params.seriesId}-${params.seasonId}/${params.episodeId}?auto=t`
            )
            break

          case META_SCHEMA_ID.SEASON:
          case META_SCHEMA_ID.LIVE_SEASON:
            route = isApp ? routes.app_program : routes.program
            params = {
              seriesId: meta.values.parents_series.id,
              seasonId: meta.meta_id
            }
            context.history.push(route.makePath(params))
            break

          case META_SCHEMA_ID.SERIES:
          case META_SCHEMA_ID.LIVE_SERIES:
            // 最新シーズンの番組ページへ遷移する
            path = [['meta', 'children', meta.meta_id]]
            context.falcorModel.fetch(path).then((result) => {
              const children = _.get(
                result,
                ['json', 'meta', 'children', meta.meta_id],
                []
              )
              const latestSeason = children
                .filter((m) => META_SCHEMA_ID.SEASON === m.meta_schema_id)
                .sort((a, b) =>
                  Number(a.values.avails_SeasonNumber) <
                  Number(b.values.avails_SeasonNumber)
                    ? 1
                    : -1
                )[0]
              if (!latestSeason) return
              route = isApp ? routes.app_program : routes.program
              params = {
                seriesId: latestSeason.values.parents_series.id,
                seasonId: latestSeason.meta_id
              }
              context.history.push(route.makePath(params))
            })
            break

          default:
        }
      } else if (product) {
        route = isApp ? routes.app_pack : routes.pack
        context.history.push(
          route.makePath({ productId: product.product_id || product.id })
        )
      } else if (course) {
        route = isApp ? routes.app_plan : routes.plan
        context.history.push(route.makePath({ slug: course.slug }))
      }
    },

    /** タイトル */
    titles(meta, product = null, course = null) {
      if (meta) {
        if (meta.meta_schema_id === META_SCHEMA_ID.SEASON) {
          let title = _.get(meta, ['values', 'evis_FrontDisplayTitle'], '')
          return [title || meta.name, '']
        }

        let title = _.get(meta, ['values', 'evis_FrontDisplayTitle'], '')
        if (!title) {
          title = [
            _.get(meta, ['values', 'avails_SeriesTitleDisplayUnlimited'], ''),
            _.get(meta, ['values', 'avails_SeasonTitleDisplayUnlimited'], '')
          ]
            .join(' ')
            .trim()
        }
        // 更にタイトルが取れない場合は parent_season, parent_seriesの情報を見に行く
        if (!title) {
          title = [
            _.get(
              meta,
              [
                'values',
                'parents_series',
                'avails_SeriesTitleDisplayUnlimited'
              ],
              ''
            ),
            _.get(
              meta,
              [
                'values',
                'parents_season',
                'avails_SeasonTitleDisplayUnlimited'
              ],
              ''
            )
          ]
            .join(' ')
            .trim()
        }
        const subTitle = _.get(
          meta,
          ['values', 'avails_EpisodeTitleDisplayUnlimited'],
          ''
        )

        return [title, subTitle]
      }
      if (product) return [product.name, '']
      if (course) return [course.name, '']
      return ['', '']
    },

    /** 放送日 */
    onAirDate(meta, format = 'YYYY/M/D') {
      const availsReleaseHistoryOriginal = _.get(meta, [
        'values',
        'avails_ReleaseHistoryOriginal'
      ])
      if (!availsReleaseHistoryOriginal) return ''
      return moment(availsReleaseHistoryOriginal).format(format)
    },

    /** 配信日 */
    deliveryStartAt(meta, product, course, format = 'YYYY/M/D') {
      if (meta) {
        const deliveryStartAt = _.get(meta, ['delivery_start_at'])
        if (!deliveryStartAt) return ''
        return moment(deliveryStartAt).format(`${format}配信`)
      }
      return ''
    },

    /** 配信期間 */
    deliveryEndAt(meta, format = 'YYYY/M/D HH:mm') {
      if (!meta) return ''
      const deliveryEndAt = meta.delivery_end_at
      if (!deliveryEndAt) return ''
      const localizedFormat = deliveryEndAt.replace(/\//g, '-')
      return moment(localizedFormat).format(`${format}まで`)
    },

    /**
     * 指定された終了日までの残り日数を計算し、
     * その残り日数が指定された日数以内かどうかを判断
     */
    checkRemainingDays(endDateStr, days = 7) {
      const today = new Date()
      const endDate = new Date(endDateStr)

      // 残り日数を計算
      const diffTime = endDate - today
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      // 指定された日数以内かどうかのフラグを返す
      return diffDays <= days
    },

    /**
     * 視聴期限(あとn日)
     * 日数の区切りは24時間ではなく00:00
     * 例)配信期間が0:59迄の場合は0:00-0:59までが「本日まで」となる
     */
    remaining(context, dateStr, configArg = null) {
      if (!dateStr) return ''
      if (!context) throw 'Error. context required.'
      if (!context.models) throw 'Error. context.models required.'

      const config =
        configArg || context.models.config.data.extras.show_remaining_days
      const date = new Date(dateStr)
      const now = this.now(context)
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      // 期限切れ
      if (date < now) return ''

      // 当日
      if (date - today < 24 * 60 * 60 * 1000) {
        // 本日まで表記
        switch (config.today_type) {
          case 'today':
            return '本日まで'
          case 'hour': {
            const remainingHours = Math.floor((date - now) / 1000 / 60 / 60)
            return `あと${remainingHours}時間`
          }
          default:
        }
      }

      // 前日以前、または日数表示指定
      const remainingDays = Math.floor((date - today) / 1000 / 60 / 60 / 24)
      // あとn日表示対象期間でない場合
      if (config.period > -1 && config.period < remainingDays) return ''

      return `あと${remainingDays}日`
    },

    /** 視聴期限 */
    deadLine(
      context,
      limitDate,
      isCourse = false,
      format = 'YYYY/MM/DD HH:mm',
      showRemaining = true
    ) {
      if (!context) throw 'Error. context required.'
      if (!context.models) throw 'Error. context.models required.'
      if (!limitDate) return ''

      // 9999年の場合は表示しない
      if (limitDate.replace(/-/g, '/').startsWith('9999/')) return ``

      const now = this.now(context)
      const deadLine = new Date(limitDate.replace(/-/g, '/'))

      // 月額見放題の場合はlimit_dateに対し23:59を当て込む
      // https://logiclogic.backlog.jp/view/EX_DIO-1529#comment-1276517010
      if (isCourse) deadLine.setHours(23, 59, 59)
      const remaining = this.remaining(
        context,
        moment(deadLine).format('YYYY/MM/DD HH:mm:ss')
      )
      const suffix = isCourse ? '※自動更新' : ''
      const ymd = moment(deadLine).format(format)
      return now < deadLine && showRemaining
        ? `視聴期限：${ymd}まで（${remaining}）${suffix}`
        : `視聴期限：${ymd}まで`
    },

    /** 動画の時間表示 */
    duration(meta) {
      const duration = _.get(meta, ['values', 'duration'])
      if (!duration) return ''
      return this.formatDuration(duration)
    },

    /** シークバー割合 */
    progress(config, meta) {
      if (!meta || !window.localStorage) return 0

      const metaIdForPlayer = `${config.videocloud.reference_id_prefix || ''}${
        meta.meta_id
      }`
      const ls = getResumeInfo(metaIdForPlayer)
      if (!ls) return 0

      const progress =
        _.get(ls, ['lastPlayed']) === -1
          ? 100
          : Math.ceil((_.get(ls, ['lastPlayed']) / ls.duration) * 100)
      return progress
    },

    /** 残りn分 */
    rest(meta) {
      const duration = _.get(meta, ['values', 'duration'])
      if (!duration) return ''

      let rest = null
      if (window.localStorage) {
        const resume_info = getResumeInfo(meta.meta_id)
        rest = resume_info
          ? resume_info.duration - resume_info.lastPlayed
          : duration
      }
      if (rest === null) rest = duration
      return `残り${this.formatDuration(rest)}`
    },

    /**
     * 視聴済みか
     * @param onlyWatchedAll {bool} - 最後まで視聴したものを視聴済みとする
     */
    isWatched(meta, onlyWatchedAll = false) {
      if (!meta || !window.localStorage) return false
      const ls = getResumeInfo(meta.meta_id)

      if (!_.get(ls, ['lastPlayed'])) {
        return false
      }

      if (onlyWatchedAll) {
        // アプリのときすべて再生してもlastPlayedが-1にならない
        return (
          _.get(ls, ['lastPlayed']) === -1 ||
          _.get(ls, ['lastPlayed']) === _.get(ls, ['duration'])
        )
      }

      return !!_.get(ls, ['lastPlayed'])
    },

    /** NEW判定 */
    isNew(context, meta) {
      if (!context) throw 'Error. context required.'
      if (!context.models) throw 'Error. context.models required.'
      if (!meta) return false

      // グループメタは配信日持っていないが、非表示ということなので念の為break
      if (
        [META_SCHEMA_ID.SEASON, META_SCHEMA_ID.SERIES].includes(
          meta.meta_schema_id
        )
      ) {
        return false
      }

      const newPeriod = context.models.config.data.extras.show_new_period
      const deliveryStartAt = meta.delivery_start_at
      const diff = this.now(context) - new Date(deliveryStartAt)

      return diff < newPeriod * 24 * 60 * 60 * 1000
    },

    /** NEW表示判定 */
    showNew(meta) {
      if (!meta) return false
      return ![
        META_SCHEMA_ID.SEASON,
        META_SCHEMA_ID.SERIES,
        META_SCHEMA_ID.EPISODE
      ].includes(meta.meta_schema_id)
    },

    /** 価格 */
    price(meta, product, course, displayMode) {
      if (!meta) return null

      const productPrice = _.get(product, ['active_pricing', 'price'])
      const coursePrice = _.get(course, ['active_pricing', 'price'])

      switch (displayMode) {
        case EPISODE_DISPLAY_MODE.FREE:
        case EPISODE_DISPLAY_MODE.TVOD_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_FREE:
          return <Fragment>無料</Fragment>
        case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
          return <Fragment>{productPrice}</Fragment>
        case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
          return (
            <Fragment>
              {productPrice}
              <span>or</span>月額{coursePrice}円
            </Fragment>
          )
        case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
          return <Fragment>月額{coursePrice}円</Fragment>
        case EPISODE_DISPLAY_MODE.UNKNOWN:
        default:
          return null
      }
    },

    // *********************
    // 購入情報関連
    // *********************
    /** 価格算出 */
    calcPrice(howToPlays, metaIds) {
      if (!metaIds || !metaIds.length) return null

      const total = metaIds.reduce((summary, metaId) => {
        const products = _.get(howToPlays, [metaId, 'products']) || []
        const product = products.find(
          (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
        )
        const price = _.get(product, ['active_pricing', 'price'])
        return price ? summary + price : summary
      }, null)
      return total
    },

    /** パックの割引率算出 */
    calcPackDiscount(product) {
      if (!product) return null
      const price = _.get(product, ['active_pricing', 'price'])
      if (!price && price !== 0) return null
      const originalPrice = _.get(product, ['original_price'])
      if (!originalPrice) return null
      return Math.round(((originalPrice - price) / originalPrice) * 100)
    },

    /** 商品ラベル */
    productLabel(product, course) {
      if (product) {
        const productSchema = PRODUCT_SCHEMAS.find(
          (s) => s.id === product.schema_id
        )
        if (productSchema && productSchema !== PRODUCT_SCHEMA.SINGLE_STORY)
          return productSchema.label
      }
      if (course) return '見放題パック'
      return ''
    },

    feedLine(caption) {
      const regex = /(\r?\n)/g
      return caption.split(regex).map((line, i) => {
        return line.match(regex) ? <br key={i} /> : line
      })
    },

    getPlayerSettings(config, meta, displayMode) {
      const urlSchemeParams = {
        product_type: null,
        channel: null,
        ssai_ad_config_id: null,
        ssai_player_id: null,
        stvod_player_id: null,
        live_player_id: null,
        delivery_config_id: null
      }
      if (
        meta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE
      ) {
        urlSchemeParams.channel = 'ex'
        if (meta.meta_schema_id === META_SCHEMA_ID.EPISODE) {
          urlSchemeParams.ssai_ad_config_id = config.ssai_ad_config.avod
          urlSchemeParams.ssai_player_id = config.videocloud.ssai_player_id
          urlSchemeParams.delivery_config_id = config.delivery_config_id.avod
        } else {
          urlSchemeParams.ssai_ad_config_id =
            _.get(meta, ['values', 'target_ad_config_id']) || null
          urlSchemeParams.live_player_id = config.videocloud.live_player_id
          urlSchemeParams.delivery_config_id = config.delivery_config_id.avod
        }
      } else if (
        meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
      ) {
        urlSchemeParams.channel = 'douga_st'
        if (meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE) {
          urlSchemeParams.stvod_player_id = config.videocloud.stvod_player_id
          urlSchemeParams.delivery_config_id = config.delivery_config_id.stvod
        } else {
          urlSchemeParams.ssai_ad_config_id =
            _.get(meta, ['values', 'target_ad_config_id']) || null
          urlSchemeParams.live_player_id =
            config.videocloud.stvod_live_player_id
          urlSchemeParams.stvod_player_id = config.videocloud.stvod_player_id
          urlSchemeParams.delivery_config_id = config.delivery_config_id.stvod
        }
      }
      switch (displayMode) {
        case EPISODE_DISPLAY_MODE.FREE:
          urlSchemeParams.product_type = 1
          break
        case EPISODE_DISPLAY_MODE.TVOD_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_FREE:
          urlSchemeParams.product_type = 2
          break
        case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
          urlSchemeParams.product_type = 3
          break
        default:
          break
      }
      return urlSchemeParams
    },

    getDisplayMode(meta, product, course) {
      let displayMode = null

      if (!meta) return displayMode

      if (
        META_SCHEMA_ID.EPISODE === meta.meta_schema_id ||
        META_SCHEMA_ID.LIVE === meta.meta_schema_id
      ) {
        // スキーマが無料だったら無条件で無料
        displayMode = EPISODE_DISPLAY_MODE.FREE
        // console.log("無料")
      } else if (
        META_SCHEMA_ID.EPISODE_NOT_FREE === meta.meta_schema_id ||
        META_SCHEMA_ID.LIVE_NOT_FREE === meta.meta_schema_id
      ) {
        if (!course) {
          if (product) {
            // TVODが設定されていた場合はTVODのactive_pricingが0なら無料
            const isNotFree =
              _.get(product, ['active_pricing', 'price']) != null &&
              _.get(product, ['active_pricing', 'price']) > 0
            if (!isNotFree) {
              displayMode = EPISODE_DISPLAY_MODE.TVOD_FREE
              // console.log("TVOD 無料")
            } else {
              displayMode = EPISODE_DISPLAY_MODE.TVOD_NOT_FREE
              // console.log("TVOD 有料")
            }
          } else {
            // courseもproductも両方入っていない場合は、買わせない
            displayMode = EPISODE_DISPLAY_MODE.UNKNOWN
            // console.log("いずれでもない。有料")
          }
        } else {
          // SVODに含まれていた場合
          switch (parseInt(meta.values.svod_delivery_pattern)) {
            case SVOD_DELIVERY_PATTERN.FREE:
              // 無料設定がされていた場合は強制的に無料
              displayMode = EPISODE_DISPLAY_MODE.SVOD_FREE
              // console.log("SVOD 無料")
              break
            case SVOD_DELIVERY_PATTERN.AUTH_FREE:
              // ログイン時無料設定がされていた場合はログイン済みなら無料
              const isLoggedIn = this.isLoggedIn(this.context)
              if (isLoggedIn) {
                displayMode = EPISODE_DISPLAY_MODE.SVOD_FREE
                // console.log("SVOD 会員無料")
              } else {
                // ログインしていないなら有料判定させる
                displayMode = EPISODE_DISPLAY_MODE.SVOD_NOT_FREE
                // console.log("SVOD 有料")
              }
              break
            default:
              const coursePrice = _.get(course, ['active_pricing', 'price'])
              if (product) {
                // TVODが設定されていた場合はTVODのactive_pricingが0なら無料
                const productPrice = _.get(product, ['active_pricing', 'price'])
                if (productPrice === 0) {
                  // SVODに含まれるTVOD0円はSVOD契約で見れる
                  displayMode = EPISODE_DISPLAY_MODE.SVOD_NOT_FREE
                  // console.log("SVOD/TVOD 併売 TVOD無料");
                } else {
                  // SVODに含まれるTVODに金額が設定されていた場合は、TVOD単独で購入させることができる
                  displayMode = EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE
                  // console.log("SVOD/TVOD 併売 TVOD有料");
                }
              } else {
                // TVODが設定されていない場合は、SVODのactive_pricingが0なら無料
                if (coursePrice === 0) {
                  displayMode = EPISODE_DISPLAY_MODE.SVOD_FREE
                  // console.log("SVOD/TVOD 併売 SVOD無料")
                } else {
                  displayMode = EPISODE_DISPLAY_MODE.SVOD_NOT_FREE
                  // console.log("SVOD/TVOD 併売 SVOD有料")
                }
              }
              break
          }
        }
      }
      return displayMode
    },

    /** プレビュー環境の時間偽装に対応した現在時刻 */
    now(context) {
      if (!context) throw 'Error. context required.'
      if (!context.models) throw 'Error. context.models required.'
      const config = context.models.config.data
      if (
        process.env.BROWSER &&
        config.preview_url_list.indexOf(window.location.hostname) !== -1
      ) {
        const rubocopTimeZoneName =
          window.location.hostname + config.cookie_rubocop_prefix
        const rubocopTimeZone = Cookie.get(rubocopTimeZoneName)
        if (rubocopTimeZone) {
          return new Date(rubocopTimeZone)
        }
      }
      return new Date()
    },

    /** 販売期間内判定 */
    isOnSale(context, product, now) {
      if (!product) return false
      if (!context) throw 'Error. context required.'
      if (!context.models) throw 'Error. context.models required.'

      const wNow = now || this.now(context)
      const salesStartAt = _.get(product, ['sales_start_at'])
      const salesEndAt = _.get(product, ['sales_end_at'])
      return (
        !moment(wNow).isBefore(salesStartAt) &&
        !moment(wNow).isAfter(salesEndAt)
      )
    },

    /**
     * 基盤host判定
     * iframeの基盤画面については、DIO側がプレビュー環境の場合は基盤画面もプレビュー環境とする。
     * https://logiclogic.backlog.jp/view/EX_DIO-1865
     */
    infraHost(context) {
      if (!context) throw 'Error. context required.'
      if (!context.models) throw 'Error. context.models required.'

      const host = _.get(window, ['location', 'host']) || ''
      const isPreview = context.models.config.data.preview_url_list.includes(
        host
      )
      return isPreview
        ? context.models.config.data.preview_infra_host
        : context.models.config.data.infra_host
    },

    /**
     * douga_mv host判定
     * 静的リンク等のURLはDIO側がプレビュー環境の場合は基盤画面もプレビュー環境とする。
     */
    dougaMvHost(context) {
      if (!context) throw 'Error. context required.'
      if (!context.models) throw 'Error. context.models required.'

      const host = _.get(window, ['location', 'host']) || ''
      const isPreview = context.models.config.data.preview_url_list.includes(
        host
      )
      return isPreview
        ? context.models.config.data.preview_douga_mv_host
        : context.models.config.data.douga_mv_host
    },

    rootUrl() {
      return window && window.location
        ? `${window.location.protocol}//${window.location.host}`
        : ''
    },

    /**
     * 指定したサイズの画像パスを返す
     * @param strUrl: String (require) サムネイルURL文字列
     * @param keySize: String[large|medium|small|origin] (default: '') 返却したいサイズ Dio上で生成されるサイズに準じて
     * @returns {string}
     */
    customSizeImageUrl(strUrl, keySize) {
      if (!strUrl) return ''

      // パターンを指定
      const sizePattern = {
        small: 'small',
        medium: 'medium',
        large: 'large',
        origin: ''
      }

      // パターンに一致していれば文字列を挿入、一致しない場合は空白文字を挿入
      const strSize = sizePattern[keySize] || ''

      // originを指定した場合、パターンに一致しない場合はオリジナルのURLを返す
      if (strSize === '') {
        return strUrl
      }

      const strExtension = String(strUrl.split('.').slice(-1)) // 拡張子 (ex: jpg, png ...)
      const strFilename = strUrl
        .split('.')
        .slice(0, -1)
        .join('.') // ドメイン等を含むファイル名 (https://douga.tv-asahi.co.jp/uploads/attachment/file/xxxxx)

      // 拡張子のあり/なしでサイズ(String)を挿入する位置が違うため出し分け
      const thumbnailUrl =
        strExtension !== ''
          ? `${strFilename}_${strSize}.${strExtension}`
          : `${strUrl}_${strSize}`

      // サムネイルURL(String)を返す
      return thumbnailUrl
    },

    // *********************
    // チャットbot関連
    // *********************
    /** チャットbotの初期化
     * @param {Window} c - windowを指定する。
     * @description https://supportbot-admin.userlocal.jp/pdf/UL_chatbot_widget_manual.pdf
     */
    initializeChatBot(window) {
      const n = 'ul_widget'
      const s = 'https://support-widget.userlocal.jp'
      if (window[n] === void 0) {
        window['ULObject'] = n
        window[n] =
          window[n] ||
          function() {
            ;(window[n].q = window[n].q || []).push(arguments)
          }
        window[n].l = 1 * new Date()
        var e = document.createElement('script')
        e.async = 1
        e.src = s + '/chatbot.js'
        var t = document.getElementsByTagName('script')[0]
        t.parentNode.insertBefore(e, t)
      }

      ul_widget('init', { id: 'f6c2ee155d43fdfc2ab5', lg_id: '' })
      ul_widget('show')
    },
    /** コンポーネントのunmount時に実行する関数
     * SPAの場合これを実行しないとページ切り替えをしても残ってしまう。
     * @param {Window} c - windowを指定する。
     * @description https://supportbot-admin.userlocal.jp/pdf/UL_chatbot_widget_manual.pdf
     */
    unmountChatBot(window) {
      ul_widget('hide')
      // 以下コードはiOSの場合、display: block;がvisibility: hidden;
      // を上書きしてしまうようなので必要。
      const chatBotButton = window.document.getElementsByClassName(
        'ul-widget-main-window'
      )
      if (chatBotButton[0]) chatBotButton[0].style.display = 'none'
    },

    /** IPアドレスの取得 */
    async getIp() {
      const res = await fetch('https://ipinfo.io?callback')
      const { ip = '' } = await res.json()
      return ip
    },

    /** タイムスタンプの取得 */
    getTimeStamp() {
      const _date = new Date()
      const year = _date.getFullYear()
      const month = `0${_date.getMonth() + 1}`.slice(-2)
      const date = `0${_date.getDate()}`.slice(-2)
      const hours = `0${_date.getHours()}`.slice(-2)
      const minutes = `0${_date.getMinutes()}`.slice(-2)
      const seconds = `0${_date.getSeconds()}`.slice(-2)

      return `${year}${month}${date}${hours}${minutes}${seconds}`
    }
  }
}

export default exdioWebAppUtils
