import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import useIsMounted from '../../../../hooks/useIsMounted'
import browserInfo from '../../../../../sketch-platform/utils/browserInfo'

/* components */
import { Link } from '../../../../../sketch-platform/ui/routing'
// import Button1 from './Button1'
import HowToPlayLink from '../details/HowToPlayLink'

/** style */
import {
  mediaPc,
  mediaSp,
  contentWidth,
  white,
  black,
  blue,
  darkBlue,
  green,
  darkGreen,
  yellow,
  darkYellow,
  pink,
  darkPink
} from './styles'
import { mediaQuery } from '../../style'

/* しんちゃん メインヴィジュアル */
const MainVisual = (
  { isLoggedIn = false, isPurchased = false, theme = 'normal', ...props },
  context
) => {
  const config = context.models.config.data
  const isMounted = useIsMounted()
  const isApp = webApp.utils.isApp(context) // アプリ判別

  const deviceInfo = browserInfo(navigator.userAgent, (data) => data)
  const isIPadOS =
    deviceInfo.isMac &&
    global.navigator.platform === 'MacIntel' &&
    typeof global.navigator.standalone !== 'undefined'
  const isIOS = deviceInfo.isIOS || isIPadOS
  const slug = 'shinchan'
  const loginBtnProps = {
    route: isApp ? routes.app_login : routes.login,
    query: {
      redirect: isApp
        ? routes.app_plan.makePath({ slug })
        : routes.plan.makePath({ slug })
    }
  }

  const spMain = `/douga_mv/shinchan/svod/images/main/${theme}/sp_main.png`
  const spMainBatch = `/douga_mv/shinchan/svod/images/main/${theme}/sp_main_batch.png`

  if (!isMounted) return null

  return (
    <>
      <StyledTest />
      <StyledDiv1 theme={theme} {...props}>
        <StyledImg4
          src={!isApp && !isPurchased ? spMainBatch : spMain}
          width="375"
          height="302"
          alt="テレ朝動画では約2000話(※2024年4月現在)のおはなしを配信中!"
          className="u-hide--pc"
        />
        <StyledInner1 theme={theme}>
          {!isApp && !isPurchased && (
            <StyledImg1
              src="/douga_mv/shinchan/svod/images/main/key_batch.png"
              width="140"
              height="110"
              alt="月額550円(税込)"
              className="u-hide--sp"
            />
          )}
          <StyledImg2
            src="/douga_mv/shinchan/svod/images/main/logo.png"
            width="220"
            height="71"
            alt="毎週土曜日 ごご4:30放送 クレヨンしんちゃん"
            className="u-hide--sp"
          />
          <StyledImg3
            src="/douga_mv/shinchan/svod/images/main/key_txt.png"
            width="490"
            height="110"
            alt="テレ朝動画では約2000話(※2024年4月現在)のおはなしを配信中!"
            className="u-hide--sp"
          />
          <StyledUl>
            {!isLoggedIn && (
              <StyledLi1>
                <StyledLink {...loginBtnProps} type="green">
                  ログイン
                </StyledLink>
              </StyledLi1>
            )}
            {!isApp && !isPurchased && (
              <StyledLi1>
                <StyledLink href={config.purchase_plan.shinchan} type="blue">
                  購入する
                </StyledLink>
              </StyledLi1>
            )}
            {(!isApp || (isApp && !isIOS)) && (
              <StyledLi2>
                <StyledButton1
                  href="https://www.tv-asahi.co.jp/redirect/?site=shinchan_lp"
                  type="pink"
                  line={2}
                >
                  ぶりぶりCLUB
                  <br className="u-hide--pc" />
                  について
                </StyledButton1>
              </StyledLi2>
            )}
            {isApp && <HowToPlayLink isPlanPage />}
          </StyledUl>
        </StyledInner1>
      </StyledDiv1>
    </>
  )
}

export default memo(MainVisual)

MainVisual.propTypes = {
  isLoggedIn: PropTypes.bool,
  isPurchased: PropTypes.bool,
  theme: PropTypes.oneOf([
    'normal',
    'spring',
    'rainy',
    'summer',
    'autumn',
    'winter',
    'movie',
    'buriburiclub'
  ])
}

MainVisual.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}

// 1つ目のスタイルが反映されないため、仮設置
const StyledTest = styled.span`
  display: none;
`

const StyledDiv1 = styled.div`
  position: relative;
  overflow: hidden;

  ${({ theme }) => {
    switch (theme) {
      /** 春テーマ */
      case 'spring':
        return css`
          background: #fdeff1
            url(/douga_mv/shinchan/svod/images/main/spring/bg.png) repeat-x top
            center / auto 250px;

          ${mediaQuery()} {
            background: #b3ea57
              url(/douga_mv/shinchan/svod/images/main/bg-pattern.png);
          }
        `

      /** 梅雨テーマ */
      case 'rainy':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/rainy/bg.png)
              repeat-x top center / auto 250px,
            linear-gradient(#ff8cce, #fdc0d4);
          ${mediaQuery()} {
            background: #95d6ff
              url(/douga_mv/shinchan/svod/images/main/bg-pattern.png);
          }
        `

      /** 夏テーマ */
      case 'summer':
        return css`
          background: #2fa4e4
            url(/douga_mv/shinchan/svod/images/main/summer/bg.png) repeat-x top
            center / auto 250px;

          ${mediaQuery()} {
            background: #f5e2a6
              url(/douga_mv/shinchan/svod/images/main/bg-pattern.png);
          }
        `

      /** 秋テーマ */
      case 'autumn':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/autumn/bg.png)
              repeat-x top center / auto 176px,
            linear-gradient(#ff8c40, #ffdd40 23%, #ffffff);

          ${mediaQuery()} {
            background: #ffb640
              url(/douga_mv/shinchan/svod/images/main/bg-pattern.png);
          }
        `

      /** 冬テーマ */
      case 'winter':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/winter/bg.png)
              repeat-x top center / auto 250px,
            linear-gradient(#40b7e9, #3892d7);

          ${mediaQuery()} {
            background: #dbecf6
              url(/douga_mv/shinchan/svod/images/main/bg-pattern.png);
          }
        `

      /** 映画テーマ */
      case 'movie':
        return css`
          background: #fdd10b;

          ${mediaQuery()} {
            background: #feef89
              url(/douga_mv/shinchan/svod/images/main/bg-pattern.png);
          }
        `

      /** ぶりぶりclub */
      case 'buriburiclub':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/buriburiclub/bg.png)
              repeat-x top center / auto 250px,
            #d3f3ff;

          ${mediaQuery()} {
            background: #acffa0
              url(/douga_mv/shinchan/svod/images/main/bg-pattern.png);
          }
        `

      default:
        return null
    }
  }}

  .u-hide--sp {
    ${mediaQuery()} {
      display: none !important;
    }
  }

  .u-hide--pc {
    ${mediaQuery('sm', 'min')} {
      display: none !important;
    }
  }
`

const StyledInner1 = styled.div`
  margin: 0 auto;
  max-width: ${contentWidth};
  height: 250px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;

  ${({ theme }) => {
    switch (theme) {
      /** 春テーマ */
      case 'spring':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/spring/main.png)
            no-repeat bottom center / cover;
        `

      /** 梅雨テーマ */
      case 'rainy':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/rainy/main.png)
            no-repeat bottom center / cover;
        `

      /** 夏テーマ */
      case 'summer':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/summer/main.png)
            no-repeat bottom center / cover;
        `

      /** 秋テーマ */
      case 'autumn':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/autumn/main.png)
            no-repeat bottom center / cover;
        `

      /** 冬テーマ */
      case 'winter':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/winter/main.png)
            no-repeat bottom center / cover;
        `

      /** 映画テーマ */
      case 'movie':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/movie/main.png)
            no-repeat bottom center / cover;
          box-shadow: none;
        `

      /** ぶりぶりclub */
      case 'buriburiclub':
        return css`
          background: url(/douga_mv/shinchan/svod/images/main/buriburiclub/main.png)
            no-repeat bottom center / cover;
        `

      default:
        return null
    }
  }}

  ${mediaQuery()} {
    padding: 10px 10px 0;
    width: auto;
    height: auto;
    background: none;
    box-shadow: none;
    position: static;
  }
`

const StyledImg1 = styled.img`
  display: block;
  position: absolute;
  top: 30px;
  left: 60px;
`

const StyledImg2 = styled.img`
  position: absolute;
  bottom: 10px;
  left: 10px;
`

const StyledImg3 = styled.img`
  margin: 0 auto;
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
`

const StyledImg4 = styled.img`
  ${mediaQuery()} {
    width: 100%;
  }
`

const StyledUl = styled.ul`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;

  ${mediaQuery()} {
    display: block;
    position: static;
  }
`

const StyledLi1 = styled.li`
  margin-top: 25px;
  padding-bottom: 5px; // Button1のbox-shadow分

  ${mediaQuery()} {
    margin-top: 10px;
  }

  &:first-child {
    margin-top: 0;
  }
`

const StyledLi2 = styled(StyledLi1)`
  // MV画像アスペクト比 375 / 302

  ${mediaQuery()} {
    --width: 375;
    --height: 302;
    --heightPerWidth: var(--height) / var(--width);
    margin-top: 0;
    width: calc(70 / var(--width) * 100vw); // 幅375pxのとき70px
    position: absolute;
    // 高さ302pxのとき175px
    top: calc((175 / var(--height)) * (var(--heightPerWidth) * 100vw));
    right: calc(10 / var(--width) * 100vw); // 幅375pxのとき10px
  }
`

const typeBlue = css`
  background: ${blue};
  box-shadow: ${darkBlue} 0 5px 0 0px;

  &:hover {
    ${mediaQuery('sm', 'min')} {
      box-shadow: ${blue} 0 0 0 0px;
    }
  }
`

const typeGreen = css`
  background: ${green};
  box-shadow: ${darkGreen} 0 5px 0 0px;

  &:hover {
    ${mediaQuery('sm', 'min')} {
      box-shadow: ${green} 0 0 0 0px;
    }
  }
`

const typeYellow = css`
  color: ${black};
  background: ${yellow};
  box-shadow: ${darkYellow} 0 5px 0 0px;

  &:link,
  &:visited,
  &:active {
    color: ${black};
  }

  &:hover {
    ${mediaQuery('sm', 'min')} {
      box-shadow: ${yellow} 0 0 0 0px;
    }
  }
`

const typePink = css`
  color: ${white};
  background: ${pink};
  box-shadow: ${darkPink} 0 5px 0 0px;

  &:hover {
    ${mediaQuery('sm', 'min')} {
      box-shadow: ${pink} 0 0 0 0px;
    }
  }
`
// Button1の内容
export const StyledLink = styled(Link)`
  width: 220px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${white};
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;

  ${({ line }) => {
    switch (line) {
      case 2:
        return css`
          height: 60px;
          border-radius: 30px;
        `
      default:
        return css`
          height: 45px;
          border-radius: 23px;
        `
    }
  }}

  ${mediaQuery()} {
    padding: 0;
    width: 100%;
    font-size: 2rem;
  }

  &:link,
  &:visited,
  &:active {
    color: ${white};
  }

  &:hover {
    ${mediaQuery('sm', 'min')} {
      transform: translateY(5px);
    }
  }

  ${({ type }) => {
    switch (type) {
      case 'blue':
        return typeBlue
      case 'green':
        return typeGreen
      case 'yellow':
        return typeYellow
      case 'pink':
        return typePink
      default:
        return null
    }
  }}
`

// const StyledButton1 = styled(Button1)
const StyledButton1 = styled(StyledLink)`
  ${mediaQuery()} {
    // 高さ302pxのとき70px
    height: calc((70 / var(--height)) * (var(--heightPerWidth) * 100vw));
    border-radius: 50%;
    // 幅375pxのとき9px
    font-size: calc(9 / var(--width) * 100vw);
  }
`

const StyledHowToPlayLink = styled(HowToPlayLink)`
  margin-top: 13px;
`
