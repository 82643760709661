import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

/* style */
import {
  StyledLi,
  StyledLink,
  StyledImg,
  StyledDiv,
  StyledSpan,
  StyledDl,
  StyledDt,
  StyledDd,
  StyledTime
} from './style'

/** Logirlプランページ | リスト5 */
const List5Item = ({ article }) => {
  /**
   * WPで変換される数値参照文字をデコードして置換する
   * @param {String} str
   * @return {String} 置換後の文字列
   */
  const decode10 = (str) => {
    let tmpStr = str
    /** 数値参照文字を正規表現で抜き出し、重複を削除 */
    const numCharRefArr = Array.from(new Set(str.match(/&#\d+;/g)))

    /** デコード後、置換 */
    numCharRefArr.forEach((char) => {
      let replacedChar = ''

      /** &#160; は改行コード(&nbsp;)なので空文字列のまま変換する */
      if (char !== '&#160;') {
        replacedChar = char.replace('&#', '')
        replacedChar = replacedChar.replace(';', '')
        replacedChar = String.fromCharCode(replacedChar)
      }
      tmpStr = tmpStr.replace(new RegExp(char, 'g'), replacedChar)
    })
    return tmpStr
  }

  return (
    <StyledLi>
      <StyledLink href={_.get(article, ['link', 0])} data-chromatic="ignore">
        <StyledDiv>
          <StyledImg
            // chromaticで画像の変更を検知しないための設定
            data-chromatic="ignore"
            src={`https://www.tv-asahi.co.jp${_.get(article, [
              'thumbnail',
              0
            ])}`}
            width="279"
            height="146"
            alt=""
            loading="lazy"
            className="c-list5__img"
          />
        </StyledDiv>
        <StyledSpan>{_.get(article, ['category'])}</StyledSpan>
        <StyledDl>
          <StyledDt>{_.get(article, ['title', 0])}</StyledDt>
          <StyledDd>{decode10(_.get(article, ['description']))}</StyledDd>
        </StyledDl>
        <StyledTime dateTime={_.get(article, ['date'])}>
          {_.get(article, ['date'])}
        </StyledTime>
      </StyledLink>
    </StyledLi>
  )
}

export default List5Item

List5Item.propTypes = {
  /** 描画する記事のデータ */
  article: PropTypes.shape({
    date: PropTypes.string,
    date_time: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
      0: PropTypes.string
    }),
    thumbnail: PropTypes.shape({
      0: PropTypes.string
    }),
    title: PropTypes.shape({
      0: PropTypes.string
    })
  }).isRequired
}

List5Item.defaultProps = {
  article: {
    date: '',
    date_time: '',
    description: '',
    link: { 0: '' },
    thumbnail: { 0: '' },
    title: { 0: '' }
  }
}
