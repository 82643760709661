import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** お支払い方法 */
export default class AccountPayment extends Component {
  static propTypes = {};
  static defaultProps = {};
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};

    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit() {}

  goBack(e) {
    e.preventDefault();
    this.context.history.goBack();
  }

  render() {
    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <div className="c-mypageAccount">
            <header className="c-mypageAccount-header">
              <a href="#" className="c-mypage-btnBack alwaysOn" onClick={this.goBack}>
                戻る
              </a>
              <h3 className="c-mypageAccount-header-title">お支払い方法</h3>
            </header>
            <div className="c-mypageAccount-cont">
              <div className="c-mypageAccount-cont-personal">
                <div className="c-mypageAccountEditCard">
                  <div className="c-mypageAccountEditCard-cardCurrent">
                    <dl className="c-mypageAccountEditCard-cardCurrent-inBox">
                      <dt className="c-mypageAccountEditCard-cardCurrent-inBox-title">カード番号（登録済み）</dt>
                      <dd className="c-mypageAccountEditCard-cardCurrent-inBox-card">1234-56**-****-**00</dd>
                    </dl>
                  </div>
                  <div className="c-mypageAccountEditCard-cardNext">
                    <h4 className="c-mypageAccountEditCard-cardNext-title require">カード会社</h4>
                    <div className="c-mypageAccountEditCard-cardNext-input company">
                      <div className="c-pulldown">
                        <div className="c-pulldown-current">VISA</div>
                        <div className="c-pulldown-menu">
                          <ul className="c-pulldown-menu-inBox">
                            <li className="c-pulldown-menu-inBox-btn current">VISA</li>
                            <li className="c-pulldown-menu-inBox-btn">Mastercard</li>
                            <li className="c-pulldown-menu-inBox-btn">JCB</li>
                            <li className="c-pulldown-menu-inBox-btn">AMERICAN EXPRESS</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <h4 className="c-mypageAccountEditCard-cardNext-title require">カード番号</h4>
                    <div className="c-mypageAccountEditCard-cardNext-input number">
                      <input type="text" name="cardNum" placeholder="000000000000" className="c-inputText" />
                    </div>
                    <p className="c-mypageAccountEditCard-cardNext-attention">半角数字 スペース、ハイフン不要</p>
                    <h4 className="c-mypageAccountEditCard-cardNext-title require">カード名義</h4>
                    <div className="c-mypageAccountEditCard-cardNext-input name">
                      <input type="text" name="cardName" placeholder="TARO ROPPONGI" className="c-inputText" />
                    </div>
                    <p className="c-mypageAccountEditCard-cardNext-attention">半角英数字</p>
                    <h4 className="c-mypageAccountEditCard-cardNext-title require">有効期限</h4>
                    <div className="c-mypageAccountEditCard-cardNext-input expiration">
                      <ul className="c-mypageAccountEditCard-cardNext-input-btns">
                        <li className="c-mypageAccountEditCard-cardNext-input-btns-month">
                          <div className="c-pulldown">
                            <div className="c-pulldown-current">月</div>
                            <div className="c-pulldown-menu">
                              <ul className="c-pulldown-menu-inBox">
                                <li className="c-pulldown-menu-inBox-btn current">1</li>
                                <li className="c-pulldown-menu-inBox-btn">2</li>
                                <li className="c-pulldown-menu-inBox-btn">3</li>
                                <li className="c-pulldown-menu-inBox-btn">4</li>
                                <li className="c-pulldown-menu-inBox-btn">5</li>
                                <li className="c-pulldown-menu-inBox-btn">6</li>
                                <li className="c-pulldown-menu-inBox-btn">7</li>
                                <li className="c-pulldown-menu-inBox-btn">8</li>
                                <li className="c-pulldown-menu-inBox-btn">9</li>
                                <li className="c-pulldown-menu-inBox-btn">10</li>
                                <li className="c-pulldown-menu-inBox-btn">11</li>
                                <li className="c-pulldown-menu-inBox-btn">12</li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="c-mypageAccountEditCard-cardNext-input-btns-separate">/</li>
                        <li className="c-mypageAccountEditCard-cardNext-input-btns-year">
                          <div className="c-pulldown">
                            <div className="c-pulldown-current">年</div>
                            <div className="c-pulldown-menu">
                              <ul className="c-pulldown-menu-inBox">
                                <li className="c-pulldown-menu-inBox-btn">2019年</li>
                                <li className="c-pulldown-menu-inBox-btn">2018年</li>
                                <li className="c-pulldown-menu-inBox-btn">2017年</li>
                                <li className="c-pulldown-menu-inBox-btn">2016年</li>
                                <li className="c-pulldown-menu-inBox-btn">2015年</li>
                                <li className="c-pulldown-menu-inBox-btn">2014年</li>
                                <li className="c-pulldown-menu-inBox-btn">2013年</li>
                                <li className="c-pulldown-menu-inBox-btn">2012年</li>
                                <li className="c-pulldown-menu-inBox-btn">2011年</li>
                                <li className="c-pulldown-menu-inBox-btn">2010年</li>
                                <li className="c-pulldown-menu-inBox-btn">2009年</li>
                                <li className="c-pulldown-menu-inBox-btn">2008年</li>
                                <li className="c-pulldown-menu-inBox-btn">2007年</li>
                                <li className="c-pulldown-menu-inBox-btn">2006年</li>
                                <li className="c-pulldown-menu-inBox-btn">2005年</li>
                                <li className="c-pulldown-menu-inBox-btn">2004年</li>
                                <li className="c-pulldown-menu-inBox-btn">2003年</li>
                                <li className="c-pulldown-menu-inBox-btn">2002年</li>
                                <li className="c-pulldown-menu-inBox-btn">2001年</li>
                                <li className="c-pulldown-menu-inBox-btn">2000年</li>
                                <li className="c-pulldown-menu-inBox-btn">〜〜</li>
                                <li className="c-pulldown-menu-inBox-btn">1900年</li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <p className="c-mypageAccountEditCard-cardNext-attention">カードの表記に合わせてご入力ください</p>
                    <h4 className="c-mypageAccountEditCard-cardNext-title require">セキュリティコード</h4>
                    <div className="c-mypageAccountEditCard-cardNext-input security">
                      <input type="text" name="mail" className="c-inputText" />
                      <p className="c-mypageAccountEditCard-cardNext-input-aboutSecurityCode">セキュリティコードとは</p>
                    </div>
                    <div className="c-modal m-securityCode">
                      <div className="c-modal-inBox">
                        <div className="c-modal-inBox-close" />
                        <div className="c-modal-inBox-cont">
                          <div className="securityCode">
                            <h5 className="securityCode-title">セキュリティコードとは</h5>
                            <div className="securityCode-cont">
                              <div className="securityCode-cont-imgs">
                                <img src="images/securitycode.png" />
                              </div>
                              <div className="securityCode-cont-text">
                                セキュリティコードとはクレジットカード背面に記載されている3桁の番号です。<br />
                                ※アメリカンエキスプレスカードはクレジットカードの前面に記載されている4桁の番号です。
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="c-mypageAccountEditCard-btnSave">
                    <a href="#" className="c-mypageAccountEditCard-btnSave-link" onClick={this.onSubmit}>
                      変更する
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
