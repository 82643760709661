import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ProgramItemSkeleton from '../ProgramItemSkeleton'

const CardSkelton = ({ number = 6 }) => {
  const [skeletonObjects, setSkeltonObjects] = useState(null)

  useEffect(() => {
    let _skeletonObjects = []
    for (let i = 0; i < number; i++) {
      _skeletonObjects.push(
        <ProgramItemSkeleton key={i} hasThumb={true} titleLength={2} />
      )
    }
    setSkeltonObjects(_skeletonObjects)
  }, [])

  return skeletonObjects
}

export default CardSkelton

CardSkelton.propTypes = {
  number: PropTypes.number
}
