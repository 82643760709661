import styled, { keyframes } from 'styled-components'
import { mediaQuery } from '../../../../style'

const katakata = keyframes`
0% {transform:translate(0, 0) rotate(0deg);}
21% {transform:translate(0, 0) rotate(0deg);}

22% {transform:translate(0, 0) rotate(-3deg);}
23% {transform:translate(0, -1px) rotate(0deg);}
24% {transform:translate(0, -1px) rotate(3deg);}

25% {transform:translate(1px, 0) rotate(-2deg);}
26% {transform:translate(0, 1px) rotate(0deg);}
27% {transform:translate(0, 0) rotate(3deg);}

28% {transform:translate(0, 0) rotate(0deg);}
100% {transform:translate(0, 0) rotate(0deg);}
`

export const StyledUl = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  position: relative;
  z-index: 1;

  ${mediaQuery()} {
    grid-template-columns: 1fr 1fr;
    gap: 5px;
  }

  &.lazyloaded::after {
    width: 116px;
    height: 41px;
    background: url(/images/exdio/renewal/gariben_daigaku/components/list10_img1.webp)
      no-repeat center / 100% auto;
    position: absolute;
    top: -24px;
    right: -9px;
    animation: ${katakata} 6s infinite ease-in-out alternate;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));
    content: '';

    ${mediaQuery()} {
      width: 52px;
      height: 20px;
      top: -30px;
      right: -2px;
    }
  }
`

export const StyledLi = styled.li`
  filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.3));
`
