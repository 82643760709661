import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

export default class HtmlSnippet extends React.Component {
  static propTypes = {
    snippetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    className: PropTypes.string
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object
  }

  static defaultProps = {
    className: ''
  }

  static getPaths(models, options, props = {}) {
    const rootPath = this.getRootPath(models, options, props)
    return [rootPath.concat([['content', 'javascript', 'style']])]
  }

  static getRootPath(models, options, props = {}) {
    return props.snippetId ? ['htmlSnippets', props.snippetId] : []
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    const rootPath = this.constructor.getRootPath(context.models, {}, props)
    this.htmlSnippet = this.model.getSync(rootPath)
    this.state = {
      fetchDataError: null,
      generation: this.model.getVersion(rootPath)
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.fetchData(this.props, this.context)
  }

  componentWillUnmount() {
    this._isMounted = false
    if (this.state.dispose) {
      this.state.dispose()
    }
    if (this.__$promise && this.__$promise.dispose) {
      this.__$promise.dispose()
    }
  }

  fetchData(props, context) {
    const paths = this.constructor.getPaths(context.models, {}, props)
    const rootPath = this.constructor.getRootPath(context.models, {}, props)
    const evaluator = this.model.fetch(paths)
    this.state.dispose = evaluator.dispose
    evaluator
      .then((res) => {
        this.htmlSnippet = _.get(res.json, rootPath)
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.pathEvaluator.getVersion(rootPath)
        }
        if (this._isMounted) this.setState(newState)
        else Object.assign(this.state, newState)
      })
      .catch((e) => {
        const newState = {
          fetchDataError: e,
          dispose: null
        }
        if (this._isMounted) this.setState(newState)
        else Object.assign(this.state, newState)
      })
  }

  render() {
    if (!this.htmlSnippet) return null

    let html = ''
    if (_.get(this, 'htmlSnippet.style')) {
      html += `<style>${this.htmlSnippet.style}</style>`
    }
    html += _.get(this, 'htmlSnippet.content') || ''
    if (_.get(this, 'htmlSnippet.javascript')) {
      html += `<script>${this.htmlSnippet.javascript}</script>`
    }

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
        className={this.props.className}
      />
    )
  }
}
