import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import { META_SCHEMA_ID, PUBLISH_STATUS } from '../../../../../constants/app'
import SwitchableList from '../../../../common/components/renewal/SwitchableList'
import SwitchableListApp from '../episode_shinchan/SwitchableListApp'

/** マイページ:購入済月額見放題 */
export default class PurchasedPlan extends Component {
  static propTypes = {
    /** 購入済のコース */
    course: PropTypes.shape({
      course_id: PropTypes.number.isRequired,
      schema_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired
    }).isRequired,
    /** 購入日時 */
    purchase_date: PropTypes.string.isRequired,
    /** 視聴期限 */
    limit_date: PropTypes.string.isRequired,
    /** 「戻る」ボタン押下時実行する関数 */
    goBack: PropTypes.func.isRequired
  }

  static defaultProps = {}

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.state = {
      right: {},
      rightMetas: [],
      episodes: []
    }

    // DioPlayer_iOSかチェック
    this.isIosDioPlayer = navigator.userAgent
      .split(' ')
      .find((value) => value.match(/^DioPlayer_iOS/g))
  }

  componentDidMount() {
    this._isMounted = true
    this.getRight()
      .then(() => this.getRightMetas())
      // .then(() => this.getEpisodes())
      .catch(() => {})
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** 権利情報取得 */
  getRight() {
    const { course } = this.props
    if (!course) return Promise.resolve()

    const path = [['course', 'rights', course.course_id]]
    return this.model.fetch(path).then((result) => {
      const rights = _.get(
        result,
        ['json', 'course', 'rights', course.course_id],
        []
      )
      if (this._isMounted) {
        this.setState({ right: rights[0] })
      }
    })
  }

  /** 権利メタ取得 */
  getRightMetas() {
    const { right } = this.state
    const path = ['right', 'metas', right.right_id]
    return this.model.fetch([path]).then((result) => {
      const metas = _.get(result, ['json', ...path]) || []
      // 公開以外も取得される&重複して取得されることがあるのでフィルター
      const metaIds = {}
      const rightMetas = metas.filter((meta) => {
        const published = meta.publish_status === PUBLISH_STATUS.PUBLISHED
        const duprecated = Object.keys(metaIds).includes(
          meta.meta_id.toString()
        )
        metaIds[meta.meta_id] = null
        return published && !duprecated
      })
      if (this._isMounted) {
        this.setState({ rightMetas })
      }
    })
  }

  /** エピソード取得 */
  getEpisodes() {
    const { rightMetas } = this.state

    const stories = rightMetas.filter((meta) =>
      [
        META_SCHEMA_ID.EPISODE,
        META_SCHEMA_ID.EPISODE_NOT_FREE,
        META_SCHEMA_ID.LIVE,
        META_SCHEMA_ID.LIVE_NOT_FREE
      ].includes(meta.meta_schema_id)
    )
    // コースの権利にグループメタが設定されている場合は子メディアメタを取得
    const parentIds = rightMetas
      .filter((meta) =>
        [
          META_SCHEMA_ID.SERIES,
          META_SCHEMA_ID.SEASON,
          META_SCHEMA_ID.LIVE_SERIES,
          META_SCHEMA_ID.LIVE_SEASON
        ].includes(meta.meta_schema_id)
      )
      .map((meta) => meta.meta_id)

    const path = [['meta', 'children', parentIds]]
    return this.model.fetch(path).then((result) => {
      const childrenMap = _.get(result, ['json', 'meta', 'children']) || {}
      const children = parentIds
        .flatMap((id) => childrenMap[id])
        .filter((meta) =>
          [
            META_SCHEMA_ID.EPISODE,
            META_SCHEMA_ID.EPISODE_NOT_FREE,
            META_SCHEMA_ID.LIVE,
            META_SCHEMA_ID.LIVE_NOT_FREE
          ].includes(meta.meta_schema_id)
        )
      // distinct
      const episodeMap = {}
      ;[...stories, ...children].forEach((e) => {
        episodeMap[e.meta_id] = e
      })
      const episodes = Object.values(episodeMap)
      episodes.sort((a, b) =>
        a.delivery_start_at < b.delivery_start_at ? 1 : -1
      )

      if (this._isMounted) {
        this.setState({ episodes })
      }
    })
  }

  render() {
    const { course, goBack } = this.props
    const { rightMetas } = this.state
    const limitDate = this.props.limit_date
    const purchaseDate = this.props.purchase_date

    const config = this.context.models.config.data
    const price = _.get(course, ['active_pricing', 'price'])
    const thumbnailUrl =
      _.get(course, ['thumbnail_url']) || config.default_thumbnail
    const purchaseDateFmt = moment(purchaseDate).format('YYYY/MM/DD')
    const deadLine = webApp.utils.deadLine(this.context, limitDate, true)
    const courseSlug = course.slug

    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <header className="c-mypage-cont-inBox-head">
            <a href="#" className="c-mypage-btnBack" onClick={goBack}>
              戻る
            </a>
            <h3 className="c-mypage-cont-inBox-head-title">
              {this.isIosDioPlayer ? "お持ちのコンテンツ" : "購入済"}
            </h3>
          </header>

          <div className="c-myHeadMeta">
            <div className="c-myHeadMeta-metaBox">
              <div className="c-myHeadMeta-metaBox-art">
                <div className="c-myHeadMeta-metaBox-art-inBox">
                  <div className="c-myHeadMeta-metaBox-art-inBox-labelBottom">
                    見放題パック
                  </div>
                  <div className="c-myHeadMeta-metaBox-art-inBox-artwork">
                    <img
                      src={thumbnailUrl}
                      className="c-myHeadMeta-metaBox-art-inBox-artwork-img"
                    />
                  </div>
                </div>
              </div>
              <div
                className="c-myHeadMeta-metaBox-info clickable"
                onClick={() =>
                  webApp.utils.goToProgramLink(this.context, null, null, course)
                }
              >
                <h2 className="c-myHeadMeta-metaBox-info-title">
                  {course.name}
                </h2>
                <div className="c-myHeadMeta-metaBox-info-purchasedDate">
                  {this.isIosDioPlayer ? "コンテンツ取得日：" : "購入日："}{purchaseDateFmt}
                </div>
                {!this.isIosDioPlayer && (
                  <div className="c-headMeta-metaBox-info-price pack">
                    購入金額：月額{price}円（税込）
                  </div>
                )}
                <div className="c-myHeadMeta-metaBox-info-viewingPeriod">
                  視聴期間：視聴ライセンス{this.isIosDioPlayer ? "取得後" : "購入後"}1ヶ月間
                </div>
                <div
                  // chromaticで画像の変更を検知しないための設定
                  data-chromatic="ignore"
                  className="c-myHeadMeta-metaBox-info-viewingDeadline"
                >
                  {deadLine}
                </div>
              </div>
            </div>
          </div>

          {courseSlug === 'shinchan' ? (
            <SwitchableListApp
              episodes={null}
              rootMetas={rightMetas}
              className="myPageList"
              placeholder="パックの中から探す"
              showNew
              showChecked
              onlySubTitle
            />
          ) : (
            <SwitchableList
              episodes={null}
              rootMetas={rightMetas}
              className="myPageList"
              placeholder="パックの中から探す"
              showNew
              showChecked
              onlySubTitle
            />
          )}
        </div>
      </div>
    )
  }
}
