import React from 'react'
import PropTypes from 'prop-types'

/* components */
import Button from '../../../../../../common/components/renewal/Pager2/Button'

/* style */
import { StyledUl, StyledLi, StyledP } from './style'

/** List3 Component */
const List3 = ({ page = 1, setPage = () => {}, maxPage = 1, ...props }) => {
  return (
    <StyledUl {...props}>
      <StyledLi>
        <StyledP>
          {page} / {maxPage}
        </StyledP>
      </StyledLi>
      <StyledLi>
        <Button type="button" value={1} isFirstChild onClick={() => setPage(1)}>
          &lt;&lt;
        </Button>
      </StyledLi>
      <StyledLi>
        <Button
          type="button"
          value={page - 1}
          onClick={() => setPage(page - 1)}
          disabled={page <= 1}
        >
          &lt;
        </Button>
      </StyledLi>
      <StyledLi>
        <Button
          type="button"
          value={page + 1}
          onClick={() => setPage(page + 1)}
          disabled={page >= maxPage}
        >
          &gt;
        </Button>
      </StyledLi>
      <StyledLi>
        <Button type="button" value={maxPage} onClick={() => setPage(maxPage)}>
          &gt;&gt;
        </Button>
      </StyledLi>
    </StyledUl>
  )
}

export default List3

List3.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  maxPage: PropTypes.number
}
