import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { EPISODE_DISPLAY_MODE } from '../../../../../constants/app'

/** 配信開始日 */
export default class DeliveryStartAt extends Component {
  static propTypes = {
    /**
     動画メタ情報<br>
      <span class="css-ks2jcn">object</span><br>
      <br>
      <table>
        <thead>
          <tr>
              <th>meta</th>
          </tr>
        </thead>
        <tbody>
          <tr>
              <td>meta_id<span class="css-1ywjlcj">*</span>: メタID<br>
              <span class="css-ks2jcn">number</span></td>
          </tr>
          <tr>
              <td>delivery_start_at: 配信開始時間<br>
              <span class="css-ks2jcn">string</span></td>
          </tr>
        </tbody>
      </table>
     */
    meta: PropTypes.shape({
      meta_id: PropTypes.number,
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    /**
     * 動画ステータス<br>
      <span class="css-ks2jcn">object</span><br>
      <br>
      <table>
        <thead>
          <tr>
              <th>status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
              <td>displayMode: 動画の視聴形態<br>
                <span class="css-ks2jcn">number(1 - 10)</span><br>
                <span class="css-ks2jcn">
                  1:  FREE<br>
                  2:  TVOD_FREE<br>
                  3:  TVOD_NOT_FREE<br>
                  4:  SVOD_FREE<br>
                  5:  SVOD_NOT_FREE<br>
                  6:  SVOD_AUTH_FREE<br>
                  7:  STVOD_FREE<br>
                  8:  STVOD_TVOD_NOT_FREE<br>
                  9:  STVOD_SVOD_NOT_FREE<br>
                  10: UNKNOWN
                </span>
              </td>
          </tr>
        </tbody>
      </table>
     */
    status: PropTypes.shape({
      displayMode: PropTypes.number,
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      isDeviceNotAvailable: PropTypes.bool,
      limitDate: PropTypes.string,
      isPossible: PropTypes.bool,
      isBelonging: PropTypes.bool
    }),
    /** ライブ配信かそれ以外か */
    isLive: PropTypes.bool
  }

  static defaultProps = {
    status: {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    isLive: false
  }

  static contextTypes = {
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const { meta, status, isLive } = this.props
    if (!meta || !meta.meta_id) return null

    // LIVEは放送日に加え、配信開始日も表示しない
    // https://docs.google.com/spreadsheets/d/110fDrQ0sNJ7f1QCiE1Hw-qNMjU-lVBAccAQFFbysvvA/edit?pli=1#gid=699926601&range=L162
    if (isLive) return null

    switch (status.displayMode) {
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
        const deliveryStartAt = moment(meta.delivery_start_at).format('YYYY年M月D日')
        return <div className="c-storyMeta-inBox-meta-castStart">配信開始日：{deliveryStartAt}</div>

      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.UNKNOWN:
      default:
        return null
    }
  }
}
