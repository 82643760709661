import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import webApp from '../../../utils/exdioWebAppUtils'
import { GlobalStyle } from './style'
import MainTitle from './components/MainTitle'
import Nav from './components/Nav'
import navItems from './json/navItems.json'
import PopularSeries from './components/PopularSeries'
import popularSeriesItems from './json/popularSeriesItems.json'
import SeriesList from './components/SeriesList'
import HowToWatch from './components/HowToWatch'
import howToWatchItems from './json/howToWatchItems.json'
import PopulurContents from './components/PopulurContents'
import populurContentsItems from './json/populurContentsItems.json'
import Footer from './components/Footer'
import useIsMounted from '../../../../hooks/useIsMounted'

/** 必殺仕事人 LP */
const Hissatu = (props, context) => {
  const isMounted = useIsMounted()
  const model = context.falcorModel.batch(100)
  const [seasonMetas, setSeasonMetas] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const seriesId = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 11013
    : 16083

  /** テキスト検索で使用する関数 */
  const handleSearchText = (event) => {
    setSearchText(event.target.value)
  }

  const deleteSearchText = () => {
    setSearchText('')
  }

  const conversionToKana = (text) => {
    if (text === null) return
    return text
      .toUpperCase()
      .replace(/[あ-ん]/g, (str) => String.fromCharCode(str.charCodeAt(0) + 96))
  }

  /** シーズンのメタ取得 */
  const fetchSeasonAndMeta = async (currentSeriesId) => {
    setIsLoading(true)
    try {
      const path = ['meta', currentSeriesId]
      const result = await model.fetch([path])
      const currentSeasonIds = get(
        result,
        ['json', 'meta', currentSeriesId, 'values', 'child_season_ids'],
        []
      )

      const fetchedSeasonMetas = await Promise.all(
        currentSeasonIds.map(async (seasonId) => {
          // シーズンのメタデータを取得
          const seasonPath = ['metas', seasonId]
          const seasonResult = await model.fetch([seasonPath])
          const seasonMeta = get(seasonResult, ['json', 'metas', seasonId])

          if (!seasonMeta) return null

          // エピソードのメタデータを取得
          const episodesPath = ['meta', 'children', seasonId]
          const episodesResult = await model.fetch([episodesPath])
          const episodes = get(
            episodesResult,
            ['json', 'meta', 'children', seasonId],
            []
          )

          /**
           * 最古の日付を探す
           * // NOTE
           * シリーズの配信開始日(values.evis_SeasonStart)と
           * 各単話の初回放送日(values.avails_ReleaseHistoryOriginal)を比較して
           * 古い方をoldestDateとして追加
           */

          let oldestDate = new Date(seasonMeta.values.evis_SeasonStart)
          episodes.forEach((episode) => {
            const episodeReleaseDateStr =
              episode.values.avails_ReleaseHistoryOriginal
            if (episodeReleaseDateStr) {
              let episodeDate = new Date(episodeReleaseDateStr)
              if (episodeDate < oldestDate) {
                oldestDate = episodeDate
              }
            }
          })

          return { ...seasonMeta, oldestDate }
        })
      )

      const validSeasonMetas = fetchedSeasonMetas.filter(
        (seasonMeta) => seasonMeta !== null
      )
      setSeasonMetas(validSeasonMetas)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  /** SPAでのHTML HEADタグ更新 */
  const updateMetaTags = () => {
    const title = '最新作を含めた必殺仕事人シリーズ36作品を配信中！| テレ朝動画'
    webApp.utils.updateTitle(title)

    const description =
      'テレ朝動画では必殺仕事人シリーズ36作品を最新作を含めて配信中！人気シリーズの主題歌情報やキャスト名での作品検索機能も充実しています。'
    const metaTags = {
      names: [
        { name: 'description', content: description },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'og:title', content: title },
        { property: 'og:description', content: description }
      ]
    }
    webApp.utils.updateMeta(metaTags)
  }

  useEffect(() => {
    if (isMounted) {
      fetchSeasonAndMeta(seriesId)
      updateMetaTags()
    }
  }, [isMounted, seriesId])

  if (!isMounted) return null

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;900&display=swap"
        />
      </Helmet>
      <GlobalStyle />
      <MainTitle />
      <Nav navItems={navItems} />
      <StyledDiv1 id="link1">
        {popularSeriesItems.map((popularSeriesItem, i) => {
          return (
            <PopularSeries
              popularSeriesItem={popularSeriesItem}
              i={i}
              key={i}
            />
          )
        })}
      </StyledDiv1>
      <SeriesList
        seasonMetas={seasonMetas}
        handleSearchText={handleSearchText}
        deleteSearchText={deleteSearchText}
        conversionToKana={conversionToKana}
        searchText={searchText}
        isLoading={isLoading}
      />
      <HowToWatch howToWatchItems={howToWatchItems} />
      <PopulurContents populurContentsItems={populurContentsItems} />
      <Footer />
    </>
  )
}

export default Hissatu

Hissatu.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  routeHandler: PropTypes.object
}

const StyledDiv1 = styled.div`
  overflow: hidden;
`
