import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'
import routes from '../../../routes'

/** style */
import {
  fixColors,
  borderColor2,
  mediaQuery
} from '../../../../exdio/components/style'

/** ヘッダー > ハンバーガーメニュ > コンテンツ */
const LogoutButton = (
  { onClick = () => {}, isLoggedIn = false, ...props },
  context
) => {
  const isApp = webApp.utils.isApp(context)
  /** ログアウト */
  const logout = () => {
    webApp.utils.logout(context).then(() => {
      onClick()
      window.location.href = routes[isApp ? 'app_home' : 'home'].makePath()
    })
  }

  if (!isLoggedIn) return null

  return (
    <StyledDiv {...props}>
      <StyledButton onClick={logout}>ログアウト</StyledButton>
    </StyledDiv>
  )
}

export default LogoutButton

LogoutButton.propTypes = {
  onClick: PropTypes.func,
  isLoggedIn: PropTypes.bool
}

LogoutButton.contextTypes = {
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object
}

const StyledDiv = styled.div`
  margin: 20px auto 0;
  max-width: 186px;
  width: 100%;
  background-color: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;

  ${mediaQuery('sm')} {
    margin-top: 10px;
    max-width: 100%;
    border-bottom: 1px solid #fff;
  }
`

const StyledButton = styled.button`
  padding: 0.7em 0;
  width: 100%;
  background-color: #fff;
  display: block;
  border: none;
  color: ${fixColors.colorFont};
  font-size: 1.5rem;
  font-family: inherit;
  font-weight: 600;
  text-align: center;

  ${mediaQuery('sm')} {
    padding: 20px;
    border-bottom: 1px solid ${borderColor2};
    text-align: left;
  }
`
