import $ from 'jquery'

/**
 * PageTop スクロールアニメーション
 */
const pageTopAnimation = (e, _self) => {
  e.preventDefault()

  /** scrollの変数 */
  const offset = 50 // 画面上部にはみ出させる値
  const windowHeight = $(window).height() // 画面の高さ
  const selfPosition = _self.css('position') // 自身のpositionの値
  const selfBottom = parseInt(_self.css('bottom')) // 自身のbottomの値
  const finishPos = windowHeight + offset // アニメーション完了時の位置
  const $target = $(_self.attr('href')) // リンクの値を取得
  const targetPos = $target.offset().top

  /** アニメーションの順番をまとめる */
  function playAnimation() {
    _self.css({ pointerEvents: 'none' })
    Anime()
      .then(moveTop)
      .then(removeAction)
  }

  /** 上下にバウンドするアニメーション動き */
  function Anime() {
    const DURATION1 = 1000
    const DURATION2 = 700
    const d = new $.Deferred()
    _self.animate(
      {
        bottom: `${selfBottom + 110}px`
      },
      DURATION1,
      function() {
        _self.animate(
          {
            bottom: `${selfBottom + 40}px`
          },
          DURATION2,
          function() {
            d.resolve()
          }
        )
      }
    )
    return d.promise()
  }

  /** ページトップに移動するアニメーション動き */
  function moveTop() {
    const DURATION = 2000
    const d = new $.Deferred()
    const isSp = window.matchMedia('(max-width: 1024px)').matches

    $('html, body').animate({ scrollTop: targetPos }, DURATION, 'swing')
    if (isSp) {
      _self
        .css({ position: 'fixed' })
        .animate({ bottom: `${finishPos}px` }, DURATION, 'swing', () => {
          d.resolve()
        })
    } else {
      _self.animate({ bottom: `${finishPos}px` }, DURATION, 'swing', () => {
        d.resolve()
      })
    }
    return d.promise()
  }

  /** アイコンを元の表示と位置に戻す動き */
  function removeAction() {
    return setTimeout(() => {
      const duration = 400
      const d = new $.Deferred()
      _self
        .fadeOut(0, () => {
          _self.css({ position: selfPosition, bottom: selfBottom })
        })
        .fadeIn(duration, () => {
          _self.removeAttr('style')
          d.resolve()
          return d.promise()
        })
    }, 1000)
  }

  /** アニメーションの関数を実行 */
  playAnimation()
  return false
}

export default pageTopAnimation
