import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import { breakPoints, mediaQuery } from '../../style'
import webApp from '../../../utils/exdioWebAppUtils'
import useIsMounted from '../../../../hooks/useIsMounted'

/** テレ朝ポイントシステム */
const TPS = ({ ...props }, context) => {
  const model = context.falcorModel.batch(100)

  const defaultTvAsahiPoint = {
    result_code: '',
    point: 0,
    limited_point: 0,
    rank_id: ''
  }
  const [noticeText, setNoticeText] = useState('')
  const isMounted = useIsMounted()

  /** テレ朝ポイントの取得 */
  const getTvAsahiPoint = () => {
    const path = [['infra', 'tvasahiPoint']]
    return model.fetch(path).then((result) => {
      const data = _.get(result, ['json', 'infra', 'tvasahiPoint'])
      if (data) {
        return data
      }
    })
  }

  /** テレ朝ポイント描画用のscriptを設置 */
  const insertScript = () => {
    const head = document.head
    const currentElm = document.getElementById('point_parts_js')
    if (currentElm) return

    const scriptElm = document.createElement('script')
    scriptElm.id = 'point_parts_js'
    scriptElm.src = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 'https://st-point.tvasahi.jp/point_parts/js/point_parts.js'
      : 'https://point.tv-asahi.co.jp/point_parts/js/point_parts.js'
    head.appendChild(scriptElm)
  }

  /** 描画用のスクリプトを追加 */
  const renderExeScriptElm = (tvAsahiPoint = defaultTvAsahiPoint) => {
    const { point, limited_point: limitedPoint, rank_id: rankId } = tvAsahiPoint

    const currentElm = document.getElementById('point_parts_js_exe')
    if (currentElm) currentElm.remove()

    const head = document.head
    const scriptElm = document.createElement('script')
    scriptElm.id = 'point_parts_js_exe'
    scriptElm.innerHTML = `setPointHeader(${point}, ${limitedPoint}, ${rankId})`
    head.appendChild(scriptElm)
  }

  useEffect(() => {
    const isLoggedIn = webApp.utils.isLoggedIn(context)
    if (!isLoggedIn) {
      setNoticeText('tv asahi iDに登録してテレ朝ポイントを貯めよう！')
    } else {
      insertScript()
      getTvAsahiPoint()
        .then((data) => {
          setTimeout(() => {
            renderExeScriptElm(data)
          }, 2000)
        })
        .catch((e) => {
          setNoticeText('テレ朝ポイントの情報が取得できませんでした')
          webApp.utils.handleFalcorError(e, context)
        })
    }
  }, [])

  if (!isMounted) return null

  return (
    <div id="point-header" {...props}>
      <StyledP>{noticeText}</StyledP>
      <GlobalStyle />
    </div>
  )
}

export default TPS

TPS.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object
}

const GlobalStyle = createGlobalStyle`
  #point-header {
    max-width: ${breakPoints.mx}px;
    text-align: right;

    ${mediaQuery()} {
      padding: 0 15px;
    }

    .tvasahi-point-status-wrapper {
      margin-bottom: 0;
      justify-content: flex-end;
    }
  }

  #mp-header + #point-header {
    margin: 15px auto 0;
  }
`

const StyledP = styled.p`
  font-size: 1.2rem;
`
