import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import webApp from '../../utils/exdioWebAppUtils'
import HeaderNewsComponent from './HeaderNewsComponent'

import Logirl_Header from '../../../exdio/components/browse/plan_logirl/components/layout/Header'
import Logirl_Footer from '../../../exdio/components/browse/plan_logirl/components/layout/Footer'
import Nav2 from '../../../exdio/components/browse/plan_logirl/components/nav/Nav2'
import Hdg1 from '../../../exdio/components/browse/plan_logirl/components/heading/Hdg1'
import Copy from './plan_logirl/components/other/Copy'

import LogirlLiveList from '../../../exdio/components/browse/plan_logirl/LogirlLiveList'

// Logirl Live 一覧
export default class LogirlLiveListContent extends Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  static getPath(_models, options, _props) {
    const { routeHandler } = options
    const url = routeHandler.path
    return ['page', url]
  }

  static getPrefetchPaths(models, options, props) {
    return [LogirlLiveListContent.getPath(models, options, props)]
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const page = _.get(prefetchResult, [
      'json',
      ...LogirlLiveListContent.getPath(models, options, props)
    ])
    return { title: page.name }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    const path = LogirlLiveListContent.getPath(
      {},
      { routeHandler: context.routeHandler },
      props
    )
    const page = this.model.getSync(path) || null

    this.state = {
      page,
      isNotFound: false
    }
  }

  componentDidMount() {
    this.fetchData()
      .then(() => this.updateMetaTags())
      .catch((e) => webApp.utils.handleFalcorError(e, this.context))

    // GTMで使用する静的ページのイベントを入れる
    let gtm_tags = [
      { key: 'event', value: 'staticPageChange' },
      { key: 'genre', value: 'cu' }
    ]
    webApp.utils.updateDataLayer(gtm_tags)
    webApp.utils.updateCookieSync(window.navigator.userAgent)
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags() {
    const { page } = this.state
    const title = page.name
    webApp.utils.updateTitle(title)

    const { copyright } = this.config
    const description = page.description || this.config.description
    const keywords = page.keywords || this.config.keywords
    const url = window.location.href
    const regularUrl = url.replace(/\?.*$/, '')
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: regularUrl }]
    }
    webApp.utils.updateMeta(metaTags)
  }

  fetchData() {
    // 念のため削除
    const scriptTags = document.querySelector(
      '#logirl-static-page-content script'
    )
    if (scriptTags) Array.from(scriptTags).forEach((e) => e.remove())

    const url = this.context.routeHandler.path
    const path = ['page', url]
    return this.model.fetch([path]).then((result) => {
      const page = _.get(result, ['json', ...path]) || null
      this.setState({ page, isNotFound: !page || !page.innerHTML }, () => {
        // ブラウザバック時にscriptが走らないので、クライアント側で明示的にscriptタグの追加を行いscriptを実行する
        if (!page) return
        const divElem = document.getElementById('logirl-static-page-content')
        if (!divElem) return
        const scriptElem = document.createElement('script')
        scriptElem.innerHTML = page.script
        divElem.appendChild(scriptElem)
      })
    })
  }

  render() {
    return (
      <div className="p-logirl p-live">
        <Logirl_Header />
        <Nav2 className="p-live__nav u-hide--sp" />
        {/* <HeaderNewsComponent /> */}
        <div className="l-container">
          <StyledHdg1 src="/images/exdio/renewal/logirl/icon/live.svg">LIVE</StyledHdg1>
          <LogirlLiveList />
        </div>
        <Logirl_Footer />
        <Copy />
      </div>
    )
  }
}

const mediaSp = '(max-width: 1024px)'
const StyledHdg1 = styled(Hdg1)`
  && {
    @media ${mediaSp} {
      i {
        span {
          width: 27px;
          height: 24px;
        }
      }
    }
  }
`
