import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import axios from 'axios'
import webApp from '../../../utils/exdioWebAppUtils'
import { PANEL_ID } from './config'

/** components */
import Slider from 'react-slick'
import Banner from './Banner'
import ProgramItem from './ProgramItem'

/** style */
import styled from 'styled-components'
import { mediaPc, mediaSp } from './styles'

/** 新着動画コンポーネント */
const NewArrivals = (
  {
    seasonIds = {},
    scrollTargetRef = {
      current: null
    },
    setSelectedTabId = () => {},
    setSelectedTags = () => {},
    ...props
  },
  context
) => {
  const FETCH_COUNT = 10
  const model = context.falcorModel.batch(100)
  const [sliderItems, setSliderItems] = useState([])

  const sliderSetting = {
    dots: false,
    infinite: true,
    speed: 400,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  }

  useEffect(async () => {
    /**
     * TODO
     * Promise.all(Object.values(seasonIds) => {})の形にすると
     * seasonIdが[xxx, xxx, xxx]の形で渡されるため、一旦ベタ書きで対応
     * MetaRoute側で配列対応をすることで一括取得が可能
     */
    let latestEpisodes = []

    /** クレヨンしんちゃん */
    const episodes1 = await getEpisodes(
      get(seasonIds, ['SHIN_CHAN'])
    ).catch((e) => webApp.utils.handleFalcorError(e, context))
    if (episodes1) {
      latestEpisodes = [...latestEpisodes, ...episodes1]
    }

    /** クレヨンしんちゃん SHIN-MEN */
    const episodes2 = await getEpisodes(
      get(seasonIds, ['SHIN_MEN'])
    ).catch((e) => webApp.utils.handleFalcorError(e, context))
    if (episodes2) {
      latestEpisodes = [...latestEpisodes, ...episodes2]
    }

    /** クレヨンしんちゃん テレビスペシャル */
    const episodes3 = await getEpisodes(
      get(seasonIds, ['TV_SPECIAL'])
    ).catch((e) => webApp.utils.handleFalcorError(e, context))
    if (episodes3) {
      latestEpisodes = [...latestEpisodes, ...episodes3]
    }

    /** 配信開始日でソート */
    latestEpisodes = latestEpisodes.sort((a, b) => {
      const deliveryStartAt_A = get(a, ['delivery_start_at'])
      const deliveryStartAt_B = get(b, ['delivery_start_at'])
      const Date_deliveryStartAt_A = new Date(deliveryStartAt_A)
      const Date_deliveryStartAt_B = new Date(deliveryStartAt_B)
      return Date_deliveryStartAt_A > Date_deliveryStartAt_B ? -1 : 1
    })

    const banners = await getBanners().catch((e) =>
      webApp.utils.handleFalcorError(e, context)
    )
    const _sliderItems = insertBannersToSliderItems(
      latestEpisodes,
      banners
    ).slice(0, FETCH_COUNT)

    setSliderItems(_sliderItems)
  }, [])

  /**
   * エピソード取得
   * 最新話10件
   */
  const getEpisodes = (seasonId) => {
    const path = [['meta', 'latest', seasonId, FETCH_COUNT]]
    return model.fetch(path).then((result) => {
      if (!result || !result.json) return Promise.resolve()
      return get(result, ['json', ...path[0]])
    })
  }

  /**
   * バナー取得
   * 基盤に設置しているバナー情報を取得
   */
  const getBanners = () => {
    const rootPath = webApp.utils.dougaMvHost(context)
    const path = `//${rootPath}/douga_mv/shinchan/svod/data/new_arrivals.json`
    return axios.get(path).then((res) => res.data)
  }

  /**
   * 最新話リストの任意の位置にバナーを挿入する
   * @param {object} episodes 最新話メタ情報
   * @param {object} banners バナー情報
   */
  const insertBannersToSliderItems = (episodes = [], banners = {}) => {
    // episodesを破壊しないように複製
    let insertedArr = [...episodes]
    Object.keys(banners).forEach((key) => {
      insertedArr.splice(key, 0, banners[key])
    })
    return insertedArr
  }

  /**
   * 厳選エピソードバナークリック時にタブエリアにスクロール
   * @param {object} e クリックイベント
   * @param {object} banner new_arrivals.jsonのバナー情報
   */
  const onClickScroll = (e, banner) => {
    const { type, tag } = banner
    if (scrollTargetRef.current && type === 'banner' && tag !== '') {
      e.preventDefault()
      setSelectedTabId(PANEL_ID.SELECTION)
      setSelectedTags([tag])
      scrollTargetRef.current.scrollIntoView({
        behavior: 'smooth'
      })
      return false
    }
  }

  return (
    <StyledDiv>
      {sliderItems.length > 0 && (
        <Slider {...sliderSetting} {...props}>
          {sliderItems.map((metaOrBanner, i) => {
            if (get(metaOrBanner, ['type']) === 'banner') {
              return (
                <Banner
                  key={i}
                  banner={metaOrBanner}
                  onClick={(e) => onClickScroll(e, metaOrBanner)}
                />
              )
            } else {
              const routes = webApp.utils.getProgramLinkRoutes(
                context,
                metaOrBanner
              )
              return (
                <ProgramItem
                  key={i}
                  meta={metaOrBanner}
                  isSliderItem
                  {...routes}
                />
              )
            }
          })}
        </Slider>
      )}
    </StyledDiv>
  )
}

export default memo(NewArrivals)

NewArrivals.propTypes = {
  seasonIds: PropTypes.object,
  scrollTargetRef: PropTypes.object,
  setSelectedTabId: PropTypes.func,
  setSelectedTags: PropTypes.func
}

NewArrivals.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  routeHandler: PropTypes.object
}

const StyledDiv = styled.div`
  .slick {
    &-slider {
      padding: 20px 30px;
      background: #fff;
      border-radius: 20px;
      position: relative;

      @media ${mediaSp} {
        padding: 10px 2px;
        border-radius: 8px;
      }
    }

    &-slide {
      padding: 0 10px;

      @media ${mediaSp} {
        padding: 0 8px;
      }
    }

    &-arrow {
      margin-top: -22px;
      width: 48px;
      height: 48px;
      box-shadow: 0px 3px 0px 0px #fcdb29;
      border-radius: 90px;
      position: absolute;
      top: 50%;
      z-index: 10;
      transform: none;
      transition: box-shadow 0.2s, transform 0.2s;
      cursor: pointer;

      &:hover {
        @media ${mediaPc} {
          box-shadow: 0px 0px 0px 0px #fcdb29;
          transform: translateY(3px);
        }
      }

      &::before {
        content: none;
      }
    }

    &-prev {
      background: #ffeb00
        url('/douga_mv/shinchan/svod/images/common/slider-arrow-l.svg')
        no-repeat center / auto;
      left: -24px;
      right: auto;

      &:hover,
      &:focus {
        background: #ffeb00
          url('/douga_mv/shinchan/svod/images/common/slider-arrow-l.svg')
          no-repeat center / auto;
      }
    }

    &-next {
      background: #ffeb00
        url('/douga_mv/shinchan/svod/images/common/slider-arrow-r.svg')
        no-repeat center / auto;
      right: -24px;
      left: auto;

      &:hover,
      &:focus {
        background: #ffeb00
          url('/douga_mv/shinchan/svod/images/common/slider-arrow-r.svg')
          no-repeat center / auto;
      }
    }

    &-track {
      display: flex;
    }
  }
`
