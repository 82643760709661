import styled from 'styled-components'
import { mediaQuery, breakPoints } from '../../../../../exdio/components/style'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import Sns from '../components/Sns'
import { breakPoints as SailGPbreakPoints } from '../style'

export const StyledDiv1 = styled.div`
  padding-top: 30px;
  background: #010505
    url('https://wws.tv-asahi.co.jp/douga_mv/sailgp/images/bg_01.png') no-repeat
    top/100% auto;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo,
    メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;

  ${mediaQuery(breakPoints.sm)} {
    padding-top: 0;
  }
`

//** player area
export const StyledDiv2 = styled.div`
  padding: 0 12px;
  overflow: hidden;

  ${mediaQuery(breakPoints.sm)} {
    padding-top: 0;
  }
`

export const StyledDiv3 = styled.div`
  margin: 0 auto;
  width: 1280px;
  position: relative;

  ${mediaQuery(SailGPbreakPoints.tab)} {
    width: 960px;
  }

  ${mediaQuery(breakPoints.sm)} {
    margin: auto;
    width: auto;
    position: inherit;
  }
`

export const StyledH1 = styled.h1`
  margin: 0 auto;
  width: 1280px;

  ${mediaQuery(SailGPbreakPoints.tab)} {
    width: 960px;
  }

  ${mediaQuery(breakPoints.sm)} {
    padding: 15px 0;
    width: auto;
  }
`

export const StyledLink1 = styled(Link)`
  display: inline-block;
`

export const StyledImg1 = styled.img`
  width: 180px;

  ${mediaQuery(breakPoints.sm)} {
    width: 120px;
  }
`

export const StyledDiv4 = styled.div`
  margin: 0 auto;
  background: inherit;
  overflow: hidden;

  ${mediaQuery(breakPoints.sm)} {
    padding: 0 8px;
  }
`

export const StyledDiv5 = styled.div`
  width: 640px;
  height: 360px;
  float: left;
  position: relative;

  ${mediaQuery(breakPoints.sm)} {
    width: auto;
    height: auto;
    float: inherit;
  }
`

export const StyledA1 = styled.a`
  ${mediaQuery(breakPoints.sm)} {
  }
`

export const StyledDiv6 = styled.div`
  margin: auto;
  padding-top: 170px;
  width: 144px;
  height: 144px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_pc.svg)
    no-repeat center center;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;

  ${mediaQuery(breakPoints.sm)} {
    display: none;
  }
`

export const StyledDiv7 = styled.div`
  display: none;
  ${mediaQuery(breakPoints.sm)} {
    display: block;
    padding-top: 68px;
    width: 80px;
    height: 80px;
    background: url(/images/exdio/renewal/icon_play_btn_for_video_area_sp.svg)
      no-repeat top center;
    position: absolute;
    right: 1%;
    bottom: 5%;
    z-index: 1;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    vertical-align: bottom;
  }
`

export const StyledDiv8 = styled.div`
  margin-left: auto;
  padding: 0.6em 1.2em;
  background: rgba(102, 102, 102, 0.5);
  border-radius: 5px;
  position: absolute;
  bottom: 21px;
  left: 15px;
  z-index: 1;
  color: #fff;
  font-size: 1.8rem;
  text-align: center;

  ${mediaQuery(breakPoints.sm)} {
    font-size: 1.3rem;
  }
`

export const StyledDiv9 = styled.div`
  width: 600px;
  float: right;
  color: #e6ebef;

  ${mediaQuery(SailGPbreakPoints.tab)} {
    width: 300px;
  }
`

export const StyledH2 = styled.h2`
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.2;
`

export const StyledLink2 = styled(Link)`
  && {
    color: #e23b25;
    font-weight: bold;
    font-style: italic;
  }
`

export const StyledSns = styled(Sns)`
  margin: 20px auto 25px;
  width: 1280px;
  position: inherit;

  ${mediaQuery(SailGPbreakPoints.tab)} {
    width: 960px;
  }

  ${mediaQuery(breakPoints.sm)} {
    width: 100%;
  }
`

export const StyledImg2 = styled.img`
  margin: 0 auto 0 0;

  ${mediaQuery(breakPoints.sm)} {
    margin: 5px auto 0 0;
  }
`

export const StyledH3 = styled.h3`
  margin-top: 6px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  line-height: 22px;
  color: #fff;

  ${mediaQuery(breakPoints.sm)} {
    padding: 0 8px;
  }
`

export const StyledUl1 = styled.ul`
  padding: 18px 0 12px;
  border-top: 1px solid #353a40;
  color: #6c6e6e;

  ${mediaQuery(breakPoints.sm)} {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
  }
`

export const Styledli1 = styled.li`
  margin-bottom: 12px;
  font-size: 12px;

  &::before {
    color: #6c6e6e;
    margin-right: 5px;
    font-size: 12px;
    font-family: 'Material Icons';
    content: '${({ icon }) => icon}';
  }
`

export const StyledP1 = styled.p`
  font-size: 13px;
  line-height: 1.5;
`

export const StyledDiv10 = styled.div`
  ${mediaQuery(breakPoints.sm)} {
    margin: 0 8px 0;
    max-width: unset;
  }
`
export const StyledDiv11 = styled.div`
  ${mediaQuery(breakPoints.sm)} {
    margin: 0 0 10px;
    text-align: right;
  }
`

export const StyledDiv12 = styled.div`
  ${mediaQuery(breakPoints.sm)} {
    margin: 15px 8px;
    color: #6c6e6e;
  }
`

export const StyledP2 = styled.p`
  ${mediaQuery(breakPoints.sm)} {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.5;
  }
`

export const StyledP3 = styled.p`
  ${mediaQuery(breakPoints.sm)} {
    margin-bottom: 10px;
    color: #fff;
    font-size: 13px;
  }
`
