import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ProgramItem from '../../../../common/components/renewal/ProgramItem';
import webApp from '../../../utils/exdioWebAppUtils';
import { META_SCHEMA_ID } from '../../../../../constants/app';
import * as DOMUtils from '../../../../utils/DOMUtils';
import * as browserEvents from '../../../../utils/browserEvents';

/** クレヨンしんちゃんアプリplanページ・アプリブラウザ購入済みページ リスト表示切り替えコンポーネント */
export default class SwitchableListApp extends Component {
  static propTypes = {
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        meta_schema_id: PropTypes.number.isRequired,
        thumbnail_url: PropTypes.string,
        values: PropTypes.object.isRequired,
        name: PropTypes.string,
        duration: PropTypes.number,
        delivery_start_at: PropTypes.string,
        delivery_end_at: PropTypes.string
      })
    ),
    rootMetas: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    placeholder: PropTypes.string,
    showNew: PropTypes.bool,
    showChecked: PropTypes.bool,
    showDelivery: PropTypes.bool,
    onlySubTitle: PropTypes.bool
  };

  static defaultProps = {
    episodes: [],
    rootMetas: null,
    className: '',
    placeholder: '',
    showNew: false,
    showChecked: false,
    showDelivery: false,
    onlySubTitle: false
  };

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func,
    routeHandler: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.state = {
      filteredEpisodes: props.episodes,
      isList: false,
      modeDsearch: !props.episodes, // episodesにnullが指定されたらrootMetasを利用してdsearch検索を行う
      onlySubTitle: props.onlySubTitle
    };

    this.isLoading = false;

    this.setListRef = e => {
      this.listRef = e;
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.state.modeDsearch) {
      this.search();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rootMetas !== this.props.rootMetas) {
      this.search();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async search() {
    const { rootMetas } = this.props;
    const { filteredEpisodes } = this.state;

    if (!rootMetas || rootMetas.length <=0 || this.isLoading) {
      return;
    }
    // fromが常に0でもfalcorが差分のみリクエストしてくれる
    const range = { from: 0, to: (filteredEpisodes || []).length + 19 };

    // シリーズは運用で入らない
    let episode_ids = [];
    let season_ids = [];
    let series_ids = [0];

    _.forEach(rootMetas, meta => {
      if (meta.meta_schema_id === META_SCHEMA_ID.EPISODE || meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE ||
          meta.meta_schema_id === META_SCHEMA_ID.LIVE || meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE) {
        episode_ids.push(meta.meta_id);
      } else if (meta.meta_schema_id === META_SCHEMA_ID.SEASON || meta.meta_schema_id === META_SCHEMA_ID.LIVE_SEASON) {
        season_ids.push(meta.meta_id);
      }
    });

    let showEpisodeFlag = true;
    if (episode_ids.length === 0 && season_ids.length > 1) {
      // シーズンが複数件登録されていたらシーズン一覧表示
      showEpisodeFlag = false;
    }

    if (episode_ids.length === 0) {
      episode_ids = [0];
    }
    if (season_ids.length === 0) {
      season_ids = [0];
    }

    const searchType = showEpisodeFlag ? 'episode' : 'season';
    this.isLoading = true;
    const path = [['meta', 'packSearch', searchType, '', episode_ids, season_ids, series_ids, [range]]];

    const deviceInfo = this.context.models.browserInfo.data;
    let func = () => {
      return this.model
        .fetch(path)
        .then(result => {
          if (result && result.json) {
            const metas = _.get(result.json, [
              'meta',
              'packSearch',
              searchType,
              '',
              episode_ids[0],
              season_ids[0],
              series_ids[0]
            ]);
            if (metas) {
              delete metas.$__path;
              if (this._isMounted) {
                if (metas !== this.state.filteredEpisodes) {
                  // ID昇順でソート
                  const sortedEpisodes = (Object.values(metas)||[]).sort((a, b) => Number(_.get(a, ['id'])) > Number(_.get(b, ['id'])) ? 1 : -1)
                  // シリーズを検索する場合はonlySubTitleを変えてタイトルを表示してあげる
                  this.setState({ filteredEpisodes: sortedEpisodes, onlySubTitle: showEpisodeFlag });
                }
              }
            }
          } else {
            this.setState({ filteredEpisodes: [] });
          }
        })
    }
    if(deviceInfo.isIE) {
      // IEだとfinallyがエラーになる
      await func();
      this.isLoading = false;
    } else {
      func().finally(() => {
        this.isLoading = false;
      });
    }
  }

  render() {
    const { className, showNew, showDelivery, showChecked } = this.props;
    const { filteredEpisodes, isList, onlySubTitle } = this.state;

    return (
      <div className={`c-card-vertical mt-tab ${className} ${isList ? 'list' : ''}`}>
        <div className="c-card-vertical-cont" ref={this.setListRef}>
          {/* ID昇順で表示 */}
          {filteredEpisodes && filteredEpisodes.map((meta, i) => {
              return (
                <ProgramItem
                  key={i}
                  meta={meta}
                  showCaption
                  showNew={showNew && webApp.utils.showNew(meta)}
                  showChecked={showChecked && webApp.utils.isWatched(meta)}
                  showDelivery={showDelivery}
                  onlySubTitle={onlySubTitle}
                  onClickThumbnail={() => webApp.utils.goToProgramLink(this.context, meta)}
                  onClickPlay={() => webApp.utils.goToProgramLink(this.context, meta)}
                  onClickCaption={() => webApp.utils.goToProgramLink(this.context, meta, null, null, { autoPlay: false })}
                />
              );
            })}
        </div>
      </div>
    );
  }
}
