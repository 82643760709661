import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../utils/exdioWebAppUtils'
import Pager from '../../../../../common/components/renewal/Pager2'
import Item from './item'
import { StyledDiv1, StyledUl1 } from './styles'

/** そだてれび：海外視聴用 pc 動画リスト表示切り替えコンポーネント */
export default class Switchable extends Component {
  static propTypes = {
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        meta_schema_id: PropTypes.number.isRequired,
        thumbnail_url: PropTypes.string,
        values: PropTypes.object.isRequired,
        name: PropTypes.string,
        duration: PropTypes.number,
        delivery_start_at: PropTypes.string,
        delivery_end_at: PropTypes.string
      })
    ),
    isSmp: PropTypes.bool,
    className: PropTypes.string
  }

  static defaultProps = {
    episodes: []
  }

  static contextTypes = {
    history: PropTypes.object,
    falcorModel: PropTypes.object,
    routeHandler: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      pagerOptions: {
        // ページャーの設定
        episodesPerPages: 24,
        range: 2
      },
      currentPageNum: 1,
      currentEpisodes: []
    }
    this.setListRef = (e) => {
      this.listRef = e
    }
    this.updateCurrentPageNum = this.updateCurrentPageNum.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { episodes } = this.props

    // 親のepisodeが更新されたとき検索を再実行する(初期化)
    if (prevProps.episodes !== episodes) {
      this.initializePagination(episodes)
    }
  }

  /** エピソードをソートする関数 */
  sortEpisodes(episodes) {
    const direction = this.props.disp_order == 'asc' ? -1 : 1
    episodes.sort((a, b) => {
      //ソート番号 -> 管理画面の昇順or降順に合わせる
      if (a.sort < b.sort) return direction * 1
      if (a.sort > b.sort) return direction * -1

      // 配信開始日時 -> 管理画面の昇順or降順に合わせる
      if (a.delivery_start_at < b.delivery_start_at) return direction * 1
      if (a.delivery_start_at > b.delivery_start_at) return direction * -1

      // メタスキーマIDの昇順(無料, 有料の順) -> 固定
      if (a.meta_schema_id < b.meta_schema_id) return -1
      if (a.meta_schema_id > b.meta_schema_id) return 1

      // エピソード番号 -> 管理画面の昇順or降順に合わせる
      if (
        Number(a.values.avails_EpisodeNumber) <
        Number(b.values.avails_EpisodeNumber)
      )
        return direction * 1
      if (
        Number(a.values.avails_EpisodeNumber) >
        Number(b.values.avails_EpisodeNumber)
      )
        return direction * -1

      // ID -> 管理画面の昇順or降順に合わせる
      if (a.id < b.id) return direction * 1
      if (a.id > b.id) return direction * -1

      return 0
    })
  }

  /** ページ番号の更新 */
  updateCurrentPageNum(currentPageNum) {
    this.setState({
      currentPageNum,
      currentEpisodes: this.returnCurrentPageEpisodes(currentPageNum)
    })
  }

  /** ページネーションを1ページ目に設定する */
  initializePagination(episodes) {
    this.sortEpisodes(episodes)
    this.setState({
      currentEpisodes: this.returnCurrentPageEpisodes(1),
      currentPageNum: 1
    })
  }

  /** ページネーションのページに応じて表示する動画を返す */
  returnCurrentPageEpisodes(pageNum) {
    const { pagerOptions } = this.state
    const { episodes } = this.props
    const firstVal = pagerOptions.episodesPerPages * (pageNum - 1)
    const lastVal = pagerOptions.episodesPerPages * pageNum
    return episodes.slice(firstVal, lastVal)
  }

  render() {
    const { pagerOptions, currentPageNum, currentEpisodes } = this.state
    const { episodes, isSmp, className } = this.props

    return (
      <div className={className}>
        {/* 動画の総数が1ページに表示する動画数よりも少ない場合は表示しない。 */}
        {episodes.length > pagerOptions.episodesPerPages && (
          <StyledDiv1 isSmp={isSmp}>
            <Pager
              option={pagerOptions}
              episodeLength={episodes.length}
              currentPageNum={currentPageNum}
              updateCurrentPageNum={this.updateCurrentPageNum}
            />
          </StyledDiv1>
        )}
        <StyledUl1 isSmp={isSmp} ref={this.setListRef}>
          {currentEpisodes &&
            currentEpisodes.map((meta) => {
              return (
                <Item
                  key={meta.meta_id}
                  meta={meta}
                  isSmp={isSmp}
                  onClickThumbnail={() =>
                    webApp.utils.goToProgramLink(this.context, meta)
                  }
                />
              )
            })}
        </StyledUl1>
      </div>
    )
  }
}
