import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import { FAVORITE_TYPE } from '../../../../../constants/app'
import EpisodePager from '../details/EpisodePager'
import AddButtonBlock from '../details/AddButtonBlock'
import PlayLink from './PlayLink'
import { getPrice, isPlayable, whatKindOfVideo } from './util'

/** 動画に対するアクション */
export default class Actions extends Component {
  static propTypes = {
    /** 「視聴ボタン」のクリック時に発火するイベント */
    onClickPlay: PropTypes.func,
    /** falseの時「視聴ボタン」のクリックイベントが無効化される */
    inViewTerm: PropTypes.bool,
    /** twitterシェアボタンのurl作成に使用される */
    metaName: PropTypes.string,
    /** trueの時、ログインもしくはマイページ遷移ボタンが描画される */
    renderLinkLoginOrMyPage: PropTypes.bool,
    /** trueの時、且つApp使用時にログインボタンが描画される */
    renderLinkAppLogin: PropTypes.bool,
    /** 動画のmeta情報
     * @param {number} meta_id - 最新話の判別に使用
     */
    meta: PropTypes.object,
    /** 動画の価格、配信期間情報など */
    product: PropTypes.object,
    // 月額見放題
    /** 動画のコース情報 */
    course: PropTypes.shape({
      course_id: PropTypes.number,
      schema_id: PropTypes.number,
      name: PropTypes.string,
      active_pricing: PropTypes.object,
      values: PropTypes.object
    }),
    /** 動画の視聴ステータス情報 */
    status: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      isDeviceNotAvailable: PropTypes.bool,
      limitDate: PropTypes.string,
      isPossible: PropTypes.bool,
      isBelonging: PropTypes.bool
    }),
    /** 動画エピソードページャーの描画に使用 */
    seriesId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** 動画エピソードページャーの描画に使用 */
    seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isPackOnly: PropTypes.bool,
    loaded: PropTypes.bool,
    /** 自動再生On/Off */
    auto_start: PropTypes.bool,
    /** 商品タイプ */
    product_type:PropTypes.number,
    /** アプリ用チャンネルID */
    channel:PropTypes.string,
    /** DIOメタID */
    meta_id: PropTypes.number,
    /** ssai(動画広告を挿入する技術)の設定id */
    ssai_ad_config_id:PropTypes.string,
    /** ユーザ識別パラメータ */
    uid:PropTypes.string,
    /** コンテンツ識別パラメータ */
    ga_cid:PropTypes.string,
    /** サブタイトルを表示するか */
    subtitle:PropTypes.bool,
    /** ユニバーサルリンクを使用するか否か */
    useUniversalLinks: PropTypes.bool,
    /** ボタンのテキストを`アプリで視聴`というテキストにするか */
    isAppWatch: PropTypes.bool
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  static defaultProps = {
    onClickPlay: () => {},
    inViewTerm: false,
    metaName: '',
    renderLinkLoginOrMyPage: false,
    renderLinkAppLogin: false,
    isPackOnly: false,
    auto_start: true,
    loaded: false,
    product_type: 1,
    channel: 'ex',
    meta_id: null,
    ssai_ad_config_id: '',
    uid: '',
    ga_cid: '',
    subtitle: false,
    useUniversalLinks: false,
    isAppWatch: false
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      isLoggedIn: webApp.utils.isLoggedIn(this.context)
    }
  }

  componentDidMount() {}

  /**
   * 最新話判別
   */
  isLatest() {
    const { meta, episodes } = this.props
    const latest = _.get(episodes, [0]) || {}
    return (
      meta && latest && _.get(meta, ['meta_id']) === _.get(latest, ['meta_id'])
    )
  }

  render() {
    const {
      seriesId,
      seasonId,
      meta,
      product,
      products,
      status,
      course,
      season,
      episodes,
      onClickPlay,
      inViewTerm,
      metaName,
      renderLinkAppLogin,
      renderLinkLoginOrMyPage,
      isPackOnly,
      className,
      loaded,
      auto_start,
      product_type,
      channel,
      meta_id,
      ssai_ad_config_id,
      uid,
      ga_cid,
      subtitle,
      useUniversalLinks,
      isAppWatch
    } = this.props
    const isApp = webApp.utils.isApp(this.context)
    const episodeId = _.get(meta, ['id'], '')
    const isFree = whatKindOfVideo(this.props.status.displayMode) === 'FREE'

    const todayData = new Date(Date.now())
    let salesEndAt =
      _.get(this.props, ['product', 'sales_end_at']) ||
      _.get(this.props, ['products', 0, 'sales_end_at'])

    const isDeadline = (() => {
      // 販売終了期間が設定されていなかったら表示
      if (salesEndAt === null || salesEndAt === undefined) return false

      // 販売終了期間が未来だったら表示
      salesEndAt = new Date(salesEndAt)

      if (todayData < salesEndAt) return false

      return true
    })()

    return (
      <div className={`c-storyMeta-inBox-actions ${className}`}>
        {(!isApp || isPlayable(status)) && (
          <PlayLink
            seriesId={seriesId}
            seasonId={seasonId}
            episodeId={episodeId}
            price={getPrice(meta, product, status, course)}
            product={product}
            status={status}
            onClickPlay={onClickPlay}
            disabled={!inViewTerm}
            renderLinkLoginOrMyPage={renderLinkLoginOrMyPage}
            isPackOnly={isPackOnly}
            auto_start={auto_start}
            product_type={product_type}
            channel={channel}
            meta_id={meta_id}
            ssai_ad_config_id={ssai_ad_config_id}
            uid={uid}
            ga_cid={ga_cid}
            subtitle={subtitle}
            useUniversalLinks={useUniversalLinks}
            isAppWatch={isAppWatch}
          />
        )}

        {/* for PC&SP */}
        <AddButtonBlock
          favoriteType={FAVORITE_TYPE.META}
          favoriteId={meta.meta_id}
          title={metaName}
          showLoginButton={
            isApp && renderLinkAppLogin && !this.state.isLoggedIn
          }
          showAddMyList={isApp && !isPlayable(status)}
          officialUrl={
            meta.schema_id === 8 && season !== null
              ? season.values.evis_OfficialSiteURL
              : null
          }
          isPurchased={status.isPurchased}
          isFree={isFree}
          isLoaded={loaded}
          isDeadline={isDeadline}
          showPurchasedBrowserBtn
        />
        <EpisodePager
          meta={meta}
          seriesId={seriesId}
          seasonId={seasonId}
          episodes={episodes}
          course={course}
        />
      </div>
    )
  }
}
