import React from 'react'
import { StyledA } from './style'

/**
 * ## ヒラメキのタネ メニューボタンリンク MenuButton
 *
 * 親のMenuコンポーネントに使用される想定。
 *
 * `props.href`の値とボタンテキストの値を受け取り、aタグを配置するコンポーネント。
 */
const MenuButton = ({ href, isSmp, children }) => {
  return (
    <StyledA isSmp={isSmp} href={href}>
      {children}
    </StyledA>
  )
}

export default MenuButton
