import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '../../../../hooks/useMediaQuery'

import SliderCardList from './SliderCardList'
import VerticalCardList from './VerticalCardList'

const SlideCards = ({
  heading = '',
  cardDataList = [],
  link = {
    route: {},
    params: {},
    query: {}
  },
  loaded = false
}) => {
  const isSp = useMediaQuery()

  if (isSp) {
    return (
      <>
        {loaded && (
          <VerticalCardList
            heading={heading}
            cardDataList={cardDataList.slice(0, 3)}
            link={link}
          />
        )}
      </>
    )
  }

  return (
    <SliderCardList
      heading={heading}
      cardDataList={cardDataList}
      link={link}
      loaded={loaded}
    />
  )
}

export default SlideCards

SlideCards.propTypes = {
  /** カードリストの見出し */
  heading: PropTypes.string,
  cardDataList: PropTypes.array.isRequired,
  link: PropTypes.object,
  /** フェッチの状況 */
  loaded: PropTypes.bool
}
