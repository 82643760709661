import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../../../../../../../../sketch-platform/ui/routing'

/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../../../../../../exdio/components/style'

/** 必殺仕事人 LP | テレ朝動画の人気コンテンツカード */
const Card = ({ populurContentsItem = [] }) => {
  return (
    <StyledLink href={populurContentsItem.href}>
      <img src={populurContentsItem.src} alt="" />
      <StyledP>{populurContentsItem.title}</StyledP>
    </StyledLink>
  )
}

export default Card

Card.propTypes = {
  /**
   * 人気のコンテンツアイテムを表示
   *
   * @param {Object[]} populurContentsItem - 人気コンテンツアイテムの配列
   * @param {string} populurContentsItem.href - コンテンツアイテムへのリンクURL
   * @param {string} populurContentsItem.src - コンテンツアイテムの画像URL
   * @param {string} populurContentsItem.title - コンテンツアイテムのタイトル
   */
  populurContentsItem: PropTypes.shape({
    href: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string
  })
}

const StyledLink = styled(Link)`
  transition: opacity 0.3s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }

  ${mediaQuery(768)} {
    width: calc(336 / 690 * 100%);
  }
`

const StyledP = styled.p`
  margin-top: 5px;
  font-size: 1.6rem;
  color: #fff;
`
