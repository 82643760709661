import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

/** Twitter埋込みコンポーネント */
const TwitterIframe = ({ height, ...props }) => {
  useEffect(() => {
    const scriptElm = document.createElement('script')
    const oldScriptElm = document.getElementById('scriptForTwitter')
    if (oldScriptElm) {
      oldScriptElm.remove()
    }

    scriptElm.src = 'https://platform.twitter.com/widgets.js'
    scriptElm.async = true
    scriptElm.id = 'scriptForTwitter'
    document.body.appendChild(scriptElm)

    return () => {
      const scriptElm = document.getElementById('scriptForTwitter')
      document.body.appendChild(scriptElm)
    }
  }, [])

  return (
    <div className="common-box-twitter">
      <a
        className="twitter-timeline"
        data-height={height}
        href="https://twitter.com/tv_asahi_douga?ref_src=twsrc%5Etfw"
      />
    </div>
  )
}

export default TwitterIframe

TwitterIframe.propTypes = {
  height: PropTypes.number
}

TwitterIframe.defaultProps = {
  height: 384
}
