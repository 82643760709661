import React from 'react'
import PropTypes from 'prop-types'
import DropdownItem from './DropdownItem'
import NavLink from './NavLink'
import useMediaQuery from '../../../hooks/useMediaQuery'

/** style */
import styled, { css } from 'styled-components'
import { mediaQuery, fixColors } from '../../../exdio/components/style'
import { StyledNavLink } from './style'

/** ナビゲーション > 各項目 */
const NavItem = (
  {
    menu = {
      title: '',
      route: '',
      children: '',
      className: '',
      url: '',
      target: '',
      params: {}
    },
    toggleNavMenu = () => {},
    show = {},
    ...props
  },
  context
) => {
  const config = context.models.config.data
  const navigationMenu = config.global_navigation
  const isSmp = useMediaQuery()
  // .genreSvodと.genreAnotherは同等なので、.genreSvodに集約
  const menuIdx = navigationMenu.indexOf(menu)
  const open = Boolean(show[`subMenu${menuIdx}`])

  return (
    <StyledLi className={menu.className || ''} {...props}>
      {menu.subNavigation ? (
        <React.Fragment>
          <StyledButton
            key="anchor"
            open={open}
            onClick={() => toggleNavMenu(`subMenu${menuIdx}`)}
          >
            {menu.title}
          </StyledButton>
          {!isSmp && (
            <DropdownItem
              menu={menu}
              toggleNavMenu={toggleNavMenu}
              show={show}
            />
          )}
        </React.Fragment>
      ) : (
        <NavLink menu={menu} toggleNavMenu={toggleNavMenu} />
      )}
    </StyledLi>
  )
}

export default NavItem

NavItem.contextTypes = {
  models: PropTypes.object
}

NavItem.propTypes = {
  /** ナビゲーションドロップダウン開閉処理 */
  toggleNavMenu: PropTypes.func.isRequired,
  /** ナビゲーション毎の値 */
  menu: PropTypes.shape({
    title: PropTypes.string.isRequired,
    route: PropTypes.string,
    children: PropTypes.string,
    className: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
    params: PropTypes.object
  }).isRequired,
  /** ナビゲーションドロップダウン開閉状態 */
  show: PropTypes.object.isRequired
}

const StyledLi = styled.li`
  margin-right: 16px;
  position: relative;
  ${mediaQuery('mx')} {
    margin-right: 14px;
  }
  ${mediaQuery()} {
    margin-right: 0;
  }
  &.iconHome {
    ${mediaQuery()} {
      margin-right: 2px;
      > a img {
        width: 20px;
      }
    }
  }
  &.exTheaterOnlineBtnWithSeparator {
    position: relative;
    margin-right: 30px;
    ${mediaQuery()} {
      margin-right: 20px;
    }
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 18px;
      background: #c7c7c7;
      position: absolute;
      right: -10px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .exto-nav-icon {
      max-width: 82px;
    }
  }
  &.separator {
    position: relative;
    margin-right: 35px;
    ${mediaQuery()} {
      margin-left: 10px;
    }
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 18px;
      background: #666;
      position: absolute;
      right: -23px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
`

const StyledButton = styled.button`
  ${StyledNavLink}

  /* ドロップダウンメニューが設定されている場合 */
  background-color: transparent;
  font-family: inherit;
  border: none;
  position: relative;
  padding-right: 26px;
  font: inherit;
  color: #343434;
  ${mediaQuery('sm', 'min')} {
    font-size: 1.2rem;
  }
  &::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-right: 2px solid ${fixColors.colorFont};
    border-bottom: 2px solid ${fixColors.colorFont};
    transform: rotate(45deg);
    position: absolute;
    right: 10px;
    top: 18px;
    ${mediaQuery()} {
      right: 10px;
      top: 11px;
    }
  }

  ${({ open }) =>
    open &&
    css`
      background: #fff;
      &::after {
        transform: rotate(-135deg);
        top: 21px;
        ${mediaQuery()} {
          top: 11px;
        }
      }
    `}
`
