import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import Cookie from 'js-cookie';
import routes from '../../../../common/routes';

import $ from 'jquery';
import GalleryContent from '../watch/GalleryContent';
import AnotherContent from '../appli/watch/AnotherContent';
import HeaderOld from '../../../../common/components/appli/HeaderOld';
import ExVideoPlayer from '../../player/ExVideoPlayer';
import ExLivePlayer from '../../player/ExLivePlayer';
import MainViewLink from '../../../../common/components/MainViewLink';

var copyright
let setHeight;
let imgHeight;
class TmplateApp extends Component {

  static getPaths = function(models, options, props) {
    return [];
  };

  static propTypes = {
    history: PropTypes.object,
    id: PropTypes.string,
    metadata: PropTypes.object.isRequired,
  };

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    getModelData: PropTypes.func,
    activeMetaId: PropTypes.number,
    webApp: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);

    this.state = {
      dispose: null,
      generation: -1,
      fetchDataError: null
    };
    this.setResize = this.setResize.bind(this);
    this.imgResize = this.imgResize.bind(this);
    this.previewUrlList = this.context.models.config.data.preview_url_list;
    this.cookieRubocopPrefix = this.context.models.config.data.cookie_rubocop_prefix;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData(this.props);
    window.addEventListener('resize', this.setResize);
    window.addEventListener('resize', this.imgResize);

    this.props.setHeadTags(this.props.metadata, this.context.webApp.utils, this.context.models.config.data);
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
    window.removeEventListener('resize', this.setResize);
    window.removeEventListener('resize', this.imgResize);
  }

  setResize() {
    let imgElement = $(".cnt_ph_main");
    if(imgElement != "undefined"){
      $(imgElement).height($(imgElement).width() * 0.562);
    }
  }

  imgResize() {
    let imgEle = $("#slides");
    if(imgEle != "undefined"){
      $(imgEle).height($(imgEle).width() * 0.562);
    }
  }

  componentDidUpdate(){
    setHeight = this.setResize();
    imgHeight = this.imgResize();
  }

  fetchData(props) {
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, {dispose});
    } else {
      this.setState({dispose});
    }

    evaluator.then(res => {
      delete this.state[JSON.stringify(paths)];

      const newState = {
        fetchDataError: null,
        dispose: null,
        generation: this.model.getVersion(rootPath)
      };

      if (this._isMounted) this.setState(newState);
      else Object.assign(this.state, newState);
    }).catch((e) => {
      const newState = {
        fetchDataError: e,
        fetchingMoreRows: undefined,
        dispose: null
      };
      delete this.state[JSON.stringify(paths)];
      if (this._isMounted) this.setState(newState);
      else Object.assign(this.state, newState);
    });
  }

  renderHeader() {
    const routeHandler = this.props.routeHandler || this.context.routeHandler;
    const term = routeHandler.query.q || decodeURIComponent(routeHandler.params.term || "");
    const props = {
      fixed: true
    };
    if (term) {
      props.term = term;
    }
    return (
      <HeaderOld {...props} />
    );
  }

  nextPrevText(thumbnail_url, e) {
    return(
      <div id="slides">
        <img src={thumbnail_url} width="100%" />
        <span className="slides_back"></span>
        <p className="slides_ready">{e}</p>
      </div>
    )
  }

  render() {
    if (this.props.metadata.values && this.props.metadata.values.evis_Copyright) {
      copyright = this.props.metadata.values.evis_Copyright
    } else {
      copyright = '(C)テレビ朝日'
    }

    var deliveryEndAt = '　'
    var longSynopsis = '　'
    var duration = ''
    var onAirAt = ''
    var cast_area = ''
    var title = ''
    var meta_id = ''
    var target_ref_id = ''
    var target_ad_config_id = ''
    if (this.props.metadata){
      // MEMO: タイトル
      if (this.props.metadata.name && title == '') {
        title = this.props.metadata.name
      }

      // MEMO: meta_id
      if (this.props.metadata.meta_id && meta_id == '') {
        meta_id = this.props.metadata.meta_id
      }
      // LIVE配信 主/副切替ID
      if (this.props.metadata.values.target_ref_id && target_ref_id == '') {
        target_ref_id = this.props.metadata.values.target_ref_id
      }
      // LIVE配信 主/副切替広告ID
      if (this.props.metadata.values.target_ad_config_id && target_ad_config_id == '') {
        target_ad_config_id = this.props.metadata.values.target_ad_config_id;
      }
      // MEMO: 配信期間終了日
      if (this.props.metadata.delivery_end_at && deliveryEndAt == '　') {
        deliveryEndAt = moment(this.props.metadata.delivery_end_at).format("配信期間：YYYY年M月D日 HH:mmまで")
      }

      // MEMO: 時間
      if (this.props.metadata.duration && duration == ''){
        duration = this.props.metadata.duration;
        let seconds = duration % 60; let minutes = ((duration - seconds) / 60) % 60; let hour = parseInt(duration / 3600, 10);
        if (hour > 0) {
          if (minutes > 0) {
            duration = `${hour}時間${minutes}分`;
          } else {
            duration = `${hour}時間`;
          }
        } else if (minutes > 0) {
          duration = `${minutes}分`;
        } else {
          duration = `${seconds}秒`;
        }
        duration = `時間：${duration}`;
      }

      if (this.props.metadata.values) {
        // MEMO: エピソードあらすじ(長)
        if (this.props.metadata.values.evis_EpisodeLongSynopsis && longSynopsis == '　') {
          var regex = /(\n)/g
          longSynopsis = this.props.metadata.values.evis_EpisodeLongSynopsis.split(regex).map(function (line, i) {
            if (line.match(regex)) {
              return React.createElement('br', {key: i})
            }
            else {
              return line;
            }
          });
        }

        // MEMO: 放送日
        if (this.props.metadata.values.avails_ReleaseHistoryOriginal && onAirAt == '') {
          onAirAt = moment(this.props.metadata.values.avails_ReleaseHistoryOriginal).format("放送日：YYYY/M/D")
        }

        if (this.props.metadata.values.evis_Actors) {
          cast_area = (
            <div className="info-cast">
              <h2 className="details-inner-title"><span className="icon-cast"></span>キャスト</h2>
              <p className="episode-cast">{this.props.metadata.values.evis_Actors}</p>
            </div>
          )
        }
      }
    }

    var titleArtwork;
    if (this.props.metadata && this.props.metadata.thumbnail_url){
      titleArtwork = (<div
        className={classnames('title-artwork avod_ep_list_thumb thumbnail', {
          'double-wide': this.props.isDoubleWide === true && this.props.isTallPanel !== true
        })}
        style={{backgroundImage: `url('${this.props.metadata.thumbnail_url}')`}}>
          <img src="/images/exdio/play.png" className="btn_play" />
        </div>
      );
    }
    const duration_and_air = duration && onAirAt ? `${duration}　｜　${onAirAt}` : duration ? duration : onAirAt ? onAirAt : ""

    let playerArea;
    const prevTime = Cookie.get(this.previewUrlList + this.cookieRubocopPrefix);

    let playerIcon = (
      <img src="/images/exdio/play.png" id="overPlay" width="78"/>
    );
    let setPlayerIcon;
    if(this.deviceInfo.isIOS || this.deviceInfo.isAndroid) {
      setPlayerIcon = playerIcon;
    }

    if (moment(prevTime).isBefore(this.props.metadata.delivery_start_at)) {
      var thumbnail_url = (this.props.metadata.meta_schema_id == 4) ? this.props.metadata.values.livectl_image_before_stream_url : this.props.metadata.thumbnail_url;
      playerArea = this.nextPrevText(thumbnail_url, 'まもなく配信予定');
    } else if (moment(prevTime).isAfter(this.props.metadata.delivery_end_at)) {
      var thumbnail_url = (this.props.metadata.meta_schema_id == 4) ? this.props.metadata.values.livectl_image_after_stream_url : '/images/exdio/nextImage.jpg';
      playerArea = this.nextPrevText(thumbnail_url, '次回の配信もお楽しみに！');
    } else {
      if (this.props.metadata.meta_schema_id == 4 && this.props.metadata.values.target_vod_status === 'not_created') {
        playerArea = (
            <div id="slides" style={imgHeight}>
                <ExLivePlayer
                model={this.model}
                meta_id={target_ref_id ? target_ref_id : meta_id}
                auto_start={true}
                play_head_time={0}
                volume={1}
                is_mute={false}
                controller="default"
                bitrate="auto"
                product_type={1}
                uid=""
                ad_config_id={target_ad_config_id}
                ad_insertion={this.props.metadata.values.livectl_ad_insertion}
                thumbnail_url={this.props.metadata.thumbnail_url}
                subtitle={this.props.metadata.values.subtitle}
                />
              <div className="icon_area">
              {setPlayerIcon}
              </div>
            </div>
        );
      } else {
        meta_id = (this.props.metadata.meta_schema_id == 4 && target_ref_id) ? target_ref_id : meta_id;
        playerArea = (
          <div id="slides" style={imgHeight}>
            <ExVideoPlayer
              model={this.model}
              meta_id={meta_id}
              auto_start={true}
              play_head_time={0}
              volume={1}
              is_mute={false}
              controller="default"
              bitrate="auto"
              product_type={1}
              uid=""
              thumbnail_url={this.props.metadata.thumbnail_url}
              subtitle={this.props.metadata.values.subtitle}
            />
            <div className="icon_area">
            {setPlayerIcon}
            </div>
          </div>
        );
      }
    }

    return (
      <div>
        {this.renderHeader()}
        <div id="app_global_nav_space"></div>
        <div className={classnames("watch", "copy_block")}>
          <div>
            <p>アプリ用ライブテンプレート</p>
            <div id="avod_head_contents_wrap" className="clearfix">
              <div className="avod_inner">
                <div className={classnames("avod_main_ep", "clearfix")}>
                  <div className={classnames("avod_main_ep_L", "avod_video")}>
                    <div className="cnt_ph_main"  style={setHeight}>
                      {playerArea}
                    </div>
                  </div>
                  <div className="avod_main_ep_R">
                    <div className="avod_main_ep_detail">
                    <div className="main-post-header">
                    <p className={classnames("main-post-node", "main-delivery-end")}>{deliveryEndAt}</p>
                      <div className="main-post-title">
                        <h2 className="main-post-p-title">{title}</h2>
                      </div>
                        <p className="main-post-air">{duration_and_air}</p>
                      </div>
                    </div>
                  </div>
                  <div className="avod_main_ep_detail_right">
                    <div className="avod_main_ep_detail_right_inner">
                      <p className="avod_main_ep_back">
                        <span><img src="/images/exdio/pc_bullet.png" /></span>
                        <MainViewLink to={routes.app} className="main_api">
                          テレ朝キャッチアップ一覧
                        </MainViewLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="avod_contents_wrap">
            <div className="avod_inner">
              <div id="app_contents_main" className="avod_ep_contents_main">
                <div className="js-main-bnr">
                </div>
                <div className="avod_ep-main-container row app_avod_ep-main-container">
                  <div className="new_buy_info">
                    <div className="info-wrap">
                      <p className="episode-story">{longSynopsis}</p>
                    </div>
                    {cast_area}
                  </div>
                </div>
                <div className="cpr app_cpr">
                  <p className="program-copyright">{copyright}</p>
                </div>
              </div>
            </div>
          </div>
          <AnotherContent
            keyPrefix={"catchup"}
            mainId={this.props.metadata.meta_id}
            sortOrder={'su'}
            program_link={true}
            model={this.model} />
        </div>
      </div>
    );
  }
}

export default TmplateApp;
