import { includes, size } from 'lodash'
import { LOCAL_STORAGE_KEY } from '../../../../constants/app'

/** 全角->半角変換、大文字->小文字変換 */
const cleanseWord = (str) => {
  return str
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) =>
      String.fromCharCode(s.charCodeAt(0) - 65248)
    )
    .toLowerCase()
}

/** ひらがな->カタカナ */
const hiraToKana = (str) => {
  return str.replace(/[\u3041-\u3096]/g, (match) => {
    const chr = match.charCodeAt(0) + 0x60
    return String.fromCharCode(chr)
  })
}

/** 検索ワードの整形 */
export const formatKeywords = (keywords = '') => {
  const keywordArr = keywords.split(/\s/) // 空白文字で区切って配列にする
  const formattedKeywords = keywordArr.reduce((prev, current) => {
    let word = cleanseWord(current)
    word = hiraToKana(word)
    return [...prev, word]
  }, [])
  return formattedKeywords
}

/** sortedByの値からAPIで使用するキーを取得 */
export const getMetaKey = (sortedBy = '') => {
  switch (sortedBy) {
    case 'avails_release_history_original_newer': // 放送が新しい順
      return {
        sort: 'values.avails_ReleaseHistoryOriginal',
        order: 'desc'
      }
    case 'avails_release_history_original_older': // 放送が古い順
      return {
        sort: 'values.avails_ReleaseHistoryOriginal',
        order: 'asc'
      }
    case 'delivery_start_at_newer': // 配信が新しい順
      return {
        sort: 'delivery_start_at',
        order: 'desc'
      }
    case 'delivery_start_at_older': // 配信が古い順
      return {
        sort: 'delivery_start_at',
        order: 'asc'
      }
    case 'jp_syllabary_order_asc': // あいうえお順（昇順）
      return {
        sort: 'name_ruby',
        order: 'asc'
      }
    case 'jp_syllabary_order_desc': // あいうえお順（降順）
      return {
        sort: 'name_ruby',
        order: 'desc'
      }
    case 'play_time_longer': // 動画が長い順
      return {
        sort: 'values.duration',
        order: 'desc'
      }
    case 'play_time_shorter': // 動画が短い順
      return {
        sort: 'values.duration',
        order: 'asc'
      }
    default:
      return {
        sort: 'values.avails_EpisodeNumber',
        order: 'asc'
      }
  }
}

/**
 * 視聴済みのID配列を取得
 * @param childEpisodeIds {number[]} - フィルタリング対象のすべてのepisodeId
 * @param onlyWatchedAll {bool} - 最後まで視聴したものを視聴済みとする
 */
export const getWatchedIds = (childEpisodeIds = [], onlyWatchedAll = false) => {
  const defaultValue = []
  if (!childEpisodeIds.length) return defaultValue
  const ls = window.localStorage.getItem(LOCAL_STORAGE_KEY)
  if (ls && ls !== 'null') {
    // setItemでnullをセットするとnullという文字列になる
    const resumeInfos = JSON.parse(ls)

    // HACK
    // episodeIdが入っているもののみを取得する
    // Appではmetaidが文字列として渡されるため、typeofで比較しない
    let metaIdsArr = resumeInfos.filter((v) => {
      return String(v.metaid).match(/^[0-9]+$/)
    })
    if (size(metaIdsArr)) {
      if (onlyWatchedAll) {
        metaIdsArr = metaIdsArr.filter(
          (v) =>
            Number(v.lastPlayed) === -1 ||
            Number(v.lastPlayed) === Number(v.duration) // アプリのときすべて再生してもlastPlayedが-1にならない
        )
      }
      metaIdsArr = metaIdsArr.map((v) => Number(v.metaid))
      metaIdsArr = metaIdsArr.filter((v) => includes(childEpisodeIds, v))
    }
    return metaIdsArr
  }
  return defaultValue
}

/**
 * フィルターで除外するepisodeIdリスト
 * @param childEpisodeIds {number[]} - フィルタリング対象のすべてのepisodeId
 * @param filteredBy {watched|not_watched} - フィルター条件
 * @param onlyWatchedAll {bool} - 最後まで視聴したものを視聴済みとする
 */
export const getNotIdArr = (
  childEpisodeIds = [],
  filteredBy = '',
  onlyWatchedAll = false
) => {
  /** 視聴済みのID */
  const watchedIds = getWatchedIds(childEpisodeIds, onlyWatchedAll)
  const notIdArr = (() => {
    switch (filteredBy) {
      case 'watched': // 視聴済みに含まれないIDを抽出
        return childEpisodeIds.filter(
          (id) => includes(watchedIds, Number(id)) === false
        )
      case 'not_watched': // 視聴済みに含まれるIDを抽出
        return childEpisodeIds.filter(
          (id) => includes(watchedIds, Number(id)) === true
        )
      default:
        return []
    }
  })()

  return notIdArr
}
