import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import routes from '../../../../routes'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import NewsContent from './NewsContent'
import LinkHeader from '../../../renewal/LinkCol/LinkHeader/'
import useIsMounted from '../../../../../hooks/useIsMounted'

/** style */
import { fixColors } from '../../../../../exdio/components/style'

/** ヘッダー > ハンバーガーメニュ > ニュース */
const News = (
  { onClick, articles, show, newsCount, setNewsCount, ...props },
  context
) => {
  const config = context.models.config.data
  const isApp = webApp.utils.isApp(context)
  const intervalTime = config.extras.header_menu_news_interval * 1000
  const articleIntervalId = useRef(null)
  const isMounted = useIsMounted()

  /** ニュース更新 */
  const articleInterval = () => {
    if (articles === null || (!articles.length && articleIntervalId.current))
      return
    articleIntervalId.current = setInterval(() => {
      setNewsCount((nextId) => (nextId + 1) % articles.length)
    }, intervalTime)
  }

  useEffect(() => {
    return () => {
      clearInterval(articleIntervalId.current)
    }
  }, [])

  useEffect(() => {
    if (show && isMounted) articleInterval()

    return () => {
      if (articleIntervalId.current) clearInterval(articleIntervalId.current)
    }
  }, [show, isMounted])

  return (
    <StyledDiv1 {...props}>
      <header>
        <LinkHeader
          HtmlElement="h3"
          route={routes[isApp ? 'app_information' : 'information']}
          onClick={onClick}
        >
          ニュース
        </LinkHeader>
      </header>
      {!articles && <StyledDiv2 noNews />}
      {articles && articles.length === 0 && (
        <StyledDiv2 noNews>お知らせはありません</StyledDiv2>
      )}
      {articles && articles.length > 0 && (
        <StyledDiv2>
          {articles.map((article, idx) => {
            if (idx === newsCount)
              return (
                <NewsContent
                  key={article.id}
                  article={article}
                  onClick={onClick}
                  intervalTime={intervalTime}
                />
              )
            return null
          })}
        </StyledDiv2>
      )}
    </StyledDiv1>
  )
}

export default News

News.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  })
}

News.propTypes = {
  /** GlobalNavigation.js > showSubMenu/showSearch/showMemberMenu 開閉処理 */
  onClick: PropTypes.func.isRequired,
  /** お知らせ一覧 */
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number
    })
  ),
  /** ハンバーガーメニュ開閉状態 */
  show: PropTypes.bool.isRequired,
  /** お知らせの表示位置 */
  newsCount: PropTypes.number.isRequired,
  /** お知らせの表示位置 setState */
  setNewsCount: PropTypes.func.isRequired
}

const StyledDiv1 = styled.div`
  width: 100%;
`

const StyledDiv2 = styled.div`
  background: #fff;
  margin-top: 1px;
  color: ${fixColors.colorFont};
  height: auto;
  ${({ noNews }) =>
    noNews &&
    css`
      height: 5.7rem;
      padding: 2rem;
    `}

  // [HACK] どこで使用予定のstyleか不明
  .no-news {
    padding: 2rem;
  }
`
