import React, { memo } from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { mediaSp, mainColor } from '../common/styles'

/** 全英オープン IconText */
const IconText = ({ icon, children, ...props }) => (
  <StyledP icon={icon} {...props}>{ children }</StyledP>
)

export default memo(IconText)

IconText.propTypes = {
  /**
   * アイコンに使用する文字コード
   * バックスラッシュはエスケープする
   */
  icon: propTypes.string
}

IconText.defaultProps = {
  icon: ''
}

const StyledP = styled.p`
&::before {
  margin-right: 5px;
  color: ${mainColor};
  font-size: 1.2rem;
  font-family: 'Material Icons';
  content: '${props => props.icon}';

  @media ${mediaSp} {
    margin-right: 6px;
  }
}
`
