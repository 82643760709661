import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { mediaSp } from './styles'
import useMediaQuery from '../../../../hooks/useMediaQuery'

/** チャット機能 */
const Chat = ({ hash = '', setPostMessage = () => {}, ...props }, context) => {
  const config = context.models.config.data
  const isSp = useMediaQuery()
  const parentUrl = encodeURIComponent(window.location.href) || ''
  let src = `${config.chat.src}?hash=${hash}`
  if (parentUrl) src += `&parent_url=${parentUrl}`

  /** useState */
  const [maxHeight, setMaxHeight] = useState('800px')

  /** iframeの高さ上限を計算(PC) */
  const getMaxHeight = () => {
    const header = document.querySelector('.c-head, .p-gariben__header')
    const headerHeight = header && header.offsetHeight
    const headSub = document.querySelector('.c-head-sub')
    const headSubHeight = headSub && headSub.offsetHeight
    const containerMarginTop = 30 // .p-episode_live__containerのmargin-top
    const iframeMarginBottom = 30 // iframeと画面下部とのマージン
    const tabHeight = 55 // TabPanelのタブ
    const sum =
      headerHeight +
      headSubHeight +
      containerMarginTop +
      iframeMarginBottom +
      tabHeight

    return isSp ? '' : `calc(100vh - ${sum}px)`
  }

  /** 画面サイズの変更に応じてSP判定 */
  const onResizeHandler = () => {
    setMaxHeight(getMaxHeight())
  }

  /** プレイヤー操作をチャットに送信 */
  const postMessage = (seekTime) => {
    const chatFrame = document.getElementById('chatArea')
    chatFrame.contentWindow.postMessage({ seekTime }, '*')
  }

  const handleSeekTime = (e) => {
    const { origin, data } = e
    if (!(config.chat.origin.includes(origin) && data)) return

    const seekTime = get(data, ['seekTime'])
    if (typeof seekTime !== 'number') return

    const DioPlayer = document.getElementById('DIOplayer')
    const videoElm = DioPlayer ? DioPlayer.querySelector('video') : null
    if (videoElm) {
      videoElm.currentTime = seekTime
    }
  }

  /** useEffect */
  useEffect(() => {
    /** 初期表示は開いた状態 */
    window.addEventListener('resize', onResizeHandler)

    setPostMessage(postMessage)

    // eslint-disable-next-line valid-typeof
    if (typeof window !== undefined) {
      window.addEventListener('message', handleSeekTime, false)
    }

    /** maxHeightを取得・セット */
    setMaxHeight(getMaxHeight())

    return () => {
      window.removeEventListener('resize', onResizeHandler)
    }
  }, [])

  if (!hash) return null

  return (
    <StyledIframe
      src={src}
      width="100%"
      height="800"
      maxHeight={maxHeight}
      id="chatArea"
      frameBorder="0"
      scrolling="no"
      {...props}
    />
  )
}

export default Chat

Chat.propTypes = {
  hash: PropTypes.string.isRequired,
  setPostMessage: PropTypes.func
}

Chat.contextTypes = {
  models: PropTypes.shape({
    config: PropTypes.shape({
      data: PropTypes.shape({
        chat: PropTypes.shape({
          src: PropTypes.string,
          origin: PropTypes.arrayOf(PropTypes.string)
        })
      })
    })
  })
}

const StyledIframe = styled.iframe`
  width: 100%;
  min-height: 478px;
  max-height: ${({ maxHeight }) => maxHeight};
  background-color: #fff;
  overscroll-behavior-y: none;

  @media ${mediaSp} {
    min-height: auto;
    max-height: none;
    height: 100%;
  }
`
