import React from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'

/* components */
import { LOGIN_CONTENTS } from '../../../../../../constants/app'

/* style */
import { StyledNav, StyledUl, StyledLi, StyledButton } from './styles'

/** ログインコンテンツ ナビ */
const Nav = ({ currentContent = {}, onChangeContent = () => {} }, context) => {
  const isApp = webApp.utils.isApp(context)

  const Tabs = () => {
    return Object.keys(LOGIN_CONTENTS).map((contentKey) => {
      if (isApp && contentKey === 'SIGNUP') return null

      const content = LOGIN_CONTENTS[contentKey]

      return (
        <StyledLi key={content.id} className="c-login-head-nav-inBox-btn">
          <StyledButton
            type="button"
            isActive={currentContent === content}
            onClick={(e) => onChangeContent(content, e)}
          >
            {content.label}
          </StyledButton>
        </StyledLi>
      )
    })
  }

  return (
    <StyledNav>
      <StyledUl>
        <Tabs />
      </StyledUl>
    </StyledNav>
  )
}

export default Nav

Nav.propTypes = {
  onChangeContent: PropTypes.func
}

Nav.contextTypes = {
  routeHandler: PropTypes.object
}
