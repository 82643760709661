import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import window from 'global'
import { createGlobalStyle } from 'styled-components'
import { MYPAGE_CONTENTS } from '../../../../../constants/app'
import webApp from '../../../utils/exdioWebAppUtils'
import routes from '../../../../common/routes'

/* components */
import MPMaintenanceMessage from '../../../../common/components/renewal/MPMaintenanceMessage'
import AnnounceModal from '../../../../common/components/renewal/AnnounceModal'
import ImageSlider from '../../../../common/components/renewal/ImageSlider'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import Purchased from '../mypage/Purchased'
import PurchasedEpisodes from '../mypage/PurchasedEpisodes'
import PurchasedPack from '../mypage/PurchasedPack'
import PurchasedPlan from '../mypage/PurchasedPlan'
import MyList from '../mypage/MyList'
import Account from '../mypage/Account'
import AccountSetting from '../mypage/AccountSetting'
import AccountEditProfile from '../mypage/AccountEditProfile'
import AccountEditInfo from '../mypage/AccountEditInfo'
import AccountEditPass from '../mypage/AccountEditPass'
import AccountEditEmail from '../mypage/AccountEditEmail'
import AccountDelete from '../mypage/AccountDelete'
import AccountPayment from '../mypage/AccountPayment'
import AppTop from '../mypage/AppTop'
import Coin from '../mypage/Coin'
import ViewLicenseContent from '../mypage/ViewLicenseContent'
import CancelLicenseContent from '../mypage/CancelLicenseContent'
import MemberSetting from '../mypage/MemberSetting'
import Footer from '../../../../common/components/appli/StaticFooter'

/* style */
import { mediaQuery } from '../../style'
import StickyLoginButton from '../mypage/StickyLoginButton'

/** マイページ(マイリスト, 購入済) */
export default class MyPageContent extends Component {
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    webApp: PropTypes.object,
    history: PropTypes.object,
    updateUserInfoSubscribe: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = this.context.models.config.data

    this.state = {
      imageSliderObjects: [],
      newsArticles: [],
      popupArticles: [],
      currentContent: MYPAGE_CONTENTS.APPTAB.APPTOP,
      startPosition: 0,
      showLoginButtons: true
    }

    this.setContent = this.setContent.bind(this)
    this.emitSetState = this.emitSetState.bind(this)
    this.goBackPurchased = this.goBackPurchased.bind(this)
    this.forceUpdateMyPage = this.forceUpdateMyPage.bind(this)
    this.checkScroll = this.checkScroll.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    // 画面の初期表示時、ページ外のリンクからの遷移時に表示する情報判定. history.location.state利用.
    const currentPath = this.context.history.location.pathname
    this.removeHistoryListener = this.context.history.listen((location) =>
      this.setContentByLocation(location, currentPath)
    )
    this.setContentByLocation(this.context.history.location)
    this.context.updateUserInfoSubscribe(this.forceUpdateMyPage)

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context)

    // GTMの更新
    const title = this.context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    this.context.webApp.utils.updateDataLayer(gtmTags)

    window.addEventListener('scroll', this.checkScroll)
    const commonWrapper = document.querySelector('.common-wrapper')
    if (commonWrapper) {
      commonWrapper.addEventListener('scroll', this.checkScroll)
    }

    this.getImageSliderObjects().catch((e) => console.error(e))
    this.getArticles().catch((e) => console.error(e))
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('scroll', this.checkScroll)
    if (typeof this.removeHistoryListener === 'function')
      this.removeHistoryListener()
  }

  forceUpdateMyPage() {
    if (this._isMounted) {
      this.forceUpdate()
    }
  }

  /** location.stateから現在の対象コンテンツを判定し内部コンテンツとして設定する. */
  setContentByLocation(location, prevPath) {
    // URLが変わった場合はcomponentDidMountも走るので処理不要
    if (prevPath && location.pathname !== prevPath) return

    let targetContent
    // 下記画面はルートから表示モードを切り替える必要ある
    if (this.isMatch(location, routes.app_mypage_wallet)) {
      targetContent = MYPAGE_CONTENTS.LINK.COIN
    } else if (this.isMatch(location, routes.app_mypage_view_license)) {
      targetContent = MYPAGE_CONTENTS.ACCOUNT.PLAN
    } else if (this.isMatch(location, routes.app_mypage_cancel_license)) {
      targetContent = MYPAGE_CONTENTS.ACCOUNT.CANCEL_PAYMENT
    } else if (this.isMatch(location, routes.app_mypage_account_setting)) {
      targetContent = MYPAGE_CONTENTS.ACCOUNT.SETTING
    } else if (this.isMatch(location, routes.app_mypage_account_info)) {
      targetContent = MYPAGE_CONTENTS.LINK.ACCOUNT
    } else if (this.isMatch(location, routes.app_mypage_update_credit)) {
      targetContent = MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_CREDIT
    } else if (this.isMatch(location, routes.app_edit_icon)) {
      targetContent = MYPAGE_CONTENTS.MEMBER_SETTING.ICON_EDIT
    } else {
      const content = _.get(location, ['state', 'content']) || {}
      targetContent = Object.values(MYPAGE_CONTENTS)
        .flatMap((v) => Object.values(v))
        .find((v) => v.id === content.id)
    }

    if (targetContent !== this.state.currentContent) {
      this.setContent(targetContent || MYPAGE_CONTENTS.APPTAB.APPTOP)
    }
  }

  isMatch(location, route) {
    const routePath = route.makePath()
    // context.history.replaceで遷移していくるとlocation.pathnameが正しく取れないのでmatchを優先的に使う
    if (typeof location.match === 'function') {
      return location.match(routePath)
    }
    const locationPath = location.pathname
    return routePath === locationPath
  }

  setContent(content) {
    if (this._isMounted) {
      this.setState({ currentContent: content })
    }
  }

  emitSetState(obj, callback = () => {}) {
    if (this._isMounted) {
      this.setState(obj, () => callback())
    }
  }

  /** 画像スライド用パレット取得 */
  getImageSliderObjects() {
    const paletteKey = this.config.palettes.app_image_slider
    const path = [['paletteByKey', paletteKey, ['name', 'objects']]]
    return this.model.fetch(path).then((result) => {
      const palette = _.get(result, ['json', 'paletteByKey', paletteKey]) || {}
      const imageSliderObjects = _.get(palette, 'objects') || []
      if (this._isMounted) {
        this.setState({ imageSliderObjects })
      }
    })
  }

  /** お知らせ取得 */
  getArticles() {
    const publishPeriod = this.context.models.config.data.extras
      .header_menu_news_period
    const newsPath = ['articles', 'news', publishPeriod] // マイリストと同じ期間にしないと表示できないので期間指定にする
    const popupPath = ['articles', 'popup']
    return this.model.fetch([newsPath, popupPath]).then((result) => {
      const newsArticles = _.get(result, ['json', ...newsPath]) || []
      const topPageArticles = _.get(result, ['json', ...popupPath]) || []
      //ポップアップ記事の中でタグにTOPページ-アプリが含まれているものを抽出
      const popupArticles = topPageArticles.filter((article) => {
        const matchTag = article.admin_tags_array.map((tag) => {
          if (tag.indexOf('TOPページ-アプリ') >= 0) return true
        })
        return matchTag.includes(true)
      })

      if (this._isMounted) {
        this.setState({ newsArticles, popupArticles })
      }
    })
  }

  /** トップ画面で表示するマイページコンテンツか */
  isTopContent() {
    const { currentContent } = this.state
    return [
      MYPAGE_CONTENTS.APPTAB.PURCHASED,
      MYPAGE_CONTENTS.APPTAB.MYLIST
    ].includes(currentContent)
  }

  isAppTop() {
    const { currentContent } = this.state
    return [MYPAGE_CONTENTS.APPTAB.APPTOP].includes(currentContent)
  }

  goBackPurchased(e) {
    e.preventDefault()
    if (this._isMounted) {
      this.setState({ currentContent: MYPAGE_CONTENTS.APPTAB.PURCHASED })
    }
  }

  /** マイページ内のコンテンツ切り替え */
  createContents() {
    const { currentContent, currentVodPurchased } = this.state
    switch (currentContent) {
      // 購入済
      case MYPAGE_CONTENTS.APPTAB.PURCHASED:
        return [
          <Purchased
            setContent={this.setContent}
            emitSetState={this.emitSetState}
          />,
          'login'
        ]
      case MYPAGE_CONTENTS.PURCHASED.EPISODE:
        return [
          <PurchasedEpisodes
            {...currentVodPurchased}
            goBack={this.goBackPurchased}
          />,
          'myPageMylist'
        ]
      case MYPAGE_CONTENTS.PURCHASED.PACK:
        return [
          <PurchasedPack
            {...currentVodPurchased}
            goBack={this.goBackPurchased}
          />,
          'myPageMylist'
        ]
      case MYPAGE_CONTENTS.PURCHASED.PLAN:
        return [
          <PurchasedPlan
            {...currentVodPurchased}
            goBack={this.goBackPurchased}
          />,
          'myPageMylist'
        ]

      // マイリスト
      case MYPAGE_CONTENTS.APPTAB.MYLIST:
        return [<MyList />, 'login']

      // アプリTOP
      case MYPAGE_CONTENTS.APPTAB.APPTOP:
        return [<AppTop />, 'login']

      // アカウント情報
      case MYPAGE_CONTENTS.LINK.ACCOUNT:
        return [<Account setContent={this.setContent} />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.SETTING:
        return [<AccountSetting />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.EDIT_PROFILE:
        return [<AccountEditProfile />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.EDIT_INFO:
        return [<AccountEditInfo />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.EDIT_PASS:
        return [<AccountEditPass />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.EDIT_EMAIL:
        return [<AccountEditEmail />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.DELETE:
        return [<AccountDelete />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.PLAN:
        return [<ViewLicenseContent />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.CANCEL_PAYMENT:
        return [<CancelLicenseContent />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.PAYMENT:
        return [<AccountPayment />, 'account']

      // アカウント情報内の各基盤ページ
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_MEMBER:
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_PASSWORD:
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_EMAIL:
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_EMAIL_RESULT:
      case MYPAGE_CONTENTS.MEMBER_SETTING.DELETE_TVASAHI_ID:
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_CREDIT:
      case MYPAGE_CONTENTS.MEMBER_SETTING.ICON_EDIT:
        return [<MemberSetting setting={currentContent} />, 'account']

      // 通帳
      case MYPAGE_CONTENTS.LINK.COIN:
        return [<Coin />, 'coins']
      default:
        return [null, '']
    }
  }

  renderTabs() {
    const { currentContent } = this.state

    const isPurchased = [
      MYPAGE_CONTENTS.APPTAB.PURCHASED,
      MYPAGE_CONTENTS.PURCHASED.EPISODE,
      MYPAGE_CONTENTS.PURCHASED.PACK,
      MYPAGE_CONTENTS.PURCHASED.PLAN
    ].includes(currentContent)

    return Object.values(MYPAGE_CONTENTS.APPTAB).map((tab) => {
      const isCurrent =
        currentContent === tab ||
        (isPurchased && MYPAGE_CONTENTS.APPTAB.PURCHASED === tab)
      return (
        <div key={tab.id} className="c-indexCards-header-tabs-tab">
          <Link
            route={routes.app_mypage}
            state={{ content: tab }}
            className={`c-indexCards-header-tabs-tab-link ${
              isCurrent ? 'current' : ''
            }`}
          >
            <span>{tab.label}</span>
          </Link>
        </div>
      )
    })
  }

  checkScroll() {
    const { startPosition } = this.state
    const currentPos = window.scrollY

    this.setState({
      showLoginButtons: currentPos > startPosition,
      startPosition: currentPos
    })
  }

  render() {
    const {
      imageSliderObjects,
      newsArticles,
      popupArticles,
      showLoginButtons
    } = this.state
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    const [contents, className] = this.createContents()
    const isTopContent = this.isTopContent()
    const isAppTop = this.isAppTop()
    const isDoraemon = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 11003
      : 13509

    const headArticles = newsArticles
      .filter((a) => _.get(a, ['values', 'show_top']))
      .map((a) => ({ ...a, msg: a.display_name }))

    return (
      <div className="common-wrapper">
        <AnnounceModal key="announce-modal" articles={popupArticles} />
        <MPMaintenanceMessage
          key="maintenance-message"
          messages={headArticles}
          seasonId={isDoraemon}
        />
        {(isTopContent || isAppTop || !isLoggedIn) && (
          <ImageSlider key="image-slider" objects={imageSliderObjects} />
        )}

        {((isLoggedIn && isTopContent) || isAppTop) && (
          <div className="c-indexCards">
            <div className="c-indexCards-header">
              <nav className="c-indexCards-header-tabs">
                {this.renderTabs()}
              </nav>
            </div>
            <div
              className={`c-indexCards-cont ${
                isAppTop ? 'apptop_padding' : ''
              }`}
            >
              {contents}
            </div>
          </div>
        )}

        {isLoggedIn && !isTopContent && !isAppTop && (
          <div className={`c-mypage ${className}`}>{contents}</div>
        )}

        {!isLoggedIn &&
          !isAppTop && [
            <div key="no-login-message" className="c-indexCards">
              <div className="c-indexCards-header">
                <nav className="c-indexCards-header-tabs">
                  {this.renderTabs()}
                </nav>
              </div>
              <div className="c-indexCards-cont">
                <p className="c-indexCards-cont-logoutInfo">
                  ログイン後に表示されます
                </p>
                <div key="login-button" className="c-gMenu-logins">
                  <div className="c-gMenu-logins-in">
                    <div className="c-gMenu-logins-login">
                      <Link
                        route={routes.app_login}
                        query={{ redirect: routes.app_mypage.makePath() }}
                        className="c-gMenu-logins-login-link"
                      >
                        ログイン
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ]}

        <Footer className="mp-mt0" />
        {!isLoggedIn && isAppTop && (
          <StickyLoginButton showLoginButtons={showLoginButtons} />
        )}
        <GlobalStyle />
      </div>
    )
  }
}

const GlobalStyle = createGlobalStyle`
  .appli {
    .c-mv {
      overflow: hidden;

      .slick-slide-container {
        padding-bottom: 0;
      }

      .slick-slider {
        padding-bottom: 20px;
      }

      .slick-slide {
        height: auto;
      }

      .slick-dots {
        bottom: -3px;
      }

      .c-indexCards {
        margin-top: 0;
      }
    }
  }
`
