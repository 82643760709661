import styled from 'styled-components'
import {
  mediaQuery,
  breakPoints
} from '../../../../../../exdio/components/style'
import { breakPoints as SailGPbreakPoints } from '../../style'

export const StyledDiv = styled.div`
  position: absolute;
  top: 32px;

  ${mediaQuery(breakPoints.sm)} {
    text-align: center;
    position: initial;
    margin: 20px 0;
  }
`

export const StyledA = styled.a`
  margin-right: 5px;
  display: inline-block;

  ${mediaQuery(SailGPbreakPoints.sp)} {
    margin: 0 5px;
    display: inline-block;
  }
`

export const StyledImg = styled.img`
  width: 30px;
`
