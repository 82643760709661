import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconPlay from '../IconPlay'
import { fixColors, mediaQuery } from '../../../style'

const PlayerCover = ({
  metaName = '',
  thumbnailUrl = '',
  rest = '',
  showRest = false,
  status = {},
  product = {},
  progress = 0,
  onClickPlay = () => {}
}) => {
  return (
    <StyledButton type="button" onClick={onClickPlay}>
      <StyledImg src={thumbnailUrl} alt={metaName} width="1080" height="610" />
      {showRest && <span className="remaining-time">{rest}</span>}
      <SeekBar>
        {progress > 0 && <span style={{ width: `${progress}%` }} />}
      </SeekBar>
      <StyledIconPlay status={status} product={product} />
    </StyledButton>
  )
}

export default PlayerCover

PlayerCover.propTypes = {
  metaName: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  rest: PropTypes.string,
  showRest: PropTypes.bool,
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  product: PropTypes.shape({
    product_id: PropTypes.number,
    name: PropTypes.string,
    original_price: PropTypes.number,
    active_pricing: PropTypes.shape({
      price: PropTypes.number,
      unit: PropTypes.string
    })
  }),
  progress: PropTypes.number,
  onClickPlay: PropTypes.func
}

const StyledButton = styled.button`
  padding: 0;
  background: none;
  border-width: 0;
  position: absolute;
  inset: 0;
`

const StyledIconPlay = styled(IconPlay)`
  margin: auto;
  position: absolute;
  inset: 0;

  ${mediaQuery()} {
    inset: auto 1% 15px auto;
  }
`

const StyledImg = styled.img`
  width: 100%;
`

const SeekBar = styled.span`
  width: 100%;
  height: 6px;
  background-color: rgb(226, 226, 226);
  display: block;
  position: absolute;
  bottom: 0;

  ${mediaQuery()} {
    background-color: transparent;
  }

  span {
    height: 100%;
    background-color: ${fixColors.colorSub};
    display: block;
  }
`
