import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ProgramItemLink from '../../../../common/components/renewal/ProgramItemLink'
import ProgramItemSkeleton from '../../../../common/components/renewal/ProgramItemSkeleton'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  META_SCHEMA_ID,
  ADVERTISING_SCHEMA_ID
} from '../../../../../constants/app'
import Pager from '../../../../common/components/renewal/Pager2/' // ページャー

/** style */
import styled, { css } from 'styled-components'

/**
 * ブレイクポイント(579～1024px)では18件ずつに変更
 * updateFetchCountでstateのfetchCountを更新
 */
const FETCH_COUNT = 20
const FETCH_COUNT_TAB = 18

/** ジャンルトップ 番組一覧(ページャー有り) */
export default class ProgramListGrid extends Component {
  static propTypes = {
    palette: PropTypes.shape({
      name: PropTypes.string,
      palette_id: PropTypes.number.isRequired,
      objects: PropTypes.array
    }),
    howToPlays: PropTypes.object,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    palette: null,
    howToPlays: null,
    placeholder: ''
  }

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data
    this.state = {
      objects: [],
      totalCount: null,
      howToPlays: this.props.howToPlays,
      loaded: false,
      filteredEpisodes: [],
      sortBy: 'avails_release_history_original_older',
      filteredBy: '',
      searchWord: '',
      currentPageNum: 1,
      fetchCount: FETCH_COUNT,
      prevFetchCount: FETCH_COUNT,
      programItems: {
        1: (_.get(props, ['palette', 'objects']) || []).slice(0, FETCH_COUNT)
      }
    }

    this.isFetching = false
    this.onClickItem = this.onClickItem.bind(this)
    this.onClickItemOther = this.onClickItemOther.bind(this)
    this.updateCurrentPageNum = this.updateCurrentPageNum.bind(this)
    this.fetchObjects = this.fetchObjects.bind(this)
    this.renderObjects = this.renderObjects.bind(this)

    this.setListRef = (e) => {
      this.listRef = e
    }
  }

  async componentDidMount() {
    this.updateFetchCount()
    window.addEventListener('resize', this.updateFetchCount)

    const { programItems } = this.state
    this._isMounted = true
    this.setState(
      {
        totalCount: programItems[1].length,
        loaded: true
      },
      async () => {
        await this.getHowToPlays()
        await this.getObjectCount().catch((e) =>
          webApp.utils.handleFalcorError(e, this.context)
        )
        await this.fetchObjects()
      }
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateFetchCount)
    this._isMounted = false
  }

  async getObjectCount() {
    const { palette } = this.props
    if (!palette || !palette.palette_id) return Promise.resolve()

    this.isFetching = true
    const path = ['paletteObjects', palette.palette_id, 'length']

    const deviceInfo = this.context.models.browserInfo.data
    const func = () => {
      return this.model.fetch([path]).then((result) => {
        const totalCount = _.get(result, ['json', ...path]) || 0
        if (this._isMounted) {
          this.setState({ totalCount, loaded: true })
        }
      })
    }
    if (deviceInfo.isIE) {
      // IEだとfinallyがエラーになる
      await func()
      this.isFetching = false
    } else {
      func().finally(() => {
        this.isFetching = false
      })
    }
  }

  async fetchObjects() {
    const { palette } = this.props
    const {
      totalCount,
      programItems,
      currentPageNum,
      fetchCount,
      prevFetchCount
    } = this.state

    /** パレット or パレットIDの指定がない場合は終了 */
    if (!palette || !palette.palette_id) return Promise.resolve()
    /** totalCountが取得できない場合は終了(改修で不要になったかも) */
    if (!totalCount) {
      this.setState({ programItems: [] })
      return Promise.resolve()
    }
    /** すでに読み込まれているページの場合は終了 */
    if (
      fetchCount === prevFetchCount &&
      Object.keys(programItems).indexOf(String(currentPageNum)) > -1
    ) {
      this.renderObjects()
      return Promise.resolve()
    }
    // if (programItems && programItems.length >= totalCount) return Promise.resolve();

    this.isFetching = true
    this.setState({ loaded: false }, async () => {
      const path = [
        'paletteObjects',
        palette.palette_id,
        fetchCount,
        currentPageNum
      ]
      const deviceInfo = this.context.models.browserInfo.data
      const func = () => {
        return this.model.fetch([path]).then((result) => {
          const wObjects = _.get(result, ['json', ...path]) || []
          if (this._isMounted) {
            this.setState(
              {
                programItems: {
                  ...programItems,
                  [currentPageNum]: wObjects
                }
              },
              () => {
                this.getHowToPlays()
              }
            )
          }
        })
      }
      if (deviceInfo.isIE) {
        // IEだとfinallyがエラーになる
        await func()
        this.isFetching = false
        this.setState({ loaded: true }, () => {
          this.renderObjects()
        })
      } else {
        func().finally(() => {
          this.isFetching = false
          this.setState({ loaded: true }, () => {
            this.renderObjects()
          })
        })
      }
    })

    this.setState({ prevFetchCount: fetchCount })
  }

  /** 価格情報取得 */
  getHowToPlays() {
    const { howToPlays, programItems, currentPageNum } = this.state
    const currentItems = _.get(programItems, [currentPageNum])

    const metaIdsMap = {}
    ;(currentItems || [])
      .filter((obj) => obj.type === 'meta')
      .map((obj) => obj.meta)
      .filter((meta) =>
        [META_SCHEMA_ID.EPISODE_NOT_FREE, META_SCHEMA_ID.SEASON].includes(
          meta.meta_schema_id
        )
      )
      .forEach((meta) => {
        metaIdsMap[meta.meta_id] = meta.meta_id
      })
    const metaIds = Object.keys(metaIdsMap)
    if (!metaIds.length) return Promise.resolve()

    // array分割
    const arrayChunk = ([...array], size = 1) => {
      return array.reduce(
        (acc, value, index) =>
          index % size ? acc : [...acc, array.slice(index, index + size)],
        []
      )
    }

    const chunk = arrayChunk(metaIds, 5)

    for (let i = 0; i < chunk.length; i++) {
      const path = [['meta', 'howToPlay', false, chunk[i]]]
      const w_model = this.context.falcorModel
      if (Object.keys(howToPlays).indexOf(chunk[i]) > -1) {
      } else {
        w_model.fetch(path).then((result) => {
          const w_howToPlays =
            _.get(result, ['json', 'meta', 'howToPlay', false]) || {}
          this.setState({ howToPlays: Object.assign(w_howToPlays, howToPlays) })
        })
      }
    }
  }

  /** 画面の幅に応じてfetchCountを更新 */
  updateFetchCount = () => {
    let fetchCount = FETCH_COUNT
    if (window.innerWidth >= 579 && window.innerWidth <= 1024) {
      fetchCount = FETCH_COUNT_TAB
    }
    this.setState({ fetchCount }, () => {
      this.fetchObjects()
    })
  }

  onClickItem(e, meta) {
    e.preventDefault()
    webApp.utils.goToProgramLink(this.context, meta, null, null)
  }

  onClickItemOther(meta, product, course, autoPlay = true) {
    if (this.isIndividualRecommend && meta) {
      this.recommendClick(meta.meta_id)
    }
    webApp.utils.goToProgramLink(this.context, meta, product, course, {
      autoPlay
    })
  }

  /** パレットの編成内容をレンダリング */
  renderObjects() {
    if (this.state.loaded) {
      const { courses } = this.props
      const { programItems, howToPlays, currentPageNum } = this.state
      const objects = []

      /** 表示するページの番組を抜き出す */
      const currentItems = _.get(programItems, [currentPageNum])
      if (!currentItems) return null

      currentItems.map(async (object) => {
        const productId =
          _.get(object, ['advertising', 'values', 'product']) || null
        const courseId =
          _.get(object, ['advertising', 'values', 'course']) || null
        const product = productId && _.get(products, productId)
        const course = courseId && _.get(courses, courseId)
        const howToPlay = _.get(howToPlays, object.id) || null
        const paletteAd = _.get(object, 'advertising')

        if (paletteAd) {
          if (paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT) {
            const thumbnail =
              _.get(paletteAd, ['creatives', 0, 'attachment', 'file_url']) ||
              this.context.models.config.data.default_thumbnail
            if (paletteAd.url) {
              objects.push(
                <ProgramItemLink
                  key={`${object.type}-${object.id}`}
                  title={paletteAd.name}
                  thumbnail={thumbnail}
                  href={paletteAd.url}
                />
              )
            }
          } else {
            const { route, params, query } = webApp.utils.getProgramLinkRoutes(
              this.context,
              null,
              product,
              course
            )
            objects.push(
              <ProgramItemLink
                key={`${object.type}-${object.id}`}
                product={product}
                course={course}
                howToPlay={howToPlay}
                showDelivery
                showInCourse
                route={route}
                params={params}
                query={query}
              />
            )
          }
        } else {
          const meta = object.meta || null
          const routes = webApp.utils.getProgramLinkRoutes(this.context, meta)
          const { route, params, query } =
            typeof routes === 'function' ? await routes() : routes
          if (route && params) {
            objects.push(
              <ProgramItemLink
                key={meta.meta_id}
                meta={meta}
                howToPlay={_.get(howToPlays, [meta.meta_id]) || null}
                showNew={webApp.utils.showNew(meta)}
                showInCourse
                route={route}
                params={params}
                query={query}
              />
            )
          }
        }

        this.setState({ objects })
      })
    } else {
      let skeletonObjects = []
      for (let i = 0; i < this.state.fetchCount; i++) {
        skeletonObjects.push(
          <ProgramItemSkeleton key={i} hasThumb={true} titleLength={2} />
        )
      }
      return skeletonObjects
    }
  }

  // 子コンポーネントからのからのアクセス用
  updateCurrentPageNum(currentPageNum) {
    this.setState({ currentPageNum }, async () => {
      await this.fetchObjects()
    })
  }

  render() {
    const { pagerOptions, showPagerUnder, palette } = this.props
    const { currentPageNum, totalCount, objects } = this.state

    return (
      <div
        className="common-free-programs-set c-switchableListGrid--genre"
        ref={this.setListRef}
      >
        <div className="common-free-programs-set-content no-background">
          <div className="inner">
            <div className="c-card-catchup">
              <StyledHeader>
                {palette.name && (
                  <StyledH3 className="c-cards-head-hedding">
                    <span className="c-cards-head-hedding-inner">
                      {palette.name}
                    </span>
                  </StyledH3>
                )}
                <StyledDiv>
                  <Pager
                    option={pagerOptions}
                    episodeLength={totalCount}
                    currentPageNum={currentPageNum}
                    updateCurrentPageNum={this.updateCurrentPageNum}
                  />
                </StyledDiv>
              </StyledHeader>
              <div className="c-card-catchup-cont">{objects}</div>
              <footer>
                {showPagerUnder && (
                  <StyledDiv>
                    <Pager
                      option={pagerOptions}
                      episodeLength={totalCount}
                      currentPageNum={currentPageNum}
                      updateCurrentPageNum={this.updateCurrentPageNum}
                      clickScroll
                    />
                  </StyledDiv>
                )}
              </footer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const StyledHeader = styled.header`
  position: relative;
  min-height: 30px;
  margin-bottom: 20px;
`

const StyledH3 = styled.h3`
  @media (min-width: 1025px) {
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`

const mediaWidthStyle = (array, columnWidth, marginWidth) =>
  array.reduce((prev, cur) => {
    return `${prev}@media (min-width: ${cur * columnWidth + marginWidth}px) {
      max-width: ${cur * columnWidth}px
    }`
  }, '')

const StyledDiv = styled.div`
  margin: 0 auto;
  @media (max-width: 314px) {
    max-width: 160px;
  }
  @media (min-width: 315px) {
    max-width: 315px;
  }
  @media (min-width: 361px) {
    max-width: 356px;
  }
  ${css`
    ${mediaWidthStyle([2, 3, 4, 5, 6], 178, 15)}
  `}
  @media (min-width: 1025px) {
    max-width: 848px;
  }
  ${css`
    ${mediaWidthStyle([5, 6], 212, 8)}
  `}
`
