import { Link } from '../../../../../../../sketch-platform/ui/routing'
import styled from 'styled-components'
import { mediaQuery } from '../../../../style'
import { white, black } from '../../styles'

export const StyledImg = styled.img`
  width: 470px;
  height: calc(470px * (9 / 16));
  vertical-align: bottom;
  object-fit: contain;
  transition: transform 0.4s ease-in-out;

  ${mediaQuery()} {
    width: 100%;
    height: auto;
  }
`

export const StyledLink = styled(Link)`
  padding: 10px 10px 15px;
  background-color: ${white};
  border-radius: 12px;
  flex: 1 0;
  position: relative;
  z-index: 1;
  color: ${black};
  font-size: 1.6rem;
  letter-spacing: 0.1em;

  ${mediaQuery()} {
    padding: 0;
    overflow: hidden;
  }

  &:hover {
    ${StyledImg} {
      transform: scale(1.2);
    }
  }

  &:not([href]) {
    cursor: normal;
    pointer-events: none;
  }
`

export const StyledDiv = styled.div`
  border-radius: 12px;
  overflow: hidden;

  ${mediaQuery()} {
    border-radius: 0;
  }
`

export const StyledP = styled.p`
  margin-top: 15px;
  padding: 0 10px;
  font-size: 1.6rem;
  font-weight: bold;

  ${mediaQuery()} {
    margin-top: 0;
    padding: 5px;
    font-size: 1.3rem;
  }
`
