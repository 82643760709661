import styled from 'styled-components'
import { mediaQuery } from '../../../style'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import Button from './Button'

export const StyledHeader = styled.header`
  margin: 0 auto;
  padding: 30px 0;
  max-width: 1160px;
  background-color: #fff;
  position: relative;
  text-align: center;

  ${mediaQuery()} {
    padding: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const StyledLink = styled(Link)`
  width: 360px;
  display: inline-block;
  transition: opacity 0.2s ease-in-out;

  ${mediaQuery('sm', 'min')} {
    &:hover {
      opacity: 0.7;
    }
  }

  ${mediaQuery()} {
    max-width: 160px;
    width: auto;
  }
`

export const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  ${mediaQuery()} {
    left: 5px;
  }
`
