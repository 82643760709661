import React from 'react'
import PropTypes from 'prop-types'
import SpPlayButton from './SpPlayButton'
import styled from 'styled-components'

/** そだてれび：海外視聴用 SP 単話ページ動画詳細 サムネイル */
const SpDetailThumbnail = ({ thumbnailAlt, thumbnailUrl, onClickPlay }) => {
  return (
    <StyledDiv className="c-player-btn__inner">
      <a href="#" onClick={onClickPlay}>
        <StyledSpPlayButton />
        <img src={thumbnailUrl} alt={thumbnailAlt} />
      </a>
    </StyledDiv>
  )
}

SpDetailThumbnail.propTypes = {
  thumbnailAlt: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  onClickPlay: PropTypes.func
}

export default SpDetailThumbnail

const StyledDiv = styled.div`
  position: relative;

  a {
    display: block;
  }
`

const StyledSpPlayButton = styled(SpPlayButton)`
  position: absolute;
  z-index: 1;
  right: 1%;
  bottom: 5%;
`
