import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import styled, { createGlobalStyle } from 'styled-components'
import webApp from '../../../utils/exdioWebAppUtils'
import DFPBanner from '../../../../common/components/DFPBanner'
import { FAVORITE_TYPE } from '../../../../../constants/app'

/* hooks */
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'
import useMediaQuery from '../../../../hooks/useMediaQuery'

/* components */
import SideTab from '../details/SideTab'
import Caption from '../details/Caption'
import AddButtonBlock from '../details/AddButtonBlock'
import SideRecommend from '../details/SideRecommend'
import HtmlSnippet from '../../HtmlSnippet'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'
import Header from '../../../../common/components/Header'
import HeaderNewsComponent from '../HeaderNewsComponent'
import FilterSort from '../../../../common/components/FilterSort'
import ListHeadMeta from '../details/ListHeadMeta'
import Footer from '../../../../common/components/Footer'

/** style */
const mediaSp = '(max-width: 1024px)'

/** 番組ページ logirl用テンプレート */
const ProgramLogirl = (
  {
    seasonId = '',
    meta = {},
    episodes = [],
    howToPlays = {},
    displayModes = {},
    otherSeasons = {},
    latestEpisodeStatus = {
      displayMode: null,
      isFree: null,
      isNotFree: null,
      isPurchased: null,
      isNotPurchased: null,
      isInCourse: null,
      isNotInCourse: null
    }
  },
  context
) => {
  const config = context.models.config.data
  const isSp = useMediaQuery()

  const searchParams = useSearchParams({
    childEpisodeIds: get(meta, ['values', 'child_episode_ids'], []),
    seasonIds: [Number(seasonId)],
    sortedBy: 'delivery_start_at_newer',
    pagerOptions: {
      episodesPerPages: 60,
      range: 2,
      showBottom: true
    }
  })

  /* 番組別お知らせの表示 */
  const renderNotice = () => {
    const notice = {
      text: get(meta, ['values', 'notice']),
      hyperlink: get(meta, ['values', 'notice_hyperlink']),
      start_at: get(meta, ['values', 'notice_publish_start_at']),
      end_at: get(meta, ['values', 'notice_publish_end_at'])
    }
    const now = Date.now()

    if (
      !notice.text ||
      now < Date.parse(notice.start_at) ||
      Date.parse(notice.end_at) < now
    ) {
      return null
    }

    const html = (
      <dl className="c-program_notice">
        <dt className="c-program_notice__term">お知らせ</dt>
        <dd className="c-program_notice__desc">{notice.text}</dd>
      </dl>
    )

    return notice.hyperlink ? (
      <a href={notice.hyperlink} className="c-program_notice__wrapper">
        {html}
      </a>
    ) : (
      <div className="c-program_notice__wrapper">{html}</div>
    )
  }

  const thumbnailUrl = meta.thumbnail_url || config.default_thumbnail
  const bannerId1 = get(meta, ['values', 'banner_1'])
  const bannerId2 = get(meta, ['values', 'banner_2'])

  const [metaName] = webApp.utils.titles(meta)
  const latestEpisode = episodes[0]
  const schemeId = meta.meta_schema_id
  let courseId = null

  // 許諾番号表示のため
  if (latestEpisode) {
    const latestEpisodeCourses =
      get(howToPlays, [latestEpisode.meta_id, 'courses']) || []
    courseId = latestEpisodeCourses.length
      ? latestEpisodeCourses[0].course_id
      : null
  }

  return (
    <>
      <Header />
      <div className="common-wrapper">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />
        {!isSp && renderNotice()}

        <div className="c-headMeta">
          <div className="c-headMeta-metaBox">
            <div className="c-headMeta-metaBox-art">
              <div className="c-headMeta-metaBox-art-inBox">
                <div className="c-headMeta-metaBox-art-inBox-artwork">
                  <img
                    src={thumbnailUrl}
                    alt=""
                    className="c-headMeta-metaBox-art-inBox-artwork-img"
                  />
                </div>
              </div>

              {isSp && renderNotice()}

              {/* for PC */}
              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.META}
                favoriteId={meta.meta_id}
                title={metaName}
              />
            </div>

            <div className="c-headMeta-metaBox-info">
              <h2 className="c-headMeta-metaBox-info-title">{metaName}</h2>

              <Caption
                caption={meta.values && meta.values.evis_SeasonLongSynopsis}
                className="c-headMeta-metaBox-info-caption"
              />

              {/* for SP */}
              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.META}
                favoriteId={meta.meta_id}
                title={metaName}
              />
            </div>
          </div>
        </div>

        <div className="c-listMeta">
          <div className="c-listMeta-inBox">
            <div className="c-listMeta-inBox-main">
              {latestEpisode && schemeId !== 6 && (
                <ListHeadMeta
                  meta={meta}
                  howToPlays={howToPlays}
                  displayMode={displayModes[latestEpisode.meta_id]}
                  episode={latestEpisode}
                  latestEpisodeStatus={latestEpisodeStatus}
                />
              )}
              {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
              {Object.keys(meta).length > 0 && (
                <section>
                  <StyledH3>単話</StyledH3>
                  <StyledFilterSort
                    searchParams={searchParams}
                    episodeListItemProps={{
                      showNew: true,
                      showChecked: true,
                      showCoin: true,
                      showCaption: true,
                      onlySubTitle: true
                    }}
                  />
                </section>
              )}
            </div>
            <div className="c-listMeta-inBox-sub">
              <div className="common-box-ad-rectangle">
                <DFPBanner position="foot" meta_id="" />
              </div>
              {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
              <SideTab otherSeasons={otherSeasons} />
              {/* <ProgramTopLink seriesId={seriesId} seasonId={seasonId} isFree={isFree} /> */}
              <HtmlSnippet
                snippetId={config.extras.common_banner_snippet_key}
              />
              <SideRecommend
                typeName={config.recommend.type_name.view}
                spotName={config.recommend.spot_name.program}
              />
            </div>
          </div>
        </div>
        <Footer className="mt0" courseId={courseId} />
      </div>
      <GlobalStyle />
    </>
  )
}

export default ProgramLogirl

ProgramLogirl.propTypes = {
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  /** episodesのhowToPlay情報 */
  howToPlays: PropTypes.object
}

ProgramLogirl.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

const GlobalStyle = createGlobalStyle`
.u-hide--browser {
  display: none !important;
}

.c-listMeta-inBox-main {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
`

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 600;

  @media ${mediaSp} {
    padding: 0 15px;
  }
`

const StyledFilterSort = styled(FilterSort)`
  margin-top: 20px;
`
