import React from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import styled, { css } from 'styled-components'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'
import { breakPoints, mediaQuery } from '../../../../exdio/components/style'

/* components */
import ListItem from './ListItem'
import Balloon from './Balloon'

/* style */
import { StyledTitle } from './ListItem/style'

/** 単話リスト */
const EpisodeList = (
  {
    episodes = [],
    howToPlays = [],
    favorites = [],
    showNew = false,
    showChecked = false,
    showCoin = false,
    showCaption = false,
    onlySubTitle = false,
    clampWidth = 1,
    target = null,
    listType = 'default',
    hasBalloon = false,
    hasFavorite = false,
    toggleFavorites = () => {},
    images = {
      on: '/images/exdio/renewal/icon_mylist_added.svg',
      off: ''
    },
    onlyWatchedAll = false,
    ...props
  },
  context
) => {
  const isApp = webApp.utils.isApp(context)

  /**
   * アプリ判別でtargetの出し分け
   * 放送年が選択されている場合は新規タブで単話ページを開く
   * アプリの場合はblankはつけない
   * + ---------- + ------- + ------- +
   * |            | ブラウザ | アプリ    |
   * + ---------- + ------- + ------- +
   * | 放送年あり   | _blank  | _self   |
   * + ---------- + ------- + ------- +
   * | 放送年なし   | _self   | _self   |
   * + ---------- + ------- + ------- +
   */
  return (
    <StyledUl
      as={size(episodes) > 0 ? null : 'div'}
      listType={listType}
      {...props}
    >
      {size(episodes) > 0 ? (
        episodes.map((meta) => {
          const itemRoutes = webApp.utils.getProgramLinkRoutes(
            context,
            meta,
            null,
            null,
            {
              autoPlay: true
            }
          )
          const isFavorite =
            Object.values(favorites).find(
              ({ meta: favoriteMeta }) => favoriteMeta.meta_id === meta.meta_id
            ) || false
          return (
            <StyledLi
              key={meta.meta_id}
              listType={listType}
              hasFavorite={hasFavorite}
            >
              <StyledListItem
                meta={meta}
                howToPlay={
                  howToPlays && howToPlays[meta.meta_id]
                    ? howToPlays[meta.meta_id]
                    : null
                }
                showNew={showNew && webApp.utils.showNew(meta)}
                showChecked={
                  showChecked && webApp.utils.isWatched(meta, onlyWatchedAll)
                }
                showCoin={!isApp && showCoin && listType === 'default'}
                showCaption={showCaption && listType === 'default'}
                onlySubTitle={onlySubTitle}
                clampWidth={clampWidth}
                target={target}
                listType={listType}
                images={images}
                {...itemRoutes}
              />

              {hasFavorite && listType === 'grid' && (
                <StyledLabel
                  htmlFor={`fav-${meta.meta_id}`}
                  title={isFavorite ? `マイリストから削除` : `マイリストに追加`}
                  isFavorite={isFavorite}
                >
                  <input
                    type="checkbox"
                    id={`fav-${meta.meta_id}`}
                    name="favorite"
                    value={meta.meta_id}
                    checked={Boolean(isFavorite)}
                    onChange={() => toggleFavorites(meta, !isFavorite)}
                  />
                  <img
                    src={`/images/exdio/renewal/doraemon/my_list_${
                      isFavorite ? 'added' : 'removed'
                    }.svg`}
                    width="20"
                    height="18"
                    alt=""
                  />
                </StyledLabel>
              )}

              {hasBalloon && listType === 'grid' && (
                <StyledBalloon
                  name={get(meta, ['name'], '')}
                  synopsis={get(
                    meta,
                    ['values', 'evis_EpisodeLongSynopsis'],
                    ''
                  )}
                />
              )}
            </StyledLi>
          )
        })
      ) : (
        <p>該当のエピソードがありません。</p>
      )}
    </StyledUl>
  )
}

export default EpisodeList

EpisodeList.propTypes = {
  episodes: PropTypes.array,
  howToPlays: PropTypes.object,
  favorites: PropTypes.array,
  showNew: PropTypes.bool,
  showChecked: PropTypes.bool,
  showCoin: PropTypes.bool,
  showCaption: PropTypes.bool,
  onlySubTitle: PropTypes.bool,
  clampWidth: PropTypes.number,
  target: PropTypes.string,
  listType: PropTypes.oneOf(['default', 'list', 'grid']),
  hasBalloon: PropTypes.bool,
  hasFavorite: PropTypes.bool,
  toggleFavorites: PropTypes.func,
  images: PropTypes.shape({
    on: PropTypes.string,
    off: PropTypes.string
  }),
  onlyWatchedAll: PropTypes.bool
}

EpisodeList.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  routeHandler: PropTypes.object
}

export const StyledUl = styled.ul.withConfig({
  shouldForwardProp: (prop) => !['listType'].includes(prop)
})`
  position: relative;
  z-index: 0;

  ${({ listType, as }) => {
    if (as === 'div') {
      return css`
        display: block;
      `
    }

    switch (listType) {
      case 'default':
        return css`
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        `

      case 'list':
        return css`
          display: flex;
          flex-direction: column;
          row-gap: 12px;
        `

      case 'grid':
        return css`
          display: grid;
          gap: 20px 10px;
          grid-template-columns: repeat(4, 1fr);

          ${mediaQuery()} {
            grid-template-columns: repeat(2, 1fr);
          }
        `

      default:
        return null
    }
  }}
`

const StyledBalloon = styled(Balloon)`
  @media (hover: none) {
    display: none !important;
  }
`

const StyledListItem = styled(ListItem)`
  grid-column: 1 / 3;
  grid-row: 1 / 3;

  @media (hover: hover) {
    & ~ ${StyledBalloon} {
      width: calc(200% + 10px);
      position: absolute;
      top: 100%;
      left: -10px;
      visibility: hidden;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.4s ease-in;

      ${mediaQuery()} {
        width: 100%;
        left: 0;
      }
    }

    &:focus {
      & ~ ${StyledBalloon} {
        visibility: visible;
        z-index: 2;
        opacity: 1;
      }
    }
  }
`

const StyledLi = styled.li.withConfig({
  shouldForwardProp: (prop) => !['listType', 'hasFavorite'].includes(prop)
})`
  position: relative;
  display: grid;
  grid: repeat(2, auto) / 1fr auto;

  @media (hover: hover) {
    &:hover {
      ${StyledBalloon} {
        visibility: visible;
        z-index: 2;
        opacity: 1;
      }
    }

    &:nth-child(5n) {
      ${StyledBalloon} {
        ${mediaQuery(breakPoints.sm, 'min')} {
          right: -10px;
          left: auto;
        }
      }
    }
  }

  ${StyledTitle} {
    ${({ listType, hasFavorite }) => {
      if (listType === 'grid' && hasFavorite) {
        return css`
          padding-right: 20px;
        `
      }
      return null
    }}
  }
`

export const StyledLabel = styled.label.withConfig({
  shouldForwardProp: (prop) => !['isFavorite'].includes(prop)
})`
  position: relative;
  bottom: calc(15px * 1.25 * 2 - 20px); // ListItem > StyledPに合わせる
  z-index: 1;
  grid-column: 2;
  grid-row: 2;
  transition: opacity 0.4s;
  cursor: pointer;

  ${mediaQuery()} {
    bottom: calc(13px * 1.25 * 2 - 22px); // ListItem > StyledPに合わせる
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`
