import styled from 'styled-components'
import { mediaQuery, breakPoints } from '../../../style'

export const StyledUl1 = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @media (min-width: 600px) and (max-width: ${breakPoints.sm}px) {
    gap: min(2.9vw, 30px);
  }

  ${mediaQuery(599)} {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 10px;
  }
`

export const StyledLi1 = styled.li``
