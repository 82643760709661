import hoistStatics from 'hoist-non-react-statics';
import invariant from 'invariant';
import React, { Component, createElement } from 'react';
import _ from 'lodash';

import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import window from 'global/window';

export default function layoutContext(WrappedComponent) {
  invariant(
    typeof WrappedComponent === 'function',
    `You must pass a component to the function returned by ` +
      `layoutContext. Instead received ${JSON.stringify(WrappedComponent)}`,
  );

  class LayoutContext extends Component {

    static __meta__ = WrappedComponent.__meta__;

    static childContextTypes = {
      offline: PropTypes.bool,
      popType: PropTypes.string,
      models: PropTypes.object,
      webApp: PropTypes.object,
      node: PropTypes.object
    };

    static contextTypes = {
      getCookieDough: PropTypes.func,
      getModelData: PropTypes.func,
      webApp: PropTypes.object,
      store: PropTypes.object,
    };

    constructor(props, context) {
      super(props, context);

      this.state = {
        offline:
          typeof window === 'undefined' ||
          typeof window.navigator === 'undefined'
            ? false
            : !window.navigator.onLine,
      };
      this._handleUpdateOnlineStatus = this._handleUpdateOnlineStatus.bind(
        this,
      );
    }

    getChildContext() {
      const model = this.props.model;
      const webApp = this.props.webApp;
      return {
        models: model.models || this.context.models || {},
        webApp: webApp || this.context.webApp || {},
        node: model.node,
        offline: this.state.offline,
        popType: 'bounce'
      };
    }

    componentWillMount() {
      const { store: propsStore } = this.props;
      this.store = propsStore || this.context.store;
    }

    componentDidMount() {
      this._isMounted = true;
      window.addEventListener('online', this._handleUpdateOnlineStatus);
      window.addEventListener('offline', this._handleUpdateOnlineStatus);
    }

    componentWillReceiveProps(nextProps) {}

    componentDidUpdate(beforeProps) {}

    componentWillUnmount() {
      this._isMounted = false;

      window.removeEventListener('online', this._handleUpdateOnlineStatus);
      window.removeEventListener('offline', this._handleUpdateOnlineStatus);
    }

    _handleUpdateOnlineStatus() {
      if (this._isMounted) {
        if (window.navigator.onLine) {
        }
        this.setState({ offline: !window.navigator.onLine });
      }
    }

    render() {
      return React.createElement(WrappedComponent, Object.assign({model: _.omit(this.props.model, 'models'), webApp: _.omit(this.props.webApp, 'utils') }, _.omit(this.props, 'model')));
    }
  }

  LayoutContext.displayName = 'LayoutContext';

  return hoistStatics(LayoutContext, WrappedComponent);
};
