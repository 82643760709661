/* components */
import ChildComponent from './ChildComponent'

/* style */
import styled from 'styled-components'
export const mediaSp = '(max-width: 1024px)'

export const StyledSection = styled.section`
  margin: 0 auto;
  padding-top: 60px;
  width: 1024px;

  @media ${mediaSp} {
    padding: 30px 15px 0;
    width: auto;
  }
`

export const StyledH2 = styled.h2`
  color: #f00;
  font-size: 3.2rem;
  text-align: center;
`

export const StyledDiv1 = styled.div`
  margin-top: 30px;
  text-align: center;
`

export const StyledP = styled.p`
  font-size: 1.6rem;
  line-height: 1.75;

  & + & {
    margin-top: 15px;
  }
`

export const StyledUl = styled.ul`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledLi = styled.li`
  padding-left: 15px;
  position: relative;
  font-size: 1.4rem;

  & + & {
    margin-top: 15px;
  }

  &::before {
    width: 0;
    height: 0;
    border-width: 4px 0 4px 7px;
    border-style: solid;
    border-color: transparent transparent transparent #333;
    display: block;
    position: absolute;
    top: 0.25em;
    left: 0;
    content: '';
  }
`

export const StyledDiv2 = styled.div`
  margin-top: 30px;
`

export const StyledChildComponent = styled(ChildComponent)`
  margin-top: 60px;
`
