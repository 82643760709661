import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import window from 'global/window'
import { size } from 'lodash'
import Header from '../../common/components/Header'
import ScrollTopButton from '../../common/components/renewal/ScrollTopButton'
import PreviewComponent from '../../common/components/renewal/PreviewComponent'

import {
  setAppSwipePrev,
  setAppSwipeNext,
  bulkSetResumeInfo
} from '../../common/global_functions'

const ExdioAppLayout = (
  { routeHandler: propsRouteHandler = undefined, children },
  context
) => {
  const routeHandler = propsRouteHandler || context.routeHandler
  if (size(routeHandler) === 0) return null

  const { header = false, scrollTop = false } = routeHandler

  // ネイティブから呼ばれるglobal function定義
  const defineGlobalFunction = () => {
    window.setAppSwipePrev = setAppSwipePrev
    window.setAppSwipeNext = setAppSwipeNext
    window.setResumeInfo = bulkSetResumeInfo // アプリチームが指定したfunction名で実行できるように
  }

  useEffect(() => {
    defineGlobalFunction()
  }, [])

  return (
    <div className="bd lang_ja appli">
      {header && <Header path={routeHandler.path} />}
      <CSSTransitionGroup
        component="div"
        className="mainView"
        role="main"
        transitionEnterTimeout={450}
        // 遷移前の要素が残存してしまうのでtransitionLeaveはfalse
        transitionLeave={false}
        transitionName="pageTransition"
      >
        {React.Children.map(children, (child) => {
          if (routeHandler) {
            return React.cloneElement(child, {
              key: routeHandler.path,
              ...routeHandler.params
            })
          }
          return child
        })}
      </CSSTransitionGroup>
      {scrollTop && <ScrollTopButton />}
      <PreviewComponent />
    </div>
  )
}

export default ExdioAppLayout

ExdioAppLayout.propTypes = {
  routeHandler: PropTypes.shape({
    path: PropTypes.string
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element
  ]).isRequired
}

ExdioAppLayout.contextTypes = {
  routeHandler: PropTypes.shape({
    path: PropTypes.string
  })
}
