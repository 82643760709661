import React from 'react'
import styled from 'styled-components'

/** そだてれび：海外視聴用 SP 単話ページ動画詳細 再生ボタン */
const SpPlayButton = ({ className }) => {
  return (
    <StyledDiv className={className} key="btn-sp">
      PLAY
    </StyledDiv>
  )
}

export default SpPlayButton

const StyledDiv = styled.div`
  font-size: 1.1rem;
  text-align: center;
  font-weight: bold;
  padding-top: 68px;
  vertical-align: bottom;
  width: 80px;
  height: 80px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_sp.svg)
    no-repeat top center;
`
