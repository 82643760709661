import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'

/** 動画リンク: サムネイル下部のメッセージ付きのラベル */
const ImgBottomLabel = ({ product, course, className }) => {
  const text = webApp.utils.productLabel(product, course) || ''

  return text && <StyledDiv className={className}>{text}</StyledDiv>
}

export default ImgBottomLabel

ImgBottomLabel.propTypes = {
  /** 月額見放題パックコース('見放題パック'のラベルの描画判断に使用) */
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired
  }),
  /** 月額見放題パックコース('見放題パック'のラベルの描画判断に使用) */
  course: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired
  })
}

ImgBottomLabel.defaultProps = {
  product: {},
  course: {}
}

const StyledDiv = styled.div`
  width: 100%;
  height: 21px;
  background: #343434;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  padding-top: 0.5rem;
`
