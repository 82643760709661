/* style */
import styled from 'styled-components'
import List5 from '../components/list/List5'
import { mediaSp } from '../style'

export const StyledSection = styled.section`
  margin-top: 48px;

  &:first-child {
    margin-top: 0;
  }

  @media ${mediaSp} {
    margin-top: 16px;
  }
`
