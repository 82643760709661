/* components */
import { Link } from '../../../../../../../../sketch-platform/ui/routing'

/* style */
import styled from 'styled-components'

export const mediaPc = '(min-width: 1024px)'
export const mediaSp = '(max-width: 1024px)'

export const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;

  &[data-size='label'] {
    padding: 4px 6px;
    background: #00cccc;
    display: inline-block;
    color: #fff;
    font-size: 1rem;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-weight: bold;

    &::after {
      @media ${mediaSp} {
        margin-right: 1px;
        width: 0;
        color: transparent;
        font-size: 0;
        content: '.';
      }
    }
  }

  &[data-size='large'] {
    @media ${mediaPc} {
      flex-wrap: nowrap;
    }
  }
`

export const StyledLi = styled.li`
  margin-left: 20px;
  width: calc((100% - 60px) / 4);

  @media ${mediaSp} {
    margin-top: 10px;
    margin-left: 0;
    width: auto;
  }

  &:first-child {
    @media ${mediaSp} {
      margin-top: 0;
    }
  }

  &:nth-child(n + 5) {
    @media ${mediaPc} {
      margin-top: 20px;
    }
  }

  &:nth-child(4n + 1) {
    @media ${mediaPc} {
      margin-left: 0;
    }
  }

  [data-size='large'] & {
    margin-left: 20px;
    width: calc((100% - 20px) / 2);

    @media ${mediaSp} {
      margin-top: 10px;
      margin-left: 0;
      width: auto;
    }

    &:first-child {
      margin-left: 0;

      @media ${mediaSp} {
        margin-top: 0;
      }
    }
  }
`

export const StyledLink = styled(Link)`
  padding: 13px;
  border-radius: 5px;
  background-color: #fff;
  font-size: 0;
  cursor: pointer;

  &:hover {
    .c-list5__img {
      transform: scale(1.3);
    }
  }
`

export const StyledImg = styled.img`
  width: 100%;
  display: block;
  transition: transform 0.3s ease-in-out;
`

export const StyledDiv = styled.div`
  height: 147px;
  overflow: hidden;

  @media ${mediaSp} {
    height: auto;
  }

  [data-size='large'] & {
    height: 316px;

    @media ${mediaSp} {
      height: auto;
    }
  ｝
`

export const StyledSpan = styled.span`
  padding: 4px 6px;
  background: #00cccc;
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: bold;

  &::after {
    @media ${mediaSp} {
      margin-right: 1px;
      width: 0;
      color: transparent;
      font-size: 0;
      content: '.';
    }
  }
`

export const StyledDl = styled.dl`
  margin-top: 20px;

  @media ${mediaSp} {
    margin-top: 10px;
  }
`

export const StyledDt = styled.dt`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
  overflow: hidden;
  height: 48px;
  -webkit-line-clamp: 2;
  color: #00a3a3;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;

  &::after {
    width: 110px;
    height: calc(1em * 1.5);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    content: '';
  }
`

export const StyledDd = styled.dd`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
  overflow: hidden;
  margin-top: 12px;
  height: 66px;
  -webkit-line-clamp: 3;
  color: #333;
  font-size: 1.4rem;
  line-height: 1.57;

  &::after {
    width: 170px;
    height: calc(1em * 1.57);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    content: '';
  }
`

export const StyledTime = styled.time`
  margin-top: 8px;
  width: 100%;
  display: inline-block;
  color: #7e7e7e;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 11px;
  text-align: right;
`
