import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { MYPAGE_CONTENTS } from '../../../../../constants/app';

import routes from '../../../../../apps/common/routes';
import webApp from '../../../utils/exdioWebAppUtils';

/** マイページ アカウント設定 基盤連携 iframe親画面 */
export default class MemberSetting extends Component {
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    webApp: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.config = context.models.config.data;
    this.routeHandler = context.routeHandler;

    this.state = {
      url: null
    };

    this.onBack = this.onBack.bind(this);
  }

  componentDidMount() {
    this.setIframeUrl();

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context);

    // GTMの更新
    const title = this.context.models.config.data.default_title;
    const [program] = title === undefined ? [''] : title.split(' | ');
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ];
    this.context.webApp.utils.updateDataLayer(gtmTags);

    // 子フレームから呼ばれる用のメソッドをセット
    window.onBack = this.onBack;

    // リスナーを追加する
    window.addEventListener('message', this.onBack);
  }

  componentWillUnmount() {
    // リスナーを削除する
    window.removeEventListener('message', this.onBack);
  }

  onBack(e) {
    const { setting } = this.props;
    if (e.data.action === 'onBack') {
      // アカウントページへ戻したいので直指定
      const toAccountInfo = [
        MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_CREDIT,
        MYPAGE_CONTENTS.MEMBER_SETTING.ICON_EDIT
      ].includes(setting);
      let route;
      if (toAccountInfo) {
        route = webApp.utils.isApp(this.context) ? routes.app_mypage_account_info : routes.mypage_account_info;
      } else {
        route = webApp.utils.isApp(this.context) ? routes.app_mypage_account_setting : routes.mypage_account_setting;
      }
      this.context.history.replace(route);
      window.history.replaceState(null, null, route.makePath());
    }
  }

  setIframeUrl() {
    const { setting } = this.props;

    const account_setting = webApp.utils.isApp(this.context)
      ? routes.app_mypage_account_setting
      : routes.mypage_account_setting;
    const account_info = webApp.utils.isApp(this.context) ? routes.app_mypage_account_info : routes.mypage_account_info;

    let path = null;
    let cont_url = null;
    const rootUrl = webApp.utils.rootUrl();
    switch (setting) {
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_MEMBER:
        path = this.config.static_links.update_member;
        cont_url = encodeURIComponent(rootUrl + account_setting.makePath({}));
        break;
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_PASSWORD:
        path = this.config.static_links.update_password;
        cont_url = encodeURIComponent(rootUrl + account_setting.makePath({}));
        break;

      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_EMAIL:
        cont_url = encodeURIComponent(rootUrl + routes.mypage_update_email_result.makePath({}));
        // 楽天会員メールアドレス登録・変更ページ /apps/douga_member/update_rakuten_mail.php
        if (document.cookie.split(';').some(item => item.includes('RAKUTEN_LOGIN=1'))) {
          path = this.config.static_links.update_rakuten_mail;
        } else {
          path = this.config.static_links.update_email;
        }
        break;
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_EMAIL_RESULT:
        cont_url = `${encodeURIComponent(rootUrl + account_setting.makePath({}))}&${queryString.stringify(
          this.routeHandler.query
        )}`;
        // 楽天会員メールアドレス変更＆メールアドレス設定完了ページ /apps/douga_member/update_rakuten_mail_result.php
        if (document.cookie.split(';').some(item => item.includes('RAKUTEN_LOGIN=1'))) {
          path = this.config.static_links.update_rakuten_mail_result;
        } else {
          path = this.config.static_links.update_email_result;
        }
        break;
      case MYPAGE_CONTENTS.MEMBER_SETTING.DELETE_TVASAHI_ID:
        path = this.config.static_links.delete_member;
        cont_url = encodeURIComponent(rootUrl + account_setting.makePath({}));
        break;
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_CREDIT:
        path = this.config.static_links.update_credit;
        cont_url = encodeURIComponent(rootUrl + account_info.makePath({}));
        break;
      case MYPAGE_CONTENTS.MEMBER_SETTING.ICON_EDIT:
        path = this.config.static_links.edit_icon;
        if (this.routeHandler.query.CONT_URL) {
          cont_url = encodeURIComponent(this.routeHandler.query.CONT_URL);
        } else {
          cont_url = encodeURIComponent(rootUrl + account_info.makePath({}));
        }
        break;
      default:
    }

    const url =
      path && cont_url ? `${path.replace(':host', webApp.utils.infraHost(this.context))}?CONT_URL=${cont_url}` : '';

    this.setState({ url });
  }

  render() {
    const { url } = this.state;
    return (
      <React.Fragment>
        <div className="ex-base">{url && <iframe src={url} />}</div>
      </React.Fragment>
    );
  }
}
