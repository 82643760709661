/* style */
import styled, { createGlobalStyle } from 'styled-components'
import { mediaQuery } from '../../../style'
import Link from '../../../../../../sketch-platform/ui/routing/Link'

export const GlobalStyle = createGlobalStyle`
  body {
    background: url('/images/exdio/renewal/hiramekinotane/bg.png') center top
    repeat;
  }
`

export const StyledDiv1 = styled.div`
  margin: 0 auto;
  max-width: 1240px;
  font-family: 'Kaisei Opti', serif;
`

export const StyledSection = styled.section`
  position: relative;
  margin: 30px auto 40px;
  text-align: center;
  text-shadow: #fff 0 0 15px;
  color: #666;
`

export const StyledH2 = styled.h2`
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 1.2rem;

  ${mediaQuery()} {
    font-size: 2.4rem;
    letter-spacing: 3px;
  }
`

export const StyledP = styled.p`
  font-size: 1.9rem;
  line-height: 2.5;
  margin-bottom: 40px;

  ${mediaQuery()} {
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 0;
  }
`

export const StyledDiv2 = styled.div`
  margin-bottom: 40px;
  background: inherit;

  ${mediaQuery()} {
    margin-bottom: 0;
    padding: 0 8px;
  }
`

export const StyledDiv3 = styled.div`
  margin: 0 auto;

  ${mediaQuery(1320)} {
    width: 920px;
  }

  ${mediaQuery()} {
    margin: auto;
    width: auto;
    position: inherit;
  }
`

export const StyledDiv10 = styled.div`
  ${mediaQuery()} {
    max-width: unset;
  }
`

export const StyledDiv4 = styled.div`
  margin: 0 auto;
  display: flex;
  position: relative;

  ${mediaQuery()} {
    padding: 0;
  }
`

export const StyledDiv5 = styled.div`
  width: 640px;
  height: 360px;
  float: left;
  position: relative;

  ${mediaQuery()} {
    width: auto;
    height: auto;
    float: inherit;
  }
`

export const StyledLink1 = styled(Link)`
  && {
    font-weight: 700;
    color: #343434;
  }
`

export const StyledDiv6 = styled.div`
  width: 600px;
  color: rgb(230, 235, 239);
  padding: 30px 20px;
  position: relative;
  background: rgba(255, 255, 255, 0.698);

  img {
    width: 40px;
  }

  ${mediaQuery(1320)} {
    width: 300px;
  }
`

export const StyledDiv7 = styled.div`
  display: none;
  ${mediaQuery()} {
    display: block;
    padding-top: 68px;
    width: 80px;
    height: 80px;
    background: url(/images/exdio/renewal/icon_play_btn_for_video_area_sp.svg)
      no-repeat top center;
    position: absolute;
    right: 1%;
    bottom: 5%;
    z-index: 1;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
  }
`

export const StyledDiv8 = styled.div`
  margin-left: auto;
  padding: 0.6em 1.2em;
  background: rgba(102, 102, 102, 0.5);
  border-radius: 5px;
  position: absolute;
  bottom: 21px;
  left: 15px;
  z-index: 1;
  color: #fff;
  font-size: 1.8rem;
  text-align: center;

  ${mediaQuery()} {
    font-size: 1.3rem;
  }
`

export const StyledDiv9 = styled.div`
  margin: auto;
  padding-top: 170px;
  width: 144px;
  height: 144px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_pc.svg)
    no-repeat center center;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;

  ${mediaQuery()} {
    display: none;
  }
`

export const StyledImg1 = styled.img`
  margin: 0 auto 0 0;

  ${mediaQuery()} {
    width: 40px;
  }
`

export const StyledH3 = styled.h3`
  font-weight: 700;
  color: rgb(81, 81, 81);
  font-size: 1.8rem;
  line-height: 1.22;
  margin-top: 6px;
  margin-bottom: 16px;

  ${mediaQuery()} {
    padding: 15px 10px;
    background: linear-gradient(
      -90deg,
      rgb(255, 255, 112) 0%,
      rgb(135, 245, 131) 65%,
      rgb(15, 235, 149) 100%
    );
    font-size: 1.4rem;
    line-height: 1.16;
    margin-bottom: 0;
  }
`

export const StyledUl1 = styled.ul`
  color: rgb(81, 81, 81);
  border-top: 1px solid rgb(230, 228, 228);
  padding: 18px 0 12px;

  ${mediaQuery()} {
    margin-bottom: 10px;
    font-size: 1.4rem;
    line-height: 1.16;
  }
`

export const Styledli1 = styled.li`
  margin-bottom: 12px;
  font-size: 1.2rem;

  &::before {
    color: #6c6e6e;
    margin-right: 5px;
    font-size: 1.2rem;
    font-family: 'Material Icons';
    content: '${({ icon }) => icon}';
  }
`

export const StyledP1 = styled.p`
  white-space: pre-wrap;
  font-size: 1.3rem;
  line-height: 1.5;
  color: rgb(81, 81, 81);
`

export const StyledDiv11 = styled.div`
  ${mediaQuery()} {
    margin: 0 0 10px;
    text-align: right;
  }
`

export const StyledDiv12 = styled.div`
  ${mediaQuery()} {
    color: #515151;
    padding: 15px 0;
    background: hsla(0, 0%, 100%, 0.7);
  }
`

export const StyledDiv13 = styled.div`
  margin-bottom: 40px;

  ${mediaQuery()} {
    margin-bottom: 0;
  }
`

export const StyledP2 = styled.p`
  ${mediaQuery()} {
    color: #515151;
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 1.2rem;
    padding: 0 10px;
  }
`

export const StyledP3 = styled(StyledP2)`
  ${mediaQuery()} {
    white-space: pre-wrap;
    font-size: 1.3rem;
  }
`
