import React from 'react'
import styled from 'styled-components'

/** そだてれび：海外視聴用 PC 単話ページ動画詳細 リスト */
const List = ({ children }) => {
  return (
    <StyledUl className="c-post__info__detail">
      {children.map((child, i) => (
        <StyledLi key={i}>{child}</StyledLi>
      ))}
    </StyledUl>
  )
}

export default List

const StyledUl = styled.ul`
  border-top: 1px solid #e6e4e4;
  padding: 18px 0 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`

const StyledLi = styled.li`
  font-size: 12px;

  &::before {
    color: #515151;
  }
`
