import styled, { css } from 'styled-components'
import { mediaSp } from '../styles'
import { Link } from '../../../../../../sketch-platform/ui/routing'

export const StyledDiv1 = styled.div`
  padding: 28px 0 26px;
  display: flex;

  @media ${mediaSp} {
    padding-bottom: 0;
    display: block;
  }
`

export const StyledSection = styled.section`
  width: 50%;

  @media ${mediaSp} {
    margin: 0 auto;
    padding-bottom: 26px;
    width: auto;
  }

  &:not(:first-child) {
    border-left: 1px solid #9a9a9a;

    @media ${mediaSp} {
      padding-top: 28px;
      border-top: 1px solid #9a9a9a;
      border-left-width: 0;
    }
  }
`

export const StyledForm = styled.form`
  margin: 0 auto;
  max-width: 450px;
  text-align: center;
`

export const StyledUl = styled.ul`
  margin-top: 28px;
`

export const StyledLi = styled.li`
  margin-top: 34px;

  @media ${mediaSp} {
    margin-top: 14px;
  }

  &:first-child {
    margin-top: 0;
  }

  input {
    padding: 12px;
    width: 100%;
    height: 50px;
    border: 1px solid #343434;
    border-radius: 2px;
    font-size: 1.4rem;
  }
`

export const StyledLabel = styled.label`
  margin: 34px auto 0;
  padding-left: 30px;
  max-width: 346px;
  height: 22px;
  display: flex;
  position: relative;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 300;
  cursor: pointer;

  @media ${mediaSp} {
    max-width: 323px;
    font-size: 1.2rem;
  }

  &::after,
  &::before {
    margin: auto 0;
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
  }

  &::before {
    content: '';
    width: 22px;
    height: 22px;
    background-color: ${({ isChecked }) => (isChecked ? '#343434' : '#fff')};
    border: 1px solid #343434;
    border-radius: 5px;
    left: 0;
  }

  &::after {
    content: ${({ isChecked }) => (isChecked ? "''" : 'none')};
    width: 5px;
    height: 9px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 8px;
    transform: translateY(-2px) rotate(45deg);
  }
`

export const StyledButton = styled.button`
  margin: 54px auto 0;
  padding: 15px 20px;
  width: 346px;
  background-color: #343434;
  border-width: 0;
  border-radius: 5px;
  display: block;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  transition: opacity 0.3s, background-color 0.3s;

  @media ${mediaSp} {
    margin-top: 14px;
    line-height: 1;
  }

  &:hover {
    color: #fff;
    background-color: #939394;
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  ${({ isSilk }) => {
    if (isSilk) {
      return css`
        margin-top: 14px;
      `
    }
    return null
  }}
`

export const StyledLink = styled(Link)`
  margin-top: 14px;
  display: inline-block;
  color: #b7b7b7;
  font-size: 1.3rem;
  text-decoration: underline;
  text-align: center;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: #b7b7b7;
  }

  &:hover {
    text-decoration: none;
  }
`
