import styled, { css } from 'styled-components'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import Nav5 from '../Nav5/'
import Notice from './Notice/'
import Button1 from '../common/Button/Button1'
import Button3 from '../common/Button/Button3'
import Button2 from '../common/Button/Button2'
import { mediaQuery, breakPoints } from '../../../style'
import { pcMinSize } from '../styles'

export const StyledDiv1 = styled.div`
  background: url(/images/exdio/renewal/gariben_daigaku/top/MV_gari_2023.webp)
    repeat-x top -520px center / 1920px auto fixed;
  text-align: center;

  ${mediaQuery()} {
    background: url(/images/exdio/renewal/gariben_daigaku/top/MV_gari_2023.webp)
      repeat-x top -260px center / 960px auto fixed;
  }
`

export const StyledDiv2 = styled.div`
  margin: 0 auto;
  padding: 30px 0 15px;
  width: ${pcMinSize};
  min-height: 754px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  ${mediaQuery()} {
    margin: 0;
    padding: 10px 0 0;
    width: 100%;
    height: auto;
    min-height: 236px;
  }
`

export const StyledLink = styled(Link)`
  position: absolute;
  top: 10px;
  left: 0;
  transition: 0.4s ease-in-out;

  ${mediaQuery()} {
    top: 5px;
    left: 4px;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const StyledImg1 = styled.img`
  width: 150px;
  vertical-align: baseline;

  ${mediaQuery()} {
    vertical-align: bottom;
    width: 80px;
  }
`

export const StyledH1 = styled.h1`
  margin: 0 auto;
  width: 248px;

  ${mediaQuery()} {
    width: 128px;
  }
`

export const StyledButton3 = styled(Button3)`
  margin: 50px auto 0;
  width: 470px;
  height: 80px;
  font-size: 2.4rem;
  font-weight: 700;

  span {
    font-size: 2rem;
  }

  ${mediaQuery()} {
    display: none;
  }
`

export const StyledButton1 = styled(Button1)`
  width: 334px;
  height: 60px;
  font-size: 2rem;
`

export const StyledButton2 = styled(Button2)`
  width: 334px;
  height: 60px;
  font-size: 2rem;
`

export const StyledUl = styled.ul`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;

  ${mediaQuery()} {
    display: none;
  }
`

export const StyledNotice = styled(Notice)`
  margin-top: 50px;

  ${mediaQuery()} {
    margin: 0 auto;
    width: calc(100vw - 20px);
    position: absolute;
    top: 165px;
    right: 0;
    left: 0;
  }

  ${({ status }) => {
    if (status.isPurchased) {
      return css`
        ${mediaQuery('sm', 'min')} {
          margin-top: 270px;
        }
      `
    }
    return null
  }}
`

export const StyledNav5 = styled(Nav5)`
  margin: 66px auto 0;

  ${({ status }) => {
    if (status.isPurchased) {
      return css`
        margin-top: auto;
      `
    }
    return null
  }}

  ${mediaQuery()} {
    margin-top: 130px;
  }
`
