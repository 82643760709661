import React from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import MyPoint from './MyPoint'

/** style */
import styled, { css } from 'styled-components'
import { fixColors, mediaQuery } from '../../../../../exdio/components/style'

const LinkHeader = (
  {
    HtmlElement = 'h3',
    children = '',
    myPage = false,
    route = '',
    onClick = () => {},
    ...props
  },
  context
) => {
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  if (!children) return null

  return (
    <StyledHeader myPage={myPage} isLoggedIn={isLoggedIn} {...props}>
      {/* 見出し */}
      <StyledH3 as={HtmlElement}>
        {route ? (
          <StyledLink1 route={route} onClick={onClick}>
            {children}
          </StyledLink1>
        ) : (
          <StyledSpan>{children}</StyledSpan>
        )}
      </StyledH3>
      <MyPoint myPage={myPage} isLoggedIn={isLoggedIn} onClick={onClick} />
    </StyledHeader>
  )
}

export default LinkHeader

LinkHeader.contextTypes = {
  models: PropTypes.object,
  history: PropTypes.object,
  routeHandler: PropTypes.object
}

LinkHeader.propTypes = {
  /** 見出しがリンクの場合こちらを追加 */
  route: PropTypes.object,
  /** 見出しがマイページのコンテンツを表示する場合追加 */
  myPage: PropTypes.bool,
  HtmlElement: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func
}

const StyledHeader = styled.header`
  ${({ myPage, isLoggedIn }) =>
    myPage &&
    isLoggedIn &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    `}
`

const StyledH3 = styled.h3`
  width: 124px;
  margin-bottom: -1px;
  font-weight: 600;
`

const StyledHeadInner = css`
  display: block;
  font-size: 1.4rem;
  padding: 1.2rem 0 1.2rem 1.8rem;
  background: ${fixColors.colorFont};
  border-top-right-radius: 10px;
  position: relative;
  color: #fff;
  ${mediaQuery('mm')} {
    font-size: 1.2rem;
  }
`

const StyledLink1 = styled(Link)`
  ${StyledHeadInner}

  &:link {
    color: #fff;
  }

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }

  &:visited {
    color: #fff;
  }

  &::before {
    content: '';
    display: block;
    background-size: contain;
    vertical-align: middle;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 18px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 8px;
    height: 8px;
    transform: rotate(-45deg);
    right: 12px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }
`

const StyledSpan = styled.span`
  ${StyledHeadInner}
`
