import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import window from 'global';
import Cookie from 'js-cookie';
import FeaturePaletteItem from './FeaturePaletteItem';
import routes from '../../routes';
import {
  ADVERTISING_SCHEMA_ID,
  META_SCHEMA_ID,
  PALETTE_SCHEMA_ID,
  GENRE_SEARCH_TYPE,
  PRODUCT_SCHEMA,
  SEARCH_TYPE
} from '../../../../constants/app';
import webApp from '../../../exdio/utils/exdioWebAppUtils';
import Link from '../../../../sketch-platform/ui/routing/Link';
import PaletteKeywords from './PaletteKeywords';

/** 特集用パレットコンポーネント */
class FeaturePalette extends Component {
  static propTypes = {
    palette: PropTypes.shape({
      name: PropTypes.string.isRequired,
      schema_id: PropTypes.number,
      objects: PropTypes.array.isRequired
    }).isRequired,
    howToPlays: PropTypes.object,
    large: PropTypes.bool,
    products: PropTypes.object,
    courses: PropTypes.object,
    paletteHeader: PropTypes.bool
  };

  static defaultProps = {
    howToPlays: {},
    large: false,
    products: null,
    courses: null,
    paletteHeader: true
  };

  static contextTypes = {
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object,
    models: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.config = this.context.models.config.data;
    this.state = {
      link: null,
      showPrev: false,
      showNext: false,
      recommendItems: [],
      genre: null,
      dsearchMetas: null,
      paletteInSeriesLatestEpisode: null // パレットに含まれるシリーズの最新のエピソード
    };

    this.isIndividualRecommend = props.palette.schema_id === PALETTE_SCHEMA_ID.INDIVIDUAL_RECOMMEND;
    this.isDsearch = PALETTE_SCHEMA_ID.GENRE_DSEARCH === props.palette.schema_id;

    this.onClickItem = this.onClickItem.bind(this);

    this.setSliderContentRef = e => {
      this.sliderContentRef = e;
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { palette } = this.props;
    const genreId = _.get(palette, ['values', 'genre']) || _.get(palette, ['values', 'category2']);

    // 複数箇所で参照するのでジャンル属性は先に同期取得
    if (genreId) await this.getAttribute();

    // props.paletteのobject(編成情報)にシーズンが含まれていたら、
    // そのシーズンの最新のエピソードを、最終更新日として表示するために取得する
    // ※ ここが処理のボトルネックになる可能性が高い。どうしても遅くなる場合は要検討、
    //   １発で取る方法を考えたが思いつかない
    // TODO 念の為残しておく
    //if ( palette.objects ) {
    //  this.getSeriesLatestEpisode();
    //}

    if (this.isDsearch) {
      this.getMetasByGenre()
        .then(() => this.getHowToPlays())
        .then(() => this.scrollPalette(0))
        .catch(e => webApp.utils.handleFalcorError(e, this.context));
    } else {
      this.scrollPalette(0);
    }

    this.setMoreLink();

    // レコメンド
    this.getRecommend();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /** ジャンル属性情報取得 */
  getAttribute() {
    const { palette } = this.props;
    const genreId = _.get(palette, ['values', 'genre']) || _.get(palette, ['values', 'category2']);
    const path = ['attribute', genreId];
    return this.model.fetch([path]).then(result => {
      const genre = _.get(result, ['json', ...path]) || {};
      if (genre && this._isMounted) {
        this.setState({ genre });
      }
    });
  }

  /** メタ情報取得 */
  getMetasByGenre() {
    const { palette } = this.props;
    const genreId = _.get(palette, ['values', 'genre']);
    const searchType = _.get(palette, ['values', 'search_type']);

    // 注)オートで検索させるパレットを使用したときはライブは入れないようにしてます。
    //    入れたいならここを直す。もしかしたらfalcorも直さないといけないかもしれない
    const metaSchemaId = GENRE_SEARCH_TYPE.FREE.value === searchType ? META_SCHEMA_ID.EPISODE : '';
    const sort = 'delivery_start_at';
    const order = 'desc';
    const range = { from: 0, to: this.config.extras.genre_list_limit_in_palette - 1 };
    const isExclusive = 0;
    const metaPath = [['metaByGenre', metaSchemaId, genreId, isExclusive, sort, order, [range]]];
    return this.model.fetch(metaPath).then(result => {
      const resMetas = _.get(result, ['json', 'metaByGenre', metaSchemaId, genreId, isExclusive, sort, order]) || {};
      const metas = _.range(range.from, range.to + 1)
        .map(i => resMetas[i])
        .filter(v => v);
      if (this._isMounted) {
        this.setState({ dsearchMetas: metas });
      }
    });
  }

  /** 指定されたシーズンに紐付く最新のエピソードを取得する */
  async getSeriesLatestEpisode() {
    const { palette } = this.props;

    let paletteInSeriesLatestEpisode = {};
    for (let object of palette.objects || []) {
      const meta = _.get(object, 'meta');
      if (meta && meta.meta_schema_id === META_SCHEMA_ID.SEASON) {
        const metaPath = [['meta', 'season', meta_id, 'latestChild']];
        await this.model.fetch(metaPath).then(result => {
          const resMetas = _.get(result.json, metaPath[0]) || {};
          if ( resMetas[0] ) {
            paletteInSeriesLatestEpisode[meta_id] = resMetas[0];
          }
        });
      }
    }
    if (this._isMounted) {
      this.setState({paletteInSeriesLatestEpisode: paletteInSeriesLatestEpisode});
    }
  }

  /** 価格情報取得 */
  getHowToPlays() {
    const { howToPlays } = this.props;
    const { dsearchMetas } = this.state;
    if (!dsearchMetas || !dsearchMetas.length) return Promise.resolve();

    // propsのhowToPlayにないものがあれば別途取得
    const metaIds = dsearchMetas
      .filter(meta => meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE || meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE)
      .filter(meta => !howToPlays[meta.meta_id])
      .map(e => e.meta_id);
    const path = [['meta', 'howToPlay', false, metaIds]];
    return this.model.fetch(path).then(result => {
      const howToPlaysOpt = _.get(result, ['json', 'meta', 'howToPlay', false]) || {};
      if (this._isMounted) {
        this.setState({ howToPlays : Object.assign(howToPlays, howToPlaysOpt)});
      }
    });
  }

  /**
   * パレットスクロール
   * ボタンのみの制御で通常のscrollを使用しないため位置座標にて制御
   */
  scrollPalette(direction) {
    if (!this.sliderContentRef) return;

    // 位置、サイズ取得
    const scrollAreaWidth = this.sliderContentRef.offsetWidth;
    let left = this.sliderContentRef.offsetLeft;
    const children = this.sliderContentRef.childNodes;
    const totalWidth = Array.from(children)
      .map(child => window.getComputedStyle(child))
      .flatMap(childStyle => [childStyle.width, childStyle.marginLeft, childStyle.marginRight])
      .reduce((reduced, value) => {
        return reduced + Number(value.replace('px', ''));
      }, 0);

    // 次の位置算出
    // 左スクロール
    if (direction === -1) {
      // 表示が切れている左端の要素
      let borderChild = null;
      for (let i = children.length; i > 0; i--) {
        if (left + children[i - 1].offsetLeft < 0) {
          borderChild = children[i - 1];
          break;
        }
      }
      left += scrollAreaWidth - (left + borderChild.offsetLeft + borderChild.offsetWidth);
      if (left > 0) left = 0;
    }
    // 右スクロール
    else if (direction === 1) {
      // 表示が切れている右端の要素
      let borderChild = null;
      for (let i = 0; i < children.length; i++) {
        if (left + children[i].offsetLeft + children[i].offsetWidth > scrollAreaWidth) {
          borderChild = children[i];
          break;
        }
      }
      left = -borderChild.offsetLeft;
    }

    // 位置設定
    let right = left + totalWidth;
    if (left < 0 && right < scrollAreaWidth) {
      left = scrollAreaWidth - totalWidth;
      right = left + totalWidth;
    }
    this.sliderContentRef.style.left = `${left}px`;

    // scrollボタン表示判定
    if (this._isMounted) {
      this.setState({
        showPrev: left < 0,
        showNext: scrollAreaWidth < right
      });
    }
  }

  /** パレット要素クリック時 */
  onClickItem(meta, product, course, autoPlay = true) {
    if (this.isIndividualRecommend && meta) {
      this.recommendClick(meta.meta_id);
    }
    webApp.utils.goToProgramLink(this.context, meta, product, course, { autoPlay });
  }

  /** もっと見るリンク先設定 */
  setMoreLink() {
    const { palette } = this.props;
    const { genre } = this.state;

    if (genre) {
      let idOrGenreSearchType = null;
      if (palette.schema_id === PALETTE_SCHEMA_ID.GENRE_RECOMMEND) {
        // /genre/[ジャンルディレクトリ名]/recommend
        idOrGenreSearchType = 'recommend';
      } else if (palette.schema_id === PALETTE_SCHEMA_ID.GENRE_DSEARCH) {
        // /genre/[ジャンルディレクトリ名]/newarrival
        // /genre/[ジャンルディレクトリ名]/free
        idOrGenreSearchType = _.get(palette, ['values', 'search_type']);
      } else if (palette.schema_id === PALETTE_SCHEMA_ID.GENRE_FIXED) {
        // /genre/[ジャンルディレクトリ名]/{パレットID}
        idOrGenreSearchType = palette.palette_id;
      }
      if (!idOrGenreSearchType) return;
      if (genre.slug && this._isMounted) {
        this.setState({
          link: { route: routes.genreSearch, params: { genreKey: genre.slug, idOrGenreSearchType } }
        });
      }
    } else {
      if (palette.schema_id === PALETTE_SCHEMA_ID.RECOMMEND) {
        // テレ朝動画のオススメ
        if (this._isMounted) {
          this.setState({ link: { route: routes.recommend } });
        }
      } else if (palette.schema_id === PALETTE_SCHEMA_ID.CATCHUP) {
        // 見逃し無料
        if (this._isMounted) {
          this.setState({ link: { route: routes.catchup } });
        }
      } else if (palette.schema_id === PALETTE_SCHEMA_ID.INDIVIDUAL_RECOMMEND) {
        // あなたへのオススメ
        if (this._isMounted) {
          this.setState({ link: { route: routes.recommendYou, params: { paletteId: palette.palette_id } } });
        }
      }
    }
  }

  /** レコメンド処理 */
  async getRecommend() {
    const { palette } = this.props;
    if (!this.isIndividualRecommend || !palette) return;
    const { api_type, tags1, category2 } = _.get(palette, ['values']) || {};

    let refId = '';
    if (tags1) {
      // tags1はコースID
      const path = ['course', tags1];
      refId = await this.model.fetch([path]).then(result => {
        const course = _.get(result, ['json', ...path]) || {};
        return course.ref_id;
      });
    }

    let genreName = '';
    if (category2) {
      // category2はジャンル名
      const path = ['attribute', category2];
      genreName = await this.model.fetch([path]).then(result => {
        const genre = _.get(result, ['json', ...path]) || {};
        return genre.name;
      });
    }

    let getRecommendExec = null;
    if (api_type === 'one_to_one') {
      getRecommendExec = this.oneToOne.bind(this);
    } else if (api_type === 'recommend') {
      getRecommendExec = this.recommend.bind(this);
    } else if (api_type === 'longtail') {
      getRecommendExec = this.longtail.bind(this);
    } else {
      return;
    }
    getRecommendExec(genreName, refId)
      .then(() => this.recommendActionLog())
      .then(() => this.scrollPalette(0))
      .catch(e => webApp.utils.handleFalcorError(e, this.context));
  }

  /** One to Oneレコメンド */
  oneToOne(genreName, courseRefId) {
    const { palette } = this.props;
    const itemIdsCsv = (_.get(palette, ['values', 'item_ids']) || []).slice(0, 10).join(',');
    const userId = Cookie.get('luid') || Cookie.get('_ga') || '';
    // onetooneはuser_id必須なのでluid取れなければ非表示
    if (!userId) {
      webApp.utils.debug(`[Palette] skip call one_to_one API because luid and _ga are blank. id: ${(palette || {}).palette_id}`);
      return Promise.resolve();
    }
    const spotName = _.get(palette, ['values', 'spot_name']) || '';
    let type = _.get(palette, ['values', 'category1']) || SEARCH_TYPE.ALL.value;
    if (type === SEARCH_TYPE.FREE.value && webApp.utils.isLoggedIn(this.context)) {
      type = SEARCH_TYPE.AUTH_FREE.value;
    }
    const metaSchemaId = _.get(palette, ['values', 'category3']) || '';
    const responseNumber = _.get(palette, ['values', 'display_count']) || 8;
    const path = ['oneToOne', itemIdsCsv, userId, spotName, type, genreName, metaSchemaId, courseRefId, responseNumber];
    return this.model.fetch([path]).then(result => {
      const recommendItems = _.get(result, ['json', ...path]) || [];
      if (this._isMounted) {
        this.setState({ recommendItems });
      }
    });
  }

  /** レコメンド */
  recommend(genreName, courseRefId) {
    const { palette } = this.props;
    const itemIdsCsv = (_.get(palette, ['values', 'item_ids']) || []).slice(0, 10).join(',');
    if (!itemIdsCsv) return Promise.resolve();
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || '';
    const spotName = _.get(palette, ['values', 'spot_name']) || '';
    let type = _.get(palette, ['values', 'category1']) || SEARCH_TYPE.ALL.value;
    if (type === SEARCH_TYPE.FREE.value && webApp.utils.isLoggedIn(this.context)) {
      type = SEARCH_TYPE.AUTH_FREE.value;
    }
    const metaSchemaId = _.get(palette, ['values', 'category3']) || '';
    const responseNumber = _.get(palette, ['values', 'display_count']) || 8;
    const path = ['recommend', itemIdsCsv, sessionId, spotName, type, genreName, metaSchemaId, courseRefId, responseNumber];
    return this.model.fetch([path]).then(result => {
      const recommendItems = _.get(result, ['json', ...path]) || [];
      if (this._isMounted) {
        this.setState({ recommendItems });
      }
    });
  }

  /** ロングテール */
  longtail(genreName, courseRefId) {
    const { palette } = this.props;
    const itemIdsCsv = (_.get(palette, ['values', 'item_ids']) || []).slice(0, 10).join(',');
    if (!itemIdsCsv) return Promise.resolve();
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || '';
    const spotName = _.get(palette, ['values', 'spot_name']) || '';
    let type = _.get(palette, ['values', 'category1']) || SEARCH_TYPE.ALL.value;
    if (type === SEARCH_TYPE.FREE.value && webApp.utils.isLoggedIn(this.context)) {
      type = SEARCH_TYPE.AUTH_FREE.value;
    }
    const metaSchemaId = _.get(palette, ['values', 'category3']) || '';
    const responseNumber = _.get(palette, ['values', 'display_count']) || 8;
    const path = ['recommendLongtail', itemIdsCsv, sessionId, spotName, type, genreName, metaSchemaId, courseRefId, responseNumber];
    return this.model.fetch([path]).then(result => {
      const recommendItems = _.get(result, ['json', ...path]) || [];
      if (this._isMounted) {
        this.setState({ recommendItems });
      }
    });
  }

  /** レコメンド(ログ蓄積) */
  recommendActionLog() {
    const { palette } = this.props;
    const { recommendItems } = this.state;
    if (!recommendItems || !recommendItems.length) return Promise.resolve();

    const itemIds = recommendItems.map(r => r.meta_id).join(',');
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || '';
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) {
      webApp.utils.debug(`[Pallete] skip call action_log/kpi/display API because luid and _ga are blank. id: ${(palette || {}).palette_id}`);
      return Promise.resolve();
    }
    const typeName = this.config.recommend.type_name.view;
    const spotName = _.get(palette, ['values', 'spot_name']) || '';
    const path = ['action_log', 'kpi', 'display', itemIds, sessionId, typeName, spotName];
    return this.model.fetch([path]);
  }

  /** レコメンド(クリック) */
  recommendClick(itemId) {
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || '';
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) {
      const { palette } = this.props;
      webApp.utils.debug(`[Pallete] skip call action_log/kpi/click API because luid and _ga are blank. id: ${(palette || {}).palette_id}`);
      return Promise.resolve();
    }
    const typeName = this.config.recommend.type_name.view;
    const spotName = this.config.recommend.spot_name.top;
    const path = ['action_log', 'kpi', 'click', itemId, sessionId, typeName, spotName];
    return this.model.fetch([path]);
  }


  /** あなたへのオススメをレンダリング */
  renderRecommend() {
    if (!this.isIndividualRecommend) return null;
    const { recommendItems } = this.state;
    return (recommendItems || []).map(item => {
      const ids = item.ids ? JSON.parse(item.ids) : {};
      const deliveryDateTime = item.delivery_datetime ? JSON.parse(item.delivery_datetime) : {};
      const delivery_start_at = (deliveryDateTime.start_at || '').replace(/-/g, '/');
      const delivery_end_at = (deliveryDateTime.end_at || '').replace(/-/g, '/');
      const meta = {
        meta_id: item.meta_id,
        meta_schema_id: item.meta_schema_id,
        thumbnail_url: item.thumbnail_url,
        duration: item.duration,
        delivery_start_at,
        delivery_end_at,
        name: item.episode_name,
        values: {
          avails_EpisodeTitleDisplayUnlimited: item.episode_name,
          evis_EpisodeLongSynopsis: item.synopsis,
          parents_series: {
            id: ids.series
          },
          parents_season: {
            id: ids.season,
            avails_SeasonTitleDisplayUnlimited: item.program_name
          }
        }
      };
      const price = item.price ? JSON.parse(item.price) : {};
      const priceTvod = price.tvod;
      const refId = item.course_ref_ids ? item.course_ref_ids.split(',')[0] : '';
      const priceSvod = _.get(price, ['svod', refId]) || null;
      const howToPlay = {
        products: [{
          schema_id: PRODUCT_SCHEMA.SINGLE_STORY.id,
          active_pricing: { price: priceTvod }
        }],
        courses: [{ active_pricing: { price: priceSvod } }]
      };
      return (
        <FeaturePaletteItem
          key={meta.meta_id}
          meta={meta}
          howToPlay={howToPlay}
          showRemaining={META_SCHEMA_ID.EPISODE === meta.meta_schema_id || META_SCHEMA_ID.LIVE === meta.meta_schema_id}
          showDeadLine
          showDelivery
          showDeliveryEndPriorToStart
          showCoin
          showNew={webApp.utils.showNew(meta)}
          breakSubTitle
          onClickThumbnail={() => this.onClickItem(meta, null, null)}
          onClickCaption={() => this.onClickItem(meta, null, null, false)}
        />
      );
    });
  }

  /** パレットの編成内容をレンダリング */
  renderObjects() {
    if (this.isDsearch || this.isIndividualRecommend) return null;
    const { palette, howToPlays, products, courses } = this.props;

    return (palette.objects || []).map(object => {
      const productId = _.get(object, ['advertising', 'values', 'product']) || null;
      const courseId = _.get(object, ['advertising', 'values', 'course']) || null;
      const product = productId && _.get(products, productId);
      const course = courseId && _.get(courses, courseId);
      const howToPlay = _.get(howToPlays, object.id) || null;

      const paletteAd = _.get(object, 'advertising');
      if (paletteAd) {
        if (paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT) {
          const thumbnail =
            _.get(paletteAd, ['creatives', 0, 'attachment', 'file_url']) ||
            this.context.models.config.data.default_thumbnail;
          return (
            <FeaturePaletteItem
              key={`${object.type}-${object.id}`}
              title={paletteAd.name}
              thumbnail={thumbnail}
              onClick={() => {
                if (paletteAd.url) window.location.href = paletteAd.url;
              }}
            />
          );
        } else {
          return (
            <FeaturePaletteItem
              key={`${object.type}-${object.id}`}
              product={product}
              course={course}
              howToPlay={howToPlay}
              showDelivery
              showInCourse
              onClick={() => this.onClickItem(null, product, course)}
            />
          );
        }
      }

      const meta = object.meta || null;
      const showRemaining = meta && (META_SCHEMA_ID.EPISODE === object.meta.meta_schema_id || META_SCHEMA_ID.LIVE === object.meta.meta_schema_id) ;
      return (
        <FeaturePaletteItem
          key={`${object.type}-${object.id}`}
          meta={meta}
          product={product}
          course={course}
          howToPlay={howToPlay}
          showRemaining={showRemaining}
          showDelivery
          showDeadLine
          showCoin
          showInCourse
          showNew={webApp.utils.showNew(meta)}
          breakSubTitle
          showDeliveryEndPriorToStart
          onClickThumbnail={() => this.onClickItem(meta, product, course)}
          onClickCaption={() => this.onClickItem(meta, product, course, false)}
        />
      );
    });
  }

  render() {
    return this.renderObjects()
  }
}

export default FeaturePalette;
