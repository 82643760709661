import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import webApp from '../../../utils/exdioWebAppUtils';

moment.createFromInputFallback = config => { config._d = new Date(config._i) };

/** 動画、番組リスト要素コンポーネント */
export default class PaletteItem extends Component {
  static propTypes = {
    // 主情報
    /** メタ */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.shape({
        evis_EpisodeLongSynopsis: PropTypes.string,
        evis_SeasonLongSynopsis: PropTypes.string,
        evis_SeriesLongSynopsis: PropTypes.string,
        parents_series: PropTypes.shape({
          avails_SeriesTitleDisplayUnlimited: PropTypes.string
        }),
        parents_season: PropTypes.shape({
          avails_SeasonTitleDisplayUnlimited: PropTypes.string
        }),
        avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
        ex_templateId: PropTypes.number
      }).isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }),


    // イベントハンドラ
    // 各onClickは引数に(event)を渡して呼び出される
    onClickThumbnail: PropTypes.func,
  };

  static defaultProps = {
    meta: null,
    onClickThumbnail: null,
  };

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.config = this.context.models.config.data;
    this.state = {};
  }

  /** サムネイル画像 */
  thumbnail() {
    const { meta } = this.props;
    let thumbnailUrl = null;
    let thumbnailAlt = null;
    if (meta) {
      thumbnailUrl = meta.thumbnail_url;
    }

    /* 読み込む画像サイズを変更する */
    thumbnailUrl = webApp.utils.customSizeImageUrl(thumbnailUrl, 'medium')

    return [thumbnailUrl || this.config.default_thumbnail, thumbnailAlt];
  }

  handleClick(propFunc, e) {
    e.preventDefault();
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation();
      propFunc(e);
    }
  }

  render() {
    const { onClickThumbnail } = this.props;
    const [thumbnailUrl] = this.thumbnail();

    return (
      <a href="#" className="c-palettes__plan--slideItem" onClick={e => this.handleClick(onClickThumbnail, e)}>
        <img src={thumbnailUrl} alt="" />
      </a>
    );
  }
}
