/* style */
import styled from 'styled-components'

/* components */
import Link from '../../../../../../../../sketch-platform/ui/routing/Link'

export const mediaSp = '(max-width: 1024px)'

export const StyledFooter = styled.footer`
  grid-area: footer;
  padding: 20px 0 16px;
  background-color: #000;

  @media ${mediaSp} {
    margin-top: 3.5rem;
    padding: 4rem 16px 16px 16px;
  }
`

export const StyledDiv1 = styled.div`
  display: flex;
  max-width: 1280px;
  margin: 0 auto;

  @media ${mediaSp} {
    max-width: 1024px;
    display: block;
  }
`

export const StyledDiv2 = styled.div`
  margin-top: 2.4rem;
  width: 50%;
  display: flex;

  @media ${mediaSp} {
    display: block;
    width: 100%;
    padding-bottom: 0;
    margin-top: 4rem;
  }
`

export const StyledDiv3 = styled.div`
  width: 50%;

  @media ${mediaSp} {
    width: 100%;
  }
`

export const StyledUl1 = styled.ul`
  padding: 1.5rem;

  @media ${mediaSp} {
    padding: 0;
    background: #fff;
  }
`

export const StyledLi1 = styled.li`
  border-bottom: 1px solid #e2e2e2;

  ${StyledDiv3} & {
    font-size: 1.3rem;
    @media ${mediaSp} {
      font-size: 1.5rem;
    }
  }
`

export const StyledLink1 = styled(Link)`
  && {
    color: #fff;
    display: block;
    line-height: 1.4;

    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }

    &:visited {
      color: #fff;
    }

    ${StyledDiv3} & {
      padding: 1.57em 0.42em;
      @media ${mediaSp} {
        padding: 1.46em;
      }
    }
  }
`

export const StyledDiv4 = styled.div`
  margin-top: 4.8rem;
  padding: 0 0 0 1.2rem;
  width: 50%;
  color: #343434;

  @media ${mediaSp} {
    margin-top: 0;
    width: 100%;
    padding: 0;
  }
`

export const StyledP = styled.p`
  font-size: 1.1rem;
  line-height: 1.4;
  color: #fff;

  @media ${mediaSp} {
    padding: 2rem 1.5rem 1.5rem 1.5rem;
  }
`

export const StyledDiv5 = styled.div`
  margin-top: 2rem;
  word-break: break-all;

  @media ${mediaSp} {
    display: none;
  }
`

export const StyledUl2 = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 1.4rem;
  background: #2d2d2d;
`

export const StyledLi2 = styled.li`
  width: 33%;
  color: #fff;
`

export const StyledDl = styled.dl`
  display: flex;
`

export const StyledDt = styled.dt`
  width: 5.2rem;
`

export const StyledDd = styled.dd`
  margin-left: 1.4rem;
  width: 100%;
  font-size: 1rem;
`

export const StyledH4 = styled.h4`
  margin-bottom: 0.5em;
  font-weight: 600;
`

export const StyledP2 = styled.p`
  line-height: 1.4;
`

export const StyledUl3 = styled.ul`
  margin: 64px auto 0;
  width: 872px;
  text-align: center;

  @media ${mediaSp} {
    margin: 16px 0 0;
    width: 100%;
  }
`

export const StyledLi3 = styled.li`
  margin-left: 32px;
  width: 256px;
  display: inline-block;

  @media ${mediaSp} {
    margin-left: 16px;
    max-width: 240px;
    width: calc((100% - 16px) / 2);
  }

  &:first-child {
    margin-left: 0;
  }
`

export const StyledLink2 = styled(Link)`
  text-align: center;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`

export const StyledImg = styled.img`
  width: 100%;
`
