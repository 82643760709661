import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import routes from '../../../../common/routes'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import webApp from '../../../utils/exdioWebAppUtils'
import { META_SCHEMA_ID } from '../../../../../constants/app'

/** 詳細画面:単話ページングコンポーネント */
export default class EpisodePager extends Component {
  static propTypes = {
    // 月額見放題に含まれない単話の場合
    meta: PropTypes.shape({
      values: PropTypes.shape({
        prev_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
        next_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
      })
    }),
    seriesId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        meta_schema_id: PropTypes.number
      })
    ),

    // 月額見放題に含まれる単話の場合
    course: PropTypes.shape({
      slug: PropTypes.string
    }),
    className: PropTypes.string
  }

  static defaultProps = {
    meta: null,
    seriesId: null,
    seasonId: null,
    episodes: [],
    course: null,
    className: ''
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    routeHandler: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.state = {
      prevMeta: null,
      nextMeta: null
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getPrevMeta()
    this.getNextMeta()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** 前のメタ情報取得 */
  getPrevMeta() {
    const { meta } = this.props
    if (!meta || !meta.values || !meta.values.prev_meta)
      return Promise.resolve()
    if (typeof meta.values.prev_meta === 'object') {
      this.setState({ prevMeta: meta.values.prev_meta })
      return Promise.resolve()
    }
    const path = ['metas', meta.values.prev_meta]
    return this.model.fetch([path]).then((result) => {
      this.setState({ prevMeta: _.get(result, ['json', ...path]) || null })
    })
  }

  /** 次のメタ情報取得 */
  getNextMeta() {
    const { meta } = this.props
    if (!meta || !meta.values || !meta.values.next_meta)
      return Promise.resolve()
    if (typeof meta.values.next_meta === 'object') {
      this.setState({ nextMeta: meta.values.next_meta })
      return Promise.resolve()
    }
    const path = ['metas', meta.values.next_meta]
    return this.model.fetch([path]).then((result) => {
      this.setState({ nextMeta: _.get(result, ['json', ...path]) || null })
    })
  }

  judgeEpisodeRoute(meta) {
    if (!meta) return null
    if (webApp.utils.isApp(this.context)) {
      return meta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE
        ? routes.app_catchupEpisode
        : routes.app_episode
    }
    return meta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
      meta.meta_schema_id === META_SCHEMA_ID.LIVE
      ? routes.catchupEpisode
      : routes.episode
  }

  render() {
    const { seriesId, seasonId, course, className } = this.props
    if (!seriesId || !seasonId) return null
    const { prevMeta, nextMeta } = this.state

    const isInCourse = course && course.slug
    const prevEpisodeRoute = this.judgeEpisodeRoute(prevMeta)
    const nextEpisodeRoute = this.judgeEpisodeRoute(nextMeta)
    const programRoute = webApp.utils.isApp(this.context)
      ? routes.app_program
      : routes.program
    const planRoute = webApp.utils.isApp(this.context)
      ? routes.app_plan
      : routes.plan

    let prevSeriesId = null
    let prevSeasonId = null
    if (prevMeta) {
      prevSeriesId = prevMeta.values.parents_series.id
      prevSeasonId = prevMeta.values.parents_season.id
    }
    let nextSeriesId = null
    let nextSeasonId = null
    if (nextMeta) {
      nextSeriesId = nextMeta.values.parents_series.id
      nextSeasonId = nextMeta.values.parents_season.id
    }

    return (
      <div className={`c-storyPager ${className}`}>
        <ul className="c-storyPager-inBox">
          <li className="c-storyPager-inBox-previous">
            {prevMeta ? (
              <Link
                route={prevEpisodeRoute}
                params={{
                  seriesId: prevSeriesId,
                  seasonId: prevSeasonId,
                  episodeId: prevMeta.meta_id
                }}
                className="c-storyPager-inBox-previous-link"
              >
                前のストーリー
              </Link>
            ) : (
              <span className="c-storyPager-inBox-previous-link">
                前のストーリー
              </span>
            )}
          </li>
          <li className="c-storyPager-inBox-list">
            <Link
              route={programRoute}
              params={{ seriesId, seasonId }}
              className="c-storyPager-inBox-list-link"
            >
              番組TOP
            </Link>
          </li>
          <li className="c-storyPager-inBox-forward">
            {nextMeta ? (
              <Link
                route={nextEpisodeRoute}
                params={{
                  seriesId: nextSeriesId,
                  seasonId: nextSeasonId,
                  episodeId: nextMeta.meta_id
                }}
                className="c-storyPager-inBox-forward-link"
              >
                次のストーリー
              </Link>
            ) : (
              <span className="c-storyPager-inBox-forward-link">
                次のストーリー
              </span>
            )}
          </li>
        </ul>
      </div>
    )
  }
}
