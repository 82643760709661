import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { mediaQuery } from '../../../../exdio/components/style'

/** リストタイプの切り替え */
const SwitchElm = ({ listType = '', updateListType = () => {}, ...props }) => {
  const types = ['default', 'list', 'grid']

  return (
    <StyledUl {...props}>
      {types.map((type) => (
        <li key={type}>
          <StyledLabel type={type} checked={listType === type}>
            <input
              type="radio"
              name="listType"
              value={type}
              checked={listType === type}
              onChange={() => updateListType(type)}
            />
          </StyledLabel>
        </li>
      ))}
    </StyledUl>
  )
}

export default SwitchElm

SwitchElm.propTypes = {
  listType: PropTypes.string,
  updateListType: PropTypes.func
}
const StyledUl = styled.ul`
  display: flex;
  gap: 0 30px;

  ${mediaQuery()} {
    gap: 0 15px;
  }
`

const StyledLabel = styled.label.withConfig({
  shouldForwardProp: (prop) => !['type', 'checked'].includes(prop)
})`
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;

  ${({ type, checked }) => {
    const ext = checked ? '_on' : ''
    const src = () => {
      switch (type) {
        case 'default':
          return `/images/exdio/renewal/icon_sort_thumb${ext}.svg`
        case 'list':
          return `/images/exdio/renewal/icon_sort_list${ext}.svg`
        case 'grid':
          return `/images/exdio/renewal/icon_sort_grid${ext}.svg`
        default:
          return ``
      }
    }

    return css`
      background: url(${src}) no-repeat center center;
    `
  }}
`
