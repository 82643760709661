import styled from 'styled-components'
import Slider from 'react-slick'
import { brown3 } from '../../styles'
import { mediaQuery } from '../../../../style'

export const StyledSlider = styled(Slider)`
  .slick {
    &-slide {
      padding: 0 60px;
    }

    &-arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: ${brown3};
      z-index: 1;
      transition: opacity 0.4s ease-in-out;

      &::before {
        width: 17px;
        height: 14px;
        background: url(/images/exdio/renewal/gariben_daigaku/components/list4_arrow.svg)
          no-repeat center / 17px auto;
        display: inline-block;
        opacity: 1;
        content: '';
      }
    }

    &-next {
      right: 5px;
    }

    &-prev {
      left: 5px;

      &::before {
        transform: rotate(180deg);
      }
    }
  }
`
