import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../common/routes'

/* style */
import { StyledHeader, StyledLink, StyledH1 } from './style'

/** Header Component */
const Header = ({ heading = '', ...props }, ref) => {
  return (
    <StyledHeader ref={ref} {...props}>
      <StyledLink route={routes.plan} params={{ slug: 'gariben_daigaku' }}>
        <img
          src="/images/exdio/renewal/gariben_daigaku/gariben_top_banner.png"
          width="250"
          height="45"
          alt="私立ガリベン大学"
        />
      </StyledLink>
      {heading && <StyledH1>{heading}</StyledH1>}
    </StyledHeader>
  )
}

export default forwardRef(Header)

Header.propTypes = {
  heading: PropTypes.string
}
