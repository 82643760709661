import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import window from 'global'
import Footer from '../../../../common/components/Footer'
import DFPBanner from '../../../../common/components/DFPBanner'
import { FAVORITE_TYPE } from '../../../../../constants/app'
import routes from '../../../../common/routes'

/** components */
import SideTab from '../details/SideTab'
import Caption from '../details/Caption'
import AddButtonBlock from '../details/AddButtonBlock'
import SideRecommend from '../details/SideRecommend'
import HtmlSnippet from '../../HtmlSnippet'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'
import Header from '../../../../common/components/Header'
import HeaderNewsComponent from '../HeaderNewsComponent'
import FilterSort from '../../../../common/components/FilterSort'
import SearchBoxYearWrapper from '../../../../common/components/FilterSort/SearchBoxYearWrapper'

/** style */
import styled from 'styled-components'
import { mediaSp } from '../plan_shinchan/styles'

/** 番組ページ デフォルトテンプレート */
export default class ProgramShinchan extends Component {
  static propTypes = {
    seriesId: PropTypes.string,
    seasonId: PropTypes.string,
    version: PropTypes.string,
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    episodes: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        original_price: PropTypes.number,
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    ),
    howToPlays: PropTypes.object
  }

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    version: '',
    episodes: [],
    products: [],
    howToPlays: {},
    loaded: true
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.state = {
      isSp: window.matchMedia('screen and (max-width: 1024px)').matches,
      pagerOptions: {
        // ページャーの設定
        episodesPerPages: 60,
        range: 2
      },
      selectedYear: null,
      rectY: 0
    }
    this.onResizeHandler = this.onResizeHandler.bind(this)
    this.updateSelectedYear = this.updateSelectedYear.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResizeHandler)
    /**
     * Planページにリダイレクト
     * https://media-plex.backlog.jp/view/DGA2-1479
     */
    this.context.history.push(routes.plan.makePath({ slug: 'shinchan' }))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeHandler)
  }

  /* 番組別お知らせの表示 */
  renderNotice() {
    const { meta } = this.props
    const notice = {
      text: _.get(meta, ['values', 'notice']),
      hyperlink: _.get(meta, ['values', 'notice_hyperlink']),
      start_at: _.get(meta, ['values', 'notice_publish_start_at']),
      end_at: _.get(meta, ['values', 'notice_publish_end_at'])
    }
    const now = Date.now()

    if (
      !notice.text ||
      now < Date.parse(notice.start_at) ||
      Date.parse(notice.end_at) < now
    ) {
      return null
    }

    const html = (
      <dl className="c-program_notice">
        <dt className="c-program_notice__term">お知らせ</dt>
        <dd className="c-program_notice__desc">{notice.text}</dd>
      </dl>
    )

    return notice.hyperlink ? (
      <a href={notice.hyperlink} className="c-program_notice__wrapper">
        {html}
      </a>
    ) : (
      <div className="c-program_notice__wrapper">{html}</div>
    )
  }

  /** 選択中の放送年を更新 */
  updateSelectedYear(selectYear) {
    this.setState({ selectedYear: selectYear })

    // sp時のみ年次選択時スクロール
    if (window.matchMedia('(max-width: 1024px)').matches) {
      const divElm = document.getElementById('episodes-list')
      const clientRect = divElm.getBoundingClientRect()
      const rectY = clientRect.top + window.pageYOffset
      this.setState({ rectY: rectY - 56 }, () => {
        window.scroll({ top: this.state.rectY, behavior: 'smooth' })
      })
    }
  }

  onResizeHandler() {
    const isSp = window.matchMedia('screen and (max-width: 1024px)').matches
    if (isSp !== this.state.isSp) {
      this.setState({ isSp })
    }
  }

  render() {
    const {
      seriesId,
      seasonId,
      version,
      meta,
      episodes,
      howToPlays,
      otherSeasons,
      loaded
    } = this.props

    const { isSp, pagerOptions, selectedYear } = this.state

    const thumbnailUrl = meta.thumbnail_url || this.config.default_thumbnail
    const bannerId1 = _.get(meta, ['values', 'banner_1'])
    const bannerId2 = _.get(meta, ['values', 'banner_2'])
    const noticeSnippet = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 45
      : 44

    const [metaName] = webApp.utils.titles(meta)
    let courseId = null

    // 許諾番号表示のため
    const firstEpisode = episodes[0]
    if (firstEpisode) {
      const firstEpisodeCourses =
        _.get(howToPlays, [firstEpisode.meta_id, 'courses']) || []
      courseId = firstEpisodeCourses.length
        ? firstEpisodeCourses[0].course_id
        : null
    }

    return (
      <React.Fragment>
        <Header />
        <div className="common-wrapper">
          <HeaderNewsComponent />
          <SpSubNavigation spOff />
          {!isSp && this.renderNotice()}

          <div className="c-headMeta">
            <div className="c-headMeta-metaBox">
              <div className="c-headMeta-metaBox-art">
                <div className="c-headMeta-metaBox-art-inBox">
                  <div className="c-headMeta-metaBox-art-inBox-artwork">
                    <img
                      src={thumbnailUrl}
                      className="c-headMeta-metaBox-art-inBox-artwork-img"
                    />
                  </div>
                </div>

                {isSp && this.renderNotice()}

                {/* for PC */}
                <AddButtonBlock
                  favoriteType={FAVORITE_TYPE.META}
                  favoriteId={meta.meta_id}
                  title={metaName}
                />
              </div>

              <div className="c-headMeta-metaBox-info">
                <h2 className="c-headMeta-metaBox-info-title">{metaName}</h2>

                <Caption
                  caption={meta.values && meta.values.evis_SeasonLongSynopsis}
                  className="c-headMeta-metaBox-info-caption"
                />

                {/* for SP */}
                <AddButtonBlock
                  favoriteType={FAVORITE_TYPE.META}
                  favoriteId={meta.meta_id}
                  title={metaName}
                />
              </div>
            </div>
          </div>

          <div className="c-listMeta">
            <HtmlSnippet snippetId={noticeSnippet} />
            <div className="c-listMeta-inBox">
              <div className="c-listMeta-inBox-main">
                {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
                {Object.keys(meta).length > 0 && (
                  <div className="c-switchableListGrid--shinchan">
                    <StyledH3>単話</StyledH3>
                    {meta.name === 'クレヨンしんちゃん' && (
                      <StyledSearchBoxYearWrapper
                        selectedYear={selectedYear}
                        setSelectedYear={this.updateSelectedYear}
                      />
                    )}
                    <StyledFilterSort
                      childEpisodeIds={_.get(
                        meta,
                        ['values', 'child_episode_ids'],
                        []
                      )}
                      seasonIds={[Number(seasonId)]}
                      howToPlays={null}
                      placeholder="検索　※上限50文字です"
                      showNew
                      showChecked
                      onlySubTitle
                      listType={'grid'}
                      pagerOptions={pagerOptions}
                      isShinchan={meta.name === 'クレヨンしんちゃん'}
                      showBottomPager
                      selectedYear={selectedYear}
                      loaded={loaded}
                    />
                  </div>
                )}
              </div>
              <div className="c-listMeta-inBox-sub">
                <div className="common-box-ad-rectangle">
                  <DFPBanner position="foot" meta_id="" />
                </div>
                {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
                <SideTab otherSeasons={otherSeasons} />
                {/* <ProgramTopLink seriesId={seriesId} seasonId={seasonId} isFree={isFree} /> */}
                <HtmlSnippet
                  snippetId={this.config.extras.common_banner_snippet_key}
                />
                <SideRecommend
                  typeName={this.config.recommend.type_name.view}
                  spotName={this.config.recommend.spot_name.program}
                />
              </div>
            </div>
          </div>

          <Footer className="mt0" courseId={courseId} />
        </div>
      </React.Fragment>
    )
  }
}

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 600;

  @media ${mediaSp} {
    padding: 0 15px;
  }
`

const StyledSearchBoxYearWrapper = styled(SearchBoxYearWrapper)`
  margin-top: 20px;
`

const StyledFilterSort = styled(FilterSort)`
  margin-top: 20px;
`
