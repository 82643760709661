import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'
import { mediaQuery } from '../../../../style'

const Nav = ({ fvHeight, sectionsRef }) => {
  const isIOS = /iP(hone|(o|a)d)/.test(navigator.userAgent)
  const [isFixed, setIsFixed] = useState(false)
  const [isNavOpen, setIsNavOpen] = useState(false)
  const navItems = [
    {
      href: '#link1',
      text: 'あたらしいおはなし'
    },
    {
      href: '#link2',
      text: '<ruby>今月<rt>こんげつ</rt></ruby>のテーマ'
    },
    {
      href: '#link3',
      text: 'カテゴリでみる'
    },
    {
      href: '#link4',
      text: '<ruby>番組<rt>ばんぐみ</rt></ruby>・SNS'
    }
  ]
  const spNavImg = isNavOpen ? 'nav_spcloase' : 'nav_spmenu'

  const onScroll = useCallback(() => {
    const currentScroll = document.scrollingElement.scrollTop
    const shouldBeFixed = currentScroll > fvHeight

    if (isFixed !== shouldBeFixed) {
      setIsFixed(shouldBeFixed)
    }
  }, [fvHeight, isFixed])

  const handleOnClick = (event, sectionKey) => {
    event.preventDefault()

    setIsNavOpen((prev) => !prev)

    const sectionRef = sectionsRef[sectionKey]
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const handleScroll = () => requestAnimationFrame(onScroll)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [onScroll])

  return (
    <>
      <StyledBg isNavOpen={isNavOpen} />
      <StyledButton onClick={handleOnClick}>
        <img
          src={`/images/exdio/renewal/doraemon/${spNavImg}.png`}
          alt="MENU"
        />
      </StyledButton>
      <StyledNav isNavOpen={isNavOpen} isFixed={isFixed}>
        <StyledUl>
          {navItems.map(({ href = '', text = '' }, index) => {
            return (
              <StyledLi key={href}>
                <StyledLink
                  href={href}
                  dangerouslySetInnerHTML={{ __html: text }}
                  onClick={(e) => handleOnClick(e, `section${index + 1}`)}
                  isIOS={isIOS}
                />
              </StyledLi>
            )
          })}
        </StyledUl>
      </StyledNav>
    </>
  )
}

export default Nav

Nav.propTypes = {
  fvHeight: PropTypes.number
}

const fadeInBg = keyframes`
from {
  transform: scale(1.5);
  opacity: 0;
  visibility: visible;
}
to {
  transform: scale(1);
  opacity: 1;
}
`

const fadeOutBg = keyframes`
from {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
to {
  transform: scale(1.5);
  opacity: 0;
  visibility: hidden;
}
`

const StyledBg = styled.div`
  background: rgba(0, 0, 0, 0.25)
    url(/images/exdio/renewal/doraemon/nav_spbg.png) no-repeat center center /
    cover;
  position: fixed;
  inset: 0;
  z-index: 28;
  visibility: ${({ isNavOpen }) => (isNavOpen ? null : 'hidden')};
  transition: background-color 0.4s;
  animation: ${({ isNavOpen }) => (isNavOpen ? fadeInBg : fadeOutBg)} 0.4s
    forwards;

  ${mediaQuery('sm', 'min')} {
    display: none;
  }
`

const fadeIn = keyframes`
from {
  opacity: 0;
  visibility: visible;
}
to {
  opacity: 1;
}
`

const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
  visibility: hidden;
}
`

const StyledNav = styled.nav`
  ${mediaQuery('sm', 'min')} {
    background: #e60012;

    ${({ isFixed }) =>
      isFixed &&
      css`
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
      `}
  }

  ${mediaQuery()} {
    width: 100%;
    height: 100vh;
    position: fixed;
    inset: 0;
    z-index: 29;
    animation: ${({ isNavOpen }) => (isNavOpen ? fadeIn : fadeOut)} 0.4s
      forwards;
  }
`

const StyledUl = styled.ul`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;

  ${mediaQuery()} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['isIOS'].includes(prop)
})`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 55px;
  padding: 13px 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
  transition: background 0.2s;

  &:link,
  &:visited {
    color: #fff;
  }

  ruby {
    position: relative;
    top: -6px;
  }

  ${mediaQuery('sm', 'min')} {
    @media (hover: hover) {
      &&&:hover {
        background-color: #c30110;
        background-position-y: 5px;
      }
    }
  }

  ${mediaQuery()} {
    &,
    &:link,
    &:visited {
      font-size: 2.4rem;
      color: #e7141a;
    }
  }

  ${mediaQuery('mm')} {
    ruby {
      top: ${({ isIOS }) => (isIOS ? '-11px' : '-6px')};
      rt {
        transform: ${({ isIOS }) =>
          isIOS ? 'translateY(3px)' : 'translateY(-1px)'};
      }
    }
  }
`

const StyledLi = styled.li`
  width: calc(100% / 4);
  transition: background-image 0.2s;

  ${mediaQuery()} {
    width: fit-content;

    &&& ${StyledLink} {
      background: transparent;
    }
  }

  &:nth-of-type(1) {
    ${StyledLink} {
      ${mediaQuery('sm', 'min')} {
        background: url(/images/exdio/renewal/doraemon/nav_icon1.png) no-repeat -1px
          55px / 45px;
      }
    }
  }

  &:nth-of-type(2) {
    ${StyledLink} {
      ${mediaQuery('sm', 'min')} {
        background: url(/images/exdio/renewal/doraemon/nav_icon2.png) no-repeat
          200px 55px / 51px;
      }
    }
  }

  &:nth-of-type(3) {
    ${StyledLink} {
      ${mediaQuery('sm', 'min')} {
        background: url(/images/exdio/renewal/doraemon/nav_icon3.png) no-repeat
          center 55px / 90%;
      }
    }
  }

  &:nth-of-type(4) {
    ${StyledLink} {
      ${mediaQuery('sm', 'min')} {
        background: url(/images/exdio/renewal/doraemon/nav_icon4.png) no-repeat
          center 55px / 90%;
      }
    }
  }
`

const StyledButton = styled.button`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 30;
  background: transparent;
  border: 0;
  width: 50px;
  padding: 0;

  ${mediaQuery('sm', 'min')} {
    display: none;
  }
`
