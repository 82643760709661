import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import styled, { createGlobalStyle } from 'styled-components'
import webApp from '../../exdio/utils/exdioWebAppUtils'
import NavItems from './NavItems/'
import IconMenu from './IconMenu/'
import useIsMounted from '../../hooks/useIsMounted'

/** style */
import { mediaQuery } from '../../exdio/components/style'
import useMediaQuery from '../../hooks/useMediaQuery'

/** ヘッダー > グローバルナビゲーション */
const GlobalNavigation = ({ path = '' }, context) => {
  const isMounted = useIsMounted()
  const isApp = webApp.utils.isApp(context)
  const isSp = useMediaQuery()

  const [showSubMenu, setShowSubMenu] = useState({}) // ドロップダウン object 開:true/閉:false
  const [showSearch, setShowSearch] = useState(false) // 検索バー 開:true/閉:false
  const [showMemberMenu, setShowMemberMenu] = useState(false) // ハンバーガーメニュ 開:true/閉:false

  const isSpSearch = useRef(false) // SP検索画面か
  const menuRef = useRef(null)
  const navRef = useRef(null)
  const oldScrollTop = useRef(0)

  /** useEffect内でstateの値を確認できないためsubMenuの値を代入するためのuseRef */
  const monitorSubMenu = useRef({})

  /** 現在表示中のページが/searchかつSPかどうかの判定 */
  const setIsSpSearch = useCallback(
    /**
     * 現在表示中のページが/searchかどうかの判定
     * @param {boolean} _isSpSearch SP:true/PC:false
     */
    (_isSpSearch) => {
      if (typeof _isSpSearch === 'boolean') isSpSearch.current = _isSpSearch
    },
    []
  )

  /**
   * SPでメニュー表示時はバックグラウンドをスクロールさせない
   * @param {boolean} isModalOpen
   *
   * // HACK
   * アプリでメニューを開いたとき、画面最上部のスクロールでリロードされてしまう
   * 回避のためメニューを開いたとき少し下にスクロールする
   */
  const toggleBodyClass = (isModalOpen) => {
    if (isSp) {
      if (isModalOpen) {
        const scrollTop = window.scrollY
        oldScrollTop.current = scrollTop
        document.body.classList.add('modal-open')
        window.scrollTo(0, 100)
      } else {
        document.body.classList.remove('modal-open')
        window.scrollTo(0, oldScrollTop.current)
      }
    }
  }

  /**
   * showSubMenuがすべて閉じているか確認
   * @return {object} monitorSubMenu.currentの値がすべてfalseならtrue
   */
  const checkMonitorSubMenu = () => {
    const isAllFalse = (value) => value === false
    return Object.values(monitorSubMenu.current).every(isAllFalse)
  }

  /**
   * showSubMenu開閉処理の切り分け
   * @param {?string} stateName
   */
  const toggleSubMenu = (stateName = null) => {
    if (stateName === null && checkMonitorSubMenu()) return
    setShowSubMenu((oldShow) => {
      const show = { ...oldShow }
      if (stateName !== null) show[stateName] = !show[stateName]
      let keys = Object.keys(show)
      if (stateName !== null) keys = keys.filter((k) => k !== stateName)
      keys.forEach((k) => {
        show[k] = false
      })
      monitorSubMenu.current = show
      return show
    })
    toggleBodyClass(!checkMonitorSubMenu())
  }

  /** showSubMenu/showSearch/showMemberMenu 開閉処理 */
  const toggleNavMenu = useCallback(
    /**
     * @param {?string} stateName
     */
    (stateName = null) => {
      switch (true) {
        case /^subMenu/.test(stateName):
          toggleSubMenu(stateName)
          setShowSearch(false)
          setShowMemberMenu(false)
          break

        case /^search$/.test(stateName):
          toggleSubMenu()
          setShowMemberMenu(false)
          setShowSearch((oldValue) => {
            toggleBodyClass(false)
            return !oldValue
          })
          break

        case /^memberMenu$/.test(stateName):
          toggleSubMenu()
          setShowSearch(false)
          setShowMemberMenu((oldValue) => {
            toggleBodyClass(!oldValue)
            return !oldValue
          })
          break

        case stateName === null:
          toggleSubMenu()
          setShowSearch(false)
          setShowMemberMenu(false)
          break

        default:
          break
      }
    },
    []
  )

  /** ナビ外をクリックした場合に各ドロップダウン要素を閉じる */
  const onClickWindow = (e) => {
    // useRefと画面遷移とタイミングの都合によりNodeを文字列に変換して判定
    const stringNavRef = navRef.current && navRef.current.outerHTML
    const stringMenuRef = menuRef.current && menuRef.current.outerHTML

    if (
      stringNavRef &&
      stringMenuRef &&
      navRef &&
      !stringNavRef.includes(e.target.outerHTML) &&
      menuRef &&
      !stringMenuRef.includes(e.target.outerHTML) &&
      !isSpSearch.current &&
      (showSubMenu || showSearch || showMemberMenu)
    ) {
      toggleNavMenu()
    }
  }

  useEffect(() => document.body.classList.remove('modal-open'), [])
  useEffect(() => {
    window.addEventListener('click', onClickWindow)

    return () => {
      window.removeEventListener('click', onClickWindow)
    }
  }, [showSubMenu, showSearch, showMemberMenu])

  if (!isMounted) return null

  return (
    <>
      {!isApp && (
        <StyledDiv1 ref={navRef}>
          <NavItems toggleNavMenu={toggleNavMenu} show={showSubMenu} />
        </StyledDiv1>
      )}
      <StyledDiv2 ref={menuRef} isApp={isApp}>
        <IconMenu
          toggleNavMenu={toggleNavMenu}
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          showMemberMenu={showMemberMenu}
          setIsSpSearch={setIsSpSearch}
          path={path}
        />
      </StyledDiv2>
      <GlobalStyle />
    </>
  )
}

export default GlobalNavigation

GlobalNavigation.propTypes = {
  /** 現在のURL */
  path: PropTypes.string
}

GlobalNavigation.contextTypes = {
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  })
}

const GlobalStyle = createGlobalStyle`
  .appli {
    .c-gMenu-logins-in {
      justify-content: center;
    }
    .c-indexCards-header {
      padding: 0;

      &-tabs {
        &-tab {
          width: 30%;

          &:nth-child(2) {
            width: 40%;
          }
        }
      }

    }
  }
`

const StyledDiv1 = styled.div`
  align-self: center;

  ${mediaQuery(1137)} {
    display: none;
  }
`

const StyledDiv2 = styled.div`
  align-self: center;
  justify-self: end;

  ${mediaQuery()} {
    max-width: 200px;
    width: ${({ isApp }) => (isApp ? '200px' : 'calc(100vw - 203px)')};
  }
`
