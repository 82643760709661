import React from 'react'
import MenuButton from '../MenuButton'
import { StyledUl } from './style'

/**
 * ## ヒラメキのタネ 番組ページ・単話ページ Menu
 *
 * テレビ朝日のヒラメキのタネ公式サイト [ https://www.tv-asahi.co.jp/hiramekinotane/ ]へのリンク（MenuButton）を含むリストとリストアイテムを配置するコンポーネント。
 *
 * 4つのボタンは横並びになる仕様。
 *
 * `props.isSmp`の場合、2×2グリッドの配置になる。
 *
 * 番組ページ（ProgramHiramekinotane）・単話ページ（EpisodeHiramekinotane）で使用される想定のコンポーネント。
 *
 * menuItemsでは、`text`と`href`のpropsを子コンポーネントMenuButtonへ渡している。
 */

const Menu = ({ isSmp = false, className }) => {
  const menuItems = [
    {
      href: 'https://www.tv-asahi.co.jp/hiramekinotane/#section-next',
      text: '次回のヒラメキ'
    },
    {
      href: 'https://www.tv-asahi.co.jp/hiramekinotane/backnumber/',
      text: 'バックナンバー'
    },
    {
      href: 'https://www.tv-asahi.co.jp/hiramekinotane/#section-about',
      text: '番組概要'
    },
    {
      href: 'https://www.tv-asahi.co.jp/hiramekinotane/',
      text: '公式サイト'
    }
  ]

  return (
    <StyledUl className={className} isSmp={isSmp}>
      {menuItems.map((item) => {
        return (
          <li key={item.text}>
            <MenuButton isSmp={isSmp} href={item.href}>
              {item.text}
            </MenuButton>
          </li>
        )
      })}
    </StyledUl>
  )
}

export default Menu
