import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { StyledUl, StyledLi, StyledLabel, StyledCheckbox } from './style'

/** タグ一覧 */
const TagList = (
  { tags = [], selected = [], changeTags = () => {}, ...props },
  ref
) => {
  return (
    <StyledUl ref={ref} {...props}>
      {tags.map((tag, i) => (
        <StyledLi key={tag}>
          <StyledCheckbox
            type="checkbox"
            name="selectedTag"
            value={tag}
            id={`fCheckBox${i}`}
            checked={selected.indexOf(tag) > -1}
            onChange={(e) => typeof changeTags === 'function' && changeTags(e)}
          />
          <StyledLabel htmlFor={`fCheckBox${i}`} role="button" tabIndex="0">
            {tag}
          </StyledLabel>
        </StyledLi>
      ))}
    </StyledUl>
  )
}

export default forwardRef(TagList)

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  changeTags: PropTypes.func
}
