import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import webApp from '../../../utils/exdioWebAppUtils'
import SelectBox from '../../../../common/components/renewal/SelectBox'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import routes from '../../../../common/routes'
import History from './Coin/History'

/** コイン通帳 */
export default class Coin extends Component {
  static propTypes = {}

  static defaultProps = {}

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    const tenDaysAfter = webApp.utils.now(this.context)
    tenDaysAfter.setDate(
      tenDaysAfter.getDate() + this.config.my_page.coin_expiration_alert
    )
    this.tenDaysAfter = tenDaysAfter

    const currentYear = moment().format('YYYY')
    const selectYearList = []
    for (let i = 0; i < 10; i++) {
      const w_year = String(parseInt(currentYear) - i)
      selectYearList.push({
        label: `${w_year}年`,
        value: w_year
      })
    }

    this.state = {
      availables: null,
      histories: null,
      isExpanded: false,
      selectYearList,
      currentYear
    }

    this.onChangeYear = this.onChangeYear.bind(this)
    this.onClickMore = this.onClickMore.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.getVodAvailable().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
    this.getVodHistory().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** 所持メダル情報取得 */
  getVodAvailable() {
    const path = ['infra', 'vodAvailable']
    return this.model.fetch([path]).then((result) => {
      const availables = _.get(result, ['json', ...path]) || {}
      if (this._isMounted) {
        return this.setState({ availables })
      }
    })
  }

  /** メダル利用履歴取得 */
  getVodHistory() {
    const path = ['infra', 'vodHistory', this.state.currentYear]
    return this.model.fetch([path]).then((result) => {
      const histories = _.get(result, ['json', ...path]) || {}
      if (this._isMounted) {
        return this.setState({ histories })
      }
    })
  }

  onChangeYear(year) {
    if (this._isMounted) {
      this.setState({ currentYear: year }, () => this.getVodHistory())
    }
  }

  onClickMore(e) {
    e.preventDefault()
    if (this._isMounted) {
      this.setState({ isExpanded: !this.state.isExpanded })
    }
  }

  renderAvailables() {
    const { availables } = this.state
    if (!availables) return <div className="c-coinCheck-hist-cont-entry" />
    if (!availables.length) {
      return (
        <div className="c-coinCheck-hist-cont-entry no-history">
          メダル購入履歴はありません。
        </div>
      )
    }

    return availables.map((available) => {
      const className =
        new Date(available.limit_date) < this.tenDaysAfter ? 'attention' : ''
      const purchaseDate =
        available.purchase_date &&
        moment(available.purchase_date).format('YYYY/MM/DD')
      const limitDate =
        available.limit_date &&
        moment(available.limit_date).format('YYYY/MM/DD')
      return (
        <div key={available.idx} className="c-coinCheck-hist-cont-entry">
          <div className="c-coinCheck-hist-cont-entry-date">{purchaseDate}</div>
          <div
            // chromaticで画像の変更を検知しないための設定
            data-chromatic="ignore"
            className={`c-coinCheck-hist-cont-entry-expires ${className}`}
          >
            {limitDate}
          </div>
          <div className={`c-coinCheck-hist-cont-entry-coins ${className}`}>
            {available.point}
          </div>
        </div>
      )
    })
  }

  /** 失効間近のコイン有無 */
  existsExpiring() {
    const { availables } = this.state
    if (!availables) return false

    const existsExpiring = availables.find((available) => {
      const limitDate = new Date(available.limit_date)
      return limitDate < this.tenDaysAfter
    })
    return !!existsExpiring
  }

  goBack(e) {
    e.preventDefault()
    this.context.history.goBack()
  }

  render() {
    const { availablePoint } = this.context.models.userInfo.data
    const { availables, histories, isExpanded, selectYearList } = this.state

    const existsExpiring = this.existsExpiring()
    const selectStyle =
      histories && histories.length ? {} : { background: 'unset' }

    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <div className="c-mypageCoins">
            <header className="c-mypageCoins-header">
              {this.context.history && this.context.history.length > 1 ? (
                <a href="#" className="c-mypage-btnBack" onClick={this.goBack}>
                  戻る
                </a>
              ) : (
                <Link route={routes.app_home} className="c-mypage-btnBack">
                  戻る
                </Link>
              )}
              <h3 className="c-mypageCoins-header-title">通帳</h3>
            </header>
            <div className="c-mypageCoins-cont">
              <div className="c-coinCheck">
                <div className="c-coinCheck-amount">
                  <div className="c-coinCheck-amount-title">メダル残高</div>
                  <div className="c-coinCheck-amount-num">
                    {_.get(availablePoint, ['available_point'], 0)}
                  </div>
                </div>

                {existsExpiring && (
                  <div className="c-coinCheck-attention">
                    <div className="c-coinCheck-attention-inBox">
                      ※失効まで{this.config.my_page.coin_expiration_alert}
                      日以内のメダルがあります
                    </div>
                  </div>
                )}

                <div className="c-coinCheck-hist">
                  <div className="c-coinCheck-hist-head">
                    <div className="c-coinCheck-hist-head-date">購入日</div>
                    <div className="c-coinCheck-hist-head-expires">
                      有効期限
                    </div>
                    <div className="c-coinCheck-hist-head-coins">メダル数</div>
                  </div>

                  <div
                    className={`c-coinCheck-hist-cont ${
                      isExpanded ? 'open' : ''
                    }`}
                  >
                    {this.renderAvailables()}
                  </div>

                  {availables && availables.length > 3 && (
                    <div className="c-more">
                      <a
                        href="#"
                        className="c-more-btn"
                        onClick={this.onClickMore}
                      >
                        <span
                          className={`c-more-btn-txt ${isExpanded && 'open'}`}
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="c-coinHist">
                <header className="c-coinHist-head">
                  <div className="c-coinHist-head-titleMenu">
                    <h3 className="c-coinHist-head-titleMenu-title">
                      ご利用履歴
                    </h3>
                  </div>
                  <div className="c-coinHist-head-sort" style={selectStyle}>
                    <SelectBox
                      types={selectYearList}
                      onSelect={this.onChangeYear}
                      sm
                    />
                  </div>
                </header>
                <div className="c-coinHist-cont"><History histories={histories} /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
