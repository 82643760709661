import React from 'react'
import PropTypes from 'prop-types'

/** スライダーのボタンコンポーネント */
const SliderArrow = ({
  className = '',
  style = {},
  onClick = () => {},
  type = ''
}) => (
  <button
    tabIndex={0}
    className={className}
    style={{ ...style, display: 'block' }}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        onClick()
      }
    }}
  >
    <div className={`slide-${type}`} />
  </button>
)

export default SliderArrow

SliderArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  type: PropTypes.string
}
