import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import routes from '../../../../../common/routes'
import { MYPAGE_CONTENTS } from '../../../../../../constants/app'
import webApp from '../../../../utils/exdioWebAppUtils'
import { Link } from '../../../../../../sketch-platform/ui/routing'

const ButtonLogin = ({ ...props }, context) => {
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  /*
   * ログインしている場合は「マイページ」へのリンクを設置
   * ログインしていない場合は「ログイン」ページへのリンクを設置
   */
  if (isLoggedIn) {
    return (
      <StyledLink1
        route={routes.mypage}
        state={{
          content: MYPAGE_CONTENTS.TAB.PURCHASED
        }}
        {...props}
      >
        マイページ
      </StyledLink1>
    )
  }

  return (
    <StyledLink2
      route={routes.login}
      query={{ redirect: context.routeHandler.path } || null}
      {...props}
    >
      ログイン
    </StyledLink2>
  )
}

export default ButtonLogin

ButtonLogin.contextTypes = {
  routeHandler: PropTypes.shape({
    path: PropTypes.string
  }),
  models: PropTypes.shape({
    authContext: PropTypes.shape({
      data: PropTypes.object
    })
  })
}

const StyledLink = styled(Link)`
  padding: 14px 24px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  transition: opacity 0.4s;
  cursor: pointer;

  &:link,
  &:visited {
    color: #fff;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`

const StyledLink1 = styled(StyledLink)`
  background-color: #0baffd;
`

const StyledLink2 = styled(StyledLink)`
  background-color: #4ad28a;
`
