import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../../utils/exdioWebAppUtils'
import routes from '../../../../../../common/routes'
import { MYPAGE_CONTENTS } from '../../../../../../../constants/app'

/* style */
import {
  StyledWrapper,
  StyledAlert,
  StyledLink,
  StyledAlertButton,
  StyledButton,
  StyledImg
} from './style'

/** マイリストに追加するボタン */
const MyListButton = (
  { favoriteType = '', favoriteId = null, ...props },
  context
) => {
  const model = context.falcorModel.batch(100)
  const isLoggedIn = webApp.utils.isLoggedIn(context)

  const [isMyListEpisode, setIsMyListEpisode] = useState(false)
  const [isAlertShow, setIsAlertShow] = useState(false)
  const [isAddable, setIsAddable] = useState(false)
  const messageFadeTimeoutId = useRef(null)

  useEffect(() => {
    ;(async () => {
      const favorites = await getMyListEpisodes()
        // 認証エラー時はマイリスト追加処理走らせない(isAddable:falseのまま)
        .catch((e) => webApp.utils.handleFalcorError(e, context))

      const myListEpisodes = (favorites || []).find((favorite) => {
        const id =
          favorite[favoriteType].id ||
          favorite[favoriteType][`${favoriteType}_id`]
        return favorite.model_type === favoriteType && id === favoriteId
      })
      setIsAddable(true)
      setIsMyListEpisode(myListEpisodes !== undefined)
    })()
  }, [favoriteType, favoriteId, isMyListEpisode])

  /** マイリストのエピソードを取得 */
  const getMyListEpisodes = () => {
    if (!isLoggedIn) return Promise.reject()
    if (!favoriteType || !favoriteId) return Promise.reject()

    const path = ['favorites', favoriteType, favoriteId]
    return model.fetch([path]).then((result) => {
      const favorites = _.get(result, ['json', ...path]) || []
      return favorites
    })
  }

  /** 「マイリストに追加」メッセージ押下時、マイリストページに遷移 */
  const onClickAlert = () => {
    const isApp = webApp.utils.isApp(context)
    const route = isApp ? routes.app_mypage : routes.mypage
    const content = isApp
      ? MYPAGE_CONTENTS.APPTAB.MYLIST
      : MYPAGE_CONTENTS.TAB.MYLIST

    context.history.push(route.makePath(), { content })
  }

  /**
   * アラート表示/非表示
   * @param {boolean} open - 開く/閉じる
   */
  const toggleAlert = (open) => {
    setIsAlertShow(open)

    if (open) {
      setIsAddable(true)
      clearTimeout(messageFadeTimeoutId.current)
      messageFadeTimeoutId.current = setTimeout(() => {
        toggleAlert(false)
      }, 3000)
    }
  }

  /**
   * マイリストに追加
   * @param {string} addOrDelete [add|delete]
   */
  const editMyList = (addOrDelete) => {
    if (!addOrDelete) return
    const path = ['favorite', addOrDelete]
    const args = [{ modelType: favoriteType, modelId: favoriteId }]
    return model.call(path, args)
  }

  /** マイリスト追加/削除 */
  const addMyList = () => {
    /** 未ログイン時はログインページに遷移 */
    if (!isLoggedIn) {
      context.history.push(
        routes.login.makePath({}, { redirect: context.routeHandler.path })
      )
      return
    }

    if (!isAddable || !favoriteType || !favoriteId) return
    editMyList(isMyListEpisode ? 'delete' : 'add')
      .then(() => {
        setIsMyListEpisode(!isMyListEpisode)
        toggleAlert(true)
        setIsAddable(false)
      })
      .catch((e) => webApp.utils.handleFalcorError(e, context))
  }

  return (
    <StyledWrapper {...props}>
      <StyledAlert isShow={isAlertShow}>
        <StyledLink onClick={onClickAlert}>
          {isMyListEpisode
            ? 'マイリストに追加しました'
            : 'マイリストから削除しました'}
        </StyledLink>
        <StyledAlertButton type="button" onClick={() => toggleAlert(false)} />
      </StyledAlert>

      <StyledButton isMyListEpisode={isMyListEpisode} onClick={addMyList}>
        <StyledImg
          src="/images/exdio/renewal/gariben_daigaku/episode/icon/mylist.svg"
          width="38"
          height="38"
          alt=""
        />
        マイリスト
      </StyledButton>
    </StyledWrapper>
  )
}

export default MyListButton

MyListButton.propTypes = {
  favoriteType: PropTypes.string,
  favoriteId: PropTypes.number
}

MyListButton.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  updateUserInfoSubscribe: PropTypes.func
}
