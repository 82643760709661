import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../../utils/exdioWebAppUtils'

/* style */
import { StyledSpan } from './style'

/** 港時間 スライダー内 タイトル コンポーネント */
const SliderTitle = ({ meta, onClickThumbnail, ...props }) => {
  const [title, subTitle] = webApp.utils.titles(meta)
  const handleClick = (propFunc, e) => {
    e.preventDefault()
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation()
      propFunc(e)
    }
  }

  return (
    <StyledSpan href="#" onClick={(e) => handleClick(onClickThumbnail, e)}>
      {subTitle}
    </StyledSpan>
  )
}

export default SliderTitle

SliderTitle.propTypes = {
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),

  /** イベントハンドラ タイトルクリック時のページ遷移に使用 */
  onClickThumbnail: PropTypes.func
}

SliderTitle.defaultProps = {
  meta: null,
  onClickThumbnail: null
}
