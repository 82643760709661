import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import webApp from '../../../utils/exdioWebAppUtils';

/**
 * パック詳細画面:視聴期間、視聴期限コンポーネント
 * https://docs.google.com/spreadsheets/d/110fDrQ0sNJ7f1QCiE1Hw-qNMjU-lVBAccAQFFbysvvA/edit?pli=1#gid=0&range=F28
 */
export default function PackViewTerm(props, context) {
  const { right, status } = props;

  let viewTermText = '';
  const viewTerm = _.get(right, ['view_term']);
  if (status.isNotPurchased) {
    // 視聴期間が0日の場合は表示しない
    if (viewTerm) {
      viewTermText = `視聴期間：${viewTerm}日間`;
    }
  } else if (status.isPurchased) {
    // 視聴期間が0日、視聴期限が9999年の場合は表示しない
    if (viewTerm && status.limitDate && !status.limitDate.startsWith('9999/')) {
      viewTermText = webApp.utils.deadLine(context, status.limitDate, false, 'YYYY年MM月DD日 HH:mm', false);
    }
  }

  return <div className="c-headMeta-metaBox-info-viewingPeriod">{viewTermText}</div>;
}

PackViewTerm.propTypes = {
  right: PropTypes.shape({
    viewTerm: PropTypes.number
  }),
  status: PropTypes.shape({
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    limitDate: PropTypes.string
  })
};

PackViewTerm.defaultProps = {
  right: null,
  status: {
    isPurchased: null,
    isNotPurchased: null,
    limitDate: null
  }
};

PackViewTerm.contextTypes = {
  models: PropTypes.object
};
