import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import GenreLinksSp from './renewal/GenreLinksSp'
import Link from '../../../sketch-platform/ui/routing/Link'
import webApp from '../../../apps/exdio/utils/exdioWebAppUtils'
import routes from '../routes'

/** style */
import { mediaQuery } from '../../exdio/components/style'

const EpisodeFooter = (
  { copyrights = '', courseId = null, showLogo = false, className = '' },
  context
) => {
  const config = context.models.config.data
  const guideLinks = config.guide_links
  const linkRowCount = guideLinks.length / 2 + (guideLinks.length % 2)
  const firstLinks = guideLinks.slice(0, linkRowCount)
  const secondLinks = guideLinks.slice(linkRowCount)

  const Jasrac = () => {
    const doraemonId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 2
      : 1
    const shinchanId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 3
      : 2
    const logirlId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 4
      : 3
    const garibenId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 13
      : 5

    switch (courseId) {
      case doraemonId:
        return <p>6688647087Y45038</p>
      case shinchanId:
        return <p>6688647078Y45038</p>
      case logirlId:
        return <p>6688647051Y45038</p>
      case garibenId:
        return <p>6688647068Y45038</p>
      default:
        return (
          <p>
            6688647019Y45038
            <br />
            6688647032Y45038
            <br />
            6688647069Y45038
          </p>
        )
    }
  }

  return (
    <footer className={`common-footer ${className}`}>
      <StyledGenreLinksSp />

      <div className="inner">
        <div className="common-menu-footer">
          <div className="guides">
            <ul>
              {firstLinks.map((link) => {
                return (
                  <li key={link.title}>
                    <Link
                      href={
                        link.url
                          ? link.url.replace(
                              ':host',
                              webApp.utils.dougaMvHost(context)
                            )
                          : null
                      }
                      route={routes[link.route]}
                      params={link.params}
                      target={link.target}
                    >
                      {link.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="terms">
            <ul>
              {secondLinks.map((link) => (
                <li key={link.title}>
                  <Link
                    href={
                      link.url
                        ? link.url.replace(
                            ':host',
                            webApp.utils.dougaMvHost(context)
                          )
                        : null
                    }
                    route={routes[link.route]}
                    params={link.params}
                    target={link.target}
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="footer-notes">
          <div className="notes-content">
            <p>
              配信されている動画は放送内容と一部異なる場合がございます。また、予告なく配信終了する場合がございますがご了承ください。表示されている情報は、収録および放送当時のものです。
              <br />
              消費税率の変更により、テレビ朝日番組内およびホームページ内の価格表示と異なる場合があります。
            </p>
          </div>
          <div className="copyrights">
            <p>{copyrights}</p>
          </div>
          <div className="marks">
            <ul>
              <li className="jasrac">
                <dl>
                  <dt>
                    <img
                      src="/images/exdio/renewal/icon_jasrac.png"
                      alt="JASRAC"
                    />
                  </dt>
                  <dd>
                    <h4>JASRAC許諾番号</h4>
                    <Jasrac />
                  </dd>
                </dl>
              </li>
              <li className="nextone">
                <dl>
                  <dt>
                    <img
                      src="/images/exdio/renewal/icon_nextone.png"
                      alt="NexTone"
                    />
                  </dt>
                  <dd>
                    <h4>NexTone許諾番号</h4>
                    <p>
                      UD000001695 <br />
                      UD000001696
                    </p>
                  </dd>
                </dl>
              </li>
              <li className="lMark">
                <dl>
                  <dt>
                    <img
                      src="/images/exdio/renewal/icon_lmark.png"
                      alt="エルマーク"
                    />
                  </dt>
                  <dd>
                    <p className="lMark">
                      エルマークは、レコード会社・映像制作会社が提供するコンテンツを示す登録商標です
                    </p>
                    <p className="lMarkNum">IRAJ60017003</p>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>
        {showLogo && (
          <StyledDiv3>
            <StyledLink route={routes.home}>
              <img
                src="/images/exdio/renewal/logo_tv_asahi_douga.png"
                width="207"
                height="41"
                alt="テレ朝動画"
              />
            </StyledLink>
          </StyledDiv3>
        )}
      </div>
      <div className="footer-copyrights">
        <small>{config.copyright}</small>
      </div>
      <GlobalStyle />
    </footer>
  )
}

export default EpisodeFooter

EpisodeFooter.propTypes = {
  copyrights: PropTypes.string,
  courseId: PropTypes.number,
  showLogo: PropTypes.bool,
  className: PropTypes.string
}

EpisodeFooter.contextTypes = {
  models: PropTypes.shape({
    config: PropTypes.shape({
      data: PropTypes.shape({
        guide_links: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            route: PropTypes.string,
            spOnly: PropTypes.bool,
            className: PropTypes.string
          })
        ),
        copyright: PropTypes.string
      })
    })
  })
}

const GlobalStyle = createGlobalStyle`
  .common-footer .inner {
    flex-wrap: wrap;
  }
`

const StyledGenreLinksSp = styled(GenreLinksSp)`
  margin-top: 2rem;
  ${mediaQuery('mm')} {
    margin-top: 1rem;
  }
`

const StyledDiv3 = styled.div`
  margin-top: 24px;
  width: 100%;
  text-align: center;

  ${mediaQuery()} {
    margin-top: 0;
    padding-bottom: 24px;
  }
`

const StyledLink = styled(Link)`
  display: inline-block;

  ${mediaQuery()} {
    img {
      width: 207px;
    }
  }
`
