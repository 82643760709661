import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/* component */
import Radio from '../Radio'

/* style */
import { StyledDiv, StyledUl, StyledLi } from './style'
import { StyledLabel } from '../Radio/style'

/**
 * 追加フィルタ機能
 */
const ExFilterElm = ({
  filteredBy = '',
  updateFilteredBy = () => {},
  stateSelectedElm = ['', () => {}],
  options = [],
  initialValue = '',
  slug = '',
  ...props
}) => {
  const [selectedElm, setSelectedElm] = stateSelectedElm
  const isOpen = selectedElm === slug
  const [currentOption, setCurrentOption] = useState({})
  const [filteredByDefaultLabel, setFilteredByDefaultLabel] = useState(
    filteredBy ? '' : initialValue
  )

  /** 先頭の文字を大文字に変換 */
  const toUpperFirstCharacter = (str = '') =>
    `${str.charAt(0).toUpperCase()}${str.substring(1).toLowerCase()}`

  useEffect(() => {
    setCurrentOption(
      options.find((option) => option.value === filteredBy) || options[0]
    )
    if (filteredBy && filteredByDefaultLabel !== '')
      setFilteredByDefaultLabel('')
  }, [filteredBy])

  return (
    <StyledDiv isOpen={isOpen} {...props}>
      <StyledUl isOpen={isOpen}>
        <StyledLi>
          <StyledLabel onClick={() => setSelectedElm(isOpen ? '' : slug)}>
            {filteredByDefaultLabel || currentOption.label}
          </StyledLabel>
        </StyledLi>
        {options.map(({ id, value, label }) => {
          return (
            <StyledLi key={id}>
              <Radio
                label={label}
                checked={filteredBy === value}
                radioProps={{
                  name: 'filter',
                  value,
                  id: `fFilter${toUpperFirstCharacter(slug)}${id}`,
                  onChange: () => {
                    updateFilteredBy(value)
                  },
                  onClick: () => setSelectedElm('')
                }}
              />
            </StyledLi>
          )
        })}
      </StyledUl>
    </StyledDiv>
  )
}

export default memo(ExFilterElm)

ExFilterElm.propTypes = {
  filteredBy: PropTypes.string,
  updateFilteredBy: PropTypes.func,
  stateSelectedElm: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  initialValue: PropTypes.string,
  slug: PropTypes.string
}
