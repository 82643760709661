import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import routes from '../../../routes'
import {
  PALETTE_SCHEMA_ID,
  META_SCHEMA_ID,
  ADVERTISING_SCHEMA_ID
} from '../../../../../constants/app'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'

import SlideCards from '../SlideCards'
import getCardRoute from './functions/getCardRoute'
import _getHowToPlayForPalettes from './functions/getHowToPlayForPalettes'

const PaletteForCardList = ({ paletteKey }, context) => {
  const model = context.falcorModel.batch(100)
  const [loaded, setLoaded] = useState(false)
  const [paletteObjects, setPaletteObjects] = useState([])
  const [cardDataList, setCardDataList] = useState([])
  const [advertising, setAdvertising] = useState({ courses: [], products: [] })
  const [howToPlaysForPalettes, setHowToPlaysForPalettes] = useState([])
  const [heading, setHeading] = useState('')
  const [link, setLink] = useState({ route: {}, params: {}, query: {} })

  /**
   * パレット用の情報を取得し、stateにセットする
   * @async
   * @param {Array.<Object>} palettesObject - パレットオブジェクトの配列
   * @returns {Promise<void>}
   */
  const getHowToPlayForPalettes = async (palettesObject) => {
    // 価格情報を取得
    const metaIds = palettesObject
      .filter((meta) => meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE)
      .map((e) => e.meta_id)
    const howToPlaysForPalettes = await _getHowToPlayForPalettes(model, metaIds)
    setHowToPlaysForPalettes(howToPlaysForPalettes)
    setLoaded(true)
  }

  /**
   * 商品とコースの情報を取得する関数。
   * @returns {Promise<{products: Object, courses: Object}>} - 商品とコースのオブジェクトを持つ Promise。
   */
  const getProductsAndCourses = () => {
    const advertisings = paletteObjects
      .filter((obj) => obj.type === 'advertising')
      .map((obj) => obj.advertising)

    const productIds = advertisings
      .filter(
        (ad) =>
          ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT ||
          ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT_LIVE
      )
      .map((h) => h.values.product)
      .filter((v) => v)
    const courseIds = advertisings
      .filter(
        (ad) =>
          ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE ||
          ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE_LIVE
      )
      .map((h) => h.values.course)
      .filter((v) => v)

    const path = [
      ['product', productIds],
      ['course', courseIds]
    ]
    return model.fetch(path).then((result) => {
      const products = _.get(result, ['json', 'product']) || {}
      const courses = _.get(result, ['json', 'course']) || {}
      return { products, courses }
    })
  }

  /**
   * 対応するルートを設定する
   * @param {string} schema_id - パレットのスキーマID
   * @returns {void}
   */
  const getRoute = (schema_id) => {
    if (schema_id === PALETTE_SCHEMA_ID.RECOMMEND) {
      // テレ朝動画のオススメ
      setLink({ route: routes.recommend })
    } else if (schema_id === PALETTE_SCHEMA_ID.CATCHUP) {
      // 見逃し無料
      setLink({ route: routes.catchup })
    }
  }

  /**
   * パレットを取得する関数
   * @function
   * @returns {Promise<Object[]>} パレットオブジェクトの配列を解決するPromise
   */
  const getPalette = () => {
    const path = ['paletteByKey', paletteKey]
    return model.fetch([path]).then(async (result) => {
      const palette = _.get(result, ['json', ...path]) || {}

      setHeading(palette.name)

      getRoute(palette.schema_id)

      getHowToPlayForPalettes(palette.objects)

      return palette.objects
    })
  }

  /**
   * generateCardData - パレットオブジェクトからカードデータを生成する
   *
   * @returns {Promise<void>}
   */
  const generateCardData = async () => {
    /**
     * getCardRoute - カードのリンク先を取得する
     *
     * @param {Object|null} meta - メタデータオブジェクト
     * @param {Object|null} product - 商品オブジェクト
     * @param {Object|null} course - コースオブジェクト
     * @param {Boolean} [autoPlay=true] - 自動再生フラグ
     * @returns {Object} - リンク先情報オブジェクト
     */

    // カードデータのリストを生成
    const cardDataList = (paletteObjects || []).map((object) => {
      const productId =
        _.get(object, ['advertising', 'values', 'product']) || null
      const courseId =
        _.get(object, ['advertising', 'values', 'course']) || null
      const product = productId && _.get(advertising.products, productId)
      const course = courseId && _.get(advertising.courses, courseId)
      const howToPlay = _.get(howToPlaysForPalettes, object.id) || null

      const paletteAd = _.get(object, 'advertising')
      if (paletteAd) {
        if (paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT) {
          const thumbnail =
            _.get(paletteAd, ['creatives', 0, 'attachment', 'file_url']) ||
            this.context.models.config.data.default_thumbnail

          return {
            key: `${object.type}-${object.id}`,
            title: paletteAd.name,
            thumbnail: thumbnail,
            href: _.get(paletteAd, ['url'])
          }
        } else {
          const route = _.get(getCardRoute(context, meta, product, course), [
            'route'
          ])
          const params = _.get(getCardRoute(context, meta, product, course), [
            'params'
          ])
          const query = _.get(getCardRoute(context, meta, product, course), [
            'query'
          ])
          return {
            key: `${object.type}-${object.id}`,
            product: product,
            course: course,
            howToPlay: howToPlay,
            showDelivery: true,
            showInCourse: true,
            showCoin: true,
            breakSubTitle: true,
            showCaption: true,
            textOverFlow: true,
            titlesMinHeight: false,
            route: route,
            params: params,
            query: query
          }
        }
      }

      const meta = object.meta || null
      const showRemaining =
        meta &&
        (META_SCHEMA_ID.EPISODE === object.meta.meta_schema_id ||
          META_SCHEMA_ID.LIVE === object.meta.meta_schema_id)

      const route = _.get(getCardRoute(context, meta, product, course), [
        'route'
      ])
      const params = _.get(getCardRoute(context, meta, product, course), [
        'params'
      ])
      const query = _.get(getCardRoute(context, meta, product, course), [
        'query'
      ])

      return {
        key: `${object.type}-${object.id}`,
        meta: meta,
        product: product,
        course: course,
        howToPlay: howToPlay,
        showRemaining: showRemaining,
        showDelivery: true,
        showDeadLine: true,
        showCoin: true,
        showInCourse: true,
        showNew: webApp.utils.showNew(meta),
        breakSubTitle: true,
        showDeliveryEndPriorToStart: true,
        route: route,
        params: params,
        query: query
      }
    })

    setCardDataList(cardDataList)
  }

  useEffect(async () => {
    const paletteObjects = await getPalette()
    setPaletteObjects(paletteObjects)
  }, [])

  useEffect(async () => {
    if (paletteObjects.length > 0) {
      const advertising = await getProductsAndCourses()
      setAdvertising(advertising)
    }
  }, [paletteObjects])

  useEffect(() => {
    if (paletteObjects.length > 0) {
      generateCardData()
    }
  }, [advertising])

  useEffect(() => {
    if (paletteObjects.length > 0) {
      setLoaded(true)
    }
  }, [cardDataList])

  return (
    <SlideCards
      heading={heading}
      cardDataList={cardDataList}
      link={link}
      loaded={loaded}
    />
  )
}

export default PaletteForCardList

PaletteForCardList.propTypes = {
  paletteKey: PropTypes.string
}

PaletteForCardList.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}
