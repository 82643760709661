import pathToRegexp from 'path-to-regexp';
import URLGenerator from './URLGenerator';

export default {
  create: function(options) {
    const hostname = options.hostname;
    const protocol = options.protocol;
    const exact = options.exact || false;
    const path = options.path;
    const _keys = [];
    const _regex = pathToRegexp(path, _keys);
    const _pathMaker = pathToRegexp.compile(path);

    return {
      _keys,
      _regex,
      _pathMaker,
      match: (route) => {
        const regex = _regex.exec(route);
        if (!regex) return null;
        const results = {};
        const keys = _keys;
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          const pattern = regex[index + 1];
          if (!!pattern && !results[key.name]) {
            results[key.name] = decodeURIComponent(pattern);
          }
        }
        return results;
      },
      makePath:(params, query, hash) => {
        if (hostname) {
          return makeAbsolutePath({
            query,
            hash,
            params,
          });
        }

        let path;
        try {
          path = _pathMaker(params);
        } catch (e) {
          e.message += ` for ${path}`;
          throw e;
        }
        return URLGenerator.createRelative({
          path,
          query,
          hash,
        });
      },

      makeAbsolutePath: (route = {}) => {
        let path;
        try {
          path = _pathMaker(route.params);
        } catch (e) {
          e.message += ` for ${path}`;
          throw e;
        }

        return URLGenerator.create({
          protocol: route.protocol || protocol,
          hostname: route.hostname || hostname,
          path,
          query: route.query,
          hash: route.hash,
        });
      }
    };
  }
};
