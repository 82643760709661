/* style */
import styled from 'styled-components'

export const StyledDiv1 = styled.div`
  margin: 20px auto;
`

export const StyledUl1 = styled.ul`
  margin: 0 -4px;
  overflow: hidden;
`
