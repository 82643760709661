import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import webApp from '../../../../../utils/exdioWebAppUtils'

/* style */
import {
  StyledLi,
  StyledA,
  StyledDiv1,
  StyledImg1,
  StyledP1,
  StyledDiv2,
  StyledP2,
  StyledImg2
} from './style'

/** SailGp sp 動画一覧 要素コンポーネント */
const SpSailItem = ({ meta, onClickThumbnail, ...props }, context) => {
  const config = context.models.config.data

  /** タイトル */
  const title = () => {
    return webApp.utils.titles(meta)
  }

  /** サムネイル画像 */
  const thumbnail = () => {
    let generateThumbnailUrl = null
    let generateThumbnailAlt = null
    generateThumbnailUrl = meta.thumbnail_url
    const [thumbnailTitle, thumbnailSubTitle] = title()
    generateThumbnailAlt = `${thumbnailTitle} ${thumbnailSubTitle}`

    /* 読み込む画像サイズを変更する */
    generateThumbnailUrl = webApp.utils.customSizeImageUrl(
      generateThumbnailUrl,
      'medium'
    )

    return [
      generateThumbnailUrl || config.default_thumbnail,
      generateThumbnailAlt
    ]
  }

  const handleClick = (propFunc, e) => {
    e.preventDefault()
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation()
      propFunc(e)
    }
  }

  const [episodeTitle, subTitle] = title()
  const [thumbnailUrl, thumbnailAlt] = thumbnail()
  const duration = webApp.utils.duration(meta)

  return (
    <StyledLi {...props}>
      <StyledA href="#" onClick={(e) => handleClick(onClickThumbnail, e)}>
        <StyledDiv1>
          <StyledImg1 src={thumbnailUrl} alt={thumbnailAlt} />
          <StyledP1>{duration}</StyledP1>
        </StyledDiv1>
        <StyledDiv2>
          <StyledP2>{subTitle}</StyledP2>
          <StyledImg2
            src="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_free-ep.jpg"
            alt="無料"
            srcSet="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_free-ep@2x.jpg 2x"
          />
        </StyledDiv2>
      </StyledA>
    </StyledLi>
  )
}

export default SpSailItem

SpSailItem.propTypes = {
  // 主情報
  /** メタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  // イベントハンドラ
  // onClickは引数に(event)を渡して呼び出される
  onClickThumbnail: PropTypes.func,
  className: PropTypes.string
}

SpSailItem.defaultProps = {
  meta: null,
  onClickThumbnail: null,
  className: ''
}

SpSailItem.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}
