import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import routes from '../../../../../common/routes'
/* style */
import { StyledDiv1, StyledH1, StyledImg } from './style'

/**
 * ## ヒラメキのタネ Header
 *
 * 番組ロゴとパンくずリストを配置するコンポーネント。
 *
 * `props.isSmp`ではない場合に、パンくずリストを表示する。（PC表示でパンくずリストを表示する。）
 *
 * エピソードのタイトルを親コンポーネントから受け取り、パンくずリストのタイトルへ反映する。
 *
 * ### パンくずリストのタイトル
 * - 番組ページ：'ヒラメキのタネ'
 * - 単話ページ：'ヒラメキのタネ' + '単話（エピソード）のタイトル'
 *
 * h1とパンくずリストの各項目にLinkコンポーネントでリンクが設定されており、Linkコンポーネントへはリンク先URL生成用のパラメータを渡している。
 *
 * Headingコンポーネントは、番組ページ・単話ページ双方に使用させる想定で作られている。
 */
// TODO: コンポーネント名Headerへ修正→影響範囲も同様に修正
const Heading = ({ isSmp = false, params }) => {
  return (
    <StyledDiv1>
      <StyledH1 isSmp={isSmp}>
        <Link route={routes.program} params={params}>
          {/* TODO: LOGO変更 */}
          <StyledImg
            isSmp={isSmp}
            src="/images/exdio/renewal/hiramekinotane/logo.png"
          />
        </Link>
      </StyledH1>
    </StyledDiv1>
  )
}

export default memo(Heading)

Heading.propTypes = {
  /** リンク先URLの生成に使用 */
  params: PropTypes.shape({
    /** シリーズID */
    seriesId: PropTypes.string,
    /** シーズンID */
    seasonId: PropTypes.string
  }),
  /** スマートフォンでの表示か */
  isSmp: PropTypes.bool
}
