/* style */
import styled from 'styled-components'

/* components */
import ConvertSvg from '../../util/ConvertSvg'

export const mediaSp = '(max-width: 1024px)'

export const StyledH2 = styled.h2`
  padding: 8px 24px 8px 16px;
  border-radius: 24px;
  background-color: #ff0000;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledSpan = styled.span`
  display: inline-block;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1;
  font-family: 'M PLUS Rounded 1c', sans-serif;
`

export const StyledConvertSvg = styled(ConvertSvg)`
  margin-right: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
