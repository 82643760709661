/* style */
import styled from 'styled-components'
export const mediaSp = '(max-width: 1024px)'

export const StyledA = styled.a`
  && {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.4s ease-in-out;

    &:hover {
      svg {
        path,
        rect {
          fill: #0cc;
        }
      }
    }

    svg {
      path,
      rect {
        fill: #fff;
        transition: fill 0.2s ease-in-out;
      }
    }
  }
`
