import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import window from 'global'
import Footer from '../../../common/components/Footer'
import NotFound from '../../../generic/components/errors/NotFound'
import DFPBanner from '../../../common/components/DFPBanner'
import {
  ADVERTISING_SCHEMA_ID,
  META_SCHEMA_ID,
  SEARCH_TYPE,
  SORT_TYPE,
  SORT_TYPES
} from '../../../../constants/app'
import TabNavigation from '../../../common/components/renewal/TabNavigation'
import ProgramItem from '../../../common/components/renewal/ProgramItem'
import webApp from '../../utils/exdioWebAppUtils'
import SelectBox from '../../../common/components/renewal/SelectBox'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import HeaderNewsComponent from './HeaderNewsComponent'

const PAGE_TITLE = 'テレ朝動画のオススメ'

/** テレ朝動画のオススメページ */
export default class RecommendListContent extends Component {
  static propTypes = {}
  static defaultProps = {}
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  static getSsrMetaTags(_models, _options, _props, _prefetchResult) {
    return { title: PAGE_TITLE }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    this.state = {
      palette: {},
      objects: [],
      paletteChildren: {
        free: null,
        not_free: null
      },
      metas: [],
      howToPlays: {},
      products: {},
      courses: {},
      searchType: SEARCH_TYPE.ALL.value,
      sortType: SORT_TYPE.NEWER.value,
      isNotFound: false,
      sorted: false
    }

    this.onChangeSearchType = this.onChangeSearchType.bind(this)
    this.onChangeSortType = this.onChangeSortType.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context, PAGE_TITLE)
    // GTMの更新
    const title = this.context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)

    this.getPalette()
      .then(() => this.getHowToPlays())
      .then(() => this.getProductsAndCourses())
      .catch((e) => webApp.utils.handleFalcorError(e, this.context))
  }

  /** パレット情報取得 */
  getPalette() {
    const { searchType, paletteChildren } = this.state

    let path = null
    if (searchType === SEARCH_TYPE.ALL.value) {
      // 「すべて」はキー指定
      path = ['paletteByKey', 'recommend']
    } else if (
      [SEARCH_TYPE.FREE.value, SEARCH_TYPE.NOT_FREE.value].includes(searchType)
    ) {
      // 「無料」/「有料」はID指定
      const paletteId = paletteChildren[searchType]
      if (!paletteId) return Promise.resolve()
      path = ['palette', paletteId]
    } else {
      return Promise.resolve()
    }
    return this.model.fetch([path]).then((result) => {
      const palette = _.get(result, ['json', ...path]) || {}
      const metas = (palette.objects || [])
        .filter((obj) => obj.type === 'meta')
        .map((obj) => obj.meta)
      this.setState({ palette, metas })
      if (searchType === SEARCH_TYPE.ALL.value) {
        const free = _.get(palette, ['values', 'palette_free']) || null
        const not_free = _.get(palette, ['values', 'palette_not_free']) || null
        this.setState({ paletteChildren: { free, not_free } })
      }
    })
  }

  /** 価格情報取得 */
  getHowToPlays() {
    const { metas } = this.state
    if (!metas.length) return Promise.resolve()

    const metaIds = metas
      .filter((meta) => meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE)
      .map((e) => e.meta_id)
    const path = [['meta', 'howToPlay', false, metaIds]]
    return this.model.fetch(path).then((result) => {
      const howToPlays =
        _.get(result, ['json', 'meta', 'howToPlay', false]) || {}
      this.setState({ howToPlays })
    })
  }

  /** 商品・コース情取得報 */
  getProductsAndCourses() {
    const { palette } = this.state

    const advertisings = (palette.objects || [])
      .filter((obj) => obj.type === 'advertising')
      .map((obj) => obj.advertising)

    const productIds = advertisings
      .filter(
        (ad) =>
          ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT ||
          ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT_LIVE
      )
      .map((h) => h.values.product)
      .filter((v) => v)
    const courseIds = advertisings
      .filter(
        (ad) =>
          ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE ||
          ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE_LIVE
      )
      .map((h) => h.values.course)
      .filter((v) => v)

    const path = [
      ['product', productIds],
      ['course', courseIds]
    ]
    return this.model.fetch(path).then((result) => {
      const products = _.get(result, ['json', 'product']) || {}
      const courses = _.get(result, ['json', 'course']) || {}
      this.setState({ products, courses }, () => this.sort())
    })
  }

  /** 検索条件変更時 */
  onChangeSearchType(searchType) {
    this.setState({ searchType, sorted: false }, () => {
      this.getPalette()
        .then(() => this.getHowToPlays())
        .then(() => this.getProductsAndCourses())
        .catch((e) => webApp.utils.handleFalcorError(e, this.context))
    })
  }

  /** 並び替え条件変更時 */
  onChangeSortType(sortType) {
    this.setState({ sortType }, () => this.sort())
  }

  goBack(e) {
    e.preventDefault()
    this.context.history.goBack()
  }

  sort() {
    const { palette, sortType } = this.state
    if (!palette || !Object.keys(palette).length) return

    const objects = Object.assign([], palette.objects)
    let sortFunc
    if (sortType === SORT_TYPE.OLDER.value) {
      sortFunc = (a, b) => {
        const startAtA = this.getStartAt(a)
        const startAtB = this.getStartAt(b)
        if (!startAtA) return 1
        if (!startAtB) return -1
        return startAtA < startAtB ? -1 : 1
      }
    } else if (sortType === SORT_TYPE.ENDING.value) {
      sortFunc = (a, b) => {
        const endAtA = this.getEndAt(a)
        const endAtB = this.getEndAt(b)
        if (!endAtA) return 1
        if (!endAtB) return -1
        return endAtA < endAtB ? -1 : 1
      }
    } else if (sortType === SORT_TYPE.ASC.value) {
      sortFunc = (a, b) =>
        this.getObject(a).name < this.getObject(b).name ? -1 : 1
    } else if (sortType === SORT_TYPE.DESC.value) {
      sortFunc = (a, b) =>
        this.getObject(a).name > this.getObject(b).name ? -1 : 1
    } else {
      sortFunc = (a, b) => (this.getStartAt(a) > this.getStartAt(b) ? -1 : 1)
    }
    objects.sort(sortFunc)

    this.setState({ objects, sorted: true })
  }

  getStartAt(object) {
    const obj = this.getObject(object)
    if (obj.product_id) return obj.publish_start_at
    if (obj.course_id) return obj.contractable_start_at
    return obj.delivery_start_at || obj.publish_start_at
  }

  getEndAt(object) {
    const obj = this.getObject(object)
    if (obj.product_id) return obj.publish_end_at
    if (obj.course_id) return obj.contractable_end_at
    return obj.delivery_end_at || obj.publish_end_at
  }

  getObject(object) {
    const { products, courses } = this.state
    if (object.meta) return object.meta
    const productId =
      _.get(object, ['advertising', 'values', 'product']) || null
    if (productId) return products[productId]
    const courseId = _.get(object, ['advertising', 'values', 'course']) || null
    if (courseId) return courses[courseId]
    const paletteAd = _.get(object, ['advertising'])
    if (paletteAd && paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT) {
      return paletteAd
    }
    return {}
  }

  render() {
    if (this.state.isNotFound) return <NotFound />
    const {
      objects,
      paletteChildren,
      products,
      courses,
      howToPlays,
      sorted
    } = this.state

    const searchTypes = []
    if (paletteChildren.free) searchTypes.push(SEARCH_TYPE.FREE)
    if (paletteChildren.not_free) searchTypes.push(SEARCH_TYPE.NOT_FREE)
    if (searchTypes.length) searchTypes.unshift(SEARCH_TYPE.ALL)

    return (
      <div className="common-wrapper">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />
        <div className="c-listByGenre">
          <div className="c-listByGenre-head">
            <a
              href="#"
              className="c-listByGenre-head-back"
              onClick={this.goBack}
            >
              戻る
            </a>
            <div className="c-listByGenre-head-title">
              <h3 className="c-listByGenre-head-title-head">{PAGE_TITLE}</h3>
            </div>

            <div className="c-listByGenre-head-navBox">
              <div className="c-listByGenre-head-navBox-nav">
                <TabNavigation
                  types={searchTypes}
                  onSelect={this.onChangeSearchType}
                  className={{ hidden: !searchTypes.length }}
                />
                <SelectBox
                  types={SORT_TYPES}
                  onSelect={this.onChangeSortType}
                />
              </div>
            </div>
          </div>
          <div className="c-card-panel">
            <div className="c-card-panel-cont">
              {sorted &&
                (objects || []).map((object) => {
                  const meta = object.meta || null
                  const howToPlay = meta && howToPlays[object.id]
                  const productId =
                    _.get(object, ['advertising', 'values', 'product']) || null
                  const courseId =
                    _.get(object, ['advertising', 'values', 'course']) || null
                  const product = productId && products && products[productId]
                  const course = courseId && courses && courses[courseId]

                  const paletteAd = _.get(object, ['advertising'])
                  if (
                    paletteAd &&
                    paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT
                  ) {
                    const thumbnail =
                      _.get(paletteAd, [
                        'creatives',
                        0,
                        'attachment',
                        'file_url'
                      ]) || this.context.models.config.data.default_thumbnail
                    return (
                      <ProgramItem
                        key={`${object.type}-${object.id}`}
                        title={paletteAd.name}
                        thumbnail={thumbnail}
                        onClick={() => {
                          if (paletteAd.url)
                            window.location.href = paletteAd.url
                        }}
                      />
                    )
                  }

                  return (
                    <ProgramItem
                      key={`${object.type}-${object.id}`}
                      meta={meta}
                      product={product}
                      course={course}
                      howToPlay={howToPlay}
                      showCaption
                      showCoin
                      showDelivery
                      showNew={webApp.utils.showNew(meta)}
                      onClickThumbnail={() =>
                        webApp.utils.goToProgramLink(
                          this.context,
                          meta,
                          product,
                          course
                        )
                      }
                      onClickCaption={() =>
                        webApp.utils.goToProgramLink(
                          this.context,
                          meta,
                          product,
                          course,
                          { autoPlay: false }
                        )
                      }
                      titlesMinHeight={false}
                      textOverFlow
                    />
                  )
                })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
