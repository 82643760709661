import Heading1 from '../Heading1'
import Button3 from '../common/Button/Button3'
import Banner from './Banner/'
import List from './List/'
import styled from 'styled-components'
import { mediaQuery } from '../../../style'
import { pcMinSize, spMinSize } from '../styles'

export const StyledHeading1 = styled(Heading1)`
  height: 255px;
  position: relative;

  ${mediaQuery()} {
    height: 80px;
  }

  img {
    position: relative;
    z-index: 1;

    ${mediaQuery()} {
      width: 172px;
    }
  }

  &.lazyloaded::after {
    margin: 0 auto;
    width: 1158px;
    height: 239px;
    background: url(/images/exdio/renewal/gariben_daigaku/top/bg2_2.webp)
      no-repeat bottom center / 100% auto;
    position: absolute;
    right: 0;
    bottom: -14px;
    left: 0;
    content: '';

    ${mediaQuery()} {
      width: ${spMinSize};
      height: calc(239px * (320 / 1158));
      bottom: -5px;
    }
  }
`

export const StyledDiv1 = styled.div`
  &.lazyloaded {
    background: #f4e3d3
      url(/images/exdio/renewal/gariben_daigaku/top/sec3_bg.webp) repeat left
      top / 852px auto;
  }
`

export const StyledDiv2 = styled.div`
  margin: 0 auto;
  padding: 40px 0 30px;
  width: ${pcMinSize};
  position: relative;
  z-index: 1;

  ${mediaQuery()} {
    padding: 35px 5px 15px;
    width: auto;
  }
`

export const StyledBanner = styled(Banner)`
  ${mediaQuery()} {
    margin-top: 10px;
  }
`

export const StyledList = styled(List)`
  margin-top: 40px;

  ${mediaQuery()} {
    margin-top: 10px;
  }
`

export const StyledButton3 = styled(Button3)`
  margin: 40px auto 0;
  width: 340px;
  font-size: 2rem;

  ${mediaQuery()} {
    margin-top: 10px;
    width: auto;
    font-size: 1.6rem;
  }
`
