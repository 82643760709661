import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import styled, { createGlobalStyle } from 'styled-components'
import { META_SCHEMA_ID } from '../../../../../constants/app'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  checkAvailableStatus,
  getThumbnailUrl,
  play
} from '../EpisodeDefault/util'
import routes from '../../../../common/routes'
import * as tags from '../plan_logirl/Tags'

/* hooks */
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/* components */
import PackList from '../details/PackList'
import RenewalPlayer from '../../player/RenewalPlayer'
import FilterSort from '../../../../common/components/FilterSort'
import GoBack from '../../../../common/components/appli/renewal/GoBack'
import HtmlSnippet from '../../HtmlSnippet'
import HeaderNewsComponent from '../HeaderNewsComponent'
import Footer from '../../../../common/components/appli/EpisodeAppFooter'
import Notice from '../EpisodeDefault/Notice'
import CommonVideoArea from '../EpisodeDefault/CommonVideoArea'
import Meta from '../EpisodeDefault/Meta'
import Actions from '../EpisodeDefault/Actions'
import ListMetaSub from '../EpisodeDefault/ListMetaSub'

/** style */
import { mediaQuery } from '../../style'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 単話ページ */
const EpisodeAppLogirl = (
  {
    seriesId = '',
    seasonId = '',
    episodeId = '',
    meta = {},
    programMeta = {},
    episodes = [],
    product = {},
    products = [],
    course = {},
    status = {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    sendPlayLog = () => {},
    loaded = false
  },
  context
) => {
  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix
  const isLoggedIn = webApp.utils.isLoggedIn(context)

  const [progress, setProgress] = useState(webApp.utils.progress(config, meta))

  const playerRef = useRef(null)
  const setPlayerRef = (e) => {
    playerRef.current = e
  }

  const searchParams = useSearchParams({
    childEpisodeIds: get(programMeta, ['values', 'child_episode_ids'], []),
    seasonIds: [Number(seasonId)],
    sortedBy: 'delivery_start_at_newer',
    pagerOptions: {
      episodesPerPages: 60,
      range: 2,
      showBottom: true
    }
  })

  const onClickPlay = (e, asSvod = false) => {
    if (e) e.preventDefault()

    // 未ログイン状態の場合はログイン画面へ遷移する。
    if (!isLoggedIn) {
      context.history.push(
        routes.app_login.makePath(null, {
          redirect: context.routeHandler.path
        })
      )
      return
    }

    switch (checkAvailableStatus({ status, product }, context, asSvod)) {
      case 'PLAY':
        play(playerRef.current, sendPlayLog)
        break
      case 'PURCHASE_EPISODE': // 有料なので何もする必要がない
      case 'PURCHASE_PLAN': // 有料なので何もする必要がない
      default:
        break
    }
  }

  const onClosePlayer = () => {
    setProgress(webApp.utils.progress(config, meta))
  }

  useEffect(() => {
    // リタゲタグの挿入
    Object.keys(tags.tagIds).forEach((key) => {
      const target = document.getElementById(tags.tagIds[key])
      if (!target) {
        webApp.utils.appendUserGram(tags[key])
      }
    })

    return () => {
      document.body.style.overflow = null

      // リタゲタグの削除
      webApp.utils.removeUserGram(Object.values(tags.tagIds))
    }
  }, [])

  const [mainTitle, subTitle] = webApp.utils.titles(meta)
  const metaName = `${mainTitle} ${subTitle}`

  const episodeBannerId1 = get(meta, ['values', 'banner_1'])
  const episodeBannerId2 = get(meta, ['values', 'banner_2'])
  const programBannerId1 = get(programMeta, ['values', 'banner_1'])
  const programBannerId2 = get(programMeta, ['values', 'banner_2'])

  const isLive =
    meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
    meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
  let isClipVod = false

  let metaIdForPlayer
  if (isLive) {
    if (meta.values.clipping_select_media_type === 'vod') {
      metaIdForPlayer = meta.values.clipping_vod_ref_id
      isClipVod = true
    }
    if (!metaIdForPlayer) metaIdForPlayer = meta.values.target_ref_id
  }
  if (!metaIdForPlayer)
    metaIdForPlayer = `${config.videocloud.reference_id_prefix || ''}${
      meta.meta_id
    }`
  const playerSettings = webApp.utils.getPlayerSettings(
    config,
    meta,
    status.displayMode
  )

  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  const isBefore = Boolean(
    !withValidPreviewToken && moment(prevTime).isBefore(meta.delivery_start_at)
  )
  const isAfter = Boolean(
    !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
  )
  const thumbnailUrl = getThumbnailUrl(
    { meta, programMeta },
    context,
    config,
    previewUrlList,
    cookieRubocopPrefix
  )
  const inViewTerm = !(isBefore || isAfter)
  const nextPrevText = (() => {
    if (inViewTerm || isLive) return null
    if (isBefore) return 'まもなく配信予定'
    return '次回の配信もお楽しみに！'
  })()
  const isPlayerRenderable = metaIdForPlayer && status.displayMode && inViewTerm

  return (
    <div className="common-wrapper">
      <GoBack />
      <HeaderNewsComponent />
      <Notice programMeta={programMeta} />

      {isPlayerRenderable && (
        <RenewalPlayer
          ref={(e) => setPlayerRef(e)}
          meta_id={metaIdForPlayer}
          product_type={playerSettings.product_type}
          channel={playerSettings.channel}
          ssai_ad_config_id={playerSettings.ssai_ad_config_id}
          delivery_config_id={playerSettings.delivery_config_id}
          thumbnail_url={meta.thumbnail_url}
          subtitle={!!meta.values.subtitle}
          enqueteEnabled={status.isFree}
          material_id={get(product, ['ref_id']) || ''}
          license_id={get(course, ['ref_id']) || ''}
          display_mode={status.displayMode}
          onClose={onClosePlayer}
          isLive={isLive}
          isClipVod={isClipVod}
        />
      )}

      <CommonVideoArea
        progress={progress}
        onClickPlay={onClickPlay}
        isLive={isLive}
        inViewTerm={inViewTerm}
        metaName={metaName}
        thumbnailUrl={thumbnailUrl}
        nextPrevText={nextPrevText}
        meta={meta}
        status={status}
        product={product}
        episodeId={episodeId}
        seriesId={seriesId}
        seasonId={seasonId}
        permitClickPlayButton={!isLoggedIn}
      />

      <div className="c-storyMeta">
        <div className="c-storyMeta-inBox">
          <Meta
            isLive={isLive}
            metaName={metaName}
            meta={meta}
            product={product}
            course={course}
            status={status}
          />
          <Actions
            onClickPlay={onClickPlay}
            inViewTerm={inViewTerm}
            metaName={metaName}
            seriesId={seriesId}
            seasonId={seasonId}
            meta={meta}
            product={product}
            status={status}
            course={course}
            loaded={loaded}
            renderLinkAppLogin
          />
        </div>
      </div>

      <div className="c-listMeta">
        <div className="c-listMeta-inBox">
          <div className="c-listMeta-inBox-main">
            {episodeBannerId1 && <HtmlSnippet snippetId={episodeBannerId1} />}
            {programBannerId1 && <HtmlSnippet snippetId={programBannerId1} />}
            {status.isNotInCourse && <PackList products={products} />}
            {Object.keys(programMeta).length > 0 && (
              <section>
                <StyledH3>単話</StyledH3>
                <StyledFilterSort
                  searchParams={searchParams}
                  episodeListItemProps={{
                    showNew: true,
                    showChecked: true,
                    showCoin: true,
                    showCaption: true,
                    onlySubTitle: true
                  }}
                />
              </section>
            )}
          </div>
          <ListMetaSub
            loaded={loaded}
            episodeBannerId={episodeBannerId2}
            programBannerId={programBannerId2}
            episodes={episodes}
            course={course}
            seriesId={seriesId}
            seasonId={seasonId}
            status={status}
            meta={meta}
          />
        </div>
      </div>
      <Footer
        className="mp-mt0"
        copyrights={get(meta, ['values', 'evis_Copyright'])}
      />
      <GlobalStyle />
    </div>
  )
}

export default EpisodeAppLogirl

EpisodeAppLogirl.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のエピソードID */
  episodeId: PropTypes.string,

  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** 動画の視聴権利関連情報 */
  howToPlay: PropTypes.object,
  /** 動画の価格、配信期間情報など */
  product: PropTypes.object,

  // 通常単話
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  /** パック販売情報 */
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number,
      name: PropTypes.string,
      original_price: PropTypes.number,
      active_pricing: PropTypes.shape({
        price: PropTypes.number,
        unit: PropTypes.string
      })
    })
  ),
  /** episodesのhowToPlay情報 */
  howToPlays: PropTypes.object,
  // 月額見放題
  /** 動画のコース情報 */
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),
  /** 動画の視聴ステータス情報 */
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  /** 動画のシーズン情報 */
  season: PropTypes.object,
  /** 動画再生時のログ送信 */
  sendPlayLog: PropTypes.func,
  /** データの取得が終わっているか */
  loaded: PropTypes.bool
}

EpisodeAppLogirl.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

const GlobalStyle = createGlobalStyle`
.u-hide--app {
  display: none !important;
}

.c-listMeta-inBox-main {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
`

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 600;

  ${mediaQuery()} {
    padding: 0 15px;
  }
`

const StyledFilterSort = styled(FilterSort)`
  margin-top: 20px;
`
