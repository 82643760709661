import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import useMediaQuery from '../../../../../../hooks/useMediaQuery'

/* style */
import { StyledDivInner } from '../../style'
import styled, { css } from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'

/** 必殺仕事人 LP | ナビ */
const Nav = ({ navItems = [] }) => {
  const isSp = useMediaQuery()
  const [open, setOpen] = useState(false)
  const [isFixed, setIsFixed] = useState(false)

  const onScroll = () => {
    const currentScroll = document.scrollingElement.scrollTop
    setIsFixed(currentScroll > 529)
  }

  /** spナビ */
  const toggleFunction = () => setOpen(!open)

  /** スムーススクロール */
  const smoothScroll = (e, href) => {
    if (href.startsWith('#')) {
      e.preventDefault()
      setOpen(false)
      const position = document.getElementById(href.substring(1)).offsetTop
      window.scrollTo({
        top: position,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      {isSp && (
        <StyledDiv1 onClick={toggleFunction} open={open}>
          <span></span>
          <span></span>
          <span></span>
        </StyledDiv1>
      )}
      <StyledNav open={open} isFixed={isFixed}>
        <StyledDivInner>
          <StyledUl>
            {navItems.map(
              (
                { href = '', src = '', width = '', height = '', html = '' },
                i
              ) => (
                <StyledLi key={i}>
                  <StyledLink
                    href={href}
                    onClick={(e) => smoothScroll(e, href)}
                  >
                    <StyledI>
                      <img
                        src={src}
                        width={width}
                        height={height}
                        alt={html}
                        loading="lazy"
                      />
                    </StyledI>
                    <StyledSpan dangerouslySetInnerHTML={{ __html: html }} />
                  </StyledLink>
                </StyledLi>
              )
            )}
          </StyledUl>
        </StyledDivInner>
      </StyledNav>
    </>
  )
}

export default Nav

Nav.propTypes = {
  /**
   * ナビの要素の配列
   * @param {Object[]} navItems - ナビ項目の配列
   * @param {string} navItems.href - ナビリンクのURL
   * @param {string} navItems.src - 画像のURL
   * @param {string} navItems.width - 画像の幅
   * @param {string} navItems.height - 画像の高さ
   * @param {string} navItems.html - HTML文字列またはテキスト
   */
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      src: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      html: PropTypes.string
    })
  )
}

const StyledNav = styled.nav`
  background: url(/images/exdio/renewal/hissatsu/nav_bg.png) no-repeat top
    center / cover;

  ${({ isFixed }) =>
    isFixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      z-index: 50;
      width: 100%;
    `}

  ${mediaQuery()} {
    background: #740000;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;

    ${({ open }) =>
      open &&
      css`
        opacity: 1;
        visibility: visible;
      `}
  }
`

const StyledUl = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  ${mediaQuery()} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
`

const StyledLi = styled.li`
  width: calc(100% / 4);

  ${mediaQuery()} {
    width: 100%;
    padding-left: 30px;
  }
`

const StyledI = styled.i`
  /* text-align: center; */
`

const StyledSpan = styled.span`
  color: #fff;
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  min-height: 59px;
  font-size: 1.6rem;
  transition: background 0.3s;

  @media (hover: hover) {
    &:hover {
      background: rgba(82, 1, 0, 0.6);
    }
  }

  ${mediaQuery()} {
    justify-content: flex-start;
  }
`

const StyledDiv1 = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 38px;
  height: 38px;
  background: #740000;
  z-index: 15;
  cursor: pointer;

  span {
    display: block;
    width: 20px;
    height: 2px;
    background: #fff;
  }

  ${({ open }) =>
    open &&
    css`
      & {
        span {
          position: relative;

          &:nth-of-type(1) {
            top: 5px;
            transform: rotate(-45deg);
          }
          &:nth-of-type(2) {
            display: none;
          }
          &:nth-of-type(3) {
            transform: rotate(45deg);
            top: -2px;
          }
        }
      }
    `}
`
