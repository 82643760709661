import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../common/routes'

/* components */
import { Link } from '../../../../../sketch-platform/ui/routing'
import Counter from './Counter'

/* style */
import {
  StyledH2,
  StyledSection,
  StyledDiv1,
  StyledP,
  StyledUl,
  StyledLi,
  StyledDiv2,
  StyledChildComponent
} from './style'

/** Sample Component (Dev Only) */
const Sample = ({ somethingString = '', somethingArray = [], ...props }) => {
  /** useState */
  const [somethingState, setSomethingState] = useState(null)

  /** useEffect */
  useEffect(() => {
    // console.info('"useEffect" is active.')

    setSomethingState(() => {
      // console.info('"somethingState" is updated.')
      return 'somethingState'
    })
  }, [])

  return (
    <StyledSection {...props}>
      {somethingString && (
        <StyledH2>Props.somethingString: {somethingString}.</StyledH2>
      )}

      <StyledDiv1>
        <StyledP>Props.somethingState: {somethingState}.</StyledP>
        <StyledP>
          Sample Text. Sample Text. Sample Text.{' '}
          <Link route={routes.home}>Top Page Link.</Link> Sample Text. Sample
          Text.
        </StyledP>
      </StyledDiv1>

      {somethingArray.length > 0 && (
        <StyledUl>
          {somethingArray.map((item) => {
            const { id, text } = item
            return <StyledLi key={id}>{text}</StyledLi>
          })}
        </StyledUl>
      )}

      <StyledDiv2>
        <Counter />
      </StyledDiv2>

      <StyledChildComponent
        somethingString={somethingString}
        somethingImagePath={'https://placehold.jp/150x150.png'}
      />
    </StyledSection>
  )
}

export default Sample

Sample.propTypes = {
  /** Description of somethingString */
  somethingString: PropTypes.string,
  /** Description of somethingArray */
  somethingArray: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string
    })
  )
}
