import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import routes from '../../../../../common/routes'
import webApp from '../../../../utils/exdioWebAppUtils'
import { mediaQuery } from '../../../style'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import useMediaQuery from '../../../../../hooks/useMediaQuery'

/** そだてれび：海外視聴用 番組ページ SNSエリア */
const SnsArea = ({ seriesId = '', seasonId = '', ...props }) => {
  const isSp = useMediaQuery()
  const pageName = encodeURI(
    '世界を目指そう！そだてれび ／SodaTV: Aim for the World!｜テレ朝動画'
  )
  const pageUrl = `${webApp.utils.rootUrl()}${routes.program.makePath({
    seriesId,
    seasonId
  })}`
  const X = {
    href: `https://twitter.com/share?url=${pageUrl}&text=${pageName}`,
    src: '/images/exdio/renewal/soda_tv/tw.svg',
    alt: 'X',
    isX: true
  }
  const facebook = {
    href: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`,
    src: '/images/exdio/renewal/soda_tv/fb.png',
    alt: 'facebook',
    isX: false
  }
  const line = {
    href: `https://line.me/R/msg/text/?${pageName}%0D%0A${pageUrl}`,
    src: '/images/exdio/renewal/soda_tv/line.jpg',
    alt: 'LINE',
    isX: false
  }
  const listItems = [X, facebook, ...(isSp ? [line] : [])]

  return (
    <StyledUl {...props}>
      {listItems.map(({ href = '', src = '', alt = '', isX = false }) => (
        <li key={alt}>
          <StyledLink href={href} target="_blank" isX={isX}>
            <StyledImg src={src} alt={alt} width="30" height="30" />
          </StyledLink>
        </li>
      ))}
    </StyledUl>
  )
}

export default SnsArea

SnsArea.propTypes = {
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  /** スマートフォンでの表示か */
  isSmp: PropTypes.bool,
  className: PropTypes.string
}

const StyledUl = styled.ul`
  display: flex;
  gap: 10px;

  ${mediaQuery()} {
    justify-content: center;
    gap: 5px;
  }
`

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['isX'].includes(prop)
})`
  padding: ${({ isX }) => (isX ? '5px' : null)};
  width: 30px;
  height: 30px;
  background-color: ${({ isX }) => (isX ? '#000' : null)};
  display: block;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
  }
`

const StyledImg = styled.img`
  width: 100%;
`
