import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DetailInfo from './DetailInfo'
import DetailThumbnail from './DetailThumbnail'
import webApp from '../../../../../utils/exdioWebAppUtils'
import EpisodePager from './EpisodePager'
import convertTimeString from '../../functions/convertTimeString'

/** そだてれび：海外視聴用 PC 単話ページ動画詳細 */
const Detail = (
  { meta, params, onClickPlay, episodes, course, className },
  context
) => {
  const [hover, setHover] = useState(false)
  const [title, subTitle] = webApp.utils.titles(meta)

  // 動画試聴時間
  const time = convertTimeString(webApp.utils.duration(meta)) || '-'
  //配信期間
  const separatorString = /\s+/
  const delivery_date = meta.publish_start_at.split(separatorString, 1)
  //配信開始日
  const release_date =
    meta.values.avails_ReleaseHistoryOriginal.split(separatorString, 1) ||
    delivery_date
  //あらすじ
  const desc = meta.values.evis_EpisodeLongSynopsis
    ? webApp.utils.feedLine(meta.values.evis_EpisodeLongSynopsis)
    : ''
  // 最新動画かどうか
  const isNew = webApp.utils.isNew(context, meta)

  return (
    <StyledDiv
      className={className}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <DetailThumbnail
        thumbnailAlt={title}
        thumbnailUrl={meta.thumbnail_url}
        onClickPlay={onClickPlay}
      />
      <StyledDetailInfo
        params={params}
        title={title}
        subTitle={subTitle}
        time={time}
        delivery_date={delivery_date}
        release_date={release_date}
        content={desc}
        isNew={isNew}
      />
      <EpisodePager
        meta={meta}
        seriesId={params.seriesId}
        seasonId={params.seasonId}
        episodes={episodes}
        course={course}
        hover={hover}
      />
    </StyledDiv>
  )
}

Detail.propTypes = {
  params: PropTypes.shape({
    seriesId: PropTypes.string,
    seasonId: PropTypes.string
  }),
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  onClickPlay: PropTypes.func
}

Detail.contextTypes = {
  models: PropTypes.object
}

export default Detail

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`

const StyledDetailInfo = styled(DetailInfo)`
  width: calc(100% - 640px);
`
