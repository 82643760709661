import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../../../common/routes'
import Link from '../../../../../../../../sketch-platform/ui/routing/Link'
import webApp from '../../../../../../utils/exdioWebAppUtils'
import Menu from '../../other/Menu'

/* style */
import {
  StyledHeader,
  StyledDiv1,
  StyledBtn1,
  StyledNav1,
  StyledLink1,
  StyledButton,
  StyledI,
  StyledSpan
} from './style'

/** Logirlプランページ | ヘッダー */
const Header = (props, context) => {
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const menuRef = useRef(null)

  // Menuの関数を参照
  const openMenuHandler = () => {
    menuRef.current.openMenuHandler()
  }

  return (
    <StyledHeader>
      <StyledDiv1>
        <StyledLink1 route={routes.plan} params={{ slug: 'logirl' }}>
          <picture>
            <source
              srcSet="/images/exdio/renewal/logirl/logo_ja.svg"
              media="(min-width: 1024px)"
            />
            <img
              src="/images/exdio/renewal/logirl/logo.svg"
              alt="Logirl ロガール"
            />
          </picture>
        </StyledLink1>
        <StyledNav1 />
        {isLoggedIn ? (
          <StyledBtn1
            route={routes.mypage}
            text="マイページ"
            color="#00a3a3"
            borderColor="#00a3a3"
            shadowColor="#00a3a3"
            isLoggedIn={isLoggedIn}
          />
        ) : (
          <StyledBtn1
            route={routes.login}
            query={{ redirect: 'plan/logirl' }}
            text="ログイン"
            icon={{
              src: '/images/exdio/renewal/logirl/icon/login.svg',
              alt: ''
            }}
            color="#00a3a3"
            borderColor="#00a3a3"
            shadowColor="#00a3a3"
          />
        )}
        <StyledButton type="button" onClick={openMenuHandler}>
          <StyledI />
          <StyledSpan>MENU</StyledSpan>
        </StyledButton>
      </StyledDiv1>
      <Menu ref={menuRef} />
    </StyledHeader>
  )
}

export default Header

Header.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func
}
