import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button1 from '../../common/Button/Button1'

/** Nav｜List2 */
const List2 = ({ linkItems = {}, onClick = () => {}, ...props }) => {
  return (
    <StyledUl {...props}>
      {linkItems.map((link, i) => (
        <li key={`link-${i}`}>
          <Button1
            href={link.href}
            target={link.target}
            html={link.html}
            onClick={(event) => onClick(event, link.href)}
          />
        </li>
      ))}
    </StyledUl>
  )
}

export default List2

List2.propTypes = {
  /** ナビゲーションリンクの表示要素
   * @param {string} href - Button1コンポーネントに渡すhref
   * @param {string} target - Button1コンポーネントに渡すtarget
   * @param {string} html - リンクに表示するhtml
   */
  linkItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      html: PropTypes.string
    })
  ),
  /** ボタンクリック時に発火する関数 */
  onClick: PropTypes.func,
  /** styleの継承 */
  className: PropTypes.string
}

const StyledUl = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`
