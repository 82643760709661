import { Link } from '../../../../../../../sketch-platform/ui/routing'
import styled, { css, keyframes } from 'styled-components'
import { mediaQuery } from '../../../../style'

const scroll = keyframes`
  0% {transform: translateX(100%);}
  100% {transform: translateX(-100%);}
`

const neon = keyframes`
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #019370,
      0 0 70px #019370, 0 0 80px #019370, 0 0 100px #019370, 0 0 150px #019370;
  }

  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #019370,
      0 0 35px #019370, 0 0 40px #019370, 0 0 50px #019370, 0 0 75px #019370;
  }
`

export const StyledDl = styled.dl`
  padding: 10px;
  background: #019370
    url(/images/exdio/renewal/gariben_daigaku/components/dl6_bg.webp) no-repeat
    center / 100%;
  border: 2px solid #019370;
  position: relative;
  text-align: left;
  color: #fff;

  ${mediaQuery()} {
    background: url(/images/exdio/renewal/gariben_daigaku/components/dl6_bg_sp_left.webp)
        no-repeat left center / auto 100%,
      url(/images/exdio/renewal/gariben_daigaku/components/dl6_bg_sp_right.webp)
        no-repeat right center / auto 100%,
      #019370
        url(/images/exdio/renewal/gariben_daigaku/components/dl6_bg_sp_center.webp)
        repeat-x center center / auto 100%;
  }
`

export const StyledLink = styled(Link)`
  display: block;
  transition: filter 0.4s ease-in-out;
  filter: drop-shadow(0 0 0 transparent);

  &:hover {
    filter: drop-shadow(0 0 10px rgba(1, 147, 112, 0.6));

    ${StyledDd} {
      background-color: rgba(1, 147, 112, 0.4);
      background-image: radial-gradient(
          rgba(51, 51, 51, 0.1) 33%,
          transparent 33%
        ),
        radial-gradient(rgba(51, 51, 51, 0.1) 33%, transparent 33%);
      animation: ${neon} 1.5s ease-in-out infinite alternate;
    }
  }

  ${mediaQuery()} {
    margin: 120px 10px 0;
  }
`

export const StyledDt = styled.dt`
  background-color: rgba(1, 147, 112, 0.4);
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;

  ${mediaQuery()} {
    display: none;
  }

  &::after {
    width: 0;
    height: 0;
    border-top: 10px solid rgba(1, 147, 112, 0.4);
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid rgba(1, 147, 112, 0.4);
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    content: '';
  }
`

export const StyledSpan = styled.span`
  padding: 4px 0 4px 10px;
  display: block;
  font-size: 1.2rem;
  line-height: 1;
  letter-spacing: 0.5em;
  filter: drop-shadow(0 0 3px #fff);
`

export const StyledDd = styled.dd`
  background-color: #015f48;
  background-image: radial-gradient(rgba(51, 51, 51, 0.4) 33%, transparent 33%),
    radial-gradient(rgba(51, 51, 51, 0.4) 33%, transparent 33%);
  background-size: 8px 8px;
  background-position: 0 0, 4px 4px;
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  transition: background 0.2s ease-in-out;
`

export const StyledP = styled.p`
  padding: 20px;
  min-width: 100%;
  display: inline-block;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.2em;
  white-space: nowrap;
  transition: all 0.4s ease-in-out;
  filter: drop-shadow(0 0 5px #fff);

  ${mediaQuery()} {
    padding: 13px;
    font-size: 1.6rem;
  }

  ${({ isAnimate }) => {
    if (!isAnimate) return
    return css`
      text-align: left;
      animation: ${scroll} 30s linear infinite;
      animation-delay: -10s;

      ${mediaQuery()} {
        animation: ${scroll} 20s linear infinite;
        animation-delay: -7s;
      }
    `
  }}
`
