/* style */
import styled from 'styled-components'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import { mediaPc, mediaSp } from '../style'

export const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`

export const StyledLi = styled.li`
  margin-left: 32px;
  width: calc((100% - (32px * 3)) / 4);
  position: relative;

  @media ${mediaSp} {
    margin-left: 16px;
    width: calc((100% - 16px) / 2);
  }

  &:nth-child(odd) {
    @media ${mediaSp} {
      margin-left: 0;
    }
  }

  &:nth-child(n + 3) {
    @media ${mediaSp} {
      margin-top: 8px;
    }
  }

  &:nth-child(n + 5) {
    @media ${mediaPc} {
      margin-top: 32px;
    }
  }

  &:nth-child(4n + 1) {
    @media ${mediaPc} {
      margin-left: 0;
    }
  }
`

export const StyledDiv1 = styled.div`
  height: 160px;
  overflow: hidden;

  @media ${mediaSp} {
    height: auto;
  }
`

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.5;
  transition: opacity 0.2s ease-in-out;

  @media ${mediaSp} {
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.25;
  }

  &:hover {
    opacity: 0.7;

    ${StyledDiv1} {
      img {
        transform: scale(1.1);
      }
    }
  }
`

export const StyledImg = styled.img`
  && {
    width: 280px;
    height: 160px;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;

    @media ${mediaSp} {
      width: 100%;
      height: 100%;
    }
  }
`

export const StyledDiv2 = styled.div`
  margin-top: 4px;
  color: #fff;
`
