import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

/* style */
import { StyledUl, StyledLi, StyledLink } from './style'
import routes from '../../../../../common/routes'

/** EpisodeList Component */
const EpisodeList = ({ episodes = [], ...props }) => {
  return (
    <StyledUl {...props}>
      {episodes.map(
        ({
          id: episodeId = null,
          name = '',
          thumbnail_url = '',
          values = {}
        }) => {
          const params = {
            seriesId: get(values, ['parents_series', 'id'], null),
            seasonId: get(values, ['parents_season', 'id'], null),
            episodeId
          }

          return (
            <StyledLi key={episodeId}>
              <StyledLink route={routes.episode} params={params}>
                <img src={thumbnail_url} alt="" />
                <span>{name}</span>
              </StyledLink>
            </StyledLi>
          )
        }
      )}
    </StyledUl>
  )
}

export default EpisodeList

EpisodeList.propTypes = {
  /** Description of somethingString */
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      thumbnail_url: PropTypes.string,
      values: PropTypes.shape({
        parents_series: PropTypes.shape({
          id: PropTypes.number
        }),
        parents_season: PropTypes.shape({
          id: PropTypes.number
        })
      })
    })
  )
}
