import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SearchBoxYear, { StyledUl } from '../SearchBoxYear'
import { mediaQuery } from '../../../../exdio/components/style'

/** 放送年検索 */
const SearchBoxYearWrapper = ({
  selectedYear = null,
  setSelectedYear = () => {},
  ...props
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  /** アコーディオンの開閉 */
  const toggleAccordionState = () => {
    setIsAccordionOpen(!isAccordionOpen)
  }

  return (
    <StyledDiv {...props}>
      <StyledH3 isActive={isAccordionOpen} onClick={toggleAccordionState}>
        <StyledStrong data-text="ほうそう">放送</StyledStrong>された
        <StyledStrong data-text="とし">年</StyledStrong>でさがす
      </StyledH3>
      <StyledSearchBoxYear
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        isOpen={isAccordionOpen}
      />
    </StyledDiv>
  )
}

export default memo(SearchBoxYearWrapper)

SearchBoxYearWrapper.propTypes = {
  selectedYear: PropTypes.string,
  setSelectedYear: PropTypes.func
}

const StyledDiv = styled.div`
  padding: 15px 10px 10px;
  background: #c3f74a;
  border-radius: 10px;
  overflow: hidden;

  ${mediaQuery()} {
    margin: 20px 8px 0;
  }

  &::before {
    margin: 6px 10px 0 0;
    float: right;
    color: #333;
  }
`

const StyledH3 = styled.h3`
  padding: 5px 0 5px 10px;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  cursor: pointer;

  ${mediaQuery()} {
    font-size: 1.5rem;
    cursor: pointer;
  }

  &:before {
    margin-right: 10px;
    font-family: 'Material Icons';
    content: '\\E8B6';
  }

  &::after {
    margin-right: 8px;
    margin-left: auto;
    font-family: 'douga';
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\\e624';
  }

  /** 三項演算子で表示されないので一旦この形 */
  ${({ isActive }) => {
    if (isActive) {
      return `
        &::after {
          content: '\\e60d';
        }
      `
    }
    return null
  }}
`

const StyledStrong = styled.strong`
  position: relative;
  font-weight: normal;

  &::before {
    content: attr(data-text);
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 130%;
    text-align: center;
    white-space: nowrap;
    font-size: 10px;
    transform: scale(0.8);
    transform-origin: left;
  }
`

const StyledSearchBoxYear = styled(SearchBoxYear)`
  margin-top: 10px;
  display: ${({ isOpen }) => (isOpen ? null : 'none')};

  ${StyledUl} {
    padding: 20px 20px 25px;
  }
`
