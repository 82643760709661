import styled from 'styled-components'
import { mediaQuery, breakPoints } from '../../../style'

export const StyledInner = styled.div`
  padding-top: 30px;
  font-size: 1.3rem;
  line-height: 1.5;

  ${mediaQuery()} {
    padding-top: 0;
  }
`

export const StyledButton = styled.button`
  padding: 0;
  border-width: 0;
  background-color: #fff;
  text-align: left;
  transition: opacity 0.4s ease-in-out;

  ${mediaQuery()} {
    width: 100%;
    text-align: center;
  }

  ${mediaQuery('sm', 'min')} {
    &:hover {
      opacity: 0.7;
    }
  }
`

export const StyledButtonInner = styled.span`
  display: inline-block;
  position: relative;
  font-size: 1.3rem;
  font-weight: 600;
  color: #343434;

  &::after {
    margin-left: 10px;
    content: url(/images/exdio/renewal/arrow_bottom.svg);
  }
`

export const StyledWrapper = styled.div`
  ${StyledInner} {
    display: ${({ isOpen }) => (isOpen ? null : 'none')};
  }

  ${StyledButton} {
    margin-top: ${({ isOpen }) => (isOpen ? '20px' : '0')};
  }

  ${StyledButtonInner} {
    &::after {
      content: ${({ isOpen }) =>
        isOpen
          ? 'url(/images/exdio/renewal/arrow_top.svg)'
          : 'url(/images/exdio/renewal/arrow_bottom.svg)'};
    }
  }
`
