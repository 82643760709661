import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import webApp from '../../../../utils/exdioWebAppUtils'
import { PANEL_ID } from '../config'
import { SelectionItemType } from '../types'

/* components */
import SelectionItem from '../SelectionItem'

/* style */
import {
  StyledWrapper,
  StyledInner2,
  StyledInner1,
  StyledDiv2,
  StyledEpisodeListItem,
  StyledP2,
  StyledSnsButtons2,
  StyledButton2Left,
  StyledButton2Right
} from './styles'

/** SelectionDetail Component */
const SelectionDetail = (
  {
    data,
    meta = false,
    banner = '',
    hasNext = false,
    hasPrev = false,
    onClickNext = () => {},
    onClickPrev = () => {},
    ...props
  },
  context
) => {
  const { id, primary } = data
  const host = get(context, ['models', 'config', 'data', 'host'], '')
  const query = {
    tab: PANEL_ID.SELECTION,
    id
  }
  const queryString = new URLSearchParams(query).toString()
  const planPageHref = encodeURIComponent(
    `${host}/plan/shinchan?${queryString}`
  )
  const snsHref = {
    twitter: `http://twitter.com/share?url=${planPageHref}&text=クレヨンしんちゃんぶりぶりCLUB｜テレ朝動画`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${planPageHref}`,
    line: `https://social-plugins.line.me/lineit/share?url=${planPageHref}`
  }
  const routeParams = size(meta)
    ? webApp.utils.getProgramLinkRoutes(context, meta)
    : {}

  return (
    <StyledWrapper hasMeta={Boolean(meta)} {...props}>
      <StyledButton2Left
        prevOrNext="prev"
        isShow={hasPrev}
        onClick={onClickPrev}
      />
      <StyledDiv2 hasMeta={Boolean(meta)}>
        <StyledInner1>
          <SelectionItem {...primary} />
        </StyledInner1>
        {size(meta) > 0 && (
          <StyledInner2>
            <StyledEpisodeListItem
              meta={meta}
              showChecked={webApp.utils.isWatched(meta)}
              onlySubTitle
              thumbnailSize="medium"
              listType="grid"
              {...routeParams}
            />
            <StyledP2>
              <img
                src="/douga_mv/shinchan/svod/images/selection/description.png"
                width="500"
                height="270"
                alt="特集を一本にまとめた動画で、一気にご視聴できます！"
              />
            </StyledP2>
          </StyledInner2>
        )}
        {banner && (
          <StyledInner2 dangerouslySetInnerHTML={{ __html: banner }} />
        )}
        <StyledSnsButtons2 href={snsHref} />
      </StyledDiv2>
      <StyledButton2Right
        prevOrNext="next"
        isShow={hasNext}
        onClick={onClickNext}
      />
    </StyledWrapper>
  )
}

export default memo(SelectionDetail)

SelectionDetail.propTypes = {
  /** 選択中の厳選エピソードの情報 */
  data: SelectionItemType.isRequired,
  /** 特集エピソード */
  meta: PropTypes.oneOf([PropTypes.object, false]),
  /** バナーHTML */
  banner: PropTypes.string,
  /** 次(より古い)エピソードがあるか */
  hasNext: PropTypes.bool,
  /** 前(より新しい)エピソードがあるか */
  hasPrev: PropTypes.bool,
  /** Nextボタンを押したときに実行されるメソッド */
  onClickNext: PropTypes.func,
  /** Prevボタンを押したときに実行されるメソッド */
  onClickPrev: PropTypes.func
}

SelectionDetail.contextTypes = {
  models: PropTypes.object,
  history: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object
}
