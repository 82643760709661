import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import webApp from '../../../../../utils/exdioWebAppUtils'
import Thumbnail, { StyledImg } from './Thumbnail'
import Info from './Info'
import Label from '../Label'

const Item = ({ meta, onClickThumbnail, isSmp = false }, context) => {
  const formatBroadcast = () => {
    const _broadcast = new Date(meta.values.avails_ReleaseHistoryOriginal)
    const year = _broadcast.getFullYear()
    const month = _broadcast.getMonth() + 1
    const date = _broadcast.getDate()
    return `${year}.${month}.${date}`
  }

  const formatThumbnail = () => {
    let thumbnailUrl = meta.thumbnail_url
    /* 読み込む画像サイズを変更する */
    thumbnailUrl = webApp.utils.customSizeImageUrl(thumbnailUrl, 'medium')

    return thumbnailUrl || context.models.config.data.default_thumbnail
  }

  const handleClick = (propFunc, e) => {
    e.preventDefault()
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation()
      propFunc(e)
    }
  }

  const [title, subTitle] = webApp.utils.titles(meta)
  const broadcast = formatBroadcast()
  const thumbnailUrl = formatThumbnail()
  const thumbnailAlt = `${title} ${subTitle}`
  const duration = webApp.utils.duration(meta)
  const isNew = webApp.utils.isNew(context, meta)

  return (
    <StyledLi isSmp={isSmp}>
      <a href="#" onClick={(e) => handleClick(onClickThumbnail, e)}>
        <Label isNew={isNew} duration={duration} />
        <Thumbnail
          thumbnailAlt={thumbnailAlt}
          thumbnailUrl={thumbnailUrl}
          isSmp={isSmp}
        />
        <Info
          title={subTitle}
          isNew={isNew}
          broadcast={broadcast}
          isSmp={isSmp}
        />
      </a>
    </StyledLi>
  )
}

Item.propTypes = {
  /** メタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  // 各onClickは引数に(event)を渡して呼び出される
  onClickThumbnail: PropTypes.func,
  isSmp: PropTypes.bool
}

Item.defaultProps = {
  meta: null,
  onClickThumbnail: null
}

Item.contextTypes = {
  models: PropTypes.object
}

export default Item

const StyledLi = styled.li`
  a:hover {
    ${StyledImg} {
      opacity: 0.7;
    }
  }
`
