import React, { Fragment, memo } from 'react'
import PropTypes from 'prop-types'

/** 投票機能 ラベル */
const Label = ({ isVoted, isDead }) => {
  return (
    <Fragment>
      {isVoted ? (
        <p id="labelVoted" className="c-label1--voted">
          投票済
        </p>
      ) : (
        <p id="labelUnVoted" className="c-label1">
          未投票
        </p>
      )}
      {isDead && (
        <p id="labelDeadline" className="c-label1--deadline">
          投票締切
        </p>
      )}
    </Fragment>
  )
}

export default memo(Label)

Label.propTypes = {
  /** 投票済みか */
  isVoted: PropTypes.bool,
  /** 投票期限が過ぎているか */
  isDead: PropTypes.bool
}

Label.defaultProps = {
  isVoted: false,
  isDead: false
}
