import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import routes from '../../../../common/routes'
import Canvas from '../../../../common/components/renewal/Canvas'

/** マイページ:(APP)TOP */
const AppTop = (props, context) => {
  const canvasConfig = context.models.config.data.canvas.apptop
  canvasConfig.ad = false // アプリで表示するときは広告を表示しない
  const isApp = !!routes.app_mypage.match(context.routeHandler.url)

  const renderBrowser = () => {
    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">{renderCommon()}</div>
      </div>
    )
  }

  const renderCommon = () => {
    return (
      <div className="apptop_canvas">
        <Canvas canvasKey="apptop" canvasConfig={canvasConfig} />
      </div>
    )
  }

  // ブラウザ(PC, SP)、WebView(APP)で取得・制御処理は変わらないのでrenderのみ分ける
  return isApp ? renderCommon() : renderBrowser()
}

export default AppTop

AppTop.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  falcorModel: PropTypes.object,
  updateUserInfo: PropTypes.func
}
