import styled from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'
import { breakPoints } from '../../style'

export const StyledLi = styled.li`
  margin-right: 12px;
  width: calc(100% / 4 - 9px);
  float: left;

  &:nth-child(4n) {
    margin-right: 0;
  }

  ${mediaQuery(breakPoints.sp)} {
    margin-right: 8px;
    margin-bottom: 12px;
    width: calc(100% / 2 - 4px);
    float: left;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`

export const StyledA = styled.a`
  overflow: hidden;
`

export const StyledDiv1 = styled.div`
  position: relative;
`

export const StyledImg1 = styled.img`
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
`

export const StyledP1 = styled.p`
  padding: 0 5px;
  background: #e23b25;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 1.2rem;
  font-style: italic;
`

export const StyledDiv2 = styled.div`
  padding: 5px 0;
`

export const StyledP2 = styled.p`
  color: #fff;
  margin-top: 3px;
  overflow: hidden;
  display: -webkit-box;
  font-size: 13px;
  font-weight: 700;
  font-style: italic;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.2;
`
export const StyledImg2 = styled.img`
  margin: 5px auto 0 0;
`
