import React, { memo } from 'react'
import styled from 'styled-components'

/** 全英オープン Caption */
const Caption = ({ children, ...props }) => (
  <StyledP {...props}>{ children }</StyledP>
)

export default memo(Caption)

const StyledP = styled.p`
line-height: 1.6;
font-size: 1.3rem;
`
