import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import window from 'global'
import styled from 'styled-components'
import webApp from '../../../utils/exdioWebAppUtils'
import ProgramItem from '../../../../common/components/renewal/ProgramItem'
import * as browserEvents from '../../../../utils/browserEvents'
import HeaderNewsComponent from '../HeaderNewsComponent'
import Footer from '../../../../common/components/appli/StaticFooter'
import ProgramItemSkeleton from '../../../../common/components/renewal/ProgramItemSkeleton' // スケルトンスクリーン
import { mediaQuery } from '../../style'

const { sprintf } = require('sprintf-js')

/** 見逃し無料ページ */
export default class CatchupContent extends Component {
  /**
   * サーバーサイドでsrc/apps/exdio/router.jsから呼ばれる
   * @param models
   * @param options
   * @param _props
   * @param _prefetchResult
   * @return {{titlePrior: string, description: string, thumbnail: string}}
   */
  static getSsrMetaTags(models, options, _props, _prefetchResult) {
    const config = models.config.data
    const { req } = options
    const rootUrl = `${req.protocol}://${req.get('host')}`
    return {
      titlePrior: config.default_title_catchup,
      description: config.description_catchup,
      thumbnail: sprintf('%s/images/exdio/%s', rootUrl, config.og_image_catchup)
    }
  }

  /**
   * タブ定義生成
   * falcorのpathに指定しやすいよう整形
   * @param genres
   * @returns {Array}
   */
  static createTabs(genres) {
    return genres.map((genre, i) => {
      const ids = genre.exclusive
        ? genres.filter((g) => !g.exclusive).flatMap((g) => g.keys)
        : genre.keys
      const path = ids.join(',')
      const key = [i, ...ids].join('_')
      return { key, path, ids, ...genre }
    })
  }

  /** スケルトンスクリーン */
  static Skeletons() {
    const skeletonObj = []
    for (let i = 0; i < 12; i += 1) {
      skeletonObj.push(<ProgramItemSkeleton key={i} hasThumb titleLength={2} />)
    }
    return skeletonObj
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data
    this.genres = this.config.catchup.genres

    this.state = {
      currentGenre: this.genres[0],
      genreAttributes: [],
      metas: [],
      isFetching: false,
      metasLoaded: false
    }

    this.onClickItem = this.onClickItem.bind(this)
    this.onClickTab = this.onClickTab.bind(this)

    this.setListRef = (e) => {
      this.listRef = e
    }
  }

  componentDidMount() {
    webApp.utils.load_vr_sync()
    this.fetchGenres()
      .then(() => this.fetchListData())
      .catch((e) => webApp.utils.handleFalcorError(e, this.context))
    this.updateMetaTags()

    browserEvents.addEventListener('touchmove', this.onTouchmove)
    browserEvents.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    browserEvents.removeEventListener('touchmove', this.onTouchmove)
    browserEvents.removeEventListener('scroll', this.onScroll)
    webApp.utils.remove_vr_sync()
  }

  onClickItem(e, meta, autoPlay = true) {
    e.preventDefault()
    webApp.utils.goToProgramLink(this.context, meta, null, null, {
      autoPlay,
      programLink: true
    })
  }

  onClickTab(genre) {
    if (genre === this.state.currentGenre || this.state.isFetching) return

    this.setState(
      {
        currentGenre: genre,
        metas: []
      },
      () => {
        this.fetchListData()
        CatchupContent.setCurrentMenuCenter()
      }
    )
  }

  static setCurrentMenuCenter() {
    if (window.screen.width > 768) return

    const genreNav = document.getElementById('genre-nav')

    // .currentのoffsetを取得
    const li = genreNav.querySelectorAll('button')
    let isLeftFromCurrent = false
    const offsetLeft = Array.from(li).reduce((prev, elm) => {
      if (Array.from(elm.classList).indexOf('current') > -1) {
        isLeftFromCurrent = true
      }
      return isLeftFromCurrent ? prev : prev + elm.offsetWidth
    }, 0)

    const windowWidthHalf = window.innerWidth / 2 - 24 // #genre-navから左の余白分を引く
    const tabWidthHalf = genreNav.querySelector('.current').offsetWidth / 2
    const margin = 16 // paddingの余白分を調整

    /**
     * offsetLeft: .currentを左端に配置
     * margin: 余白等があり左端からずれるため調整
     * windowWidthHalf: .currentを画面中央に配置
     * tabWidthHalf: .currentの左端が基準になっているため、要素分だけ調整
     */
    const scrollLeft = offsetLeft + margin - windowWidthHalf + tabWidthHalf
    genreNav.scrollTo({ left: scrollLeft, behavior: 'smooth' })
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags() {
    // タイトルタグの更新
    const title = this.config.default_title_catchup
    webApp.utils.updateTitle(title)
    // メタタグの更新
    const { copyright } = this.config
    const description = this.config.description_catchup
    const keywords = this.config.keywords_catchup
    const rootUrl = `${window.location.protocol}//${window.location.host}`
    const ogImage = sprintf(
      '%s/images/exdio/%s',
      rootUrl,
      this.config.og_image_catchup
    )
    const url = window.location.href
    const regularUrl = url.replace(/\?.*$/, '')
    const removeAppUrl = regularUrl.replace('/app', '')
    // GTMの更新
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: ogImage },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'mixi:image', content: ogImage },
        { property: 'og:image', content: ogImage },
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: removeAppUrl }]
    }
    webApp.utils.updateMeta(metaTags)
    webApp.utils.updateDataLayer(gtmTags)
    webApp.utils.updateCookieSync(window.navigator.userAgent)
  }

  fetchGenres() {
    const genreKeys = this.genres.flatMap((g) => g.keys).filter((k) => k)
    const path = [
      ['attributeByType', 'genre', genreKeys],
      ['attributeByType', 'sub_genre', genreKeys]
    ]

    return this.model.fetch(path).then((result) => {
      const attributes = _.get(result, ['json', 'attributeByType'], {})
      delete attributes.$__path
      const genreAttributes = Object.keys(attributes).reduce((prev, key) => {
        delete attributes[key].$__path
        return { ...prev, ...attributes[key] }
      }, {})
      this.setState({ genreAttributes })
    })
  }

  fetchListData() {
    const { isFetching, currentGenre, genreAttributes } = this.state
    if (isFetching) return
    this.setState({ isFetching: true, metasLoaded: false }, async () => {
      let isSubGenre = 0
      const ids = currentGenre.exclusive
        ? genreAttributes.map((attr) => attr.attribute_id)
        : currentGenre.keys
            .map((key) => {
              const genreAttr = Object.values(genreAttributes).find(
                (attr) => attr.slug === key
              )
              if (genreAttr.type === 'SUB_GENRE') {
                isSubGenre = 1
              }
              return genreAttr.attribute_id || null
            })
            .filter((v) => v)
      const genreIds = ids.join(',')
      const isExclusive = currentGenre.exclusive ? 1 : 0

      // 注)ライブは除外しています。
      const path = ['meta', 'catchupList', genreIds, isSubGenre, isExclusive]

      const deviceInfo = this.context.models.browserInfo.data
      if (deviceInfo.isIE) {
        // IEだとfinallyがエラーになる
        await this.model.fetch([path]).then((result) => {
          const metas = _.get(result, ['json', ...path]) || []
          this.setState({ metas }, () => {
            this.setState({ metasLoaded: true })
          })
        })
        this.setState({ isFetching: false })
      } else {
        this.model
          .fetch([path])
          .then((result) => {
            const metas = _.get(result, ['json', ...path]) || []
            this.setState({ metas }, () => {
              this.setState({ metasLoaded: true })
            })
          })
          .finally(() => {
            this.setState({
              isFetching: false
            })
          })
      }
    })
  }

  render() {
    const { currentGenre, metas, metasLoaded } = this.state

    return (
      <div className="common-wrapper catchup">
        <HeaderNewsComponent />
        <div className="c-catchupIndex" ref={this.setListRef}>
          <div className="c-catchupIndex-head">
            <ul id="genre-nav" className="c-NavSlider">
              {this.genres.map((genre) => (
                <li key={genre.title} className="c-NavSlider-btn">
                  <button
                    type="button"
                    className={`c-NavSlider-btn-link ${
                      genre.title === currentGenre.title ? 'current' : ''
                    }`}
                    onClick={() => this.onClickTab(genre)}
                  >
                    {genre.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="c-catchupIndex-cont">
            <div className="c-card-catchup">
              {metasLoaded ? (
                (() => {
                  if (!_.size(metas)) {
                    return <StyledP>現在配信中のエピソードはありません</StyledP>
                  }

                  return (
                    <div className="c-card-catchup-cont">
                      {metas.map((meta) => (
                        <ProgramItem
                          key={meta.meta_id}
                          meta={meta}
                          showRemaining
                          showDeadLine
                          showNew={false}
                          onClickThumbnail={(e) => this.onClickItem(e, meta)}
                          onClickCaption={(e) =>
                            this.onClickItem(e, meta, false)
                          }
                          programLink={meta.is_grouped}
                          showEndText
                        />
                      ))}
                    </div>
                  )
                })()
              ) : (
                <div className="c-card-catchup-cont">
                  <CatchupContent.Skeletons />
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer className="mp-mt0" />
      </div>
    )
  }
}

const StyledP = styled.p`
  font-size: 1.5rem;

  ${mediaQuery()} {
    font-size: 1.3rem;
  }
`
