import styled from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'
import Link from '../../../../../../sketch-platform/ui/routing/Link'

export const StyledDiv = styled.div`
  width: 1280px;
  text-align: right;
  margin: 0 auto 15px;
  color: #6d9cc8;
  clear: none;
  font-size: 1.1rem;

  ${mediaQuery(1320)} {
    width: 960px;
  }
`

export const StyledP = styled.p`
  display: inline-block;

  ::after {
    content: '>';
    margin: 0 3px;
  }
`

export const StyledLink = styled(Link)`
  color: #6d9cc8;

  :visited,
  :link {
    color: #6d9cc8;
  }

  :hover {
    text-decoration: underline;
  }
`
