import React from 'react'
import PropTypes from 'prop-types'
import HtmlSnippet from '../../../../HtmlSnippet'
import ListItem from '../ListItem/'
import { StyledLi, StyledUl, StyledP } from './style'

/** Section6｜リスト */
const List = ({ garibenV = {}, snippetId = null, dougaNews = {} }) => {
  const isHide = !document.querySelector('#secondaryAudio div .c-list12__link')

  return (
    <>
      <StyledUl>
        {garibenV.values && (
          <StyledLi>
            <ListItem meta={garibenV} />
          </StyledLi>
        )}
        {snippetId && (
          <StyledLi id="secondaryAudio" isHide={isHide}>
            <HtmlSnippet snippetId={snippetId} />
          </StyledLi>
        )}
        {dougaNews.values && (
          <StyledLi>
            <ListItem meta={dougaNews} />
          </StyledLi>
        )}
      </StyledUl>

      {garibenV.values === undefined &&
        isHide &&
        dougaNews.values === undefined && (
          <StyledP>現在視聴可能な番組はありません。</StyledP>
        )}
    </>
  )
}

export default List

List.propTypes = {
  /* 謎解き戦士！ガリベンガーVのメタ情報 */
  garibenV: PropTypes.shape({
    meta_id: PropTypes.number,
    values: PropTypes.object,
    thumbnail_url: PropTypes.string
  }),
  /* 謎解き戦士！ガリベンガーVの副音声メタ情報取得に使用 */
  snippetId: PropTypes.number,
  /* 楽しく学ぶ！世界動画ニュースのメタ情報 */
  dougaNews: PropTypes.shape({
    meta_id: PropTypes.number,
    values: PropTypes.object,
    thumbnail_url: PropTypes.string
  })
}
