import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import window from 'global';
import Cookie from 'js-cookie';
import webApp from '../../../utils/exdioWebAppUtils';
import Link from '../../../../../sketch-platform/ui/routing/Link';
import routes from '../../../../common/routes';
import { INFRA_LOGIN_TYPE } from '../../../../../constants/app';
import browserInfo from '../../../../../sketch-platform/utils/browserInfo';

/** アカウント情報 */
export default class Account extends Component {
  static propTypes = {
    setContent: PropTypes.func.isRequired
  };

  static defaultProps = {};

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.config = context.models.config.data;
    this.state = {
      userInfo: {},
      nickname: '',
      loginType: null
    };

    this.logout = this.logout.bind(this);
    this.goBack = this.goBack.bind(this);

    // DioPlayer_iOSかチェック
    this.isIosDioPlayer = navigator.userAgent
      .split(' ')
      .find((value) => value.match(/^DioPlayer_iOS/g))
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUserInfo().catch(e => webApp.utils.handleFalcorError(e, this.context));
    this.getNickName();
    this.loginCheck().catch(e => webApp.utils.handleFalcorError(e, this.context));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /** ユーザー情報取得 */
  getUserInfo() {
    const path = ['user', 'info'];
    return this.model.fetch([path]).then(result => {
      const userInfo = _.get(result, ['json', ...path]) || null;
      if (this._isMounted) {
        this.setState({ userInfo });
      }
    });
  }

  /** ニックネームの取得 */
  getNickName() {
    const api = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 'https://st-wws.tvasahi.jp//mps/bangumi/gariben_daigaku/api/getnickname.php'
      : 'https://wws.tv-asahi.co.jp/mps/bangumi/gariben_daigaku/api/getnickname.php'

    axios
      .get(api, { withCredentials: true })
      .then(function(res) {
        const nick_name = _.get(res, ['data', 'nick_name'])
        if (nick_name) {
          this.setState({ nickname: nick_name })
        }
      }.bind(this))
      .catch(function(err) {
        console.error(err);
      })
  }

  /** ログインアカウント取得(ログインチェック) */
  loginCheck() {
    const path = ['user', 'loginCheck'];
    return this.model.fetch([path]).then(result => {
      const loginCheck = _.get(result, ['json', ...path]) || null;
      const loginTypeId = _.get(loginCheck, ['login_type']);
      const loginType = Object.values(INFRA_LOGIN_TYPE).find(type => type.id === loginTypeId);
      if (this._isMounted) {
        this.setState({ loginType: loginType || INFRA_LOGIN_TYPE.TV_ASAHI });
      }
    });
  }

  setContent(content, e) {
    e.preventDefault();
    this.props.setContent(content);
  }

  renderGuideLinks() {
    let guideLinks = this.config.guide_links;
    if ( webApp.utils.isApp(this.context) ) {
      guideLinks = this.config.guide_links_app;
    }
    const guideLinkBlankCells = [];
    if (guideLinks.length % 3) {
      for (let i = 0; i < 3 - guideLinks.length % 3; i++) {
        guideLinkBlankCells.push(i);
      }
    }

    return (
      <ul className="c-mypageAccount-cont-guides-inBox">
        {guideLinks.map(link => {
          const className = link.className || '';
          return (
            <li key={link.title} className={`c-mypageAccount-cont-guides-inBox-btn ${className}`}>
              <Link
                href={(link.url) ? link.url.replace(':host', webApp.utils.dougaMvHost(this.context)) : null}
                route={routes[link.route]}
                params={link.params}
                className="c-mypageAccount-cont-guides-inBox-btn-link"
              >
                {link.title}
              </Link>
            </li>
          );
        })}
        {guideLinkBlankCells.map(e => (
          <li key={`blank-${e}`} className="c-mypageAccount-cont-guides-inBox-btn blank" />
        ))}
      </ul>
    );
  }

  /** ログアウト */
  logout(e) {
    e.preventDefault();
    webApp.utils.logout(this.context).then(() => {
      const route = webApp.utils.isApp(this.context) ? routes.app_mypage : routes.home;
      window.location.href = route.makePath();
    });
  }

  goBack(e) {
    e.preventDefault();
    this.context.history.goBack();
  }

  renderNotificationLink() {
    if (!webApp.utils.isApp(this.context)) return null;

    const className = 'c-mypageAccount-cont-personal-setting-inBox-btn-link';
    const label = '通知設定';
    const uid = Cookie.get('luid') || '';
    return (
      <li className="c-mypageAccount-cont-personal-setting-inBox-btn">
        <a className={className} href={`${this.config.dioapp.custom_schema}?type=pushsetting&uid=${uid}&cid=`}>{label}</a>
      </li>
    );
  }

  renderAppVersion() {
    if (!webApp.utils.isApp(this.context)) return null;

    const className = 'c-mypageAccount-cont-version-link';
    const label = 'アプリバージョン';
    const uid = Cookie.get('luid') || '';
    return (
      <div className="c-mypageAccount-cont-version">
        <a className={className} href={`${this.config.dioapp.custom_schema}?type=version&uid=${uid}&cid=`}>{label}</a>
      </div>
    );
  }

  renderLoginAccountType() {
    const { loginType } = this.state;
    if (!loginType) return null;
    return (
      <React.Fragment>
        <span className="c-mypageAccount-cont-personal-prof-info-acctype-accname" style={{ color: loginType.color }}>
          {loginType.label}
        </span>でログイン中
      </React.Fragment>
    );
  }

  render() {
    const { userInfo, nickname } = this.state;
    const isApp = webApp.utils.isApp(this.context);
    const routeMyPageAccountSetting = isApp ? routes.app_mypage_account_setting : routes.mypage_account_setting;
    const routeMyPageViewLicense = isApp ? routes.app_mypage_view_license : routes.mypage_view_license;
    const routeMyPageUpdateCredit = isApp ? routes.app_mypage_update_credit : routes.mypage_update_credit;
    const routeInformation = isApp ? routes.app_information : routes.information;
    const routeEditIcon = routes.edit_icon;
    const profileIcon = userInfo.img_url || '/images/exdio/renewal/prof_icon.png';

    // アプリの場合アイコン編集用にCONT_URLをここで生成して置かなければならない
    const rootUrl = `${window.location.protocol}//${window.location.host}`;
    let editIconUrl = `${rootUrl}${routeEditIcon.makePath({})}`;
    if (isApp) {
      const url = window.location.href;
      const uid = Cookie.get('luid') || '';
      const commonParam = `type=view&uid=${uid}&cid=&url=${url}`;
      // デバイス判定
      const device = browserInfo(navigator.userAgent, data => data);
      if (device.isIOS) {
        const scheme = `${this.config.dioapp.custom_schema}?${commonParam}`;
        editIconUrl = `${editIconUrl}?CONT_URL=${encodeURIComponent(scheme)}`;
      } else if (device.isAndroid) {
        const scheme = `intent://tv-asahi.co.jp?${commonParam}` +
          `#Intent;scheme=dioplayer;package=${this.config.dioapp.android_package};end"`;
        editIconUrl = `${editIconUrl}?CONT_URL=${encodeURIComponent(scheme)}`;
      }
    }

    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <div className="c-mypageAccount">
            {isApp
            ?<header className="c-mypageAccount-header">
              {(this.context.history && this.context.history.length > 1) ? (
                <a href="#" className="c-mypage-btnBack" onClick={this.goBack}>戻る</a>
              ) : (
                <Link route={routes.app_home} className="c-mypage-btnBack">戻る</Link>
              )}
              <h3 className="c-mypageAccount-header-title">アカウント情報</h3>
            </header>
            : null}
            <div className="c-mypageAccount-cont">
              <div className="c-mypageAccount-cont-personal">
                <div className="c-mypageAccount-cont-personal-prof">
                  <div className="c-mypageAccount-cont-personal-prof-pictBox">
                    <img
                      src={profileIcon}
                      alt="プロフィール画像"
                      className="c-mypageAccount-cont-personal-prof-pictBox-pict"
                    />
                    <Link href={editIconUrl} className="c-mypageAccount-cont-personal-prof-pictBox-edit">
                      編集する
                    </Link>
                  </div>
                  <div className="c-mypageAccount-cont-personal-prof-info">
                    <h4 className="c-mypageAccount-cont-personal-prof-info-name">{userInfo.member_name}</h4>
                    {nickname && <p className="c-mypageAccount-cont-personal-prof-info-nickname">{nickname}</p>}
                    <p className="c-mypageAccount-cont-personal-prof-info-mail">{userInfo.e_mail1}</p>
                    <p className="c-mypageAccount-cont-personal-prof-info-acctype">
                      {this.renderLoginAccountType()}
                    </p>
                  </div>
                </div>
                <div className="c-mypageAccount-cont-personal-setting">
                  <ul className="c-mypageAccount-cont-personal-setting-inBox">
                    <li className="c-mypageAccount-cont-personal-setting-inBox-btn">
                      <Link
                        route={routeMyPageAccountSetting}
                        className="c-mypageAccount-cont-personal-setting-inBox-btn-link"
                      >
                        アカウント設定
                      </Link>
                    </li>
                    {!this.isIosDioPlayer &&(
                      <li className="c-mypageAccount-cont-personal-setting-inBox-btn">
                        <Link
                          route={routeMyPageViewLicense}
                          className="c-mypageAccount-cont-personal-setting-inBox-btn-link"
                        >
                          契約中プラン
                        </Link>
                      </li>
                    )}
                    <li className="c-mypageAccount-cont-personal-setting-inBox-btn">
                      <Link
                        route={routeMyPageUpdateCredit}
                        className="c-mypageAccount-cont-personal-setting-inBox-btn-link"
                      >
                        お支払い方法
                      </Link>
                    </li>
                    {this.renderNotificationLink()}
                    <li className="c-mypageAccount-cont-personal-setting-inBox-btn">
                      <Link route={routeInformation} className="c-mypageAccount-cont-personal-setting-inBox-btn-link">
                        お知らせ
                      </Link>
                    </li>
                    <li key="acount-blank-0" className="c-mypageAccount-cont-personal-setting-inBox-btn blank" />
                    <li key="acount-blank-1" className="c-mypageAccount-cont-personal-setting-inBox-btn blank" />
                  </ul>
                </div>
              </div>

              <div className="c-mypageAccount-cont-guides">{this.renderGuideLinks()}</div>

              {this.renderAppVersion()}

              <div className="c-mypageAccount-cont-logout">
                <a href="#" className="c-mypageAccount-cont-logout-link" onClick={this.logout}>
                  ログアウト
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
