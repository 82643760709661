import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
export default class Template1 extends Component {
  constructor(props, context) {
    super(props, context);
  }

  static propTypes = {
    metadata: PropTypes.object
  };

  render() {
    return (
      <div className="template1">
        <div className="template_name">
          {"テンプレート1"}
        </div>
        <div className="meta_name">
        {this.props.metadata.name}
        </div>
      </div>
    );
  }
}
