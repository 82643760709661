import safeTimeout from '../../common/safeTimeout';

export function updateTag(tagName, keyName, keyValue, attrName, attrValue) {
  const node = document.head.querySelector(
    `${tagName}[${keyName}="${keyValue}"]`,
  );
  if (node && node.getAttribute(attrName) === attrValue) return;

  // Remove and create a new tag in order to make it work with bookmarks in Safari
  if (node) {
    node.parentNode.removeChild(node);
  }
  if (typeof attrValue === 'string') {
    const nextNode = document.createElement(tagName);
    nextNode.setAttribute(keyName, keyValue);
    nextNode.setAttribute(attrName, attrValue);
    document.head.appendChild(nextNode);
  }
}

export function updateMeta(name, content) {
  updateTag('meta', 'name', name, 'content', content);
}

export function updateCustomMeta(property, content) {
  updateTag('meta', 'property', property, 'content', content);
}

export function updateLink(rel, href) {
  updateTag('link', 'rel', rel, 'href', href);
}

export function runTransition(el, options, cb) {
  const beforeStyle = options.before;
  if (!!beforeStyle) {
    el.style.transitionDuration = null;
    Object.assign(el.style, beforeStyle);
  }
  el.style.transitionDuration = `${options.duration || 300}ms`;

  let afterStyle = Object.assign({}, options.after || options);
  delete afterStyle.duration;
  delete afterStyle.before;

  const endEventNames = ['webkitTransitionEnd', 'transitionend', 'oTransitionEnd', 'mozTransitionEnd']
  let _emit = false;
  const callback = (e) => {
    if (_emit) return;
    _emit = true;
    if (el) {
      endEventNames.map((eventName) => {
        el.removeEventListener(eventName, callback, false);
      });
    }
    if (cb) cb();
  };
  endEventNames.map((eventName) => {
    el.addEventListener(eventName, callback, false)
  });
  safeTimeout(() => {
    Object.assign(el.style, afterStyle);
  }, 5);
};

export function getRect(node) {
  return node && node.getBoundingClientRect();
};

export function getWindowRect() {
  const firstEl = document.getElementsByTagName("body")[0];
  const width = window.innerWidth || document.documentElement.clientWidth || firstEl.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight|| firstEl.clientHeight;
  return {
    top: 0,
    bottom: height,
    left: 0,
    right: width,
    width,
    height
  }
};

export function isElementInViewport(node) {
  return isElementInRegion(getRect(node), getWindowRect());
};

export function isElementInRegion(rect, region) {
  const rectX = rect.left + (rect.right - rect.left)/2;
  const rectY = rect.top + (rect.bottom - rect.top)/2;
  return rect.left !== rect.right && region.left !== region.right && (rectX >= region.left&& rectX <= region.right&& rectY >= region.top && rectY <= region.bottom);
};

export function getScrollTop() {
  return window.pageYOffset;
};

export function getDistanceToTopOfElement(node) {
  return getRect(node).top - getWindowRect().height;
};

export function getDistanceToBottomOfElement(node) {
  const scrollTop = getScrollTop();
  const windowRect = getWindowRect();
  const rect = getRect(node);
  const scrollHeight = scrollTop + windowRect.height;
  return rect.top + scrollTop + rect.height - scrollHeight;
};

export function getTotalDocumentSize() {
  return {
    height: document.height !== undefined ? document.height : document.body.offsetHeight,
    width: document.width !== undefined ? document.width : document.body.offsetWidth
  };
};
