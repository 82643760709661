import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import styled from 'styled-components'
import { size } from 'lodash'
import { PANEL_ID } from './config'

/** components */
import ToggleImgButton from './ToggleImgButton'
import BuzzImgButton from './BuzzImgButton'

/** style */
import { mediaSp } from './styles'

const Character = (
  {
    sliderItems = [],
    selectedTabId = '',
    setSelectedTags = () => {},
    initialSlide = 0,
    ...props
  },
  context
) => {
  const sliderRef = useRef(null)

  /** スライダー設定 */
  const sliderSetting = {
    dots: false,
    infinite: false,
    speed: 400,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: true,
    initialSlide: initialSlide || 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      }
    ]
  }

  /** タグが変更されたとき履歴とパラメータの書き換えをする */
  const onChangeTags = (sliderItem = {}) => {
    const { id = null, name = '' } = sliderItem
    if (name) setSelectedTags([name])

    const { routeHandler } = context
    const { query, params } = routeHandler
    const { slug } = params

    /**
     * 履歴の追加、パスパラメータの書き換え
     * // NOTE
     * contextが書き換えられると再レンダリングされるため
     * context.history.replaceは使えない
     */
    const path = new URLSearchParams({ ...query, id }).toString()
    const url = `${slug}?${path}`
    window.history.replaceState(null, '', url)
  }

  useEffect(() => {
    if (selectedTabId === PANEL_ID.CHARACTER && sliderRef.current) {
      sliderRef.current.slickGoTo(initialSlide)
    }
  }, [selectedTabId])

  return (
    <StyledDiv {...props}>
      <Slider ref={sliderRef} {...sliderSetting}>
        {size(sliderItems) > 0 &&
          sliderItems.map((item, index) => {
            const buttonProps = {
              key: item.id,
              index,
              ...item,
              onClick: () => onChangeTags(item)
            }
            if (typeof item.img === 'string') {
              return <BuzzImgButton {...buttonProps} />
            }
            return <ToggleImgButton {...buttonProps} />
          })}
      </Slider>
    </StyledDiv>
  )
}

export default Character

Character.propTypes = {
  sliderItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      category: PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string,
        img: PropTypes.string
      }),
      imgName: PropTypes.string,
      img: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          on: PropTypes.string,
          off: PropTypes.string
        })
      ])
    })
  ),
  selectedTabId: PropTypes.string,
  setSelectedTags: PropTypes.func,
  initialSlide: PropTypes.number
}

Character.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}

const StyledDiv = styled.div`
  .slick {
    &-track {
      display: flex;
    }

    &-slide {
      padding: 0 10px;
      display: block;
      position: relative;

      &:not(:first-child) {
        margin-left: 30px;

        @media ${mediaSp} {
          margin-left: 0;
        }
      }
    }

    &-arrow {
      margin-top: -22px;
      width: 48px;
      height: 48px;
      box-shadow: 0px 3px 0px 0px #fcdb29;
      border-radius: 90px;
      position: absolute;
      top: 50%;
      z-index: 10;
      transform: none;
      transition: box-shadow 0.2s, transform 0.2s;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 0px 0px #fcdb29;
        transform: translateY(3px);
      }

      &::before {
        content: none;
      }
    }

    &-prev {
      background: #ffeb00
        url('/douga_mv/shinchan/svod/images/common/slider-arrow-l.svg')
        no-repeat center / auto;
      left: -24px;
      right: auto;

      &:hover,
      &:focus {
        background: #ffeb00
          url('/douga_mv/shinchan/svod/images/common/slider-arrow-l.svg')
          no-repeat center / auto;
      }
    }

    &-next {
      background: #ffeb00
        url('/douga_mv/shinchan/svod/images/common/slider-arrow-r.svg')
        no-repeat center / auto;
      right: -24px;
      left: auto;

      &:hover,
      &:focus {
        background: #ffeb00
          url('/douga_mv/shinchan/svod/images/common/slider-arrow-r.svg')
          no-repeat center / auto;
      }
    }

    &-disabled {
      display: none !important;
    }
  }
`
