import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledListItem } from './style'

/** シェアボタン Line */
const Line = ({ isApp = false, linkUrl = '', ...props }) => {
  const windowOpenOption = [
    'linewindow',
    'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes'
  ]

  return (
    <StyledListItem
      src={'/images/exdio/renewal/share_btn_line.svg'}
      alt={'LINE'}
      isApp={isApp}
      linkUrl={linkUrl}
      windowOpenOption={windowOpenOption}
      {...props}
    />
  )
}

export default Line

Line.prototype = {
  isApp: PropTypes.bool,
  linkUrl: PropTypes.string
}
