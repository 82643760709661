import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** tv asahi idの削除 */
export default class AccountDelete extends Component {
  static propTypes = {};
  static defaultProps = {};
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};

    this.onConfirm = this.onConfirm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onConfirm() {}

  onSubmit() {}

  onCancel() {}

  goBack(e) {
    e.preventDefault();
    this.context.history.goBack();
  }

  render() {
    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <div className="c-mypageAccount">
            <header className="c-mypageAccount-header">
              <a href="#" className="c-mypage-btnBack alwaysOn" onClick={this.goBack}>
                戻る
              </a>
              <h3 className="c-mypageAccount-header-title">tv asahi idの削除</h3>
            </header>
            <div className="c-mypageAccount-cont">
              <div className="c-mypageAccount-cont-personal">
                <div className="c-mypageAccountEditDeleteID">
                  <div className="c-mypageAccountEditDeleteID-inBox">
                    <h4 className="c-mypageAccountEditDeleteID-inBox-title">tv asahi id の削除にあたっての注意事項</h4>
                    <p className="c-mypageAccountEditDeleteID-inBox-text">
                      tv asahi idの削除は元に戻せません。削除を行うと、tv asahi idに登録した情報を削除します。<br />
                      よろしければ理由をご入力ください。
                    </p>
                    <textarea rows="8" className="c-inputTextarea" />
                  </div>
                  <div className="c-mypageAccountEditDeleteID-btnSave">
                    <a href="#" className="c-mypageAccountEditDeleteID-btnSave-link" onClick={this.onConfirm}>
                      削除する
                    </a>
                  </div>

                  <div className="c-modal m-deleteCheck">
                    <div className="c-modal-inBox">
                      <div className="c-modal-inBox-close" />
                      <div className="c-modal-inBox-cont">
                        <div className="deleteCheck">
                          <p className="deleteCheck-text">
                            削除するとキャンセルできません。<br />本当に削除していいですか？
                          </p>
                          <ul className="deleteCheck-inBox">
                            <li className="deleteCheck-inBox-btnSubmit">
                              <a href="#" className="deleteCheck-inBox-btnSubmit-link" onClick={this.onSubmit}>
                                削除する
                              </a>
                            </li>
                            <li className="deleteCheck-inBox-btnBack">
                              <a href="#" className="deleteCheck-inBox-btnBack-link" onClick={this.onCancel}>
                                戻る
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
