import React, { Component } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import LoginOrSignup from '../../../../common/components/renewal/LoginOrSignup'
import { PURCHASE_CONTENTS, LOGIN_CONTENTS } from '../../../../../constants/app'
import Steps from './Steps'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'

/** 購入ページ:ログイン */
export default class Login extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number).isRequired,
    purchased: PropTypes.arrayOf(PropTypes.object).isRequired,
    setContent: PropTypes.func.isRequired,
    emitSetState: PropTypes.func.isRequired,
    getMaterial: PropTypes.func.isRequired,
    getBelonging: PropTypes.func.isRequired
  }

  static defaultProps = {}

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data

    this.state = {
      loginContent: LOGIN_CONTENTS.LOGIN
    }

    this.onChangeLoginContent = this.onChangeLoginContent.bind(this)
    this.goNext = this.goNext.bind(this)
  }

  onChangeLoginContent(content) {
    this.setState({ loginContent: content })
  }

  goNext() {
    window.location.href = routes.purchase.makePath(
      {},
      { content: 'after_login' }
    )
  }

  render() {
    const { loginContent } = this.state
    let signupUrl = webApp.utils.isApp(this.context)
      ? routes.app_regist_member
      : routes.regist_member
    signupUrl = encodeURIComponent(
      webApp.utils.rootUrl() +
        signupUrl.makePath(
          {},
          {
            redirect:
              webApp.utils.rootUrl() +
              routes.purchase.makePath({}, { content: 'after_login' })
          }
        )
    )

    return (
      <div className="c-login">
        <div className="c-login-wrapper">
          <Steps current={PURCHASE_CONTENTS.LOGIN} />

          {LOGIN_CONTENTS.LOGIN === loginContent && (
            <p className="c-login-flowwrapper-text">
              アカウントをお持ちでない方は
              <span>
                <a
                  href="#"
                  onClick={() =>
                    this.onChangeLoginContent(LOGIN_CONTENTS.SIGNUP)
                  }
                >
                  新規アカウント登録
                </a>
              </span>
              をしてください。
            </p>
          )}
          {LOGIN_CONTENTS.SIGNUP === loginContent && (
            <p className="c-login-flowwrapper-text">
              既にアカウントをお持ちの方は、
              <span>
                <a
                  href="#"
                  onClick={() =>
                    this.onChangeLoginContent(LOGIN_CONTENTS.LOGIN)
                  }
                >
                  ログイン
                </a>
              </span>
              してください。
            </p>
          )}
        </div>

        <LoginOrSignup
          content={loginContent}
          onChangeContent={this.onChangeLoginContent}
          onLoggedIn={this.goNext}
          callbackUrl={signupUrl}
        />
      </div>
    )
  }
}
