import styled, { css } from 'styled-components'
import { mediaQuery } from '../../../../style'

export const StyledUl = styled.ul`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  overflow-y: scroll;

  ${mediaQuery()} {
    grid-template-columns: 1fr 1fr;
  }
`

export const StyledLi = styled.li``

export const StyledButton = styled.button`
  padding: 0 10px;
  background: none;
  border-width: 0;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
`
