import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../../../utils/exdioWebAppUtils'

/* style */
import { StyledDiv, StyledA, StyledImg } from './style'

/** SailGp スライダー内 画像部分 コンポーネント */
const SailSlideItemImage = ({
  meta,
  product,
  course,
  onClickThumbnail,
  ...props
}) => {
  /** サムネイル画像 */
  const thumbnail = () => {
    let thumbnailUrl = null
    let thumbnailAlt = null
    if (meta) {
      thumbnailUrl = meta.thumbnail_url
      const [title, subTitle] = webApp.utils.titles(meta, product, course)
      thumbnailAlt = `${title} ${subTitle}`
    } else if (product) {
      thumbnailUrl = product.thumbnail_url
      thumbnailAlt = product.name
    } else if (course) {
      thumbnailUrl = course.thumbnail_url
      thumbnailAlt = course.name
    }
    return [thumbnailUrl || config.default_thumbnail, thumbnailAlt]
  }
  const [thumbnailUrl, thumbnailAlt] = thumbnail()

  const handleClick = (propFunc, e) => {
    e.preventDefault()
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation()
      propFunc(e)
    }
  }

  return (
    <StyledDiv>
      <StyledA href="#" onClick={(e) => handleClick(onClickThumbnail, e)}>
        <StyledImg src={thumbnailUrl} />
      </StyledA>
    </StyledDiv>
  )
}

export default SailSlideItemImage

SailSlideItemImage.propTypes = {
  // 主情報
  /** メタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  /** パック商品 */
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired
  }),
  /** 月額見放題パックコース */
  course: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired
  }),

  // イベントハンドラ
  onClickThumbnail: PropTypes.func,
  /** 強制的にタイトルを指定する場合に設定 */
  title: PropTypes.string,
  /** 強制的にサムネイル画像を指定する場合に設定 */
  thumbnail: PropTypes.string
}

SailSlideItemImage.defaultProps = {
  meta: null,
  product: null,
  course: null,
  onClickThumbnail: null
}
