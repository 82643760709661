import styled from 'styled-components'
import { mediaQuery } from '../../../style'

export const StyledDiv = styled.div`
  max-width: 480px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  line-height: 0;

  ${mediaQuery()} {
    max-width: 100%;
    border: 0;
    border-radius: 0;
  }
`
