import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import {
  META_SCHEMA_ID,
  SVOD_DELIVERY_PATTERN,
  EPISODE_DISPLAY_MODE,
  PRODUCT_SCHEMA
} from '../../../../../../constants/app'
import webApp from '../../../../utils/exdioWebAppUtils'

/* style */
import {
  StyledDiv1,
  StyledImg,
  StyledA,
  StyledDiv2,
  StyledSpan1,
  StyledSpan2,
  StyledConvertSvg
} from './style'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 番組リスト要素コンポーネント */
const LogirlProgramItem = (
  { meta, product, course, title, thumbnail, onClickThumbnail, ...proos },
  context
) => {
  const config = context.models.config.data
  const model = context.falcorModel.batch(100)
  const [howToPlay, setHowToPlay] = useState(null)

  const isMounted = useRef(false)
  const isFree = useRef(null)
  const isNotFree = useRef(null)
  const isSeries = useRef(null)
  const isSeason = useRef(null)

  useEffect(() => {
    isMounted.current = true
    getHowToPlays()
    setupFlags(meta, product, course)
  }, [])

  const setupFlags = (meta, product, course) => {
    if (!meta) return
    const metaSchemaId = meta.meta_schema_id
    const displayMode = webApp.utils.getDisplayMode(meta, product, course)
    switch (displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        isFree.current = true
        break
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.UNKNOWN:
      default:
        isFree.current = false
        break
    }
    isNotFree.current = !isFree.current
    isSeries.current =
      META_SCHEMA_ID.SERIES === metaSchemaId ||
      META_SCHEMA_ID.LIVE_SERIES === metaSchemaId
    isSeason.current =
      META_SCHEMA_ID.SEASON === metaSchemaId ||
      META_SCHEMA_ID.LIVE_SEASON === metaSchemaId
  }

  /** タイトル */
  const getTitle = () => {
    if (title) return [title, '']
    return webApp.utils.titles(meta, product, course)
  }

  /** サムネイル画像 */
  const getThumbnail = () => {
    if (thumbnail)
      return [webApp.utils.customSizeImageUrl(thumbnail, 'medium'), '']

    let thumbnailUrl = null
    let thumbnailAlt = null
    if (meta) {
      thumbnailUrl = meta.thumbnail_url
      const [titleMain, titleSub] = getTitle()
      thumbnailAlt = `${titleMain} ${titleSub}`
    } else if (product) {
      thumbnailUrl = product.thumbnail_url
      thumbnailAlt = product.name
    } else if (course) {
      thumbnailUrl = course.thumbnail_url
      thumbnailAlt = course.name
    }

    /* 読み込む画像サイズを変更する */
    thumbnailUrl = webApp.utils.customSizeImageUrl(thumbnailUrl, 'medium')

    return [thumbnailUrl || config.default_thumbnail, thumbnailAlt]
  }

  /** 価格 */
  const renderCoin = () => {
    if (meta) {
      if (isFree.current) {
        return <div className="c-card-inBox-meta-price free">無料</div>
      } else if (isNotFree.current) {
        if (!howToPlay) {
          return null
        }

        const thisHowToPlay = _.get(howToPlay, [meta.meta_id])

        // 単話TVOD価格
        const howToPlayProducts = _.get(thisHowToPlay, ['products']) || []
        const singleStoryProduct = howToPlayProducts.find(
          (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
        )
        const productPrice = _.get(singleStoryProduct, ['active_pricing'])

        // 月額見放題パックに含まれる場合
        if (!thisHowToPlay.courses) return
        const isInCourse = !!thisHowToPlay.courses.length
        let coursePrice = null

        if (isInCourse) {
          coursePrice = _.get(thisHowToPlay, [
            'courses',
            0,
            'active_pricing',
            'price'
          ])
        }

        let displayProductPrice = null
        let displayCoursePrice = null
        let isFreeInCourse = false
        if (isInCourse) {
          if (meta.values.svod_delivery_pattern == SVOD_DELIVERY_PATTERN.FREE) {
            isFreeInCourse = true
          } else if (
            meta.values.svod_delivery_pattern == SVOD_DELIVERY_PATTERN.AUTH_FREE
          ) {
            // ログイン時無料設定がされていた場合はログイン済みなら無料
            const isLoggedIn = webApp.utils.isLoggedIn(context)
            if (isLoggedIn) {
              isFreeInCourse = true
            }
          }
        }

        if (isFreeInCourse) {
          return <div className="c-card-inBox-meta-price free">無料</div>
        } else {
          if (productPrice && productPrice.price !== 0) {
            displayProductPrice = productPrice.price
          }
          if (coursePrice && coursePrice.price !== 0) {
            displayCoursePrice = (
              <React.Fragment>
                {displayPrice}月額{coursePrice}円
              </React.Fragment>
            )
          }
        }

        let displayPrice = null
        if (displayCoursePrice) {
          // コースの金額が設定されている場合は、コースのみ表示するか、コースとTVOD有料(0円以上)の２パターンが表示される
          // if (displayProductPrice) {
          //   displayPrice = (<React.Fragment>{displayProductPrice}<span>or</span></React.Fragment>);
          // }
          // displayPrice = (<React.Fragment>{displayPrice}{displayCoursePrice}</React.Fragment>);

          //月額料金表示のみの場合はコインアイコンを表示しない
          return (
            <React.Fragment>
              {displayProductPrice === null ? (
                <div className="c-card-inBox-meta-price c-card-inBox-meta-price-in-course hide-coin">
                  {displayCoursePrice}
                </div>
              ) : (
                <div className="c-card-inBox-meta-price c-card-inBox-meta-price-in-course">
                  {displayProductPrice}
                  <span>or</span>
                  {displayCoursePrice}
                </div>
              )}
            </React.Fragment>
          )
        } else {
          // 月額見放題パックに含まれない場合は
          // TVOD無料(0円とTVOD有料の2パターンが表示される
          displayPrice = displayProductPrice
          if (productPrice && productPrice.price === 0) {
            return <div className="c-card-inBox-meta-price free">無料</div>
          }

          return <div className="c-card-inBox-meta-price">{displayPrice}</div>
        }
      }
    } else if (product) {
      const activePrice = _.get(product, ['active_pricing'])
      return (
        activePrice && (
          <div className="c-card-inBox-meta-price ">{activePrice.price}</div>
        )
      )
    } else if (course) {
      const activePrice = _.get(course, ['active_pricing'])
      return (
        activePrice && (
          <div className="c-card-inBox-meta-price ">
            月額{activePrice.price}円
          </div>
        )
      )
    }
    return null
  }

  /** 価格情報取得 */
  const getHowToPlays = () => {
    const metaId = _.get(meta, ['meta_id'])
    if (!metaId) return Promise.reject()

    const path = [['meta', 'howToPlay', false, metaId]]
    return model.fetch(path).then((result) => {
      const howToPlay =
        _.get(result, ['json', 'meta', 'howToPlay', false]) || {}
      if (isMounted) {
        setHowToPlay(howToPlay)
      }
    })
  }

  const handleClick = (propFunc, e) => {
    e.preventDefault()
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation()
      propFunc(e)
    }
  }

  const [mainTitle, subTitle] = getTitle()
  const [thumbnailUrl, thumbnailAlt] = getThumbnail()

  const isLive = _.get(meta, ['values', 'clipping_select_media_type'])

  return (
    <StyledA href="#" onClick={(e) => handleClick(onClickThumbnail, e)}>
      <StyledDiv1>
        <StyledImg src={thumbnailUrl} alt={thumbnailAlt} loading="lazy" />
      </StyledDiv1>
      <StyledDiv2>
        {mainTitle}
        <br />
        {subTitle}
        <StyledSpan1>{renderCoin()}</StyledSpan1>
        {isLive && (
          <StyledSpan2>
            <StyledConvertSvg
              src="/images/exdio/renewal/logirl/icon/live.svg"
              alt=""
            />
            LIVE
          </StyledSpan2>
        )}
      </StyledDiv2>
    </StyledA>
  )
}

export default LogirlProgramItem

LogirlProgramItem.propTypes = {
  // 主情報
  /** メタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  /** パック商品 */
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired
  }),
  /** 月額見放題パックコース */
  course: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired
  }),

  // 副情報
  /** 有料単話メタHowToPlay */
  howToPlay: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    )
  }),

  // イベントハンドラ
  // 各onClickは引数に(event)を渡して呼び出される
  onClick: PropTypes.func,
  onClickThumbnail: PropTypes.func,
  onClickCaption: PropTypes.func,
  onClickTitle: PropTypes.func,
  /** 削除時callback. 設定されている場合のみ削除ボタンを表示する. */
  onClickDelete: PropTypes.func,
  /** 再生時callback. 設定されている場合のみ再生ボタンを表示する. */
  onClickPlay: PropTypes.func,

  // 表示制御
  /** NEWマークを表示するか */
  showNew: PropTypes.bool,
  /** チェックマークを表示するか */
  showChecked: PropTypes.bool,
  /** 視聴期限(あとn日)を表示するか */
  showRemaining: PropTypes.bool,
  /** あらすじ(長)を表示するか */
  showCaption: PropTypes.bool,
  /** 配信日を表示するか */
  showDelivery: PropTypes.bool,
  /** 配信期限を表示するか */
  showDeadLine: PropTypes.bool,
  /** コインを表示するか */
  showCoin: PropTypes.bool,
  /** コイン非表示に無料を表示するか */
  showFreeCoinOnly: PropTypes.bool,
  /** (最新話)を表示するか */
  showLatest: PropTypes.bool,
  /** サムネ下部ラベル文言を表示するか */
  showBottomLabel: PropTypes.bool,
  /** 見放題パックアイコンを表示するか */
  showInCourse: PropTypes.bool,
  /** サブタイトルのみを表示するか */
  onlySubTitle: PropTypes.bool,
  /** サブタイトルを改行して表示するか */
  breakSubTitle: PropTypes.bool,
  /** 配信日フォーマット */
  deliveryEndFormat: PropTypes.string,
  /** 配信期限を配信日より優先して表示するか */
  showDeliveryEndPriorToStart: PropTypes.bool,
  /** 番組へのリンクとして表示するか */
  programLink: PropTypes.bool,

  /** 強制的にタイトルを指定する場合に設定 */
  title: PropTypes.string,
  /** 強制的にサムネイル画像を指定する場合に設定 */
  thumbnail: PropTypes.string
}

LogirlProgramItem.defaultProps = {
  meta: null,
  product: null,
  course: null,

  onClick: null,
  onClickThumbnail: null,
  onClickCaption: null,
  onClickTitle: null,
  onClickDelete: null,
  onClickPlay: null,

  showNew: true,
  showChecked: false,
  showRemaining: false,
  showCaption: false,
  showDelivery: false,
  showDeadLine: false,
  showCoin: false,
  showFreeCoinOnly: false,
  showLatest: false,
  showBottomLabel: false,
  showInCourse: false,
  onlySubTitle: false,
  breakSubTitle: false,
  deliveryEndFormat: null,
  showDeliveryEndPriorToStart: false,
  programLink: false
}

LogirlProgramItem.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  routeHandler: PropTypes.object
}
