import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import useMediaQuery from '../../../../../hooks/useMediaQuery'
import Heading from '../Heading'
import Footer from '../../../../../common/components/Footer'
import routes from '../../../../../common/routes'
import Breadcrumbs from '../Breadcrumbs'
import Sns from '../Sns'
import Menu from '../Menu'

// pc
import MainSlider from './MainSlider'
import SwitchableHirameki from '../SwitchableHirameki'
// sp
import SpMainSlider from './SpMainSlider'
import SpSwitchableHirameki from '../SpSwitchableHirameki'

/* style */
import { GlobalStyle, StyledDiv1, StyledDiv2 } from './style'

/** ヒラメキのタネ番組ページ テンプレート */
const ProgramHirameki = ({ episodes, seriesId, seasonId, meta }, context) => {
  const pageUrl =
    webApp.utils.rootUrl() + routes.program.makePath({ seriesId, seasonId })
  const isSmp = useMediaQuery()
  const params = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? { seriesId: '14415', seasonId: '14414' }
    : { seriesId: '35996', seasonId: '35995' }

  // snsコンポーネントへ渡す配列
  const twitter = {
    alt: 'twitter',
    href: `https://twitter.com/share?url=${pageUrl}&text=%E3%83%92%E3%83%A9%E3%83%A1%E3%82%AD%E3%81%AE%E3%82%BF%E3%83%8D%EF%BD%9C%E3%83%86%E3%83%AC%E6%9C%9D%E5%8B%95%E7%94%BB`,
    img: 'https://wws.tv-asahi.co.jp/douga_mv/minato/images/tw.png'
  }
  const facebook = {
    alt: 'facebook',
    href: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`,
    img: 'https://wws.tv-asahi.co.jp/douga_mv/minato/images/fb.png'
  }
  const line = {
    alt: 'line',
    href: `https://line.me/R/msg/text/?ヒラメキのタネ｜テレ朝動画%0D%0A${pageUrl}`,
    img: 'https://wws.tv-asahi.co.jp/douga_mv/minato/images/line.jpg'
  }
  const snsPc = [twitter, facebook]
  const snsSp = [...snsPc, line]

  /** pcページ表示 */
  const renderPcPage = () => {
    const slideEpisodes = episodes.slice(0, 5)
    const disp_order = _.get(meta, ['values', 'disp_order'])
    const top = { seriesId, seasonId }
    const breadcrumbsItems = [top]

    return (
      <>
        <GlobalStyle />
        <StyledDiv1>
          <div>
            <Heading params={params} />
            <Breadcrumbs items={breadcrumbsItems} />
          </div>

          {slideEpisodes.length > 0 && (
            <MainSlider episodes={slideEpisodes} rootMetas={slideEpisodes} />
          )}

          <Sns items={snsPc} />

          <Menu />

          <SwitchableHirameki
            episodes={episodes}
            rootMetas={episodes}
            disp_order={disp_order}
          />
        </StyledDiv1>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Kaisei+Opti:wght@400;500;700&display=swap');
        </style>
        <Footer />
      </>
    )
  }

  /** spページ表示 */
  const renderSpPage = () => {
    const slideEpisodes = episodes.slice(0, 5)
    const disp_order = _.get(meta, ['values', 'disp_order'])
    const top = { seriesId, seasonId }

    return (
      <>
        <GlobalStyle />
        <StyledDiv2>
          <Heading isSmp params={top} />

          {slideEpisodes.length > 0 && (
            <SpMainSlider
              episodes={slideEpisodes}
              rootMetas={slideEpisodes}
              showNew
              showChecked
              onlySubTitle
            />
          )}

          <Sns items={snsSp} />
          <Menu isSmp />

          <SpSwitchableHirameki
            episodes={episodes}
            rootMetas={episodes}
            disp_order={disp_order}
          />
        </StyledDiv2>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Kaisei+Opti:wght@400;500;700&display=swap');
        </style>
        <Footer />
      </>
    )
  }

  return isSmp ? renderSpPage() : renderPcPage()
}

export default ProgramHirameki

ProgramHirameki.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  /** パック販売情報 */
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      original_price: PropTypes.number,
      active_pricing: PropTypes.shape({
        price: PropTypes.number,
        unit: PropTypes.string
      })
    })
  )
}

ProgramHirameki.defaultProps = {
  seriesId: '',
  seasonId: '',
  episodes: [],
  products: []
}

ProgramHirameki.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  falcorModel: PropTypes.object
}
