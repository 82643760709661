import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import window from 'global'
import NotFound from '../../../../generic/components/errors/NotFound'
import { META_SCHEMA_ID, PUBLISH_STATUS } from '../../../../../constants/app'
import webApp from '../../../utils/exdioWebAppUtils'
import namespace from '../../../../../common/namespace'

import redirectConfig from '../../../redirect.config.json' // アプリからブラウザへのリダイレクトに使用

const { sprintf } = require('sprintf-js')

/** 月額見放題ページ */
export default class PlanContent extends Component {
  static propTypes = {
    slug: PropTypes.string
  }

  static defaultProps = {
    slug: ''
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  static getPath(_models, _options, props) {
    return ['courseBySlug', props.slug]
  }

  static getPrefetchPaths(models, options, props) {
    return [PlanContent.getPath(models, options, props)]
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const course = _.get(prefetchResult, [
      'json',
      ...PlanContent.getPath(models, options, props)
    ])
    if (!course) return { title: '', thumbnail: '', description: '' }
    const title = course.name
    const thumbnail = course.thumbnail_url
    const { description } = course
    return { title, thumbnail, description }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    const path = PlanContent.getPath({}, {}, props)
    const course = this.model.getSync(path) || {}

    this.state = {
      course,
      right: {},
      rightMetas: [],
      season: null,
      series: null,
      episodes: [],
      isNotFound: false,
      status: {
        isPurchseAvailable: course.active_pricing != null,
        isPurchased: false,
        isNotPurchased: false
      },
      loaded: false
    }
  }

  async componentDidMount() {
    this._isMounted = true

    // リダイレクトが必要な場合はリダイレクトを実行
    this.redirect2Browser()

    const { slug } = this.props
    if (!slug) {
      this.setState({ isNotFound: true })
      return
    }

    const { course, isNotFound, season } = await this.getCourse().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    // headタグのメタ情報を書き換えるだけで、setstateしないので、awaitしない
    this.updateMetaTags(course)

    //クレヨンしんちゃんplanページへuergramタグ追加
    this.addUsergramTagShinchan()

    // スキーマに設定したシーズンを取得
    const series = await this.getSeries(season).catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
    // ライセンス取得
    const status = (await this.getBelonging(course)) || this.state.status
    // 権利を取得
    const right = await this.getRight(course).catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
    // rightに紐付くメタ(すべてでは無く権利に紐つけたメタのみ)
    const rightMetas = await this.getRightMetas(right).catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    // rightに紐付くメタすべてを取得。あなたへのオススメ表示で必要
    // 時間がかかるのでこれだけ非同期通信
    this.getEpisodes(rightMetas).catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    // getSyncだとfalcorのキャッシュが入ってないとundefinedになるっぽいので、ここで再設定
    status.isPurchseAvailable = course && course.active_pricing != null

    this.setState({
      course,
      series,
      season,
      isNotFound,
      status,
      right,
      rightMetas,
      loaded: true
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    const { slug } = this.props
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    const usergramIds = [
      'usergram-member-shinchan-plan-app',
      'usergram-common-shinchan-plan-app'
    ]
    if (slug === 'shinchan' && isLoggedIn === true) {
      webApp.utils.removeUserGram(usergramIds)
    }
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags(course) {
    const tmpCourse = course || {}

    // タイトルタグの更新
    const title = sprintf(this.config.title_template, tmpCourse.name)
    webApp.utils.updateTitle(title)

    // メタタグの更新
    const { copyright } = this.config
    const description = tmpCourse.description || this.config.description
    const keywords = this.config.keywords
    const rootUrl = `${window.location.protocol}//${window.location.host}`
    const ogImage =
      _.get(course, ['thumbnail_url']) ||
      sprintf('%s/images/exdio/%s', rootUrl, this.config.og_image)
    const url = window.location.href
    const regularUrl = url.replace(/\?.*$/, '')
    const removeAppUrl = regularUrl.replace('/app', '')

    // GTMの更新
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: ogImage },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'mixi:image', content: ogImage },
        { property: 'og:image', content: ogImage },
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: removeAppUrl }]
    }
    webApp.utils.updateMeta(metaTags)
    webApp.utils.updateDataLayer(gtmTags)
  }

  /** クレヨンしんちゃんplanページ　 USERGRAMタグ追加 */
  addUsergramTagShinchan() {
    const { slug } = this.props
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)

    if (isLoggedIn === false) {
      return
    }

    if (slug !== 'shinchan') {
      return
    }

    let member_id = ''
    const cookies = document.cookie
    const cookiesArr = cookies.split(';')

    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split('=')
      if (cookieArr[0] == ' CBM_ID') {
        member_id = cookieArr[1]
      }
    }

    let browseUserGram = ''
    browseUserGram +=
      '<script type="text/javascript" id="usergram-member-shinchan-plan-app">'
    browseUserGram += 'window.ugattr = window.ugattr || {};'
    browseUserGram += `ugattr[\'serviceId\'] = \'${member_id}\';`
    browseUserGram += `ugattr[\'prop07\'] = \'【アプリ】クレヨンしんちゃんスペシャルページ\';`
    browseUserGram += `ugattr[\'prop09\'] = \'\';`
    browseUserGram += `ugattr[\'prop10\'] = \'\';`
    browseUserGram += '</script>'

    browseUserGram +=
      '<script type="text/javascript" id="usergram-common-shinchan-plan-app">'
    browseUserGram +=
      '(function(){var a=window,b=document,c=a.usergram=a.usergram||[],d,e;'
    browseUserGram +=
      "c.l||(c.s=(new Date()).getTime(),c.l=!0,d=b.getElementsByTagName('script')[0],"
    browseUserGram +=
      "e=b.createElement('script'),e.type='text/javascript',e.async=true,"
    browseUserGram +=
      "e.src='//code.usergram.info/js/usergram.js',d.parentNode.insertBefore(e,d))})();"
    browseUserGram +=
      'window.usergram=window.usergram||[],window.ugattr=window.ugattr||{};'
    browseUserGram +=
      "usergram.push(['send','Ug37cn-1','cv','shinchan_program',ugattr]);"
    browseUserGram += '</script>'

    webApp.utils.appendUserGram(browseUserGram)
  }

  /** コース情報取得 */
  getCourse() {
    const { slug } = this.props
    const path = [['courseBySlug', slug]]
    return this.model.fetch(path).then((result) => {
      const course = _.get(result, ['json', 'courseBySlug', slug])
      const season = _.get(course, ['values', 'season'])
      if (this._isMounted) {
        const isNotFound = !course
        return { course, isNotFound, season }
      }
    })
  }

  getSeries(season) {
    if (!season) return Promise.resolve()
    const path = [['meta', 'season', season.meta_id, 'parent']]
    return this.model.fetch(path).then((result) => {
      const series = _.get(result.json, path[0])
      if (this._isMounted) {
        return series
      }
    })
  }

  /** グループのライセンス有無取得 */
  getBelonging(course) {
    if (!course) return Promise.resolve()

    const refId = course.ref_id
    if (!refId) return Promise.resolve()

    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) {
      if (this._isMounted) {
        return {
          ...this.state.status,
          isNotPurchased: true
        }
      }
      return Promise.resolve()
    }

    const path = ['infra', 'gBelong', refId]
    return this.model.fetch([path]).then((result) => {
      const belonging = _.get(result, ['json', ...path]) || false
      if (this._isMounted) {
        return {
          ...this.state.status,
          isPurchased: belonging,
          isNotPurchased: !belonging
        }
      }
    })
  }

  /** 権利情報取得 */
  getRight(course) {
    if (!course) return Promise.resolve()

    const path = ['course', 'rights', course.course_id]
    return this.model.fetch([path]).then((result) => {
      const rights = _.get(result, ['json', ...path], [])
      if (this._isMounted) {
        // 紐つける権利は一つに限定
        return rights[0]
      }
    })
  }

  /** 権利メタ取得 */
  getRightMetas(right) {
    if (!right || !right.right_id) return Promise.resolve([])
    const path = ['right', 'metas', right.right_id]
    return this.model.fetch([path]).then((result) => {
      // TODO とり方変えたのでここの処理は考え直す
      const metas = _.get(result, ['json', ...path], [])
      // 公開以外も取得される&重複して取得されることがあるのでフィルター
      const metaIds = {}
      const rightMetas = metas.filter((meta) => {
        const published = meta.publish_status === PUBLISH_STATUS.PUBLISHED
        const duprecated = Object.keys(metaIds).includes(
          meta.meta_id.toString()
        )
        metaIds[meta.meta_id] = null
        return published && !duprecated
      })
      if (this._isMounted) {
        return rightMetas
      }
    })
  }

  /** エピソード取得 */
  getEpisodes(rightMetas) {
    if (!rightMetas) return Promise.resolve([])

    let episode_ids = [0]
    let series_ids = rightMetas
      .filter((meta) =>
        [META_SCHEMA_ID.SERIES, META_SCHEMA_ID.LIVE_SERIES].includes(
          meta.meta_schema_id
        )
      )
      .map((meta) => meta.meta_id)
    if (series_ids.length === 0) {
      series_ids = [0]
    }
    let season_ids = rightMetas
      .filter((meta) =>
        [META_SCHEMA_ID.SEASON, META_SCHEMA_ID.LIVE_SEASON].includes(
          meta.meta_schema_id
        )
      )
      .map((meta) => meta.meta_id)
    if (season_ids.length === 0) {
      season_ids = [0]
    }

    const range = { from: 0, to: 1000 }
    const path = [
      [
        'meta',
        'packSearch',
        'episode',
        '',
        episode_ids,
        season_ids,
        series_ids,
        [range]
      ]
    ]

    return this.model.fetch(path).then((result) => {
      if (result.json) {
        const metas = _.get(result.json, [
          'meta',
          'packSearch',
          'episode',
          '',
          episode_ids[0],
          season_ids[0],
          series_ids[0]
        ])
        if (metas) {
          delete metas['$__path']
          this.setState({ episodes: Object.values(metas) })
        }
      }
    })
  }

  // リダイレクト対象のページの場合、ブラウザにリダイレクトする
  redirect2Browser() {
    const { slug } = this.props

    /*
     * development(ローカル) or STGのときはstgを参照
     * それ以外はprdを参照
     */
    const envKey = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 'stg'
      : 'prd'

    // リストの項目と一致する場合はリダイレクト
    if (window && window.location) {
      if (
        redirectConfig[envKey].plan.data.includes(slug) ||
        slug === 'logirl'
      ) {
        window.location.href = `/plan/${slug}`
      }
    }
  }

  render() {
    if (this.state.isNotFound) return <NotFound />

    const { course, season, series, episodes, rightMetas, status } = this.state
    if (!course || !_.size(course)) return null

    // 月額見放題ページはテンプレート利用対象
    const templateId = course.values.ex_templateId || 0
    const templateKeyPrefix = 'plan_app_'
    const templateKey = `${templateKeyPrefix}${templateId}`
    const template =
      namespace.templates[templateKey] ||
      namespace.templates[`${templateKeyPrefix}0`]
    if (!template) {
      console.error(`Template:${templateKey} not found.`)
      return null
    }

    const childProps = Object.assign({}, this.props, {
      course,
      season,
      series,
      episodes,
      rightMetas,
      status
    })
    return React.createElement(template, childProps)
  }
}
