import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

/* hooks */
import useMediaQuery from '../../../../../hooks/useMediaQuery'

/** style */
import {
  StyledDiv,
  StyledUl1,
  StyledLi1,
  StyledLabel,
  StyledRadio
} from './styles'

/** EpisodeLive サイドタブ */
const TabPanel = ({
  hasChat = false,
  hasComment = false,
  currentTabId = '',
  setCurrentTabId = () => {},
  ...props
}) => {
  const isSp = useMediaQuery()

  /** タブの変更 */
  const changeCurrentTab = (e) => {
    const value = get(e, ['target', 'value'])
    setCurrentTabId(value)
  }

  /**
   * 背景固定
   * @param {boolean} isRelease - 解放するか
   */
  const freezeBackground = (isRelease = false) => {
    if (isRelease) {
      /** 解除 */
      document.body.style = null
    } else {
      /** 固定 */
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    }
  }

  /** useEffect */
  useEffect(() => {
    return () => {
      /** 背景固定の解除 */
      freezeBackground(true)
    }
  }, [])

  useEffect(() => {
    /** パネルが開いているとき背景固定 */
    freezeBackground(!isSp || currentTabId === '')
  }, [isSp, currentTabId])

  if (!isSp && !hasChat) return null

  return (
    <StyledDiv isClose={currentTabId === ''} {...props}>
      <StyledUl1>
        <StyledLi1 hidePc>
          <StyledRadio
            type="radio"
            name="currentTab"
            value=""
            id="fSummary"
            checked={currentTabId === ''}
            onChange={changeCurrentTab}
          />
          <StyledLabel htmlFor="fSummary">番組概要</StyledLabel>
        </StyledLi1>
        {hasChat && (
          <StyledLi1>
            <StyledRadio
              type="radio"
              name="currentTab"
              value="chat"
              id="fChat"
              checked={currentTabId === 'chat'}
              onChange={changeCurrentTab}
            />
            <StyledLabel htmlFor="fChat">チャット</StyledLabel>
          </StyledLi1>
        )}
        {hasComment && (
          <StyledLi1 hidePc>
            <StyledRadio
              type="radio"
              name="currentTab"
              value="comment"
              id="fComment"
              checked={currentTabId === 'comment'}
              onChange={changeCurrentTab}
            />
            <StyledLabel htmlFor="fComment">コメント</StyledLabel>
          </StyledLi1>
        )}
      </StyledUl1>
    </StyledDiv>
  )
}

export default TabPanel

TabPanel.propTypes = {
  hasChat: PropTypes.bool,
  hasComment: PropTypes.bool,
  chatHash: PropTypes.string,
  currentTabId: PropTypes.oneOf(['', 'chat', 'comment']),
  setCurrentTabId: PropTypes.func
}
