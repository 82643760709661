import ReactDOM from 'react-dom';
import { createPath } from 'history/PathUtils';
import _ from 'lodash';
import moment from 'moment';
import url from 'url';
import window from 'global';

import safeTimeout from '../../common/safeTimeout';
import tokenDataStore from '../../utils/tokenDataStore';
import webApp from '../../apps/exdio/utils/exdioWebAppUtils';

class ReactClientRenderer {
  constructor(mountNode, context) {
    const logger = context.getLogger();
    this.mountNode = mountNode;
    this.appContext = context;
    this.cl = logger;
    this.isPerfAPIAvailable = typeof window !== 'undefined' && window.performance && window.performance.mark;
    this.mounted = false;

    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    this.isListeningToHistory = false;

    // Switch off the native scroll restoration behavior and handle it manually
    // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
    this.scrollPositionsHistory = {};
    if (window.history && 'scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    this.currentLocation = context.getHistory().location;

    // TokenManager関連
    // 有効設定の場合、定期実行
    const tokenConfig = this.appContext.getModelData('tokenConfig');
    if (tokenConfig && tokenConfig.available && tokenConfig.cycleTokenCheck) this.tokenConfig = tokenConfig;

    this.handleTokenCheck = this.handleTokenCheck.bind(this);
    this.handleTokenRefresh = this.handleTokenRefresh.bind(this);
    this.handleTokenCheckStart = this.handleTokenCheckStart.bind(this);
    this.handleTokenRefreshStart = this.handleTokenRefreshStart.bind(this);
    /** サーバーへの画面アクセス初回リクエスト時のみfalse、以後SPAでは常にtrue */
    this.isTokenCheckInterval = false;
    this.isTokenRefreshInterval = false;
    this.isOnbeforeunload = false;
    this.execSyncTokenCheck = false;
    window.addEventListener('beforeunload', () => {
      this.isOnbeforeunload = true;
    });
  }

  handleHistoryChange(location, action) {
    if (this.scrollTimeoutId) {
      clearTimeout(this.scrollTimeoutId);
      delete this.scrollTimeoutId;
    }
    // Remember the latest scroll position for the previous location
    this.scrollPositionsHistory[this.currentLocation.key] = {
      scrollX: window.pageXOffset,
      scrollY: window.pageYOffset
    };

    // Delete stored scroll position for next page if any
    if (action === 'PUSH') {
      delete this.scrollPositionsHistory[location.key];
    }

    this.currentLocation = location;

    const state = this.appContext.getModelData('state');
    state.isFirstServerAccess = false;

    // SPAでの画面遷移時にトークンチェックを実施
    this.execSyncTokenCheck = true;
    this.render((err, _html) => {
      if (err) console.error(err.stack);
    });
  }

  handleTokenCheckStart() {
    if (this.tokenConfig && this.tokenConfig.tokenCheckInterval) {
      safeTimeout(() => {
        this.handleTokenCheck(true);
      }, this.tokenConfig.tokenCheckInterval);
    }
  }

  handleTokenRefreshStart() {
    if (this.tokenConfig && this.tokenConfig.tokenRefreshInterval) {
      safeTimeout(() => {
        this.handleTokenRefresh(true);
      }, this.tokenConfig.tokenRefreshInterval);
    }
  }

  /**
   * トークンチェック
   * @param {boolean} interval trueの場合、次回トークンチェックの予約を行う
   * @param {boolean} async trueの場合 非同期、falseの場合 同期。画面遷移時のトークンチェックでfalse指定
   */
  handleTokenCheck(interval = false, async = true) {
    if (this.isOnbeforeunload) return;
    try {
      const tokenData = this.appContext.getTokenData();
      if (tokenData && tokenData.token) {
        const service = Object.assign({}, this.appContext.getModelData('config', 'token_manager'));
        if (!service) return;

        if (tokenData.uuid !== this.appContext.uuid || tokenData.isRefreshing) {
          if (interval) this.handleTokenCheckStart();
          return;
        }

        // Session Manager APIに直接リクエスト
        service.pathname = _.join(_.concat(service.endpoint, 'token/check'), '/');
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url.format(service), async);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Authorization', `Bearer ${tokenData.token}`);
        xhr.setRequestHeader('X-Token-Id', tokenData.id);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            webApp.utils.debug(`[ReactClientRenderer] handleTokenCheck response: ${JSON.stringify(xhr.response)}`);
            if (xhr.status === 0 || xhr.status === 200) {
              if (interval) this.handleTokenCheckStart();
            }
            if (xhr.status === 401) {
              if (interval) this.handleTokenCheckStart();
              webApp.utils.debug('[ReactClientRenderer] call handleTokenRefresh() because token check ended with 401.');
              this.handleTokenRefresh(false, async);
            }
          }
        };
        webApp.utils.debug('[ReactClientRenderer][START] token/check');
        xhr.send();
      }
    } catch (e) {
      console.error(`ReactClientRenderer::handleTokenCheckError: status: ${_.get(e, 'response.status')} data:${JSON.stringify(_.get(e, 'response.data'))}` );
    }
  }

  /**
   * トークンリフレッシュ
   * @param {boolean} interval trueの場合、次回トークンリフレッシュの予約を行う
   * @param {boolean} async 画面遷移時のトークンチェックでリフレッシュが必要となった場合にfalse(同期)指定
   */
  handleTokenRefresh(interval = false, async = true) {
    if (this.isOnbeforeunload) return;
    try {
      const tokenData = this.appContext.getTokenData();
      if (tokenData && tokenData.token) {
        let timeCheck = false;
        // 最後にトークンが生成されてから一定時間はリフレッシュしない
        if (tokenData.time) {
          const noTokenRefreshInterval = this.tokenConfig.noTokenRefreshInterval / 1000;
          timeCheck = moment().diff(moment(tokenData.time, 'x'), 'second') < noTokenRefreshInterval;
          if (timeCheck) webApp.utils.debug(`[ReactClientRenderer] skip handleTokenRefresh because ${noTokenRefreshInterval} sec hasn't passed since the last refresh.`);
        }
        if (tokenData.uuid !== this.appContext.uuid || tokenData.isRefreshing || timeCheck) {
          if (interval) this.handleTokenRefreshStart();
          return;
        }

        tokenData.isRefreshing = true;
        this.appContext.setTokenData(tokenData);

        // Express Server API(src/api/user.js)経由でSession Manager APIにリクエスト
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/api/user/token/refresh', async);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            const response = JSON.parse(xhr.responseText);
            webApp.utils.debug(`[ReactClientRenderer] handleTokenRefresh response: ${JSON.stringify(response)}`);
            if (xhr.status === 0) {
              tokenData.isRefreshing = false;
              this.appContext.setTokenData(tokenData);
              if (interval) this.handleTokenRefreshStart();
            }
            if (xhr.status === 200) {
              if (response.result) {
                tokenData.isRefreshing = false;
                this.appContext.setTokenData(tokenData);
                if (response.authContext) {
                  // リフレッシュ結果をlocalstorageに保持
                  tokenDataStore.setAuthContextData(response.authContext);
                  this.token = response.authContext.token;
                  // APPの場合はcookieにも保持
                  const { pathname } = window.location;
                  if (pathname === '/app' || pathname.startsWith('/app/')) {
                    const config = this.appContext.getModelData('config');
                    const domain = config.domain ? ` domain=${config.domain};` : '';
                    document.cookie = `DIO_TOKEN=${response.authContext.token}; path=/;${domain}`;
                  }
                  this.render((err, _html) => {
                    if (err) console.error(err.stack);
                  });
                }
                if (interval) this.handleTokenRefreshStart();
              } else {
                const tmpCtx = { falcorModel: this.appContext.getFalcorModel().batch(100) };
                webApp.utils.debug('[ReactClientRenderer] will logout because tokenRefresh response result is false.');
                webApp.utils
                  .logout(tmpCtx)
                  .then(() => window.location.reload())
                  .catch(e => webApp.utils.handleFalcorError(e, tmpCtx));
              }
            }
          }
        };
        webApp.utils.debug('[ReactClientRenderer][START] token/refresh');
        xhr.send();
      }
    } catch (e) {
      console.error('ReactClientRenderer::handleTokenRefreshError', e);
    }
  }

  async loginCheck() {
    if (!webApp.utils.isLoggedIn({ models: this.appContext.getModels() })) return;

    const path = ['user', 'loginCheck'];
    const falcorModel = this.appContext.getFalcorModel().batch(100);
    await falcorModel.fetch([path])
      .then()
      .catch(e => webApp.utils.handleFalcorError(e, { falcorModel }));
  }

  /** プレビュー用トークンの有効チェック */
  handlePreviewTokenCheck() {
    try {
      const tokenQuery = window.location.search
        .substr(1)
        .split('&')
        .find(q => q.startsWith('preview='));
      const token = tokenQuery ? tokenQuery.split('=')[1] : null;

      const state = this.appContext.getModelData('state');
      if (!token) {
        state.withValidPreviewToken = false;
        return;
      }

      const config = this.appContext.getModelData('config');
      const service = config.token_manager;
      service.pathname = _.join(_.concat(service.endpoint, 'token/check'), '/');
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url.format(service), false);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.setRequestHeader('X-Token-Id', config.logica.preview_token_id);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          webApp.utils.debug(`[ReactClientRenderer] PreviewTokenCheck response: ${JSON.stringify(xhr.response)}`);
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            state.withValidPreviewToken = response.result;
          }
        }
      };
      xhr.send();
    } catch (e) {
      console.error(`PreviewTokenCheck Error: status: ${_.get(e, ['response', 'status'])} data: ${JSON.stringify(_.get(e, ['response', 'data']))}` );
    }
  }

  async render(cb) {
    this.currentUrl = window.location.pathname + window.location.search + window.location.hash;
    await this.appContext.resolveState(this.currentUrl, (state, _routeHandler) => {
      if (this.token) {
        _.set(state, ['model', 'models', 'authContext', 'data', 'token'], this.token);
        delete this.token;
      }
      return state;
    });

    if (!this.isListeningToHistory) {
      this.appContext.getHistory().listen(this.handleHistoryChange);
      this.isListeningToHistory = true;
    }

    if (this.tokenConfig) {
      if (!this.isTokenCheckInterval) {
        // サーバーへの画面アクセス初回リクエスト時
        this.isTokenCheckInterval = true;
        this.handleTokenCheckStart();
      } else if (this.execSyncTokenCheck) {
        // SPAでの画面遷移時、トークンチェックを行ってから画面描画
        this.execSyncTokenCheck = false;
        this.handleTokenCheck(false, false);
      }

      if (!this.isTokenRefreshInterval) {
        this.isTokenRefreshInterval = true;
        this.handleTokenRefreshStart();
      }
    }

    // ログインチェックを実施
    await this.loginCheck();

    // プレビュー用トークンチェックを実施
    this.handlePreviewTokenCheck();

    this.appContext.resolveElement((err, elements) => {
      if (err) {
        return cb(err);
      }

      const self = this;
      const renderReactApp = !this.mounted ? ReactDOM.hydrate : ReactDOM.render;
      renderReactApp(
        this.appContext.provideAppContextToElement(elements),
        this.mountNode,
        function () {
          self.mounted = true;

          let scrollX = 0;
          let scrollY = 0;
          const pos = self.scrollPositionsHistory[self.currentLocation.key];
          if (pos) {
            scrollX = pos.scrollX;
            scrollY = pos.scrollY;
          } else {
            const targetHash = self.currentLocation.hash.substr(1);
            if (targetHash) {
              const target = document.getElementById(targetHash);
              if (target) {
                scrollY = window.pageYOffset + target.getBoundingClientRect().top;
              }
            }
          }

          // Restore the scroll position if it was saved into the state
          // or scroll to the given #hash anchor
          // or scroll to top of the page
          if (document.documentElement.scrollHeight > scrollY) {
            window.scrollTo(scrollX, scrollY);
          } else {
            this.scrollTimeoutId = setTimeout(function() {
              delete this.scrollTimeoutId;
              window.scrollTo(scrollX, scrollY);
            }, 10);
          }

          // Google Analytics tracking. Don't send 'pageview' event after
          // the initial rendering, as it was already sent
          if (window.ga) {
            window.ga('send', 'pageview', createPath(location));
          }
          if (window.FB && window.FB.XFBML && typeof window.FB.XFBML.parse === 'function') {
            window.FB.XFBML.parse();
          }
          if (window.twttr && window.twttr.widgets && typeof window.twttr.widgets.load === 'function') {
            window.twttr.widgets.load();
          }
          cb(null, this);
        },
      );
    });
  }

  unMount() {
    if (this.mountNode) {
      // TODO history remove
      this.isListeningToHistory = false;
      this.mounted = false;
      ReactDOM.unmountComponentAtNode(this.mountNode);
    }
  }
}

export default ReactClientRenderer;
