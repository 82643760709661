import React, { memo, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import $ from 'jquery'
import pageTopAnimation from './pageTopAnimation'
import { mediaSp } from './styles'

const PageTop = ({ ...props }) => {
  useEffect(() => {
    /** クリック時のアニメーション着脱 */
    const $pageTop = $('#shinchanPageTop')
    $pageTop.on('click', (e) => pageTopAnimation(e, $pageTop))

    return () => {
      $('#shinchanPageTop').off('click', pageTopAnimation)
    }
  }, [])

  return (
    <StyledA href="#PlanShinchan" id="shinchanPageTop" {...props}>
      <StyledImg1
        src="/douga_mv/shinchan/svod/images/footer/shinchan.svg"
        width="110px"
        height="119px"
        alt=""
        loading="lazy"
      />
      <StyledImg2
        src="/douga_mv/shinchan/svod/images/footer/back-txt.svg"
        width="80"
        height="11"
        alt="うえにもどる"
        loading="lazy"
      />
    </StyledA>
  )
}

export default memo(PageTop)

const balloon = keyframes`
0% { transform: translate(0, 0) rotate(-5deg); }
50% { transform: translate(0, -5px) rotate(0deg); }
100% { transform: translate(0, 0) rotate(5deg); }
`

const StyledA = styled.a`
  width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mediaSp} {
    width: 84px;
  }
`

const StyledImg1 = styled.img`
  animation: ${balloon} 3s infinite linear alternate;
`

const StyledImg2 = styled.img`
  margin-top: 10px;
`
