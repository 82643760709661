import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import webApp from '../../../exdio/utils/exdioWebAppUtils'

/** style */
import { mediaQuery } from '../../../exdio/components/style'

/** ヘッダー > ハンバーガーメニュ開閉ボタン */
const Hamburger = ({ toggleNavMenu = () => {}, show = {} }, context) => {
  const isApp = webApp.utils.isApp(context)
  return (
    <StyledButton
      onClick={() => toggleNavMenu('memberMenu')}
      open={show}
      isApp={isApp}
    />
  )
}

export default Hamburger

Hamburger.propTypes = {
  /** GlobalNavigation.js > showSubMenu/showSearch/showMemberMenu 開閉処理 */
  toggleNavMenu: PropTypes.func.isRequired,
  /** ハンバーガーメニュ開閉状態 */
  show: PropTypes.bool.isRequired
}

Hamburger.contextTypes = {
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  })
}

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isApp'].includes(prop)
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background: url(/images/exdio/renewal/icon_menuMember.svg) no-repeat center
    center;
  background-size: ${({ isApp }) => (isApp ? '18px' : null)};
  border: none;

  ${mediaQuery()} {
    width: 50px;
  }

  ${mediaQuery(374)} {
    width: 40px;
  }

  ${({ open }) =>
    open &&
    css`
      background: #f0f0f0 url(/images/exdio/renewal/icon_menuMember_close.svg)
        no-repeat center center;
      opacity: 1 !important;
    `}

  @media (hover: hover) {
    transition: opacity 0.2s linear;

    &:hover {
      opacity: 0.6;
    }
  }
`
