import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import routes from '../../../../../common/routes'
import webApp from '../../../../utils/exdioWebAppUtils'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import { mediaQuery } from '../../../style'

/** Header Component */
const Header = ({ ...props }, context) => {
  const isApp = webApp.utils.isApp(context)

  return (
    <StyledHeader {...props}>
      <StyledLink
        route={routes[isApp ? 'app_plan' : 'plan']}
        params={{ slug: 'doraemon' }}
      >
        <img
          src="/images/exdio/renewal/doraemon/logo.webp"
          width="207"
          height="200"
          alt="ドラえもんTV"
        />
      </StyledLink>
    </StyledHeader>
  )
}

export default Header

Header.contextTypes = {
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  })
}

const StyledHeader = styled.header`
  display: grid;
`

const StyledLink = styled(Link)`
  max-width: 75px;
  display: inline-block;
  justify-self: start;
  line-height: 0;

  ${mediaQuery()} {
    max-width: 45px;
    justify-self: center;
  }

  @media (hover: hover) {
    transition: opacity 0.4s;

    &:hover {
      opacity: 0.7;
    }
  }
`
