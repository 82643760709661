import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { get } from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import classnames from 'classnames'
import {
  META_SCHEMA_ID,
  SVOD_DELIVERY_PATTERN,
  EPISODE_DISPLAY_MODE,
  PRODUCT_SCHEMA
} from '../../../../constants/app'
import webApp from '../../../exdio/utils/exdioWebAppUtils'
import Link from '../../../../sketch-platform/ui/routing/Link'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 動画、番組リスト要素コンポーネント */
const ProgramItemLink = (
  {
    breakSubTitle,
    course,
    deliveryEndFormat,
    hidePrice,
    howToPlay,
    href,
    meta,
    onClick,
    onClickCaption,
    onClickDelete,
    onClickPlay,
    onClickThumbnail,
    onClickTitle,
    onlySubTitle,
    onlyTitle,
    params,
    product,
    programLink,
    purchased,
    query,
    recommendItem,
    route,
    showBottomLabel,
    showCaption,
    showChecked,
    showCoin,
    showDeadLine,
    showDelivery,
    showDeliveryEndPriorToStart,
    showEndText,
    showFreeCoin,
    showFreeCoinOnly,
    showInCourse,
    showLatest,
    showNew,
    showRemaining,
    textOverFlow,
    thumbnail,
    thumbnailSize,
    titlesMinHeight,
    title,
    className,
    isSideRecommend,
    ...props
  },
  context
) => {
  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix
  const isApp = webApp.utils.isApp(context)
  const isShowRemaining = showRemaining !== -1 || showRemaining

  const [isFree, setIsFree] = useState(false)
  const [isNotFree, setIsNotFree] = useState(false)
  const [isSeries, setIsSeries] = useState(false)
  const [isSeason, setIsSeason] = useState(false)

  const setupFlags = (metaProp, productProp, courseProp) => {
    if (!metaProp) return
    const metaSchemaId = metaProp.meta_schema_id
    const displayMode = webApp.utils.getDisplayMode(
      metaProp,
      productProp,
      courseProp
    )
    let tempIsFree
    switch (displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        tempIsFree = true
        break
      default:
        tempIsFree = false
    }
    setIsFree(tempIsFree)
    setIsNotFree(!tempIsFree)
    setIsSeries(
      META_SCHEMA_ID.SERIES === metaSchemaId ||
        META_SCHEMA_ID.LIVE_SERIES === metaSchemaId
    )
    setIsSeason(
      META_SCHEMA_ID.SEASON === metaSchemaId ||
        META_SCHEMA_ID.LIVE_SEASON === metaSchemaId
    )
  }

  useEffect(() => {
    let ignore = false
    if (!ignore) {
      setupFlags(meta, product, course)
    }

    return () => {
      ignore = true
    }
  }, [meta, product, course])

  /** タイトル */
  const getTitle = () => {
    if (title) return [title, '']
    return webApp.utils.titles(meta, product, course)
  }

  /** サムネイル画像 */
  const getThumbnail = () => {
    if (thumbnail)
      return [webApp.utils.customSizeImageUrl(thumbnail, thumbnailSize), '']

    let thumbnailUrl = null
    let thumbnailAlt = null
    if (meta) {
      thumbnailUrl = meta.thumbnail_url
      const [title, subTitle] = getTitle()
      thumbnailAlt = `${title} ${subTitle}`
    } else if (product) {
      thumbnailUrl = product.thumbnail_url
      thumbnailAlt = product.name
    } else if (course) {
      thumbnailUrl = course.thumbnail_url
      thumbnailAlt = course.name
    }

    /* 読み込む画像サイズを変更する */
    thumbnailUrl = webApp.utils.customSizeImageUrl(thumbnailUrl, 'medium')

    return [thumbnailUrl || config.default_thumbnail, thumbnailAlt]
  }

  const bottomLabel = () => {
    return webApp.utils.productLabel(product, course)
  }

  const getDeliveryEndAt = () => {
    if (meta) {
      if (
        meta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE
      ) {
        const deliveryEndAt = (get(meta, ['delivery_end_at']) || '').replace(
          /\//g,
          '-'
        )
        if (!deliveryEndAt) return ''
        return moment(deliveryEndAt).format(
          deliveryEndFormat || `YYYY/M/D HH:mmまで`
        )
      }
    } else if (course) {
      if (purchased && purchased.length > 0) {
        return webApp.utils.deadLine(context, purchased[0].limit_date, true)
      }
    }
    return ''
  }

  const getDeliveryStartAt = () => {
    if (showDeliveryEndPriorToStart) {
      const deliveryEndAt = getDeliveryEndAt(meta, product, course)
      if (deliveryEndAt) return ''
    }
    if (!meta) return ''
    if (
      [
        META_SCHEMA_ID.EPISODE,
        META_SCHEMA_ID.EPISODE_NOT_FREE,
        META_SCHEMA_ID.LIVE,
        META_SCHEMA_ID.LIVE_NOT_FREE
      ].includes(meta.meta_schema_id)
    ) {
      const deliveryStartAt = get(meta, ['delivery_start_at'])
      if (!deliveryStartAt) return ''
      return moment(deliveryStartAt).format('YYYY/M/D配信')
    }
    // 表示しなくてよいとのことなので一旦コメントアウト
    // ※下記のコードだとちなみにupdated_atはまだレスポンスにいれていないので、サーバ側の対応が必要
    // if ([META_SCHEMA_ID.SEASON, META_SCHEMA_ID.SERIES].includes(meta.meta_schema_id)) {
    //  const updatedAt = get(meta, ['updated_at']);
    //  if (!updatedAt) return '';
    //  return moment(updatedAt).format('YYYY/M/D更新');
    // }
    return ''
  }

  /** あらすじ */
  const renderCaption = () => {
    let synopsis = ''
    if (meta) {
      synopsis = meta.values.evis_EpisodeLongSynopsis
      if (isSeason) {
        synopsis = meta.values.evis_SeasonLongSynopsis
      } else if (isSeries) {
        synopsis = meta.values.evis_SeriesLongSynopsis
      }
    } else if (product) {
      synopsis = product.description
    } else if (course) {
      synopsis = course.description
    }
    if (synopsis) {
      return (
        <StyledCaption
          className="c-card-inBox-meta-caption"
          textOverFlow={textOverFlow}
        >
          {synopsis}
        </StyledCaption>
      )
    }

    return null
  }

  /** 価格 */
  const renderCoin = () => {
    if (meta) {
      // シリーズ・シーズンは表示なし
      if (isSeries || isSeason) return null

      if (recommendItem) {
        // recomendItemを指定された場合はdisplayModeをみて無料判定
        // 以降の判定は、metaにセットされたデータに準じて表示するようにする
        switch (recommendItem.display_mode) {
          case EPISODE_DISPLAY_MODE.FREE:
          case EPISODE_DISPLAY_MODE.TVOD_FREE:
          case EPISODE_DISPLAY_MODE.SVOD_FREE:
          case EPISODE_DISPLAY_MODE.STVOD_FREE:
            return (
              <div className="c-card-inBox-meta-price free sp-pb">無料</div>
            )

          case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
          case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
          case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
          case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
          case EPISODE_DISPLAY_MODE.UNKNOWN:
          default:
            break
        }
      }

      if (isFree) {
        return <div className="c-card-inBox-meta-price free sp-pb">無料</div>
      }

      if (isNotFree) {
        if (!howToPlay) return null

        // 単話TVOD価格
        const howToPlayProducts = get(howToPlay, ['products']) || []
        const singleStoryProduct = howToPlayProducts.find(
          (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
        )
        const productPrice = get(singleStoryProduct, ['active_pricing'])

        // 月額見放題パックに含まれる場合
        const isInCourse = !!howToPlay.courses.length

        let coursePrice = null
        if (isInCourse) {
          coursePrice = get(howToPlay, [
            'courses',
            0,
            'active_pricing',
            'price'
          ])
        }

        let displayProductPrice = null
        let displayCoursePrice = null
        let isFreeInCourse = false

        if (isInCourse) {
          if (meta.values.svod_delivery_pattern == SVOD_DELIVERY_PATTERN.FREE) {
            isFreeInCourse = true
          } else if (
            meta.values.svod_delivery_pattern == SVOD_DELIVERY_PATTERN.AUTH_FREE
          ) {
            // ログイン時無料設定がされていた場合はログイン済みなら無料
            const isLoggedIn = webApp.utils.isLoggedIn(context)
            if (isLoggedIn) {
              isFreeInCourse = true
            }
          }
        }

        if (isFreeInCourse) {
          return <div className="c-card-inBox-meta-price free sp-pb">無料</div>
        }

        if (productPrice && productPrice.price !== 0) {
          displayProductPrice = productPrice.price
        }

        if (coursePrice && coursePrice.price !== 0) {
          displayCoursePrice = (
            <React.Fragment>月額{coursePrice}円</React.Fragment>
          )
        }

        let displayPrice = null

        if (displayCoursePrice) {
          // コースの金額が設定されている場合は、コースのみ表示するか、コースとTVOD有料(0円以上)の2パターンが表示される
          // APPでは価格表示なし
          if (webApp.utils.isApp(context)) return null

          // 無料のみの表示の場合は表示しない
          if (showFreeCoin) return null

          // 月額料金表示のみの場合はコインアイコンを表示しない
          return (
            <React.Fragment>
              {displayProductPrice === null ? (
                <div className="c-card-inBox-meta-price c-card-inBox-meta-price-in-course hide-coin sp-pb">
                  {displayCoursePrice}
                </div>
              ) : (
                <div className="c-card-inBox-meta-price c-card-inBox-meta-price-in-course sp-pb">
                  {displayProductPrice}
                  <span>or</span>
                  {displayCoursePrice}
                </div>
              )}
            </React.Fragment>
          )
        }

        // パックのみ販売は価格非表示
        if (hidePrice) return null

        // 月額見放題パックに含まれない場合は
        // TVOD無料(0円とTVOD有料の2パターンが表示される)
        displayPrice = displayProductPrice

        if (productPrice && productPrice.price === 0) {
          return <div className="c-card-inBox-meta-price free sp-pb">無料</div>
        }

        // APPでは価格表示なし
        if (webApp.utils.isApp(context)) return null

        // 無料のみの表示の場合は表示しない
        if (showFreeCoin) return null

        return (
          <div className="c-card-inBox-meta-price sp-pb">{displayPrice}</div>
        )
      }
    }

    if (product) {
      // APPでは価格表示なし
      if (webApp.utils.isApp(context)) return null

      // 無料のみの表示の場合は表示しない
      if (showFreeCoin) return null

      const activePrice = get(product, ['active_pricing'])
      return (
        activePrice && (
          <div className="c-card-inBox-meta-price sp-pb">
            {activePrice.price}
          </div>
        )
      )
    }

    if (course) {
      // APPでは価格表示なし
      if (webApp.utils.isApp(context)) return null

      // 無料のみの表示の場合は表示しない
      if (showFreeCoin) return null

      const activePrice = get(course, ['active_pricing'])
      return (
        activePrice && (
          <div className="c-card-inBox-meta-price sp-pb">
            月額{activePrice.price}円
          </div>
        )
      )
    }

    return null
  }

  const handleClick = (propFunc, e) => {
    e.preventDefault()
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation()
      propFunc(e)
    }
  }

  const isGenreOrHomePage = () => {
    const url = window.location.href
    const path = window.location.pathname

    const hasGenre = url.includes('genre')
    const isHomePage = path === '/' || path === ''
    const isCatchupTop = path === '/catchup' || path === '/catchup/'

    return hasGenre || isHomePage || isCatchupTop
  }

  const [episodeTitle, episodeSubTitle] = getTitle()
  const [thumbnailUrl, thumbnailAlt] = getThumbnail()
  const isWithin3Days = webApp.utils.checkRemainingDays(
    get(meta, ['delivery_end_at']),
    3
  )
  const isWithin7Days = webApp.utils.checkRemainingDays(
    get(meta, ['delivery_end_at'])
  )
  const remaining = webApp.utils.remaining(
    context,
    get(meta, ['delivery_end_at'])
  )
  const deliveryEndAt = get(meta, ['delivery_end_at'])
  const progress = webApp.utils.progress(config, meta)
  const isInCourseNotFree = !!get(howToPlay, ['courses', 'length']) && isNotFree
  const duration = webApp.utils.duration(meta)
  const isNew = webApp.utils.isNew(context, meta)
  const showInCourseIcon = showInCourse && isInCourseNotFree
  // 配信終了判定
  let inViewTerm = true
  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  if (meta && moment(prevTime).isAfter(meta.delivery_end_at)) {
    inViewTerm = false
  }

  return (
    <Link
      route={route}
      params={params}
      query={query}
      href={href}
      className={classnames('c-card', [[className]])}
      {...props}
    >
      {isShowRemaining &&
        remaining &&
        isGenreOrHomePage() &&
        isWithin7Days &&
        !isApp &&
        !isSideRecommend && (
          <StyledSpan3 data-chromatic="ignore">{remaining}</StyledSpan3>
        )}
      <figure className="c-card-inBox clickable">
        <div className="c-card-inBox-art">
          <div className="c-card-inBox-art-artwork">
            {!inViewTerm && showEndText && !programLink && (
              <StyledDiv4 className="c-card-inBox-art-artwork-mask">
                <span>配信終了しました</span>
              </StyledDiv4>
            )}
            <img
              src={thumbnailUrl}
              alt={thumbnailAlt}
              width="200"
              height="112"
              className="c-card-inBox-art-artwork-img"
              loading="lazy"
            />
          </div>
          {showNew && isNew && <StyledSpan2 />}
          {isShowRemaining &&
            remaining &&
            isWithin7Days &&
            (isApp || isSideRecommend) && (
              <div data-chromatic="ignore" className="c-artInfo-period">
                {remaining}
              </div>
            )}
          <div className="c-artInfo-seekarea h3px">
            {progress > 0 && (
              <div
                className="c-artInfo-seekarea-bar"
                style={{ width: `${progress}%` }}
              />
            )}
          </div>
          {showBottomLabel && (
            <div className="c-artInfo-labelBottom">{bottomLabel()}</div>
          )}
        </div>

        <figcaption className="c-card-inBox-meta">
          <StyledDiv1
            className="c-card-inBox-meta-titles"
            titlesMinHeight={titlesMinHeight}
          >
            {showInCourseIcon && (
              <StyledSpan4>
                <img
                  src="/images/exdio/renewal/icon_unlimited_pack.png"
                  alt=""
                  loading="lazy"
                />
              </StyledSpan4>
            )}
            <StyledSpan1
              className={classnames('c-card-inBox-meta-titles-title', {
                noHidden: programLink,
                isInCourse: !onlySubTitle && showInCourseIcon
              })}
            >
              {showNew && isNew && (
                <span className="c-card-inBox-meta-title-new" />
              )}
              {showChecked && <span className="iconMylistAdded" />}
              {!onlySubTitle && episodeTitle}
            </StyledSpan1>
            {!onlySubTitle && breakSubTitle && <br />}
            {!onlySubTitle && !breakSubTitle && ' '}
            {!onlyTitle && episodeSubTitle && (
              <span
                className={classnames('c-card-inBox-meta-titles-titleSub', {
                  isInCourse: onlySubTitle && showInCourseIcon
                })}
              >
                {episodeSubTitle}
                {showLatest && '（最新話）'}
              </span>
            )}
            {showDelivery && (
              <span className="c-card-inBox-meta-titles-on-air">
                {getDeliveryStartAt()}
              </span>
            )}
          </StyledDiv1>
          {duration && <p className="c-card-inBox-meta-time">{duration}</p>}
          {showCaption && renderCaption()}

          {showDelivery && (
            <div className="c-card-inBox-meta-onAir">
              {getDeliveryStartAt()}
            </div>
          )}

          {showDeadLine && (
            <div
              className={classnames('c-card-inBox-meta-viewingDeadline', {
                isDeadline: isWithin3Days
              })}
            >
              {getDeliveryEndAt()}
            </div>
          )}

          {(showCoin || (!showCoin && showFreeCoinOnly)) && renderCoin()}
        </figcaption>
        {onClickDelete && (
          <div className="c-card-inBox-remove">
            <div
              className="c-card-inBox-remove-link"
              onClick={(e) => handleClick(onClickDelete, e)}
            >
              <img
                src="/images/exdio/renewal/icon_remove_mylist.svg"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        )}
        <div className="c-card-inBox-btnPlay">
          <div className="c-card-inBox-btnPlay-link">
            <img
              src="/images/exdio/renewal/icon_list_btn_play.svg"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </figure>
    </Link>
  )
}

export default ProgramItemLink

ProgramItemLink.propTypes = {
  // 主情報
  /** メタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  /** パック商品 */
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired
  }),
  /** 月額見放題パックコース */
  course: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired
  }),

  // 副情報
  /** 有料単話メタHowToPlay */
  howToPlay: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    )
  }),

  // レコメンドデータ
  recommendItem: PropTypes.object,

  // イベントハンドラ
  // 各onClickは引数に(event)を渡して呼び出される
  onClick: PropTypes.func,
  onClickThumbnail: PropTypes.func,
  onClickCaption: PropTypes.func,
  onClickTitle: PropTypes.func,
  /** 削除時callback. 設定されている場合のみ削除ボタンを表示する. */
  onClickDelete: PropTypes.func,
  /** 再生時callback. 設定されている場合のみ再生ボタンを表示する. */
  onClickPlay: PropTypes.func,

  // 表示制御
  /** NEWマークを表示するか */
  showNew: PropTypes.bool,
  /** チェックマークを表示するか */
  showChecked: PropTypes.bool,
  /** 視聴期限(あとn日)を表示するか */
  showRemaining: PropTypes.bool,
  /** あらすじ(長)を表示するか */
  showCaption: PropTypes.bool,
  /** 配信日を表示するか */
  showDelivery: PropTypes.bool,
  /** 配信期限を表示するか */
  showDeadLine: PropTypes.bool,
  /** コインを表示するか */
  showCoin: PropTypes.bool,
  /** コイン非表示に無料を表示するか */
  showFreeCoinOnly: PropTypes.bool,
  /** (最新話)を表示するか */
  showLatest: PropTypes.bool,
  /** サムネ下部ラベル文言を表示するか */
  showBottomLabel: PropTypes.bool,
  /** 見放題パックアイコンを表示するか */
  showInCourse: PropTypes.bool,
  /** タイトルのみを表示するか */
  onlyTitle: PropTypes.bool,
  /** サブタイトルのみを表示するか */
  onlySubTitle: PropTypes.bool,
  /** サブタイトルを改行して表示するか */
  breakSubTitle: PropTypes.bool,
  /** 配信日フォーマット */
  deliveryEndFormat: PropTypes.string,
  /** 配信期限を配信日より優先して表示するか */
  showDeliveryEndPriorToStart: PropTypes.bool,
  /** 番組へのリンクとして表示するか */
  programLink: PropTypes.bool,

  /** 強制的にタイトルを指定する場合に設定 */
  title: PropTypes.string,
  /** 強制的にサムネイル画像を指定する場合に設定 */
  thumbnail: PropTypes.string,
  /** サムネイルのサイズ指定、デフォルトはmedius */
  thumbnailSize: PropTypes.string,
  showEndText: PropTypes.bool,
  /** c-card-inBox-meta-titlesの1025px以上のmin-heightをキャンセル */
  titlesMinHeight: PropTypes.bool,
  /** c-card-inBox-meta-captionを一行に収める */
  textOverFlow: PropTypes.bool,
  purchased: PropTypes.arrayOf(PropTypes.object),

  /** SideRecommendかどうか */
  isSideRecommend: PropTypes.bool
}

ProgramItemLink.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}

const StyledDiv1 = styled.div`
  position: relative !important;
  font-weight: 600;
  line-height: 1.3;

  ${({ titlesMinHeight }) =>
    !titlesMinHeight &&
    css`
      min-height: auto !important;
    `}
`

const StyledCaption = styled.div`
  ${({ textOverFlow }) =>
    textOverFlow &&
    css`
      display: block;
      font-size: 1.3rem;
      overflow: hidden;
      margin-top: 0.4rem;
      line-height: 1.4;
      max-height: 1.4em;
      text-overflow: ellipsis;
      max-width: 196px;
      white-space: nowrap;
    `}
`

const StyledLink = styled(Link)`
  position: relative;
  margin-right: 1.6rem; /* for basic size */
  width: 256px;
  display: block;

  @media (hover: hover) {
    transition: color 0.3s;
    &:hover {
      color: #939394;
    }
  }
`

const StyledFigure = styled.figure`
  margin: 0 auto;
  cursor: pointer;
`

const StyledDiv2 = styled.div`
  position: relative;
  overflow: hidden;
  width: 256px;
  height: calc(256px * (9 / 16));
  z-index: 0;
`

const StyledDiv3 = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
`

const StyledDiv4 = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;

  span {
    display: inline-block;
  }
`

const StyledSpan1 = styled.span`
  position: relative;
`

// newアイコン
const StyledSpan2 = styled.span`
  overflow: hidden;
  font-size: 0.9rem;
  z-index: 1;
  &::before {
    position: absolute;
    top: -36px;
    left: -30px;
    width: 60px;
    height: 60px;
    content: '';
    background-color: #ffff11;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
  }
  &::after {
    position: absolute;
    content: 'NEW';
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 1rem;
    left: 0.3rem;
    font-size: 0.9rem;
    font-weight: 600;
    z-index: 1;
  }
`

// あと◯日
const StyledSpan3 = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px 0 8px;
  text-align: center;
  background: #f0027f;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
  width: fit-content;
  height: 20px;
  border-radius: 0 0 0 4px;
  z-index: 1;
`

// 見放題アイコン
const StyledSpan4 = styled.span`
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: -14px;
  z-index: 1;
`
