import React, { Component } from 'react';

export default class PresTrackedContainer extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div className="avod_ep_list">
        {this.props.children}
      </div>
    );
  }
}
