import { useEffect } from 'react'

export const useIntersectionObserver = (callback, options) => {
  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)
    const targets = document.querySelectorAll('.scroll')
    targets.forEach((elem) => observer.observe(elem))

    return () => observer.disconnect()
  }, [callback, options])
}
