import React from 'react'
import PropTypes from 'prop-types'
import { SelectionItemType } from '../../types'

/* components */
import List1 from '../List1'
import List2 from '../List2'

/* style */
import { StyledDiv1, StyledDiv2, StyledButton3 } from '../styles'

/** モーダル内コンテンツ (通常) */
const ModalDefault = ({
  selectionItems = [],
  orderBy = '',
  setOrderBy = () => {},
  setIsModalOpen = () => {},
  onChangeTags = () => {}
}) => {
  return (
    <StyledDiv1>
      <List2 orderBy={orderBy} setOrderBy={setOrderBy} />
      <StyledDiv2>
        <List1
          orderBy={orderBy}
          selectionItems={selectionItems}
          onChangeTags={onChangeTags}
        />
      </StyledDiv2>
      <StyledButton3 onClick={() => setIsModalOpen(false)} />
    </StyledDiv1>
  )
}

export default ModalDefault

ModalDefault.propTypes = {
  selectionItems: PropTypes.arrayOf(SelectionItemType),
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  setIsModalOpen: PropTypes.func,
  onChangeTags: PropTypes.func
}
