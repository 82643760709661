import React, { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { get, size, union, shuffle } from 'lodash'
import { Helmet } from 'react-helmet'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import { FAVORITE_TYPE } from '../../../../../constants/app'

/* components */
import CastInfo from '../details/CastInfo'
import AddButtonBlock from '../details/AddButtonBlock'
import HtmlSnippet from '../../HtmlSnippet'
import HeaderNewsComponent from '../HeaderNewsComponent'
import Header from './Header'
import Thumbnail from './Thumbnail'
import FilterSort from '../../../../common/components/FilterSort'

/** hooks */
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/** style */
import useMediaQuery from '../../../../hooks/useMediaQuery'
import * as Component from './style'

/** 番組ページ デフォルトテンプレート */
const ProgramGariben = (
  { seasonId = '', meta = {}, episodes = [], howToPlays = {}, ...props },
  context
) => {
  if (!size(meta)) return null

  const model = context.falcorModel.batch(100)
  const config = context.models.config.data
  const [tags, setTags] = useState()
  const listRef = useRef(null)

  const programIds = config.svod.gariben_daigaku
  const liveArchivesSeasonId = programIds.live_archives.season_id // ライブ配信アーカイブ
  const specialMoviesSeasonId = programIds.special_movies.season_id // 特別授業動画
  const archivesSeasonId = programIds.archives.season_id // 神回アーカイブ

  /** 番組ページで他の番組のエピソードを表示するか */
  const useInProgramPage = get(
    meta,
    [
      'values',
      'season_front_display_config',
      'episodeList',
      'useInProgramPage'
    ],
    false
  )
  /** 番組ページで使用する他の番組のid */
  const extendSeasonIds = useInProgramPage
    ? get(
        meta,
        [
          'values',
          'season_front_display_config',
          'episodeList',
          'extendSeasonIds'
        ],
        []
      )
    : []
  const thumbnailUrl = get(meta, ['thumbnail_url']) || config.default_thumbnail
  const actors = get(meta, ['values', 'evis_SeasonActors'], null)
  const directors = get(meta, ['values', 'evis_SeasonDirectors'], null)
  const producers = get(meta, ['values', 'evis_SeasonProducers'], null)
  const writers = get(meta, ['values', 'evis_SeasonWriters'], null)
  const productions = get(meta, ['values', 'evis_SeasonProductions'], null)
  const copyRight = get(meta, ['values', 'evis_Copyright'], null)
  const seasonLongSynopsis = (meta, ['values', 'evis_SeasonLongSynopsis'], null)
  const bannerId1 = get(meta, ['values', 'banner_1'])

  const [metaName] = webApp.utils.titles(meta)
  const isSp = useMediaQuery()

  // 許諾番号表示のため
  const firstEpisode = episodes[0]
  const courseId = (() => {
    if (!firstEpisode) return null

    const firstEpisodeCourses =
      get(howToPlays, [firstEpisode.meta_id, 'courses']) || []
    return firstEpisodeCourses.length ? firstEpisodeCourses[0].course_id : null
  })()

  /**
   * child_episode_idsを抽出し、metaの内容に含めてセット
   * FilterSortのフィルタで使用するため
   */
  const getChildEpisodeIds = (otherSeasonMetas = {}) => {
    const childEpisodeIds = get(meta, ['values', 'child_episode_ids'], [])

    if (!size(otherSeasonMetas) === 0) return childEpisodeIds

    /** FilterSortコンポーネント用のmetaを作成 */
    const otherChildEpisodeIds = Object.values(otherSeasonMetas).flatMap(
      (otherSeasonMeta) => {
        return get(otherSeasonMeta, ['values', 'child_episode_ids'], [])
      }
    )
    return [...childEpisodeIds, ...otherChildEpisodeIds]
  }

  const searchParams = useSearchParams({
    childEpisodeIds: getChildEpisodeIds(),
    seasonIds: [seasonId, ...extendSeasonIds].map(Number),
    tags: [],
    sortedBy: 'delivery_start_at_newer',
    pagerOptions: {
      episodesPerPages: 60,
      range: 2,
      showBottom: true
    }
  })

  /** 他のシーズンに属するエピソードのID(child_episode_ids)がほしいので取得 */
  const getOtherSeasonMetas = () => {
    if (!useInProgramPage || !extendSeasonIds) return Promise.reject()

    const path = ['metas', extendSeasonIds]
    return model.fetch([path]).then((result) => {
      const seasons = get(result, ['json', 'metas'], null) // シーズンのメタ情報
      delete seasons.$__path
      Object.keys(seasons).forEach((key) => {
        if (seasons[key] === null) delete seasons[key]
      })
      return seasons
    })
  }

  /**
   * 選択中のタグを管理
   * @param e {object} イベント
   */
  const onChangeTags = (e) => {
    const { checked, value } = e.currentTarget
    const selected =
      checked === true
        ? [...searchParams.state.tags, value]
        : searchParams.state.tags.filter((tag) => tag !== value)
    searchParams.set({ tags: selected })
  }

  /** 検索エリアで使用するタグを取得 (ランダム１０子) */
  const getTags = async () => {
    /** 指定したシーズンのエピソードを取得 */
    const getAllEpisodes = (seasonIdList) => {
      const path = ['meta', 'children', seasonIdList]
      return model.fetch([path]).then((result) => {
        return seasonIdList.reduce((acc, id) => {
          const seasonEpisodes = get(result, ['json', 'meta', 'children', id])
          return [...acc, ...seasonEpisodes]
        }, [])
      })
    }

    /** 取得したエピソードのタグを取得 */
    const allEpisodes = await getAllEpisodes([
      liveArchivesSeasonId,
      specialMoviesSeasonId,
      archivesSeasonId
    ])
    const allTags = union(
      allEpisodes.reduce((acc, episode) => {
        const episodeTags = get(episode, ['tags'])
        return [...acc, ...episodeTags]
      }, [])
    )
    // ランダムに並べ替え、上から１０件を切り取って返す
    return shuffle(allTags).slice(0, 10)
  }

  useEffect(() => {
    let ignore = false

    /** 検索用に他のシーズンのエピソードIDを取得 */
    ;(async () => {
      const otherSeasonMetas = await getOtherSeasonMetas()
      searchParams.set({
        childEpisodeIds: getChildEpisodeIds(otherSeasonMetas)
      })
    })()

    /** 検索用にタグを他のシーズンも含めたタグを取得 */
    ;(async () => {
      const resTags = await getTags()
      if (!ignore.current) setTags(resTags)
    })()

    /** 計測用のclassを追加 (フリーワード検索) */
    ;(() => {
      const filterSort = document.getElementById('episodes-list')
      if (!filterSort) return

      const input = filterSort.querySelector('input[type="text"]')
      if (!input) return

      input.classList.add('gari_episode_free')
    })()

    return () => {
      ignore = true
    }
  }, [])

  /** 計測用のclassを追加 (タグ) */
  useEffect(() => {
    if (!listRef.current) return

    const listItems = listRef.current.querySelectorAll('li')
    listItems.forEach((li) => {
      li.classList.add('gari_episode_tag')
    })
  }, [tags])

  return (
    <Component.StyledDiv1 {...props}>
      <Helmet>
        <link
          rel="icon"
          href="/images/exdio/renewal/gariben_daigaku/top/gbu_favicon.webp"
        />
        <link
          rel="apple-touch-icon"
          href="/images/exdio/renewal/gariben_daigaku/top/gbu_favicon.webp"
        />
      </Helmet>
      <Header />
      <HeaderNewsComponent />
      {!isSp && <Component.StyledNotice meta={meta} />}
      <Component.StyledSection1>
        <Component.StyledDiv2>
          <Thumbnail src={thumbnailUrl} alt={metaName} />

          {isSp && <Component.StyledNotice meta={meta} />}

          {/* for PC */}
          {!isSp && (
            <AddButtonBlock
              favoriteType={FAVORITE_TYPE.META}
              favoriteId={meta.meta_id}
              title={metaName}
            />
          )}
        </Component.StyledDiv2>

        <Component.StyledDiv3>
          <Component.StyledH2>{metaName}</Component.StyledH2>

          <Component.StyledLink1 href={config.purchase_plan.gariben_daigaku}>
            月額見放題で購入
          </Component.StyledLink1>

          <Component.StyledCaption caption={seasonLongSynopsis} />

          {/* for SP */}
          {isSp && (
            <AddButtonBlock
              favoriteType={FAVORITE_TYPE.META}
              favoriteId={meta.meta_id}
              title={metaName}
            />
          )}
        </Component.StyledDiv3>
      </Component.StyledSection1>
      <Component.StyledSection2>
        <Component.StyledSection2Inner>
          {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
          {size(meta) > 0 && (
            <>
              <Component.StyledH3>単話</Component.StyledH3>
              <Component.StyledDiv4>
                <Component.StyledH4>おすすめのタグ</Component.StyledH4>
                <Component.StyledTagList
                  tags={tags}
                  selected={searchParams.state.tags}
                  changeTags={onChangeTags}
                  ref={listRef}
                />
                <Component.StyledLink2 route={routes.gariben_tag_list}>
                  タグ一覧はこちら
                </Component.StyledLink2>
              </Component.StyledDiv4>
              <FilterSort
                searchParams={searchParams}
                episodeListItemProps={{
                  showNew: true,
                  showChecked: true,
                  showCaption: true,
                  showCoin: true,
                  onlySubTitle: true
                }}
              />
            </>
          )}
          <CastInfo
            actors={actors}
            directors={directors}
            producers={producers}
            writers={writers}
            productions={productions}
            copyRight={copyRight}
            isPadding
          />
        </Component.StyledSection2Inner>
      </Component.StyledSection2>
      <Component.StyledFooter courseId={courseId} />
      <Component.GlobalStyle />
    </Component.StyledDiv1>
  )
}

export default memo(ProgramGariben)

ProgramGariben.propTypes = {
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({}).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.shape({})),
  /** episodesのhowToPlay情報 */
  howToPlays: PropTypes.shape({}),
  /** 最新話の動画ステータス */
  latestEpisodeStatus: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    limitDate: PropTypes.string
  })
}

ProgramGariben.contextTypes = {
  falcorModel: PropTypes.shape({}),
  models: PropTypes.shape({})
}
