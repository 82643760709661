import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '../../../../../../hooks/useMediaQuery'

/* components */
import ButtonList2 from '../../ButtonList2'

/* style */
import { StyledDiv, StyledP, StyledVoteForm, StyledButtonList1 } from './style'

/** Vote Component */
const Vote = ({
  meta = {},
  status = {},
  price = '',
  metaName = '',
  buttonData = {},
  isLoggedIn = false,
  ...props
}) => {
  const isSp = useMediaQuery()

  if (isLoggedIn && status.isPurchased) {
    return (
      <StyledVoteForm
        meta={meta}
        status={status}
        price={price}
        buttonData={buttonData}
        {...props}
      />
    )
  }

  /** PC && 未購入 */
  if (!isSp) return null

  /** SP && 未ログイン || 未購入 */
  const text = isLoggedIn
    ? '投票するには購入が必要です。'
    : '投票するにはログインが必要です。'
  return (
    <StyledDiv>
      <StyledP>{text}</StyledP>
      <StyledButtonList1 isLoggedIn={isLoggedIn} />
      <ButtonList2 meta={meta} metaName={metaName} />
    </StyledDiv>
  )
}

export default Vote

Vote.propTypes = {
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  price: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    PropTypes.number
  ),
  metaName: PropTypes.string,
  buttonData: PropTypes.shape({
    visible: PropTypes.bool,
    gifting_id: PropTypes.number,
    event_id: PropTypes.string,
    purchase_page_url: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        src: PropTypes.string,
        alt: PropTypes.string
      })
    )
  }),
  isLoggedIn: PropTypes.bool
}
