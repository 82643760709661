import styled from 'styled-components'
import {
  mediaQuery,
  breakPoints
} from '../../../../../../exdio/components/style'
import { breakPoints as SailGPbreakPoints } from '../../style'

export const StyledLi = styled.li`
  margin-right: 12px;
  width: calc(100% / 4 - 9px);
  float: left;

  &:nth-child(4n) {
    margin-right: 0;
  }

  ${mediaQuery(SailGPbreakPoints.sp)} {
    margin-right: 8px;
    margin-bottom: 12px;
    width: calc(100% / 2 - 4px);

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`

export const StyledDiv1 = styled.div`
  position: relative;
`

export const StyledImg1 = styled.img`
  width: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
`

export const StyledP1 = styled.p`
  padding: 0 5px;
  background: #e23b25;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 1.2rem;
  font-style: italic;
`

export const StyledDiv2 = styled.div`
  padding: 20px 0;
`

export const StyledP2 = styled.p`
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.2;
`
export const StyledI = styled.i`
  background: linear-gradient(#e23b25, #e23b25) 0 100%/0 2px no-repeat;
  position: relative;
  line-height: 1.4;
  text-decoration: none;
  transition: background 0.4s;
`

export const StyledA = styled.a`
  overflow: hidden;

  ${mediaQuery('sm', 'min')} {
    &:hover {
      ${StyledImg1} {
        opacity: 0.7;
        transition: opacity 0.3s;
      }
      ${StyledI} {
        background-size: 100% 2px;
      }
    }
  }
`

export const StyledImg2 = styled.img`
  margin: 0 auto 0 0;
`
