import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledDiv,
  StyledDl,
  StyledH4,
  StyledP,
  StyledP1,
  StyledP2
} from './style'

/** Footer｜JASRAC,NexTone,Lmark */
const Marks = ({ ...props }) => {
  const markList = [
    {
      src: '/images/exdio/renewal/icon_jasrac.png',
      alt: 'icon_jasrac',
      width: 39,
      height: 39,
      title: 'JASRAC許諾番号',
      number: '6688647068Y45038'
    },
    {
      src: '/images/exdio/renewal/icon_nextone.png',
      alt: 'icon_nextone',
      width: 39,
      height: 39,
      title: 'NexTone許諾番号',
      number: 'ID000008181'
    },
    {
      src: '/images/exdio/renewal/icon_lmark.png',
      alt: 'icon_lmark',
      width: 39,
      height: 41,
      content: (
        <React.Fragment>
          <StyledP1>
            エルマークは、レコード会社・映像制作会社が提供するコンテンツを示す登録商標です
          </StyledP1>
          <StyledP2>IRAJ60017003</StyledP2>
        </React.Fragment>
      )
    }
  ]

  return (
    <StyledDl {...props}>
      {markList.map((mark) => {
        return (
          <StyledDiv key={mark.alt}>
            <dt>
              <img
                src={mark.src}
                alt={mark.alt}
                width={mark.width}
                height={mark.height}
              />
            </dt>
            <dd>
              {mark.content ? (
                mark.content
              ) : (
                <React.Fragment>
                  <StyledH4>{mark.title}</StyledH4>
                  <StyledP dangerouslySetInnerHTML={{ __html: mark.number }} />
                </React.Fragment>
              )}
            </dd>
          </StyledDiv>
        )
      })}
    </StyledDl>
  )
}

export default Marks

Marks.propTypes = {
  /** styleの継承 */
  className: PropTypes.string
}
