import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { FAVORITE_TYPE } from '../../../../../../constants/app'
import { mediaQuery } from '../../../style'

/* components */
import Heading from '../Heading'
import DeliverPeriod from '../../details/DeliverPeriod'
import Caption from '../../details/Caption'
import PlayLink from '../PlayLink'
import ButtonLogin from '../ButtonLogin'
import AddButtonBlock from '../../details/AddButtonBlock'
import EpisodePager from '../../details/EpisodePager'
import Price from '../Price'

const Info = ({
  seriesId = '',
  seasonId = '',
  episodeId = '',
  meta = '',
  episodes = [],
  status = {
    isFree: false,
    isNotFree: false,
    isPurchased: false,
    isNotPurchased: false,
    isInCourse: false,
    isNotInCourse: false,
    isGeoDeliverable: null,
    isDeviceNotAvailable: null,
    limitDate: null,
    isPossible: null,
    isBelonging: null
  },
  product = {},
  course = {},
  productRight = {},
  onClickPlay = () => {},
  currentTabId = 'chat',
  metaName = '',
  hasChat = false,
  isLatest = false
}) => {
  return (
    <StyledInfo hasChat={hasChat} isActive={currentTabId === ''}>
      <StyledMeta>
        <Heading isLatest={isLatest}>{metaName}</Heading>
        <StyledPrice
          meta={meta}
          status={status}
          product={product}
          course={course}
        />
        <DeliverPeriod
          meta={meta}
          status={status}
          productRight={productRight}
        />
        <Caption
          caption={get(meta, ['values', 'evis_EpisodeLongSynopsis'])}
          otherInstructions={get(meta, ['values', 'avalis_OtherInstructions'])}
          actors={get(meta, ['values', 'evis_Actors'])}
          directors={get(meta, ['values', 'evis_Directors'])}
          producers={get(meta, ['values', 'evis_Producers'])}
          writers={get(meta, ['values', 'evis_Writers'])}
          productions={get(meta, ['values', 'evis_Productions'])}
        />
      </StyledMeta>
      <StyledActions>
        <StyledActionsInner>
          <StyledPlayLink
            seasonId={seasonId}
            episodeId={episodeId}
            meta={meta}
            status={status}
            product={product}
            course={course}
            onClickPlay={onClickPlay}
            disabled={false}
          />
          <StyledButtonLogin />
        </StyledActionsInner>

        <AddButtonBlock
          favoriteType={FAVORITE_TYPE.META}
          favoriteId={meta.meta_id}
          title={metaName}
        />

        <StyledEpisodePager
          meta={meta}
          seriesId={seriesId}
          seasonId={seasonId}
          episodes={episodes}
          course={course}
        />
      </StyledActions>
    </StyledInfo>
  )
}

export default Info

Info.propTypes = {
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  episodeId: PropTypes.string,
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  episodes: PropTypes.arrayOf(PropTypes.object),
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  product: PropTypes.object,
  productRight: PropTypes.object,
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),
  onClickPlay: PropTypes.func,
  currentTabId: PropTypes.string,
  metaName: PropTypes.string,
  hasChat: PropTypes.bool,
  isLatest: PropTypes.bool
}

const StyledInfo = styled.div`
  display: grid;

  ${mediaQuery()} {
    padding: 0 15px;
    display: ${({ isActive }) => (isActive ? null : 'none')};
  }

  ${({ hasChat }) =>
    hasChat &&
    css`
      grid-template-areas: none;
      grid-template-columns: auto;
      row-gap: 30px;
    `}
`

const StyledActions = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery()} {
    margin-top: 20px;
    display: block;
  }
`

const StyledActionsInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 30px;

  ${mediaQuery()} {
    flex-direction: column;
    gap: 10px;
  }
`

const StyledPlayLink = styled(PlayLink)`
  width: calc((100% - 30px) / 2);

  ${mediaQuery()} {
    width: 100%;
  }
`

const StyledButtonLogin = styled(ButtonLogin)`
  width: calc((100% - 30px) / 2);

  ${mediaQuery()} {
    width: 100%;
  }
`

const StyledMeta = styled.div`
  font-size: 1.3rem;
  line-height: 1.6;

  ${mediaQuery()} {
    min-width: unset;
  }

  .c-more-btn {
    padding-top: 20px;
    text-align: center;
    font-weight: 600;

    ${mediaQuery()} {
      padding-top: 15px;
    }

    @media (hover: hover) {
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`

const StyledPrice = styled(Price)`
  margin-top: 20px;
`

const StyledEpisodePager = styled(EpisodePager)`
  margin-top: 15px;
  max-width: none;
  width: 460px;

  ${mediaQuery()} {
    width: 100%;
  }
`
