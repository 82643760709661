import React from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../../common/routes'
import webApp from '../../../../../utils/exdioWebAppUtils'

/* style */
import {
  StyledSpan,
  StyledDiv1,
  StyledDiv2,
  StyledLink,
  StyledDiv3,
  StyledDl,
  StyledDt,
  StyledDd,
  StyledTime,
  StyledUl2,
  StyledLi2
} from './style'

/** Item Component */
const Item = ({ meta = null, rank = null, ...props }) => {
  const {
    values,
    publish_start_at: publishStartAt,
    thumbnail_url: thumbnailUrl = '',
    tags = []
  } = meta
  const { avails_EpisodeTitleDisplayUnlimited: name } = values
  const duration = webApp.utils.duration(meta)
  const date = new Date(publishStartAt)
  const publishYear = date.getFullYear()
  const publishMonth = date.getMonth() + 1
  const publishDate = date.getDate()
  const publish = `${publishYear}年${publishMonth}月${publishDate}日`
  const dateTime = `${publishYear}-${publishMonth}-${publishDate}`

  const linkProps = {
    route: routes.episode,
    params: {
      seriesId: _.get(values, ['parents_series', 'id'], ''),
      seasonId: _.get(values, ['parents_season', 'id'], ''),
      episodeId: _.get(meta, ['id'], '')
    }
  }

  /**
   * タッチ端末判別
   * 画面幅に関わらずタッチ端末のときはランク文字をホバー色にする
   * https://media-plex.backlog.jp/view/DGA2-1702#comment-1402570870
   */
  const isTouchScreenDevice = webApp.utils.isSpAndIPad()

  return (
    <StyledDiv1 {...props}>
      <StyledSpan isTouchable={isTouchScreenDevice}>{rank}</StyledSpan>
      <StyledDiv2>
        {/* // HACK gtm-gari-rankはGTMでの計測用class */}
        <StyledLink className={`gtm-gari-rank${rank}`} {...linkProps}>
          <StyledDiv3>
            <img src={thumbnailUrl} alt="" />
            <span>{duration}</span>
          </StyledDiv3>
          <StyledDl>
            <StyledDt>{name}</StyledDt>
            <StyledDd>
              <StyledTime dateTime={dateTime}>{publish}</StyledTime>
              <StyledUl2>
                {tags.map((tag) => (
                  <StyledLi2 key={tag}>{tag}</StyledLi2>
                ))}
              </StyledUl2>
            </StyledDd>
          </StyledDl>
        </StyledLink>
      </StyledDiv2>
    </StyledDiv1>
  )
}

export default Item

Item.propTypes = {
  meta: PropTypes.object.isRequired,
  rank: PropTypes.number
}
