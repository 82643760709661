import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled, { keyframes } from 'styled-components'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import { ARTICLE_SCHEMAS } from '../../../../../../constants/app'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import routes from '../../../../routes'

/** style */
import { fixColors, mediaQuery } from '../../../../../exdio/components/style'

/** ヘッダー > ハンバーガーメニュ > ニュース > 各記事 */
const NewsContent = (
  { article, onClick, intervalTime = 5000, ...props },
  context
) => {
  const isApp = webApp.utils.isApp(context)
  const schema = Object.values(ARTICLE_SCHEMAS).find(
    (s) => s.id === article.schema_id
  )
  const publishStartAt = moment(article.publish_start_at).format('YYYY/MM/DD')

  return (
    <StyledLink
      key={article.id}
      route={routes[isApp ? 'app_information' : 'information']}
      onClick={onClick}
      hash={`news-${article.id}`}
      {...props}
    >
      <StyledDiv intervalTime={intervalTime + 1}>
        <StyledSpanDate>{publishStartAt}</StyledSpanDate>
        <StyledSpanBadge backgroundColor={schema.color}>
          {schema.label}
        </StyledSpanBadge>
        <StyledSpanTitle>{article.display_name}</StyledSpanTitle>
      </StyledDiv>
    </StyledLink>
  )
}

export default NewsContent

NewsContent.propTypes = {
  /** お知らせ */
  article: PropTypes.shape({
    id: PropTypes.number,
    display_name: PropTypes.string,
    schema_id: PropTypes.number,
    publish_start_at: PropTypes.string
  }),
  /** GlobalNavigation.js > showSubMenu/showSearch/showMemberMenu 開閉処理 */
  onClick: PropTypes.func,
  /** フェードインアウトアニメーションの時間設定 */
  intervalTime: PropTypes.number
}

NewsContent.contextTypes = {
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  })
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const StyledLink = styled(Link)`
  display: block;
  padding: 1.6rem 2rem;
  color: ${fixColors.colorFont};
  &:hover {
    opacity: 0.7;
    transition: 0.3s;

    span {
      border-bottom: none;
    }
  }

  &:visited {
    color: ${fixColors.colorFont};
  }
`

const StyledDiv = styled.div`
  animation: ${fadeIn} ${({ intervalTime }) => `${intervalTime}ms`} ease-in 0s
    forwards;
  background: #fff;
  margin-top: 1px;
  color: ${fixColors.colorFont};
`

const StyledSpanDate = styled.span`
  font-size: 1.1rem;
  ${mediaQuery('mm')} {
    color: ${fixColors.colorFont};
  }
`

const StyledSpanBadge = styled.span`
  padding: 0.2em 0.6em;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  background: #f87b98;
  border-radius: 11px;
  background-color: ${({ backgroundColor }) => backgroundColor || ''};
  margin-left: 0.5rem;
`

const StyledSpanTitle = styled.span`
  font-size: 1.6rem;
  border-bottom: 1px solid ${fixColors.colorFont};
  line-height: 1.4;

  &::before {
    content: '';
    display: block;
    margin-top: 0.9rem;
  }
`
