import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** ニックネーム編集 */
export default class AccountEditProfile extends Component {
  static propTypes = {};
  static defaultProps = {};
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};

    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit() {}

  goBack(e) {
    e.preventDefault();
    this.context.history.goBack();
  }

  render() {
    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <div className="c-mypageAccount">
            <header className="c-mypageAccount-header">
              <a href="#" className="c-mypage-btnBack alwaysOn" onClick={this.goBack}>
                戻る
              </a>
              <h3 className="c-mypageAccount-header-title">アカウント情報</h3>
            </header>
            <div className="c-mypageAccount-cont">
              <div className="c-mypageAccount-cont-personal">
                <div className="c-mypageAccountEditNickName">
                  <div className="c-mypageAccountEditNickName-icons">
                    <div className="c-mypageAccountEditNickName-icons-icon">
                      <img
                        src="/images/exdio/renewal/prof_icon.png"
                        className="c-mypageAccountEditNickName-icons-icon-img"
                      />
                    </div>
                    <div className="c-mypageAccountEditNickName-icons-camera">
                      <img src="/images/exdio/renewal/icon_camera.svg" />
                    </div>
                  </div>
                  <div className="c-mypageAccountEditNickName-nickname">
                    <div className="c-mypageAccountEditNickName-nickname-inBox">
                      <label htmlFor="nickname" className="c-inputLabel">
                        ニックネーム
                      </label>
                      <input type="text" name="nickname" placeholder="ロッポンギ" className="c-inputText" />
                      <p className="c-mypageAccountEditNickName-nickname-inBox-error">
                        ＊このニックネームは使用されています
                      </p>
                    </div>
                  </div>
                  <div className="c-mypageAccountEditNickName-btnSave">
                    <a href="#" className="c-mypageAccountEditNickName-btnSave-link" onClick={this.onSubmit}>
                      保存する
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
