import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import webApp from '../../../utils/exdioWebAppUtils'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import { mediaSp } from './styles'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 動画、番組リスト要素コンポーネント */
const ProgramItem = (
  { meta, route, params, query, href, isSliderItem, ...props },
  context
) => {
  const config = context.models.config.data
  const [subTitle, setSubTitle] = useState('')
  const [thumbnailUrl, setThumbnailUrl] = useState([])
  const [thumbnailAlt, setThumbnailAlt] = useState([])

  useEffect(() => {
    const [title, subTitle] = getTitle()
    const [thumbUrl, thumbAlt] = getThumbnail()
    setSubTitle(subTitle)
    setThumbnailUrl(thumbUrl)
    setThumbnailAlt(thumbAlt)
  }, [meta])

  /** タイトル */
  const getTitle = () => {
    return webApp.utils.titles(meta)
  }

  /** サムネイル画像 */
  const getThumbnail = () => {
    if (meta) {
      const thumbnailUrl = webApp.utils.customSizeImageUrl(
        meta.thumbnail_url,
        'large'
      )
      const [title, subTitle] = getTitle()
      const thumbnailAlt = `${title} ${subTitle}`
      return [thumbnailUrl || config.default_thumbnail, thumbnailAlt]
    }

    return [config.default_thumbnail, thumbnailAlt]
  }

  return (
    <StyledLink
      route={route}
      params={params}
      query={isSliderItem ? { ...query, slider: null } : query}
      href={href}
      className="shinchan_new_api"
      {...props}
    >
      <StyledSpan>
        <StyledImg
          src={thumbnailUrl}
          alt={thumbnailAlt}
          width="200"
          height="113"
          loading="lazy"
        />
      </StyledSpan>
      <StyledP>{subTitle}</StyledP>
    </StyledLink>
  )
}

export default memo(ProgramItem)

ProgramItem.propTypes = {
  // 主情報
  /** メタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  route: PropTypes.object,
  params: PropTypes.shape({
    seriesId: PropTypes.number,
    seasonId: PropTypes.number,
    episodeId: PropTypes.number
  }),
  query: PropTypes.object,
  href: PropTypes.string,
  /** 新着スライダーで使用する場合、?sliderパラメータを付与 */
  isSliderItem: PropTypes.bool
}

ProgramItem.defaultProps = {
  meta: null,
  route: {},
  params: {},
  query: {},
  href: '',
  isSliderItem: false
}

ProgramItem.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}

export const StyledSpan = styled.span`
  background-color: #aee536;
  display: block;
`

export const StyledImg = styled.img`
  width: 100%;
  transition: opacity 0.3s;
`

export const StyledLink = styled(Link)`
  display: block;
  color: #333;
  font-size: 1.3rem;
  font-weight: bold;
  transition: color 0.3s;

  @media ${mediaSp} {
    font-size: 1.1rem;
  }

  &:hover {
    color: rgba(51, 51, 51, 0.7);

    ${StyledImg} {
      opacity: 0.5;
    }
  }
`

export const StyledP = styled.p`
  margin-top: 4px;
  line-height: 1.5;
`
