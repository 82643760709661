import styled from 'styled-components'
import { mediaQuery } from '../../../../style'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

export const StyledDiv = styled.div`
  margin: 0 auto 15px;
  padding: 24px 0 12px;
  width: 1280px;
  height: auto;
  color: #636363;
  font-size: 11px;
  font-style: italic;
  text-align: right;

  ${mediaQuery(1320)} {
    width: 960px;
  }
`

export const StyledP = styled.p`
  display: inline-block;
  &::after {
    margin: 0 3px;
    content: '>';
  }
`

export const StyledLink = styled(Link)`
  &:hover {
    color: #ffb900;
    text-decoration: underline;
  }
`
