import React from 'react'
import PropTypes from 'prop-types'
import { size } from 'lodash'
import SlideItem from '../SlideItem'
import { StyledLink, StyledSlider } from './style'

/** Section1｜スライド */
const InfoSlider = ({ news = [] }) => {
  const sliderSetting = {
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          arrows: false
        }
      }
    ]
  }

  return (
    <StyledSlider className="lazyload" {...sliderSetting}>
      {news.map((item) => {
        const {
          name = '',
          href = '',
          route = {},
          params = {},
          query = ''
        } = item
        return size(route) > 0 ? (
          <StyledLink key={name} route={route} params={params} query={query}>
            <SlideItem item={item} />
          </StyledLink>
        ) : (
          <StyledLink key={name} href={href} target="_blank">
            <SlideItem item={item} />
          </StyledLink>
        )
      })}
    </StyledSlider>
  )
}

export default InfoSlider

InfoSlider.propTypes = {
  /** 更新情報のリスト
   * @param {string} type - 情報のタイプ"動画"か"ブログ"
   * @param {string} thumbnail - サムネ画像のパス
   * @param {string} dateTime - 投稿日
   * @param {string} name - タイトル
   * @param {string} params - Linkに渡すためのパラメータ
   */
  news: PropTypes.arrayOf(PropTypes.object)
}
