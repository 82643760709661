import React, { Component } from 'react';
import PropTypes from 'prop-types';
import window from 'global';
import routes from '../../../../apps/common/routes';
import webApp from '../../utils/exdioWebAppUtils';
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation';
import HeaderNewsComponent from './HeaderNewsComponent'

/**
  iframe処理をするためのコンポーネント
  他のところで基盤連携のために同じようなコンポーネントがあるが、
　後から静的な２ページが追加されたので、
　新たに共通で使うコンポーネントとして用意した。
*/
export default class PlainIframeContent extends Component {
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    webApp: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.config = context.models.config.data;

    this.state = {
      url: null
    };

    this.onBack = this.onBack.bind(this);
  }

  componentDidMount() {
    this.setIframeUrl();

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context);

    // GTMの更新
    const title = this.context.models.config.data.default_title;
    const [program] = title === undefined ? [''] : title.split(' | ');
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ];
    this.context.webApp.utils.updateDataLayer(gtmTags);

    // 子フレームから呼ばれる用のメソッドをセット
    window.onBack = this.onBack;

    // リスナーを追加する
    window.addEventListener('message', this.onBack);
  }

  componentWillUnmount() {
    // リスナーを削除する
    window.removeEventListener('message', this.onBack);
  }

  onBack(e) {
    if (e.data.action === 'onBack') {
      // 今の所戻る処理の指定はないので何もしない
    }
  }

  setIframeUrl() {
    let url = '';

    // 下記画面はルートから表示モードを切り替える必要ある
    if (this.isMatch(location, routes.wmquick_return)) {
      url = this.config.static_links.wmquick_return.replace(':host', webApp.utils.infraHost(this.context));
    } else if (this.isMatch(location, routes.purchase_cancel)) {
      url = this.config.static_links.purchase_cancel.replace(':host', webApp.utils.infraHost(this.context));
    }
    this.setState({ url });
  }

  isMatch(location, route) {
    const routePath = route.makePath();
    // context.history.replaceで遷移していくるとlocation.pathnameが正しく取れないのでmatchを優先的に使う
    if (typeof location.match === 'function') {
      return location.match(routePath);
    }
    const locationPath = location.pathname;
    return routePath === locationPath;
  }

  render() {
    const { url } = this.state;
    return (
      <React.Fragment>
        <div className="common-wrapper">
          <HeaderNewsComponent />
          <SpSubNavigation spOff />
          <div className="ex-base">
            {url && <iframe src={url} />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
