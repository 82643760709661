import App from '../../sketch-platform/app/App'
import routeHandlers from './routeHandlers'
import routes from '../common/routes'
import createApp from './createApp'

import namespace from '../../common/namespace'
import Catchup from './components/browse/templates/Catchup'
import TemplateApp from './components/browse/templates/TemplateApp'
import Default from './components/browse/templates/Catchup'
import Template1 from './components/browse/templates/Template1'
import Live from './components/browse/templates/Live'
import AppLive from './components/browse/templates/LiveApp'

import EpisodeDefault from './components/browse/templates/EpisodeDefault'
import EpisodePcModeOnly from './components/browse/templates/EpisodePcModeOnly'
import EpisodeGrid from './components/browse/templates/EpisodeGrid'
import EpisodeDoraemon from './components/browse/EpisodeDoraemon'
import EpisodeShinchan from './components/browse/templates/EpisodeShinchan'
import EpisodeGariben from './components/browse/EpisodeGariben'
import EpisodeLiveGariben from './components/browse/EpisodeLiveGariben'
import EpisodeLogirl from './components/browse/templates/EpisodeLogirl'
import EpisodeHarbor from './components/browse/Harbor/Episode'
import EpisodeSail from './components/browse/SailGP/Episode'
import EpisodePack from './components/browse/templates/EpisodePack'
import EpisodeBritish from './components/browse/templates/EpisodeBritish'
import EpisodeHiramekinotane from './components/browse/Hiramekinotane/Episode'
import EpisodeSodaTvGlobal from './components/browse/templates/EpisodeSodaTvGlobal'
import ProgramDefault from './components/browse/templates/ProgramDefault'
import ProgramGrid from './components/browse/templates/ProgramGrid'
import ProgramDoraemon from './components/browse/templates/ProgramDoraemon'
import ProgramHarbor from './components/browse/Harbor/Program'
import ProgramSailGp from './components/browse/SailGP/Program'
import ProgramGariben from './components/browse/ProgramGariben'
import ProgramShinchan from './components/browse/templates/ProgramShinchan'
import ProgramLogirl from './components/browse/templates/ProgramLogirl'
import ProgramPack from './components/browse/templates/ProgramPack'
import ProgramBritish from './components/browse/templates/ProgramBritish'
import ProgramHiramekinotane from './components/browse/Hiramekinotane/Program'
import ProgramSodaTvGlobal from './components/browse/templates/ProgramSodaTvGlobal'
import EpisodeLive from './components/browse/EpisodeLive'
import EpisodeLiveShinchan from './components/browse/templates/EpisodeLiveShinchan'
import EpisodeLiveLogirl from './components/browse/templates/EpisodeLiveLogirl'
import PackDefault from './components/browse/templates/PackDefault'
import PlanDefaultContent from './components/browse/PlanDefaultContent'
import PlanDoraemonContent from './components/browse/PlanDoraemonContent'
import PlanShinchanContent from './components/browse/PlanShinchanContent'
import PlanLogirlContent from './components/browse/PlanLogirlContent'
import PlanGaribenContent from './components/browse/PlanGaribenContent'
import GenreDefault from './components/browse/templates/GenreDefault'
import EpisodeAppDefault from './components/browse/templates/EpisodeAppDefault'
import EpisodeAppPack from './components/browse/templates/EpisodeAppPack'
import EpisodeAppDoraemon from './components/browse/EpisodeDoraemon/App'
import EpisodeAppShinchan from './components/browse/templates/EpisodeAppShinchan'
import EpisodeAppLogirl from './components/browse/templates/EpisodeAppLogirl'
import EpisodeAppHiramekinotane from './components/browse/templates/EpisodeAppHiramekinotane'
import ProgramAppDefault from './components/browse/templates/ProgramAppDefault'
import ProgramAppShinchan from './components/browse/templates/ProgramAppShinchan'
import ProgramAppLogirl from './components/browse/templates/ProgramAppLogirl'
import PackAppDefault from './components/browse/templates/PackAppDefault'
import PlanAppDefault from './components/browse/templates/PlanAppDefault'

/** TEST */
import EpisodeTemplateTest from './components/browse/templates/EpisodeTemplateTest'
import ProgramTemplateTest from './components/browse/templates/ProgramTemplateTest'
import PackTemplateTest from './components/browse/templates/PackTemplateTest'
import PlanTemplateTest from './components/browse/templates/PlanTemplateTest'
import GenreTemplateTest from './components/browse/templates/GenreTemplateTest'

// デフォルトテンプレ
namespace.templates = namespace.templates || {}
namespace.templates.catchup = Catchup
namespace.templates.app = TemplateApp
namespace.templates.default = Default

// テスト
namespace.templates['1'] = Template1
// 番組一覧
namespace.templates['2'] = Default
// ライブ
namespace.templates['3'] = Live
namespace.templates['3-app'] = AppLive

// 有料移行
// キー:(単話/番組/パック/月額見放題)_${ex_templateId}

// episode
namespace.templates.episode_0 = EpisodeDefault
namespace.templates.episode_1 = EpisodeGrid
namespace.templates.episode_2 = EpisodeDefault // 旧Dioで使用していたため、欠番扱い
namespace.templates.episode_3 = EpisodePack
namespace.templates.episode_4 = EpisodePcModeOnly

namespace.templates.episode_100 = EpisodeDoraemon
namespace.templates.episode_200 = EpisodeShinchan
namespace.templates.episode_300 = EpisodeLogirl
namespace.templates.episode_301 = EpisodeLiveLogirl
namespace.templates.episode_400 = EpisodeGariben
namespace.templates.episode_401 = EpisodeLiveGariben
namespace.templates.episode_500 = EpisodeSail
namespace.templates.episode_600 = EpisodeHarbor
namespace.templates.episode_700 = EpisodeBritish
namespace.templates.episode_800 = EpisodeHiramekinotane
namespace.templates.episode_900 = EpisodeSodaTvGlobal
namespace.templates.episode_1000 = EpisodeLive
namespace.templates.episode_1100 = EpisodeLiveShinchan

// program
namespace.templates.program_0 = ProgramDefault
namespace.templates.program_1 = ProgramGrid
namespace.templates.program_3 = ProgramPack

namespace.templates.program_100 = ProgramDoraemon
namespace.templates.program_200 = ProgramShinchan
namespace.templates.program_300 = ProgramLogirl
namespace.templates.program_400 = ProgramGariben
namespace.templates.program_500 = ProgramSailGp
namespace.templates.program_600 = ProgramHarbor
namespace.templates.program_700 = ProgramBritish
namespace.templates.program_800 = ProgramHiramekinotane
namespace.templates.program_900 = ProgramSodaTvGlobal

// pack
namespace.templates.pack_0 = PackDefault

// plan
namespace.templates.plan_0 = PlanDefaultContent
namespace.templates.plan_100 = PlanDoraemonContent
namespace.templates.plan_200 = PlanShinchanContent
namespace.templates.plan_300 = PlanLogirlContent
namespace.templates.plan_400 = PlanGaribenContent

// genre
namespace.templates.genre_0 = GenreDefault

// episode_app
namespace.templates.episode_app_0 = EpisodeAppDefault
namespace.templates.episode_app_2 = EpisodeAppDefault
namespace.templates.episode_app_3 = EpisodeAppPack
namespace.templates.episode_app_4 = EpisodePcModeOnly
namespace.templates.episode_app_100 = EpisodeAppDoraemon
namespace.templates.episode_app_200 = EpisodeAppShinchan
namespace.templates.episode_app_300 = EpisodeAppLogirl
namespace.templates.episode_app_301 = EpisodeLiveLogirl
namespace.templates.episode_app_800 = EpisodeAppHiramekinotane
namespace.templates.episode_app_900 = EpisodeSodaTvGlobal
namespace.templates.episode_app_1000 = EpisodeLive
namespace.templates.episode_app_1100 = EpisodeLiveShinchan

// program_app
namespace.templates.program_app_0 = ProgramAppDefault
namespace.templates.program_app_3 = ProgramAppDefault
namespace.templates.program_app_4 = ProgramAppDefault
namespace.templates.program_app_100 = ProgramDoraemon
namespace.templates.program_app_200 = ProgramAppShinchan
namespace.templates.program_app_300 = ProgramAppLogirl
namespace.templates.program_app_900 = ProgramSodaTvGlobal

// pack_app
namespace.templates.pack_app_0 = PackAppDefault

// plan_app
namespace.templates.plan_app_0 = PlanAppDefault
namespace.templates.plan_app_100 = PlanDoraemonContent
namespace.templates.plan_app_200 = PlanShinchanContent

/** TEST */
namespace.templates.episode_99999 = EpisodeTemplateTest
namespace.templates.program_99999 = ProgramTemplateTest
namespace.templates.pack_99999 = PackTemplateTest
namespace.templates.plan_99999 = PlanTemplateTest
namespace.templates.genre_99999 = GenreTemplateTest

const discoveryApp = createApp()

export default new App(
  {
    routeHandlers,
    routes
  },
  {
    handleCreateContext(context) {
      return Object.assign({}, context, {
        discoveryApp,
        discoveryStore: discoveryApp.getStore()
      })
    }
  }
)
