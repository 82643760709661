import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledDd,
  StyledDl,
  StyledDt,
  StyledLink,
  StyledP,
  StyledSpan
} from './style'

/** MV｜お知らせ */
const Notice = ({ notice = {}, noticeCharacterLength = 0, ...props }) => {
  return (
    <StyledLink href={notice.values.link_url} {...props}>
      <StyledDl>
        <StyledDt>
          <StyledSpan>お知らせ</StyledSpan>
        </StyledDt>
        <StyledDd>
          <StyledP
            // 計測用class名 https://media-plex.backlog.jp/view/DGA2-1752
            className="gtm-gari-popup"
            isAnimate={notice.display_name.length > noticeCharacterLength}
          >
            {notice.display_name}
          </StyledP>
        </StyledDd>
      </StyledDl>
    </StyledLink>
  )
}

export default Notice

Notice.propTypes = {
  /** お知らせの情報
   * @param {object} values.link_url - お知らせのリンク
   * @param {string} display_name - お知らせの題名
   */
  notice: PropTypes.shape({
    values: PropTypes.object,
    display_name: PropTypes.string
  }),
  /** notice.display_nameの文字数がこれを超えたときアニメーションが発火 */
  noticeCharacterLength: PropTypes.number,
  /** styleの継承 */
  className: PropTypes.string
}
