import _ from 'lodash'
import moment from 'moment'
import window from 'global'
import Cookie from 'js-cookie'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  LOCAL_STORAGE_KEY_PURCHASE,
  PURCHASE_TYPE,
  META_SCHEMA_ID,
  EPISODE_DISPLAY_MODE
} from '../../../../../constants/app'

/**
 * 動画の種類を判別
 * @param {Object} displayMode
 * @return {'FREE'|'TVOD'|'SVOD'|'UNKNOWN'} 動画のタイプを返す
 */
export const whatKindOfVideo = (displayMode) => {
  switch (displayMode) {
    case EPISODE_DISPLAY_MODE.FREE:
    case EPISODE_DISPLAY_MODE.TVOD_FREE:
    case EPISODE_DISPLAY_MODE.SVOD_FREE:
    case EPISODE_DISPLAY_MODE.STVOD_FREE:
      return 'FREE'
    case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
    case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
      return 'TVOD'
    case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
    case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
      return 'SVOD'
    case EPISODE_DISPLAY_MODE.UNKNOWN:
    default:
      return 'UNKNOWN'
  }
}

/**
 * 視聴権の確認
 * STVOD_TVOD_NOT_FREE, STVOD_SVOD_NOT_FREEっていうキーがあるなら`asSvod`いらなくない？
 * @param {Object} props Episodeテンプレートのprops
 * @param {Object} context
 * @param {Boolean} asSvod STVODのとき、SVODとして処理するか
 * @return {'PLAY'|'PURCHASE_EPISODE'|'PURCHASE_PLAN'|'NOT_PLAY'} 動画に対するアクション
 */
export const checkAvailableStatus = (props, context, asSvod = false) => {
  const { status, product } = props
  const purchaseAvailable = {
    0: 'PLAY',
    1: 'PURCHASE_EPISODE',
    2: 'PURCHASE_PLAN',
    3: 'NOT_PLAY'
  }
  const kindOfVideo = whatKindOfVideo(status.displayMode)

  // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
  // https://logiclogic.backlog.jp/view/EX_DIO-1467
  const withValidPreviewToken = _.get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  if (withValidPreviewToken) {
    return purchaseAvailable[0] // PLAY
  }

  // 念の為購入できないコンテンツはブロックする
  if (!status.isFree && !status.isPurchseAvailable) {
    return purchaseAvailable[3] // NOT_PLAY
  }

  // 端末・地域制限に引っかかる場合は反応させない
  if (!status.isGeoDeliverable || status.isDeviceNotAvailable) {
    return purchaseAvailable[3] // NOT_PLAY
  }

  // 購入済み or 無料 の場合は再生
  if (status.isPurchased || kindOfVideo === 'FREE') {
    return purchaseAvailable[0] // PLAY
  }

  if (kindOfVideo === 'TVOD') {
    if (asSvod) {
      // STVODでSVODボタンが押された場合
      return purchaseAvailable[2] // PURCHASE_PLAN
    }

    return product && !webApp.utils.isOnSale(context, product)
      ? purchaseAvailable[3] // 商品が販売期間外
      : purchaseAvailable[1] // PURCHASE_EPISODE
  }

  if (kindOfVideo === 'SVOD') {
    return purchaseAvailable[2] // PURCHASE_PLAN
  }

  return purchaseAvailable[3] // NOT_PLAY
}

/**
 * 動画の再生、ログの送信
 * @param {Function} sendPlayLog
 * @return {Void}
 */
export const play = (playerRef, sendPlayLog) => {
  if (playerRef) playerRef.play()
  // 再生ログ送信
  if (typeof sendPlayLog === 'function') sendPlayLog()
}

/**
 * TVOD購入ページへの遷移
 * @param {Object} props Episodeテンプレートのprops
 * @param {Object} context
 * @return {Void}
 */
export const purchaseEpisode = (props, context) => {
  const { episodeId, seasonId } = props
  window.localStorage.setItem(
    LOCAL_STORAGE_KEY_PURCHASE,
    JSON.stringify({
      type: PURCHASE_TYPE.EPISODE,
      id: Number(seasonId),
      selected: [Number(episodeId)],
      page: context.routeHandler.url
    })
  )
  context.history.push(routes.purchase.makePath())
}

/**
 * SVOD購入ページへの遷移
 * @param {Object} props Episodeテンプレートのprops
 * @param {Object} context
 * @return {Void}
 */
export const purchasePlan = (props, context, originalPageUrl) => {
  // 購入時の遷移で独自ページ移動する場合は、originalPageでurlを渡すことで独自ページに遷移する。
  if (originalPageUrl) {
    const purchasePath = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? `https://st-www.tvasahi.jp/douga_mv/purchase/${originalPageUrl}/`
      : `https://wws.tv-asahi.co.jp/douga_mv/purchase/${originalPageUrl}/`
    window.location.href = purchasePath
    return
  }

  const { course } = props
  const courseIdNum = Number(course.course_id)
  window.localStorage.setItem(
    LOCAL_STORAGE_KEY_PURCHASE,
    JSON.stringify({
      type: PURCHASE_TYPE.PLAN,
      id: courseIdNum,
      selected: [courseIdNum],
      page: context.routeHandler.url
    })
  )
  context.history.push(routes.purchase.makePath())
}

/**
 * 料金
 * @pram {Object} meta
 * @pram {Object} product
 * @pram {Object} status
 * @pram {Object} course
 * @return {String[]} [単一価格, 月額見放題価格]
 */
export const getPrice = (meta, product, status, course) => {
  const kindOfVideo = whatKindOfVideo(status.displayMode)
  if (!meta) return [null, null]
  switch (kindOfVideo) {
    case 'FREE':
      return ['無料', null]
    case 'TVOD':
      if (status.displayMode === EPISODE_DISPLAY_MODE.TVOD_NOT_FREE) {
        return [_.get(product, ['active_pricing', 'price']) || null, null]
      }
      if (EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE) {
        return [
          _.get(product, ['active_pricing', 'price']) || null,
          _.get(course, ['active_pricing', 'price']) || null
        ]
      }
      return [null, null]
    case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
    case 'SVOD':
      return [null, _.get(course, ['active_pricing', 'price']) || null]
    case 'UNKNOWN':
    default:
      return [null, null]
  }
}

/**
 * サムネイルの取得
 * @param {{ meta, programMeta }} props Episodeテンプレートのprops
 * @param {Object} context
 * @param {Object} config
 * @param {Array} previewUrlList プレビュー判別に使う何か
 * @param {String} cookieRubocopPrefix プレビュー判別に使う何か
 * @return {String} サムネイル画像のパス
 */
export const getThumbnailUrl = (
  props,
  context,
  config,
  previewUrlList,
  cookieRubocopPrefix
) => {
  const { meta, programMeta } = props
  const isLive =
    meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
    meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
  const withValidPreviewToken = _.get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  const isBefore = Boolean(
    !withValidPreviewToken && moment(prevTime).isBefore(meta.delivery_start_at)
  )
  const isAfter = Boolean(
    !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
  )
  const defaultThumb = _.get(config, ['default_thumbnail'])
  const programThumb = _.get(programMeta, ['thumbnail_url'])

  if (isLive) {
    switch (true) {
      case isBefore:
        return meta.values.livectl_image_before_stream_url || defaultThumb
      case isAfter:
        return meta.values.livectl_image_after_stream_url || defaultThumb
      default:
        return meta.thumbnail_url || defaultThumb
    }
  } else {
    switch (true) {
      case isBefore:
        return meta.thumbnail_url || defaultThumb
      case isAfter:
        return programThumb || '/images/exdio/main_1920.jpg'
      default:
        return meta.thumbnail_url || defaultThumb
    }
  }
}

/**
 * 権限的に再生可能か
 * @param {Object} status
 * @return {Boolean} 再生可能か
 */
export const isPlayable = (status) => {
  if (!status.displayMode) return true
  if (status.isPurchased) return true
  switch (status.displayMode) {
    case EPISODE_DISPLAY_MODE.SVOD_FREE:
    case EPISODE_DISPLAY_MODE.STVOD_FREE:
    case EPISODE_DISPLAY_MODE.FREE:
    case EPISODE_DISPLAY_MODE.TVOD_FREE:
      return true
    default:
      return false
  }
}
