import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../common/routes'
import webApp from '../../../../utils/exdioWebAppUtils'
import useIsMounted from '../../../../../hooks/useIsMounted'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import styled from 'styled-components'
import Footer from '../../PlanGariben/Footer'
import { mediaQuery } from '../../../style'

/** ガリベン大学アニメ祭り エラーページ */
const GaribenAnimeMatsuriError = ({ ...props }, context) => {
  const config = context.models.config.data
  const isMounted = useIsMounted()

  const updateMetaTags = () => {
    const title =
      'エラーページ | ABEMAアニメ祭「ガリベンガーV」スペシャルステージチケットを私立ガリベン大学会員限定で先行発売中！| テレ朝動画'
    webApp.utils.updateTitle(title)

    const description =
      '9月15日（日）にABEMAアニメ祭で行われる「ガリベンガーV」スペシャルステージのチケットを私立ガリベン大学会員限定で先行発売中！'
    const metaTags = {
      names: [
        { name: 'description', content: description },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'og:title', content: title },
        { property: 'og:description', content: description }
      ]
    }
    webApp.utils.updateMeta(metaTags)
  }

  useEffect(() => {
    if (isMounted) {
      updateMetaTags()
    }
  }, [isMounted])

  if (!isMounted) return null

  return (
    <>
      <StyledMain {...props}>
        <StyledH2>
          先行販売チケット購入は私立ガリベン大学会員限定になります
          <br />
          会員登録はこちらからお願いします
        </StyledH2>
        <StyledA1
          href={`${config.purchase_plan.gariben_daigaku}?animematsuri`}
          target="_blank"
        >
          私立ガリベン大学に会員登録する
        </StyledA1>
        <StyledP>
          別のアカウントでログインしている可能性がある方は
          <br />
          こちらより再度ログインしてください
        </StyledP>
        <StyledLink1
          route={routes.login}
          query={{ redirect: routes.gariben_anime_matsuri.makePath() }}
        >
          ログイン
        </StyledLink1>
        <StyledLink2 route={routes.plan} params={{ slug: 'gariben_daigaku' }}>
          戻る
        </StyledLink2>
      </StyledMain>
      <Footer />
    </>
  )
}

export default GaribenAnimeMatsuriError

GaribenAnimeMatsuriError.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}

const StyledMain = styled.main`
  margin: 0px auto;
  padding: 100px 15px;
  min-width: 320px;
  align-content: center;
  line-height: 1.5;
  text-align: center;
`

const StyledH2 = styled.h2`
  color: #707070;
  font-size: 2.4rem;
  line-height: 1.5;
  text-align: center;
`

const StyledP = styled.p`
  margin-top: 93px;
  color: #707070;
  line-height: 1.5;
  text-align: center;
`

const StyledA1 = styled.a`
  margin: 44px auto 0;
  max-width: 400px;
  width: 100%;
  height: 67px;
  background: #ff009b;
  border-radius: 9px;
  display: block;
  color: #fff !important;
  line-height: 67px;
  font-size: 2.1rem;
  text-align: center;
  transition: opacity 0.2s;

  ${mediaQuery()} {
    font-size: 1.6rem;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
      color: #fff;
    }
  }
`

const StyledLink1 = styled(Link)`
  margin: 40px auto 0;
  max-width: 240px;
  width: 100%;
  height: 40px;
  background: #343434;
  border-radius: 9px;
  display: block;
  color: #fff !important;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.2s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
      color: #fff !important;
    }
  }
`

const StyledLink2 = styled(Link)`
  margin: 62px auto 0;
  width: fit-content;
  display: block;
  color: #03a9f4 !important;
  transition: opacity 0.2s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`
