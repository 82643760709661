import React, { memo, useState, useRef, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get, size } from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  LOCAL_STORAGE_KEY_DORAEMON_PAGER,
  LOCAL_STORAGE_KEY_DORAEMON_SORT,
  LOCAL_STORAGE_KEY_DORAEMON_FILTER,
  LOCAL_STORAGE_KEY_DORAEMON_EXFILTER,
  LOCAL_STORAGE_KEY_DORAEMON_YEAR
} from '../../../../../constants/app'
import { PANEL_ID } from './config'
import { GlobalStyle } from './styles'
import browserInfo from '../../../../../sketch-platform/utils/browserInfo'

/** hooks */
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/** components */
import HtmlSnippet from '../../HtmlSnippet'
import Footer from '../../../../common/components/Footer'
import AppFooter from '../../../../common/components/appli/StaticFooter'
import Fv from './components/Fv/'
import Nav from './components/Nav/'
import NewArrivals from './components/NewArrivals'
import MonthlyTheme from './components/MonthlyTheme/'
import ProgressEpisodes from './components/ProgressEpisodes/'
import Favorites from './components/Favorites/'
import Search from './components/Search/'
import PageTop from './components/PageTop/'
import FloatingBanner from './components/FloatingBanner/'
import SnsButtons from './components/SnsButtons'
import Alert from '../../../../common/components/FilterSort/Alert'
import AnnounceModal from '../../../../common/components/renewal/AnnounceModal'

/* style */
import { mediaQuery } from '../../style'

const PlanDoraemon = (
  {
    slug = '',
    course,
    popupArticles = [],
    programMetas = {},
    progressEpisodes = [],
    favorites = [],
    toggleFavorites = () => {},
    characterItems = [],
    secretToolsItems = [],
    initialTabId = PANEL_ID.ALL,
    initialTag = [],
    initialNotTag = [],
    initialSecretTools = '',
    newArrivals = {},
    monthlyThemes = [],
    isPurchased = false,
    isAdded = false,
    stateIsShow = [false, () => {}]
  },
  context
) => {
  if (!size(context)) return null
  if (!size(programMetas)) return null

  const config = context.models.config.data
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const seasonId = config.svod.doraemon.doraemon.season_id
  const newsSnippetKey = 'doraemontv_plan_news'
  const banner1 = get(course, ['values', 'banner_1', 'id_key'], '')
  const banner2 = get(course, ['values', 'banner_2', 'id_key'], '')
  const childEpisodeIds = programMetas.flatMap((program) =>
    get(program, ['values', 'child_episode_ids'], null)
  )
  const planPageHref = `${config.host}/plan/doraemon`
  const snsHref = {
    twitter: `http://twitter.com/share?url=${planPageHref}&text=ドラえもんTV｜テレ朝動画`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${planPageHref}`,
    line: `https://social-plugins.line.me/lineit/share?url=${planPageHref}`
  }
  const isApp = webApp.utils.isApp(context)
  const device = browserInfo(navigator.userAgent, (data) => data)

  /**
   * ページャー、ソート、フィルタをlocalStorageから取得
   */
  const dataFromLocalStorage = (() => {
    // ローカルストレージの値を取得
    const pageNum =
      parseInt(
        window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_PAGER),
        10
      ) || 1
    const sortedBy =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_SORT) ||
      'avails_release_history_original_newer'
    const filteredBy =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_FILTER) || ''
    return { pageNum, sortedBy, filteredBy }
  })()

  /** useState */
  const [selectedTabId, setSelectedTabId] = useState(
    initialTabId || PANEL_ID.ALL
  ) // 選択中のタブID
  const [selectedSecretTools, setSelectedSecretTools] = useState('')
  const [exFilteredBy, setExFilteredBy] = useState({
    birthday:
      window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_EXFILTER) || ''
  })
  const [fvHeight, setFvHeight] = useState(0)

  /** useRef */
  const tabPanelRef = useRef(null)
  const isInitial = useRef(true)
  const sectionsRef = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null)
  }

  /** 検索用パラメータ */
  const searchParams = useSearchParams(
    {
      childEpisodeIds,
      seasonIds: [seasonId],
      onlyWatchedAll: true,
      tags: initialTag || [],
      notTags: initialNotTag || [],
      ...dataFromLocalStorage,
      pagerOptions: {
        episodesPerPages: 40,
        range: 2,
        showBottom: true
      },
      forceUpdateParams: (current) => {
        const params = { ...current }
        if (initialSecretTools) {
          params.condition.and = [
            ...params.condition.and,
            {
              like: [{ 'values.evis_EpisodeLongSynopsis': initialSecretTools }]
            }
          ]
        }
        return params
      }
    },
    {
      afterSearch: ({
        pageNum = 1,
        sortedBy = 'avails_release_history_original_newer',
        filteredBy = ''
      }) => {
        const ls = window.localStorage
        ls.setItem(LOCAL_STORAGE_KEY_DORAEMON_SORT, sortedBy)
        ls.setItem(LOCAL_STORAGE_KEY_DORAEMON_FILTER, filteredBy)
        ls.setItem(LOCAL_STORAGE_KEY_DORAEMON_PAGER, pageNum)
      }
    }
  )

  /** 誕生日スペシャルフィルタ機能 */
  const exFilterProps = [
    {
      filteredBy: get(exFilteredBy, ['birthday'], ''),
      updateFilteredBy: (newFilteredBy) => {
        console.log(searchParams.state.tags)
        console.log(searchParams.state.notTags)
        const stateTags = searchParams.state.tags
        const stateNotTags = searchParams.state.notTags
        const tags =
          newFilteredBy === 'birthday'
            ? ['誕生日スペシャル']
            : stateTags.filter((tag) => tag !== '誕生日スペシャル')
        const notTags =
          newFilteredBy === 'normal'
            ? ['誕生日スペシャル']
            : stateNotTags.filter((tag) => tag !== '誕生日スペシャル')

        searchParams.set({
          tags,
          notTags
        })
        window.localStorage.setItem(
          LOCAL_STORAGE_KEY_DORAEMON_EXFILTER,
          newFilteredBy
        )
        setExFilteredBy({ birthday: newFilteredBy })
      },
      options: [
        { id: 1, value: '', label: 'すべて' },
        { id: 2, value: 'normal', label: '通常回' },
        { id: 3, value: 'birthday', label: '誕生日スペシャル' }
      ],
      initialValue: 'おはなしの種類',
      slug: 'birthday'
    }
  ]

  /** 子コンポーネントでのselectedYear変更 */
  const setSelectedYear = (newSelectedYear) =>
    searchParams.set({ selectedYear: newSelectedYear })

  /** 子コンポーネントでのtags変更 */
  const setSelectedTags = (newTags) => searchParams.set({ tags: newTags })

  const resetSelectedSecretTools = (newSynopsis) => {
    setSelectedSecretTools(newSynopsis)
    searchParams.set({
      forceUpdateParams: (current) => {
        const params = { ...current }
        if (newSynopsis) {
          params.condition.and = [
            ...params.condition.and,
            {
              like: [{ 'values.evis_EpisodeLongSynopsis': newSynopsis }]
            }
          ]
        }
        return params
      }
    })
  }

  useEffect(() => {
    /** 単話ページでセットした年代ローカルストレージをクリア */
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_DORAEMON_YEAR)

    /** アプリ用のclassを消去 */
    const wrapperElm = document.querySelector('.bd.lang_ja.appli')
    if (wrapperElm) wrapperElm.classList.remove('appli')

    return () => {
      document.body.style.overflow = null
      /** アプリ用のclassを戻す */
      if (wrapperElm) wrapperElm.classList.add('appli')
    }
  }, [])

  /** タブを切り替えたとき初期化 */
  useEffect(() => {
    if (isInitial.current === true) {
      /** 初回は実行しない */
      isInitial.current = false
      return
    }

    const { routeHandler } = context
    const { query } = routeHandler

    // 不要なキーを削除
    delete query.tab
    delete query.id

    /**
     * 選択中のタブをパスパラメータに付与
     * 「すべてのおはなし」のときはtabパラメータをトル
     */
    if (selectedTabId && selectedTabId !== PANEL_ID.ALL) {
      query.tab = selectedTabId
    }

    /** 固有の初期値を設定 */
    switch (selectedTabId) {
      case PANEL_ID.CHARACTER: {
        query.id = 1
        const tag = get(characterItems, [0, 'name'], '')
        const selectedTags = [tag]

        searchParams.set({
          selectedYear: null,
          tags: selectedTags,
          forceUpdateParams: null
        })
        break
      }
      case PANEL_ID.SECRET_TOOLS: {
        query.id = 1
        const name = get(secretToolsItems, [0, 'name'], '')

        searchParams.set({
          selectedYear: null,
          tags: [],
          forceUpdateParams: (current) => {
            const params = { ...current }
            if (name) {
              params.condition.and = [
                ...params.condition.and,
                {
                  like: [{ 'values.evis_EpisodeLongSynopsis': name }]
                }
              ]
            }
            return params
          }
        })
        break
      }
      default:
        searchParams.set({
          selectedYear: null,
          tags: [],
          forceUpdateParams: null
        })
        break
    }

    /**
     * 履歴の追加、パスパラメータの書き換え
     * // NOTE
     * contextが書き換えられると再レンダリングされるため
     * context.history.replaceは使えない
     */
    const path = new URLSearchParams(query).toString()
    const url = path ? `${slug}?${path}` : slug
    window.history.replaceState(null, '', url)

    /** タブエリアまでスクロール */
    if (tabPanelRef.current) {
      tabPanelRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [selectedTabId])

  /** パレットアイテム */
  const newArrivalsObjects = get(newArrivals, ['objects'], {})

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          href="/images/exdio/renewal/doraemon/dora_favicon.webp"
        />
        <link
          rel="apple-touch-icon"
          href="/images/exdio/renewal/doraemon/favicon.webp"
        />
      </Helmet>

      <Fv isPurchased={isPurchased} setFvHeight={setFvHeight} />
      <Nav fvHeight={fvHeight} sectionsRef={sectionsRef} />

      <HtmlSnippet snippetId={banner1} />
      <HtmlSnippet snippetId={newsSnippetKey} />

      {size(progressEpisodes) > 0 && (
        <StyledDiv2>
          <ProgressEpisodes
            favorites={favorites}
            toggleFavorites={toggleFavorites}
            episodes={progressEpisodes}
          />
        </StyledDiv2>
      )}

      <StyledDiv>
        {size(newArrivalsObjects) > 0 && (
          <NewArrivals
            ref={sectionsRef.section1}
            favorites={favorites}
            toggleFavorites={toggleFavorites}
            objects={newArrivalsObjects}
            context={context}
          />
        )}

        {size(monthlyThemes) > 0 && (
          <MonthlyTheme
            ref={sectionsRef.section2}
            favorites={favorites}
            toggleFavorites={toggleFavorites}
            objects={monthlyThemes}
            context={context}
          />
        )}

        {size(favorites) > 0 && (
          <Favorites favorites={favorites} toggleFavorites={toggleFavorites} />
        )}
      </StyledDiv>

      <Search
        ref={sectionsRef.section3}
        tabPanelRef={tabPanelRef}
        selectedTabId={selectedTabId}
        setSelectedTabId={setSelectedTabId}
        selectedYear={searchParams.state.selectedYear}
        setSelectedYear={setSelectedYear}
        selectedTags={searchParams.state.tags}
        setSelectedTags={setSelectedTags}
        characterItems={characterItems}
        secretToolsItems={secretToolsItems}
        selectedSecretTools={selectedSecretTools}
        setSelectedSecretTools={resetSelectedSecretTools}
        searchParams={searchParams}
        exFilterProps={exFilterProps}
      />

      <div ref={sectionsRef.section4}>
        <HtmlSnippet snippetId={banner2} />
        <StyledSnsButtons href={snsHref} />
      </div>

      <PageTop isShowBanner={!isApp && (!isLoggedIn || !isPurchased)} />

      {isApp ? (
        <AppFooter courseId={get(course, ['course_id'])} showLogo />
      ) : (
        <Footer courseId={get(course, ['course_id'])} showLogo />
      )}

      {!isApp && (!isLoggedIn || !isPurchased) && <FloatingBanner />}

      <Alert stateIsShow={stateIsShow} isAdded={isAdded} />

      {!device.isSilk && size(popupArticles) > 0 && (
        <AnnounceModal articles={popupArticles} />
      )}

      <GlobalStyle isApp={isApp} />
    </>
  )
}

export default memo(PlanDoraemon)

PlanDoraemon.propTypes = {
  slug: PropTypes.string,
  course: PropTypes.shape({
    course_id: PropTypes.number.isRequired,
    schema_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    active_pricing: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  }).isRequired,
  popupArticles: PropTypes.arrayOf(PropTypes.object),
  programMetas: PropTypes.arrayOf(PropTypes.object),
  progressEpisodes: PropTypes.arrayOf(PropTypes.object),
  favorites: PropTypes.arrayOf(PropTypes.object),
  toggleFavorites: PropTypes.func,
  /** 「キャラクターでさがす」データ */
  characterItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      src: PropTypes.string
    })
  ),
  /** 「ひみつ道具でさがす」データ */
  secretToolsItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      img: PropTypes.string
    })
  ),
  /** 初期表示タブID */
  initialTabId: PropTypes.string,
  /** 初期表示タグ */
  initialTag: PropTypes.arrayOf(PropTypes.string),
  /** 初期表示NOTタグ */
  initialNotTag: PropTypes.arrayOf(PropTypes.string),
  /** 初期表示ひみつ道具 */
  initialSecretTools: PropTypes.string,
  isPurchased: PropTypes.bool,
  newArrivals: PropTypes.shape({
    description: PropTypes.string,
    header: PropTypes.string,
    id_key: PropTypes.string,
    name: PropTypes.string,
    objects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        meta: PropTypes.object,
        type: PropTypes.string,
        values: PropTypes.object
      })
    ),
    palette_id: PropTypes.number,
    publish_end_at: PropTypes.string,
    publish_start_at: PropTypes.string,
    schema_id: PropTypes.number,
    short_name: PropTypes.string,
    type: PropTypes.string
  }),
  monthlyThemes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.object),
      themecaption: PropTypes.string,
      themetitle: PropTypes.string
    })
  ),
  stateIsShow: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  ),
  isAdded: PropTypes.bool
}

PlanDoraemon.contextTypes = {
  history: PropTypes.object,
  routeHandler: PropTypes.object,
  falcorModel: PropTypes.object,
  models: PropTypes.object
}

// あたらしいおはなし・今月のテーマ・マイリスト 背景
const StyledDiv = styled.div`
  padding: 60px 0;
  background-color: #fee3ef;

  ${mediaQuery()} {
    padding: 30px 10px;
  }
`

// つづきをみる 背景
const StyledDiv2 = styled.div`
  padding: 60px 0;
  background: url(/images/exdio/renewal/doraemon/progress_bg.png) repeat;

  ${mediaQuery()} {
    padding: 30px 10px;
  }
`

const StyledSnsButtons = styled(SnsButtons)`
  ${mediaQuery()} {
    margin-top: 20px;
  }
`
