import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import webApp from '../../../../../utils/exdioWebAppUtils'
import SliderImage from '../SliderImage'
import SliderTitle from '../SliderTitle'

/* style */
import { StyledDiv1, StyledDiv2, StyledDiv3, StyledImg } from './style'

/** ヒラメキのタネ メインスライダー pcコンポーネント */
const MainSlider = ({ episodes }, context) => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [nav3, setNav3] = useState(null)

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    asNavFor: 'Slider'
  }

  const settings2 = {
    arrows: false,
    infinite: true,
    vertical: true,
    slidesToShow: 4,
    swipeToSlide: true,
    focusOnSelect: true,
    asNavFor: 'Slider'
  }

  const settings3 = {
    arrows: false,
    infinite: true,
    fade: true,
    speed: 1500,
    slidesToShow: 1,
    asNavFor: 'Slider'
  }

  return (
    <StyledDiv1>
      <Slider {...settings} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
        {episodes &&
          episodes.map((meta) => {
            return (
              <SliderImage
                key={meta.meta_id}
                meta={meta}
                onClickThumbnail={() =>
                  webApp.utils.goToProgramLink(context, meta)
                }
              />
            )
          })}
      </Slider>
      <StyledDiv2>
        <StyledDiv3>
          <Slider
            {...settings2}
            asNavFor={nav3}
            ref={(slider2) => setNav2(slider2)}
          >
            {episodes.map((meta, index) => {
              const thumbnailUrl = webApp.utils.customSizeImageUrl(
                meta.thumbnail_url,
                'medium'
              )
              const [title, subTitle] = webApp.utils.titles(meta)
              const thumbnailAlt = `${title} ${subTitle}`
              return (
                <div key={index}>
                  <StyledImg src={thumbnailUrl} alt={thumbnailAlt} />
                </div>
              )
            })}
          </Slider>
        </StyledDiv3>
      </StyledDiv2>
      <Slider
        {...settings3}
        asNavFor={nav1}
        ref={(slider3) => setNav3(slider3)}
      >
        {episodes &&
          episodes.map((meta) => {
            return (
              <SliderTitle
                key={meta.meta_id}
                meta={meta}
                onClickTitle={() => webApp.utils.goToProgramLink(context, meta)}
              />
            )
          })}
      </Slider>
    </StyledDiv1>
  )
}

export default MainSlider

MainSlider.propTypes = {
  // 主情報
  /** メタ */
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    })
  ),
  // イベントハンドラ
  onClickThumbnail: PropTypes.func
}

MainSlider.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  falcorModel: PropTypes.object
}
