import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import routes from '../../routes'
import { StyledDiv3, StyledLink } from '../Footer'

/** アプリ用フッター 静的 */
const StaticFooter = (
  { courseId = null, showLogo = false, className = '' },
  context
) => {
  const config = context.models.config.data

  return (
    <footer className={`common-footer ${className}`}>
      <div className="inner">
        <div className="footer-notes">
          <div className="notes-content">
            <p>
              配信されている動画は放送内容と一部異なる場合がございます。また、予告なく配信終了する場合がございますがご了承ください。表示されている情報は、収録および放送当時のものです。
              <br />
              消費税率の変更により、テレビ朝日番組内およびホームページ内の価格表示と異なる場合があります。
            </p>
          </div>
        </div>
      </div>
      {showLogo && (
        <StyledDiv3 style={{ paddingBottom: '24px' }}>
          <StyledLink route={routes.app_home}>
            <img
              src="/images/exdio/renewal/logo_tv_asahi_douga.png"
              width="207"
              height="41"
              alt="テレ朝動画"
            />
          </StyledLink>
        </StyledDiv3>
      )}
      <div className="footer-copyrights">
        {courseId === config.course_id.doraemon && (
          <StyledSmall>
            (C)藤子プロ・小学館・テレビ朝日・シンエイ・ADK
          </StyledSmall>
        )}
        {courseId === config.course_id.shinchan && (
          <StyledSmall>
            © 臼井儀人／双葉社・シンエイ・テレビ朝日・ADK
          </StyledSmall>
        )}
        <small>Copyright(C) tv asahi All rights reserved.</small>
      </div>
    </footer>
  )
}
export default StaticFooter

StaticFooter.propTypes = {
  courseId: PropTypes.number,
  showLogo: PropTypes.bool,
  className: PropTypes.string
}

StaticFooter.contextTypes = {
  models: PropTypes.shape({})
}

const StyledSmall = styled.small`
  display: block;
  margin-bottom: 26px;
`
