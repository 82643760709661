import React, { useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TabPanel from './TabPanel'
import FilterSort from '../../../../../../common/components/FilterSort'
import { mediaQuery, breakPoints } from '../../../../style'
import { defaultState } from '../../../../../../common/components/FilterSort/hooks/useSearchParams'

const Search = (
  {
    selectedTabId = '',
    setSelectedTabId = () => {},
    selectedYear = null,
    setSelectedYear = () => {},
    selectedTags = [],
    setSelectedTags = () => {},
    characterItems = [],
    secretToolsItems = [],
    selectedSecretTools = '',
    setSelectedSecretTools = () => {},

    // for search
    searchParams = {
      state: defaultState,
      response: {
        episodes: [],
        totalCount: 0
      },
      set: () => {},
      paging: () => {},
      isLoading: false
    },
    exFilterProps = [],
    ...props
  },
  ref
) => {
  /** useRef */
  const tabPanelRef = useRef(null)

  return (
    <StyledSection {...props} ref={ref}>
      <StyledH2>カテゴリでみる</StyledH2>
      <StyledDiv>
        <TabPanel
          ref={tabPanelRef}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          characterItems={characterItems}
          secretToolsItems={secretToolsItems}
          selectedSecretTools={selectedSecretTools}
          setSelectedSecretTools={setSelectedSecretTools}
        />

        <StyledFilterSort
          searchParams={searchParams}
          episodeListItemProps={{
            showNew: true,
            showChecked: true,
            showCoin: true,
            showCaption: true,
            onlySubTitle: true,
            images: {
              on: '/images/exdio/renewal/doraemon/watched.png',
              off: '/images/exdio/renewal/doraemon/not_watched.png'
            }
          }}
          exFilterProps={exFilterProps}
          hasFavorite
        />
      </StyledDiv>
    </StyledSection>
  )
}

export default forwardRef(Search)

Search.propTypes = {
  selectedTabId: PropTypes.string,
  setSelectedTabId: PropTypes.func,
  selectedYear: PropTypes.number,
  setSelectedYear: PropTypes.func,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  setSelectedTags: PropTypes.func,
  /** 「キャラクターでさがす」データ */
  characterItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      img: PropTypes.string
    })
  ),
  /** 「ひみつ道具でさがす」データ */
  secretToolsItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      src: PropTypes.string
    })
  ),
  selectedSecretTools: PropTypes.string,
  setSelectedSecretTools: PropTypes.func,
  disabled: PropTypes.bool,
  searchParams: PropTypes.shape({
    state: PropTypes.shape({
      /** ページャーの設定 */
      pagerOptions: PropTypes.shape({
        episodesPerPages: PropTypes.number,
        range: PropTypes.number,
        showBottom: PropTypes.bool
      }),
      seasonIds: PropTypes.arrayOf(PropTypes.number),
      childEpisodeIds: PropTypes.arrayOf(PropTypes.number),
      selectedYear: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
      notTags: PropTypes.arrayOf(PropTypes.string),
      forceEditParams: PropTypes.func,
      pageNum: PropTypes.number,
      filteredBy: PropTypes.oneOf(['', 'watched', 'not_watched']),
      sortedBy: PropTypes.string
    }),
    response: PropTypes.shape({
      episodes: PropTypes.arrayOf(PropTypes.object),
      totalCount: PropTypes.number
    }),
    set: PropTypes.func,
    paging: PropTypes.func,
    isLoading: PropTypes.bool
  }),
  exFilterProps: PropTypes.arrayOf(
    PropTypes.shape({
      filteredBy: PropTypes.string,
      updateFilteredBy: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      initialValue: PropTypes.string,
      slug: PropTypes.string
    })
  )
}

Search.contextTypes = {
  history: PropTypes.object,
  routeHandler: PropTypes.object,
  falcorModel: PropTypes.object,
  models: PropTypes.object
}

const StyledSection = styled.section`
  padding: 60px 0 200px;
  position: relative;
  /* background: #fef545 url(/images/exdio/renewal/doraemon/category_bg.png) repeat
    top center / 100%; */
  background: linear-gradient(to top, #bcdca8, #bcdca8 399px, transparent 400px),
    #fef545 url(/images/exdio/renewal/doraemon/category_bg.png) repeat top
      center / 100%;
  overflow-x: hidden;

  ${mediaQuery()} {
    padding: 30px 0 35.6vw;
  }

  &::before {
    content: '';
    width: 1920px;
    height: 511px;
    background: url(/images/exdio/renewal/doraemon/search_bg1.png) no-repeat top
      center / 100%;
    position: absolute;
    bottom: 45px;
    left: 53%;
    transform: translateX(-50%);

    ${mediaQuery()} {
      content: none;
    }
  }

  &::after {
    ${mediaQuery()} {
      content: '';
      width: 100%;
      height: 40vw;
      background: url(/images/exdio/renewal/doraemon/search_bg1_sp.png)
        no-repeat top center / 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const StyledDiv = styled.div`
  margin: 0 auto;
  padding: 40px;
  max-width: 1322px;
  /* width: calc(1322 / 1920 * 100%); */
  background-color: #00a0e9;
  border: 3px solid #45313d;
  border-radius: 20px;
  position: relative;

  ${mediaQuery(1322)} {
    margin: 0 20px;
    padding: 20px;
  }

  ${mediaQuery()} {
    margin: 0 5px;
    padding: 6px 8px 8px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -3%;
    right: -11%;
    width: calc(301 / 1920 * 100%);
    height: 9%;
    max-width: 301px;
    max-height: 348px;
    min-height: 246px;
    background: url(/images/exdio/renewal/doraemon/search_bg2.png) no-repeat top
      center / 100%;

    ${mediaQuery(1600)} {
      bottom: -15vw;
      right: 13vw;
      width: 12vw;
      height: 17vw;
      max-width: inherit;
      max-height: inherit;
      min-height: inherit;
    }

    ${mediaQuery()} {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -4%;
    left: -15.2%;
    width: calc(344 / 1920 * 100%);
    height: 10.6%;
    max-width: 344px;
    max-height: 526px;
    min-height: 290px;
    background: url(/images/exdio/renewal/doraemon/search_bg3.png) no-repeat top
      center / 100%;

    ${mediaQuery(1600)} {
      bottom: -15vw;
      left: 13vw;
      width: 12vw;
      height: 17vw;
      max-width: inherit;
      max-height: inherit;
      min-height: inherit;
    }

    ${mediaQuery()} {
      display: none;
    }
  }
`

const StyledH2 = styled.h2`
  font-weight: 700;
  font-size: 2.6rem;
  width: 100%;
  max-width: 1322px;
  margin: 0 auto 2rem;

  ${mediaQuery()} {
    padding: 0 20px;
  }
`

const StyledFilterSort = styled(FilterSort)`
  margin-top: 30px;
  border-radius: 20px;
  border: 3px solid #45313d;

  ${mediaQuery()} {
    margin-top: 10px;
    padding: 10px 10px 50px;
    min-height: 300px;
  }

  & > ul {
    ${mediaQuery(768, 'min')} {
      grid-template-columns: repeat(3, 1fr);
    }

    ${mediaQuery(1025, 'min')} {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  & > div {
    margin-left: auto;
    margin-right: auto;
  }
`
