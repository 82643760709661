import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import NotFound from '../../../generic/components/errors/NotFound';
import webApp from '../../utils/exdioWebAppUtils';
import namespace from '../../../../common/namespace';
import { ADVERTISING_SCHEMA_ID, META_SCHEMA_ID, PALETTE_SCHEMA_ID } from '../../../../constants/app';

/** ジャンルトップページ */
export default class GenreContent extends Component {
  static propTypes = {
    genre: PropTypes.string
  };

  static defaultProps = {
    genre: ''
  };

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  };

  static getAttrPath(_models, _options, props) {
    return ['attributeByType', 'genre', props.genre];
  }

  static getPrefetchPaths(models, options, props) {
    return [GenreContent.getAttrPath(models, options, props)];
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const attribute = _.get(prefetchResult, ['json', ...GenreContent.getAttrPath(models, options, props)]) || {};
    const title = attribute.name || _.get(models, ['config', 'data', 'canvas', 'genre', props.genre, 'title']) || '';
    return { title };
  }

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.state = {
      isNotFound: false,
      attribute: null,
      canvas: null,
      howToPlays: null,
      products: null,
      courses: null,
      loaded: false
    };
  }

  componentWillMount() {
    // URLパラメータはpropsから取れる.
    // routeHandlerから直で取る場合は
    // const genre = this.context.routeHandler.params.genre;
    const { genre } = this.props;
    if (!genre) {
      this.setState({ isNotFound: true });
    }
    this.canvasConfig = this.context.models.config.data.canvas.genre[genre] || {};

    this.getCanvas()
      .then(() => this.getPalettes())
      .then(() => this.getHowToPlays())
      .then(() => this.getProductsAndCourses())
      .then(() => {
        if (this._isMounted) {
          this.setState({ loaded: true });
        }
      })
      .catch(e => webApp.utils.handleFalcorError(e, this.context));
  }

  async componentDidMount() {
    this._isMounted = true;

    const attribute = await this.getAttribute().catch(e => webApp.utils.handleFalcorError(e, this.context));

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context, attribute.name);
    // GTMの更新
    const title = this.context.models.config.data.default_title;
    const [program] = title === undefined ? [''] : title.split(' | ');
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ];
    webApp.utils.updateDataLayer(gtmTags);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /** ジャンル属性情報取得 */
  getAttribute() {
    const { genre } = this.props;
    if (!genre) return Promise.resolve();

    const path = GenreContent.getAttrPath({}, {}, this.props);
    return this.model.fetch([path]).then(result => {
      const attribute = _.get(result, ['json', ...path]);
      if (this._isMounted) {
        this.setState({ attribute });
        if (!attribute) this.setState({ isNotFound: true });
      }
      return attribute;
    });
  }

  /** キャンバス情報取得 */
  getCanvas() {
    const { genre } = this.props;
    if (!genre) return Promise.resolve();

    const path = ['canvas', genre];
    return this.model.fetch([path]).then(result => {
      const canvas = _.get(result, ['json', ...path], {});
      if (this._isMounted) {
        this.setState({ canvas });
      }
    });
  }

  /** パレット情報取得 */
  async getPalettes() {
    const { canvas } = this.state;
    if (!canvas || !canvas.structure || !canvas.structure.length) {
      this.setState({ palettes: [] });
      return Promise.resolve();
    }

    const paletteIds = canvas.structure.map(s => s.id);

    const structures = canvas.structure || [];
    let imagePaletteIds = [];
    let w_paletteIds = [];
    // 画像とそれ以外を分離
    structures.forEach((structure) => {
      if(structure.palette) {
        if (structure.palette.schema_id === PALETTE_SCHEMA_ID.IMAGES) {
          imagePaletteIds.push(structure.id);
        } else {
          w_paletteIds.push(structure.id);
        }
      }
    })

    // array分割
    const arrayChunk = ([...array], size = 1) => {
      return array.reduce((acc, value, index) => index % size ? acc : [...acc, array.slice(index, index + size)], []);
    }

    let paletteIdsChunk = arrayChunk(w_paletteIds, 3); // パレットは極力少なくかつリクエスト数は増えないように
    const chunk = [imagePaletteIds].concat(paletteIdsChunk);

    let all_palettes = [];
    for(let i = 0; i < chunk.length; i++) {
      const path = [['palette', chunk[i]]];
      if(i == 0) {
        await this.context.falcorModel.fetch(path).then(result => {
          const palettesObj = _.get(result, ['json', 'palette'], {});
          const palettes = paletteIds.map(key => palettesObj[key]).filter(v => v);

          all_palettes = all_palettes.concat(palettes);
          if (this._isMounted) {
            this.setState({ palettes: all_palettes });
          }
        });
      } else {
        await this.context.falcorModel.fetch(path).then(result => {
          const palettesObj = _.get(result, ['json', 'palette'], {});
          const palettes = paletteIds.map(key => palettesObj[key]).filter(v => v);

          all_palettes = all_palettes.concat(palettes);
          if (this._isMounted) {
            this.setState({ palettes: all_palettes });
          }
        });
      }
    }

    return Promise.resolve();
  }

  /** 価格情報取得 */
  getHowToPlays() {
    const { palettes } = this.state;

    const metaIdsMap = {};
    (palettes || [])
      .filter(p => ![PALETTE_SCHEMA_ID.IMAGES, PALETTE_SCHEMA_ID.KEYWORDS].includes(p.schema_id))
      .flatMap(p => p.objects)
      .filter(obj => obj.type === 'meta')
      .map(obj => obj.meta)
      .filter(meta => [META_SCHEMA_ID.EPISODE_NOT_FREE, META_SCHEMA_ID.SEASON].includes(meta.meta_schema_id))
      .forEach(meta => {
        metaIdsMap[meta.meta_id] = meta.meta_id;
      });
    const metaIds = Object.keys(metaIdsMap);
    if (!metaIds.length) return Promise.resolve();

    const path = [['meta', 'howToPlay', false, metaIds]];
    return this.model.fetch(path).then(result => {
      const howToPlays = _.get(result, ['json', 'meta', 'howToPlay', false]) || {};
      if (this._isMounted) {
        this.setState({ howToPlays });
      }
    });
  }

  /** 商品・コース情取得報 */
  getProductsAndCourses() {
    const { palettes } = this.state;

    const advertisings = (palettes || [])
      .filter(p => ![PALETTE_SCHEMA_ID.IMAGES, PALETTE_SCHEMA_ID.KEYWORDS].includes(p.schema_id))
      .flatMap(p => p.objects)
      .filter(obj => obj.type === 'advertising')
      .map(obj => obj.advertising);

    const productIds = advertisings
      .filter(ad => ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT || ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT_LIVE)
      .map(h => h.values.product)
      .filter(v => v);
    const courseIds = advertisings
      .filter(ad => ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE || ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE_LIVE)
      .map(h => h.values.course)
      .filter(v => v);

    const path = [['product', productIds], ['course', courseIds]];
    return this.model.fetch(path).then(result => {
      const products = _.get(result, ['json', 'product']) || {};
      const courses = _.get(result, ['json', 'course']) || {};
      if (this._isMounted) {
        this.setState({ products, courses });
      }
    });
  }

  render() {
    if (this.state.isNotFound) return <NotFound />;

    const { attribute, palettes, howToPlays, products, courses, loaded } = this.state;
    if (!attribute) return null;

    // ジャンルトップページはテンプレート利用対象
    const templateId = _.get(attribute, ['values', 'ex_templateId']) || 0;
    const templateKey = `genre_${templateId}`;
    const template = namespace.templates[templateKey];
    if (!template) {
      console.error(`Template:${templateKey} not found.`);
      return null;
    }

    const childProps = Object.assign({}, this.props, {
      canvasConfig: this.canvasConfig,
      attribute,
      palettes,
      howToPlays: howToPlays || {}, // 新着などの特殊条件パレットだとnullがあり得るのでブロックしておく
      products,
      courses,
      loaded
    });
    return React.createElement(template, childProps);
  }
}
