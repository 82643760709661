import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { StyledDiv1, StyledDiv2, StyledDiv3, StyledDiv5 } from './styles'
import Slider1 from './Slider1'
import Slider2 from './Slider2'
import Slider3 from './Slider3'

/** 海外視聴 メインスライダー sp コンポーネント */
const SpMainSlider = ({ episodes, className }) => {
  const [slider1, setSlider1] = useState(null)
  const [slider2, setSlider2] = useState(null)
  const [slider3, setSlider3] = useState(null)

  return (
    <StyledDiv1 className={className}>
      <StyledDiv2>
        <Slider1 episodes={episodes} nav={slider2} setSlider={setSlider1} />
      </StyledDiv2>

      <StyledDiv3>
        <Slider2 episodes={episodes} nav={slider3} setSlider={setSlider2} />
      </StyledDiv3>

      <StyledDiv5>
        <Slider3 episodes={episodes} nav={slider1} setSlider={setSlider3} />
      </StyledDiv5>
    </StyledDiv1>
  )
}

SpMainSlider.propTypes = {
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    })
  ),
  className: PropTypes.string
}

SpMainSlider.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  falcorModel: PropTypes.object
}

export default SpMainSlider
