import React, { memo, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQuery } from '../../../../style'

const PageTop = ({ isShowBanner, ...props }) => {
  const [isShow, setIsShow] = useState(false)

  const changeShow = useCallback(() => {
    const shouldBeShown = window.pageYOffset > 50
    setIsShow(shouldBeShown)
  }, [])

  const handleScroll = useCallback(() => {
    requestAnimationFrame(changeShow)
  }, [changeShow])

  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <StyledButton
      onClick={returnTop}
      isShow={isShow}
      {...props}
      isShowBanner={isShowBanner}
    >
      <img
        src="/images/exdio/renewal/doraemon/pagetop.png"
        alt="ページTOP"
        loading="lazy"
      />
    </StyledButton>
  )
}

export default memo(PageTop)

PageTop.propTypes = {
  isShowBanner: PropTypes.bool,
  className: PropTypes.string
}

const StyledButton = styled.button`
  width: 103px;
  background: transparent;
  border: 0;
  position: fixed;
  right: 10px;
  bottom: ${({ isShowBanner }) => (isShowBanner ? '100px' : '10px')};
  z-index: ${({ isShow }) => (isShow ? 11 : -1)};
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  transition: opacity 0.3s;

  ${mediaQuery()} {
    margin: 30px auto 0;
    width: 80px;
    display: block;
    position: static;
  }

  @media (hover: hover) {
    &:hover {
      opacity: ${({ isShow }) => (isShow ? 0.7 : 0)};
    }
  }

  img {
    width: 100%;
  }
`
