import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import routes from '../../../../apps/common/routes'
import Footer from '../../../common/components/Footer'
import DFPBanner from '../../../common/components/DFPBanner'
import Link from '../../../../sketch-platform/ui/routing/Link'
import NoLogin from './mypage/NoLogin'
import Purchased from './mypage/Purchased'
import { LOGIN_CONTENTS, MYPAGE_CONTENTS } from '../../../../constants/app'
import Account from './mypage/Account'
import Coin from './mypage/Coin'
import MyList from './mypage/MyList'
import PurchasedEpisodes from './mypage/PurchasedEpisodes'
import PurchasedPack from './mypage/PurchasedPack'
import PurchasedPlan from './mypage/PurchasedPlan'
import AccountSetting from './mypage/AccountSetting'
import AccountEditInfo from './mypage/AccountEditInfo'
import AccountEditPass from './mypage/AccountEditPass'
import AccountEditEmail from './mypage/AccountEditEmail'
import AccountDelete from './mypage/AccountDelete'
import AccountPayment from './mypage/AccountPayment'
import AccountEditProfile from './mypage/AccountEditProfile'
import ViewLicenseContent from './mypage/ViewLicenseContent'
import CancelLicenseContent from './mypage/CancelLicenseContent'
import MemberSetting from './mypage/MemberSetting'
import webApp from '../../utils/exdioWebAppUtils'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import HeaderNewsComponent from './HeaderNewsComponent'

const PAGE_TITLE = 'マイページ'

/** マイページ(マイリスト, 購入済, アカウント情報, 通帳) */
export default class MyPageContent extends Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object,
    updateUserInfo: PropTypes.func,
    updateUserInfoSubscribe: PropTypes.func
  }

  static getSsrMetaTags(_models, _options, _props, _prefetchResult) {
    return { title: PAGE_TITLE }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    this.state = {
      currentContent: MYPAGE_CONTENTS.TAB.PURCHASED,
      currentVodPurchased: {}
    }

    this.setContent = this.setContent.bind(this)
    this.emitSetState = this.emitSetState.bind(this)
    this.goBackPurchased = this.goBackPurchased.bind(this)
    this.forceUpdateMyPage = this.forceUpdateMyPage.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    // 画面の初期表示時、ページ外のリンクからの遷移時に表示する情報判定. history.location.state利用.
    const currentPath = this.context.history.location.pathname
    this.removeHistoryListener = this.context.history.listen((location) =>
      this.setContentByLocation(location, currentPath)
    )

    const { query } = this.context.routeHandler
    if (query.content === 'mylist') {
      this.setState({ currentContent: MYPAGE_CONTENTS.TAB.MYLIST })
    } else if (query === 'wallet') {
      this.setState({ currentContent: MYPAGE_CONTENTS.LINK.COIN })
    } else {
      this.setContentByLocation(this.context.history.location)
    }
    this.context.updateUserInfoSubscribe(this.forceUpdateMyPage)
    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context, PAGE_TITLE)
    // GTMの更新
    const title = this.context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)

    this.getAvailablePoint()
  }

  componentWillUnmount() {
    this._isMounted = false
    if (typeof this.removeHistoryListener === 'function')
      this.removeHistoryListener()
  }

  forceUpdateMyPage() {
    if (this._isMounted) {
      this.forceUpdate()
    }
  }

  /** location.stateから現在の対象コンテンツを判定し内部コンテンツとして設定する. */
  setContentByLocation(location, prevPath) {
    // URLが変わった場合はcomponentDidMountも走るので処理不要
    if (prevPath && location.pathname !== prevPath) return

    let targetContent
    // 下記画面はルートから表示モードを切り替える必要ある
    if (this.isMatch(location, routes.mypage_wallet)) {
      targetContent = MYPAGE_CONTENTS.LINK.COIN
    } else if (this.isMatch(location, routes.mypage_view_license)) {
      targetContent = MYPAGE_CONTENTS.ACCOUNT.PLAN
    } else if (this.isMatch(location, routes.mypage_cancel_license)) {
      targetContent = MYPAGE_CONTENTS.ACCOUNT.CANCEL_PAYMENT
    } else if (this.isMatch(location, routes.mypage_account_setting)) {
      targetContent = MYPAGE_CONTENTS.ACCOUNT.SETTING
    } else if (this.isMatch(location, routes.mypage_update_email_result)) {
      targetContent = MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_EMAIL_RESULT
    } else if (this.isMatch(location, routes.mypage_account_info)) {
      targetContent = MYPAGE_CONTENTS.LINK.ACCOUNT
    } else if (this.isMatch(location, routes.mypage_update_credit)) {
      targetContent = MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_CREDIT
    } else if (this.isMatch(location, routes.edit_icon)) {
      targetContent = MYPAGE_CONTENTS.MEMBER_SETTING.ICON_EDIT
    } else {
      const content = _.get(location, ['state', 'content']) || {}
      targetContent = Object.values(MYPAGE_CONTENTS)
        .flatMap((v) => Object.values(v))
        .find((v) => v.id === content.id)
    }

    if (targetContent !== this.state.currentContent) {
      this.setContent(targetContent || MYPAGE_CONTENTS.TAB.PURCHASED)
    }
  }

  isMatch(location, route) {
    const routePath = route.makePath()
    // context.history.replaceで遷移していくるとlocation.pathnameが正しく取れないのでmatchを優先的に使う
    if (typeof location.match === 'function') {
      return location.match(routePath)
    }
    const locationPath = location.pathname
    return routePath === locationPath
  }

  setContent(content) {
    if (this._isMounted) {
      this.setState({ currentContent: content })
    }
  }

  emitSetState(obj, callback = () => {}) {
    if (this._isMounted) {
      this.setState(obj, () => callback())
    }
  }

  goTo(route, query, state) {
    this.context.history.push(route.makePath({}, query), state)
  }

  goBackPurchased(e) {
    e.preventDefault()
    if (this._isMounted) {
      this.setState({ currentContent: MYPAGE_CONTENTS.TAB.PURCHASED })
    }
  }

  /** 利用可能コイン数取得 */
  getAvailablePoint() {
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) return

    const path = [['infra', 'availablePoint']]
    this.model.fetch(path).then((result) => {
      const availablePoint = _.get(result, ['json', 'infra', 'availablePoint'])
      this.context.updateUserInfo({ availablePoint })
    })
  }

  renderTabs() {
    const { currentContent } = this.state

    const isPurchased = [
      MYPAGE_CONTENTS.TAB.PURCHASED,
      MYPAGE_CONTENTS.PURCHASED.EPISODE,
      MYPAGE_CONTENTS.PURCHASED.PACK,
      MYPAGE_CONTENTS.PURCHASED.PLAN
    ].includes(currentContent)

    return Object.values(MYPAGE_CONTENTS.TAB).map((tab) => {
      const isCurrent =
        currentContent.id === tab.id ||
        (isPurchased && MYPAGE_CONTENTS.TAB.PURCHASED === tab)
      const isAccountInfo = tab.id === MYPAGE_CONTENTS.TAB.ACCOUNT.id
      return (
        <li
          key={tab.id}
          className={`c-mypage-head-nav-inBox-btn ${
            isCurrent ? 'current' : ''
          }`}
        >
          <Link
            route={isAccountInfo ? routes.mypage_account_info : routes.mypage}
            state={{ content: tab }}
            className="c-mypage-head-nav-inBox-btn-link"
          >
            {tab.label}
          </Link>
        </li>
      )
    })
  }

  /** マイページ内のコンテンツ切り替え */
  createContents() {
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (isLoggedIn === false) return [<NoLogin />, 'logout']

    const { currentContent, currentVodPurchased } = this.state

    switch (currentContent) {
      // 購入済
      case MYPAGE_CONTENTS.TAB.PURCHASED:
        return [
          <Purchased
            setContent={this.setContent}
            emitSetState={this.emitSetState}
          />,
          'login'
        ]
      case MYPAGE_CONTENTS.PURCHASED.EPISODE:
        return [
          <PurchasedEpisodes
            {...currentVodPurchased}
            goBack={this.goBackPurchased}
          />,
          'myPageMylist'
        ]
      case MYPAGE_CONTENTS.PURCHASED.PACK:
        return [
          <PurchasedPack
            {...currentVodPurchased}
            goBack={this.goBackPurchased}
          />,
          'myPageMylist'
        ]
      case MYPAGE_CONTENTS.PURCHASED.PLAN:
        return [
          <PurchasedPlan
            {...currentVodPurchased}
            goBack={this.goBackPurchased}
          />,
          'myPageMylist'
        ]

      // マイリスト
      case MYPAGE_CONTENTS.TAB.MYLIST:
        return [<MyList />, 'login']

      // アカウント情報
      case MYPAGE_CONTENTS.LINK.ACCOUNT:
        return [<Account setContent={this.setContent} />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.SETTING:
        return [<AccountSetting />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.EDIT_PROFILE:
        return [<AccountEditProfile />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.EDIT_INFO:
        return [<AccountEditInfo />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.EDIT_PASS:
        return [<AccountEditPass />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.EDIT_EMAIL:
        return [<AccountEditEmail />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.DELETE:
        return [<AccountDelete />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.PLAN:
        return [<ViewLicenseContent />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.CANCEL_PAYMENT:
        return [<CancelLicenseContent />, 'account']
      case MYPAGE_CONTENTS.ACCOUNT.PAYMENT:
        return [<AccountPayment />, 'account']

      // アカウント情報内の各基盤ページ
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_MEMBER:
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_PASSWORD:
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_EMAIL:
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_EMAIL_RESULT:
      case MYPAGE_CONTENTS.MEMBER_SETTING.DELETE_TVASAHI_ID:
      case MYPAGE_CONTENTS.MEMBER_SETTING.UPDATE_CREDIT:
      case MYPAGE_CONTENTS.MEMBER_SETTING.ICON_EDIT:
        return [<MemberSetting setting={currentContent} />, 'account']

      case MYPAGE_CONTENTS.LINK.COIN:
        return [<Coin />, 'coins']
      default:
        return [null, '']
    }
  }

  render() {
    const { availablePoint } = this.context.models.userInfo.data
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    const [contents, className] = this.createContents()
    const { currentContent } = this.state

    let redirectUrl = webApp.utils.rootUrl()
    switch (currentContent) {
      // マイリスト
      case MYPAGE_CONTENTS.TAB.MYLIST:
        redirectUrl += routes.mypage.makePath({}, { content: 'mylist' })
        break
      // アカウント情報
      case MYPAGE_CONTENTS.LINK.ACCOUNT:
        redirectUrl += routes.mypage_account_setting.makePath({})
        break
      case MYPAGE_CONTENTS.LINK.COIN:
        redirectUrl += routes.mypage_wallet.makePath({}, { content: 'wallet' })
        break
      // アカウント設定
      case MYPAGE_CONTENTS.ACCOUNT.SETTING:
        redirectUrl += routes.mypage_account_setting.makePath({})
        break
      default:
        redirectUrl += routes.mypage.makePath({})
        break
    }

    let signupUrl = webApp.utils.isApp(this.context)
      ? routes.app_regist_member
      : routes.regist_member
    signupUrl = signupUrl.makePath({}, { redirect: redirectUrl })
    signupUrl = encodeURIComponent(webApp.utils.rootUrl() + signupUrl)

    return (
      <div className="common-wrapper">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />

        <div className={`c-mypage ${className}`}>
          <header className="c-mypage-head">
            <div className="c-mypage-head-titleBox">
              <h2 className="c-mypage-head-titleBox-title">{PAGE_TITLE}</h2>
            </div>
            {!isLoggedIn && (
              <div className="c-myRegist">
                <div className="c-myRegist-btn signup">
                  <div
                    className="c-myRegist-btn-link"
                    onClick={() =>
                      this.goTo(
                        routes.login,
                        { redirect: redirectUrl, signup: signupUrl },
                        { content: LOGIN_CONTENTS.SIGNUP }
                      )
                    }
                  >
                    新規登録
                  </div>
                </div>
                <div className="c-myRegist-btn login">
                  <div
                    className="c-myRegist-btn-link"
                    onClick={() =>
                      this.goTo(routes.login, {
                        redirect: redirectUrl,
                        signup: signupUrl
                      })
                    }
                  >
                    ログイン
                  </div>
                </div>
              </div>
            )}

            {isLoggedIn && (
              <div className="c-myInfoBox">
                <div className="c-myInfoBox-coins">
                  <div className="c-myInfoBox-coins-balance">
                    残高
                    <span className="c-myInfoBox-coins-balance-num">
                      {_.get(availablePoint, ['available_point'], 0)}
                    </span>
                  </div>
                  <div className="c-myInfoBox-coins-charge">
                    <Link
                      route={routes.medal_charge}
                      query={{ src: this.context.routeHandler.url }}
                      className="c-myInfoBox-coins-charge-link"
                    >
                      チャージ
                    </Link>
                  </div>
                  <div className="c-myInfoBox-coins-coinHistory">
                    <Link
                      route={routes.mypage_wallet}
                      className="c-myInfoBox-coins-coinHistory-link"
                    >
                      通帳
                    </Link>
                  </div>
                </div>
              </div>
            )}

            <nav className="c-mypage-head-nav">
              <ul className="c-mypage-head-nav-inBox">{this.renderTabs()}</ul>
            </nav>
          </header>

          {contents}
        </div>

        <Footer className="mt0" />
      </div>
    )
  }
}
