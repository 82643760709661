import React, { memo } from 'react'
import PropTypes from 'prop-types'

/* component */
import Radio from '../Radio'

/* style */
import { StyledDiv, StyledUl, StyledLi } from './style'
import { StyledLabel } from '../Radio/style'

/**
 * ソート機能
 * フィルター機能と内容がほぼ同じなので、共通コンポーネントにしても良い
 */
const YearsElm = ({
  from = 0,
  to = 0,
  selectedYear = null,
  updateSearchParams = () => {},
  stateSelectedElm = ['', () => {}],
  ...props
}) => {
  const [selectedElm, setSelectedElm] = stateSelectedElm
  const isOpen = selectedElm === 'selectedYear'
  const yearArr = Array.from({ length: to - from + 1 }, (_, i) => from + i)

  return (
    <StyledDiv isOpen={isOpen} {...props}>
      <StyledUl isOpen={isOpen}>
        <StyledLi>
          <StyledLabel
            onClick={() => setSelectedElm(isOpen ? '' : 'selectedYear')}
          >
            {selectedYear === null ? '年代を選択する' : `${selectedYear}年`}
          </StyledLabel>
        </StyledLi>
        <StyledLi>
          <Radio
            label="すべて"
            checked={selectedYear === null}
            radioProps={{
              name: 'sort',
              value: '',
              id: `fYearAll`,
              onChange: () => {
                updateSearchParams({ selectedYear: null })
              },
              onClick: () => setSelectedElm('')
            }}
          />
        </StyledLi>
        {yearArr.map((year) => (
          <StyledLi key={`year-${year}`}>
            <Radio
              label={`${year}年`}
              checked={selectedYear === year}
              radioProps={{
                name: 'sort',
                value: String(year),
                id: `fYear${year}`,
                onChange: () => {
                  updateSearchParams({ selectedYear: year })
                },
                onClick: () => setSelectedElm('')
              }}
            />
          </StyledLi>
        ))}
      </StyledUl>
    </StyledDiv>
  )
}

export default memo(YearsElm)

YearsElm.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  selectedYear: PropTypes.number,
  updateSearchParams: PropTypes.func,
  stateSelectedElm: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  )
}
