import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '../ListItem/'
import { StyledUl } from './style'

/** Section8｜リスト */
const List = ({ episodes = [] }) => {
  return (
    <StyledUl>
      {episodes.map(
        (episode) => episode && <ListItem key={episode.id} episode={episode} />
      )}
    </StyledUl>
  )
}

export default List

List.propTypes = {
  /** 神回アーカイブ動画情報の配列 */
  episodes: PropTypes.arrayOf(PropTypes.object)
}
