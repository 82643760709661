import React from 'react'
import {
  StyledDd,
  StyledDiv1,
  StyledDiv2,
  StyledDt,
  StyledLink1,
  StyledLink2
} from './style'

/** Section7｜Twitterエリア */
const TwitterArea = ({ ...props }) => {
  return (
    <StyledDiv1 {...props}>
      <dl>
        <StyledDt>
          <StyledLink1 href="https://twitter.com/garibenV">
            <img
              src="/images/exdio/renewal/gariben_daigaku/logo/twitter.webp"
              alt="私立ガリベン大学 公式Twitter"
              width="190"
              height="43"
              loading="lazy"
            />
          </StyledLink1>
        </StyledDt>
        <StyledDd>
          <StyledDiv2 data-chromatic="ignore">
            <a
              href="https://twitter.com/garibenV?ref_src=twsrc%5Etfw"
              width="596"
              height="260"
              data-chrome="noheader nofooter noborders"
              className="twitter-timeline"
              data-chromatic="ignore"
            >
              Tweets by garibenV
            </a>
          </StyledDiv2>
          <StyledLink2
            href="https://twitter.com/garibenV"
            target="_blank"
            className="c-btn4"
          >
            もっと見る
          </StyledLink2>
        </StyledDd>
      </dl>
    </StyledDiv1>
  )
}

export default TwitterArea
