import styled from 'styled-components'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import { mediaQuery, breakPoints } from '../../../style'

export const StyledNav = styled.nav`
  width: 1080px;

  ${mediaQuery()} {
    width: auto;
  }
`

export const StyledUl = styled.ul`
  display: flex;

  ${mediaQuery()} {
    width: 100%;
    flex-wrap: wrap;
  }
`

export const StyledLi = styled.li`
  position: relative;
  z-index: 3;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  ${mediaQuery('sm', 'min')} {
    &:hover {
      transform: translateY(-10px);
    }
  }

  ${mediaQuery()} {
    width: calc(100% / 3);
    z-index: inherit;
  }
`

export const StyledLink = styled(Link)`
  display: inline-block;
`
