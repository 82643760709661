import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import useMediaQuery from '../../../../hooks/useMediaQuery'

/** style */
import { fixColors } from '../../../../exdio/components/style'

const Item = ({ item = {}, arrow = true, className = '', ...props }) => {
  const isSp = useMediaQuery('max', 578)

  if (item.spOnly && !isSp) return null

  return (
    <StyledLi
      className={`${className || ''} ${item.className || ''}`}
      {...props}
    >
      <StyledLink
        arrow={arrow}
        href={item.href}
        route={item.route}
        params={item.params}
        onClick={item.onClick}
        state={item.content && { content: item.content }}
        target={item.target}
      >
        {item.content && item.content.label
          ? item.content.label
          : item.children}
      </StyledLink>
    </StyledLi>
  )
}

export default Item

Item.propTypes = {
  /** リンクの内容 */
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Linkコンポーネント用 */
    content: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    }),
    /** Linkコンポーネント用 */
    route: PropTypes.shape({}),
    /** Linkコンポーネント用 */
    href: PropTypes.string,
    /** Linkコンポーネント用 */
    params: PropTypes.shape({}),
    /** 追加でクラス名を指定する場合 */
    className: PropTypes.string,
    /** SPのみで表示する場合true */
    spOnly: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  }).isRequired,
  /** ボタンの矢印 */
  arrow: PropTypes.bool,
  className: PropTypes.string
}

const StyledLi = styled.li`
  box-sizing: border-box;
  background: #fff;
`

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['arrow'].includes(prop)
})`
  padding: 20px 35px 20px 20px;
  display: grid;
  place-content: center start;
  position: relative;
  color: ${fixColors.colorFont};
  font-weight: 600;
  line-height: 1.25;

  ${({ arrow }) =>
    arrow &&
    css`
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 8px;
        height: 8px;
        border-right: 2px solid #404040;
        border-bottom: 2px solid #404040;
        right: 20px;
        transform: rotate(-45deg);
      }
    `}

  &:visited {
    color: ${fixColors.colorFont};
  }

  img {
    vertical-align: top;
    height: 17px;
  }

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`
