import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Slider from 'react-slick'
import webApp from '../../../../../../utils/exdioWebAppUtils'
import SailSlideItemImage from '../SailSlideItemImage'
import SailSlideItemTitle from '../SailSlideItemTitle'

/* style */
import {
  StyledDiv1,
  StyledDiv2,
  StyledDiv3,
  StyledDiv4,
  StyledDiv5,
  StyledDiv6,
  StyledImg
} from './style'

/** SailGp メインスライダー sp コンポーネント */
const SpSailSlide = ({ episodes }, context) => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [nav3, setNav3] = useState(null)

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000
  }

  const settings2 = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    focusOnSelect: true
  }

  const settings3 = {
    arrows: false,
    infinite: true,
    fade: true,
    slidesToShow: 1
  }

  return (
    <StyledDiv1>
      <StyledDiv2>
        <Slider
          {...settings}
          asNavFor={nav2}
          ref={(slider1) => setNav1(slider1)}
        >
          {episodes &&
            episodes.map((meta) => {
              return (
                <SailSlideItemImage
                  key={meta.meta_id}
                  meta={meta}
                  onClickThumbnail={() =>
                    webApp.utils.goToProgramLink(context, meta)
                  }
                />
              )
            })}
        </Slider>
      </StyledDiv2>

      <StyledDiv3>
        <Slider
          {...settings3}
          asNavFor={nav3}
          ref={(slider2) => setNav2(slider2)}
        >
          {episodes &&
            episodes.map((meta) => {
              return (
                <SailSlideItemTitle
                  key={meta.meta_id}
                  meta={meta}
                  onClickThumbnail={() =>
                    webApp.utils.goToProgramLink(context, meta)
                  }
                />
              )
            })}
        </Slider>
      </StyledDiv3>

      <StyledDiv4>
        <StyledDiv5>
          <Slider
            {...settings2}
            asNavFor={nav1}
            ref={(slider3) => setNav3(slider3)}
          >
            {episodes.map((meta, index) => {
              const thumbnailUrl = meta.thumbnail_url
              return (
                <StyledDiv6 key={index}>
                  <StyledImg src={thumbnailUrl} />
                </StyledDiv6>
              )
            })}
          </Slider>
        </StyledDiv5>
      </StyledDiv4>
    </StyledDiv1>
  )
}

export default SpSailSlide

SpSailSlide.propTypes = {
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    })
  ),
  className: PropTypes.string
}

SpSailSlide.defaultProps = {
  episodes: [],
  className: ''
}

SpSailSlide.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  routeHandler: PropTypes.object
}
