import styled, { css } from 'styled-components'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

const style = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;

  img {
    margin-bottom: 5px;
    margin-right: 0;
    margin-left: 0;
    display: inline-block;
  }
`
export const StyledP = styled.p`
  ${style}
  opacity: .4;
  cursor: default;
`

export const StyledLink = styled(Link)`
  ${style}
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`
