import styled, { css } from 'styled-components'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import Btn1 from '../../components/button/Btn1'
import { mediaPc, mediaSp } from '../../style'

export const StyledImg = styled.img`
  width: 280px;
  height: 160px;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;

  @media ${mediaSp} {
    width: 100%;
    height: 100%;
  }
`

export const StyledDl = styled.dl`
  padding: 16px;
  width: 150%;
  min-height: 120px;
  background-color: rgba(153, 153, 153, 0.9);
  border: 1px solid #000;
  border-radius: 24px;
  position: absolute;
  top: 160px;
  left: 0;
  z-index: -1;
  opacity: 0;
  line-height: 1.5;
  transform: translateY(25%);
  transition: z-index 0.2s ease-in-out, opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;

  @media ${mediaSp} {
    padding: 8px;
    width: calc(200% + 16px);
    border-radius: 16px;
  }
`

export const StyledDt = styled.dt`
  font-size: 2rem;
  font-weight: bold;

  @media ${mediaSp} {
    font-size: 1.6rem;
  }
`

export const StyledDd = styled.dd`
  margin-top: 16px;
`

export const StyledP = styled.p`
  margin-top: 8px;

  @media ${mediaSp} {
    font-size: 1.2rem;
  }

  &:first-child {
    margin-top: 0;
  }
`

export const StyledBtn1 = styled(Btn1)`
  display: inline-flex;
  margin-top: 16px;

  i {
    transform: rotate(-90deg);

    svg {
      width: 20px;
      height: 20px;

      @media ${mediaSp} {
        width: 16px;
        height: 16px;
      }
    }
  }

  span {
    margin-left: 6px;
  }
`

export const StyledLi = styled.li`
  margin-left: 32px;
  width: calc((100% - (32px * 3)) / 4);
  position: relative;

  @media ${mediaSp} {
    margin-left: 16px;
    width: calc((100% - 16px) / 2);

    ${({ selected, imgHeight }) => {
      if (selected) {
        return css`
          ${StyledDl} {
            top: ${imgHeight}px;
            z-index: 1;
            opacity: 1;
            transform: translateY(0);
          }
        `
      }
      return null
    }}
  }

  &:nth-child(odd) {
    @media ${mediaSp} {
      margin-left: 0;
    }
  }

  &:nth-child(even) {
    ${StyledDl} {
      @media ${mediaSp} {
        left: calc((100% + 16px) * -1);
      }
    }
  }

  &:nth-child(n + 3) {
    @media ${mediaSp} {
      margin-top: 8px;
    }
  }

  &:nth-child(n + 5) {
    @media ${mediaPc} {
      margin-top: 32px;
    }
  }

  &:nth-child(4n) {
    ${StyledDl} {
      @media ${mediaPc} {
        left: auto;
        right: 0;
      }
    }
  }

  &:nth-child(4n + 1) {
    @media ${mediaPc} {
      margin-left: 0;
    }
  }

  &:hover {
    @media ${mediaPc} {
      ${StyledDl} {
        z-index: 1;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`

export const StyledDiv1 = styled.div`
  height: 160px;
  overflow: hidden;

  @media ${mediaSp} {
    height: auto;
  }
`

export const StyledDiv2 = styled.div`
  margin-top: 4px;
  color: #fff;
`

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.5;
  transition: opacity 0.2s ease-in-out;

  @media ${mediaSp} {
    font-size: 1.2rem;
    line-height: 1.25;
  }

  &:hover {
    @media ${mediaPc} {
      opacity: 0.7;

      ${StyledDiv1} {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
`
