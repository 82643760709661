import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledDiv, StyledP } from './styles'

/** Errors Component */
const Errors = ({ errors = [], ...props }) => {
  if (!errors.length) return null

  return (
    <StyledDiv {...props}>
      {errors.map((msg) => (
        <StyledP key={msg}>{msg}</StyledP>
      ))}
    </StyledDiv>
  )
}

export default Errors

Errors.propTypes = {
  /** エラー文言配列 */
  errors: PropTypes.arrayOf(PropTypes.string)
}
