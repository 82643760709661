import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Cookie from 'js-cookie'
import { get } from 'lodash'
import styled from 'styled-components'
import useIsMounted from '../../../hooks/useIsMounted'

/** アプリSNSログイン受けページ (ブラウザで表示) */
const SnsLoginContent = (props, context) => {
  const isMounted = useIsMounted()

  /** アプリを開く */
  const redirect2App = () => {
    const config = get(context, ['models', 'config', 'data'], {})
    const locationSearch = get(context, ['history', 'location', 'search'], '')
    const urlSearchParams = new URLSearchParams(locationSearch)
    const query = Object.fromEntries(urlSearchParams.entries())
    /**
     * dioplayer://tv-asahi.co.jp
     * ?type=login
     * &redirect=[※2] // 基盤ログイン後のアプリ表示ページのURL
     * &ret=[※3] // encrypt_member_id (基盤レスポンスからの取得)
     * &kind=[※4] // SNS種別
     * &service_id=[※5] // サービスID
     * &cbsessid=[※6] // CBSESSIONID (Cookieから取得)
     */
    const customSchema = get(config, ['dioapp', 'custom_schema'], '')
    const type = 'login'
    const cbsessid = Cookie.get('CBSESSIONID') || ''
    const params = new URLSearchParams({ ...query, type, cbsessid }).toString()
    const redirectURI = `${customSchema}?${params}`
    window.open(redirectURI, '_blank')
  }

  if (!isMounted) return null

  return (
    <StyledDiv>
      <StyledP>テレ朝動画（テレ朝見逃し）アプリを起動します。</StyledP>
      <StyledP>以下のリンクから起動してください。</StyledP>
      <StyledButton type="button" onClick={redirect2App}>
        アプリを起動
      </StyledButton>
    </StyledDiv>
  )
}

export default SnsLoginContent

SnsLoginContent.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  falcorModel: PropTypes.object
}

SnsLoginContent.getPath = (_models, options, _props) => {
  const { routeHandler } = options
  const url = routeHandler.path
  return ['page', url]
}

SnsLoginContent.getPrefetchPaths = (models, options, props) => {
  return [SnsLoginContent.getPath(models, options, props)]
}

SnsLoginContent.getSsrMetaTags = (models, options, props, prefetchResult) => {
  const page = get(prefetchResult, [
    'json',
    ...SnsLoginContent.getPath(models, options, props)
  ])
  return { title: page.name }
}

const StyledDiv = styled.div`
  padding: 30px 15px;
  text-align: center;
`

const StyledP = styled.p`
  text-align: left;

  & + & {
    margin-top: 30px;
  }
`

const StyledButton = styled.button`
  margin-top: 30px;
  padding: 0;
  background: none;
  border: 0;
  color: #00f;
  font-size: 1.6rem;
  text-decoration: underline;
`
