import React from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../utils/exdioWebAppUtils'

/* style */
import {
  StyledLi,
  StyledDiv1,
  StyledP1,
  StyledDiv2,
  StyledDiv3,
  StyledDiv4,
  StyledP2,
  StyledImg,
  StyledP3
} from './style'

/** ヒラメキのタネ動画一覧 要素コンポーネント */
const SpHiramekiItem = ({ meta, onClickThumbnail }, context) => {
  // /** タイトル */
  const [title, subTitle] = webApp.utils.titles(meta)

  /** サムネイル画像 */
  const thumbnail = () => {
    let thumbnailUrl = meta.thumbnail_url
    const thumbnailAlt = `${title} ${subTitle}`

    /* 読み込む画像サイズを変更する */
    thumbnailUrl = webApp.utils.customSizeImageUrl(thumbnailUrl, 'medium')

    return [thumbnailUrl, thumbnailAlt]
  }

  const formatBroadcast = () => {
    const _broadcast = new Date(meta.values.avails_ReleaseHistoryOriginal)
    const year = _broadcast.getFullYear()
    const month = _broadcast.getMonth() + 1
    const date = _broadcast.getDate()
    return `${year}年${month}月${date}日`
  }

  const handleClick = (propFunc, e) => {
    e.preventDefault()
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation()
      propFunc(e)
    }
  }

  const duration = webApp.utils.duration(meta)
  const [thumbnailUrl, thumbnailAlt] = thumbnail()
  const isNew = webApp.utils.isNew(context, meta)
  const broadcast = formatBroadcast()

  return (
    <StyledLi>
      <a href="#" onClick={(e) => handleClick(onClickThumbnail, e)}>
        <StyledDiv1>
          <img src={thumbnailUrl} alt={thumbnailAlt} />
          <StyledP1 className="time">{duration}</StyledP1>
        </StyledDiv1>
        <StyledDiv2>
          <StyledDiv3>
            <StyledP2>{subTitle}</StyledP2>
          </StyledDiv3>
          <StyledDiv4>
            <div>
              {isNew && (
                <StyledImg
                  src="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_new.jpg"
                  srcSet="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_new@2x.jpg 2x"
                  alt="NEW"
                />
              )}
              <StyledImg
                src="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_free-ep.jpg"
                srcSet="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_free-ep@2x.jpg 2x"
                alt="無料"
              />
            </div>
            <StyledP3>{broadcast}放送</StyledP3>
          </StyledDiv4>
        </StyledDiv2>
      </a>
    </StyledLi>
  )
}

export default SpHiramekiItem

SpHiramekiItem.propTypes = {
  /** メタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  // イベントハンドラ
  // 各onClickは引数に(event)を渡して呼び出される
  onClickThumbnail: PropTypes.func
}

SpHiramekiItem.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}
