import React from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../../common/routes'
import addSizePrefix from '../../addSizePrefix'
import { StyledImg, StyledLink, StyledP } from './style'
import { StyledLi } from '../List/style'

/** Section8｜リストの要素 */
const ListItem = ({ episode = {} }) => {
  if (!Object.keys(episode).length) return null

  return (
    <StyledLi>
      <StyledLink
        route={routes.episode}
        params={{
          seriesId: _.get(episode, ['values', 'parents_series', 'id']),
          seasonId: _.get(episode, ['values', 'parents_season', 'id']),
          episodeId: _.get(episode, ['id'])
        }}
      >
        <div>
          <StyledImg
            src={addSizePrefix(episode.thumbnail_url, 'large')}
            alt={episode.name}
            width="520"
            height="292"
            loading="lazy"
          />
        </div>
        <StyledP>{episode.name}</StyledP>
      </StyledLink>
    </StyledLi>
  )
}

export default ListItem

ListItem.propTypes = {
  /** リストの表示要素
   * @param {string} id - コンテンツのid
   * @param {string} values - 親要素のID, 遷移先の指定に使用
   * @param {string} thumbnail_url - コンテンツのサムネイル画像
   * @param {string} name - コンテンツのタイトル
   */
  episode: PropTypes.shape({
    id: PropTypes.number,
    values: PropTypes.object,
    thumbnail_url: PropTypes.string,
    name: PropTypes.string
  })
}
