import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import useMediaQuery from '../../../../../hooks/useMediaQuery'
import Footer from '../../../../../common/components/Footer'
import routes from '../../../../../common/routes'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import Breadcrumbs from '../Breadcrumbs'
import Sns from '../Sns'
//pc
import MainSlider from './MainSlider/'
import SwitchableHarbor from '../SwitchableHarbor'
//sp
import SpMainSlider from './SpMainSlider'
import SpSwitchableHarbor from '../SpSwitchableHarbor'

/* style */
import {
  StyledH1,
  StyledDiv1,
  StyledSection,
  StyledH2,
  StyledP,
  StyledDiv2
} from './style'

/** 港時間番組ページ テンプレート */
const ProgramHarbor = ({ episodes, seriesId, seasonId, meta }, context) => {
  const pageUrl =
    webApp.utils.rootUrl() + routes.program.makePath({ seriesId, seasonId })
  const isSmp = useMediaQuery()

  // snsコンポーネントへ渡す配列
  const twitter = {
    alt: 'twitter',
    href: `https://twitter.com/share?url=${pageUrl}&text=%E6%B8%AF%E6%99%82%E9%96%93%EF%BD%9C%E3%83%86%E3%83%AC%E6%9C%9D%E5%8B%95%E7%94%BB`,
    img: 'https://wws.tv-asahi.co.jp/douga_mv/minato/images/tw.png'
  }
  const facebook = {
    alt: 'facebook',
    href: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`,
    img: 'https://wws.tv-asahi.co.jp/douga_mv/minato/images/fb.png'
  }
  const line = {
    alt: 'line',
    href: `https://line.me/R/msg/text/?港時間｜テレ朝動画%0D%0A${pageUrl}`,
    img: 'https://wws.tv-asahi.co.jp/douga_mv/minato/images/line.jpg'
  }
  const snsPc = [twitter, facebook]
  const snsSp = [...snsPc, line]

  /** pcページ表示 */
  const renderPcPage = () => {
    const hobby = { genre: 'hobby' }
    const slideEpisodes = episodes.slice(0, 5)
    const disp_order = _.get(meta, ['values', 'disp_order'])
    const top = { seriesId, seasonId }
    const breadcrumbsItems = [top]

    return (
      <StyledDiv1>
        <div>
          <StyledH1>
            <Link route={routes.program} params={top}>
              <img
                src="https://wws.tv-asahi.co.jp/douga_mv/minato/images/logo.png"
                alt="港時間"
              />
            </Link>
          </StyledH1>

          <Breadcrumbs items={breadcrumbsItems} />
        </div>

        {slideEpisodes.length > 0 && (
          <MainSlider episodes={slideEpisodes} rootMetas={slideEpisodes} />
        )}

        <Sns items={snsPc} />

        <StyledSection>
          <StyledH2>ABOUT</StyledH2>
          <StyledP>
            「港」は、過去から現在までの様々な「時間」を見てきています。
            <br />
            この番組は、世界各国の綺麗な港町や風景を伝え、それぞれの港の生活そこに根付く文化や
            <br />
            人々とヨットとの関わりについても紹介していきます。
          </StyledP>
        </StyledSection>

        <SwitchableHarbor
          episodes={episodes}
          rootMetas={episodes}
          disp_order={disp_order}
        />

        <Footer />
      </StyledDiv1>
    )
  }

  /** spページ表示 */
  const renderSpPage = () => {
    const slideEpisodes = episodes.slice(0, 5)
    const disp_order = _.get(meta, ['values', 'disp_order'])
    const top = { seriesId, seasonId }

    return (
      <>
        <StyledDiv2>
          <div>
            <StyledH1>
              <Link route={routes.program} params={top}>
                <img
                  src="https://wws.tv-asahi.co.jp/douga_mv/minato/images/logo.png"
                  alt="港時間"
                />
              </Link>
            </StyledH1>
          </div>

          {slideEpisodes.length > 0 && (
            <SpMainSlider
              episodes={slideEpisodes}
              rootMetas={slideEpisodes}
              showNew
              showChecked
              onlySubTitle
            />
          )}

          <Sns items={snsSp} />

          <StyledSection>
            <StyledH2>ABOUT</StyledH2>
            <StyledP>
              「港」は、過去から現在までの
              <br />
              様々な「時間」を見てきています。
              <br />
              この番組は、世界各国の綺麗な港町や風景を伝え、
              <br />
              それぞれの港の生活そこに根付く文化や
              <br />
              人々とヨットとの関わりについても紹介していきます。
            </StyledP>
          </StyledSection>

          <SpSwitchableHarbor
            episodes={episodes}
            rootMetas={episodes}
            disp_order={disp_order}
          />
        </StyledDiv2>
        <Footer />
      </>
    )
  }

  return isSmp ? renderSpPage() : renderPcPage()
}

export default ProgramHarbor

ProgramHarbor.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  /** パック販売情報 */
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      original_price: PropTypes.number,
      active_pricing: PropTypes.shape({
        price: PropTypes.number,
        unit: PropTypes.string
      })
    })
  )
}

ProgramHarbor.defaultProps = {
  seriesId: '',
  seasonId: '',
  episodes: [],
  products: []
}

ProgramHarbor.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  falcorModel: PropTypes.object
}
