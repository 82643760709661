import React, { memo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import styled, { createGlobalStyle } from 'styled-components'
import {
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE,
  DIO_PLAYER_DEVICE,
  FAVORITE_TYPE
} from '../../../../../constants/app'
import webApp from '../../../utils/exdioWebAppUtils'
import EpisodeFooter from '../../../../common/components/EpisodeFooter'
import RenewalPlayer from '../../player/RenewalPlayer'
import PlayButton from '../EpisodeDefault/PlayButton'
import BreadCrumb from '../british/common/BreadCrumb'
import Header from '../british/common/Header'
import EpisodeList from '../british/common/EpisodeList'
import Heading2 from '../british/common/Heading2'
import Heading3 from '../british/common/Heading3'
import EpisodePager from '../british/episode/EpisodePager'
import ProgramName from '../british/episode/ProgramName'
import List from '../british/episode/List'
import IconText from '../british/episode/IconText'
import Caption from '../british/episode/Caption'
import {
  checkAvailableStatus,
  play,
  purchaseEpisode,
  purchasePlan,
  getThumbnailUrl,
  isPlayable
} from '../EpisodeDefault/util'
import { mediaSp, yuGothic } from '../british/common/styles'
import AddButtonBlock from '../details/AddButtonBlock'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 全英オープン 単話ページ */
const EpisodeBritish = (
  {
    seriesId,
    seasonId,
    meta,
    programMeta,
    episodes,
    status,
    product,
    course,
    loaded,
    ...props
  },
  context
) => {
  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix
  const isPc = webApp.utils.getDevice() === DIO_PLAYER_DEVICE.PC
  const isApp = webApp.utils.isApp(context)
  const isLoggedIn = webApp.utils.isLoggedIn(context)

  const playerRef = useRef(null)
  const setPlayerRef = (e) => {
    playerRef.current = e
  }

  useEffect(() => {
    return () => (document.body.style.overflow = null)
  }, [])

  useEffect(() => {
    const isAutoPlay = context.routeHandler.query.auto === 't'
    if (loaded && status && isPc && isAutoPlay) {
      const isFree = EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode)
      const isAuthFree =
        EPISODE_DISPLAY_MODE.SVOD_AUTH_FREE === status.displayMode && isLoggedIn
      const withValidPreviewToken = get(context, [
        'models',
        'state',
        'data',
        'withValidPreviewToken'
      ])
      if (isFree || isAuthFree || status.isPurchased || withValidPreviewToken) {
        onClickPlay()
      }
    }
  }, [loaded, status, context])

  /**
   * 視聴権を確認して再生するか購入ページに飛ばすかをしている
   */
  const onClickPlay = (e, asSvod = false) => {
    if (e) e.preventDefault()

    switch (checkAvailableStatus({ status, product }, context, asSvod)) {
      case 'PLAY':
        play(playerRef.current, props.sendPlayLog)
        break
      case 'PURCHASE_EPISODE':
        purchaseEpisode(props, context)
        break
      case 'PURCHASE_PLAN':
        purchasePlan(props, context)
        break
      default:
        break
    }
  }

  const time = webApp.utils.duration(meta) || '-'

  // LIVE配信 主/副切替ID
  const metaIdForPlayer = `${config.videocloud.reference_id_prefix || ''}${
    meta.meta_id
  }`
  const playerSettings = webApp.utils.getPlayerSettings(
    config,
    meta,
    status.displayMode
  )

  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  const isBefore = Boolean(
    !withValidPreviewToken && moment(prevTime).isBefore(meta.delivery_start_at)
  )
  const isAfter = Boolean(
    !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
  )
  const thumbnailUrl = getThumbnailUrl(
    { meta, programMeta },
    context,
    config,
    previewUrlList,
    cookieRubocopPrefix
  )
  const inViewTerm = !(isBefore || isAfter)
  const isPlayerRenderable =
    metaIdForPlayer &&
    status.displayMode &&
    (inViewTerm || withValidPreviewToken)

  const date = get(meta, ['publish_start_at'], '').split(/\s+/, 1)
  const title = get(meta, ['values', 'avails_EpisodeTitleDisplayUnlimited'], '')
  const [mainTitle, subTitle] = webApp.utils.titles(meta)
  const metaName = `${mainTitle} ${subTitle}`

  return (
    <div className="p-british p-episode">
      <StyledBreadCrumb
        seriesId={seriesId}
        seasonId={seasonId}
        episodeTitle={title}
      />
      <Header seriesId={seriesId} seasonId={seasonId} />

      {isPlayerRenderable && (
        <RenewalPlayer
          ref={setPlayerRef}
          meta_id={metaIdForPlayer}
          product_type={playerSettings.product_type}
          channel={playerSettings.channel}
          ssai_ad_config_id={playerSettings.ssai_ad_config_id}
          ssai_player_id={playerSettings.ssai_player_id}
          stvod_player_id={playerSettings.stvod_player_id}
          live_player_id={playerSettings.live_player_id}
          delivery_config_id={playerSettings.delivery_config_id}
          thumbnail_url={meta.thumbnail_url}
          subtitle={!!meta.values.subtitle}
          enqueteEnabled={status.isFree}
          material_id={get(product, ['ref_id']) || ''}
          license_id={get(course, ['ref_id']) || ''}
          display_mode={status.displayMode}
        />
      )}

      <StyledContainer1>
        <StyledContainer1Inner>
          <StyledThumbnail role="button" onClick={onClickPlay}>
            <PlayButton status={status} product={product} />
            <img src={thumbnailUrl} width="640" height="360" alt={title} />
          </StyledThumbnail>

          <StyledDiv1>
            <ProgramName seriesId={seriesId} seasonId={seasonId}>
              全英オープン最強王者決定戦 ～歴代優勝者No.1は誰だ？～
            </ProgramName>
            <StyledImg
              src="/douga_mv/common/images/pc/icon_free-ep.jpg"
              width="44"
              height="14"
              alt="無料"
            />
            <Heading2>{title}</Heading2>
            <StyledList>
              {time && <IconText icon={'\\e8ae'}>時間：{time}</IconText>}
              {date[0] && (
                <IconText icon={'\\e916'}>配信期間：{date[0]}〜</IconText>
              )}
            </StyledList>
            <StyledCaption>
              全英オープンの歴代王者がセントアンドリュースに集結！！
              <br />
              Jニクラウス、Tワトソンら往年の名プレイヤーとTウッズ、Rマキロイといった現役最強プレイヤーが時空を超えて架空のトーナメントで熱き戦いを繰り広げる。
              <br />
              歴代優勝者のNo.1に輝くのは果たして…
            </StyledCaption>
          </StyledDiv1>

          <StyledDiv3>
            <AddButtonBlock
              favoriteType={FAVORITE_TYPE.META}
              favoriteId={meta.meta_id}
              title={metaName}
              showLoginButton={isApp && !isLoggedIn}
              showAddMyList={isApp && !isPlayable(status)}
            />
          </StyledDiv3>

          <StyledDiv2>
            <EpisodePager
              meta={meta}
              seriesId={seriesId}
              seasonId={seasonId}
              episodes={episodes}
              course={course}
            />
          </StyledDiv2>
        </StyledContainer1Inner>
      </StyledContainer1>

      <StyledSection>
        <Heading3 enStr={'MOVIE'} jaStr={'動画一覧'} />
        <StyledEpisodeList episodes={episodes} />
      </StyledSection>

      <StyledEpisodeFooter
        copyrights={get(meta, ['values', 'evis_Copyright'])}
      />
      <GlobalStyle />
    </div>
  )
}

export default memo(EpisodeBritish)

EpisodeBritish.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のエピソードID */
  episodeId: PropTypes.string,

  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** 動画の視聴権利関連情報 */
  howToPlay: PropTypes.object,
  /** 動画の価格、配信期間情報など */
  product: PropTypes.object,
  productRight: PropTypes.object,

  // 通常単話
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  /** パック販売情報 */
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number,
      name: PropTypes.string,
      original_price: PropTypes.number,
      active_pricing: PropTypes.shape({
        price: PropTypes.number,
        unit: PropTypes.string
      })
    })
  ),
  /** episodesのhowToPlay情報 */
  howToPlays: PropTypes.object,
  // 月額見放題
  /** 動画のコース情報 */
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),

  /** 動画の視聴ステータス情報 */
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  /** 動画のシーズン情報 */
  season: PropTypes.object,
  /** 動画再生時のログ送信 */
  sendPlayLog: PropTypes.func,
  /** データの取得が終わっているか */
  loaded: PropTypes.bool
}

EpisodeBritish.defaultProps = {
  seriesId: '',
  seasonId: '',
  episodeId: '',
  howToPlay: {},
  episodes: [],
  products: [],
  howToPlays: {},
  course: {},
  status: {
    isFree: false,
    isNotFree: false,
    isPurchased: false,
    isNotPurchased: false,
    isInCourse: false,
    isNotInCourse: false,
    isGeoDeliverable: null,
    isDeviceNotAvailable: null,
    limitDate: null,
    isPossible: null,
    isBelonging: null
  },
  sendPlayLog: () => {},
  loaded: false
}

EpisodeBritish.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

const StyledContainer1 = styled.div`
  padding: 50px 0 20px;
  background: url(/douga_mv/british-open-golf/images/player_bg.png) center top /
    cover;

  @media ${mediaSp} {
    padding: 0 0 10px;
    background: #fff;
  }
`

const StyledContainer1Inner = styled.div`
  margin: 0 auto;
  width: 960px;
  display: flex;
  flex-wrap: wrap;

  @media ${mediaSp} {
    width: auto;
    flex-direction: column;
  }
`

const StyledBreadCrumb = styled(BreadCrumb)`
  @media ${mediaSp} {
    display: none;
  }
`

const StyledThumbnail = styled.button`
  padding: 0;
  border-width: 0;
  background: none;
  width: 640px;
  height: 360px;
  position: relative;
  cursor: pointer;

  @media ${mediaSp} {
    padding: 8px;
    width: auto;
    height: auto;
    background: url(/douga_mv/british-open-golf/images/player_bg.png) no-repeat
      center top / cover;
    order: 1;
  }
`

const StyledDiv1 = styled.div`
  padding: 20px;
  width: 320px;
  background: #fff;
  color: #515151;
  font-family: ${yuGothic};

  @media ${mediaSp} {
    margin: 5px 15px 0;
    padding: 10px;
    border: 1px solid #ccc;
    width: auto;
    order: 3;
  }
`

const StyledDiv2 = styled.div`
  margin: 30px auto 0;
  width: 100%;
  font-size: 0;
  font-family: ${yuGothic};
  text-align: center;

  @media ${mediaSp} {
    margin-top: 0;
    order: 2;
  }
`

const StyledDiv3 = styled.div`
  width: 100%;
  text-align: center;

  @media ${mediaSp} {
    order: 4;
  }
`

const StyledList = styled(List)`
  margin-top: 12px;

  @media ${mediaSp} {
    margin-top: 10px;
  }
`

const StyledCaption = styled(Caption)`
  margin-top: 12px;

  @media ${mediaSp} {
    margin-top: 6px;
  }
`

const StyledSection = styled.section`
  padding-bottom: 100px;

  @media ${mediaSp} {
    padding-bottom: 50px;
  }
`

const StyledEpisodeList = styled(EpisodeList)`
  margin: 30px auto 0;
  width: 960px;

  @media ${mediaSp} {
    margin: 20px 15px 0;
    width: auto;
  }
`

const StyledImg = styled.img`
  margin-top: 6px;
`

const StyledEpisodeFooter = styled(EpisodeFooter)`
  margin-top: 0;
`

const GlobalStyle = createGlobalStyle`
.play-btn {
  margin: auto;
  padding-top: 170px;
  width: 144px;
  height: 144px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_pc.svg) no-repeat center center;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;

  @media ${mediaSp} {
    display: none;
  }
}

.play-btn-sp {
  padding-top: 68px;
  width: 80px;
  height: 80px;
  background: url(/images/exdio/renewal/icon_play_btn_for_video_area_sp.svg) no-repeat top center;
  display: none;
  position: absolute;
  right: 1%;
  bottom: 15px;
  z-index: 1;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  vertical-align: bottom;
  filter: drop-shadow(0 0 3px #fff);

  @media ${mediaSp} {
    display: block;
  }
}
`
