import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/* component */
import Radio from '../Radio'

/* style */
import { StyledDiv, StyledUl, StyledLi } from './style'
import { StyledLabel } from '../Radio/style'

const options = [
  {
    id: 1,
    value: '',
    label: 'すべて'
  },
  {
    id: 2,
    value: 'watched',
    label: '視聴済み'
  },
  {
    id: 3,
    value: 'not_watched',
    label: '未視聴'
  }
]

/**
 * フィルタ機能
 * ソート機能と内容がほぼ同じなので、共通コンポーネントにしても良い
 */
const FilterElm = ({
  filteredBy = '',
  updateSearchParams = () => {},
  stateSelectedElm = ['', () => {}],
  ...props
}) => {
  const [selectedElm, setSelectedElm] = stateSelectedElm
  const isOpen = selectedElm === 'filteredBy'
  const [currentOption, setCurrentOption] = useState({})
  const [filteredByDefaultLabel, setFilteredByDefaultLabel] = useState(
    filteredBy ? '' : 'フィルタ'
  )

  useEffect(() => {
    setCurrentOption(
      options.find((option) => option.value === filteredBy) || options[0]
    )
    if (filteredBy && filteredByDefaultLabel !== '')
      setFilteredByDefaultLabel('')
  }, [filteredBy])

  return (
    <StyledDiv isOpen={isOpen} {...props}>
      <StyledUl isOpen={isOpen}>
        <StyledLi>
          <StyledLabel
            onClick={() => setSelectedElm(isOpen ? '' : 'filteredBy')}
          >
            {filteredByDefaultLabel || currentOption.label}
          </StyledLabel>
        </StyledLi>
        {options.map(({ id, value, label }) => {
          return (
            <StyledLi key={id}>
              <Radio
                label={label}
                checked={filteredBy === value}
                radioProps={{
                  name: 'filter',
                  value,
                  id: `fFilter${id}`,
                  onChange: () => {
                    updateSearchParams({ filteredBy: value })
                  },
                  onClick: () => setSelectedElm('')
                }}
              />
            </StyledLi>
          )
        })}
      </StyledUl>
    </StyledDiv>
  )
}

export default memo(FilterElm)

FilterElm.propTypes = {
  filteredBy: PropTypes.oneOf(['', 'watched', 'not_watched']),
  updateSearchParams: PropTypes.func,
  stateSelectedElm: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  )
}
