import axios from 'axios';
import _ from 'lodash';

class Axios {

  constructor(options = {}) {
    this.options = options;
    this.api = axios.create(_.assign({responseType: 'json'}, options));

    // タイムアウトしていないのにタイムアウトエラーがでる対応ととして
    // retryオプションを使えるようにする
    // https://github.com/axios/axios/issues/164#issuecomment-327837467
    if (options.retry) {
      const _axios = this.api;
      _axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        var config = err.config;
        // If config does not exist or the retry option is not set, reject
        if(!config || !config.retry) return Promise.reject(err);

        // Set the variable for keeping track of the retry count
        config.__retryCount = config.__retryCount || 0;

        // Check if we've maxed out the total number of retries
        if(config.__retryCount >= config.retry) {
            // Reject with the error
            return Promise.reject(err);
        }

        // Increase the retry count
        config.__retryCount += 1;
        // Create new promise to handle exponential backoff
        var backoff = new Promise(function(resolve) {
            setTimeout(function() {
                resolve();
            }, config.retryDelay || 1);
        });

        // Return the promise in which recalls axios to retry the request
        return backoff.then(function() {
            return _axios(config);
        });
      });
    }
  }

  fetch(config, cancel=false) {
    console.log('axios', JSON.stringify(_.assign({}, this.options, config)));

    const CancelToken = axios.CancelToken;
    if (cancel) {
      let cancelSource;
      const req = this.api(Object.assign({}, {
        cancelToken: new CancelToken(function (c) {
          cancelSource = c;
        })
      }, config));
      return [req, cancelSource];
    } else {
      return new Promise((resolve, reject) => {
        this.api(config).then(function() {
          resolve.apply(this, arguments);
        }).catch((e) => {
          console.error(e.message);
          reject(e);
        })
      });
    }
  }

  get(url, params, options={}, cancel=false) {
    return this.fetch(Object.assign({}, {method: 'get', url: url, params: params}, options), cancel).then((res) => {
      return {data: res.data} });
  }

  oneGet(url, options={}, cancel=false) {
    return this.fetch(Object.assign({}, {method: 'get', url: url}, options), cancel).then((res) => {
      return {data: {metas: [res.data]}} });
  }

  post(url, data, options={}, cancel=false) {
    return this.fetch(Object.assign({}, {method: 'post', url: url, data: data}, options), cancel).then((res) => { return {data: res.data} });
  }

  patch(url, data, options={}, cancel=false) {
    return this.fetch(Object.assign({}, {method: 'patch', url: url, data: data}, options), cancel).then((res) => { return {data: res.data} });
  }

  del(url, options={}, cancel=false) {
    return this.fetch(Object.assign({}, {method: 'delete', url: url}, options), cancel).then((res) => { return {data: res.data} });
  }

  formPost(url, data, options={}, cancel=false) {
    return this.post(url, data, options, cancel).then((res) => { return {data: res.data} });
  }

  multi(requests) {
    return axios.all(requests).then(axios.spread((...response) => {
      return response;
    }));
  }
}

export default Axios;
