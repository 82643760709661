import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  ADVERTISING_SCHEMA_ID,
  META_SCHEMA_ID,
  PALETTE_SCHEMA_ID
} from '../../../../../constants/app'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'
import ImageSlider from '../../../../common/components/renewal/ImageSlider'
import Footer from '../../../../common/components/Footer'
import FeaturePalette from '../../../../common/components/renewal/FeaturePalette'
import HeaderNewsComponent from '../HeaderNewsComponent'
import useIsMounted from '../../../../hooks/useIsMounted'

/* style */
import {
  StyledDiv1,
  StyledDiv2,
  StyledDiv3,
  StyledH2,
  StyledDiv4,
  StyledDiv5,
  StyledDiv6
} from './style'

const PAGE_TITLE = '特集'

/** 特集トップ mpページ */
const FeatureMpContent = (props, context) => {
  const model = context.falcorModel.batch(100)
  const isMounted = useIsMounted()
  const [palette, setPalette] = useState([])
  const [boxLength, setBoxLength] = useState(0)
  const [howToPlays, setHowToPlays] = useState({})
  const [products, setProducts] = useState({})
  const [courses, setCourses] = useState({})

  /** パレット情報取得 */
  const getPalettes = () => {
    const pathCanvas = ['canvas', 'feature']
    return model.fetch([pathCanvas]).then((result) => {
      const canvasObj = _.get(result, ['json', ...pathCanvas])

      if (!canvasObj || !canvasObj.structure || !canvasObj.structure.length) {
        setPalette([])
        return Promise.resolve()
      }
      const featureListId = document.URL.match(/tv-asahi.co.jp/) ? 62 : 108
      const paletteIds = canvasObj.structure.map((s) => s.id)
      const path = [['palette', paletteIds]]

      return model.fetch(path).then((resultPalette) => {
        const palettesObj = _.get(resultPalette, ['json', 'palette'], {})
        const palettes = paletteIds
          .map((key) => palettesObj[key])
          .filter((v) => v)
        const objectLength = palettesObj[featureListId].objects.length
        setPalette(palettes)
        setBoxLength(objectLength)
      })
    })
  }

  /** 価格情報取得 */
  const getHowToPlays = () => {
    const metaIdsMap = {}
    palette
      .filter(
        (p) =>
          ![PALETTE_SCHEMA_ID.IMAGES, PALETTE_SCHEMA_ID.KEYWORDS].includes(
            p.schema_id
          )
      )
      .flatMap((p) => p.objects)
      .filter((obj) => obj.type === 'meta')
      .map((obj) => obj.meta)
      .filter(
        (metas) => metas.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE
      )
      .forEach((metas) => {
        metaIdsMap[metas.meta_id] = metas.meta_id
      })
    const metaIds = Object.keys(metaIdsMap)
    if (!metaIds.length) return Promise.resolve()

    const path = [['meta', 'howToPlay', false, metaIds]]
    return model.fetch(path).then((result) => {
      const howToPlaysObj =
        _.get(result, ['json', 'meta', 'howToPlay', false]) || {}

      if (isMounted) {
        setHowToPlays(howToPlaysObj)
      }
    })
  }

  /** 商品・コース情取得報 */
  const getProductsAndCourses = () => {
    const advertisings = palette
      .filter(
        (p) =>
          ![PALETTE_SCHEMA_ID.IMAGES, PALETTE_SCHEMA_ID.KEYWORDS].includes(
            p.schema_id
          )
      )
      .flatMap((p) => p.objects)
      .filter((obj) => obj.type === 'advertising')
      .map((obj) => obj.advertising)

    const productIds = advertisings
      .filter(
        (ad) =>
          ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT ||
          ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT_LIVE
      )
      .map((h) => h.values.product)
      .filter((v) => v)
    const courseIds = advertisings
      .filter(
        (ad) =>
          ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE ||
          ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE_LIVE
      )
      .map((h) => h.values.course)
      .filter((v) => v)
    const path = [
      ['product', productIds],
      ['course', courseIds]
    ]
    return model.fetch(path).then((result) => {
      const productsObj = _.get(result, ['json', 'product']) || {}
      const coursesObj = _.get(result, ['json', 'course']) || {}
      if (isMounted) {
        setProducts(productsObj)
        setCourses(coursesObj)
      }
    })
  }

  // 初回レンダリング時のみ実行
  useEffect(() => {
    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(context, PAGE_TITLE)
    // GTMの更新
    const title = context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)

    getPalettes()
  }, [])

  // palette取得後のみ実行
  useEffect(() => {
    if (palette.length) {
      getHowToPlays()
      getProductsAndCourses()
    }
  }, [palette])

  // 画像スライド用パレット
  const imageSliderPalette = (palette || []).find(
    (p) => p.schema_id === PALETTE_SCHEMA_ID.IMAGES
  )
  const imageSliderObjects = _.get(imageSliderPalette, 'objects') || []

  // 画像スライド以外のパレット
  const paletteComponents = (palette || []).filter(
    (p) => p.schema_id !== PALETTE_SCHEMA_ID.IMAGES
  )

  const emptyBox = []
  for (let i = 0; i < boxLength; i += 1) {
    emptyBox.push(<StyledDiv1 key={`emptyBox${i}`} className="box is-empty" />)
  }

  if (!isMounted) return null

  return (
    <StyledDiv2>
      <HeaderNewsComponent />
      <SpSubNavigation />
      <StyledDiv3>
        <StyledDiv4>
          <StyledH2>特集</StyledH2>
        </StyledDiv4>
      </StyledDiv3>
      <ImageSlider key="slide-program" objects={imageSliderObjects} />
      <StyledDiv5>
        {paletteComponents.map((p) => (
          <FeaturePalette
            key={p.palette_id}
            palette={p}
            howToPlays={howToPlays}
            products={products}
            courses={courses}
            paletteHeader={false}
          />
        ))}
        {emptyBox}
      </StyledDiv5>
      <StyledDiv6>
        <Footer />
      </StyledDiv6>
    </StyledDiv2>
  )
}

export default FeatureMpContent

FeatureMpContent.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}
