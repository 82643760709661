import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Cookie from 'js-cookie'
import namespace from '../../../../common/namespace'
import webApp from '../../utils/exdioWebAppUtils'
import { META_SCHEMA_ID, PRODUCT_SCHEMA } from '../../../../constants/app'
import NotFound from '../../../generic/components/errors/NotFound'
import Footer from '../../../common/components/Footer'
import * as tags from './plan_logirl/Tags'
import logirlId from './plan_logirl/data/program_list.json'

const { sprintf } = require('sprintf-js')
//
/** 番組ページ */
export default class ProgramContent extends Component {
  //型宣言
  static propTypes = {
    seriesId: PropTypes.string,
    seasonId: PropTypes.string,
    version: PropTypes.string
  }

  //初期値設定
  static defaultProps = {
    seriesId: '',
    seasonId: '',
    version: ''
  }

  //型宣言
  static contextTypes = {
    app: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  static getPath(_models, _options, props) {
    return ['metas', props.seasonId]
  }

  static getPrefetchPaths(models, options, props) {
    return [ProgramContent.getPath(models, options, props)]
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const meta =
      _.get(prefetchResult, [
        'json',
        ...ProgramContent.getPath(models, options, props)
      ]) || {}
    let [metaName, subTitle] = webApp.utils.titles(meta)
    metaName = `${metaName} ${subTitle}`
    const title = metaName
    const description = meta.values ? meta.values.evis_SeasonLongSynopsis : null
    const thumbnail = meta.thumbnail_url
    return { title, thumbnail, description }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    const path = ProgramContent.getPath({}, {}, props)
    const meta = this.model.getSync(path) || null

    this.state = {
      meta,
      otherSeasons: null,
      episodes: [],
      products: [],
      howToPlays: {},
      displayModes: {},
      latestEpisodeStatus: {
        displayMode: null,
        isFree: null,
        isNotFree: null,
        isPurchased: null,
        isNotPurchased: null,
        isInCourse: null,
        isNotInCourse: null
      },
      loaded: false
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const { seriesId, seasonId, version } = this.props
    const shinchanSeriesId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? ['10965', '12859']
      : ['11721']

    const envKey = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 'stg'
      : 'prd'
    const str_series_season = `${seriesId}-${seasonId}`

    // logirl判定
    const isLogirl = logirlId[envKey].data.includes(str_series_season)

    this.getMeta()
      .then(() => this.updateMetaTags())
      .then(() => this.getOtherSeason())
      .then(async () => {
        const { meta } = this.state
        const templateId = _.get(meta, ['values', 'ex_templateId'], 0)
        const isShinchan =
          shinchanSeriesId.indexOf(seriesId) > -1 &&
          version !== '検索高機能版' &&
          templateId == 200

        if (shinchanSeriesId.indexOf(seriesId) > -1) {
          this.addUsergramTagShinchan()
        }
        // logirlリタゲタグの挿入(logirl以外も全ページ)
        this.addLogirlTags()

        if (isShinchan || isLogirl) {
          /** 最新話のみ取得し、必要な情報をセットする */
          const latestEpisode = await this.getLatestEpisode().catch((e) =>
            webApp.utils.handleFalcorError(e, this.context)
          )
          const {
            products,
            howToPlays,
            displayModes
          } = await this.getHowToPlay([latestEpisode]).catch((e) =>
            webApp.utils.handleFalcorError(e, this.context)
          )
          this.setState(
            {
              episodes: [latestEpisode],
              products,
              howToPlays,
              displayModes
            },
            () => {
              const { episodes, howToPlays } = this.state
              const latestEpisode = episodes[0]
              const howToPlay = latestEpisode
                ? howToPlays[latestEpisode.meta_id]
                : null
              this.getLatestEpisodeStatus(latestEpisode, howToPlay).catch((e) =>
                webApp.utils.handleFalcorError(e, this.context)
              )
              this.setState({ loaded: true })
            }
          )
        } else {
          /** 全話取得し、必要な情報をセットする */
          const episodes = await this.getEpisodes().catch((e) =>
            webApp.utils.handleFalcorError(e, this.context)
          )
          this.setState({ episodes }, async () => {
            const {
              products,
              howToPlays,
              displayModes
            } = await this.getHowToPlay(episodes).catch((e) =>
              webApp.utils.handleFalcorError(e, this.context)
            )

            this.setState(
              { products, howToPlays, displayModes, loaded: true },
              () => {
                // PCの場合は最新話の視聴期限表示のために購入情報を取得
                if (!webApp.utils.isSp()) {
                  const latestEpisode = _.get(episodes, [0]) || null
                  const howToPlay = latestEpisode
                    ? howToPlays[latestEpisode.meta_id]
                    : null
                  this.getLatestEpisodeStatus(
                    latestEpisode,
                    howToPlay
                  ).catch((e) =>
                    webApp.utils.handleFalcorError(e, this.context)
                  )
                }
              }
            )
          })
        }
      })
      .catch((e) => webApp.utils.handleFalcorError(e, this.context))

    // レコメンド
    this.recommendActionLog().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
  }

  componentWillUnmount() {
    this._isMounted = false
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    const { seriesId } = this.props
    const shinchanSeriesId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? '10965'
      : '11721'
    const shinchanUsergramIds = [
      'usergram-member-shinchan-program',
      'usergram-common-shinchan-program'
    ]

    if (seriesId === shinchanSeriesId && isLoggedIn === true) {
      webApp.utils.removeUserGram(shinchanUsergramIds)
    }
    webApp.utils.removeUserGram(Object.values(tags.tagIds))

    webApp.utils.returnDefaultWebClip()
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags() {
    const { meta } = this.state

    // タイトルタグの更新
    let [metaName, subTitle] = webApp.utils.titles(meta)
    metaName = `${metaName} ${subTitle}`
    const title = metaName.trim()
    webApp.utils.updateTitle(
      sprintf(this.config.title_template, title || this.config.default_title)
    )

    // メタタグの更新
    const { copyright } = this.config
    const description =
      (meta.values ? meta.values.evis_SeasonLongSynopsis : null) ||
      this.config.description
    const keywords = this.config.keywords_catchup
    const rootUrl = `${window.location.protocol}//${window.location.host}`
    const ogImage =
      meta.thumbnail_url ||
      sprintf('%s/images/exdio/%s', rootUrl, this.config.og_image)
    const url = window.location.href
    const regularUrl = url.replace(/\?.*$/, '')
    const episodeId = meta.meta_id
    const seriesAltId =
      _.get(meta, ['values', 'parents_series', 'avails_SeriesAltID']) || ''
    const seasonAltId = _.get(meta, ['values', 'avails_SeasonAltID']) || ''
    const programId = `${seriesAltId}_${seasonAltId}`
    const now = new Date().getTime()
    const tsukasaId =
      _.get(meta, ['values', 'avails_SeasonTitleInternalAlias']) || ''

    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'startTime', value: now },
      { key: 'program_id', value: programId },
      { key: 'program', value: title },
      { key: 'episode_id', value: episodeId },
      { key: 'episode', value: title },
      { key: 'tsukasa_id', value: tsukasaId }
    ]
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: ogImage },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'mixi:image', content: ogImage },
        { property: 'og:image', content: ogImage },
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: regularUrl }]
    }
    webApp.utils.updateMeta(metaTags)
    webApp.utils.updateDataLayer(gtmTags)
    webApp.utils.updateCookieSync(window.navigator.userAgent)
  }

  /** logirl 広告タグ */
  addLogirlTags() {
    Object.keys(tags.tagIds).forEach((key) => {
      const target = document.getElementById(tags.tagIds[key])
      if (!target) {
        webApp.utils.appendUserGram(tags[key])
      }
    })
  }

  /** クレヨンしんちゃん番組ページ USERGRAMタグ追加 */
  addUsergramTagShinchan() {
    const { meta } = this.state
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)

    if (isLoggedIn === false) {
      return
    }

    const season_name = meta.values.evis_FrontDisplayTitle
    let member_id = ''
    const cookies = document.cookie
    const cookiesArr = cookies.split(';')

    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split('=')
      if (cookieArr[0] == ' CBM_ID') {
        member_id = cookieArr[1]
      }
    }

    let browseUserGram = ''
    browseUserGram +=
      '<script type="text/javascript" id="usergram-member-shinchan-program">'
    browseUserGram += 'window.ugattr = window.ugattr || {};'
    browseUserGram += `ugattr[\'serviceId\'] = \'${member_id}\';`
    browseUserGram += `ugattr[\'prop07\'] = \'${season_name}\';`
    browseUserGram += `ugattr[\'prop09\'] = \'\';`
    browseUserGram += `ugattr[\'prop10\'] = \'\';`
    browseUserGram += '</script>'

    browseUserGram +=
      '<script type="text/javascript" id="usergram-common-shinchan-program">'
    browseUserGram +=
      '(function(){var a=window,b=document,c=a.usergram=a.usergram||[],d,e;'
    browseUserGram +=
      "c.l||(c.s=(new Date()).getTime(),c.l=!0,d=b.getElementsByTagName('script')[0],"
    browseUserGram +=
      "e=b.createElement('script'),e.type='text/javascript',e.async=true,"
    browseUserGram +=
      "e.src='//code.usergram.info/js/usergram.js',d.parentNode.insertBefore(e,d))})();"
    browseUserGram +=
      'window.usergram=window.usergram||[],window.ugattr=window.ugattr||{};'
    browseUserGram +=
      "usergram.push(['send','Ug37cn-1','cv','shinchan_program',ugattr]);"
    browseUserGram += '</script>'

    webApp.utils.appendUserGram(browseUserGram)
  }

  /** メタ情報詳細取得 */
  getMeta() {
    const { seriesId, seasonId } = this.props
    if (!seriesId || !seasonId) {
      if (this._isMounted) {
        this.setState({ meta: {} })
      }
      return Promise.resolve()
    }
    const path = ProgramContent.getPath({}, {}, this.props)
    return this.model.fetch([path]).then((result) => {
      const meta = _.get(result, ['json', ...path]) || {} //シーズンのメタ情報
      const parentSeriesId = _.get(meta, ['values', 'parents_series', 'id'])
      const isValidSeriesId = seriesId === String(parentSeriesId)
      if (this._isMounted) {
        this.setState({ meta: isValidSeriesId ? meta : {} })
      }
    })
  }

  // 他のシーズンの取得
  getOtherSeason() {
    const { meta } = this.state

    let w_otherSeasons = _.get(meta, ['values', 'other_series']) || []
    const w_otherSeasonsLive =
      _.get(meta, ['values', 'other_series_live']) || []
    w_otherSeasons = w_otherSeasons.concat(w_otherSeasonsLive)
    w_otherSeasons = w_otherSeasons.filter((v) => v)
    if (!w_otherSeasons) return Promise.resolve()

    const path = ['metas', w_otherSeasons]
    return this.model.fetch([path]).then((result) => {
      const hash_otherSeasons = _.get(result, ['json', 'metas']) || {}
      delete hash_otherSeasons.$__path
      if (hash_otherSeasons && this._isMounted) {
        this.setState({ otherSeasons: Object.values(hash_otherSeasons) })
      }
    })
  }

  /** 同シーズンのエピソード一覧取得 */
  getEpisodes() {
    const { seasonId } = this.props
    if (!seasonId) return Promise.resolve()

    const path = ['meta', 'children', seasonId]
    const w_model = this.context.falcorModel

    //シーズンに登録してある単話一覧
    return w_model.fetch([path]).then((result) => {
      const episodes =
        _.get(result, ['json', 'meta', 'children', seasonId]) || []
      if (this._isMounted && (episodes || []).length) {
        // 話数でソートする
        return episodes.sort((a, b) => {
          // 配信開始日時 -> 管理画面の降順
          if (a.delivery_start_at < b.delivery_start_at) return 1
          if (a.delivery_start_at > b.delivery_start_at) return -1

          // エピソード番号 -> 管理画面の降順
          if (
            Number(a.values.avails_EpisodeNumber) <
            Number(b.values.avails_EpisodeNumber)
          )
            return 1
          if (
            Number(a.values.avails_EpisodeNumber) >
            Number(b.values.avails_EpisodeNumber)
          )
            return -1

          // ID -> 管理画面の降順
          if (a.id < b.id) return 1
          if (a.id > b.id) return -1

          return 0
        })
      } else {
        return this.state.episodes
      }
    })
  }

  /**
   * パック商品情報取得
   * 同一シーズンのエピソードを含むパックを取得
   */
  async getHowToPlay(episodes) {
    if (!episodes || !episodes.length) return Promise.resolve({})

    const metaIds = episodes.map((e) => e.meta_id)

    return await (async () => {
      // howtoplayがかなり重いので
      // 最初に5件取得してファーストビューのデータを表示した後、残りを遅延ロードさせる

      // array分割
      const arrayChunk = ([...array], size = 1) => {
        return array.reduce(
          (acc, value, index) =>
            index % size ? acc : [...acc, array.slice(index, index + size)],
          []
        )
      }

      let initial_chunk = metaIds.slice(0, 5)
      let after_chunk = metaIds.slice(5, metaIds.length)

      after_chunk = arrayChunk(after_chunk, 10)
      const chunk = [initial_chunk].concat(after_chunk)

      let products = []
      let howToPlays = {}
      let displayModes = {}

      for (let i = 0; i < chunk.length; i++) {
        const path = [['meta', 'howToPlay', true, chunk[i]]]
        const w_model = this.context.falcorModel
        await w_model.fetch(path).then((result) => {
          let w_howToPlays =
            _.get(result, ['json', 'meta', 'howToPlay', true]) || {}

          w_howToPlays = Object.assign(w_howToPlays, howToPlays)

          const productsTmp = {}
          Object.keys(w_howToPlays)
            .filter((k) => !k.startsWith('$'))
            .filter((k) => !!w_howToPlays[k])
            .flatMap((k) => w_howToPlays[k].products)
            .filter((p) => p.schema_id !== PRODUCT_SCHEMA.SINGLE_STORY.id)
            // distinct
            .forEach((p) => {
              productsTmp[p.product_id] = p
            })
          const w_products = Object.keys(productsTmp).map((k) => productsTmp[k])

          products = Object.assign(products, w_products)
          howToPlays = Object.assign(howToPlays, w_howToPlays)

          // 各エピソードの表示モード判定
          const w_displayModes = {}
          _.forEach(episodes, (episode) => {
            const metaId = episode.meta_id
            const productsForDisp =
              _.get(howToPlays, [metaId, 'products']) || []
            const product = productsForDisp.find(
              (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
            )
            const course = _.get(howToPlays, [metaId, 'courses', 0])
            w_displayModes[episode.meta_id] = webApp.utils.getDisplayMode(
              episode,
              product,
              course
            )
          })

          displayModes = Object.assign(displayModes, w_displayModes)
        })

        // 最初１グループのときだけレンダリングして一旦表示させて、あとは最後まで読み込んでから表示
        if (i === 0) {
          this.setState({ products, howToPlays, displayModes })
        }
      }

      return { products, howToPlays, displayModes }
    })()
  }

  /** 最新話の購入情報取得 */
  getLatestEpisodeStatus(latestEpisode, howToPlay) {
    const product =
      (_.get(howToPlay, ['products']) || []).find(
        (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
      ) || null
    const course = _.get(howToPlay, ['courses', 0]) || null
    const displayMode = webApp.utils.getDisplayMode(
      latestEpisode,
      product,
      course
    )
    const metaSchemaId = _.get(latestEpisode, ['meta_schema_id']) || null

    if (this._isMounted) {
      this.setState({
        latestEpisodeStatus: {
          ...this.state.latestEpisodeStatus,
          displayMode,
          isFree: [META_SCHEMA_ID.EPISODE, META_SCHEMA_ID.LIVE].includes(
            metaSchemaId
          ),
          isNotFree: [
            META_SCHEMA_ID.EPISODE_NOT_FREE,
            META_SCHEMA_ID.LIVE_NOT_FREE
          ].includes(metaSchemaId),
          isInCourse: !!course,
          isNotInCourse: !course
        }
      })
    }

    // 通常単話の場合の取得処理
    if (!course) {
      return this.getIsPossible(product)
    }
    // 月額見放題に含まれる単話の場合の取得処理
    if (course) {
      return this.getBelonging(course).then(() => {
        const { isPurchased, isNotPurchased } = this.state.latestEpisodeStatus
        if (isPurchased) {
          // 月額見放題加入済みの場合は期限取得
          return this.getPurchased(course)
        } else if (isNotPurchased) {
          // 月額見放題未加入の場合は単話購入確認
          return this.getIsPossible(product)
        }
        return Promise.resolve()
      })
    }
    return Promise.resolve()
  }

  /** 素材のライセンス有無取得 */
  getIsPossible(product) {
    if (!product) return Promise.resolve()
    const refId = product.ref_id
    if (!refId) {
      // コンソールにエラーログだけ吐いて後続処理
      console.error(
        `The "ref_id" of the product "${product.name}" is not registered.`
      )
      return Promise.resolve()
    }

    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) {
      if (this._isMounted) {
        this.setState({
          latestEpisodeStatus: {
            ...this.state.latestEpisodeStatus,
            isNotPurchased: true
          }
        })
      }
      return Promise.resolve()
    }

    const path = ['infra', 'isPossible', refId]
    return this.model.fetch([path]).then((result) => {
      const isPossible = _.get(result, ['json', ...path]) || {}
      if (this._isMounted) {
        this.setState({
          latestEpisodeStatus: {
            ...this.state.latestEpisodeStatus,
            isPurchased: !!isPossible.status,
            isNotPurchased: !isPossible.status,
            limitDate: isPossible.limit_date
          }
        })
      }
    })
  }
  /** グループのライセンス有無取得 */
  getBelonging(course) {
    if (!course) return Promise.resolve()

    const refId = course.ref_id
    if (!refId) {
      // コンソールにエラーログだけ吐いて後続処理
      console.error(
        `The "ref_id" of the course "${course.name}" is not registered.`
      )
      return Promise.resolve()
    }

    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) {
      if (this._isMounted) {
        this.setState({
          latestEpisodeStatus: {
            ...this.state.latestEpisodeStatus,
            isNotPurchased: true
          }
        })
      }
      return Promise.resolve()
    }

    const path = ['infra', 'gBelong', refId]
    return this.model.fetch([path]).then((result) => {
      const belonging = _.get(result, ['json', ...path]) || false
      if (this._isMounted) {
        this.setState({
          latestEpisodeStatus: {
            ...this.state.latestEpisodeStatus,
            isPurchased: belonging,
            isNotPurchased: !belonging
          }
        })
      }
    })
  }
  /** 購入済情報取得 */
  getPurchased(course) {
    const { latestEpisodeStatus } = this.state
    if (!course || !latestEpisodeStatus.isPurchased) return Promise.resolve()
    const refId = course.ref_id
    if (!refId) return Promise.resolve()

    const path = ['infra', 'vodPurchased']
    return this.model.fetch([path]).then((result) => {
      const vodPurchased = _.get(result, ['json', ...path]) || []
      const targetPurchased = vodPurchased.find((purchased) => {
        return purchased.course && purchased.course.ref_id === refId
      })
      if (this._isMounted && targetPurchased) {
        this.setState({
          latestEpisodeStatus: {
            ...this.state.latestEpisodeStatus,
            limitDate: targetPurchased.limit_date
          }
        })
      }
    })
  }

  /** レコメンド(閲覧ログ蓄積) */
  recommendActionLog() {
    const { seasonId } = this.props
    if (!seasonId) return Promise.resolve()
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) return Promise.resolve()
    const path = [['action_log', 'history', 'view', seasonId, sessionId]]
    return this.model.fetch(path)
  }

  /** 最新話の取得 */
  getLatestEpisode() {
    const { seasonId } = this.props
    const path = ['meta', 'season', seasonId, 'latestChild']
    return this.model
      .fetch([path])
      .then((result) => _.get(result, ['json', ...path]) || null)
  }

  render() {
    const {
      meta,
      otherSeasons,
      episodes,
      products,
      howToPlays,
      displayModes,
      recommendItems,
      latestEpisodeStatus,
      loaded
    } = this.state
    if (!meta) return null
    if (!Object.keys(meta).length)
      return [<NotFound key="not-found" />, <Footer key="footer" />]

    // 番組ページはテンプレート利用対象
    const templateId = meta.values.ex_templateId || 0
    const templateKeyPrefix = 'program_'
    const templateKey = `${templateKeyPrefix}${templateId}`
    const template =
      namespace.templates[templateKey] ||
      namespace.templates[`${templateKeyPrefix}0`]
    if (!template) {
      console.error(`Template:${templateKey} not found.`)
      return null
    }

    const childProps = Object.assign({}, this.props, {
      meta,
      otherSeasons,
      episodes,
      products,
      howToPlays,
      displayModes,
      recommendItems,
      latestEpisodeStatus,
      loaded
    })
    return React.createElement(template, childProps)
  }
}
