import PropTypes from 'prop-types'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'

/** ドラえもん番組ページ テンプレート */
const ProgramDoraemon = (_, context) => {
  /** PLANページにリダイレクト */
  const isApp = webApp.utils.isApp(context)
  const routeKey = isApp ? 'app_plan' : 'plan'
  context.history.push(routes[routeKey].makePath({ slug: 'doraemon' }))
  return null
}

export default ProgramDoraemon

ProgramDoraemon.contextTypes = {
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  })
}
