import React from 'react'
import PropTypes from 'prop-types'
import { SelectionItemType } from '../../types'

/* components */
import SelectionItem from '../../SelectionItem'

/* style */
import { StyledUl, StyledLi, StyledButton } from './style'

/** List1 Component */
const List1 = ({
  orderBy = 'asc',
  selectionItems = [],
  start = 0,
  end = 0,
  onChangeTags = () => {},
  ...props
}) => {
  /**
   * 厳選エピソードをidでソートした配列を返す
   * @param {array} selectionItemArr - 厳選エピソード配列
   * @return {array} - ソートされた配列
   */
  const sortSelectionItems = (selectionItemArr = []) => {
    const sortedItems = selectionItemArr.sort(({ id: idA }, { id: idB }) => {
      switch (orderBy) {
        case 'asc':
          return idA > idB ? 1 : -1
        case 'desc':
          return idA < idB ? 1 : -1
        default:
          return 0
      }
    })

    if (end) {
      return sortedItems.slice(start, end)
    }
    return sortedItems
  }

  return (
    <StyledUl {...props}>
      {sortSelectionItems(selectionItems).map((item) => {
        const { id, primary } = item
        return (
          <StyledLi key={id}>
            <StyledButton
              type="button"
              key={`selection-${id}`}
              onClick={() => onChangeTags(item)}
            >
              <SelectionItem {...primary} />
            </StyledButton>
          </StyledLi>
        )
      })}
    </StyledUl>
  )
}

export default List1

List1.propTypes = {
  orderBy: PropTypes.oneOf(['asc', 'desc']),
  selectionItems: PropTypes.arrayOf(SelectionItemType),
  start: PropTypes.number,
  end: PropTypes.number,
  onChangeTags: PropTypes.func
}
