import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import window from 'global'
import Cookie from 'js-cookie'
import {
  LOCAL_STORAGE_KEY_PURCHASE,
  PURCHASE_TYPE,
  META_SCHEMA_ID,
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE,
  FAVORITE_TYPE
} from '../../../../../../constants/app'
import routes from '../../../../../common/routes'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import useMediaQuery from '../../../../../hooks/useMediaQuery'
import useIsMounted from '../../../../../hooks/useIsMounted'
import { isPlayable } from '../../EpisodeDefault/util'
import EpisodeFooter from '../../../../../common/components/EpisodeFooter'
import RenewalPlayer from '../../../player/RenewalPlayer'
import AddButtonBlock from '../../details/AddButtonBlock'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import Breadcrumbs from '../Breadcrumbs'

//pc
import SwitchableHarbor from '../SwitchableHarbor'
import EpisodePager from './EpisodePager'

//sp
import SpSwitchableHarbor from '../SpSwitchableHarbor'
import SpEpisodePager from './SpEpisodePager'

/* style */
import {
  StyledH1,
  StyledDiv1,
  StyledH2,
  StyledDiv2,
  StyledDiv3,
  StyledDiv4,
  StyledDiv5,
  StyledA1,
  StyledLink1,
  StyledDiv6,
  StyledDiv7,
  StyledDiv8,
  StyledDiv9,
  StyledImg1,
  StyledH3,
  StyledUl1,
  Styledli1,
  StyledP1,
  StyledDiv10,
  StyledDiv11,
  StyledDiv12,
  StyledDiv13,
  StyledP2
} from './style'

/** 港時間単話ページ テンプレート */
const EpisodeHarbor = (
  {
    seriesId,
    seasonId,
    episodeId,
    meta,
    otherSeasons,
    episodes,
    products,
    status,
    product,
    course,
    rightMetas,
    productRight,
    loaded,
    sendPlayLog,
    ...props
  },
  context
) => {
  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix

  const [progress, setProgress] = useState(
    webApp.utils.progress(config, props.meta)
  )
  const [hover, setHover] = useState(false)
  const isSmp = useMediaQuery()
  const playerRef = useRef(true)
  const isMounted = useIsMounted()
  const pageUrl =
    webApp.utils.rootUrl() +
    routes.episode.makePath({ seriesId, seasonId, episodeId })

  useEffect(() => {
    return () => {
      setIsMounted(false)
      document.body.style.overflow = null
    }
  }, [])

  useEffect(() => {
    const isAutoPlay = context.routeHandler.query.auto === 't'

    if (loaded && status && !isSmp && isAutoPlay) {
      const isFree = EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode)
      const isAuthFree =
        EPISODE_DISPLAY_MODE.SVOD_AUTH_FREE === status.displayMode &&
        webApp.utils.isLoggedIn(context)
      const withValidPreviewToken = _.get(context, [
        'models',
        'state',
        'data',
        'withValidPreviewToken'
      ])
      if (isFree || isAuthFree || status.isPurchased || withValidPreviewToken) {
        onClickPlay()
      }
    }
  }, [loaded])

  const onClickPlay = (e, asSvod = false) => {
    if (e) e.preventDefault()

    let purchaseAvarableStatus = 3

    // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
    // https://logiclogic.backlog.jp/view/EX_DIO-1467
    const withValidPreviewToken = _.get(context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])
    if (withValidPreviewToken) {
      purchaseAvarableStatus = 0
    } else {
      // 念の為購入できないコンテンツはブロックする
      if (!status.isPurchseAvailable) {
        return
      }

      // 端末・地域制限に引っかかる場合は反応させない
      if (!status.isGeoDeliverable || status.isDeviceNotAvailable) {
        return
      }

      switch (status.displayMode) {
        case EPISODE_DISPLAY_MODE.FREE:
        case EPISODE_DISPLAY_MODE.TVOD_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_FREE:
          purchaseAvarableStatus = 0
          break
        case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
          if (status.isPurchased) {
            purchaseAvarableStatus = 0
          } else if (status.isNotPurchased) {
            if (asSvod) {
              // STVODでSVODボタンが押された場合
              purchaseAvarableStatus = 2
            } else if (product && !webApp.utils.isOnSale(context, product)) {
              // 商品が販売期間外
            } else {
              purchaseAvarableStatus = 1
            }
          }
          break
        case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
          if (status.isPurchased) {
            purchaseAvarableStatus = 0
          } else if (status.isNotPurchased) {
            purchaseAvarableStatus = 2
          }
          break
        case EPISODE_DISPLAY_MODE.UNKNOWN:
        default:
          break
      }
    }

    switch (purchaseAvarableStatus) {
      case 0:
        if (playerRef) playerRef.current.play()
        // 再生ログ送信
        if (typeof sendPlayLog === 'function') sendPlayLog()
        break
      case 1:
        const metaId = episodeId
        window.localStorage.setItem(
          LOCAL_STORAGE_KEY_PURCHASE,
          JSON.stringify({
            type: PURCHASE_TYPE.EPISODE,
            id: Number(seasonId),
            selected: [Number(metaId)],
            page: context.routeHandler.url
          })
        )
        context.history.push(routes.purchase.makePath())
        break
      case 2:
        const courseIdNum = Number(course.course_id)
        window.localStorage.setItem(
          LOCAL_STORAGE_KEY_PURCHASE,
          JSON.stringify({
            type: PURCHASE_TYPE.PLAN,
            id: courseIdNum,
            selected: [courseIdNum],
            page: context.routeHandler.url
          })
        )
        context.history.push(routes.purchase.makePath())
        break
      default:
        break
    }
  }

  const onClosePlayer = () => {
    if (isMounted) {
      setProgress({
        progress: webApp.utils.progress(config, meta)
      })
    }
  }

  const renderPlayButton = () => {
    let watchAvarableStatus = 3

    if (!loaded || !status.displayMode) return null

    // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
    const withValidPreviewToken = _.get(context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])
    if (withValidPreviewToken) {
      watchAvarableStatus = 0
    } else {
      switch (status.displayMode) {
        case EPISODE_DISPLAY_MODE.FREE:
        case EPISODE_DISPLAY_MODE.TVOD_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_FREE:
          watchAvarableStatus = 0
          break
        case EPISODE_DISPLAY_MODE.UNKNOWN:
          watchAvarableStatus = 2
          break
        default:
          // その他はすべて有料設定
          if (status.isNotPurchased) {
            if (status.isPurchseAvailable) {
              watchAvarableStatus = 1
              if (
                [
                  EPISODE_DISPLAY_MODE.TVOD_NOT_FREE,
                  EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE
                ].includes(status.displayMode) &&
                product &&
                !webApp.utils.isOnSale(context, product)
              ) {
                // TVODについては商品が販売期間外の場合は購入不可
                watchAvarableStatus = 2
              }
            } else {
              watchAvarableStatus = 2
            }
          } else {
            watchAvarableStatus = 0
          }
          break
      }
    }

    let ret = null
    switch (watchAvarableStatus) {
      case 0:
        ret = [
          <StyledDiv9 key="btn" />,
          <StyledDiv7 key="btn-sp">視聴する</StyledDiv7>
        ]
        break
      case 1:
        ret = [
          <StyledDiv8 key="status">未購入</StyledDiv8>,
          <StyledDiv9 key="btn">購入して再生</StyledDiv9>,
          <StyledDiv7 key="btn-sp">購入して再生</StyledDiv7>
        ]
        break
      case 2:
        ret = [null, null, null]
        break
      default:
        break
    }

    return ret
  }

  const hoverArrow = () => {
    setHover(true)
  }

  const outArrow = () => {
    setHover(false)
  }

  let [metaName, subTitle] = webApp.utils.titles(meta)
  metaName = `${metaName} ${subTitle}`
  const time = webApp.utils.duration(meta) || '-'

  let showRest = status.isPurchased
  if (!showRest) {
    switch (status.displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        showRest = true
        break
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
        break
      default:
    }
  }

  const isLive =
    meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
    meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE

  // LIVE配信 主/副切替ID
  let metaIdForPlayer
  if (isLive) {
    if (meta.values.clipping_select_media_type === 'vod')
      metaIdForPlayer = meta.values.clipping_vod_ref_id
    if (!metaIdForPlayer) metaIdForPlayer = meta.values.target_ref_id
  }
  if (!metaIdForPlayer)
    metaIdForPlayer = `${config.videocloud.reference_id_prefix || ''}${
      meta.meta_id
    }`

  const playerSettings = webApp.utils.getPlayerSettings(
    config,
    meta,
    status.displayMode
  )

  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  let thumbnailUrl = meta.thumbnail_url
  let nextPrevText = null
  let inViewTerm = true
  if (moment(prevTime).isBefore(meta.delivery_start_at)) {
    if (isLive) thumbnailUrl = meta.values.livectl_image_before_stream_url
    nextPrevText = isLive ? null : 'まもなく配信予定'
    inViewTerm = false
  } else if (moment(prevTime).isAfter(meta.delivery_end_at)) {
    thumbnailUrl = isLive
      ? meta.values.livectl_image_after_stream_url
      : '/images/exdio/nextImage.jpg'
    nextPrevText = isLive ? null : '次回の配信もお楽しみに！'
    inViewTerm = false
  }
  if (!thumbnailUrl) thumbnailUrl = config.default_thumbnail

  const material_id = _.get(product, ['ref_id']) || ''
  const license_id = _.get(course, ['ref_id']) || ''
  const withValidPreviewToken = _.get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  const isPlayerRenderable =
    metaIdForPlayer &&
    status.displayMode &&
    (inViewTerm || withValidPreviewToken)

  //ジャンルページ パラメーター
  //配信期間表示
  const delivery_date = meta.publish_start_at
  const separatorString = /\s+/
  const date = delivery_date.split(separatorString, 1)
  //あらすじ表示
  const desc = meta.values.evis_EpisodeLongSynopsis
  //表示タイトル
  var targetStr = '港時間'
  var regExp = new RegExp(targetStr, 'g')
  var afterName = metaName.replace(regExp, '')

  //copyrights
  const copyrights = meta.values.evis_Copyright

  /** pcページ表示 */
  const renderPcPage = () => {
    const disp_order = _.get(meta, ['values', 'disp_order'])
    const top = { seriesId, seasonId }
    const breadcrumbsItems = [top, afterName]

    return (
      <StyledDiv1>
        <StyledDiv2 onMouseOver={hoverArrow} onMouseOut={outArrow}>
          <StyledDiv3>
            <div>
              <StyledH1>
                <Link route={routes.program} params={top}>
                  <img
                    src="https://wws.tv-asahi.co.jp/douga_mv/minato/images/logo.png"
                    alt="港時間"
                  />
                </Link>
              </StyledH1>

              <Breadcrumbs items={breadcrumbsItems} />
            </div>

            {isPlayerRenderable && (
              <RenewalPlayer
                ref={playerRef}
                meta_id={metaIdForPlayer}
                product_type={playerSettings.product_type}
                channel={playerSettings.channel}
                ssai_ad_config_id={playerSettings.ssai_ad_config_id}
                ssai_player_id={playerSettings.ssai_player_id}
                stvod_player_id={playerSettings.stvod_player_id}
                live_player_id={playerSettings.live_player_id}
                delivery_config_id={playerSettings.delivery_config_id}
                thumbnail_url={meta.thumbnail_url}
                subtitle={!!meta.values.subtitle}
                enqueteEnabled={status.isFree}
                material_id={material_id}
                license_id={license_id}
                display_mode={status.displayMode}
                onClose={onClosePlayer}
              />
            )}

            <StyledDiv4>
              <StyledDiv5>
                <StyledA1 href="#" onClick={onClickPlay}>
                  {renderPlayButton()}
                  <img src={thumbnailUrl} alt={afterName} />
                </StyledA1>
              </StyledDiv5>

              <StyledDiv6>
                <StyledH2>
                  <StyledLink1 route={routes.program} params={top}>
                    港時間
                  </StyledLink1>
                </StyledH2>

                <StyledImg1
                  src="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_free-ep.jpg"
                  srcSet="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_free-ep@2x.jpg 2x"
                  alt="無料"
                />

                <StyledH3>{afterName}</StyledH3>

                <StyledUl1>
                  <Styledli1 icon="\e8ae">時間：{time}</Styledli1>
                  <Styledli1 icon="\e916">配信期間：{date[0]}〜</Styledli1>
                </StyledUl1>

                <StyledP1>{desc}</StyledP1>
              </StyledDiv6>
            </StyledDiv4>

            <EpisodePager
              meta={meta}
              seriesId={seriesId}
              seasonId={seasonId}
              episodes={episodes}
              course={course}
              hover={hover}
            />
          </StyledDiv3>
        </StyledDiv2>

        <StyledDiv13>
          <AddButtonBlock
            favoriteType={FAVORITE_TYPE.META}
            favoriteId={meta.meta_id}
            title={metaName}
            showLoginButton={
              webApp.utils.isApp(context) && !webApp.utils.isLoggedIn(context)
            }
            showAddMyList={webApp.utils.isApp(context) && !isPlayable(status)}
          />
        </StyledDiv13>

        <SwitchableHarbor
          episodes={episodes}
          rootMetas={episodes}
          disp_order={disp_order}
        />

        <EpisodeFooter className="mt0" copyrights={copyrights} />
      </StyledDiv1>
    )
  }

  /** spページ表示 */
  const renderSpPage = () => {
    const disp_order = _.get(meta, ['values', 'disp_order'])
    const top = { seriesId, seasonId }

    return (
      <StyledDiv1>
        <StyledDiv2>
          <div id="player-wrap">
            <StyledH1>
              <Link route={routes.program} params={top}>
                <img
                  src="https://wws.tv-asahi.co.jp/douga_mv/minato/images/logo.png"
                  alt="港時間"
                />
              </Link>
            </StyledH1>

            <StyledDiv3>
              <StyledDiv10>
                <StyledDiv11 className="post-icon-box">
                  <StyledImg1
                    src="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_free-ep.jpg"
                    srcSet="https://wws.tv-asahi.co.jp/douga_mv/common/images/pc/icon_free-ep@2x.jpg 2x"
                    alt="無料"
                  />
                </StyledDiv11>
              </StyledDiv10>

              <StyledH3 as="h1">{afterName}</StyledH3>

              {isPlayerRenderable && (
                <RenewalPlayer
                  ref={playerRef}
                  meta_id={metaIdForPlayer}
                  product_type={playerSettings.product_type}
                  channel={playerSettings.channel}
                  ssai_ad_config_id={playerSettings.ssai_ad_config_id}
                  ssai_player_id={playerSettings.ssai_player_id}
                  stvod_player_id={playerSettings.stvod_player_id}
                  live_player_id={playerSettings.live_player_id}
                  delivery_config_id={playerSettings.delivery_config_id}
                  thumbnail_url={meta.thumbnail_url}
                  subtitle={!!meta.values.subtitle}
                  enqueteEnabled={status.isFree}
                  material_id={material_id}
                  license_id={license_id}
                  display_mode={status.displayMode}
                  onClose={onClosePlayer}
                />
              )}

              <StyledDiv4>
                <StyledDiv5>
                  <a href="#" onClick={onClickPlay}>
                    {renderPlayButton()}
                    <img src={thumbnailUrl} alt={afterName} />
                  </a>
                </StyledDiv5>
              </StyledDiv4>
            </StyledDiv3>
          </div>

          <SpEpisodePager meta={meta} seriesId={seriesId} seasonId={seasonId} />

          <StyledDiv12>
            <StyledP2>
              時間：{time} / 配信期間：
              <time dateTime={date[0].replace(/\//g, '-')}>{date[0]}</time>～
            </StyledP2>
            <StyledP2>{desc}</StyledP2>
            <StyledP2>(C)テレビ朝日</StyledP2>
          </StyledDiv12>

          <StyledDiv13>
            <AddButtonBlock
              favoriteType={FAVORITE_TYPE.META}
              favoriteId={meta.meta_id}
              title={metaName}
              showLoginButton={
                webApp.utils.isApp(context) && !webApp.utils.isLoggedIn(context)
              }
              showAddMyList={webApp.utils.isApp(context) && !isPlayable(status)}
            />
          </StyledDiv13>

          <SpSwitchableHarbor
            episodes={episodes}
            rootMetas={episodes}
            disp_order={disp_order}
          />
        </StyledDiv2>
        <EpisodeFooter
          className="mt0 sp-harbor-footer"
          copyrights={copyrights}
        />
      </StyledDiv1>
    )
  }

  return isSmp ? renderSpPage() : renderPcPage()
}

export default EpisodeHarbor

EpisodeHarbor.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** 動画の視聴権利関連情報 */
  howToPlay: PropTypes.object,
  /** 動画の価格、配信期間情報など */
  product: PropTypes.object,
  productRight: PropTypes.object,
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  /** パック販売情報 */
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      original_price: PropTypes.number,
      active_pricing: PropTypes.shape({
        price: PropTypes.number,
        unit: PropTypes.string
      })
    })
  ),
  /** episodesのhowToPlay情報 */
  howToPlays: PropTypes.object,
  // 月額見放題
  /** 動画のコース情報 */
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),

  /** 動画の視聴ステータス情報 */
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  /** 動画のシーズン情報 */
  season: PropTypes.object,
  /** 動画再生時のログ送信 */
  sendPlayLog: PropTypes.func,
  /** データの取得が終わっているか */
  loaded: PropTypes.bool
}

EpisodeHarbor.defaultProps = {
  seriesId: '',
  seasonId: '',
  episodeId: '',
  howToPlay: {},
  episodes: [],
  products: [],
  howToPlays: {},
  course: {},
  status: {
    isFree: false,
    isNotFree: false,
    isPurchased: false,
    isNotPurchased: false,
    isInCourse: false,
    isNotInCourse: false,
    isGeoDeliverable: null,
    isDeviceNotAvailable: null,
    limitDate: null,
    isPossible: null,
    isBelonging: null
  },
  sendPlayLog: () => {},
  loaded: false
}

EpisodeHarbor.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}
