import React from 'react'

/* style */
import { StyledDiv, StyledP, StyledUl, StyledLink } from './style'

/** QRCode Component */
const QRCode = ({ ...props }) => {
  return (
    <StyledDiv>
      <StyledUl {...props}>
        <li>
          <StyledP>
            Amazon Fire TVアプリを
            <br />
            ご利用いただくことで、
            <br />
            ID・パスワードをスマートフォンから
            <br />
            入力することが可能となります。
          </StyledP>
        </li>
        <li>
          <StyledLink
            href="https://apps.apple.com/jp/app/amazon-fire-tv/id947984433"
            target="blank"
          >
            <img src="/images/exdio/renewal/login/ios.png" alt="iOS" />
            <span>App Store</span>
          </StyledLink>
        </li>
        <li>
          <StyledLink
            href="https://play.google.com/store/apps/details?id=com.amazon.storm.lightning.client.aosp&hl=ja&gl=US"
            target="blank"
          >
            <img src="/images/exdio/renewal/login/android.png" alt="Android" />
            <span>Google Play</span>
          </StyledLink>
        </li>
      </StyledUl>
    </StyledDiv>
  )
}

export default QRCode
