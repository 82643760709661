import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import { META_SCHEMA_ID } from '../../../../../constants/app'
import webApp from '../../../utils/exdioWebAppUtils'
import RenewalPlayer from '../../player/RenewalPlayer'
import GoBack from '../../../../common/components/appli/renewal/GoBack'
import HtmlSnippet from '../../HtmlSnippet'
import HeaderNewsComponent from '../HeaderNewsComponent'
import Footer from '../../../../common/components/appli/EpisodeAppFooter'
import Notice from '../EpisodeDefault/Notice'
import CommonVideoArea from '../EpisodeDefault/CommonVideoArea'
import Meta from '../EpisodeDefault/Meta'
import Actions from '../EpisodeDefault/Actions'
import ListMetaMain from '../EpisodeDefault/ListMetaMain'
import ListMetaSub from '../EpisodeDefault/ListMetaSub'
import {
  checkAvailableStatus,
  play,
  getThumbnailUrl
} from '../EpisodeDefault/util'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 単話ページ */
export default class EpisodeAppDefault extends Component {
  static propTypes = {
    /** 動画のシリーズID */
    seriesId: PropTypes.string,
    /** 動画のシーズンID */
    seasonId: PropTypes.string,
    /** 動画のエピソードID */
    episodeId: PropTypes.string,

    /** 動画のメタ情報 */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    /** 動画の視聴権利関連情報 */
    howToPlay: PropTypes.object,
    /** 動画の価格、配信期間情報など */
    product: PropTypes.object,

    // 通常単話
    /** 関連動画情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** パック販売情報 */
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number,
        name: PropTypes.string,
        original_price: PropTypes.number,
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    ),
    /** episodesのhowToPlay情報 */
    howToPlays: PropTypes.object,
    // 月額見放題
    /** 動画のコース情報 */
    course: PropTypes.shape({
      course_id: PropTypes.number,
      schema_id: PropTypes.number,
      name: PropTypes.string,
      active_pricing: PropTypes.object,
      values: PropTypes.object
    }),

    /** 動画の視聴ステータス情報 */
    status: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      isDeviceNotAvailable: PropTypes.bool,
      limitDate: PropTypes.string,
      isPossible: PropTypes.bool,
      isBelonging: PropTypes.bool
    }),
    /** 動画のシーズン情報 */
    season: PropTypes.object,
    /** 動画再生時のログ送信 */
    sendPlayLog: PropTypes.func,
    /** データの取得が終わっているか */
    loaded: PropTypes.bool
  }

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    episodeId: '',
    howToPlay: {},
    episodes: [],
    products: [],
    howToPlays: {},
    course: {},
    status: {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    sendPlayLog: () => {},
    loaded: false
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.previewUrlList = this.config.preview_url_list
    this.cookieRubocopPrefix = this.config.cookie_rubocop_prefix
    this.state = {
      progress: webApp.utils.progress(this.config, props.meta),
      pagerOptions: {
        // ページャーの設定
        episodesPerPages: 12,
        range: 2
      }
    }

    this.onClickPlay = this.onClickPlay.bind(this)
    this.onClosePlayer = this.onClosePlayer.bind(this)

    this.setPlayerRef = (e) => {
      this.playerRef = e
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
    document.body.style.overflow = null
  }

  onClickPlay(e) {
    if (e) e.preventDefault()

    switch (checkAvailableStatus(this.props, this.context)) {
      case 'PLAY':
        play(this.playerRef, this.props.sendPlayLog)
        break
      case 'PURCHASE_EPISODE': // 有料なので何もする必要がない
      case 'PURCHASE_PLAN': // 有料なので何もする必要がない
      default:
        break
    }
  }

  /**
   * プレイヤー閉じ
   */
  onClosePlayer() {
    if (this._isMounted) {
      this.setState({
        progress: webApp.utils.progress(this.config, this.props.meta)
      })
    }
  }

  render() {
    const { meta, programMeta, status, product, course, loaded } = this.props
    const { pagerOptions } = this.state

    const [mainTitle, subTitle] = webApp.utils.titles(meta)
    const metaName = `${mainTitle} ${subTitle}`

    const progress = webApp.utils.progress(this.config, meta)
    const isLive =
      meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
      meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
    let isClipVod = false

    let metaIdForPlayer
    if (isLive) {
      if (meta.values.clipping_select_media_type === 'vod') {
        metaIdForPlayer = meta.values.clipping_vod_ref_id
        isClipVod = true
      }
      if (!metaIdForPlayer) metaIdForPlayer = meta.values.target_ref_id
    }
    if (!metaIdForPlayer)
      metaIdForPlayer = `${this.config.videocloud.reference_id_prefix || ''}${
        meta.meta_id
      }`
    const playerSettings = webApp.utils.getPlayerSettings(
      this.config,
      meta,
      status.displayMode
    )

    const withValidPreviewToken = _.get(this.context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])
    const prevTime = Cookie.get(this.previewUrlList + this.cookieRubocopPrefix)
    const isBefore = Boolean(
      !withValidPreviewToken &&
        moment(prevTime).isBefore(meta.delivery_start_at)
    )
    const isAfter = Boolean(
      !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
    )
    const thumbnailUrl = getThumbnailUrl(
      this.props,
      this.context,
      this.config,
      this.previewUrlList,
      this.cookieRubocopPrefix
    )
    const inViewTerm = !(isBefore || isAfter)
    const nextPrevText =
      inViewTerm || isLive
        ? null
        : isBefore
        ? 'まもなく配信予定'
        : '次回の配信もお楽しみに！'

    const isPlayerRenderable =
      metaIdForPlayer && status.displayMode && inViewTerm
    const shinchan_live_id = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? 14017
      : 32337

    return (
      <div className="p-episode_app_default common-wrapper">
        <GoBack />
        <HeaderNewsComponent />
        <Notice programMeta={programMeta} />

        {isPlayerRenderable && (
          <RenewalPlayer
            ref={(e) => this.setPlayerRef(e)}
            meta_id={metaIdForPlayer}
            product_type={playerSettings.product_type}
            channel={playerSettings.channel}
            ssai_ad_config_id={playerSettings.ssai_ad_config_id}
            delivery_config_id={playerSettings.delivery_config_id}
            thumbnail_url={meta.thumbnail_url}
            subtitle={!!meta.values.subtitle}
            enqueteEnabled={status.isFree}
            onClose={this.onClosePlayer}
            material_id={_.get(product, ['ref_id']) || ''}
            license_id={_.get(course, ['ref_id']) || ''}
            display_mode={status.displayMode}
            isLive={isLive}
            isClipVod={isClipVod}
          />
        )}

        <CommonVideoArea
          progress={progress}
          onClickPlay={this.onClickPlay}
          isLive={isLive}
          inViewTerm={inViewTerm}
          metaName={metaName}
          thumbnailUrl={thumbnailUrl}
          nextPrevText={nextPrevText}
          permitClickPlayButton
          {...this.props}
        />

        <div className="c-storyMeta">
          <div className="c-storyMeta-inBox">
            <Meta isLive={isLive} metaName={metaName} {...this.props} />
            <Actions
              onClickPlay={this.onClickPlay}
              inViewTerm={inViewTerm}
              metaName={metaName}
              loaded={loaded}
              {...this.props}
            />
          </div>
        </div>

        <div className="c-listMeta">
          {meta && meta.id === shinchan_live_id && (
            <HtmlSnippet snippetId={'shinchan_live'} />
          )}
          <div className="c-listMeta-inBox">
            <ListMetaMain pagerOptions={pagerOptions} {...this.props} />
            <ListMetaSub loaded={loaded} {...this.props} />
          </div>
        </div>
        <Footer
          className="mp-mt0"
          copyrights={_.get(meta, ['values', 'evis_Copyright'])}
        />
      </div>
    )
  }
}
