import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';

export default class HeadNotice extends Component {

  constructor(props, context) {
    super(props, context);

    this.items = [];
    this.state = {
      linkset: this.items
    }
  };

  static contextTypes = {
    models: PropTypes.object,
  };

  fetchData(){
    const api = axios.create({
      baseURL: this.context.models.config.data.service.endpoint,
      timeout: this.context.models.config.data.service.timeout
    });
    api
      .get('/link_sets/2')
      .then(res => {
        this.items = res.data.links;
        _.map(this.items, (item, key) => {
          item['color'] = this.setLinkState(item.title);
        });

        this.setState({
          linkset: this.items
        });
      })
      .catch(res => {
        this.setState({
          linkset: []
        });
        console.warn(res);
      });
  }

  setLinkState(data){
    switch(true){
      case /^赤/.test(data):
        return 'is-red';
      case /^青/.test(data):
        return 'is-blue';
      default:
        return 'is-default';
    }
  }

  componentDidMount(){
    this.fetchData();
  }

  render() {
    let linkset = _.map(this.state.linkset || [], (item, index) => {
      if(!item.description.trim()) return ;
      return (
        <div className="head_notice_item" key={index}>
          <p className="head_notice_text">
            <a
              href={item.url}
              className={`head_notice_link ${item.color}`}
              target={this.context.models.browserInfo.data.isAppli ? '' : '_blank'}>
              {item.color !== 'is-default' ? <b className='triangle-wrap'><i className="triangle"></i></b> : ''}
              {item.description}
            </a>
          </p>
        </div>
      );
    });

    return (
      <div className="head_notice">
        <div className="head_notice_items">{linkset}</div>
      </div>
    );
  }
};