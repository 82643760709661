import styled from 'styled-components'
import { mediaQuery } from '../../../../style'
import { pink } from '../../styles'

export const StyledButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  position: relative;
`

export const StyledImg = styled.img`
  padding-top: 20px;

  ${mediaQuery()} {
    padding-top: 15px;
  }
`

export const StyledSpan1 = styled.span`
  position: absolute;
  bottom: 5px;
  padding-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: ${pink};
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 1px #fff, -2px 2px 1px #fff, 2px -2px 1px #fff,
    -2px -2px 1px #fff, 2px 0px 1px #fff, 0px 2px 1px #fff, -2px 0px 1px #fff,
    0px -2px 1px #fff;

  @media screen and (max-width: 425px) {
    padding-bottom: 8px;
  }

  ${mediaQuery()} {
    font-size: 1.2rem;
  }
`

export const StyledSpan2 = styled.span`
  margin-right: 10px;
  display: inline-block;
  font-size: 3.4rem;
  transform: translateY(0.1em);

  ${mediaQuery()} {
    margin-right: 5px;
    font-size: 2.8rem;
  }
`
