import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ProgramItem from '../../../../common/components/renewal/ProgramItem'
import {
  CARD_TYPE,
  FAVORITE_TYPE,
  CARD_TYPES,
  META_SCHEMA_ID
} from '../../../../../constants/app'
import SelectBox from '../../../../common/components/renewal/SelectBox'
import webApp from '../../../utils/exdioWebAppUtils'
import * as DOMUtils from '../../../../utils/DOMUtils'
import * as browserEvents from '../../../../utils/browserEvents'

/** マイページ:マイリスト */
export default class MyList extends Component {
  static propTypes = {}

  static defaultProps = {}

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)

    this.state = {
      favorites: [],
      filtered: null,
      filterType: CARD_TYPE.ALL.value
    }

    this.onChangeFilterType = this.onChangeFilterType.bind(this)
    this.onTouchmove = this.onTouchmove.bind(this)
    this.onScroll = this.onScroll.bind(this)

    this.setListRef = (e) => {
      this.listRef = e
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getFavorites()

    browserEvents.addEventListener('touchmove', this.onTouchmove)
    browserEvents.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    this._isMounted = false
    browserEvents.removeEventListener('touchmove', this.onTouchmove)
    browserEvents.removeEventListener('scroll', this.onScroll)
  }

  async getFavorites() {
    const { filtered } = this.state
    const from = 0
    const to = (filtered || []).length + 19
    const path = [['favorites', Object.values(FAVORITE_TYPE), from, to]]

    this.model
      .fetch(path)
      .then((result) => {
        const favorites = Object.values(FAVORITE_TYPE)
          .flatMap(
            (modelType) =>
              _.get(result, ['json', 'favorites', modelType, from, to]) || []
          )
          .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))

        if (this._isMounted) {
          this.setState({ favorites, filtered: favorites }, () => this.filter())
        }
      })
      .catch((e) => webApp.utils.handleFalcorError(e, this.context))
  }

  onClickDelete(favorite) {
    const modelType = favorite.model_type
    const modelIdField = favorite[modelType].id ? 'id' : `${modelType}_id`
    const modelId = favorite[modelType][modelIdField]

    const path = ['favorite', 'delete']
    const args = [{ modelType, modelId }]
    return this.model
      .call(path, args)
      .then(() => {
        if (this._isMounted) {
          const { favorites } = this.state
          this.setState(
            {
              favorites: favorites.filter(
                (f) =>
                  f.model_type !== modelType ||
                  modelId !== f[modelType][modelIdField]
              )
            },
            () => this.filter()
          )
        }
      })
      .catch((e) => console.error(e))
  }

  /** 並び替え条件変更時 */
  onChangeFilterType(filterType) {
    if (this._isMounted) {
      this.setState({ filterType }, () => this.filter())
    }
  }

  filter() {
    const { favorites, filterType } = this.state

    let filter = () => true
    if (filterType === CARD_TYPE.EPISODE.value) {
      filter = (e) =>
        e.model_type === FAVORITE_TYPE.META &&
        [
          META_SCHEMA_ID.EPISODE,
          META_SCHEMA_ID.EPISODE_NOT_FREE,
          META_SCHEMA_ID.LIVE,
          META_SCHEMA_ID.LIVE_NOT_FREE
        ].includes(e.meta.meta_schema_id)
    } else if (filterType === CARD_TYPE.PROGRAM.value) {
      filter = (e) =>
        e.model_type === FAVORITE_TYPE.META &&
        [META_SCHEMA_ID.SEASON, META_SCHEMA_ID.LIVE_SEASON].includes(
          e.meta.meta_schema_id
        )
    } else if (filterType === CARD_TYPE.PACK.value) {
      filter = (e) => e.model_type === FAVORITE_TYPE.PRODUCT
    } else if (filterType === CARD_TYPE.PLAN.value) {
      filter = (e) => e.model_type === FAVORITE_TYPE.COURSE
    }
    const filtered = favorites.filter(filter)
    if (this._isMounted) {
      this.setState({ filtered })
    }
  }

  onTouchmove() {
    if (this && this.isWithinDistanceBuffer()) this.getFavorites()
  }

  onScroll() {
    if (this && this.isWithinDistanceBuffer()) this.getFavorites()
  }

  isWithinDistanceBuffer() {
    if (!this.listRef) return false
    return DOMUtils.getDistanceToBottomOfElement(this.listRef) < 0
  }

  render() {
    const { filtered } = this.state
    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <div className="c-card-vertical mylist">
            <div className="c-card-vertical-head">
              <div className="c-card-vertical-head-sort">
                {filtered && (
                  <SelectBox
                    types={CARD_TYPES}
                    onSelect={this.onChangeFilterType}
                  />
                )}
              </div>
            </div>
            {filtered && filtered.length === 0 && (
              <div className="c-card-vertical-cont no-mylist">
                マイリストに登録がありません
              </div>
            )}
            <div className="c-card-vertical-cont" ref={this.setListRef}>
              {filtered &&
                filtered.map((f) => {
                  if (FAVORITE_TYPE.META === f.model_type) {
                    return (
                      <ProgramItem
                        key={`${f.model_type}-${f.meta.meta_id}`}
                        meta={f.meta}
                        showCaption
                        showNew={webApp.utils.showNew(f.meta)}
                        onClickThumbnail={() =>
                          webApp.utils.goToProgramLink(this.context, f.meta)
                        }
                        onClickCaption={() =>
                          webApp.utils.goToProgramLink(
                            this.context,
                            f.meta,
                            null,
                            null,
                            { autoPlay: false }
                          )
                        }
                        onClickDelete={() => this.onClickDelete(f)}
                      />
                    )
                  }
                  if (FAVORITE_TYPE.PRODUCT === f.model_type) {
                    return (
                      <ProgramItem
                        key={`${f.model_type}-${f.product.id}`}
                        product={f.product}
                        showCaption
                        showBottomLabel
                        onClick={() =>
                          webApp.utils.goToProgramLink(
                            this.context,
                            null,
                            f.product
                          )
                        }
                        onClickDelete={() => this.onClickDelete(f)}
                      />
                    )
                  }
                  if (FAVORITE_TYPE.COURSE === f.model_type) {
                    return (
                      <ProgramItem
                        key={`${f.model_type}-${f.course.id}`}
                        course={f.course}
                        showCaption
                        showBottomLabel
                        onClick={() =>
                          webApp.utils.goToProgramLink(
                            this.context,
                            null,
                            null,
                            f.course
                          )
                        }
                        onClickDelete={() => this.onClickDelete(f)}
                      />
                    )
                  }
                  return null
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
