/**
 * 与えられたプロパティから、LinkColのprops.itemsに対応するオブジェクトを生成します。
 * @param {Object} options - 入力オプション
 * @param {number} options.id - アイテムの識別子
 * @param {string} [options.children=''] - アイテムの子要素（contentsが優先されます）
 * @param {Object} [options.content={ id: '', label: '' }] - アイテムのコンテンツ
 * @param {string} options.content.id - コンテンツの識別子（contentsを設定する場合はchildrenは不要です）
 * @param {string} options.content.label - コンテンツのラベル（contentsを設定する場合はchildrenは不要です）
 * @param {Object} [options.route={}] - アイテムのルーティング情報
 * @param {string} [options.href=''] - アイテムのリンクURL
 * @param {string} [options.className=''] - アイテムのクラス名
 * @param {boolean} [options.spOnly=false] - アイテムがスマートフォン専用かどうかを示すフラグ
 * @param {Object} [options.params={}] - アイテムのパラメータ
 * @returns {Object} LinkColのprops.itemsに対応するオブジェクト
 */
const generateItem = ({
  id,
  children = '',
  content = {
    id: '',
    label: ''
  },
  route = {},
  href = '',
  className = '',
  spOnly = false,
  params = {}
} = {}) => ({
  id,
  children,
  content,
  route,
  href,
  className,
  spOnly,
  params
})

export default generateItem
