/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'
import browserInfo from '../../../../../../sketch-platform/utils/browserInfo'

/* components */
import { LOGIN_CONTENTS } from '../../../../../../constants/app'

/* style */
import { StyledH2 } from '../styles'
import { StyledUl, StyledLi, StyledP, StyledLink } from './styles'

/** SNSログインボタン */
const SnsButtons = (
  { currentContent = {}, socialLoginRedirectUrl = '' },
  context
) => {
  const config = get(context, ['models', 'config', 'data'], {})
  const isApp = webApp.utils.isApp(context)
  const device = browserInfo(navigator.userAgent, (data) => data)
  const isIOS = get(device, ['isIOS'], false)
  const socialLogin = get(config, ['static_links', 'social_login'])
  const socialLoginUrl = socialLogin.replace(
    ':host',
    webApp.utils.infraHost(context)
  )
  const snsLoginButtonArray = [
    {
      href: `${socialLoginUrl}/rakuten/login/?r=${socialLoginRedirectUrl}`,
      src: '/images/exdio/renewal/login/btn_rakuten.png',
      alt: '楽天会員のユーザーIDでログイン'
    },
    {
      href: `${socialLoginUrl}/yahoo/login/?r=${socialLoginRedirectUrl}`,
      src: '/images/exdio/renewal/login/btn_yahoo.png',
      alt: 'Yahoo! JAPAN IDでログイン'
    },
    {
      href: `${socialLoginUrl}/google/login/?r=${socialLoginRedirectUrl}`,
      src: '/images/exdio/renewal/login/btn_google.png',
      alt: 'Sign in with Google'
    },
    {
      href: `${socialLoginUrl}/facebook/login/?r=${socialLoginRedirectUrl}`,
      src: '/images/exdio/renewal/login/btn_facebook.png',
      alt: 'Facebookでログイン'
    }
  ]

  /**
   * Androidアプリバージョンの取得
   *
   * @return {string|null} - Androidアプリバージョン文字列
   */
  const getAppVerStr = () => {
    /* UAからAndroidアプリバージョンを取得 */
    const reg = /DioPlayer_Android\/(\d+\.\d+\.\d+)/
    const matchedArr = navigator.userAgent.match(reg)
    const appVerStr = get(matchedArr, [1], null)
    return appVerStr
  }

  /**
   * バージョン文字列をオブジェクトに変換
   *
   * @param {string} - バージョン文字列
   * @return {object} - バージョンオブジェクト
   */
  const versionStr2Obj = (versionStr = '0.0.0') => {
    const splitArr = versionStr.split('.')
    const versionObj = {}
    versionObj.major = get(splitArr, [0], 0)
    versionObj.minor = get(splitArr, [1], 0)
    versionObj.patch = get(splitArr, [2], 0)
    return versionObj
  }

  /**
   * 指定のバージョンより新しいか判別
   *
   * @param {string} - 指定のバージョン文字列
   * @return {boolean} - 指定のバージョンより新しいか (同じ場合はfalse)
   */
  const isNewerVersion = (versionStr = '0.0.0') => {
    /** 指定のバージョン配列 */
    const targetVerObj = versionStr2Obj(versionStr)

    /** 環境のバージョン配列 */
    const currentVerStr = getAppVerStr() || '0.0.0'
    const currentVerObj = versionStr2Obj(currentVerStr)

    const {
      major: targetMajor,
      minor: targetMinor,
      patch: targetPatch
    } = targetVerObj
    const {
      major: currentMajor,
      minor: currentMinor,
      patch: currentPatch
    } = currentVerObj
    if (currentMajor > targetMajor) return true
    if (currentMinor > targetMinor) return true
    if (currentPatch > targetPatch) return true
    return false
  }

  if (isApp && isIOS) {
    return (
      <StyledP>
        ※ご迷惑おかけいたしますが、現時点でSNSログイン（楽天, Yahoo!, Google,
        Facebook）は、iOSアプリ未対応のため、ブラウザよりWEB版をご利用ください。
      </StyledP>
    )
  }

  /**
   * //HACK AndroidApp審査用
   * 現行バージョンで非表示、審査用の新バージョンでは表示
   * 審査通過後は削除しても問題ない
   */
  if (isApp && !isIOS && !isNewerVersion('2.0.11')) {
    return (
      <StyledP>
        ※ご迷惑おかけいたしますが、現時点でSNSログイン（楽天, Yahoo!, Google,
        Facebook）は、アプリ未対応のため、ブラウザよりWEB版をご利用ください。
      </StyledP>
    )
  }

  return (
    <>
      <StyledH2>
        {currentContent === LOGIN_CONTENTS.LOGIN
          ? 'SNSアカウントでログイン'
          : 'SNSアカウントで新規登録'}
      </StyledH2>
      <StyledUl>
        {snsLoginButtonArray.map((item) => (
          <StyledLi key={`sns-${item.alt}`}>
            <StyledLink href={item.href} target={isApp ? '_blank' : null}>
              <img src={item.src} width="370" height="56" alt={item.alt} />
            </StyledLink>
          </StyledLi>
        ))}
      </StyledUl>
    </>
  )
}

export default SnsButtons

SnsButtons.propTypes = {
  currentContent: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string
  }),
  socialLoginRedirectUrl: PropTypes.string
}

SnsButtons.contextTypes = {
  routeHandler: PropTypes.object,
  models: PropTypes.object
}
