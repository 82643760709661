import styled from 'styled-components'

export const StyledDiv = styled.div`
  margin-top: 24px;
  text-align: center;
`

export const StyledP = styled.p`
  color: #f33699;
`
