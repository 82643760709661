/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'
import Link from '../../../../../../sketch-platform/ui/routing/Link'

export const StyledLi = styled.li`
  text-align: center;
  width: 50%;
  flex-shrink: 1;
`

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['current'].includes(prop)
})`
  font-size: 1.6rem;
  font-weight: 600;
  display: block;
  padding: 14px 0;
  display: block;
  border-bottom: ${({ current }) => (current ? '5px solid #cece00' : null)};
  font-size: 1.6rem;
  font-weight: 600;

  :hover {
    color: #939394;
  }

  ${mediaQuery()} {
    border-bottom-width: 3px;
    font-size: 1.4rem;
  }
`
