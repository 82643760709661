import AppContext from './AppContext'

class App {
  constructor(initialState, options) {
    this.initialState = initialState
    this.options = options || {}

    if (this.options.handleRouteChange) {
      this.handleRouteChange = this.options.handleRouteChange
    }

    if (this.options.stringProvider) {
      this.stringProvider = this.options.stringProvider
    }
  }

  createContext(state, options) {
    const _options = Object.assign({}, options, {
      stringProvider: this.stringProvider
    })
    let _state = Object.assign({}, this.initialState, state)
    if (this.options.handleCreateContext) {
      _state = this.options.handleCreateContext(_state)
    }
    return new AppContext(this, _state, _options)
  }
}

export default App
