import PropTypes from 'prop-types';

const exdioContextTypes = Object.assign({}, {
  currentPage: PropTypes.string,
  columnsInRow: PropTypes.number.isRequired,
  isInitialRender: PropTypes.bool,
  isOverlayPage: PropTypes.bool,
  canvasId: PropTypes.string,
  rowNum: PropTypes.number,
  rankNum: PropTypes.number,
  requestId: PropTypes.string,
  listContext: PropTypes.string,
  listType: PropTypes.string,
  isTallRow: PropTypes.bool,
  trackId: PropTypes.number,
  jawBoneTrackId: PropTypes.number,
  jawBoneEpisodeTrackId: PropTypes.number,
  jawBoneTrailerTrackId: PropTypes.number,
  parentContext: PropTypes.object,
  isRtl: PropTypes.bool
});

export default exdioContextTypes;
