import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { get, size, includes } from 'lodash'
import MDSpinner from 'react-md-spinner'
import {
  FAVORITE_TYPE,
  LOCAL_STORAGE_KEY_DORAEMON_EXFILTER
} from '../../../../constants/app'
import routes from '../../../common/routes'
import webApp from '../../utils/exdioWebAppUtils'
import PlanDoraemon from './PlanDoraemon'
import { PANEL_ID } from './PlanDoraemon/config'
import { getResumeInfo } from '../../../common/global_functions'
import browserInfo from '../../../../sketch-platform/utils/browserInfo'

/** 月額見放題ページ */
const PlanDoraemonContent = (
  {
    slug = '',
    course = {
      course_id: 0,
      schema_id: 0,
      name: '',
      active_pricing: {},
      values: {}
    },
    ...props
  },
  context
) => {
  if (!size(context)) return null

  const model = context.falcorModel.batch(100)
  const config = context.models.config.data
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isApp = webApp.utils.isApp(context)
  const device = browserInfo(navigator.userAgent, (data) => data)
  const dougaMvHost = webApp.utils.dougaMvHost(context)

  const [status, setStatus] = useState({
    isPurchseAvailable: course.active_pricing != null,
    isPurchased: false,
    isNotPurchased: false
  })
  const [programMetas, setProgramMetas] = useState([])
  const [favorites, setFavorites] = useState([])
  const [popupArticles, setPopupArticles] = useState([])
  const [initialTabId, setInitialTabId] = useState('')
  const [initialTag, setInitialTag] = useState([])
  const [initialNotTag, setInitialNotTag] = useState([])
  const [initialSecretTools, setInitialSecretTools] = useState('')
  const [characterItems, setCharacterItems] = useState()
  const [secretToolsItems, setSecretToolsItems] = useState()
  const [progressEpisodes, setProgressEpisodes] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [isAdded, setIsAdded] = useState(false)
  const [isShow, setIsShow] = useState(false) // 子コンポーネントで使用するため分割代入し
  const timeoutId = useRef(null)
  // パレット
  const [newArrivals, setNewArrivals] = useState({})
  const [monthlyThemes, setMonthlyThemes] = useState([])

  /** グループのライセンス有無取得 */
  const getBelonging = () => {
    if (!course) return Promise.resolve()

    const refId = course.ref_id
    if (!refId) return Promise.resolve()

    if (!isLoggedIn) {
      return Promise.resolve({
        ...status,
        isNotPurchased: true
      })
    }

    const path = ['infra', 'gBelong', refId]
    return model.fetch([path]).then((result) => {
      const belonging = get(result, ['json', ...path]) || false
      return {
        ...status,
        isPurchased: belonging,
        isNotPurchased: !belonging
      }
    })
  }

  /** シーズンのメタを取得 */
  const getProgramMetas = (metaId) => {
    const path = ['metas', metaId]
    return model
      .fetch([path])
      .then((result) => get(result, ['json', ...path], []))
  }

  /**
   * マイリストを取得
   * ドラえもん(AVOD, SVOD)のみ返却する
   */
  const getFavorites = async () => {
    if (!isLoggedIn) return Promise.resolve([])
    const path = ['favorites', FAVORITE_TYPE.META, 0, 1000]

    return model
      .fetch([path])
      .then((result) => {
        return (get(result, ['json', ...path]) || []).filter((favorite) => {
          const favSeasonId = get(favorite, [
            'meta',
            'values',
            'parents_season',
            'id'
          ])
          const doraemonSeasons = Object.values(config.svod.doraemon).map(
            ({ season_id }) => season_id
          )
          return doraemonSeasons.indexOf(favSeasonId) > -1
        })
      })
      .catch((e) => webApp.utils.handleFalcorError(e, context))
  }

  /** お知らせ取得 */
  const getArticles = () => {
    const popupPath = ['articles', 'popup']
    return model.fetch([popupPath]).then((result) => {
      let articles = get(result, ['json', ...popupPath]) || []
      if (size(articles) > 0) {
        const isAndroidBrowser = !isApp && device.isAndroid
        articles = articles.filter((article) => {
          const tagArr = get(article, ['admin_tags_array'], [])
          const isDoraemonArticle = tagArr.indexOf('ドラえもん') > -1
          const isForAndroidArticle = tagArr.indexOf('ブラウザ-Android') > -1

          /** AndroidBrowserのときだけ表示する記事がある (DGA2-1616) */
          if (isAndroidBrowser) {
            return isDoraemonArticle
          }
          return isDoraemonArticle && !isForAndroidArticle
        })
      }
      setPopupArticles(articles)
    })
  }

  /** マイリスト取得 */
  const reloadFavorites = async () => {
    const resFavorites = await getFavorites()
    setFavorites(resFavorites)
  }

  /** マイリスト脱着 */
  const toggleFavorites = async (meta, isAdd = true) => {
    const path = ['favorite', isAdd ? 'add' : 'delete']
    const args = [{ modelType: FAVORITE_TYPE.META, modelId: meta.meta_id }]
    if (isLoggedIn) {
      await model.call(path, args)
      reloadFavorites()
      setIsAdded(isAdd)
      setIsShow(true)
      if (timeoutId.current) clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(() => {
        setIsShow(false)
      }, 3000)
    } else {
      context.history.push(
        routes.login.makePath(null, {
          redirect: context.routeHandler.path
        })
      )
    }
  }

  /** 途中まで見たエピソード メタID取得 */
  const getResumeMetaIds = () => {
    const resumeInfos = getResumeInfo()
    const childEpisodeIds = programMetas
      .flatMap((program) => get(program, ['values', 'child_episode_ids'], []))
      .map((v) => Number(v))
    if (size(resumeInfos) > 0) {
      return resumeInfos
        .filter((resumeInfo) => {
          if (!includes(childEpisodeIds, Number(resumeInfo.metaid))) {
            return false
          }

          // webApp.utils.progressと同じ
          const progress =
            resumeInfo.lastPlayed === -1
              ? 100
              : Math.ceil((resumeInfo.lastPlayed / resumeInfo.duration) * 100)
          return progress > 0 && progress < 100
        })
        .map(({ metaid }) => metaid)
    }
    return []
  }

  /** 途中まで見たエピソード メタ取得 */
  const getProgressEpisodes = async () => {
    const resumeMetaIds = getResumeMetaIds()
    if (!size(resumeMetaIds)) return []

    const path = ['metas', resumeMetaIds]
    return model.fetch([path]).then((result) => {
      const metas = get(result, ['json', 'metas']) || {}
      delete metas.$__path
      return Object.values(metas)
    })
  }
  useEffect(() => {
    ;(async () => {
      const resProgressEpisodes = await getProgressEpisodes().then((episodes) =>
        episodes.filter((v) => v)
      )
      setProgressEpisodes(resProgressEpisodes)
    })()
  }, [programMetas])

  /** douga_mv配下のjsonを取得 */
  const getDougaMvJson = async (fileName = '') => {
    if (!fileName) return Promise.resolve([])
    const timeStamp = webApp.utils.getTimeStamp()
    const jsonPath = `//${dougaMvHost}/douga_mv/doraemon/svod/data/${fileName}.json?t=${timeStamp}`
    return axios
      .get(jsonPath)
      .then(({ data }) => data)
      .catch((e) => webApp.utils.handleFalcorError(e, context))
  }

  /** 新着動画パレットの取得 */
  const getNewArrivalsPalette = () => {
    const path = ['paletteByKey', 'doraemontv_new']
    return model.fetch([path]).then((result) => {
      setNewArrivals(get(result, ['json', ...path]) || {})
    }, [])
  }

  /** ドラえもんPLANページ USERGRAMタグ追加 */
  const addUsergramTagDoraemon = () => {
    if (isLoggedIn === false) return

    let member_id = ''
    const cookies = document.cookie
    const cookiesArr = cookies.split(';')

    cookiesArr.forEach((cookie) => {
      const cookieArr = cookie.split('=')
      if (cookieArr[0] === ' CBM_ID') {
        member_id = cookieArr[1]
      }
    })

    const browseUserGram = `
<script id="usergram-member-doraemonTV-plan${isApp ? '-app' : ''}">
window.ugattr = window.ugattr || {};
ugattr['serviceId'] = '${member_id}';
ugattr['prop07'] = '${isApp ? '【アプリ】' : ''}ドラえもん';
ugattr['prop09'] = '';
ugattr['prop10'] = '';
</script>

<script id="usergram-common-doraemonTV-plan${isApp ? '-app' : ''}">
(function(){var a=window,b=document,c=a.usergram=a.usergram||[],d,e;
c.l||(c.s=(new Date()).getTime(),c.l=!0,d=b.getElementsByTagName('script')[0],
e=b.createElement('script'),e.type='text/javascript',e.async=true,
e.src='//code.usergram.info/js/usergram.js',d.parentNode.insertBefore(e,d))})();
window.usergram=window.usergram||[],window.ugattr=window.ugattr||{};
usergram.push(['send','Ug37cn-1','cv','doraemonTV_plan${
      isApp ? '_app' : ''
    }',ugattr]);
</script>
`
    webApp.utils.appendUserGram(browseUserGram)
  }

  useEffect(() => {
    ;(async () => {
      // ライセンス取得
      const resStatus = (await getBelonging()) || status
      // getSyncだとfalcorのキャッシュが入ってないとundefinedになるっぽいので、ここで再設定
      status.isPurchseAvailable = course && course.active_pricing != null
      setStatus(resStatus)

      setLoaded(true)
    })()
  }, [course])

  useEffect(() => {
    addUsergramTagDoraemon()
    getArticles().catch((e) => console.error(e))
    getNewArrivalsPalette().catch((e) => console.error(e))
    reloadFavorites()

    /** 番組メタの取得 */
    ;(async () => {
      const metaIds = [
        config.svod.doraemon.doraemon.season_id,
        config.svod.doraemon.catchup.season_id
      ]
      const resProgramMetas = await Promise.all(
        metaIds.map((metaId) => getProgramMetas(metaId))
      ).catch((e) => webApp.utils.handleFalcorError(e, context))
      setProgramMetas(resProgramMetas)
    })()

    /** 今月のテーマの取得 */
    ;(async () => {
      const resMonthlyTheme = await getDougaMvJson('monthlytheme')
      setMonthlyThemes(resMonthlyTheme)
    })()

    /** パスパラメータにIDの指定がある場合は初期表示 */
    const queryId = get(context.routeHandler, ['query', 'id'], '')

    /** パスパラメータにタブの指定がある場合は初期表示 */
    const initialTabIdFromQuery = get(
      context.routeHandler,
      ['query', 'tab'],
      ''
    )
    setInitialTabId(initialTabIdFromQuery)

    /** キャラクターでさがす */
    ;(async () => {
      const resCharacterItems = await getDougaMvJson('characters')
      setCharacterItems(resCharacterItems)
      /** キャラクターでさがす初期値 */
      const initialTagFromQuery = (() => {
        if (queryId && initialTabIdFromQuery === PANEL_ID.CHARACTER) {
          const selectedItem = resCharacterItems.find(
            ({ id = '' }) => String(id) === queryId
          )
          return get(selectedItem, ['name'], '')
        }
        return ''
      })()
      if (initialTagFromQuery) {
        setInitialTag([...initialTag, decodeURI(initialTagFromQuery)])
      }
    })()

    /** ひみつ道具でさがす */
    ;(async () => {
      const resSecretToolsItems = await getDougaMvJson('secret_tools')
      setSecretToolsItems(resSecretToolsItems)
      /** ひみつ道具でさがす初期値 */
      const initialSecretToolsFromQuery = (() => {
        if (queryId && initialTabIdFromQuery === PANEL_ID.SECRET_TOOLS) {
          const selectedItem = resSecretToolsItems.find(
            ({ id = '' }) => String(id) === queryId
          )
          return get(selectedItem, ['name'], '')
        }
        return ''
      })()
      setInitialSecretTools(initialSecretToolsFromQuery)
    })()

    const lsExFilteredBy = window.localStorage.getItem(
      LOCAL_STORAGE_KEY_DORAEMON_EXFILTER
    )
    const tags =
      lsExFilteredBy === 'birthday'
        ? [...initialTag, '誕生日スペシャル']
        : initialTag
    setInitialTag(tags)
    const notTags =
      lsExFilteredBy === 'normal' ? ['誕生日スペシャル'] : initialNotTag
    setInitialNotTag(notTags)

    return () => {
      const usergramIds = [
        `usergram-member-doraemonTV-plan${isApp ? '-app' : ''}`,
        `usergram-common-doraemonTV-plan${isApp ? '-app' : ''}`
      ]
      if (isLoggedIn) {
        webApp.utils.removeUserGram(usergramIds)
      }
      webApp.utils.returnDefaultWebClip()
    }
  }, [])

  if (!loaded) {
    return (
      <div className="common-wrapper">
        <div className="mdspinner">
          <MDSpinner size={96} />
        </div>
      </div>
    )
  }

  return (
    <PlanDoraemon
      {...props}
      slug={slug}
      course={course}
      status={status}
      popupArticles={popupArticles}
      programMetas={programMetas}
      progressEpisodes={progressEpisodes}
      favorites={favorites}
      toggleFavorites={toggleFavorites}
      characterItems={characterItems}
      secretToolsItems={secretToolsItems}
      initialTabId={initialTabId}
      initialTag={initialTag}
      initialNotTag={initialNotTag}
      initialSecretTools={initialSecretTools}
      newArrivals={newArrivals}
      monthlyThemes={monthlyThemes}
      isPurchased={status.isPurchased}
      isAdded={isAdded}
      stateIsShow={[isShow, setIsShow]}
    />
  )
}

export default PlanDoraemonContent

PlanDoraemonContent.propTypes = {
  slug: PropTypes.string,
  course: PropTypes.shape({
    course_id: PropTypes.number.isRequired,
    schema_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    active_pricing: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  }).isRequired
}

PlanDoraemonContent.contextTypes = {
  falcorModel: PropTypes.shape({
    batch: PropTypes.func
  }),
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func
}
