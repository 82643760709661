import styled, { keyframes } from 'styled-components'
import { mediaQuery } from '../../../style'
import { aqua, pcMinSize } from '../styles'
import Heading1 from '../Heading1'

const wave = keyframes`
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  51% {
      opacity: 1;
  }
  100% {
      opacity: 1;
  }
`

export const StyledHeading1 = styled(Heading1)`
  height: 285px;
  position: relative;
  &.lazyloaded {
    background: url(/images/exdio/renewal/gariben_daigaku/top/bg5_2.webp)
      no-repeat bottom center / 1158px auto;
    position: relative;
  }

  ${mediaQuery()} {
    height: 90px;
    background-size: 320px, cover;
  }

  img {
    ${mediaQuery()} {
      width: 270px;
    }
  }
`

export const StyledI = styled.i`
  position: absolute;
  animation: ${wave} 2s linear infinite;

  &.lazyloaded:nth-of-type(1) {
    width: 22px;
    height: 24px;
    background: url(/images/exdio/renewal/gariben_daigaku/top/bg5_icon1.webp)
      no-repeat center / 100% auto;
    top: 30px;
    left: 50%;
    transform: translateX(-557px);

    ${mediaQuery()} {
      width: 7px;
      height: 8px;
      top: 7px;
      transform: translateX(-154px);
    }
  }

  &.lazyloaded:nth-of-type(2) {
    width: 27px;
    height: 29px;
    background: url(/images/exdio/renewal/gariben_daigaku/top/bg5_icon2.webp)
      no-repeat center / 100% auto;
    top: 83px;
    left: 50%;
    transform: translateX(-455px);

    ${mediaQuery()} {
      width: 8px;
      height: 9px;
      top: 24px;
      transform: translateX(-126px);
    }
  }

  &.lazyloaded:nth-of-type(3) {
    width: 22px;
    height: 24px;
    background: url(/images/exdio/renewal/gariben_daigaku/top/bg5_icon3.webp)
      no-repeat center / 100% auto;
    top: 30px;
    right: 50%;
    transform: translateX(566px);

    ${mediaQuery()} {
      width: 7px;
      height: 8px;
      top: 7px;
      transform: translateX(156px);
    }
  }
`

export const StyledDiv1 = styled.div`
  &.lazyloaded {
    background: ${aqua}
      url(/images/exdio/renewal/gariben_daigaku/top/sec6_bg.webp) no-repeat top
      center / cover;
  }
`

export const StyledDiv2 = styled.div`
  margin: 0 auto;
  padding: 80px 0;
  width: ${pcMinSize};

  ${mediaQuery()} {
    padding: 50px 10px 10px;
    width: auto;
  }
`
