import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Cookie from 'js-cookie'
import routes from '../../../routes'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'
import { MYPAGE_CONTENTS } from '../../../../../constants/app'
import LoginButtons from './LoginButtons'
import LogoutButton from './LogoutButton'
import News from './News/'
import LinkCol from '../../renewal/LinkCol'
import GenreLinksSp from '../../renewal/GenreLinksSp'
import generateItem from '../../renewal/LinkCol/functions/generateItem'

/** style */
import {
  fixColors,
  hoverBgColor,
  mediaQuery
} from '../../../../exdio/components/style'

/** ヘッダー > ハンバーガーメニュ > コンテンツ */
const MemberMenu = (
  {
    toggleNavMenu = () => {},
    show = false,
    articles = [],
    newsCount = 0,
    setNewsCount = () => {},
    ...props
  },
  context
) => {
  const config = context.models.config.data
  const isApp = webApp.utils.isApp(context)
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const guideLinks = webApp.utils.isApp(context)
    ? config.guide_links_app
    : config.guide_links

  const uid = Cookie.get('luid') || ''

  /** 再レンダリング対策のためtoggleNavMenuをuseCallback化 */
  const onClickLink = useCallback(() => {
    toggleNavMenu('memberMenu')
  }, [])

  const NewArrivalFreeLinksItems = [
    {
      id: 0,
      content: {
        label: '新着動画'
      },
      route: routes[`${isApp ? 'app_newarrival' : 'newarrival'}`]
    },
    {
      id: 1,
      children: (
        <img
          src="/images/exdio/renewal/logo_tv_asahi_free.png"
          alt="テレ朝見逃し無料"
        />
      ),
      route: routes[`${isApp ? 'app_catchup' : 'catchup'}`]
    },
    ...(isApp
      ? []
      : [
          {
            id: 2,
            children: (
              <img
                src="/images/exdio/renewal/logo_extheateronline_r.png"
                alt="EX THEATER ONLINE"
              />
            ),
            href: 'https://www.tv-asahi.co.jp/douga_mv/ex-theater-online/',
            target: '_blank'
          }
        ])
  ]

  const myPageLinkItems = [
    {
      /** アカウント情報 */
      id: 0,
      content: MYPAGE_CONTENTS.LINK.ACCOUNT,
      route:
        routes[`${isApp ? 'app_mypage_account_info' : 'mypage_account_info'}`]
    },
    {
      /** 購入済み */
      id: 1,
      content: MYPAGE_CONTENTS[isApp ? 'APPTAB' : 'TAB'].PURCHASED,
      route: routes[`${isApp ? 'app_mypage' : 'mypage'}`]
    },
    {
      /** マイリスト */
      id: 2,
      content: MYPAGE_CONTENTS[isApp ? 'APPTAB' : 'TAB'].MYLIST,
      route: routes[`${isApp ? 'app_mypage' : 'mypage'}`]
    },
    {
      /** 通帳 */
      id: 3,
      content: MYPAGE_CONTENTS.LINK.COIN,
      route: routes[`${isApp ? 'app_mypage_wallet' : 'mypage_wallet'}`]
    }
  ]

  const guideItems = guideLinks.map((item, index) =>
    generateItem({
      id: index,
      children: item.title,
      route: routes[item.route],
      href: item.url,
      className: item.className,
      spOnly: item.spOnly
    })
  )

  return (
    <StyledDiv1 isApp={isApp} {...props}>
      <StyledDiv2 isApp={isApp}>
        {!isLoggedIn && <LoginButtons onClick={onClickLink} />}

        {/* News */}
        <StyledNews
          onClick={onClickLink}
          articles={articles}
          show={show}
          newsCount={newsCount}
          setNewsCount={setNewsCount}
          isLoggedIn={isLoggedIn}
        />

        {/* NewArrivalFreeLinks */}
        <StyledLinkColNewArrival
          pcCol={2}
          spCol={2}
          items={NewArrivalFreeLinksItems}
          onClick={onClickLink}
        />

        {!isApp && <StyledGenreLinksSp onClick={onClickLink} />}

        {/* MyPage */}
        <StyledLinkColMyPage
          pcCol={3}
          spCol={2}
          items={myPageLinkItems}
          header={{
            title: 'マイページ',
            myPage: true
          }}
          onClick={onClickLink}
          isLoggedIn={isLoggedIn}
          arrow={isApp}
        />

        {/* Guide */}
        <StyledLinkColGuide
          spCol={1}
          pcCol={2}
          items={guideItems}
          onClick={onClickLink}
          isLoggedIn={isLoggedIn}
          arrow
        />

        {isApp && (
          <StyledDiv3 className="c-gMenu-logout">
            <div className="c-gMenu-logout-btn">
              <a
                href={`${config.dioapp.custom_schema}?type=version&uid=${uid}&cid=`}
                className="c-gMenu-logout-btn-link"
                onClick={() => {}}
              >
                アプリバージョン
              </a>
            </div>
          </StyledDiv3>
        )}

        <StyledLogoutButton
          onClick={onClickLink}
          isLoggedIn={isLoggedIn}
          isApp={isApp}
        />
      </StyledDiv2>
    </StyledDiv1>
  )
}

export default MemberMenu

MemberMenu.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  routeHandler: PropTypes.object
}

MemberMenu.propTypes = {
  /** GlobalNavigation.js > showSubMenu/showSearch/showMemberMenu 開閉処理 */
  toggleNavMenu: PropTypes.func.isRequired,
  /** ハンバーガーメニュ開閉状態 */
  show: PropTypes.bool.isRequired,
  /** お知らせ一覧 */
  articles: PropTypes.array,
  /** お知らせの表示位置 */
  newsCount: PropTypes.number.isRequired,
  /** お知らせの表示位置 setState */
  setNewsCount: PropTypes.func.isRequired
}

const StyledDiv1 = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isApp'].includes(prop)
})`
  font-size: 1.5rem;
  width: 578px;
  position: absolute;
  z-index: 2;
  top: 61px;
  right: 0;
  display: block;
  overscroll-behavior: none;

  ${({ isApp }) =>
    isApp
      ? css`
          padding: 18px 0;
          width: 100%;
          height: calc(100% - 56px);
          background: ${hoverBgColor};
          top: 56px;
          position: fixed;
          overflow-y: scroll;
        `
      : css`
          ${mediaQuery('sm')} {
            top: 56px;
          }

          ${mediaQuery('mm')} {
            width: 100%;
            height: calc(100% - 50px);
            position: fixed;
            overflow-y: auto;
          }

          ${mediaQuery('mm', 'min')} {
            height: calc(100vh - 100px);
            overflow-y: auto;
          }
        `}
`

const StyledDiv2 = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isApp'].includes(prop)
})`
  padding: 2rem 1.4rem;
  display: flex;
  flex-wrap: wrap;
  background: ${hoverBgColor};
  ${mediaQuery('mm', 'min')} {
    justify-content: flex-start;
  }

  ${mediaQuery('mm')} {
    padding: 1rem 0;
  }

  ${({ isApp }) =>
    isApp &&
    css`
      padding: 0;
    `}
`

const StyledNews = styled(News)`
  order: ${({ isLoggedIn }) => (isLoggedIn ? '1' : '2')};
  margin-top: 2rem;
`

const StyledLinkColNewArrival = styled(LinkCol)`
  order: 3;
  color: ${fixColors.colorFont};
  margin-top: 2rem;
  ${mediaQuery('mm')} {
    margin-top: 1rem;
  }
`

const StyledGenreLinksSp = styled(GenreLinksSp)`
  order: 4;
  margin-top: 2rem;
  ${mediaQuery('mm')} {
    margin-top: 1rem;
  }
`

const StyledLinkColMyPage = styled(LinkCol)`
  order: ${({ isLoggedIn }) => (isLoggedIn ? '2' : '5')};
  margin-top: 2rem;
  ${mediaQuery('mm')} {
    margin-top: 1rem;
  }
`

const StyledLinkColGuide = styled(LinkCol)`
  order: ${({ isLoggedIn }) => (isLoggedIn ? '5' : '6')};
  margin-top: 2rem;
  ${mediaQuery('mm')} {
    margin-top: 1rem;
    a {
      &::after {
        content: none;
      }
    }
  }
`

const StyledDiv3 = styled.div`
  order: 6;
  width: 100%;

  & > div {
    border-bottom: 0 !important;
  }
`

const StyledLogoutButton = styled(LogoutButton)`
  order: 7;
  border: 0;

  button {
    ${({ isApp }) =>
      isApp &&
      css`
        border: 0;
        line-height: 1;
      `}
  }
`
