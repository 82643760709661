import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import routes from '../../../../../../common/routes'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'
import webApp from '../../../../../utils/exdioWebAppUtils'
import { META_SCHEMA_ID } from '../../../../../../../constants/app'
import styled from 'styled-components'
import { blue } from '../../styles'

/** そだてれび：海外視聴用:単話ページングコンポーネント */
export default class EpisodePager extends Component {
  static propTypes = {
    // 月額見放題に含まれない単話の場合
    meta: PropTypes.shape({
      values: PropTypes.shape({
        prev_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
        next_meta: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
      })
    }),
    seriesId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        meta_schema_id: PropTypes.number
      })
    ),

    // 月額見放題に含まれる単話の場合
    course: PropTypes.shape({
      slug: PropTypes.string
    })
  }

  static defaultProps = {
    meta: null,
    seriesId: null,
    seasonId: null,
    episodes: [],
    course: null,
    hover: false
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    routeHandler: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.state = {
      prevMeta: null,
      nextMeta: null
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getPrevMeta()
    this.getNextMeta()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** 前のメタ情報取得 */
  getPrevMeta() {
    const { meta } = this.props
    if (!meta || !meta.values || !meta.values.prev_meta)
      return Promise.resolve()
    if (typeof meta.values.prev_meta === 'object') {
      this.setState({ prevMeta: meta.values.prev_meta })
      return Promise.resolve()
    }
    const path = ['metas', meta.values.prev_meta]
    return this.model.fetch([path]).then((result) => {
      this.setState({ prevMeta: _.get(result, ['json', ...path]) || null })
    })
  }

  /** 次のメタ情報取得 */
  getNextMeta() {
    const { meta } = this.props
    if (!meta || !meta.values || !meta.values.next_meta)
      return Promise.resolve()
    if (typeof meta.values.next_meta === 'object') {
      this.setState({ nextMeta: meta.values.next_meta })
      return Promise.resolve()
    }
    const path = ['metas', meta.values.next_meta]
    return this.model.fetch([path]).then((result) => {
      this.setState({ nextMeta: _.get(result, ['json', ...path]) || null })
    })
  }

  judgeEpisodeRoute(meta) {
    if (!meta) return null
    if (webApp.utils.isApp(this.context)) {
      return meta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE
        ? routes.app_catchupEpisode
        : routes.app_episode
    }
    return meta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
      meta.meta_schema_id === META_SCHEMA_ID.LIVE
      ? routes.catchupEpisode
      : routes.episode
  }

  render() {
    const { seriesId, seasonId, hover } = this.props
    if (!seriesId || !seasonId) return null
    const { prevMeta, nextMeta } = this.state
    const prevEpisodeRoute = this.judgeEpisodeRoute(prevMeta)
    const nextEpisodeRoute = this.judgeEpisodeRoute(nextMeta)

    return (
      <React.Fragment>
        {prevMeta && (
          <StyledLink1
            route={prevEpisodeRoute}
            params={{ seriesId, seasonId, episodeId: prevMeta.meta_id }}
            className={`${hover && 'active'}`}
          ></StyledLink1>
        )}
        {nextMeta && (
          <StyledLink2
            route={nextEpisodeRoute}
            params={{ seriesId, seasonId, episodeId: nextMeta.meta_id }}
            className={`${hover && 'active'}`}
          ></StyledLink2>
        )}
      </React.Fragment>
    )
  }
}

const StyledLink = styled(Link)`
  margin: auto;
  position: absolute;
  top: 0px;
  bottom: 0;
  height: 24px;
  color: ${blue} !important;
  font-size: 24px;
  opacity: 0;
  transition: 0.2s;

  &.active {
    opacity: 1;
  }
`

const StyledLink1 = styled(StyledLink)`
  left: -40px;

  &::before {
    font-family: 'Material Icons';
    content: '\\e314';
    font-size: 40px;
  }
`

const StyledLink2 = styled(StyledLink)`
  right: -40px;

  &::before {
    font-family: 'Material Icons';
    content: '\\e315';
    font-size: 40px;
  }
`
