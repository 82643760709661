import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

const isModifiedEvent = (event) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

class Link extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string,
    replace: PropTypes.bool,
    onAfterNavigation: PropTypes.func,
    route: PropTypes.object,
    params: PropTypes.object,
    query: PropTypes.object,
    hash: PropTypes.string,
    /** history.push(), replace()に指定するstate */
    state: PropTypes.object,
    preventScrollPositionReset: PropTypes.bool,
    innerRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  }

  static defaultProps = {
    state: {}
  }

  static contextTypes = {
    history: PropTypes.object,
    routeHandler: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)
    this.handleClick = this.handleClick.bind(this)
  }

  isActive(path) {
    const routeHandler = this.context.routeHandler
    return (
      path ===
      routeHandler.route.makePath(routeHandler.params, routeHandler.query)
    )
  }

  getHref() {
    if (this.props.href) {
      return this.props.href
    } else if (this.props.route) {
      return this.props.route.makePath(
        this.props.params,
        this.props.query,
        this.props.hash
      )
    }
    return null
  }

  handleClick(event) {
    if (this.props.onClick) {
      this.props.onClick(event)
    }

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !this.props.target && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault()

      if (this.props.href && this.props.href.startsWith('http')) {
        window.location = this.props.href
        return
      }

      const { history } = this.context
      const { replace, state } = this.props
      const to = this.getHref()

      if (replace) {
        history.replace(to, state)
      } else {
        history.push(to, state)
      }

      if (this.props.preventScrollPositionReset) {
        window.scrollTo(0, 0)
      }
      if (this.props.onAfterNavigation) {
        this.props.onAfterNavigation()
      }
    }
  }

  render() {
    const { replace, to, innerRef, ...props } = this.props // eslint-disable-line no-unused-vars

    const childProps = _.omit(props, [
      'href',
      'route',
      'params',
      'query',
      'hash',
      'onClick',
      'children',
      'className',
      'onAfterNavigation'
    ])

    const href = this.getHref()
    const className = classnames(props.className, {
      active: !!href && this.isActive(href)
    })
    if (className) {
      childProps.className = className
    }
    if (href) {
      childProps.href = href
    }
    if (innerRef) {
      childProps.ref = innerRef
    }

    return React.createElement(
      'a',
      _.extend({}, childProps, { onClick: this.handleClick }),
      props.children
    )
  }
}

Link.displayName = 'Link'

export default Link
