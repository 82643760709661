/* eslint-disable react/require-default-props */
import React, { memo, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

/** components */
import Options from './Options'

/* style */
import {
  StyledDiv,
  StyledUl,
  StyledLi,
  StyledSelect,
  StyledButton1,
  StyledButton2
} from './styles'

/** 動画再生時間変更コントローラ */
const Controller = ({
  visible = false,
  videoLength = '00:00:00',
  targetVideoSelector = '#DIOplayer video'
}) => {
  /** useState */
  const [stHours, setStHours] = useState('')
  const [stMinutes, setStMinutes] = useState('')
  const [stSeconds, setStSeconds] = useState('')
  const [stMaxTime, setStMaxTime] = useState({
    hours: 23,
    minutes: 59
  })

  /**
   * 動画のcurrentTimeを任意秒数だけ移動
   * @param {number} seekTime 秒数
   */
  const skipCurrentTime = (seekTime = 15) => {
    const videoElm = document.querySelector(targetVideoSelector)
    if (!videoElm) return

    const currentTime = get(videoElm, ['currentTime'], 0)
    videoElm.currentTime = currentTime + seekTime
  }

  /**
   * 対象videoのcurrentTimeを指定の秒数に変更
   * @param {number} currentTime 秒数
   */
  const seekTo = (currentTime = 0) => {
    const videoElm = document.querySelector(targetVideoSelector)
    if (!videoElm) return

    videoElm.currentTime = currentTime
  }

  /** selectで選択された時間帯にcurrentTimeを変更 */
  const seekToInputTime = () => {
    const hours = stHours * 60 * 60 // 秒に変換
    const minutes = stMinutes * 60 // 秒に変換
    const inputTime = hours + minutes + Number(stSeconds)
    seekTo(inputTime)
  }

  const onChangeHours = (e) => {
    const { value } = e.target
    setStHours(value)
  }

  const onChangeMinutes = (e) => {
    const { value } = e.target
    setStMinutes(value)
  }

  const onChangeSeconds = (e) => {
    const { value } = e.target
    setStSeconds(value)
  }

  /** 最大時間 or 最大分数を取得 */
  const getTime = useCallback(() => {
    const lengthArr = videoLength.split(':')
    const hours = Number(get(lengthArr, 0))
    const minutes = Number(get(lengthArr, 1))
    if (hours > 0) {
      return {
        hours,
        minutes: 59
      }
    }
    return {
      hours: 0,
      minutes
    }
  }, [videoLength])

  /* useEffect */
  useEffect(() => {
    setStMaxTime(getTime())
  }, [videoLength])

  if (!visible) return null

  return (
    <StyledDiv>
      <StyledButton1 onClick={() => skipCurrentTime(-15)} />
      <StyledUl>
        <StyledLi>
          <StyledSelect name="hours" value={stHours} onChange={onChangeHours}>
            <option value="" disabled>
              hh
            </option>
            <Options from={0} to={stMaxTime.hours} />
          </StyledSelect>
        </StyledLi>
        <StyledLi hasDot>
          <StyledSelect
            name="minutes"
            value={stMinutes}
            onChange={onChangeMinutes}
          >
            <option value="" disabled>
              mm
            </option>
            <Options from={0} to={stMaxTime.minutes} />
          </StyledSelect>
        </StyledLi>
        <StyledLi hasDot>
          <StyledSelect
            name="seconds"
            value={stSeconds}
            onChange={onChangeSeconds}
          >
            <option value="" disabled>
              ss
            </option>
            <Options from={0} to={60} />
          </StyledSelect>
        </StyledLi>
        <StyledLi>
          <StyledButton2 onClick={seekToInputTime}>移動</StyledButton2>
        </StyledLi>
      </StyledUl>
      <StyledButton1 direction="forward" onClick={() => skipCurrentTime(15)} />
    </StyledDiv>
  )
}

export default memo(Controller)

Controller.propTypes = {
  /** 表示制御 */
  visible: PropTypes.bool,
  /** 動画の時間 */
  videoLength: PropTypes.string,
  /** 対象のvideo要素 */
  targetVideoSelector: PropTypes.string
}
