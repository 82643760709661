import styled from 'styled-components'

/** mediaQuery */
export const mediaPc = '(min-width: 1025px)'
export const mediaSp = '(max-width: 1024px)'

/** colors */
export const yellow = '#ffff11'

export const StyledWrapper = styled.div`
  display: block !important;
`

export const StyledH2 = styled.h2`
  text-align: center;
  font-weight: 600;

  @media ${mediaSp} {
    font-size: 1.4rem;
  }
`

export const StyledDiv1 = styled.div`
  padding-bottom: 48px;

  @media ${mediaSp} {
    padding: 0 15px 48px;
  }
`

export const StyledDiv1Inner = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`
