import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { PALETTE_SCHEMA_ID } from '../../../../../constants/app'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'
import HtmlSnippet from '../../../../exdio/components/HtmlSnippet'
import {
  getCanvas,
  getPalettes,
  getHowToPlays,
  getProductsAndCourses
} from './functions'

/* components */
import ImageSlider from '../ImageSlider'
import Palettes from './Palettes'
import PalettesWithAd from './PalettesWithAd'

/** キャンバスコンポーネント */
const Canvas = ({ canvasKey, canvasConfig }, context) => {
  const model = context.falcorModel.batch(100)
  const config = context.models.config.data
  const isApp = webApp.utils.isApp(context)
  const { ad } = canvasConfig
  const snippetId = config.canvas_snippet[isApp ? 'cp_app' : 'cp']

  const [palettes, setPalettes] = useState([])
  const [howToPlays, setHowToPlays] = useState({})
  const [products, setProducts] = useState({})
  const [courses, setCourses] = useState({})
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    ;(async () => {
      const resCanvas = await getCanvas(model, canvasKey)
      const resPalettes = await getPalettes(model, resCanvas)
      setPalettes(resPalettes)
    })()
  }, [])

  useEffect(() => {
    if (!palettes.length) return
    ;(async () => {
      const resHowToPlays = await getHowToPlays(model, palettes)
      const productsAndCourses = await getProductsAndCourses(model, palettes)
      const { product: resProducts, course: resCourses } = productsAndCourses
      setHowToPlays(resHowToPlays)
      setProducts(resProducts)
      setCourses(resCourses)
      setLoaded(true)
    })()
  }, [palettes])

  // 画像スライド用パレット
  const imageSliderPalette =
    palettes && palettes.find((p) => p.schema_id === PALETTE_SCHEMA_ID.IMAGES)
  const imageSliderObjects = _.get(imageSliderPalette, 'objects') || []
  const notImageSliderPalette =
    palettes && palettes.filter((p) => p.schema_id !== PALETTE_SCHEMA_ID.IMAGES)

  return (
    <>
      {imageSliderObjects && (
        <ImageSlider key="slide-program" objects={imageSliderObjects} />
      )}
      <div className="bnr-flex">
        <HtmlSnippet snippetId={snippetId} />
      </div>
      {ad === false ? (
        <Palettes
          palettes={notImageSliderPalette}
          howToPlays={howToPlays}
          products={products}
          courses={courses}
          canvasConfig={canvasConfig}
          loaded={loaded}
        />
      ) : (
        <PalettesWithAd
          palettes={notImageSliderPalette}
          howToPlays={howToPlays}
          products={products}
          courses={courses}
          canvasConfig={canvasConfig}
          loaded={loaded}
        />
      )}
    </>
  )
}

export default Canvas

Canvas.propTypes = {
  canvasKey: PropTypes.string.isRequired,
  canvasConfig: PropTypes.shape({
    ad_idx_banner: PropTypes.number,
    ad_idx_side: PropTypes.number,
    large_idx: PropTypes.arrayOf(PropTypes.number),
    twitter: PropTypes.bool,
    ad: PropTypes.bool,
    palette_header: PropTypes.bool
  }).isRequired
}

Canvas.defaultProps = {}

Canvas.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func
}
