/* style */
import styled from 'styled-components'

/* components */
import ConvertSvg from '../../util/ConvertSvg'

export const mediaSp = '(max-width: 1024px)'

export const StyledH2 = styled.h2`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media ${mediaSp} {
    justify-content: center;
  }

  &[data-modifier='main'] {
    color: #00cccc;
  }

  &[data-modifier='red'] {
    color: #ff0000;
  }
`

export const StyledSpan = styled.span`
  display: inline-block;
  font-size: 3.2rem;
  line-height: 1.25;
  font-weight: bold;
  font-family: 'M PLUS Rounded 1c', sans-serif;

  @media ${mediaSp} {
    font-size: 2.4rem;
  }
`

export const StyledConvertSvg = styled(ConvertSvg)`
  margin-right: 8px;
  margin-left: 0;

  [data-modifier='main'] & {
    color: #00cccc;

    svg {
      path,
      rect {
        fill: #00cccc;
      }
    }
  }

  [data-modifier='red'] & {
    color: #ff0000;

    svg {
      path,
      rect {
        fill: #ff0000;
      }
    }
  }
`
