import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import window from 'global';
import Footer from '../../../common/components/Footer';
import NotFound from '../../../generic/components/errors/NotFound';
import DFPBanner from '../../../common/components/DFPBanner';
import {
  ADVERTISING_SCHEMA_ID,
  GENRE_SEARCH_TYPE,
  META_SCHEMA_ID,
  META_SCHEMA_IDS,
  PALETTE_SCHEMA_ID,
  SEARCH_TYPE,
  SEARCH_TYPES,
  SORT_TYPE,
  SORT_TYPES,
  EPISODE_DISPLAY_MODE
} from '../../../../constants/app';
import TabNavigation from '../../../common/components/renewal/TabNavigation';
import SelectBox from '../../../common/components/renewal/SelectBox';
import ProgramItem from '../../../common/components/renewal/ProgramItem';
import webApp from '../../utils/exdioWebAppUtils';
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation';
import * as browserEvents from '../../../utils/browserEvents';
import * as DOMUtils from '../../../utils/DOMUtils';
import HeaderNewsComponent from './HeaderNewsComponent'

/** ジャンル検索一覧ページ */
export default class GenreListContent extends Component {
  static propTypes = {
    genreKey: PropTypes.string,
    idOrGenreSearchType: PropTypes.string
  };

  static defaultProps = {
    genreKey: '',
    idOrGenreSearchType: ''
  };

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  };

  static getAttrPath(_models, _options, props) {
    return ['attributeByType', 'genre', props.genreKey];
  }

  static getPlttPath(_models, _options, props) {
    return ['palette', props.idOrGenreSearchType];
  }

  static getPrefetchPaths(models, options, props) {
    const rtn = [GenreListContent.getAttrPath(models, options, props)];
    if (GenreListContent.isNum(props.idOrGenreSearchType)) {
      rtn.push(GenreListContent.getPlttPath(models, options, props));
    }
    return rtn;
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const attribute = _.get(prefetchResult, ['json', ...GenreListContent.getAttrPath(models, options, props)]);
    const palette = _.get(prefetchResult, ['json', ...GenreListContent.getPlttPath(models, options, props)]);
    const title = GenreListContent.title(attribute, palette, props.idOrGenreSearchType);
    return { title };
  }

  /**
   * タイトルの取得
   * @param {object} attribute
   * @param {object} palette
   * @param {string} idOrGenreSearchType
  */
  static title(attribute, palette, idOrGenreSearchType) {
    /** attributeの値が向こうのとき、''を返す */
    if (!attribute) return '';

    /** idOrGenreSearchTypeがIDだった場合、パレットのnameを返す */
    if (GenreListContent.isNum(idOrGenreSearchType)) return palette.name;

    /**
     * idOrGenreSearchTypeの検索タイプの整合性をチェック
     * 返り値に応じてタイトルを整形して返す
    */
    const genreSearchType = Object.values(GENRE_SEARCH_TYPE).find(type => type.value === idOrGenreSearchType);
    return `${attribute.name}${genreSearchType ? genreSearchType.label : ''}`;
  }

  /**
   * 引数に渡された文字列がIDに相当するかを判別
   * 1-9から始まる数字のみの文字列
   * @param {string} val 判別する文字列
  */
  static isNum(val) {
    return /^([1-9]\d*|0)$/.test(val);
  }

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.config = context.models.config.data;

    const attrPath = GenreListContent.getAttrPath({}, {}, props);
    const attribute = this.model.getSync(attrPath) || null;

    let palette = {};
    if (GenreListContent.isNum(props.idOrGenreSearchType)) {
      const plttPath = GenreListContent.getAttrPath({}, {}, props);
      palette = this.model.getSync(plttPath) || {};
    }

    let searchType = SEARCH_TYPE.ALL.value;
    if (props.idOrGenreSearchType === GENRE_SEARCH_TYPE.FREE.value) {
      searchType = SEARCH_TYPE.FREE.value;
    }

    this.state = {
      attribute,
      palette,
      paletteChildren: {
        free: null,
        not_free: null
      },
      metas: [],
      filteredMetas: [],
      filteredObjects: [],
      howToPlays: {},
      products: {},
      courses: {},
      searchType,
      sortType: SORT_TYPE.NEWER.value,
      isNotFound: false,
      sorted: false,
    };

    this.onChangeSearchType = this.onChangeSearchType.bind(this);
    this.onChangeSortType = this.onChangeSortType.bind(this);
    this.goBack = this.goBack.bind(this);
    this.sort = this.sort.bind(this);
    this.onTouchmove = this.onTouchmove.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.setListRef = e => {
      this.listRef = e;
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const { genreKey, idOrGenreSearchType } = this.props;
    if (genreKey) {
      if ([GENRE_SEARCH_TYPE.NEW_ARRIVAL.value, GENRE_SEARCH_TYPE.FREE.value].includes(idOrGenreSearchType)) {
        this.fetchForGenreSearch(true);
        browserEvents.addEventListener('touchmove', this.onTouchmove);
        browserEvents.addEventListener('scroll', this.onScroll);
        return;
      } else if (GENRE_SEARCH_TYPE.RECOMMEND.value === idOrGenreSearchType) {
        this.fetchForRecommend(true);
        return;
      } else if (GenreListContent.isNum(idOrGenreSearchType)) {
        this.fetchById();
        return;
      }
    }

    this.setState({ isNotFound: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
    browserEvents.removeEventListener('touchmove', this.onTouchmove);
    browserEvents.removeEventListener('scroll', this.onScroll);
  }

  /**
   * ## ジャンル検索
   * 新着・無料のときに実行される
   * @param {boolean} isInitial 初回実行フラグ
   * @param {boolean} resetLimit 初回表示件数にリセットするか
  */
  async fetchForGenreSearch(isInitial = false, resetLimit = false) {
    // ロード中は実行しない
    if (this.isLoading) return Promise.resolve();

    // ロード中のフラグを建てる
    this.isLoading = true;

    // 初回実行の場合はメタ情報を更新する
    const initialProc = isInitial ? this.getAttribute().then(() => this.updateMetaTags()) : Promise.resolve();

    // デバイス情報を取得
    const deviceInfo = this.context.models.browserInfo.data;
    let func = () => {
      return initialProc
        .then(() => this.getMetasByGenre(resetLimit))
        .then(() => this.getHowToPlays())
        .catch(e => webApp.utils.handleFalcorError(e, this.context))
    }

    if(deviceInfo.isIE) {
      // IEだとfinallyがエラーになる
      await func();
      this.isLoading = false;
    } else {
      func()
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  /** オススメ */
  async fetchForRecommend(isInitial = false) {
    if (this.isLoading) return Promise.resolve();
    this.isLoading = true;
    const initialProc = isInitial ? this.getAttribute().then(() => this.updateMetaTags()) : Promise.resolve();

    const deviceInfo = this.context.models.browserInfo.data;
    let func = () => {
      return initialProc
        .then(() => this.getPaletteByKey())
        .then(() => this.getHowToPlays())
        .then(() => this.getProductsAndCourses())
        .then(() => this.sort())
        .catch(e => webApp.utils.handleFalcorError(e, this.context))
    }
    if(deviceInfo.isIE) {
      // IEだとfinallyがエラーになる
      await func();
      this.isLoading = false;
    } else {
      func()
      .finally(() => {
        this.isLoading = false;
      });
    }
  }

  /** その他、ID指定(「ももいろクローバーZ」「PASSPO」等) */
  async fetchById() {
    if (this.isLoading) return Promise.resolve();
    this.isLoading = true;
    const deviceInfo = this.context.models.browserInfo.data;
    let func = () => {
      return this.getAttribute()
        .then(() => this.getPalette())
        .then(() => this.updateMetaTags())
        .then(() => this.getHowToPlays())
        .then(() => this.getProductsAndCourses())
        .then(() => this.filter(this.sort))
        .catch(e => webApp.utils.handleFalcorError(e, this.context))
    }
    if(deviceInfo.isIE) {
      // IEだとfinallyがエラーになる
      await func();
      this.isLoading = false;
    } else {
      func()
      .finally(() => {
        this.isLoading = false;
      });
    }
  }

  updateMetaTags() {
    const { attribute, palette } = this.state;
    const { idOrGenreSearchType } = this.props;
    webApp.utils.setDefaultMetaTags(this.context, GenreListContent.title(attribute, palette, idOrGenreSearchType));

    // GTMの更新
    const title = this.context.models.config.data.default_title;
    const [program] = title === undefined ? [''] : title.split(' | ');
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ];
    webApp.utils.updateDataLayer(gtmTags);
  }

  /** ジャンル属性情報取得 */
  getAttribute() {
    const { genreKey } = this.props;

    const attrPath = [['attributeByType', 'genre', genreKey]];
    return this.model.fetch(attrPath).then(result => {
      const attribute = _.get(result, ['json', 'attributeByType', 'genre', genreKey], {});
      this.setState({ attribute });
    });
  }

  /**
   * 番組メタ情報取得
   * @params {boolean} resetLimit 初回表示件数にリセットするか falseの場合は追加で読み込む
  */
  getMetasByGenre(resetLimit) {
    const { attribute, searchType, sortType, metas } = this.state;
    const genreId = attribute.attribute_id;

    // ジャンルIDがない場合は実行しない
    if (!genreId) return Promise.resolve();

    const length = resetLimit ? 23 : (metas || []).length + 23; // 取得件数 初回は23件
    const range = { from: 0, to: length };
    const basePath = ['metaByGenreSearch', genreId, searchType, sortType];
    const path = basePath.concat([range]);

    // dsearchの実行
    return this.model.fetch([path]).then(result => {
      const tmpSearchResult = _.get(result, ['json', ...basePath]) || {};
      delete tmpSearchResult.$__path;
      const tmpMetas = Object.values(tmpSearchResult);

      if (this._isMounted) {
        this.setState({ metas: tmpMetas, filteredMetas: tmpMetas });
      }
    });
  }

  /** パレット情報取得(キー条件) */
  getPaletteByKey() {
    const { attribute, searchType, paletteChildren } = this.state;
    const { slug } = attribute;
    if (!slug) return Promise.resolve();

    let path = null;
    if (searchType === SEARCH_TYPE.ALL.value) {
      // 「すべて」はキー指定
      path = ['paletteByKey', `${slug}/recommend`];
    } else if ([SEARCH_TYPE.FREE.value, SEARCH_TYPE.NOT_FREE.value].includes(searchType)) {
      // 「無料」/「有料」はID指定
      const paletteId = paletteChildren[searchType];
      if (!paletteId) return Promise.resolve();
      path = ['palette', paletteId];
    } else {
      return Promise.resolve();
    }

    return this.model.fetch([path]).then(result => {
      const palette = _.get(result, ['json', ...path]) || null;
      if (!palette) {
        this.setState({ isNotFound: true });
        return Promise.resolve();
      }
      const metas = (palette.objects || []).filter(obj => obj.type === 'meta').map(obj => obj.meta);
      this.setState({ metas, filteredMetas: metas, palette, filteredObjects: Object.assign([], palette.objects) });
      if (searchType === SEARCH_TYPE.ALL.value) {
        const free = _.get(palette, ['values', 'palette_free']) || null;
        const not_free = _.get(palette, ['values', 'palette_not_free']) || null;
        this.setState({ paletteChildren: { free, not_free } });
      }
    });
  }

  /** パレット情報取得(ID条件) */
  getPalette() {
    const { idOrGenreSearchType } = this.props;
    const { attribute } = this.state;

    const path = ['palette', idOrGenreSearchType];
    return this.model.fetch([path]).then(result => {
      const palette = _.get(result, ['json', ...path]) || null;

      if (!palette || palette.schema_id !== PALETTE_SCHEMA_ID.GENRE_FIXED ||
        !palette.values || palette.values.genre !== attribute.attribute_id
      ) {
        this.setState({ isNotFound: true });
        return;
      }
      const metas = (palette.objects || []).filter(obj => obj.type === 'meta').map(obj => obj.meta);
      this.setState({ metas, filteredMetas: metas, palette, filteredObjects: Object.assign([], palette.objects) });
    });
  }

  /** 価格情報取得 */
  getHowToPlays() {
    const { metas } = this.state;
    if (!metas.length) return Promise.resolve();

    const metaIds = metas.filter(meta => meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE).map(e => e.meta_id);
    const path = [['meta', 'howToPlay', false, metaIds]];
    return this.model.fetch(path).then(result => {
      const howToPlays = _.get(result, ['json', 'meta', 'howToPlay', false]) || {};
      this.setState({ howToPlays });
    });
  }

  /** 商品・コース情取得報 */
  getProductsAndCourses() {
    const { palette } = this.state;

    const advertisings = (palette.objects || [])
      .filter(obj => obj.type === 'advertising')
      .map(obj => obj.advertising);

    const productIds = advertisings
      .filter(ad => ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT || ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT_LIVE)
      .map(h => h.values.product)
      .filter(v => v);
    const courseIds = advertisings
      .filter(ad => ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE || ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE_LIVE)
      .map(h => h.values.course)
      .filter(v => v);

    const path = [['product', productIds], ['course', courseIds]];
    return this.model.fetch(path).then(result => {
      const products = _.get(result, ['json', 'product']) || {};
      const courses = _.get(result, ['json', 'course']) || {};
      this.setState({ products, courses });
    });
  }

  /** 検索条件変更時 */
  onChangeSearchType(searchType) {
    const { idOrGenreSearchType } = this.props;
    this.setState({ searchType, sorted: false }, () => {
      // ID指定以外の場合はパレットを再取得
      if ([GENRE_SEARCH_TYPE.NEW_ARRIVAL.value, GENRE_SEARCH_TYPE.FREE.value].includes(idOrGenreSearchType)) {
        this.fetchForGenreSearch(false, true);
      } else if (GENRE_SEARCH_TYPE.RECOMMEND.value === idOrGenreSearchType) {
        this.fetchForRecommend();
      } else if (GenreListContent.isNum(idOrGenreSearchType)) {
        this.filter(this.sort);
      }
    });
  }

  /**
   * ## 並び替え条件変更時の挙動
   * SelectBoxで並びを変更したときに実行される
   * @param {string} sortType
  */
  onChangeSortType(sortType) {
    const { idOrGenreSearchType } = this.props;
    const { palette } = this.state;

    this.setState({ sortType, metas: [] }, () => {
      if ([GENRE_SEARCH_TYPE.NEW_ARRIVAL.value, GENRE_SEARCH_TYPE.FREE.value].includes(idOrGenreSearchType)) {
        // 新着/無料の場合は再検索
        this.fetchForGenreSearch(false, true);
      } else if (GENRE_SEARCH_TYPE.RECOMMEND.value === idOrGenreSearchType) {
        // おすすめの場合はパレットの内容を挿入
        this.setState({ filteredObjects: Object.assign([], palette.objects) }, () => {
          this.sort();
        });
      } else if (GenreListContent.isNum(idOrGenreSearchType)) {
        // IDが設定されている場合
        this.filter(this.sort);
      }
    });
  }

  /** 戻るボタンの挙動 */
  goBack(e) {
    e.preventDefault();
    this.context.history.goBack();
  }

  /**
   * フィルタ
   * @param {function} cb フィルタリング後に実行するコールバック関数
  */
  filter(cb = () => {}) {
    const { metas, palette, searchType, products, courses } = this.state;

    const metaSchemaIds = [];
    if (searchType === SEARCH_TYPE.FREE.value) {
      metaSchemaIds.push(META_SCHEMA_ID.EPISODE);
    } else if (searchType === SEARCH_TYPE.NOT_FREE.value) {
      metaSchemaIds.push(META_SCHEMA_ID.EPISODE_NOT_FREE);
    } else {
      metaSchemaIds.push(...META_SCHEMA_IDS);
    }

    const filteredMetas = metas.filter(e => metaSchemaIds.includes(e.meta_schema_id));

    const filteredObjects = (palette.objects || []).filter(object => {
      const { meta } = object;

      if (meta &&
        (searchType === SEARCH_TYPE.ALL.value ||
        (searchType === SEARCH_TYPE.FREE.value && meta.meta_schema_id === META_SCHEMA_ID.EPISODE) ||
        (searchType === SEARCH_TYPE.NOT_FREE.value && meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE))) {
        return true;
      }

      const productId = _.get(object, ['advertising', 'values', 'product']);
      const product = productId && products && products[productId];
      if (product &&
        (searchType === SEARCH_TYPE.ALL.value ||
        (searchType === SEARCH_TYPE.FREE.value && product.active_pricing.price === 0) ||
        (searchType === SEARCH_TYPE.NOT_FREE.value && product.active_pricing.price > 0))) {
        return true;
      }

      const courseId = _.get(object, ['advertising', 'values', 'course']) || null;
      const course = courseId && courses && courses[courseId];
      if (course &&
        (searchType === SEARCH_TYPE.ALL.value ||
        (searchType === SEARCH_TYPE.FREE.value && course.active_pricing.price === 0) ||
        (searchType === SEARCH_TYPE.NOT_FREE.value && course.active_pricing.price > 0))) {
        return true;
      }

      const paletteAd = _.get(object, ['advertising']);
      if (paletteAd &&
        paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT &&
        searchType === SEARCH_TYPE.ALL.value) {
        return true;
      }

      return false;
    });

    this.setState({ filteredMetas, filteredObjects }, () => cb());
  }

  /**
   * ## 並べ替え
   * パレットで初期取得したメタを並べ替える
  */
  sort() {
    const { products, courses, sortType, filteredMetas, filteredObjects } = this.state;
    let sortFunc;

    if (sortType === SORT_TYPE.OLDER.value) {
      /** 古い順 */
      sortFunc = (a, b) => {
        const startAtA = getStartAt(a);
        const startAtB = getStartAt(b);
        if (!startAtA) return 1;
        if (!startAtB) return -1;
        return startAtA < startAtB ? -1 : 1;
      };

      if (filteredMetas) filteredMetas.sort(sortFunc);
      if (filteredObjects) filteredObjects.sort(sortFunc);

      this.setState({ filteredMetas, filteredObjects, sorted: true });
    } else if (sortType === SORT_TYPE.ENDING.value) {
      /** もうすぐ終了 */
      sortFunc = (a, b) => {
        /**
         * 配信終了が近い順
         * AVODにのみ設定されている'delivery_end_at'の小さい順にソート
        */
        const deliveryEndAtA = _.get(getObject(a), ['delivery_end_at'])
        const deliveryEndAtB = _.get(getObject(b), ['delivery_end_at'])

        if (deliveryEndAtA && deliveryEndAtB) {
          /** 両方とも'delivery_end_at'が存在する場合 */
          return deliveryEndAtA > deliveryEndAtB ? 1 : -1
        } else {
          /** 片方のみのとき */
          if (deliveryEndAtA) return -1
          if (deliveryEndAtB) return 1
        }

        /**
         * 配信開始が古い順
         * STVODは'delivery_start_at'の小さい順にソート
        */
        const startAtA = _.get(getObject(a), ['delivery_start_at'])
        const startAtB = _.get(getObject(b), ['delivery_start_at'])

        if (startAtA && startAtB) {
          return startAtA < startAtB ? -1 : 1
        } else {
          if (!startAtA) return 1
          if (!startAtB) return -1
        }
      }

      if (filteredMetas) filteredMetas.sort(sortFunc)
      if (filteredObjects) filteredObjects.sort(sortFunc)

      this.setState({ filteredMetas, filteredObjects, sorted: true })
    } else if (sortType === SORT_TYPE.ASC.value) {
      /**
       * 50音順（あ→わ）
       * シリーズ読みが登録されていない場合は除外
      */
      const path = ['values', 'parents_series', 'evis_SeriesTitlePronunciation'] // シリーズタイトル読みまでのパス
      let newMetas = [], newObj = [] // ソート後の配列を格納

      // ソート内容
      sortFunc = (a, b) => {
        // カタカナに直してソートする
        const seriesNameKanaA = hiraToKana(_.get(getObject(a), path))
        const seriesNameKanaB = hiraToKana(_.get(getObject(b), path))
        return (seriesNameKanaA||'').localeCompare(seriesNameKanaB, 'ja')
      }

      // シリーズ読みが登録されていない場合は除外
      if (filteredMetas) {
        newMetas = filteredMetas.filter((meta) => Boolean(_.get(getObject(meta), path))).sort(sortFunc)
      }

      // シリーズ読みが登録されていない場合は除外
      if (filteredObjects) {
        newObj = filteredObjects.filter((meta) => Boolean(_.get(getObject(meta), path))).sort(sortFunc)
      }

      this.setState({
        filteredMetas: newMetas,
        filteredObjects: newObj,
        sorted: true
      })
    } else if (sortType === SORT_TYPE.DESC.value) {
      /**
       * 50音順（わ→あ）
       * シリーズ読みが登録されていない場合は除外
      */
      const path = ['values', 'parents_series', 'evis_SeriesTitlePronunciation'] // シリーズタイトル読みまでのパス
      let newMetas = [], newObj = [] // ソート後の配列を格納

      // ソート内容
      sortFunc = (a, b) => {
        // カタカナに直してソートする
        const seriesNameKanaA = hiraToKana(_.get(getObject(a), path))
        const seriesNameKanaB = hiraToKana(_.get(getObject(b), path))
        return (seriesNameKanaB||'').localeCompare(seriesNameKanaA, 'ja')
      }

      // シリーズ読みが登録されていない場合は除外
      if (filteredMetas) {
        newMetas = filteredMetas.filter((meta) => Boolean(_.get(getObject(meta), path))).sort(sortFunc)
      }

      // シリーズ読みが登録されていない場合は除外
      if (filteredObjects) {
        newObj = filteredObjects.filter((meta) => Boolean(_.get(getObject(meta), path))).sort(sortFunc)
      }

      this.setState({
        filteredMetas: newMetas,
        filteredObjects: newObj,
        sorted: true
      })
    } else {
      /** 新着順 */
      sortFunc = (a, b) => (getStartAt(a) > getStartAt(b) ? -1 : 1);

      if (filteredMetas) filteredMetas.sort(sortFunc);
      if (filteredObjects) filteredObjects.sort(sortFunc);
      this.setState({ filteredMetas, filteredObjects, sorted: true });
    }

    function getObject(object) {
      if (object.meta_id) return object;
      if (object.meta) return object.meta;
      const productId = _.get(object, ['advertising', 'values', 'product']) || null;
      if (productId) return products[productId];
      const courseId = _.get(object, ['advertising', 'values', 'course']) || null;
      if (courseId) return courses[courseId];
      const paletteAd = _.get(object, ['advertising']);
      if (paletteAd && paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT) {
        return paletteAd;
      }
      return {};
    }

    /** 配信開始日or公開開始日を取得 */
    function getStartAt(object) {
      const obj = getObject(object);
      if (obj.product_id) return obj.publish_start_at;
      if (obj.course_id) return obj.contractable_start_at;
      return obj.delivery_start_at || obj.publish_start_at;
    }

    /** 配信終了日or公開終了日を取得 */
    function getEndAt(object) {
      const obj = getObject(object);
      if (obj.product_id) return obj.publish_end_at;
      if (obj.course_id) return obj.contractable_end_at;
      return obj.delivery_end_at || obj.publish_end_at;
    }

    /** ひらがなからカタカナに変換 */
    function hiraToKana(str) {
      if (!str) return ''

      return str.replace(/[\u3041-\u3096]/g, (match) => {
          var chr = match.charCodeAt(0) + 0x60
          return String.fromCharCode(chr)
      })
    }
  }

  onTouchmove() {
    if (this && this.isWithinDistanceBuffer()) {
      const { idOrGenreSearchType } = this.props;
      if ([GENRE_SEARCH_TYPE.NEW_ARRIVAL.value, GENRE_SEARCH_TYPE.FREE.value].includes(idOrGenreSearchType)) {
        this.fetchForGenreSearch();
      }
    }
  }

  onScroll() {
    if (this && this.isWithinDistanceBuffer()) {
      const { idOrGenreSearchType } = this.props;
      if ([GENRE_SEARCH_TYPE.NEW_ARRIVAL.value, GENRE_SEARCH_TYPE.FREE.value].includes(idOrGenreSearchType)) {
        this.fetchForGenreSearch();
      }
    }
  }

  isWithinDistanceBuffer() {
    if (!this.listRef) return false;
    return DOMUtils.getDistanceToBottomOfElement(this.listRef) < 0;
  }

  render() {
    if (this.state.isNotFound) return [<NotFound key="not-found" />, <Footer key="footer" />];

    const {
      attribute,
      palette,
      paletteChildren,
      sortType,
      filteredMetas,
      filteredObjects,
      products,
      courses,
      howToPlays,
      sorted
    } = this.state;

    /** ID or 検索タイプ 親コンポーネントから渡された値を参照 */
    const { idOrGenreSearchType } = this.props;

    /* h3に表示するタイトルの取得 */
    const title = GenreListContent.title(attribute, palette, idOrGenreSearchType);

    /**
     * ID or 検索タイプが[free or newarrival]かどうか
     * ここにどういう目的で絞り込んでいるのかを記入
    */
    const isOnlyMeta = [GENRE_SEARCH_TYPE.FREE.value, GENRE_SEARCH_TYPE.NEW_ARRIVAL.value].includes(idOrGenreSearchType);

    let searchTypes = [];
    if (idOrGenreSearchType === GENRE_SEARCH_TYPE.RECOMMEND.value) {
      if (paletteChildren.free) searchTypes.push(SEARCH_TYPE.FREE);
      if (paletteChildren.not_free) searchTypes.push(SEARCH_TYPE.NOT_FREE);
      if (searchTypes.length) searchTypes.unshift(SEARCH_TYPE.ALL);
    } else if (idOrGenreSearchType !== GENRE_SEARCH_TYPE.FREE.value) {
      searchTypes = SEARCH_TYPES;
    }

    return (
      <div className="common-wrapper">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />
        <div className="c-listByGenre">
          <div className="c-listByGenre-head">
            <a href="#" className="c-listByGenre-head-back" onClick={this.goBack}>
              戻る
            </a>
            <div className="c-listByGenre-head-title">
              <h3 className="c-listByGenre-head-title-head">{title}</h3>
            </div>

            <div className="c-listByGenre-head-navBox">
              <div className="c-listByGenre-head-navBox-nav">
                <TabNavigation
                  types={searchTypes}
                  onSelect={this.onChangeSearchType}
                  className={{ hidden: !searchTypes.length }}
                />
                <SelectBox types={SORT_TYPES} onSelect={this.onChangeSortType} />
              </div>
            </div>
          </div>
          <div className="c-card-panel">
            <div className="c-card-panel-cont" ref={this.setListRef}>
              {isOnlyMeta &&
                (filteredMetas || []).map(meta => (
                  <ProgramItem
                    key={meta.meta_id}
                    meta={meta}
                    howToPlay={howToPlays[meta.meta_id]}
                    showCaption
                    showCoin
                    showDelivery
                    showNew={webApp.utils.showNew(meta)}
                    showDeadLine={sortType === SORT_TYPE.ENDING.value}
                    showDeliveryEndPriorToStart={sortType === SORT_TYPE.ENDING.value}
                    onClickThumbnail={() => webApp.utils.goToProgramLink(this.context, meta)}
                    onClickCaption={() => webApp.utils.goToProgramLink(this.context, meta, null, null, { autoPlay: false })}
                  />
                ))}
              {!isOnlyMeta && sorted &&
                (filteredObjects || []).map(object => {
                  const meta = object.meta || null;
                  const howToPlay = meta && howToPlays[object.id];
                  const productId = _.get(object, ['advertising', 'values', 'product']) || null;
                  const courseId = _.get(object, ['advertising', 'values', 'course']) || null;
                  const product = productId && products && products[productId];
                  const course = courseId && courses && courses[courseId];

                  const paletteAd = _.get(object, ['advertising']);
                  if (paletteAd && paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT) {
                    const thumbnail = _.get(paletteAd, ['creatives', 0, 'attachment', 'file_url']) || this.context.models.config.data.default_thumbnail;
                    return (
                      <ProgramItem
                        key={`${object.type}-${object.id}`}
                        title={paletteAd.name}
                        thumbnail={thumbnail}
                        deliveryEndFormat="YYYY年M月D日 HH:mmまで"
                        onClick={() => {
                          if (paletteAd.url) window.location.href = paletteAd.url;
                        }}
                      />
                    );
                  }

                  return (
                    <ProgramItem
                      key={`${object.type}-${object.id}`}
                      meta={meta}
                      product={product}
                      course={course}
                      howToPlay={howToPlay}
                      showCaption
                      showCoin
                      showDelivery
                      showDeadLine={sortType === SORT_TYPE.ENDING.value}
                      showDeliveryEndPriorToStart={sortType === SORT_TYPE.ENDING.value}
                      showNew={webApp.utils.showNew(meta)}
                      onClickThumbnail={() => webApp.utils.goToProgramLink(this.context, meta, product, course)}
                      onClickCaption={() => webApp.utils.goToProgramLink(this.context, meta, product, course, { autoPlay: false })}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
