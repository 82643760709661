import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'
import { mediaQuery } from '../../../../style'

/** ドラえもん SNSボタンリスト */
const SnsButtons = ({
  href = {
    twitter: '',
    facebook: '',
    line: ''
  },
  ...props
}) => {
  const { twitter, facebook, line } = href
  const linkItems = [
    {
      href: twitter,
      src: '/douga_mv/shinchan/svod/images/footer/icon-x.svg',
      alt: 'X',
      isX: true
    },
    {
      href: facebook,
      src: '/douga_mv/shinchan/svod/images/footer/icon-fb.svg',
      alt: 'facebook',
      isX: false
    },
    {
      href: line,
      src: '/douga_mv/shinchan/svod/images/footer/icon-line.svg',
      alt: 'line',
      isX: false
    }
  ]

  return (
    <StyledDiv {...props}>
      {linkItems.map(
        ({ href: linkHref = '', src = '', alt = '', isX = false }) => {
          if (!href) return null
          return (
            <StyledLink key={alt} href={linkHref} target="_blank" isX={isX}>
              <img src={src} width="36" height="36" alt={alt} loading="lazy" />
            </StyledLink>
          )
        }
      )}
    </StyledDiv>
  )
}

export default memo(SnsButtons)

SnsButtons.propTypes = {
  href: PropTypes.shape({
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    line: PropTypes.string
  })
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;

  gap: 20px;

  ${mediaQuery()} {
    gap: 30px;
  }
`

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['isX'].includes(prop)
})`
  padding: ${({ isX }) => (isX ? '5px' : null)};
  width: 36px;
  height: 36px;
  background-color: ${({ isX }) => (isX ? '#000' : null)};
  border-radius: 3px;
  display: block;

  &:hover {
    transform: translateY(3px);
  }
`
