import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import { MYPAGE_CONTENTS } from '../../../../../../constants/app'
import routes from '../../../../routes'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import webApp from '../../../../../exdio/utils/exdioWebAppUtils'

/** style */
import { fixColors, mediaQuery } from '../../../../../exdio/components/style'

const MyPoint = (
  { myPage = false, isLoggedIn = false, onClick = () => {}, ...props },
  context
) => {
  const isApp = webApp.utils.isApp(context)
  const availablePoint = _.get(
    context.models,
    ['userInfo', 'data', 'availablePoint', 'available_point'],
    0
  )

  if (isApp) return null
  if (!myPage || !isLoggedIn) return null
  return (
    <StyledDiv {...props}>
      <div>
        <StyledLinkBalance
          route={routes.mypage}
          state={{ content: MYPAGE_CONTENTS.LINK.COIN }}
          onClick={onClick}
        >
          残高
          <StyledSpanPoint>{availablePoint}</StyledSpanPoint>
        </StyledLinkBalance>
      </div>
      <StyledDivBtn>
        <StyledLinkBtn
          route={routes.medal_charge}
          query={{ src: context.routeHandler.url }}
          onClick={onClick}
        >
          チャージ
        </StyledLinkBtn>
      </StyledDivBtn>
    </StyledDiv>
  )
}

export default MyPoint

MyPoint.propTypes = {
  /** 見出しがマイページのコンテンツを表示する場合追加 */
  myPage: PropTypes.bool,
  /** 閲覧しているユーザーがログインしているか */
  isLoggedIn: PropTypes.bool,
  onClick: PropTypes.func
}

MyPoint.contextTypes = {
  models: PropTypes.object,
  history: PropTypes.object,
  routeHandler: PropTypes.object
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 0.8rem;
  flex-grow: 1;
  ${mediaQuery('mm')} {
    margin-right: 0.6rem;
  }
`

const StyledLinkBalance = styled(Link)`
  font-size: 1.3rem;
  font-weight: 600;
`

const StyledSpanPoint = styled.span`
  font-size: 2rem;
  margin-left: 0.3rem;

  &::before {
    content: url(/images/exdio/renewal/icon_coin.svg);
    margin-right: 0.5rem;
    vertical-align: baseline;
  }
`

const StyledDivBtn = styled.div`
  margin-left: 1.8rem;
  margin-bottom: -3px;
  font-size: 1.1rem;
  font-weight: 600;
  width: 80px;
`

const StyledLinkBtn = styled(Link)`
  display: block;
  text-align: center;
  background-color: ${fixColors.colorMain};
  padding: 0.7em;
  border-radius: 5px;
`
