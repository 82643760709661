/* style */
import styled, { css } from 'styled-components'

/* components */
import Link from '../../../../../../../../sketch-platform/ui/routing/Link'
import Btn1 from '../../button/Btn1'
import { StyledSpan as StyledBtn1Span } from '../../button/Btn1/style'
import Nav1 from '../../nav/Nav1'

export const mediaPc = '(min-width: 1024px)'
export const mediaSp = '(max-width: 1024px)'

export const StyledHeader = styled.header`
  grid-area: header;
  width: 100%;
  background-color: #00cccc;
`

export const StyledDiv1 = styled.div`
  margin: 0 auto;
  width: 1280px;
  display: flex;
  align-items: center;

  @media ${mediaSp} {
    padding: 8px 16px;
    width: auto;
  }
`

export const StyledLink1 = styled(Link)`
  width: 276px;
  line-height: 1;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  @media ${mediaSp} {
    width: 84px;
  }
`

export const StyledBtn1 = styled(Btn1).withConfig({
  shouldForwardProp: (prop) => !['isLoggedIn'].includes(prop)
})`
  margin-left: 16px;

  @media ${mediaSp} {
    margin-left: auto;
  }

  ${({ isLoggedIn }) =>
    isLoggedIn &&
    css`
      ${StyledBtn1Span} {
        margin-left: 0;
      }
    `}

  svg {
    width: 12px;
    height: 15px;
  }
`

export const StyledNav1 = styled(Nav1)`
  margin-left: auto;

  @media ${mediaSp} {
    display: none;
  }
`

export const StyledButton = styled.button`
  margin-left: 12px;
  padding: 0;
  width: 32px;
  height: 32px;
  background: none;
  border-width: 0;
  position: relative;

  @media ${mediaPc} {
    display: none;
  }
`

export const StyledI = styled.i`
  width: 100%;
  height: 16px;
  border-width: 2px 0;
  border-style: solid;
  border-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  &::after {
    margin: auto 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }
`

export const StyledSpan = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 1rem;
  color: #fff;
  font-family: 'M PLUS Rounded 1c', sans-serif;
`
