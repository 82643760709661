import styled from 'styled-components'
import { mediaQuery } from '../../../../style'

export const StyledUl = styled.ul`
  padding: 0 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  position: relative;
  z-index: 1;

  ${mediaQuery()} {
    padding: 0;
    grid-template-columns: 1fr;
    gap: 12px;
  }
`

export const StyledLi = styled.li`
  position: relative;

  &::after {
    width: 100%;
    height: 100%;
    background-image: radial-gradient(#eaea02 33%, rgba(255, 255, 255, 0) 38%),
      radial-gradient(#eaea02 33%, rgba(255, 255, 255, 0) 38%);
    background-position: 0 0, 5px 5px;
    background-size: 10px 10px;
    border-radius: 12px;
    position: absolute;
    right: -7px;
    bottom: -7px;
    z-index: 0;
    content: '';

    ${mediaQuery()} {
      content: none;
    }
  }
`
