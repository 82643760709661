import styled from 'styled-components'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import { mediaQuery } from '../../../style'

export const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const StyledLi = styled.li``

export const StyledLink = styled(Link)`
  display: block;
  transition: opacity 0.2s;

  &:hover {
    ${mediaQuery('sm', 'min')} {
      opacity: 0.7;
    }
  }

  img {
    width: 100%;
  }
`
