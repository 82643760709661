import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PANEL_ID, TABS_DATA } from '../../../config'

/** components */
import SearchBoxYear from '../SearchBoxYear'
import TabList from '../TabList'
import Character from '../Character'
import SecretTools from '../SecretTools'
import SpNav from '../SpNav'

/** style */
import { mediaSp } from '../../../styles'

/** タブリスト */
const TabPanel = (
  {
    selectedTabId = '',
    setSelectedTabId = () => {},
    selectedYear = null,
    setSelectedYear = () => {},
    selectedTags = [],
    setSelectedTags = () => {},
    characterItems = [],
    secretToolsItems = [],
    selectedSecretTools = '',
    setSelectedSecretTools = () => {},
    disabled = false,
    ...props
  },
  ref
) => {
  /** SPモーダル開閉状況 */
  const [isModalOpen, setIsModalOpen] = useState(false)

  /** パネルがアクティブになるタブ */
  const actionableTabs = [
    PANEL_ID.AGE,
    PANEL_ID.CHARACTER,
    PANEL_ID.SECRET_TOOLS
  ]

  return (
    <StyledWrapper disabled={disabled} ref={ref} {...props}>
      <TabList
        tabsData={TABS_DATA}
        selectedTabId={selectedTabId}
        setSelectedTabId={setSelectedTabId}
        setIsModalOpen={setIsModalOpen}
      />
      <StyledDiv isActive={actionableTabs.indexOf(selectedTabId) > -1}>
        <StyledSearchBoxYear
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          isShow={selectedTabId === PANEL_ID.AGE}
        />
        <StyledCharacter
          sliderItems={characterItems}
          selectedTabId={selectedTabId}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          isShow={selectedTabId === PANEL_ID.CHARACTER}
        />
        <StyledSecretTools
          sliderItems={secretToolsItems}
          selectedTabId={selectedTabId}
          selectedSecretTools={selectedSecretTools}
          setSelectedSecretTools={setSelectedSecretTools}
          isShow={selectedTabId === PANEL_ID.SECRET_TOOLS}
        />
      </StyledDiv>
      <SpNav
        isShow={isModalOpen}
        tabsData={TABS_DATA}
        selectedTabId={selectedTabId}
        setSelectedTabId={setSelectedTabId}
        setSelectedTags={setSelectedTags}
        setIsModalOpen={setIsModalOpen}
      />
    </StyledWrapper>
  )
}

export default forwardRef(TabPanel)

TabPanel.propTypes = {
  selectedTabId: PropTypes.string,
  setSelectedTabId: PropTypes.func,
  selectedYear: PropTypes.number,
  setSelectedYear: PropTypes.func,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  setSelectedTags: PropTypes.func,
  /** 「キャラクターでさがす」データ */
  characterItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      img: PropTypes.string
    })
  ),
  /** 「ひみつ道具でさがす」データ */
  secretToolsItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      src: PropTypes.string
    })
  ),
  selectedSecretTools: PropTypes.string,
  setSelectedSecretTools: PropTypes.func,
  disabled: PropTypes.bool
}

const StyledWrapper = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : null)};
  cursor: ${({ disabled }) => (disabled ? 'default' : null)};
`

const StyledDiv = styled.div`
  /* height: ${({ isActive }) => (isActive ? '270px' : 'auto')}; */
  background-color: #fff;
  margin-top: 20px;

  @media ${mediaSp} {
    height: auto;
  }
`

const StyledSearchBoxYear = styled(SearchBoxYear).withConfig({
  shouldForwardProp: (prop) => !['isShow'].includes(prop)
})`
  padding: 25px 25px;
  display: ${({ isShow }) => (isShow ? null : 'none')};

  @media ${mediaSp} {
    padding: 10px 10px 5px;
  }
`

const StyledCharacter = styled(Character).withConfig({
  shouldForwardProp: (prop) => !['isShow'].includes(prop)
})`
  display: ${({ isShow }) => (isShow ? null : 'none')};

  .slick-slider {
    padding: 25px;

    @media ${mediaSp} {
      padding: 10px;
    }
  }
`

const StyledSecretTools = styled(SecretTools).withConfig({
  shouldForwardProp: (prop) => !['isShow'].includes(prop)
})`
  display: ${({ isShow }) => (isShow ? null : 'none')};

  .slick-slider {
    padding: 25px;

    @media ${mediaSp} {
      padding: 10px;
    }
  }
`
