import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import window from 'global';
import webApp from "../../../exdio/utils/exdioWebAppUtils";

export default class SelectBox extends Component {
  static propTypes = {
    types: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ).isRequired,
    initialValue: PropTypes.string,
    onSelect: PropTypes.func,
    lg: PropTypes.bool,
    sm: PropTypes.bool
  };

  static defaultProps = {
    initialValue: '',
    onSelect: () => {},
    lg: false,
    sm: false
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue,
      show: false
    };

    this.onClick = this.onClick.bind(this);
    this.onClickWindow = this.onClickWindow.bind(this);
    this.toggle = this.toggle.bind(this);

    this.setSelectRef = e => {
      this.selectRef = e;
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('click', this.onClickWindow);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.types !== this.props.types || nextState.value !== this.state.value || nextState.show !== this.state.show
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('click', this.onClickWindow);
  }

  /** public */
  setValue(value) {
    this.setState({ value });
  }

  onClick(e) {
    const value = e.target.dataset.type;
    this.setState({ value });
    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  }

  toggle() {
    const show = !this.state.show;
    // モーダル表示時はバックグラウンドをスクロールさせない
    if (show) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    this.setState({ show });
  }

  onClickWindow(e) {
    // セレクトボックス外をクリックした場合にセレクトボックスを閉じる
    if (this.selectRef && !this.selectRef.contains(e.target)) {
      document.body.classList.remove('modal-open');
      this.setState({ show: false });
    }
  }

  render() {
    const { types, lg, sm } = this.props;
    const { value, show } = this.state;
    const className = classnames('c-pulldown-menu', { open: show });
    const currentType = types.find(type => type.value == value) || types[0] || { label: '' };

    return (
      <div className="c-pulldown" onClick={this.toggle} ref={this.setSelectRef}>
        <div className="c-pulldown-current">{currentType.label}</div>
        <div className={className}>
          <ul className="c-pulldown-menu-inBox">
            {types.map(type => {
              const liClassName = classnames('c-pulldown-menu-inBox-btn', { current: type === currentType, lg, sm });
              return (
                <li key={type.value} className={liClassName} data-type={type.value} onClick={this.onClick}>
                  <img className="select_box_check_icon"></img>{type.label}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
