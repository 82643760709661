/**
 * // TODO
 * - FCに変更する
 * - styled-componentsに変更する
 * - onClickを使用している`a, div`を`button`に変更する
 */
import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import window from 'global'
import { createGlobalStyle } from 'styled-components'
import webApp from '../../../../utils/exdioWebAppUtils'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import routes from '../../../../../common/routes'
import {
  LOGIN_CONTENTS,
  MYPAGE_CONTENTS
} from '../../../../../../constants/app'
import HowToPlayLink from './HowToPlayLink'

/** 詳細画面:マイリストに追加ボタン、マイリスト、シェアボタン */
export default class AddButtonBlock extends Component {
  static propTypes = {
    favoriteType: PropTypes.string,
    favoriteId: PropTypes.number,
    title: PropTypes.string,
    showLoginButton: PropTypes.bool,
    showAddMyList: PropTypes.bool,
    onClickAddMyList: PropTypes.func,
    officialUrl: PropTypes.string
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func,
    updateUserInfoSubscribe: PropTypes.func
  }

  static defaultProps = {
    favoriteType: '',
    favoriteId: null,
    title: '',
    showLoginButton: false,
    showAddMyList: false,
    onClickAddMyList: null,
    officialUrl: null
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)

    const { params, query, hash } = context.routeHandler
    const validQuery = Object.assign({}, query)
    // 自動再生フラグは渡さない
    delete validQuery.auto
    // SNS用シェアURLはアプリでもWEB用URL
    const linkUrl = context.routeHandler.route.makeAbsolutePath({
      params,
      validQuery,
      hash
    })
    this.linkUrl = linkUrl.replace('/app/', '/')

    this.state = {
      isAdded: false,
      showAddMessage: false,
      showShareLinks: false,
      isAddable: false,
      showAlertModal: false
    }

    this.onClickAddMyList = this.onClickAddMyList.bind(this)
    this.onClickAddMyListButton = this.onClickAddMyListButton.bind(this)
    this.onClickAddMessage = this.onClickAddMessage.bind(this)
    this.onClickCloseAlertModal = this.onClickCloseAlertModal.bind(this)
    this.onClickShowShareLinks = this.onClickShowShareLinks.bind(this)
    this.onClickCloseShareLinks = this.onClickCloseShareLinks.bind(this)
    this.onClickTwitter = this.onClickTwitter.bind(this)
    this.onClickFacebook = this.onClickFacebook.bind(this)
    this.onClickLine = this.onClickLine.bind(this)
    this.hideAddMessage = this.hideAddMessage.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.context.updateUserInfoSubscribe(() => {
      if (this._isMounted && !this.state.isAddable) this.getFavorites()
    })
    this.getFavorites()
  }

  componentDidUpdate(prevProps) {
    const { favoriteType, favoriteId } = this.props
    if (
      favoriteType !== prevProps.favoriteType ||
      favoriteId !== prevProps.favoriteId
    ) {
      this.getFavorites()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    document.body.classList.remove('modal-open')
  }

  /** 「マイリストに追加」ボタン押下時処理 */
  onClickAddMyListButton(e) {
    e.preventDefault()
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) {
      this.setState({ showAlertModal: true })
      document.body.classList.add('modal-open')
      return
    }

    if (this.state.isAdded) {
      this.showAddMessage()
    } else {
      this.onClickAddMyList()
    }
  }

  /** マイリスト追加/削除 */
  onClickAddMyList() {
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) {
      if (webApp.utils.isApp(this.context)) {
        this.setState({ showAlertModal: true })
        document.body.classList.add('modal-open')
      } else {
        this.context.history.push(
          routes.login.makePath(
            {},
            { redirect: this.context.routeHandler.path }
          )
        )
      }
      return
    }

    const { isAddable, isAdded } = this.state
    const { favoriteType, favoriteId, onClickAddMyList } = this.props
    if (!isAddable || !favoriteType || !favoriteId) return

    if (this._isMounted) {
      this.setState({ isAddable: false })
    }
    this.addFavorite()
      .then(() => {
        if (this._isMounted) {
          this.setState({ isAdded: !isAdded })
        }
        this.showAddMessage()
        if (onClickAddMyList) onClickAddMyList()
      })
      .catch((e) => webApp.utils.handleFalcorError(e, this.context))
  }

  /** 「マイリストに追加」メッセージ押下時処理 */
  onClickAddMessage(e) {
    e.preventDefault()
    e.stopPropagation()
    if (!webApp.utils.isApp(this.context)) {
      const route = routes.mypage
      this.context.history.push(route.makePath(), {
        content: MYPAGE_CONTENTS.TAB.MYLIST
      })
    } else {
      const routeApp = routes.app_mypage
      this.context.history.push(routeApp.makePath(), {
        content: MYPAGE_CONTENTS.APPTAB.MYLIST
      })
    }
  }

  onClickShowShareLinks() {
    if (this._isMounted) {
      this.setState({ showShareLinks: true })
    }
  }

  onClickCloseAlertModal() {
    this.setState({ showAlertModal: false })
    document.body.classList.remove('modal-open')
  }

  onClickCloseShareLinks(e) {
    e.preventDefault()
    if (this._isMounted) {
      this.setState({ showShareLinks: false })
    }
  }

  onClickTwitter(e) {
    e.preventDefault()
    const titleTemplate = this.context.models.config.data.title_template
    const linkUrl = `https://twitter.com/share?text=${encodeURIComponent(
      sprintf(titleTemplate, this.props.title)
    )}&url=${encodeURI(this.linkUrl)}`
    // アプリの場合は画面内遷移
    if (webApp.utils.isApp(this.context)) {
      window.location.href = linkUrl
    } else {
      window.open(
        linkUrl,
        'tweetwindow',
        'width=650, height=450, personalbar=no, toolbar=no, scrollbars=yes, resizable=yes, left=(window.screen.width-300)/2, top=(window.screen.height-300)/2'
      )
    }
  }

  onClickFacebook(e) {
    e.preventDefault()
    const linkUrl = `https://www.facebook.com/share.php?u=${encodeURI(
      this.linkUrl
    )}`
    // アプリの場合は画面内遷移
    if (webApp.utils.isApp(this.context)) {
      window.location.href = linkUrl
    } else {
      window.open(
        linkUrl,
        'FBwindow',
        'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes'
      )
    }
  }

  onClickLine(e) {
    e.preventDefault()
    const linkUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURI(
      this.linkUrl
    )}`
    // アプリの場合は画面内遷移
    if (webApp.utils.isApp(this.context)) {
      window.location.href = linkUrl
    } else {
      window.open(
        linkUrl,
        'linewindow',
        'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes'
      )
    }
  }

  getFavorites() {
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    const { favoriteType, favoriteId } = this.props
    if (!isLoggedIn || !favoriteType || !favoriteId) return Promise.resolve()

    const path = [['favorites', favoriteType, favoriteId]]
    return (
      this.model
        .fetch(path)
        .then((result) => {
          const favorites =
            _.get(result, ['json', 'favorites', favoriteType, favoriteId]) || []
          const isAdded = favorites.find((favorite) => {
            const id =
              favorite[favoriteType].id ||
              favorite[favoriteType][`${favoriteType}_id`]
            return favorite.model_type === favoriteType && id === favoriteId
          })
          if (this._isMounted) {
            this.setState({ isAddable: true, isAdded: !!isAdded })
          }
        })
        // 認証エラー時はマイリスト追加処理走らせない(isAddable:falseのまま)
        .catch((e) => webApp.utils.handleFalcorError(e, this.context))
    )
  }

  addFavorite() {
    const { isAdded } = this.state
    const path = ['favorite', isAdded ? 'delete' : 'add']
    const { favoriteType, favoriteId } = this.props
    const args = [{ modelType: favoriteType, modelId: favoriteId }]
    return this.model.call(path, args)
  }

  showAddMessage() {
    if (this._isMounted) {
      this.setState({ showAddMessage: true, isAddable: true }, () => {
        clearTimeout(this.messageFadeTimeoutId)
        this.messageFadeTimeoutId = setTimeout(() => {
          this.hideAddMessage()
        }, 3000)
      })
    }
  }

  hideAddMessage(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (this._isMounted) {
      this.setState({ showAddMessage: false })
    }
  }

  render() {
    const { showLoginButton, showAddMyList, officialUrl } = this.props
    const {
      isAdded,
      showAddMessage,
      showShareLinks,
      showAlertModal
    } = this.state
    const addLabelClassName = classnames('c-addLabel', {
      on: showAddMessage,
      added: isAdded
    })
    const alertModalClassName = classnames('c-modal addMylist', {
      on: showAlertModal
    })
    const myListClassName = classnames('c-addBtns-inBox-mylist-link', {
      added: isAdded
    })
    const shareLinksClassName = classnames('c-shareBtns', {
      on: showShareLinks
    })
    const routeLogin = webApp.utils.isApp(this.context)
      ? routes.app_login
      : routes.login

    return [
      <div key="add-my-list-button" className="c-pageBtn">
        {showLoginButton && (
          <Link
            className="c-pageBtn-link mb10"
            route={routes.app_login}
            query={{ redirect: this.context.routeHandler.path } || null}
            style={{ backgroundColor: '#4ad28a' }}
          >
            Login
          </Link>
        )}
        {showAddMyList && (
          <a
            href="#"
            className="c-pageBtn-link"
            onClick={this.onClickAddMyListButton}
          >
            <span className="c-pageBtn-link-addMylist">Add Mylist</span>
          </a>
        )}
        <div className={alertModalClassName}>
          <div className="c-modal-inBox">
            <div
              className="c-modal-inBox-close"
              onClick={this.onClickCloseAlertModal}
              role="button"
            />
            <div className="c-modal-inBox-cont">
              <div className="addMylist-conts">
                <header className="addMylist-conts-head">
                  マイリスト機能を使用するにはログインが必要です。
                </header>
                <div className="addMylist-conts-cont">
                  <div className="c-gMenu-logins">
                    <div className="c-gMenu-logins-in">
                      <div className="c-gMenu-logins-signup">
                        <Link
                          route={routeLogin}
                          state={{ content: LOGIN_CONTENTS.SIGNUP }}
                          className="c-gMenu-logins-signup-link"
                        >
                          SignUp
                        </Link>
                      </div>
                      <div className="c-gMenu-logins-login">
                        <Link
                          route={routeLogin}
                          query={{ redirect: this.context.routeHandler.path }}
                          className="c-gMenu-logins-login-link"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,
      showAddMyList && <HowToPlayLink key="how-to-play-link" />,
      <div key="add-buttons" className="c-addBtns">
        {showShareLinks && (
          <div
            className="c-shareBtns-bg overlayOn"
            onClick={this.onClickCloseShareLinks}
            role="button"
          />
        )}
        <div className={addLabelClassName}>
          <div
            className="c-addLabel-inBox clickable"
            onClick={this.onClickAddMessage}
          >
            <a
              href="#"
              className="c-addLabel-inBox-close"
              onClick={this.hideAddMessage}
            />
          </div>
        </div>

        <ul className="c-addBtns-inBox">
          <li className="c-addBtns-inBox-mylist">
            <div
              className={myListClassName}
              onClick={this.onClickAddMyList}
              role="button"
            >
              Mylist
            </div>
          </li>

          <li
            className={classnames('c-addBtns-inBox-share', {
              'c-addBtns-inBox-middle': officialUrl !== null
            })}
          >
            <div
              className="c-addBtns-inBox-share-link"
              onClick={this.onClickShowShareLinks}
              role="button"
            >
              Share
            </div>
            <ul className={shareLinksClassName}>
              <li className="c-shareBtns-close">
                <a href="#" onClick={this.onClickCloseShareLinks}>
                  <img
                    src="/images/exdio/renewal/icon_share_close.svg"
                    alt="閉じる"
                  />
                </a>
              </li>
              <li className="c-shareBtns-twitter">
                <a href="#" onClick={this.onClickTwitter}>
                  <img
                    src="/images/exdio/renewal/share_btn_twitter.svg"
                    alt="Twitter"
                  />
                </a>
              </li>
              <li className="c-shareBtns-facebook">
                <a href="#" onClick={this.onClickFacebook}>
                  <img
                    src="/images/exdio/renewal/share_btn_facebook.svg"
                    alt="Facebook"
                  />
                </a>
              </li>
              <li className="c-shareBtns-line">
                <a href="#" onClick={this.onClickLine}>
                  <img
                    src="/images/exdio/renewal/share_btn_line.svg"
                    alt="Line"
                  />
                </a>
              </li>
            </ul>
          </li>
          {officialUrl !== null && (
            <li className="c-addBtns-inBox-official">
              <a
                className="c-addBtns-inBox-official-link"
                href={officialUrl}
                target="_blank"
              >
                公式サイト
              </a>
            </li>
          )}
        </ul>
        <GlobalStyle />
      </div>
    ]
  }
}

const GlobalStyle = createGlobalStyle`
  .c-shareBtns-close {
    left: 0;
  }
`
