import { useMemo } from 'react'

export const useFilteredSortedData = (
  seasonMetas,
  searchText,
  conversionToKana,
  sort
) => {
  const conversionSearchText = conversionToKana(searchText)

  // キーワードでフィルタリング
  const filterData = useMemo(() => {
    return seasonMetas.filter((data) => {
      const conversionTitle = conversionToKana(
        data.values.avails_SeasonTitleDisplayUnlimited || data.name
      )
      const conversionTitleKana = conversionToKana(data.name_ruby)
      const conversionActors = conversionToKana(
        String(data.values.evis_SeasonActors)
      )

      return (
        conversionTitle.includes(conversionSearchText) ||
        conversionTitleKana.includes(conversionSearchText) ||
        conversionActors.includes(conversionSearchText)
      )
    })
  }, [seasonMetas, conversionSearchText])

  // 日付でソート
  const sortedData = useMemo(() => {
    return filterData.sort((a, b) =>
      sort === 0 ? a.oldestDate - b.oldestDate : b.oldestDate - a.oldestDate
    )
  }, [filterData, sort])

  return sortedData
}
