export const PANEL_ID = {
  ALL: 'ALL',
  AGE: 'AGE',
  CHARACTER: 'CHARACTER',
  SECRET_TOOLS: 'SECRET_TOOLS'
}

export const TABS_DATA = [
  {
    id: PANEL_ID.ALL,
    title: 'すべてのおはなし'
  },
  {
    id: PANEL_ID.AGE,
    title: '年代別でさがす',
    html: '<ruby>年代別<rt>ねんだいべつ</rt></ruby>でさがす'
  },
  {
    id: PANEL_ID.CHARACTER,
    title: 'キャラクターでさがす'
  },
  {
    id: PANEL_ID.SECRET_TOOLS,
    title: 'ひみつ道具でさがす',
    html: 'ひみつ<ruby>道具<rt>どうぐ</rt></ruby>でさがす'
  }
]
