import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import routes from '../../../../../common/routes'

/** そだてれび：海外視聴用 Header */
const Heading = ({ isSmp = false, params }) => {
  const variety = { genre: 'variety' }

  return (
    <StyledDiv1>
      <StyledH1 isSmp={isSmp}>
        <Link route={routes.program} params={params}>
          {/* TODO: LOGO変更 */}
          <StyledImg
            isSmp={isSmp}
            src="/images/exdio/renewal/global/logo.png"
          />
        </Link>
      </StyledH1>
    </StyledDiv1>
  )
}

export default memo(Heading)

Heading.propTypes = {
  /** リンク先URLの生成に使用 */
  params: PropTypes.shape({
    seriesId: PropTypes.string,
    seasonId: PropTypes.string
  }),
  /** スマートフォンでの表示か */
  isSmp: PropTypes.bool
}

const StyledDiv1 = styled.div`
  overflow: hidden;
`

const StyledH1 = styled.h1`
  padding-top: ${({ isSmp }) => (isSmp ? '15px' : '30px')};
  margin-left: 20px;

  @media (max-width: 1024px) {
    margin-left: 0;
    text-align: center;
  }

  a {
    display: inline-block;
  }
`

const StyledImg = styled.img`
  width: ${({ isSmp }) => (isSmp ? '200px' : '335px')};
`
