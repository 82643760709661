import styled, { css, createGlobalStyle } from 'styled-components'
import Slider from 'react-slick'
import { mediaQuery } from '../../style'

/** mediaQuery */
export const mediaPc = '(min-width: 1025px)'
export const mediaSp = '(max-width: 1024px)'

export const contentWidth = '1080px'

/** colors */
export const white = '#fff'
export const black = '#333'
export const pink = '#e551a3'
export const darkPink = '#b2166c'
export const blue = '#09f'
export const darkBlue = '#007eff'
export const green = '#07b21a'
export const darkGreen = '#06a217'
export const yellow = '#fcee21'
export const darkYellow = '#fcdb29'

export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'M PLUS Rounded 1c';
  src: url('/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Regular.ttf') format('TrueType');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  src: url('/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Medium.ttf') format('TrueType');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  src: url('/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Bold.ttf') format('TrueType');
  font-weight: 700;
  font-display: swap;
}

html, body {
  height: initial;
}


body {
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

.c-pageTop {
  display: none;
}

${({ isApp }) => {
  if (isApp) {
    return css`
      #mp-header {
        display: none !important;
      }

      .c-storyPager {
        margin: 0;
        max-width: none;
      }

      .common-footer {
        .inner {
          padding-bottom: 48px;
        }
      }

      .u-hide--app {
        display: none !important;
      }
    `
  }
  return css`
    .u-hide--browser {
      display: none !important;
    }
  `
}}
`

export const StyledSlider = styled(Slider)`
  & {
    ${mediaQuery()} {
      margin-right: -10px;
    }

    .slick-arrow {
      z-index: 1;
      transform: translateY(-50px);

      &:before {
        content: '';
        display: block;
        width: 45px;
        height: 45px;
        background: url(/images/exdio/renewal/doraemon/arrow.png) no-repeat
          center center / 100%;
        transition: opacity 0.2s;
      }
    }

    .slick-next {
      right: 0;
    }

    .slick-prev:before {
      transform: scale(-1, 1);
    }

    .slick-disabled {
      opacity: 0;
    }

    ${mediaQuery()} {
      .slick-slide {
        margin-right: 10px;
        width: calc((100vw - 20px) * 0.3);
      }
    }

    ${mediaQuery(767)} {
      .slick-slide {
        width: calc((100vw - 20px) * 0.95);
      }
    }
  }
`
