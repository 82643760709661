import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/** components */
import Tab, { StyledLabel } from './Tab'
import Button2 from './Button2'

/** style */
import { contentWidth, mediaPc, mediaSp } from './styles'

/** タブリスト */
const TabList = ({
  tabsData = [],
  selectedTabId = null,
  setSelectedTabId = () => {},
  setIsModalOpen = () => {},
  ...props
}) => {
  const tabWidth = 240
  const [isLeft, setIsLeft] = useState(true)

  /**
   * タブクリック時、見切れているタブの場合はスライドする
   * @param {number} index
   */
  const onClickTab = (index) => {
    const tabLength = tabsData.length
    const tabsPerSlide = 4 // 1ページあたりのタブの数
    const isSp = window.matchMedia(mediaSp).matches

    /** PCのときだけ実行 */
    if (isSp) return

    /** 右側に見切れているタブのとき、右にスライド */
    if (isLeft && index > tabsPerSlide - 1) {
      setIsLeft(false)
    }

    /** 左側に見切れているタブのとき、左にスライド */
    if (!isLeft && index <= tabLength - tabsPerSlide - 1) {
      setIsLeft(true)
    }
  }

  useEffect(() => {
    tabsData.forEach((tab, i) => {
      const { id } = tab
      if (id === selectedTabId) {
        onClickTab(i)
      }
    })
  }, [])

  return (
    <StyledWrapper>
      <StyledButton2
        prevOrNext="prev"
        isShow={!isLeft}
        onClick={() => setIsLeft(true)}
      />
      <StyledInner>
        <StyledUl
          tabWidth={tabWidth}
          length={tabsData.length}
          isLeft={isLeft}
          {...props}
        >
          {tabsData.map((tab, i) => {
            const { id } = tab
            return (
              <StyledLi width={tabWidth} key={id}>
                <Tab
                  {...tab}
                  iconType={id}
                  isChecked={id === selectedTabId}
                  setIsModalOpen={setIsModalOpen}
                  onChangeFunc={() => setSelectedTabId(id)}
                  onClick={() => onClickTab(i)}
                />
              </StyledLi>
            )
          })}
        </StyledUl>
      </StyledInner>
      <StyledButton2
        prevOrNext="next"
        isShow={isLeft}
        onClick={() => setIsLeft(false)}
      />
    </StyledWrapper>
  )
}

export default memo(TabList)

TabList.propTypes = {
  /** それぞれのタブの内容 */
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      /** id */
      id: PropTypes.string,
      /** 見出し画像alt */
      title: PropTypes.string
    })
  ),
  /** 選択中のタブID */
  selectedTabId: PropTypes.string,
  /** タブ変更時の関数 */
  setSelectedTabId: PropTypes.func,
  /** モーダル開閉状況変更 */
  setIsModalOpen: PropTypes.func
}

const StyledWrapper = styled.div`
  max-width: ${contentWidth};
  position: relative;
`

const StyledInner = styled.div`
  padding-top: 20px;
  width: 100%;
  height: 70px;
  position: relative;
  overflow: hidden;
`

const StyledUl = styled.ul`
  width: ${({ tabWidth, length }) => `${tabWidth * length}px`};
  height: 50px;
  display: flex;
  position: absolute;
  left: ${({ tabWidth, length, isLeft }) =>
    isLeft ? 0 : `calc(${contentWidth} - ${tabWidth * length}px)`};
  bottom: 0;
  transition: left 0.4s;

  @media ${mediaSp} {
    width: auto;
    display: block;
    position: static;
    left: auto;
    transition: none;
  }
`

const StyledLi = styled.li`
  width: ${({ width }) => `${width}px`};
  border-left: 1px solid #fff;

  @media ${mediaSp} {
    width: 100%;
    border-left-width: 0;
  }

  ${StyledLabel} {
    @media ${mediaSp} {
      border-radius: 8px 8px 0 0;
    }
  }

  &:first-child {
    border-left-width: 0;

    ${StyledLabel} {
      @media ${mediaPc} {
        border-top-left-radius: 8px;
      }
    }
  }

  &:last-child {
    ${StyledLabel} {
      @media ${mediaPc} {
        border-top-right-radius: 8px;
      }
    }
  }
`

const StyledButton2 = styled(Button2)`
  margin: 0;
  display: ${({ isShow }) => (isShow ? null : 'none')};
  top: auto;
  bottom: 0;

  @media ${mediaSp} {
    display: none;
  }
`
