import CookieDough from 'cookie-dough';
import moment from 'moment';
import { TOKEN_KEY } from '../constants/cookie';

const cookie = CookieDough();
const setTokenData = function(tokenData) {
  if (typeof window !== 'undefined') {
    if (window.localStorage) {
      window.localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenData));
    } else if (window.navigator.cookieEnabled) {
      cookie.set(TOKEN_KEY, JSON.stringify(tokenData), { path: '/' });
    }
  }
};
const getTokenData = function() {
  try {
    if (typeof window !== 'undefined') {
      if (window.localStorage) {
        return JSON.parse(window.localStorage.getItem(TOKEN_KEY));
      } else if (window.navigator.cookieEnabled) {
        return JSON.parse(cookie.get(TOKEN_KEY));
      }
    }
  } catch (e) {
    return null;
  }
};
const setAuthContextData = function(authContext) {
  if (!authContext) return;
  const tokenData = getTokenData() || {};
  if (!tokenData.time || (tokenData && tokenData.time < authContext.time)) {
    tokenData.id = authContext.userId;
    tokenData.token = authContext.token;
    tokenData.time = authContext.time;
    tokenData.refreshTime = moment().format();
    setTokenData(tokenData);
  }
};

export default {
  set: setTokenData,
  get: getTokenData,
  setAuthContextData
};
