import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import PackList from '../details/PackList'
import EpisodeListPack from '../details/EpisodeListPack'
import SideTab from '../details/SideTab'
import ProgramTopLink from '../details/ProgramTopLink'
import EpisodePager from '../details/EpisodePager'
import { FAVORITE_TYPE } from '../../../../../constants/app'
import AddButtonBlock from '../details/AddButtonBlock'
import GoBack from '../../../../common/components/appli/renewal/GoBack'
import HtmlSnippet from '../../HtmlSnippet'
import SideRecommend from '../details/SideRecommend'
import PackViewTerm from '../details/PackViewTerm'
import HeaderNewsComponent from '../HeaderNewsComponent'
import Footer from '../../../../common/components/appli/StaticFooter'
import { createGlobalStyle } from 'styled-components'
import { whatKindOfVideo } from '../EpisodeDefault/util'

/** パックページ デフォルトテンプレート */
export default class PackAppDefault extends Component {
  static propTypes = {
    /** パックのID */
    productId: PropTypes.string,
    /** パックの情報 */
    product: PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object,
      values: PropTypes.object.isRequired
    }).isRequired,
    /** パックに含まれる動画情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** パックの詳細情報、視聴権利関連情報 */
    right: PropTypes.shape({
      view_term: PropTypes.number
    }),
    /** パックに含まれる動画の視聴権利情報 */
    rightMetas: PropTypes.arrayOf(PropTypes.object),
    /** パックのシーズン情報 */
    season: PropTypes.shape({
      meta_id: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
      values: PropTypes.object
    }).isRequired,
    /** パック販売情報 */
    products: PropTypes.arrayOf(PropTypes.object),
    /** パックの購入情報 */
    status: PropTypes.shape({
      isPurchseAvailable: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      limitDate: PropTypes.string
    })
  }

  static defaultProps = {
    episodes: [],
    packs: [],
    right: null,
    status: {
      isPurchased: false,
      isNotPurchased: false
    }
  }

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.state = {
      isExpanded: false
    }

    this.onClickMore = this.onClickMore.bind(this)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onClickMore(e) {
    e.preventDefault()
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  render() {
    const {
      product,
      series,
      season,
      packs,
      right,
      rightMetas,
      status
      // loaded
    } = this.props
    const { isExpanded } = this.state

    const thumbnailUrl =
      _.get(product, ['thumbnail_url']) || this.config.default_thumbnail

    const description = _.get(product, ['description'], '').replace(
      /\r?\n/g,
      '<br />'
    )

    // 他のシリーズ用ID
    let otherSeasons = _.get(product, ['values', 'other_series']) || []
    const otherSeasonsLive =
      _.get(product, ['values', 'other_series_live']) || []
    otherSeasons = otherSeasons.concat(otherSeasonsLive)

    // const bannerId1 = _.get(product, ['values', 'banner_1', 'id']);
    // const bannerId2 = _.get(product, ['values', 'banner_2', 'id']);

    const labelStatus = status.isPurchased ? (
      ''
    ) : (
      <React.Fragment>
        ログインすることで
        <br />
        既にお持ちのコンテンツを
        <br />
        お楽しみいただけます
      </React.Fragment>
    )

    const disp_order = _.get(season, ['values', 'disp_order'])

    const isFree = whatKindOfVideo(status.displayMode) === 'FREE'
    const todayData = new Date(Date.now())
    const salesEndAt = _.get(this.props, ['product', 'sales_end_at'])
    const isDeadline = (() => {
      // 販売終了期間が設定されていなかったら表示
      if (salesEndAt === null) return false

      // 販売終了期間が未来だったら表示
      if (todayData < salesEndAt) return false

      return true
    })()

    return (
      <div className="common-wrapper">
        <GoBack />
        <HeaderNewsComponent />

        <div className="c-headMeta">
          <div className="c-headMeta-metaBox">
            <div className="c-headMeta-metaBox-art">
              <div className="c-headMeta-metaBox-art-inBox">
                {labelStatus && (
                  <div className="c-headMeta-metaBox-art-inBox-labelStatus">
                    {labelStatus}
                  </div>
                )}
                <div className="c-headMeta-metaBox-art-inBox-labelBottom">
                  見放題パック
                </div>
                <div className="c-headMeta-metaBox-art-inBox-artwork">
                  <img
                    src={thumbnailUrl}
                    className="c-headMeta-metaBox-art-inBox-artwork-img"
                  />
                </div>
              </div>

              {/* for PC */}
              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.PRODUCT}
                favoriteId={product.product_id}
                title={product.name}
                showAddMyList={false}
              />
            </div>

            <div className="c-headMeta-metaBox-info">
              <h2 className="c-headMeta-metaBox-info-title">{product.name}</h2>
              <PackViewTerm right={right} status={status} />
              <div
                className={`c-headMeta-metaBox-info-titleHeader ${
                  isExpanded ? 'open' : ''
                }`}
                dangerouslySetInnerHTML={{ __html: description }}
              />
              {description && (
                <div className="c-more">
                  <a href="#" className="c-more-btn" onClick={this.onClickMore}>
                    <span
                      className={`c-more-btn-txt ${isExpanded && 'open'}`}
                    />
                  </a>
                </div>
              )}
              <AddButtonBlock
                favoriteType={FAVORITE_TYPE.PRODUCT}
                favoriteId={product.product_id}
                title={product.name}
                showAddMyList
                isPurchased={status.isPurchased}
                isFree={isFree}
                isDeadline={isDeadline}
                isLoaded
                showPurchasedBrowserBtn
              />
              <EpisodePager
                seriesId={series && series.id}
                seasonId={season && season.meta_id}
              />
            </div>
          </div>
        </div>

        <div className="c-listMeta">
          <div className="c-listMeta-inBox">
            <div className="c-listMeta-inBox-main">
              <PackList products={packs} />
              {/* {bannerId1 && <HtmlSnippet snippetId={bannerId1} />} */}
              {rightMetas && rightMetas.length > 0 && (
                <EpisodeListPack
                  episodes={rightMetas}
                  showOnlyFreeCoin
                  disp_order={disp_order}
                />
              )}
            </div>
            <div className="c-listMeta-inBox-sub">
              {/* {bannerId2 && <HtmlSnippet snippetId={bannerId2} />} */}
              <SideTab otherSeasons={otherSeasons} />
              <ProgramTopLink
                seriesId={series && series.id}
                seasonId={season && season.meta_id}
                isFree={false}
                disabled={!series || !season}
              />
              {/* <HtmlSnippet snippetId={this.config.extras.common_banner_snippet_key} /> */}
              <SideRecommend
                typeName={this.config.recommend.type_name.view}
                spotName={this.config.recommend.spot_name.program}
              />
            </div>
          </div>
        </div>
        <Footer className="mp-mt0" />
        <GlobalStyle />
      </div>
    )
  }
}

const GlobalStyle = createGlobalStyle`
  .appli {
    .c-headMeta-metaBox-art-inBox-labelStatus {
      padding: 5px 0;
      width: 180px;
      height: auto;
      font-size: 1.3rem;
      line-height: 1.5;

      @media (max-width: 1024px) {
        width: 150px;
        top: auto;
        right: auto;
        bottom: 15px;
        left: 15px;
        font-size: 1.1rem;
      }
    }
    .c-headMeta-metaBox-info {
      .c-storyPager {
        margin: 20px auto 0;
      }
    }
  }
`
