import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQuery, fixColors } from '../../../../../../exdio/components/style'

const Title = ({
  showNew = false,
  showChecked = false,
  onlySubTitle = false,
  breakSubTitle = false,
  showLatest = false,
  title = '',
  subTitle = '',
  images = {
    on: '/images/exdio/renewal/icon_mylist_added.svg',
    off: ''
  },
  ...props
}) => {
  const src = showChecked ? images.on : images.off

  /** 改行 or 空白 */
  const titles = (() => {
    if (onlySubTitle) return subTitle
    if (breakSubTitle)
      return (
        <>
          {title}
          <br />
          {subTitle}
        </>
      )
    return `${title} ${subTitle}`
  })()

  return (
    <StyledP {...props}>
      {/* 新着 */}
      {showNew && <StyledNew>NEW</StyledNew>}

      {/* 視聴済み */}
      {src && (
        <StyledChecked>
          <img src={showChecked ? images.on : images.off} alt="" />
        </StyledChecked>
      )}

      {/* 番組名 */}
      {titles}

      {showLatest && '（最新話）'}
    </StyledP>
  )
}

export default Title

Title.propTypes = {
  /** Newを表示するか */
  showNew: PropTypes.bool,
  /** 視聴済み表示をするか */
  showChecked: PropTypes.bool,
  /** エピソード名のみ表示するか */
  onlySubTitle: PropTypes.bool,
  /** 番組名とエピソード名の間を開業するか */
  breakSubTitle: PropTypes.bool,
  /** 最新話と表示するか */
  showLatest: PropTypes.bool,
  /** 番組名 */
  title: PropTypes.string,
  /** エピソード名 */
  subTitle: PropTypes.string,
  /** 視聴済み画像 */
  images: PropTypes.shape({
    on: PropTypes.string,
    off: PropTypes.string
  })
}

const StyledP = styled.p.withConfig({
  shouldForwardProp: (prop) => !['clampWidth'].includes(prop)
})`
  max-width: 100%;
  min-height: calc(1em * 1.25 * ${({ clampWidth }) => clampWidth || 1});
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ clampWidth }) => clampWidth || null};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
  overflow: hidden;
  word-break: break-all;

  ${mediaQuery()} {
    font-size: 1.3rem;
  }
`

const StyledNew = styled.span`
  margin-right: 4px;
  padding: 0 9px;
  height: 16px;
  background-color: ${fixColors.colorMain};
  display: inline-flex;
  align-items: center;
  font-size: 0.9rem;
  line-height: 1;
  vertical-align: text-top;
`

export const StyledChecked = styled.span`
  margin-right: 4px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: text-top;
`
