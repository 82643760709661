/* style */
import styled, { css } from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'

export const StyledDiv1 = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
  width: 1280px;
  margin: 0 auto;

  ${mediaQuery(1320)} {
    width: 960px;
  }
`

export const StyledButton = styled.button`
  background: url('https://wws.tv-asahi.co.jp/douga_mv/minato/images/sort-btn.jpg');
  background-size: cover;
  display: block;
  width: 19.4%;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  float: left;
  margin-right: 0.675%;
  margin-bottom: 10px;
  border: none;
  font-size: 1.6rem;
  line-height: 1;
  font-family: 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体',
    'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif;

  :nth-child(5n) {
    margin-right: 0;
  }

  :visited {
    color: #fff;
  }

  ${({ isActive }) => {
    if (isActive) {
      return css`
        background: url('https://wws.tv-asahi.co.jp/douga_mv/minato/images/sort-btn_active.jpg');
        background-size: cover;
      `
    }
    return null
  }}
`

export const StyledDiv2 = styled.div`
  max-width: 1280px;
  margin: 20px auto 30px;

  ${mediaQuery(1320)} {
    max-width: 920px;
  }
`

export const StyledDiv3 = styled.div`
  overflow: hidden;
  width: 1240px;
  margin: 40px auto 20px;

  ${mediaQuery(1320)} {
    max-width: 920px;
  }
`

export const StyledUl1 = styled.ul`
  margin: 0 -6px;
  overflow: hidden;
`
