import React, { useEffect } from 'react'

import BannerList from './BannerList/'
import { StyledDiv1, StyledDiv3, StyledTwitterArea } from './style'

/** ガリベン大学 Section7 */
const Section7 = () => {
  /** Twitter Timeline用のCSSを挿入 */
  const addTimelineCss = (intervalId) => {
    const iframe = document.querySelector('.twitter-timeline iframe')

    if (iframe) {
      // 対象の要素が存在しないときは実行しない
      const head = iframe.contentWindow.document.querySelector('head')
      const linkElement = document.createElement('link')
      linkElement.href = '/douga_mv/gariben_daigaku/css/timeline.css'
      linkElement.rel = 'stylesheet'
      head.appendChild(linkElement)
      clearInterval(intervalId)
    }
  }

  useEffect(() => {
    /** twitterウィジェットの追加 */
    const script = document.createElement('script')
    script.src = 'https://platform.twitter.com/widgets.js'
    script.async = true
    document.body.appendChild(script)

    const intervalId = setInterval(() => {
      addTimelineCss(intervalId)
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const twitterRenderAble = typeof window !== 'undefined'

  return (
    <StyledDiv1 id="top7">
      {twitterRenderAble && <StyledTwitterArea />}
      <StyledDiv3>
        <BannerList />
      </StyledDiv3>
    </StyledDiv1>
  )
}

export default Section7
