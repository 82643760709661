import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Link from '../../../../sketch-platform/ui/routing/Link'
import routes from '../../routes'

/** style */
import styled, { css } from 'styled-components'
import {
  mediaQuery,
  fixColors,
  borderColor2
} from '../../../exdio/components/style'

/** ナビゲーション > ドロップダウンメニュー */
const DropdownItem = (
  {
    menu = {
      title: '',
      subNavigation: []
    },
    toggleNavMenu = () => {},
    show = {},
    ...props
  },
  context
) => {
  const config = context.models.config.data
  const navigationMenu = config.global_navigation

  /** .genreSvodと.genreAnotherは同等なので、.genreSvodに集約 */
  const menuIdx = navigationMenu.indexOf(menu)
  const open = Boolean(show[`subMenu${menuIdx}`])

  /** 再レンダリング対策のためtoggleNavMenuをuseCallback化 */
  const onClickFunc = useCallback(() => {
    toggleNavMenu()
  }, [])

  return (
    <StyledDiv key="sub-navi" open={open} {...props}>
      <StyledUl>
        {menu.subNavigation.map((subMenu) => (
          <StyledLi key={subMenu.title}>
            <StyledLink
              href={subMenu.url}
              route={routes[subMenu.route]}
              params={subMenu.params}
              state={subMenu.state}
              target={subMenu.target || null}
              onClick={onClickFunc}
            >
              {subMenu.title}
            </StyledLink>
          </StyledLi>
        ))}
      </StyledUl>
    </StyledDiv>
  )
}

export default DropdownItem

DropdownItem.contextTypes = {
  models: PropTypes.object
}

DropdownItem.propTypes = {
  /** ナビゲーション毎の値 */
  menu: PropTypes.shape({
    title: PropTypes.string,
    subNavigation: PropTypes.array
  }).isRequired,
  /** ナビゲーションドロップダウン開閉処理 */
  toggleNavMenu: PropTypes.func.isRequired,
  /** ナビゲーションドロップダウン開閉状態 */
  show: PropTypes.object.isRequired
}

const StyledDiv = styled.div`
  z-index: 7;
  display: none;
  ${({ open }) =>
    open &&
    css`
      display: block;
      position: absolute;
      width: 315px;
      top: 45px;
      right: 0;
      ${mediaQuery()} {
        top: 43px;
        width: 100%;
      }
    `}
`

const StyledUl = styled.ul`
  background-color: #fff;
  padding: 1.2rem 1.4rem;
  font-size: 1.3rem;
  font-weight: 600;
  ${mediaQuery()} {
    background-color: #f0f0f0;
    padding: 0 2rem;
  }
`

const StyledLi = styled.li`
  box-sizing: border-box;
  ${mediaQuery()} {
    background-color: #f0f0f0;
  }
  &:last-child {
    > a {
      border-bottom: none;
    }
  }
`

const StyledLink = styled(Link)`
  display: block;
  padding: 1.6rem 1rem;
  position: relative;
  color: ${fixColors.colorFont};
  ${mediaQuery()} {
    border-bottom: 1px solid ${borderColor2};
    padding: 1.6rem 0;
  }
  &:hover {
    opacity: 0.6;
    transition: 0.3s;
  }
`
