import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

/** style */
import { mediaQuery } from '../../../../../style'
import useMediaQuery from '../../../../../../../hooks/useMediaQuery'

const Tab = ({
  id = '',
  title = '',
  html = '',
  isChecked = false,
  onChangeFunc = () => {},
  setIsModalOpen = () => {},
  ...props
}) => {
  const isSp = useMediaQuery()

  const onClickHandler = () => {
    if (isSp) {
      setIsModalOpen(true)
    }
  }

  return (
    <>
      <StyledLabel htmlFor={`tab-${id}`} isChecked={isChecked} {...props}>
        <StyledRadio
          type="radio"
          id={`tab-${id}`}
          name="tabs"
          value={id}
          checked={isChecked}
          onChange={onChangeFunc}
        />
        <StyledSpan1
          as={isSp ? 'button' : null}
          type={isSp ? 'button' : null}
          html={html ? true : false}
          onClick={onClickHandler}
          dangerouslySetInnerHTML={{ __html: html || title }}
        />
      </StyledLabel>
      <StyledSpan2 isChecked={isChecked} />
    </>
  )
}

export default memo(Tab)

Tab.propTypes = {
  /** Tabのid HTMLのidに使用する */
  id: PropTypes.string,
  /** 見出し画像alt */
  title: PropTypes.string,
  html: PropTypes.string,
  /** 装飾の種類 */
  iconType: PropTypes.string,
  /** チェックしているか */
  isChecked: PropTypes.bool,
  /** 変更時に実行する関数 */
  onChangeFunc: PropTypes.func,
  /** モーダル開閉状況変更 */
  setIsModalOpen: PropTypes.func
}

const StyledRadio = styled.input`
  display: none;
`

const StyledSpan1 = styled.span`
  display: inline-block;
  color: #343434;
  font-weight: 600;

  ${mediaQuery()} {
    padding-left: 0;
    padding: 0 30px 0 15px;
    width: 100%;
    background: #fff;
    border-width: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
    background: transparent;
    font-size: 2rem;
    font-family: 'M PLUS Rounded 1c', sans-serif;

    ruby {
      position: relative;
      top: -1px;

      rt {
        transform: translateY(5px);
      }
    }
  }

  ${({ html }) =>
    html &&
    css`
      ${mediaQuery('mm')} {
        display: flex;
        align-items: flex-end;
        position: relative;
        top: 6px;

        ruby {
          rt {
            transform: translateY(3px);
          }
        }
      }
    `}
`

const StyledSpan2 = styled.span`
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #b5a001;
  border: 3px solid #45313d;
  border-radius: 4rem;

  ${mediaQuery()} {
    display: ${({ isChecked }) => (isChecked ? null : 'none')};
  }
`

export const StyledLabel = styled.label`
  padding: 0 20px;
  width: 100%;
  height: 54px;
  background-color: #ffe100;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  border-radius: 4rem;
  border: 3px solid #45313d;
  color: #45313d;
  cursor: pointer;
  transition: transform 0.2s;

  @media (hover: hover) {
    &:hover {
      transform: translateY(6px);
    }
  }

  ${mediaQuery()} {
    position: relative;
    padding: 8px;
    display: ${({ isChecked }) => (isChecked ? null : 'none')};
    align-items: stretch;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 25px;
      width: 18px;
      height: 16.5px;
      background: url(/images/exdio/renewal/doraemon/tab_arrow.png) no-repeat
        top center / 100%;
      transform: translateY(-50%);
    }
  }
`
