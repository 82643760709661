import React from 'react'
import PropTypes from 'prop-types'
import { StyledImg1, StyledImg2, StyledImg3, StyledLink } from './style'

/** Section1｜投書箱ボタン */
const SuggestionBox = ({ ...props }) => {
  return (
    <StyledLink
      href="https://www.tv-asahi.co.jp/apps/apply/jump.php?fid=9147"
      target="_blank"
      rel="noopener"
      {...props}
    >
      <StyledImg1
        src="/images/exdio/renewal/gariben_daigaku/top/touhyou_bako.png"
        alt="私立ガリベン大学 [投票箱] おたよりを送る"
        width="235"
        height="388"
        loading="lazy"
      />
      <StyledImg2
        src="/images/exdio/renewal/gariben_daigaku/top/touhyou_bako_hover.webp"
        alt="私立ガリベン大学 [投票箱] おたよりを送る"
        width="235"
        height="388"
        loading="lazy"
      />
      <StyledImg3
        src="/images/exdio/renewal/gariben_daigaku/top/touhyou_bako_sp.webp"
        alt="私立ガリベン大学 [投票箱] おたよりを送る"
        width="235"
        height="388"
        loading="lazy"
      />
    </StyledLink>
  )
}

export default SuggestionBox

SuggestionBox.propTypes = {
  /** styleの継承 */
  className: PropTypes.string
}
