import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import cn from 'classnames'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  META_SCHEMA_ID,
  EPISODE_DISPLAY_MODE,
  PRODUCT_SCHEMA
} from '../../../../../constants/app'
import DeliverPeriod from './DeliverPeriod'
import Link from '../../../../../sketch-platform/ui/routing/Link'

export default class ListHeadMeta extends Component {
  static propTypes = {
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    howToPlays: PropTypes.object,
    displayMode: PropTypes.number,
    episode: PropTypes.object.isRequired,
    latestEpisodeStatus: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      limitDate: PropTypes.string
    }),
    noPrice: PropTypes.bool
  }

  static defaultProps = {
    howToPlays: {},
    displayMode: EPISODE_DISPLAY_MODE.UNKNOWN,
    episode: {},
    latestEpisodeStatus: {
      isFree: null,
      isNotFree: null,
      isPurchased: null,
      isNotPurchased: null,
      isInCourse: null,
      isNotInCourse: null,
      limitDate: null
    },
    noPrice: false
  }

  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = this.context.models.config.data
    this.state = {}

    this.price = this.price.bind(this)
    this.onAirDate = this.onAirDate.bind(this)
    this.getRoute = this.getRoute.bind(this)
    this.getCourseId = this.getCourseId.bind(this)
    this.getIsFree = this.getIsFree.bind(this)
  }

  /** 料金 */
  price() {
    const { meta, episode, displayMode, howToPlays } = this.props
    if (!meta) return null

    const howToPlay = howToPlays[episode.id] || {}
    const products = _.get(howToPlay, ['products']) || []
    const product = products.find(
      (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
    )
    const course = _.get(howToPlay, ['courses', 0])
    return webApp.utils.price(meta, product, course, displayMode)
  }

  /**
   * 放送日
   * 配信オリジナル(=放送日がない素材)とLIVE配信では放送日は項目自体非表示
   * https://docs.google.com/spreadsheets/d/110fDrQ0sNJ7f1QCiE1Hw-qNMjU-lVBAccAQFFbysvvA/edit?pli=1#gid=699926601&range=L162
   */
  onAirDate(meta, isSp, isLive) {
    if (!meta || isLive) return null

    const formatStr = isSp ? 'YYYY/M/D放送' : '放送日：YYYY年M月D日'
    const onAirDate = _.get(meta, 'values.avails_ReleaseHistoryOriginal')
    return onAirDate ? moment(onAirDate).format(formatStr) : ''
  }

  /**
   * 配信開始日
   */
  deliveryStartAt(meta, isSp, isLive) {
    if (!meta || isLive) return null

    const formatStr = isSp ? 'YYYY/M/D配信' : '配信開始日：YYYY年M月D日'
    const deliveryStartAt = _.get(meta, 'delivery_start_at')
    return deliveryStartAt ? moment(deliveryStartAt).format(formatStr) : ''
  }

  /** ルーティングに必要な情報の取得 */
  getRoute(meta, product, course, autoPlay = true) {
    return webApp.utils.getProgramLinkRoutes(
      this.context,
      meta,
      product,
      course,
      { autoPlay }
    )
  }

  /** コースIDを取得 */
  getCourseId() {
    const { howToPlays, episode } = this.props
    const courses = _.get(howToPlays, [episode, 'meta_id', 'courses']) || []
    const courseId = courses.length ? courses[0].course_id : null
    return courseId
  }

  /** 無料かどうか判別 */
  getIsFree() {
    const displayMode = this.props

    switch (displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        return true
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.UNKNOWN:
      default:
        return false
    }
  }

  checkRemainingDays = (endDateStr, days = 7) => {
    const today = new Date()
    const endDate = new Date(endDateStr)

    // 残り日数を計算
    const diffTime = endDate - today
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    // 指定された日数以内かどうかのフラグを返す
    return diffDays <= days
  }

  render() {
    const {
      meta,
      howToPlays,
      episode,
      latestEpisodeStatus,
      noPrice,
      displayMode
    } = this.props

    if (!episode) return null

    const courseId = this.getCourseId()
    const progress = webApp.utils.progress(meta)
    const [name, subTitle] = webApp.utils.titles(episode)
    const isLive =
      episode.meta_schema_id === META_SCHEMA_ID.LIVE ||
      episode.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
    const isFree = this.getIsFree()
    const time = webApp.utils.duration(episode)
    const deliveryEndAt = _.get(episode, ['delivery_end_at'])
    const remaining = webApp.utils.remaining(this.context, deliveryEndAt)

    const episodeProduct = (
      _.get(howToPlays, [episode.meta_id, 'products']) || []
    ).find((p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id)
    const episodeProductRight = (
      _.get(howToPlays, [episode.meta_id, 'rights']) || []
    ).find((p) => episodeProduct && p.right_id === episodeProduct.right_ids[0])
    const isSp = webApp.utils.isSp()
    const onAirDate =
      this.onAirDate(episode, isSp, isLive) ||
      this.deliveryStartAt(episode, isSp, isLive)
    const episodeProductPrice =
      episodeProduct && episodeProduct.active_pricing.price
    const { route, params, query } = this.getRoute(episode, null, null)

    return (
      <Link
        className="c-listHeadMeta"
        route={route}
        params={params}
        query={query}
      >
        <div className="c-listHeadMeta-cont clickable">
          <div className="c-listHeadMeta-cont-art">
            <div className="c-listHeadMeta-cont-art-inBox">
              <div className="c-card-inBox-art-artwork">
                <img
                  src={
                    webApp.utils.customSizeImageUrl(
                      episode.thumbnail_url,
                      'medium'
                    ) || this.config.default_thumbnail
                  }
                  width="192"
                  height="108"
                  alt=""
                  className="c-card-inBox-art-artwork-img"
                />
              </div>

              {remaining && this.checkRemainingDays(deliveryEndAt) && (
                <div className="c-artInfo-period">{remaining}</div>
              )}

              <div className="c-artInfo-seekarea h3px">
                {progress > 0 && (
                  <div
                    className="c-artInfo-seekarea-bar"
                    style={{ width: `${progress}%` }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="c-listHeadMeta-cont-info">
            <h2 className="c-listHeadMeta-cont-info-title clickable">
              {subTitle}（最新話）
              {isFree && <span className="priceFree">無料</span>}
            </h2>

            {!isLive && (
              <div className="c-listHeadMeta-cont-info-time">
                時間：{time || '-'}
              </div>
            )}

            {!noPrice && (
              <div
                className={cn('c-listHeadMeta-cont-info-price', {
                  free: isFree
                })}
              >
                <React.Fragment>
                  料金：
                  <span
                    className={cn('c-card-inBox-meta-price-in-course', {
                      'c-listHeadMeta-cont-info-price-coin': !(
                        courseId && episodeProductPrice === 0
                      )
                    })}
                  >
                    {this.price()}
                  </span>
                </React.Fragment>
              </div>
            )}

            {onAirDate && (
              <div
                key={isSp ? 'onAirDate-sp' : 'onAirDate-pc'}
                className={
                  isSp
                    ? 'c-listHeadMeta-cont-info-onAirSp'
                    : 'c-listHeadMeta-cont-info-onAir'
                }
              >
                {onAirDate}
              </div>
            )}

            {!isSp && (
              <DeliverPeriod
                meta={episode}
                status={latestEpisodeStatus}
                productRight={episodeProductRight}
              />
            )}

            <div className="c-listHeadMeta-cont-info-caption">
              {_.get(episode, ['values', 'evis_EpisodeLongSynopsis']) || null}
            </div>
          </div>
        </div>
      </Link>
    )
  }
}
