import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaSp, mainColor, white, keyColor, yuMincho, yuGothic } from './styles'

/** 全英オープン Heading3 */
const Heading3 = ({ enStr, jaStr, ...props }) => {
  return (
    <StyledH2 {...props}>
      <StyledH2En>{ enStr }</StyledH2En>
      <StyledH2Ja>{ jaStr }</StyledH2Ja>
    </StyledH2>
  )
}

export default memo(Heading3)

Heading3.propTypes = {
  enStr: PropTypes.string,
  jaStr: PropTypes.string
}

Heading3.defaultProps = {
  enStr: '',
  jaStr: ''
}

const StyledH2 = styled.h2`
padding: 8px 0;
border-top: 3px solid ${keyColor};
background-color: ${mainColor};
color: #fff;
text-align: center;
`

const StyledH2En = styled.span`
display: block;
font-size: 3rem;
font-family: ${yuMincho};
line-height: 1.2;

@media ${mediaSp} {
  font-size: 2rem;
}
`

const StyledH2Ja = styled.span`
display: block;
color: ${white};
font-size: 1.4rem;
font-family: ${yuGothic};
line-height: 1.2;

@media ${mediaSp} {
  font-size: 1.2rem;
}
`
