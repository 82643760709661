import styled from 'styled-components'
import { mediaQuery } from '../../style'
import TagList from '../ProgramGariben/TagList'
import Footer from '../../../../common/components/Footer'

export const StyledWrapper = styled.div`
  ${mediaQuery()} {
    // 最初にmediaQueryを仕様したコンポーネントにはスタイルが聞かないため、仮で置く
  }
`

export const StyledH2 = styled.h2`
  font-size: 3.8rem;
  font-weight: bold;
  text-align: center;

  ${mediaQuery()} {
    font-size: 1.6rem;
  }
`

export const StyledTagList = styled(TagList)`
  margin-top: 30px;

  ${mediaQuery()} {
    margin-top: 20px;
  }
`

export const StyledDiv1 = styled.div`
  margin: 0 auto;
  padding: 30px 0 45px;
  max-width: 960px;

  ${mediaQuery()} {
    padding: 10px 15px 15px;
  }
`

export const StyledDiv2 = styled.div`
  padding-top: 40px;
  background-color: #f3f4f5;

  ${mediaQuery()} {
    padding-top: 10px;
  }
`

export const StyledDivInner = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`

export const StyledUl = styled.ul`
  margin-top: 30px;
  display: flex;
  justify-content: center;

  ${mediaQuery()} {
    margin-top: 15px;
    display: block;
  }
`

export const StyledLi = styled.li`
  margin-left: 40px;

  ${mediaQuery()} {
    margin-left: 0;
    margin-top: 15px;
    text-align: center;
  }

  &:first-child {
    margin-left: 0;

    ${mediaQuery()} {
      margin-top: 0;
    }
  }
`

export const StyledButton1 = styled.button`
  padding: 15px 0;
  width: 230px;
  background-color: #02d4b1;
  border-width: 0;
  border-radius: 4px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;

  ${mediaQuery('sm', 'min')} {
    &:hover {
      opacity: 0.7;
    }
  }

  ${mediaQuery()} {
    padding: 10px 0;
    width: 100%;
    max-width: 460px;
    font-size: 1.2rem;
  }
`

export const StyledButton2 = styled.button`
  padding: 15px 0;
  width: 230px;
  background-color: #939394;
  border-width: 0;
  border-radius: 4px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;

  ${mediaQuery('sm', 'min')} {
    &:hover {
      opacity: 0.7;
    }
  }

  ${mediaQuery()} {
    padding: 10px 0;
    width: 100%;
    max-width: 460px;
    font-size: 1.2rem;
  }
`

export const StyledFooter = styled(Footer)`
  margin-top: 0;
`
