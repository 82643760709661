import propTypes from 'prop-types'
import React, { memo } from 'react'
import styled from 'styled-components'
import { mediaSp, mainColor, yuMincho } from '../common/styles'

/** 全英オープン Heading1 */
const Heading1 = ({ text, ...props }) => {
  const firstChar = text.charAt(0)
  const otherChars = text.slice(1)

  return (
    <StyledH2 {...props}>
      <StyledSpan>{ firstChar }</StyledSpan>
      { otherChars }
    </StyledH2>
  )
}

export default memo(Heading1)

Heading1.propTypes = {
  text: propTypes.string.isRequire
}

Heading1.defaultProps = {
  text: ''
}

const StyledH2 = styled.h2`
color: ${mainColor};
font: 4rem ${yuMincho};
line-height: 1.2;

@media ${mediaSp} {
  text-align: center;
  font-size: 2rem;
}
`

const StyledSpan = styled.span`
display: inline-block;
position: relative;

&::before {
  width: 106%;
  height: 3px;
  background: rgb(255, 181, 0);
  position: absolute;
  top: 110%;
  left: -6%;
  content: '';
}
`
