import React from 'react'
import PropTypes from 'prop-types'

const Options = ({ from = 0, to = 59 }) => {
  const countArr = []
  for (let i = from; i <= to; i++) {
    countArr.push(i)
  }

  return countArr.map((count) => (
    <option key={count} value={count}>
      {count}
    </option>
  ))
}

export default Options

Options.prototypes = {
  /** 開始の数 */
  from: PropTypes.number,
  /** 終了の数 */
  to: PropTypes.number
}
