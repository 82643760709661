import React from 'react'
import PropTypes from 'prop-types'
import Button1 from '../../common/Button/Button1'
import Button2 from '../../common/Button/Button2'
import styled from 'styled-components'

/** Nav｜List3 */
const List3 = ({ isPurchased = false, ...props }) => {
  return (
    <StyledUl {...props}>
      {!isPurchased && (
        <li>
          <StyledButton1
            href="https://www.tv-asahi.co.jp/douga_mv/purchase/gariben_daigaku/"
            target="_blank"
            html='生徒になる<span className="c-btn1__small">（有料会員）</span>'
            icon
          />
        </li>
      )}
      <li>
        <StyledButton2
          href="https://wws.tv-asahi.co.jp/apps/stock/lp/result.php?s=svod_gariv"
          target="_blank"
          name="ポイントを確認する"
          icon
        />
      </li>
    </StyledUl>
  )
}

export default List3

List3.propTypes = {
  /** サブスクライブボタンの表示制御に使用 */
  isPurchased: PropTypes.bool,
  /** styleの継承 */
  className: PropTypes.string
}

const StyledUl = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
`

const StyledButton1 = styled(Button1)`
  font-size: 1.6rem;

  span {
    font-size: 1.4rem;
  }
`

const StyledButton2 = styled(Button2)`
  font-size: 1.6rem;
`
