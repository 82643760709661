/**
 * 指定されたメタIDに対応するパレットを取得するための関数です。
 * @param {Object} model - データモデル
 * @param {string[]} metaIds - 取得したいパレットのメタIDの配列
 * @returns {Promise<Object>} - パレットのオブジェクトを含むPromise
 */
const getHowToPlayForPalettes = (model, metaIds) => {
  const path = [['meta', 'howToPlay', false, metaIds]]
  return model
    .fetch(path)
    .then((result) => _.get(result, ['json', 'meta', 'howToPlay', false]) || {})
}

export default getHowToPlayForPalettes
