import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'

/** エピソードリスト 価格 (SVOD, STVODのみ) */
const Price = ({ productPrice = null, coursePrice = null }) => {
  if (!productPrice && !coursePrice) return null

  if (productPrice === null || productPrice === 0) {
    return <StyledDiv hideCoin>月額{coursePrice}円</StyledDiv>
  }

  return (
    <StyledDiv>
      {productPrice}
      <span>or</span>
      {`月額${coursePrice}円`}
    </StyledDiv>
  )
}

export default Price

Price.propTypes = {
  productPrice: PropTypes.number,
  coursePrice: PropTypes.number
}

const StyledDiv = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hideCoin'].includes(prop)
})`
  display: flex;
  align-items: center;
  color: #343434;
  font-size: 1.3rem;
  font-weight: 600;

  ${mediaQuery()} {
    font-size: 1.2rem;
  }

  &::before {
    margin-right: 0.3em;
    width: 16px;
    height: 16px;
    background: url(/images/exdio/renewal/icon_medal.svg) no-repeat center
      center / contain;
    display: inline-block;
    content: ${({ hideCoin }) => (hideCoin ? 'none' : '""')};

    ${mediaQuery()} {
      width: 15px;
      height: 15px;
    }
  }

  span {
    padding: 0 5px;
    color: lightgray;
  }
`
