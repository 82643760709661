import styled from 'styled-components'
import { Link } from '../../../../../../../sketch-platform/ui/routing'

export const StyledDiv = styled.div`
  margin-top: 30px;
`

export const StyledP = styled.p`
  text-align: left;
  font-size: 1.3rem;
  line-height: 1.5;
`

export const StyledUl = styled.ul`
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 15px;

  li {
    &:first-child {
      margin-right: auto;
    }
  }
`

export const StyledLink = styled(Link)`
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 1.3rem;
  line-height: 1.5;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
  }

  img {
    width: 100%;
  }
`
