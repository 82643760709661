import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import styled from 'styled-components'
import webApp from '../../../../utils/exdioWebAppUtils'
import { Link } from '../../../../../../sketch-platform/ui/routing'
import { mediaSp, mainColor, keyColor, yuGothic } from './styles'

/** 全英オープン動画一覧 要素コンポーネント */
const EpisodeListItem = ({ meta, forceTitle, forceThumbnail, ...props }, context) => {
  /** タイトル */
  const [title, setTitle] = useState(forceTitle)

  /** サムネイル */
  const [thumbnailUrl, setThumbnailUrl] = useState(forceThumbnail)

  /* 動画の長さ */
  const duration = webApp.utils.duration(meta)
  const { route, params, query } = webApp.utils.getProgramLinkRoutes(context, meta)

  useEffect(() => {
    /**
     * メタからタイトルの設定
     * 親要素で指定している場合は実行しない
    */
    if (!forceTitle) {
      setTitle(webApp.utils.titles(meta, null, null)[1])
    }

    /**
     * メタからサムネイルの設定
     * 親要素で指定している場合は実行しない
    */
    if (!forceThumbnail) {
      setThumbnailUrl(get(meta, ['thumbnail_url'], null))
    }
  }, [meta])

  return (
    <StyledLink
      route={route}
      params={params}
      query={query}
      {...props}
    >
      <StyledImg
        src={thumbnailUrl}
        width="312"
        height="176"
        alt={title}
      />
      {duration && <StyledSpan1>{duration}</StyledSpan1>}
      <StyledDiv1>
        <img
          src="/douga_mv/common/images/pc/icon_free-ep.jpg"
          width="44"
          height="14"
          alt="無料"
        />
        <StyledSpan2>{title}</StyledSpan2>
      </StyledDiv1>
    </StyledLink>
  )
}

export default memo(EpisodeListItem)

EpisodeListItem.propTypes = {
  // 主情報
  /** メタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),

  /** 強制的にタイトルを指定する場合に設定 */
  forceTitle: PropTypes.string,
  /** 強制的にサムネイル画像を指定する場合に設定 */
  forceThumbnail: PropTypes.string
}

EpisodeListItem.defaultProps = {
  meta: null,
  title: '',
  thumbnail: ''
}

EpisodeListItem.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  routeHandler: PropTypes.object
}

const StyledSpan1 = styled.span`
padding: 6px;
background-color: ${keyColor};
position: absolute;
top: 0;
right: 0;
font-size: 1.2rem;
`

const StyledSpan2 = styled.span`
margin-top: 8px;
display: block;
transition: color .4s ease-in-out;

@media ${mediaSp} {
  display: block;
  overflow: hidden;
  font-size: 1.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
`

const StyledImg = styled.img`
width: 100%;
`

const StyledDiv1 = styled.div`
padding: 10px;
`

const StyledLink = styled(Link)`
background: ${mainColor};
display: block;
position: relative;
color: #fff;
font-family: ${yuGothic};

&:link,
&:visited,
&:active {
  color: #fff;
}

&:hover {
  ${StyledSpan2} {
    color: ${keyColor};
  }
}
`
