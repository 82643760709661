import styled from 'styled-components'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

export const StyledDiv1 = styled.div`
  background: hsla(0, 0%, 100%, 0.7);
  overflow: hidden;
`

export const StyledLink = styled(Link)`
  && {
    border: none;
    border-radius: 0;
    background: url('https://wws.tv-asahi.co.jp/douga_mv/minato/images/smp_sort-btn.jpg');
    background-position-x: 100%;
    background-size: auto 100%;
    color: #fff;
    padding: 2px 0;
    width: 49.75%;
    box-sizing: border-box;
    line-height: 28px;
    position: relative;
    text-align: center;
    font-size: 12px;
  }
`

export const StyledLink1 = styled(StyledLink)`
  float: left;
  &::before {
    font-family: Material Icons;
    content: '\\E314';
    font-size: 20px;
    position: absolute;
    left: 8%;
    top: 2px;
  }
`

export const StyledLink2 = styled(StyledLink)`
  float: right;
  &::before {
    font-family: Material Icons;
    content: '\\E315';
    font-size: 20px;
    position: absolute;
    right: 8%;
    top: 2px;
  }
`
