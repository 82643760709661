/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'

export const StyledP = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`

export const StyledDiv = styled.div`
  padding-top: 10px;

  ${mediaQuery()} {
    padding: 15.5px 0 16.5px;
    background: #f3f4f5;
  }

  div {
    :first-child {
      width: 200px;
      margin-left: auto;
      margin-right: 10px;
      font-size: 1.2rem;
      display: block;
      position: relative;
    }
  }
`
