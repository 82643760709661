import React, { Component } from "react";
import routes, { getHomeRoute } from '../routes';
import PropTypes from 'prop-types';

export default class SearchForm extends React.Component {
  static contextTypes = {
    models: PropTypes.object,
  }
  constructor(props, context) {
    super(props, context);
    this.dougaLink = this.context.models.config.data.dougaLink;
  }

  render() {
    return (
      <div id="global_nav_search_form">
        <form action={this.dougaLink.Search} method="get">
          <input type="text" name="keyword" id="keywords" className="menu_api" placeholder="番組名・キーワード" />
          <button type="submit" className="search_btn icon-loupe menu_api" />
        </form>
      </div>
    )
  }
}
