import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { EPISODE_DISPLAY_MODE } from '../../../../../../constants/app'
import webApp from '../../../../utils/exdioWebAppUtils'

/* hooks */
import useMediaQuery from '../../../../../hooks/useMediaQuery'

/* components */
import ButtonPC from './ButtonPC'
import ButtonSP from './ButtonSP'

const IconPlay = ({ status = {}, product = {}, ...props }, context) => {
  const {
    isNotPurchased,
    isPurchseAvailable: isPurchaseAvailable, // 誤字なので置き換え
    displayMode
  } = status

  if (!displayMode) return null

  const isSp = useMediaQuery()

  // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
  const withValidPreviewToken = _.get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])

  const watchAvailableStatus = (() => {
    if (withValidPreviewToken) return 0

    switch (displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        return 0

      case EPISODE_DISPLAY_MODE.UNKNOWN:
        return 2

      default:
        // その他はすべて有料設定
        if (!isNotPurchased) return 0
        if (!isPurchaseAvailable) return 2
        if (
          [
            EPISODE_DISPLAY_MODE.TVOD_NOT_FREE,
            EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE
          ].includes(displayMode) &&
          product &&
          !webApp.utils.isOnSale(context, product)
        ) {
          // TVODについては商品が販売期間外の場合は購入不可
          return 2
        }
        return 1
    }
  })()

  if (isSp)
    return <ButtonSP watchAvailableStatus={watchAvailableStatus} {...props} />

  return <ButtonPC watchAvailableStatus={watchAvailableStatus} {...props} />
}

export default IconPlay

IconPlay.propTypes = {
  status: PropTypes.shape({
    isNotPurchased: PropTypes.bool,
    isPurchseAvailable: PropTypes.bool,
    displayMode: PropTypes.number
  }),
  product: PropTypes.shape({
    product_id: PropTypes.number,
    name: PropTypes.string,
    original_price: PropTypes.number,
    active_pricing: PropTypes.shape({
      price: PropTypes.number,
      unit: PropTypes.string
    })
  })
}

IconPlay.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}
