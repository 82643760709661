import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useMediaQuery from '../../../../../../../hooks/useMediaQuery'
import { mediaQuery } from '../../../../../style'

/** 放送年検索 */
const SearchBoxYear = ({
  selectedYear = null,
  setSelectedYear = () => {},
  ...props
}) => {
  const isSp = useMediaQuery()
  const [isOpen, setIsOpen] = useState(!isSp)

  /** episodesからすべての放送年を取得 */
  const yearsArr = (() => {
    const startY = 2005
    const endY = 2020
    return Array.from(new Array(endY - startY + 1)).map((v, i) => i + startY)
  })()

  const onClickHandler = (e) => {
    const val = Number(e.target.value)
    setSelectedYear(val === selectedYear ? null : val)

    if (isSp) setIsOpen(!isOpen)
  }

  // 表示する項目がなければ終了
  if (!yearsArr.length) return null

  return (
    <StyledDiv {...props}>
      <StyledButton
        type="button"
        value=""
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedYear ? `${selectedYear}年` : '年代を選択する'}
      </StyledButton>
      <StyledUl isOpen={isOpen}>
        {yearsArr.map((year) => {
          return (
            <StyledLi key={year}>
              <StyledLabel checked={year === selectedYear}>
                <input
                  type="radio"
                  name="selectedYear"
                  value={year}
                  checked={year === selectedYear}
                  onChange={onClickHandler}
                />
                {year}年
              </StyledLabel>
            </StyledLi>
          )
        })}
      </StyledUl>
    </StyledDiv>
  )
}

export default memo(SearchBoxYear)

SearchBoxYear.propTypes = {
  selectedYear: PropTypes.number,
  setSelectedYear: PropTypes.func
}

const StyledDiv = styled.div`
  display: block;

  ${mediaQuery()} {
    height: calc(38px + 15px);
    position: relative;
    z-index: 2;
  }
`

export const StyledUl = styled.ul.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop)
})`
  background: #fff;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px 10px;

  ${mediaQuery()} {
    margin: 0 auto;
    width: calc(100% - 20px);
    border: 1px solid #d3d6db;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    gap: 0;
    overflow: hidden;
    position: absolute;
    top: calc(10px + 38px);
    right: 0;
    left: 0;
  }
`

const StyledLi = styled.li`
  ${mediaQuery()} {
    border-top: 1px solid #d3d6db;
  }

  &:first-child {
    ${mediaQuery()} {
      border-top-width: 0;
    }
  }
`

const StyledLabel = styled.label`
  padding: 12px 0 9px;
  width: 100%;
  background: ${({ checked }) => (checked ? '#ffbc09' : '#e60012')};
  border-width: 0;
  border-radius: 15px;
  display: block;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  transform: ${({ checked }) => (checked ? 'translateY(5px)' : null)};
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
  cursor: pointer;

  ${mediaQuery()} {
    padding: 0 5px;
    min-height: 36px;
    background-color: ${({ checked }) => (checked ? '#c3f74a' : '#fff')};
    border-radius: 0;
    display: flex;
    align-items: center;
    position: relative;
    color: #333;
    font-size: 1rem;
    transform: none;
    cursor: pointer;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #ffbc09;
      box-shadow: 0 0 0 #ffbc09;
      transform: translateY(5px);
    }
  }
`

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop)
})`
  padding: 10px;
  width: 100%;
  background: #e60012;
  border-width: 0;
  border-radius: 10px;
  display: block;
  position: relative;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  cursor: pointer;

  ${mediaQuery('sm', 'min')} {
    display: none;
  }

  &::after {
    margin: auto 0;
    width: 0;
    height: 0;
    border-width: 7px 4px 0 4px;
    border-style: solid;
    border-color: #fff transparent transparent;
    position: absolute;
    top: 0;
    right: 12px;
    bottom: 0;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    content: '';
  }
`
