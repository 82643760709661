import styled from 'styled-components'
import { mediaQuery } from '../../../../style'

export const StyledUl = styled.ul`
  padding: 0 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  position: relative;
  z-index: 1;

  ${mediaQuery()} {
    padding: 0;
    gap: 5px;
  }
`

export const StyledLi = styled.li`
  width: calc((100% - 20px) / 2);
  display: flex;
  position: relative;

  ${mediaQuery()} {
    width: calc((100% - 5px) / 2);
  }

  &::after {
    width: 100%;
    height: 100%;
    background-image: radial-gradient(#00dbfb 33%, rgba(255, 255, 255, 0) 38%),
      radial-gradient(#00dbfb 33%, rgba(255, 255, 255, 0) 38%);
    background-position: 0 0, 5px 5px;
    background-size: 10px 10px;
    border-radius: 12px;
    position: absolute;
    right: -7px;
    bottom: -7px;
    z-index: 0;
    content: '';

    ${mediaQuery()} {
      content: none;
    }
  }

  ${({ isHide }) =>
    isHide &&
    `
    display: none;
  `}
`

export const StyledP = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
`
