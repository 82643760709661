import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

/** 番組別お知らせ */
const Notice = ({ programMeta = {}, ...props }) => {
  const notice = {
    text: _.get(programMeta, ['values', 'notice']),
    hyperlink: _.get(programMeta, ['values', 'notice_hyperlink']),
    start_at: _.get(programMeta, ['values', 'notice_publish_start_at']),
    end_at: _.get(programMeta, ['values', 'notice_publish_end_at'])
  }

  const now = new Date(
    Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000
  )

  if (
    !notice.text ||
    now < Date.parse(notice.start_at) ||
    Date.parse(notice.end_at) < now
  ) {
    return null
  }

  return React.createElement(
    notice.hyperlink ? 'a' : 'div',
    {
      href: notice.hyperlink || null,
      className: 'c-program_notice__wrapper',
      ...props
    },
    <dl className="c-program_notice">
      <dt className="c-program_notice__term">お知らせ</dt>
      <dd className="c-program_notice__desc">{notice.text}</dd>
    </dl>
  )
}

export default Notice

Notice.propTypes = {
  /**
   動画メタ情報<span class="css-1ywjlcj">*</span><br>
    <span class="css-ks2jcn">object</span><br>
    <br>
    <table>
      <thead>
        <tr>
            <th>values</th>
        </tr>
      </thead>
      <tbody>
        <tr>
            <td>notice: お知らせ内容<br>
            <span class="css-ks2jcn">string</span></td>
        </tr>
        <tr>
            <td>notice_hyperlink: お知らせリンク<br>
            <span class="css-ks2jcn">string</span></td>
        </tr>
        <tr>
            <td>notice_publish_start_at: お知らせ開始日<br>
            <span class="css-ks2jcn">string</span></td>
        </tr>
        <tr>
            <td>notice_publish_end_at: お知らせ終了日<br>
            <span class="css-ks2jcn">string</span></td>
        </tr>
      </tbody>
    </table>
   */
  programMeta: PropTypes.shape({
    meta_schema_id: PropTypes.number,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired
}

Notice.contextTypes = {
  routeHandler: PropTypes.object,
  falcorModel: PropTypes.object,
  models: PropTypes.object
}
