import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import webApp from '../../../utils/exdioWebAppUtils'
import { mediaQuery } from '../../style'

/** APP:視聴方法リンク */
const HowToPlayLink = ({ isPlanPage = false, ...props }, context) => {
  const isApp = webApp.utils.isApp(context)
  // DioPlayer_iOSかチェック
  const isIosDioPlayer = navigator.userAgent
    .split(' ')
    .find((value) => value.match(/^DioPlayer_iOS/g))

  // DioPlayer_iOSのバージョンチェック
  const checkIosDioPlayerVersion = (target) => {
    if (!target) return false
    const version = parseInt(target.split('/')[1].replace(/\./g, ''), 10)
    return version > 2013
  }
  const checkIosDioPlayerVersionResult = checkIosDioPlayerVersion(
    isIosDioPlayer
  )

  const dialogRef = useRef(null)

  /** ダイアログの開閉 */
  const toggleDialog = (open = true) => {
    if (open) {
      dialogRef.current.showModal()
    } else {
      dialogRef.current.close()
    }
  }

  const Description = () => {
    if (isPlanPage) {
      return (
        <p>
          アプリ内では本コンテンツを購入いただけません。
          <br />
          <br />
          テレ朝動画のウェブサイトでご購入ください。
        </p>
      )
    }

    if (isPlanPage || (isApp && !isIosDioPlayer)) {
      return (
        <p>
          アプリ内購入には対応していません。
          <br />
          <br />
          ログインすることで既にお持ちのコンテンツをお楽しみいただけます。
        </p>
      )
    }

    if (isApp && isIosDioPlayer && checkIosDioPlayerVersionResult) {
      return (
        <p>
          アプリ内購入には対応していません。
          <br />
          <br />
          「テレ朝動画ウェブサイトへ（douga.tv-asahi.co.jpを開きます）」ボタンからテレ朝動画のブラウザページにアクセスいただくことで購入することが可能です。
        </p>
      )
    }

    return (
      <p>
        このアプリを使用すると、ウェブや他のデバイスでテレ朝動画から購入した動画を再生できます。
        <br />
        <br />
        このデバイスはアプリ内購入には対応していません。
      </p>
    )
  }

  return (
    <StyledDiv {...props}>
      <StyledButton1 type="button" onClick={toggleDialog}>
        視聴方法
      </StyledButton1>
      <StyledDialog ref={dialogRef}>
        <Description />
        <StyledButton2 type="button" onClick={() => toggleDialog(false)}>
          OK
        </StyledButton2>
        <StyledButton3 type="button" onClick={() => toggleDialog(false)} />
      </StyledDialog>
    </StyledDiv>
  )
}

export default HowToPlayLink

HowToPlayLink.propTypes = {
  /** プランページであるか*/
  isPlanPage: PropTypes.bool
}

HowToPlayLink.contextTypes = {
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  })
}

const StyledDiv = styled.div`
  display: grid;
`

/** 視聴方法 */
const StyledButton1 = styled.button`
  padding: 0;
  border: 0;
  background: none;
  justify-self: end;
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  column-gap: 8px;
  color: #343434;
  font-size: 1.3rem;
  transition: opacity 0.2s;

  &::after {
    width: 18px;
    height: 18px;
    display: inline-block;
    background: url(/images/exdio/renewal/icon_question_circle.svg) no-repeat
      center/ 100% auto;
    content: '';
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`

/** OKボタン */
const StyledButton2 = styled.button`
  padding: 10px 75px;
  background: #000;
  border: 0;
  border-radius: 5px;
  display: inline-block;
  align-self: center;
  color: #fff;
  font-size: 1.5rem;
  transition: opacity 0.2s;

  &:link,
  &:visited {
    color: #fff;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`

/** 閉じるボタン */
const StyledButton3 = styled.button`
  padding: 0;
  width: 26px;
  height: 26px;
  background: url(/images/exdio/renewal/icon_close_modal.svg) no-repeat center /
    100% auto;
  border: 0;
  display: block;
  position: absolute;
  top: -40px;
  right: 0;

  ${mediaQuery()} {
    top: -34px;
    right: 0;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledDialog = styled.dialog`
  padding: 24px;
  max-width: 840px;
  width: 86%;
  border: 0;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  position: fixed;
  inset: 0;
  color: #343434;
  font-size: 1.3rem;
  line-height: 1.5;
  text-align: left;
  overflow: visible;
  opacity: 1;
  animation: ${fadeIn} 0.2s linear 0s;

  ${mediaQuery()} {
    width: 92%;
  }

  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }

  &:not([open]) {
    display: none;
  }
`
