export const SEASON_SLUG = {
  SHIN_CHAN: 'SHIN_CHAN',
  SHIN_MEN: 'SHIN_MEN',
  TV_SPECIAL: 'TV_SPECIAL',
  LIVE_ARCHIVE: 'LIVE_ARCHIVE'
}

export const PANEL_ID = {
  ALL: 'ALL',
  SELECTION: 'SELECTION',
  AGE: 'AGE',
  CHARACTER: 'CHARACTER',
  [SEASON_SLUG.SHIN_MEN]: SEASON_SLUG.SHIN_MEN,
  [SEASON_SLUG.TV_SPECIAL]: SEASON_SLUG.TV_SPECIAL
}

export const TABS_DATA = [
  {
    id: PANEL_ID.ALL,
    title: 'すべてのおはなし'
  },
  {
    id: PANEL_ID.SELECTION,
    title: '厳選エピソード'
  },
  {
    id: PANEL_ID.AGE,
    title: '年代別'
  },
  {
    id: PANEL_ID.CHARACTER,
    title: 'キャラクター別'
  },
  {
    id: PANEL_ID.TV_SPECIAL,
    title: 'クレヨンしんちゃんテレビスペシャル'
  },
  {
    id: PANEL_ID.SHIN_MEN,
    title: 'SHIN-MEN'
  }
]
