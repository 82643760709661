import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import classnames from 'classnames';
import Cookie from 'js-cookie';
import _ from 'lodash';
import $ from 'jquery';

import ExPresTrackedElement from '../../../../common/components/ExPresTrackedElement';
import routes from '../../../../common/routes';
import MainViewLink from '../../../../common/components/MainViewLink';

// MEMO: watchページの子メタ
export default class Card extends Component {

  static getPaths = function(models, options, props = {}) {
    let keyPath = [];
    if (props.titleId) {
      keyPath = keyPath.concat(['meta', props.titleId]);
    }
    return [keyPath.concat([['id','name', 'thumbnailUrl', 'type', 'values', 'deliveryStartAt', 'deliveryEndAt']])];
  };

  static getRootPath = function(models, options, props = {}) {
    if (!props.titleId) return;
    return ['meta', props.titleId];
  }

  static propTypes = {
    mainId: PropTypes.number,
    sliderItemId: PropTypes.string,
    getRowHasPopOpen: PropTypes.func,
    isDoubleWide: PropTypes.bool,
    isTallPanel: PropTypes.bool,
    itemTabbable: PropTypes.bool,
    model: PropTypes.object.isRequired,
    onPopClose: PropTypes.func,
    onPopLeave: PropTypes.func,
    onPopOpen: PropTypes.func,
    rowModel: PropTypes.object,
    rankNum: PropTypes.number,
    rowNum: PropTypes.number,
    titleId: PropTypes.number.isRequired,
    popType: PropTypes.string,
    popClasses: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    progress: PropTypes.number
  };

  static defaultProps = {
    rankNum: 0,
    rowNum: 0
  };

  static contextTypes = {
    models: PropTypes.object,
    columnsInRow: PropTypes.number,
    currentPage: PropTypes.string,
    getModelData: PropTypes.func,
    history: PropTypes.object,
    isInitialRender: PropTypes.bool,
    isOverlayPage: PropTypes.bool,
    isRtl: PropTypes.bool,
    isTallRow: PropTypes.bool,
    titleRowEpisodeTrackId: PropTypes.number,
    titleRowTrackId: PropTypes.number,
    titleRowTrailerTrackId: PropTypes.number,
    listContext: PropTypes.string,
    listType: PropTypes.string,
    loggingApp: PropTypes.object,
    lolomoId: PropTypes.string,
    profileManager: PropTypes.string,
    requestId: PropTypes.string,
    routeHandler: PropTypes.object,
    rowNum: PropTypes.number,
    trackId: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    this.item = props.model.getSync(rootPath);
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
      imgWidth:{
        width: "100%"
      }
    };
    this.imgHeightBox = this.imgHeightBox.bind(this);
    this._isMounted = false;
    this.previewUrlList = this.context.models.config.data.preview_url_list;
    this.cookieRubocopPrefix = this.context.models.config.data.cookie_rubocop_prefix;
  }

  componentWillMount() {
    this.fetchData(this.props);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.mouseOutTimeoutId) {
      clearTimeout(this.mouseOutTimeoutId);
      delete this.mouseOutTimeoutId;
    }
    if (this.mouseOverTimeoutId) {
      clearTimeout(this.mouseOverTimeoutId);
      delete this.mouseOverTimeoutId;
    }
  }
  
  imgHeightBox() {
    let imgSize = $(".avod_ep_list_img");
    if(imgSize != "undefined"){
      return $(imgSize).width() * 0.562+ "px";
    }else {
      {}
    }
  }

  nextPrevText(e, styles) {
    return(
      <div className='avod_ep_list_img' style={this.state.imgWidth}>
      <div
        className={classnames('title-artwork avod_ep_list_thumb', {
          'double-wide': this.props.isDoubleWide === true && this.props.isTallPanel !== true
        })}
        style={styles}></div>
        <div className="avod_ep_list_prg_end"><p>{e}</p></div>
      </div> 
    )
  }

  render() {
    if (!this.item) {
      // TODO: 読み込み中
      return null;
    }

    var itemName = this.item.name;
    var episodeName = this.props.program_link ? 'エピソード一覧' : this.item.values.avails_EpisodeTitleDisplayUnlimited;
    var programName = itemName.replace(episodeName, '').trim();


    const childFactory = (child) => {
      return React.cloneElement(child, {
      });
    };

    const key = `title-card-${this.props.rowNum}-${this.props.rankNum}`;
    const tabIndex = (this.props.itemTabbable) ? 0 : -1;


    var targetImage
    if (this.item.id == this.props.mainId){
      if (this.props.program_link ) {
        targetImage = (
        <img src="/images/exdio/list_slider.png" alt="list_slider" className="catchup_playing"/>
        )  
      } else {
      targetImage = (
        <img src="/images/exdio/play_slider.png" alt="play_slider" className="catchup_playing"/>
      )
      }
    }

    let titleArtwork;

    const thumbnailUrl = this.item.thumbnailUrl + '_small' || window.app.reactContext.models.config.data.default_thumbnail;
    const prevTime = Cookie.get(this.previewUrlList + this.cookieRubocopPrefix);
    const styles = {
      backgroundImage: `url('${thumbnailUrl}')`,
      maxHeight: this.imgHeightBox()
    };
    
    const progressbar = this.props.program_link || this.props.progress === 0 ? '' :
    (
      <div className="avod_ep_list_progress_wrap">
        <div className="avod_ep_list_progress" style={{width: `${this.props.progress}%`}}></div>
      </div>
    );

    if (moment(prevTime).isBefore(this.item.deliveryStartAt) && !this.props.program_link) {
      titleArtwork = this.nextPrevText('まもなく配信予定', styles);
    } else if(moment(prevTime).isAfter(this.item.deliveryEndAt) && !this.props.program_link) {
      titleArtwork = this.nextPrevText('次回もお楽しみに！', styles);
    } else {
      if (this.props.program_link) {
        titleArtwork = (<div className='avod_ep_list_img program_list' style={this.state.imgWidth}>
        <div
        className={classnames('title-artwork avod_ep_list_thumb', {
          'double-wide': this.props.isDoubleWide === true && this.props.isTallPanel !== true
        })}
        style={styles}></div>
        {targetImage}
        {progressbar}
        </div>
        );
      } else {
        titleArtwork = (<div className='avod_ep_list_img' style={this.state.imgWidth}>
        <div
        className={classnames('title-artwork avod_ep_list_thumb', {
          'double-wide': this.props.isDoubleWide === true && this.props.isTallPanel !== true
        })}
        style={styles}></div>
        {targetImage}
        {progressbar}
        </div>
        );
      }
    }

    const itemPrefixSubtileIcon = this.props.subtitle ? <img src="/images/exdio/top_cc_icon.png" className="icon-subtitle" /> : ''

    return (
      <li className="another">
        <div
          className={classnames('smallTitleCard', 'title_card', 'post_l_api', {
            'hasPop': this.state.hasPop === true,
            'lockup': true,
          })}
          aria-label={this.item.name}
          ref="titleCard"
          tabIndex={tabIndex}
          role={'link'}
          key={key}
          id={key}
          onClick={this.handleClick.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
          onMouseEnter={this.handleMouseOver.bind(this)}
          onMouseLeave={this.handleMouseOut.bind(this)}>
          <ExPresTrackedElement idDoubleWide={this.props.isDoubleWide}>
          <div className="avod_line_box_link">
            {titleArtwork}
            <h3>
              <span>{programName}</span>
              <span>{!this.props.program_link && itemPrefixSubtileIcon}{episodeName}</span>
              <p className="avod_main_ep_air">
              </p>
            </h3>
            </div>
          </ExPresTrackedElement>
        </div>
      </li>
    );
  }

  handleClick(e) {
    e.preventDefault();
    const { history } = this.context;
    if (this.props.program_link == true && this.props.seasonId){
      history.push(routes.program.makePath({id: this.props.seasonId}));
    } else {
      history.push(routes.watch.makePath({id: this.props.titleId}));
    }
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  handleMouseOver(e) {

    if (this.props.popType === 'none') {
      return;
    }

    if (this.state.hasPop && this.mouseOutTimeoutId && this.props.onPopOpen) {
      this.props.onPopOpen(this);
    }

    if (this.mouseOutTimeoutId) {
      clearTimeout(this.mouseOutTimeoutId);
      delete this.mouseOutTimeoutId;
      return;
    }

    let timeout = 300;
    if (this.props.getRowHasPopOpen) {
      if (this.props.getRowHasPopOpen()) {
        timeout = 100;
      }
    }
    if (this.popUnmounting === true) {
      timeout = 300;
    }

    this.alreadyPopOpened = false;
    this.mouseOverTimeoutId = setTimeout(() => {
      delete this.mouseOverTimeoutId;
      this.setState({hasPop: true});
    }, timeout);
  }

  handleMouseOut(e) {

    if (this.props.popType === 'none') {
      return;
    }

    // まだ拡大が始まっていない時は拡大しない
    if (this.mouseOverTimeoutId) {
      clearTimeout(this.mouseOverTimeoutId);
      delete this.mouseOverTimeoutId;
    }

    if (!this.state.hasPop) return;

    const delay = (this.alreadyPopOpened) ? 150 : 1;
    if (this.props.onPopLeave) {
      this.props.onPopLeave(this, delay);
    }

    this.mouseOutTimeoutId = setTimeout(() => {
      delete this.mouseOutTimeoutId;
      this.popUnmounting = true;
      this.setState({hasPop: false});
    }, delay);
  }

  onPopOpen() {
    if (this.props.popType === 'none') {
      return;
    }

    this.alreadyPopOpened = true;
    if (this.props.onPopOpen && this.state.hasPop === true) {
      this.props.onPopOpen(this);
    }
  }

  onPopClose() {
    if (this.props.popType === 'none') {
      return;
    }

    delete this.popUnmounting;
    if (this.props.onPopClose) {
      this.props.onPopClose(this);
    }
  }

  fetchData(props) {
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    this.state.dispose = evaluator.dispose;
    evaluator.then((res) => {
      this.item = _.get(res.json, rootPath, {});
      const newState = {
        fetchDataError: null,
        dispose: null,
        generation: props.model.getVersion(rootPath)
      };
      if (this._isMounted) this.setState(newState);
      else Object.assign(this.state, newState);
    }).catch((e) => {
      console.error(e.stack);
      const newState = {
        fetchDataError: e,
        dispose: null
      };
      if (this._isMounted) this.setState(newState);
      else this.state = Object.assign(newState);
    });
  }
}
