import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import routes from '../../routes';
import Link from '../../../../sketch-platform/ui/routing/Link';

/** パレット(注目キーワード)コンポーネント */
export default function PaletteKeywords(props) {
  return (
    <div className="c-keywords">
      <header className="c-keywords-head">
        <h3 className="c-keywords-head-hedding">
          <span className="c-keywords-head-hedding-inner">注目キーワード</span>
        </h3>
      </header>
      <ul className="c-keywords-cont">
        {props.palette.values.keywords.map(keyword => {
          return (
            <li key={keyword} className="c-keywords-cont-btn">
              <Link route={routes.search} query={{ keyword }} className="c-keywords-cont-btn-link">
                {keyword}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

PaletteKeywords.propTypes = {
  palette: PropTypes.shape({
    values: PropTypes.shape({
      keywords: PropTypes.array.isRequired
    })
  }).isRequired
};
