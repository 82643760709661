import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  SEARCH_TYPE,
  KEY_CODE,
  META_SCHEMA_ID,
  SORT_TYPE,
  SORT_TYPES
} from '../../../../constants/app'
import routes from '../../../common/routes'
import ProgramItem from '../../../common/components/renewal/ProgramItem'
import TabNavigation from '../../../common/components/renewal/TabNavigation'
import SelectBox from '../../../common/components/renewal/SelectBox'
import Footer from '../../../common/components/Footer'
import webApp from '../../utils/exdioWebAppUtils'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import * as browserEvents from '../../../utils/browserEvents'
import * as DOMUtils from '../../../utils/DOMUtils'
import HeaderNewsComponent from './HeaderNewsComponent'
import window from 'global/window'

import Palette from '../../../common/components/renewal/Palette'
import PaletteSkeleton from '../../../common/components/renewal/PaletteSkeleton'

import SearchInitial from '../../../common/components/renewal/SearchInitial/'

const PAGE_TITLE = '検索結果'

/** 検索結果ページ */
export default class SearchContent extends Component {
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  static getSsrMetaTags(_models, _options, _props, _prefetchResult) {
    return { title: PAGE_TITLE }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data
    const { keyword } = context.routeHandler.query
    const isInitial = !Object.prototype.hasOwnProperty.call(
      this.context.routeHandler.query,
      'keyword'
    )
    this.state = {
      // 入力値
      inputKeyword: keyword || '',
      // 実際の検索値
      keyword: keyword || '',
      // 検索後の保持用
      searchedKeyword: null,
      searchType: SEARCH_TYPE.ALL.value,
      sortType: SORT_TYPE.SCORE.value,
      searchResult: [],
      totalCount: null,
      howToPlays: {},
      metasForPalettes: [],
      howToPlaysForPalettes: {},
      /** 初期表示か */
      isInitial,
      isSp: null,
      products: {},
      courses: {},
      palettes: {}
    }

    this.onClickSearchIcon = this.onClickSearchIcon.bind(this)
    this.onChangeKeyword = this.onChangeKeyword.bind(this)
    this.onKeyDownKeyword = this.onKeyDownKeyword.bind(this)
    this.onClearKeyword = this.onClearKeyword.bind(this)
    this.onChangeSearchType = this.onChangeSearchType.bind(this)
    this.onChangeSortType = this.onChangeSortType.bind(this)
    this.onClickItem = this.onClickItem.bind(this)
    this.onTouchmove = this.onTouchmove.bind(this)
    this.onScroll = this.onScroll.bind(this)

    this.setSortRef = (e) => {
      this.sortRef = e
    }
    this.setListRef = (e) => {
      this.listRef = e
    }

    this.paletteComponents = this.paletteComponents.bind(this)
  }

  componentDidMount() {
    this._isMounted = true

    const { isInitial } = this.state
    const isSp = window.matchMedia('(max-width: 1024px)').matches
    this.setState({ isSp })
    this.search().catch((e) => webApp.utils.handleFalcorError(e, this.context))
    this.getTotalCount().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    // 検索画面でヘッダーの検索フォームから再検索された場合のためにリスナー登録.
    // 自画面遷移のhistory.push()ではコンポーネントの状態変更を直接トリガーできないため.
    this.removeHistoryListener = this.context.history.listen((location) => {
      if (routes.search.makePath() !== location.pathname) return
      const queryParams = new URLSearchParams(location.search)
      const keywordParam = queryParams.get('keyword') || ''
      if (!isInitial && keywordParam === this.state.keyword) return
      if (this._isMounted) {
        // ヘッダーから検索が走った場合は初期化
        const sortType = SORT_TYPE.SCORE.value
        this.setState(
          {
            inputKeyword: keywordParam,
            keyword: keywordParam,
            isInitial: !queryParams.has('keyword'),
            sortType
          },
          () => {
            if (this.sortRef) this.sortRef.setValue(sortType)
            this.search().catch((e) =>
              webApp.utils.handleFalcorError(e, this.context)
            )
            this.getTotalCount().catch((e) =>
              webApp.utils.handleFalcorError(e, this.context)
            )
          }
        )
      }
    })

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context, PAGE_TITLE)
    // GTMの更新
    const title = this.context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)

    browserEvents.addEventListener('touchmove', this.onTouchmove)
    browserEvents.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    this._isMounted = false
    browserEvents.removeEventListener('touchmove', this.onTouchmove)
    browserEvents.removeEventListener('scroll', this.onScroll)
    if (typeof this.removeHistoryListener === 'function')
      this.removeHistoryListener()
  }

  /** 検索呼び出し */
  onSearch() {
    const { inputKeyword } = this.state
    const sortType = SORT_TYPE.SCORE.value
    this.setState({ keyword: inputKeyword, sortType }, () => {
      if (this.sortRef) this.sortRef.setValue(sortType)
      this.search(true).catch((e) =>
        webApp.utils.handleFalcorError(e, this.context)
      )
      this.getTotalCount().catch((e) =>
        webApp.utils.handleFalcorError(e, this.context)
      )
    })
  }

  /** 検索アイコン押下時 */
  onClickSearchIcon() {
    this.onSearch()
  }

  /** 検索ワード変更時 */
  onChangeKeyword(e) {
    const inputKeyword = e.target.value
    if (this._isMounted) {
      this.setState({ inputKeyword })
    }
  }

  /** 検索ワード確定時 */
  onKeyDownKeyword(event) {
    if (KEY_CODE.ENTER !== event.keyCode) return
    this.onSearch()
  }

  /** 検索ワードクリア時 */
  onClearKeyword() {
    if (this._isMounted) {
      this.setState({ inputKeyword: '' })
    }
  }

  /** 検索条件変更時 */
  onChangeSearchType(searchType) {
    if (this._isMounted) {
      this.setState({ searchResult: [], isInitial: false, searchType }, () => {
        this.search().catch((e) =>
          webApp.utils.handleFalcorError(e, this.context)
        )
        this.getTotalCount().catch((e) =>
          webApp.utils.handleFalcorError(e, this.context)
        )
      })
    }
  }

  /** 並び替え条件変更時 */
  onChangeSortType(sortType) {
    this.setState({ searchResult: [], isInitial: false, sortType }, () =>
      this.search()
    )
  }

  /** 検索結果の動画リンク押下時 */
  onClickItem(meta, product = null, course = null, autoPlay = true) {
    webApp.utils.goToProgramLink(this.context, meta, product, course, {
      autoPlay
    })
  }

  /** タグ検索か */
  isTagSearch() {
    return this.state.inputKeyword.trim().startsWith('タグ:')
  }

  /**
   * 検索
   * @param withHistory
   *   ブラウザヒストリーへの反映要否.
   *   テキストボックスからの検索時はクエリパラメータへの反映が必要なためtrueを指定.
   */
  search(withHistory = false) {
    const { searchResult, searchType, sortType } = this.state
    const keyword = this.state.keyword.trim()
    if (withHistory) {
      this.context.history.push(routes.search.makePath({}, { keyword }))
    }

    if (!keyword) {
      this.setState({
        keyword: '',
        searchedKeyword: '',
        searchResult: []
      })
      return Promise.resolve()
    }

    const range = { from: 0, to: (searchResult || []).length + 29 }

    const tag = keyword.replace(/^タグ:(\s|　)*/, '')
    const base_path = this.isTagSearch()
      ? ['metaByTag', tag, searchType, sortType]
      : ['meta', 'dsearch', keyword, searchType, sortType, 25]
    const path = base_path.concat([range])
    return this.model
      .fetch([path])
      .then((result) => {
        const tmpSearchResult = _.get(result, ['json', ...base_path]) || {}
        delete tmpSearchResult.$__path
        if (this._isMounted) {
          this.setState({
            searchResult: Object.values(tmpSearchResult),
            searchedKeyword: keyword
          })
        }
      })
      .then(() => this.getHowToPlays())
  }

  getTotalCount() {
    const { searchType } = this.state
    const keyword = this.state.keyword.trim()
    if (!keyword) {
      this.setState({ totalCount: 0 })
      return Promise.resolve()
    }

    const tag = keyword.replace(/^タグ:(\s|　)*/, '')
    const path = this.isTagSearch()
      ? ['metaByTag', tag, searchType, 'length']
      : ['meta', 'dsearch', keyword, searchType, 25, 'length']
    return this.model.fetch([path]).then((result) => {
      const totalCount = _.get(result, ['json', ...path]) || 0
      if (this._isMounted) {
        this.setState({ totalCount })
      }
    })
  }

  /** 価格情報取得 */
  getHowToPlays() {
    const { searchResult } = this.state
    // タグ検索の場合は取得しない
    if (!searchResult.length || this.isTagSearch()) return Promise.resolve()

    const metaIds = searchResult
      .filter((meta) => meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE)
      .map((e) => e.meta_id)
    const path = [['meta', 'howToPlay', false, metaIds]]
    return this.model.fetch(path).then((result) => {
      const howToPlays =
        _.get(result, ['json', 'meta', 'howToPlay', false]) || {}
      if (this._isMounted) {
        this.setState({ howToPlays })
      }
    })
  }

  onTouchmove() {
    if (this && this.isWithinDistanceBuffer()) this.search()
  }

  onScroll() {
    if (this && this.isWithinDistanceBuffer()) this.search()
  }

  isWithinDistanceBuffer() {
    if (!this.listRef) return false
    return DOMUtils.getDistanceToBottomOfElement(this.listRef) < 100
  }

  // パレット
  paletteComponents({ palette, courses = {} }) {
    if (Object.keys(palette).length) {
      return (
        <Palette
          courses={courses}
          palette={palette}
          captionSetting={{
            showCoin: true,
            breakSubTitle: true,
            showCaption: true,
            titlesMinHeight: false,
            textOverFlow: true
          }}
          loaded={true}
        />
      )
    } else {
      return <PaletteSkeleton />
    }
  }

  render() {
    const {
      inputKeyword,
      searchedKeyword,
      searchResult,
      totalCount,
      howToPlays,
      isInitial,
      isSp,
      sortType
    } = this.state

    return (
      <div className="p-search common-wrapper">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />

        {(!isSp || !isInitial) && (
          <div className="c-searchResults center">
            <div className="c-searchResults-searchBox">
              <div className="c-searchInput">
                <span
                  className="c-searchInput-btnSearch"
                  onClick={this.onClickSearchIcon}
                />
                <input
                  type="text"
                  value={inputKeyword}
                  placeholder="検索"
                  onChange={this.onChangeKeyword}
                  onKeyDown={this.onKeyDownKeyword}
                />
                <span
                  className="c-searchInput-btnClose"
                  onClick={this.onClearKeyword}
                />
              </div>
            </div>

            {searchedKeyword && (
              <div className="c-searchResults-head">
                <div className="c-searchResults-head-keyword">
                  <h3>
                    「{searchedKeyword}」の検索結果（{totalCount}件）
                  </h3>
                </div>

                <div className="c-searchResults-head-nav">
                  <TabNavigation onSelect={this.onChangeSearchType} />
                  <SelectBox
                    ref={this.setSortRef}
                    types={[SORT_TYPE.SCORE].concat(SORT_TYPES)}
                    onSelect={this.onChangeSortType}
                  />
                </div>
              </div>
            )}

            <div className="c-card-panel">
              <div className="c-card-panel-cont" ref={this.setListRef}>
                {searchResult.map((meta) => (
                  <ProgramItem
                    key={meta.meta_id}
                    meta={meta}
                    howToPlay={howToPlays[meta.meta_id]}
                    showCaption
                    showCoin
                    showDelivery={sortType === SORT_TYPE.ENDING.value}
                    showInCourse
                    showNew={webApp.utils.showNew(meta)}
                    showDeadLine={sortType === SORT_TYPE.ENDING.value}
                    showDeliveryEndPriorToStart={
                      sortType === SORT_TYPE.ENDING.value
                    }
                    onClickThumbnail={() => this.onClickItem(meta)}
                    onClickCaption={() =>
                      this.onClickItem(meta, null, null, false)
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* 初期表示時 */}
        {isInitial && <SearchInitial />}

        <Footer />
      </div>
    )
  }
}
