/* style */
import styled from 'styled-components'

export const StyledDiv1 = styled.div`
  position: relative;
  margin: 0 auto 20px;
`

export const StyledDiv2 = styled.div`
  .slick-dots {
    bottom: -155px;

    li {
      width: 12px;
      height: 12px;
      background: #b7c5d9;

      &.slick-active {
        background: #069ee7;
      }

      button {
        width: 100%;
        height: 100%;

        ::before {
          content: '';
        }
      }
    }
  }
`

export const StyledDiv3 = styled.div`
  text-align: left;
  padding: 20px 10px 30px;
  margin-top: -3px;
  overflow: hidden;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  font-weight: 700;
  z-index: 98;
  position: relative;
  background: rgba(108, 156, 200, 0.7);
`

export const StyledDiv4 = styled.div`
  margin-top: -13px;
  margin-bottom: 50px;
`

export const StyledDiv5 = styled.div`
  .slick-current img {
    opacity: 1;
  }

  .slick-current div:before {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 5px solid #5d9fe3;
    top: 0;
    margin-top: 13px;
  }

  .slick-current div:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: 0;
    margin-left: -8px;
    border-bottom: 13px solid #5d9fe3;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
`

export const StyledDiv6 = styled.div`
  position: relative;
  height: 89px;
  z-index: 99;
`

export const StyledImg = styled.img`
  display: block;
  padding-top: 18px;
  margin-top: 0;
  opacity: 0.5;
`
