import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledH2, StyledSpan, StyledConvertSvg } from './style'

/** Logirlプランページ | ページタイトル3 */
const Hdg3 = ({ src, alt, children, ...props }) => {
  return (
    <StyledH2 {...props}>
      <StyledSpan>
        <StyledConvertSvg src={src} alt={alt} />
        {children}
      </StyledSpan>
    </StyledH2>
  )
}

export default Hdg3

Hdg3.propTypes = {
  /** アイコン画像のリンク */
  src: PropTypes.string,
  /** アイコン画像のalt */
  alt: PropTypes.string,
  /** styled-components拡張の為のclassName */
  className: PropTypes.string
}

Hdg3.defaultProps = {
  src: '',
  alt: '',
  className: ''
}
