import uaparser from 'ua-parser-js'
import useragent from 'express-useragent'

export default function browserInfo(userAgent, cb) {
  if (!cb) {
    cb = function(browseInfo, ua, uaType) {
      return browserInfo
    }
  }
  const ua = uaparser(userAgent)
  const uaType = useragent.parse(userAgent)
  uaType.isiOS = uaType.isiPhone || uaType.isiPad || uaType.isiPod

  const browserMajor = parseInt(ua.browser.major, 10)
  let drmSystem
  let maybeSupportsHTML5 = true
  let maybeSupportsHTML5withDRM = false
  let es2015 = true
  let webp = false
  if (uaType.isiOS) {
    maybeSupportsHTML5withDRM = false
  } else if (uaType.isChrome) {
    webp = browserMajor >= 23
    maybeSupportsHTML5withDRM = browserMajor >= 35
    drmSystem = 'widevine'
  } else if (uaType.isFirefox) {
    maybeSupportsHTML5 = browserMajor >= 4
    maybeSupportsHTML5withDRM = browserMajor >= 42
    drmSystem = 'widevine'
  } else if (uaType.isSafari) {
    maybeSupportsHTML5 = browserMajor >= 4
    maybeSupportsHTML5withDRM = browserMajor >= 8
    drmSystem = 'fairplay'
  } else if (uaType.isOpera) {
    webp = browserMajor >= 12
    maybeSupportsHTML5withDRM = browserMajor >= 29
    drmSystem = 'widevine'
  } else if (uaType.isIE) {
    if (browserMajor >= 11) {
      drmSystem = 'playready'
    } else {
      maybeSupportsHTML5 = false
      es2015 = false
    }
  } else if (uaType.isEdge) {
    if (parseInt(ua.browser.major, 10) >= 15) {
      maybeSupportsHTML5withDRM = true
    }
    drmSystem = 'playready'
  }
  // アプリ WebView UAでDioPlayerは先頭とは限らなくなった
  // https://logiclogic.backlog.jp/view/EX_DIO-1895
  uaType.isAppli = ua.ua.match(/DioPlayer_/) ? true : false

  let isTablet = false
  if (uaType.isiOS) {
    if (userAgent.indexOf('iPad') > 0) {
      isTablet = true
    }
  } else if (uaType.isAndroid) {
    if (userAgent.indexOf('Android') > 0 && userAgent.indexOf('Mobile') == -1) {
      isTablet = true
    }
  }

  return cb(
    {
      features: {
        drmSystem,
        webp,
        es2015,
        maybeSupportsHTML5,
        maybeSupportsHTML5withDRM
      },
      isAppli: uaType.isAppli,
      isAndroid: uaType.isAndroid,
      isIPhone: uaType.isiPhone,
      isChrome: uaType.isChrome,
      isChromeOS: uaType.isChromeOS,
      isSilk: uaType.isSilk,
      isEdge: uaType.isEdge,
      isFirefox: uaType.isFirefox,
      isIE: uaType.isIE,
      isIOS: uaType.isiOS,
      isIOSGT91: uaType.isiOS && parseFloat(ua.os.version) >= 9.1,
      isLinux: uaType.isLinux,
      isMac: uaType.isMac,
      isMobile: uaType.isMobile,
      isOpera: uaType.isOpera,
      isSafari: uaType.isSafari,
      isWindows: uaType.isWinwdows,
      isWindows7: uaType.os === 'Windows 7',
      isWindows8: uaType.os === 'Windows 8',
      isWindows10: uaType.os === 'Windows 10',
      isWindowsPhone: uaType.isWindows && uaType.isMobile,
      isWindowsVista: uaType.os === 'Windows Vista',
      isWindowsXP: uaType.os === 'Windows XP',
      isTablet: isTablet,
      major: ua.browser.major,
      name: ua.browser.name,
      os: {
        name: ua.os.name,
        fullName: ua.os.name,
        version: ua.os.version
      },
      specialFlags: {},
      version: ua.browser.version
    },
    ua,
    uaType
  )
}
