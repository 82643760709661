import React, { memo } from 'react'
import PropTypes from 'prop-types'
import useIsMounted from '../../../../hooks/useIsMounted'

/** components */
import { Link } from '../../../../../sketch-platform/ui/routing'

/** style */
import styled, { css } from 'styled-components'
import {
  mediaPc,
  mediaSp,
  white,
  black,
  blue,
  darkBlue,
  green,
  darkGreen,
  yellow,
  darkYellow,
  pink,
  darkPink
} from './styles'

/**
 * しんちゃん ボタン1
 * 表示する1つめにスタイルが効かないため、MainVisualでstyledを直書き
 */
const Button1 = ({ className, children, ...props }) => {
  const isMounted = useIsMounted()
  if (!isMounted) return null

  return (
    <StyledLink className={className} {...props}>
      {children}
    </StyledLink>
  )
}

export default memo(Button1)

Button1.propTypes = {
  /** 背景色タイプ */
  type: PropTypes.oneOf(['blue', 'green', 'yellow', 'pink']),
  /** 行数 */
  line: PropTypes.number
}

Button1.defaultProps = {
  type: '',
  line: 1
}

const typeBlue = css`
  background: ${blue};
  box-shadow: ${darkBlue} 0 5px 0 0px;

  &:hover {
    @media ${mediaPc} {
      box-shadow: ${blue} 0 0 0 0px;
    }
  }
`

const typeGreen = css`
  background: ${green};
  box-shadow: ${darkGreen} 0 5px 0 0px;

  &:hover {
    @media ${mediaPc} {
      box-shadow: ${green} 0 0 0 0px;
    }
  }
`

const typeYellow = css`
  color: ${black};
  background: ${yellow};
  box-shadow: ${darkYellow} 0 5px 0 0px;

  &:link,
  &:visited,
  &:active {
    color: ${black};
  }

  &:hover {
    @media ${mediaPc} {
      box-shadow: ${yellow} 0 0 0 0px;
    }
  }
`

const typePink = css`
  color: ${white};
  background: ${pink};
  box-shadow: ${darkPink} 0 5px 0 0px;

  &:hover {
    @media ${mediaPc} {
      box-shadow: ${pink} 0 0 0 0px;
    }
  }
`

export const StyledLink = styled(Link)`
  width: 220px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${white};
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;

  ${({ line }) => {
    switch (line) {
      case 2:
        return css`
          height: 60px;
          border-radius: 30px;
        `
      default:
        return css`
          height: 45px;
          border-radius: 23px;
        `
    }
  }}

  @media ${mediaSp} {
    padding: 0;
    width: 100%;
    font-size: 2rem;
  }

  &:link,
  &:visited,
  &:active {
    color: ${white};
  }

  &:hover {
    @media ${mediaPc} {
      transform: translateY(5px);
    }
  }

  ${({ type }) => {
    switch (type) {
      case 'blue':
        return typeBlue
      case 'green':
        return typeGreen
      case 'yellow':
        return typeYellow
      case 'pink':
        return typePink
      default:
        return
    }
  }}
`
