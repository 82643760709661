import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class LoadingBox extends Component {

  static propTypes = {
    boxClassName: PropTypes.object,
    delay: PropTypes.number,
    displayWhenNotPulsing: PropTypes.bool,
    pulsate: PropTypes.bool,
  };

  static defaultProps = {
    boxClassNames: {},
    delay: 0,
    displayWhenNotPulsing: true,
    pulsate: false,
  };

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const pulsate = this.props.pulsate;
    const displayWhenNotPulsing = this.props.displayWhenNotPulsing;
    const boxClassNames = this.props.boxClassNames;
    const delay = this.props.delay + 's';
    const style = pulsate ? {
      WebkitAnimationDelay:delay,
      AnimationDelay:delay
    } : {};

    boxClassNames["pulsate"] = pulsate;
    boxClassNames["pulsate-transparent"] = pulsate && !displayWhenNotPulsing;
    boxClassNames["no-pulsate"] = !pulsate && displayWhenNotPulsing;

    return React.createElement("div",{
      className:classnames(boxClassNames),
      style
    });
  }
}
