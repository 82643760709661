import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../../utils/exdioWebAppUtils'
import Twitter from './Twitter'
import Facebook from './Facebook'
import Line from './Line'

/* style */
import {
  StyledWrapper,
  StyledButton,
  StyledImg,
  StyledList,
  StyledBg
} from './style'

/** 詳細画面:マイリストに追加ボタン、マイリスト、シェアボタン */
const ShareButton = ({ title }, context) => {
  const isApp = webApp.utils.isApp(context)
  const { params, query, hash } = context.routeHandler
  const validQuery = Object.assign({}, query)
  // 自動再生フラグは渡さない
  delete validQuery.auto
  // SNS用シェアURLはアプリでもWEB用URL
  const URI = context.routeHandler.route
    .makeAbsolutePath({
      params,
      validQuery,
      hash
    })
    .replace('/app/', '/')
  const encodedURI = encodeURI(URI)

  const [isOpen, setIsOpen] = useState(false)
  const buttonSrc = `/images/exdio/renewal/gariben_daigaku/episode/icon/share${
    isOpen ? '_close' : ''
  }.svg`

  const titleTemplate = context.models.config.data.title_template
  const hrefTwitter = `https://twitter.com/share?text=${encodeURIComponent(
    sprintf(titleTemplate, title)
  )}&url=${encodedURI}`
  const hrefFacebook = `https://www.facebook.com/share.php?u=${encodedURI}`
  const hrefLine = `https://social-plugins.line.me/lineit/share?url=${encodedURI}`

  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open')
    }
  })

  return (
    <StyledWrapper>
      {/* シェアボタン */}
      <StyledButton
        type="button"
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <StyledImg src={buttonSrc} width="38" height="38" alt="" />
        シェア
      </StyledButton>

      {/* 各SNSボタン */}
      <StyledList isShow={isOpen}>
        <Twitter isApp={isApp} linkUrl={hrefTwitter} />
        <Facebook isApp={isApp} linkUrl={hrefFacebook} />
        <Line isApp={isApp} linkUrl={hrefLine} />
      </StyledList>

      {/* 背景 */}
      <StyledBg isShow={isOpen} onClick={() => setIsOpen(false)} />
    </StyledWrapper>
  )
}

export default ShareButton

ShareButton.propTypes = {
  title: PropTypes.string
}

ShareButton.defaultProps = {
  title: ''
}

ShareButton.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  updateUserInfo: PropTypes.func,
  updateUserInfoSubscribe: PropTypes.func
}
