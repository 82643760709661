import styled from 'styled-components'
import { mediaQuery } from '../../../style'

const NextPrevText = styled.div`
  margin: auto;
  height: 1em;
  color: #fff;
  font-size: 4.6rem;
  font-weight: 600;
  text-align: center;

  ${mediaQuery(767, 'max')} {
    font-size: 2.5rem;
  }
`

export default NextPrevText
