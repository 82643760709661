import React, { Component } from 'react'
import PropTypes from 'prop-types'
import webApp from '../../utils/exdioWebAppUtils'
import Footer from '../../../common/components/Footer'
import NotFound from '../../../generic/components/errors/NotFound'
import Sample from './Sample'

/** 静的ページ */
export default class SampleContent extends Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  static getPath(_models, options, _props) {
    const { routeHandler } = options
    const url = routeHandler.path
    return ['page', url]
  }

  static getPrefetchPaths(models, options, props) {
    return [SampleContent.getPath(models, options, props)]
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    return { title: 'Sample Content' }
  }

  constructor(props, context) {
    super(props, context)
    this.config = context.models.config.data

    this.somethingArray = [
      {
        id: 1,
        text: 'sample1'
      },
      {
        id: 2,
        text: 'sample2'
      },
      {
        id: 3,
        text: 'sample3'
      }
    ]
  }

  componentDidMount() {}

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags() {
    const title = 'Sample Content'
    webApp.utils.updateTitle(title)

    const { copyright } = this.config
    const description = this.config.description
    const keywords = this.config.keywords
    const url = window.location.href
    const regularUrl = url.replace(/\?.*$/, '')
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: regularUrl }]
    }
    webApp.utils.updateMeta(metaTags)
  }

  render() {
    const isProduction = ['production'].includes(process.env.NODE_ENV)
    if (isProduction === true) {
      return <NotFound />
    }

    return (
      <div className="common-wrapper">
        <Sample
          somethingString={'somethingString'}
          somethingArray={this.somethingArray}
        />
        <Footer />
      </div>
    )
  }
}
