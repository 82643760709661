import styled from 'styled-components'
export const mediaSp = '(max-width: 1024px)'

export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledUl = styled.ul`
  display: flex;
`

export const StyledLi = styled.li`
  display: flex;
  align-items: center;
`
