import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import HtmlSnippet from '../../HtmlSnippet'
import Comment from '../comment'
import PackList from '../details/PackList';
import SwitchableListGrid from '../../../../common/components/renewal/SwitchableListGrid';

/** サイドバー */
export default class ListMetaMain extends Component {
  static propTypes = {
    /** HtmlSnippetの描画に使用 */
    episodeBannerId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    /** HtmlSnippetの描画に使用 */
    programBannerId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    /** 動画リストの初期表示設定 */
    listType: 'default' | 'list' | 'grid',
    /** trueの時、料金表示を消す */
    hidePrice: PropTypes.bool,
    /** 動画リストに表示する動画の情報 */
    episodes: PropTypes.arrayOf(
      PropTypes.object
    ),
    /** 「お得なパック」の表示に使用 */
    products: PropTypes.arrayOf(
      PropTypes.object
    ),
    /** 動画リストのオプション設定
     * @param {number} episodesPerPages - ページャー1ページに表示する動画の数
     * @param {number} range - ページャーに表示するページNoの設定
     */
    pagerOptions: PropTypes.object
  }

  static defaultProps = {
    episodeBannerId: null,
    programBannerId: null,
    listType: 'default',
    hidePrice: false
  }

  static contextTypes = {
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const {
      meta,
      status,
      episodes,
      products,
      rightMetas,
      howToPlays,
      programMeta,
      pagerOptions,
      episodeBannerId,
      programBannerId,
      listType,
      hidePrice
    } = this.props
    const isApp = webApp.utils.isApp(this.context)

    return (
      <div className="c-listMeta-inBox-main">
        {status.isNotInCourse &&
          <PackList
            products={products}
            showMedal={!isApp}
          />
        }

        {!isApp && episodeBannerId && <HtmlSnippet snippetId={episodeBannerId} />}
        {!isApp && programBannerId && <HtmlSnippet snippetId={programBannerId} />}

        <Comment meta={meta} />

        <SwitchableListGrid
          episodes={episodes}
          rootMetas={rightMetas}
          howToPlays={howToPlays}
          placeholder="検索"
          showNew
          showChecked
          onlySubTitle
          listType={listType}
          disp_order={_.get(programMeta, ['values', 'disp_order'])}
          pagerOptions={pagerOptions}
          hidePrice={hidePrice}
        />
      </div>
    )
  }
}
