import React from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'

import webApp from '../../../../../../../exdio/utils/exdioWebAppUtils'

/* style */
import {
  StyledFigure,
  StyledImg,
  StyledTitle,
  StyledFigcaption,
  StyledP,
  StyledTime,
  StyledPlayButton,
  StyledLink
} from './style'

/** 番組、単話ページ 動画、番組リスト要素コンポーネント */
const ListItem = (
  {
    meta = null,
    product = null,
    course = null,
    showNew = false,
    showChecked = false,
    showCaption = false,
    showLatest = false,
    onlySubTitle = false,
    breakSubTitle = false,
    isFree = false,
    clampWidth = 1,
    thumbnailSize = 'large',
    route = {},
    params = {},
    query = {},
    href = '',
    target = '',
    listType = 'default',
    images = {
      on: '/images/exdio/renewal/icon_mylist_added.svg',
      off: ''
    },
    deliveryDate = '',
    ...props
  },
  context
) => {
  const config = context.models.config.data
  const progress = webApp.utils.progress(config, meta)
  const duration = webApp.utils.duration(meta)

  // 無料アイコンとかぶるためNEWアイコンは非表示にする
  // const isNew = webApp.utils.isNew(context, meta)
  const isNew = false

  /** タイトル */
  const [title, subTitle] = (() => {
    if (title) return [title, '']
    return webApp.utils.titles(meta, product, course)
  })()

  /** サムネイル画像 */
  const [thumbnailUrl, thumbnailAlt] = (() => {
    const [url, alt] = (() => {
      if (meta) return [meta.thumbnail_url, `${title} ${subTitle}`]
      return [null, null]
    })()

    /* 読み込む画像サイズを変更する */
    return [
      webApp.utils.customSizeImageUrl(url, thumbnailSize) ||
        config.default_thumbnail,
      alt
    ]
  })()

  const synopsis = (() => {
    if (size(meta)) return get(meta, ['values', 'evis_EpisodeLongSynopsis'], '')
    return ''
  })()

  return (
    <StyledLink
      route={route}
      params={params}
      query={query}
      href={href}
      target={target}
      listType={listType}
      {...props}
    >
      <StyledFigure>
        {listType !== 'list' && (
          <StyledImg
            src={thumbnailUrl}
            alt={thumbnailAlt}
            showNew={showNew && isNew}
            duration={duration}
            progress={progress}
            isFree={isFree}
          />
        )}

        <StyledFigcaption>
          <StyledTitle
            showNew={showNew && isNew}
            showChecked={showChecked}
            onlySubTitle={onlySubTitle}
            breakSubTitle={breakSubTitle}
            showLatest={showLatest}
            title={title}
            subTitle={subTitle}
            clampWidth={clampWidth}
            images={images}
            deliveryDate={deliveryDate}
          />

          {duration && listType === 'list' && (
            <StyledTime>({duration})</StyledTime>
          )}

          {showCaption && <StyledP>{synopsis}</StyledP>}
        </StyledFigcaption>

        {listType === 'list' && (
          <StyledPlayButton
            src="/images/exdio/renewal/icon_list_btn_play.svg"
            alt=""
            loading="lazy"
          />
        )}
      </StyledFigure>
    </StyledLink>
  )
}

export default ListItem

ListItem.propTypes = {
  // 主情報
  /** メタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  /** パック商品 */
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired
  }),
  /** 月額見放題パックコース */
  course: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired
  }),

  // 表示制御
  /** NEWマークを表示するか */
  showNew: PropTypes.bool,
  isFree: PropTypes.bool,
  /** チェックマークを表示するか */
  showChecked: PropTypes.bool,
  /** あらすじ(長)を表示するか */
  showCaption: PropTypes.bool,
  /** (最新話)を表示するか */
  showLatest: PropTypes.bool,
  /** 価格を表示するか */
  showCoin: PropTypes.bool,
  /** サブタイトルのみを表示するか */
  onlySubTitle: PropTypes.bool,
  /** サブタイトルを改行して表示するか */
  breakSubTitle: PropTypes.bool,
  /** 何行目から...表示にするか */
  clampWidth: PropTypes.number,
  /** サムネイルのサイズ指定、デフォルトはmedius */
  thumbnailSize: PropTypes.oneOf(['large', 'medium', 'small', 'origin', '']),
  route: PropTypes.object,
  params: PropTypes.object,
  query: PropTypes.object,
  href: PropTypes.string,
  target: PropTypes.string,
  listType: PropTypes.oneOf(['default', 'list', 'grid']),
  images: PropTypes.shape({
    on: PropTypes.string,
    off: PropTypes.string
  }),
  deliveryDate: PropTypes.string
}

ListItem.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}
