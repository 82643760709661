import React, { Component } from 'react';
import PropTypes from 'prop-types';

const FIELDS = {
  birth: 'birth',
  genderCode: 'genderCode',
  postCode: 'postCode',
};

export default class Enquete extends Component {
  static contextTypes = {
    models: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isSubmitEnabled: false,
    };

    this.elements = null;
    this.dougaLink = this.context.models.config.data.dougaLink;

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormBlur = this.handleFormBlur.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  checkBirth(val) {
    if (!val.match(/^[0-9]{6}$/)) return false;
    const y = parseInt(val.slice(0, 4)),
      m = parseInt(val.slice(-2));
    if (1 > m || m > 12) return false;
    const date = new Date(),
      min_date = 190001,
      now_yyyymm = parseInt('' + date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2));
    if (min_date > val || val > now_yyyymm) return false;
    return true;
  }

  checkGender(val) {
    if (val == null || !val.match(/^(1|2)$/)) return false;
    return true;
  }

  checkPostalcode(val) {
    if (!val.match(/^[0-9]{7}$/)) return false;
    return true;
  }

  validate(field, value) {
    if (!value) {
      return false;
    }

    let isValid = true;
    switch (field) {
      case FIELDS.birth:
        isValid = this.checkBirth(value);
        break;
      case FIELDS.genderCode:
        isValid = this.checkGender(value);
        break;
      case FIELDS.postCode:
        isValid = this.checkPostalcode(value);
        break;
      default:
        break;
    }
    return isValid;
  }

  getElementValue(element) {
    let value;
    if (element.value) {
      value = element.value;
    } else if (element.length && element.length > 0) {
      for (let i = 0; i < element.length; i++) {
        if (element[i].checked) {
          value = element[i].value;
        }
      }
    }
    return value;
  }

  issetElementValue(field) {
    let value = this.getElementValue(this.elements[field]);
    return this.validate(field, value);
  }

  setSubmitEnabled() {
    if (!this.elements) {
      return;
    }
    const isNoEmptyFields = Object.values(FIELDS).every(field => this.issetElementValue(field));
    const isNoErrors = Object.values(this.state.errors).every(enquete_form_error => !enquete_form_error);
    this.setState({
      isSubmitEnabled: isNoEmptyFields && isNoErrors,
    });
  }

  handleFormChange() {
    this.setSubmitEnabled();
  }

  handleFormBlur(e) {
    const field = e.target.name;
    if (!field) return;

    // validation
    this.setState({
      errors: {
        ...this.state.errors,
        [field]: !this.validate(field, this.getElementValue(this.elements[field])),
      },
    }, () => {
      this.setSubmitEnabled();
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    // form値取得
    const params = {
      birth: this.elements[FIELDS.birth].value,
      genderCode: this.getElementValue(this.elements[FIELDS.genderCode]),
      postCode: this.elements[FIELDS.postCode].value,
    }
  }

  skip(e) {
    e.preventDefault();

  }

  render() {
    const { errors } = this.state;
    return (
      <div id="enquete_overlay">
        <h1>アンケートにご協力お願いします</h1>
        <div id="enquete_main">
          <form
            onChange={this.handleFormChange}
            onBlur={this.handleFormBlur}
            onSubmit={this.handleFormSubmit}
            ref={el => this.elements = el && el.elements}
          >
            <div id="enquete_form">
              <ul>
                <li>
                  <div className="enquete_form_title">生年月</div>
                  <div className="enquete_form_area">
                    <input type="text" defaultValue="" id="birth" name={FIELDS.birth} placeholder="198804" maxLength="6" />
                    <p className="enquete_form_text">
                      ※半角数字のみで入力してください。<br />例：1988年4月生まれの場合「198804」
                    </p>
                  </div>
                </li>
                <li>
                  <div className="enquete_form_title">性別</div>
                  <input type="radio" name={FIELDS.genderCode} value="1" id="radio01" defaultChecked />
                  <label htmlFor="radio01" className="radio">男性</label>
                  <input type="radio" name={FIELDS.genderCode} value="2" id="radio02" />
                  <label htmlFor="radio02" className="radio">女性</label>
                </li>
                <li>
                  <div className="enquete_form_title">郵便番号</div>
                  <div className="enquete_form_area">
                    <input type="text" defaultValue="" id="postal_code" name={FIELDS.postCode} placeholder="1970807" maxLength="7" />
                    <p className="enquete_form_text">
                      ※半角数字のみで入力してください。<br />例：〒197-0807の場合「1970807」
                    </p>
                  </div>
                </li>
                <li className="error_area">
                  {
                    this.state.errors[FIELDS.birth] &&
                    <div className="enquete_form_error">※生年月の入力が間違っています。</div>
                  }
                  {
                    this.state.errors[FIELDS.genderCode] &&
                    <div className="enquete_form_error">※性別を選んでください。</div>
                  }
                  {
                    this.state.errors[FIELDS.postCode] &&
                    <div className="enquete_form_error">※郵便番号の入力が間違っています。</div>
                  }
                </li>
              </ul>
            </div>
            <div id="enquete_btn">
              <button className="enquete_btn_later" onClick={this.skip.bind(this)}>あとで</button>
              <button type="submit" disabled={!this.state.isSubmitEnabled} className="enquete_btn_answer">同意して回答する</button>
            </div>
          </form>
        </div>
        <div id="enquete_footer">
          <h4>※ご回答内容の取扱いについては、<a href={this.dougaLink.EnquetePrivacy} target="_blank">プライバシーポリシー</a>をご確認ください。</h4>
        </div>
      </div>
    )
  }
}
