import React, { useState, useRef, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import styled, { createGlobalStyle, css } from 'styled-components'
import {
  META_SCHEMA_ID,
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE,
  LOCAL_STORAGE_KEY_DORAEMON_PAGER,
  LOCAL_STORAGE_KEY_DORAEMON_SORT,
  LOCAL_STORAGE_KEY_DORAEMON_FILTER,
  LOCAL_STORAGE_KEY_DORAEMON_EXFILTER,
  LOCAL_STORAGE_KEY_DORAEMON_YEAR
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  checkAvailableStatus,
  getThumbnailUrl,
  play,
  purchasePlan
} from '../EpisodeDefault/util'

/** hooks */
import useMediaQuery from '../../../../hooks/useMediaQuery'
import useSearchParams from '../../../../common/components/FilterSort/hooks/useSearchParams'

/** context */
import { EpisodeProvider } from './context/Provider'

/** components */
import Header from './Header'
import Meta from './Meta'
import Actions from './Actions/index'
import EpisodeFooter from '../../../../common/components/EpisodeFooter'
import EpisodeAppFooter from '../../../../common/components/appli/EpisodeAppFooter'
import RenewalPlayerPcModeOnly from '../../player/RenewalPlayerPcModeOnly'
import Notice from '../EpisodeDefault/Notice'
import CommonVideoArea from '../EpisodeDefault/CommonVideoArea'
import HtmlSnippet from '../../HtmlSnippet'
import FilterSort from '../../../../common/components/FilterSort'
import HeaderNewsComponent from '../HeaderNewsComponent'
import EpisodePager from '../details/EpisodePager'

/** style */
import { mediaQuery, breakPoints } from '../../style'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

const defaultProps = {
  seriesId: '',
  seasonId: '',
  episodeId: '',
  meta: {},
  programMeta: {},
  howToPlay: {},
  episodes: [],
  product: {},
  course: {},
  status: {
    isFree: false,
    isNotFree: false,
    isPurchased: false,
    isNotPurchased: false,
    isInCourse: false,
    isNotInCourse: false,
    isGeoDeliverable: null,
    isDeviceNotAvailable: null,
    limitDate: null,
    isPossible: null,
    isBelonging: null
  },
  sendPlayLog: () => {},
  loaded: true
}

/** 単話ページ */
const EpisodeDoraemon = (props = { ...defaultProps }, context) => {
  const {
    seriesId = defaultProps.seriesId,
    seasonId = defaultProps.seasonId,
    episodeId = defaultProps.episodeId,
    meta = defaultProps.meta,
    programMeta = defaultProps.programMeta,
    howToPlay = defaultProps.howToPlay,
    episodes = defaultProps.episodes,
    product = defaultProps.product,
    course = defaultProps.course,
    status = defaultProps.status,
    sendPlayLog = defaultProps.sendPlayLog,
    loaded = defaultProps.loaded
  } = props
  if (!size(meta) || !size(programMeta)) return null

  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const isSp = useMediaQuery()
  const isApp = webApp.utils.isApp(context)

  const [progress, setProgress] = useState(webApp.utils.progress(config, meta))
  const [exFilteredBy, setExFilteredBy] = useState({
    birthday:
      window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_EXFILTER) || ''
  })
  const [initialTag, setInitialTag] = useState(
    get(exFilteredBy, ['birthday']) === 'birthday' ? ['誕生日スペシャル'] : []
  )
  const [initialNotTag, setInitialNotTag] = useState(
    get(exFilteredBy, ['birthday']) === 'normal' ? ['誕生日スペシャル'] : []
  )
  const isPlayedAuto = useRef(false)
  const playerRef = useRef(null)
  const setPlayerRef = (e) => {
    playerRef.current = e
  }

  /**
   * ページャー、ソート、フィルタをlocalStorageから取得
   */
  const dataFromLocalStorage = (() => {
    // ローカルストレージの値を取得
    const pageNum =
      parseInt(
        window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_PAGER),
        10
      ) || 1
    const sortedBy =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_SORT) ||
      'avails_release_history_original_older'
    const filteredBy =
      window.localStorage.getItem(LOCAL_STORAGE_KEY_DORAEMON_FILTER) || ''
    const storedYear = window.localStorage.getItem(
      LOCAL_STORAGE_KEY_DORAEMON_YEAR
    )
    const selectedYear = storedYear && storedYear !== 'null' ? storedYear : null

    return { pageNum, sortedBy, filteredBy, selectedYear }
  })()

  const searchParams = useSearchParams(
    {
      childEpisodeIds: get(programMeta, ['values', 'child_episode_ids'], []),
      seasonIds: [Number(seasonId)],
      ...dataFromLocalStorage,
      tags: initialTag || [],
      onlyWatchedAll: true,
      notTags: initialNotTag || [],
      pagerOptions: {
        episodesPerPages: 40,
        range: 2,
        showBottom: true
      }
    },
    {
      afterSearch: ({
        pageNum = 1,
        sortedBy = 'avails_release_history_original_newer',
        filteredBy = '',
        selectedYear = null
      }) => {
        const ls = window.localStorage
        ls.setItem(LOCAL_STORAGE_KEY_DORAEMON_SORT, sortedBy)
        ls.setItem(LOCAL_STORAGE_KEY_DORAEMON_FILTER, filteredBy)
        ls.setItem(LOCAL_STORAGE_KEY_DORAEMON_PAGER, pageNum)
        selectedYear != 'null'
          ? ls.setItem(LOCAL_STORAGE_KEY_DORAEMON_YEAR, selectedYear)
          : ls.removeItem(LOCAL_STORAGE_KEY_DORAEMON_YEAR)
      }
    }
  )

  const exFilterProps = [
    // 誕生日スペシャルフィルタ機能
    {
      filteredBy: get(exFilteredBy, ['birthday'], ''),
      updateFilteredBy: (newFilteredBy) => {
        const tags = newFilteredBy === 'birthday' ? ['誕生日スペシャル'] : []
        const notTags = newFilteredBy === 'normal' ? ['誕生日スペシャル'] : []
        searchParams.set({
          tags,
          notTags
        })
        window.localStorage.setItem(
          LOCAL_STORAGE_KEY_DORAEMON_EXFILTER,
          newFilteredBy
        )
        setExFilteredBy({ birthday: newFilteredBy })
        setInitialTag(tags)
        setInitialNotTag(notTags)
      },
      options: [
        { id: 1, value: '', label: 'すべて' },
        { id: 2, value: 'normal', label: '通常回' },
        { id: 3, value: 'birthday', label: '誕生日スペシャル' }
      ],
      initialValue: 'おはなしの種類',
      slug: 'birthday'
    }
  ]

  /** ドラえもん視聴ページ USERGRAMタグ追加 */
  const addUsergramTag = () => {
    if (isLoggedIn === false) return

    let member_id = ''
    const cookies = document.cookie
    const cookiesArr = cookies.split(';')

    cookiesArr.forEach((cookie) => {
      const cookieArr = cookie.split('=')
      if (cookieArr[0] === ' CBM_ID') {
        member_id = cookieArr[1]
      }
    })

    const browseUserGram = `
<script id="usergram-member-doraemonTV-episode${isApp ? '-app' : ''}">
window.ugattr = window.ugattr || {};
ugattr['serviceId'] = '${member_id}';
ugattr['prop07'] = '${isApp ? '【アプリ】' : ''}ドラえもん';
ugattr['prop09'] = '';
ugattr['prop10'] = '';
</script>

<script id="usergram-common-doraemonTV-episode${isApp ? '-app' : ''}">
(function(){var a=window,b=document,c=a.usergram=a.usergram||[],d,e;
c.l||(c.s=(new Date()).getTime(),c.l=!0,d=b.getElementsByTagName('script')[0],
e=b.createElement('script'),e.type='text/javascript',e.async=true,
e.src='//code.usergram.info/js/usergram.js',d.parentNode.insertBefore(e,d))})();
window.usergram=window.usergram||[],window.ugattr=window.ugattr||{};
usergram.push(['send','Ug37cn-1','cv','doraemonTV_episode${
      isApp ? '_app' : ''
    }',ugattr]);
</script>
`
    webApp.utils.appendUserGram(browseUserGram)
  }

  const onClickPlay = (e, asSvod = false) => {
    if (e) e.preventDefault()

    // SVOD配信パターン
    const svodDeliveryPattern = get(meta, ['values', 'svod_delivery_pattern'])

    // PLAYかPURCHASE_PLANの状態しか返ってこない。
    const action = checkAvailableStatus({ status, product }, context, asSvod)

    // 購入済みかSVOD配信パターンが無料視聴可能の場合、再生
    if (action === 'PLAY' || svodDeliveryPattern === '1') {
      play(playerRef.current, sendPlayLog)
      return
    }

    // 未購入の場合、ログイン状態を確認
    if (action === 'PURCHASE_PLAN') {
      /**
       * 未ログイン状態の場合はログイン画面へ遷移する
       * SVOD配信パターンが無料視聴可能のときはスルーする
       */
      if (!isLoggedIn) {
        context.history.push(
          routes.login.makePath(null, {
            redirect: context.routeHandler.path
          })
        )
        return
      }

      purchasePlan({ course }, context, 'doraemon')
    }
  }

  const onClosePlayer = () => {
    setProgress(webApp.utils.progress(config, meta))
  }

  useEffect(() => {
    /** アプリ用のclassを消去 */
    const wrapperElm = document.querySelector('.bd.lang_ja.appli')
    if (wrapperElm) wrapperElm.classList.remove('appli')

    /** usergramタグの挿入 */
    addUsergramTag()

    return () => {
      document.body.style.overflow = null
      /** アプリ用のclassを戻す */
      if (wrapperElm) wrapperElm.classList.add('appli')

      /** usergramタグの削除 */
      const usergramIds = [
        `usergram-member-doraemonTV-episode${isApp ? '-app' : ''}`,
        `usergram-common-doraemonTV-episode${isApp ? '-app' : ''}`
      ]
      if (isLoggedIn) {
        webApp.utils.removeUserGram(usergramIds)
      }
      webApp.utils.returnDefaultWebClip()
    }
  }, [])

  useEffect(() => {
    if (isPlayedAuto.current) return
    const isAutoPlay = context.routeHandler.query.auto === 't'
    if (loaded && status && !isApp && isAutoPlay) {
      const isFree = EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode)
      const isAuthFree =
        EPISODE_DISPLAY_MODE.SVOD_AUTH_FREE === status.displayMode &&
        webApp.utils.isLoggedIn(context)
      const withValidPreviewToken = get(context, [
        'models',
        'state',
        'data',
        'withValidPreviewToken'
      ])
      if (isFree || isAuthFree || status.isPurchased || withValidPreviewToken) {
        onClickPlay()
        isPlayedAuto.current = true
      }
    }
  }, [loaded, status])

  const metaName = (() => {
    const [title, subTitle] = webApp.utils.titles(meta)
    return `${title} ${subTitle}`
  })()

  /** シーズンIDでサマステ判別 */
  const summerStationId = ['development', 'staging'].includes(
    process.env.NODE_ENV
  )
    ? 15401
    : 49361
  const isSummerStation = Number(seasonId) === summerStationId

  const episodeBannerId1 = get(meta, ['values', 'banner_1'])
  // const episodeBannerId2 = get(meta, ['values', 'banner_2'])
  const programBannerId1 = get(programMeta, ['values', 'banner_1'])
  // const programBannerId2 = get(programMeta, ['values', 'banner_2'])

  /** サマステのシーズンIDに合致する場合、エピソードに紐付くbanner_1を使用する */
  const innerBanner1 = isSummerStation ? episodeBannerId1 : programBannerId1

  const selectableYears = get(
    programMeta,
    ['values', 'season_front_display_config', 'selectableYears'],
    null
  )
  const isLive =
    meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
    meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
  let isClipVod = false

  // LIVE配信 主/副切替ID
  let metaIdForPlayer
  if (isLive) {
    if (meta.values.clipping_select_media_type === 'vod') {
      metaIdForPlayer = meta.values.clipping_vod_ref_id
      isClipVod = true
    }
    if (!metaIdForPlayer) metaIdForPlayer = meta.values.target_ref_id
  }
  if (!metaIdForPlayer)
    metaIdForPlayer = `${config.videocloud.reference_id_prefix || ''}${
      meta.meta_id
    }`
  const playerSettings = webApp.utils.getPlayerSettings(
    config,
    meta,
    status.displayMode
  )

  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])
  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  const isBefore = Boolean(
    !withValidPreviewToken && moment(prevTime).isBefore(meta.delivery_start_at)
  )
  const isAfter = Boolean(
    !withValidPreviewToken && moment(prevTime).isAfter(meta.delivery_end_at)
  )
  const thumbnailUrl = getThumbnailUrl(
    { meta, programMeta },
    context,
    config,
    previewUrlList,
    cookieRubocopPrefix
  )
  const inViewTerm = !(isBefore || isAfter)
  const nextPrevText = (() => {
    if (inViewTerm || isLive) return null
    if (isBefore) return 'まもなく配信予定'
    return '次回の配信もお楽しみに！'
  })()
  const isPlayerRenderable =
    metaIdForPlayer &&
    status.displayMode &&
    (inViewTerm || withValidPreviewToken)

  const Footer = (footerProps) =>
    isApp ? (
      <EpisodeAppFooter {...footerProps} />
    ) : (
      <EpisodeFooter {...footerProps} />
    )

  return (
    <EpisodeProvider value={props}>
      <Helmet>
        <link
          rel="icon"
          href="/images/exdio/renewal/doraemon/dora_favicon.webp"
        />
        <link
          rel="apple-touch-icon"
          href="/images/exdio/renewal/doraemon/favicon.webp"
        />
      </Helmet>
      <GridContainer>
        <StyledHeader />
        <StyledHeaderNewsComponent />
        <StyledNotice programMeta={programMeta} />

        <StyledDiv1>
          <StyledDiv1Inner>
            <StyledVideoWrapper>
              {isPlayerRenderable && (
                <RenewalPlayerPcModeOnly
                  ref={(e) => setPlayerRef(e)}
                  meta_id={metaIdForPlayer}
                  thumbnail_url={meta.thumbnail_url}
                  subtitle={!!meta.values.subtitle}
                  enqueteEnabled={status.isFree}
                  material_id={get(product, ['ref_id']) || ''}
                  license_id={get(course, ['ref_id']) || ''}
                  display_mode={status.displayMode}
                  onClose={onClosePlayer}
                  isLive={isLive}
                  isClipVod={isClipVod}
                  {...playerSettings}
                />
              )}

              <CommonVideoArea
                progress={progress}
                onClickPlay={onClickPlay}
                isLive={isLive}
                inViewTerm={inViewTerm}
                metaName={metaName}
                thumbnailUrl={thumbnailUrl}
                nextPrevText={nextPrevText}
                meta={meta}
                status={status}
                product={product}
                episodeId={episodeId}
                seriesId={seriesId}
                seasonId={seasonId}
              />
            </StyledVideoWrapper>

            {!isSp && (
              <StyledEpisodePager
                meta={meta}
                seriesId={seriesId}
                seasonId={seasonId}
                episodes={episodes}
                course={course}
              />
            )}
          </StyledDiv1Inner>

          <StyledMeta isLive={isLive} metaName={metaName} />

          <StyledActions
            onClickPlay={onClickPlay}
            inViewTerm={inViewTerm}
            renderLinkLoginOrMyPage
            metaName={metaName}
            isSummerStation={isSummerStation}
            programBannerId1={programBannerId1}
          />

          {isSp && (
            <StyledEpisodePager
              meta={meta}
              seriesId={seriesId}
              seasonId={seasonId}
              episodes={episodes}
              course={course}
            />
          )}
        </StyledDiv1>

        {innerBanner1 && <StyledHtmlSnippet snippetId={innerBanner1} />}

        {size(programMeta) > 0 && (
          <StyledSection>
            <StyledH3>すべてのおはなし</StyledH3>
            <StyledFilterSort
              searchParams={searchParams}
              episodeListItemProps={{
                showNew: true,
                showChecked: true,
                showCoin: true,
                showCaption: true,
                onlySubTitle: true,
                images: {
                  on: '/images/exdio/renewal/doraemon/watched.png',
                  off: '/images/exdio/renewal/doraemon/not_watched.png'
                }
              }}
              selectableYears={selectableYears}
              exFilterProps={exFilterProps}
              hasBalloon
              hasFavorite
            />
          </StyledSection>
        )}
      </GridContainer>

      <Footer
        className="mt0"
        copyrights={get(meta, ['values', 'evis_Copyright'])}
        courseId={
          howToPlay && howToPlay.courses
            ? get(howToPlay, ['courses', 0, 'course_id'])
            : null
        }
        showLogo
      />
      <GlobalStyle isApp={isApp} />
    </EpisodeProvider>
  )
}

export default EpisodeDoraemon

EpisodeDoraemon.propTypes = {
  /** 動画のシリーズID */
  seriesId: PropTypes.string,
  /** 動画のシーズンID */
  seasonId: PropTypes.string,
  /** 動画のエピソードID */
  episodeId: PropTypes.string,

  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** シーズンのメタ情報 */
  programMeta: PropTypes.object,
  /** 動画の視聴権利関連情報 */
  howToPlay: PropTypes.object,
  /** 動画の価格、配信期間情報など */
  product: PropTypes.shape({
    product_id: PropTypes.number,
    name: PropTypes.string,
    original_price: PropTypes.number,
    active_pricing: PropTypes.shape({
      price: PropTypes.number,
      unit: PropTypes.string
    })
  }),

  // 通常単話
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object),
  // 月額見放題
  /** 動画のコース情報 */
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.object,
    values: PropTypes.object
  }),

  /** 動画の視聴ステータス情報 */
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }),
  /** 動画再生時のログ送信 */
  sendPlayLog: PropTypes.func,
  /** データの取得が終わっているか */
  loaded: PropTypes.bool
}

EpisodeDoraemon.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}

const GridContainer = styled.div`
  padding-top: 30px;
  display: grid;
  grid-template-columns:
    minmax(72px, auto)
    minmax(auto, 1280px)
    minmax(72px, auto);
  row-gap: 30px;

  ${mediaQuery()} {
    padding-top: 10px;
    grid-template-columns: 15px auto 15px;
    row-gap: 10px;
  }
`

const StyledHeader = styled(Header)`
  grid-column: 2;
`

const StyledNotice = styled(Notice)`
  grid-column: 2;
  margin: 0;
  max-width: auto;
`

const StyledDiv1 = styled.div`
  grid-column: 2;
  padding-bottom: 35px;
  display: grid;
  grid:
    'StyledDiv1Inner ... StyledMeta    '
    'StyledDiv1Inner ... ...           ' 26px
    'StyledDiv1Inner ... StyledActions '
    / minmax(auto, 850px) 50px 380px;

  ${mediaQuery()} {
    padding-bottom: 5px;
    grid:
      'StyledDiv1Inner'
      '...' 10px
      'StyledMeta'
      '...' 20px
      'StyledActions'
      '...' 10px
      'StyledEpisodePager'
      / auto;
  }
`

const StyledDiv1Inner = styled.div`
  grid-area: StyledDiv1Inner;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

const StyledVideoWrapper = styled.div`
  position: relative;

  .common-video-area {
    margin-top: 0;
  }
`

const StyledEpisodePager = styled(EpisodePager)`
  grid-area: StyledEpisodePager;
  margin: 0;
  max-width: none;
  display: flex;
  justify-content: center;

  .c-storyPager-inBox {
    max-width: 460px;
    width: 100%;
  }
`

const StyledMeta = styled(Meta)`
  grid-area: StyledMeta;
`

const StyledActions = styled(Actions)`
  grid-area: StyledActions;
`

const StyledHtmlSnippet = styled(HtmlSnippet)`
  grid-column: 1 / 4;
`

const StyledSection = styled.div`
  grid-column: 2;
  padding: 30px 0 60px;
  display: grid;
  grid:
    'StyledH3'
    'StyledFilterSort'
    / minmax(auto, 1280px);
  row-gap: 20px;

  ${mediaQuery()} {
    padding: 20px 0 30px;
    grid:
      'StyledH3'
      'StyledFilterSort';
    row-gap: 10px;
  }
`

const StyledH3 = styled.h3`
  grid-area: StyledH3;
  font-size: 2.4rem;
  font-weight: bold;
`

const StyledFilterSort = styled(FilterSort)`
  grid-area: StyledFilterSort;
  padding: 0;

  & > ul {
    ${mediaQuery('sm', 'min')} {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 768px) and (max-width: ${breakPoints.sm}px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`

const StyledHeaderNewsComponent = styled(HeaderNewsComponent)`
  grid-column: 2;
  margin: 0;
  padding: 0;
  max-width: none;
  display: grid;
`

const GlobalStyle = createGlobalStyle`
  html, body {
    height: initial;
  }

  /** スニペット用 */
  .u-hide--browser {
    display: none !important;
  }

  ${({ isApp }) => {
    if (isApp) {
      return css`
        .c-gHead {
          display: none !important;
        }

        .c-storyPager {
          margin: 0;
          max-width: none;
        }

        .common-footer {
          .inner {
            padding-bottom: 48px;
          }
        }
      `
    }

    return null
  }}
`
