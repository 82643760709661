import styled from 'styled-components'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import { pink } from '../../styles'
import { mediaQuery, breakPoints } from '../../../../style'

const rankColor = '#d7ca8a' // ランク文字色
const rankColorHover = '#d9b300' // 欄っく文字色(ホバー時)

/**
 * // HACK
 * ブレークポイントについて
 * `Section9`にて、`List`, `Slider`の出し分けを
 * `breakPoints.mm`で行っているため、それに合わせている
 * https://media-plex.backlog.jp/view/DGA2-1702#comment-1402299961
 */

/**
 * ランク文字
 * 画面幅に関わらずタッチ端末のときはランク文字をホバー色にする
 * https://media-plex.backlog.jp/view/DGA2-1702#comment-1402570870
 */
export const StyledSpan = styled.span`
  display: block;
  color: ${({ isTouchable }) => (isTouchable ? rankColorHover : rankColor)};
  font-size: 10rem;
  font-weight: 900;
  line-height: 1;
  transition: color 0.4s;

  ${mediaQuery(breakPoints.mm)} {
    position: absolute;
    top: 0;
    right: 100%;
    font-size: 8rem;
  }
`

export const StyledDiv2 = styled.div`
  flex-basis: 100%;
  position: relative;
  transition: transform 0.4s;

  &::after {
    width: 100%;
    height: 100%;
    background-image: radial-gradient(#eaea02 33%, rgba(255, 255, 255, 0) 38%),
      radial-gradient(#eaea02 33%, rgba(255, 255, 255, 0) 38%);
    background-position: 0 0, 5px 5px;
    background-size: 10px 10px;
    border-radius: 12px;
    position: absolute;
    right: -7px;
    bottom: -7px;
    z-index: 0;
    content: '';

    ${mediaQuery(breakPoints.mm)} {
      content: none;
    }
  }
`

export const StyledDiv1 = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    ${StyledSpan} {
      ${mediaQuery(breakPoints.sm + 1, 'min')} {
        color: ${rankColorHover};
      }
    }

    ${StyledDiv2} {
      ${mediaQuery(breakPoints.sm + 1, 'min')} {
        transform: translateY(-5px);
      }
    }
  }
`

export const StyledLink = styled(Link)`
  padding: 16px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  gap: 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  ${mediaQuery(breakPoints.mm)} {
    padding: 0;
    border-radius: 8px;
    flex-direction: column;
    gap: 0;
  }
`

export const StyledDiv3 = styled.div`
  width: 256px;
  height: 141px;
  position: relative;

  ${mediaQuery(breakPoints.mm)} {
    width: auto;
    height: auto;
  }

  &::before {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(0, 0, 0, 0.1) 50%,
      rgba(0, 0, 0, 0.6) 100%
    );
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: '';
  }

  img {
    width: 100%;
  }

  span {
    position: absolute;
    bottom: 6px;
    right: 6px;
    color: #fff;
    z-index: 1;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.3;
  }
`

export const StyledDl = styled.dl`
  flex-basis: calc(100% - 256px);

  ${mediaQuery(breakPoints.mm)} {
    padding: 5px 10px 10px;
  }
`

export const StyledDt = styled.dt`
  font-size: 1.6rem;
  font-weight: 900;

  ${mediaQuery(breakPoints.mm)} {
    font-size: 1.3rem;
  }
`

export const StyledDd = styled.dd`
  margin-top: 15px;

  ${mediaQuery(breakPoints.mm)} {
    margin-top: 10px;
  }
`

export const StyledTime = styled.time`
  font-size: 1.3rem;
  line-height: 1;

  ${mediaQuery(breakPoints.mm)} {
    font-size: 1.1rem;
  }
`

export const StyledUl2 = styled.ul`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  ${mediaQuery(breakPoints.mm)} {
    margin-top: 10px;
  }
`

export const StyledLi2 = styled.li`
  padding: 5px 15px;
  border: 2px solid ${pink};
  border-radius: 3px;
  color: ${pink};
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1;
`
