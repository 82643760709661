import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import AboutMedalLink from '../../../../common/components/renewal/AboutMedalLink'
import routes from '../../../../common/routes'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import webApp from '../../../utils/exdioWebAppUtils'
import PackItem from './PackItem'

/** 詳細画面:お得なパックコンポーネント */
export default class PackList extends Component {
  static propTypes = {
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        }),
        original_price: PropTypes.number
      })
    ),
    showMedal: PropTypes.bool
  }

  static defaultProps = {
    products: [],
    showMedal: false
  }

  static contextTypes = {
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      isExpanded: false
    }

    this.onClickMore = this.onClickMore.bind(this)
  }

  onClickMore(e) {
    e.preventDefault()
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  render() {
    const { products, showMedal, showDiscount } = this.props
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!products || !products.length) return null
    // 価格設定がされているものに絞る
    // ２回ループ回してしまうけど、render時に生成しないとLinkコンポーネントのstateがnullになってエラーになるので
    // ここではフィルタリングのみにする
    let show_products = []
    products.forEach((product) => {
      const price = _.get(product, ['active_pricing', 'price'])
      if (price != null) {
        show_products.push(product)
      }
    })
    if (show_products.length <= 0) {
      return null
    }

    show_products.sort((a, b) => {
      if (a.publish_start_at < b.publish_start_at) return 1
      if (a.publish_start_at > b.publish_start_at) return -1

      if (Number(a.product_id) < Number(b.product_id)) return 1
      if (Number(a.product_id) > Number(b.product_id)) return -1

      return 0
    })

    const { isExpanded } = this.state
    const route = webApp.utils.isApp(this.context)
      ? routes.app_pack
      : routes.pack
    //const moreBtnThreshold = webApp.utils.isApp(this.context) ? 2 : 4;
    const moreBtnThreshold = window.matchMedia('screen and (min-width: 1024px)')
      .matches
      ? 4
      : 2
    const showMoreBtn = products && products.length > moreBtnThreshold

    const showMoreBtnStyle = showMoreBtn
      ? {
          maxHeight: window.matchMedia('screen and (min-width: 1024px)').matches
            ? '110px'
            : '120px'
        }
      : null

    return (
      <div className="c-listPack">
        <div className="c-listPack-inBox">
          <header className="c-listPack-inBox-head">
            <h3 className="c-listPack-inBox-head-title">お得なパック</h3>
            {showMedal && (
              <AboutMedalLink linkClassName="c-listPack-inBox-head-link" />
            )}
          </header>
          <div
            className={`c-listPack-inBox-cont ${isExpanded ? 'open' : ''}`}
            style={isExpanded ? null : showMoreBtnStyle}
          >
            {/* {show_products.map(product => {
              const discount = webApp.utils.calcPackDiscount(product);
              const price = _.get(product, ['active_pricing', 'price']);
              return (
                <div key={product.product_id} className="c-pageBtn">
                  <Link route={route} params={{ productId: product.product_id }} className="c-pageBtn-link">
                    <div className="c-pageBtn-link-packName">{product.name}</div>
                    {showMedal && [
                      showDiscount != false && discount > 0 && (
                        <div key="discount" className="c-pageBtn-link-discountRate">
                          {discount}%<span className="fontBold">お得</span>
                        </div>
                      ),
                      <div key="price" className="c-pageBtn-link-price">
                        <span>{price}</span>
                      </div>
                    ]}
                  </Link>
                </div>
              );
            })} */}

            {show_products.map((product) => {
              const discount = webApp.utils.calcPackDiscount(product)
              const price = _.get(product, ['active_pricing', 'price'])
              return (
                <PackItem
                  key={product.product_id}
                  product={product}
                  discount={discount}
                  price={price}
                  showMedal={showMedal}
                  showDiscount={showDiscount}
                />
              )
            })}
          </div>
          {showMoreBtn && (
            <div className="c-more">
              <a href="#" className="c-more-btn" onClick={this.onClickMore}>
                <span
                  className={`c-more-btn-txt ${isExpanded ? 'open' : ''}`}
                />
              </a>
            </div>
          )}
        </div>
      </div>
    )
  }
}
