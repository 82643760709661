import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../../../utils/exdioWebAppUtils'
import routes from '../../../../../../../common/routes'

/* style */
import {
  StyledUl,
  StyledLi,
  StyledLink,
  StyledConvertSvg,
  StyledSpan
} from './style'

/** Logirlプランページ | ヘッダーナビゲーション */
const Nav1 = ({ closeMenu, ...props }) => {
  const [isSp, setIsSp] = useState(webApp.utils.isSp())
  const [isMounted, setIsMounted] = useState(false)

  const navItem = [
    {
      disabled: false,
      route: routes.logirl_live,
      params: {},
      href: '/logirl/live',
      target: '',
      src: '/images/exdio/renewal/logirl/icon/live.svg',
      alt: '',
      text: 'LIVE'
    },
    {
      disabled: false,
      route: {},
      params: {},
      href: '/plan/logirl#program',
      target: '',
      src: '/images/exdio/renewal/logirl/icon/program.svg',
      alt: '',
      text: '番組'
    },
    {
      disabled: false,
      route: {},
      params: {},
      href: 'https://www.tv-asahi.co.jp/reading/logirl/category/daily-logirl/',
      target: '',
      src: '/images/exdio/renewal/logirl/icon/daily.svg',
      alt: '',
      text: 'デイリー'
    },
    {
      disabled: false,
      route: routes.logirl_static,
      params: { url: 'gravure' },
      href: '/logirl/gravure',
      target: '',
      src: '/images/exdio/renewal/logirl/icon/gravure.svg',
      alt: '',
      text: 'グラビア'
    },
    {
      disabled: false,
      route: {},
      params: {},
      href: '/plan/logirl#blog',
      target: '',
      src: '/images/exdio/renewal/logirl/icon/note.svg',
      alt: '',
      text: '記事'
    },
    {
      disabled: false,
      route: routes.logirl_static,
      params: { url: 'radio' },
      href: '/logirl/radio',
      target: '',
      src: '/images/exdio/renewal/logirl/icon/radio.svg',
      alt: '',
      text: 'ラジオ'
    },
    {
      disabled: false,
      route: {},
      params: {},
      href: 'https://www.youtube.com/channel/UCXLxGE7dE74Lui4xPuUSrMA',
      target: '_blank',
      src: '/images/exdio/renewal/logirl/icon/youtube1.svg',
      alt: '',
      text: 'YouTube'
    }
  ]

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    // PC/SPの判定が変わったときだけ実行する
    if (isSp === webApp.utils.isSp()) return

    // state変更中は実行しない
    if (!isMounted) return

    isMounted = false

    setIsSp(webApp.utils.isSp())
    setIsMounted(true)
  }, [])

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])

  const onClickHandler = (e, href) => {
    if (typeof window == 'undefined') return false

    const pathname = window.location.pathname

    if (href && href.match(pathname) && href.indexOf('#') > -1) {
      e.preventDefault()

      if (isSp) {
        // SPのときメニューを閉じる
        closeMenu()
      }

      // ページ内リンクを行う
      const hash = href.match(/#\w+$/)
      const targetId = hash[0].replace('#', '')
      const target = document.getElementById(targetId)
      const targetPosTop = target.offsetTop
      window.scrollTo({
        top: targetPosTop,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  const isCurrent = (href) => {
    if (typeof window == 'undefined') return false
    return href === window.location.pathname
  }

  return (
    <nav {...props}>
      <StyledUl>
        {navItem.length > 0 &&
          navItem.map((item, i) => {
            return (
              <StyledLi key={i}>
                <StyledLink
                  route={item.route}
                  params={item.params}
                  href={item.href}
                  target={item.target}
                  isDisabled={item.disabled}
                  isCurrent={isCurrent(item.href)}
                  onClick={(e) => onClickHandler(e, item.href)}
                >
                  <StyledConvertSvg src={item.src} alt={item.alt} />
                  <StyledSpan>{item.text}</StyledSpan>
                </StyledLink>
              </StyledLi>
            )
          })}
      </StyledUl>
    </nav>
  )
}

export default Nav1

Nav1.propTypes = {
  className: PropTypes.string
}

Nav1.defaultProps = {
  className: ''
}
