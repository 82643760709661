import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ProgramItem from '../../../../common/components/renewal/ProgramItem';
import webApp from '../../../utils/exdioWebAppUtils';
import { ADVERTISING_SCHEMA_ID, META_SCHEMA_ID, PALETTE_SCHEMA_ID } from '../../../../../constants/app';
import window from "global";

/** マイページ:未ログイン */
export default class NoLogin extends Component {
  static contextTypes = {
    models: PropTypes.object,
    falcorModel: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.config = context.models.config.data;
    this.state = {
      isFree: true,
      paletteFree: null,
      paletteNotFree: null,
      products: null,
      courses: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getPalette()
      .then(() => this.getProductsAndCourses())
      .catch(e => console.error(e));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /** パレット情報詳細取得 */
  getPalette() {
    const paletteKeyFree = this.config.my_page.bottom_palette_key.free;
    const paletteKeyNotFree = this.config.my_page.bottom_palette_key.not_free;
    const path = [['paletteByKey', [paletteKeyFree, paletteKeyNotFree]]];
    return this.model.fetch(path).then(result => {
      if (this._isMounted) {
        this.setState({
          paletteFree: _.get(result, ['json', 'paletteByKey', paletteKeyFree]) || {},
          paletteNotFree: _.get(result, ['json', 'paletteByKey', paletteKeyNotFree]) || {}
        });
      }
    });
  }

  /** 商品・コース情取得報 */
  getProductsAndCourses() {
    const { paletteFree, paletteNotFree } = this.state;
    const palettes = [paletteFree, paletteNotFree].filter(p => !!Object.keys(p).length);
    if (!palettes.length) return Promise.resolve();

    const advertisings = palettes
      .filter(p => ![PALETTE_SCHEMA_ID.IMAGES, PALETTE_SCHEMA_ID.KEYWORDS].includes(p.schema_id))
      .flatMap(p => p.objects)
      .filter(obj => obj.type === 'advertising')
      .map(obj => obj.advertising);

    const productIds = advertisings
      .filter(ad => ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT || ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT_LIVE)
      .map(h => h.values.product)
      .filter(v => v);
    const courseIds = advertisings
      .filter(ad => ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE || ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE_LIVE)
      .map(h => h.values.course)
      .filter(v => v);

    const path = [['product', productIds], ['course', courseIds]];
    return this.model.fetch(path).then(result => {
      const products = _.get(result, ['json', 'product']) || {};
      const courses = _.get(result, ['json', 'course']) || {};
      this.setState({ products, courses });
    });
  }

  onClickTab(isFree, e) {
    e.preventDefault();
    this.setState({ isFree });
  }

  render() {
    const { isFree, paletteFree, paletteNotFree, products, courses } = this.state;
    const palette = isFree ? paletteFree : paletteNotFree;

    return [
      <div key="message" className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">ログイン後に表示されます</div>
      </div>,
      <div key="program-list" className="c-card-vertical mypage" style={{ marginBottom: '4.8rem' }}>
        <header className="c-card-vertical-head">
          <h2>{paletteFree && paletteFree.name}</h2>
          <nav className="c-mypage-head-nav">
            <ul className="c-mypage-head-nav-inBox">
              <li className={`c-mypage-head-nav-inBox-btn ${isFree ? 'current' : ''}`}>
                <a href="#" className="c-mypage-head-nav-inBox-btn-link" onClick={e => this.onClickTab(true, e)}>
                  無料
                </a>
              </li>
              <li className={`c-mypage-head-nav-inBox-btn ${isFree ? '' : 'current'}`}>
                <a href="#" className="c-mypage-head-nav-inBox-btn-link" onClick={e => this.onClickTab(false, e)}>
                  有料
                </a>
              </li>
            </ul>
          </nav>
        </header>
        <div className="c-card-vertical-cont">
          {palette && (palette.objects || []).map(object => {
            const paletteAd = _.get(object, 'advertising');
            if (paletteAd && paletteAd.schema_id === ADVERTISING_SCHEMA_ID.DEFAULT) {
              const thumbnail =
                _.get(paletteAd, ['creatives', 0, 'attachment', 'file_url']) ||
                this.context.models.config.data.default_thumbnail;
              return (
                <ProgramItem
                  key={`${object.type}-${object.id}`}
                  title={paletteAd.name}
                  thumbnail={thumbnail}
                  onClick={() => {
                    if (paletteAd.url) window.location.href = paletteAd.url;
                  }}
                />
              );
            }

            const meta = object.meta || null;
            const productId = _.get(object, ['advertising', 'values', 'product']) || null;
            const courseId = _.get(object, ['advertising', 'values', 'course']) || null;
            const product = productId && products && products[productId];
            const course = courseId && courses && courses[courseId];
            return (
              <ProgramItem
                key={`${object.type}-${object.id}`}
                meta={meta}
                product={product}
                course={course}
                showCaption
                showNew={webApp.utils.showNew(meta)}
                onClickThumbnail={() => webApp.utils.goToProgramLink(this.context, meta, product, course)}
                onClickCaption={() => webApp.utils.goToProgramLink(this.context, meta, product, course, { autoPlay: false })}
              />
            );
          })}
        </div>
      </div>
    ];
  }
}
