/* style */
import styled from 'styled-components'

export const StyledSelect = styled.select`
  appearance: none;
  color: #fff;
  display: block;
  width: 150px;
  padding: 7px 30px 7px 10px;
  font-size: 1.6rem;
  transform: scale(0.9);
  line-height: 1.1em;
  border-radius: 5px;
  border: none;
  background: #6c9cc8
    url('https://wws.tv-asahi.co.jp/douga_mv/minato/images/arrow.png') no-repeat
    right 10px center/ 12px 8px;
  margin: 25px auto 20px;
`

export const StyledDiv1 = styled.div`
  margin: 20px auto;
`

export const StyledUl1 = styled.ul`
  margin: 0 -4px;
  overflow: hidden;
`
