import React, { useEffect, useRef, memo } from 'react'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import routes from '../../../../../../common/routes'

/* style */
import { StyledDivInner } from '../../style'
import styled from 'styled-components'
import { mediaQuery, pxToVw } from '../../../../../../exdio/components/style'

const MainTitle = () => {
  const ref = useRef(null)
  let bgPosX = 0
  let bgPosY = 0

  useEffect(() => {
    const timerId = setInterval(() => {
      bgPosX += 0
      bgPosY += -0.5
      ref.current.style.backgroundPosition = `${bgPosX}px ${bgPosY}px`
    }, 10)

    return () => clearInterval(timerId)
  }, [])

  return (
    <StyledDiv>
      <StyledDivInner>
        <Link route={routes.home}>
          <img
            src="/images/exdio/renewal/logo_tv_asahi_douga.png"
            alt="テレ朝動画ロゴ"
            width="150"
            height="30"
            loading="lazy"
          />
        </Link>
        <h1>
          <StyledSpan1 ref={ref}>
            <img
              src="/images/exdio/renewal/hissatsu/logo_text.png"
              alt="必殺仕事人"
              loading="lazy"
            />
          </StyledSpan1>
          <StyledSpan2>
            テレ朝動画では
            <br />
            必殺仕事人シリーズを
            <br />
            最新話を含めて配信中！
          </StyledSpan2>
        </h1>
      </StyledDivInner>
    </StyledDiv>
  )
}

export default memo(MainTitle)

const StyledDiv = styled.div`
  padding: 30px 0 50px;
  background: url(/images/exdio/renewal/hissatsu/main_bg.png) no-repeat top
    center / cover;

  ${mediaQuery(768)} {
    background-image: url(/images/exdio/renewal/hissatsu/main_bg_sp.png);
  }
`

const StyledSpan1 = styled.span`
  display: block;
  text-align: center;
  background-image: url(/images/exdio/renewal/hissatsu/logo_bg.jpg);
  width: 447px;
  margin: 0 auto;

  img {
    width: 100%;
    vertical-align: bottom;
  }

  ${mediaQuery(768)} {
    margin-top: 30px;
    width: min(${pxToVw(416, 750)}, 416px);
  }
`

const StyledSpan2 = styled.span`
  display: block;
  margin-top: 50px;
  text-align: center;
  font-size: 3.7rem;
  color: #fff;

  br {
    display: none;
  }

  ${mediaQuery(768)} {
    margin-top: 18px;
    font-size: ${pxToVw(44, 750)};
    line-height: 1.3;

    br {
      display: block;
    }
  }
`
