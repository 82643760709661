import styled, { createGlobalStyle } from 'styled-components'
import { breakPoints, mediaQuery } from '../../style'
import { Link } from '../../../../../sketch-platform/ui/routing'
import Notice from './Notice'
import Caption from '../details/Caption'
import TagList from './TagList'
import Footer from '../../../../common/components/Footer'

export const StyledDiv1 = styled.div`
  ${mediaQuery('sm', 'min')} {
    min-width: 1024px;
  }
`

export const StyledDiv2 = styled.div`
  flex-basis: 44%;

  ${mediaQuery()} {
    flex-basis: 100%;
  }
`

export const StyledDiv3 = styled.div`
  margin-left: 4%;
  max-width: 560px;
  width: 52%;
  min-height: 273px;
  font-size: 1.4rem;
  line-height: 1.57;

  ${mediaQuery()} {
    margin: 30px auto 0;
    padding: 0 16px 15px;
    max-width: initial;
    width: 100%;
    min-height: auto;
  }
`

export const StyledSection1 = styled.section`
  margin: 0 auto;
  padding: 30px 40px 40px;
  max-width: 1160px;
  display: flex;

  ${mediaQuery()} {
    padding: 0;
    display: block;
    position: relative;
  }
`

export const StyledSection2 = styled.section`
  padding-top: 70px;
  width: 100%;
  background: #f3f4f5;

  ${mediaQuery()} {
    padding-top: 45px;
  }
`

export const StyledSection2Inner = styled.div`
  margin: 0 auto;
  max-width: ${breakPoints.mx}px;
`

export const StyledH2 = styled.h2`
  font-size: 3.8rem;
  font-weight: 600;
  line-height: 1.2;

  ${mediaQuery()} {
    font-size: 2rem;
  }
`

export const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 600;

  ${mediaQuery()} {
    padding: 0 15px;
  }
`

export const StyledNotice = styled(Notice)`
  margin: 0 auto;
  width: min(calc(100% - 80px), 1080px);

  ${mediaQuery()} {
    margin: 10px 15px;
    width: auto;
  }
`

export const StyledLink1 = styled(Link)`
  margin-top: 30px;
  padding: 14px 24px;
  max-width: 460px;
  background: #2d2d2d;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  transition: opacity 0.4s;

  &:link,
  &:visited {
    color: #fff;
  }

  &:hover {
    opacity: 0.7;
  }

  ${mediaQuery()} {
    margin: 20px auto 0;
  }

  ${mediaQuery(breakPoints.ss)} {
    font-size: 1.2rem;
  }
`

export const StyledCaption = styled(Caption)`
  margin-top: 2rem;

  ${mediaQuery()} {
    margin-top: 2.6rem;
  }
`

export const StyledDiv4 = styled.div`
  margin-top: 20px;
  padding: 25px;
  background-color: #fff;
  display: grid;
  gap: 25px;
  position: relative;
  z-index: 1;

  ${mediaQuery()} {
    padding: 15px;
    gap: 15px;
  }
`

export const StyledH4 = styled.h4`
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;

  ${mediaQuery()} {
    font-size: 1.6rem;
  }
`

export const StyledTagList = styled(TagList)`
  display: grid;
  grid-template-columns: repeat(5, auto);

  ${mediaQuery()} {
    grid-template-columns: auto;
  }

  li {
    text-align: center;
  }
`

export const StyledLink2 = styled(Link)`
  padding: 14px 24px;
  background-color: #02d4b1;
  border-radius: 5px;
  display: inline-block;
  justify-self: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  transition: opacity 0.4s;

  &:link,
  &:visited {
    color: #fff;
  }
  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }

  ${mediaQuery()} {
    font-size: 1.2rem;
  }
`

export const StyledFooter = styled(Footer)`
  margin-top: 0;
`

export const GlobalStyle = createGlobalStyle`
  .c-head-sub {
    max-width: 1160px;

    ${mediaQuery('sm', 'min')} {
      padding: 0 40px;
    }

    &-announce {
      margin-top: 0;
    }

    & + ${StyledNotice} {
      margin-top: 10px;
    }
  }
`
