import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import styled, { css } from 'styled-components'
import { KEY_CODE } from '../../../../constants/app'
import routes from '../../routes'
import webApp from '../../../exdio/utils/exdioWebAppUtils'

/** hooks */
import useMediaQuery from '../../../hooks/useMediaQuery'
import useIsMounted from '../../../hooks//useIsMounted'

/** style */
import { mediaQuery, fixColors } from '../../../exdio/components/style'

/** ヘッダー > 検索 */
const Search = (
  {
    show = false,
    setShow = () => {},
    setIsSpSearch = () => {},
    toggleNavMenu = () => {},
    ...props
  },
  context
) => {
  const isApp = webApp.utils.isApp(context)
  const { query } = context.routeHandler
  const { keyword } = query
  const [searchWord, setSearchWord] = useState(keyword || '') // 検索ワード string
  const isMounted = useIsMounted()
  const searchInputRef = useRef(null)
  const isSp = useMediaQuery()
  // SPの場合は検索画面に遷移

  /** 検索フォーム表示 */
  const onClickSearchButton = () => {
    process.nextTick(() => {
      searchInputRef.current.focus()
    })

    if (isSp) {
      if (isMounted) setSearchWord('')
      context.history.push(
        routes[isApp ? 'app_search' : 'search'].makePath({}, {}, {})
      )
      setIsSpSearch(true)
    }
    if (!show) toggleNavMenu('search')
  }

  /** 検索ワード変更時 */
  const onChangeSearchWord = (e) => {
    if (isMounted) setSearchWord(e.target.value)
  }

  /** 検索ワードキャンセル時 */
  const onClickCancel = () => {
    if (isSp) {
      if (context.history.length > 1) {
        context.history.goBack()
      } else {
        // キャンセルでブラウザバックできなくなったらホーム画面へ遷移
        context.history.push(routes[isApp ? 'app_home' : 'home'].makePath())
        if (show) toggleNavMenu('search')
      }
    } else {
      setSearchWord('')
      if (show) toggleNavMenu('search')
    }
  }

  /** 検索ワードクリア時 */
  const onClearSearchWord = () => {
    if (isSp) {
      if (isMounted) setSearchWord('')
    } else {
      onClickCancel()
    }
  }

  /** 検索ワード確定時 */
  const onSearch = () => {
    context.history.push(
      routes[isApp ? 'app_search' : 'search'].makePath(
        {},
        { keyword: searchWord.trim() }
      )
    )
    // PCの場合のみ検索フォームを閉じる
    if (!isSp) onClickCancel()
  }

  /** 検索ワード確定時 */
  const onKeyDownSearchInput = (e) => {
    if (KEY_CODE.ENTER !== e.keyCode) return
    onSearch()
  }

  /** 検索ワード取得 */
  const getSearchWord = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const keywordParam = queryParams.get('keyword') || ''
    if (keywordParam !== keyword) setSearchWord(keywordParam)
  }

  /** 現在のページが/search以外の場合は検索バーを閉じる */
  const closeSearchInput = () => {
    let _show
    setShow((oldShow) => {
      _show = oldShow
      return oldShow
    })
    if (isSp) {
      if (
        _show &&
        !(
          window.location.pathname === '/search' ||
          window.location.pathname === '/app/search'
        )
      ) {
        toggleNavMenu('search')
        setIsSpSearch(false)
      } else if (window.location.pathname === '/search') {
        if (!_show) toggleNavMenu('search')
        getSearchWord()
      }
    }
  }

  useEffect(() => {
    // サイトの初期表示が/searchのSP表示だった場合
    const _isSpSearch =
      !!routes[isApp ? 'app_search' : 'search'].match(
        context.routeHandler.path
      ) && isSp
    setIsSpSearch(_isSpSearch)

    if (_isSpSearch && !show) {
      getSearchWord()
      toggleNavMenu('search')
    }

    window.addEventListener('click', closeSearchInput)
    window.addEventListener('popstate', closeSearchInput)

    return () => {
      window.removeEventListener('click', closeSearchInput)
      window.removeEventListener('popstate', closeSearchInput)
    }
  }, [])

  if (!isMounted) return null

  return (
    <div {...props}>
      <StyledButton onClick={onClickSearchButton} isApp={isApp} />
      <StyledDiv1 show={show}>
        <StyledDiv2>
          <StyledSpan1 onClick={onSearch} />
          <StyledInput
            type="text"
            value={searchWord}
            placeholder="検索"
            onChange={onChangeSearchWord}
            onKeyDown={onKeyDownSearchInput}
            ref={searchInputRef}
          />
          <StyledSpan2 onClick={onClearSearchWord} />
        </StyledDiv2>
        <StyledDiv3>
          <StyledSpan3 onClick={onClickCancel}>キャンセル</StyledSpan3>
        </StyledDiv3>
      </StyledDiv1>
    </div>
  )
}

export default Search

Search.propTypes = {
  /** 検索バー開閉状態 */
  show: PropTypes.bool.isRequired,
  /** 検索バー開閉状態setState */
  setShow: PropTypes.func.isRequired,
  /** GlobalNavigation.js > isSpSearch 更新 */
  setIsSpSearch: PropTypes.func.isRequired,
  /** GlobalNavigation.js > showSubMenu/showSearch/showMemberMenu 開閉処理 */
  toggleNavMenu: PropTypes.func.isRequired
}

Search.contextTypes = {
  history: PropTypes.object,
  routeHandler: PropTypes.object
}

/* 共通ヘッダーのメニューブロック内の検索ボックス */
const StyledDiv1 = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  right: 0;
  top: 5px;
  width: 240px;
  ${mediaQuery()} {
    background: #fff;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 21;
    padding: 13px 0 6px 10px;
  }
  ${({ show }) =>
    show &&
    css`
      animation: fadeIn 0.2s linear 0s;
      ${mediaQuery()} {
        display: flex;
      }
    `}
`

const StyledDiv2 = styled.div`
  font-size: 1.6rem;
  background: ${fixColors.colorFormBg};
  border-radius: 19px;
  padding-left: 37px;
  padding-right: 37px;
  transition: 0.3s;
  position: relative;
  height: 37px;
  > input[type='text'] {
  }
  &::before {
    content: '';
    background: url(/images/exdio/renewal/icon_search.svg);
    background-size: 100%;
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 12px;
    margin: auto;
  }
  ${mediaQuery()} {
    width: 100%;
  }
`

const StyledDiv3 = styled.div`
  display: none;
  ${mediaQuery()} {
    display: flex;
    width: 97px;
    min-width: 97px;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
  }
`

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isApp'].includes(prop)
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 47px;
  background: url(/images/exdio/renewal/icon_search.svg) no-repeat center center;
  background-size: ${({ isApp }) => (isApp ? '19px' : null)};
  border: none;
  &:hover {
    opacity: 0.6;
    transition: 0.2s;
  }
  &-img {
    ${mediaQuery()} {
      width: 17px;
    }
  }
  ${mediaQuery()} {
    width: 27px;
    height: 39px;

    ${mediaQuery(374)} {
      width: 18px;
      background-size: contain;
    }
  }
`

const StyledInput = styled.input`
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
  height: 37px;
  width: 100%;
  color: ${fixColors.colorFont};
  font-size: 1.6rem;
`

// 検索用テキストボックスの虫眼鏡にイベント付与できるよう擬似化解除
const StyledSpan1 = styled.span`
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 12px;
  margin: auto;
  cursor: pointer;
  background: url(/images/exdio/renewal/icon_search.svg);
  background-size: 100%;
`

const StyledSpan2 = styled.span`
  display: block;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 12px;
  margin: auto;
  cursor: pointer;
  background: url(/images/exdio/renewal/icon_close.svg);
  background-size: 100%;
`

const StyledSpan3 = styled.span`
  cursor: pointer;
`
