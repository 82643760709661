import styled from 'styled-components'
import { mediaQuery } from '../../../../style'
import ButtonList1 from '../../ButtonList1'

export const StyledDiv = styled.div`
  margin-top: 30px;

  ${mediaQuery()} {
    margin-top: 0;
    padding: 20px 10px 0;
  }
`

export const StyledButtonList1 = styled(ButtonList1)`
  &:not(:first-child) {
    margin-top: 30px;

    ${mediaQuery()} {
      margin-top: 20px;
    }
  }
`
