import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakPoints, mediaQuery } from '../../../../exdio/components/style'

/** 放送年検索 */
const SearchBoxYear = ({
  selectedYear = null,
  setSelectedYear = () => {},
  ...props
}) => {
  /** episodesからすべての放送年を取得 */
  const yearsArr = (() => {
    const startY = 1992
    const endY = 2019
    return Array.from(new Array(endY - startY + 1)).map((v, i) => i + startY)
  })()

  const onClickHandler = (e) => {
    const val = Number(e.target.dataset.era)
    setSelectedYear(val === selectedYear ? null : val)
  }

  // 表示する項目がなければ終了
  if (!yearsArr.length) return null

  return (
    <StyledDiv {...props}>
      <StyledUl>
        {yearsArr.map((year) => {
          return (
            <li key={year}>
              <StyledButton
                type="button"
                data-sort="era"
                data-era={year}
                isActive={year === selectedYear}
                onClick={(e) => onClickHandler(e)}
              >
                {year}年
              </StyledButton>
            </li>
          )
        })}
      </StyledUl>
    </StyledDiv>
  )
}

export default memo(SearchBoxYear)

SearchBoxYear.propTypes = {
  selectedYear: PropTypes.number,
  setSelectedYear: PropTypes.func
}

const StyledDiv = styled.div`
  display: block;
`

export const StyledUl = styled.ul`
  background: #fff;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px 10px;

  ${mediaQuery()} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const StyledButton = styled.button`
  padding: 12px 0 9px;
  width: 100%;
  background: ${({ isActive }) => (isActive ? '#ffbc09' : '#ffe401')};
  box-shadow: ${({ isActive }) => (isActive ? '0 0 0' : '0 5px 0')} #ffbc09;
  border-width: 0;
  border-radius: 15px;
  display: block;
  color: #672806;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  transform: ${({ isActive }) => (isActive ? 'translateY(5px)' : null)};
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
  cursor: pointer;

  &:hover {
    ${mediaQuery(breakPoints.sm, 'min')} {
      background-color: #ffbc09;
      box-shadow: 0 0 0 #ffbc09;
      transform: translateY(5px);
    }
  }
`
