import _ from 'lodash'
import {
  META_SCHEMA_ID,
  PALETTE_SCHEMA_ID,
  ADVERTISING_SCHEMA_ID
} from '../../../../../constants/app'

/** キャンバス情報取得 */
export const getCanvas = (model, canvasKey) => {
  const path = ['canvas', canvasKey]
  return model
    .fetch([path])
    .then((result) => _.get(result, ['json', ...path], {}))
    .catch((err) => console.error(err))
}

/** パレット情報取得 */
export const getPalettes = async (model, canvas) => {
  const structures = _.get(canvas, ['structure'], [])
  if (!structures.length) return []

  const paletteIds = structures.map((s) => s.id)
  let imagePaletteIds = []
  let w_paletteIds = []

  // 画像とそれ以外を分離
  structures.forEach((structure) => {
    if (structure.palette) {
      if (structure.palette.schema_id === PALETTE_SCHEMA_ID.IMAGES) {
        imagePaletteIds.push(structure.id)
      } else {
        w_paletteIds.push(structure.id)
      }
    }
  })

  let paletteIdsChunk = arrayChunk(w_paletteIds, 3) // パレットは極力少なくかつリクエスト数は増えないように
  const chunk = [imagePaletteIds].concat(paletteIdsChunk)

  let all_palettes = []
  for (let i = 0; i < chunk.length; i++) {
    const path = [['palette', chunk[i]]]
    const _palettes = await model
      .fetch(path)
      .then((result) => {
        const palettesObj = _.get(result, ['json', 'palette'], {})
        const resPalettes = paletteIds
          .map((key) => palettesObj[key])
          .filter((v) => v)
        return resPalettes
      })
      .catch((err) => console.error(err))
    all_palettes = all_palettes.concat(_palettes)
  }

  return all_palettes
}

// array分割
const arrayChunk = ([...array], size = 1) => {
  return array.reduce(
    (acc, value, index) =>
      index % size ? acc : [...acc, array.slice(index, index + size)],
    []
  )
}

/** 価格情報取得 */
export const getHowToPlays = (model, palettes) => {
  const metaIdsMap = {}
  palettes
    .filter(
      (p) =>
        ![PALETTE_SCHEMA_ID.IMAGES, PALETTE_SCHEMA_ID.KEYWORDS].includes(
          p.schema_id
        )
    )
    .flatMap((p) => p.objects)
    .filter((obj) => obj.type === 'meta')
    .map((obj) => obj.meta)
    .filter(
      (meta) =>
        meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
    )
    .forEach((meta) => {
      metaIdsMap[meta.meta_id] = meta.meta_id
    })
  const metaIds = Object.keys(metaIdsMap)
  if (!metaIds.length) return Promise.resolve()

  const path = [['meta', 'howToPlay', false, metaIds]]
  return model
    .fetch(path)
    .then((result) => _.get(result, ['json', 'meta', 'howToPlay', false]) || {})
    .catch((err) => console.error(err))
}

/** 商品・コース情取得報 */
export const getProductsAndCourses = (model, palettes) => {
  const advertisings = palettes
    .filter(
      (p) =>
        ![PALETTE_SCHEMA_ID.IMAGES, PALETTE_SCHEMA_ID.KEYWORDS].includes(
          p.schema_id
        )
    )
    .flatMap((p) => p.objects)
    .filter((obj) => obj.type === 'advertising')
    .map((obj) => obj.advertising)

  const productIds = advertisings
    .filter(
      (ad) =>
        ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT ||
        ad.schema_id === ADVERTISING_SCHEMA_ID.PRODUCT_LIVE
    )
    .map((h) => h.values.product)
    .filter((v) => v)
  const courseIds = advertisings
    .filter(
      (ad) =>
        ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE ||
        ad.schema_id === ADVERTISING_SCHEMA_ID.COURSE_LIVE
    )
    .map((h) => h.values.course)
    .filter((v) => v)

  const path = [
    ['product', productIds],
    ['course', courseIds]
  ]
  return model
    .fetch(path)
    .then((result) => {
      return {
        product: _.get(result, ['json', 'product']) || {},
        course: _.get(result, ['json', 'course']) || {}
      }
    })
    .catch((err) => console.error(err))
}

/** 広告を挿入 */
export const paletteAddAd = (palettes = [], adIdxBanner = 4) => {
  return [
    ...palettes.slice(0, 1),
    {
      id: 1,
      type: 'flex_bnr'
    },
    ...palettes.slice(1, adIdxBanner - 1),
    {
      id: adIdxBanner,
      type: 'dfp_bnr'
    },
    ...palettes.slice(adIdxBanner - 1)
  ]
}
