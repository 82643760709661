/* style */
import styled, { css } from 'styled-components'
/* components */
import Link from '../../../../../../../../sketch-platform/ui/routing/Link'
import ConvertSvg from '../../util/ConvertSvg'

export const mediaPc = '(min-width: 1025px)'
export const mediaSp = '(max-width: 1024px)'

export const StyledSpan = styled.span`
  display: inline-block;
  font-size: 1.6rem;
  color: #fff;
  margin-left: 8px;

  @media ${mediaSp} {
    flex: 1 0;
  }
`

export const StyledConvertSvg = styled(ConvertSvg)`
  && {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      min-width: 20px;
      display: inline-block;

      path,
      rect,
      circle {
        fill: #fff;
        transition: fill 0.2s ease-in-out;
      }

      @media ${mediaSp} {
        width: 27px;
      }
    }
  }
`

export const StyledUl = styled.ul`
  display: flex;

  @media ${mediaSp} {
    flex-direction: column;
  }
`

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['isCurrent', 'isDisabled'].includes(prop)
})`
  && {
    padding: 8px 12px;
    background-color: rgba(0, 163, 163, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    font-family: 'M PLUS Rounded 1c', sans-serif;

    ${({ isCurrent }) =>
      isCurrent &&
      css`
        overflow: hidden;
        background-color: #fff;
        color: #00cccc;
        pointer-events: none;
      `}

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        position: relative;
        overflow: hidden;
        pointer-events: none;
      `}

    @media ${mediaSp} {
      padding: 12px;
      width: 100%;
      background: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 2.4rem;
    }

    &::before,
    &::after {
      ${({ isDisabled }) =>
        isDisabled &&
        css`
          margin: auto;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          @media ${mediaSp} {
            position: static;
          }
        `}
    }

    &::before {
      ${({ isDisabled }) =>
        isDisabled &&
        css`
          display: block;
          background-color: rgba(0, 0, 0, 0.25);
          content: '';

          @media ${mediaSp} {
            content: none;
          }
        `}
    }

    &::after {
      ${({ isDisabled }) =>
        isDisabled &&
        css`
          display: flex;
          align-items: center;
          justify-content: center;
          transform: rotate(-15deg);
          color: #fff;
          font-size: 1.4rem;
          content: 'coming soon';
        `}

      @media ${mediaSp} {
        ${({ isCurrent }) =>
          isCurrent &&
          css`
            margin-left: auto;
            display: inline-block;
            color: #fff;
            font-size: 1.4rem;
            content: 'current';
          `}

        ${({ isDisabled }) =>
          isDisabled &&
          css`
            margin-left: auto;
            display: inline-block;
            transform: none;
          `}
      }
    }

    &:hover {
      background-color: #fff;
      color: #00cccc;

      ${StyledSpan} {
        color: #00cccc;
      }

      i {
        svg {
          path,
          rect,
          circle {
            fill: #00cccc;
          }
        }
      }
    }

    ${StyledConvertSvg} {
      svg {
        path,
        rect,
        circle {
          ${({ isCurrent }) =>
            isCurrent &&
            css`
              fill: #00cccc;
            `}

          @media ${mediaSp} {
            ${({ isDisabled, isCurrent }) =>
              (isCurrent || isDisabled) &&
              css`
                fill: rgba(255, 255, 255, 0.5);
              `}
          }
        }
      }
    }

    ${StyledSpan} {
      ${({ isCurrent }) =>
        isCurrent &&
        css`
          color: #00cccc;
        `}

      @media ${mediaSp} {
        ${({ isDisabled, isCurrent }) =>
          (isCurrent || isDisabled) &&
          css`
            flex: 1 0;
            color: rgba(255, 255, 255, 0.5);
          `}
      }
    }
  }
`

export const StyledLi = styled.li`
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;

  @media ${mediaSp} {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left-width: 0;
  }

  &:first-child {
    border-left-width: 0;

    @media ${mediaSp} {
      border-top-width: 0;
    }

    ${StyledLink} {
      @media ${mediaPc} {
        padding-left: 32px;
        border-radius: 24px 0 0 24px;
      }
    }
  }

  &:last-child {
    ${StyledLink} {
      @media ${mediaPc} {
        padding-right: 32px;
        border-radius: 0 24px 24px 0;
      }
    }
  }
`
