import React from 'react'

/* style */
import { StyledDiv, StyledP, StyledA, StyledImg } from './style'

const Bnr = () => {
  return (
    <StyledDiv>
      <StyledP>
        <StyledA href="https://sailgp.com/ja/" target="_blank">
          <StyledImg
            src="https://wws.tv-asahi.co.jp/douga_mv/sailgp/images/bnr_official.png"
            alt="SailGP公式サイト"
          />
        </StyledA>
      </StyledP>
    </StyledDiv>
  )
}

export default Bnr
