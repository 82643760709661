import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Item from './Item'
import LinkHeader from './LinkHeader/'

/** style */
import { mediaQuery, borderColor2 } from '../../../../exdio/components/style'

const LinkCol = ({
  items = [],
  pcCol = 1,
  spCol = 1,
  arrow = true,
  onClick = () => {},
  header = {
    title: '',
    route: '',
    myPage: false
  },
  ...props
}) => {
  return (
    <StyledDiv {...props}>
      {header.title && (
        <LinkHeader
          route={header.route}
          myPage={header.myPage}
          onClick={onClick}
        >
          {header.title}
        </LinkHeader>
      )}
      <StyledUl pcCol={pcCol} spCol={spCol}>
        {items.map((item) => (
          <Item
            key={
              item.content && item.content.label ? item.content.label : item.id
            }
            item={item}
            arrow={arrow}
            onClick={onClick}
          />
        ))}
      </StyledUl>
    </StyledDiv>
  )
}

export default LinkCol

LinkCol.propTypes = {
  /** リンクの内容 */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /** Linkコンポーネント用 */
      content: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      }),
      /** Linkコンポーネント用 */
      route: PropTypes.shape({}),
      /** Linkコンポーネント用 */
      href: PropTypes.string,
      /** Linkコンポーネント用 */
      params: PropTypes.shape({}),
      /** 追加でクラス名を指定する場合 */
      className: PropTypes.string,
      /** SPのみで表示する場合true */
      spOnly: PropTypes.bool,
      children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    })
  ).isRequired,
  /** ボタンの矢印 */
  arrow: PropTypes.bool,
  onClick: PropTypes.func,
  /** PCの列数 */
  pcCol: PropTypes.number,
  /** SPの列数 */
  spCol: PropTypes.number,
  /** 見出しの内容 */
  header: PropTypes.shape({
    /** 見出しの文字列 */
    title: PropTypes.string.isRequired,
    /** 見出しがリンクの場合こちらを追加 */
    route: PropTypes.shape({}),
    /** 見出しがマイページのコンテンツを表示する場合追加 */
    myPage: PropTypes.bool
  })
}

const StyledDiv = styled.div`
  width: 100%;
`

const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    margin-top: 1px;
    width: ${({ pcCol }) => {
      switch (pcCol) {
        case 2:
          return '50%'
        case 3:
          return '33.3333%'
        default:
          return '100%'
      }
    }};
    border-right: 1px solid ${borderColor2};
    display: grid;

    ${mediaQuery('mm')} {
      width: ${({ spCol }) => {
        if (spCol) {
          return `calc(100% / ${spCol})`
        }
        return '100%'
      }};
    }

    &:nth-child(${({ spCol }) => `${spCol}n`}) {
      ${mediaQuery('mm')} {
        border-right: none;
      }
    }
    &:nth-child(${({ pcCol }) => `${pcCol}n`}) {
      ${mediaQuery('mm', 'min')} {
        border-right: none;
      }
    }
  }
`
