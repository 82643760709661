/*
* ページャー
*/
import React, { Component } from 'react';
import window from 'global';

export default class Pager extends Component {
  static defaultProps = {
    clickScroll: false
  };

constructor(props, context) {
    super(props, context)

    this.state = {
      currentPageNum: 1
    }
  }

  /**
  * ページ遷移を管理
  * @param {Number} pageNum - エピソード一覧を取得
  * @param {Object} option
  * @param {Number} option.episodesPerPages - 1ページあたりの表示数
  * @param {Number} option.range - 前後の表示件数
  */
  paging (pageNum, option={episodesPerPages: 12, range: 2}) {
    /**
    * @type {Object} episodes エピソード一覧を取得
    * @type {Number} currentPageNum 表示中のページ番号
    */
    const { episodes, updateDisplayEpisodes } = this.props

    // エピソードを取得できないときは終了
    if (!(episodes||[]).length > 0) return

    const startEpisodeNum = ((pageNum - 1) * option.episodesPerPages) + 1 // 開始位置
    const endEpisodeNum = pageNum * option.episodesPerPages // 終了位置
    let extractedEpisodes = [] // 抽出後のエピソードを格納する配列

    // 該当するエピソードを配列に格納する
    for (let i = startEpisodeNum - 1; i < endEpisodeNum; i++) {
      if (episodes[i]) {
        extractedEpisodes.push(episodes[i])
      }
    }

    // 抽出後のエピソードを更新
    updateDisplayEpisodes(extractedEpisodes)
  }

  render () {
    /*
    * episodes: エピソード一覧を取得
    * currentPageNum: 表示中のページ番号
    */
    const { option, episodes, currentPageNum, updateCurrentPageNum, clickScroll} = this.props
    if (!(episodes||[]).length > 0) return null // エピソードが存在しなければ終了

    const ALL_EPISODE_LENGTH = episodes.length // エピソードの数を取得
    const ALL_PAGE_LENGTH = Math.ceil(ALL_EPISODE_LENGTH / option.episodesPerPages) // ページ数を算出

    /** 先頭に表示する数字 */
    const getFirstPageNum = () => {
      if (currentPageNum <= option.range + 1) {
        // 表示が少ないとき
        return 1
      } else if (currentPageNum > ALL_PAGE_LENGTH - option.range) {
        // 右側に寄るとき
        const firstNum = (currentPageNum - option.range) - (option.range - (ALL_PAGE_LENGTH - currentPageNum))
        return firstNum > 0 ? firstNum : 1
      } else if (currentPageNum - option.range > 1) {
        // 通常時
        return currentPageNum - option.range
      } else {
        // 左側に寄るとき
        return 1
      }
    }

    // 末尾に表示する数字
    const getLastPageNum = () => {
      if (currentPageNum <= option.range) {
        // 左側に寄るとき
        return (currentPageNum + option.range) + (option.range - (currentPageNum - 1))
      } else if (currentPageNum + option.range <= ALL_PAGE_LENGTH) {
        // 通常時
        return currentPageNum + option.range
      } else {
        // 右側に寄るとき
        return ALL_PAGE_LENGTH
      }
    }

    // li > button を格納
    let listItems = []

    for (let i = getFirstPageNum(); i <= getLastPageNum(); i++) {
      if (i <= ALL_PAGE_LENGTH) {
        listItems.push(<li key={i} className="s-pager__item"><button type="button" value={i} className={`s-pager__num${i == currentPageNum ? '--current' : ''}`} onClick={pagingHandler.bind(this)}>{i}</button></li>)
      }
    }

    /** ページングの実行 */
    function pagingHandler (e) {
      const pageNum = Number(e.target.value)

      // 表示中のページ番号のボタンがクリックされた場合は実行しない
      if (pageNum == currentPageNum) {
        return
      }

      if (pageNum) {
        // 選択中のページ番号を更新する
        updateCurrentPageNum(pageNum)

        // ページングを実行する
        this.paging(pageNum, option)
      }

      //単話リスト上部へスクロール
      if (clickScroll) {
        const divElm = document.getElementById('episodes-list')
        const headerElm = document.getElementById('mp-header')
        const divRect = divElm.getBoundingClientRect() ;
        const headerRect = headerElm.getBoundingClientRect() ;
        const rectY = (divRect.top +  window.pageYOffset) - headerRect.height ;
        window.scroll({ top: rectY, behavior: 'smooth' });
      }
    }

    /** 次へボタンを押したときの挙動 */
    function pagePlusHandler () {
      const { currentPageNum } = this.props
      const nextNum = currentPageNum + 1 <= ALL_PAGE_LENGTH ? currentPageNum + 1 : ALL_PAGE_LENGTH

      updateCurrentPageNum(nextNum)

      // ページングを実行する
      this.paging(nextNum, option)

      //単話リスト上部へスクロール
      if (clickScroll) {
        const divElm = document.getElementById('episodes-list')
        const headerElm = document.getElementById('mp-header')
        const divRect = divElm.getBoundingClientRect() ;
        const headerRect = headerElm.getBoundingClientRect() ;
        const rectY = (divRect.top +  window.pageYOffset) - headerRect.height ;
        window.scroll({ top: rectY, behavior: 'smooth' });
      }
    }

    /** 前へボタンを押したときの挙動 */
    function pageMinusHandler () {
      const { currentPageNum } = this.props
      const prevNum = currentPageNum - 1 >= 1 ? currentPageNum - 1 : 1

      updateCurrentPageNum(prevNum)

      // ページングを実行する
      this.paging(prevNum, option)

      //単話リスト上部へスクロール
      if (clickScroll) {
        const divElm = document.getElementById('episodes-list')
        const headerElm = document.getElementById('mp-header')
        const divRect = divElm.getBoundingClientRect() ;
        const headerRect = headerElm.getBoundingClientRect() ;
        const rectY = (divRect.top +  window.pageYOffset) - headerRect.height ;
        window.scroll({ top: rectY, behavior: 'smooth' });
      }
    }

    /** 最初へボタンを押したときの挙動 */
    function pageFirstHandler () {
      updateCurrentPageNum(1)

      // ページングを実行する
      this.paging(1, option)

      //単話リスト上部へスクロール
      if (clickScroll) {
        const divElm = document.getElementById('episodes-list')
        const headerElm = document.getElementById('mp-header')
        const divRect = divElm.getBoundingClientRect() ;
        const headerRect = headerElm.getBoundingClientRect() ;
        const rectY = (divRect.top +  window.pageYOffset) - headerRect.height ;
        window.scroll({ top: rectY, behavior: 'smooth' });
      }
    }

    /** 最後へボタンを押したときの挙動 */
    function pageLastHandler () {
      updateCurrentPageNum(ALL_PAGE_LENGTH)

      // ページングを実行する
      this.paging(ALL_PAGE_LENGTH, option)

      //単話リスト上部へスクロール
      if (clickScroll) {
        const divElm = document.getElementById('episodes-list')
        const headerElm = document.getElementById('mp-header')
        const divRect = divElm.getBoundingClientRect() ;
        const headerRect = headerElm.getBoundingClientRect() ;
        const rectY = (divRect.top +  window.pageYOffset) - headerRect.height ;
        window.scroll({ top: rectY, behavior: 'smooth' });
      }
    }

    // 話数が1ページの表示件数以上のとき、表示する
    return episodes.length > option.episodesPerPages
      ? (
        <div className="s-pager__wrapper">
          <ul className="s-pager">
            <li className="s-pager__item"><button type="button" value="1" className="s-pager__num" onClick={pageFirstHandler.bind(this)}>&lt;&lt;</button></li>
            <li className="s-pager__item"><button type="button" value={getFirstPageNum()} className="s-pager__num" onClick={pageMinusHandler.bind(this)}>&lt;</button></li>
            {listItems}
            <li className="s-pager__item"><button type="button" value={getLastPageNum()} className="s-pager__num" onClick={pagePlusHandler.bind(this)}>&gt;</button></li>
            <li className="s-pager__item"><button type="button" value={ALL_PAGE_LENGTH} className="s-pager__num" onClick={pageLastHandler.bind(this)}>&gt;&gt;</button></li>
          </ul>
        </div>
      )
    : null
  }
}
