import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SEARCH_TYPE, SEARCH_TYPES } from '../../../../constants/app';

class TabNavigation extends Component {
  static propTypes = {
    types: PropTypes.arrayOf(PropTypes.object),
    initialType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }),
    onSelect: PropTypes.func
  };

  static defaultProps = {
    types: SEARCH_TYPES,
    initialType: SEARCH_TYPE.ALL,
    onSelect: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.initialType.value
    };

    this.onClick = this.onClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.types !== this.props.types || nextState.value !== this.state.value;
  }

  onClick(e) {
    e.preventDefault();
    const value = e.target.dataset.type;
    this.setState({ value });
    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  }

  render() {
    return (
      <div className="c-navTab">
        <ul className="c-navTab-inBox">
          {this.props.types.map(type => {
            const anchorClassName = classnames('c-navTab-inBox-btn-link', {
              current: type.value === this.state.value
            });
            return (
              <li key={type.value} className={`c-navTab-inBox-btn results-${type.value}`}>
                <a href="#" className={anchorClassName} data-type={type.value} onClick={this.onClick}>
                  {type.label}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default TabNavigation;
