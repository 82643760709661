import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import axios from 'axios'
import { get } from 'lodash'
import Cookie from 'js-cookie'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import {
  PURCHASE_TYPE,
  EPISODE_DISPLAY_MODES_FREE,
  LOCAL_STORAGE_KEY_PURCHASE
} from '../../../../../constants/app'

import Link from '../../../../../sketch-platform/ui/routing/Link'
import Label from './Label'

const noticeTextList = {
  default: '投票は一度しかできません。投票しますか？',
  success: '投票を受け付けました。ありがとうございました。',
  error: '期間外のため、投票することができません。',
  notLoggedIn: '投票するにはログインが必要です。',
  notPurchased: '投票するには購入が必要です。',
  certificationError:
    '認証エラーが発生しました。お手数ですが再ログインしてください。'
}

const CBSESSIONID = Cookie.get('CBSESSIONID')
const CBM_ID = Cookie.get('CBM_ID')

const endpoint = `${
  ['development', 'staging'].includes(process.env.NODE_ENV)
    ? '//st-wws.tvasahi.jp'
    : '//wws.tv-asahi.co.jp'
}/apps/douga/gifting`
const instance = axios.create({
  baseURL: endpoint,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }
})

/** コメント機能 */
const VoteForm = (
  {
    meta,
    status = {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    buttonData = {
      visible: false,
      gifting_id: null,
      event_id: '',
      purchase_page_url: '',
      list: []
    },
    price = [null, null],
    className = ''
  },
  context
) => {
  const config = context.models.config.data
  const { visible, purchase_page_url, list } = buttonData

  /* visibleで表示の切り替えを行う */
  if (visible === false) return null
  const isLoggedIn = webApp.utils.isLoggedIn(context)

  const [settingObj, setSettingObj] = useState({
    startAt: null,
    endAt: null
  })
  const [selectedRadioId, setSelectedRadioId] = useState(null)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [isFree, setIsFree] = useState(false)
  const [noticeText, setNoticeText] = useState(noticeTextList.default)
  const [certificationError, setCertificationError] = useState(false)
  const [isVoted, setIsVoted] = useState(false)
  const [isDead, setIsDead] = useState(false)
  const intervalId = useRef(null)

  /**
   * 設定ファイル取得
   *
   * @param string $path
   */
  const getSettingJson = (path) => {
    instance
      .get(`//${config.infra_host}${path}`)
      .then(({ data }) => {
        const { start_at, end_at } = data
        const startAt = new Date(
          (start_at || '1970-01-01 00:00:00').replace(' ', 'T')
        )
        const endAt = new Date(
          (end_at || '3000-12-31 23:59:59').replace(' ', 'T')
        )
        setSettingObj({
          startAt,
          endAt
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  /**
   * 設定ファイルのパスを取得
   */
  const getSettingJsonPath = () =>
    instance
      .get(`/data/${get(buttonData, ['event_id'])}.json`)
      .then(({ data }) => data.setting_json_path)
      .catch((err) => {
        console.error(err)
      })

  /**
   * 未ログイン・未購入の場合のエラーテキスト
   */
  const getErrorText = () => {
    let text = ''
    // 未購入のテキストをセット
    if (!isFree && status.isNotPurchased) {
      text = noticeTextList.notPurchased
    }
    // 未ログインのテキストをセット
    if (!isLoggedIn) {
      text = noticeTextList.notLoggedIn
    }
    // ログイン認証エラー
    if (certificationError) {
      text = noticeTextList.certificationError
    }

    return text
  }

  /**
   * 投票済みかチェック
   * 投票済みの場合はラベルを投票済みに変更
   */
  const checkVote = () => {
    if (
      !isLoggedIn ||
      (!isFree && status.isNotPurchased) ||
      certificationError
    ) {
      setIsVoted(false)
      setNoticeText(getErrorText())
      return
    }

    let params = ''
    params += `ACT=check_vote`
    params += `&CBM_ID=${CBM_ID}`
    params += `&CBSESSIONID=${CBSESSIONID}`
    params += `&GIFTING_ID=${get(buttonData, ['gifting_id'])}`

    instance
      .post('/', params)
      .then(({ data }) => {
        const { code = '', MATERIAL_ID = '', deadlineStatus = '' } = data
        const hasMaterialId = Boolean(MATERIAL_ID)

        switch (code) {
          case 'NORMAL_END':
            // 投票/未投票が変更されたときだけ更新する
            if (hasMaterialId !== isVoted) {
              setIsVoted(hasMaterialId)
              setSelectedRadioId(MATERIAL_ID || null)
              setNoticeText(
                MATERIAL_ID ? noticeTextList.success : noticeTextList.default
              )
              setCertificationError(false)
            }
            if (deadlineStatus) {
              setIsDead(Number(deadlineStatus) === 1)
            }
            break

          case 'ERROR_LOGIN_CHECK':
            setIsVoted(false)
            setNoticeText(noticeTextList.certificationError)
            setCertificationError(true)
            break

          default:
            break
        }
      })
      .catch((err) => {
        setIsVoted(false)
        console.error(err)
      })
  }

  /**
   * 送信後アクション
   *
   * @param object $e
   */
  const onSubmit = (e) => {
    /** submitイベント中止 */
    e.preventDefault()

    const now = new Date()

    /* 投票可能か */
    const canVote = settingObj.startAt < now || now < settingObj.endAt

    if (canVote) {
      /** 投票期間の場合、APIの実行 */
      let params = ''
      params += `ACT=nauth_use_point_once`
      params += `&CBM_ID=${CBM_ID}`
      params += `&CBSESSIONID=${CBSESSIONID}`
      params += `&GIFTING_ID=${get(buttonData, ['gifting_id'])}`
      params += `&POINT_ID=gifting_walet`
      params += `&MATERIAL_TYPE_ID=gifting_kotei`
      params += `&MATERIAL_ID=${selectedRadioId}`
      params += `&POINT=0`

      instance
        .post('/', params)
        .then(({ data }) => {
          if (get(data, ['code']) === 'NORMAL_END') {
            setIsVoted(true)
            setNoticeText(noticeTextList.success)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      /** 投票期間外の場合 */
      setSelectedRadioId(null)
      setNoticeText(noticeTextList.error)
    }
  }

  /**
   * ラジオボタン変更後の挙動
   *
   * @param string radioId
   */
  const onChangeRadio = (radioId) => {
    setSelectedRadioId(radioId)
    setIsAccordionOpen(true)
  }

  /**
   * キャンセルボタンクリック後の挙動
   */
  const onClickCancel = () => {
    setSelectedRadioId(null)
    setIsAccordionOpen(false)
  }

  /** 購入ページへ遷移 */
  const onClickPurchase = () => {
    const metaId = get(meta, ['id'])
    const seasonId = get(meta, ['values', 'parents_season', 'id'])
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_PURCHASE,
      JSON.stringify({
        type: PURCHASE_TYPE.EPISODE,
        id: Number(seasonId),
        selected: [Number(metaId)],
        page: context.routeHandler.url
      })
    )
    context.history.push(routes.purchase.makePath())
  }

  const settingJsonPath = useRef(null)
  useEffect(() => {
    /** ログイン&購入済みの場合、ログインチェック */
    if (isLoggedIn && (isFree || status.isPurchased)) {
      checkVote() // 投票済みかチェック
    } else {
      setNoticeText(getErrorText())
    }
  }, [])

  /**
   * 投票API実行インターバルの管理
   * インターバルの中ではisVotedの変更を感知できないため変更がある度にセットし直す
   */
  useEffect(() => {
    clearInterval(intervalId.current)

    intervalId.current = setInterval(async () => {
      if (settingJsonPath.current) {
        getSettingJson(settingJsonPath.current)
      } else {
        settingJsonPath.current = await getSettingJsonPath()
      }
      checkVote()
    }, 5000)

    return () => clearInterval(intervalId)
  }, [isVoted])

  /** statusに応じて無料フラグを管理 */
  useEffect(() => {
    setIsFree(EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode))
  }, [status])

  const Buttons = () => {
    if (!isLoggedIn || certificationError) {
      return (
        <li className="c-list2__item">
          <Link
            route={routes.login}
            query={{ redirect: context.routeHandler.path } || null}
            className="c-btn1--green"
          >
            ログイン
          </Link>
        </li>
      )
    }

    if (!isFree && status.isNotPurchased) {
      return (
        <li className="c-list2__item">
          {purchase_page_url ? (
            <Link href={purchase_page_url} className="c-btn1--pink">
              月額{price[1]}円で視聴
            </Link>
          ) : (
            <button
              type="button"
              onClick={onClickPurchase}
              className="c-btn1--pink"
            >
              <img
                src="/images/exdio/renewal/icon_coin.svg"
                width="15"
                height="15"
                alt=""
              />
              {price[0]}で購入
            </button>
          )}
        </li>
      )
    }

    if (isVoted) {
      return (
        <li className="c-list2__item">
          <button
            type="button"
            id="closeButton"
            className="c-btn1"
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          >
            閉じる
          </button>
        </li>
      )
    }

    return (
      <>
        <li className="c-list2__item">
          <button type="button" className="c-btn1" onClick={onClickCancel}>
            キャンセル
          </button>
        </li>
        <li className="c-list2__item">
          <button type="submit" id="votebtn" className="c-btn1--pink">
            投票する
          </button>
        </li>
      </>
    )
  }

  return (
    <form className={`c-vote_form ${className}`} onSubmit={onSubmit}>
      <div className="c-vote_form__label_wrapper">
        <Label isVoted={isVoted} isDead={isDead} />
      </div>
      <ul className="c-list1">
        {Boolean(list) &&
          list.map((item) => {
            const { id, src, alt } = item
            return (
              <li key={id} className="c-list1__item">
                <input
                  type="radio"
                  id={`fRadio${id}`}
                  name="vote"
                  value={`vote${id}`}
                  className="c-radio1"
                  data-material-id={id}
                  disabled={isVoted || isDead}
                  checked={Number(id) === Number(selectedRadioId)}
                  onChange={() => onChangeRadio(id)}
                />
                <label
                  htmlFor={`fRadio${id}`}
                  className={cn('c-radio1__label', {
                    grayout: isDead
                  })}
                  style={{
                    pointerEvents: isVoted || isDead ? 'none' : 'auto'
                  }}
                >
                  <img
                    src={src}
                    width="67"
                    height="67"
                    alt={alt}
                    className="c-radio1__img"
                  />
                </label>
              </li>
            )
          })}
      </ul>
      {isAccordionOpen && (
        <div className="c-accordion">
          <p className="c-text1">{noticeText}</p>
          <ul className="c-list2">
            <Buttons />
          </ul>
        </div>
      )}
    </form>
  )
}

export default VoteForm

VoteForm.propTypes = {
  /** エピソードのメタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({}).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }).isRequired,
  buttonData: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    gifting_id: PropTypes.number.isRequired,
    event_id: PropTypes.string.isRequired,
    purchase_page_url: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired,
        alt: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  price: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number])
  ),
  className: PropTypes.string
}

VoteForm.contextTypes = {
  falcorModel: PropTypes.shape({}),
  models: PropTypes.shape({}),
  routeHandler: PropTypes.shape({}),
  history: PropTypes.shape({})
}
