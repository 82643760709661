import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'
import { META_SCHEMA_ID } from '../../../../../constants/app'
import routes from '../../../routes'
import SlideCards from '../SlideCards'
import getCardRoute from './functions/getCardRoute'
import _getHowToPlayForPalettes from './functions/getHowToPlayForPalettes'

const NewArrivalCardList = ({ ...props }, context) => {
  const [loaded, setLoaded] = useState(false)
  const [cardDataList, setCardDataList] = useState([])
  const [link, setLink] = useState({ route: '' })
  const [howToPlaysForPalettes, setHowToPlaysForPalettes] = useState([])
  const model = context.falcorModel.batch(100)
  const config = context.models.config.data

  /**
   * パレット用の情報を取得し、stateにセットする
   * @async
   * @param {Array.<Object>} palettesObject - パレットオブジェクトの配列
   * @returns {Promise<void>}
   */
  const getHowToPlayForPalettes = async (newArrival) => {
    // 価格情報を取得
    const metaIds = newArrival
      .filter((meta) => meta.meta_schema_id === META_SCHEMA_ID.EPISODE_NOT_FREE)
      .map((e) => e.meta_id)
    const howToPlaysForPalettes = await _getHowToPlayForPalettes(model, metaIds)
    setHowToPlaysForPalettes(howToPlaysForPalettes)
  }

  /**
   * 新着動画を取得して、カードデータを生成して state にセットします。
   * @returns {Promise<void>} Promiseオブジェクト
   */
  /** 新着動画取得 */
  const getNewArrival = async () => {
    const period = config.news_page.new_arrival_period

    const path = ['meta', 'newArrival', period]

    const newArrivalResult = await model.fetch([path])
    const newArrivals =
      _.get(newArrivalResult, ['json', ...path]).slice(0, 20) || []

    getHowToPlayForPalettes(newArrivals)

    // カードデータのリストを生成
    const cardDataList = newArrivals.map((meta) => {
      const route = _.get(getCardRoute(context, meta), ['route'])
      const params = _.get(getCardRoute(context, meta), ['params'])
      const query = _.get(getCardRoute(context, meta), ['query'])
      return {
        key: meta.meta_id,
        meta: meta,
        howToPlay: howToPlaysForPalettes[meta.meta_id],
        showCaption: true,
        showCoin: true,
        showNew: webApp.utils.showNew(meta),
        breakSubTitle: true,
        route: route,
        params: params,
        query: query
      }
    })

    setCardDataList(cardDataList)
    setLoaded(true)
  }

  useEffect(() => {
    setLink({ route: routes.newarrival })
    getNewArrival()
  }, [])

  return (
    <SlideCards
      heading="新着動画"
      cardDataList={cardDataList}
      link={link}
      loaded={loaded}
      {...props}
    />
  )
}

export default NewArrivalCardList

NewArrivalCardList.contextTypes = {
  falcorModel: PropTypes.object,
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object
}
