import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/** 在学証明書｜印刷ボタン */
const CertificateBtn = ({ ...props }) => {
  return (
    <StyledButton type="button" onClick={window.print} {...props}>
      在学証明書を印刷する
    </StyledButton>
  )
}

export default CertificateBtn

CertificateBtn.propTypes = {
  /** styleの継承 */
  className: PropTypes.string
}

const StyledButton = styled.button`
  padding: 1px 6px;
  background-color: #efefef;
  border: 1px outset #767676;
  display: block;
`
