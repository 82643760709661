import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import ProgramItemLink from '../ProgramItemLink'

const VerticalCardList = ({
  cardDataList = [],
  link = { route: {}, params: {}, query: {} },
  heading = ''
}) => {
  return (
    <div className="c-card-panel input">
      <div className="c-card-panel-head">
        <h3>{heading}</h3>
      </div>
      <StyledDiv className="c-card-panel-cont">
        {cardDataList &&
          cardDataList.map((cardData) => <ProgramItemLink {...cardData} />)}
      </StyledDiv>
      <div className="c-more">
        <Link
          route={link.route}
          params={link.params}
          query={link.query}
          className="c-more-btn"
        >
          <span className="c-more-btn-txt link" />
        </Link>
      </div>
    </div>
  )
}

export default VerticalCardList

VerticalCardList.propTypes = {
  /** カードリストの見出し */
  heading: PropTypes.string,
  cardDataList: PropTypes.array.isRequired,
  link: PropTypes.object
}

const StyledDiv = styled.div`
  .c-card-inBox-meta-caption {
    text-overflow: ellipsis;
    display: block;
    max-width: 175px;
    margin-right: 0;

    @media (max-width: 360px) {
      max-width: 144px;
    }
  }
`
