import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PURCHASE_CONTENTS } from '../../../../../constants/app';
import Steps from './Steps';

/** 購入ページ:未選択 */
export default class NotSelected extends Component {
  static contextTypes = {
    history: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    this.goBack = this.goBack.bind(this);
  }

  goBack(e) {
    e.preventDefault();
    this.context.history.goBack();
  }

  render() {
    return (
      <div className="c-login">
        <div className="c-login-wrapper coin-step-head">
          <Steps current={PURCHASE_CONTENTS.SELECT} />
        </div>

        <div className="movie-purchase_cont">
          <div className="c-login-cont-wrapper coin-payment-wrapper">
            <div className="c-login-flowwrapper">
              <div className="payment-pieces-wrapper">
                <div className="movie-top-attention">
                  <p className="c-login-subtext">視聴する商品が選択されていません。</p>
                </div>
              </div>
            </div>
            <div className="check-wrapper">
              <div className="back-btn">
                <a href="#" onClick={this.goBack}>
                  前のページへ戻る
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
