import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import SwitchableList from '../../../../../common/components/renewal/SwitchableList'
import useIsMounted from '../../../../../hooks/useIsMounted'

/* style */
import { StyledP, StyledDiv } from './style'

/** 新着ページ */
const NewArrival = (props, context) => {
  const model = context.falcorModel.batch(100)
  const config = context.models.config.data
  const isMounted = useIsMounted()
  const [metas, setMetas] = useState(null)

  const fetchData = () => {
    const period = config.news_page.new_arrival_period
    const path = ['meta', 'newArrival', period]
    return model.fetch([path]).then((result) => {
      const metasObj = _.get(result, ['json', ...path]) || []
      setMetas(metasObj)
    })
  }

  // マウント時のみ実行
  useEffect(() => {
    if (isMounted) {
      fetchData()
    }
  }, [isMounted])

  if (!metas) return null

  if (metas && metas.length === 0) {
    return <StyledP>新着動画はありません</StyledP>
  }

  if (!isMounted) return null

  return (
    // 共通コンポーネントレイアウト調整用のdiv
    <StyledDiv className="c-mypageNews">
      <SwitchableList
        episodes={metas}
        className="myPageList"
        placeholder="新着動画の中から探す"
        showDelivery
      />
    </StyledDiv>
  )
}

export default NewArrival

NewArrival.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object,
  history: PropTypes.object,
  falcorModel: PropTypes.object
}
