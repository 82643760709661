import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import Cookie from 'js-cookie'
import classnames from 'classnames'
import {
  META_SCHEMA_ID,
  SVOD_DELIVERY_PATTERN,
  EPISODE_DISPLAY_MODE,
  PRODUCT_SCHEMA
} from '../../../../constants/app'
import webApp from '../../../exdio/utils/exdioWebAppUtils'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** 動画、番組リスト要素コンポーネント */
export default class ProgramItem extends Component {
  static propTypes = {
    // 主情報
    /** メタ */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.shape({
        evis_EpisodeLongSynopsis: PropTypes.string,
        evis_SeasonLongSynopsis: PropTypes.string,
        evis_SeriesLongSynopsis: PropTypes.string,
        parents_series: PropTypes.shape({
          avails_SeriesTitleDisplayUnlimited: PropTypes.string
        }),
        parents_season: PropTypes.shape({
          avails_SeasonTitleDisplayUnlimited: PropTypes.string
        }),
        avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
        ex_templateId: PropTypes.number
      }).isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }),
    /** パック商品 */
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired
    }),
    /** 月額見放題パックコース */
    course: PropTypes.shape({
      name: PropTypes.string.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired
    }),

    // 副情報
    /** 有料単話メタHowToPlay */
    howToPlay: PropTypes.shape({
      products: PropTypes.arrayOf(
        PropTypes.shape({
          active_pricing: PropTypes.shape({
            price: PropTypes.number,
            unit: PropTypes.string
          })
        })
      )
    }),

    // レコメンドデータ
    recommendItem: PropTypes.object,

    // イベントハンドラ
    // 各onClickは引数に(event)を渡して呼び出される
    onClick: PropTypes.func,
    onClickThumbnail: PropTypes.func,
    onClickCaption: PropTypes.func,
    onClickTitle: PropTypes.func,
    /** 削除時callback. 設定されている場合のみ削除ボタンを表示する. */
    onClickDelete: PropTypes.func,
    /** 再生時callback. 設定されている場合のみ再生ボタンを表示する. */
    onClickPlay: PropTypes.func,

    // 表示制御
    /** NEWマークを表示するか */
    showNew: PropTypes.bool,
    /** チェックマークを表示するか */
    showChecked: PropTypes.bool,
    /** 視聴期限(あとn日)を表示するか */
    showRemaining: PropTypes.bool,
    /** あらすじ(長)を表示するか */
    showCaption: PropTypes.bool,
    /** 配信日を表示するか */
    showDelivery: PropTypes.bool,
    /** 配信期限を表示するか */
    showDeadLine: PropTypes.bool,
    /** コインを表示するか */
    showCoin: PropTypes.bool,
    /** コイン非表示に無料を表示するか */
    showFreeCoinOnly: PropTypes.bool,
    /** (最新話)を表示するか */
    showLatest: PropTypes.bool,
    /** サムネ下部ラベル文言を表示するか */
    showBottomLabel: PropTypes.bool,
    /** 見放題パックアイコンを表示するか */
    showInCourse: PropTypes.bool,
    /** サブタイトルのみを表示するか */
    onlySubTitle: PropTypes.bool,
    /** サブタイトルを改行して表示するか */
    breakSubTitle: PropTypes.bool,
    /** 配信日フォーマット */
    deliveryEndFormat: PropTypes.string,
    /** 配信期限を配信日より優先して表示するか */
    showDeliveryEndPriorToStart: PropTypes.bool,
    /** 番組へのリンクとして表示するか */
    programLink: PropTypes.bool,

    /** 強制的にタイトルを指定する場合に設定 */
    title: PropTypes.string,
    /** 強制的にサムネイル画像を指定する場合に設定 */
    thumbnail: PropTypes.string,
    showEndText: PropTypes.bool,
    /** c-card-inBox-meta-titlesの1025px以上のmin-heightをキャンセル */
    titlesMinHeight: PropTypes.bool,
    /** c-card-inBox-meta-captionを一行に収める */
    textOverFlow: PropTypes.bool
  }

  static defaultProps = {
    meta: null,
    product: null,
    course: null,
    recommendItem: null,

    onClick: null,
    onClickThumbnail: null,
    onClickCaption: null,
    onClickTitle: null,
    onClickDelete: null,
    onClickPlay: null,

    showNew: true,
    showChecked: false,
    showRemaining: false,
    showCaption: false,
    showDelivery: false,
    showDeadLine: false,
    showCoin: false,
    showFreeCoinOnly: false,
    showLatest: false,
    showBottomLabel: false,
    showInCourse: false,
    onlySubTitle: false,
    breakSubTitle: false,
    deliveryEndFormat: null,
    showDeliveryEndPriorToStart: false,
    programLink: false,
    showEndText: false,
    titlesMinHeight: true,
    textOverFlow: false
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    const { meta, product, course } = props
    this.state = {}
    this.setupFlags(meta, product, course)
  }

  setupFlags(meta, product, course) {
    if (!meta) return
    const metaSchemaId = meta.meta_schema_id
    const displayMode = webApp.utils.getDisplayMode(meta, product, course)
    switch (displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        this.isFree = true
        break
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.UNKNOWN:
      default:
        this.isFree = false
        break
    }
    this.isNotFree = !this.isFree
    this.isEpisode = this.isFree || this.isNotFree
    this.isSeries =
      META_SCHEMA_ID.SERIES === metaSchemaId ||
      META_SCHEMA_ID.LIVE_SERIES === metaSchemaId
    this.isSeason =
      META_SCHEMA_ID.SEASON === metaSchemaId ||
      META_SCHEMA_ID.LIVE_SEASON === metaSchemaId
    this.isProgram = this.isSeries || this.isSeason
  }

  /** タイトル */
  title() {
    const { meta, product, course, title } = this.props
    if (title) return [title, '']
    return webApp.utils.titles(meta, product, course)
  }

  /** サムネイル画像 */
  thumbnail() {
    const { meta, product, course, thumbnail } = this.props
    if (thumbnail)
      return [webApp.utils.customSizeImageUrl(thumbnail, 'medium'), '']

    let thumbnailUrl = null
    let thumbnailAlt = null
    if (meta) {
      thumbnailUrl = meta.thumbnail_url
      const [title, subTitle] = this.title()
      thumbnailAlt = `${title} ${subTitle}`
    } else if (product) {
      thumbnailUrl = product.thumbnail_url
      thumbnailAlt = product.name
    } else if (course) {
      thumbnailUrl = course.thumbnail_url
      thumbnailAlt = course.name
    }

    /* 読み込む画像サイズを変更する */
    thumbnailUrl = webApp.utils.customSizeImageUrl(thumbnailUrl, 'medium')

    return [thumbnailUrl || this.config.default_thumbnail, thumbnailAlt]
  }

  bottomLabel() {
    const { product, course } = this.props
    return webApp.utils.productLabel(product, course)
  }

  deliveryStartAt() {
    const { meta, product, course, showDeliveryEndPriorToStart } = this.props
    if (showDeliveryEndPriorToStart) {
      const deliveryEndAt = this.deliveryEndAt(meta, product, course)
      if (deliveryEndAt) return ''
    }
    if (!meta) return ''
    if (
      [
        META_SCHEMA_ID.EPISODE,
        META_SCHEMA_ID.EPISODE_NOT_FREE,
        META_SCHEMA_ID.LIVE,
        META_SCHEMA_ID.LIVE_NOT_FREE
      ].includes(meta.meta_schema_id)
    ) {
      const deliveryStartAt = _.get(meta, ['delivery_start_at'])
      if (!deliveryStartAt) return ''
      return moment(deliveryStartAt).format('YYYY/M/D配信')
    }
    // 表示しなくてよいとのことなので一旦コメントアウト
    // ※下記のコードだとちなみにupdated_atはまだレスポンスにいれていないので、サーバ側の対応が必要
    //if ([META_SCHEMA_ID.SEASON, META_SCHEMA_ID.SERIES].includes(meta.meta_schema_id)) {
    //  const updatedAt = _.get(meta, ['updated_at']);
    //  if (!updatedAt) return '';
    //  return moment(updatedAt).format('YYYY/M/D更新');
    //}
    return ''
  }

  deliveryEndAt() {
    const { meta, course, purchased, deliveryEndFormat } = this.props
    if (meta) {
      if (
        meta.meta_schema_id === META_SCHEMA_ID.EPISODE ||
        meta.meta_schema_id === META_SCHEMA_ID.LIVE
      ) {
        const deliveryEndAt = (_.get(meta, ['delivery_end_at']) || '').replace(
          /\//g,
          '-'
        )
        if (!deliveryEndAt) return ''
        return moment(deliveryEndAt).format(
          deliveryEndFormat || `YYYY/M/D HH:mmまで`
        )
      }
    } else if (course) {
      if (purchased && purchased.length > 0) {
        return webApp.utils.deadLine(
          this.context,
          purchased[0].limit_date,
          true
        )
      }
    }
    return ''
  }

  /** あらすじ */
  renderCaption() {
    const { meta, product, course, textOverFlow } = this.props
    let synopsis = ''
    if (meta) {
      synopsis = meta.values.evis_EpisodeLongSynopsis
      if (this.isSeason) {
        synopsis = meta.values.evis_SeasonLongSynopsis
      } else if (this.isSeries) {
        synopsis = meta.values.evis_SeriesLongSynopsis
      }
    } else if (product) {
      synopsis = product.description
    } else if (course) {
      synopsis = course.description
    }
    if (synopsis)
      return (
        <StyledCaption
          className="c-card-inBox-meta-caption"
          textOverFlow={textOverFlow}
        >
          {synopsis}
        </StyledCaption>
      )
  }

  /** 価格 */
  renderCoin() {
    const {
      meta,
      howToPlay,
      product,
      course,
      showFreeCoin,
      recommendItem
    } = this.props
    if (meta) {
      // シリーズ・シーズンは表示なし
      if (this.isSeries || this.isSeason) return null
      if (recommendItem) {
        // recomendItemを指定された場合はdisplayModeをみて無料判定
        // 以降の判定は、metaにセットされたデータに準じて表示するようにする
        switch (recommendItem.display_mode) {
          case EPISODE_DISPLAY_MODE.FREE:
          case EPISODE_DISPLAY_MODE.TVOD_FREE:
          case EPISODE_DISPLAY_MODE.SVOD_FREE:
          case EPISODE_DISPLAY_MODE.STVOD_FREE:
            return <div className="c-card-inBox-meta-price free">無料</div>
            break
          case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
          case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
          case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
          case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
          case EPISODE_DISPLAY_MODE.UNKNOWN:
          default:
            break
        }
      }

      if (this.isFree) {
        return <div className="c-card-inBox-meta-price free">無料</div>
      } else if (this.isNotFree) {
        if (!howToPlay) {
          return null
        }

        // 単話TVOD価格
        const howToPlayProducts = _.get(howToPlay, ['products']) || []
        const singleStoryProduct = howToPlayProducts.find(
          (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
        )
        const productPrice = _.get(singleStoryProduct, ['active_pricing'])

        // 月額見放題パックに含まれる場合
        const isInCourse = !!howToPlay.courses.length
        let coursePrice = null
        if (isInCourse) {
          coursePrice = _.get(howToPlay, [
            'courses',
            0,
            'active_pricing',
            'price'
          ])
        }

        let displayProductPrice = null
        let displayCoursePrice = null
        let isFreeInCourse = false
        if (isInCourse) {
          if (meta.values.svod_delivery_pattern == SVOD_DELIVERY_PATTERN.FREE) {
            isFreeInCourse = true
          } else if (
            meta.values.svod_delivery_pattern == SVOD_DELIVERY_PATTERN.AUTH_FREE
          ) {
            // ログイン時無料設定がされていた場合はログイン済みなら無料
            const isLoggedIn = webApp.utils.isLoggedIn(this.context)
            if (isLoggedIn) {
              isFreeInCourse = true
            }
          }
        }

        if (isFreeInCourse) {
          return <div className="c-card-inBox-meta-price free">無料</div>
        } else {
          if (productPrice && productPrice.price !== 0) {
            displayProductPrice = productPrice.price
          }
          if (coursePrice && coursePrice.price !== 0) {
            displayCoursePrice = (
              <React.Fragment>
                {displayPrice}月額{coursePrice}円
              </React.Fragment>
            )
          }
        }

        let displayPrice = null
        if (displayCoursePrice) {
          // コースの金額が設定されている場合は、コースのみ表示するか、コースとTVOD有料(0円以上)の２パターンが表示される
          // if (displayProductPrice) {
          //   displayPrice = (<React.Fragment>{displayProductPrice}<span>or</span></React.Fragment>);
          // }
          // displayPrice = (<React.Fragment>{displayPrice}{displayCoursePrice}</React.Fragment>);

          // APPでは価格表示なし
          if (webApp.utils.isApp(this.context)) return null

          // 無料のみの表示の場合は表示しない
          if (showFreeCoin) return null

          //月額料金表示のみの場合はコインアイコンを表示しない
          return (
            <React.Fragment>
              {displayProductPrice === null ? (
                <div className="c-card-inBox-meta-price c-card-inBox-meta-price-in-course hide-coin">
                  {displayCoursePrice}
                </div>
              ) : (
                <div className="c-card-inBox-meta-price c-card-inBox-meta-price-in-course">
                  {displayProductPrice}
                  <span>or</span>
                  {displayCoursePrice}
                </div>
              )}
            </React.Fragment>
          )
        } else {
          // 月額見放題パックに含まれない場合は
          // TVOD無料(0円とTVOD有料の2パターンが表示される
          displayPrice = displayProductPrice
          if (productPrice && productPrice.price === 0) {
            return <div className="c-card-inBox-meta-price free">無料</div>
          }

          // APPでは価格表示なし
          if (webApp.utils.isApp(this.context)) return null

          // 無料のみの表示の場合は表示しない
          if (showFreeCoin) return null

          return <div className="c-card-inBox-meta-price">{displayPrice}</div>
        }
      }
    } else if (product) {
      // APPでは価格表示なし
      if (webApp.utils.isApp(this.context)) return null
      // 無料のみの表示の場合は表示しない
      if (showFreeCoin) return null
      const activePrice = _.get(product, ['active_pricing'])
      return (
        activePrice && (
          <div className="c-card-inBox-meta-price">{activePrice.price}</div>
        )
      )
    } else if (course) {
      // APPでは価格表示なし
      if (webApp.utils.isApp(this.context)) return null
      // 無料のみの表示の場合は表示しない
      if (showFreeCoin) return null
      const activePrice = _.get(course, ['active_pricing'])
      return (
        activePrice && (
          <div className="c-card-inBox-meta-price">
            月額{activePrice.price}円
          </div>
        )
      )
    }
    return null
  }

  handleClick(propFunc, e) {
    e.preventDefault()
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation()
      propFunc(e)
    }
  }

  checkRemainingDays = (endDateStr, days = 7) => {
    const today = new Date()
    const endDate = new Date(endDateStr)

    // 残り日数を計算
    const diffTime = endDate - today
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    // 指定された日数以内かどうかのフラグを返す
    return diffDays <= days
  }

  render() {
    const {
      meta,
      howToPlay,
      onClick,
      onClickThumbnail,
      onClickCaption,
      onClickTitle,
      onClickDelete,
      onClickPlay,
      showNew,
      showChecked,
      showRemaining,
      showCaption,
      showDelivery,
      showDeadLine,
      showCoin,
      showFreeCoinOnly,
      showLatest,
      showBottomLabel,
      showInCourse,
      onlySubTitle,
      breakSubTitle,
      programLink,
      showEndText,
      titlesMinHeight
    } = this.props

    const [title, subTitle] = this.title()
    const [thumbnailUrl, thumbnailAlt] = this.thumbnail()
    const isWithin7Days = webApp.utils.checkRemainingDays(
      _.get(meta, ['delivery_end_at'])
    )
    const remaining = webApp.utils.remaining(
      this.context,
      _.get(meta, ['delivery_end_at'])
    )
    const progress = webApp.utils.progress(this.config, meta)
    const isInCourseNotFree =
      !!_.get(howToPlay, ['courses', 'length']) && this.isNotFree
    const duration = webApp.utils.duration(meta)
    const isNew = webApp.utils.isNew(this.context, meta)
    const showInCourseIcon = showInCourse && isInCourseNotFree
    //配信終了判定
    let inViewTerm = true
    const prevTime = Cookie.get(this.previewUrlList + this.cookieRubocopPrefix)
    if (meta && moment(prevTime).isAfter(meta.delivery_end_at)) {
      inViewTerm = false
    }
    const deliveryEndAt = _.get(meta, ['delivery_end_at'])
    const isShowRemaining = showRemaining !== -1 || showRemaining

    return (
      <a
        href="#"
        className="c-card"
        onClick={(e) => this.handleClick(onClickThumbnail, e)}
      >
        <figure
          className={classnames('c-card-inBox', {
            clickable: typeof onClick === 'function'
          })}
          onClick={(e) => this.handleClick(onClick, e)}
        >
          <div className="c-card-inBox-art">
            <div className="c-card-inBox-art-artwork">
              {!inViewTerm && showEndText && !programLink && (
                <div className="c-card-inBox-art-artwork-mask">
                  <span>配信終了しました</span>
                </div>
              )}
              <img
                src={thumbnailUrl}
                alt={thumbnailAlt}
                width="200"
                height="112"
                className="c-card-inBox-art-artwork-img"
                loading="lazy"
              />
            </div>
            {showNew && isNew && <div className="c-artInfo-ribbonNew" />}

            {isShowRemaining && remaining && isWithin7Days && (
              <div data-chromatic="ignore" className="c-artInfo-period">
                {remaining}
              </div>
            )}

            <div className="c-artInfo-seekarea h3px">
              {progress > 0 && (
                <div
                  className="c-artInfo-seekarea-bar"
                  style={{ width: `${progress}%` }}
                />
              )}
            </div>
            {showBottomLabel && (
              <div className="c-artInfo-labelBottom">{this.bottomLabel()}</div>
            )}
          </div>

          <figcaption
            className={classnames('c-card-inBox-meta', {
              clickable:
                typeof onClickCaption === 'function' ||
                typeof onClick === 'function'
            })}
            onClick={(e) => this.handleClick(onClickCaption, e)}
          >
            <StyledDiv
              className={classnames('c-card-inBox-meta-titles', {
                clickable:
                  typeof onClickTitle === 'function' ||
                  typeof onClick === 'function'
              })}
              onClick={(e) => this.handleClick(onClickTitle, e)}
              titlesMinHeight={titlesMinHeight}
            >
              {showInCourseIcon && (
                <span className="c-card-inBox-meta-titles-inCourseBadge">
                  <img
                    src="/images/exdio/renewal/icon_unlimited_pack.png"
                    alt=""
                    loading="lazy"
                  />
                </span>
              )}
              <span
                className={classnames('c-card-inBox-meta-titles-title', {
                  noHidden: programLink,
                  isInCourse: !onlySubTitle && showInCourseIcon
                })}
              >
                {showNew && isNew && (
                  <span className="c-card-inBox-meta-title-new" />
                )}
                {showChecked && <span className="iconMylistAdded" />}
                {!onlySubTitle && title}
              </span>
              {!onlySubTitle && breakSubTitle && <br />}
              {!onlySubTitle && !breakSubTitle && ' '}
              {subTitle && (
                <span
                  className={classnames('c-card-inBox-meta-titles-titleSub', {
                    isInCourse: onlySubTitle && showInCourseIcon
                  })}
                >
                  {subTitle}
                  {showLatest && '（最新話）'}
                </span>
              )}
              {showDelivery && (
                <span className="c-card-inBox-meta-titles-on-air">
                  {this.deliveryStartAt()}
                </span>
              )}
            </StyledDiv>
            {duration && <p className="c-card-inBox-meta-time">{duration}</p>}
            {showCaption && this.renderCaption()}

            {showDelivery && (
              <div className="c-card-inBox-meta-onAir">
                {this.deliveryStartAt()}
              </div>
            )}
            {showDeadLine && (
              <div
                className={classnames('c-card-inBox-meta-viewingDeadline', {
                  isDeadline: this.checkRemainingDays(deliveryEndAt, 3)
                })}
              >
                {this.deliveryEndAt()}
              </div>
            )}

            {(showCoin || (!showCoin && showFreeCoinOnly)) && this.renderCoin()}
          </figcaption>
          {onClickDelete && (
            <div className="c-card-inBox-remove">
              <div
                className="c-card-inBox-remove-link"
                onClick={(e) => this.handleClick(onClickDelete, e)}
              >
                <img
                  src="/images/exdio/renewal/icon_remove_mylist.svg"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          )}
          {onClickPlay && (
            <div className="c-card-inBox-btnPlay">
              <div
                className="c-card-inBox-btnPlay-link"
                onClick={(e) => this.handleClick(onClickPlay, e)}
              >
                <img
                  src="/images/exdio/renewal/icon_list_btn_play.svg"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          )}
        </figure>
      </a>
    )
  }
}

const StyledDiv = styled.div`
  ${({ titlesMinHeight }) =>
    !titlesMinHeight &&
    css`
      min-height: auto !important;
    `}
`

const StyledCaption = styled.div`
  ${({ textOverFlow }) =>
    textOverFlow &&
    css`
      display: block;
      font-size: 1.3rem;
      overflow: hidden;
      margin-top: 0.4rem;
      line-height: 1.4;
      max-height: 1.4em;
      text-overflow: ellipsis;
      max-width: 196px;
      white-space: nowrap;
    `}
`
