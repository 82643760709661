import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import styled, { css } from 'styled-components'

/** components */
import ProgramBanners from './ProgramBanners'
import SnsButtons from './SnsButtons'
import PageTop from './PageTop'

/** style */
import { mediaSp, contentWidth } from './styles'

/** しんちゃん Footer */
const Footer = ({ theme = 'normal', ...props }, context) => {
  const host = get(context, ['models', 'config', 'data', 'host'], '')
  const planPageHref = `${host}/plan/shinchan`
  const snsHref = {
    twitter: `http://twitter.com/share?url=${planPageHref}&text=クレヨンしんちゃんぶりぶりCLUB｜テレ朝動画`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${planPageHref}`,
    line: `https://social-plugins.line.me/lineit/share?url=${planPageHref}`
  }

  return (
    <StyledFooter theme={theme} {...props}>
      <StyledDiv>
        <ProgramBanners />
        <StyledSnsButtons href={snsHref} />
      </StyledDiv>
      <StyledPageTop />
    </StyledFooter>
  )
}

export default Footer

Footer.propTypes = {
  theme: PropTypes.oneOf([
    'normal',
    'spring',
    'rainy',
    'summer',
    'autumn',
    'winter',
    'movie',
    'buriburiclub'
  ])
}

Footer.contextTypes = {
  models: PropTypes.object
}

const StyledFooter = styled.footer.withConfig({
  shouldForwardProp: (prop) => !['theme'].includes(prop)
})`
  padding: 60px 0;

  ${({ theme }) => {
    switch (theme) {
      /** 春テーマ */
      case 'spring':
        return css`
          background: url(/douga_mv/shinchan/svod/images/footer/spring/bg.png)
              repeat-x top center / auto 100%,
            linear-gradient(#fadce2, #ffffff);

          @media ${mediaSp} {
            background: url(/douga_mv/shinchan/svod/images/footer/spring/bg_sp.png)
                repeat top left,
              linear-gradient(#fadce2, #ffffff);
          }
        `

      /** 梅雨テーマ */
      case 'rainy':
        return css`
          background: url(/douga_mv/shinchan/svod/images/footer/rainy/bg.png)
              repeat-x top center / auto 100%,
            linear-gradient(#ff8cce, #fdc0d4);
        `

      /** 夏テーマ */
      case 'summer':
        return css`
          background: url(/douga_mv/shinchan/svod/images/footer/summer/cloud-l.svg)
              no-repeat 8% 20%,
            #ccf0ff
              url(/douga_mv/shinchan/svod/images/footer/summer/cloud-r.svg)
              no-repeat 100% 10%;
        `

      /** 秋テーマ */
      case 'autumn':
        return css`
          background: url(/douga_mv/shinchan/svod/images/footer/autumn/cloud-l.png)
              no-repeat 8% 20%,
            url(/douga_mv/shinchan/svod/images/footer/autumn/cloud-r.png)
              no-repeat 100% 10%,
            linear-gradient(#ffb640, #ffdd40 23%, #ffffff);

          @media ${mediaSp} {
            background-position: top 10px left 10px, bottom 20px right 10px,
              center;
          }
        `

      /** 冬テーマ */
      case 'winter':
        return css`
          background: url(/douga_mv/shinchan/svod/images/footer/winter/bg.png)
              repeat-x top center / auto 100%,
            linear-gradient(#40b7e9, #3892d7);

          @media ${mediaSp} {
            background-repeat: repeat-y;
            background-size: 100% auto;
          }
        `

      /** 映画テーマ */
      case 'movie':
        return css`
          background: #fdd10b;
        `

      /** ぶりぶりclub */
      case 'buriburiclub':
        return css`
          background: url(/douga_mv/shinchan/svod/images/footer/buriburiclub/bg.png)
              repeat-x top center / auto 100%,
            #d3f3ff;

          @media ${mediaSp} {
            background: url(/douga_mv/shinchan/svod/images/footer/buriburiclub/bg_sp.png)
                repeat-y center center / 100% auto,
              #d3f3ff;
          }
        `

      default:
        return null
    }
  }}

  @media ${mediaSp} {
    padding: 10px;
    position: relative;
  }
`

const StyledDiv = styled.div`
  margin: 0 auto;
  max-width: ${contentWidth};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mediaSp} {
    padding: 0 15px;
  }
`

const StyledSnsButtons = styled(SnsButtons)`
  margin-top: 30px;

  @media ${mediaSp} {
    margin-top: 20px;
  }
`

const StyledPageTop = styled(PageTop)`
  position: fixed;
  right: 60px;
  bottom: 60px;
  z-index: 99;

  @media ${mediaSp} {
    margin: 0 auto;
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
  }
`
