import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../../../../sketch-platform/ui/routing/Link';
import routes from '../../routes';
import webApp from '../../../exdio/utils/exdioWebAppUtils';

/** メダルについてリンク・モーダル */
export default class AboutMedalLink extends Component {
  static propTypes = {
    /** 「メダルについて」リンクに付与するclassName */
    linkClassName: PropTypes.string
  };

  static defaultProps = {
    linkClassName: ''
  };

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.config = context.models.config.data;

    this.state = {
      isLoggedIn: webApp.utils.isLoggedIn(this.context),
      show: false
    };

    this.onClickLink = this.onClickLink.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClickLink(e) {
    e.preventDefault();
    document.body.classList.add('modal-open');
    this.setState({ show: true });
  }

  onClickClose() {
    document.body.classList.remove('modal-open');
    this.setState({ show: false });
  }

  render() {
    const { linkClassName } = this.props;
    const { isLoggedIn } = this.state;
    const modalClassName = classnames('c-modal m-aboutCoin', { on: this.state.show });
    const aboutMedalList = this.config.extras.about_medal_list;
    const autoChargeList = this.config.extras.auto_charge_list;
    const coinRate = 11;
    const route = isLoggedIn ? routes.medal_charge : routes.login
    const query = isLoggedIn
      ? { src: this.context.routeHandler.url }
      : { redirect: routes.medal_charge.makePath({}, {src: this.context.routeHandler.url})}

    return [
      <a key="link" className={`about-coin ${linkClassName}`} onClick={this.onClickLink}>
        メダルについて
      </a>,
      <div key="modal" className={modalClassName} onClick={this.onClickClose}>
        <div className="c-modal-inBox" onClick={e => e.stopPropagation()}>
          <div className="c-modal-inBox-close" onClick={this.onClickClose} />
          <div className="c-modal-inBox-cont">
            <div className="aboutCoin">
              <div className="aboutCoin-cont">
                <div className="aboutCoin-cont-inBox">
                  <div className="aboutCoin-cont-heading">
                    月額チャージ
                  </div>
                  {autoChargeList.map(v => (
                    <div key={v.coin} className="aboutCoin-cont-inBox-article autoCharge">
                      <div>
                        <span>毎月</span>
                        <span className="aboutCoin-cont-inBox-article-coin">{v.coin}</span>
                        <span className="aboutCoin-cont-inBox-article-text1">毎月<span className="aboutCoin-cont-inBox-article-coinIcon"></span>{v.otoku}（{v.otoku * coinRate}円分）お得！</span>
                      </div>
                      <div className="aboutCoin-cont-inBox-article-price">
                        <span className="aboutCoin-cont-inBox-article-price-prefix">月額</span>
                        {v.price}円<span className="aboutCoin-cont-inBox-article-price-tax">(税込)</span>
                      </div>
                      <p className="aboutCoin-cont-inBox-article-text2">
                        ※視聴目安：バラエティ{v.variety}本、もしくはドラマ{v.drama}本視聴可能！
                        {v.balloon ? <span className="aboutCoin-cont-inBox-article-balloon">{v.balloon}</span> : null}
                      </p>
                    </div>
                  ))}
                  <p className="aboutCoin-cont-inBox-article-text2">※視聴目安：バラエティ20メダル、ドラマ30メダルと計算。あくまで目安のため、番組によってはメダル数が変動します。</p>
                  <div className="aboutCoin-cont-heading">
                    個別チャージ
                  </div>
                  {aboutMedalList.map(v => (
                    <div key={v.coin} className="aboutCoin-cont-inBox-article">
                      <div>
                        <span className="aboutCoin-cont-inBox-article-coin">{v.coin}</span>
                      </div>
                      <div className="aboutCoin-cont-inBox-article-price">
                        {v.price}円<span className="aboutCoin-cont-inBox-article-price-tax">(税込)</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="aboutCoin-charge">
                <Link
                  route={route}
                  query={query}
                  className="aboutCoin-charge-link"
                >
                  チャージする
                </Link>
              </div>
              <div className="aboutCoin-kwsk">
                <a className="aboutCoin-kwsk-link" href={this.config.dougaLink.AboutMedalKwsk}>もっと詳しく</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ];
  }
}
