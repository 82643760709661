import styled from 'styled-components'
import { breakPoints, mediaQuery } from '../../../../exdio/components/style'

// eslint-disable-next-line import/prefer-default-export
export const StyledLabel = styled.label.withConfig({
  shouldForwardProp: (prop) => !['checked'].includes(prop)
})`
  padding: 10px 30px 10px 10px;
  min-height: 36px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  ${mediaQuery()} {
    padding: 0 5px;
    background-color: ${({ checked }) => (checked ? '#c3f74a' : null)};
  }

  &::after {
    margin: auto 0;
    width: 12px;
    height: 5px;
    border: solid #333;
    border-width: 0 0 2px 2px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    transform: translate(-50%, -50%) rotate(-45deg);

    ${mediaQuery(breakPoints.sm, 'min')} {
      content: ${({ checked }) => (checked ? '""' : null)};
    }
  }

  &:hover {
    @media (hover: hover) {
      background-color: #f3f4f5;
    }
  }
`
