/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'

export const StyledSpan = styled.span`
  color: #fff;
  font-weight: 700;
  letter-spacing: 6px;
  display: inline-block;
  padding: 10px 0 18px 10px;
  width: 100%;

  ${mediaQuery()} {
    letter-spacing: inherit;
    padding: 0;
  }
`
