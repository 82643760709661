import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import routes from '../../../../../../../common/routes'

/* components */
import Btn1 from '../../button/Btn1'
import Btn2 from '../../button/Btn2'

/* style */
import { StyledNav, StyledUl, StyledLi } from './style'

/** Logirlプランページ | ヘッダー下ナビゲーション */
const Nav2 = ({ ...props }) => {
  const purchasePath = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 'https://st-www.tvasahi.jp/douga_mv/purchase/logirl/'
    : 'https://wws.tv-asahi.co.jp/douga_mv/purchase/logirl/'

  return (
    <StyledNav {...props}>
      <StyledUl>
        <StyledLi>
          <Btn2
            href="https://twitter.com/LoGiRL2015"
            target="_blank"
            icon={{
              src: '/images/exdio/renewal/logirl/icon/X-2.svg',
              alt: 'X'
            }}
          />
        </StyledLi>
        <StyledLi>
          <Btn2
            href="https://www.instagram.com/tv_asahi_logirl/"
            target="_blank"
            icon={{
              src: '/images/exdio/renewal/logirl/icon/instagram.svg',
              alt: 'instagram'
            }}
          />
        </StyledLi>
        <StyledLi>
          <StyledBtn1
            route={routes.logirl_static}
            params={{ url: 'about' }}
            text="logirl紹介・見放題について"
            icon={{
              src: '/images/exdio/renewal/logirl/icon/question.svg',
              alt: ''
            }}
            color=""
            borderColor=""
          />
        </StyledLi>
        <StyledLi>
          <StyledBtn1
            href={purchasePath}
            text="会員になる"
            icon={{
              src: '/images/exdio/renewal/logirl/icon/member.svg',
              alt: ''
            }}
            color="#00a3a3"
          />
        </StyledLi>
      </StyledUl>
    </StyledNav>
  )
}
export default Nav2

Nav2.propTypes = {
  className: PropTypes.string
}

Nav2.defaultProps = {
  className: ''
}

const StyledBtn1 = styled(Btn1)`
  margin-left: 16px;
`
