import React, { Component } from 'react';
import PropTypes from 'prop-types';
import window from 'global';
import Link from '../../../../sketch-platform/ui/routing/Link';
import routes from '../../routes';
import webApp from '../../../exdio/utils/exdioWebAppUtils';
import { LOCAL_STORAGE_KEY_NOTICE_ARTICLES } from '../../../../constants/app';

/** メンテナンスメッセージ クラスコンポーネント */
export default class MPMaintenanceMessage extends Component {

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    webApp: PropTypes.object,
    history: PropTypes.object,
  };

  static propTypes = {
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        msg: PropTypes.string
      })
    )
  };

  static defaultProps = {
    messages: []
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.config = context.models.config.data;
    this.state = {
      isShow: false,
      notShowIds: [],
      updateAt: [],
      notShowArticles: []
    }
    this.clickClose = this.clickClose.bind(this);
    this.DoraemonSeasonId = ['development', 'staging'].includes(process.env.NODE_ENV) ? 11003 : 13509;
  }

  componentDidMount() {
    const tmpStorage = window.localStorage.getItem(LOCAL_STORAGE_KEY_NOTICE_ARTICLES);
    if (tmpStorage) {
      const storage = JSON.parse(tmpStorage);
      const ids = storage.articles.map(article => article.id);  //ローカルストレージに保存されている記事のid
      const update_at = storage.articles.map(article => article.update_at);  //ローカルストレージに保存されている記事の更新時間
      this.setState({
        notShowIds: this.state.notShowIds.concat(ids),
        updateAt: this.state.updateAt.concat(update_at)
      });
    }
  }

  /** 閉じるボタン押下時 */
  clickClose(id, time) {
    const { notShowIds, updateAt } = this.state;

    if (!notShowIds.includes(id) || !updateAt.includes(time)) {  //ローカルストレージにあるデータの中でidを含んでいない、または更新日時が一致しない場合
      this.setState({
        notShowIds: this.state.notShowIds.concat(id),
        updateAt: this.state.updateAt.concat(time)
      });
      const item = {  //閉じるボタンを押した記事データ
        id: id,
        update_at: time
      }
      this.setState({notShowArticles: this.state.notShowArticles.concat(item)}, () => {  //stateに保存しておかないと記事が複数あった場合一気にローカルストレージに保存出来ない
        const tmpStorage = window.localStorage.getItem(LOCAL_STORAGE_KEY_NOTICE_ARTICLES);
        if (tmpStorage) {
          const storage = JSON.parse(tmpStorage);
          const filteredArticles = storage.articles.filter(article => {
            return id !== article.id
          })
          const articles = filteredArticles.concat(item);
          window.localStorage.setItem(
            LOCAL_STORAGE_KEY_NOTICE_ARTICLES,
              JSON.stringify({
                articles
              })
            );
        } else {  //ローカルストレージにデータがない場合は閉じるボタンを押した記事をそのまま保存
          const articles = this.state.notShowArticles;
          window.localStorage.setItem(
            LOCAL_STORAGE_KEY_NOTICE_ARTICLES,
              JSON.stringify({
                articles
              })
            );
        }
      })
    }
  }

  render() {
    const route = webApp.utils.isApp(this.context) ? routes.app_information : routes.information;
    const { messages, seasonId } = this.props;
    const { notShowIds, updateAt } = this.state;
    const doraemonArticleId = ['development', 'staging'].includes(process.env.NODE_ENV) ? 100 : 82;

    return (
      <div className="c-headAnnounce">
        {messages.map((msg, i) => {
          const msgId = msg.id;
          //dioのcaptionにURLが設定されている場合はaタグにする
          const msgLink = msg.caption;
          const regExp =  /([A-Za-z]{3,9})(?::\/\/|@)((?:[A-Za-z0-9\-.]+[.:])|(?:www\.|[-;:&=+$,\w]+@))([A-Za-z0-9.-]+)([/\-+=&;%@.\w_~()]*)([.!/\\\w-?%#~&=+()]*)/  //url判別
          const result = regExp.test(msgLink);

          //特定の記事はドラえもん番組配下ページ、topページのみ表示
          if ((msgId !== doraemonArticleId) || (msgId === doraemonArticleId && seasonId === this.DoraemonSeasonId)) {
            return(
              <div className={`c-headAnnounce-box ${i > 0 ? 'mt10' : ''} ${(notShowIds.includes(msg.id) && updateAt.includes(msg.updated_at)) ? 'mp-none' : ''}`} key={i}>
                {(msgLink !== "" && result) ? (
                  <a href={msg.caption} className="c-headAnnounce-link" key={msg.id}>
                    {msg.msg}
                  </a>
                ) : (
                  <Link
                    key={msg.id}
                    route={route}
                    className={`c-headAnnounce-link `}
                    hash={`news-${msg.id}`}
                  >
                    {msg.msg}
                  </Link>
                )}
                <div className="c-headAnnounce-close" onClick={() => this.clickClose(msg.id, msg.updated_at)}>×</div>
              </div>
            )
          }
        })}
      </div>
    );
  }
}

