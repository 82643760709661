import React, { memo } from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'

/** そだてれび：海外視聴用:IconText */
const IconText = ({ icon, children }) => (
  <StyledP icon={icon}>{children}</StyledP>
)

export default memo(IconText)

IconText.propTypes = {
  /**
   * アイコンに使用する文字コード
   * バックスラッシュはエスケープする
   */
  icon: propTypes.string
}

IconText.defaultProps = {
  icon: ''
}

const StyledP = styled.p`
color: #515151;

&::before {
  margin-right: 5px;
  color: #515151;
  font-size: 1.2rem;
  font-family: 'Material Icons';
  content: '${({ icon }) => icon}';
}
`
