import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import webApp from '../../../../../utils/exdioWebAppUtils'

/* components */
import ListItem from '../EpisodeList/ListItem'

/* style */
import { mediaQuery } from '../../../../style'
import { StyledTitle } from '../EpisodeList/ListItem/style'
import { StyledChecked } from '../EpisodeList/ListItem/Title'
import { StyledSlider } from '../../styles'

/** ドラえもん つづきをみる */
const ProgressEpisodes = (
  { favorites = [], toggleFavorites = () => {}, episodes = [], ...props },
  context
) => {
  const sliderRef = useRef(null)

  /** スライダー設定 */
  const sliderSetting = {
    dots: false,
    infinite: false,
    speed: 400,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          variableWidth: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          variableWidth: true
        }
      }
    ]
  }

  return (
    <StyledSection {...props}>
      <StyledH2>つづきをみる</StyledH2>
      <StyledSlider ref={sliderRef} {...sliderSetting}>
        {episodes.map((meta) => {
          const isFavorite = favorites.find(
            (favorite) =>
              get(favorite, ['meta', 'meta_id']) === get(meta, ['meta_id'])
          )
          const itemRoutes = webApp.utils.getProgramLinkRoutes(
            context,
            meta,
            null,
            null,
            {
              autoPlay: true
            }
          )
          return (
            <StyledDiv key={get(meta, ['meta_id'])}>
              <StyledListItem
                meta={meta}
                showNew={webApp.utils.showNew(meta)}
                showChecked={webApp.utils.isWatched(meta, true)}
                onlySubTitle
                clampWidth={2}
                listType="grid"
                images={{
                  on: '/images/exdio/renewal/doraemon/watched.png',
                  off: '/images/exdio/renewal/doraemon/not_watched.png'
                }}
                {...itemRoutes}
              />
              <StyledLabel
                htmlFor={`fav-${get(meta, ['meta_id'])}`}
                title={isFavorite ? `マイリストから削除` : `マイリストに追加`}
                isFavorite={isFavorite}
              >
                <input
                  type="checkbox"
                  id={`fav-${get(meta, ['meta_id'])}`}
                  name="favorite"
                  value={get(meta, ['meta_id'])}
                  checked={Boolean(isFavorite)}
                  onChange={() => toggleFavorites(meta, !isFavorite)}
                />
                <img
                  src={`/images/exdio/renewal/doraemon/my_list_${
                    isFavorite ? 'added' : 'removed'
                  }.svg`}
                  width="20"
                  height="18"
                  alt=""
                />
              </StyledLabel>
            </StyledDiv>
          )
        })}
      </StyledSlider>
    </StyledSection>
  )
}

export default ProgressEpisodes

ProgressEpisodes.propTypes = {
  favorites: PropTypes.array,
  toggleFavorites: PropTypes.func,
  episodes: PropTypes.array,
  className: PropTypes.string
}

ProgressEpisodes.contextTypes = {
  history: PropTypes.object,
  routeHandler: PropTypes.object,
  falcorModel: PropTypes.object,
  models: PropTypes.object
}

const StyledSection = styled.section`
  margin: 0 auto;
  padding: 0 60px;
  max-width: 1800px;

  ${mediaQuery()} {
    padding: 0;
  }
`

const StyledH2 = styled.h2`
  font-weight: 700;
  font-size: 2.6rem;
  margin-bottom: 2rem;
`

const StyledDiv = styled.div`
  position: relative;
  display: inline-grid !important; // slickに上書きされるため!importantつける
  grid: repeat(2, auto) / 1fr auto;
  padding: 0 10px;

  ${mediaQuery()} {
    padding: 0;
  }
`

const StyledListItem = styled(ListItem)`
  grid-column: 1 / 3;
  grid-row: 1 / 3;

  ${StyledTitle} {
    padding-right: 45px;
    position: relative;

    ${StyledChecked} {
      margin-right: 0;
      width: 20px;
      height: 18px;
      position: absolute;
      top: 1px;
      right: 25px;
    }
  }
`

export const StyledLabel = styled.label.withConfig({
  shouldForwardProp: (prop) => !['isFavorite'].includes(prop)
})`
  position: relative;
  bottom: calc(15px * 1.25 * 2 - 20px); // ListItem > StyledPに合わせる
  z-index: 1;
  grid-column: 2;
  grid-row: 2;
  transition: opacity 0.4s;
  cursor: pointer;

  ${mediaQuery()} {
    bottom: calc(13px * 1.25 * 2 - 22px); // ListItem > StyledPに合わせる
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`
