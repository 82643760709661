import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQuery } from '../../../../style'
import Btn from '../Btn'

const FloatingBanner = (props, context) => {
  const purchasePath = `${context.models.config.data.purchase_plan.doraemon}?plan=1`
  const [isShow, setIsShow] = useState(false)
  const bannerRef = useRef(null)

  const updateFooterPadding = () => {
    const footer = document.querySelector('footer')

    if (footer) {
      const bannerHeight = bannerRef.current.clientHeight
      footer.style.paddingBottom = `${bannerHeight}px`
    }
  }

  const showBanner = () => {
    const scrollPosition = window.scrollY
    setIsShow(scrollPosition > 50)
  }

  useEffect(() => {
    window.addEventListener('scroll', showBanner)
    updateFooterPadding()

    return () => window.removeEventListener('scroll', showBanner)
  }, [])

  useEffect(() => {
    updateFooterPadding()
  }, [isShow])

  return (
    <StyledDiv ref={bannerRef} isShow={isShow}>
      <StyledP>
        月額550円で
        <br />
        ドラえもんのおはなしが見放題！
      </StyledP>
      <StyledBtn href={purchasePath} color="red" text="今すぐはじめる！" />
    </StyledDiv>
  )
}

export default FloatingBanner

FloatingBanner.contextTypes = {
  models: PropTypes.object
}

const StyledDiv = styled.div`
  opacity: ${({ isShow }) => (isShow ? '1' : '0')};
  z-index: ${({ isShow }) => (isShow ? '10' : '-1')};
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 10px 15px 10px;
  background: #00a0e9;
  color: #fff;
  text-align: center;
  transition: opacity 0.3s;

  ${mediaQuery(520)} {
    padding: 15px 10px 15px;
  }
`

const StyledP = styled.p`
  position: relative;
  width: fit-content;
  margin: 0 auto;
  font-size: 2.5rem;
  font-weight: 800;

  ${mediaQuery(520)} {
    font-size: 2rem;
  }

  &::before,
  &::after {
    position: absolute;
    bottom: 0;
    content: '';
    display: inline-block;
    width: 19px;
    height: 25px;
    background: url(/images/exdio/renewal/doraemon/bnr_decoration.svg) no-repeat
      top center / 100%;

    ${mediaQuery(520)} {
      width: 24px;
      height: 32px;
    }
  }

  &::before {
    left: 0;
    transform: translateX(-110%);
  }

  &::after {
    right: 0;
    transform: translateX(87%) scale(-1, 1);
  }

  br {
    display: none;

    ${mediaQuery(700)} {
      display: block;
    }
  }
`

const StyledBtn = styled(Btn)`
  position: relative;
  width: fit-content;
  height: auto;
  margin: 10px auto 0;
  padding: 10px 48px 10px 80px;
  font-size: 2rem;
  font-weight: 700;

  ${mediaQuery(520)} {
    margin-top: 10px;
    padding: 17px 59px 17px 90px;
    font-size: 2rem;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 60px;
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 11px;
    border-color: transparent transparent transparent #fff;
    border-radius: 3px;
    transform: translateY(-50%);
  }
`
