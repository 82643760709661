import React, { memo } from 'react'
import PropTypes from 'prop-types'
import routes from '../../routes'
import LinkCol from './LinkCol'
import generateItem from './LinkCol/functions/generateItem'
import useMediaQuery from '../../../hooks/useMediaQuery'

/** SP用ジャンルリンク */
const GenreLinksSp = ({ onClick = () => {}, ...props }, context) => {
  const isSp = useMediaQuery('max', 'mm')
  const genreList = context.models.config.data.genre_links_sp

  const genreItems = genreList.map((item, index) =>
    generateItem({
      content: {
        id: index,
        label: item.title
      },
      route: routes[item.route],
      href: item.url,
      params: item.params
    })
  )

  if (!isSp) return null

  return (
    <LinkCol
      spCol={2}
      items={genreItems}
      header={{
        title: 'ジャンル'
      }}
      onClick={onClick}
      {...props}
    />
  )
}
export default memo(GenreLinksSp)

GenreLinksSp.propTypes = {
  onClick: PropTypes.func
}

GenreLinksSp.contextTypes = {
  models: PropTypes.shape({})
}
