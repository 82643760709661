import styled from 'styled-components'
import { Link } from '../../../../../../../sketch-platform/ui/routing'
import { mediaQuery, breakPoints } from '../../../../style'

export const StyledLink = styled(Link)`
  width: 235px;
  height: 398px;
  display: block;
  position: relative;
  transition: opacity 0.4s ease-in-out;

  ${mediaQuery()} {
    margin: 0 auto;
    width: auto;
    height: auto;
  }
`

export const StyledImg1 = styled.img`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
  pointer-events: auto;

  &:hover {
    opacity: 0;
  }

  ${mediaQuery()} {
    display: none;
  }
`

export const StyledImg2 = styled(StyledImg1)`
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
  pointer-events: auto;

  &:hover {
    opacity: 1;
  }
`

export const StyledImg3 = styled.img`
  width: 100%;
  height: auto;
  vertical-align: bottom;

  ${mediaQuery('sm', 'min')} {
    display: none;
  }
`
