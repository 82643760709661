/** mediaQuery */
export const mediaSp = '(max-width: 1024px)'

/** Colors */
export const mainColor = '#1a1c3c'
export const white = '#b3b3b3'
export const keyColor = '#ffb500'

/** Fonts */
export const yuMincho = '"YuMincho", "Yu Mincho", serif'
export const yuGothic = '"YuGothic", "Yu Gothic", sans-serif'
