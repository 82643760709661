import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PANEL_ID, TABS_DATA } from './config'

/** components */
import SearchBoxYear from '../../../../common/components/FilterSort/SearchBoxYear'
import TabList from './TabList'
import Selection from './Selection/'
import Character from './Character'
import SpNav from './SpNav'

/** style */
import { mediaSp } from './styles'

/** タブリスト */
const TabPanel = (
  {
    selectedTabId = '',
    setSelectedTabId = () => {},
    selectedYear = null,
    setSelectedYear = () => {},
    selectedTags = [],
    setSelectedTags = () => {},
    selectionItems = [],
    characterItems = [],
    initialSlide = 0,
    disabled = false,
    ...props
  },
  ref
) => {
  /** SPモーダル開閉状況 */
  const [isModalOpen, setIsModalOpen] = useState(false)

  /** パネルがアクティブになるタブ */
  const actionableTabs = [PANEL_ID.SELECTION, PANEL_ID.AGE, PANEL_ID.CHARACTER]

  return (
    <StyledWrapper disabled={disabled} ref={ref} {...props}>
      <TabList
        tabsData={TABS_DATA}
        selectedTabId={selectedTabId}
        setSelectedTabId={setSelectedTabId}
        setIsModalOpen={setIsModalOpen}
      />
      <StyledDiv isActive={actionableTabs.indexOf(selectedTabId) > -1}>
        <StyledSelection
          selectedTabId={selectedTabId}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          selectionItems={selectionItems}
          isShow={selectedTabId === PANEL_ID.SELECTION}
        />
        <StyledSearchBoxYear
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          isShow={selectedTabId === PANEL_ID.AGE}
        />
        <StyledCharacter
          sliderItems={characterItems}
          selectedTabId={selectedTabId}
          setSelectedTags={setSelectedTags}
          initialSlide={initialSlide}
          isShow={selectedTabId === PANEL_ID.CHARACTER}
        />
      </StyledDiv>
      <SpNav
        isShow={isModalOpen}
        tabsData={TABS_DATA}
        selectedTabId={selectedTabId}
        setSelectedTabId={setSelectedTabId}
        setSelectedTags={setSelectedTags}
        setIsModalOpen={setIsModalOpen}
      />
    </StyledWrapper>
  )
}

export default forwardRef(TabPanel)

TabPanel.propTypes = {
  selectedTabId: PropTypes.string,
  setSelectedTabId: PropTypes.func,
  selectedYear: PropTypes.number,
  setSelectedYear: PropTypes.func,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  setSelectedTags: PropTypes.func,
  /** 厳選エピソードデータ */
  selectionItems: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      primary: PropTypes.shape({
        thumb: PropTypes.string,
        title: PropTypes.string,
        alt: PropTypes.string
      }),
      tag: PropTypes.string
    })
  ),
  /** キャラクター別データ */
  characterItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      category: PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string,
        img: PropTypes.string
      }),
      imgName: PropTypes.string,
      img: PropTypes.shape({
        on: PropTypes.string,
        off: PropTypes.string
      })
    })
  ),
  initialSlide: PropTypes.number,
  disabled: PropTypes.bool
}

const StyledWrapper = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : null)};
  cursor: ${({ disabled }) => (disabled ? 'default' : null)};
`

const StyledDiv = styled.div`
  /* height: ${({ isActive }) => (isActive ? '270px' : 'auto')}; */
  background-color: #fff;

  @media ${mediaSp} {
    height: auto;
  }
`

const StyledSelection = styled(Selection).withConfig({
  shouldForwardProp: (prop) => !['isShow'].includes(prop)
})`
  display: ${({ isShow }) => (isShow ? null : 'none')};

  .slick-slider {
    padding: 25px 25px 0;

    @media ${mediaSp} {
      padding: 10px 10px 0;
    }
  }
`

const StyledSearchBoxYear = styled(SearchBoxYear).withConfig({
  shouldForwardProp: (prop) => !['isShow'].includes(prop)
})`
  padding: 25px 25px 5px;
  display: ${({ isShow }) => (isShow ? null : 'none')};

  @media ${mediaSp} {
    padding: 10px 10px 5px;
  }
`

const StyledCharacter = styled(Character).withConfig({
  shouldForwardProp: (prop) => !['isShow'].includes(prop)
})`
  display: ${({ isShow }) => (isShow ? null : 'none')};

  .slick-slider {
    padding: 25px 25px 0;

    @media ${mediaSp} {
      padding: 10px 10px 0;
    }
  }
`
