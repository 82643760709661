import window from 'global'
import { get } from 'lodash'
import moment from 'moment'
import routes from '../../../../common/routes'
import {
  EPISODE_DISPLAY_MODE,
  PURCHASE_TYPE,
  LOCAL_STORAGE_KEY_PURCHASE
} from '../../../../../constants/app'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/**
 * 料金
 * @return {Array} [単一価格, 月額見放題価格]
 */
export const getPrice = (meta, status, product, course) => {
  if (!meta) return [null, null]

  // 無料
  switch (status.displayMode) {
    case EPISODE_DISPLAY_MODE.FREE:
    case EPISODE_DISPLAY_MODE.TVOD_FREE:
    case EPISODE_DISPLAY_MODE.SVOD_FREE:
    case EPISODE_DISPLAY_MODE.STVOD_FREE:
      return ['無料', null]

    // TVOD
    case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
      return [get(product, ['active_pricing', 'price']) || null, null]

    // STVOD(TVOD)
    case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
      return [
        get(product, ['active_pricing', 'price']) || null,
        get(course, ['active_pricing', 'price']) || null
      ]

    // SVOD
    case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
    case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
      return [null, get(course, ['active_pricing', 'price']) || null]

    // UNKNOWN
    case EPISODE_DISPLAY_MODE.UNKNOWN:
    default:
      return [null, null]
  }
}

/** 購入ページに遷移 */
export const gotoPurchasePage = (context, episodeId, seasonId) => {
  window.localStorage.setItem(
    LOCAL_STORAGE_KEY_PURCHASE,
    JSON.stringify({
      type: PURCHASE_TYPE.EPISODE,
      id: Number(seasonId),
      selected: [Number(episodeId)],
      page: context.routeHandler.url
    })
  )
  window.location.href = routes.purchase.makePath()
  // context.history.push(routes.purchase.makePath())
}
