import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQuery, breakPoints } from '../../../../style'
import { pink, white } from '../../styles'

/** Nav｜ハンバーガーボタン */
const HamburgerButton = ({ isOpen = false, ...props }) => {
  return (
    <StyledButton type="button" isOpen={isOpen} {...props}>
      <StyledI isOpen={isOpen}></StyledI>
      <StyledI isOpen={isOpen}></StyledI>
      <StyledI isOpen={isOpen}></StyledI>
    </StyledButton>
  )
}

export default HamburgerButton

HamburgerButton.propTypes = {
  /** ボタン押下時に発火する関数 */
  onClick: PropTypes.func,
  /** メニューの開閉状態 */
  isOpen: PropTypes.bool
}

const StyledButton = styled.button`
  padding: 0;
  width: 38px;
  height: 38px;
  background-color: ${white};
  border: 2px solid ${pink};
  border-radius: 50%;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;

  ${mediaQuery('sm', 'min')} {
    display: none;
  }
`

const StyledI = styled.i`
  margin: auto;
  width: 20px;
  height: 2px;
  background-color: ${pink};
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.2s ease-in-out;

  &:nth-child(1) {
    transform: rotate(-45deg);
  }

  &:nth-child(2) {
    transform: rotateX(90deg);
  }

  &:nth-child(3) {
    transform: rotate(45deg);
  }

  ${({ isOpen }) =>
    !isOpen &&
    `
    &:nth-child(1) {
      transform: translateY(-6px);
    }

    &:nth-child(2) {
      transform: rotateX(0);
    }

    &:nth-child(3) {
      transform: translateY(6px);
    }
    `}
`
