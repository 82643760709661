/* style */
import styled from 'styled-components'

export const StyledLi = styled.li`
  width: calc(100% / 4 - 12px);
  margin: 0 6px;
  float: left;

  a {
    :hover {
      img {
        opacity: 0.7;
      }
    }
  }
`

export const StyledDiv1 = styled.div`
  position: relative;
`

export const StyledP1 = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  background: #69add2;
  padding: 3px 5px;
  color: #fff;
  font-size: 1.2rem;
`

export const StyledDiv2 = styled.div`
  padding: 20px 0;
  position: relative;
`

export const StyledP2 = styled.p`
  color: #333;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  background: #eee;
  position: absolute;
  top: -20px;
  right: -5px;
  width: 88%;
  padding: 8px 12px;
`

export const StyledImg = styled.img`
  width: 40px;
  margin-top: 25px;
  clip-path: none;
`
