import React from 'react'
import PropTypes from 'prop-types'
import window from 'global/window'
import Cookie from 'js-cookie'
import { getResumeInfo } from '../../../common/global_functions.js'
import Enquete from '../../../common/components/Enquete'
import {
  DIO_PLAYER_DEVICE,
  EPISODE_DISPLAY_MODE
} from '../../../../constants/app'
import webApp from '../../utils/exdioWebAppUtils'

window.getBCResumeInfo = function(meta) {
  const bcResumeInfo = getResumeInfo(meta.meta_id)
  return bcResumeInfo ? bcResumeInfo.lastPlayed : null
}

window.isBCRefIdMatch = function(str1, str2) {
  const str = ` ${str1} `
  return str.indexOf(` ${str2} `) !== -1
}

const PLAYER_AREA_ID = 'DIOplayer'
const PLAYER_SCRIPT_TAG_ID = 'ex-dio-player-script'

/** 有料移行動画プレイヤー */
export default class RenewalPlayer extends React.PureComponent {
  static propTypes = {
    meta_id: PropTypes.string,
    auto_start: PropTypes.bool,
    play_head_time: PropTypes.number,
    volume: PropTypes.number,
    is_mute: PropTypes.bool,
    controller: PropTypes.string,
    bitrate: PropTypes.string,
    product_type: PropTypes.number,
    channel: PropTypes.string,
    ssai_ad_config_id: PropTypes.string,
    ssai_player_id: PropTypes.string,
    stvod_player_id: PropTypes.string,
    live_player_id: PropTypes.string,
    delivery_config_id: PropTypes.string,
    subtitle: PropTypes.bool,
    thumbnail_url: PropTypes.string,
    enqueteEnabled: PropTypes.bool,
    onClose: PropTypes.func,
    playbackToken: PropTypes.string,
    material_id: PropTypes.string,
    license_id: PropTypes.string,
    display_mode: PropTypes.number
  }

  static defaultProps = {
    meta_id: null,
    auto_start: true,
    play_head_time: 0,
    volume: 1,
    is_mute: false,
    controller: 'default',
    bitrate: 'auto',
    product_type: 1,
    channel: 'ex',
    ssai_ad_config_id: '',
    ssai_player_id: '',
    stvod_player_id: '',
    live_player_id: '',
    delivery_config_id: '',
    subtitle: false,
    thumbnail_url: null,
    enqueteEnabled: false,
    onClose: () => {},
    playbackToken: '',
    material_id: '',
    license_id: '',
    display_mode: null,
    isLive: false,
    isClipVod: false
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data
    this.device = webApp.utils.getDevice()

    this.state = {
      playing: false,
      disposed: true
    }

    this.definePlayer = this.definePlayer.bind(this)
    this.onClickModal = this.onClickModal.bind(this)
    this.onClickPlayer = this.onClickPlayer.bind(this)
    this.onClickEnquete = this.onClickEnquete.bind(this)
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.disposePlayer()
  }

  /**
   * 動画再生
   * コンポーネントを利用する側は当コンポーネントのrefに対し
   * renewalPlayerRef.play()
   * で呼び出す。
   */
  play() {
    const { playing } = this.state
    if (playing) return

    // if (this.device === DIO_PLAYER_DEVICE.PC) {
    webApp.utils.debug('[RenewalPlayer] play() on PC.')
    this.setState({ playing: true, disposed: false }, () => {
      this.makePlayer()
      // 背景固定スタイル設定
      document.body.classList.add('modal-open')
      // バックグラウンドのスクロール抑止
      document.body.style.overflow = 'hidden'
    })
    // } else {
    //   webApp.utils.debug('[RenewalPlayer] play() on SP/APP.');
    //   this.setState({ disposed: false }, () => {
    //     this.makePlayer();
    //     const playLink = document.getElementById('appSelector');
    //     if (playLink) playLink.click();
    //   });
    // }
  }

  disposePlayer() {
    webApp.utils.debug('[RenewalPlayer] disposePlayer()')
    // playerを削除
    if (typeof videojs !== 'undefined') {
      Object.keys(videojs.getPlayers()).forEach((key) => {
        if (videojs.getPlayers()[key]) videojs.getPlayers()[key].dispose()
      })
    }
    const playerScriptTag = document.getElementById(PLAYER_SCRIPT_TAG_ID)
    if (playerScriptTag) {
      if (!this.context.models.browserInfo.data.isIE) {
        playerScriptTag.remove()
      } else {
        playerScriptTag.parentNode.removeChild(playerScriptTag)
      }
    }
    window.setDIOplayer = undefined

    // 背景固定スタイル削除
    document.body.classList.remove('modal-open')
    if (!this.context.models.browserInfo.data.isIE) {
      document.body.style.overflow = null
    } else {
      document.body.style.overflow = '' // IEはnullが設定できないっぽい
    }

    this.setState({ disposed: true })
  }

  getPlaybackToken() {
    const path = ['token', 'value']
    return this.model.fetch([path]).then((result) => {
      const playbackToken = _.get(result, ['json', ...path])
      return playbackToken
    })
  }

  definePlayer(embedData) {
    this.account_id = null
    this.player = null
    this.ga_cid = ''

    // エンベッドデータ初期化
    this.device = embedData.device
    // DIOメタID
    this.meta_id = null
    if (embedData.meta_id !== undefined) {
      this.meta_id = embedData.meta_id
    } else {
      console.log("meta_id is blank, so DIOPlayer can't be init.")
    }
    // 自動再生On/Off
    this.auto_start = true
    if (embedData.auto_start !== undefined && embedData.auto_start === false) {
      this.auto_start = false
    }
    // 再生開始秒数
    this.play_head_time = 0
    if (embedData.play_head_time !== undefined) {
      this.play_head_time = embedData.play_head_time
    }
    // 再生開始音量
    this.volume = 1
    if (embedData.volume !== undefined) {
      this.volume = embedData.volume
    }
    // 再生開始Mute設定
    this.is_mute = false
    if (embedData.is_mute !== undefined && embedData.is_mute === true) {
      this.is_mute = true
    }
    // 再生開始ビットレート
    this.bitrate = 'auto'
    if (embedData.bitrate !== undefined) {
      if (embedData.bitrate === 'middle') this.bitrate = 'middle'
      if (embedData.bitrate === 'low') this.bitrate = 'low'
    }
    // コントロールバー表示設定
    this.controller = 'default'
    if (embedData.controller !== undefined) {
      if (embedData.controller === 'always') this.controller = 'always'
    }
    // ユーザ識別子情報
    this.uid = ''
    if (embedData.uid !== undefined) {
      this.uid = embedData.uid
    }
    // 商品タイプ
    this.product_type = 1
    if (embedData.product_type !== undefined) {
      this.product_type = embedData.product_type
    }
    // アプリ用チャンネルID
    this.channel = 'ex'
    if (embedData.channel !== undefined) {
      this.channel = embedData.channel
    }

    // VideoCloudから取得するメタ情報
    this.video_type = 'vod'

    // 動画再生開始フラグ
    this.mediaStarted = false
    this.setMediaStarted = function(flg) {
      this.mediaStarted = flg
    }

    // 配信コンフィグID
    this.delivery_config_id = embedData.delivery_config_id
    // PlaybackAPIリクエストToken
    this.playback_token = embedData.playback_token
    // カスタムパラメータ
    this.custom_params = embedData.custom_params
  }

  // プレイヤー生成
  async makePlayer() {
    const {
      meta_id,
      auto_start,
      play_head_time,
      volume,
      is_mute,
      controller,
      bitrate,
      product_type,
      channel,
      ssai_ad_config_id,
      ssai_player_id,
      stvod_player_id,
      live_player_id,
      delivery_config_id,
      subtitle,
      playbackToken,
      material_id,
      license_id,
      display_mode,
      isLive,
      isClipVod
    } = this.props

    // プレイバックトークンの生成
    let playback_token = ''
    // プレビューの場合は、playback proxy用tokenをreact側で取得し、プレフィックスを付与したものをプレイヤー(DioPlayer.js)に渡す
    // プレビュー以外の場合は、旧キャッチアップ同様、プレイヤー側でplayback proxy用tokenを取得する
    const withValidPreviewToken = _.get(this.context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])
    if (withValidPreviewToken) {
      const tmpPlaybackToken = playbackToken || (await this.getPlaybackToken())
      playback_token = `${this.config.logica.preview_playback_token_prefix}:${tmpPlaybackToken}`
    }

    // カスタムパラメータの生成
    const authContext =
      _.get(this.context, ['models', 'authContext', 'data']) || {}
    const { cbsessid, memberId, token, userId } = authContext
    const isFree = [
      EPISODE_DISPLAY_MODE.FREE,
      EPISODE_DISPLAY_MODE.TVOD_FREE,
      EPISODE_DISPLAY_MODE.SVOD_FREE,
      EPISODE_DISPLAY_MODE.STVOD_FREE
    ].includes(display_mode)

    // プレビューの場合、member_idを付与するとplayback api側でis_possible_multiを実行し購入判定を行ってしまうので、プレビュー以外の場合のみ付与する
    const memberIdForCustomParams = withValidPreviewToken ? '' : memberId
    // 有料コンテンツの場合のみcustom_paramsを付与
    const custom_params = isFree
      ? ''
      : encodeURIComponent(
          `token=${token || ''}` +
            `&user_id=${userId || ''}` +
            `&member_id=${memberIdForCustomParams || ''}` +
            `&cbsessid=${cbsessid || ''}` +
            `&material_id=${material_id || ''}` +
            `&group_id=${license_id || ''}` +
            `&meta_id=${meta_id || ''}`
        )

    window.DioPlayer = new this.definePlayer({
      meta_id,
      auto_start,
      play_head_time,
      volume,
      is_mute,
      controller,
      bitrate,
      product_type,
      delivery_config_id,
      channel,
      uid: Cookie.get('luid') || undefined,
      browserInfo: this.context.models.browserInfo.data,
      playback_token,
      custom_params,
      device: this.device
    })
    window.DioPlayer.subtitle =
      this.config.preview_url_list.indexOf(location.hostname) !== -1
        ? true
        : subtitle
    if (isLive) {
      // ライブは常にセットただし、クリッピングVODの場合はつけない
      window.DioPlayer.ssai_ad_config_id = isClipVod ? '' : ssai_ad_config_id
    } else {
      window.DioPlayer.ssai_ad_config_id =
        ssai_ad_config_id &&
        this.config.avod_ssai_ad_device[window.DioPlayer.device]
          ? ssai_ad_config_id
          : ''
    }
    window.DioPlayer.account_id = this.config.videocloud.account_id

    if (live_player_id) {
      // クリッピングの場合は vodのplayer_idを使う
      // 有料スキーマの場合はstvod_player_idが入ってくる。つまり入ってなければ無料と判断できる
      const vod_player_id = stvod_player_id
        ? stvod_player_id
        : this.config.videocloud.player_id
      window.DioPlayer.player_id = isClipVod ? vod_player_id : live_player_id
    } else if (stvod_player_id) {
      // 有料 VOD ※有料のssaiは存在しない
      window.DioPlayer.player_id = stvod_player_id
    } else {
      // 無料 VOD
      // ssai_player_idがセットされている場合かつ設定がtrueになっている場合は、ssai用のplayerを使う
      window.DioPlayer.player_id =
        ssai_player_id &&
        this.config.avod_ssai_ad_device[window.DioPlayer.device]
          ? ssai_player_id
          : this.config.videocloud.player_id
    }

    this.addPlayer()
  }

  // プレイヤーの挿入
  addPlayer() {
    const {
      meta_id,
      auto_start,
      play_head_time,
      volume,
      is_mute,
      bitrate,
      controller,
      uid,
      product_type,
      ssai_ad_config_id,
      delivery_config_id,
      channel,
      ga_cid,
      player_id,
      subtitle,
      playback_token,
      custom_params
    } = window.DioPlayer

    webApp.utils.debug(`[RenewalPlayer] custom_params=${custom_params}`)

    // if (window.DioPlayer.device === DIO_PLAYER_DEVICE.PC) {
    // PC(Android,iOS,アプリ以外)の場合
    const call = function(src, handler) {
      // Dioplayer.jsを読み込む
      // jsのloadが完了してからhandlerの処理を行うようscriptタグにリスナーを付与
      const base = document.getElementsByTagName('script')[0]
      const obj = document.createElement('script')
      obj.id = PLAYER_SCRIPT_TAG_ID
      obj.async = true
      obj.src = src
      if (obj.addEventListener) {
        obj.onload = () => {
          handler()
        }
      } else {
        obj.onreadystatechange = () => {
          if (obj.readyState === 'loaded' || obj.readyState === 'complete') {
            obj.onreadystatechange = null
            handler()
          }
        }
      }
      base.parentNode.insertBefore(obj, base)
    }

    const setDIOplayerArgs = {
      meta_id,
      auto_start,
      play_head_time,
      volume,
      is_mute,
      bitrate,
      controller,
      uid,
      product_type,
      ssai_ad_config_id,
      delivery_config_id,
      channel,
      player_id,
      subtitle,
      playback_token,
      custom_params
    }
    if (typeof setDIOplayer === 'function') {
      setDIOplayer(setDIOplayerArgs)
    } else {
      call(this.config.playerEmbed, () => {
        setDIOplayer(setDIOplayerArgs)
      })
    }
    // } else {
    //   let html = '';
    //   html += '<div id="playerWrapper">';
    //   html += '<div id="app_player">';

    //   const commonParams =
    //     'type=play' +
    //     `&auto_start=${auto_start}` +
    //     `&product_type=${product_type}` +
    //     `&channel=${channel}` +
    //     `&meta_id=${meta_id}` +
    //     `&ssai_ad_config_id=${ssai_ad_config_id}` +
    //     `&delivery_config_id=${delivery_config_id}` +
    //     `&uid=${uid}` +
    //     `&cid=${ga_cid}` +
    //     `&subtitle=${subtitle}` +
    //     `&return_url=${encodeURIComponent(window.location.href)}` +
    //     `&custom_params=${custom_params}`;

    //   if (window.DioPlayer.device === DIO_PLAYER_DEVICE.IOS) {
    //     // iOSブラウザからのアクセスの場合
    //     // Universal Linkは延期
    //     const app_url = `${this.config.dioapp.custom_schema}?${commonParams}`;
    //     const store_url = this.config.dioapp.ios_store_link;
    //     html += `<a id="appSelector" class="appSelector_iOS" href="javascript:void(0);" onclick="dioApp('${app_url}', '${store_url}')">`;

    //     this.appendDioAppScript();
    //   } else if (window.DioPlayer.device === DIO_PLAYER_DEVICE.ANDROID) {
    //     // Androidブラウザからのアクセスの場合
    //     html += `<a id="appSelector" class="appSelector_Android" href="intent://tv-asahi.co.jp?${commonParams}#Intent;scheme=dioplayer;package=${this.config.dioapp.android_package};end" >`;
    //   } else if (window.DioPlayer.device === DIO_PLAYER_DEVICE.APPLI) {
    //     // アプリでのアクセスの場合
    //     html += `<a id="appSelector" class="appSelector" href="${this.config.dioapp.custom_schema}?${commonParams}&return_url=${location.href}" >`;
    //   }
    //   html += '</a>';
    //   html += '</div>';
    //   html += '</div>';
    //   document.getElementById(PLAYER_AREA_ID).innerHTML = html;
    // }
  }

  appendDioAppScript() {
    // iOS用のscriptを出力する
    if (window.DioPlayer.device === DIO_PLAYER_DEVICE.IOS) {
      const s = document.createElement('script')
      s.innerHTML =
        'function dioApp(app_url, store_url) {' +
        '  let timeout;' +
        '  let time = new Date();' +
        '  window.location.href = app_url;' +
        '  timeout = setTimeout(function(){' +
        '    var now = new Date() ;' +
        '    if(now - time < 3000) {' +
        "      if(confirm('“App Store”で開きますか？')){" +
        '        window.location.href = store_url ;' +
        '      }' +
        '    }' +
        '  }, 15);' +
        '}'

      document.getElementById(PLAYER_AREA_ID).appendChild(s)
    }
  }

  onClickModal(e) {
    e.stopPropagation()
    this.props.onClose(e)
    this.disposePlayer()

    // プレイヤー再生時に読み込まれるCSSで背景が黒くなるので削除
    const headElem = document.getElementsByTagName('head')[0]
    const styleElem = Array.from(headElem.childNodes).find((child) => {
      return child.tagName === 'STYLE' && child.className.startsWith('bc-style')
    })
    if (styleElem) {
      headElem.removeChild(styleElem)
    }

    // 背景固定スタイル削除
    document.body.classList.remove('modal-open')
    document.body.style.overflow = null

    this.setState({ playing: false })
  }

  onClickPlayer(e) {
    e.stopPropagation()
  }

  onClickEnquete(e) {
    e.stopPropagation()
  }

  render() {
    const { enqueteEnabled } = this.props
    const { playing, disposed } = this.state
    if (disposed) return null

    const isPc = true // this.device === DIO_PLAYER_DEVICE.PC;
    const style = {} // playing && isPc ? {} : { display: 'none' };

    webApp.utils.debug('[RenewalPlayer] render')
    return (
      <div
        className="player-modal-wrapper"
        style={style}
        onClick={this.onClickModal}
      >
        <div className="watch">
          {enqueteEnabled && (
            <div className="enquete_area" onClick={this.onClickEnquete}>
              {isPc ? <Enquete /> : null}
            </div>
          )}
          <div id={PLAYER_AREA_ID} onClick={this.onClickPlayer} />
        </div>
      </div>
    )
  }
}
