import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import window from 'global'
import Cookie from 'js-cookie'
import axios from 'axios'
import styled, { createGlobalStyle } from 'styled-components'
import {
  LOCAL_STORAGE_KEY_PURCHASE,
  PURCHASE_TYPE,
  FAVORITE_TYPE,
  META_SCHEMA_ID,
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE,
  DIO_PLAYER_DEVICE,
  MYPAGE_CONTENTS
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import Header from '../../../../common/components/Header'
import EpisodeFooter from '../../../../common/components/EpisodeFooter'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import DFPBanner from '../../../../common/components/DFPBanner'
import AboutMedalLink from '../../../../common/components/renewal/AboutMedalLink'
import PackList from '../details/PackList'
import SideTab from '../details/SideTab'
import Caption from '../details/Caption'
import ProgramTopLink from '../details/ProgramTopLink'
import EpisodePager from '../details/EpisodePager'
import RenewalPlayerInline from '../../player/RenewalPlayerInline'
import SwitchableListGrid from '../../../../common/components/renewal/SwitchableListGrid'
import AddButtonBlock from '../details/AddButtonBlock'
import SideRecommend from '../details/SideRecommend'
import HtmlSnippet from '../../HtmlSnippet'
import HeaderNewsComponent from '../HeaderNewsComponent'
import LiveTimeLine from '../twitter/LiveTimeLine'
import Chat from '../chat'
import VoteForm from '../vote_form'

import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'
import DeliverPeriod from '../details/DeliverPeriod'
import { mediaQuery } from '../../style'

moment.createFromInputFallback = (config) => {
  config._d = new Date(config._i)
}

/** クレヨンしんちゃんライブ配信用テンプレ */
export default class EpisodeLiveShinchan extends Component {
  static propTypes = {
    seriesId: PropTypes.string,
    seasonId: PropTypes.string,
    episodeId: PropTypes.string,

    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    howToPlay: PropTypes.object,
    product: PropTypes.object,
    productRight: PropTypes.object,

    // 通常単話
    episodes: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number,
        name: PropTypes.string,
        original_price: PropTypes.number,
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    ),
    howToPlays: PropTypes.object,
    // 月額見放題
    course: PropTypes.shape({
      course_id: PropTypes.number,
      schema_id: PropTypes.number,
      name: PropTypes.string,
      active_pricing: PropTypes.object,
      values: PropTypes.object
    }),

    status: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      isDeviceNotAvailable: PropTypes.bool,
      limitDate: PropTypes.string,
      isPossible: PropTypes.bool,
      isBelonging: PropTypes.bool
    }),

    season: PropTypes.object,

    // 再生ログ送信
    sendPlayLog: PropTypes.func,

    // データ取得が完了したか
    loaded: PropTypes.bool
  }

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    episodeId: '',
    howToPlay: {},
    episodes: [],
    products: [],
    howToPlays: {},
    course: {},
    status: {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    sendPlayLog: () => {},
    loaded: false
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.config = this.context.models.config.data
    this.previewUrlList = this.config.preview_url_list
    this.cookieRubocopPrefix = this.config.cookie_rubocop_prefix
    this.model = context.falcorModel.batch(100)
    const device = webApp.utils.getDevice()
    if (device === DIO_PLAYER_DEVICE.PC) {
      this.isPc = true
      this.isSp = false
    } else {
      this.isPc = false
      this.isSp = true
    }

    this.state = {
      progress: webApp.utils.progress(this.config, props.meta),
      isLoggedIn: webApp.utils.isLoggedIn(this.context),
      pagerOptions: {
        // ページャーの設定
        episodesPerPages: 12,
        range: 2
      },
      showThumb: true,
      bannerId: null,
      handlePostMessage: () => {},
      isArchived: false
    }

    this.onClickPlay = this.onClickPlay.bind(this)
    this.onClosePlayer = this.onClosePlayer.bind(this)
    this.gotoPurchasePage = this.gotoPurchasePage.bind(this)
    this.toggleShowThumb = this.toggleShowThumb.bind(this)
    this.getBannerSnippetId = this.getBannerSnippetId.bind(this)
    this.setPostMessage = this.setPostMessage.bind(this)
    this.setIsArchived = this.setIsArchived.bind(this)
    this.onOpenChat = this.onOpenChat.bind(this)
    this.onCloseChat = this.onCloseChat.bind(this)

    this.setPlayerRef = (e) => {
      this.playerRef = e
    }

    // AdUnitの為genreの先頭の値を取得
    var metaValues = props.meta.values
    var metaGenre = props.meta.values.genre
    this.genre = ''
    if (metaGenre) {
      if (this.config.genre_maps[metaGenre[0]]) {
        this.genre = this.config.genre_maps[metaGenre[0]].typeName
      }
    } else {
      var evis_genre_data = props.meta.values.parents_series.evis_Genre
      if (evis_genre_data && typeof evis_genre_data[0].id != 'undefined') {
        if (this.config.genre_maps[evis_genre_data[0].id]) {
          this.genre = this.config.genre_maps[evis_genre_data[0].id].typeName
        }
      }
    }
  }

  componentDidMount() {
    this._isMounted = true

    this.getBannerSnippetId()
    this.renderDeadLine()
    this.setIsArchived()
    this.setVh()
    // DBG_CODE
    //window.setResumeInfo = setResumeInfo;
    //window.getResumeInfo = getResumeInfo;
    //window.removeAllResumeInfo = removeAllResumeInfo;

    /** アプリからブラウザにリダイレクト */
    if (window.location.pathname.indexOf('/app/') > -1) {
      window.location.href = window.location.pathname.replace('/app', '')
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loaded, status } = nextProps
    const isAutoPlay = this.context.routeHandler.query.auto === 't'
    if (loaded && status && isAutoPlay) {
      const isFree = EPISODE_DISPLAY_MODES_FREE.includes(status.displayMode)
      const isAuthFree =
        EPISODE_DISPLAY_MODE.SVOD_AUTH_FREE === status.displayMode &&
        webApp.utils.isLoggedIn(this.context)
      const withValidPreviewToken = _.get(this.context, [
        'models',
        'state',
        'data',
        'withValidPreviewToken'
      ])
      if (isFree || isAuthFree || status.isPurchased || withValidPreviewToken) {
        this.toggleShowThumb()
        this.onClickPlay()
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    document.body.style.overflow = null
    clearInterval(this.intervalId)
  }

  setVh() {
    const vh = window.innerHeight * 0.01
    document.body.style.setProperty('--vh', `${vh}px`)
  }

  setIsArchived() {
    const { meta } = this.props
    if (!meta) return

    const isLive =
      meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
      meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
    const baseURL = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 'https://st-cc-api.tvasahi.jp/api/v1/chats'
      : 'https://cc-api.tv-asahi.co.jp/api/v1/chats'
    const frontDisplayConfigStr = isLive
      ? 'live_stvod_front_display_config'
      : 'episode_front_display_config'
    const chatHash = _.get(meta, ['values', frontDisplayConfigStr, 'chat_hash'])
    axios.get(`${baseURL}/${chatHash}/information`).then((res) => {
      const status = _.get(res.data, ['status'])
      this.setState({ isArchived: status === 2 })
      /**
       * READY: 0
       * LIVE: 1
       * ARCHIVED: 2
       */
    })
  }

  onClickPlay(e, asSvod = false) {
    if (e) e.preventDefault()

    const { meta } = this.props
    const { isLoggedIn, isArchived } = this.state

    let purchaseAvarableStatus = 3

    // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
    // https://logiclogic.backlog.jp/view/EX_DIO-1467
    const withValidPreviewToken = _.get(this.context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])

    const prevTime = Cookie.get(this.previewUrlList + this.cookieRubocopPrefix)
    let inViewTerm = true
    // ワンタイムプレビューの場合は常に蓋開け
    if (!withValidPreviewToken) {
      // 視聴期間内かどうか
      if (
        moment(prevTime).isBefore(meta.delivery_start_at) ||
        moment(prevTime).isAfter(meta.delivery_end_at)
      ) {
        inViewTerm = false
      }
    }

    if (withValidPreviewToken) {
      purchaseAvarableStatus = 0
    } else {
      const { status, product } = this.props

      // 念の為購入できないコンテンツはブロックする
      // if (!status.isFree && !status.isPurchseAvailable) {
      //   return;
      // }

      // 端末・地域制限に引っかかる場合は反応させない
      if (!status.isGeoDeliverable || status.isDeviceNotAvailable) {
        return
      }

      switch (status.displayMode) {
        case EPISODE_DISPLAY_MODE.FREE:
        case EPISODE_DISPLAY_MODE.TVOD_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_FREE:
          purchaseAvarableStatus = 0
          break
        case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
          if (status.isPurchased) {
            purchaseAvarableStatus = 0
          } else if (status.isNotPurchased) {
            if (asSvod) {
              // STVODでSVODボタンが押された場合
              purchaseAvarableStatus = 2
            } else if (
              product &&
              !webApp.utils.isOnSale(this.context, product)
            ) {
              // 商品が販売期間外
              purchaseAvarableStatus = 2
            } else {
              purchaseAvarableStatus = 1
            }
          }
          break
        case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
          if (status.isPurchased) {
            purchaseAvarableStatus = 0
          } else if (status.isNotPurchased) {
            purchaseAvarableStatus = 2
          }
          break
        case EPISODE_DISPLAY_MODE.UNKNOWN:
        default:
          break
      }
    }

    switch (purchaseAvarableStatus) {
      case 0:
        if (inViewTerm) {
          if (this.playerRef) {
            this.playerRef.play()
            if (isArchived) {
              clearInterval(this.intervalId)
              this.intervalId = setInterval(() => {
                const DioPlayer = document.getElementById('DIOplayer')
                const videoElm = DioPlayer
                  ? DioPlayer.querySelector('video')
                  : null
                const seekTime = videoElm ? Math.floor(videoElm.currentTime) : 0
                this.state.handlePostMessage(seekTime)
              }, 1000)
            }
          }
          // 再生ログ送信
          if (typeof this.props.sendPlayLog === 'function')
            this.props.sendPlayLog()
        }
        break
      case 1:
      case 2:
        if (isLoggedIn) {
          const purchasePath = ['development', 'staging'].includes(
            process.env.NODE_ENV
          )
            ? 'https://st-www.tvasahi.jp/douga_mv/purchase/shinchan/'
            : 'https://wws.tv-asahi.co.jp/douga_mv/purchase/shinchan/'
          window.location.href = purchasePath
        } else {
          // ログインページに遷移
          this.context.history.push(
            routes.login.makePath(null, {
              redirect: this.context.routeHandler.path
            })
          )
        }
        break
      default:
        break
    }
  }

  onClosePlayer() {
    if (this._isMounted) {
      this.setState({
        progress: webApp.utils.progress(this.config, this.props.meta)
      })
    }
  }

  /**
   * 料金
   * @return {Array} [単一価格, 月額見放題価格]
   */
  price() {
    const { meta, product, status, course } = this.props
    if (!meta) return [null, null]
    switch (status.displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        return ['無料', null]
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
        return [_.get(product, ['active_pricing', 'price']) || null, null]
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
        return [
          _.get(product, ['active_pricing', 'price']) || null,
          _.get(course, ['active_pricing', 'price']) || null
        ]
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
        return [null, _.get(course, ['active_pricing', 'price']) || null]
      case EPISODE_DISPLAY_MODE.UNKNOWN:
      default:
        return [null, null]
    }
  }

  /** 放送日 */
  onAirDate(isLive) {
    const { meta } = this.props
    if (!meta) return null
    if (isLive) return ''
    const onAirDate = _.get(meta, 'values.avails_ReleaseHistoryOriginal')
    return onAirDate ? moment(onAirDate).format('放送日：YYYY年M月D日') : ''
  }

  renderPlayButton() {
    const { status, product } = this.props
    const { isLoggedIn } = this.state
    let watchAvarableStatus = 3

    if (!status.displayMode) return null

    // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
    const withValidPreviewToken = _.get(this.context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])
    if (withValidPreviewToken) {
      watchAvarableStatus = 0
    } else {
      switch (status.displayMode) {
        case EPISODE_DISPLAY_MODE.FREE:
        case EPISODE_DISPLAY_MODE.TVOD_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_FREE:
          watchAvarableStatus = 0
          break
        case EPISODE_DISPLAY_MODE.UNKNOWN:
          watchAvarableStatus = 2
          break
        default:
          // その他はすべて有料設定
          if (status.isNotPurchased) {
            if (status.isPurchseAvailable) {
              watchAvarableStatus = 1
              if (
                [
                  EPISODE_DISPLAY_MODE.TVOD_NOT_FREE,
                  EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE
                ].includes(status.displayMode) &&
                product &&
                !webApp.utils.isOnSale(this.context, product)
              ) {
                // TVODについては商品が販売期間外の場合は購入不可
                watchAvarableStatus = 2
              }
            } else {
              watchAvarableStatus = 2
            }
          } else {
            watchAvarableStatus = 0
          }
          break
      }
    }

    let ret = null
    switch (watchAvarableStatus) {
      case 0:
        ret = [
          <div key="btn" className="play-btn" />,
          <div key="btn-sp" className="play-btn-sp">
            視聴する
          </div>
        ]
        break
      case 1:
        ret = isLoggedIn
          ? [
              <div key="btn" className="play-btn">
                購入して再生
              </div>,
              <div key="btn-sp" className="play-btn-sp">
                購入して再生
              </div>
            ]
          : [
              <div key="btn" className="play-btn">
                ログイン
              </div>,
              <div key="btn-sp" className="play-btn-sp">
                ログイン
              </div>
            ]
        break
      case 2:
        ret = isLoggedIn
          ? [
              <div key="btn" className="play-btn" />,
              <div key="btn-sp" className="play-btn-sp">
                視聴する
              </div>
            ]
          : [
              <div key="btn" className="play-btn">
                ログイン
              </div>,
              <div key="btn-sp" className="play-btn-sp">
                ログイン
              </div>
            ]
        break
      default:
        break
    }

    return ret
  }

  renderPlayLink(disabled = false) {
    const { status, product, loaded } = this.props
    const { isLoggedIn } = this.state
    // if (!loaded) return <div className="c-pageBtn" />;

    let ret = null
    let retSvod = null
    // trueの場合、黒背景で非活性になる
    let wDisabled = false

    // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
    const withValidPreviewToken = _.get(this.context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])
    if (withValidPreviewToken) {
      // ret = '視聴する';
    } else {
      // 海外判定
      if (status.isGeoDeliverable === false) {
        return <div className="c-pageBtn">ご利用の地域では視聴できません</div>
      } else if (status.isDeviceNotAvailable === true) {
        return <div className="c-pageBtn">ご利用の端末では視聴できません</div>
      } else if (!status.isGeoDeliverable) {
        return <div className="c-pageBtn" />
      }

      switch (status.displayMode) {
        case EPISODE_DISPLAY_MODE.FREE:
        case EPISODE_DISPLAY_MODE.TVOD_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_FREE:
          ret = [
            <span key="free" className="c-pageBtn-link-free">
              無料
            </span>,
            'で視聴'
          ]
          break
        case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
          if (status.isPurchased) {
            // ret = '視聴する';
          } else if (status.isNotPurchased) {
            if (!status.isPurchseAvailable) {
              // データの準備ができていない
              ret = '現在購入いただけません'
              wDisabled = true
            } else {
              if (product && !webApp.utils.isOnSale(this.context, product)) {
                // 商品が販売期間外
                ret = '現在購入いただけません'
                wDisabled = true
              } else {
                ret = [
                  <span key="not-free" className="c-pageBtn-link-coin">
                    {this.price()[0]}
                  </span>,
                  'で視聴'
                ]
              }
            }
          }
          break
        case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
          if (status.isPurchased) {
            // ret = '視聴する';
          } else if (status.isNotPurchased) {
            if (!status.isPurchseAvailable) {
              // データの準備ができていない
              ret = '現在購入いただけません'
              wDisabled = true
            } else {
              ret = [
                '月額',
                <span key="in-course" className="c-pageBtn-link-yen">
                  {this.price()[1]}
                </span>,
                '円で視聴'
              ]
            }
          }
          break
        case EPISODE_DISPLAY_MODE.UNKNOWN:
        default:
          break
      }

      switch (status.displayMode) {
        case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
          // 前出のret設定ロジックと同等
          if (status.isPurchased) {
          } else if (status.isNotPurchased) {
            if (!status.isPurchseAvailable) {
            } else {
              if (product && !webApp.utils.isOnSale(this.context, product)) {
              } else {
                retSvod = [
                  '月額',
                  <span key="in-course" className="c-pageBtn-link-yen">
                    {this.price()[1]}
                  </span>,
                  '円で視聴'
                ]
              }
            }
          }
          break
        default:
          break
      }
    }

    const elements = []
    if (ret) {
      elements.push(
        <div key="pageBtn-1" className="c-pageBtn">
          <a
            href="https://wws.tv-asahi.co.jp/douga_mv/purchase/shinchan/"
            className={`c-pageBtn-link ${
              status.isGeoDeliverable === false ||
              status.isDeviceNotAvailable === true
                ? 'disabled'
                : ''
            }`}
            target="_blank"
          >
            {ret}
          </a>
        </div>
      )
    }
    if (retSvod) {
      elements.push(
        <div key="pageBtn-2" className="c-pageBtn mt10">
          <a
            href="#"
            className="c-pageBtn-link"
            onClick={
              disabled || wDisabled
                ? (e) => e.preventDefault()
                : (e) => this.onClickPlay(e, true)
            }
          >
            {retSvod}
          </a>
        </div>
      )
    }

    /*
     * ログインしている場合は「マイページ」へのリンクを設置
     * ログインしていない場合は「ログイン」ページへのリンクを設置
     */
    if (isLoggedIn) {
      elements.push(
        <div key="pageBtn-2" className="c-pageBtn">
          <StyledLink1
            route={routes.mypage}
            state={{
              content: MYPAGE_CONTENTS.TAB.PURCHASED
            }}
          >
            マイページ
          </StyledLink1>
        </div>
      )
    } else {
      elements.push(
        <div key="pageBtn-2" className="c-pageBtn">
          <StyledLink2
            route={routes.login}
            query={{ redirect: this.context.routeHandler.path } || null}
          >
            ログイン
          </StyledLink2>
        </div>
      )
    }

    return elements
  }

  gotoPurchasePage() {
    const metaId = this.props.episodeId
    const { seasonId } = this.props
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_PURCHASE,
      JSON.stringify({
        type: PURCHASE_TYPE.EPISODE,
        id: Number(seasonId),
        selected: [Number(metaId)],
        page: this.context.routeHandler.url
      })
    )
    this.context.history.push(routes.purchase.makePath())
  }

  renderDeliveryStartAt(isLive) {
    const { meta, status } = this.props
    if (!meta || !meta.meta_id) return null

    // LIVEは放送日に加え、配信開始日も表示しない
    // https://docs.google.com/spreadsheets/d/110fDrQ0sNJ7f1QCiE1Hw-qNMjU-lVBAccAQFFbysvvA/edit?pli=1#gid=699926601&range=L162
    if (isLive) return null

    let ret = null
    switch (status.displayMode) {
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
        const deliveryStartAt = moment(meta.delivery_start_at).format(
          'YYYY年M月D日'
        )
        ret = (
          <div className="c-storyMeta-inBox-meta-castStart">
            配信開始日：{deliveryStartAt}
          </div>
        )
        break

      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.UNKNOWN:
      default:
        break
    }

    return ret
  }

  renderPrice() {
    const { status } = this.props
    const price = this.price()

    // 有料コンテンツで、内部設定が完了していないものは出さない
    if (!status.isFree && !status.isPurchseAvailable) {
      return null
    }

    let ret = null
    switch (status.displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        ret = (
          <div className="c-storyMeta-inBox-meta-price free">
            料金：
            <span className="c-storyMeta-inBox-meta-price-coin">
              {price[0]}
            </span>
            <AboutMedalLink linkClassName="c-storyMeta-inBox-meta-price-aboutCoin" />
          </div>
        )
        break
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
        ret = (
          <div className="c-storyMeta-inBox-meta-price">
            料金：
            <span className="c-storyMeta-inBox-meta-price-coin">
              {price[0]}
            </span>
            <AboutMedalLink linkClassName="c-storyMeta-inBox-meta-price-aboutCoin" />
          </div>
        )
        break
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
        ret = (
          <div className="c-storyMeta-inBox-meta-price">
            料金：
            <span className="c-storyMeta-inBox-meta-price-coin">
              {price[0]}
            </span>
            <span className="c-storyMeta-inBox-meta-price-or">or</span>
            <span className="c-storyMeta-inBox-meta-price-stvod">
              月額 {price[1]}円
            </span>{' '}
            (税込)
            <AboutMedalLink linkClassName="c-storyMeta-inBox-meta-price-aboutCoin" />
          </div>
        )
        break
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
        ret = (
          <div className="c-storyMeta-inBox-meta-price">
            料金：月額 {price[1]}円 (税込)
          </div>
        )
        break
      case EPISODE_DISPLAY_MODE.UNKNOWN:
      default:
        break
    }

    return ret
  }

  getProductType() {
    const { status } = this.props
    switch (status.displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
        return 1
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        return 2
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
        return 3
      default:
        return null
    }
  }

  getChannel() {
    const { status } = this.props
    switch (status.displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
        return 'ex'
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
        return 'douga_st'
      default:
        return null
    }
  }

  /* 番組別お知らせの表示 */
  renderNotice() {
    const { programMeta } = this.props
    const notice = {
      text: _.get(programMeta, ['values', 'notice']),
      hyperlink: _.get(programMeta, ['values', 'notice_hyperlink']),
      start_at: _.get(programMeta, ['values', 'notice_publish_start_at']),
      end_at: _.get(programMeta, ['values', 'notice_publish_end_at'])
    }
    const now = Date.now()

    if (
      !notice.text ||
      now < Date.parse(notice.start_at) ||
      Date.parse(notice.end_at) < now
    ) {
      return null
    }

    const html = (
      <dl className="c-program_notice">
        <dt className="c-program_notice__term">お知らせ</dt>
        <dd className="c-program_notice__desc">{notice.text}</dd>
      </dl>
    )

    return notice.hyperlink ? (
      <a href={notice.hyperlink} className="c-program_notice__wrapper">
        {html}
      </a>
    ) : (
      <div className="c-program_notice__wrapper">{html}</div>
    )
  }

  toggleShowThumb() {
    const { showThumb } = this.state
    this.setState({ showThumb: !showThumb })
  }

  getBannerSnippetId() {
    const { meta } = this.props
    const metaId = _.get(meta, ['id'])
    const date = new Date()
    const timeStamp = `${date.getFullYear()}${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}${('0' + date.getDate()).slice(-2)}${(
      '0' + date.getHours()
    ).slice(-2)}${('0' + date.getMinutes()).slice(-2)}${(
      '0' + date.getSeconds()
    ).slice(-2)}`

    axios
      .get(
        `https://www.tv-asahi.co.jp/douga_mv/twitter/banner_id_list.json?time=${timeStamp}`
      )
      .then(
        function(res) {
          const bannerIdList = ['development', 'staging'].includes(
            process.env.NODE_ENV
          )
            ? _.get(res, ['data', 'staging'])
            : _.get(res, ['data', 'production'])

          if (!bannerIdList) return

          const bannerId = _.get(bannerIdList, [metaId])

          this.setState({ bannerId })
        }.bind(this)
      )
      .catch(
        function(err) {
          console.error(err)
        }.bind(this)
      )
  }

  renderDeadLine() {
    const { meta, status } = this.props
    const rightMetas = _.get(meta, ['right_metas'])
    const rightDeadLine = _.get(rightMetas, [0, 'strict_validity_end_at'], '')

    if (status.isNotPurchased) {
      return (
        <div className="c-storyMeta-inBox-meta-viewingPeriod">
          視聴期間：{rightDeadLine}まで
        </div>
      )
    } else if (status.isPurchased) {
      return (
        <div className="c-storyMeta-inBox-meta-viewingPeriod purchased">
          <dl>
            <dt>購入済</dt>
            <dd>視聴期間：{rightDeadLine}まで</dd>
          </dl>
        </div>
      )
    }
  }

  setPostMessage(postMessage) {
    this.setState({ handlePostMessage: postMessage })
  }

  onOpenChat() {
    const header = document.getElementById('mp-header')
    const videoWrapper = document.querySelector('.c-video_wrapper')
    const iframeWrapper = document.querySelector('.c-iframe2__wrapper')

    /** 背景スクロールの禁止 */
    document.body.style.overflow = 'hidden'

    /** ヘッダー非表示 */
    const headerHeight = header && header.offsetHeight
    header && (header.style.marginTop = `-${headerHeight}px`)
    header && (header.style.top = 'auto')
    videoWrapper && (videoWrapper.style.top = 0)
    iframeWrapper && (iframeWrapper.style.top = 'calc(100vw * 9 / 16)')
  }

  onCloseChat() {
    const header = document.getElementById('mp-header')
    const videoWrapper = document.querySelector('.c-video_wrapper')
    const iframeWrapper = document.querySelector('.c-iframe2__wrapper')

    /** 背景スクロールの禁止 解除 */
    document.body.style.overflow = null

    /** ヘッダー非表示 解除 */
    header && (header.style.marginTop = null)
    header && (header.style.top = null)
    videoWrapper && (videoWrapper.style.top = null)
    videoWrapper && (videoWrapper.style.position = null)
    videoWrapper && (videoWrapper.style.zIndex = null)
    iframeWrapper && (iframeWrapper.style.top = null)
  }

  render() {
    const {
      seriesId,
      seasonId,
      meta,
      programMeta,
      otherSeasons,
      episodes,
      products,
      howToPlays,
      status,
      product,
      course,
      rightMetas,
      productRight,
      loaded
    } = this.props
    const { progress, pagerOptions, showThumb, bannerId } = this.state

    let [metaName, subTitle] = webApp.utils.titles(meta)
    metaName = `${metaName} ${subTitle}`
    const latest = (episodes && episodes[0]) || {}
    const isLatest = meta && latest && meta.meta_id === latest.meta_id
    const time = webApp.utils.duration(meta) || '-'
    const rest = this.isSp ? webApp.utils.rest(meta) : null

    let showRest = status.isPurchased
    if (!showRest) {
      switch (status.displayMode) {
        case EPISODE_DISPLAY_MODE.FREE:
        case EPISODE_DISPLAY_MODE.TVOD_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_FREE:
          showRest = true
          break
        case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
        case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
          break
        default:
      }
    }

    const bannerId1 = _.get(programMeta, ['values', 'banner_1'])
    const bannerId2 = _.get(programMeta, ['values', 'banner_2'])
    const isLive =
      meta.meta_schema_id === META_SCHEMA_ID.LIVE ||
      meta.meta_schema_id === META_SCHEMA_ID.LIVE_NOT_FREE
    let isClipVod = false

    // LIVE配信 主/副切替ID
    let metaIdForPlayer
    if (isLive) {
      if (meta.values.clipping_select_media_type === 'vod') {
        metaIdForPlayer = meta.values.clipping_vod_ref_id
        isClipVod = true
      }
      if (!metaIdForPlayer) metaIdForPlayer = meta.values.target_ref_id
    }
    if (!metaIdForPlayer)
      metaIdForPlayer = `${this.config.videocloud.reference_id_prefix || ''}${
        meta.meta_id
      }`
    const playerSettings = webApp.utils.getPlayerSettings(
      this.config,
      meta,
      status.displayMode
    )

    const withValidPreviewToken = _.get(this.context, [
      'models',
      'state',
      'data',
      'withValidPreviewToken'
    ])

    const prevTime = Cookie.get(this.previewUrlList + this.cookieRubocopPrefix)
    let thumbnailUrl = meta.thumbnail_url
    let nextPrevText = null
    let inViewTerm = true
    // ワンタイムプレビューの場合は常に蓋開け
    if (!withValidPreviewToken) {
      if (moment(prevTime).isBefore(meta.delivery_start_at)) {
        if (isLive) thumbnailUrl = meta.values.livectl_image_before_stream_url
        nextPrevText = isLive ? null : 'まもなく配信予定'
        inViewTerm = false
      } else if (moment(prevTime).isAfter(meta.delivery_end_at)) {
        thumbnailUrl = isLive
          ? meta.values.livectl_image_after_stream_url
          : '/images/exdio/nextImage.jpg'
        nextPrevText = isLive ? null : '次回の配信もお楽しみに！'
        inViewTerm = false
      }
    }
    if (!thumbnailUrl) thumbnailUrl = this.config.default_thumbnail
    const onAirDate = this.onAirDate(isLive)
    const playLink = this.renderPlayLink(!!nextPrevText)

    const material_id = _.get(product, ['ref_id']) || ''
    const license_id = _.get(course, ['ref_id']) || ''

    const isPlayerRenderable =
      metaIdForPlayer &&
      status.displayMode &&
      (inViewTerm || withValidPreviewToken)

    const copyrights = meta.values.evis_Copyright

    const disp_order = _.get(programMeta, ['values', 'disp_order'])

    /** 通常orライブでキーが変わるため出し分け */
    const frontDisplayConfigStr = isLive
      ? 'live_stvod_front_display_config'
      : 'episode_front_display_config'
    const chatHash = _.get(meta, ['values', frontDisplayConfigStr, 'chat_hash'])
    const hasChat = Boolean(chatHash)
    const voteButtonData = _.get(meta, [
      'values',
      frontDisplayConfigStr,
      'button_list'
    ])
    const voteVisible = _.get(voteButtonData, ['visible']) || false

    return (
      <React.Fragment>
        <Header />
        <div
          className={`p-episode_live_shinchan common-wrapper ${
            isLive || hasChat ? 'is-play_inline' : ''
          }`}
        >
          {!this.isSp && <HeaderNewsComponent />}
          <SpSubNavigation spOff />
          {!this.isSp && this.renderNotice()}
          <StyledVideoSpacer />

          <StyledContainer>
            <div className="p-episode_live__container">
              <div className="p-episode_live__container__inner">
                <div
                  className={`c-video_wrapper ${showThumb ? '' : 'is-open'}`}
                  onClick={this.toggleShowThumb}
                >
                  {isPlayerRenderable && (
                    <RenewalPlayerInline
                      ref={this.setPlayerRef}
                      meta_id={metaIdForPlayer}
                      product_type={playerSettings.product_type}
                      channel={playerSettings.channel}
                      ssai_ad_config_id={playerSettings.ssai_ad_config_id}
                      ssai_player_id={playerSettings.ssai_player_id}
                      stvod_player_id={playerSettings.stvod_player_id}
                      live_player_id={playerSettings.live_player_id}
                      delivery_config_id={playerSettings.delivery_config_id}
                      thumbnail_url={meta.thumbnail_url}
                      subtitle={!!meta.values.subtitle}
                      enqueteEnabled={status.isFree}
                      material_id={material_id}
                      license_id={license_id}
                      display_mode={status.displayMode}
                      onClose={this.onClosePlayer}
                      isLive={isLive}
                      isClipVod={isClipVod}
                      playInline={isLive || hasChat}
                    />
                  )}
                  <div className="common-video-area">
                    {inViewTerm && (
                      <a href="#" onClick={this.onClickPlay}>
                        {this.renderPlayButton()}
                        {showRest && (
                          <div className="remaining-time">{rest}</div>
                        )}
                        <div className="seekbar">
                          {progress > 0 && (
                            <div
                              className="seek"
                              style={{ width: `${progress}%` }}
                            />
                          )}
                        </div>
                        <div className="video-area">
                          <img src={thumbnailUrl} alt={metaName} />
                        </div>
                      </a>
                    )}
                    {!inViewTerm && (
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <div className="video-area not-clickable">
                          <img
                            src={thumbnailUrl}
                            alt={metaName}
                            width="1080"
                            height="610"
                          />
                          {!isLive && <div className="mask" />}
                          <div className="next-prev-text">{nextPrevText}</div>
                        </div>
                      </a>
                    )}
                  </div>
                </div>

                {/* 投票ボタン */}
                {voteVisible && (
                  <VoteForm
                    meta={meta}
                    status={status}
                    price={this.price()}
                    buttonData={voteButtonData}
                  />
                )}

                <div className="c-storyMeta--live">
                  <div className="c-storyMeta-inBox">
                    <div className="c-storyMeta-inBox-meta">
                      <h2 className="c-storyMeta-inBox-meta-title">
                        {metaName}
                        <span className="c-storyMeta-inBox-meta-title-small">
                          {isLatest && '（最新話）'}
                        </span>
                      </h2>
                      {!isLive && (
                        <div className="c-storyMeta-inBox-meta-time">
                          時間：{time}
                        </div>
                      )}
                      {this.renderPrice() || (
                        <div className="inBox">料金：-</div>
                      )}
                      {onAirDate && (
                        <div className="c-storyMeta-inBox-meta-onAir">
                          {onAirDate}
                        </div>
                      )}
                      {this.renderDeliveryStartAt(isLive)}
                      {/* <DeliverPeriod meta={meta} status={status} productRight={productRight} /> */}
                      <div className="c-storyMeta-inBox-meta-viewingPeriod purchased">
                        視聴期間：決済月の最終日まで
                      </div>

                      <Caption
                        caption={
                          meta.values && meta.values.evis_EpisodeLongSynopsis
                        }
                        otherInstructions={
                          meta.values && meta.values.avalis_OtherInstructions
                        }
                        actors={meta.values && meta.values.evis_Actors}
                        directors={meta.values && meta.values.evis_Directors}
                        producers={meta.values && meta.values.evis_Producers}
                        writers={meta.values && meta.values.evis_Writers}
                        productions={
                          meta.values && meta.values.evis_Productions
                        }
                      />
                    </div>
                    <div className="c-storyMeta-inBox-actions">
                      <div className="p-episode_live__btn_wrapper">
                        {playLink}
                      </div>

                      {/* for PC&SP */}
                      <AddButtonBlock
                        favoriteType={FAVORITE_TYPE.META}
                        favoriteId={meta.meta_id}
                        title={metaName}
                      />

                      <EpisodePager
                        meta={meta}
                        seriesId={seriesId}
                        seasonId={seasonId}
                        episodes={episodes}
                        course={course}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {hasChat && (
                <Chat
                  hash={chatHash}
                  hasVote={voteVisible}
                  setPostMessage={this.setPostMessage}
                  onOpen={this.onOpenChat}
                  onClose={this.onCloseChat}
                />
              )}
            </div>

            <div className="c-listMeta">
              <HtmlSnippet snippetId={'shinchan_live'} />
              <div className="c-listMeta-inBox">
                <div className="c-listMeta-inBox-main">
                  {status.isNotInCourse && (
                    <PackList key="pack" products={products} showMedal />
                  )}
                  {bannerId && (
                    <HtmlSnippet key="banner" snippetId={bannerId} />
                  )}
                  {bannerId1 && (
                    <HtmlSnippet key="banner" snippetId={bannerId1} />
                  )}
                  <SwitchableListGrid
                    key="episode"
                    episodes={episodes}
                    rootMetas={rightMetas}
                    howToPlays={howToPlays}
                    placeholder="検索"
                    showNew
                    showChecked
                    onlySubTitle
                    listType="grid"
                    disp_order={disp_order}
                    pagerOptions={pagerOptions}
                  />
                </div>

                <div className="c-listMeta-inBox-sub">
                  <div className="common-box-ad-rectangle">
                    <DFPBanner position="foot" meta_id="" genre={this.genre} />
                  </div>
                  {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
                  <SideTab otherSeasons={otherSeasons} />
                  <ProgramTopLink
                    seriesId={seriesId}
                    seasonId={seasonId}
                    isFree={status.isFree}
                  />
                  <HtmlSnippet
                    snippetId={this.config.extras.common_banner_snippet_key}
                  />
                  {loaded && (
                    <SideRecommend
                      typeName={this.config.recommend.type_name.view}
                      spotName={this.config.recommend.spot_name.episode}
                      courseRefId={course ? course.ref_id : ''}
                      episodes={episodes}
                    />
                  )}
                </div>
              </div>
            </div>

            <EpisodeFooter className="mt0" copyrights={copyrights} />
          </StyledContainer>
        </div>

        <GlobalStyle />
      </React.Fragment>
    )
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    ${mediaQuery()} {
      height: calc(var(--vh, 1vh) * 100);
      position: static;
      overflow: hidden;
    }

    ${mediaQuery(320)} {
      zoom: normal;
    }
  }

  .c-pageTop {
    ${mediaQuery()} {
      display: none;
    }
  }
`

const StyledVideoSpacer = styled.div`
  display: none;

  ${mediaQuery()} {
    height: calc(100vw * 9 / 16);
    display: block;
  }
`

const StyledContainer = styled.div`
  ${mediaQuery()} {
    height: calc((var(--vh, 1vh) * 100) - (56px + (100vw * 9 / 16) + 40px));
    overflow-y: scroll;
  }
`

const StyledLink = styled(Link)`
  padding: 14px 24px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: opacity 0.4s;

  &:link,
  &:visited {
    color: #fff;
  }

  &:hover {
    color: #fff;
    opacity: 0.7;
    cursor: pointer;
  }
`

const StyledLink1 = styled(StyledLink)`
  background-color: #0baffd;
`

const StyledLink2 = styled(StyledLink)`
  background-color: #4ad28a;
`
