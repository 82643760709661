import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import webApp from '../../../apps/exdio/utils/exdioWebAppUtils'
import routes from '../routes'
import useIsMounted from '../../hooks/useIsMounted'

/** components */
import GenreLinksSp from './renewal/GenreLinksSp'
import Link from '../../../sketch-platform/ui/routing/Link'
import Jasrac from './renewal/Jasrac'

/** style */
import { mediaQuery, fixColors } from '../../exdio/components/style'

/** Footer */
const Footer = ({ courseId = null, showLogo = false, ...props }, context) => {
  const config = context.models.config.data

  const isMounted = useIsMounted()

  const guideLinks = config.guide_links
  const linkRowCount = guideLinks.length / 2 + (guideLinks.length % 2)
  const firstLinks = guideLinks.slice(0, linkRowCount)
  const secondLinks = guideLinks.slice(linkRowCount)

  if (!isMounted) return null
  return (
    <StyledFooter {...props}>
      <StyledGenreLinksSp />
      <StyledInner>
        <StyledDiv1>
          <ul>
            {firstLinks.map((link) => {
              return (
                <li key={link.title}>
                  <Link
                    href={
                      link.url
                        ? link.url.replace(
                            ':host',
                            webApp.utils.dougaMvHost(context)
                          )
                        : null
                    }
                    route={routes[link.route]}
                    params={link.params}
                    target={link.target}
                  >
                    {link.title}
                  </Link>
                </li>
              )
            })}
          </ul>
          <ul>
            {secondLinks.map((link) => (
              <li key={link.title}>
                <Link
                  href={
                    link.url
                      ? link.url.replace(
                          ':host',
                          webApp.utils.dougaMvHost(context)
                        )
                      : null
                  }
                  route={routes[link.route]}
                  params={link.params}
                  target={link.target}
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        </StyledDiv1>
        <StyledDiv2>
          <p>
            配信されている動画は放送内容と一部異なる場合がございます。また、予告なく配信終了する場合がございますがご了承ください。表示されている情報は、収録および放送当時のものです。
            <br />
            消費税率の変更により、テレビ朝日番組内およびホームページ内の価格表示と異なる場合があります。
          </p>
          <StyledDiv2Inner>
            <dl>
              <dt>
                <img
                  src="/images/exdio/renewal/icon_jasrac.png"
                  width="52"
                  height="52"
                  alt=""
                />
              </dt>
              <dd>
                <strong>JASRAC許諾番号</strong>
                <Jasrac courseId={courseId} />
              </dd>
            </dl>
            <dl>
              <dt>
                <img
                  src="/images/exdio/renewal/icon_nextone.png"
                  width="52"
                  height="52"
                  alt=""
                />
              </dt>
              <dd>
                <strong>NexTone許諾番号</strong>
                <p>
                  UD000001695
                  <br />
                  UD000001696
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <img
                  src="/images/exdio/renewal/icon_lmark.png"
                  width="49"
                  height="52"
                  alt=""
                />
              </dt>
              <dd>
                <p className="lMark">
                  エルマークは、レコード会社・映像制作会社が提供するコンテンツを示す登録商標です
                </p>
                <p className="lMarkNum">IRAJ60017003</p>
              </dd>
            </dl>
          </StyledDiv2Inner>
        </StyledDiv2>
        {showLogo && (
          <StyledDiv3>
            <StyledLink route={routes.home}>
              <img
                src="/images/exdio/renewal/logo_tv_asahi_douga.png"
                width="207"
                height="41"
                alt="テレ朝動画"
              />
            </StyledLink>
          </StyledDiv3>
        )}
      </StyledInner>
      {courseId === config.course_id.doraemon && (
        <StyledSmall2>
          (C)藤子プロ・小学館・テレビ朝日・シンエイ・ADK
        </StyledSmall2>
      )}
      {courseId === config.course_id.shinchan && (
        <StyledSmall2>
          © 臼井儀人／双葉社・シンエイ・テレビ朝日・ADK
        </StyledSmall2>
      )}
      <StyledSmall>Copyright(C) tv asahi All rights reserved.</StyledSmall>
    </StyledFooter>
  )
}

export default memo(Footer)

Footer.propTypes = {
  courseId: PropTypes.number,
  showLogo: PropTypes.bool
}

Footer.contextTypes = {
  models: PropTypes.shape({})
}

const StyledFooter = styled.footer`
  margin-top: 48px; // コンポーネント側にマージンをつけない
  display: block !important; // 初回読み込み時に崩れるため反映されるまで非表示にする
  background: #f3f4f5;

  ${mediaQuery()} {
    margin-top: 35px; // コンポーネント側にマージンをつけない
    padding-top: 40px;
  }

  &.mt0 {
    margin-top: 0;
  }

  &.mt120 {
    margin-top: 120px;
  }
`

const StyledGenreLinksSp = styled(GenreLinksSp)`
  order: 4;
  margin-top: 2rem;
  ${mediaQuery('mm')} {
    margin-top: 1rem;
  }
`

const StyledInner = styled.div`
  margin: 0 auto;
  padding: 40px 0;
  max-width: 1280px;
  min-height: 328px;
  display: flex;
  flex-wrap: wrap;

  ${mediaQuery()} {
    padding: 0;
    max-width: 1024px;
    min-height: auto;
    display: block;
  }
`

const StyledDiv1 = styled.div`
  width: calc((100% - 12px) / 2);
  display: flex;

  ${mediaQuery()} {
    margin-top: 24px;
    width: auto;
    display: block;
  }

  ul {
    width: calc((100% - 30px) / 2);

    ${mediaQuery()} {
      width: auto;
      background: #fff;
    }

    & + ul {
      margin-left: 30px;

      ${mediaQuery()} {
        margin-left: 0;
      }
    }
  }

  li {
    border-bottom: 1px solid #e2e2e2;
  }

  a {
    padding: 1.57em 0.42em;
    display: block;
    color: ${fixColors.colorFont};
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.4;
    transition: opacity 0.3s;

    ${mediaQuery()} {
      padding: 1.46em;
      font-size: 1.5rem;
    }

    &:hover {
      opacity: 0.7;
    }

    &:visited {
      color: ${fixColors.colorFont};
    }
  }
`

const StyledDiv2 = styled.div`
  margin-left: 12px;
  padding-top: 16px;
  width: calc((100% - 12px) / 2);
  color: #343434;

  @media (max-width: 1280px) {
    padding: 0 12px;
  }

  ${mediaQuery()} {
    margin-left: 0;
    width: 100%;
    padding: 0;
  }

  & > p {
    font-size: 1.1rem;
    line-height: 1.4;

    ${mediaQuery()} {
      padding: 20px 15px 15px;
    }
  }
`

const StyledDiv2Inner = styled.div`
  margin-top: 2rem;
  padding: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: #fff;
  word-break: break-all;

  ${mediaQuery()} {
    display: none;
  }

  dl {
    display: flex;
    dt {
      width: 52px;
    }
    dd {
      margin-left: 14px;
      width: 100%;
      font-size: 1rem;

      strong {
        margin-bottom: 0.5em;
        display: block;
        font-weight: 600;
      }

      p {
        line-height: 1.4;
        &.lMark {
          font-size: 0.8rem;
        }
        &.lMarkNum {
          font-weight: 600;
        }
      }
    }
  }
`

export const StyledDiv3 = styled.div`
  margin-top: 24px;
  width: 100%;
  text-align: center;

  ${mediaQuery()} {
    margin-top: 0;
    padding-bottom: 24px;
  }
`

export const StyledLink = styled(Link)`
  display: inline-block;

  ${mediaQuery()} {
    img {
      width: 207px;
    }
  }
`

const StyledSmall = styled.small`
  padding: 26px 0 48px;
  background: #fff;
  display: block;
  color: ${fixColors.colorFont};
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
`

const StyledSmall2 = styled(StyledSmall)`
  padding-bottom: 0;
`
