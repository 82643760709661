import { useState, useRef, useEffect } from 'react'
import { get, size } from 'lodash'
import axios from 'axios'
import { getMetaKey, formatKeywords, getNotIdArr } from '../util'

/* default */
export const defaultState = {
  pagerOptions: {
    episodesPerPages: 12,
    range: 2,
    showBottom: false
  },
  childEpisodeIds: [],
  selectedYear: null,
  seasonIds: [],
  tags: [],
  notTags: [],
  pageNum: 1,
  onlyWatchedAll: false,
  filteredBy: '',
  sortedBy: 'values.avails_EpisodeNumber',
  searchWord: '',
  forceUpdateParams: (current) => current
}

const defaultOptions = {
  beforeSearch: () => {},
  afterSearch: () => {}
}

const mergeDeeply = (target, source, opts) => {
  const isObject = (obj) =>
    obj && typeof obj === 'object' && !Array.isArray(obj)
  const isConcatArray = opts && opts.concatArray
  const result = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      const value = source[key]
      const targetValue = target[key]
      if (isConcatArray && Array.isArray(value) && Array.isArray(targetValue)) {
        result[key] = targetValue.concat(...value)
      } else if (
        isObject(value) &&
        Object.prototype.hasOwnProperty.call(target, key)
      ) {
        result[key] = mergeDeeply(targetValue, value, opts)
      } else {
        Object.assign(result, { [key]: value })
      }
    })
  }
  return result
}

const useSearchParams = (initState = {}, initOptions = {}) => {
  /** initialize */
  const [state, setState] = useState({
    ...defaultState,
    ...initState
  })
  const [response, setResponse] = useState({
    episodes: [],
    totalCount: 0
  })
  const [isLoading, setIsLoading] = useState(false)
  const options = {
    ...defaultOptions,
    ...initOptions
  }

  /** 比較用 */
  const prevState = useRef({})
  const prevSearchParams = useRef({})

  /** 条件変更(1ページ目を表示) */
  const set = (newState) => {
    setState({
      ...state,
      ...newState,
      pageNum: 1
    })
  }

  /** ページングのみ */
  const paging = (nextPageNum) => {
    setState({
      ...state,
      pageNum: nextPageNum
    })
  }

  const getParams = () => {
    const {
      pagerOptions,
      seasonIds,
      childEpisodeIds,
      selectedYear,
      tags,
      notTags,
      pageNum,
      onlyWatchedAll,
      filteredBy,
      sortedBy,
      searchWord,
      forceUpdateParams
    } = state

    /** 除外するID */
    const notIdArr = getNotIdArr(childEpisodeIds, filteredBy, onlyWatchedAll)
    const { sort, order } = getMetaKey(sortedBy)
    const searchWordKana = formatKeywords(searchWord).join(' ')
    const tagsArr = size(tags) ? tags.map((tag) => ({ tags: tag })) : []
    const notTagsArr = size(notTags)
      ? notTags.map((tag) => ({ tags: tag }))
      : []
    const limit = get(pagerOptions, ['episodesPerPages'])
    let params = {
      page: Number(pageNum),
      limit: String(limit),
      sort: {
        [sort]: { order },
        'values.avails_EpisodeNumber': 'asc'
      },
      condition: {
        'values.parent_season_ids': seasonIds,
        'values.evis_ProductionYear': Number(selectedYear) || '', // nullにすると何も返ってこなくなる
        ...(tagsArr ? { and: tagsArr } : []),
        or: [
          {
            like: [
              { 'values.avails_SeriesTitleDisplayUnlimited': searchWord },
              { 'values.evis_SeriesTitlePronunciation': searchWordKana },
              { 'values.avails_SeasonTitleDisplayUnlimited': searchWord },
              { 'values.evis_SeasonTitlePronunciation': searchWordKana },
              { 'values.avails_EpisodeTitleDisplayUnlimited': searchWord },
              { 'values.evis_EpisodeTitlePronunciation': searchWordKana },
              { 'values.evis_EpisodeLongSynopsis': searchWord },
              { 'values.evis_EpisodeLongSynopsis': searchWordKana },
              { tags: searchWord },
              { tags: searchWordKana }
            ]
          }
        ],
        not: [
          {
            id: notIdArr
          },
          ...notTagsArr
        ]
      },
      fields: 'values,tags',
      with_total_count: true
    }

    if (typeof forceUpdateParams === 'function') {
      params = forceUpdateParams(params)
    }

    return params
  }

  /**
   * エピソード一覧取得
   * // HACK
   * getDsearchだとnotに入る件数が多くて414エラーが返る
   * -> postDsearchを使用する
   * Falcorを使うとpostDsearchのときに431エラーが返る
   * -> axiosを使用する
   * タグ検索はAND条件
   */
  const search = async (params) => {
    const { beforeSearch = () => {}, afterSearch = () => {} } = options

    setIsLoading(true)
    if (typeof beforeSearch === 'function') {
      beforeSearch()
    }

    return axios
      .post('/api/dsearch/post', params)
      .then((res) => {
        return {
          episodes: get(res, ['data', 'metas']) || [],
          totalCount: get(res, ['data', 'total_count']) || 0
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false)
        if (typeof afterSearch === 'function') {
          afterSearch(state)
        }
      })
  }

  /** stateが変更されるたび検索を行う */
  useEffect(() => {
    const params = getParams()

    /** 検索パラメータと状態が両方変更されていない場合は検索しない */
    if (
      JSON.stringify(params) === JSON.stringify(prevSearchParams.current) &&
      JSON.stringify(state) === JSON.stringify(prevState.current)
    ) {
      return
    }

    prevState.current = state
    prevSearchParams.current = params
    ;(async () => {
      const res = await search(params)
      setResponse(res)
    })()
  }, [state])

  return {
    state,
    response,
    set,
    paging,
    isLoading
  }
}

export default useSearchParams
