import routes from '../common/routes'

import BaseRouteHandler from './layouts/BaseRouteHandler'
import ExdioLayout from './layouts/ExdioLayout'
import ExdioAppLayout from './layouts/ExdioAppLayout'

// PC, SP
import HomeContent from './components/browse/HomeContent'
import LoginContent from './components/browse/LoginContent'
import CatchupContent from './components/browse/CatchupContent'
import PackContent from './components/browse/PackContent'
import PlanContent from './components/browse/PlanContent'
import GenreContent from './components/browse/GenreContent'
import ProgramContent from './components/browse/ProgramContent'
import EpisodeContent from './components/browse/EpisodeContent'
import SearchContent from './components/browse/SearchContent'
import MyPageContent from './components/browse/MyPageContent'
import NewsContent from './components/browse/news'
import GenreListContent from './components/browse/GenreListContent'
import RecommendListContent from './components/browse/RecommendListContent'
import RecommendYouListContent from './components/browse/RecommendYouListContent'
import PurchaseContent from './components/browse/PurchaseContent'
import PurchaseLicenseContent from './components/browse/PurchaseLicenseContent'
import PurchaseResultContent from './components/browse/PurchaseResultContent'
import MedalChargeContent from './components/browse/MedalChargeContent'
import StaticPageContent from './components/browse/StaticPageContent'
import LogirlStaticPageContent from './components/browse/LogirlStaticPageContent'
import GaribenTagListContent from './components/browse/GaribenTagListContent'
import RegistEmailContent from './components/browse/RegistEmailContent'
import RegistMemberContent from './components/browse/RegistMemberContent'
import RemakePasswordContent from './components/browse/RemakePasswordContent'
import RemakeSetPasswordContent from './components/browse/RemakeSetPasswordContent'
import LogirlLiveListContent from './components/browse/LogirlLiveListContent'
import FeatureMpContent from './components/browse/FeatureMp'
import PlainIframeContent from './components/browse/PlainIframeContent'
import ForKidsContent from './components/browse/ForKidsContent'
import CampaignStaticPageContent from './components/browse/CampaignStaticPageContent'
import SnsLoginContent from './components/browse/SnsLoginContent'
import Hissatsu from './components/browse/Hissatsu'
import GaribenAnimeMatsuri from './components/browse/GaribenAnimeMatsuri'
import GaribenAnimeMatsuriError from './components/browse/GaribenAnimeMatsuri/Error'

// APP
import AppLoginContent from './components/browse/appli/LoginContent'
import AppCatchupContent from './components/browse/appli/CatchupContent'
import AppPackContent from './components/browse/appli/PackContent'
import AppPlanContent from './components/browse/appli/PlanContent'
import AppProgramContent from './components/browse/appli/ProgramContent'
import AppEpisodeContent from './components/browse/appli/EpisodeContent'
import AppSearchContent from './components/browse/appli/SearchContent'
import AppMyPageContent from './components/browse/appli/MyPageContent'
import AppNewsContent from './components/browse/appli/NewsContent'
import AppRecommendListContent from './components/browse/appli/RecommendListContent'
import AppRegistEmailContent from './components/browse/appli/RegistEmailContent'
import AppRemakePasswordContent from './components/browse/appli/RemakePasswordContent'

// SAMPLE
import SampleContent from './components/browse/SampleContent'

const routeHandlers = [
  // PC・SP
  {
    route: routes.home,
    components: [BaseRouteHandler, ExdioLayout, HomeContent],
    uiView: 'homeScreen',
    view: 'browseTitles',
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.login,
    components: [BaseRouteHandler, ExdioLayout, LoginContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.catchup,
    components: [BaseRouteHandler, ExdioLayout, CatchupContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.pack,
    components: [BaseRouteHandler, ExdioLayout, PackContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.plan,
    components: [BaseRouteHandler, ExdioLayout, PlanContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.genre,
    components: [BaseRouteHandler, ExdioLayout, GenreContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.genreSearch,
    components: [BaseRouteHandler, ExdioLayout, GenreListContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.recommend,
    components: [BaseRouteHandler, ExdioLayout, RecommendListContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.recommendYou,
    components: [BaseRouteHandler, ExdioLayout, RecommendYouListContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.program_other_version,
    components: [BaseRouteHandler, ExdioLayout, ProgramContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.program,
    components: [BaseRouteHandler, ExdioLayout, ProgramContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.episode,
    components: [BaseRouteHandler, ExdioLayout, EpisodeContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.catchupEpisode,
    components: [BaseRouteHandler, ExdioLayout, EpisodeContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.search,
    components: [BaseRouteHandler, ExdioLayout, SearchContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.mypage,
    components: [BaseRouteHandler, ExdioLayout, MyPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.mypage_wallet,
    components: [BaseRouteHandler, ExdioLayout, MyPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.mypage_view_license,
    components: [BaseRouteHandler, ExdioLayout, MyPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.mypage_cancel_license,
    components: [BaseRouteHandler, ExdioLayout, MyPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.mypage_account_info,
    components: [BaseRouteHandler, ExdioLayout, MyPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.mypage_account_setting,
    components: [BaseRouteHandler, ExdioLayout, MyPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.mypage_member_setting,
    components: [BaseRouteHandler, ExdioLayout, MyPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.mypage_update_email_result,
    components: [BaseRouteHandler, ExdioLayout, MyPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.mypage_update_credit,
    components: [BaseRouteHandler, ExdioLayout, MyPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.edit_icon,
    components: [BaseRouteHandler, ExdioLayout, MyPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.purchase,
    components: [BaseRouteHandler, ExdioLayout, PurchaseContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.purchase_license,
    components: [BaseRouteHandler, ExdioLayout, PurchaseLicenseContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.purchase_result,
    components: [BaseRouteHandler, ExdioLayout, PurchaseResultContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.medal_charge,
    components: [BaseRouteHandler, ExdioLayout, MedalChargeContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.information,
    components: [BaseRouteHandler, ExdioLayout, NewsContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.newarrival,
    components: [BaseRouteHandler, ExdioLayout, NewsContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.feature,
    components: [BaseRouteHandler, ExdioLayout, FeatureMpContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.static,
    components: [BaseRouteHandler, ExdioLayout, StaticPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.regist_email,
    components: [BaseRouteHandler, ExdioLayout, RegistEmailContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.regist_member,
    components: [BaseRouteHandler, ExdioLayout, RegistMemberContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.remake_password,
    components: [BaseRouteHandler, ExdioLayout, RemakePasswordContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.remake_set_password,
    components: [BaseRouteHandler, ExdioLayout, RemakeSetPasswordContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.privacy,
    components: [BaseRouteHandler, ExdioLayout, StaticPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.redirect,
    components: [BaseRouteHandler, ExdioLayout, StaticPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.wmquick_return,
    components: [BaseRouteHandler, ExdioLayout, PlainIframeContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.purchase_cancel,
    components: [BaseRouteHandler, ExdioLayout, PlainIframeContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.logirl_live,
    components: [BaseRouteHandler, ExdioLayout, LogirlLiveListContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.logirl_static,
    components: [BaseRouteHandler, ExdioLayout, LogirlStaticPageContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.logirl_program_radio,
    components: [BaseRouteHandler, ExdioLayout, LogirlStaticPageContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.gariben_tag_list,
    components: [BaseRouteHandler, ExdioLayout, GaribenTagListContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.present_static,
    components: [BaseRouteHandler, ExdioLayout, CampaignStaticPageContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.campaign_static,
    components: [BaseRouteHandler, ExdioLayout, CampaignStaticPageContent],
    header: false,
    scrollTop: true,
    tvAsahiPoint: false
  },
  {
    route: routes.kids,
    components: [BaseRouteHandler, ExdioLayout, ForKidsContent],
    header: false,
    scrollTop: false,
    tvAsahiPoint: false
  },
  {
    route: routes.info,
    components: [BaseRouteHandler, ExdioLayout, StaticPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.info_static,
    components: [BaseRouteHandler, ExdioLayout, StaticPageContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.android_sns_login,
    components: [BaseRouteHandler, ExdioLayout, SnsLoginContent],
    header: false,
    scrollTop: false,
    tvAsahiPoint: false
  },
  {
    route: routes.sample,
    components: [BaseRouteHandler, ExdioLayout, SampleContent],
    header: true,
    scrollTop: true,
    tvAsahiPoint: true
  },
  {
    route: routes.hissatsu,
    components: [BaseRouteHandler, ExdioLayout, Hissatsu],
    header: false,
    scrollTop: false,
    tvAsahiPoint: false
  },
  {
    route: routes.gariben_anime_matsuri,
    components: [BaseRouteHandler, ExdioLayout, GaribenAnimeMatsuri],
    header: false,
    scrollTop: false,
    tvAsahiPoint: false
  },
  {
    route: routes.gariben_anime_matsuri_error,
    components: [BaseRouteHandler, ExdioLayout, GaribenAnimeMatsuriError],
    header: false,
    scrollTop: false,
    tvAsahiPoint: false
  },

  // APP
  {
    route: routes.app_home,
    components: [BaseRouteHandler, ExdioAppLayout, AppMyPageContent], // アプリホームはマイページと同じコンテンツ
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_login,
    components: [BaseRouteHandler, ExdioAppLayout, AppLoginContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_catchup,
    components: [BaseRouteHandler, ExdioAppLayout, AppCatchupContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_pack,
    components: [BaseRouteHandler, ExdioAppLayout, AppPackContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_plan,
    components: [BaseRouteHandler, ExdioAppLayout, AppPlanContent],
    header: false,
    scrollTop: true
  },
  {
    route: routes.app_recommend,
    components: [BaseRouteHandler, ExdioAppLayout, AppRecommendListContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_program_other_version,
    components: [BaseRouteHandler, ExdioAppLayout, AppProgramContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_program,
    components: [BaseRouteHandler, ExdioAppLayout, AppProgramContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_episode,
    components: [BaseRouteHandler, ExdioAppLayout, AppEpisodeContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_catchupEpisode,
    components: [BaseRouteHandler, ExdioAppLayout, AppEpisodeContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_search,
    components: [BaseRouteHandler, ExdioAppLayout, AppSearchContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_mypage,
    components: [BaseRouteHandler, ExdioAppLayout, AppMyPageContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_mypage_wallet,
    components: [BaseRouteHandler, ExdioAppLayout, AppMyPageContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_mypage_view_license,
    components: [BaseRouteHandler, ExdioAppLayout, AppMyPageContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_mypage_cancel_license,
    components: [BaseRouteHandler, ExdioAppLayout, AppMyPageContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_mypage_account_info,
    components: [BaseRouteHandler, ExdioAppLayout, AppMyPageContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_mypage_account_setting,
    components: [BaseRouteHandler, ExdioAppLayout, AppMyPageContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_mypage_member_setting,
    components: [BaseRouteHandler, ExdioAppLayout, AppMyPageContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_mypage_update_credit,
    components: [BaseRouteHandler, ExdioAppLayout, AppMyPageContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_edit_icon,
    components: [BaseRouteHandler, ExdioAppLayout, AppMyPageContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_information,
    components: [BaseRouteHandler, ExdioAppLayout, AppNewsContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_newarrival,
    components: [BaseRouteHandler, ExdioAppLayout, AppNewsContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_regist_email,
    components: [BaseRouteHandler, ExdioAppLayout, AppRegistEmailContent],
    header: true,
    scrollTop: true
  },
  {
    route: routes.app_remake_password,
    components: [BaseRouteHandler, ExdioAppLayout, AppRemakePasswordContent],
    header: true,
    scrollTop: true
  }
]

export default routeHandlers
