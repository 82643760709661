export const tagIds = {
  logicad: 'tag-logicad',
  TVBridgeAds: 'tag-TVBridgeAds',
  PiaDSP: 'tag-PiaDSP'
}

export const logicad = `<script type="text/javascript" id="${tagIds.logicad}">
(function(s,m,n,l,o,g,i,c,a,d){c=(s[o]||(s[o]={}))[g]||(s[o][g]={});if(c[i])return;c[i]=function(){(c[i+"_queue"]||(c[i+"_queue"]=[])).push(arguments)};a=m.createElement(n);a.charset="utf-8";a.async=true;a.src=l;d=m.getElementsByTagName(n)[0];d.parentNode.insertBefore(a,d)})(window,document,"script","https://cd.ladsp.com/script/pixel2.js","Smn","Logicad","pixel");
Smn.Logicad.pixel({
"smnAdvertiserId":"00017496"
});
</script>`

export const TVBridgeAds = `<script type="text/javascript" id="${tagIds.TVBridgeAds}">
(function(s,m,n,t,a,g,h,d,l,r){d=(s[a]||(s[a]={}))[g]||(s[a][g]={});if(d[h])return;d[h]=function(){(d[h+"_queue"]||(d[h+"_queue"]=[])).push(arguments)};l=m.createElement(n);l.charset="utf-8";l.async=true;l.src=t;r=m.getElementsByTagName(n)[0];r.parentNode.insertBefore(l,r)})(window,document,"script","https://cd.ladsp.com/script/pb_pixel2.js","Smn","AdTag","pixel");
Smn.AdTag.pixel({
"smnAdvertiserId":"00017508"
});
</script>`

export const PiaDSP = `<script type="text/javascript" id="${tagIds.PiaDSP}">
var _fout_queue = _fout_queue || {}; if (_fout_queue.segment === void 0) _fout_queue.segment = {};
if (_fout_queue.segment.queue === void 0) _fout_queue.segment.queue = [];

_fout_queue.segment.queue.push({
  'user_id': 44788,
  'segment_id': 2191706
});

(function() {
  var el = document.createElement('script'); el.type = 'text/javascript'; el.async = true;
  el.src = 'https://js.fout.jp/segmentation.js';
  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(el, s);
})();
</script>`
