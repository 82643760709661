import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import styled from 'styled-components'
import Button from './Button'

/*
 * ページャー
 */
const Pager = ({
  option = {
    episodesPerPages: 20,
    range: 2
  },
  episodeLength = 0,
  currentPageNum = 1,
  updateCurrentPageNum = () => {},
  clickScroll = false,
  ...props
}) => {
  /*
   * currentPageNum: 表示中のページ番号
   */
  const [allPageLength, setAllPageLength] = useState(0)

  /** ページ数を算出 */
  useEffect(() => {
    setAllPageLength(Math.ceil(episodeLength / option.episodesPerPages))
  }, [episodeLength, option.episodesPerPages])

  /** 単話リスト上部へスクロール */
  const scrollTop = () => {
    if (clickScroll === false) return
    const episodeList = document.getElementById('episodes-list')
    const episodeListPos = episodeList.getBoundingClientRect().top
    const header = document.getElementById('mp-header')
    const headerHeight = header ? header.getBoundingClientRect().height : 0
    const rectY = episodeListPos + window.pageYOffset - headerHeight
    window.scroll({ top: rectY, behavior: 'smooth' })
  }

  /** 先頭に表示する数字 */
  const getFirstPageNum = () => {
    const { range } = option
    const defaultNum = currentPageNum - range
    const limitNum = 1

    if (currentPageNum <= range + 1) {
      // 表示が少ないとき
      return limitNum
    }
    if (currentPageNum > allPageLength - range) {
      // 右側に寄るとき
      const firstNum = defaultNum - (range - (allPageLength - currentPageNum))
      return firstNum > 0 ? firstNum : limitNum
    }
    if (defaultNum > limitNum) {
      // 通常時
      return defaultNum
    }
    // 左側に寄るとき
    return limitNum
  }

  // 末尾に表示する数字
  const getLastPageNum = () => {
    const { range } = option
    const defaultNum = currentPageNum + range

    if (currentPageNum <= range) {
      // 左側に寄るとき
      return defaultNum + (range - (currentPageNum - 1))
    }
    if (defaultNum <= allPageLength) {
      // 通常時
      return defaultNum
    }
    // 右側に寄るとき
    return allPageLength
  }

  /**
   * ページングの実行
   * @param {number|string} pageNumOrKey ページ番号 or キー
   */
  const pagingHandler = (pageNumOrKey) => {
    // 表示中のページ番号のボタンがクリックされた場合は実行しない
    if (pageNumOrKey === currentPageNum) return

    switch (pageNumOrKey) {
      /** 次へボタンを押したときの挙動 */
      case 'next': {
        const nextNum =
          currentPageNum + 1 <= allPageLength
            ? currentPageNum + 1
            : allPageLength
        updateCurrentPageNum(nextNum)
        break
      }

      /** 前へボタンを押したときの挙動 */
      case 'prev': {
        const prevNum = currentPageNum - 1 >= 1 ? currentPageNum - 1 : 1
        updateCurrentPageNum(prevNum)
        break
      }

      /** 最初へボタンを押したときの挙動 */
      case 'first':
        updateCurrentPageNum(1)
        break

      /** 最後へボタンを押したときの挙動 */
      case 'last':
        updateCurrentPageNum(allPageLength)
        break

      /** 選択中のページ番号を更新する */
      default:
        if (typeof pageNumOrKey === 'number') {
          updateCurrentPageNum(pageNumOrKey)
        }
        break
    }

    /** 単話リスト上部へスクロール */
    scrollTop()
  }

  /** エピソードが存在しなければ終了 */
  if (!(episodeLength > 0)) return null

  /** 話数が1ページの表示件数未満のとき、表示しない */
  if (episodeLength <= option.episodesPerPages) return null

  return (
    <StyledDiv {...props}>
      <StyledUl>
        <StyledLi>
          <Button
            type="button"
            value={1}
            isFirstChild
            onClick={() => pagingHandler('first')}
          >
            &lt;&lt;
          </Button>
        </StyledLi>
        <StyledLi>
          <Button
            type="button"
            value={getFirstPageNum()}
            onClick={() => pagingHandler('prev')}
          >
            &lt;
          </Button>
        </StyledLi>
        {(() => {
          let listItems = []
          for (let i = getFirstPageNum(); i <= getLastPageNum(); i++) {
            if (i <= allPageLength) {
              listItems.push(
                <StyledLi key={i}>
                  <Button
                    value={i}
                    isCurrent={i == currentPageNum}
                    onClick={() => pagingHandler(Number(i))}
                  >
                    {i}
                  </Button>
                </StyledLi>
              )
            }
          }
          return listItems
        })()}
        <StyledLi>
          <Button
            type="button"
            value={getLastPageNum()}
            onClick={() => pagingHandler('next')}
          >
            &gt;
          </Button>
        </StyledLi>
        <StyledLi>
          <Button
            type="button"
            value={allPageLength}
            onClick={() => pagingHandler('last')}
          >
            &gt;&gt;
          </Button>
        </StyledLi>
      </StyledUl>
    </StyledDiv>
  )
}

export default memo(Pager)

Pager.propTypes = {
  option: PropTypes.shape({
    episodesPerPages: PropTypes.number,
    range: PropTypes.number
  }),
  episodeLength: PropTypes.number,
  currentPageNum: PropTypes.number,
  updateCurrentPageNum: PropTypes.func,
  clickScroll: PropTypes.bool
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
`

const StyledUl = styled.ul`
  display: flex;
`

const StyledLi = styled.li``
