import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Cookie from 'js-cookie'
import Footer from '../../../common/components/Footer'
import NotFound from '../../../generic/components/errors/NotFound'
import {
  SEARCH_TYPE,
  SEARCH_TYPES,
  SORT_TYPE,
  SORT_TYPES,
  PRODUCT_SCHEMA
} from '../../../../constants/app'
import TabNavigation from '../../../common/components/renewal/TabNavigation'
import ProgramItem from '../../../common/components/renewal/ProgramItem'
import webApp from '../../utils/exdioWebAppUtils'
import SelectBox from '../../../common/components/renewal/SelectBox'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import HeaderNewsComponent from './HeaderNewsComponent'

const PAGE_TITLE = 'あなたへのオススメ'
const FETCH_COUNT = 20

/** あなたへのオススメページ */
export default class RecommendYouListContent extends Component {
  static propTypes = {
    paletteId: PropTypes.string
  }
  static defaultProps = {
    paletteId: null
  }
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  static getSsrMetaTags(_models, _options, _props, _prefetchResult) {
    return { title: PAGE_TITLE }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    this.state = {
      recommendItems: null,
      searchType: SEARCH_TYPE.ALL.value,
      sortType: SORT_TYPE.NEWER.value,
      isNotFound: false,
      sorted: false,
      palette: null,
      apiType: null,
      refId: null,
      genreName: null
    }

    this.onClickItem = this.onClickItem.bind(this)
    this.onChangeSearchType = this.onChangeSearchType.bind(this)
    this.onChangeSortType = this.onChangeSortType.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    this._isMounted = true

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context, PAGE_TITLE)
    // GTMの更新
    const title = this.context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)

    this.getPalette()
      .then(() => this.getRecommend())
      .catch((e) => webApp.utils.handleFalcorError(e, this.context))
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /** パレット情報取得 */
  getPalette() {
    const { paletteId } = this.props
    const path = ['palette', paletteId]
    return this.model.fetch([path]).then((result) => {
      const palette = _.get(result, ['json', ...path]) || {}
      this.setState({ palette, isNotFound: !palette })
    })
  }

  /** レコメンド処理 */
  async getRecommend() {
    const { palette } = this.state
    if (!palette) return Promise.resolve()

    const { api_type, tags1, category2 } = _.get(palette, ['values']) || {}

    let refId = ''
    if (tags1) {
      // tags1はコースID
      const path = ['course', tags1]
      refId = await this.model.fetch([path]).then((result) => {
        const course = _.get(result, ['json', ...path]) || {}
        return course.ref_id
      })
    }

    let genreName = ''
    if (category2) {
      // category2はジャンル名
      const path = ['attribute', category2]
      genreName = await this.model.fetch([path]).then((result) => {
        const genre = _.get(result, ['json', ...path]) || {}
        return genre.name
      })
    }

    if (this._isMounted) {
      this.setState({
        apiType: api_type,
        refId,
        genreName
      })
    }

    let getRecommendExec = null
    if (api_type === 'one_to_one') {
      getRecommendExec = this.oneToOne.bind(this)
    } else if (api_type === 'recommend') {
      getRecommendExec = this.recommend.bind(this)
    } else if (api_type === 'longtail') {
      getRecommendExec = this.longtail.bind(this)
    } else {
      return Promise.resolve()
    }
    return getRecommendExec(genreName, refId).then(() =>
      this.recommendActionLog()
    )
  }

  /** One to Oneレコメンド */
  oneToOne(genreName, refId, typeParam = SEARCH_TYPE.ALL.value) {
    const { palette } = this.state
    const itemIdsCsv = (_.get(palette, ['values', 'item_ids']) || []).join(',')
    const userId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // onetooneはuser_id必須なのでluid取れなければ非表示
    if (!userId) {
      webApp.utils.debug(
        '[RecommendYouListContent] skip call one_to_one API because luid and _ga are blank.'
      )
      return Promise.resolve()
    }
    const spotName = _.get(palette, ['values', 'spot_name']) || ''
    let type = typeParam
    if (
      type === SEARCH_TYPE.FREE.value &&
      webApp.utils.isLoggedIn(this.context)
    ) {
      type = SEARCH_TYPE.AUTH_FREE.value
    }
    const metaSchemaId = _.get(palette, ['values', 'category3']) || ''
    const path = [
      'oneToOne',
      itemIdsCsv,
      userId,
      spotName,
      type,
      genreName,
      metaSchemaId,
      refId,
      FETCH_COUNT
    ]
    return this.model.fetch([path]).then((result) => {
      const recommendItems = _.get(result, ['json', ...path]) || []
      if (this._isMounted) {
        this.setState({ recommendItems }, () => this.sort())
      }
    })
  }

  /** レコメンド */
  recommend(genreName, refId, typeParam = SEARCH_TYPE.ALL.value) {
    const { palette } = this.state
    const itemIdsCsv = (_.get(palette, ['values', 'item_ids']) || []).join(',')
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    const spotName = _.get(palette, ['values', 'spot_name']) || ''
    let type = typeParam
    if (
      type === SEARCH_TYPE.FREE.value &&
      webApp.utils.isLoggedIn(this.context)
    ) {
      type = SEARCH_TYPE.AUTH_FREE.value
    }
    const metaSchemaId = _.get(palette, ['values', 'category3']) || ''
    const path = [
      'recommend',
      itemIdsCsv,
      sessionId,
      spotName,
      type,
      genreName,
      metaSchemaId,
      refId,
      FETCH_COUNT
    ]
    return this.model.fetch([path]).then((result) => {
      const recommendItems = _.get(result, ['json', ...path]) || []
      if (this._isMounted) {
        this.setState({ recommendItems }, () => this.sort())
      }
    })
  }

  /** ロングテール */
  longtail(genreName, refId, typeParam = SEARCH_TYPE.ALL.value) {
    const { palette } = this.state
    const itemIdsCsv = (_.get(palette, ['values', 'item_ids']) || []).join(',')
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    const spotName = _.get(palette, ['values', 'spot_name']) || ''
    let type = typeParam
    if (
      type === SEARCH_TYPE.FREE.value &&
      webApp.utils.isLoggedIn(this.context)
    ) {
      type = SEARCH_TYPE.AUTH_FREE.value
    }
    const metaSchemaId = _.get(palette, ['values', 'category3']) || ''
    const path = [
      'recommendLongtail',
      itemIdsCsv,
      sessionId,
      spotName,
      type,
      genreName,
      metaSchemaId,
      refId,
      FETCH_COUNT
    ]
    return this.model.fetch([path]).then((result) => {
      const recommendItems = _.get(result, ['json', ...path]) || []
      if (this._isMounted) {
        this.setState({ recommendItems }, () => this.sort())
      }
    })
  }

  /** レコメンド(ログ蓄積) */
  recommendActionLog() {
    const { recommendItems } = this.state
    const { palette } = this.state
    if (!recommendItems || !recommendItems.length || !palette)
      return Promise.resolve()
    const itemIds = recommendItems.map((r) => r.meta_id).join(',')
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) {
      webApp.utils.debug(
        '[RecommendYouListContent] skip call action_log/kpi/display API because luid and _ga are blank.'
      )
      return Promise.resolve()
    }
    const typeName = this.config.recommend.type_name.view
    const spotName = _.get(palette, ['values', 'spot_name']) || ''
    const path = [
      'action_log',
      'kpi',
      'display',
      itemIds,
      sessionId,
      typeName,
      spotName
    ]
    return this.model.fetch([path])
  }

  /** レコメンド(クリック) */
  recommendClick(itemId) {
    const { palette } = this.state
    if (!palette) return Promise.resolve()
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) {
      webApp.utils.debug(
        '[RecommendYouListContent] skip call action_log/kpi/click API because luid and _ga are blank.'
      )
      return Promise.resolve()
    }
    const typeName = this.config.recommend.type_name.view
    const spotName = _.get(palette, ['values', 'spot_name']) || ''
    const path = [
      'action_log',
      'kpi',
      'click',
      itemId,
      sessionId,
      typeName,
      spotName
    ]
    return this.model.fetch([path])
  }

  /** 検索条件変更時 */
  onChangeSearchType(searchType) {
    const { apiType, genreName, refId } = this.state
    let getRecommendExec = null
    if (apiType === 'one_to_one') {
      getRecommendExec = this.oneToOne.bind(this)
    } else if (apiType === 'recommend') {
      getRecommendExec = this.recommend.bind(this)
    } else if (apiType === 'longtail') {
      getRecommendExec = this.longtail.bind(this)
    } else {
      return Promise.resolve()
    }
    return getRecommendExec(genreName, refId, searchType).then(() =>
      this.recommendActionLog()
    )
  }

  /** 並び替え条件変更時 */
  onChangeSortType(sortType) {
    if (this._isMounted) {
      this.setState({ sortType }, () => this.sort())
    }
  }

  goBack(e) {
    e.preventDefault()
    this.context.history.goBack()
  }

  sort() {
    const { recommendItems, sortType } = this.state
    if (!recommendItems || !recommendItems.length) return

    let sortFunc
    if (sortType === SORT_TYPE.OLDER.value) {
      sortFunc = (a, b) => {
        const startAtA = this.getStartAt(a)
        const startAtB = this.getStartAt(b)
        if (!startAtA) return 1
        if (!startAtB) return -1
        return startAtA < startAtB ? -1 : 1
      }
    } else if (sortType === SORT_TYPE.ENDING.value) {
      sortFunc = (a, b) => {
        const endAtA = this.getEndAt(a)
        const endAtB = this.getEndAt(b)
        if (!endAtA) return 1
        if (!endAtB) return -1
        return endAtA < endAtB ? -1 : 1
      }
    } else if (sortType === SORT_TYPE.ASC.value) {
      sortFunc = (a, b) => (this.getName(a) < this.getName(b) ? -1 : 1)
    } else if (sortType === SORT_TYPE.DESC.value) {
      sortFunc = (a, b) => (this.getName(a) > this.getName(b) ? -1 : 1)
    } else {
      sortFunc = (a, b) => (this.getStartAt(a) > this.getStartAt(b) ? -1 : 1)
    }
    recommendItems.sort(sortFunc)

    if (this._isMounted) {
      this.setState({ recommendItems, sorted: true })
    }
  }

  getStartAt(object) {
    const deliveryDateTime = object.delivery_datetime
      ? JSON.parse(object.delivery_datetime)
      : {}
    return (deliveryDateTime.start_at || '').replace(/-/g, '/')
  }

  getEndAt(object) {
    const deliveryDateTime = object.delivery_datetime
      ? JSON.parse(object.delivery_datetime)
      : {}
    return (deliveryDateTime.end_at || '').replace(/-/g, '/')
  }

  getName(item) {
    return `${item.program_name}${item.episode_name}`
  }

  /** 一覧要素クリック時 */
  onClickItem(meta, autoPlay = true) {
    if (meta) {
      this.recommendClick(meta.meta_id)
    }
    webApp.utils.goToProgramLink(this.context, meta, null, null, { autoPlay })
  }

  render() {
    if (this.state.isNotFound) return <NotFound />
    const { recommendItems, sorted } = this.state

    return (
      <div className="common-wrapper">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />
        <div className="c-listByGenre">
          <div className="c-listByGenre-head">
            <a
              href="#"
              className="c-listByGenre-head-back"
              onClick={this.goBack}
            >
              戻る
            </a>
            <div className="c-listByGenre-head-title">
              <h3 className="c-listByGenre-head-title-head">{PAGE_TITLE}</h3>
            </div>

            <div className="c-listByGenre-head-navBox">
              <div className="c-listByGenre-head-navBox-nav">
                <TabNavigation
                  types={SEARCH_TYPES}
                  initialType={SEARCH_TYPE.ALL}
                  onSelect={this.onChangeSearchType}
                />
                <SelectBox
                  types={SORT_TYPES}
                  onSelect={this.onChangeSortType}
                />
              </div>
            </div>
          </div>
          <div className="c-card-panel">
            <div className="c-card-panel-cont">
              {sorted &&
                (recommendItems || []).map((item) => {
                  const ids = item.ids ? JSON.parse(item.ids) : {}
                  const deliveryDateTime = item.delivery_datetime
                    ? JSON.parse(item.delivery_datetime)
                    : {}
                  const delivery_start_at = (
                    deliveryDateTime.start_at || ''
                  ).replace(/-/g, '/')
                  const delivery_end_at = (
                    deliveryDateTime.end_at || ''
                  ).replace(/-/g, '/')
                  const meta = {
                    meta_id: item.meta_id,
                    meta_schema_id: item.meta_schema_id,
                    thumbnail_url: item.thumbnail_url,
                    delivery_start_at,
                    delivery_end_at,
                    name: item.program_name,
                    values: {
                      avails_EpisodeTitleDisplayUnlimited: item.episode_name,
                      evis_EpisodeLongSynopsis: item.synopsis,
                      evis_SeasonLongSynopsis: item.synopsis,
                      parents_series: {
                        id: ids.series
                      },
                      parents_season: {
                        id: ids.season,
                        avails_SeasonTitleDisplayUnlimited: item.program_name
                      },
                      duration: item.duration
                    }
                  }
                  const price = item.price ? JSON.parse(item.price) : {}
                  const priceTvod = price.tvod
                  const refId = item.course_ref_ids
                    ? item.course_ref_ids.split(':')[0]
                    : ''
                  const priceSvod = refId ? _.get(price, ['svod', refId]) : null
                  const howToPlay = {
                    products: [
                      {
                        schema_id: PRODUCT_SCHEMA.SINGLE_STORY.id,
                        active_pricing: { price: priceTvod }
                      }
                    ],
                    courses: [{ active_pricing: { price: priceSvod } }]
                  }
                  return (
                    <ProgramItem
                      key={meta.meta_id}
                      meta={meta}
                      howToPlay={howToPlay}
                      recomenndItem={item}
                      showCaption
                      showCoin
                      showDelivery
                      showNew={webApp.utils.showNew(meta)}
                      breakSubTitle
                      onClickThumbnail={() => this.onClickItem(meta)}
                      onClickCaption={() => this.onClickItem(meta, false)}
                    />
                  )
                })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
