import styled, { createGlobalStyle } from 'styled-components'
import { Link } from '../../../../../sketch-platform/ui/routing'
import Caption from './Caption'
import EpisodePager from './EpisodePager'
import Comment from '../comment'
import FilterSort, {
  StyledDiv1 as FilterSortDiv1,
  StyledSearchBoxText
} from '../../../../common/components/FilterSort'
import EpisodeFooter from '../../../../common/components/EpisodeFooter'
import { mediaQuery, breakPoints, fixColors } from '../../style'

export const StyledContainer = styled.div`
  background-color: ${fixColors.colorBg};
  overflow-x: hidden;
`

export const StyledContainerInner = styled.div`
  margin: 0 auto;
  padding: 30px 80px 80px;
  max-width: 1280px;
  background-color: #fff;
  display: flex;
  column-gap: 30px;

  @media (min-width: ${breakPoints.sm + 1}px) and (max-width: 1279px) {
    padding: 30px 15px 80px;
    justify-content: space-around;
  }

  ${mediaQuery()} {
    padding: calc(100vw * (9 / 16)) 0 40px;
    display: block;
  }
`

export const StyledDiv1 = styled.div`
  flex: 1 0;
  position: relative;
  z-index: 2;

  ${mediaQuery()} {
    max-width: none;
  }
`

export const StyledDiv2 = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  ${mediaQuery()} {
    padding: 0 15px;
    width: auto;
  }
`

export const StyledHdg = styled.h2`
  margin-top: 10px;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.5;

  ${mediaQuery()} {
    padding: 0 15px;
    font-size: 1.6rem;
  }
`

export const StyledHdgSpan = styled.span`
  font-size: 1.8rem;

  ${mediaQuery()} {
    font-size: 1.2rem;
  }
`

export const StyledMeta = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  ${mediaQuery()} {
    margin-top: 0;
    padding: 15px;
    display: ${(props) => (props.isShow ? 'flex' : 'none')};
    flex-direction: column;
  }
`

export const StyledList1 = styled.ul`
  margin-top: 10px;
  width: 50%;
  display: flex;

  ${mediaQuery()} {
    width: auto;
    display: block;
    order: 1;
  }
`

export const StyledList1Item = styled.li`
  margin-left: 20px;
  font-size: 1.3rem;

  ${mediaQuery()} {
    margin-left: 0;
    margin-top: 5px;
  }

  &:first-child {
    margin-left: 0;

    ${mediaQuery()} {
      margin-top: 0;
    }
  }
`

export const StyledEpisodePager = styled(EpisodePager)`
  width: 50%;
  position: relative;
  z-index: 1;

  ${mediaQuery()} {
    margin-top: 15px;
    width: auto;
    order: 3;
  }
`

export const StyledCaption = styled(Caption)`
  width: 100%;
  transform: ${(props) =>
    props.isOpen || props.hideButton ? null : 'translateY(-1em)'};

  ${mediaQuery()} {
    margin-top: 20px;
    width: auto;
    order: 2;
    transform: none;
  }
`

export const StyledFilterSort = styled(FilterSort)`
  padding: 0;

  ${FilterSortDiv1} {
    flex-direction: column-reverse;
    gap: 15px 0;
  }

  ${StyledSearchBoxText} {
    width: 100%;
  }
`

export const StyledComment = styled(Comment).withConfig({
  shouldForwardProp: (prop) => !['hide'].includes(prop)
})`
  min-height: 400px;
  display: ${({ hide }) => (hide ? 'none' : null)};
`

export const StyledLink = styled(Link)`
  margin: 30px auto 0;
  padding: 14px 24px;
  width: 300px;
  border-radius: 5px;
  display: block;
  background-color: #ff0096;
  color: #fff;
  text-align: center;
  transition: opacity 0.4s;

  &:link,
  &:visited {
    color: #fff;
  }

  &:hover {
    color: #fff;
    opacity: 0.7;
    cursor: pointer;
  }
`

export const StyledEpisodeFooter = styled(EpisodeFooter)`
  margin-top: 0;
`

export const GlobalStyle = createGlobalStyle`
@media (max-width: 320px) {
  body {
    zoom: normal;
  }
}

/* video */
.c-video_wrapper {
  position: relative;

  ${mediaQuery()} {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
  }

  &::before {
    ${mediaQuery()} {
      padding-top: 56.25%;
      display: block;
      content: '';
    }
  }
}
.player-modal-wrapper {
  position: absolute;
  display: block;
}
.player-modal-wrapper .watch {
  width: auto;
}

/* video thumbnail */
.common-video-area {
  margin-top: 0;
}

/* episode list */
.c-sortSwitch {
  display: none;
}
.c-card-vertical-cont {
  .c-card-inBox-meta-caption,
  .c-card-inBox-meta-price {
    display: none;
  }
}
`
