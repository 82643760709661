import React from 'react'
import PropTypes from 'prop-types'
import addSizePrefix from '../../addSizePrefix'
import routes from '../../../../../../common/routes'
import { StyledDiv, StyledImg, StyledLink, StyledP } from './style'

/** Section6｜リストの要素 */
const ListItem = ({ meta = {} }) => {
  return (
    <StyledLink
      route={routes.episode}
      params={{
        seriesId: _.get(meta, ['values', 'parents_series', 'id']),
        seasonId: _.get(meta, ['values', 'parents_season', 'id']),
        episodeId: _.get(meta, ['meta_id'])
      }}
    >
      <StyledDiv>
        <StyledImg
          src={addSizePrefix(_.get(meta, ['thumbnail_url']), 'large')}
          alt={_.get(meta, [
            'values',
            'parents_series',
            'avails_SeriesTitleDisplayUnlimited'
          ])}
          width="470"
          height="265"
          loading="lazy"
        />
      </StyledDiv>
      <StyledP>
        {_.get(meta, [
          'values',
          'parents_series',
          'avails_SeriesTitleDisplayUnlimited'
        ])}
      </StyledP>
    </StyledLink>
  )
}

export default ListItem

ListItem.propTypes = {
  /** リストの表示要素
   * @param {string} meta_id - コンテンツのid
   * @param {string} values.parents_series.id - 親要素のID, 遷移先の指定に使用
   * @param {string} values.parents_season.id - 親要素のID, 遷移先の指定に使用
   * @param {string} thumbnail_url - コンテンツのサムネイル画像
   */
  meta: PropTypes.shape({
    meta_id: PropTypes.string,
    values: PropTypes.object,
    thumbnail_url: PropTypes.string
  })
}
