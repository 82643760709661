import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import Card from './Card'
import { useFilteredSortedData } from './useFilteredSortedData'
import useIsMounted from '../../../../../../hooks/useIsMounted'

/* style */
import { StyledDivInner } from '../../style'
import styled from 'styled-components'
import { mediaQuery, pxToVw } from '../../../../../../exdio/components/style'

/** 必殺仕事人 LP | シリーズ一覧 */
const SeriesList = ({
  seasonMetas,
  searchText,
  conversionToKana,
  handleSearchText,
  deleteSearchText,
  isLoading
}) => {
  const isMounted = useIsMounted()
  const selectValue = useMemo(
    () => [
      { val: 0, label: '放送が古い順' },
      { val: 1, label: '放送が新しい順' }
    ],
    []
  )

  const [sort, setSort] = useState(1)
  const sortedData = useFilteredSortedData(
    seasonMetas,
    searchText,
    conversionToKana,
    sort
  )

  if (!isMounted) return null

  return (
    <StyledSection id="link2">
      <StyledDivInner>
        <StyledH2>
          <img
            src="/images/exdio/renewal/hissatsu/serieslist_title.png"
            alt="シリーズ一覧"
          />
        </StyledH2>
        <StyledDiv1>
          <StyledDiv2>
            <StyledDiv3>
              <StyledInput
                type="text"
                value={searchText}
                placeholder="検索"
                onChange={handleSearchText}
                onBlur={handleSearchText}
                onKeyDown={handleSearchText}
              />
              <StyledButton onClick={deleteSearchText} />
            </StyledDiv3>
          </StyledDiv2>

          <StyledDiv4>
            <StyledP>番組タイトル、キャストの一部を入力してください。</StyledP>

            <StyledDiv5>
              <StyledSelect
                name="sortSelect"
                value={sort}
                onChange={(e) => setSort(parseInt(e.target.value, 10))}
              >
                {selectValue.map((item) => (
                  <option value={item.val} key={item.val}>
                    {item.label}
                  </option>
                ))}
              </StyledSelect>
            </StyledDiv5>
          </StyledDiv4>

          <StyledDiv6>
            {isLoading && <p>ただいま情報を取得しています。</p>}
            {!isLoading && sortedData.length === 0 ? (
              <p>検索結果が見つかりませんでした。</p>
            ) : (
              sortedData.map((item) => (
                <Card seasonMetas={item} key={item.id} />
              ))
            )}
          </StyledDiv6>
        </StyledDiv1>
      </StyledDivInner>
    </StyledSection>
  )
}

export default SeriesList

SeriesList.propTypes = {
  /** シリーズのメタデータの配列。 */
  seasonMetas: PropTypes.array,
  /** ユーザーによって入力された検索テキスト */
  searchText: PropTypes.string,
  /** テキストをカナに変換するための関数 */
  conversionToKana: PropTypes.func,
  /** 検索テキストを変更する際に呼び出される関数 */
  handleSearchText: PropTypes.func,
  /** 検索テキストを削除するための関数 */
  deleteSearchText: PropTypes.func,
  /** コンテンツが読み込み中かどうかを示すブール値 */
  isLoading: PropTypes.bool
}

const StyledH2 = styled.h2`
  display: block;
  margin: 0 auto;
  text-align: center;

  ${mediaQuery(768)} {
    img {
      width: min(${pxToVw(367, 750)}, 367px);
    }
  }
`

const StyledSection = styled.section`
  padding: 100px 0 80px;
  background: url(/images/exdio/renewal/hissatsu/serieslist_bg.png) repeat top
    center / 60px;

  ${mediaQuery(768)} {
    padding: 30px 0;
  }
`

const StyledDiv1 = styled.div`
  margin-top: 25px;
  padding: 40px 25px;
  background: url(/images/exdio/renewal/hissatsu/serieslist_bg2.png) repeat;

  ${mediaQuery(768)} {
    padding: 20px 10px;
  }
`

const StyledDiv2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StyledDiv3 = styled.div`
  width: 100%;
  position: relative;
  font-size: 1.3rem;
  background: #e5e6cb;
  border-radius: 19px;
  padding-left: 37px;
  padding-right: 37px;

  &::before {
    content: '';
    background: url(/images/exdio/renewal/icon_search.svg);
    background-size: 100%;
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 12px;
    margin: auto;
  }
`

const StyledInput = styled.input`
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
  height: 37px;
  width: 100%;
  color: #343434;
  font-size: 1.3rem;
`

const StyledButton = styled.button`
  display: block;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 12px;
  margin: auto;
  cursor: pointer;
  background: url(/images/exdio/renewal/icon_close.svg);
  background-size: 100%;
  border: 0;
`

const StyledDiv4 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;

  ${mediaQuery(768)} {
    flex-direction: column;
    align-items: self-start;
    gap: 10px;
  }
`

const StyledP = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
`

const StyledSelect = styled.select`
  appearance: none;
  padding: 10px 30px 10px 10px;
  border: 1px solid #d3d6db;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  overflow: hidden;
  cursor: pointer;
`

const StyledDiv5 = styled.div`
  position: relative;

  &::after {
    content: '';
    margin: auto 0;
    width: 0;
    height: 0;
    border-width: 8px 5px 0;
    border-style: solid;
    border-color: #343434 transparent transparent;
    position: absolute;
    top: 18px;
    right: 10px;
    transform: translateY(-50%);
  }
`

const StyledDiv6 = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  ${mediaQuery(768)} {
    margin-top: 20px;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
`
