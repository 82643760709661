import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { get } from 'lodash'
import { createGlobalStyle } from 'styled-components'

/** チャット機能 */
const Chat = ({
  hash = '',
  hasVote = false,
  setPostMessage = () => {},
  onOpen = () => {},
  onClose = () => {},
  headerRef = null,
  ...props
}) => {
  const buttonTextTemp = {
    open: 'チャットエリアを開く',
    close: hasVote
      ? 'チャットエリアを閉じる（投票する）'
      : 'チャットエリアを閉じる'
  }

  const iframeSrc = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 'https://st-cc-front-chat.tvasahi.jp/index.html'
    : 'https://cc-front-chat.tv-asahi.co.jp/index.html'

  if (!hash) return null

  const parentUrl = encodeURIComponent(window.location.href) || ''

  let src = `${iframeSrc}?hash=${hash}`
  if (parentUrl) src += `&parent_url=${parentUrl}`

  const [isSp, setIsSp] = useState(
    window.matchMedia('screen and (max-width: 1024px)').matches
  )
  const [isChatAreaOpen, setIsChatAreaOpen] = useState(true)
  const [buttonText, setButtonText] = useState(buttonTextTemp.close)
  const [isFocused, setIsFocused] = useState(false)
  const [iframeHeight, setIframeHeight] = useState(null)
  const frameRef = useRef(null)

  const handleSeekTime = (e) => {
    const { origin, data } = e
    const originArr = [
      // 'http://localhost:8000', // ローカルでのテスト用
      'https://st-cc-front-chat.tvasahi.jp',
      'https://cc-front-chat.tv-asahi.co.jp'
    ]
    if (!(originArr.includes(origin) && data)) return

    const seekTime = get(data, ['seekTime'])
    if (typeof seekTime !== 'number') return

    const DioPlayer = document.getElementById('DIOplayer')
    const videoElm = DioPlayer ? DioPlayer.querySelector('video') : null
    if (videoElm) {
      videoElm.currentTime = seekTime
    }
  }

  /** SP チャットエリアの開閉 */
  const toggleChatArea = (_isChatAreaOpen = false) => {
    setIsChatAreaOpen(_isChatAreaOpen)
    if (isSp === true && _isChatAreaOpen === true) {
      if (typeof onOpen === 'function') onOpen()
      setButtonText(buttonTextTemp.close) // 開閉ボタンテキスト変更
    } else {
      if (typeof onClose === 'function') onClose()
      setButtonText(buttonTextTemp.open) // 開閉ボタンテキスト変更
    }
  }

  const onResizeHandler = () => {
    const _isSp = window.matchMedia('screen and (max-width: 1024px)').matches
    if (_isSp !== isSp) {
      setIsSp(isSp)

      if (_isSp === false) {
        toggleChatArea(false)
      }
    }
  }

  /** iframeの高さ上限を計算(PC) */
  const getMaxHeight = () => {
    const headerHeight = headerRef && headerRef.offsetHeight
    const headSub = document.querySelector('.c-head-sub')
    const headSubHeight = headSub && headSub.offsetHeight
    const containerMarginTop = 30 // .p-episode_live__containerのmargin-top
    const iframeMarginBottom = 30 // iframeと画面下部とのマージン
    const sum =
      headerHeight + headSubHeight + containerMarginTop + iframeMarginBottom

    if (window.matchMedia('(max-width: 1024px)').matches === false) {
      return `calc(100vh - ${sum}px)`
    }
    return ''
  }

  /** プレイヤー操作をチャットに送信 */
  const postMessage = (seekTime) => {
    const chatFrame = document.getElementById('chatArea')
    chatFrame.contentWindow.postMessage({ seekTime }, '*')
  }

  useEffect(() => {
    /** 初期表示は開いた状態 */
    toggleChatArea(true)
    window.addEventListener('resize', onResizeHandler)

    setPostMessage(postMessage)

    if (typeof window !== undefined) {
      window.addEventListener('message', handleSeekTime, false)
    }

    return () => {
      window.removeEventListener('resize', onResizeHandler)
    }
  }, [])

  return (
    <div
      className={cn('c-iframe2__wrapper', {
        'is-active': isChatAreaOpen,
        'is-focused': isFocused
      })}
      {...props}
    >
      <button
        type="button"
        className="c-iframe2__btn"
        onClick={() => toggleChatArea(!isChatAreaOpen)}
      >
        <span
          className={cn('c-iframe2__btn__text', {
            'is-active': isChatAreaOpen
          })}
        >
          {buttonText}
        </span>
      </button>
      <iframe
        title="chat"
        src={src}
        id="chatArea"
        className="c-iframe2"
        width="100%"
        height="800"
        frameBorder="0"
        scrolling="no"
        style={{
          maxHeight: getMaxHeight(),
          height: iframeHeight || null
        }}
        ref={frameRef}
      />
      <GlobalStyle />
    </div>
  )
}

export default Chat

Chat.propTypes = {
  hash: PropTypes.string.isRequired,
  hasVote: PropTypes.bool,
  setPostMessage: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  headerRef: PropTypes.shape({
    current: PropTypes.element
  })
}

const GlobalStyle = createGlobalStyle`
@media (max-width: 1024px) {
  body {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  #mp-header{
    margin-top: 0;
    transition: margin-top .4s ease-in-out;
  }
}
`
