import React from 'react'
import PropTypes from 'prop-types'
import { PALETTE_SCHEMA_ID } from '../../../../../../constants/app'
import Palette from '../../Palette'

const Palettes = ({
  palettes = [],
  howToPlays = {},
  products = null,
  courses = null,
  canvasConfig = {},
  loaded = false
}) => {
  if (!(palettes.length > 0)) return null
  const { large_idx, palette_header } = canvasConfig

  return palettes
    .filter((palette) => palette.schema_id !== PALETTE_SCHEMA_ID.IMAGES)
    .map((palette, i) => (
      <Palette
        key={palette.palette_id}
        palette={palette}
        howToPlays={howToPlays}
        large={(large_idx || []).includes(i + 1)}
        products={products}
        courses={courses}
        paletteHeader={palette_header !== false}
        loaded={loaded}
      />
    ))
}

export default Palettes

Palettes.propTypes = {
  palettes: PropTypes.array,
  howToPlays: PropTypes.object,
  products: PropTypes.object,
  courses: PropTypes.object,
  canvasConfig: PropTypes.shape({
    ad_idx_banner: PropTypes.number,
    ad_idx_side: PropTypes.number,
    large_idx: PropTypes.arrayOf(PropTypes.number),
    twitter: PropTypes.bool,
    ad: PropTypes.bool,
    palette_header: PropTypes.bool
  }).isRequired,
  loaded: PropTypes.bool
}
