import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/** そだてれび：海外視聴用 SP 単話ページ動画詳細 情報 */
const SpDetailInfo = ({ time, delivery_date, release_date, content }) => {
  const infoCaptionRef = useRef(null)
  const captionLineHeight = 1.5
  const captionFontSize = 13
  const captionMaxHeight = captionFontSize * captionLineHeight * 4
  const [isMore, setIsMore] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const setCaptionAccordion = () => {
    if (!infoCaptionRef.current) return
    setIsMore(infoCaptionRef.current.clientHeight > captionMaxHeight)
  }

  const handleClick = () => {
    setIsShow(!isShow)
  }

  useEffect(() => {
    setCaptionAccordion()
    window.addEventListener('resize', setCaptionAccordion)

    return () => window.removeEventListener('resize', setCaptionAccordion)
  }, [])

  return (
    <StyledDiv1>
      <StyledP1>
        time：{time} / Delivery Period：<time>{delivery_date[0]}</time>～ /
        OnAir：
        <time>{release_date[0]}</time>
      </StyledP1>
      <StyledDiv2 maxHeight={captionMaxHeight} isMore={isMore} isShow={isShow}>
        <StyledP2
          ref={infoCaptionRef}
          lineHeight={captionLineHeight}
          fontSize={captionFontSize}
        >
          {content}
        </StyledP2>
      </StyledDiv2>
      {isMore && (
        <StyledDiv3>
          <StyledButton onClick={() => handleClick()} isShow={isShow}>
            {isShow ? 'close' : 'more'}
          </StyledButton>
        </StyledDiv3>
      )}
    </StyledDiv1>
  )
}

SpDetailInfo.propTypes = {
  time: PropTypes.string,
  delivery_date: PropTypes.arrayOf(PropTypes.string),
  release_date: PropTypes.arrayOf(PropTypes.string),
  content: PropTypes.string
}

export default SpDetailInfo

const StyledDiv1 = styled.div`
  color: #515151;
  padding: 15px 0;
  background: rgba(255, 255, 255, 0.7);
`

const StyledP1 = styled.p`
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 12px;
  padding: 0 10px;
`

const StyledDiv2 = styled.div`
  position: relative;
  max-height: ${({ maxHeight, isShow }) =>
    maxHeight && !isShow && `${maxHeight}px`};
  overflow: hidden;
`

const StyledP2 = styled(StyledP1)`
  color: #515151;
  font-size: ${({ fontSize }) => fontSize && `${fontSize}px`};
  line-height: ${({ lineHeight }) => lineHeight && lineHeight};
`

const StyledDiv3 = styled.div`
  text-align: center;
`

const StyledButton = styled.button`
  text-align: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  font-size: 13px;
  font-weight: 600;
  font-family: 'Noto Sans JP', sans-serif;
  color: #515151;

  &::after {
    content: ${({ isShow }) =>
      isShow
        ? 'url(/images/exdio/renewal/arrow_top.svg)'
        : 'url(/images/exdio/renewal/arrow_bottom.svg)'};
    margin-left: 0.5em;
  }
`
