import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { bgColor } from '../../styles'

/** そだてれび：海外視聴用 SP 単話ページ動画詳細 タイトル */
const SpDetailTitle = ({ title, className }) => {
  return <StyledH3 className={className}>{title}</StyledH3>
}

SpDetailTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
}

export default SpDetailTitle

const StyledH3 = styled.h3`
  padding: 15px 10px;
  background: ${bgColor};
  font-size: 14px;
  line-height: 16px;
  word-break: break-all;
`
