import React, { memo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, size } from 'lodash'
import moment from 'moment'
import window from 'global'
import Cookie from 'js-cookie'
import axios from 'axios'
import styled, { css, createGlobalStyle } from 'styled-components'
import {
  LOCAL_STORAGE_KEY_PURCHASE,
  PURCHASE_TYPE,
  EPISODE_DISPLAY_MODE,
  EPISODE_DISPLAY_MODES_FREE,
  DIO_PLAYER_DEVICE
} from '../../../../../constants/app'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import Header from '../../../../common/components/Header'
import EpisodeFooter from '../../../../common/components/EpisodeFooter'
import PackList from '../details/PackList'
import RenewalPlayerInline from '../../player/RenewalPlayerInline'
import SwitchableListGrid from '../../../../common/components/renewal/SwitchableListGrid'
import HtmlSnippet from '../../HtmlSnippet'
import ListMetaSub from '../EpisodeDefault/ListMetaSub'
import Controller from './Controller'
import Chat from '../chat/Functional'
import TabPanel from './TabPanel'
import Comment from '../comment'
import VoteForm from '../vote_form'

import PlayerCover from './PlayerCover'
import PlayerCoverDisabled from './PlayerCoverDisabled'
import { getPrice, gotoPurchasePage } from './functions'

/** style */
import { breakPoints, mediaQuery } from '../../style'
import Info from './Info'

moment.createFromInputFallback = (config) => {
  const newConfig = { ...config }
  newConfig._d = new Date(config._i)
  return newConfig
}

/**
 * ライブ配信デフォルトテンプレート
 * ブラウザ/アプリ共用
 */
const EpisodeLive = (
  {
    seriesId = '',
    seasonId = '',
    episodeId = '',
    meta = {},
    programMeta = {},
    episodes = [],
    products = [],
    howToPlays = {},
    course = {},
    status = {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    product = {},
    sendPlayLog = () => {},
    productRight = {},
    rightMetas = null,
    otherSeasons = [],
    loaded = false
  },
  context
) => {
  const {
    FREE,
    TVOD_FREE,
    TVOD_NOT_FREE,
    SVOD_FREE,
    SVOD_NOT_FREE,
    SVOD_AUTH_FREE,
    STVOD_FREE,
    STVOD_TVOD_NOT_FREE,
    STVOD_SVOD_NOT_FREE,
    UNKNOWN
  } = EPISODE_DISPLAY_MODE

  const {
    isFree: statusIsFree,
    isPurchased,
    isNotPurchased,
    isNotInCourse,
    isGeoDeliverable,
    isDeviceNotAvailable,
    displayMode
  } = status

  const config = context.models.config.data
  const previewUrlList = config.preview_url_list
  const cookieRubocopPrefix = config.cookie_rubocop_prefix
  const isApp = webApp.utils.isApp(context)
  const isLoggedIn = webApp.utils.isLoggedIn(context)
  const pagerOptions = {
    // ページャーの設定
    episodesPerPages: 12,
    range: 2
  }

  const device = webApp.utils.getDevice()
  const isSpDevice = !(device === DIO_PLAYER_DEVICE.PC)

  const [progress, setProgress] = useState(webApp.utils.progress(config, meta))
  const [showThumb, setShowThumb] = useState(true)
  const [currentTabId, setCurrentTabId] = useState('chat')
  const [isArchived, setIsArchived] = useState(false)
  const intervalId = useRef(null)
  const handlePostMessage = useRef(() => {})

  const playerRef = useRef(null)
  const setPlayerRef = (e) => {
    playerRef.current = e
  }

  // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
  // https://logiclogic.backlog.jp/view/EX_DIO-1467
  const withValidPreviewToken = get(context, [
    'models',
    'state',
    'data',
    'withValidPreviewToken'
  ])

  const prevTime = Cookie.get(previewUrlList + cookieRubocopPrefix)
  const inViewTerm = (() => {
    if (withValidPreviewToken) return true // ワンタイムプレビューの場合は常に蓋開け
    if (moment(prevTime).isBefore(meta.delivery_start_at)) return false
    if (moment(prevTime).isAfter(meta.delivery_end_at)) return false
    return true
  })()

  /**
   * @return number
   * 0: 視聴可能
   * 1: TVODで未購入
   * 2: SVODで未購入
   * 3: 購入不可
   */
  const getPurchaseAvailableStatus = (asSvod = false) => {
    // 有効なプレビュー用トークンがURLに付与されている場合は無条件で再生可能
    if (withValidPreviewToken) return 0

    // 端末・地域制限に引っかかる場合は反応させない
    if (!isGeoDeliverable || isDeviceNotAvailable) return 3

    // 購入済み
    if (isPurchased) return 0

    switch (displayMode) {
      case FREE:
      case TVOD_FREE:
      case SVOD_FREE:
      case STVOD_FREE:
        return 0

      case TVOD_NOT_FREE:
      case STVOD_TVOD_NOT_FREE:
        if (isNotPurchased) {
          // STVODでSVODボタンが押された場合
          if (asSvod) return 2
          // 商品が販売期間外
          if (product && !webApp.utils.isOnSale(context, product)) return 2
          return 1
        }
        return 3

      case SVOD_NOT_FREE:
      case STVOD_SVOD_NOT_FREE:
        if (isNotPurchased) return 2
        return 3

      case UNKNOWN:
      default:
        return 3
    }
  }

  const onClickPlay = (_, asSvod = false) => {
    const purchaseAvailableStatus = getPurchaseAvailableStatus(asSvod)

    switch (purchaseAvailableStatus) {
      case 0: // 視聴可能
        if (inViewTerm) {
          if (playerRef.current) playerRef.current.play()
          // 再生ログ送信
          if (typeof sendPlayLog === 'function') sendPlayLog()

          /** チャットアーカイブを開始する */
          if (isArchived) {
            clearInterval(intervalId.current)
            intervalId.current = setInterval(() => {
              const DioPlayer = document.getElementById('DIOplayer')
              const videoElm = DioPlayer
                ? DioPlayer.querySelector('video')
                : null
              const seekTime = videoElm ? Math.floor(videoElm.currentTime) : 0
              handlePostMessage.current(seekTime)
            }, 1000)
          }
        }
        break

      case 1: // TVOD
        if (isLoggedIn) {
          gotoPurchasePage(context, episodeId, seasonId)
        } else {
          // ログインページに遷移
          context.history.push(
            routes.login.makePath(null, {
              redirect: context.routeHandler.path
            })
          )
        }
        break

      case 2: // SVOD
        if (isLoggedIn) {
          const courseIdNum = Number(course.course_id)
          window.localStorage.setItem(
            LOCAL_STORAGE_KEY_PURCHASE,
            JSON.stringify({
              type: PURCHASE_TYPE.PLAN,
              id: courseIdNum,
              selected: [courseIdNum],
              page: context.routeHandler.url
            })
          )
          context.history.push(routes.purchase.makePath())
        } else {
          // ログインページに遷移
          context.history.push(
            routes.login.makePath(null, {
              redirect: context.routeHandler.path
            })
          )
        }
        break

      default:
        break
    }
  }

  const setPostMessage = (postMessage) => {
    handlePostMessage.current = postMessage
  }

  const metaName = (() => {
    const [main, sub] = webApp.utils.titles(meta)
    return `${main} ${sub}`
  })()
  const latest = (episodes && episodes[0]) || {}
  const isLatest = meta && latest && meta.meta_id === latest.meta_id
  const rest = isSpDevice ? webApp.utils.rest(meta) : null

  const showRest = (() => {
    if (isPurchased) {
      return true
    }

    switch (displayMode) {
      case FREE:
      case TVOD_FREE:
      case SVOD_FREE:
      case STVOD_FREE:
        return true
      case TVOD_NOT_FREE:
      case SVOD_NOT_FREE:
      case STVOD_TVOD_NOT_FREE:
      case STVOD_SVOD_NOT_FREE:
      default:
        return false
    }
  })()

  const programBannerId1 = get(programMeta, ['values', 'banner_1'])
  const programBannerId2 = get(programMeta, ['values', 'banner_2'])
  // const episodeBannerId1 = get(meta, ['values', 'banner_1'])
  // const episodeBannerId2 = get(meta, ['values', 'banner_2'])

  const isClipVod = meta.values.clipping_select_media_type === 'vod'

  // LIVE配信 主/副切替ID
  const metaIdForPlayer = (() => {
    let idForPlayer
    if (isClipVod) idForPlayer = meta.values.clipping_vod_ref_id
    if (!idForPlayer) idForPlayer = meta.values.target_ref_id
    if (!idForPlayer)
      idForPlayer = `${config.videocloud.reference_id_prefix || ''}${
        meta.meta_id
      }`
    return idForPlayer
  })()
  const playerSettings = webApp.utils.getPlayerSettings(
    config,
    meta,
    displayMode
  )

  const thumbnailUrl = (() => {
    let thumb = meta.thumbnail_url
    if (moment(prevTime).isBefore(meta.delivery_start_at)) {
      thumb = meta.values.livectl_image_before_stream_url
    }
    if (moment(prevTime).isAfter(meta.delivery_end_at)) {
      thumb = meta.values.livectl_image_after_stream_url
    }
    if (!thumb) thumb = config.default_thumbnail

    return thumb
  })()

  const isPlayerRenderable =
    metaIdForPlayer && displayMode && (inViewTerm || withValidPreviewToken)

  const isFree = EPISODE_DISPLAY_MODES_FREE.includes(displayMode)

  /** チャット */
  const chatHash = get(
    meta,
    ['values', 'live_stvod_front_display_config', 'chat_hash'],
    ''
  )
  const hasChat = Boolean(chatHash)

  /** コメント */
  const commentHash = get(
    meta,
    ['values', 'live_stvod_front_display_config', 'comment_hash'],
    ''
  )
  const hasComment = Boolean(commentHash)

  /** 投票 */
  const voteButtonData = get(meta, [
    'values',
    'live_stvod_front_display_config',
    'button_list'
  ])
  const voteVisible = get(voteButtonData, ['visible']) || false

  /** ビデオコントローラ */
  const hasController =
    (isFree || isPurchased) &&
    get(
      meta,
      ['values', 'live_stvod_front_display_config', 'controller', 'visible'],
      false
    )
  /** meta.values.durationから取れると良いが、LIVE_STVODの場合は設定されないみたい */
  const videoLength = get(
    meta,
    ['values', 'live_stvod_front_display_config', 'controller', 'videoLength'],
    '00:00:00'
  )

  /** タブ初期値 */
  const getInitialTabId = () => {
    let tabId = ''
    if (hasChat) tabId = 'chat'
    return tabId
  }

  /** チャットがアーカイブ状態か判別 */
  const resetIsArchived = () => {
    if (!size(meta)) return

    axios
      .get(`${config.chat.endpoint}/${chatHash}/information`)
      .then(({ data }) => {
        const { status: chatStatus } = data
        setIsArchived(chatStatus === 2)
        /**
         * READY: 0
         * LIVE: 1
         * ARCHIVED: 2
         */
      })
  }

  useEffect(() => {
    /** アプリからブラウザにリダイレクト */
    if (isApp) {
      const pathname = get(context, ['history', 'location', 'pathname'])
      const redirectHref = pathname && pathname.replace('/app', '')
      window.location.href = redirectHref
    }

    resetIsArchived()

    return () => {
      document.body.style.overflow = null
    }
  }, [])

  useEffect(() => {
    const isAutoPlay = context.routeHandler.query.auto === 't'
    if (loaded && status && isAutoPlay) {
      const isAuthFree = SVOD_AUTH_FREE === displayMode && isLoggedIn
      if (isFree || isAuthFree || isPurchased || withValidPreviewToken) {
        setShowThumb(!showThumb)
        onClickPlay()
      }
    }
  }, [loaded, status])

  useEffect(() => {
    const initialTabId = getInitialTabId()
    setCurrentTabId(initialTabId)
  }, [hasChat])

  return (
    <>
      <Header />
      <StyledVideoSpacer />

      <StyledTabPanel
        hasChat={hasChat}
        hasComment={hasComment}
        currentTabId={currentTabId}
        setCurrentTabId={setCurrentTabId}
      />

      <StyledMain id="episodeLive" hideOverflow={currentTabId !== ''}>
        <StyledDiv1
          hasChat={hasChat}
          hasVote={voteVisible}
          currentTabId={currentTabId}
        >
          <StyledDiv1Inner>
            <StyledVideoWrapper
              thumbnailUrl={thumbnailUrl}
              onClick={() => setShowThumb(!showThumb)}
            >
              {isPlayerRenderable && (
                <RenewalPlayerInline
                  ref={setPlayerRef}
                  meta_id={metaIdForPlayer}
                  product_type={playerSettings.product_type}
                  channel={playerSettings.channel}
                  ssai_ad_config_id={playerSettings.ssai_ad_config_id}
                  ssai_player_id={playerSettings.ssai_player_id}
                  stvod_player_id={playerSettings.stvod_player_id}
                  live_player_id={playerSettings.live_player_id}
                  delivery_config_id={playerSettings.delivery_config_id}
                  thumbnail_url={meta.thumbnail_url}
                  subtitle={!!meta.values.subtitle}
                  enqueteEnabled={statusIsFree}
                  material_id={get(product, ['ref_id']) || ''}
                  license_id={get(course, ['ref_id']) || ''}
                  display_mode={displayMode}
                  onClose={() =>
                    setProgress(webApp.utils.progress(config, meta))
                  }
                  isClipVod={isClipVod}
                  playInline
                />
              )}
              {inViewTerm && (
                <PlayerCover
                  metaName={metaName}
                  thumbnailUrl={thumbnailUrl}
                  rest={rest}
                  showRest={showRest}
                  status={status}
                  product={product}
                  progress={progress}
                  inViewTerm={inViewTerm}
                  onClickPlay={onClickPlay}
                />
              )}
              {!inViewTerm && (
                <PlayerCoverDisabled
                  metaName={metaName}
                  thumbnailUrl={thumbnailUrl}
                />
              )}
            </StyledVideoWrapper>

            {hasController && <Controller visible videoLength={videoLength} />}

            {voteVisible && (
              <StyledVoteForm
                meta={meta}
                status={status}
                price={getPrice(meta, status, product, course)}
                buttonData={voteButtonData}
                isActive={currentTabId === ''}
              />
            )}
            <Info
              seriesId={seriesId}
              seasonId={seasonId}
              episodeId={episodeId}
              meta={meta}
              episodes={episodes}
              status={status}
              product={product}
              course={course}
              productRight={productRight}
              onClickPlay={onClickPlay}
              currentTabId={currentTabId}
              metaName={metaName}
              hasChat={hasChat}
              isLatest={isLatest}
            />
          </StyledDiv1Inner>

          {hasChat && (
            <StyledChat
              hash={chatHash}
              setPostMessage={setPostMessage}
              isActive={currentTabId === 'chat'}
            />
          )}
        </StyledDiv1>

        <StyledDiv2 isActive={currentTabId === ''}>
          <StyledListMetaMain>
            {hasComment && (
              <StyledComment
                hash={commentHash}
                isActive={currentTabId === 'comment'}
                scrolling="true"
              />
            )}
            <StyledListMetaMainInner isActive={currentTabId === ''}>
              {isNotInCourse && <PackList products={products} showMedal />}
              <StyledBannerWrapper>
                {programBannerId1 && (
                  <HtmlSnippet snippetId={programBannerId1} />
                )}
              </StyledBannerWrapper>
              <SwitchableListGrid
                episodes={episodes}
                rootMetas={rightMetas}
                howToPlays={howToPlays}
                placeholder="検索"
                showNew
                showChecked
                onlySubTitle
                listType="grid"
                disp_order={get(programMeta, ['values', 'disp_order'])}
                pagerOptions={pagerOptions}
              />
            </StyledListMetaMainInner>
          </StyledListMetaMain>

          <StyledListMetaSub
            seriesId={seriesId}
            seasonId={seasonId}
            meta={meta}
            status={status}
            course={course}
            episodes={episodes}
            otherSeasons={otherSeasons}
            loaded={loaded}
            programBannerId={programBannerId2}
            isActive={currentTabId === ''}
          />
        </StyledDiv2>
        <StyledEpisodeFooter
          copyrights={meta.values.evis_Copyright}
          isActive={currentTabId === ''}
        />
      </StyledMain>

      <GlobalStyle />
    </>
  )
}

export default memo(EpisodeLive)

EpisodeLive.propTypes = {
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  episodeId: PropTypes.string,

  /** エピソードのメタ */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({}).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,

  /** シーズンのメタ */
  programMeta: PropTypes.shape({}),
  howToPlay: PropTypes.shape({}),
  product: PropTypes.shape({}),
  productRight: PropTypes.shape({}),

  /** 同一シーズンのエピソード一覧 */
  episodes: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number,
      name: PropTypes.string,
      original_price: PropTypes.number,
      active_pricing: PropTypes.shape({
        price: PropTypes.number,
        unit: PropTypes.string
      })
    })
  ),
  howToPlays: PropTypes.shape({}),
  /** 月額見放題 */
  course: PropTypes.shape({
    course_id: PropTypes.number,
    schema_id: PropTypes.number,
    name: PropTypes.string,
    active_pricing: PropTypes.shape({}),
    values: PropTypes.shape({})
  }),

  status: PropTypes.shape({
    isFree: PropTypes.bool,
    isNotFree: PropTypes.bool,
    isPurchased: PropTypes.bool,
    isNotPurchased: PropTypes.bool,
    isInCourse: PropTypes.bool,
    isNotInCourse: PropTypes.bool,
    isGeoDeliverable: PropTypes.bool,
    isDeviceNotAvailable: PropTypes.bool,
    limitDate: PropTypes.string,
    isPossible: PropTypes.bool,
    isBelonging: PropTypes.bool
  }).isRequired,

  season: PropTypes.shape({}),

  /** 再生ログ送信 */
  sendPlayLog: PropTypes.func,
  rightMetas: PropTypes.arrayOf(PropTypes.shape({})),
  otherSeasons: PropTypes.arrayOf(PropTypes.shape({})),

  /** データ取得が完了したか */
  loaded: PropTypes.bool
}

EpisodeLive.contextTypes = {
  falcorModel: PropTypes.shape({}),
  models: PropTypes.shape({}),
  routeHandler: PropTypes.shape({}),
  history: PropTypes.shape({})
}

const GlobalStyle = createGlobalStyle`
@media (max-width: 320px) {
  body {
    zoom: normal;
  }
}
.c-pageTop {
  z-index: 0;
}
${mediaQuery()} {
  .c-pageTop {
    display: none;
  }
}
.p-episode_live__container {
  ${mediaQuery()} {
    padding-top: 55px;
  }
}

#point-header + .common-wrapper {
  ${mediaQuery()} {
    margin-top: 15px;
  }
}
`

const headerHeight = '56px'
const tabHeight = '55px'

const StyledMain = styled.main`
  ${mediaQuery()} {
    height: calc(100dvh - ((100dvw * 9 / 16) + ${headerHeight} + ${tabHeight}));
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: ${({ hideOverflow }) => (hideOverflow ? 'hidden' : 'scroll')};

    &&::-webkit-scrollbar {
      display: none;
    }
  }
`

const StyledDiv1 = styled.div`
  grid-area: StyledDiv1;
  padding: 30px 0 40px;
  display: grid;
  grid: '... StyledDiv1Inner ...' / minmax(30px, auto) ${breakPoints.mx}px minmax(
      30px,
      auto
    );

  ${mediaQuery()} {
    padding: 15px 0 40px;
    display: block;

    ${({ currentTabId }) =>
      currentTabId !== '' &&
      css`
        padding: 0;
      `}
  }

  ${({ hasChat }) =>
    hasChat &&
    css`
      grid:
        '... StyledDiv1Inner ... StyledChat ...'
        / minmax(30px, auto) minmax(auto, 850px) minmax(auto, 30px) 400px minmax(30px, auto);

      ${mediaQuery()} {
        grid: none;
      }
    `}
`

const StyledDiv1Inner = styled.div`
  grid-area: StyledDiv1Inner;
  display: grid;
  row-gap: 30px;
`

const StyledVideoWrapper = styled.div`
  width: 100%;
  position: relative;

  ${mediaQuery()} {
    position: fixed;
    inset: ${headerHeight} 0 auto;
  }

  &::before {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  .player-modal-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  // 既存のスタイル(_app.scss)をリセット
  .player-modal-wrapper {
    width: auto;
    height: auto;
    background: none;
    z-index: 1;

    .watch {
      width: 1080px;

      @media (max-width: 760px) {
        width: 100%;
      }
    }
  }
`

const StyledVoteForm = styled(VoteForm)`
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${mediaQuery()} {
    padding: 0 15px;
    display: ${({ isActive }) => (isActive ? null : 'none')};
    gap: 10px;
  }

  .c-vote_form__label_wrapper {
    min-height: 28px;
  }

  .c-accordion {
    margin-top: 30px;
    text-align: center;

    .c-text1 {
      font-size: 1.4rem;
    }

    .c-list2 {
      margin-top: 20px;
    }

    .c-btn1 {
      min-width: 230px;

      ${mediaQuery()} {
        min-width: auto;
        width: 100%;
      }
    }
  }
`

const StyledTabPanel = styled(TabPanel)`
  ${mediaQuery()} {
    grid-area: StyledTabPanel;
    position: sticky;
    top: ${headerHeight};
    /* inset: calc((100dvw * 9 / 16) + ${headerHeight}) 0 auto; */
  }
`

const StyledDiv2 = styled.div`
  grid-area: StyledDiv2;
  padding-top: 30px;
  background-color: #f3f4f5;
  display: grid;
  grid:
    '... StyledListMetaMain StyledListMetaSub ...'
    / auto minmax(auto, 950px) 300px auto;
  column-gap: 30px;

  ${mediaQuery()} {
    padding-top: ${({ isActive }) => (isActive ? null : 0)};
    grid: 'StyledListMetaMain' 'StyledListMetaSub' / auto;
  }
`

const StyledListMetaMain = styled.div`
  grid-area: StyledListMetaMain;

  .c-card-vertical {
    padding: 25px;
    background-color: #fff;

    ${mediaQuery()} {
      padding: 15px;
    }
  }
`

const StyledListMetaMainInner = styled.div`
  margin-top: 30px;

  ${mediaQuery()} {
    margin-top: 0;
    display: ${({ isActive }) => (isActive ? null : 'none')};
  }
`

const StyledBannerWrapper = styled.div`
  ${mediaQuery()} {
    padding: 0 15px;
  }
`

const StyledListMetaSub = styled(ListMetaSub)`
  grid-area: StyledListMetaSub;

  ${mediaQuery()} {
    display: ${({ isActive }) => (isActive ? null : 'none')};
  }
`

const StyledChat = styled(Chat)`
  grid-area: StyledChat;

  ${mediaQuery()} {
    z-index: 1; // pageTop隠すため

    ${({ isActive }) => {
      if (isActive) {
        return css`
          height: calc(
            100dvh - ((100dvw * 9 / 16) + ${headerHeight} + ${tabHeight})
          );
        `
      }
      return css`
        display: none;
      `
    }}
  }
`

const StyledComment = styled(Comment)`
  ${mediaQuery()} {
    width: 100%;
    background-color: #fff;

    ${mediaQuery()} {
      z-index: 1; // pageTop隠すため

      ${({ isActive }) => {
        if (isActive) {
          // 100vhのバグ回避
          const windowHeight = `${window.innerHeight}px`
          // prettier-ignore
          return css`
        height: calc(${windowHeight} - ((100dvw * 9 / 16) + ${headerHeight} + ${tabHeight}));
      `
        }
        return css`
          display: none;
        `
      }}
    }
  }
`

const StyledVideoSpacer = styled.div`
  display: none;

  ${mediaQuery()} {
    height: calc(100dvw * 9 / 16);
    display: block;
  }
`

const StyledEpisodeFooter = styled(EpisodeFooter)`
  margin-top: 0;

  ${mediaQuery()} {
    display: ${({ isActive }) => (isActive ? null : 'none')};
  }
`
