import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** パスワードの変更 */
export default class AccountEditPass extends Component {
  static propTypes = {};
  static defaultProps = {};
  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};

    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit() {}

  goBack(e) {
    e.preventDefault();
    this.context.history.goBack();
  }

  render() {
    return (
      <div className="c-mypage-cont">
        <div className="c-mypage-cont-inBox">
          <div className="c-mypageAccount">
            <header className="c-mypageAccount-header">
              <a href="#" className="c-mypage-btnBack alwaysOn" onClick={this.goBack}>
                戻る
              </a>
              <h3 className="c-mypageAccount-header-title">パスワードの変更</h3>
            </header>
            <div className="c-mypageAccount-cont">
              <div className="c-mypageAccount-cont-personal">
                <div className="c-mypageAccountEditPass">
                  <div className="c-mypageAccountEditPass-passCurrent">
                    <div className="c-mypageAccountEditPass-passCurrent-head">
                      <div className="c-mypageAccountEditPass-passCurrent-head-titles">
                        <h4 className="c-mypageAccountEditPass-passCurrent-head-titles-title require">
                          現在のパスワード
                        </h4>
                      </div>
                      <div className="c-mypageAccountEditPass-passCurrent-head-reset">
                        <a href="#" target="_blank" className="c-mypageAccountEditPass-passCurrent-head-reset-link">
                          パスワードを忘れた方
                        </a>
                      </div>
                    </div>
                    <div className="c-mypageAccountEditPass-passCurrent-pass">
                      <input type="text" name="pass" placeholder="半角英数字8文字以上" className="c-inputText" />
                    </div>
                  </div>
                  <div className="c-mypageAccountEditPass-passNext">
                    <h4 className="c-mypageAccountEditPass-passNext-title require">新しいパスワード</h4>
                    <ul className="c-mypageAccountEditPass-passNext-inBox">
                      <li className="c-mypageAccountEditPass-passNext-inBox-pass">
                        <input type="text" name="pass" placeholder="半角英数字8文字以上" className="c-inputText" />
                      </li>
                      <li className="c-mypageAccountEditPass-passNext-inBox-confirm">
                        <input type="text" name="pass" placeholder="確認のためもう一度入力" className="c-inputText" />
                      </li>
                    </ul>
                    <p className="c-mypageAccountEditPass-passNext-attention">
                      8文字〜32文字以内、英文字（大文字・小文字）、数字、記号で設定してください
                    </p>
                  </div>
                  <div className="c-mypageAccountEditPass-btnSave">
                    <a href="#" className="c-mypageAccountEditPass-btnSave-link" onClick={this.onSubmit}>
                      変更する
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
