import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import routes from '../../../../common/routes';
import Link from '../../../../../sketch-platform/ui/routing/Link';
import webApp from '../../../utils/exdioWebAppUtils';

/** 詳細画面:お得なパックコンポーネント */
export default class PackItem extends Component {
  static propTypes = {
    product: PropTypes.shape({
      product_id: PropTypes.number,
      name: PropTypes.string,
      active_pricing: PropTypes.object,
      values: PropTypes.object
    }),
    showMedal: PropTypes.bool
  };

  static defaultProps = {
    product: {},
    showMedal: false
  };

  static contextTypes = {
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.model = context.falcorModel.batch(100);
    this.state = {
      status: {
        isPurchseAvailable: this.props.product.active_pricing != null,
        isPurchased: false,
        isNotPurchased: false
      },
    };
  }

  async componentDidMount() {
    const { product } = this.props;
    let status = {};
    status = await this.getIsPossible(product, status);
    this.setState({ status });
  }

  componentDidUpdate(prevProps) {}

  getIsPossible(product, status) {
    if (!product) return Promise.resolve();

    const refId = product.ref_id;
    if (!refId) return Promise.reject(new Error('Error. refId is required.'));

    const isLoggedIn = webApp.utils.isLoggedIn(this.context);
    if (!isLoggedIn) {
      status.isNotPurchased = true;
      return status;
    }

    const path = [['infra', 'isPossible', refId]];
    return this.model.fetch(path).then(result => {
      const isPossible = _.get(result, ['json', 'infra', 'isPossible', refId]) || {};
      status.isPurchased = !!isPossible.status;
      status.isNotPurchased = !isPossible.status;
      status.limitDate = isPossible.limit_date;
      return status;
    });
  }


  render() {
    const { product, discount, price, showMedal, showDiscount } = this.props;
    const { status } = this.state;
    const route = webApp.utils.isApp(this.context) ? routes.app_pack : routes.pack;

    return (
      <div className="c-pageBtn">
        <Link route={route} params={{ productId: product.product_id }} className="c-pageBtn-link">
          <div className="c-pageBtn-link-packName">
            {status.isPurchased && <span className="isPurchased">購入済</span>}
            {product.name}
          </div>
          {showMedal && [
            showDiscount != false && discount > 0 && (
              <div key="discount" className="c-pageBtn-link-discountRate">
                {discount}%<span className="fontBold">お得</span>
              </div>
            ),
            <div key="price" className="c-pageBtn-link-price">
              <span>{price}</span>
            </div>
          ]}
        </Link>
      </div>
    );
  }
}
