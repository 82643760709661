import React, { memo } from 'react'
import PropTypes from 'prop-types'

/** style */
import styled, { css } from 'styled-components'
import { mediaPc, mediaSp } from './styles'

/** 回転する画像ボタン */
const ToggleImgButton = ({ index, name, imgName, img, ...props }) => {
  const isSp = window.matchMedia('(max-width: 1024px)').matches
  const { on, off } = img

  return (
    <StyledButton type="button" {...props}>
      <StyledSpan index={index}>
        <StyledImg1Off
          src={off}
          width="270"
          height="216"
          alt=""
          loading="lazy"
        />
        {!isSp && (
          <StyledImg1On
            src={on}
            width="210"
            height="24"
            alt=""
            loading="lazy"
          />
        )}
      </StyledSpan>
      <StyledImg2
        src={imgName}
        width="210"
        height="24"
        alt={name}
        loading="lazy"
      />
    </StyledButton>
  )
}

export default memo(ToggleImgButton)

ToggleImgButton.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  imgName: PropTypes.string,
  img: PropTypes.shape({
    on: PropTypes.string,
    off: PropTypes.string
  })
}

ToggleImgButton.defaultProps = {
  index: 0,
  name: '',
  imgName: '',
  img: {
    on: '',
    off: ''
  }
}

export const Img1Style = css`
  margin: 0 auto;
  max-width: 188px;

  @media ${mediaSp} {
    max-width: 100%;
  }
`

const StyledImg1Off = styled.img`
  ${Img1Style}
  opacity: 1;
  transform: rotateY(0deg);
  transition: opacity 0.1s, transform 0.2s;
`

const StyledImg1On = styled.img`
  ${Img1Style}
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: rotateY(-90deg);
  transition: opacity 0.1s, transform 0.2s;
`

export const StyledImg2 = styled.img`
  margin-top: 8px;
`

export const StyledButton = styled.button`
  padding: 0;
  border-width: 0;
  background: none;
  position: relative;
  text-align: center;

  &:hover {
    ${StyledImg1Off} {
      @media ${mediaPc} {
        opacity: 0;
        transform: rotateY(90deg);
      }
    }

    ${StyledImg1On} {
      @media ${mediaPc} {
        opacity: 1;
        transform: rotateY(0deg);
      }
    }
  }
`

export const StyledSpan = styled.span`
  display: block;
  background: no-repeat bottom / contain;
  position: relative;

  ${({ index }) => {
    switch (index % 3) {
      case 1:
        return css`
          background-image: url(/douga_mv/shinchan/svod/images/character/circle-blue.png);
        `
      case 2:
        return css`
          background-image: url(/douga_mv/shinchan/svod/images/character/circle-yellow.png);
        `
      case 0:
      default:
        return css`
          background-image: url(/douga_mv/shinchan/svod/images/character/circle-pink.png);
        `
    }
  }}
`
