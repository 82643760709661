import { mediaQuery, fixColors } from '../../../exdio/components/style'

// ナビゲーションのボタン
export const StyledNavLink = `
  display: flex;
  align-items: center;
  height: 47px;
  padding: 0 0.6rem;
  ${mediaQuery('sm', 'min')} {
    font-size: 12px;
  }
  ${mediaQuery()} {
    height: 32px;
  }
  &:hover {
    opacity: 0.6;
    transition: 0.2s;
  }

  /* 見逃し無料のロゴ画像があるリンクのクラス */
  &.logoFree {
    padding: 0;
    margin-left: 10px;
    margin-right: 7px;
    ${mediaQuery()} {
      padding: 0 1rem;
      margin: 0;
    }
  }
  /* スマホ時のカレント表示 */
  &.current {
    ${mediaQuery()} {
      border-bottom: 3px solid ${fixColors.colorKey};
      box-sizing: content-box;
      padding-top: 3px;
    }
  }
`
