/* style */
import styled, { css } from 'styled-components'
import { mediaQuery } from '../../../../../../exdio/components/style'

export const StyledButton = styled.button`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 50px 17px 28px;
  color: #343434;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  position: relative;
  text-align: left;
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 600;
  background: #fff;

  ${({ noDetail }) =>
    noDetail
      ? css`
          cursor: default;
        `
      : null}

  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-right: 2px solid #343434;
    border-bottom: 2px solid #343434;
    transform: translateY(-50%) rotate(45deg);
    right: 20px;
    top: 50%;

    ${({ open }) =>
      open
        ? css`
            position: absolute;
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-right: 2px solid #343434;
            border-bottom: 2px solid #343434;
            transform: translateY(-50%) rotate(-135deg);
            right: 20px;
            top: 50%;
          `
        : null}

    ${({ noDetail }) =>
      noDetail
        ? css`
            display: none;
          `
        : null}
  }

  ${mediaQuery()} {
    padding: 17px 50px 12px 28px;
    font-size: 1.6rem;
  }
`

export const StyledTime = styled.time`
  font-weight: normal;
  line-height: 1;
  font-size: 1.4rem;
  margin-bottom: 7px;

  ${mediaQuery()} {
    font-size: 1.1rem;
    margin-bottom: 2px;
  }
`

export const StyledSpan1 = styled.span`
  display: inline-block;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
  background: #fff;
  padding: 4.5px 10px;
  margin: 0 0 7px 10px;
  font-weight: 600;
  font-size: 1rem;
  transform-origin: center left;
  line-height: 1;
  transform: scale(0.9);

  ${mediaQuery()} {
    margin: 0 0 2px 5px;
    padding: 5px 6.5px 3px;
    transform: scale(0.8);
  }
`

export const StyledSpan2 = styled.span`
  display: block;
  width: 100%;
`

export const StyledDDd = styled.dd`
  line-height: 1.57;
  padding: 21px 28px;
  display: block;
  background: #f3f4f5;
  font-size: 1.4rem;
`
