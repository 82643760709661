import React from 'react'
import PropTypes from 'prop-types'
import routes from '../../../../../common/routes'
import { MYPAGE_CONTENTS } from '../../../../../../constants/app'

/* components */
import Button1 from '../Button1'

/* style */
import { StyledUl1, StyledLi1 } from './style'
import { colors } from '../style'

/** ButtonList1 Component */
const ButtonList1 = ({ isLoggedIn = false, ...props }, context) => {
  const config = context.models.config.data

  return (
    <StyledUl1 {...props}>
      <StyledLi1>
        <Button1
          href={`${config.purchase_plan.gariben_daigaku}?utm_source=live&utm_medium=bt&utm_id=garilive`}
        >
          私立ガリベン大学に会員登録する
        </Button1>
      </StyledLi1>
      {!isLoggedIn && (
        <StyledLi1>
          <Button1
            route={routes.login}
            query={{ redirect: context.routeHandler.path }}
            bgColor={colors.green}
          >
            ログイン
          </Button1>
        </StyledLi1>
      )}
      {isLoggedIn && (
        <StyledLi1>
          <Button1
            route={routes.mypage}
            state={{ content: MYPAGE_CONTENTS.TAB.PURCHASED }}
            bgColor={colors.blue}
          >
            マイページ
          </Button1>
        </StyledLi1>
      )}
    </StyledUl1>
  )
}

export default ButtonList1

ButtonList1.propTypes = {
  isLoggedIn: PropTypes.bool
}

ButtonList1.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}
