import React from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'
import { Link } from '../../../../../sketch-platform/ui/routing'
import styled from 'styled-components'
import Thumbnail, { StyledDiv2, StyledImg } from './Thumbnail'
import Caption from './Caption'

/** 動画リンク: 複数の番組, シーズンで構成されているパックリストの時にのみ使用 */
const ProgramMixedItemLink = ({ meta, showNew, ...props }, context) => {
  const isNew = webApp.utils.isNew(context, meta)
  const duration = webApp.utils.duration(meta)
  const progress = webApp.utils.progress(context.models.config.data, meta)
  const [title, subTitle] = webApp.utils.titles(meta)

  return (
    <Link
      route={props.route}
      params={props.params}
      query={props.query}
      className="c-card"
    >
      <StyledFigure>
        <Thumbnail
          thumbnailAlt={`${title} ${subTitle}`}
          thumbnailUrl={meta.thumbnail_url}
          showNew={showNew && isNew}
          showDuration
          duration={duration}
          progress={progress}
        />
        <StyledCaption
          title={title}
          subTitle={subTitle}
          synopsis={meta.values.evis_EpisodeLongSynopsis}
        />
      </StyledFigure>
      <StyledP>{meta.values.evis_EpisodeLongSynopsis}</StyledP>
    </Link>
  )
}

export default ProgramMixedItemLink

ProgramMixedItemLink.propTypes = {
  /** 描画する動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.shape({
      evis_EpisodeLongSynopsis: PropTypes.string,
      evis_SeasonLongSynopsis: PropTypes.string,
      evis_SeriesLongSynopsis: PropTypes.string,
      parents_series: PropTypes.shape({
        avails_SeriesTitleDisplayUnlimited: PropTypes.string
      }),
      parents_season: PropTypes.shape({
        avails_SeasonTitleDisplayUnlimited: PropTypes.string
      }),
      avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
      ex_templateId: PropTypes.number
    }).isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }),
  /** Newラベルの表示 */
  showNew: PropTypes.bool
}

ProgramMixedItemLink.contextTypes = {
  models: PropTypes.object,
  routeHandler: PropTypes.object
}

const StyledFigure = styled.figure`
  display: flex;
  cursor: pointer;

  &:hover {
    ${StyledDiv2}::before {
      background: linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.3)
      );
    }

    ${StyledImg} {
      transform: scale(1.1);
    }
  }
`

const StyledP = styled.p`
  margin-top: 1rem;
  line-height: 1.4;
  font-size: 1.2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;

  @media (min-width: 769px) {
    display: none;
  }
`

const StyledCaption = styled(Caption)`
  margin-left: 1rem;
`
