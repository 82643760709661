/* style */
import styled from 'styled-components'

export const StyledDiv1 = styled.div`
  overflow: hidden;
`

export const StyledH1 = styled.h1`
  padding-top: ${({ isSmp }) => (isSmp ? '15px' : '30px')};

  a {
    display: inline-block;
  }
`

export const StyledImg = styled.img`
  width: ${({ isSmp }) => (isSmp ? '200px' : '335px')};
`
