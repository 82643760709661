import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import routes from '../../../../../common/routes'
import Link from '../../../../../../sketch-platform/ui/routing/Link'
import { mainColor, keyColor } from './styles'

/** 全英オープン BreadCrumb */
const BreadCrumb = ({ seriesId, seasonId, episodeTitle, ...props }) => {
  return (
    <StyledDiv {...props}>
      <StyledOl>
        <StyledLi>
          <StyledLink
            route={routes.home}
          >TOP</StyledLink>
        </StyledLi>
        <StyledLi>
          <StyledLink
            route={routes.genre}
            params={{ genre: 'hobby' }}
          >スポーツ・趣味</StyledLink>
        </StyledLi>
        {episodeTitle
          ?
            <StyledLi>
              <StyledLink
                route={routes.program}
                params={{ seriesId, seasonId }}
              >全英オープン最強王者決定戦 ～歴代優勝者No.1は誰だ？～</StyledLink>
            </StyledLi>
          : <StyledLi>全英オープン最強王者決定戦 ～歴代優勝者No.1は誰だ？～</StyledLi>
        }
        {episodeTitle && <StyledLi>{episodeTitle}</StyledLi>}
      </StyledOl>
    </StyledDiv>
  )
}

export default memo(BreadCrumb)

BreadCrumb.propTypes = {
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  episodeTitle: PropTypes.string
}

BreadCrumb.defaultProps = {
  seriesId: '',
  seasonId: '',
  episodeTitle: ''
}

const StyledDiv = styled.div`
background: ${keyColor};
`

const StyledOl = styled.ol`
width: 960px;
margin: 0 auto;
padding: 20px 0;
font-size: 1.1rem;

@media (max-width: 960px) {
  width: auto;
}
`

const StyledLi = styled.li`
display: inline-block;

&:not(:first-child) {
  &::before {
    margin: 0 5px;
    content: '>';
  }
}
`

const StyledLink = styled(Link)`
display: inline-block;

&:link,
&:visited,
&:active {
  color: ${mainColor};
}

&:hover {
  text-decoration: underline;
}
`
