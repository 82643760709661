import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import window from 'global'
import MDSpinner from 'react-md-spinner'
import { META_SCHEMA_ID, PUBLISH_STATUS } from '../../../../constants/app'
import webApp from '../../utils/exdioWebAppUtils'
import PlanDefault from './templates/PlanDefault'

/** 月額見放題ページ */
export default class PlanDefaultContent extends Component {
  static propTypes = {
    slug: PropTypes.string,
    course: PropTypes.shape({
      course_id: PropTypes.number.isRequired,
      schema_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      active_pricing: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired
    }).isRequired
  }

  static defaultProps = {
    slug: ''
  }

  static contextTypes = {
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    updateUserInfo: PropTypes.func
  }

  static getPath(_models, _options, props) {
    return ['courseBySlug', props.slug]
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    const path = PlanDefaultContent.getPath({}, {}, props)
    const course = this.model.getSync(path) || {}

    this.state = {
      course,
      right: {},
      rightMetas: [],
      series: null,
      episodes: [],
      status: {
        isPurchseAvailable: course.active_pricing != null,
        isPurchased: false,
        isNotPurchased: false
      },
      loaded: false
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const { course, season } = this.props

    // スキーマに設定したシーズンを取得
    const series = await this.getSeries(season).catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
    // ライセンス取得
    const status = (await this.getBelonging(course)) || this.state.status
    // 権利を取得
    const right = await this.getRight(course).catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
    // rightに紐付くメタ(すべてでは無く権利に紐つけたメタのみ)
    const rightMetas = await this.getRightMetas(right).catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    // rightに紐付くメタすべてを取得。あなたへのオススメ表示で必要
    // 時間がかかるのでこれだけ非同期通信
    this.getEpisodes(rightMetas).catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )

    // getSyncだとfalcorのキャッシュが入ってないとundefinedになるっぽいので、ここで再設定
    status.isPurchseAvailable = course && course.active_pricing != null

    this.setState({
      series,
      status,
      right,
      rightMetas,
      loaded: true
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    webApp.utils.returnDefaultWebClip()
  }

  getSeries(season) {
    if (!season) return Promise.resolve()
    const path = [['meta', 'season', season.meta_id, 'parent']]
    return this.model.fetch(path).then((result) => {
      const series = _.get(result.json, path[0])
      if (this._isMounted) {
        return series
      }
    })
  }

  /** グループのライセンス有無取得 */
  getBelonging(course) {
    if (!course) return Promise.resolve()

    const refId = course.ref_id
    if (!refId) return Promise.resolve()

    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) {
      if (this._isMounted) {
        return {
          ...this.state.status,
          isNotPurchased: true
        }
      }
      return Promise.resolve()
    }

    const path = ['infra', 'gBelong', refId]
    return this.model.fetch([path]).then((result) => {
      const belonging = _.get(result, ['json', ...path]) || false
      if (this._isMounted) {
        return {
          ...this.state.status,
          isPurchased: belonging,
          isNotPurchased: !belonging
        }
      }
    })
  }

  /** 権利情報取得 */
  getRight(course) {
    if (!course) return Promise.resolve()

    const path = ['course', 'rights', course.course_id]
    return this.model.fetch([path]).then((result) => {
      const rights = _.get(result, ['json', ...path], [])
      if (this._isMounted) {
        // 紐つける権利は一つに限定
        return rights[0]
      }
    })
  }

  /** 権利メタ取得 */
  getRightMetas(right) {
    if (!right || !right.right_id) return Promise.resolve([])
    const path = ['right', 'metas', right.right_id]
    return this.model.fetch([path]).then((result) => {
      // TODO とり方変えたのでここの処理は考え直す
      const metas = _.get(result, ['json', ...path], [])
      // 公開以外も取得される&重複して取得されることがあるのでフィルター
      const metaIds = {}
      const rightMetas = metas.filter((meta) => {
        const published = meta.publish_status === PUBLISH_STATUS.PUBLISHED
        const duprecated = Object.keys(metaIds).includes(
          meta.meta_id.toString()
        )
        metaIds[meta.meta_id] = null
        return published && !duprecated
      })
      if (this._isMounted) {
        return rightMetas
      }
    })
  }

  /** エピソード取得 */
  getEpisodes(rightMetas) {
    if (!rightMetas) return Promise.resolve([])

    let episode_ids = [0]
    let series_ids = rightMetas
      .filter((meta) =>
        [META_SCHEMA_ID.SERIES, META_SCHEMA_ID.LIVE_SERIES].includes(
          meta.meta_schema_id
        )
      )
      .map((meta) => meta.meta_id)
    if (series_ids.length === 0) {
      series_ids = [0]
    }
    let season_ids = rightMetas
      .filter((meta) =>
        [META_SCHEMA_ID.SEASON, META_SCHEMA_ID.LIVE_SEASON].includes(
          meta.meta_schema_id
        )
      )
      .map((meta) => meta.meta_id)
    if (season_ids.length === 0) {
      season_ids = [0]
    }

    const range = { from: 0, to: 1000 }
    const path = [
      [
        'meta',
        'packSearch',
        'episode',
        '',
        episode_ids,
        season_ids,
        series_ids,
        [range]
      ]
    ]

    return this.model.fetch(path).then((result) => {
      if (result.json) {
        const metas = _.get(result.json, [
          'meta',
          'packSearch',
          'episode',
          '',
          episode_ids[0],
          season_ids[0],
          series_ids[0]
        ])
        if (metas) {
          delete metas.$__path
          this.setState({ episodes: Object.values(metas) })
        }
      }
    })
  }

  render() {
    const { series, episodes, rightMetas, status, loaded } = this.state

    if (!loaded) {
      return (
        <div className="common-wrapper">
          <div className="mdspinner">
            <MDSpinner size={96} />
          </div>
        </div>
      )
    }

    const childProps = Object.assign({}, this.props, {
      series,
      episodes,
      rightMetas,
      status
    })
    return React.createElement(PlanDefault, childProps)
  }
}
