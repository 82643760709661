import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import _ from 'lodash'
import webApp from '../../utils/exdioWebAppUtils'
import Slider from 'react-slick'
import Cookie from 'js-cookie'
import routes from '../../../common/routes'
import Link from '../../../../sketch-platform/ui/routing/Link'
import { SEARCH_TYPE } from '../../../../constants/app'
import Header from '../../../common/components/Header'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import PaletteItem from './for_kids/PaletteItem'
import mv_slider_data from './for_kids/json/mv_slider_data.json'
import static_palette_data from './for_kids/json/static_palette_data.json'
import ScrollTopButton from '../../../common/components/renewal/ScrollTopButton'
import TPS from './TPS'

// summer station 特集ページ
export default class ForKidsContent extends Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data
    this.state = {
      isSp: webApp.utils.isSpAndIPad(),
      precureObj: [],
      sentaiObj: [],
      kamenObj: [],
      recommendPalette: {},
      recommendItems: []
    }
    this.sliderSetting = {
      mv: {
        speed: 400,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: false,
              slidesToShow: 2
            }
          }
        ]
      },
      program: {
        speed: 400,
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: false,
              slidesToShow: 1
            }
          }
        ]
      }
    }
    this.api = axios.create({
      baseURL: this.context.models.config.data.service.endpoint,
      timeout: this.context.models.config.data.service.timeout
    })
    this.onClickItem = this.onClickItem.bind(this)
    this.spModalHandler = this.spModalHandler.bind(this)
    this.spClickLayer = this.spClickLayer.bind(this)
  }

  componentDidMount() {
    this.updateMetaTags()
    this.getPalettesPrecure()
    this.getPalettesSentai()
    this.getPalettesKamen()
    this.getPaletteRecommend()
  }

  updateMetaTags() {
    const title = 'テレ朝動画 for kids'
    webApp.utils.updateTitle(title)
  }

  /** プリキュアパレット取得 */
  getPalettesPrecure() {
    const paletteId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 212
      : 108
    const path = [['palette', [paletteId]]]
    this.context.falcorModel.fetch(path).then((result) => {
      const palettesObj = _.get(result, ['json', 'palette', paletteId], {})
      const metas = palettesObj.objects.map((Obj) => Obj.meta)
      this.setState({ precureObj: metas })
    })
  }

  /** スーパー戦隊パレット取得 */
  getPalettesSentai() {
    const paletteId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 213
      : 109
    const path = [['palette', [paletteId]]]
    this.context.falcorModel.fetch(path).then((result) => {
      const palettesObj = _.get(result, ['json', 'palette', paletteId], {})
      const metas = palettesObj.objects.map((Obj) => Obj.meta)
      this.setState({ sentaiObj: metas })
    })
  }

  /** 仮面ライダーパレット取得 */
  getPalettesKamen() {
    const paletteId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 214
      : 110
    const path = [['palette', [paletteId]]]
    this.context.falcorModel.fetch(path).then((result) => {
      const palettesObj = _.get(result, ['json', 'palette', paletteId], {})
      const metas = palettesObj.objects.map((Obj) => Obj.meta)
      this.setState({ kamenObj: metas })
    })
  }

  /** おすすめパレット取得 */
  getPaletteRecommend() {
    const paletteId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 217
      : 113
    const path = [['palette', [paletteId]]]
    this.context.falcorModel.fetch(path).then((result) => {
      const palettesObj = _.get(result, ['json', 'palette', paletteId], {})
      this.setState({ recommendPalette: palettesObj }, () => {
        this.getRecommend()
      })
    })
  }

  /** パレット要素クリック時 */
  onClickItem(meta, product, course, autoPlay = true) {
    if (this.isIndividualRecommend && meta) {
      this.recommendClick(meta.meta_id)
    }
    webApp.utils.goToProgramLink(this.context, meta, product, course, {
      autoPlay
    })
  }

  /** レコメンド処理 */
  async getRecommend() {
    const { recommendPalette } = this.state
    const { api_type, tags1, category2 } =
      _.get(recommendPalette, ['values']) || {}

    let refId = ''
    if (tags1) {
      // tags1はコースID
      const path = ['course', tags1]
      refId = await this.model.fetch([path]).then((result) => {
        const course = _.get(result, ['json', ...path]) || {}
        return course.ref_id
      })
    }

    let genreName = ''
    if (category2) {
      // category2はジャンル名
      const path = ['attribute', category2]
      genreName = await this.model.fetch([path]).then((result) => {
        const genre = _.get(result, ['json', ...path]) || {}
        return genre.name
      })
    }
    let getRecommendExec = null
    if (api_type === 'one_to_one') {
      getRecommendExec = this.oneToOne.bind(this)
    } else if (api_type === 'recommend') {
      getRecommendExec = this.recommend.bind(this)
    } else if (api_type === 'longtail') {
      getRecommendExec = this.longtail.bind(this)
    } else {
      return
    }
    getRecommendExec(genreName, refId)
      .then(() => this.recommendActionLog())
      .catch((e) => webApp.utils.handleFalcorError(e, this.context))
  }

  /** One to Oneレコメンド */
  oneToOne(genreName, courseRefId) {
    const { recommendPalette } = this.state
    const itemIdsCsv = (_.get(recommendPalette, ['values', 'item_ids']) || [])
      .slice(0, 10)
      .join(',')
    const userId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // onetooneはuser_id必須なのでluid取れなければ非表示
    if (!userId) {
      webApp.utils.debug(
        `[recommendPalette] skip call one_to_one API because luid and _ga are blank. id: ${
          (recommendPalette || {}).recommendPalette_id
        }`
      )
      return Promise.resolve()
    }
    const spotName = _.get(recommendPalette, ['values', 'spot_name']) || ''
    let type =
      _.get(recommendPalette, ['values', 'category1']) || SEARCH_TYPE.ALL.value
    if (
      type === SEARCH_TYPE.FREE.value &&
      webApp.utils.isLoggedIn(this.context)
    ) {
      type = SEARCH_TYPE.AUTH_FREE.value
    }
    const metaSchemaId = _.get(recommendPalette, ['values', 'category3']) || ''
    const responseNumber =
      _.get(recommendPalette, ['values', 'display_count']) || 8
    const path = [
      'oneToOne',
      itemIdsCsv,
      userId,
      spotName,
      type,
      genreName,
      metaSchemaId,
      courseRefId,
      responseNumber
    ]
    return this.model.fetch([path]).then((result) => {
      const recommendItems = _.get(result, ['json', ...path]) || []
      this.setState({ recommendItems })
    })
  }

  /** レコメンド */
  recommend(genreName, courseRefId) {
    const { recommendPalette } = this.state
    const itemIdsCsv = (_.get(recommendPalette, ['values', 'item_ids']) || [])
      .slice(0, 10)
      .join(',')
    if (!itemIdsCsv) return Promise.resolve()
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    const spotName = _.get(recommendPalette, ['values', 'spot_name']) || ''
    let type =
      _.get(recommendPalette, ['values', 'category1']) || SEARCH_TYPE.ALL.value
    if (
      type === SEARCH_TYPE.FREE.value &&
      webApp.utils.isLoggedIn(this.context)
    ) {
      type = SEARCH_TYPE.AUTH_FREE.value
    }
    const metaSchemaId = _.get(recommendPalette, ['values', 'category3']) || ''
    const responseNumber =
      _.get(recommendPalette, ['values', 'display_count']) || 8
    const path = [
      'recommend',
      itemIdsCsv,
      sessionId,
      spotName,
      type,
      genreName,
      metaSchemaId,
      courseRefId,
      responseNumber
    ]
    return this.model.fetch([path]).then((result) => {
      const recommendItems = _.get(result, ['json', ...path]) || []
      if (this._isMounted) {
        this.setState({ recommendItems })
      }
    })
  }

  /** ロングテール */
  longtail(genreName, courseRefId) {
    const { recommendPalette } = this.state
    const itemIdsCsv = (_.get(recommendPalette, ['values', 'item_ids']) || [])
      .slice(0, 10)
      .join(',')
    if (!itemIdsCsv) return Promise.resolve()
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    const spotName = _.get(recommendPalette, ['values', 'spot_name']) || ''
    let type =
      _.get(recommendPalette, ['values', 'category1']) || SEARCH_TYPE.ALL.value
    if (
      type === SEARCH_TYPE.FREE.value &&
      webApp.utils.isLoggedIn(this.context)
    ) {
      type = SEARCH_TYPE.AUTH_FREE.value
    }
    const metaSchemaId = _.get(recommendPalette, ['values', 'category3']) || ''
    const responseNumber =
      _.get(recommendPalette, ['values', 'display_count']) || 8
    const path = [
      'recommendLongtail',
      itemIdsCsv,
      sessionId,
      spotName,
      type,
      genreName,
      metaSchemaId,
      courseRefId,
      responseNumber
    ]
    return this.model.fetch([path]).then((result) => {
      const recommendItems = _.get(result, ['json', ...path]) || []
      if (this._isMounted) {
        this.setState({ recommendItems })
      }
    })
  }

  /** レコメンド(ログ蓄積) */
  recommendActionLog() {
    const { recommendItems, recommendPalette } = this.state
    if (!recommendItems || !recommendItems.length) return Promise.resolve()

    const itemIds = recommendItems.map((r) => r.meta_id).join(',')
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) {
      webApp.utils.debug(
        `[Pallete] skip call action_log/kpi/display API because luid and _ga are blank. id: ${
          (recommendPalette || {}).recommendPalette_id
        }`
      )
      return Promise.resolve()
    }
    const typeName = this.config.recommend.type_name.view
    const spotName = _.get(recommendPalette, ['values', 'spot_name']) || ''
    const path = [
      'action_log',
      'kpi',
      'display',
      itemIds,
      sessionId,
      typeName,
      spotName
    ]
    return this.model.fetch([path])
  }

  /** レコメンド(クリック) */
  recommendClick(itemId) {
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) {
      const { recommendPalette } = this.state
      webApp.utils.debug(
        `[Pallete] skip call action_log/kpi/click API because luid and _ga are blank. id: ${
          (recommendPalette || {}).recommendPalette_id
        }`
      )
      return Promise.resolve()
    }
    const typeName = this.config.recommend.type_name.view
    const spotName = this.config.recommend.spot_name.top
    const path = [
      'action_log',
      'kpi',
      'click',
      itemId,
      sessionId,
      typeName,
      spotName
    ]
    return this.model.fetch([path])
  }

  renderRecommendItem() {
    const { recommendItems } = this.state
    return (recommendItems || []).map((item) => {
      const ids = item.ids ? JSON.parse(item.ids) : {}
      const deliveryDateTime = item.delivery_datetime
        ? JSON.parse(item.delivery_datetime)
        : {}
      const delivery_start_at = (deliveryDateTime.start_at || '').replace(
        /-/g,
        '/'
      )
      const delivery_end_at = (deliveryDateTime.end_at || '').replace(/-/g, '/')
      const meta = {
        meta_id: item.meta_id,
        meta_schema_id: item.meta_schema_id,
        thumbnail_url: item.thumbnail_url,
        delivery_start_at,
        delivery_end_at,
        name: item.program_name,
        values: {
          avails_EpisodeTitleDisplayUnlimited: item.episode_name,
          evis_EpisodeLongSynopsis: item.synopsis,
          parents_series: {
            id: ids.series,
            avails_SeriesTitleDisplayUnlimited: item.program_name
          },
          parents_season: {
            id: ids.season
          },
          duration: item.duration
        }
      }
      return (
        <PaletteItem
          key={meta.meta_id}
          meta={meta}
          onClickThumbnail={() => this.onClickItem(meta, null, null)}
        />
      )
    })
  }

  spModalHandler(e) {
    const { isSp } = this.state

    // SPでない場合は終了
    if (!isSp) return

    e.preventDefault()

    // 親要素を取得
    const parentElm = e.target.closest('.c-slider__body__item')

    // 詳細が開いているか(.onを削除する前に判別)
    const isOpen = parentElm.classList.contains('on')

    // すべてのitemから.onを削除
    Array.from(document.querySelectorAll('.c-slider__body__item'), (elm) =>
      elm.classList.remove('on')
    )

    const layerElm = document.querySelector('.l-layer')
    layerElm.classList.add('on')

    if (!isOpen) {
      openBalloon(parentElm)
    } else {
      closeBalloon(parentElm)
    }

    // 詳細を開く
    function openBalloon(item) {
      // クリックしたitemに.onを付与
      item.classList.add('on')
    }

    function closeBalloon(item) {
      // クリックしたitemから.onを削除
      item.classList.remove('on')
      layerElm.classList.remove('on')
    }
  }

  spClickLayer(e) {
    const { isSp } = this.state

    // SPでない場合は終了
    if (!isSp) return

    // すべてのitemから.onを削除
    Array.from(document.querySelectorAll('.c-slider__body__item'), (elm) =>
      elm.classList.remove('on')
    )
    e.target.classList.remove('on')
  }

  transitionPage(e, url) {
    e.preventDefault()
    window.open(url)
  }

  render() {
    const { precureObj, sentaiObj, kamenObj } = this.state

    //320px以下でzoom: 80%;がかかってしまうため上書き
    const bodyStyle = `
      body {
        zoom: 100%;
      }
    `

    return (
      <React.Fragment>
        <Header />
        <TPS />
        <div className="p-kids">
          <SpSubNavigation key="sp-nav" />
          <div className="c-header">
            <h1 className="c-header__logo">
              <img
                src="/images/exdio/renewal/for_kids/logo.png"
                alt="テレ朝動画 for kids"
              />
            </h1>
          </div>

          <div className="c-slider">
            <div className="c-slider__hdg">
              <span className="c-slider__hdg--text">キャラクターから探す</span>
            </div>
            <Slider {...this.sliderSetting.mv} className="c-slider__body">
              {mv_slider_data &&
                mv_slider_data.map((item, i) => {
                  return (
                    <div
                      className="c-slider__body__item"
                      key={i}
                      onClick={this.spModalHandler}
                    >
                      <img src={item.mv.thumb} alt={item.mv.name} />
                      <ul
                        className={`c-slider__body__item__list ${
                          item.mv.name === 'ドラえもん' ? 'c-b' : ''
                        }`}
                      >
                        {item.lists &&
                          item.lists.map((link, j) => {
                            const params = ['development', 'staging'].includes(
                              process.env.NODE_ENV
                            )
                              ? {
                                  seriesId: link.stg.seriesId,
                                  seasonId: link.stg.seasonId
                                }
                              : {
                                  seriesId: link.prod.seriesId,
                                  seasonId: link.prod.seasonId
                                }
                            return (
                              <li
                                className={`c-slider__body__item__list--link ${
                                  link.class
                                } ${link.href !== undefined ? 'c-b' : ''}`}
                                key={j}
                              >
                                {link.href !== undefined ? (
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      this.transitionPage(e, link.href)
                                    }
                                  >
                                    <img src={link.thumb} alt={link.name} />
                                    <p>{link.name}</p>
                                  </a>
                                ) : (
                                  <Link route={routes.program} params={params}>
                                    <img src={link.thumb} alt={link.name} />
                                    <p>{link.name}</p>
                                  </Link>
                                )}
                              </li>
                            )
                          })}
                      </ul>
                    </div>
                  )
                })}
            </Slider>
          </div>

          <div className="l-layer" onClick={this.spClickLayer}></div>

          <div className="l-container">
            <div className="c-hrTop"></div>
            <div className="c-palettes">
              <h2 className="c-palettes__hdg1">
                <ruby>
                  見<rt>み</rt>放<rt>ほう</rt>題<rt>だい</rt>
                </ruby>
              </h2>
              <div className="c-palettes__plan">
                {static_palette_data[0].plan &&
                  static_palette_data[0].plan.map((item, i) => {
                    const params = { slug: item.slug }
                    return (
                      <React.Fragment key={i}>
                        {item.href !== undefined ? (
                          <a
                            href={item.href}
                            className="c-palettes__plan__item bb"
                            target="_blank"
                          >
                            <img src={item.thumb} alt={item.name} />
                          </a>
                        ) : (
                          <Link
                            route={routes.plan}
                            params={params}
                            className="c-palettes__plan__item"
                          >
                            <img src={item.thumb} alt={item.name} />
                          </Link>
                        )}
                      </React.Fragment>
                    )
                  })}
              </div>

              <h2 className="c-palettes__hdg2">プリキュアシリーズ</h2>
              <div className="c-palettes__plan precure">
                <Slider {...this.sliderSetting.program}>
                  {precureObj.map((meta) => {
                    return (
                      <PaletteItem
                        key={meta.meta_id}
                        meta={meta}
                        onClickThumbnail={() =>
                          webApp.utils.goToProgramLink(this.context, meta)
                        }
                      />
                    )
                  })}
                </Slider>
              </div>

              <h2 className="c-palettes__hdg3">
                スーパー
                <ruby>
                  戦<rt>せん</rt>隊<rt>たい</rt>
                </ruby>
                シリーズ
              </h2>
              <div className="c-palettes__plan super">
                <Slider {...this.sliderSetting.program}>
                  {sentaiObj.map((meta) => {
                    return (
                      <PaletteItem
                        key={meta.meta_id}
                        meta={meta}
                        onClickThumbnail={() =>
                          webApp.utils.goToProgramLink(this.context, meta)
                        }
                      />
                    )
                  })}
                </Slider>
              </div>

              <h2 className="c-palettes__hdg4">
                <ruby>
                  仮<rt>か</rt>面<rt>めん</rt>
                </ruby>
                ライダーシリーズ
              </h2>
              <div className="c-palettes__plan kamen">
                <Slider {...this.sliderSetting.program}>
                  {kamenObj.map((meta) => {
                    return (
                      <PaletteItem
                        key={meta.meta_id}
                        meta={meta}
                        onClickThumbnail={() =>
                          webApp.utils.goToProgramLink(this.context, meta)
                        }
                      />
                    )
                  })}
                </Slider>
              </div>

              <h2 className="c-palettes__hdg5">ほのぼのストーリー</h2>
              <div className="c-palettes__plan">
                {static_palette_data[0].honobono &&
                  static_palette_data[0].honobono.map((item, i) => {
                    const params = ['development', 'staging'].includes(
                      process.env.NODE_ENV
                    )
                      ? {
                          seriesId: item.stg.seriesId,
                          seasonId: item.stg.seasonId
                        }
                      : {
                          seriesId: item.prod.seriesId,
                          seasonId: item.prod.seasonId
                        }
                    return (
                      <Link
                        route={routes.program}
                        params={params}
                        className="c-palettes__plan__item"
                        key={i}
                      >
                        <img src={item.thumb} alt={item.name} />
                      </Link>
                    )
                  })}
              </div>

              <h2 className="c-palettes__hdg6">
                かぞくで
                <ruby>
                  見<rt>み</rt>
                </ruby>
                たい
                <ruby>
                  名<rt>めい</rt>作<rt>さく</rt>
                </ruby>
              </h2>
              <div className="c-palettes__plan">
                {static_palette_data[0].family &&
                  static_palette_data[0].family.map((item, i) => {
                    const params = ['development', 'staging'].includes(
                      process.env.NODE_ENV
                    )
                      ? {
                          seriesId: item.stg.seriesId,
                          seasonId: item.stg.seasonId
                        }
                      : {
                          seriesId: item.prod.seriesId,
                          seasonId: item.prod.seasonId
                        }
                    return (
                      <Link
                        route={routes.program}
                        params={params}
                        className="c-palettes__plan__item"
                        key={i}
                      >
                        <img src={item.thumb} alt={item.name} />
                      </Link>
                    )
                  })}
              </div>

              <h2 className="c-palettes__hdg7">おすすめのおはなし</h2>
              <div className="c-palettes__plan recommend">
                <Slider {...this.sliderSetting.program}>
                  {this.renderRecommendItem()}
                </Slider>
              </div>
            </div>
            <div className="c-hrUnder"></div>
          </div>

          <div className="c-details">
            <p className="c-details--attention">
              ＼おうちの人といっしょによんでね！/
            </p>
            <a
              href="https://www.tv-asahi.co.jp/douga_mv/recommend"
              className="c-details__button"
            >
              <span className="c-details__button--text">
                テレ朝動画についてはこちら
              </span>
            </a>
          </div>

          <div className="c-copyrights">
            Copyright(C) tv asahi All rights reserved.
          </div>

          <ScrollTopButton />
          <style>{bodyStyle}</style>
        </div>
      </React.Fragment>
    )
  }
}
