/* style */
import styled from 'styled-components'

export const StyledLi = styled.li`
  width: calc(100% / 2 - 8px);
  margin: 0 4px 12px;
  float: left;
`

export const StyledDiv1 = styled.div`
  position: relative;
`

export const StyledP1 = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(108, 156, 200, 0.8);
  padding: 3px 5px;
  color: #fff;
  font-size: 1rem;
`

export const StyledDiv2 = styled.div`
  padding: 5px 0;
  position: relative;
`

export const StyledDiv3 = styled.div`
  color: #333;
  background: #eee;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.2;
  margin-top: 3px;
  position: absolute;
  top: -18px;
  right: -5px;
  padding: 7px 7px 5px;
  width: 90%;
`

export const StyledP2 = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

export const StyledImg = styled.img`
  width: 40px;
  margin-top: 30px;
  clip-path: none;
`
