import React from 'react';
import PropTypes from 'prop-types';
import window from 'global/window';
import Cookie from 'js-cookie';
import browserInfo from '../../../../sketch-platform/utils/browserInfo';
import $ from 'jquery';

class ExVideoPlayer extends React.PureComponent {
  static contextTypes = {
    models: PropTypes.object,
  };
  
  constructor(props, context) {
    super(props, context);
    this.model = props.model;
    this.deviceInfo = browserInfo(navigator.userAgent, function(data){ return data; });
    this.definePlayer = this.definePlayer.bind(this);
  }
  
  componentDidMount() {
    this.makePlayer();
  }

  componentWillUnmount() {
    if (typeof videojs === undefined) {
      Object.keys(videojs.getPlayers()).forEach(function (key) {
        if (videojs.getPlayers()[key]) videojs.getPlayers()[key].dispose();
      });
    }
  }

  definePlayer(embedData) {
    this.account_id = null;
    this.player = null;
    this.agent = navigator.userAgent;
    this.ga_cid = "";
    let device = browserInfo(this.agent, function(data){ return data; });
    const isIPadOS = device.isMac && global.navigator.platform === "MacIntel" && typeof global.navigator.standalone !== 'undefined'

    //デバイス判定
    if(device.isAppli){
      this.device = "4"; //Appli
    }else if(device.isIOS || isIPadOS){
      this.device = "2"; //iOS or iPadOS
    }else if(device.isAndroid){
      this.device = "3"; //Android
    }else{
      this.device = "1"; //PC
    }

    //エンベッドデータ初期化
    //DIOメタID
    this.meta_id = null;
    if (embedData.meta_id !== undefined) {
        this.meta_id = embedData.meta_id;
    }else{
        console.log("meta_id is blank, so DIOPlayer can't be init.");
    }
    //自動再生On/Off
    this.auto_start = true;
    if (embedData.auto_start !== undefined && embedData.auto_start === false) {
        this.auto_start = false;
    }
    //再生開始秒数
    this.play_head_time = 0;
    if (embedData.play_head_time !== undefined) {
        this.play_head_time = embedData.play_head_time;
    }
    //再生開始音量
    this.volume = 1;
    if (embedData.volume !== undefined) {
        this.volume = embedData.volume;
    }
    //再生開始Mute設定
    this.is_mute = false;
    if (embedData.is_mute !== undefined && embedData.is_mute === true) {
        this.is_mute = true;
    }
    //再生開始ビットレート
    this.bitrate = "auto";
    if (embedData.bitrate !== undefined) {
        if(embedData.bitrate === "middle") this.bitrate = "middle";
        if(embedData.bitrate === "low") this.bitrate = "low";
    }
    //コントロールバー表示設定
    this.controller = "default";
    if (embedData.controller !== undefined) {
        if(embedData.controller === "always") this.controller = "always";
    }
    //ユーザ識別子情報
    this.uid = "";
    if (embedData.uid !== undefined) {
        this.uid = embedData.uid;
    }
    //商品タイプ
    this.product_type = 1;
    if (embedData.product_type !== undefined) {
        this.product_type = embedData.product_type;
    }

    //VideoCloudから取得するメタ情報
    this.video_type = "vod";
    
    //動画再生開始フラグ
    this.mediaStarted = false;
    this.setMediaStarted = function(flg){
        this.mediaStarted = flg;
    }
  }

  // プレイヤー生成
  makePlayer() {
    window.DioPlayer = new this.definePlayer({
      meta_id : this.props.meta_id,
      auto_start : this.props.auto_start,
      play_head_time : this.props.play_head_time,
      volume : this.props.volume,
      is_mute: this.props.is_mute,
      controller : this.props.controller,
      bitrate : this.props.bitrate,
      product_type : this.props.product_type,
      uid : Cookie.get('luid') || undefined,
      browserInfo: this.context.models.browserInfo.data
    });
    window.DioPlayer.subtitle = this.context.models.config.data.preview_url_list.indexOf(location.hostname) !== -1 ? true : this.props.subtitle;
    window.DioPlayer.ssai_ad_config_id = (this.context.models.config.data.avod_ssai_ad_device[window.DioPlayer.device]) ? this.context.models.config.data.ssai_ad_config.avod : "";
    window.DioPlayer.account_id = this.context.models.config.data.videocloud.account_id;
    window.DioPlayer.player_id = (this.context.models.config.data.avod_ssai_ad_device[window.DioPlayer.device]) ? this.context.models.config.data.videocloud.ssai_player_id : this.context.models.config.data.videocloud.player_id;

    this.addPlayer();
  }
  
  // プレイヤーの挿入
  addPlayer() {
    if( window.DioPlayer.device == '1' ){
      // Android,iOS,アプリ以外の場合
      var call = function(src, handler){
        var base = document.getElementsByTagName("script")[0];
        var obj = document.createElement("script");
        obj.async = true;
        obj.src= src;
        if(obj.addEventListener){
            obj.onload = function () {
             handler();
            }
        }else{
            obj.onreadystatechange = function () {
                if ("loaded" == obj.readyState || "complete" == obj.readyState){
                    obj.onreadystatechange = null;
                    handler();
                }
            }
        }
        base.parentNode.insertBefore(obj,base);
      };
      
      if(typeof setDIOplayer == "function"){
        setDIOplayer({
          meta_id:window.DioPlayer.meta_id,
          auto_start:true,
          play_head_time:window.DioPlayer.play_head_time,
          volume:window.DioPlayer.volume,
          is_mute:false,
          controller:window.DioPlayer.controller,
          bitrate:window.DioPlayer.bitrate,
          product_type:window.DioPlayer.product_type,
          uid:window.DioPlayer.uid,
          ssai_ad_config_id:window.DioPlayer.ssai_ad_config_id,
          player_id:window.DioPlayer.player_id,
          subtitle:window.DioPlayer.subtitle
        });
      } else {
        call(this.context.models.config.data.playerEmbed,
          function(){
            setDIOplayer({
              meta_id:window.DioPlayer.meta_id,
              auto_start:true,
              play_head_time:window.DioPlayer.play_head_time,
              volume:window.DioPlayer.volume,
              is_mute:false,
              controller:window.DioPlayer.controller,
              bitrate:window.DioPlayer.bitrate,
              product_type:window.DioPlayer.product_type,
              uid:window.DioPlayer.uid,
              ssai_ad_config_id:window.DioPlayer.ssai_ad_config_id,
              player_id:window.DioPlayer.player_id,
              subtitle:window.DioPlayer.subtitle
            });
          }
        )
      }
    } else {
      const thumbnail_url = this.props.thumbnail_url || this.context.models.config.data.default_thumbnail ;

      let html = '';
      html += '<div id="playerWrapper" style="display: block; position: relative; max-width: 100%;">';
      html += '<div id="app_player" align="center">';

      if( window.DioPlayer.device == '2' ){
        // iOSブラウザからのアクセスの場合
        // Universal Linkは延期
        let params = 'type=play&auto_start=true&product_type=1&channel=ex'
          + '&meta_id=' + window.DioPlayer.meta_id 
          + '&ssai_ad_config_id=' + window.DioPlayer.ssai_ad_config_id
          + '&uid=' + window.DioPlayer.uid
          + '&cid=' + window.DioPlayer.ga_cid
          + '&subtitle=' + window.DioPlayer.subtitle ;
        const app_url = this.context.models.config.data.dioapp.custom_schema + "?" + params ;
        const store_url = this.context.models.config.data.dioapp.ios_store_link ;

        html += '<a class="appSelector_iOS" href="javascript:void(0);"'
          + 'onclick="dioApp(\''+app_url+'\', \''+store_url+'\')">' ;
        
        this.appendDioAppScript();
      } else if( window.DioPlayer.device == '3' ){
        // Androidブラウザからのアクセスの場合
        html += '<a class="appSelector_Android" href="intent://tv-asahi.co.jp?type=play&auto_start=true&product_type=1&channel=ex'
              + '&meta_id=' + window.DioPlayer.meta_id 
              + '&ssai_ad_config_id=' + window.DioPlayer.ssai_ad_config_id
              + '&uid=' + window.DioPlayer.uid
              + '&cid=' + window.DioPlayer.ga_cid
              + '&subtitle=' + window.DioPlayer.subtitle
              + '#Intent;scheme=dioplayer;'
              + 'package=' + this.context.models.config.data.dioapp.android_package
              + ';end" >' ;

      } else if( window.DioPlayer.device == '4' ){
        // アプリでのアクセスの場合
        html += '<a class="appSelector" href="' + this.context.models.config.data.dioapp.custom_schema
              + '?type=play&auto_start=true&product_type=1&channel=ex'
              + '&meta_id=' + window.DioPlayer.meta_id 
              + '&ssai_ad_config_id=' + window.DioPlayer.ssai_ad_config_id
              + '&uid=' + window.DioPlayer.uid
              + '&cid=' + window.DioPlayer.ga_cid
              + '&subtitle=' + window.DioPlayer.subtitle
              + '&return_url=' + location.href
              + '" >' ;
      }

      html += '<image src="' + thumbnail_url + '" style="width: 100%" alt="' + window.DioPlayer.program_name + '" class="pointer-none" />';
      html += '</a>';
      html += '</div>';
      html += '</div>';
      document.getElementById('DIOplayer').innerHTML = html;
    }
  }

  appendDioAppScript() {
    // iOS用のscriptを出力する
    if( window.DioPlayer.device == '2' ){
      var s = document.createElement("script");
      s.innerHTML = "function dioApp( app_url, store_url ){"
      + " let timeout;"
      + " let time = new Date();"
      + " window.location.href = app_url;"
      + " timeout = setTimeout(function(){"
      + "   var now = new Date() ;"
      + "   if(now - time < 3000) {"
      + "     if(confirm('“App Store”で開きますか？')){"
      + "       window.location.href = store_url ;"
      + "     }"
      + "   }"
      + " }, 15);"
      + "}" ;

      document.getElementById("DIOplayer").appendChild(s);
    }
  }

  render() {
    return (
      <div className="exvideo-player-error-view">
        <div id="DIOplayer"></div>
      </div>
    );
  }
}
export default ExVideoPlayer;
