/* style */
import styled from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'

export const StyledP = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`

export const StyledDiv = styled.div`
  padding-top: 0;
  padding-bottom: 0;

  > div {
    :first-child {
      margin-top: 0;
      padding: 0;

      ${mediaQuery()} {
        padding: 0 15px 15px 15px;
        background: #f3f4f5;
      }
    }
    figcaption {
      > div {
        :nth-child(1) {
          min-height: 2.6em;
          max-height: 1.3em;
        }

        :nth-child(3) {
          display: none;

          ${mediaQuery(578)} {
            display: block;
          }
        }

        :nth-child(4) {
          ${mediaQuery(578)} {
            position: static;
            margin-top: 4px;
          }
        }
      }

      span {
        display: block;

        :nth-child(2) {
          float: left;
        }

        :last-child {
          float: right;
          font-weight: 300;
          font-size: 1rem;
          line-height: 20px;

          ${mediaQuery(578)} {
            display: none;
          }
        }
      }
    }
  }
`
