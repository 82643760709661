import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { ARTICLE_SCHEMAS } from '../../../../../../../constants/app'

/* style */
/* style */
import {
  StyledButton,
  StyledTime,
  StyledSpan1,
  StyledSpan2,
  StyledDDd
} from './style'

const Articles = ({ articles }, ..._props) => {
  if (!articles) return null

  const [openIds, setOpenIds] = useState([])

  const toggleOpen = (id) => {
    const idx = openIds.indexOf(id)
    if (idx > -1) {
      // あれば削除
      const afterIds = [...openIds]
      afterIds.splice(idx, 1)
      setOpenIds(afterIds)
    } else {
      // なければ追加
      setOpenIds([...openIds, id])
    }
  }

  return articles.map((article) => {
    const schema = Object.values(ARTICLE_SCHEMAS).find(
      (s) => s.id === article.schema_id
    )
    const publishStartAt = moment(article.publish_start_at).format('YYYY/MM/DD')
    const contents = _.get(article, ['values', 'detail']) || ''
    const open =
      openIds.length > 0 && (openIds.includes(article.id) ? 'open' : '')
    const noDetail = !contents

    return (
      <React.Fragment key={article.id}>
        <dl>
          <dt>
            <StyledButton
              onClick={(e) => toggleOpen(article.id, e)}
              open={open}
              noDetail={noDetail}
              name={`news-${article.id}`}
            >
              <StyledTime>{publishStartAt}</StyledTime>
              <StyledSpan1 style={{ backgroundColor: schema.color }}>
                {schema.label}
              </StyledSpan1>
              <StyledSpan2>{article.display_name}</StyledSpan2>
            </StyledButton>
          </dt>

          {open && contents && (
            <StyledDDd dangerouslySetInnerHTML={{ __html: contents }} />
          )}
        </dl>
      </React.Fragment>
    )
  })
}

export default Articles

Articles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired
}
