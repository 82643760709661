import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import { EPISODE_DISPLAY_MODE } from '../../../../../constants/app'
import AboutMedalLink from '../../../../common/components/renewal/AboutMedalLink'

/** 価格 */
export default class Price extends Component {
  static propTypes = {
    /**
      * 動画ステータス
      * @param {number} displayMode - 動画の視聴形態(1-9)
      * @param {string} 1 - FREE
      * @param {string} 2 - TVOD_FREE
      * @param {string} 3 - TVOD_NOT_FREE
      * @param {string} 4 - SVOD_FREE
      * @param {string} 5 - SVOD_NOT_FREE
      * @param {string} 6 - SVOD_AUTH_FREE
      * @param {string} 7 - STVOD_FREE
      * @param {string} 8 - STVOD_TVOD_NOT_FREE
      * @param {string} 9 - STVOD_SVOD_NOT_FREE
     */
    status: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      isDeviceNotAvailable: PropTypes.bool,
      limitDate: PropTypes.string,
      isPossible: PropTypes.bool,
      isBelonging: PropTypes.bool
    }),
    /** [購入に必要なコイン枚数, 購入に必要な日本円] */
    price: PropTypes.array
  }

  static defaultProps = {
    status: {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    price: [null, null]
  }

  static contextTypes = {
    routeHandler: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  componentDidMount() {}

  /**
   * TODO
   * 料金部分の描画のみを担当するコンポーネントを作成
   * メダル部分のコンポーネント化（いちいち画像あてなくて良いように）
  */
  render() {
    const { status, price } = this.props
    const isApp = webApp.utils.isApp(this.context)

    // 有料コンテンツで、内部設定が完了していないものは出さない
    if (!status.isFree && !status.isPurchseAvailable) {
      return <div className="inBox">料金：-</div>
    }

    switch(status.displayMode) {
      case EPISODE_DISPLAY_MODE.FREE:
      case EPISODE_DISPLAY_MODE.TVOD_FREE:
      case EPISODE_DISPLAY_MODE.SVOD_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_FREE:
        return (
          <div className="c-storyMeta-inBox-meta-price free">
            料金：<span className="c-storyMeta-inBox-meta-price-coin">{price[0]}</span>
            {!isApp && <AboutMedalLink linkClassName="c-storyMeta-inBox-meta-price-aboutCoin" />}
          </div>
        )
      case EPISODE_DISPLAY_MODE.TVOD_NOT_FREE:
        // 金額を出してはいけない
        if (isApp) return null
        return (
          <div className="c-storyMeta-inBox-meta-price">
            料金：<span className="c-storyMeta-inBox-meta-price-coin">{price[0]}</span>
            <AboutMedalLink linkClassName="c-storyMeta-inBox-meta-price-aboutCoin" />
          </div>
        )
      case EPISODE_DISPLAY_MODE.STVOD_TVOD_NOT_FREE:
        // 金額を出してはいけない
        if (isApp) return null
        return (
          <div className="c-storyMeta-inBox-meta-price">
            料金：<span className="c-storyMeta-inBox-meta-price-coin">{price[0]}</span>
            <span className="c-storyMeta-inBox-meta-price-or">or</span>
            <span className="c-storyMeta-inBox-meta-price-stvod">月額 {price[1]}円</span> (税込)
            <AboutMedalLink linkClassName="c-storyMeta-inBox-meta-price-aboutCoin" />
          </div>
        )
      case EPISODE_DISPLAY_MODE.SVOD_NOT_FREE:
      case EPISODE_DISPLAY_MODE.STVOD_SVOD_NOT_FREE:
        // 金額を出してはいけない
        if (isApp) return null
        return (
          <div className="c-storyMeta-inBox-meta-price">料金：月額 {price[1]}円 (税込)</div>
        )
      case EPISODE_DISPLAY_MODE.UNKNOWN:
      default:
        return null
    }
  }
}
