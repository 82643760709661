import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import webApp from '../../../utils/exdioWebAppUtils'
// 共通コンポーネント
import SnsArea from '../sodaTvGlobal/common/SnsArea'
import Heading from '../sodaTvGlobal/common/Heading'
import Footer from '../sodaTvGlobal/common/Footer'
// pc
import MainSlider from '../sodaTvGlobal/program/pc/MainSlider'
import SwitchableGlobal from '../sodaTvGlobal/common/Switchable'
// sp
import SpMainSlider from '../sodaTvGlobal/program/sp/SpMainSlider'
import { GlobalStyle, mediaPc } from '../sodaTvGlobal/styles'

// そだてれび: 海外視聴用番組テンプレート
const ProgramSodaTvGlobal = (
  { seriesId = '', seasonId = '', meta = [], episodes = [] },
  context
) => {
  const isSmp = useMediaQuery()
  const isApp = webApp.utils.isApp(context)
  const slideEpisodes = episodes.slice(0, 5)
  const dispOrder = _.get(meta, ['values', 'disp_order'])
  const showSlides = isSmp ? 4 : 5

  useEffect(() => {
    /** アプリからブラウザにリダイレクト */
    if (isApp) {
      const pathname = _.get(context, ['history', 'location', 'pathname'])
      const redirectHref = pathname && pathname.replace('/app', '')
      window.location.href = redirectHref
      context.history.push(context.routes.top)
    }
  }, [])

  if (isApp) return null

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@900&display=swap"
        />
      </Helmet>
      <GlobalStyle />
      <Heading isSmp params={{ seriesId, seasonId }} />
      <StyledMain isSmp={isSmp}>
        {/* スライドのコンテンツ数がslidesToShowの設定数(x)以下の場合挙動がバグるので非表示にする */}
        {slideEpisodes.length >= showSlides && !isSmp && (
          <StyledMainSlider
            episodes={slideEpisodes}
            rootMetas={slideEpisodes}
          />
        )}
        {slideEpisodes.length >= showSlides && isSmp && (
          <StyledSpMainSlider
            episodes={slideEpisodes}
            rootMetas={slideEpisodes}
            showNew
            showChecked
            onlySubTitle
          />
        )}
        {slideEpisodes.length >= showSlides && (
          <StyledSnsArea
            seriesId={seriesId}
            seasonId={seasonId}
            isSmp={isSmp}
          />
        )}
        {slideEpisodes.length <= showSlides - 1 && (
          <StyledSnsArea
            seriesId={seriesId}
            seasonId={seasonId}
            isSmp={isSmp}
          />
        )}
        <StyledSwitchableGlobal
          episodes={episodes}
          disp_order={dispOrder}
          isSmp={isSmp}
        />
      </StyledMain>
      <StyledFooter isSmp={isSmp} />
    </>
  )
}

export default ProgramSodaTvGlobal

const StyledMain = styled.main`
  padding: ${({ isSmp }) => (isSmp ? '0 8px' : '0 20px')};
  max-width: ${({ isSmp }) => !isSmp && '960px'};
  margin: 0 auto;

  @media ${mediaPc} {
    max-width: ${({ isSmp }) => !isSmp && '1280px'};
  }
`

const StyledMainSlider = styled(MainSlider)`
  margin-top: 15px;
`

const StyledSpMainSlider = styled(SpMainSlider)`
  margin-top: 15px;
`

const StyledSnsArea = styled(SnsArea)`
  margin-top: ${({ isSmp }) => (isSmp ? '20px' : '10px')};
`

const StyledSwitchableGlobal = styled(SwitchableGlobal)`
  margin-top: ${({ isSmp }) => (isSmp ? '20px' : '40px')};
`

const StyledFooter = styled(Footer)`
  margin-top: 30px;
`

ProgramSodaTvGlobal.propTypes = {
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  /** 動画のメタ情報 */
  meta: PropTypes.shape({
    meta_schema_id: PropTypes.number.isRequired,
    thumbnail_url: PropTypes.string,
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    duration: PropTypes.number,
    delivery_start_at: PropTypes.string,
    delivery_end_at: PropTypes.string
  }).isRequired,
  /** 関連動画情報 */
  episodes: PropTypes.arrayOf(PropTypes.object)
}

ProgramSodaTvGlobal.contextTypes = {
  routeHandler: PropTypes.object,
  history: PropTypes.object
}
