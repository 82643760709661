import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NEWS_CONTENTS } from '../../../../../constants/app'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import routes from '../../../../common/routes'
import NotFound from '../../../../generic/components/errors/NotFound'
import Information from '../news/Information'
import NewArrival from '../news/NewArrival'
import webApp from '../../../utils/exdioWebAppUtils'
import HeaderNewsComponent from '../HeaderNewsComponent'
import Footer from '../../../../common/components/appli/StaticFooter'

/** ニュースページ */
export default class NewsContent extends Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)

    this.url = context.routeHandler.path

    const currentContent = Object.values(NEWS_CONTENTS).find((content) => {
      const route = `app_${content.route}`
      return routes[route].match(this.url)
    })

    this.state = {
      currentContent
    }

    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context)
    // GTMの更新
    const title = this.context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)
  }

  goBack(e) {
    e.preventDefault()
    this.context.history.goBack()
  }

  renderTabs() {
    const { currentContent } = this.state
    return Object.values(NEWS_CONTENTS).map((content) => {
      const className = classnames('c-mypageNews-header-nav-inBox-btn-link', {
        current: currentContent === content
      })
      const route = `app_${content.route}`
      return (
        <li key={content.id} className="c-mypageNews-header-nav-inBox-btn">
          <Link route={routes[route]} className={className}>
            {content.label}
          </Link>
        </li>
      )
    })
  }

  renderContents() {
    const { currentContent } = this.state
    if (!currentContent) return <NotFound />

    switch (currentContent) {
      case NEWS_CONTENTS.INFORMATION:
        return <Information />
      case NEWS_CONTENTS.NEW_ARRIVAL:
        return <NewArrival />
      default:
        return <NotFound />
    }
  }

  render() {
    return (
      <div className="common-wrapper">
        <HeaderNewsComponent />
        <div className="c-mypage news">
          <div className="c-mypage-cont">
            <div className="c-mypage-cont-inBox">
              <div className="c-mypageNews">
                <header className="c-mypageNews-header">
                  {this.context.history && this.context.history.length > 1 ? (
                    <a
                      href=""
                      className="c-mypage-btnBack"
                      onClick={this.goBack}
                    >
                      戻る
                    </a>
                  ) : (
                    <Link route={routes.app_home} className="c-mypage-btnBack">
                      戻る
                    </Link>
                  )}
                  <h3 className="c-mypageNews-header-title">ニュース</h3>
                  <nav className="c-mypageNews-header-nav">
                    <ul className="c-mypageNews-header-nav-inBox">
                      {this.renderTabs()}
                    </ul>
                  </nav>
                </header>

                {this.renderContents()}
              </div>
            </div>
          </div>
        </div>
        <Footer className="mp-mt0" />
      </div>
    )
  }
}
