/* style */
import styled from 'styled-components'

/* components */
import Link from '../../../../../../../../sketch-platform/ui/routing/Link'

export const mediaSp = '(max-width: 1024px)'

export const StyledFooter = styled.footer`
  grid-area: footer;
  padding: 20px 0 16px;
  background-color: #000;

  @media ${mediaSp} {
    padding: 16px;
  }
`

export const StyledNav = styled.nav`
  margin: 64px auto 0;
  width: 872px;
  text-align: center;

  @media ${mediaSp} {
    margin: 16px 0 0;
    width: 100%;
  }
`

export const StyledUl1 = styled.ul`
  margin: 0 auto;
  width: 872px;
  column-count: 2;

  @media ${mediaSp} {
    margin: 0;
    width: auto;
    display: flex;
    flex-direction: column;
  }
`

export const StyledLi1 = styled.li`
  margin-bottom: 12px;
  width: 360px;

  @media ${mediaSp} {
    margin-bottom: 0;
    width: auto;
  }

  &:first-child {
    @media ${mediaSp} {
      margin-top: 0;
    }
  }
`

export const StyledLink1 = styled(Link)`
  && {
    color: #fff;
    font-size: 1.6rem;

    @media ${mediaSp} {
      font-size: 1.2rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

export const StyledUl2 = styled.ul`
  margin: 64px auto 0;
  width: 872px;
  text-align: center;

  @media ${mediaSp} {
    margin: 16px 0 0;
    width: 100%;
  }
`

export const StyledLi2 = styled.li`
  margin-left: 32px;
  width: 256px;
  display: inline-block;

  @media ${mediaSp} {
    margin-left: 16px;
    max-width: 240px;
    width: calc((100% - 16px) / 2);
  }

  &:first-child {
    margin-left: 0;
  }
`

export const StyledLink2 = styled(Link)`
  text-align: center;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`

export const StyledImg = styled.img`
  width: 100%;
`
