import { Link } from '../../../../../../../sketch-platform/ui/routing'
import styled, { keyframes } from 'styled-components'
import { mediaQuery } from '../../../../style'
import { black, white } from '../../styles'

const shine1 = keyframes`
100% {
    left: 125%;
}
`

const shine2 = keyframes`
100% {
    left: calc(125% + 110px);
}
`

export const StyledLink = styled(Link)`
  padding: 10px 10px 15px;
  height: 100%;
  background-color: ${white};
  display: inline-block;
  border-radius: 12px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  color: ${black};

  ${mediaQuery()} {
    padding: 0 0 20px;
  }

  &::before,
  &::after {
    width: 90px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    display: block;
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    transform: skewX(-25deg);
    content: '';

    ${mediaQuery()} {
      content: none;
    }
  }

  &::after {
    width: 40px;
    left: calc(-75% + 110px);
  }

  &:hover {
    &::before {
      animation: ${shine1} 1s;
    }

    &::after {
      animation: ${shine2} 1s;
    }
  }
`

export const StyledImg = styled.img`
  width: 520px;
  height: 292px;
  vertical-align: bottom;
  object-fit: cover;

  ${mediaQuery()} {
    width: 100%;
    height: calc((9 / 16) * (100vw * (175 / 375)));
  }
`

export const StyledP = styled.p`
  margin-top: 15px;
  padding: 0 10px;
  font-size: 1.6rem;
  font-weight: bold;

  ${mediaQuery()} {
    margin-top: 10px;
    padding: 0 10px;
    font-size: 1.3rem;
  }
`
