import React from 'react'
import PropTypes from 'prop-types'

/* components */
import DsearchMetas from '../DsearchMetas'
import Recommend from '../Recommend'
import Objects from '../Objects'
import ProgramItemSkeleton from '../../ProgramItemSkeleton'

/** フラグによって実行する関数をを出し分け */
const CardSliderController = ({
  loaded = false,
  isDsearch = false,
  dsearchMetas = [],
  recommendItems = [],
  palette = {},
  howToPlays = {},
  courses = null,
  products = null,
  ...props
}) => {
  if (!loaded) {
    const skeletonObjects = []
    for (let i = 0; i < 6; i += 1) {
      skeletonObjects.push(
        <ProgramItemSkeleton key={i} hasThumb titleLength={2} {...props} />
      )
    }
    return skeletonObjects
  }

  if (isDsearch) {
    return (
      <DsearchMetas metas={dsearchMetas} howToPlays={howToPlays} {...props} />
    )
  }

  if (recommendItems.length > 0) {
    return <Recommend metas={recommendItems} {...props} />
  }

  return (
    <>
      <Objects
        palette={palette}
        howToPlays={howToPlays}
        courses={courses}
        products={products}
        {...props}
      />
    </>
  )
}

export default CardSliderController

CardSliderController.propTypes = {
  loaded: PropTypes.bool,
  isDsearch: PropTypes.bool,
  dsearchMetas: PropTypes.array,
  recommendItems: PropTypes.array,
  palette: PropTypes.shape({
    name: PropTypes.string.isRequired,
    schema_id: PropTypes.number,
    objects: PropTypes.array.isRequired
  }).isRequired,
  howToPlays: PropTypes.object,
  products: PropTypes.object,
  courses: PropTypes.object
}
