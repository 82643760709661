import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import _ from 'lodash'
import webApp from '../../utils/exdioWebAppUtils'

/* component */
import GaribenTagList from './GaribenTagList'

/** 静的ページ */
const GaribenTagListContent = (props, context) => {
  const model = context.falcorModel.batch(100)
  const config = context.models.config.data

  const [episodes, setEpisodes] = useState([])
  const [tags, setTags] = useState([])
  const [meta, setMeta] = useState({})
  const seasonIdList = [
    config.svod.gariben_daigaku.live_archives.season_id,
    config.svod.gariben_daigaku.special_movies.season_id,
    config.svod.gariben_daigaku.archives.season_id
  ]

  /** SPAでのHTML HEADタグ更新 */
  const updateMetaTags = () => {
    const title = 'タグ一覧｜私立ガリベン大学｜テレ朝動画'
    webApp.utils.updateTitle(title)

    const { keywords, copyright } = config
    const description = '「私立ガリベン大学」テレ朝動画で配信中！'
    const url = window.location.href
    const regularUrl = url.replace(/\?.*$/, '')
    const ogImage =
      'https://www.tv-asahi.co.jp/douga_mv/gariben_daigaku/images/OG.jpg'
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: ogImage },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'mixi:image', content: ogImage },
        { property: 'og:image', content: ogImage },
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [
        { rel: 'canonical', href: regularUrl },
        {
          rel: 'shortcut icon',
          href: '/images/exdio/renewal/gariben_daigaku/top/gbu_favicon.webp'
        },
        {
          rel: 'apple-touch-icon',
          href: '/images/exdio/renewal/gariben_daigaku/top/gbu_favicon.webp'
        }
      ]
    }
    webApp.utils.updateMeta(metaTags)
  }

  /** 指定したシーズンのエピソードを取得 */
  const getEpisodes = () => {
    const path = ['meta', 'children', seasonIdList]
    return model.fetch([path]).then((result) => {
      let _episodes = []
      seasonIdList.forEach((id) => {
        const seasonEpisodes = _.get(result, ['json', 'meta', 'children', id])
        _episodes = [].concat(_episodes, seasonEpisodes)
      })
      setEpisodes(_episodes)
    })
  }

  /** 取得したエピソードのタグを取得 */
  const getTags = () => {
    const countObj = {}
    let allTags = []
    episodes.forEach((episode) => {
      const _tags = _.get(episode, ['tags'])
      allTags = [].concat(allTags, _tags)

      allTags.forEach((tag) => {
        countObj[tag] = {
          name: tag,
          count: (_.get(countObj, [tag, 'count']) || 0) + 1
        }
      })
    })
    const sortedTags = _.sortBy(countObj, 'count').reverse()
    setTags(sortedTags)
  }

  /** 指定のシーズンのエピソードを取得 */
  const getChildEpisodeIds = () => {
    const childEpisodeIds = episodes.map((episode) => _.get(episode, ['id']))
    const _meta = {
      values: {
        child_episode_ids: childEpisodeIds
      }
    }
    setMeta(_meta)
  }

  useEffect(() => {
    updateMetaTags()

    // GTMで使用する静的ページのイベントを入れる
    const gtm_tags = [
      { key: 'event', value: 'staticPageChange' },
      { key: 'genre', value: 'cu' }
    ]
    webApp.utils.updateDataLayer(gtm_tags)
    webApp.utils.updateCookieSync(window.navigator.userAgent)

    getEpisodes().catch((e) => webApp.utils.handleFalcorError(e, context))
  }, [])

  useEffect(() => {
    getTags()
    getChildEpisodeIds()
  }, [episodes])

  return <GaribenTagList meta={meta} tags={tags} seasonIdList={seasonIdList} />
}

export default GaribenTagListContent

GaribenTagListContent.contextTypes = {
  models: PropTypes.shape({}),
  routeHandler: PropTypes.shape({}),
  history: PropTypes.shape({}),
  falcorModel: PropTypes.shape({})
}

// TODO
// スタイルが反映されないためclassで指定、要調査
// const StyledHeaderInner = styled.div`
//   margin: 0 auto;
//   max-width: 1160px;
//   position: relative;

//   @media ${mediaSp} {
//     width: 100%;
//     height: 40px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// `
