import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Cookie from 'js-cookie'
import namespace from '../../../../../common/namespace'
import webApp from '../../../utils/exdioWebAppUtils'
import { PRODUCT_SCHEMA } from '../../../../../constants/app'
import NotFound from '../../../../generic/components/errors/NotFound'

import redirectConfig from '../../../redirect.config.json' // アプリからブラウザへのリダイレクトに使用

const { sprintf } = require('sprintf-js')

/** 番組ページ */
export default class ProgramContent extends Component {
  static propTypes = {
    seriesId: PropTypes.string,
    seasonId: PropTypes.string,
    version: PropTypes.string
  }

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    version: ''
  }

  static contextTypes = {
    app: PropTypes.object,
    falcorModel: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  static getPath(_models, _options, props) {
    return ['metas', props.seasonId]
  }

  static getPrefetchPaths(models, options, props) {
    return [ProgramContent.getPath(models, options, props)]
  }

  static getSsrMetaTags(models, options, props, prefetchResult) {
    const meta =
      _.get(prefetchResult, [
        'json',
        ...ProgramContent.getPath(models, options, props)
      ]) || {}
    let [metaName, subTitle] = webApp.utils.titles(meta)
    metaName = `${metaName} ${subTitle}`
    const title = metaName
    const description = meta.values ? meta.values.evis_SeasonLongSynopsis : null
    const thumbnail = meta.thumbnail_url
    return { title, thumbnail, description }
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = context.models.config.data

    const path = ProgramContent.getPath({}, {}, props)
    const meta = this.model.getSync(path) || null

    this.state = {
      meta,
      otherSeasons: null,
      episodes: [],
      products: [],
      howToPlays: {},
      displayModes: {},
      loaded: false
    }
  }

  componentDidMount() {
    this._isMounted = true
    const { seriesId, version } = this.props
    const shinchanSeriesId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? ['10965', '12859']
      : ['11721']
    this.getMeta()
      .then(() => this.updateMetaTags())
      .then(() => {
        if (shinchanSeriesId.indexOf(seriesId) > -1) {
          this.addUsergramTagShinchan()
        }
      })
      .then(() => this.getOtherSeason())
      .then(async () => {
        const { meta } = this.state
        const templateId = _.get(meta, ['values', 'ex_templateId'], 0)
        const isShinchan =
          shinchanSeriesId.indexOf(seriesId) > -1 &&
          version !== '検索高機能版' &&
          templateId === 200
        if (isShinchan) {
          // 速度優先版を使用するため何もしない
        } else {
          await this.getEpisodes().catch((e) =>
            webApp.utils.handleFalcorError(e, this.context)
          )
        }
      })
      // .catch(e => webApp.utils.handleFalcorError(e, this.context));
      // this.getEpisodes()
      .then(() => {
        const { meta } = this.state
        const templateId = _.get(meta, ['values', 'ex_templateId'], 0)
        const isShinchan =
          shinchanSeriesId.indexOf(seriesId) > -1 &&
          version !== '検索高機能版' &&
          templateId === 200
        if (isShinchan) {
          // 速度優先版を使用するため何もしない
        } else {
          this.getHowToPlay()
        }
      })
      .catch((e) => webApp.utils.handleFalcorError(e, this.context))
      .finally(() => {
        this.setState({ loaded: true })
      })

    // レコメンド
    this.recommendActionLog().catch((e) =>
      webApp.utils.handleFalcorError(e, this.context)
    )
  }

  componentWillUnmount() {
    this._isMounted = false
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    const { seriesId } = this.props
    const shinchanSeriesId = ['development', 'staging'].includes(
      process.env.NODE_ENV
    )
      ? '10965'
      : '11721'
    const shinchanUsergramIds = [
      'usergram-member-shinchan-program-app',
      'usergram-common-shinchan-program-app'
    ]

    if (seriesId === shinchanSeriesId && isLoggedIn === true) {
      webApp.utils.removeUserGram(shinchanUsergramIds)
    }
  }

  /** SPAでのHTML HEADタグ更新 */
  updateMetaTags() {
    const { meta } = this.state

    // タイトルタグの更新
    let [metaName, subTitle] = webApp.utils.titles(meta)
    metaName = `${metaName} ${subTitle}`
    const title = metaName.trim()
    webApp.utils.updateTitle(
      sprintf(this.config.title_template, title || this.config.default_title)
    )

    // メタタグの更新
    const { copyright } = this.config
    const description =
      (meta.values ? meta.values.evis_SeasonLongSynopsis : null) ||
      this.config.description
    const keywords = this.config.keywords
    const rootUrl = `${window.location.protocol}//${window.location.host}`
    const ogImage =
      meta.thumbnail_url ||
      sprintf('%s/images/exdio/%s', rootUrl, this.config.og_image)
    const url = window.location.href
    const regularUrl = url.replace(/\?.*$/, '')
    const removeAppUrl = regularUrl.replace('/app', '')

    const episodeId = meta.meta_id
    const seriesAltId =
      _.get(meta, ['values', 'parents_series', 'avails_SeriesAltID']) || ''
    const seasonAltId = _.get(meta, ['values', 'avails_SeasonAltID']) || ''
    const programId = `${seriesAltId}_${seasonAltId}`
    const now = new Date().getTime()
    const tsukasaId =
      _.get(meta, ['values', 'avails_SeasonTitleInternalAlias']) || ''

    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'startTime', value: now },
      { key: 'program_id', value: programId },
      { key: 'program', value: title },
      { key: 'episode_id', value: episodeId },
      { key: 'episode', value: title },
      { key: 'tsukasa_id', value: tsukasaId }
    ]
    const metaTags = {
      names: [
        { name: 'copyright', content: copyright },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: ogImage },
        { name: 'twitter:title', content: title },
        { name: 'twitter:url', content: regularUrl },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'mixi:image', content: ogImage },
        { property: 'og:image', content: ogImage },
        { property: 'og:title', content: title },
        { property: 'og:url', content: regularUrl },
        { property: 'og:description', content: description }
      ],
      links: [{ rel: 'canonical', href: removeAppUrl }]
    }
    webApp.utils.updateMeta(metaTags)
    webApp.utils.updateDataLayer(gtmTags)
    webApp.utils.updateCookieSync(window.navigator.userAgent)
  }

  /** クレヨンしんちゃん番組ページ USERGRAMタグ追加 */
  addUsergramTagShinchan() {
    const { meta } = this.state
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)

    if (isLoggedIn === false) {
      return
    }

    const season_name = meta.values.evis_FrontDisplayTitle
    let member_id = ''
    const cookies = document.cookie
    const cookiesArr = cookies.split(';')

    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split('=')
      if (cookieArr[0] == ' CBM_ID') {
        member_id = cookieArr[1]
      }
    }

    let browseUserGram = ''
    browseUserGram +=
      '<script type="text/javascript" id="usergram-member-shinchan-program-app">'
    browseUserGram += 'window.ugattr = window.ugattr || {};'
    browseUserGram += `ugattr[\'serviceId\'] = \'${member_id}\';`
    browseUserGram += `ugattr[\'prop07\'] = \'【アプリ】${season_name}\';`
    browseUserGram += `ugattr[\'prop09\'] = \'\';`
    browseUserGram += `ugattr[\'prop10\'] = \'\';`
    browseUserGram += '</script>'

    browseUserGram +=
      '<script type="text/javascript" id="usergram-common-shinchan-program-app">'
    browseUserGram +=
      '(function(){var a=window,b=document,c=a.usergram=a.usergram||[],d,e;'
    browseUserGram +=
      "c.l||(c.s=(new Date()).getTime(),c.l=!0,d=b.getElementsByTagName('script')[0],"
    browseUserGram +=
      "e=b.createElement('script'),e.type='text/javascript',e.async=true,"
    browseUserGram +=
      "e.src='//code.usergram.info/js/usergram.js',d.parentNode.insertBefore(e,d))})();"
    browseUserGram +=
      'window.usergram=window.usergram||[],window.ugattr=window.ugattr||{};'
    browseUserGram +=
      "usergram.push(['send','Ug37cn-1','cv','shinchan_program',ugattr]);"
    browseUserGram += '</script>'

    webApp.utils.appendUserGram(browseUserGram)
  }

  /** メタ情報詳細取得 */
  getMeta() {
    const { seriesId, seasonId } = this.props
    if (!seriesId || !seasonId) {
      if (this._isMounted) {
        this.setState({ meta: {} })
      }
      return Promise.resolve()
    }

    const path = ProgramContent.getPath({}, {}, this.props)
    return this.model.fetch([path]).then((result) => {
      const meta = _.get(result, ['json', ...path]) || {}
      const parentSeriesId = _.get(meta, ['values', 'parents_series', 'id'])
      const isValidSeriesId = seriesId === String(parentSeriesId)
      if (this._isMounted) {
        this.setState({ meta: isValidSeriesId ? meta : {} })
      }
    })
  }

  // 他のシーズンの取得
  getOtherSeason() {
    const { meta } = this.state

    let w_otherSeasons = _.get(meta, ['values', 'other_series']) || []
    const w_otherSeasonsLive =
      _.get(meta, ['values', 'other_series_live']) || []
    w_otherSeasons = w_otherSeasons.concat(w_otherSeasonsLive)
    w_otherSeasons = w_otherSeasons.filter((v) => v)
    if (!w_otherSeasons) return Promise.resolve()

    const path = ['metas', w_otherSeasons]
    return this.model.fetch([path]).then((result) => {
      const hash_otherSeasons = _.get(result, ['json', 'metas']) || {}
      delete hash_otherSeasons.$__path
      if (hash_otherSeasons && this._isMounted) {
        this.setState({ otherSeasons: Object.values(hash_otherSeasons) })
      }
    })
  }

  /** 同シーズンのエピソード一覧取得 */
  getEpisodes() {
    const { seasonId } = this.props
    if (!seasonId) return Promise.resolve()

    const path = ['meta', 'children', seasonId]
    return this.model.fetch([path]).then((result) => {
      const episodes = _.get(result, ['json', ...path]) || []
      // 話数でソートする
      episodes.sort((a, b) =>
        Number(a.values.avails_EpisodeNumber) <
        Number(b.values.avails_EpisodeNumber)
          ? 1
          : -1
      )
      this.setState({ episodes })
    })
  }

  getHowToPlay() {
    const { episodes } = this.state
    if (!episodes || !episodes.length) return Promise.resolve({})

    const metaIds = episodes.map((e) => e.meta_id)
    const path = [['meta', 'howToPlay', true, metaIds]]
    return this.model.fetch(path).then((result) => {
      const howToPlays =
        _.get(result, ['json', 'meta', 'howToPlay', true]) || {}

      const productsTmp = {}
      Object.keys(howToPlays)
        .filter((k) => !k.startsWith('$'))
        .filter((k) => !!howToPlays[k])
        .flatMap((k) => howToPlays[k].products)
        .filter((p) => p.schema_id !== PRODUCT_SCHEMA.SINGLE_STORY.id)
        // distinct
        .forEach((p) => {
          productsTmp[p.product_id] = p
        })
      const products = Object.keys(productsTmp).map((k) => productsTmp[k])

      // 各エピソードの表示モード判定
      const displayModes = {}
      _.forEach(episodes, (episode) => {
        const metaId = episode.meta_id
        const productsForDisp = _.get(howToPlays, [metaId, 'products']) || []
        const product = productsForDisp.find(
          (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
        )
        const course = _.get(howToPlays, [metaId, 'courses', 0])
        displayModes[episode.meta_id] = webApp.utils.getDisplayMode(
          episode,
          product,
          course
        )
      })

      this.setState({ products, howToPlays, displayModes })
    })
  }

  /** レコメンド(閲覧ログ蓄積) */
  recommendActionLog() {
    const { seasonId } = this.props
    if (!seasonId) return Promise.resolve()
    const sessionId = Cookie.get('luid') || Cookie.get('_ga') || ''
    // sessionId必須なのでluid取れなければ処理なし
    if (!sessionId) return Promise.resolve()
    const path = [['action_log', 'history', 'view', seasonId, sessionId]]
    return this.model.fetch(path)
  }

  // リダイレクト対象のページの場合、ブラウザにリダイレクトする
  redirect2Browser() {
    const { seriesId, seasonId } = this.props

    /*
     * development(ローカル) or STGのときはstgを参照
     * それ以外はprdを参照
     */
    const envKey = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 'stg'
      : 'prd'
    const mergedValues = [].concat(
      redirectConfig[envKey].program.data.logirl,
      redirectConfig[envKey].program.data.gariben_daigaku
    )

    // seriesId-seasonIdを文字列連結
    const str_series_season = `${seriesId}-${seasonId}`

    // リストの項目と一致する場合はリダイレクト
    if (mergedValues.includes(str_series_season)) {
      window.location.href = `/program/${str_series_season}`
    }
  }

  render() {
    const {
      meta,
      otherSeasons,
      episodes,
      products,
      howToPlays,
      displayModes,
      recommendItems,
      loaded
    } = this.state
    if (!meta) return null
    if (!Object.keys(meta).length) return <NotFound key="not-found" />

    // リダイレクトが必要な場合はリダイレクトを実行
    this.redirect2Browser()

    // 番組ページはテンプレート利用対象
    const templateId = meta.values.ex_templateId || 0
    const templateKeyPrefix = 'program_app_'
    const templateKey = `${templateKeyPrefix}${templateId}`
    const template =
      namespace.templates[templateKey] ||
      namespace.templates[`${templateKeyPrefix}0`]
    if (!template) {
      console.error(`Template:${templateKey} not found.`)
      return null
    }

    const childProps = Object.assign({}, this.props, {
      meta,
      otherSeasons,
      episodes,
      products,
      howToPlays,
      displayModes,
      recommendItems,
      loaded
    })
    return React.createElement(template, childProps)
  }
}
