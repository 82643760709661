import React, { Component } from 'react'
import PropTypes from 'prop-types'
import window from 'global'
import _ from 'lodash'
import MDSpinner from 'react-md-spinner'
import Footer from '../../../common/components/Footer'
import Axios from '../../../../common/Axios'
import {
  LOCAL_STORAGE_KEY_PURCHASE,
  PURCHASE_CONTENTS,
  PURCHASE_TYPE,
  PAYMENT_METHOD,
  PRODUCT_SCHEMA
} from '../../../../constants/app'
import Select from './purchase/Select'
import Login from './purchase/Login'
import Completed from './purchase/Completed'
import Confirm from './purchase/Confirm'
import NotSelected from './purchase/NotSelected'
import MedalChargeContent from './MedalChargeContent'
import PurchaseResultContent from './PurchaseResultContent'
import webApp from '../../utils/exdioWebAppUtils'
import SpSubNavigation from '../../../common/components/renewal/SpSubNavigation'
import routes from '../../../common/routes'
import HeaderNewsComponent from './HeaderNewsComponent'

/** 購入ページ */
export default class PurchaseContent extends Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object,
    updateUserInfo: PropTypes.func,
    updateUserInfoSubscribe: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    let storage = {}
    if (window.localStorage) {
      // ストレージに保存してあるデータをロードする
      const tmp = window.localStorage.getItem(LOCAL_STORAGE_KEY_PURCHASE)
      storage = JSON.parse(tmp)
    }

    let currentContent = PURCHASE_CONTENTS.SELECT
    const { query } = context.routeHandler
    if (query.content === 'charge') {
      currentContent = PURCHASE_CONTENTS.CHARGE
    } else if (query.content === 'completed') {
      currentContent = PURCHASE_CONTENTS.COMPLETED
    } else if (query.content === 'charge_completed') {
      currentContent = PURCHASE_CONTENTS.CHARGE_COMPLETED
    } else if (query.content === 'after_login') {
      const isLoggedIn = webApp.utils.isLoggedIn(this.context)
      if (isLoggedIn) {
        const type = storage ? storage.type : null
        // ログイン後の遷移はafterLogin()に移譲
        currentContent = null
      } else {
        currentContent = PURCHASE_CONTENTS.LOGIN
      }
    } else if (query.content === 'confirm') {
      currentContent = PURCHASE_CONTENTS.CONFIRM
    }

    this.state = {
      storage,
      currentContent,
      episodes: [],
      howToPlays: {},
      isPossibles: {},
      isPossibleForPack: {},
      belongsToGroup: null,
      pack: {},
      course: {},
      availablePoint: {
        available_point: 0,
        available_point_from_tvasahi_point: 0
      },
      purchased: [],
      selected: [],
      alreadyPurchasedAll: false,
      paymentMethod: PAYMENT_METHOD.COIN,
      // Confirm.jsのusePoint処理で設定される
      result: {}
    }

    this.setContent = this.setContent.bind(this)
    this.emitSetState = this.emitSetState.bind(this)
    this.getMaterial = this.getMaterial.bind(this)
    this.getCourse = this.getCourse.bind(this)
    this.getBelonging = this.getBelonging.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  async componentDidMount() {
    this._isMounted = true
    this.context.updateUserInfoSubscribe(() => {
      this.getAvailablePoint()
      this.forceUpdate()
      webApp.utils.debug('PurchaseContent forceUpdated.')
    })

    const deviceInfo = this.context.models.browserInfo.data
    let func = () => {
      return this.fetchData().catch((e) => console.error(e))
    }
    if (deviceInfo.isIE) {
      // IEだとfinallyがエラーになる
      await func()
      // 完了画面は購入情報が必要
      if (this.state.currentContent === PURCHASE_CONTENTS.COMPLETED) {
        this.getPurchased()
      }
    } else {
      func().finally(() => {
        this.afterLogin()
        // 完了画面は購入情報が必要
        if (this.state.currentContent === PURCHASE_CONTENTS.COMPLETED) {
          this.getPurchased()
        }
      })
    }
    this.getAvailablePoint()

    // SPAでのHTML HEADタグ更新
    webApp.utils.setDefaultMetaTags(this.context)
    // GTMの更新
    const title = this.context.models.config.data.default_title
    const [program] = title === undefined ? [''] : title.split(' | ')
    const gtmTags = [
      { key: 'event', value: 'pageChange' },
      { key: 'genre', value: 'cu' },
      { key: 'program', value: program }
    ]
    webApp.utils.updateDataLayer(gtmTags)

    const { storage } = this.state
    const { query } = this.context.routeHandler
    if (query.content === 'after_login') {
      const isLoggedIn = webApp.utils.isLoggedIn(this.context)
      if (isLoggedIn) {
        const type = storage ? storage.type : null
        // クライアントで描画しないとMDSpinnerが崩れるのでここでセット
        this.setContent(PURCHASE_CONTENTS.LOADING)
      }
    } else if (query.ret) {
      // SNSログインの場合はsnsAuth APIを叩くためにLoginFormを一旦描画
      this.setContent(PURCHASE_CONTENTS.LOGIN)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getPurchased() {
    const { course } = this.state
    if (!course) return Promise.resolve()
    const refId = course.ref_id
    if (!refId) return Promise.resolve()

    const path = ['infra', 'vodPurchased']
    return this.model.fetch([path]).then((result) => {
      const vodPurchased = _.get(result, ['json', ...path]) || []
      const targetPurchased = vodPurchased.find((purchased) => {
        return purchased.course && purchased.course.ref_id === refId
      })
      if (this._isMounted && targetPurchased) {
        this.setState({
          purchased: [targetPurchased]
        })
      }
    })
  }

  fetchData() {
    const { storage } = this.state
    if (!storage) return Promise.resolve()
    const { type } = storage

    if (PURCHASE_TYPE.EPISODE === type) {
      return this.getEpisodes()
        .then((episodes) => this.getHowToPlay(episodes))
        .then(() => this.getMaterial())
        .then(() => {
          this.setSelected()
          this.setPurchased()
        })
        .catch((e) => console.error(e))
    } else if (PURCHASE_TYPE.PACK === type) {
      return this.getPack()
        .then(() => this.getIsPossibleForPack())
        .then(() => this.setSelected())
        .catch((e) => console.error(e))
    } else if (PURCHASE_TYPE.PLAN === type) {
      return this.getCourse()
        .then(() => this.getBelonging())
        .then(() => this.setSelected())
        .catch((e) => console.error(e))
    }
    return Promise.resolve()
  }

  /** エピソード情報取得 */
  getEpisodes() {
    const seasonId = this.state.storage.id

    const path = ['meta', 'children', seasonId]
    return this.model.fetch([path]).then((result) => {
      const episodes = _.get(result, ['json', ...path]) || []
      // 話数でソートする
      episodes.sort((a, b) =>
        Number(a.values.avails_EpisodeNumber) <
        Number(b.values.avails_EpisodeNumber)
          ? 1
          : -1
      )
      return episodes
    })
  }

  /** 商品情報取得 */
  getHowToPlay(episodes) {
    if (!episodes || !episodes.length) return Promise.resolve()

    const metaIds = episodes.map((e) => e.meta_id)
    const path = [['meta', 'howToPlay', false, metaIds]]
    return this.model.fetch(path).then((result) => {
      const howToPlays =
        _.get(result, ['json', 'meta', 'howToPlay', false]) || []

      // 価格が設定されていないものは除去する
      const w_episodes = episodes.filter((episode) => {
        const products = _.get(howToPlays, [episode.meta_id, 'products']) || []
        const product = products.find(
          (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
        )
        const price = _.get(product, ['active_pricing', 'price'])
        return !!price
      })

      if (this._isMounted) {
        // episodesは価格設定のフィルタが終わってからセット
        this.setState({ howToPlays, episodes: w_episodes })
      }
    })
  }

  /** 再生可否情報取得 */
  getMaterial() {
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) return Promise.resolve()

    const { howToPlays } = this.state
    if (!howToPlays || !Object.keys(howToPlays).length) return Promise.resolve()

    const refIds = Object.keys(howToPlays)
      .filter((k) => !k.startsWith('$'))
      .reduce((prev, k) => {
        const _refIds = howToPlays[k].products.map((p) => p.ref_id)
        if (!_refIds) return prev
        return [...prev, ..._refIds]
      }, [])
      .filter((v) => v)
    if (!refIds.length) return Promise.resolve()

    const path = [['infra', 'isPossible', refIds]]
    return this.model.fetch(path).then((result) => {
      const isPossibles = _.get(result, ['json', 'infra', 'isPossible']) || {}
      if (this._isMounted) {
        this.setState({ isPossibles })
      }
    })
  }

  /** パック商品情報取得 */
  getPack() {
    const { id } = this.state.storage
    const path = ['product', id]
    return this.model.fetch([path]).then((result) => {
      const pack = _.get(result, ['json', ...path], {})
      if (this._isMounted) {
        this.setState({ pack })
      }
    })
  }

  /** 素材のライセンス有無取得 */
  getIsPossibleForPack() {
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) return Promise.resolve()

    const { pack } = this.state
    if (!pack) return Promise.resolve()

    const refId = pack.ref_id
    if (!refId) return Promise.resolve()

    const path = ['infra', 'isPossible', refId]
    return this.model.fetch([path]).then((result) => {
      const isPossibleForPack = _.get(result, ['json', ...path]) || {}
      if (this._isMounted) {
        this.setState({ isPossibleForPack })
      }
    })
  }

  /** コース情報取得 */
  getCourse() {
    const { id } = this.state.storage
    const path = [['course', id]]
    return this.model.fetch(path).then((result) => {
      const course = _.get(result, ['json', 'course', id], {})
      if (this._isMounted) {
        this.setState({ course })
      }
    })
  }

  /** グループのライセンス有無取得 */
  getBelonging() {
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) return Promise.resolve()

    const { course } = this.state
    if (!course) return Promise.resolve()

    const refId = course.ref_id
    if (!refId) return Promise.resolve()

    const path = ['infra', 'gBelong', refId]
    return this.model.fetch([path]).then((result) => {
      const belongsToGroup = _.get(result, ['json', ...path]) || false
      if (this._isMounted) {
        this.setState({ belongsToGroup })
      }
    })
  }

  /** 利用可能コイン数取得 */
  getAvailablePoint() {
    const { availablePoint } = this.context.models.userInfo.data
    const isLoggedIn = webApp.utils.isLoggedIn(this.context)
    if (!isLoggedIn) return
    if (this._isMounted) {
      this.setState({ availablePoint })
    }
  }

  /** 選択済みリスト生成 */
  setSelected() {
    const { storage, isPossibleForPack, belongsToGroup, pack } = this.state
    const selected = []
    if (PURCHASE_TYPE.EPISODE === storage.type) {
      const now = webApp.utils.now(this.context)
      const wSelected = storage.selected
        .filter((id) => !this.isPurchased(id) && this.isOnSale(id, now))
        .map((id) => Number(id))
      selected.push(...wSelected)
    } else if (PURCHASE_TYPE.PACK === storage.type) {
      if (
        (!isPossibleForPack || !isPossibleForPack.status) &&
        webApp.utils.isOnSale(this.context, pack)
      ) {
        selected.push(...storage.selected.map((id) => Number(id)))
      }
    } else if (PURCHASE_TYPE.PLAN === storage.type) {
      if (!belongsToGroup) {
        selected.push(...storage.selected.map((id) => Number(id)))
      }
    }
    if (this._isMounted) {
      this.setState({ selected })
    }
  }

  /** 購入済みリスト生成 */
  setPurchased() {
    const { episodes } = this.state
    const purchased = episodes.filter((meta) => this.isPurchased(meta.meta_id))
    if (this._isMounted) {
      this.setState({ purchased })
    }
  }

  /** 購入済み判定 */
  isPurchased(metaId) {
    const { howToPlays, isPossibles } = this.state
    const products = _.get(howToPlays, [metaId, 'products']) || []
    const product = products.find(
      (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
    )
    const refId = _.get(product, ['ref_id']) || null
    if (!refId) return false
    const isPurchased = _.get(isPossibles, [refId, 'status']) || false
    return isPurchased
  }

  /** 販売期間判定 */
  isOnSale(metaId, now) {
    const { howToPlays } = this.state
    const products = _.get(howToPlays, [metaId, 'products']) || []
    const product = products.find(
      (p) => p.schema_id === PRODUCT_SCHEMA.SINGLE_STORY.id
    )
    return webApp.utils.isOnSale(this.context, product, now)
  }

  setContent(content) {
    if (this._isMounted) {
      this.setState({ currentContent: content }, () => window.scrollTo(0, 0))
    }
  }

  emitSetState(obj, callback = () => {}) {
    if (this._isMounted) {
      this.setState(obj, () => {
        callback()
      })
    }
  }

  goBack(e) {
    e.preventDefault()
    this.context.history.goBack()
  }

  async afterLogin() {
    const { query } = this.context.routeHandler
    if (query.content !== 'after_login') return

    const { storage, selected } = this.state
    if (!storage) return
    const { type } = storage

    // 購入済みチェック
    if (PURCHASE_TYPE.PLAN === type) {
      const { belongsToGroup } = this.state
      if (belongsToGroup) {
        // 購入済みの場合、完了画面
        this.emitSetState({ alreadyPurchasedAll: true }, () => {
          window.location.href = storage.page
          //webApp.utils.goToProgramLink(this.context, null, null, this.state.course);
        })
      } else {
        // 未購入は支払い画面
        this.context.history.push(
          routes.purchase_license.makePath(
            {},
            { LICENSE: this.state.course.ref_id }
          )
        )
      }
    } else {
      const { selected, purchased, episodes, pack, howToPlays } = this.state
      const existsNotPurchased = selected.find(
        (id) => !purchased.find((p) => p.meta_id === id)
      )
      if (existsNotPurchased) {
        const path = ['infra', 'availablePoint']
        let availablePoint = null
        await this.model.fetch([path]).then((result) => {
          availablePoint = _.get(result, ['json', ...path])
          this.context.updateUserInfo({
            availablePoint
          })
        })

        const totalPrice = this.totalPrice()
        const hasMedal =
          _.get(availablePoint, ['available_point_from_tvasahi_point'], 0) +
          _.get(availablePoint, ['available_point'], 0)
        if (hasMedal < totalPrice) {
          // 残高足りなければメダルチャージ画面
          const ax = new Axios({
            baseURL: '/api/crypto',
            timeout: 3000,
            headers: {},
            // タイムアウトしていないのにタイムアウトエラーがでる対応ととして
            // retryオプションを付ける
            retry: 1
          })
          let data = { contents: [], total: this.totalPrice() }
          if (PURCHASE_TYPE.PACK === type) {
            data.contents.push({
              title: pack.name,
              amount: _.get(pack, ['active_pricing', 'price'])
            })
          } else {
            const selectedMetas = episodes.filter((meta) =>
              selected.includes(meta.meta_id)
            )
            _.forEach(selectedMetas, (meta) => {
              const price = webApp.utils.calcPrice(howToPlays, [meta.meta_id])
              let [metaName, subTitle] = webApp.utils.titles(meta)
              metaName = `${metaName} ${subTitle}`
              data.contents.push({
                title: metaName,
                amount: price
              })
            })
          }
          ax.get('/encrypto', { data: JSON.stringify(data) })
            .then((response) => {
              console.log(`response: ${JSON.stringify(response.data)}`)
              this.context.history.push(
                routes.medal_charge.makePath(
                  {},
                  {
                    content: 'charge',
                    data: response.data.encrypted,
                    src: this.context.routeHandler.url
                  }
                )
              )
            })
            .catch((err) => console.log(JSON.stringify(err)))
        } else {
          // 残高あれば確認画面
          this.setContent(PURCHASE_CONTENTS.CONFIRM)
        }
      } else {
        //  全て購入済みの場合、完了画面
        this.emitSetState({ alreadyPurchasedAll: true }, () => {
          this.setContent(PURCHASE_CONTENTS.COMPLETED)
        })
      }
    }
  }

  totalPrice() {
    const { storage, howToPlays, pack, course, selected } = this.state
    const type = storage.type
    if (PURCHASE_TYPE.EPISODE === type) {
      return webApp.utils.calcPrice(howToPlays, selected) || 0
    }
    if (PURCHASE_TYPE.PACK === type) {
      return _.get(pack, ['active_pricing', 'price']) || 0
    }
    if (PURCHASE_TYPE.PLAN === type) {
      return _.get(course, ['active_pricing', 'price']) || 0
    }
    return null
  }

  renderContents() {
    const {
      storage,
      currentContent,
      episodes,
      howToPlays,
      isPossibles,
      isPossibleForPack,
      pack,
      course,
      availablePoint,
      selected,
      purchased,
      belongsToGroup,
      alreadyPurchasedAll,
      paymentMethod,
      result
    } = this.state

    switch (currentContent) {
      case PURCHASE_CONTENTS.SELECT:
        if (!storage) return <NotSelected />
        return (
          <Select
            type={storage.type}
            episodes={episodes}
            howToPlays={howToPlays}
            isPossibleForPack={isPossibleForPack}
            pack={pack}
            course={course}
            availablePoint={availablePoint}
            selected={selected}
            purchased={purchased}
            belongsToGroup={belongsToGroup}
            setContent={this.setContent}
            emitSetState={this.emitSetState}
          />
        )
      case PURCHASE_CONTENTS.LOGIN:
        return (
          <Login
            type={storage.type}
            selected={selected}
            purchased={purchased}
            setContent={this.setContent}
            emitSetState={this.emitSetState}
            getMaterial={this.getMaterial}
            getBelonging={this.getBelonging}
          />
        )
      case PURCHASE_CONTENTS.CONFIRM:
        return (
          <Confirm
            type={storage.type}
            episodes={episodes}
            howToPlays={howToPlays}
            isPossibles={isPossibles}
            pack={pack}
            course={course}
            availablePoint={availablePoint}
            selected={selected}
            paymentMethod={paymentMethod}
            setContent={this.setContent}
            emitSetState={this.emitSetState}
          />
        )
      case PURCHASE_CONTENTS.COMPLETED:
        return (
          <Completed
            result={result}
            type={storage.type}
            howToPlays={howToPlays}
            purchased={purchased}
            selected={storage.selected}
            srcPage={storage.page}
            alreadyPurchasedAll={alreadyPurchasedAll}
            belongsToGroup={belongsToGroup}
            pack={pack}
            course={course}
            availablePoint={availablePoint}
          />
        )
      case PURCHASE_CONTENTS.CHARGE_COMPLETED:
        // return <ChargeCompleted />;
        return <PurchaseResultContent setContent={this.setContent} />

      // 基盤提供画面遷移なので通り得ない
      case PURCHASE_CONTENTS.PAYMENT:
        // return <Payment setContent={this.setContent} emitSetState={this.emitSetState} />;
        return null
      case PURCHASE_CONTENTS.CHARGE:
        return <MedalChargeContent setContent={this.setContent} />
      case PURCHASE_CONTENTS.LOADING:
        return (
          <div className="mdspinner">
            <MDSpinner size={96} />
          </div>
        )
      default:
        return null
    }
  }

  render() {
    return (
      <div className="common-wrapper">
        <HeaderNewsComponent />
        <SpSubNavigation spOff />

        {this.renderContents()}

        <Footer className="mt0" />
      </div>
    )
  }
}
