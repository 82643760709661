import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import styled from 'styled-components'
import routes from '../../../routes'
import webApp from '../../../../exdio/utils/exdioWebAppUtils'
import { MYPAGE_CONTENTS } from '../../../../../constants/app'

/* components */
import { Link } from '../../../../../sketch-platform/ui/routing'

/* style */
import { mediaQuery, fixColors } from '../../../../exdio/components/style'

/** マイリスト アラート 追加/削除  */
const Alert = (
  { stateIsShow = [false, () => {}], isAdded = false, ...props },
  context
) => {
  const [isShow, setIsShow] = stateIsShow
  const isApp = webApp.utils.isApp(context)
  const content = get(MYPAGE_CONTENTS, [[isApp ? 'APPTAB' : 'TAB'], 'MYLIST'])
  const myListRoute = get(routes, [isApp ? 'app_mypage' : 'mypage'])

  return (
    <StyledDiv isShow={isShow} {...props}>
      <StyledLink route={myListRoute} state={{ content }}>
        {isAdded ? 'マイリストに追加しました' : 'マイリストから削除しました'}
      </StyledLink>
      <StyledButton type="button" onClick={() => setIsShow(false)}>
        <img
          src="/images/exdio/renewal/icon_notification_close_btn.svg"
          width="9"
          height="9"
          alt="閉じる"
        />
      </StyledButton>
    </StyledDiv>
  )
}

export default Alert

Alert.propTypes = {
  stateIsShow: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  ),
  isAdded: PropTypes.bool
}

Alert.contextTypes = {
  routeHandler: PropTypes.shape({
    url: PropTypes.string
  })
}

const StyledDiv = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isShow'].includes(prop)
})`
  width: 375px;
  background-color: ${fixColors.colorMain};
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr auto;
  position: fixed;
  top: 85px;
  right: 10px;
  z-index: ${({ isShow }) => (isShow ? 22 : -1)};
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  visibility: ${({ isShow }) => (isShow ? null : 'hidden')};
  transition: z-index 1s, opacity 1s;

  ${mediaQuery()} {
    max-width: 100%;
    position: fixed;
    top: auto;
    right: 0;
    bottom: 10px;
    z-index: ${({ isShow }) => (isShow ? 21 : -1)};
  }
`

const StyledLink = styled(Link)`
  padding: 15px 12px;
  color: ${fixColors.colorFont};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2;
`

const StyledButton = styled.button`
  padding: 0;
  width: 44px;
  height: 100%;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`
