import styled from 'styled-components'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

export const StyledLink = styled(Link)`
  margin: auto;
  height: 24px;
  opacity: ${({ hover }) => (hover ? '1' : '0')};
  position: absolute;
  top: 120px;
  bottom: 0;
  color: #3b4148;
  font-size: 2.4rem;
  transition: opacity 0.2s;
`

export const StyledLink1 = styled(StyledLink)`
  left: -43px;
  &::before {
    font-size: 40px;
    font-family: 'Material Icons';
    content: '\\e314';
  }
`

export const StyledLink2 = styled(StyledLink)`
  right: -43px;
  &::before {
    font-size: 40px;
    font-family: 'Material Icons';
    content: '\\e315';
  }
`
