import React from 'react'
import styled from 'styled-components'
import { mediaQuery } from '../../../../style'
import Link from '../../../../../../../sketch-platform/ui/routing/Link'

/** Section7｜バナーリスト */
const BannerList = () => {
  const bannerList = [
    {
      href: 'https://store.line.me/stickershop/product/23939530',
      src: '/images/exdio/renewal/gariben_daigaku/ad/banner_line.webp',
      alt: 'ガリベンガーV新LINEスタンプ発売'
    },
    {
      href: 'https://www.youtube.com/channel/UCzuEc7Nsm9GtMX4yuzjSqgA',
      src: '/images/exdio/renewal/gariben_daigaku/ad/banner1.webp',
      alt: '番組公式YouTubeアカウント「謎解き戦士！ガリベンガーV」'
    },
    {
      href: 'https://www.tv-asahi.co.jp/garibenv/',
      src: '/images/exdio/renewal/gariben_daigaku/ad/banner2.webp',
      alt: '謎解き戦士ガリベンガーV番組公式サイト'
    },
    {
      href: 'https://www.tiktok.com/@gariben_univ',
      src: '/images/exdio/renewal/gariben_daigaku/ad/banner_tiktok.webp',
      alt: '公式TikTokアカウント「私立ガリベン大学」'
    }
  ]

  return (
    <ul>
      {bannerList.map((banner) => {
        return (
          <StyledLi key={banner.alt}>
            <StyledLink href={banner.href} target="_blank">
              <StyledImg
                src={banner.src}
                alt={banner.alt}
                width="440"
                height="100"
                loading="lazy"
              />
            </StyledLink>
          </StyledLi>
        )
      })}
    </ul>
  )
}

export default BannerList

const StyledLi = styled.li`
  &:not(:first-child) {
    margin-top: 30px;

    ${mediaQuery()} {
      margin-top: 10px;
    }
  }
`

const StyledLink = styled(Link)`
  width: 100%;
  display: inline-block;
  transition: opacity 0.4s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`

const StyledImg = styled.img`
  width: 100%;
`
