import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** 詳細画面:サイドナビ:パレットタブコンポーネント */
export default class SidePaletteTab extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.any).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any)
  };

  static defaultProps = {
    title: '',
    style: {}
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      currentIdx: 0
    };

    this.onClickTab = this.onClickTab.bind(this);
  }

  onClickTab(idx, e) {
    e.preventDefault();
    this.setState({ currentIdx: idx });
  }

  render() {
    const { tabs, children, title, style } = this.props;
    const { currentIdx } = this.state;
    return (
      <div className="common-switching-box" style={style}>
        <header className="switching-header">
          <h3>{title}</h3>
          <ul className="switching-nav">
            {tabs.map((tab, idx) => (
              <li key={tab} className={idx === currentIdx ? 'current' : ''}>
                <a href="#" onClick={e => this.onClickTab(idx, e)}>
                  {tab}
                </a>
              </li>
            ))}
          </ul>
        </header>
        <div className="switching-content">{children[currentIdx]}</div>
      </div>
    );
  }
}
