import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import webApp from '../../../utils/exdioWebAppUtils'
import window from 'global'
import Footer from '../../../../common/components/Footer'
import DFPBanner from '../../../../common/components/DFPBanner'
import PackList from '../details/PackList'
import { FAVORITE_TYPE } from '../../../../../constants/app'
import CastInfo from '../details/CastInfo'
import SideTab from '../details/SideTab'
import Caption from '../details/Caption'
import AddButtonBlock from '../details/AddButtonBlock'
import SideRecommend from '../details/SideRecommend'
import HtmlSnippet from '../../HtmlSnippet'
import SpSubNavigation from '../../../../common/components/renewal/SpSubNavigation'
import Header from '../../../../common/components/Header'
import { createGlobalStyle } from 'styled-components'
import SwitchableListGrid from '../../../../common/components/renewal/SwitchableListGrid'
import SwitchableListGridAndFilter from '../../../../common/components/renewal/SwitchableListGridAndFilter'
import HeaderNewsComponent from '../HeaderNewsComponent'
import ListHeadMeta from '../details/ListHeadMeta'
import TPS from '../TPS'
import { mediaQuery } from '../../style'

/** 番組ページ デフォルトテンプレート */
export default class ProgramDefault extends Component {
  static propTypes = {
    /** 動画のシリーズID */
    seriesId: PropTypes.string,
    /** 動画のシーズンID */
    seasonId: PropTypes.string,
    /** 動画のメタ情報 */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }).isRequired,
    /** 関連動画情報 */
    episodes: PropTypes.arrayOf(PropTypes.object),
    /** パック販売情報 */
    products: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        original_price: PropTypes.number,
        active_pricing: PropTypes.shape({
          price: PropTypes.number,
          unit: PropTypes.string
        })
      })
    ),
    /** episodesのhowToPlay情報 */
    howToPlays: PropTypes.object,
    /** 最新話の動画ステータス */
    latestEpisodeStatus: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      limitDate: PropTypes.string
    })
  }

  static defaultProps = {
    seriesId: '',
    seasonId: '',
    episodes: [],
    products: [],
    howToPlays: {},
    latestEpisodeStatus: {
      isFree: null,
      isNotFree: null,
      isPurchased: null,
      isNotPurchased: null,
      isInCourse: null,
      isNotInCourse: null,
      limitDate: null
    }
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    falcorModel: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.model = context.falcorModel.batch(100)
    this.config = this.context.models.config.data
    this.state = {
      isSp: window.matchMedia('screen and (max-width: 1024px)').matches,
      pagerOptions: {
        // ページャーの設定
        episodesPerPages: 12,
        range: 2
      }
    }
    this.onResizeHandler = this.onResizeHandler.bind(this)

    // AdUnitの為genreの先頭の値を取得
    if (props.meta.values.evis_Genre) {
      var meta_genre_id = props.meta.values.evis_Genre[0]
      this.genre = ''
      if (this.config.genre_maps[meta_genre_id]) {
        if (this.config.genre_maps[meta_genre_id].typeName) {
          this.genre = this.config.genre_maps[meta_genre_id].typeName
        }
      }
    } else {
      var evis_genre_data = props.meta.values.parents_series.evis_Genre
      if (evis_genre_data && typeof evis_genre_data[0].id != 'undefined') {
        if (this.config.genre_maps[evis_genre_data[0].id]) {
          this.genre = this.config.genre_maps[evis_genre_data[0].id].typeName
        }
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResizeHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeHandler)
  }

  /* 番組別お知らせの表示 */
  renderNotice() {
    const { meta } = this.props
    const notice = {
      text: _.get(meta, ['values', 'notice']),
      hyperlink: _.get(meta, ['values', 'notice_hyperlink']),
      start_at: _.get(meta, ['values', 'notice_publish_start_at']),
      end_at: _.get(meta, ['values', 'notice_publish_end_at'])
    }
    const now = Date.now()

    if (
      !notice.text ||
      now < Date.parse(notice.start_at) ||
      Date.parse(notice.end_at) < now
    ) {
      return null
    }

    const html = (
      <dl className="c-program_notice">
        <dt className="c-program_notice__term">お知らせ</dt>
        <dd className="c-program_notice__desc">{notice.text}</dd>
      </dl>
    )

    return notice.hyperlink ? (
      <a href={notice.hyperlink} className="c-program_notice__wrapper">
        {html}
      </a>
    ) : (
      <div className="c-program_notice__wrapper">{html}</div>
    )
  }

  onResizeHandler() {
    const isSp = window.matchMedia('screen and (max-width: 1024px)').matches
    if (isSp !== this.state.isSp) {
      this.setState({ isSp })
    }
  }

  render() {
    const {
      meta,
      otherSeasons,
      episodes,
      products,
      howToPlays,
      displayModes,
      latestEpisodeStatus,
      loaded
    } = this.props

    const { isSp, pagerOptions } = this.state

    const disp_order = _.get(meta, ['values', 'disp_order'])

    const sortedEpisodes = episodes.sort((a, b) => {
      // 配信開始日時
      if (a.delivery_start_at < b.delivery_start_at) return 1
      if (a.delivery_start_at > b.delivery_start_at) return -1

      // メタスキーマIDの昇順(無料, 有料の順) -> 固定
      if (a.meta_schema_id < b.meta_schema_id) return -1
      if (a.meta_schema_id > b.meta_schema_id) return 1

      // エピソード番号
      if (
        Number(a.values.avails_EpisodeNumber) <
        Number(b.values.avails_EpisodeNumber)
      )
        return 1
      if (
        Number(a.values.avails_EpisodeNumber) >
        Number(b.values.avails_EpisodeNumber)
      )
        return -1

      // ID
      if (a.id < b.id) return 1
      if (a.id > b.id) return -1

      return 0
    })

    const latestEpisode = sortedEpisodes[0]
    const latestEpisodeCourses =
      _.get(howToPlays, [latestEpisode, 'meta_id', 'courses']) || []
    const courseId = latestEpisodeCourses.length
      ? latestEpisodeCourses[0].course_id
      : null

    const thumbnailUrl =
      webApp.utils.customSizeImageUrl(meta.thumbnail_url, 'large') ||
      this.config.default_thumbnail
    const actors = (meta.values && meta.values.evis_SeasonActors) || null
    const directors = (meta.values && meta.values.evis_SeasonDirectors) || null
    const producers = (meta.values && meta.values.evis_SeasonProducers) || null
    const writers = (meta.values && meta.values.evis_SeasonWriters) || null
    const productions =
      (meta.values && meta.values.evis_SeasonProductions) || null
    const copyRight =
      (meta && meta.values && meta.values.evis_Copyright) || null
    const bannerId1 = _.get(meta, ['values', 'banner_1'])
    const bannerId2 = _.get(meta, ['values', 'banner_2'])
    const schemeId = meta.meta_schema_id

    const [metaName, subTitle] = webApp.utils.titles(meta)
    const logirlId = ['development', 'staging'].includes(process.env.NODE_ENV)
      ? 4
      : 3 //logirl番組だけはソート・フィルタ機能付与
    const officialUrl =
      meta.schema_id === 6 ? meta.values.evis_OfficialSiteURL : null

    return (
      <React.Fragment>
        <Header />
        <TPS />
        <div className="common-wrapper">
          <HeaderNewsComponent />
          <SpSubNavigation spOff />
          {!isSp && this.renderNotice()}

          <div className="c-headMeta">
            <div className="c-headMeta-metaBox">
              <div className="c-headMeta-metaBox-art">
                <div className="c-headMeta-metaBox-art-inBox">
                  <div className="c-headMeta-metaBox-art-inBox-artwork">
                    <img
                      src={thumbnailUrl}
                      className="c-headMeta-metaBox-art-inBox-artwork-img"
                    />
                  </div>
                </div>

                {isSp && this.renderNotice()}

                {/* for PC */}
                <AddButtonBlock
                  favoriteType={FAVORITE_TYPE.META}
                  favoriteId={meta.meta_id}
                  title={metaName}
                  officialUrl={officialUrl}
                />
              </div>

              <div className="c-headMeta-metaBox-info">
                <h2 className="c-headMeta-metaBox-info-title">{metaName}</h2>

                <Caption
                  caption={meta.values && meta.values.evis_SeasonLongSynopsis}
                  className="c-headMeta-metaBox-info-caption"
                />

                {/* for SP */}
                <AddButtonBlock
                  favoriteType={FAVORITE_TYPE.META}
                  favoriteId={meta.meta_id}
                  title={metaName}
                  officialUrl={officialUrl}
                />
              </div>
            </div>
          </div>

          <div className="c-listMeta">
            <div className="c-listMeta-inBox">
              <div className="c-listMeta-inBox-main">
                {latestEpisode && schemeId !== 6 && (
                  <ListHeadMeta
                    meta={meta}
                    howToPlays={howToPlays}
                    displayMode={displayModes[latestEpisode.meta_id]}
                    episode={latestEpisode}
                    latestEpisodeStatus={latestEpisodeStatus}
                  />
                )}

                <PackList products={products} showMedal />
                {bannerId1 && <HtmlSnippet snippetId={bannerId1} />}
                {courseId === logirlId ? (
                  <SwitchableListGridAndFilter
                    key="episode"
                    episodes={episodes}
                    howToPlays={howToPlays}
                    placeholder="検索"
                    showNew
                    showChecked
                    onlySubTitle
                    listType="default"
                    disp_order={disp_order}
                    pagerOptions={pagerOptions}
                    courseId={courseId}
                    loaded={loaded}
                  />
                ) : (
                  <SwitchableListGrid
                    key="episode"
                    episodes={episodes}
                    howToPlays={howToPlays}
                    placeholder="検索"
                    showNew
                    showChecked
                    onlySubTitle
                    listType="default"
                    disp_order={disp_order}
                    pagerOptions={pagerOptions}
                    loaded={loaded}
                  />
                )}
                <CastInfo
                  actors={actors}
                  directors={directors}
                  producers={producers}
                  writers={writers}
                  productions={productions}
                  copyRight={copyRight}
                  isPadding
                />
              </div>
              <div className="c-listMeta-inBox-sub">
                <div className="common-box-ad-rectangle">
                  <DFPBanner position="foot" meta_id="" genre={this.genre} />
                </div>
                {bannerId2 && <HtmlSnippet snippetId={bannerId2} />}
                <SideTab otherSeasons={otherSeasons} />
                <HtmlSnippet
                  snippetId={this.config.extras.common_banner_snippet_key}
                />
                <SideRecommend
                  typeName={this.config.recommend.type_name.view}
                  spotName={this.config.recommend.spot_name.program}
                />
              </div>
            </div>
          </div>

          <Footer className="mt0" courseId={courseId} />
        </div>
        <GlobalStyle />
      </React.Fragment>
    )
  }
}

const GlobalStyle = createGlobalStyle`
  #point-header + .common-wrapper {
    ${mediaQuery()} {
      margin-top: 15px;
    }
  }
`
