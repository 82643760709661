import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import webApp from '../../../utils/exdioWebAppUtils';

/** 港時間 スライダー内 タイトル コンポーネント */
export default class SliderTitle extends Component {
  static propTypes = {
    // 主情報
    /** メタ */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.shape({
        evis_EpisodeLongSynopsis: PropTypes.string,
        evis_SeasonLongSynopsis: PropTypes.string,
        evis_SeriesLongSynopsis: PropTypes.string,
        parents_series: PropTypes.shape({
          avails_SeriesTitleDisplayUnlimited: PropTypes.string
        }),
        parents_season: PropTypes.shape({
          avails_SeasonTitleDisplayUnlimited: PropTypes.string
        }),
        avails_EpisodeTitleDisplayUnlimited: PropTypes.string,
        ex_templateId: PropTypes.number
      }).isRequired,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }),
    /** パック商品 */
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired
    }),
    /** 月額見放題パックコース */
    course: PropTypes.shape({
      name: PropTypes.string.isRequired,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object.isRequired
    }),

    // イベントハンドラ
    // 各onClickは引数に(event)を渡して呼び出される
    onClickTitle: PropTypes.func,
    /** 強制的にタイトルを指定する場合に設定 */
    title: PropTypes.string,
  };

  static defaultProps = {
    meta: null,
    product: null,
    course: null,
    onClickTitle: null,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  /** タイトル */
  title() {
    const { meta, product, course, title } = this.props;
    if (title) return [title, ''];
    return webApp.utils.titles(meta, product, course);
  }

  handleClick(propFunc, e) {
    e.preventDefault();
    if (propFunc) {
      // サムネイル等、子要素のイベントハンドラが設定されている場合は要素全体のイベントハンドラは実行しない
      e.stopPropagation();
      propFunc(e);
    }
  }

  render() {
    const { onClickTitle } = this.props;
    const [title, subTitle] = this.title();

    return (
      <span>{subTitle}</span>
    );
  }
}
