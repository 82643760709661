import styled, { createGlobalStyle, css } from 'styled-components'
import { mediaQuery, fixColors } from '../../style'
import { Link } from '../../../../../sketch-platform/ui/routing'
import Header from './Header'
import Player from './Player'
import EpisodeList from './EpisodeList'
import Tabs from './Tabs'
import Comment from '../comment'
import Chat from './Chat'
import EpisodeFooter from '../../../../common/components/EpisodeFooter'
import Heading1 from './Heading1'
import { StyledFilterSort as FilterSort } from '../EpisodeGariben/style'

export const colors = {
  pink: '#ff009b',
  yellow: '#ffff01',
  green: '#4ad289',
  blue: '#0baffd',
  black: '#333333'
}

const playerHeight = '(100vw * 9 / 16)'
const tabsHeight = '45px'

export const GlobalStyle = createGlobalStyle`
body {
  ${mediaQuery(320)} {
    zoom: normal;
  }

  .player-modal-wrapper {
    position: absolute;
    display: block;

    .watch {
      width: 100%;
    }
  }
}

.player-modal-wrapper {
  position: absolute;
  display: block;

  .watch {
    width: 100%;
  }
}

.c-pageTop {
  ${mediaQuery()} {
    display: none;
  }
}
`

export const StyledHeader = styled(Header)`
  grid-area: header;
`

export const StyledPlayer = styled(Player)`
  grid-area: player;
  z-index: 1;
  touch-action: none;

  ${mediaQuery()} {
    height: calc(${playerHeight});
    position: sticky;
    top: 0;
  }
`

export const StyledEpisodeList = styled(EpisodeList)``

export const StyledLink1 = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['bgColor', 'color'].includes(prop)
})`
  padding: 17px 0;
  background-color: ${({ bgColor }) => bgColor || colors.pink};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color || '#fff'};
  font-size: 1.7rem;
  font-weight: 600;
  transition: opacity 0.2s;

  &:link,
  &:visited {
    color: ${({ color }) => color || '#fff'};
  }

  &:hover {
    ${mediaQuery('sm', 'min')} {
      opacity: 0.7;
    }
  }

  ${mediaQuery()} {
    padding: 15px 0;
    font-size: 1.6rem;
  }
`

export const StyledLink2 = styled(Link)``

export const StyledWrapper = styled.div``

export const StyledContainer = styled.div`
  display: grid;
  grid:
    '.      header header .'
    '.      player side   .' minmax(303px, auto)
    '.      main   side   .'
    'div1   div1   div1   div1'
    'footer footer footer footer'
    / minmax(0, auto) minmax(auto, 850px) minmax(320px, 400px) minmax(0, auto);
  gap: 0 30px;

  ${mediaQuery()} {
    /* height: calc(var(--vh, 1vh) * 100 - (${playerHeight}));
    height: calc(100svh - (${playerHeight})); */
    display: block;
    /* overflow-y: scroll; */
  }
`

export const StyledMain = styled.main`
  grid-area: main;
  padding-bottom: 80px;

  ${mediaQuery()} {
    padding-bottom: 0;
  }
`

export const StyledButton1 = styled.button`
  margin-top: 30px;
  padding: 0;
  border: 0;
  background: none;
  transition: opacity 0.2s;

  ${mediaQuery()} {
    display: none;
  }

  &:hover {
    opacity: 0.7;
  }

  img {
    margin: 0;
    width: 100%;
  }
`

export const StyledComment = styled(Comment).withConfig({
  shouldForwardProp: (prop) => !['show'].includes(prop)
})`
  border: 3px solid #dbdbdb;

  ${mediaQuery()} {
    border-width: 0;
    display: ${({ show }) => (show ? null : 'none')};
  }

  &:not(:first-child) {
    margin-top: 20px;

    ${mediaQuery()} {
      margin-top: 0;
    }
  }
`

export const StyledChat = styled(Chat).withConfig({
  shouldForwardProp: (prop) => !['show'].includes(prop)
})`
  overscroll-behavior: none;

  ${mediaQuery()} {
    height: calc(var(--vh, 1vh) * 100 - (${playerHeight} + ${tabsHeight}));
    height: calc(100svh - (${playerHeight} + ${tabsHeight}));
    display: ${({ show }) => (show ? null : 'none')};
  }
`

export const StyledSide = styled.aside.withConfig({
  shouldForwardProp: (prop) => !['currentTabId'].includes(prop)
})`
  grid-area: side;
  padding-bottom: 80px;

  ${mediaQuery()} {
    margin-top: 40px;
    padding: 0 10px 30px;
    position: relative;
    z-index: ${({ currentTabId }) => (currentTabId === 'chat' ? null : 0)};
  }

  &:not(:first-child) {
    ${mediaQuery()} {
      margin-top: 40px;
    }
  }
`

export const StyledDiv1 = styled.div`
  grid-area: div1;
  padding-top: 30px;
  background-color: ${fixColors.colorBg};
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledLink1} {
    padding-right: 45px;
    padding-left: 45px;
    border: 1px solid #b7b700;
  }
`

export const StyledFilterSort = styled(FilterSort)`
  &:not(:first-child) {
    margin-top: 20px;
  }

  .c-card-inBox-meta-price {
    display: none;
  }
`

export const StyledP = styled.p`
  margin-top: 10px;

  ${mediaQuery()} {
    margin-top: 30px;
  }
`

export const StyledSection1 = styled.section`
  padding-top: 0;

  &:not(:first-child) {
    margin-top: 40px;
  }
`

export const StyledSection2 = styled.section`
  ${mediaQuery()} {
    padding: 0 10px;
  }

  &:not(:first-child) {
    margin-top: 60px;

    ${mediaQuery()} {
      margin-top: 30px;
    }
  }

  ${StyledEpisodeList} {
    &:not(:first-child) {
      margin-top: 30px;

      ${mediaQuery()} {
        margin-top: 15px;
      }
    }
  }

  ${StyledLink1} {
    margin: 0 auto;
    width: 410px;

    ${mediaQuery()} {
      margin: 0;
      width: auto;
    }

    &:not(:first-child) {
      margin-top: 40px;

      ${mediaQuery()} {
        margin-top: 30px;
      }
    }
  }
`

export const StyledSection3 = styled.section`
  ${mediaQuery()} {
    padding: 0 10px;
  }

  &:not(:first-child) {
    margin-top: 60px;

    ${mediaQuery()} {
      margin-top: 30px;
    }
  }

  ${Heading1} {
    display: flex;
    align-items: center;

    ${StyledLink2} {
      margin-left: auto;
      padding-right: 36px;
      position: relative;
      color: ${fixColors.colorFont};
      font-size: 1.4rem;
      font-weight: 600;
      transition: opacity 0.3s;

      ${mediaQuery()} {
        padding-right: 16px;
        font-size: 1.1rem;
      }

      &:hover {
        opacity: 0.7;
      }

      &:visited {
        color: ${fixColors.colorFont};
      }

      &::after {
        margin: auto 0;
        width: 8px;
        height: 8px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        position: absolute;
        top: 0;
        right: 12px;
        bottom: 0;
        transform: rotate(-45deg);
        content: '';

        ${mediaQuery()} {
          right: 0;
        }
      }
    }
  }

  ${StyledEpisodeList} {
    &:not(:first-child) {
      margin-top: 30px;

      ${mediaQuery()} {
        margin-top: 15px;
      }
    }
  }

  ${StyledLink1} {
    margin: 0 auto;
    width: 410px;

    ${mediaQuery()} {
      margin: 0;
      width: auto;
    }

    &:not(:first-child) {
      margin-top: 40px;

      ${mediaQuery()} {
        margin-top: 30px;
      }
    }
  }
`

export const StyledEpisodeFooter = styled(EpisodeFooter)`
  grid-area: footer;
  margin-top: 0;
`

export const StyledTabs = styled(Tabs).withConfig({
  shouldForwardProp: (prop) => !['displayState, currentTabId'].includes(prop)
})`
  position: sticky;
  top: calc(${playerHeight});
  z-index: 1;
  transition: opacity 0.2s;
  touch-action: none;

  ${({ displayState }) => {
    switch (displayState) {
      case 'hide':
        return css`
          z-index: -1;
          opacity: 0;
        `
      case 'show':
      default:
        return css`
          opacity: 1;
        `
    }
  }}

  ${mediaQuery('sm', 'min')} {
    display: none;
  }

  /** タブ部分をスクロールすると表示されてしまうため、display: noneで消す */
  & ~ ${StyledMain} {
    display: ${({ currentTabId }) =>
      currentTabId === 'chat' ? 'none !important' : null};
  }
  & ~ ${StyledSide} {
    ${({ currentTabId }) => {
      if (currentTabId === 'chat') {
        return css`
          margin: 0;
          padding: 0;
          line-height: 0;
        `
      }
      return null
    }};

    & > :not(${StyledChat}) {
      display: ${({ currentTabId }) =>
        currentTabId === 'chat' ? 'none !important' : null};
    }
  }

  & ~ ${StyledDiv1}, & ~ ${StyledEpisodeFooter} {
    display: ${({ currentTabId }) =>
      currentTabId === 'chat' ? 'none !important' : null};
  }
`
