import styled from 'styled-components'
import { mediaQuery, breakPoints } from '../../../../style'

export const StyledButton = styled.button`
  margin: auto 0;
  padding: 0 30px;
  height: 40px;
  background-color: #e3e3e3;
  border-width: 0;
  border-radius: 4px;
  transition: opacity 0.2s ease-in-out;

  ${mediaQuery('sm', 'min')} {
    &:hover {
      opacity: 0.7;
    }
  }

  ${mediaQuery()} {
    padding: 0 15px 0 10px;
    height: 20px;
  }
`

export const StyledSpan = styled.span`
  padding-left: 25px;
  display: block;
  position: relative;
  color: #343434;
  font-size: 1.5rem;
  font-weight: bold;

  ${mediaQuery()} {
    padding-left: 15px;
    font-size: 1.2rem;
  }

  &::before {
    margin: auto 0;
    width: 10px;
    height: 10px;
    border-width: 3px 0 0 3px;
    border-style: solid;
    border-color: #343434;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transform: rotate(-45deg);
    content: '';

    ${mediaQuery()} {
      width: 7px;
      height: 7px;
      border-width: 1px 0 0 1px;
    }
  }
`
