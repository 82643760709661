import React from 'react'
import PropTypes from 'prop-types'
import useIsMounted from '../../../../../../hooks/useIsMounted'

/* style */
import { StyledButton, StyledSpan } from './style'

const Button = ({ onClick = () => {}, children, ...props }) => {
  const isMounted = useIsMounted()

  if (!isMounted) return null

  return (
    <StyledButton type="button" onClick={onClick} {...props}>
      <StyledSpan>{children}</StyledSpan>
    </StyledButton>
  )
}

export default Button

Button.propTypes = {
  onClick: PropTypes.func
}
