import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledWrapper, StyledBackground } from './style'

const Modal = ({
  isOpen = false,
  onClickBg = () => {},
  children,
  ...props
}) => {
  return (
    <StyledWrapper isOpen={isOpen} {...props}>
      {children}
      <StyledBackground onClick={onClickBg} />
    </StyledWrapper>
  )
}

export default Modal

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClickBg: PropTypes.func
}
