import React, { memo } from 'react'
import styled from 'styled-components'
import Link from '../../../../../sketch-platform/ui/routing/Link'
import { mediaSp } from './styles'

/* images */
import Img1 from './images/bnr_buriburi.jpg'
import Img4 from './images/bnr_shinchan.jpg'

/** しんちゃん 番組バナーリスト */
const ProgramBanners = ({ ...props }) => {
  const envKey = ['development', 'staging'].includes(process.env.NODE_ENV)
    ? 'stg'
    : 'prd'
  return (
    <StyledUl {...props}>
      <StyledLi>
        <StyledLink href="https://www.tv-asahi.co.jp/redirect/?site=shinchan_buriburizaemon">
          <img
            src={Img1}
            width="250"
            height="80"
            alt="ぶりぶりざえもん特集"
            loading="lazy"
          />
        </StyledLink>
      </StyledLi>
      <StyledLi>
        <StyledLink href="https://www.tv-asahi.co.jp/redirect/?site=shinchan_official">
          <img
            src={Img4}
            width="250"
            height="80"
            alt="クレヨンしんちゃん公式サイト"
            loading="lazy"
          />
        </StyledLink>
      </StyledLi>
    </StyledUl>
  )
}

export default memo(ProgramBanners)

const StyledUl = styled.ul`
  display: flex;

  @media ${mediaSp} {
    display: block;
  }
`

const StyledLi = styled.li`
  &:not(:first-child) {
    margin-left: 20px;

    @media ${mediaSp} {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
`
