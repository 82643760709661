import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import webApp from '../../../../utils/exdioWebAppUtils'
import DescList from './DescList/'
import ThumbnailAndStamp from './ThumbnailAndStamp/'
import {
  StyledCertificateBtn,
  StyledDd,
  StyledDescImgs,
  StyledDiv,
  StyledDl,
  StyledDt
} from './style'

/** ガリベン大学 在学証明書 */
const Certificate = ({ icon = '', point = 0, nickname = '' }) => {
  const isSp = webApp.utils.isSp()

  useEffect(() => {
    window.onbeforeprint = preparePrint // 準備
    window.onafterprint = cleanUpPrint // 片付け

    return () => {
      window.removeEventListener('onbeforeprint')
      window.removeEventListener('onafterprint')
    }
  }, [])

  /** 印刷準備 */
  const preparePrint = () => {
    const pageElm = document.querySelector('#gariben') // ページ全体
    const certificate = document.querySelector('#certificate') // 在学証明書
    const printElm = certificate.cloneNode(true) // 複製した在学証明書
    printElm.setAttribute('id', 'printElm')
    const otherElm = document.querySelectorAll('#gariben > :not(#printElm)') // 印刷しないその他の要素

    pageElm.appendChild(printElm) // 複製した在学証明書をページ下部に配置
    otherElm.forEach((elm) => elm.classList.add('u-hide')) // 非表示用のclassを追加
  }

  /** 印刷片付け */
  const cleanUpPrint = () => {
    const printElm = document.getElementById('printElm') // 複製した在学証明書
    const otherElm = document.querySelectorAll('#gariben > :not(#printElm)') // 印刷しないその他の要素
    otherElm.forEach((elm) => elm.classList.remove('u-hide')) // 非表示用のclassを削除
    printElm.remove() // 複製した在学証明書を削除
  }

  return (
    <>
      <StyledDl id="certificate">
        <StyledDt>
          <img
            src="/images/exdio/renewal/gariben_daigaku/components/certificate-ttl.webp"
            alt="私立ガリベン大学 在学証明書"
          />
        </StyledDt>
        <StyledDd>
          <ThumbnailAndStamp icon={icon} />
          <StyledDiv>
            <DescList nickname={nickname} point={point} />
            <StyledDescImgs />
          </StyledDiv>
        </StyledDd>
      </StyledDl>
      {!isSp && <StyledCertificateBtn />}
    </>
  )
}

export default Certificate

Certificate.propTypes = {
  /** 証明書写真のパス */
  icon: PropTypes.string,
  /** 保有単位ポイント */
  point: PropTypes.number,
  /** ユーザーのニックネーム */
  nickname: PropTypes.string
}
