import React from 'react'
import PropTypes from 'prop-types'

/* style */
import { StyledListItem } from './style'

const Twitter = ({ isApp = false, linkUrl = '', ...props }) => {
  const windowOpenOption = [
    'tweetwindow',
    'width=650, height=450, personalbar=no, toolbar=no, scrollbars=yes, resizable=yes, left=(window.screen.width-300)/2, top=(window.screen.height-300)/2'
  ]

  return (
    <StyledListItem
      src={'/images/exdio/renewal/share_btn_twitter.svg'}
      alt={'Twitter'}
      isApp={isApp}
      linkUrl={linkUrl}
      windowOpenOption={windowOpenOption}
      {...props}
    />
  )
}

export default Twitter

Twitter.prototype = {
  isApp: PropTypes.bool,
  linkUrl: PropTypes.string
}
