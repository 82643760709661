import React from 'react'

/* style */
import { StyledButton } from './styles'

/** PlanShinchan Button3 */
const Button3 = ({ ...props }) => {
  return (
    <StyledButton type="button" {...props}>
      <img
        src="/douga_mv/shinchan/svod/images/selection/icon_close.svg "
        width="38"
        height="39"
        alt="close"
      />
    </StyledButton>
  )
}

export default Button3
