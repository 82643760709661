import styled from 'styled-components'
import List2 from './List2/'
import List3 from './List3/'
import { mediaQuery, breakPoints } from '../../../style'

export const StyledNav = styled.nav`
  ${mediaQuery('sm', 'min')} {
    display: none;
  }
`

export const StyledDiv1 = styled.div`
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.4s ease-in-out, z-index 0.4s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    z-index: 99
    `}
`

export const StyledList2 = styled(List2)`
  margin-top: 50px;
`

export const StyledList3 = styled(List3)`
  margin-top: 45px;
`
