/* style */
import styled, { createGlobalStyle } from 'styled-components'
import { mediaQuery } from '../../../../../exdio/components/style'

export const GlobalStyle = createGlobalStyle`
  body {
    background: url('/images/exdio/renewal/hiramekinotane/bg.png') center top
    repeat;
  }
`

export const StyledDiv1 = styled.div`
  margin: 0 auto;
  overflow: hidden;
  color: rgb(51, 51, 51);
  font-family: 'Kaisei Opti', serif;
  color: #333;
  padding: 0 20px;
  max-width: 1280px;
  margin: 0 auto;

  ${mediaQuery(1320)} {
    padding: 0 20px;
    max-width: 960px;
    margin: 0 auto;
  }

  ${mediaQuery()} {
    width: 100%;
    margin: 0;
    padding: 15px 0;
  }
`

export const StyledDiv2 = styled.div`
  padding: 0 8px;
  font-family: 'Kaisei Opti', serif;
`
