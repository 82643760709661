import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import cn from 'classnames'
import webApp from '../../../utils/exdioWebAppUtils'
import PlayButton from './PlayButton'
import { whatKindOfVideo } from './util'

/** 動画カバー */
export default class CommonVideoArea extends Component {
  static propTypes = {
    /** 動画のmeta情報 */
    meta: PropTypes.shape({
      meta_schema_id: PropTypes.number,
      thumbnail_url: PropTypes.string,
      values: PropTypes.object,
      name: PropTypes.string,
      duration: PropTypes.number,
      delivery_start_at: PropTypes.string,
      delivery_end_at: PropTypes.string
    }),
    /**
     * 動画ステータス
     * @param {number} displayMode - 動画の視聴形態(1-9)
     * @param {string} 1 - FREE
     * @param {string} 2 - TVOD_FREE
     * @param {string} 3 - TVOD_NOT_FREE
     * @param {string} 4 - SVOD_FREE
     * @param {string} 5 - SVOD_NOT_FREE
     * @param {string} 6 - SVOD_AUTH_FREE
     * @param {string} 7 - STVOD_FREE
     * @param {string} 8 - STVOD_TVOD_NOT_FREE
     * @param {string} 9 - STVOD_SVOD_NOT_FREE
     */
    status: PropTypes.shape({
      isFree: PropTypes.bool,
      isNotFree: PropTypes.bool,
      isPurchased: PropTypes.bool,
      isNotPurchased: PropTypes.bool,
      isInCourse: PropTypes.bool,
      isNotInCourse: PropTypes.bool,
      isGeoDeliverable: PropTypes.bool,
      isDeviceNotAvailable: PropTypes.bool,
      limitDate: PropTypes.string,
      isPossible: PropTypes.bool,
      isBelonging: PropTypes.bool
    }),
    /** PlayButtonの描画に使用 */
    product: PropTypes.object,
    /** 動画視聴状況のプログレスバーを表示する（％） */
    progress: PropTypes.number,
    /** 再生ボタンクリック時のアクション */
    onClickPlay: PropTypes.func,
    /** trueの時、ライブ配信動画になる */
    isLive: PropTypes.bool,
    /** 動画の視聴が可能かを判断(trueの時、視聴可能) */
    inViewTerm: PropTypes.bool,
    /** サムネイル画像のaltに設定する値 */
    metaName: PropTypes.string,
    /** サムネイル画像のURL指定 */
    thumbnailUrl: PropTypes.string,
    /** inViewTermがtrueの時 */
    nextPrevText: PropTypes.string,
    /** trueの時、PlayButtonを隠す */
    hidePlayButton: PropTypes.bool,
    /** trueの時、PlayButtonのクリックを許可する */
    permitClickPlayButton: PropTypes.bool,
    /** IOS用のapp-dougaへのリンクに使用 */
    episodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** IOS用のapp-dougaへのリンクに使用 */
    seriesId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** IOS用のapp-dougaへのリンクに使用 */
    seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** 自動再生On/Off */
    auto_start: PropTypes.bool,
    /** 商品タイプ */
    product_type:PropTypes.number,
    /** アプリ用チャンネルID */
    channel:PropTypes.string,
    /** DIOメタID */
    meta_id: PropTypes.number,
    /** ssai(動画広告を挿入する技術)の設定id */
    ssai_ad_config_id:PropTypes.string,
    /** ユーザ識別パラメータ */
    uid:PropTypes.string,
    /** コンテンツ識別パラメータ */
    ga_cid:PropTypes.string,
    /** サブタイトルを表示するか */
    subtitle:PropTypes.bool,
    /** UniversalLinksを使用するか否か */
    useUniversalLinks: PropTypes.bool
  }

  static defaultProps = {
    status: {
      isFree: false,
      isNotFree: false,
      isPurchased: false,
      isNotPurchased: false,
      isInCourse: false,
      isNotInCourse: false,
      isGeoDeliverable: null,
      isDeviceNotAvailable: null,
      limitDate: null,
      isPossible: null,
      isBelonging: null
    },
    product: {},
    progress: 0,
    onClickPlay: () => {},
    isLive: false,
    inViewTerm: false,
    metaName: '',
    thumbnailUrl: '',
    nextPrevText: '',
    hidePlayButton: false,
    permitClickPlayButton: false,
    auto_start: true,
    product_type: 1,
    channel: 'ex',
    meta_id: null,
    ssai_ad_config_id: '',
    uid: '',
    ga_cid: '',
    subtitle: false,
    useUniversalLinks: false
  }

  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {}
    this.config = context.models.config.data
    this.getRest = this.getRest.bind(this)
    this.renderPlayableCover = this.renderPlayableCover.bind(this)
    this.renderNotPlayableCover = this.renderNotPlayableCover.bind(this)
    this.ua = navigator.userAgent.toLowerCase()
    this.isIos =
      this.ua.indexOf('iphone') >= 0 ||
      this.ua.indexOf('ipad') >= 0 ||
      (this.ua.indexOf('macintosh') >= 0 && 'ontouchend' in document) ||
      this.ua.indexOf('ipod') >= 0
    this.isApp = webApp.utils.isApp(this.context) // アプリ判別
  }

  componentDidMount() {}

  getRest() {
    const { meta, status } = this.props
    const kindOfVideo = whatKindOfVideo(status.displayMode)
    const showRest = status.isPurchased || kindOfVideo === 'FREE'

    return showRest && webApp.utils.isSp() ? webApp.utils.rest(meta) : null
  }

  /** 動作する動画カバー */
  renderPlayableCover() {
    const {
      status,
      product,
      progress,
      onClickPlay,
      metaName,
      thumbnailUrl,
      hidePlayButton,
      permitClickPlayButton,
      auto_start,
      product_type,
      channel,
      meta_id,
      ssai_ad_config_id,
      uid,
      ga_cid,
      subtitle,
      useUniversalLinks
    } = this.props
    const rest = this.getRest()
    const isApp = webApp.utils.isApp(this.context)
    const isClickable = Boolean(
      status.isFree || status.isPurchased || permitClickPlayButton
    )
    const kindOfVideo = whatKindOfVideo(status.displayMode)
    const isUniversalLinks =
      useUniversalLinks &&
      !this.isApp &&
      this.isIos &&
      (kindOfVideo === 'FREE' || (kindOfVideo === 'TVOD' && status.isPurchased))

    const commonParams =
      'type=play' +
      `&auto_start=${auto_start}` +
      `&product_type=${product_type}` +
      `&channel=${channel}` +
      `&meta_id=${meta_id}` +
      `&ssai_ad_config_id=${ssai_ad_config_id}` +
      `&uid=${uid}` +
      `&cid=${ga_cid}` +
      `&subtitle=${subtitle}` +
      `&return_url=${window.location.href}`

    return (
      <a
        href={
          isUniversalLinks
            ? `${this.config.universal_links_domain}?${commonParams}`
            : ''
        }
        onClick={isUniversalLinks ? false : onClickPlay}
        className={cn({
          clickable: isClickable,
          'not-clickable': !isClickable
        })}
      >
        <PlayButton
          status={status}
          product={product}
          hidePlayButton={hidePlayButton}
        />
        {rest && <div className="remaining-time">{rest}</div>}
        <div className="seekbar">
          {progress > 0 && (
            <div className="seek" style={{ width: `${progress}%` }} />
          )}
        </div>
        <div className="video-area">
          <img src={thumbnailUrl} alt={metaName} width="1080" height="610" />
        </div>
      </a>
    )
  }

  /** 動作しない動画カバー */
  renderNotPlayableCover() {
    const { isLive, thumbnailUrl, nextPrevText } = this.props

    return (
      <a onClick={(e) => e.preventDefault()}>
        <div className="video-area not-clickable">
          <img src={thumbnailUrl} alt="" width="1080" height="610" />
          {!isLive && <div className="mask" />}
          <div className="next-prev-text">{nextPrevText}</div>
        </div>
      </a>
    )
  }

  render() {
    const { inViewTerm } = this.props
    const PlayableCover = this.renderPlayableCover
    const NotPlayableCover = this.renderNotPlayableCover

    return (
      <div className="common-video-area">
        {inViewTerm ? <PlayableCover /> : <NotPlayableCover />}
      </div>
    )
  }
}
