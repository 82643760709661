import styled from 'styled-components'
import { mediaQuery } from '../../../../style'

export const StyledP1 = styled.p`
  font-size: 8px;
`

export const StyledP2 = styled.p`
  font-weight: 600;
`

export const StyledDl = styled.dl`
  display: flex;
  justify-content: space-between;
  padding: 14px;
  background: #fff;

  ${mediaQuery()} {
    display: none;
  }
`

export const StyledDiv = styled.div`
  width: 33%;
  display: grid;
  grid-template-columns: 38.27px 1fr;
  gap: 14px;
  line-height: 1.4;
  font-size: 1rem;
`

export const StyledH4 = styled.h4`
  margin-bottom: 0.5em;
  font-weight: 600;
`

export const StyledP = styled.p`
  line-height: 1.4;
`
