import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

/** 動画、番組リスト要素コンポーネント スケルトンバージョン */
export default class ProgramItemSkeleton extends Component {
  static propTypes = {
    hasThumb: PropTypes.bool,
    titleLength: PropTypes.number,
    textLength: PropTypes.number,
    hasPrice: PropTypes.bool
  };

  static defaultProps = {
    hasThumb: false,
    titleLength: 0,
    textLength: 0,
    hasPrice: false
  };

  constructor(props) {
    super(props)
  }

  render() {
    const { hasThumb, titleLength, textLength, hasPrice } = this.props

    return (
      <div className="c-card--skeleton">
        <figure className="c-card-inBox">
          {hasThumb && (
            <div className="c-card-inBox-art">
              <div className="c-card-inBox-art-artwork">
                <Skeleton variant="rect" />
              </div>
            </div>
          )}

          <figcaption className="c-card-inBox-meta">
            {titleLength > 0 && (
              <div className="c-card-inBox-meta-titles-title">
                <Skeleton variant="rect" count={titleLength} />
              </div>
            )}
            {textLength > 0 && (
              <div className="c-card-inBox-meta-caption">
                <Skeleton variant="rect" count={textLength} />
              </div>
            )}
            {hasPrice && (
              <div className="c-card-inBox-meta-price">
                <Skeleton variant="rect" />
              </div>
            )}
          </figcaption>
          <div className="c-card-inBox-btnPlay">
            <div className="c-card-inBox-btnPlay-link">
              <Skeleton circle={true} width={30} height={30} />
            </div>
          </div>
        </figure>
      </div>
    );
  }
}
