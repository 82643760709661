import React from 'react'
import routes from '../../../../../../common/routes'

/* style */
import { StyledDiv, StyledLink, StyledImg } from './style'

const Logo = ({ SailGPTop, as }) => {
  return (
    <StyledDiv as={as}>
      <StyledLink route={routes.program} params={SailGPTop}>
        <StyledImg src="https://wws.tv-asahi.co.jp/douga_mv/sailgp/images/sailgp_logo.png" />
      </StyledLink>
    </StyledDiv>
  )
}

export default Logo
