import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import styled from 'styled-components'
import routes from '../../../../common/routes'
import webApp from '../../../utils/exdioWebAppUtils'
import useIsMounted from '../../../../hooks/useIsMounted'
import { Link } from '../../../../../sketch-platform/ui/routing'
import Footer from '../PlanGariben/Footer'
import { mediaQuery } from '../../style'

/** ガリベン大学アニメ祭り 会員限定ページ */
const GaribenAnimeMatsuri = ({ ...props }, context) => {
  const isMounted = useIsMounted()
  const loggedIn = webApp.utils.isLoggedIn(context)
  const model = context.falcorModel.batch(100)

  const [isPurchased, setIsPurchased] = useState(false)

  /** コース情報取得 */
  const getCourse = async () => {
    const path = ['courseBySlug', 'gariben_daigaku']
    return model
      .fetch([path])
      .then((result) => get(result, ['json', ...path]))
      .catch((error) => {
        console.error('Error fetching course', error)
      })
  }

  /** グループのライセンス有無取得 */
  const getBelonging = async (course) => {
    if (!course) return Promise.reject()
    if (!loggedIn) return Promise.resolve(false)

    const refId = course.ref_id
    if (!refId) return Promise.reject()

    const path = ['infra', 'gBelong', refId]

    return model
      .fetch([path])
      .then((result) => get(result, ['json', ...path]) || false)
      .catch(() => false)
  }

  /** SPAでのHTML HEADタグ更新 */
  const updateMetaTags = () => {
    const title =
      'ABEMAアニメ祭「ガリベンガーV」スペシャルステージチケットを私立ガリベン大学会員限定で先行発売中！| テレ朝動画'
    webApp.utils.updateTitle(title)

    const description =
      '9月15日（日）にABEMAアニメ祭で行われる「ガリベンガーV」スペシャルステージのチケットを私立ガリベン大学会員限定で先行発売中！'
    const metaTags = {
      names: [
        { name: 'description', content: description },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description }
      ],
      properties: [
        { property: 'og:title', content: title },
        { property: 'og:description', content: description }
      ]
    }
    webApp.utils.updateMeta(metaTags)
  }

  useEffect(() => {
    let ignore = false

    /** HEADタグ更新 */
    updateMetaTags()

    /** 条件に合わないユーザを他ページに遷移 */
    ;(async () => {
      const resCourse = await getCourse()
      const belonging = await getBelonging(resCourse) // コースの取得を待つ
      if (!ignore) setIsPurchased(belonging || false)

      /**
       * course, isPurchasedの取得が完了したら判別を行う
       * 未ログインの場合 => ログインページ
       * 未購入の場合 => エラーページへリダイレクト
       * 購入済みの場合 => ページを表示
       */
      if (!loggedIn) {
        context.history.push(
          routes.login.makePath({}, { redirect: context.routeHandler.path })
        )
      } else if (!belonging) {
        context.history.push(routes.gariben_anime_matsuri_error.makePath())
      }
    })()

    return () => {
      ignore = true
    }
  }, [])

  if (!isMounted || !isPurchased) return null

  return (
    <>
      <StyledMain {...props}>
        <StyledH1>
          <img
            src="/images/GaribenAnimeMatsuri/garibenV_1920x1080_4.png"
            alt="ABEMAアニメ祭「ガリベンガーV」スペシャルステージチケットを私立ガリベン大学会員限定で先行発売中！| テレ朝動画"
          />
        </StyledH1>
        <StyledH2>
          ABEMAアニメ祭
          <StyledBr />
          『謎解き戦士！ガリベンガーV』スペシャルステージ
        </StyledH2>
        <StyledP1>
          私立ガリベン大学会員限定 <StyledBr />
          ファンクラブ先行販売チケット購入はこちら
        </StyledP1>
        <StyledP2>※イープラスのファンクラブ先行販売ページに移動します</StyledP2>
        <StyledForm
          method="post"
          action="https://eplus.jp/sf/detail/4135850001?P6=766"
        >
          <input type="submit" value="申し込みへ"></input>
          <input type="hidden" name="keyword" value="14020"></input>
        </StyledForm>
        <StyledLink route={routes.plan} params={{ slug: 'gariben_daigaku' }}>
          戻る
        </StyledLink>
      </StyledMain>
      <Footer />
    </>
  )
}

export default GaribenAnimeMatsuri

GaribenAnimeMatsuri.contextTypes = {
  models: PropTypes.object,
  falcorModel: PropTypes.object,
  history: PropTypes.object,
  routeHandler: PropTypes.object
}

const StyledMain = styled.main`
  margin: 0px auto;
  padding: 30px 15px 80px;
  min-width: 320px;
  max-width: 990px;
  line-height: 1.5;
  align-content: center;
  text-align: center;
`

const StyledH1 = styled.h1`
  display: flex;
`
const StyledH2 = styled.h2`
  margin-top: 30px;
  color: #707070;
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;

  ${mediaQuery()} {
    font-size: 1.8rem;
  }
`

const StyledP1 = styled.p`
  margin-top: 20px;
  color: #707070;
  font-size: 2.2rem;
  text-align: center;

  ${mediaQuery()} {
    font-size: 1.8rem;
  }
`

const StyledP2 = styled.p`
  margin-top: 18px;
  color: #707070;
  text-align: center;
`

const StyledForm = styled.form`
  margin: 40px auto 0;
  width: 174px;
  height: 42px;
  display: block;
  background: #f0f0f0;
  border: 1px solid #707070;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.2s;

  input {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`

const StyledLink = styled(Link)`
  margin: 110px auto 0;
  width: fit-content;
  display: block;
  color: #03a9f4 !important;
  transition: opacity 0.2s;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`

const StyledBr = styled.br`
  ${mediaQuery('sm', 'min')} {
    display: none;
  }
`
